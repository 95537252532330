// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type InvoiceTableColumnsType} from "@/modules/invoices";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface InvoiceTableColumnsStoreState {
  storeInvoiceTableColumns: InvoiceTableColumnsType | null;
  setStoreInvoiceTableColumns: (
    storeInvoiceTableColumns: InvoiceTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for invoice table columns
export const useInvoiceTableColumnsStore = create(
  persist<InvoiceTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeInvoiceTableColumns: null,

      // Getter function to retrieve the current state
      getStoreInvoiceTableColumns: () => get().storeInvoiceTableColumns,

      // Setter function to update the state
      setStoreInvoiceTableColumns: (storeInvoiceTableColumns) => {
        set(() => ({storeInvoiceTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "invoices-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useInvoiceTableColumns = () =>
  useInvoiceTableColumnsStore((state) => {
    return {
      storeInvoiceTableColumns: state.storeInvoiceTableColumns,
    };
  });
