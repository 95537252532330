import {HistoryIcon, ReceiptIcon} from "lucide-react";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from "@/components/ui/card";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "@/components/ui/tabs";

import {
  ImREmitInvoiceHistory,
  ImREmitPaymentHistory,
  ProxyPayWebPaymentHistory,
} from "@/modules/imremit";

interface ProxyPayDetailsFooterPros {
  id: string;
  paymentMode: string;
}

export function ProxyPayDetailsFooter({
  id,
  paymentMode,
}: ProxyPayDetailsFooterPros) {
  return (
    <Tabs defaultValue="invoices" className="w-full">
      <TabsList className="grid h-12 w-full grid-cols-3">
        <TabsTrigger value="invoices">
          <ReceiptIcon className="p-1" />
          Invoices
        </TabsTrigger>
        <TabsTrigger value="history">
          <HistoryIcon className="p-1" />
          History
        </TabsTrigger>
        {paymentMode == "Pay By Web" && (
          <TabsTrigger value="webPaymentHistory">
            <HistoryIcon className="p-1" />
            Web Payment History
          </TabsTrigger>
        )}
      </TabsList>
      <TabsContent value="invoices">
        <Card>
          <CardHeader>
            <CardDescription>
              Changes to invoices do not affect the payable total. If invoices
              have been added, they will appear at the bottom of the table
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            {/* INVOICE HISTORY  */}
            <ImREmitInvoiceHistory id={id.toString()} />
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value="history">
        <Card>
          <CardContent className="space-y-2 pt-5">
            {/* PAYMENT HISTORY */}
            <ImREmitPaymentHistory id={id.toString()} />
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value="webPaymentHistory">
        <Card>
          <CardContent className="space-y-2 pt-5">
            {/* WEB PAYMENT HISTORY */}
            <ProxyPayWebPaymentHistory id={id.toString()} />
          </CardContent>
        </Card>
      </TabsContent>
    </Tabs>
  );
}
