import {useEffect} from "react";

import {Heading2, Paragraph} from "@/components/ui/typography";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {ImREmitRoutesSchema} from "@/lib/routes/types";
import {useImRemitCustomerStore, useRoutesStore} from "@/lib/stores";
import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useAuthorization,
} from "@/modules/auth";
import {
  SelectCustomerFormImRemit,
  SelectCustomerSectionImremit,
} from "@/modules/customers";
import {PDFUploadDropzone} from "@/modules/imremit";

export function PDFUpload() {
  const {setimREmitRoute} = useRoutesStore();
  const {imREmitStoreCustomer} = useImRemitCustomerStore();
  const {checkPoliciesAccess} = useAuthorization();

  const externalId = imREmitStoreCustomer?.externalId;

  useEffect(() => {
    setimREmitRoute(ImREmitRoutesSchema.Values["/app/imremit/pdf-upload"]);
  }, [setimREmitRoute]);

  // Handle unauthorized access
  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_IMREMIT_MAPPING])) {
    return <NotAuthorizedPage />;
  }

  // If the customer is not yet selected, render the SelectCustomerSectionImremit
  if (!imREmitStoreCustomer) {
    return <SelectCustomerSectionImremit />;
  }
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.READ_IMREMIT_MAPPING]}
    >
      <section
        className="flex flex-col"
        aria-label="This is the Supplier List level"
      >
        <header className="flex flex-col gap-2 lg:flex-row lg:items-center lg:justify-between">
          <div className="flex flex-col">
            <Heading2 className="self-start">PDF Upload Page</Heading2>
            <Paragraph className="self-start">
              Welcome to the PDF Upload page
            </Paragraph>
          </div>
          <div className="flex gap-2 p-2">
            <SelectCustomerFormImRemit />
          </div>
        </header>
        <section className="flex flex-col gap-4">
          <PDFUploadDropzone buyerExternalId={externalId || ""} />
        </section>
      </section>
    </AccessPolicyWrapper>
  );
}
