import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {formatAmount, GetKeycloakUserName} from "@/modules/admin";
import {
  RemittanceManagementRowActions,
  RenderCustomerDescription,
  RenderCustomerStatus,
  renderRemittanceBadge,
  type ImREmitPaymentType,
} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";

export const RemittanceManagementColumns: ColumnDef<ImREmitPaymentType>[] = [
  {
    accessorKey: "orgId",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Org Id" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("orgId") ? (
          row.getValue("orgId")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },

  {
    accessorKey: "supplierName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Supplier Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("supplierName") ? (
          row.getValue("supplierName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },

  {
    accessorKey: "supplierNumber",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Supplier ID" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("supplierNumber") ? (
          row.getValue("supplierNumber")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },

  {
    accessorKey: "facilityName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Facility Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("facilityName") ? (
          row.getValue("facilityName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "paymentNumber",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment Number" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("paymentNumber") ? (
          row.getValue("paymentNumber")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "accountNumber",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Account Number" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("accountNumber") ? (
          row.getValue("accountNumber")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "totalAmountSent",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Sent Amount" />
    ),
    cell: ({row}) => {
      const amountValue = row.original.totalAmountSent;

      // Check if empty and return "no data" if empty
      const formattedAmount = amountValue ? (
        formatAmount(amountValue)
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      );

      return <Paragraph>{formattedAmount}</Paragraph>;
    },
  },
  {
    accessorKey: "initiatedDate",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Sent Date" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("initiatedDate") ? (
          <Paragraph className="whitespace-nowrap">
            {row.getValue("initiatedDate")}
          </Paragraph>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "customerStatus",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({row}) => {
      if (!row.original.customerStatus) {
        return (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );
      }

      return RenderCustomerStatus(row.original.customerStatus);
    },
  },
  {
    accessorKey: "statusName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Status Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("statusName") ? (
          <Paragraph className="whitespace-nowrap">
            {row.getValue("statusName")}
          </Paragraph>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },

  {
    accessorKey: "description",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Status Description" />
    ),
    cell: ({row}) => {
      if (!row.original.description) {
        return (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );
      }

      return RenderCustomerDescription(
        row.original.customerStatus || "",
        row.original.description
      );
    },
  },
  {
    accessorKey: "endDate",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="End Date" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("endDate") ? (
          <Paragraph className="whitespace-nowrap">
            {row.getValue("endDate")}
          </Paragraph>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "methodName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Method Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("methodName") ? (
          row.getValue("methodName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "descriptionDetail",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Description Detail" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("descriptionDetail") ? (
          row.getValue("descriptionDetail")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "amountTaken",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Amount Taken" />
    ),
    cell: ({row}) => {
      const amountValue = row.original.amountTaken;

      // Check if empty and return "no data" if empty
      const formattedAmount = amountValue ? (
        formatAmount(amountValue)
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      );

      return <Paragraph>{formattedAmount}</Paragraph>;
    },
  },
  {
    accessorKey: "balanceAmount",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Balance Amount" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("balanceAmount") ? (
          row.getValue("balanceAmount")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "userId",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Agent Name" />
    ),
    cell: ({row}) => {
      const userId = row.original.userId;

      if (!userId) {
        return (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );
      }

      return <GetKeycloakUserName userId={userId} />;
    },
  },
  {
    accessorKey: "remittanceMethod",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Remittance Method" />
    ),
    cell: ({row}) => {
      if (!row.original.remittanceMethod) {
        return <Paragraph>{FallbackMessages.NO_DATA}</Paragraph>;
      }

      return renderRemittanceBadge(row.original.remittanceMethod);
    },
  },
  {
    id: "actions",
    header: () => <Paragraph className="text-right">Actions</Paragraph>,
    cell: ({row}) => (
      <div className="flex w-[80] justify-end">
        <RemittanceManagementRowActions
          paymentId={row.original.paymentDetailId.toString()}
          isReprocess={row.original.isReprocess || false}
          supplierOnboardStatus={row.original.supplierOnboardStatus || ""}
          isEditable={!!row.original.isEditable}
        />
      </div>
    ),
  },
];
