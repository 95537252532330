import {useState} from "react";

import {type Table} from "@tanstack/react-table";
import {ListRestartIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
// import {
//   HoverCard,
//   HoverCardArrow,
//   HoverCardContent,
//   HoverCardTrigger,
// } from "@/components/ui/hover-card";
import {Input} from "@/components/ui/input";
// import {Separator} from "@/components/ui/separator";
// import {Heading3, Paragraph} from "@/components/ui/typography";
import {
  // DataTableFacetedFilter,
  DataTablePagination,
  DataTableViewOptions,
} from "@/components/craft/data-table";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

// import {
//   billingTypes,
//   paymentMethodTypes,
//   paymentTypes,
//   settlementTermsTypes,
//   statusTypes,
// } from "@/modules/admin";

/**
 * Props for DupesCriteriaSettingsDataTableToolbar component.
 *
 * @template TData - The type of data used in the table.
 * @typedef {Object} DupesCriteriaSettingsDataTableToolbarProps
 * @property {Table<TData>} table - The table instance to interact with.
 */
interface DupesCriteriaSettingsDataTableToolbarProps<TData> {
  table: Table<TData>;
  isPending?: boolean;
  isErrored?: boolean;
}

/**
 * Toolbar component for customer management data table.
 * Includes pagination, search input, filter components, and view options.
 *
 * @template TData - The type of data displayed in the table.
 * @param {DupesCriteriaSettingsDataTableToolbarProps<TData>} props - The component props.
 * @returns {JSX.Element} The rendered JSX element.
 */
export function DupesCriteriaSettingsDataTableToolbar<TData>({
  table,
  isPending,
  isErrored,
}: DupesCriteriaSettingsDataTableToolbarProps<TData>) {
  // State for the global filter input
  const [globalFilterInput, setGlobalFilterInput] = useState("");
  // State for the specific filter input for invoice numbers
  const [characterFilterInput, setCharacterFilterInput] = useState("");

  // Determine if the table is currently filtered
  const isFiltered =
    table.getState().columnFilters.length > 0 ||
    !!table.getState().globalFilter;

  //Columns other names
  const columnsOtherName = [
    {key: "type", value: "Criteria Type"},
    {key: "character", value: "Character"},
    {key: "type_key", value: "Type Key"},
    {key: "weight", value: "Weight"},
    {key: "description", value: "Description"},
    {key: "created_date", value: "Date Created"},
    {key: "modified_date", value: "Date Modified"},
  ];
  return (
    <>
      <DataTablePagination
        table={table}
        isPending={isPending}
        isErrored={isErrored}
      />
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-1 flex-wrap items-center gap-y-2 space-x-2">
          <RowsPreferenceSelect />
          <Input
            placeholder="Search all entries..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={globalFilterInput}
            onChange={(event) => {
              setGlobalFilterInput(event.target.value);
              table.setGlobalFilter(event.target.value);
            }}
          />
          <Input
            placeholder="Search character..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={characterFilterInput}
            onChange={(event) => {
              setCharacterFilterInput(event.target.value);
              table.getColumn("character")?.setFilterValue(event.target.value);
            }}
          />
          {isFiltered && (
            <Button
              variant="destructive"
              className="h-10 gap-2 px-2 hover:bg-destructive/70 hover:text-destructive-foreground/80 active:bg-destructive/80 active:text-destructive-foreground/80 lg:px-3"
              onClick={() => {
                table.resetColumnFilters();
                table.resetGlobalFilter();
                setGlobalFilterInput("");
                setCharacterFilterInput("");
              }}
            >
              <span className="sr-only">Reset search filters</span>
              Search Reset
              <ListRestartIcon className="size-4" />
            </Button>
          )}
        </div>
        <div className="flex items-center space-x-2">
          {/* {table.getColumn("status") && (
            <DataTableFacetedFilter
              column={table.getColumn("status")}
              title="Status"
              options={statusTypes}
            />
          )}
          {table.getColumn("billingType") && (
            <DataTableFacetedFilter
              column={table.getColumn("billingType")}
              title="Billing"
              options={billingTypes}
            />
          )}
          {table.getColumn("paymentType") && (
            <DataTableFacetedFilter
              column={table.getColumn("paymentType")}
              title="Payment Type"
              options={paymentTypes}
            />
          )}
          {table.getColumn("paymentMethod") && (
            <DataTableFacetedFilter
              column={table.getColumn("paymentMethod")}
              title="Payment Method"
              options={paymentMethodTypes}
            />
          )}
          {table.getColumn("settlementTerms") && (
            <DataTableFacetedFilter
              column={table.getColumn("settlementTerms")}
              title="Settlement"
              options={settlementTermsTypes}
            />
          )} */}
          {/* <HoverCard openDelay={125} closeDelay={50}>
            <HoverCardTrigger>
              <Button
                variant="outline"
                size="xs"
                className="gap-2 border-dashed px-2 lg:px-3"
              >
                <FilterIcon className="size-4" />
                Filters
              </Button>
            </HoverCardTrigger>
            <HoverCardContent>
              <HoverCardArrow />
              <Heading3>Filters</Heading3>
              <div className="flex flex-col gap-y-1 pb-2">
                <div className="flex items-center gap-2">
                  <Paragraph>Exclude commented invoices: </Paragraph>
                  <Checkbox />
                </div>
                <div className="flex items-center gap-2">
                  <Paragraph>Exclude customer status invoices: </Paragraph>
                  <Checkbox />
                </div>
                <div className="flex items-center gap-2">
                  <Paragraph>Exclude reassigned invoices: </Paragraph>
                  <Checkbox />
                </div>
              </div>
              <Separator className="w-full bg-muted-foreground" />

              <div className="grid grid-cols-1 gap-x-4 gap-y-2 pb-2 pt-2 lg:grid-cols-2">
                <div className="flex flex-col gap-1">
                  <Paragraph>Facility Name: </Paragraph>
                  <Input
                    placeholder="Search facility name..."
                    className="h-10"
                    value={facilityNameFilterInput}
                    onChange={(event) => {
                      setFacilityNameFilterInput(event.target.value);
                      table
                        .getColumn("facility_name")
                        ?.setFilterValue(event.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <Paragraph>Supplier Name: </Paragraph>
                  <Input
                    placeholder="Search supplier name..."
                    className="h-10"
                    value={vendorNameFilterInput}
                    onChange={(event) => {
                      setVendorNameFilterInput(event.target.value);
                      table
                        .getColumn("vendor_name")
                        ?.setFilterValue(event.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <Paragraph>Supplier Id: </Paragraph>
                  <Input
                    placeholder="Search supplier id..."
                    className="h-10"
                    value={supplierParticipantTaxCodeFilterInput}
                    onChange={(event) => {
                      setSupplierParticipantTaxCodeFilterInput(
                        event.target.value
                      );
                      table
                        .getColumn("supplier_participant_tax_code")
                        ?.setFilterValue(event.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <Paragraph>Status: </Paragraph>
                  <Input
                    placeholder="Search status..."
                    className="h-10"
                    value={statusFilterInput}
                    onChange={(event) => {
                      setStatusFilterInput(event.target.value);
                      table
                        .getColumn("invoice_status")
                        ?.setFilterValue(event.target.value);
                    }}
                  />
                </div>
              </div>

              <Separator className="w-full bg-muted-foreground" />
              <div className="flex flex-col gap-4 pt-2 lg:flex-row">
                <div className="flex flex-col">
                  <Paragraph>Payment Date Range: </Paragraph>
                  <DatePickerWithStateRange
                    className="bg-root"
                    dateRangeState={paymentDateRangeState}
                    setDateRangeState={setPaymentDateRangeState}
                  />
                </div>
                <div className="flex flex-col">
                  <Paragraph>Comparison Payment Date Range: </Paragraph>
                  <DatePickerWithStateRange
                    className="bg-root"
                    dateRangeState={comparisonDateRangeState}
                    setDateRangeState={setComparisonPaymentDateRangeState}
                  />
                </div>
              </div>
            </HoverCardContent>
          </HoverCard> */}
          <DataTableViewOptions
            table={table}
            columnsOtherName={columnsOtherName}
          />
        </div>
      </div>
    </>
  );
}
