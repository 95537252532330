import type {DupesComparisonTypeEnum} from "@/modules/duplicate-payments";

export const dupesCustomerSettingsKeys = {
  // 🛍️🔧 A list that stores the key for accessing all customer-specific settings related to dupes
  all: ["dupes-customer-settings"] as const,
  // 🛍️🔧 A list that stores the key for accessing all customer-specific settings by customer_id
  byCustomerId: (externalId?: string) =>
    [...dupesCustomerSettingsKeys.all, externalId] as const,
};

export const dupesRunHistoryKeys = {
  // 🛍️🔧 A list that stores the key for accessing all customer-specific settings related to dupes
  all: ["dupes-run-history"] as const,
  // 🛍️🔧 A list that stores the key for accessing all customer-specific settings by customer_id
  byCustomerId: (externalId?: string) =>
    [...dupesRunHistoryKeys.all, externalId] as const,
};

export const dupesDashboardKeys = {
  // 🖥️📊 A list that stores the key for accessing all dupes displayed on the dashboard
  all: ["dupes-dashboard"] as const,
};

export const dupesDefaultSettingsKeys = {
  // ⚙️🌐 A list that stores the key for accessing all default system settings for handling dupes
  all: ["dupes-default-settings"] as const,
};

export const dupesExemptionCharacterTypeKeys = {
  // 🚫🔤 A list that stores the key for accessing all character types exempted in dupe checks
  all: ["dupes-exemption-character-type"] as const,
};

export const dupesExemptionCharacterKeys = {
  // 🚫📝 A list that stores the key for accessing all specific characters exempted in dupe checks
  all: ["dupes-exemption-character"] as const,
};

export const dupesExemptionCharactersHistoryKeys = {
  // 🚫📝🕰️ A list that stores the key for accessing all history of characters exempted in dupe checks
  all: ["dupes-exemption-characters-history"] as const,
};

export const dupesComparisonCountsKeys = {
  // 🧾🔍 A list that stores the key for accessing all initial comparisons between invoices for dupe detection
  all: ["dupes-initial-invoice-comparisons-counts"] as const,
  countsByExternalId: (externalId?: string) =>
    [...dupesComparisonCountsKeys.all, externalId] as const,
  amountsByExternalIdAndComparisonType: (
    comparisonType: DupesComparisonTypeEnum,
    externalId?: string,
    dupesRunType?: string,
    limitToLast90Days?: boolean
  ) =>
    [
      ...dupesComparisonCountsKeys.countsByExternalId(externalId),
      comparisonType,
      dupesRunType,
      limitToLast90Days,
    ] as const,
  countsByExternalIdRunType: (
    externalId?: string,
    dupesRunType?: string,
    limitToLast90Days?: boolean
  ) =>
    [
      ...dupesComparisonCountsKeys.countsByExternalId(externalId),
      dupesRunType,
      limitToLast90Days,
    ] as const,
};

export const dupesTrackerKeys = {
  // 🖥️🔍 A list that stores the key for accessing the tracker view of dupes
  all: ["dupes-tracker-view"] as const,
  // 🖥️🔍 A list that stores the key for accessing the tracker view of dupes by external_id and comparison_type
  byExternalIdAndComparisonType: (
    externalId?: string,
    comparisonType?: string,
    dupesRunType?: string,
    limitToLast90Days?: boolean
  ) =>
    [
      "dupes-tracker-view",
      externalId,
      comparisonType,
      dupesRunType,
      limitToLast90Days,
    ] as const,
};

export const dupesCriteriaSettingsKeys = {
  // 📋✅ A list that stores the key for accessing all criteria settings for identifying dupes
  all: ["dupes-criteria-settings"] as const,
  // 📋✅ A list that stores the key for accessing all criteria settings by external_id
  criteriaByExternalId: (externalId?: string) =>
    [...dupesCriteriaSettingsKeys.all, externalId] as const,
  // 📋✅ A list that stores the key for accessing all criteria settings by external_id and exemption_type_id
  criteriaByExternalIdAndExemptionTypeId: (
    externalId?: string,
    exemptionTypeId?: string
  ) =>
    [
      ...dupesCriteriaSettingsKeys.criteriaByExternalId(externalId),
      exemptionTypeId,
    ] as const,
};
