import {z} from "zod";

export const invoiceStatusHistorySchema = z.object({
  date: z.string().nullable(),
  status: z.string().nullable(), // TODO: pass in shared status enum here
  changedBy: z.string().nullable(),
  paymentNo: z.number().nullable(),
});

export type InvoiceStatusHistory = z.infer<typeof invoiceStatusHistorySchema>;
