import {z} from "zod";

export const SupplierManagementCommentSchema = z.object({
  commentId: z.number(),
  dateTime: z.date(),
  userId: z.string(),
  comments: z.string(),
  markAsRead: z.boolean(),
});

export type SupplierManagementCommentType = z.infer<
  typeof SupplierManagementCommentSchema
>;
