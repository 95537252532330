// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type ReconMappingAdminTableColumnsType} from "@/modules/imremit";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface ReconMappingAdminTableColumnsStoreState {
  storeReconMappingAdminTableColumns: ReconMappingAdminTableColumnsType | null;
  setStoreReconMappingAdminTableColumns: (
    storeReconMappingAdminTableColumns: ReconMappingAdminTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for ReconMappingAdmin table columns
export const useReconMappingAdminTableColumnsStore = create(
  persist<ReconMappingAdminTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeReconMappingAdminTableColumns: null,

      // Getter function to retrieve the current state
      getStoreReconMappingAdminTableColumns: () =>
        get().storeReconMappingAdminTableColumns,

      // Setter function to update the state
      setStoreReconMappingAdminTableColumns: (
        storeReconMappingAdminTableColumns
      ) => {
        set(() => ({storeReconMappingAdminTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "ReconMappingAdmin-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useReconMappingAdminTableColumns = () =>
  useReconMappingAdminTableColumnsStore((state) => {
    return {
      storeReconMappingAdminTableColumns:
        state.storeReconMappingAdminTableColumns,
    };
  });
