/**
 * Format the given amount to USD currency.
 *
 * @param {string|number} amount - The amount to be formatted.
 * @returns {string} - The formatted amount in USD currency.
 */
export const formatAmount = (amount: string | number) => {
  const numberValue = typeof amount === "string" ? parseFloat(amount) : amount;

  /**
   * @type {string}
   */
  const formattedAmount = numberValue.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedAmount;
};
