import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  webPaymentHistoryKeys,
  type WebPaymentHistoryType,
} from "@/modules/imremit";

type QueryFnType = typeof getWebPaymentHistory;

// Function to search useWebPaymentHistory
export function getWebPaymentHistory(paymentDetailId?: string) {
  return kyApiFn<WebPaymentHistoryType[]>(
    `api/e-payable/paybyweb-transaction/${paymentDetailId}/histories`,
    "get"
  );
  // TODO: validate data
}

// Custom hook to fetch and cache useWebPaymentHistory data using react-query
export function useWebPaymentHistory(
  paymentDetailId?: string,
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: webPaymentHistoryKeys.byId(paymentDetailId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getWebPaymentHistory(paymentDetailId), // Setting the function to be used for fetching data
    enabled: !!paymentDetailId, // Setting the query to be enabled only if the search query string is not empty
  });
}
