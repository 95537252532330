import {useCallback, useEffect, useMemo} from "react";

import {Link, useNavigate, useParams} from "@tanstack/react-router";
import {ArrowRightIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Heading2, Paragraph} from "@/components/ui/typography";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import type {PaginationMetaType} from "@/lib/ky";
import {queryClient} from "@/lib/query-client";
import {useImRemitCustomerStore, usePreferencesStore} from "@/lib/stores";
import {KeycloakRoleEnum, useAuthorization} from "@/modules/auth";
import {SelectCustomerSectionImremit} from "@/modules/customers";
import {
  getPaymentManagementCardsFilter,
  PaymentManagementCardsFilterDataTable,
  paymentManagementCardsFilterKeys,
  useGetPaymentManagementCardsFilter,
  useGetPaymentManagementFilterListColumns,
  usePaymentMgmtCardFilterMetaStore,
} from "@/modules/imremit";

import {listType} from "@/utils/constants";
import {replacePageQueryParam} from "@/utils/functions";
import {generateQueryParams} from "@/utils/query-params-generator";

/**
 * Custom Hook: Manage pagination and user preferences for the payment management dashbaord.
 *
 * @param {(pageNumber: number) => Promise<void>} setPageIndex - Function to update the current page index.
 * @returns {Object} - Number of rows per page and the current criteria for storing payment management data.
 */
function usePaymentManagementPaginationAndPreferences(
  setPageIndex: (pageNumber: number) => Promise<void>
) {
  const {numRowsPerPage} = usePreferencesStore();
  const {
    storePaymentMgmtCardFilterCriteria,
    storePaymentMgmtCardFilterPaginationMeta,
    storePaymentMgmtCardFilterSortMeta,
    setHandleOnPageChange,
  } = usePaymentMgmtCardFilterMetaStore();

  useEffect(() => {
    // Reset the page index if it is out of bounds
    if (storePaymentMgmtCardFilterPaginationMeta) {
      if (
        storePaymentMgmtCardFilterPaginationMeta.pageNumber < 0 ||
        storePaymentMgmtCardFilterPaginationMeta.pageNumber + 1 >
          storePaymentMgmtCardFilterPaginationMeta.totalPages
      ) {
        void setPageIndex(0);
      }
    }

    // Set the handler for page change events
    setHandleOnPageChange(setPageIndex);
  }, [
    setHandleOnPageChange,
    storePaymentMgmtCardFilterPaginationMeta,
    setPageIndex,
  ]);

  return {
    numRowsPerPage,
    storePaymentMgmtCardFilterCriteria,
    storePaymentMgmtCardFilterSortMeta,
  };
}

/**
 * Custom Hook: Fetch and prepare payment management data.
 *
 * @param {string} queryParams - The query parameters for the API request.
 * @returns {Object} - The content and metadata for the payment management, along with the API query object.
 */
function useFetchAndPreparePaymentManagement(queryParams: string) {
  const {methodType} = useParams({
    from: "/app/imremit/payment-management/$pmPage/payments/$methodType",
  });

  const {storePaymentMgmtCardFilterPaginationMeta} =
    usePaymentMgmtCardFilterMetaStore();

  const {imREmitStoreCustomer} = useImRemitCustomerStore();

  //Fetch the payment data based on the method
  const searchPaymentManagementQuery = useGetPaymentManagementCardsFilter(
    imREmitStoreCustomer?.externalId.toString(),
    methodType,
    queryParams
  );

  const paymentManagementContent = useMemo(() => {
    if (!searchPaymentManagementQuery.data) {
      return [];
    }
    return searchPaymentManagementQuery.data.content;
  }, [searchPaymentManagementQuery.data]);

  const paymentManagementPaginationMeta = useMemo(() => {
    if (!searchPaymentManagementQuery.data) {
      return storePaymentMgmtCardFilterPaginationMeta;
    }

    return searchPaymentManagementQuery.data.meta;
  }, [
    searchPaymentManagementQuery.data,
    storePaymentMgmtCardFilterPaginationMeta,
  ]);

  // Prefetch the next page of payment management
  useEffect(() => {
    if (paymentManagementPaginationMeta) {
      // Create the query params for the next page
      const nextPageQueryParams = replacePageQueryParam(
        queryParams,
        (paymentManagementPaginationMeta.pageNumber + 1).toString()
      );

      // Prefetch the next page of payment management
      void queryClient.prefetchQuery({
        queryKey: paymentManagementCardsFilterKeys.bySearch(
          imREmitStoreCustomer?.externalId.toString(),
          methodType,
          nextPageQueryParams
        ),
        queryFn: () =>
          getPaymentManagementCardsFilter(
            imREmitStoreCustomer?.externalId.toString(),
            methodType,
            nextPageQueryParams
          ),
      });
    }
  }, [
    queryParams,
    methodType,
    paymentManagementPaginationMeta,
    imREmitStoreCustomer?.externalId,
  ]);

  return {
    paymentManagementContent,
    paymentManagementPaginationMeta,
    searchPaymentManagementQuery,
  };
}

/**
 * Custom Hook: Manage payment management pagination metadata.
 *
 * @param {PaginationMetaType | null} paymentManagementPaginationMeta - The pagination metadata for PaymentManagement.
 */
function useManagePaymentManagementPaginationMeta(
  paymentManagementPaginationMeta: PaginationMetaType | null
) {
  const {setStorePaymentMgmtCardFilterPaginationMeta} =
    usePaymentMgmtCardFilterMetaStore();

  useEffect(() => {
    // Update the pagination metadata in the store if available
    if (paymentManagementPaginationMeta) {
      setStorePaymentMgmtCardFilterPaginationMeta(
        paymentManagementPaginationMeta
      );
    }
  }, [
    paymentManagementPaginationMeta,
    setStorePaymentMgmtCardFilterPaginationMeta,
  ]);
}

// Defining the PaymentManagementSearchFilterList component
export function PaymentManagementSearchFilterList() {
  const {imREmitStoreCustomer} = useImRemitCustomerStore();

  const navigate = useNavigate();

  const {pmPage, methodType} = useParams({
    from: "/app/imremit/payment-management/$pmPage/payments/$methodType",
  });

  //Method Descriptions
  const methodTypeDesc: string = listType
    .filter((result) => {
      return result.methodType === methodType;
    })
    .map((resp) => {
      return resp.methodDescription;
    })
    .toString();

  //Navigate to dashboard page if `methodTypeDesc` not match
  if (!methodTypeDesc) {
    void navigate({
      to: "/app/imremit/dashboard",
    });
  }

  const handleNavigationPagination = useCallback(
    (pageNumber: number) =>
      navigate({
        to: "/app/imremit/payment-management/$pmPage/payments/$methodType",
        params: {
          pmPage: (pageNumber + 1).toString(),
          methodType: methodType,
        },
      }),
    [navigate, methodType]
  );

  const paymentManagementPageNumber = useMemo(() => {
    // Reset the page index if it is out of bounds
    if (!pmPage || parseInt(pmPage) <= 0 || isNaN(parseInt(pmPage))) {
      void handleNavigationPagination(0);
      return 0;
    }

    return parseInt(pmPage) - 1;
  }, [pmPage, handleNavigationPagination]);

  // Fetch user preferences and pagination criteria
  const {
    numRowsPerPage,
    storePaymentMgmtCardFilterCriteria,
    storePaymentMgmtCardFilterSortMeta,
  } = usePaymentManagementPaginationAndPreferences(handleNavigationPagination);

  const searchPaymentManagementQueryParams = useMemo(
    () =>
      generateQueryParams({
        params: storePaymentMgmtCardFilterCriteria || {},
        page: paymentManagementPageNumber,
        size: numRowsPerPage,
        sort: storePaymentMgmtCardFilterSortMeta,
      }),
    [
      numRowsPerPage,
      paymentManagementPageNumber,
      storePaymentMgmtCardFilterCriteria,
      storePaymentMgmtCardFilterSortMeta,
    ]
  );

  // Fetch and prepare the Payment Management data
  const {
    paymentManagementContent,
    paymentManagementPaginationMeta,
    searchPaymentManagementQuery,
  } = useFetchAndPreparePaymentManagement(searchPaymentManagementQueryParams);

  // Update pagination metadata in the store
  useManagePaymentManagementPaginationMeta(paymentManagementPaginationMeta);

  const paymentManagementColumns = useGetPaymentManagementFilterListColumns();

  // Handle unauthorized access
  const {checkPoliciesAccess} = useAuthorization();
  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_PAYMENT_MANAGEMENT])) {
    return <NotAuthorizedPage />;
  }

  // TODO: replace with better error handling
  if (searchPaymentManagementQuery.isError) {
    return <Paragraph>Something went wrong...</Paragraph>;
  }

  // If the customer is not yet selected, render the SelectCustomerSectionImremit

  if (!imREmitStoreCustomer) {
    return <SelectCustomerSectionImremit />;
  }

  return (
    <section>
      <div className="mb-4 flex flex-col-reverse gap-4 md:flex-row md:justify-between">
        <div className="mb-2 flex flex-col">
          <Heading2>Payment Management</Heading2>
          <Paragraph>{methodTypeDesc}</Paragraph>
        </div>
        <Link to="/app/imremit/dashboard">
          <Button variant={"secondary"}>
            Back to dashboard
            <ArrowRightIcon className="ml-2 size-4" />
          </Button>
        </Link>
      </div>

      <PaymentManagementCardsFilterDataTable
        columns={paymentManagementColumns}
        data={paymentManagementContent}
      />
    </section>
  );
}
