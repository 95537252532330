import {Link} from "@tanstack/react-router";
import {HomeIcon, ShieldAlertIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Heading1, Heading2, Paragraph} from "@/components/ui/typography";

// This function returns not found UI that can be reused throughout our application.
export function NotAuthorized() {
  return (
    <div className="flex max-w-2xl flex-col items-center justify-center rounded-md bg-background p-8">
      <ShieldAlertIcon
        className="mb-4 h-32 w-32 text-rose-600"
        aria-hidden="true" // ShieldAlertIcon is purely decorative so it is hidden from screen readers.
      />
      <Heading1>Access Denied</Heading1>
      <Heading2>You do not have permission to access this resource</Heading2>
      <div className="flex flex-row items-center justify-between">
        <Paragraph>Please contact your administrator or go home: </Paragraph>
        <Link to="/" preload="intent">
          <Button variant="ghost" className="ml-1 p-2">
            <HomeIcon />
          </Button>
        </Link>
      </div>
    </div>
  );
}

export function NotAuthorizedPage() {
  return (
    <section className="mt-20 flex items-center justify-center text-center">
      <NotAuthorized />
    </section>
  );
}
