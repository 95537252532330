import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {supplierKeys, type SupplierType} from "@/modules/imremit";

// Defining a type based on the function `getSuppliersByCustomerId`
type QueryFnType = typeof getSuppliersByCustomerId;

// Function to search getSuppliersByCustomerId
export function getSuppliersByCustomerId(customerIds: string[]) {
  // Joining all customerIds into a string separated by comma
  const customerIdsString = customerIds.join(",");

  return kyApiFn<SupplierType[]>(
    `supplier/v1/getallsuppliers?customer_id=${customerIdsString}`,
    "get"
  );
}

// Custom hook to fetch and cache suppliers list based on the `participant id` data using react-query
export function useGetAllSuppliersByCustomerId(
  customerIds: string[],
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: supplierKeys.byCustomerIds(customerIds), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getSuppliersByCustomerId(customerIds), // Setting the function to be used for fetching data
    enabled: !!customerIds.length, // Setting the query to be enabled only if customerIds is present
  });
}
