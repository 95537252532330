import {useEffect, useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {
  CheckIcon,
  ChevronsUpDownIcon,
  FilePenIcon,
  InfoIcon,
  SaveIcon,
} from "lucide-react";
import {useForm, useFormContext} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {Card, CardContent, CardHeader} from "@/components/ui/card";
import {Checkbox} from "@/components/ui/checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {Spinner} from "@/components/ui/spinner";
import {TimePicker} from "@/components/ui/time-picker/time-picker";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {Heading3} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {cn} from "@/lib/utils";
import {
  addCustomerRunnerConfigFn,
  CustomerRunnerConfigEnum,
  ReconRunnerConfigFormSchema,
  updateCustomerRunnerConfigFn,
  useDateFormats,
  useDelimiterTypes,
  useDocumentFormats,
  usePaymentProviders,
  useRegisteredParticipants,
} from "@/modules/admin";
import {useKeyCloakInstanceStore} from "@/modules/auth";
import {useGetRunnerConfigs} from "@/modules/imremit";

const weeklyDays = [
  {value: "MON", label: "Monday"},
  {value: "TUE", label: "Tuesday"},
  {value: "WED", label: "Wednesday"},
  {value: "THU", label: "Thursday"},
  {value: "FRI", label: "Friday"},
  {value: "SAT", label: "Saturday"},
  {value: "SUN", label: "Sunday"},
];

const monthlyDays = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {value: "3", label: "3"},
  {value: "4", label: "4"},
  {value: "5", label: "5"},
];

interface AddReconRunnerConfigFormProps {
  externalId: string;
}

interface EditReconRunnerConfigFormProps
  extends AddReconRunnerConfigFormProps {}

export function AddReconRunnerConfigForm({
  externalId,
}: AddReconRunnerConfigFormProps) {
  // Need to call ParticipantRegister to check if there are more than one payment group
  const allParticipantRegistrations = useRegisteredParticipants(
    externalId.toString()
  );
  const storeKeyCloakInstance =
    useKeyCloakInstanceStore().storeKeyCloakInstance;
  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );
  const addReconRunnerConfigFormMethods = useForm<
    z.infer<typeof ReconRunnerConfigFormSchema>
  >({
    mode: "onChange",
    defaultValues: {
      batchRunnerType: CustomerRunnerConfigEnum.RECON_RUNNER,
      firstRowHeader: false,
      isHeaderRequired: false,
      partialPayReconRequired: false,
      isConsolidatedReconRequired: false,
      ignoreCredits: false,
      isEmptyReconRequired: false,
      isResponseFooterRequired: false,
      dailyReconBankRequired: false,
      monthlyReconBankRequired: false,
      weeklyReconBankRequired: false,
      removeDailyBankReconTimeStamp: false,
      removeMonthlyBankReconTimeStamp: false,
      removeWeeklyBankReconTimeStamp: false,
      isDailyReconPostDate: false,
      isResponseFooterRequiredRecon: false,
      isSeparatePaymentGroupingRequiredForDaily: false,
      isSeparatePaymentGroupingRequiredForWeekly: false,
      isSeparatePaymentGroupingRequiredForMonthly: false,
      validMinimumLength: 5,
      modifiedBy: parsedUserInfo?.name as string,
    },
    resolver: zodResolver(ReconRunnerConfigFormSchema),
  });

  const addReconRunnerConfigMutation = useReconAddRunnerConfig(externalId);
  function handleAdd(data: z.infer<typeof ReconRunnerConfigFormSchema>) {
    addReconRunnerConfigMutation.mutate(data);
  }

  const formatId = addReconRunnerConfigFormMethods.watch(
    "documentFormatDenormalize"
  );
  const allDenormalizeDocumentFormats = useDocumentFormats("4");
  const [isBAI2, setIsBAI2] = useState(false);
  const [scheduler, setScheduler] = useState(false);

  useEffect(() => {
    const selectedFormatId: number = Number(formatId);
    const selectedFormat = allDenormalizeDocumentFormats.find(
      (doc) => doc.value === selectedFormatId.toString()
    );

    setIsBAI2(selectedFormat?.label.includes("BAI2") || false);
  }, [formatId, allDenormalizeDocumentFormats]);

  useEffect(() => {
    if (isBAI2) {
      addReconRunnerConfigFormMethods.setValue("isHeaderRequired", false);
      addReconRunnerConfigFormMethods.setValue("isEmptyReconRequired", false);
      addReconRunnerConfigFormMethods.setValue(
        "isResponseFooterRequiredRecon",
        false
      );
      addReconRunnerConfigFormMethods.setValue("lineSeparator", "5");
      addReconRunnerConfigFormMethods.setValue(
        "responseHeaderDateFormatRecon",
        "yyMMdd"
      );
      addReconRunnerConfigFormMethods.setValue(
        "responseHeaderDateFormat",
        CustomerRunnerConfigEnum.RECON_RUNNER
      );
      addReconRunnerConfigFormMethods.setValue(
        "dateFormat",
        CustomerRunnerConfigEnum.RECON_RUNNER
      );
    }
  }, [isBAI2, addReconRunnerConfigFormMethods]);

  const dailyrequired = addReconRunnerConfigFormMethods.watch(
    "dailyReconBankRequired"
  );
  const weeklyRequired = addReconRunnerConfigFormMethods.watch(
    "weeklyReconBankRequired"
  );
  const monthlyRequired = addReconRunnerConfigFormMethods.watch(
    "monthlyReconBankRequired"
  );

  useEffect(() => {
    if (!dailyrequired && !weeklyRequired && !monthlyRequired) {
      // DenormalizeDocumentFormatField is now mandatory -INP-4171
      //addReconRunnerConfigFormMethods.setValue("documentFormatDenormalize", "");
      setScheduler(true);
    } else {
      setScheduler(false);
    }
  }, [
    dailyrequired,
    weeklyRequired,
    monthlyRequired,
    addReconRunnerConfigFormMethods,
  ]);

  const {
    formState: {errors},
  } = addReconRunnerConfigFormMethods;

  console.log("errors", errors);

  return (
    <Form {...addReconRunnerConfigFormMethods}>
      <form onSubmit={addReconRunnerConfigFormMethods.handleSubmit(handleAdd)}>
        <div className="my-5 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <PaymentProviderField
            isMutating={addReconRunnerConfigMutation.isPending}
          />
          <DocumentFormatField
            isMutating={addReconRunnerConfigMutation.isPending}
          />

          <DateFormatField
            isMutating={addReconRunnerConfigMutation.isPending}
          />

          <DelimiterTypeField
            isMutating={addReconRunnerConfigMutation.isPending}
          />

          <FileNameContainerField
            isMutating={addReconRunnerConfigMutation.isPending}
          />

          <ValidMinLengthField
            isMutating={addReconRunnerConfigMutation.isPending}
          />

          <DenormalizeDocumentFormatField
            scheduler={scheduler}
            isMutating={addReconRunnerConfigMutation.isPending}
          />
          <FileNameDateFormatField
            isMutating={addReconRunnerConfigMutation.isPending}
          />
          <ExtensionField isMutating={addReconRunnerConfigMutation.isPending} />
          <LineSeparatorField
            isBAI2={isBAI2}
            isMutating={addReconRunnerConfigMutation.isPending}
          />
          <ResponseHeaderDateFormatReconField
            isBAI2={isBAI2}
            isMutating={addReconRunnerConfigMutation.isPending}
          />

          <IsCustomerReconViaEmailField
            isMutating={addReconRunnerConfigMutation.isPending}
          />
        </div>
        <Card>
          <CardHeader>
            <Heading3>Runner Details</Heading3>
          </CardHeader>
          <CardContent className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            <FirstRowHeaderField
              isMutating={addReconRunnerConfigMutation.isPending}
            />
            <IsHeaderRequiredField
              isBAI2={isBAI2}
              isMutating={addReconRunnerConfigMutation.isPending}
            />
            <IsEmptyReconRequiredField
              isBAI2={isBAI2}
              isMutating={addReconRunnerConfigMutation.isPending}
            />
            <IsFooterRequiredFieldForBankFileField
              isBAI2={isBAI2}
              isMutating={addReconRunnerConfigMutation.isPending}
            />

            <DailyReconBankRequiredField
              isMutating={addReconRunnerConfigMutation.isPending}
            />
            <WeeklyReconBankRequired
              isMutating={addReconRunnerConfigMutation.isPending}
            />
            <MonthlyReconBankRequired
              isMutating={addReconRunnerConfigMutation.isPending}
            />
            <IsConsolidatedReconRequiredField
              isMutating={addReconRunnerConfigMutation.isPending}
            />

            <PartialPayReconRequiredField
              isMutating={addReconRunnerConfigMutation.isPending}
            />
            {addReconRunnerConfigFormMethods.watch(
              "isConsolidatedReconRequired"
            ) === false && (
              <IgnoreCreditsField
                isMutating={addReconRunnerConfigMutation.isPending}
              />
            )}
          </CardContent>
        </Card>
        {addReconRunnerConfigFormMethods.watch("dailyReconBankRequired") ===
          true && (
          <div className="my-5 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6">
            <ReconDailyValue
              isMutating={addReconRunnerConfigMutation.isPending}
            />
            <DailySelectedTime />
            <DailyfileNamePart
              isMutating={addReconRunnerConfigMutation.isPending}
            />
            <DailyReconTimeStamp
              isMutating={addReconRunnerConfigMutation.isPending}
            />
            <ReconPostDate
              isMutating={addReconRunnerConfigMutation.isPending}
            />
            <DailyReconPaymentGroup
              isMutating={
                addReconRunnerConfigMutation.isPending ||
                allParticipantRegistrations.length <= 1
              }
            />
          </div>
        )}
        {addReconRunnerConfigFormMethods.watch("weeklyReconBankRequired") ===
          true && (
          <div className="my-5 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6">
            <ReconWeeklyValue
              isMutating={addReconRunnerConfigMutation.isPending}
            />
            <WeeklySelectedTime />
            <WeeklyfileNamePart
              isMutating={addReconRunnerConfigMutation.isPending}
            />
            <WeeklyReconTimeStamp
              isMutating={addReconRunnerConfigMutation.isPending}
            />
            <WeeklyReconPaymentGroup
              isMutating={
                addReconRunnerConfigMutation.isPending ||
                allParticipantRegistrations.length <= 1
              }
            />
          </div>
        )}
        {addReconRunnerConfigFormMethods.watch("monthlyReconBankRequired") ===
          true && (
          <div className="my-5 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6">
            <ReconMonthlyValue
              isMutating={addReconRunnerConfigMutation.isPending}
            />
            <MonthlySelectedTime />
            <MonthlyfileNamePart
              isMutating={addReconRunnerConfigMutation.isPending}
            />
            <MonthlyReconTimeStamp
              isMutating={addReconRunnerConfigMutation.isPending}
            />

            <MonthlyReconPaymentGroup
              isMutating={
                addReconRunnerConfigMutation.isPending ||
                allParticipantRegistrations.length <= 1
              }
            />
          </div>
        )}
        <div className="flex items-center justify-end space-x-2 py-4">
          <Button
            type="submit"
            className="gap-2"
            variant="affirmative"
            disabled={addReconRunnerConfigMutation.isPending}
            aria-disabled={addReconRunnerConfigMutation.isPending}
          >
            <span className="sr-only"> Add Recon Config Runner</span>
            Add Recon Config Runner
            <SaveIcon className="size-4" />
          </Button>
        </div>
      </form>
    </Form>
  );
}

export function EditReconRunnerConfigForm({
  externalId,
}: EditReconRunnerConfigFormProps) {
  // Need to call ParticipantRegister to check if there are more than one payment group
  const allParticipantRegistrations = useRegisteredParticipants(
    externalId.toString()
  );

  const searchReconRunnerConfigQuery = useGetRunnerConfigs(
    externalId,
    CustomerRunnerConfigEnum.RECON_RUNNER
  );

  const editReconRunnerConfigFormMethods = useForm<
    z.infer<typeof ReconRunnerConfigFormSchema>
  >({
    mode: "onChange",
    defaultValues: {
      batchRunnerType: CustomerRunnerConfigEnum.RECON_RUNNER,
      firstRowHeader: false,
      isHeaderRequired: false,
      partialPayReconRequired: false,
      isConsolidatedReconRequired: false,
      ignoreCredits: false,
      isEmptyReconRequired: false,
      isResponseFooterRequired: false,
      dailyReconBankRequired: false,
      monthlyReconBankRequired: false,
      weeklyReconBankRequired: false,
      removeDailyBankReconTimeStamp: false,
      removeMonthlyBankReconTimeStamp: false,
      removeWeeklyBankReconTimeStamp: false,
      isDailyReconPostDate: false,
      isResponseFooterRequiredRecon: false,
      isSeparatePaymentGroupingRequiredForDaily: false,
      isSeparatePaymentGroupingRequiredForWeekly: false,
      isSeparatePaymentGroupingRequiredForMonthly: false,
      validMinimumLength: 5,
    },
    resolver: zodResolver(ReconRunnerConfigFormSchema),
  });

  const [handleSave, setHandleSave] = useState("add");

  useEffect(() => {
    if (searchReconRunnerConfigQuery.data?.content[0].id) {
      editReconRunnerConfigFormMethods.setValue(
        "id",
        searchReconRunnerConfigQuery.data.content[0].id
      );
      setHandleSave("update");
    }

    if (searchReconRunnerConfigQuery.data?.content[0].paymentProvider) {
      editReconRunnerConfigFormMethods.setValue(
        "paymentProvider",
        searchReconRunnerConfigQuery.data.content[0].paymentProvider.toString()
      );
    }

    if (searchReconRunnerConfigQuery.data?.content[0].dateFormat) {
      editReconRunnerConfigFormMethods.setValue(
        "dateFormat",
        searchReconRunnerConfigQuery.data.content[0].dateFormat.toString()
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].delimiterType) {
      editReconRunnerConfigFormMethods.setValue(
        "delimiterType",
        searchReconRunnerConfigQuery.data.content[0].delimiterType.toString()
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].documentFormat) {
      console.log(
        searchReconRunnerConfigQuery.data.content[0].documentFormat.toString(),
        "DF"
      );
      editReconRunnerConfigFormMethods.setValue(
        "documentFormat",
        searchReconRunnerConfigQuery.data.content[0].documentFormat.toString()
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].fileNameContainer) {
      editReconRunnerConfigFormMethods.setValue(
        "fileNameContainer",
        searchReconRunnerConfigQuery.data.content[0].fileNameContainer
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].firstRowHeader) {
      editReconRunnerConfigFormMethods.setValue(
        "firstRowHeader",
        searchReconRunnerConfigQuery.data.content[0].firstRowHeader
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].validMinimumLength) {
      editReconRunnerConfigFormMethods.setValue(
        "validMinimumLength",
        searchReconRunnerConfigQuery.data.content[0].validMinimumLength
      );
    }
    if (
      searchReconRunnerConfigQuery.data?.content[0].documentFormatDenormalize
    ) {
      editReconRunnerConfigFormMethods.setValue(
        "documentFormatDenormalize",
        searchReconRunnerConfigQuery.data.content[0].documentFormatDenormalize.toString()
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].isHeaderRequired) {
      editReconRunnerConfigFormMethods.setValue(
        "isHeaderRequired",
        searchReconRunnerConfigQuery.data.content[0].isHeaderRequired
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].partialPayReconRequired) {
      editReconRunnerConfigFormMethods.setValue(
        "partialPayReconRequired",
        searchReconRunnerConfigQuery.data.content[0].partialPayReconRequired
      );
    }
    if (
      searchReconRunnerConfigQuery.data?.content[0].isConsolidatedReconRequired
    ) {
      editReconRunnerConfigFormMethods.setValue(
        "isConsolidatedReconRequired",
        searchReconRunnerConfigQuery.data.content[0].isConsolidatedReconRequired
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].ignoreCredits) {
      editReconRunnerConfigFormMethods.setValue(
        "ignoreCredits",
        searchReconRunnerConfigQuery.data.content[0].ignoreCredits
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].isEmptyReconRequired) {
      editReconRunnerConfigFormMethods.setValue(
        "isEmptyReconRequired",
        searchReconRunnerConfigQuery.data.content[0].isEmptyReconRequired
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].fileNameDateFormat) {
      editReconRunnerConfigFormMethods.setValue(
        "fileNameDateFormat",
        searchReconRunnerConfigQuery.data.content[0].fileNameDateFormat.toString()
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].lineSeparator) {
      editReconRunnerConfigFormMethods.setValue(
        "lineSeparator",
        searchReconRunnerConfigQuery.data.content[0].lineSeparator.toString()
      );
    }
    if (
      searchReconRunnerConfigQuery.data?.content[0].isResponseFooterRequired
    ) {
      editReconRunnerConfigFormMethods.setValue(
        "isResponseFooterRequired",
        searchReconRunnerConfigQuery.data.content[0].isResponseFooterRequired
      );
    }
    if (
      searchReconRunnerConfigQuery.data?.content[0]
        .isResponseFooterRequiredRecon
    ) {
      editReconRunnerConfigFormMethods.setValue(
        "isResponseFooterRequiredRecon",
        searchReconRunnerConfigQuery.data.content[0]
          .isResponseFooterRequiredRecon
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].isCustomerReconViaEmail) {
      editReconRunnerConfigFormMethods.setValue(
        "isCustomerReconViaEmail",
        searchReconRunnerConfigQuery.data.content[0].isCustomerReconViaEmail
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].fileNamePart) {
      editReconRunnerConfigFormMethods.setValue(
        "fileNamePart",
        searchReconRunnerConfigQuery.data.content[0].fileNamePart
      );
    }

    if (searchReconRunnerConfigQuery.data?.content[0].extension) {
      editReconRunnerConfigFormMethods.setValue(
        "extension",
        searchReconRunnerConfigQuery.data.content[0].extension
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].fileNameDateFormat) {
      editReconRunnerConfigFormMethods.setValue(
        "fileNameDateFormat",
        searchReconRunnerConfigQuery.data.content[0].fileNameDateFormat.toString()
      );
    }
    if (
      searchReconRunnerConfigQuery.data?.content[0].responseHeaderDateFormat
    ) {
      editReconRunnerConfigFormMethods.setValue(
        "responseHeaderDateFormat",
        searchReconRunnerConfigQuery.data.content[0].responseHeaderDateFormat.toString()
      );
    }
    if (
      searchReconRunnerConfigQuery.data?.content[0]
        .responseHeaderDateFormatRecon
    ) {
      editReconRunnerConfigFormMethods.setValue(
        "responseHeaderDateFormatRecon",
        searchReconRunnerConfigQuery.data.content[0]
          .responseHeaderDateFormatRecon
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].dailyReconBankRequired) {
      editReconRunnerConfigFormMethods.setValue(
        "dailyReconBankRequired",
        searchReconRunnerConfigQuery.data.content[0].dailyReconBankRequired
      );
    }

    if (searchReconRunnerConfigQuery.data?.content[0].isDailyReconPostDate) {
      editReconRunnerConfigFormMethods.setValue(
        "isDailyReconPostDate",
        searchReconRunnerConfigQuery.data.content[0].isDailyReconPostDate
      );
    }

    if (
      searchReconRunnerConfigQuery.data?.content[0]
        .isSeparatePaymentGroupingRequiredForDaily
    ) {
      editReconRunnerConfigFormMethods.setValue(
        "isSeparatePaymentGroupingRequiredForDaily",
        searchReconRunnerConfigQuery.data.content[0]
          .isSeparatePaymentGroupingRequiredForDaily
      );
    }

    if (
      searchReconRunnerConfigQuery.data?.content[0]
        .isSeparatePaymentGroupingRequiredForWeekly
    ) {
      editReconRunnerConfigFormMethods.setValue(
        "isSeparatePaymentGroupingRequiredForWeekly",
        searchReconRunnerConfigQuery.data.content[0]
          .isSeparatePaymentGroupingRequiredForWeekly
      );
    }

    if (
      searchReconRunnerConfigQuery.data?.content[0]
        .isSeparatePaymentGroupingRequiredForMonthly
    ) {
      editReconRunnerConfigFormMethods.setValue(
        "isSeparatePaymentGroupingRequiredForMonthly",
        searchReconRunnerConfigQuery.data.content[0]
          .isSeparatePaymentGroupingRequiredForMonthly
      );
    }

    if (
      searchReconRunnerConfigQuery.data?.content[0].monthlyReconBankRequired
    ) {
      editReconRunnerConfigFormMethods.setValue(
        "monthlyReconBankRequired",
        searchReconRunnerConfigQuery.data.content[0].monthlyReconBankRequired
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].weeklyReconBankRequired) {
      editReconRunnerConfigFormMethods.setValue(
        "weeklyReconBankRequired",
        searchReconRunnerConfigQuery.data.content[0].weeklyReconBankRequired
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].extension) {
      editReconRunnerConfigFormMethods.setValue(
        "extension",
        searchReconRunnerConfigQuery.data.content[0].extension
      );
    }
    if (
      searchReconRunnerConfigQuery.data?.content[0]
        .removeDailyBankReconTimeStamp
    ) {
      editReconRunnerConfigFormMethods.setValue(
        "removeDailyBankReconTimeStamp",
        searchReconRunnerConfigQuery.data.content[0]
          .removeDailyBankReconTimeStamp
      );
    }
    if (
      searchReconRunnerConfigQuery.data?.content[0]
        .removeWeeklyBankReconTimeStamp
    ) {
      editReconRunnerConfigFormMethods.setValue(
        "removeWeeklyBankReconTimeStamp",
        searchReconRunnerConfigQuery.data.content[0]
          .removeWeeklyBankReconTimeStamp
      );
    }
    if (
      searchReconRunnerConfigQuery.data?.content[0]
        .removeMonthlyBankReconTimeStamp
    ) {
      editReconRunnerConfigFormMethods.setValue(
        "removeMonthlyBankReconTimeStamp",
        searchReconRunnerConfigQuery.data.content[0]
          .removeMonthlyBankReconTimeStamp
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].dailySelectedTime) {
      editReconRunnerConfigFormMethods.setValue(
        "dailySelectedTime",
        searchReconRunnerConfigQuery.data.content[0].dailySelectedTime
      );
    }

    if (searchReconRunnerConfigQuery.data?.content[0].weeklySelectedTime) {
      editReconRunnerConfigFormMethods.setValue(
        "weeklySelectedTime",
        searchReconRunnerConfigQuery.data.content[0].weeklySelectedTime
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].monthlySelectedTime) {
      editReconRunnerConfigFormMethods.setValue(
        "monthlySelectedTime",
        searchReconRunnerConfigQuery.data.content[0].monthlySelectedTime
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].weeklySelectedValue) {
      editReconRunnerConfigFormMethods.setValue(
        "weeklySelectedValue",
        searchReconRunnerConfigQuery.data.content[0].weeklySelectedValue
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].monthlySelectedValue) {
      editReconRunnerConfigFormMethods.setValue(
        "monthlySelectedValue",
        searchReconRunnerConfigQuery.data.content[0].monthlySelectedValue
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].fileNamePartDaily) {
      editReconRunnerConfigFormMethods.setValue(
        "fileNamePartDaily",
        searchReconRunnerConfigQuery.data.content[0].fileNamePartDaily
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].fileNamePartWeekly) {
      editReconRunnerConfigFormMethods.setValue(
        "fileNamePartWeekly",
        searchReconRunnerConfigQuery.data.content[0].fileNamePartWeekly
      );
    }
    if (searchReconRunnerConfigQuery.data?.content[0].fileNamePartMonthly) {
      editReconRunnerConfigFormMethods.setValue(
        "fileNamePartMonthly",
        searchReconRunnerConfigQuery.data.content[0].fileNamePartMonthly
      );
    }
  }, [searchReconRunnerConfigQuery.data, editReconRunnerConfigFormMethods]);

  const addReconRunnerConfigMutation = useReconAddRunnerConfig(externalId);
  function handleAdd(data: z.infer<typeof ReconRunnerConfigFormSchema>) {
    addReconRunnerConfigMutation.mutate(data);
  }

  const reconRunnerConfigId = editReconRunnerConfigFormMethods.getValues("id");
  const updateReconRunnerConfigMutation = useReconUpdateRunnerConfig(
    externalId,
    reconRunnerConfigId || 0
  );
  function handleUpdate(data: z.infer<typeof ReconRunnerConfigFormSchema>) {
    updateReconRunnerConfigMutation.mutate(data);
  }

  const formatId = editReconRunnerConfigFormMethods.watch(
    "documentFormatDenormalize"
  );
  const allDenormalizeDocumentFormats = useDocumentFormats("4");
  const [isBAI2, setIsBAI2] = useState(false);
  const [scheduler, setScheduler] = useState(false);

  useEffect(() => {
    const selectedFormatId: number = Number(formatId);
    const selectedFormat = allDenormalizeDocumentFormats.find(
      (doc) => doc.value === selectedFormatId.toString()
    );

    setIsBAI2(selectedFormat?.label.includes("BAI2") || false);
  }, [formatId, allDenormalizeDocumentFormats]);

  useEffect(() => {
    if (isBAI2) {
      editReconRunnerConfigFormMethods.setValue("isHeaderRequired", false);
      editReconRunnerConfigFormMethods.setValue("isEmptyReconRequired", false);
      editReconRunnerConfigFormMethods.setValue(
        "isResponseFooterRequiredRecon",
        false
      );
      editReconRunnerConfigFormMethods.setValue("lineSeparator", "5");
      editReconRunnerConfigFormMethods.setValue(
        "responseHeaderDateFormatRecon",
        "yyMMdd"
      );
      editReconRunnerConfigFormMethods.setValue(
        "responseHeaderDateFormat",
        CustomerRunnerConfigEnum.RECON_RUNNER
      );
      editReconRunnerConfigFormMethods.setValue(
        "dateFormat",
        CustomerRunnerConfigEnum.RECON_RUNNER
      );
    }
  }, [isBAI2, editReconRunnerConfigFormMethods]);

  const dailyrequired = editReconRunnerConfigFormMethods.watch(
    "dailyReconBankRequired"
  );
  const weeklyRequired = editReconRunnerConfigFormMethods.watch(
    "weeklyReconBankRequired"
  );
  const monthlyRequired = editReconRunnerConfigFormMethods.watch(
    "monthlyReconBankRequired"
  );

  useEffect(() => {
    if (!dailyrequired && !weeklyRequired && !monthlyRequired) {
      // DenormalizeDocumentFormatField is now mandatory -INP-4171
      //editReconRunnerConfigFormMethods.setValue("documentFormatDenormalize", "");
      setScheduler(true);
    } else {
      setScheduler(false);
    }
  }, [
    dailyrequired,
    weeklyRequired,
    monthlyRequired,
    editReconRunnerConfigFormMethods,
  ]);

  const {
    formState: {errors},
  } = editReconRunnerConfigFormMethods;

  console.log("errors", errors);
  //console.log("formFields: ", editReconRunnerConfigFormMethods.watch());

  return (
    <Form {...editReconRunnerConfigFormMethods}>
      <form
        onSubmit={editReconRunnerConfigFormMethods.handleSubmit(
          handleSave === "add" ? handleAdd : handleUpdate
        )}
      >
        <div className="my-5 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <PaymentProviderField
            isMutating={
              handleSave === "add"
                ? addReconRunnerConfigMutation.isPending
                : updateReconRunnerConfigMutation.isPending
            }
          />
          <DocumentFormatField
            isMutating={
              handleSave === "add"
                ? addReconRunnerConfigMutation.isPending
                : updateReconRunnerConfigMutation.isPending
            }
          />

          <DateFormatField
            isMutating={
              handleSave === "add"
                ? addReconRunnerConfigMutation.isPending
                : updateReconRunnerConfigMutation.isPending
            }
          />

          <DelimiterTypeField
            isMutating={
              handleSave === "add"
                ? addReconRunnerConfigMutation.isPending
                : updateReconRunnerConfigMutation.isPending
            }
          />

          <FileNameContainerField
            isMutating={
              handleSave === "add"
                ? addReconRunnerConfigMutation.isPending
                : updateReconRunnerConfigMutation.isPending
            }
          />

          <ValidMinLengthField
            isMutating={
              handleSave === "add"
                ? addReconRunnerConfigMutation.isPending
                : updateReconRunnerConfigMutation.isPending
            }
          />

          <DenormalizeDocumentFormatField
            scheduler={scheduler}
            isMutating={
              handleSave === "add"
                ? addReconRunnerConfigMutation.isPending
                : updateReconRunnerConfigMutation.isPending
            }
          />
          <FileNameDateFormatField
            isMutating={
              handleSave === "add"
                ? addReconRunnerConfigMutation.isPending
                : updateReconRunnerConfigMutation.isPending
            }
          />
          <ExtensionField
            isMutating={
              handleSave === "add"
                ? addReconRunnerConfigMutation.isPending
                : updateReconRunnerConfigMutation.isPending
            }
          />
          <LineSeparatorField
            isBAI2={isBAI2}
            isMutating={
              handleSave === "add"
                ? addReconRunnerConfigMutation.isPending
                : updateReconRunnerConfigMutation.isPending
            }
          />
          <ResponseHeaderDateFormatReconField
            isBAI2={isBAI2}
            isMutating={
              handleSave === "add"
                ? addReconRunnerConfigMutation.isPending
                : updateReconRunnerConfigMutation.isPending
            }
          />

          <IsCustomerReconViaEmailField
            isMutating={
              handleSave === "add"
                ? addReconRunnerConfigMutation.isPending
                : updateReconRunnerConfigMutation.isPending
            }
          />
        </div>
        <Card>
          <CardHeader>
            <Heading3>Runner Details</Heading3>
          </CardHeader>
          <CardContent className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
            <FirstRowHeaderField
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />
            <IsHeaderRequiredField
              isBAI2={isBAI2}
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />
            <IsEmptyReconRequiredField
              isBAI2={isBAI2}
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />
            <IsFooterRequiredFieldForBankFileField
              isBAI2={isBAI2}
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />

            <DailyReconBankRequiredField
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />
            <WeeklyReconBankRequired
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />
            <MonthlyReconBankRequired
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />
            <IsConsolidatedReconRequiredField
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />

            <PartialPayReconRequiredField
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />
            {editReconRunnerConfigFormMethods.watch(
              "isConsolidatedReconRequired"
            ) === false && (
              <IgnoreCreditsField
                isMutating={
                  handleSave === "add"
                    ? addReconRunnerConfigMutation.isPending
                    : updateReconRunnerConfigMutation.isPending
                }
              />
            )}
          </CardContent>
        </Card>
        {editReconRunnerConfigFormMethods.watch("dailyReconBankRequired") ===
          true && (
          <div className="my-5 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6">
            <ReconDailyValue
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />
            <DailySelectedTime />
            <DailyfileNamePart
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />
            <DailyReconTimeStamp
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />
            <ReconPostDate
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />
            <DailyReconPaymentGroup
              isMutating={
                (handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending) ||
                allParticipantRegistrations.length <= 1
              }
            />
          </div>
        )}
        {editReconRunnerConfigFormMethods.watch("weeklyReconBankRequired") ===
          true && (
          <div className="my-5 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6">
            <ReconWeeklyValue
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />
            <WeeklySelectedTime />
            <WeeklyfileNamePart
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />
            <WeeklyReconTimeStamp
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />
            <WeeklyReconPaymentGroup
              isMutating={
                (handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending) ||
                allParticipantRegistrations.length <= 1
              }
            />
          </div>
        )}
        {editReconRunnerConfigFormMethods.watch("monthlyReconBankRequired") ===
          true && (
          <div className="my-5 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6">
            <ReconMonthlyValue
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />
            <MonthlySelectedTime />
            <MonthlyfileNamePart
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />
            <MonthlyReconTimeStamp
              isMutating={
                handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending
              }
            />

            <MonthlyReconPaymentGroup
              isMutating={
                (handleSave === "add"
                  ? addReconRunnerConfigMutation.isPending
                  : updateReconRunnerConfigMutation.isPending) ||
                allParticipantRegistrations.length <= 1
              }
            />
          </div>
        )}
        <div className="flex items-center justify-end space-x-2 py-4">
          <Button
            type="submit"
            className="gap-2"
            variant="affirmative"
            disabled={
              handleSave === "add"
                ? addReconRunnerConfigMutation.isPending
                : updateReconRunnerConfigMutation.isPending
            }
            aria-disabled={
              handleSave === "add"
                ? addReconRunnerConfigMutation.isPending
                : updateReconRunnerConfigMutation.isPending
            }
          >
            {handleSave === "add" && addReconRunnerConfigMutation.isPending ? (
              <Spinner size="xs" />
            ) : (
              ""
            )}
            {handleSave === "update" &&
            updateReconRunnerConfigMutation.isPending ? (
              <Spinner size="xs" />
            ) : (
              ""
            )}
            <span className="sr-only">
              {handleSave === "add" ? "Save" : "Update"} Configuration
            </span>
            {handleSave === "add" ? "Save" : "Update"} Recon Configuration
            {handleSave === "add" ? (
              <SaveIcon className="size-4" />
            ) : (
              <FilePenIcon className="size-4" />
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
}

/**
 * Custom hook for performing the 'Add Recon Runner Config' mutation.
 * This hook abstracts the mutation logic and side effects like toast notifications.
 *
 * @param {number | undefined} externalId - The ID of the customer.
 * @returns A mutation object with methods to trigger the mutation.
 */
function useReconAddRunnerConfig(externalId: string) {
  return useMutation({
    mutationFn: (data: z.infer<typeof ReconRunnerConfigFormSchema>) => {
      // Perform the mutation using the addRunnerConfigFn function
      return addCustomerRunnerConfigFn(data, externalId);
    },
    onSuccess: (response) => {
      // Handle successful mutation
      console.log("addReconRunnerConfigResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Runner config added successfully",
      });
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add runner config",
      });
    },
  });
}

/**
 * Custom hook for performing the 'Update Recon Runner Config' mutation.
 * This hook abstracts the mutation logic and side effects like toast notifications.
 *
 * @param {number | undefined} externalId - The ID of the customer.
 * @returns A mutation object with methods to trigger the mutation.
 */
export function useReconUpdateRunnerConfig(
  externalId: string,
  runnerConfigId: number
) {
  return useMutation({
    mutationFn: (data: z.infer<typeof ReconRunnerConfigFormSchema>) => {
      // Perform the mutation using the updateRunnerConfigFn function
      return updateCustomerRunnerConfigFn(data, externalId, runnerConfigId);
    },
    onSuccess: (response) => {
      // Handle successful mutation
      console.log("updatReconRunnerConfigResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Runner config updated successfully",
      });
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update runner config",
      });
    },
  });
}

function PaymentProviderField({isMutating}: {isMutating: boolean}) {
  const allPaymentProviders = usePaymentProviders();
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="paymentProvider"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="paymentProvider" showMandatoryAsterisk>
            Payment Provider:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allPaymentProviders.find(
                        (paymentProvider) =>
                          paymentProvider.value === field.value
                      )?.label
                    : "Select Payment Provider..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Payment Providers..." />
                <CommandEmpty>No Payment Providers found.</CommandEmpty>
                <CommandGroup>
                  {allPaymentProviders.map((paymentProvider) => (
                    <CommandItem
                      key={paymentProvider.value}
                      value={paymentProvider.label}
                      onSelect={() => {
                        setValue("paymentProvider", paymentProvider.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          paymentProvider.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {paymentProvider.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function DocumentFormatField({isMutating}: {isMutating: boolean}) {
  const allDocumentFormats = useDocumentFormats("3");
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="documentFormat"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="documentFormat" showMandatoryAsterisk>
            Document Format:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "min-w-64 justify-between gap-2 whitespace-nowrap",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDocumentFormats.find(
                        (documentFormat) => documentFormat.value === field.value
                      )?.label
                    : "Select Document Format..."}
                  <ChevronsUpDownIcon className="size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Document Format..." />
                <CommandEmpty>No Document Format found.</CommandEmpty>
                <CommandGroup>
                  {allDocumentFormats.map((documentFormat) => (
                    <CommandItem
                      key={documentFormat.value}
                      value={documentFormat.label}
                      onSelect={() => {
                        setValue("documentFormat", documentFormat.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          documentFormat.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {documentFormat.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function DateFormatField({isMutating}: {isMutating: boolean}) {
  const allDateFormats = useDateFormats();
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="dateFormat"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="dateFormat" showMandatoryAsterisk>
            Date Format:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDateFormats.find(
                        (dateFormat) => dateFormat.value === field.value
                      )?.label
                    : "Select Date Format..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Date Format..." />
                <CommandEmpty>No Date Formats found.</CommandEmpty>
                <CommandGroup>
                  {allDateFormats.map((dateFormat) => (
                    <CommandItem
                      key={dateFormat.value}
                      value={dateFormat.label}
                      onSelect={() => {
                        setValue("dateFormat", dateFormat.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          dateFormat.value.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {dateFormat.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function DelimiterTypeField({isMutating}: {isMutating: boolean}) {
  const allDelimiterTypes = useDelimiterTypes();
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="delimiterType"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="delimiterType" showMandatoryAsterisk>
            Delimiter Type:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDelimiterTypes.find(
                        (delimiterType) => delimiterType.value === field.value
                      )?.label
                    : "Select Delimiter Type..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Delimiter Type..." />
                <CommandEmpty>No Delimiter Type found.</CommandEmpty>
                <CommandGroup>
                  {allDelimiterTypes.map((delimiterType) => (
                    <CommandItem
                      key={delimiterType.value}
                      value={delimiterType.label}
                      onSelect={() => {
                        setValue("delimiterType", delimiterType.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          delimiterType.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {delimiterType.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function FileNameContainerField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="fileNameContainer"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="fileNameContainer" showMandatoryAsterisk>
            File Name Container:
          </FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="text"
              placeholder="Enter the file name container..."
              {...register("fileNameContainer")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ValidMinLengthField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="validMinimumLength"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="validMinimumLength" showMandatoryAsterisk>
            Valid Mini Length:
          </FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="number"
              min={5}
              placeholder="Enter valid mini length..."
              {...register("validMinimumLength", {
                setValueAs: (v) =>
                  v === "" ? undefined : parseInt(v as string, 10),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function DenormalizeDocumentFormatField({
  isMutating,
  scheduler,
}: {
  isMutating: boolean;
  scheduler: boolean;
}) {
  const allDenormalizeDocumentFormats = useDocumentFormats("4");
  const {control, setValue} = useFormContext();

  return (
    <FormField
      control={control}
      name="documentFormatDenormalize" // Make sure this matches the name used with setValue
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="documentFormatDenormalize" showMandatoryAsterisk>
            Denormalize Document Format:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating || scheduler}
                  aria-disabled={isMutating || scheduler}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "min-w-64 justify-between gap-2 whitespace-nowrap",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDenormalizeDocumentFormats.find(
                        (documentFormat) => documentFormat.value === field.value
                      )?.label
                    : "Select Document Format..."}
                  <ChevronsUpDownIcon className="size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Document Format..." />
                <CommandEmpty>No Document Format found.</CommandEmpty>
                <CommandGroup>
                  {allDenormalizeDocumentFormats.map((documentFormat) => (
                    <CommandItem
                      key={documentFormat.value}
                      value={documentFormat.label}
                      onSelect={() => {
                        setValue(
                          "documentFormatDenormalize",
                          documentFormat.value
                        ); // Set the value using setValue
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          documentFormat.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {documentFormat.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function FirstRowHeaderField({isMutating}: {isMutating: boolean}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="firstRowHeader"
      render={({field}) => (
        <FormItem className="flex w-64 items-end justify-between gap-4">
          <FormLabel
            className="mr-2"
            htmlFor="firstRowHeader"
            showMandatoryAsterisk
          >
            First Row Header:
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating}
              aria-disabled={isMutating}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("firstRowHeader")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function FileNameDateFormatField({isMutating}: {isMutating: boolean}) {
  const allDateFormats = useDateFormats();
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="fileNameDateFormat"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="fileNameDateFormat">
            File Name Date Format:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDateFormats.find(
                        (dateFormat) => dateFormat.value === field.value
                      )?.label
                    : "Select Date Format..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Date Format..." />
                <CommandEmpty>No Date Formats found.</CommandEmpty>
                <CommandGroup>
                  {allDateFormats.map((dateFormat) => (
                    <CommandItem
                      key={dateFormat.value}
                      value={dateFormat.label}
                      onSelect={() => {
                        setValue("fileNameDateFormat", dateFormat.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          dateFormat.value.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {dateFormat.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ExtensionField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="extension"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="extension">Extension:</FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="text"
              placeholder="Enter the extension..."
              {...register("extension")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function LineSeparatorField({
  isMutating,
  isBAI2,
}: {
  isMutating: boolean;
  isBAI2: boolean;
}) {
  const allDelimiterTypes = useDelimiterTypes();
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="lineSeparator"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="lineSeparator">Line Separator:</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating || isBAI2}
                  aria-disabled={isMutating || isBAI2}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDelimiterTypes.find(
                        (lineSeparator) => lineSeparator.value === field.value
                      )?.label
                    : "Select Line Separator..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Line Separator..." />
                <CommandEmpty>No Line Separator found.</CommandEmpty>
                <CommandGroup>
                  {allDelimiterTypes.map((lineSeparator) => (
                    <CommandItem
                      key={lineSeparator.value}
                      value={lineSeparator.label}
                      onSelect={() => {
                        setValue("lineSeparator", lineSeparator.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          lineSeparator.value?.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {lineSeparator.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ResponseHeaderDateFormatReconField({
  isMutating,
  isBAI2,
}: {
  isMutating: boolean;
  isBAI2: boolean;
}) {
  const allDateFormats = useDateFormats();
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="responseHeaderDateFormat"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="responseHeaderDateFormat">
            Bank File Header Date Format:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating || isBAI2}
                  aria-disabled={isMutating || isBAI2}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDateFormats.find(
                        (dateFormat) => dateFormat.value === field.value
                      )?.label
                    : "Select Date Format..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Date Format..." />
                <CommandEmpty>No Date Formats found.</CommandEmpty>
                <CommandGroup>
                  {allDateFormats.map((dateFormat) => (
                    <CommandItem
                      key={dateFormat.value}
                      value={dateFormat.label}
                      onSelect={() => {
                        setValue("responseHeaderDateFormat", dateFormat.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          dateFormat.value.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {dateFormat.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function IsHeaderRequiredField({
  isMutating,
  isBAI2,
}: {
  isMutating: boolean;
  isBAI2: boolean;
}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="isHeaderRequired"
      render={({field}) => (
        <FormItem className="flex w-64 items-end justify-between gap-4">
          <FormLabel className="mr-2" htmlFor="isHeaderRequired">
            Header Required:
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating || isBAI2}
              aria-disabled={isMutating || isBAI2}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("isHeaderRequired")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function IsEmptyReconRequiredField({
  isMutating,
  isBAI2,
}: {
  isMutating: boolean;
  isBAI2: boolean;
}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="isEmptyReconRequired"
      render={({field}) => (
        <FormItem className="flex w-64 items-end justify-between gap-4">
          <FormLabel className="mr-2" htmlFor="isEmptyReconRequired">
            Empty Recon Required:
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating || isBAI2}
              aria-disabled={isMutating || isBAI2}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("isEmptyReconRequired")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function IsFooterRequiredFieldForBankFileField({
  isMutating,
  isBAI2,
}: {
  isMutating: boolean;
  isBAI2: boolean;
}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="isResponseFooterRequiredRecon"
      render={({field}) => (
        <FormItem className="flex w-64 items-end justify-between gap-4">
          <FormLabel className="mr-2" htmlFor="isResponseFooterRequiredRecon">
            Is Footer Required for Bank File:
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating || isBAI2}
              aria-disabled={isMutating || isBAI2}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("isResponseFooterRequiredRecon")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function IsCustomerReconViaEmailField({isMutating}: {isMutating: boolean}) {
  const {control} = useFormContext();

  const emailOptions = [
    {
      label: "Email",
      value: "Email",
    },
    {
      label: "SFTP",
      value: "SFTP",
    },
    {
      label: "Email and SFTP",
      value: "EmailAndSFTP",
    },
  ];

  return (
    <FormField
      control={control}
      name="isCustomerReconViaEmail"
      render={({field}) => (
        <FormItem>
          <FormLabel className="mr-2" htmlFor="isCustomerReconViaEmail">
            Customer recon file transfer:
          </FormLabel>

          <Select //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value={field.value}
            disabled={isMutating}
            aria-disabled={isMutating}
            onValueChange={field.onChange}
          >
            <FormControl>
              <SelectTrigger className="bg-white">
                <SelectValue placeholder="Select" />
              </SelectTrigger>
            </FormControl>
            <SelectContent className="bg-white">
              {emailOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function DailyReconBankRequiredField({isMutating}: {isMutating: boolean}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="dailyReconBankRequired"
      render={({field}) => (
        <FormItem className="flex w-64 items-end justify-between gap-4">
          <FormLabel className="mr-2" htmlFor="dailyReconBankRequired">
            Frequency (Daily):
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating}
              aria-disabled={isMutating}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("dailyReconBankRequired")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function WeeklyReconBankRequired({isMutating}: {isMutating: boolean}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="weeklyReconBankRequired"
      render={({field}) => (
        <FormItem className="flex w-64 items-end justify-between gap-4">
          <FormLabel className="mr-2" htmlFor="weeklyReconBankRequired">
            Frequency (Weekly):
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating}
              aria-disabled={isMutating}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("weeklyReconBankRequired")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function MonthlyReconBankRequired({isMutating}: {isMutating: boolean}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="monthlyReconBankRequired"
      render={({field}) => (
        <FormItem className="flex w-64 items-end justify-between gap-4">
          <FormLabel className="mr-2" htmlFor="monthlyReconBankRequired">
            Frequency (Monthly):
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating}
              aria-disabled={isMutating}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("monthlyReconBankRequired")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
function IsConsolidatedReconRequiredField({isMutating}: {isMutating: boolean}) {
  const {register, control, watch, setValue} = useFormContext();

  const isConsolidatedReconRequired = watch(
    "isConsolidatedReconRequired"
  ) as boolean;

  useEffect(() => {
    if (isConsolidatedReconRequired) {
      setValue("ignoreCredits", true);
    }
  }, [isConsolidatedReconRequired, setValue]);

  return (
    <FormField
      control={control}
      name="isConsolidatedReconRequired"
      render={({field}) => (
        <FormItem className="flex w-64 items-end justify-between gap-4">
          <FormLabel className="mr-2" htmlFor="isConsolidatedReconRequired">
            Consolidated Recon Required:
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating}
              aria-disabled={isMutating}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("isConsolidatedReconRequired")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function IgnoreCreditsField({isMutating}: {isMutating: boolean}) {
  const {register, control} = useFormContext();

  return (
    <FormField
      control={control}
      name="ignoreCredits"
      render={({field}) => (
        <FormItem className="flex w-64 items-end justify-between gap-4">
          <FormLabel className="mr-2" htmlFor="ignoreCredits">
            Ignore Credits:
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating}
              aria-disabled={isMutating}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("ignoreCredits")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function PartialPayReconRequiredField({isMutating}: {isMutating: boolean}) {
  const {register, control, watch, setValue} = useFormContext();

  const partialPayReconRequired = watch("partialPayReconRequired") as boolean;

  useEffect(() => {
    if (partialPayReconRequired) {
      setValue("isConsolidatedReconRequired", false);
    }
  }, [partialPayReconRequired, setValue]);

  return (
    <FormField
      control={control}
      name="partialPayReconRequired"
      render={({field}) => (
        <FormItem className="flex w-64 items-end justify-between gap-4">
          <FormLabel className="mr-2" htmlFor="partialPayReconRequired">
            Partial Pay Recon Required:
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating}
              aria-disabled={isMutating}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("partialPayReconRequired")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ReconDailyValue({isMutating}: {isMutating: boolean}) {
  return (
    <FormField
      name="dailySelectedValue"
      render={({field}) => (
        <FormItem className="flex flex-col">
          <FormLabel showMandatoryAsterisk>Daily Selected Value:</FormLabel>
          <FormControl>
            <Popover>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    disabled={true}
                    aria-disabled={isMutating}
                    variant="outline"
                    role="combobox"
                    className={cn(
                      "justify-between",
                      !field.value && "text-accent-foreground",
                      "w-44"
                    )}
                  >
                    Select Day...
                    <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                <Command>
                  <CommandInput placeholder="Select Day..." />

                  <CommandGroup />
                </Command>
              </PopoverContent>
            </Popover>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ReconWeeklyValue({isMutating}: {isMutating: boolean}) {
  const {control, setValue} = useFormContext();

  return (
    <FormField
      control={control}
      name="weeklySelectedValue"
      render={({field}) => (
        <FormItem className="flex flex-col">
          <FormLabel htmlFor="weeklySelectedValue" showMandatoryAsterisk>
            Weekly Selected Value:
          </FormLabel>
          <FormControl>
            <Popover>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    disabled={isMutating}
                    aria-disabled={isMutating}
                    variant="outline"
                    role="combobox"
                    className={cn(
                      "justify-between",
                      !field.value && "text-accent-foreground",
                      "w-44"
                    )}
                  >
                    {field.value
                      ? weeklyDays.find(
                          (day) =>
                            day.value ===
                            (field.value && typeof field.value === "string"
                              ? field.value.toUpperCase()
                              : "")
                        )?.label
                      : "Select Day..."}
                    <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                <Command>
                  <CommandInput placeholder="Select Day..." />

                  <CommandGroup>
                    {weeklyDays.map((day) => (
                      <CommandItem
                        key={day.value}
                        value={day.label}
                        onSelect={() => {
                          setValue("weeklySelectedValue", day.value);
                        }}
                      >
                        <CheckIcon
                          className={cn(
                            "mr-2 size-4",
                            day.value ===
                              (field.value && typeof field.value === "string"
                                ? field.value.toUpperCase()
                                : "")
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {day.label}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </Command>
              </PopoverContent>
            </Popover>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ReconMonthlyValue({isMutating}: {isMutating: boolean}) {
  const {control, setValue} = useFormContext();

  return (
    <FormField
      control={control}
      name="monthlySelectedValue"
      render={({field}) => (
        <FormItem className="flex flex-col">
          <FormLabel htmlFor="monthlySelectedValue" showMandatoryAsterisk>
            Monthly Selected Value:
          </FormLabel>
          <FormControl>
            <Popover>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    disabled={isMutating}
                    aria-disabled={isMutating}
                    variant="outline"
                    role="combobox"
                    className={cn(
                      "justify-between",
                      !field.value && "text-accent-foreground",
                      "w-44"
                    )}
                  >
                    {field.value
                      ? monthlyDays.find(
                          (day) =>
                            day.value ===
                            (field.value && typeof field.value === "string"
                              ? field.value.toUpperCase()
                              : "")
                        )?.label
                      : "Select Day..."}
                    <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
              <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                <Command>
                  <CommandInput placeholder="Select Day..." />

                  <CommandGroup>
                    {monthlyDays.map((day) => (
                      <CommandItem
                        key={day.value}
                        value={day.label}
                        onSelect={() => {
                          setValue("monthlySelectedValue", day.value);
                        }}
                      >
                        <CheckIcon
                          className={cn(
                            "mr-2 size-4",
                            day.value ===
                              (field.value && typeof field.value === "string"
                                ? field.value.toUpperCase()
                                : "")
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {day.label}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </Command>
              </PopoverContent>
            </Popover>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function DailySelectedTime() {
  const {setValue, watch} = useFormContext();
  const dailySelectedTime = watch("dailySelectedTime") as string;
  return (
    <FormField
      name="dailySelectedTime"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="dailySelectedTime" showMandatoryAsterisk>
            Daily Selected Time:
          </FormLabel>
          <FormControl>
            <TimePicker
              dateValue={dailySelectedTime}
              onChange={(value) => {
                const hours = value?.getHours();
                const minutes = value?.getMinutes();
                const formattedTime = `${
                  hours?.toString().padStart(2, "0") ?? "00"
                }:${minutes?.toString().padStart(2, "0") ?? "00"}`;
                setValue("dailySelectedTime", formattedTime);
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function WeeklySelectedTime() {
  const {setValue, watch} = useFormContext();
  const weeklySelectedTime = watch("weeklySelectedTime") as string;
  return (
    <FormField
      name="weeklySelectedTime"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="weeklySelectedTime" showMandatoryAsterisk>
            Weekly Selected Time:
          </FormLabel>
          <FormControl>
            <TimePicker
              dateValue={weeklySelectedTime}
              onChange={(value) => {
                const hours = value?.getHours();
                const minutes = value?.getMinutes();
                const formattedTime = `${
                  hours?.toString().padStart(2, "0") ?? "00"
                }:${minutes?.toString().padStart(2, "0") ?? "00"}`;
                setValue("weeklySelectedTime", formattedTime);
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function MonthlySelectedTime() {
  const {setValue, watch} = useFormContext();
  const monthlySelectedTime = watch("monthlySelectedTime") as string;
  return (
    <FormField
      name="monthlySelectedTime"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="monthlySelectedTime" showMandatoryAsterisk>
            Monthly Selected Time:
          </FormLabel>
          <FormControl>
            <TimePicker
              dateValue={monthlySelectedTime}
              onChange={(value) => {
                const hours = value?.getHours();
                const minutes = value?.getMinutes();
                const formattedTime = `${
                  hours?.toString().padStart(2, "0") ?? "00"
                }:${minutes?.toString().padStart(2, "0") ?? "00"}`;
                setValue("monthlySelectedTime", formattedTime);
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function DailyfileNamePart({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="fileNamePartDaily"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="fileNamePartDaily" showMandatoryAsterisk>
            File Name Part:
          </FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="File Name"
              {...register("fileNamePartDaily")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function WeeklyfileNamePart({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="fileNamePartWeekly"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="fileNamePartWeekly" showMandatoryAsterisk>
            File Name Part:
          </FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="File Name"
              {...register("fileNamePartWeekly")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function MonthlyfileNamePart({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="fileNamePartMonthly"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="fileNamePartMonthly" showMandatoryAsterisk>
            File Name Part:
          </FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="File Name"
              {...register("fileNamePartMonthly")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function DailyReconTimeStamp({isMutating}: {isMutating: boolean}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="removeDailyBankReconTimeStamp"
      render={({field}) => (
        <FormItem className="flex flex-col">
          <FormLabel
            className="mr-2"
            htmlFor="removeDailyBankReconTimeStamp"
            showMandatoryAsterisk
          >
            Remove Timestamp:
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating}
              aria-disabled={isMutating}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("removeDailyBankReconTimeStamp")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function WeeklyReconTimeStamp({isMutating}: {isMutating: boolean}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="removeWeeklyBankReconTimeStamp"
      render={({field}) => (
        <FormItem className="flex flex-col">
          <FormLabel
            className="mr-2"
            htmlFor="removeWeeklyBankReconTimeStamp"
            showMandatoryAsterisk
          >
            Remove Timestamp:
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating}
              aria-disabled={isMutating}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("removeWeeklyBankReconTimeStamp")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function MonthlyReconTimeStamp({isMutating}: {isMutating: boolean}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="removeMonthlyBankReconTimeStamp"
      render={({field}) => (
        <FormItem className="flex flex-col">
          <FormLabel
            className="mr-2"
            htmlFor="removeMonthlyBankReconTimeStamp"
            showMandatoryAsterisk
          >
            Remove Timestamp:
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating}
              aria-disabled={isMutating}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("removeMonthlyBankReconTimeStamp")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ReconPostDate({isMutating}: {isMutating: boolean}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="isDailyReconPostDate"
      render={({field}) => (
        <FormItem>
          <div className="flex flex-col space-x-2">
            <FormLabel
              className="mr-2"
              htmlFor="isDailyReconPostDate"
              showMandatoryAsterisk
            >
              Separate Daily Recon File Based On Posted Date:
            </FormLabel>
            <TooltipProvider delayDuration={200}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="cursor-pointer">
                    <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="flex justify-between space-x-4">
                    "Selecting 'No' will consolidate the All Daily Recon Files
                    into One, regardless of Post Date.
                    <br />
                    <br /> And if 'Yes', then daily recon file will get split so
                    that all records under the same Posted Date will be under 1
                    file."
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>

            <FormControl>
              <Checkbox
                disabled={isMutating}
                aria-disabled={isMutating}
                checked={field.value as boolean}
                onCheckedChange={field.onChange}
                {...register("isDailyReconPostDate")}
              />
            </FormControl>
            <FormMessage />
          </div>
        </FormItem>
      )}
    />
  );
}

function DailyReconPaymentGroup({isMutating}: {isMutating: boolean}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="isSeparatePaymentGroupingRequiredForDaily"
      render={({field}) => (
        <FormItem className="flex flex-col">
          <div className="flex space-x-2">
            <FormLabel
              className="mr-2"
              htmlFor="isSeparatePaymentGroupingRequiredForDaily"
              showMandatoryAsterisk
            >
              Separate Recon File Based on Payment Group:
            </FormLabel>

            <FormControl>
              <Checkbox
                disabled={isMutating}
                aria-disabled={isMutating}
                checked={field.value as boolean}
                onCheckedChange={field.onChange}
                {...register("isSeparatePaymentGroupingRequiredForDaily")}
              />
            </FormControl>
            <FormMessage />
          </div>
        </FormItem>
      )}
    />
  );
}

function WeeklyReconPaymentGroup({isMutating}: {isMutating: boolean}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="isSeparatePaymentGroupingRequiredForWeekly"
      render={({field}) => (
        <FormItem className="flex flex-col">
          <div className="flex space-x-2">
            <FormLabel
              className="mr-2"
              htmlFor="isSeparatePaymentGroupingRequiredForWeekly"
              showMandatoryAsterisk
            >
              Separate Recon File Based on Payment Group:
            </FormLabel>

            <FormControl>
              <Checkbox
                disabled={isMutating}
                aria-disabled={isMutating}
                checked={field.value as boolean}
                onCheckedChange={field.onChange}
                {...register("isSeparatePaymentGroupingRequiredForWeekly")}
              />
            </FormControl>
            <FormMessage />
          </div>
        </FormItem>
      )}
    />
  );
}

function MonthlyReconPaymentGroup({isMutating}: {isMutating: boolean}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="isSeparatePaymentGroupingRequiredForMonthly"
      render={({field}) => (
        <FormItem className="flex flex-col">
          <div className="flex space-x-2">
            <FormLabel
              className="mr-2"
              htmlFor="isSeparatePaymentGroupingRequiredForMonthly"
              showMandatoryAsterisk
            >
              Separate Recon File Based on Payment Group:
            </FormLabel>

            <FormControl>
              <Checkbox
                disabled={isMutating}
                aria-disabled={isMutating}
                checked={field.value as boolean}
                onCheckedChange={field.onChange}
                {...register("isSeparatePaymentGroupingRequiredForMonthly")}
              />
            </FormControl>
            <FormMessage />
          </div>
        </FormItem>
      )}
    />
  );
}
