import {useMemo} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import type {Table} from "@tanstack/react-table";
import {useDebounce} from "@uidotdev/usehooks";
import {
  CheckIcon,
  ChevronsUpDownIcon,
  ListRestartIcon,
  PrinterIcon,
} from "lucide-react";
import {useForm} from "react-hook-form";
import {z} from "zod";

import {Button} from "@/components/ui/button";
import {Collapsible} from "@/components/ui/collapsible";
import {Command, CommandGroup, CommandItem} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {toast} from "@/components/ui/use-toast";
import {DatePicker} from "@/components/craft/date-pickers";
import {DataTableViewOptions} from "@/components/craft/table";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

import {cn} from "@/lib/utils";
import {useAdminReports} from "@/modules/admin";

import {generateQueryString} from "@/utils/query-params";

const reportList = [
  {label: "Usages", value: "Invoices"},
  {label: "Invoice Loaded", value: "Sessions"},
];

const SearchAdminReportFormSchema = z.object({
  reportType: z.string(),
  startDate: z
    .date()
    .min(new Date("1970-01-01"), {
      message: "Date is out of range",
    })
    .optional(),
  endDate: z
    .date()
    .min(new Date("1970-01-01"), {
      message: "Date is out of range",
    })
    .optional(),
});

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  handlePrint: () => void;
}

export function AdminReportDataTableToolbar<TData>({
  table,
  handlePrint,
}: DataTableToolbarProps<TData>) {
  // Initialize useForm hook provided by react-hook-form with default values, mode, and resolver.
  // It uses the schema defined earlier to validate the form values.
  const form = useForm<z.infer<typeof SearchAdminReportFormSchema>>({
    //defaultValues,
    mode: "onChange",
    resolver: zodResolver(SearchAdminReportFormSchema),
  });

  const {
    formState: {isDirty},
  } = form;
  const formFields = form.watch();

  // Generate the search query based on the form fields.
  // The `useMemo` hook is used to avoid unnecessary re-renders.
  const searchQuery = useMemo(
    () => generateQueryString({formFields}),
    [formFields]
  );

  // TODO: replace with lodash debounce
  // Debounce the search query to limit the frequency of API calls while typing in the form.
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const debouncedSearchQuery = useDebounce(searchQuery, 150) as string;

  // Call the useAdminReports function with the debouncedSearchQuery.
  useAdminReports(debouncedSearchQuery);

  // The onSubmit function is called when the form is submitted.
  // It displays a toast notification with the submitted data.
  function onSubmit(data: z.infer<typeof SearchAdminReportFormSchema>) {
    toast({
      title: "You submitted the following values:",
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data, null, 2)}</code>
        </pre>
      ),
    });
  }

  // Return the form elements and fields as JSX. It uses Form, FormItem, FormControl, and FormMessage
  // components to structure and style the form.
  return (
    <Collapsible>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex justify-between gap-4">
            <div className="flex flex-wrap items-end gap-2">
              <RowsPreferenceSelect />
              <FormField
                control={form.control}
                name="reportType"
                render={({field}) => (
                  <div className="space-y-2">
                    <FormItem>
                      <Popover>
                        <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                          <Command>
                            <CommandGroup>
                              {reportList.map((report, i) => (
                                <CommandItem
                                  key={i}
                                  value={report.value}
                                  onSelect={(value) => {
                                    field.onChange(value);
                                  }}
                                >
                                  <CheckIcon
                                    className={cn(
                                      "mr-2 size-4",
                                      field.value &&
                                        field.value.toUpperCase() ===
                                          report.value
                                        ? "opacity-100"
                                        : "opacity-0"
                                    )}
                                  />
                                  {report.label}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </Command>
                        </PopoverContent>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant="outline"
                              role="combobox"
                              className={cn(
                                "justify-between",
                                !field.value && "text-accent-foreground",
                                "w-full"
                              )}
                            >
                              {field.value
                                ? reportList.find(
                                    (item) =>
                                      item.value.toLowerCase() === field.value
                                  )?.label
                                : "Select report type"}
                              <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  </div>
                )}
              />

              <FormField
                name="startDate"
                render={({field}) => (
                  <FormItem className="flex flex-col">
                    <DatePicker
                      {...field}
                      placeholder="Start Date"
                      disabled={
                        formFields.endDate
                          ? {
                              before: new Date("1970-01-01"),
                              after: formFields.endDate,
                            }
                          : undefined
                      }
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="endDate"
                render={({field}) => (
                  <FormItem className="flex flex-col">
                    <DatePicker
                      {...field}
                      placeholder="End Date"
                      disabled={
                        formFields.startDate
                          ? {
                              before: formFields.startDate,
                              after: new Date("9999-01-01"),
                            }
                          : undefined
                      }
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              {isDirty && (
                <Button
                  className="border-destructive-foreground/60 bg-destructive text-destructive-foreground hover:border-destructive-foreground/80 hover:bg-destructive-foreground/40 hover:text-destructive-foreground active:bg-destructive-foreground/60 active:text-destructive-foreground"
                  variant="outline"
                  onClick={() => {
                    form.reset();
                  }}
                >
                  <ListRestartIcon className="mr-2 size-4" />
                  Reset
                </Button>
              )}
            </div>

            <div className="flex flex-wrap items-center justify-end gap-2">
              <Button
                className="border-dashed border-success-foreground bg-success text-success-foreground hover:border-success-foreground hover:bg-success-foreground/40 hover:text-success-foreground active:bg-success-foreground/60 active:text-success-foreground"
                variant="outline"
                size="sm"
                type="button"
                onClick={handlePrint}
              >
                <span className="sr-only">Print table</span>
                <PrinterIcon className="mr-2 size-4" />
                Print
              </Button>
              {/* <Button
                className="border-dashed border-neutral-foreground bg-neutral text-neutral-foreground hover:border-neutral-foreground hover:bg-neutral-foreground/40 hover:text-neutral-foreground active:bg-neutral-foreground/60 active:text-neutral-foreground"
                variant="outline"
                size="sm"
                type="button"
              >
                <span className="sr-only">Export data</span>
                <FolderOutputIcon className="mr-2 size-4" />
                Export
              </Button> */}
              <DataTableViewOptions table={table} />
            </div>
          </div>
        </form>
      </Form>
    </Collapsible>
  );
}
