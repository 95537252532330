import {kyApiFn} from "@/lib/ky";
import {type EmailConfigurationFormDTO} from "@/modules/admin";

export const updateCustomerEmailConfigurationFn = (
  UpdateEmailConfigurationFormDTO: EmailConfigurationFormDTO,
  emailConfigId: string
) =>
  kyApiFn(
    `api/e-payable/notification-types/email-configs/${emailConfigId}/edit`,
    "put",
    {
      json: UpdateEmailConfigurationFormDTO,
    }
  );
