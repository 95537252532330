import * as React from "react";

import type {VariantProps} from "class-variance-authority";
import {cva} from "class-variance-authority";

import {cn} from "@/lib/utils";

// Define the variant classes for the spinner size and variant
const VARIANT_CLASSES = {
  size: {
    default: "size-8",
    xs: "size-4",
    sm: "size-6",
    lg: "w-10 h-10",
    full: "w-full h-full",
  },
  // TODO: add background colours for each variant
  variant: {
    default: "fill-foreground",
    primary: "fill-primary-foreground",
    secondary: "fill-secondary-foreground",
    accent: "fill-accent-foreground",
    destructive: "fill-destructive-foreground",
  },
};

// Use the cva function to assign the appropriate variant classes
// based on the given size and variant. This creates a dynamic class
// name generator that simplifies adding these classes to our component.
const spinnerVariants = cva(
  "mr-2 animate-spin text-gray-200 dark:text-gray-600",
  {
    variants: VARIANT_CLASSES,
    defaultVariants: {
      size: "default",
      variant: "default",
    },
  }
);

// Define the props that the Spinner component will accept
export interface SpinnerProps
  extends React.SVGAttributes<SVGSVGElement>,
    VariantProps<typeof spinnerVariants> {}

// Define the Spinner component, making sure it can properly handle refs for more complex interactions
export const Spinner = React.forwardRef<SVGSVGElement, SpinnerProps>(
  ({className, variant, size, ...props}, ref) => {
    // Generate the appropriate class string based on the given variant and size
    const svgClass = cn(spinnerVariants({variant, size, className}));

    return (
      // The div container for the spinner SVG. The 'role' attribute improves accessibility
      <div role="status">
        <svg
          // Apply the generated classes to our SVG
          className={svgClass}
          // Attach the forwarded ref to our SVG
          // eslint-disable-next-line react/jsx-sort-props
          ref={ref}
          // Define the aspect ratio and coordinate system of the SVG
          viewBox="0 0 100 101"
          // The 'fill' attribute determines the color of the current shapes
          fill="none"
          // The SVG namespace declaration
          xmlns="http://www.w3.org/2000/svg"
          // Add colour to the spinner
          style={{color: "#C900FF"}}
          // Any remaining props are passed through to the SVG
          {...props}
        >
          {/* SVG path elements define the shapes in the image. The 'd' attribute
          contains a series of commands and parameters used by the browser to
          draw the path */}
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        {/* The 'sr-only' class hides this text from sighted users but makes it
        accessible to screen readers */}
        <span className="sr-only">Loading...</span>
      </div>
    );
  }
);

Spinner.displayName = "Spinner";
