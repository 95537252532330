import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {
  EmailConfigurationRowActions,
  type EmailConfiguration,
} from "@/modules/admin";

import {FallbackMessages} from "@/utils/constants";

export const emailConfigurationTableColumns =
  (): ColumnDef<EmailConfiguration>[] => [
    {
      accessorKey: "notificationName",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Notification Type" />
      ),
      cell: ({row}) => {
        const notificationName: string = row.getValue("notificationName");
        return (
          <div className="w-44">
            {notificationName ? (
              notificationName
            ) : (
              <Badge variant="destructive">{FallbackMessages.NO_DATA}</Badge>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "recipientEmail",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Recipient Emails" />
      ),
      cell: ({row}) => {
        const recipientEmails: string = row.getValue("recipientEmail");
        const emailData = recipientEmails.split(",");
        if (Array.isArray(emailData)) {
          return (
            <div className="flex flex-wrap gap-2">
              {emailData.map((email, index) => (
                <Badge
                  key={index}
                  className="whitespace-nowrap rounded-full normal-case"
                >
                  {email}
                </Badge>
              ))}
            </div>
          );
        } else {
          return (
            <Badge variant="destructive">{FallbackMessages.NO_DATA}</Badge>
          );
        }

        // Handle the case where recipientEmails is not an array
        return <Badge variant="destructive">{FallbackMessages.NO_DATA}</Badge>;
      },
    },
    {
      id: "actions",
      header: () => <Paragraph className="text-right">Actions</Paragraph>,
      cell: ({row}) => (
        <div className="flex justify-end">
          <EmailConfigurationRowActions row={row} />
        </div>
      ),
    },
  ];
