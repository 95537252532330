import {useQuery} from "@tanstack/react-query";

import {keycloakKyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  keycloakRealmClientsQueryKeys,
  type KeycloakClientsType,
} from "@/modules/admin";

// Defining a type based on the function `getAllRealmClients`
type QueryFnType = typeof getAllRealmClients;

// Function to get all realm clients
export function getAllRealmClients() {
  const realm =
    (import.meta.env.VITE_KEYCLOAK_REALM as string | undefined) ?? "dev";
  return keycloakKyApiFn<KeycloakClientsType>(
    `admin/realms/${realm}/clients`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useAllRealmClients(config?: QueryConfig<QueryFnType>) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: keycloakRealmClientsQueryKeys.all,
    queryFn: () => getAllRealmClients(),
  });
}
