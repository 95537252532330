import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  dupesExemptionCharacterTypeKeys,
  type DupesExemptionCharacterTypesType,
} from "@/modules/duplicate-payments";

//Defining a type based on the function `getAllDupesExemptionCharacterTypes`
type QueryFnType = typeof getAllDupesExemptionCharacterTypes;

// Defining a function to fetch data from API
export function getAllDupesExemptionCharacterTypes() {
  // Fetch data from API using ky
  return kyApiFn<DupesExemptionCharacterTypesType[]>(
    `duplicate-payments/v1/exemption-character-types`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useAllDupesExemptionCharacterTypes(
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: dupesExemptionCharacterTypeKeys.all,
    queryFn: getAllDupesExemptionCharacterTypes,
  });
}
