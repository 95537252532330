import {z} from "zod";

export const AddSupplierScriptFormSchema = z.object({
  scriptData: z.array(
    z.object({
      sequenceId: z.string(),
      pageName: z.string(),
      pageCategory: z.string(),
      fieldName: z.string(),
      fieldType: z.string(),
      fieldValue: z.string(),
      tableName: z.string(),
      tableColumnName: z.string(),
      position: z.number(),
      separator: z.string(),
      dateFormat: z.string(),
      defaultValue: z.string(),
      dropDownValue: z.string(),
    })
  ),
});

export type AddSupplierScriptFormDTO = z.infer<
  typeof AddSupplierScriptFormSchema
>;
