import {type KeycloakTokenParsed} from "keycloak-js";
import {FileWarningIcon} from "lucide-react";

import {Spinner} from "@/components/ui/spinner";
import {Paragraph} from "@/components/ui/typography";

import {useStatementReconCustomerStore} from "@/lib/stores";
import {calculateUserRoles, useKeyCloakInstanceStore} from "@/modules/auth";
import {
  SelectCustomerBySupplierForm,
  useGetAllSupplierWithOutFilter,
} from "@/modules/customers";

import {KeycloakRoles} from "@/utils/constants";

/**
 * Props for supplierNotFound component.
 */
export interface supplierNotFoundProps {
  supplierId: string;
}

export type KeycloakTokenWithSupplierType = KeycloakTokenParsed & {
  supplier: string;
};

/**
 * Custom hook to manage and provide supplier data.
 * @returns An object containing user and supplier data along with state flags.
 */
function useSupplierData() {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const parsedUserInfo =
    storeKeyCloakInstance?.tokenParsed as KeycloakTokenWithSupplierType;

  const {
    data: suppliersRawData,
    isPending: suppliersArePending,
    isFetched: suppliersAreFetched,
    isError: suppliersError,
  } = useGetAllSupplierWithOutFilter();

  // Determine if the current supplier is not found in the fetched data
  const supplierNotFound =
    parsedUserInfo.supplier &&
    !suppliersRawData?.content.find(
      (supplier) =>
        supplier.supplier_id.toLowerCase() ===
        parsedUserInfo.supplier.toLowerCase().trim()
    );

  return {
    parsedUserInfo,
    suppliersArePending,
    suppliersAreFetched,
    suppliersError,
    supplierNotFound,
  };
}

/**
 * Component to display a loading spinner.
 */
function LoadingSpinner(): JSX.Element {
  return (
    <section>
      <Spinner className="mx-auto" />
    </section>
  );
}

/**
 * Component to display an error message when fetching suppliers fails.
 */
function ErrorFetchingSuppliers(): JSX.Element {
  return (
    <section>
      <Paragraph className="pt-12 text-center font-semibold">
        Error fetching suppliers
      </Paragraph>
    </section>
  );
}

/**
 * Component to display a warning when a specific supplier is not found.
 * @param {supplierNotFoundProps} props - The props for the component.
 */
export function SupplierNotFound({
  supplierId,
}: supplierNotFoundProps): JSX.Element {
  return (
    <section>
      <div className="flex justify-center gap-2 pt-16">
        <FileWarningIcon className="size-6 text-destructive-foreground" />
        <Paragraph>
          Supplier{" "}
          <span className="font-semibold text-destructive-foreground">
            {supplierId}
          </span>{" "}
          is not found. Please contact your administrator.
        </Paragraph>
        <FileWarningIcon className="size-6 text-destructive-foreground" />
      </div>
    </section>
  );
}

export function NoSupplierAssigned(): JSX.Element {
  return (
    <section>
      <div className="flex justify-center gap-2 pt-16">
        <FileWarningIcon className="size-6 text-destructive-foreground" />
        <Paragraph>
          Supplier not assigned. Please contact your administrator.
        </Paragraph>
        <FileWarningIcon className="size-6 text-destructive-foreground" />
      </div>
    </section>
  );
}

/**
 * Component representing the section where a user can select a supplier.
 * It handles various states like loading, error, supplier not found, and successful fetch.
 */
export function SelectSupplierSection(): JSX.Element | null {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const {statementReconStoreCustomer} = useStatementReconCustomerStore();

  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";
  const {
    parsedUserInfo,
    suppliersArePending,
    suppliersAreFetched,
    suppliersError,
    supplierNotFound,
  } = useSupplierData();

  // Display a spinner when data is still loading
  if (suppliersArePending && !suppliersAreFetched) {
    return <LoadingSpinner />;
  }

  // Display an error message if there is an error fetching suppliers
  if (suppliersError) {
    return <ErrorFetchingSuppliers />;
  }

  // Display a message if the specific supplier is not found
  if (supplierNotFound && parsedUserInfo.supplier) {
    return <SupplierNotFound supplierId={parsedUserInfo.supplier} />;
  }

  // Display a message if the user is a supplier user or supplier admin and no supplier is assigned

  if (
    !parsedUserInfo.supplier &&
    userRoleDisplay === KeycloakRoles.SupplierEnablement
  ) {
    return <NoSupplierAssigned />;
  }

  if (parsedUserInfo.supplier) {
    if (statementReconStoreCustomer) {
      return null;
    } else {
      <section>
        <div className="flex flex-1 justify-end">
          <SelectCustomerBySupplierForm
            supplierId={parsedUserInfo.supplier}
            requireLabel
          />
        </div>
        <Paragraph className="pt-12 text-center font-semibold">
          Please select customer
        </Paragraph>
      </section>;
    }
  }

  // Default case: display the form to select a supplier
  return (
    <section>
      <div className="flex flex-1 justify-end">
        <SelectCustomerBySupplierForm
          supplierId={parsedUserInfo.supplier}
          requireLabel
        />
      </div>
      <Paragraph className="pt-12 text-center font-semibold">
        Please select customer
      </Paragraph>
    </section>
  );
}
