import {z} from "zod";

export const imREmitLiteDashboardSchema = z.object({
  payablesOpenForMoreThan5Days: z.number(),
  endingPayablesInNext7Days: z.number(),
  payablesWithDeclines: z.number(),
  invoiceAmountMismatch: z.number(),
  invoiceNumberMismatch: z.number(),
  paymentAlreadyTaken: z.number(),
  partialPaymentAlreadyTaken: z.number(),
  escalationWithBank: z.number(),
  reopenedPayments: z.number(),
});

export type ImREmitLiteDashboard = z.infer<typeof imREmitLiteDashboardSchema>;
