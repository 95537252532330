import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  dupesCriteriaSettingsKeys,
  type DupesExemptionCharacterType,
  type DupesExemptionCharacterWithTypesType,
} from "@/modules/duplicate-payments";

type GetDupesCriteriaSettingsByExternalIdQueryFnType =
  typeof getDupesCriteriaSettingsByExternalId;

type GetDupesCriteriaSettingsByExternalIdAndExemptionTypeQueryFnType =
  typeof getDupesCriteriaSettingsByExternalIdAndExemptionType;

export function getDupesCriteriaSettingsByExternalId(externalId?: string) {
  return kyApiFn<DupesExemptionCharacterWithTypesType[]>(
    `duplicate-payments/v1/exemption-characters/${
      externalId ? externalId : ""
    }`,
    "get"
  );
}

export function useGetDupesCriteriaSettingsByExternalId(
  externalId?: string,
  config?: QueryConfig<GetDupesCriteriaSettingsByExternalIdQueryFnType>
) {
  return useQuery<
    ExtractFnReturnType<GetDupesCriteriaSettingsByExternalIdQueryFnType>
  >({
    ...config,
    queryKey: dupesCriteriaSettingsKeys.criteriaByExternalId(externalId),
    queryFn: () => getDupesCriteriaSettingsByExternalId(externalId),
    enabled: !!externalId,
  });
}

export function getDupesCriteriaSettingsByExternalIdAndExemptionType(
  exemptionType: string,
  externalId?: string
) {
  return kyApiFn<DupesExemptionCharacterWithTypesType[]>(
    `duplicate-payments/v1/exemption-characters/${
      externalId ? externalId : ""
    }/exemption-type/${exemptionType}`,
    "get"
  );
}

export function useGetDupesCriteriaSettingsByExternalIdAndExemptionType(
  exemptionType: string,
  externalId?: string,
  config?: QueryConfig<GetDupesCriteriaSettingsByExternalIdAndExemptionTypeQueryFnType>
) {
  return useQuery<
    ExtractFnReturnType<GetDupesCriteriaSettingsByExternalIdAndExemptionTypeQueryFnType>
  >({
    ...config,
    queryKey: dupesCriteriaSettingsKeys.criteriaByExternalIdAndExemptionTypeId(
      externalId,
      exemptionType
    ),
    queryFn: () =>
      getDupesCriteriaSettingsByExternalIdAndExemptionType(
        exemptionType,
        externalId
      ),
    enabled: !!externalId,
  });
}

export function createDupesExemptionCharacter(
  externalId: string,
  data: DupesExemptionCharacterType
) {
  return kyApiFn<DupesExemptionCharacterWithTypesType[]>(
    `duplicate-payments/v1/exemption-characters/${externalId}`,
    "post",
    {json: data}
  );
}

export function updateDupesExemptionCharacter(
  externalId: string,
  data: DupesExemptionCharacterType
) {
  return kyApiFn<DupesExemptionCharacterWithTypesType[]>(
    `duplicate-payments/v1/exemption-characters/${externalId}`,
    "put",
    {json: data}
  );
}

export function deleteDupesExemptionCharacter(exemption_id: string) {
  return kyApiFn<DupesExemptionCharacterWithTypesType[]>(
    `duplicate-payments/v1/exemption-characters/${exemption_id}`,
    "delete"
  );
}
