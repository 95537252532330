import {z} from "zod";

import {dupesExemptionCharacterTypesSchema} from "@/modules/duplicate-payments";

export const dupesExemptionCharacterSchema = z.object({
  exemption_id: z.number().nullable().optional(), // this is a number id
  field_id: z.string(), // this is a number id
  character: z.string(),
  buyer_external_id: z.string(),
  user_id: z.string(), // this is a number id
  last_modified_user_id: z.string(), // this is a number id
  created_date: z.string().or(z.date()).optional().nullable(),
  modified_date: z.string().or(z.date()).optional().nullable(),
  type_id: z.string(), // this is a number id
  exemption_type_id: z.string(), // this is a number id
  customer_id: z.string(), // this is a number id
});

export type DupesExemptionCharacterType = z.infer<
  typeof dupesExemptionCharacterSchema
>;

export const dupesExemptionCharacterResponseSchema = z.array(
  dupesExemptionCharacterSchema
); // 🛠️ Defining the response schema for exemption characters

// Extending the original schema with new fields from dupesExemptionCharacterTypeSchema
export const dupesExemptionCharacterWithTypesSchema =
  dupesExemptionCharacterSchema.merge(dupesExemptionCharacterTypesSchema); // 🔗 Merging schemas to include related type data

export type DupesExemptionCharacterWithTypesType = z.infer<
  typeof dupesExemptionCharacterWithTypesSchema
>;

export const dupesExemptionCharacterWithTypesResponseSchema = z.array(
  dupesExemptionCharacterWithTypesSchema
); // 🛠️ Defining the extended response schema for exemption characters with type info
