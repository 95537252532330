import {kyApiFn} from "@/lib/ky";
import {type EditImREmitFormDTO} from "@/modules/imremit";

export const editImREmitFn = (
  editImREmitDTO: EditImREmitFormDTO,
  paymentId: number | string
) =>
  kyApiFn(`api/e-payable/management/payment-details/${paymentId}/edit`, "put", {
    json: editImREmitDTO,
  });
