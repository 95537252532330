import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {supplierQueryKeys, type SupplierType} from "@/modules/customers";

// Defining a type based on the function `getAllSuppliers`
type QueryFnType = typeof getAllSuppliers;

// Function to fetch all suppliers
export function getAllSuppliers(filterData: string) {
  // Fetch all suppliers from API using ky
  const data = kyApiFn<SupplierType[]>(
    `statement-recon/v1/suppliers?filter=${filterData}`,
    "get"
  );
  // TODO: validate data
  // return supplierFetchSchema.parse(data);
  return data; // Returning the fetched data
}

// Custom hook to fetch and cache all supplier data using react-query
export function useAllSupplier(
  filterData: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: supplierQueryKeys.bySearch(filterData), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getAllSuppliers(filterData), // Setting the function to be used for fetching data
    enabled: !!filterData,
    // TODO: add onSuccess and onError handlers
  });
}

/**
 * Get all supplier with out any filter
 *
 *
 */

// Function to fetch all suppliers
export function getAllSupplierWithOutFilter() {
  // Fetch all suppliers from API using ky
  const data = kyApiFn<SupplierType[]>(
    `statement-recon/v1/suppliers/all`,
    "get"
  );
  // TODO: validate data
  // return supplierFetchSchema.parse(data);
  return data; // Returning the fetched data
}

// Custom hook to fetch and cache all supplier data using react-query
export function useGetAllSupplierWithOutFilter(
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: supplierQueryKeys.allSuppliers, // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getAllSupplierWithOutFilter(), // Setting the function to be used for fetching data
    // TODO: add onSuccess and onError handlers
  });
}
