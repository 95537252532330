import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {customersByNameQueryKeys, type CustomerType} from "@/modules/customers";

type QueryFnType = typeof getCustomerByName;

export function getCustomerByName(customerName?: string) {
  const data = kyApiFn<CustomerType[]>(
    `customer/v1/customers?customer_name=${customerName || ""}`,
    "get"
  );
  return data;
}

export function useGetCustomerByName(
  customerName?: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: customersByNameQueryKeys.byName(customerName), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getCustomerByName(customerName), // Setting the function to be used for fetching data
    enabled: !!customerName, // Setting the query to be enabled only if the search id string is not empty
  });
}
