/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import {type ColumnDef} from "@tanstack/react-table";
import {format} from "date-fns";
import {toZonedTime} from "date-fns-tz";
import type {DateRange} from "react-day-picker";
import {z} from "zod";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import type {DupeTrackerType} from "@/modules/duplicate-payments";
import {
  dateBetweenFilter,
  // DupesTrackerRowActions,
} from "@/modules/duplicate-payments";

import {FallbackMessages} from "@/utils/constants";
import {formatCurrency} from "@/utils/functions";

export const dupesTrackerTableColumnsSchema = z.object({
  invoice_number: z.boolean(),
  invoice_date: z.boolean(),
  invoice_amount: z.boolean(),
  invoice_paid_amount: z.boolean(),
  invoice_status: z.boolean(),
  facility_name: z.boolean(),
  supplier_participant_tax_code: z.boolean(),
  invoice_payment_number: z.boolean(),
  invoice_payment_date: z.boolean(),
  supplier_name: z.boolean(),
  supplier_number: z.boolean(),
  erp_unique_id: z.boolean(),
});

export type DupesTrackerTableColumnsType = z.infer<
  typeof dupesTrackerTableColumnsSchema
>;

export const dupesTrackerTableColumns: ColumnDef<DupeTrackerType>[] = [
  {
    accessorKey: "invoice_number",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice #" />
    ),
    cell: ({row}) => (
      <div className="w-auto">
        {row.getValue("invoice_number") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-bold">
              {row.getValue("invoice_number")}
            </Paragraph>
            {row.original.dupe_invoices.map((dupe_invoice, index) => {
              return (
                <Paragraph key={index} className="italic">
                  {dupe_invoice.invoice_number || "N/A"}
                </Paragraph>
              );
            })}
            {/* <Paragraph>{row.original.dupe_invoice.invoice_number}</Paragraph> */}
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "invoice_date",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Date" />
    ),
    cell: ({row}) => (
      <div className="w-auto">
        {row.getValue("invoice_date") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-bold">
              {format(
                toZonedTime(new Date(row.getValue("invoice_date")), "UTC"),
                "yyyy-MM-dd"
              )}
            </Paragraph>
            {row.original.dupe_invoices.map((dupe_invoice, index) => {
              return (
                <Paragraph key={index} className="italic">
                  {dupe_invoice.invoice_date
                    ? format(
                        toZonedTime(new Date(dupe_invoice.invoice_date), "UTC"),
                        "yyyy-MM-dd"
                      )
                    : "N/A"}
                </Paragraph>
              );
            })}
            {/* <Paragraph>
              {row.original.dupe_invoice.invoice_date
                ? format(
                    new Date(row.original.dupe_invoice.invoice_date),
                    "yyyy-MM-dd"
                  )
                : "N/A"}
            </Paragraph> */}
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    filterFn: (row, columnId, filterValue: DateRange) => {
      return dateBetweenFilter({row, columnId, filterValue});
    },
  },
  {
    accessorKey: "invoice_amount",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Amount" />
    ),
    cell: ({row}) => (
      <div className="w-auto">
        {row.getValue("invoice_amount") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-bold">
              {formatCurrency(parseFloat(row.getValue("invoice_amount")))}
            </Paragraph>
            {row.original.dupe_invoices.map((dupe_invoice, index) => {
              return (
                <Paragraph key={index} className="italic">
                  {typeof dupe_invoice.invoice_amount === "string"
                    ? formatCurrency(parseFloat(dupe_invoice.invoice_amount))
                    : dupe_invoice.invoice_amount
                    ? formatCurrency(dupe_invoice.invoice_amount)
                    : "N/A"}
                  {/* {formatCurrency(
                    parseFloat(dupe_invoice.invoice_amount || "0")
                  )} */}
                </Paragraph>
              );
            })}
            {/* <Paragraph>
              {formatCurrency(
                parseFloat(row.original.dupe_invoice.invoice_amount)
              )}
            </Paragraph> */}
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "invoice_paid_amount",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Paid Amount" />
    ),
    cell: ({row}) => (
      <div className="w-auto">
        {row.getValue("invoice_paid_amount") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-bold">
              {formatCurrency(parseFloat(row.getValue("invoice_paid_amount")))}
            </Paragraph>
            {row.original.dupe_invoices.map((dupe_invoice, index) => {
              return (
                <Paragraph key={index} className="italic">
                  {/* {formatCurrency(
                    parseFloat(dupe_invoice.invoice_paid_amount || "0")
                  )} */}
                  {typeof dupe_invoice.invoice_paid_amount === "string"
                    ? formatCurrency(
                        parseFloat(dupe_invoice.invoice_paid_amount)
                      )
                    : dupe_invoice.invoice_paid_amount
                    ? formatCurrency(dupe_invoice.invoice_paid_amount)
                    : "N/A"}
                </Paragraph>
              );
            })}
            {/* <Paragraph>
              {formatCurrency(
                parseFloat(row.original.dupe_invoice.invoice_paid_amount)
              )}
            </Paragraph> */}
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "invoice_status",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({row}) => (
      <div className="w-auto">
        {row.getValue("invoice_status") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-bold">
              {row.getValue("invoice_status")}
            </Paragraph>
            {row.original.dupe_invoices.map((dupe_invoice, index) => {
              return (
                <Paragraph key={index} className="italic">
                  {dupe_invoice.invoice_status || "N/A"}
                </Paragraph>
              );
            })}
            {/* <Paragraph>{row.original.dupe_invoice.invoice_status}</Paragraph> */}
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "facility_name",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Customer Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("facility_name") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="whitespace-nowrap font-bold">
              {row.getValue("facility_name")}
            </Paragraph>
            {row.original.dupe_invoices.map((dupe_invoice, index) => {
              return (
                <Paragraph key={index} className="whitespace-nowrap italic">
                  {dupe_invoice.facility_name || "N/A"}
                </Paragraph>
              );
            })}
            {/* <Paragraph>{row.original.dupe_invoice.facility_name}</Paragraph> */}
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "supplier_number",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Supplier Number" />
    ),
    cell: ({row}) => (
      <div className="w-auto">
        {row.getValue("supplier_number") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-bold">
              {row.getValue("supplier_number")}
            </Paragraph>
            {row.original.dupe_invoices.map((dupe_invoice, index) => {
              return (
                <Paragraph key={index} className="italic">
                  {dupe_invoice.supplier_number || "N/A"}
                </Paragraph>
              );
            })}
            {/* <Paragraph>{row.original.dupe_invoice.supplier_number}</Paragraph> */}
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "invoice_payment_number",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment Number" />
    ),
    cell: ({row}) => (
      <div className="w-auto">
        {row.getValue("invoice_payment_number") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-bold">
              {row.getValue("invoice_payment_number")}
            </Paragraph>
            {row.original.dupe_invoices.map((dupe_invoice, index) => {
              return (
                <Paragraph key={index} className="italic">
                  {dupe_invoice.invoice_payment_number || "N/A"}
                </Paragraph>
              );
            })}
            {/* <Paragraph>
              {row.original.dupe_invoice.invoice_payment_number}
            </Paragraph> */}
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "invoice_payment_date",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment Date" />
    ),
    cell: ({row}) => (
      <div className="w-auto">
        {row.getValue("invoice_payment_date") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-bold">
              {format(
                toZonedTime(
                  new Date(row.getValue("invoice_payment_date")),
                  "UTC"
                ),
                "yyyy-MM-dd"
              )}
            </Paragraph>
            {row.original.dupe_invoices.map((dupe_invoice, index) => {
              return (
                <Paragraph key={index} className="italic">
                  {dupe_invoice.invoice_payment_date
                    ? format(
                        toZonedTime(
                          new Date(dupe_invoice.invoice_payment_date),
                          "UTC"
                        ),
                        "yyyy-MM-dd"
                      )
                    : "N/A"}
                </Paragraph>
              );
            })}
            {/* <Paragraph>
              {row.original.dupe_invoice.invoice_payment_date
                ? format(
                    new Date(row.original.dupe_invoice.invoice_payment_date),
                    "yyyy-MM-dd"
                  )
                : "N/A"}
            </Paragraph> */}
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    filterFn: (row, columnId, filterValue: DateRange) => {
      return dateBetweenFilter({row, columnId, filterValue});
    },
  },
  {
    accessorKey: "supplier_name",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Supplier Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("supplier_name") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="whitespace-nowrap font-bold">
              {row.getValue("supplier_name")}
            </Paragraph>
            {row.original.dupe_invoices.map((dupe_invoice, index) => {
              return (
                <Paragraph key={index} className="whitespace-nowrap italic">
                  {dupe_invoice.supplier_name || "N/A"}
                </Paragraph>
              );
            })}
            {/* <Paragraph>{row.original.dupe_invoice.supplier_name}</Paragraph> */}
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "erp_unique_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Voucher ID" />
    ),
    cell: ({row}) => (
      <div className="w-auto">
        {row.getValue("erp_unique_id") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-bold">
              {row.getValue("erp_unique_id")}
            </Paragraph>
            {row.original.dupe_invoices.map((dupe_invoice, index) => {
              return (
                <Paragraph key={index} className="italic">
                  {dupe_invoice.erp_unique_id || "N/A"}
                </Paragraph>
              );
            })}
            {/* <Paragraph>{row.original.dupe_invoice.erp_unique_id}</Paragraph> */}
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  // {
  //   id: "actions",
  //   header: () => <Paragraph className="text-right">Actions</Paragraph>,
  //   cell: ({row}) => (
  //     <div className="flex justify-end">
  //       <DupesTrackerRowActions row={row} />
  //     </div>
  //   ),
  // },
];
