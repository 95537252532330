import type {ColumnDef} from "@tanstack/react-table";
import {format} from "date-fns";

import {Badge} from "@/components/ui/badge";
// import {Label} from "@/components/ui/label";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import type {SupplierManagementCommentType} from "@/modules/imremit";
import {SupplierCommentUpdate} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";

// function UpdateMarkAsRead(
//   row: Row<{
//     userId: string;
//     comments: string;
//     commentId: number;
//     markAsRead: boolean;
//     dateTime: Date;
//   }>
// ) {
//   const {supplierId} = useParams({
//     from: "/app/imremit/supplier-management/$supplierId/view",
//   });
//   const rowData = row.original;
//   const comments = rowData.comments;
//   const commentId = rowData.commentId;
//   const userId = rowData.userId.toString();
//   const markAsReadUpdate = !rowData.markAsRead; // Toggle the value

//   // Prepare the payload for the mutation function
//   const payload = {
//     commentId: commentId,
//     comments: comments,
//     userId: userId,
//     markAsRead: markAsReadUpdate, // Update the markAsRead value
//   };

//   const updateSupplierCommentMutation = useMutation({
//     mutationFn: (data: z.infer<typeof SupplierCommentsMarkAsRead>) => {
//       return updateSupplierCommentMarkAsReadFn(
//         data,
//         data.commentId.toString(),
//         data.markAsRead.toString()
//       );
//     },

//     onSuccess: () => {
//       toast({
//         variant: "success",
//         title: "Success!",
//         description: "Your comment was updated successfully.",
//       });
//     },
//     onError: (error: unknown) => {
//       console.error("error", error);
//     },
//   });

//   updateSupplierCommentMutation.mutate(payload);
// }

// Definition of supplier comments columns for the datatable
export const supplierCommentsColumns: ColumnDef<SupplierManagementCommentType>[] =
  [
    {
      accessorKey: "userId",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="User" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("userId") ? (
            row.getValue("userId")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "dateTime",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Date/Time" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("dateTime") ? (
            format(
              new Date(row.getValue("dateTime")),
              "EEEE MMMM do yyyy '@' HH:mm:ss"
            )
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "comments",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Comment" />
      ),
      cell: ({row}) => (
        <div className="w-full">
          {row.getValue("comments") ? (
            row.getValue("comments")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    // {
    //   accessorKey: "markAsRead",
    //   header: ({column}) => (
    //     <DataTableColumnHeader column={column} title="Comment Read" />
    //   ),
    //   cell: ({row}) => (
    //     <div className="w-fit">
    //       {row.getValue("markAsRead") !== null &&
    //       row.getValue("markAsRead") !== undefined ? (
    //         row.getValue("markAsRead") ? (
    //           <Label>Read</Label>
    //         ) : (
    //           <Label>Unread</Label>
    //         )
    //       ) : (
    //         <Badge className="whitespace-nowrap" variant="destructive">{FallbackMessages.NO_DATA}</Badge>
    //       )}
    //     </div>
    //   ),
    //   // enableSorting: false,
    //   // enableHiding: false,
    // },
    {
      id: "actions",
      header: () => <Paragraph className="text-right">Actions</Paragraph>,
      cell: ({row}) => (
        <div className="flex w-full justify-end">
          <SupplierCommentUpdate
            commentId={row.original.commentId}
            comments={row.original.comments}
          />
        </div>
      ),
    },
  ];
