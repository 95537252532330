import {useState} from "react";

import {useParams} from "@tanstack/react-router";
import {
  ArrowLeftRightIcon,
  EyeOffIcon,
  // MailCheckIcon,
  ReceiptIcon,
} from "lucide-react";

import {Badge} from "@/components/ui/badge";
import {Button} from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import {Separator} from "@/components/ui/separator";
import {Skeleton} from "@/components/ui/skeleton";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "@/components/ui/tabs";
import {Paragraph} from "@/components/ui/typography";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {cn} from "@/lib/utils";
import {
  calculateUserRoles,
  KeycloakRoleEnum,
  useAuthorization,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {
  getStatusClass,
  NewRemittanceManagementMultiView,
  PaymentManagementCommentsData,
  PaymentManagementInvoicesTable,
  PaymentManagementTransactionsTable,
  RevealCardDetails,
  statusVariants,
  useGetPaymentDetails,
} from "@/modules/imremit";

import {FallbackMessages, KeycloakRoles} from "@/utils/constants";
import {formatCurrency} from "@/utils/functions";

// Defining the PaymentManagementDetails component
export function ViewRemittanceDetailsPage() {
  //Fetching logged user role
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  const {paymentId} = useParams({
    from: "/app/imremit/remittance-management/$paymentId/view/$list_type",
  });

  //Card reveal button action state
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const searchimREmitQuery = useGetPaymentDetails(paymentId);
  const searchimREmitData = searchimREmitQuery.data?.content;

  const {checkPoliciesAccess} = useAuthorization();

  // Handle unauthorized access
  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_REMITTANCE_MANAGEMENT])) {
    return <NotAuthorizedPage />;
  }

  return (
    <section>
      <NewRemittanceManagementMultiView />
      <Card>
        <CardHeader className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
          <Paragraph className="flex flex-col text-lg">
            <strong>Payment Number: </strong>
            {searchimREmitQuery.isPending ? (
              <Skeleton className="h-6 w-full" />
            ) : (
              searchimREmitData?.paymentNumber ?? (
                <Badge className="whitespace-nowrap" variant="destructive">
                  {FallbackMessages.NO_DATA}
                </Badge>
              )
            )}
          </Paragraph>
          <Paragraph className="flex flex-col text-lg">
            <strong>Account Number: </strong>
            {searchimREmitQuery.isPending ? (
              <Skeleton className="h-6 w-full" />
            ) : (
              searchimREmitData?.accountNumber ?? (
                <Badge className="whitespace-nowrap" variant="destructive">
                  {FallbackMessages.NO_DATA}
                </Badge>
              )
            )}
          </Paragraph>
          <Paragraph className="flex flex-col text-lg">
            <strong>Status: </strong>
            {searchimREmitQuery.isPending ? (
              <Skeleton className="h-6 w-full" />
            ) : searchimREmitData?.customerStatus === "Failed" &&
              (userRoleDisplay === KeycloakRoles.CustomerAdmin ||
                userRoleDisplay === KeycloakRoles.CustomerUser ||
                userRoleDisplay === "Facility User" ||
                userRoleDisplay === "ViewOnly User") ? (
              "In Progress"
            ) : searchimREmitData?.customerStatus ? (
              <span
                className={cn(
                  statusVariants({
                    statusColour: getStatusClass(
                      searchimREmitData.customerStatus
                    ),
                  })
                )}
              >
                {searchimREmitData.customerStatus}
              </span>
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>

          <div className="flex w-full justify-end gap-1">
            <PaymentManagementCommentsData
              id={searchimREmitData?.paymentDetailId.toString()}
            />
            {/* <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
              <Button size="sm">
                <Paragraph>Export Data</Paragraph>
                <MailCheckIcon className="ml-2 size-4" />
              </Button>
            </div> */}
          </div>
        </CardHeader>
        <Separator className="mb-2" />
        <CardContent>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
            <div className="grid grid-cols-1">
              <Paragraph className="flex flex-col text-lg">
                <strong>Amount: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : searchimREmitData &&
                  searchimREmitData.totalAmountSent !== null ? (
                  formatCurrency(searchimREmitData.totalAmountSent)
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )}
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Outstanding Balance: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : searchimREmitData &&
                  searchimREmitData.balanceAmount !== null ? (
                  formatCurrency(searchimREmitData.balanceAmount)
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )}
                <small>Transactions may take upto 3 days to post.</small>
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Amount Taken: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : searchimREmitData &&
                  searchimREmitData.amountTaken !== null ? (
                  formatCurrency(searchimREmitData.amountTaken)
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )}
              </Paragraph>
            </div>

            <div className="grid grid-cols-1">
              <Paragraph className="flex flex-col text-lg">
                <strong>Authorization Type: </strong>
                {searchimREmitData?.singleTranExactAuth != null
                  ? searchimREmitData.singleTranExactAuth
                    ? "Single Authorization"
                    : "Multi Authorization"
                  : ""}
              </Paragraph>

              {searchimREmitData &&
              searchimREmitData.customerStatus !==
                "Paid - Pending Verification" &&
              // searchimREmitData.remittanceType == "Pay By Web" &&
              checkPoliciesAccess([KeycloakRoleEnum.READ_CARD_DETAILS]) ? (
                <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                  {isButtonClicked ? (
                    <RevealCardDetails
                      paymentDetailId={searchimREmitData.paymentDetailId}
                    />
                  ) : (
                    <Button
                      className="gap-2"
                      size="sm"
                      variant="outline"
                      onClick={() => {
                        setIsButtonClicked(true);
                      }}
                    >
                      <EyeOffIcon className="size-4" />
                      <Paragraph>Reveal Card Details</Paragraph>
                    </Button>
                  )}
                </div>
              ) : (
                <Paragraph className="flex flex-col text-lg">
                  <strong>Card Number: </strong>
                  {searchimREmitQuery.isPending ? (
                    <Skeleton className="h-6 w-full" />
                  ) : (
                    searchimREmitData?.cardNumber ?? (
                      <Badge variant="destructive">
                        {FallbackMessages.NO_DATA}
                      </Badge>
                    )
                  )}
                </Paragraph>
              )}

              <Paragraph className="flex flex-col text-lg">
                <strong>Payment Zip Code / Postal Code : </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.paymentZip ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
            </div>

            <div className="grid grid-cols-1">
              <Paragraph className="flex flex-col text-lg">
                <strong>Bank: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.providerName ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>

              <Paragraph className="flex flex-col text-lg">
                <strong>Sent Date: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.initiatedDate ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>

              <Paragraph className="flex flex-col text-lg">
                <strong>Payment End Date: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.endDate ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>

              <Paragraph className="flex flex-col text-lg">
                <strong>Payment Method:</strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.methodName ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
            </div>
            <div className="grid grid-cols-1">
              <Paragraph className="flex flex-col text-lg">
                <strong>Supplier: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.supplierName ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Supplier ID: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.supplierNumber ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Payee Name: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.facilityName ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
            </div>
          </div>
        </CardContent>
        <Separator className="mb-4" />
        <CardFooter>
          <Tabs defaultValue="invoices" className="w-full ">
            <TabsList className="grid h-12 w-full grid-cols-5">
              <TabsTrigger value="invoices">
                <ReceiptIcon className="p-1" />
                Invoices
              </TabsTrigger>
              <TabsTrigger value="transactions">
                <ArrowLeftRightIcon className="p-1" />
                Transactions
              </TabsTrigger>
            </TabsList>

            <TabsContent value="invoices">
              <Card>
                <CardHeader>
                  <CardDescription>
                    Changes to invoices do not affect the payable total. If
                    invoices have been added, they will appear at the bottom of
                    the table
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-2">
                  <PaymentManagementInvoicesTable
                    id={searchimREmitData?.paymentDetailId.toString()}
                  />
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="transactions">
              <Card>
                <CardContent className="space-y-2 pt-5">
                  <PaymentManagementTransactionsTable
                    id={searchimREmitData?.paymentDetailId.toString()}
                  />
                </CardContent>
              </Card>
            </TabsContent>
          </Tabs>
        </CardFooter>
      </Card>
    </section>
  );
}
