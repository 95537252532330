import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {dateFormatsKeys, type dateFormatType} from "@/modules/imremit";

//Defining a type based on the function `getAllDateFormats`
type QueryFnType = typeof getAllDateFormats;

//Funciton to get the date format list
export function getAllDateFormats() {
  // Fetch data from API using ky
  return kyApiFn<dateFormatType[]>(`api/e-payable/date-formats`, "get");
}

// Custom hook to fetch and cache delimiter type data using react-query
export function useDateFormatDetails(config?: QueryConfig<QueryFnType>) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: dateFormatsKeys.allDateFormats(),
    queryFn: () => getAllDateFormats(),
  });
}
