import {FileTerminalIcon} from "lucide-react";

import {Badge} from "@/components/ui/badge";
import {Button} from "@/components/ui/button";
import {Card, CardContent, CardFooter, CardHeader} from "@/components/ui/card";
import {Dialog, DialogContent, DialogTrigger} from "@/components/ui/dialog";
import {ScrollArea} from "@/components/ui/scroll-area";
import {Separator} from "@/components/ui/separator";
import {Skeleton} from "@/components/ui/skeleton";
import {Heading2, Heading3, Paragraph} from "@/components/ui/typography";

import {
  useGetCustomerProfileById,
  type CustomerType,
} from "@/modules/customers";

import {FallbackMessages} from "@/utils/constants";

/**
 * Custom hook for fetching customer profile data based on a participant ID.
 *
 * This hook uses `useGetCustomerProfileById` to retrieve customer profile information.
 * It returns an object containing the raw data of the customer profile, along with
 * flags indicating the state of the fetch operation, such as pending, fetched, and error states.
 *
 * @param {number} externalId - The unique identifier for the participant. This ID is used
 *                                 to fetch the corresponding customer profile data.
 *
 * @returns {{
 *   imCustomerProfileRawData: any,
 *   imCustomerProfileIsPending: boolean,
 *   imCustomerProfileAreFetched: boolean,
 *   imCustomerProfileError: boolean
 * }} An object containing the following properties:
 *
 * | Property                   | Type    | Description                                          |
 * |----------------------------|---------|------------------------------------------------------|
 * | imCustomerProfileRawData   | any     | The raw data of the customer profile as returned by `useGetCustomerProfileById`. |
 * | imCustomerProfileIsPending| boolean | A flag indicating if the customer profile data is still being fetched. |
 * | imCustomerProfileAreFetched| boolean | A flag indicating if the customer profile data has been fetched. |
 * | imCustomerProfileError     | boolean | A flag indicating if there was an error while fetching the customer profile data. |
 */
function useFetchCustomerProfileQuery(externalId: string) {
  const {
    data: imCustomerProfileRawData,
    isPending: imCustomerProfileIsPending,
    isError: imCustomerProfileError,
  } = useGetCustomerProfileById(externalId);

  return {
    imCustomerProfileRawData,
    imCustomerProfileIsPending,
    imCustomerProfileError,
  };
}

/**
 * Props for the ImREmitDashboardCustomerCard component.
 * @property {ImRemitCustomerType} customer - The currently selected customer's data.
 */
interface ImREmitDashboardCustomerCardProps {
  customer: CustomerType;
}

/**
 * ImREmitDashboardCustomerCard component.
 * Renders the customer information card in the dashboard.
 * @param {ImREmitDashboardCustomerCardProps} props - Props containing the customer data.
 * @returns {JSX.Element} JSX to render the main content section.
 */
export function ImREmitDashboardCustomerCard({
  customer,
}: ImREmitDashboardCustomerCardProps) {
  const {
    imCustomerProfileRawData,
    imCustomerProfileIsPending,
    imCustomerProfileError,
  } = useFetchCustomerProfileQuery(customer.externalId);

  if (imCustomerProfileError) {
    throw new Error("Error fetching customer profile data");
  }

  return (
    <Card className="flex w-full flex-col 2xl:basis-1/4">
      <CardHeader>
        <Heading2>Customer Information</Heading2>
        <div className="flex items-center justify-between gap-6">
          <Paragraph className="font-semibold">{customer.buyerName}</Paragraph>
          {
            // eslint-disable-next-line no-nested-ternary
            imCustomerProfileIsPending ? (
              <Skeleton className="w-1/2" />
            ) : imCustomerProfileRawData &&
              imCustomerProfileRawData.content.programType ? (
              <Badge>{imCustomerProfileRawData.content.programType}</Badge>
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )
          }
        </div>
      </CardHeader>
      <Separator className="mb-4" />
      <CardContent>
        <Heading3 className="mb-4">Billing & Payment</Heading3>
        <div className="flex flex-col gap-y-2">
          <div className="flex items-center justify-between gap-6">
            <Paragraph className="font-semibold">Billing Type:</Paragraph>
            <Paragraph>
              {
                // eslint-disable-next-line no-nested-ternary
                imCustomerProfileIsPending ? (
                  <Skeleton className="w-1/2" />
                ) : imCustomerProfileRawData &&
                  imCustomerProfileRawData.content.billingType ? (
                  imCustomerProfileRawData.content.billingType
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )
              }
            </Paragraph>
          </div>
          <div className="flex items-center justify-between gap-6">
            <Paragraph className="font-semibold">Payment Type:</Paragraph>
            <Paragraph>
              {
                // eslint-disable-next-line no-nested-ternary
                imCustomerProfileIsPending ? (
                  <Skeleton className="w-1/2" />
                ) : imCustomerProfileRawData &&
                  imCustomerProfileRawData.content.paymentType ? (
                  imCustomerProfileRawData.content.paymentType
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )
              }
            </Paragraph>
          </div>
          <div className="flex items-center justify-between gap-6">
            <Paragraph className="font-semibold">Settlement Terms:</Paragraph>
            <Paragraph>
              {
                // eslint-disable-next-line no-nested-ternary
                imCustomerProfileIsPending ? (
                  <Skeleton className="w-1/2" />
                ) : imCustomerProfileRawData &&
                  imCustomerProfileRawData.content.settlementTerms ? (
                  imCustomerProfileRawData.content.settlementTerms
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )
              }
            </Paragraph>
          </div>
          <div className="flex items-center justify-between gap-6">
            <Paragraph className="font-semibold">Payment Method:</Paragraph>
            <Paragraph>
              {
                // eslint-disable-next-line no-nested-ternary
                imCustomerProfileIsPending ? (
                  <Skeleton className="w-1/2" />
                ) : imCustomerProfileRawData &&
                  imCustomerProfileRawData.content.paymentMethod ? (
                  imCustomerProfileRawData.content.paymentMethod
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )
              }
            </Paragraph>
          </div>
          <div className="flex items-center justify-between gap-6">
            <Paragraph className="font-semibold">Cycle Date:</Paragraph>
            <Paragraph>
              {
                // eslint-disable-next-line no-nested-ternary
                imCustomerProfileIsPending ? (
                  <Skeleton className="w-1/2" />
                ) : imCustomerProfileRawData &&
                  imCustomerProfileRawData.content.cycleDate ? (
                  imCustomerProfileRawData.content.cycleDate
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )
              }
            </Paragraph>
          </div>
          <div className="flex items-center justify-between gap-6">
            <Paragraph className="font-semibold">Invoice Day:</Paragraph>
            <Paragraph>
              {
                // eslint-disable-next-line no-nested-ternary
                imCustomerProfileIsPending ? (
                  <Skeleton className="w-1/2" />
                ) : imCustomerProfileRawData &&
                  imCustomerProfileRawData.content.invoiceDay ? (
                  imCustomerProfileRawData.content.invoiceDay
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )
              }
            </Paragraph>
          </div>
        </div>
      </CardContent>
      <Separator className="mb-4" />
      <CardContent>
        <Heading3 className="mb-4">Billing Contact</Heading3>
        <div className="flex flex-col gap-y-2">
          <div className="flex items-center justify-between gap-6">
            <Paragraph className="font-semibold">Contact Name:</Paragraph>
            <Paragraph>
              {
                // eslint-disable-next-line no-nested-ternary
                imCustomerProfileIsPending ? (
                  <Skeleton className="w-1/2" />
                ) : imCustomerProfileRawData &&
                  imCustomerProfileRawData.content.contactName ? (
                  imCustomerProfileRawData.content.contactName
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )
              }
            </Paragraph>
          </div>
          <div className="flex items-center justify-between gap-6">
            <Paragraph className="font-semibold">Address:</Paragraph>
            <Paragraph>
              {imCustomerProfileIsPending ? (
                <Skeleton className="w-1/2" />
              ) : (
                (imCustomerProfileRawData &&
                  imCustomerProfileRawData.content.address1 &&
                  `${imCustomerProfileRawData.content.address1}, ${
                    imCustomerProfileRawData.content.address2 || ""
                  }, ${imCustomerProfileRawData.content.address3 || ""}, ${
                    imCustomerProfileRawData.content.zip || ""
                  }`
                    .trim()
                    .replace(/, ,/g, ",")) || (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )
              )}
            </Paragraph>
          </div>
          <div className="flex items-center justify-between gap-6">
            <Paragraph className="font-semibold">Country:</Paragraph>
            <Paragraph>
              {
                // eslint-disable-next-line no-nested-ternary
                imCustomerProfileIsPending ? (
                  <Skeleton className="w-1/2" />
                ) : imCustomerProfileRawData &&
                  imCustomerProfileRawData.content.country ? (
                  <Badge className="uppercase">
                    {imCustomerProfileRawData.content.country}
                  </Badge>
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )
              }
            </Paragraph>
          </div>
          <div className="flex items-center justify-between gap-6">
            <Paragraph className="font-semibold">Phone:</Paragraph>
            <Paragraph>
              {
                // eslint-disable-next-line no-nested-ternary
                imCustomerProfileIsPending ? (
                  <Skeleton className="w-1/2" />
                ) : imCustomerProfileRawData &&
                  imCustomerProfileRawData.content.phone ? (
                  imCustomerProfileRawData.content.phone
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )
              }
            </Paragraph>
          </div>
        </div>
      </CardContent>
      <Separator className="mb-6 mt-auto" />
      <CardFooter>
        <Dialog>
          <DialogTrigger asChild>
            <Button className="mt-auto w-full gap-2">
              <span className="sr-only">View Customer Details</span>
              View Customer Details
              <FileTerminalIcon className="size-4" />
            </Button>
          </DialogTrigger>
          <DialogContent className="min-w-[1280px]">
            <ScrollArea>
              <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                  <Heading2>Customer Information</Heading2>
                  <Paragraph className="font-semibold">
                    {customer.buyerName}
                  </Paragraph>
                  {
                    // eslint-disable-next-line no-nested-ternary
                    imCustomerProfileIsPending ? (
                      <Skeleton className="w-1/2" />
                    ) : imCustomerProfileRawData &&
                      imCustomerProfileRawData.content.programType ? (
                      <Badge>
                        {imCustomerProfileRawData.content.programType}
                      </Badge>
                    ) : (
                      <Badge variant="destructive">
                        {FallbackMessages.NO_DATA}
                      </Badge>
                    )
                  }
                </div>
                <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
                  <Card className="col-span-1 lg:col-span-4">
                    <CardHeader>
                      <Heading3>Billing & Payment</Heading3>
                    </CardHeader>
                    <CardContent>
                      <div className="grid grid-cols-1 gap-2 gap-y-2 lg:grid-cols-3">
                        <Paragraph>
                          <span className="font-semibold">Billing Type: </span>
                          {
                            // eslint-disable-next-line no-nested-ternary
                            imCustomerProfileIsPending ? (
                              <Skeleton className="w-1/2" />
                            ) : imCustomerProfileRawData &&
                              imCustomerProfileRawData.content.billingType ? (
                              imCustomerProfileRawData.content.billingType
                            ) : (
                              <Badge variant="destructive">
                                {FallbackMessages.NO_DATA}
                              </Badge>
                            )
                          }
                        </Paragraph>
                        <Paragraph className="break-words">
                          <span className="font-semibold">Payment Type: </span>
                          {
                            // eslint-disable-next-line no-nested-ternary
                            imCustomerProfileIsPending ? (
                              <Skeleton className="w-1/2" />
                            ) : imCustomerProfileRawData &&
                              imCustomerProfileRawData.content.paymentType ? (
                              imCustomerProfileRawData.content.paymentType
                            ) : (
                              <Badge variant="destructive">
                                {FallbackMessages.NO_DATA}
                              </Badge>
                            )
                          }
                        </Paragraph>
                        <Paragraph>
                          <span className="font-semibold">
                            Settlement Terms:{" "}
                          </span>
                          {
                            // eslint-disable-next-line no-nested-ternary
                            imCustomerProfileIsPending ? (
                              <Skeleton className="w-1/2" />
                            ) : imCustomerProfileRawData &&
                              imCustomerProfileRawData.content
                                .settlementTerms ? (
                              imCustomerProfileRawData.content.settlementTerms
                            ) : (
                              <Badge variant="destructive">
                                {FallbackMessages.NO_DATA}
                              </Badge>
                            )
                          }
                        </Paragraph>
                        <Paragraph>
                          <span className="font-semibold">
                            Payment Method:{" "}
                          </span>
                          {
                            // eslint-disable-next-line no-nested-ternary
                            imCustomerProfileIsPending ? (
                              <Skeleton className="w-1/2" />
                            ) : imCustomerProfileRawData &&
                              imCustomerProfileRawData.content.paymentMethod ? (
                              imCustomerProfileRawData.content.paymentMethod
                            ) : (
                              <Badge variant="destructive">
                                {FallbackMessages.NO_DATA}
                              </Badge>
                            )
                          }
                        </Paragraph>
                        <Paragraph>
                          <span className="font-semibold">Cycle Date: </span>
                          {imCustomerProfileIsPending ? (
                            <Skeleton className="w-1/2" />
                          ) : imCustomerProfileRawData &&
                            imCustomerProfileRawData.content.cycleDate ? (
                            imCustomerProfileRawData.content.cycleDate
                          ) : (
                            <Badge variant="destructive">
                              {FallbackMessages.NO_DATA}
                            </Badge>
                          )}
                        </Paragraph>
                        <Paragraph>
                          <span className="font-semibold">Invoice Day: </span>
                          {imCustomerProfileIsPending ? (
                            <Skeleton className="w-1/2" />
                          ) : imCustomerProfileRawData &&
                            imCustomerProfileRawData.content.invoiceDay ? (
                            imCustomerProfileRawData.content.invoiceDay
                          ) : (
                            <Badge variant="destructive">
                              {FallbackMessages.NO_DATA}
                            </Badge>
                          )}
                        </Paragraph>
                        <Paragraph>
                          <span className="font-semibold">Association: </span>
                          {imCustomerProfileIsPending ? (
                            <Skeleton className="w-1/2" />
                          ) : imCustomerProfileRawData &&
                            imCustomerProfileRawData.content.association ? (
                            imCustomerProfileRawData.content.association
                          ) : (
                            <Badge variant="destructive">
                              {FallbackMessages.NO_DATA}
                            </Badge>
                          )}
                        </Paragraph>
                      </div>
                    </CardContent>
                  </Card>
                  <Card className="col-span-1 lg:col-span-2">
                    <CardHeader>
                      <Heading3>Billing Contact</Heading3>
                    </CardHeader>
                    <CardContent>
                      <div className="grid grid-cols-1 gap-2 gap-y-2 lg:grid-cols-2">
                        <Paragraph>
                          <span className="font-semibold">Contact Name: </span>
                          {imCustomerProfileIsPending ? (
                            <Skeleton className="w-1/2" />
                          ) : imCustomerProfileRawData &&
                            imCustomerProfileRawData.content.contactName ? (
                            imCustomerProfileRawData.content.contactName
                          ) : (
                            <Badge variant="destructive">
                              {FallbackMessages.NO_DATA}
                            </Badge>
                          )}
                        </Paragraph>
                        <Paragraph>
                          <span className="font-semibold">Address: </span>
                          {imCustomerProfileIsPending ? (
                            <Skeleton className="w-1/2" />
                          ) : (
                            (imCustomerProfileRawData &&
                              imCustomerProfileRawData.content.address1 &&
                              `${imCustomerProfileRawData.content.address1}, ${
                                imCustomerProfileRawData.content.address2 || ""
                              }, ${
                                imCustomerProfileRawData.content.address3 || ""
                              }, ${imCustomerProfileRawData.content.zip || ""}`
                                .trim()
                                .replace(/, ,/g, ",")) || (
                              <Badge variant="destructive">
                                {FallbackMessages.NO_DATA}
                              </Badge>
                            )
                          )}
                        </Paragraph>
                        <Paragraph>
                          <span className="font-semibold">Country: </span>
                          {
                            // eslint-disable-next-line no-nested-ternary
                            imCustomerProfileIsPending ? (
                              <Skeleton className="w-1/2" />
                            ) : imCustomerProfileRawData &&
                              imCustomerProfileRawData.content.country ? (
                              <Badge className="uppercase">
                                {imCustomerProfileRawData.content.country}
                              </Badge>
                            ) : (
                              <Badge variant="destructive">
                                {FallbackMessages.NO_DATA}
                              </Badge>
                            )
                          }
                        </Paragraph>
                        <Paragraph>
                          <span className="font-semibold">Phone: </span>
                          {imCustomerProfileIsPending ? (
                            <Skeleton className="w-1/2" />
                          ) : imCustomerProfileRawData &&
                            imCustomerProfileRawData.content.phone ? (
                            imCustomerProfileRawData.content.phone
                          ) : (
                            <Badge variant="destructive">
                              {FallbackMessages.NO_DATA}
                            </Badge>
                          )}
                        </Paragraph>
                        <Paragraph>
                          <span className="font-semibold">Email: </span>
                          {imCustomerProfileIsPending ? (
                            <Skeleton className="w-1/2" />
                          ) : imCustomerProfileRawData &&
                            imCustomerProfileRawData.content.email ? (
                            imCustomerProfileRawData.content.email
                          ) : (
                            <Badge variant="destructive">
                              {FallbackMessages.NO_DATA}
                            </Badge>
                          )}
                        </Paragraph>
                      </div>
                    </CardContent>
                  </Card>
                  <Card className="col-span-1">
                    <CardHeader>
                      <Heading3>Statement Options: </Heading3>
                    </CardHeader>
                    <CardContent>
                      <div className="flex flex-col gap-y-2">
                        <Paragraph>
                          <span className="font-semibold">Bill: </span>
                          {imCustomerProfileIsPending ? (
                            <Skeleton className="w-1/2" />
                          ) : imCustomerProfileRawData &&
                            imCustomerProfileRawData.content.bill ? (
                            imCustomerProfileRawData.content.bill
                          ) : (
                            <Badge variant="destructive">
                              {FallbackMessages.NO_DATA}
                            </Badge>
                          )}
                        </Paragraph>
                      </div>
                    </CardContent>
                  </Card>
                  <Card className="col-span-1">
                    <CardHeader>
                      <Heading3>Card Controls</Heading3>
                    </CardHeader>
                    <CardContent>
                      <div className="flex flex-col gap-y-2">
                        <Paragraph>
                          <span className="font-semibold">MCC Template: </span>
                          {imCustomerProfileIsPending ? (
                            <Skeleton className="w-1/2" />
                          ) : imCustomerProfileRawData &&
                            imCustomerProfileRawData.content
                              .mccTemplateOptions ? (
                            imCustomerProfileRawData.content.mccTemplateOptions
                          ) : (
                            <Badge variant="destructive">
                              {FallbackMessages.NO_DATA}
                            </Badge>
                          )}
                        </Paragraph>
                        <Paragraph>
                          <span className="font-semibold">Options: </span>
                          {imCustomerProfileIsPending ? (
                            <Skeleton className="w-1/2" />
                          ) : imCustomerProfileRawData &&
                            imCustomerProfileRawData.content.options ? (
                            imCustomerProfileRawData.content.options
                          ) : (
                            <Badge variant="destructive">
                              {FallbackMessages.NO_DATA}
                            </Badge>
                          )}
                        </Paragraph>
                      </div>
                    </CardContent>
                  </Card>
                  <Card className="col-span-1 lg:col-span-4">
                    <CardHeader>
                      <Heading3>Technology</Heading3>
                    </CardHeader>
                    <CardContent>
                      <div className="grid grid-cols-1 gap-2 gap-y-2 lg:grid-cols-2">
                        <Paragraph>
                          <span className="font-semibold">
                            Reporting Tool:{" "}
                          </span>
                          {imCustomerProfileIsPending ? (
                            <Skeleton className="w-1/2" />
                          ) : imCustomerProfileRawData &&
                            imCustomerProfileRawData.content.reportingTool ? (
                            imCustomerProfileRawData.content.reportingTool
                          ) : (
                            <Badge variant="destructive">
                              {FallbackMessages.NO_DATA}
                            </Badge>
                          )}
                        </Paragraph>
                        <Paragraph>
                          <span className="font-semibold">
                            Organization ID:{" "}
                          </span>
                          {imCustomerProfileIsPending ? (
                            <Skeleton className="w-1/2" />
                          ) : imCustomerProfileRawData &&
                            imCustomerProfileRawData.content.organizationId ? (
                            imCustomerProfileRawData.content.organizationId
                          ) : (
                            <Badge variant="destructive">
                              {FallbackMessages.NO_DATA}
                            </Badge>
                          )}
                        </Paragraph>
                        <Paragraph>
                          <span className="font-semibold">
                            Account Number Masking:{" "}
                          </span>
                          {imCustomerProfileIsPending ? (
                            <Skeleton className="w-1/2" />
                          ) : imCustomerProfileRawData &&
                            imCustomerProfileRawData.content
                              .accountNumberMasking ? (
                            imCustomerProfileRawData.content
                              .accountNumberMasking
                          ) : (
                            <Badge variant="destructive">
                              {FallbackMessages.NO_DATA}
                            </Badge>
                          )}
                        </Paragraph>
                        <Paragraph>
                          <span className="font-semibold">
                            Response File Data Masking:{" "}
                          </span>
                          {imCustomerProfileIsPending ? (
                            <Skeleton className="w-1/2" />
                          ) : imCustomerProfileRawData &&
                            imCustomerProfileRawData.content
                              .responseFileDataMasking ? (
                            imCustomerProfileRawData.content
                              .responseFileDataMasking
                          ) : (
                            <Badge variant="destructive">
                              {FallbackMessages.NO_DATA}
                            </Badge>
                          )}
                        </Paragraph>
                        <Paragraph>
                          <span className="font-semibold">
                            Connectivity Method:{" "}
                          </span>
                          {imCustomerProfileIsPending ? (
                            <Skeleton className="w-1/2" />
                          ) : imCustomerProfileRawData &&
                            imCustomerProfileRawData.content
                              .connectivityMethod ? (
                            imCustomerProfileRawData.content.connectivityMethod
                          ) : (
                            <Badge variant="destructive">
                              {FallbackMessages.NO_DATA}
                            </Badge>
                          )}
                        </Paragraph>
                      </div>
                    </CardContent>
                  </Card>
                </div>
              </div>
            </ScrollArea>
          </DialogContent>
        </Dialog>
      </CardFooter>
    </Card>
  );
}
