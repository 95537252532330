import {z} from "zod";

export const proxyPayIMRemitLiteTableColumnsSchema = z.object({
  orgId: z.boolean(),
  remittanceMethod: z.boolean(),
  supplierName: z.boolean(),
  supplierNumber: z.boolean(),
  facilityName: z.boolean(),
  paymentNumber: z.boolean(),
  totalAmountSent: z.boolean(),
  postingDate: z.boolean(),
  userId: z.boolean(),
  description: z.boolean(),
  statusName: z.boolean(),
});
export type ProxyPayIMRemitLiteTableColumnsType = z.infer<
  typeof proxyPayIMRemitLiteTableColumnsSchema
>;
