// This file contains functions that are used in multiple places in the codebase.
// These functions are not specific to any module or component.

// Function to convert a string to a slug
export function stringToSlug(input: string): string {
  return input.toLowerCase().replace(/\s+/g, "-");
}

// Function to convert camelCase to Title Case
export function camelCaseToTitle(camelCase: string): string {
  // Create a set of prepositions to easily check if a word is a preposition.
  const prepositions = new Set([
    "of",
    "in",
    "on",
    "at",
    "for",
    "with",
    "to",
    "by",
    "from",
    "over",
    "under",
    "about",
    "as",
    "into",
    "like",
    "through",
    "after",
    "before",
    "around",
    "behind",
    "during",
    "under",
    "among",
  ]);

  return (
    camelCase
      // Insert a space before each uppercase letter to break up camelCased words.
      .replace(/([A-Z])/g, " $1")
      // Convert the entire string to lowercase.
      .toLowerCase()
      // Convert the first character of each word to uppercase.
      .replace(/(^[a-z]| [a-z])/g, (s) => s.toUpperCase())
      // Iterate over each word, if it's a preposition, ensure it's in lowercase.
      .replace(/\b(\w+)\b/g, (word) =>
        prepositions.has(word.toLowerCase()) ? word.toLowerCase() : word
      )
  );
}

/**
 * Parses a date string of the format "Fri Sep 01 2023 00:00:00 GMT-0400 (Eastern Daylight Time)"
 * and converts it into the format "2023-09-01".
 *
 * @param {string} inputDateString - The date string to be parsed.
 * @returns {string} - The parsed date in "YYYY-MM-DD" format.
 * @throws {Error} - Throws an error if the date string cannot be parsed.
 */
export function parseAndFormatDate(inputDateString: string): string {
  // Initialize a Date object with the input date string
  const dateObject = new Date(inputDateString);

  // Check if the date is valid
  if (isNaN(dateObject.getTime())) {
    throw new Error("Invalid date string");
  }

  // Extract year, month, and day
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObject.getDate().toString().padStart(2, "0");

  // Return formatted date
  return `${year.toString()}-${month.toString()}-${day.toString()}`;
}

/**
 * Creates a Date object from a date string formatted as "YYYY-MM-DD", specifically set to midnight.
 * This ensures that there are no time zone discrepancies causing the date to appear as the day before.
 * Setting the time to "00:00:00" mitigates issues with time zone conversions that might otherwise alter the date.
 *
 * @param {string|null|undefined} dateStr - The date string to convert into a Date object.
 *        If this is null or undefined, the function returns null.
 * @returns {Date|null} A Date object representing midnight of the provided date,
 *          or null if the input is null or undefined.
 */
export function createDateAtMidnight(
  dateStr: string | null | undefined
): Date | null {
  // Check if the input date string is null or undefined. If so, return null 🚫
  if (!dateStr) {
    return null; // Return null to indicate the absence of a valid date 📆
  }

  // Create and return a new Date object set to midnight using the "T00:00:00" suffix 🕛
  // This string manipulation sets the time explicitly to midnight to prevent any time zone issues 🌍
  return new Date(`${dateStr}T00:00:00`); // Ensures the date is at midnight, avoiding date roll-back ⏳
}

/**
 * Formats a given Date object into a human-readable string considering the Eastern Standard Time (EST) timezone.
 *
 * This function uses the JavaScript `toLocaleString` method to ensure the date and time are represented
 * in a manner consistent with the EST timezone. The date is formatted in the style "Month DD, YYYY",
 * and if `includeTime` is true, the time is formatted as "HH:MM:SS" using a 24-hour clock.
 *
 * @param {Date} date - The date to format. If the date is null or undefined, a fallback string is returned.
 * @param {boolean} [includeTime=false] - Flag to determine whether to include time in the output.
 * @returns {string} The formatted date string. If `includeTime` is true, the output includes the time.
 */
export function formatDate(
  date: Date | null | undefined,
  includeTime: boolean = false
): string {
  // Return a fallback message if the date is null or undefined 🛑
  if (!date) {
    return "No date available"; // Informative error message 🚫
  }

  // Define options for date formatting with timezone set to EST 🌍
  const options: Intl.DateTimeFormatOptions = {
    timeZone: "America/New_York", // Set the timezone to EST 🕖
    year: "numeric",
    month: "long",
    day: "2-digit",
    ...(includeTime && {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true, // 12-hour clock format ✔️
    }), // Conditional time inclusion ⏱️
  };

  // Format and return the date using the specified locale and options 📆
  return date.toLocaleString("en-US", options); // Using 'en-US' for English month names 🌐
}

export function SimpleFormatDate(dateString: string): string {
  const dateObject = new Date(dateString);
  const date = dateObject.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });
  return date;
}
/**
 * Replaces or adds the 'page' query parameter in the given query string.
 *
 * NOTE: This should be temporary until we have a better solution from tanstack
 *
 * @param {string} query - The query string in which to replace or add the 'page' value.
 * @param {string} newPageValue - The new value for the 'page' query parameter.
 *
 * @returns {string} - The updated query string containing the new 'page' value.
 *
 * @example
 * const oldQuery = "?page=2&sort=asc";
 * const newPageValue = "3";
 * const newQuery = replacePageQueryParam(oldQuery, newPageValue);
 * console.log(newQuery);  // Output: "?page=3&sort=asc"
 *
 * @throws {TypeError} - Throws a TypeError if the arguments are not of type string.
 */
export function replacePageQueryParam(
  query: string,
  newPageValue: string
): string {
  // Parsing the existing query string to an URLSearchParams object
  const urlParams = new URLSearchParams(query);

  // Checking if the 'page' parameter exists
  if (urlParams.has("page")) {
    // Replacing the value of 'page' parameter with newPageValue
    urlParams.set("page", newPageValue);
  } else {
    // Adding 'page' parameter if it doesn't exist
    urlParams.append("page", newPageValue);
  }

  // Returning the updated query string
  return urlParams.toString();
}

/**
 * Calculates a date range starting from a specified number of days in the past until today.
 *
 * This function returns an object containing two properties: `startDate` and `endDate`.
 * `startDate` is a Date object representing a past date, which is 'days' number of days before
 * the current date. `endDate` is a Date object representing the current date at the time of
 * function execution.
 *
 * Validations:
 * - If the 'days' parameter is not a number, a TypeError is thrown.
 * - If the 'days' parameter is not a positive integer, a RangeError is thrown.
 *
 * @param {number} days - The number of days in the past from today to calculate the start of the range.
 *                         Must be a positive integer.
 * @returns {{ startDate: Date, endDate: Date }} An object with two properties: `startDate` and `endDate`.
 *                   `startDate` is the calculated past date, and `endDate` is the current date.
 *                   The time component of these dates is set to the time of the function's execution.
 * @throws {TypeError} If 'days' is not a number.
 * @throws {RangeError} If 'days' is not a positive integer.
 */
export function getPastDateRange(days: number) {
  if (typeof days !== "number") {
    throw new TypeError('The "days" parameter must be a number.');
  }

  if (!Number.isInteger(days) || days < 0) {
    throw new RangeError('The "days" parameter must be a positive integer.');
  }

  const today = new Date();
  const pastDate = new Date(today);
  pastDate.setDate(pastDate.getDate() - days);
  return {startDate: pastDate, endDate: today};
}

/**
 * Formats a number into a currency string based on the given currency code and locale.
 *
 * @param {number} amount - The amount of money to format.
 * @param {string} [currency='USD'] - The ISO 4217 currency code (e.g., 'USD', 'EUR'). Defaults to 'USD'.
 * @param {string} [locale='en-US'] - The BCP 47 language tag for the locale format (e.g., 'en-US', 'de-DE'). Defaults to 'en-US'.
 * @returns {string} A string formatted in the specified currency and locale.
 *
 */
export function formatCurrency(
  amount: number,
  currency: string = "USD",
  locale: string = "en-US"
): string {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(amount);
}
