import {type ErrorMapCtx, type ZodIssue} from "zod";
import {create} from "zustand";

import {globalErrorMap} from "@/lib/zod";

// Define the store interface with error code support
interface ErrorStore {
  getErrorMessage: (
    issue: ZodIssue,
    ctx?: ErrorMapCtx
  ) => {message: string; code: string}; // Explicitly expect an object with `message` and `code`
}

// Create the Zustand store
export const useErrorStore = create<ErrorStore>(() => ({
  getErrorMessage: (issue, ctx) => {
    // Leverage the global error map to fetch both message and code
    const result = globalErrorMap(
      issue,
      ctx ?? {defaultError: "An error occurred.", data: {}}
    );

    // Explicitly cast the result to ensure it includes `message` and `code`
    const {message, code} = result as {message: string; code: string};

    return {message, code}; // Return both message and code
  },
}));
