import {z} from "zod";

// Export supplier data
export const ExportSupplierDataFormSchema = z.object({
  emailId: z.string(),
  subject: z.string().min(1).max(140),
  message: z.string().min(1),
  customerName: z.string().nullable().optional(),
  participant: z.string(),
  supplierId: z.string(),
  columnList: z.array(z.string()).nullable().optional(),
  columns: z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
      })
    )
    .optional(),
});

export type ExportSupplierDataFormDTO = z.infer<
  typeof ExportSupplierDataFormSchema
>;
