import {useMemo} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {MessageSquarePlusIcon, MessagesSquareIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {Label} from "@/components/ui/label";
import {Spinner} from "@/components/ui/spinner";
import {Textarea} from "@/components/ui/textarea";
import {Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {LoadingSection} from "@/components/craft/loading-section";

import {calculateUserRoles, useKeyCloakInstanceStore} from "@/modules/auth";
import {
  saveSupplierCommentFn,
  supplierCommentsColumns,
  SupplierCommentsDataTable,
  SupplierCommentsFormSchema,
  useSupplierManagementComments,
} from "@/modules/imremit";

export function CommentsForm({id}: {id: string}) {
  // 🔑 Retrieve Keycloak instance for user authentication
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";
  // 🆕 Use custom hook to get supplier comments data
  const {supplierManagementCommentsData, isPending, isSuccess, isError} =
    useSupplierCommentsData(id);

  // 📝 Initialize form methods for adding comments
  const supplierCommentsFormMethods = useForm<
    z.infer<typeof SupplierCommentsFormSchema>
  >({
    defaultValues: {
      userId: storeKeyCloakInstance?.tokenParsed?.name as string,
      role: userRoleDisplay,
    },
    resolver: zodResolver(SupplierCommentsFormSchema),
  });

  // 💾 Mutation to save a new supplier comment
  const saveSupplierCommentMutation = useMutation({
    mutationFn: (data: z.infer<typeof SupplierCommentsFormSchema>) =>
      saveSupplierCommentFn(data, id),
    onSuccess: (response) => {
      // ✅ Reset form on successful submission
      supplierCommentsFormMethods.reset();
      console.log("saveSupplierCommentResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Comment added successfully",
      });
    },
    onError: (error) => {
      // 🚨 Handle errors and display a toast message
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add comment",
      });
    },
  });

  // 📨 Handler for form submission
  const onSupplierCommentSubmit = (
    data: z.infer<typeof SupplierCommentsFormSchema>
  ) => {
    const {comments, ...rest} = data;

    const processedData = {
      ...rest,
      comments: comments.trim(),
    };
    saveSupplierCommentMutation.mutate(processedData);
  };

  // 🔄 Check if mutation is in progress
  const {isPending: isMutating} = saveSupplierCommentMutation;

  return (
    <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
      <Dialog>
        <DialogTrigger asChild>
          <Button className="gap-2" variant="outline" disabled={isPending}>
            <span className="sr-only">Comments</span>
            {isPending ? (
              <LoadingSection sectionSize="xs" />
            ) : (
              <MessagesSquareIcon className="size-4" />
            )}
            <Paragraph>Comments</Paragraph>
          </Button>
        </DialogTrigger>
        <DialogContent className="overflow-scroll sm:min-w-[640px] md:min-w-[768px] lg:min-w-[1024px] xl:min-w-[1280px] 2xl:min-w-[1378px]">
          <DialogHeader>
            <DialogTitle>Supplier Comments</DialogTitle>
          </DialogHeader>
          {/* 📝 Comment Form */}
          <Form {...supplierCommentsFormMethods}>
            <form
              className="grid w-full gap-1.5"
              onSubmit={supplierCommentsFormMethods.handleSubmit(
                onSupplierCommentSubmit
              )}
            >
              <Label htmlFor="comments">Add a comment:</Label>
              <FormField
                name="comments"
                render={() => (
                  <FormItem>
                    <FormControl>
                      <Textarea
                        id="comments"
                        placeholder="Type your comment here."
                        disabled={isMutating}
                        aria-disabled={isMutating}
                        autoCapitalize="none"
                        autoComplete="comments"
                        autoCorrect="off"
                        {...supplierCommentsFormMethods.register("comments")}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button
                type="submit"
                disabled={isMutating}
                aria-disabled={isMutating}
                variant="default"
                size="xs"
                className="gap-2"
              >
                {isMutating ? (
                  <Spinner size="xs" />
                ) : (
                  <MessageSquarePlusIcon className="size-4" />
                )}
                Add comment
              </Button>
            </form>
          </Form>
          {/* 📃 Display the comments data table */}
          <SupplierCommentsDataTable
            columns={supplierCommentsColumns}
            data={supplierManagementCommentsData}
            isPending={isPending}
            isSuccess={isSuccess}
            isError={isError}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}

// 🆕 Custom hook to fetch and process supplier comments data
function useSupplierCommentsData(id: string) {
  // 📥 Fetch supplier comments data
  const supplierManagementCommentsQuery = useSupplierManagementComments(id);

  // 📊 Sort comments by date in descending order
  const supplierManagementCommentsData = useMemo(() => {
    const comments = supplierManagementCommentsQuery.data?.content ?? [];
    return comments.sort(
      (a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
    );
  }, [supplierManagementCommentsQuery.data?.content]);

  // ⏳ Extract loading and status indicators
  const {isPending, isSuccess, isError} = supplierManagementCommentsQuery;

  return {
    supplierManagementCommentsData,
    isPending,
    isSuccess,
    isError,
  };
}
