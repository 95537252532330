import {
  bankFileColumns,
  BankFileDataTable,
  useBankFileDetails,
  type BankAuditMappingFileSpecsType,
} from "@/modules/imremit";

/**
 * BankFileList Component
 *
 * This component provides the report for Bank File Mapping
 * and rendering the Bank File Mapping  report table.
 */
export function BankFileList() {
  // Fetch the Bank File Mapping data using the custom hook `useBankFileDetails`
  const searchBankFileResponse = useBankFileDetails();

  // Destructure the `data` property, focusing on its `content` attribute
  const bankFileData = searchBankFileResponse.data?.content || [];

  /**
   * `bankFileAllData` array is holding all the `fileSpecs[]` data in single array- not a good approch
   * This is temporary basis, will update in future
   * */
  const bankFileAllData: BankAuditMappingFileSpecsType[] = [];
  bankFileData.map((data) => {
    if (!data.fileSpecs.length) {
      // This was a hotfix, it really shouldn't be like this
      bankFileAllData.push({masterMappingName: data.masterMappingName});
    } else {
      data.fileSpecs.map((fileSpecsData) => {
        fileSpecsData.masterMappingName = data.masterMappingName;
        bankFileAllData.push(fileSpecsData);
      });
    }
  });

  // Render the bank file mapping report table when the data available
  return (
    <BankFileDataTable
      columns={bankFileColumns}
      data={bankFileAllData}
      isPending={searchBankFileResponse.isPending}
      isSuccess={searchBankFileResponse.isSuccess}
      isError={searchBankFileResponse.isError}
    />
  );
}
