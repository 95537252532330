import {create} from "zustand";

import {type PaginationMetaType} from "@/lib/ky";
import {type InvoicesCriteriaType} from "@/modules/invoices";

import {type SortMetaType} from "@/utils/query-params-generator";

/**
 * Interface: InvoicesMetaStoreState
 * Defines the shape of the state for the InvoicesPaginationMetaStore
 */
interface InvoicesMetaStoreState {
  /**
   * storeInvoicesPaginationMeta
   * Holds the pagination metadata related to invoices.
   */
  storeInvoicesPaginationMeta: PaginationMetaType | null;

  /**
   * storeInvoicesCriteria
   * Holds the criteria by which invoices are filtered.
   */
  storeInvoicesCriteria: InvoicesCriteriaType | null;

  /**
   * storeInvoicesSortMeta
   * Holds the sort metadata related to invoices.
   */
  storeInvoicesSortMeta: SortMetaType | null;

  /**
   * handleOnPageChange
   * Holds the function to handle page change.
   * This is used to update the page number in the store.
   * This is used in the InvoicesDataTable component.
   *
   * @param pageNumber - The new page number
   * @returns void
   *
   * @example
   */
  handleOnPageChange?: (pageNumber: number) => Promise<void>;
}

/**
 * Interface: InvoicesMetaStoreActions
 * Defines the set of actions for updating the InvoicesMetaStoreState.
 */
interface InvoicesMetaStoreActions {
  /**
   * Action: setStoreInvoicesPaginationMeta
   * Updates the 'storeInvoicesPaginationMeta' field in the state.
   *
   * @param storeInvoicesPaginationMeta - The new value for 'storeInvoicesPaginationMeta'
   */
  setStoreInvoicesPaginationMeta: (
    storeInvoicesPaginationMeta: InvoicesMetaStoreState["storeInvoicesPaginationMeta"]
  ) => void;

  /**
   * Action: setStoreInvoicesCriteria
   * Updates the 'storeInvoicesCriteria' field in the state.
   *
   * @param storeInvoicesCriteria - The new value for 'storeInvoicesCriteria'
   */
  setStoreInvoicesCriteria: (
    storeInvoicesCriteria: InvoicesMetaStoreState["storeInvoicesCriteria"]
  ) => void;

  /**
   * Action: setStoreInvoicesSortMeta
   * Updates the 'storeInvoicesSortMeta' field in the state.
   *
   * @param storeInvoicesSortMeta - The new value for 'storeInvoicesSortMeta'
   */
  setStoreInvoicesSortMeta: (
    storeInvoicesSortMeta: InvoicesMetaStoreState["storeInvoicesSortMeta"]
  ) => void;

  /**
   * Action: setHandleOnPageChange
   * Updates the 'handleOnPageChange' field in the state.
   *
   * @param handleOnPageChange - The new value for 'handleOnPageChange'
   */
  setHandleOnPageChange: (
    handleOnPageChange: (pageNumber: number) => Promise<void>
  ) => void;
}

/**
 * Function: useInvoicesMetaStore
 * Creates a Zustand store to handle invoice pagination metadata and criteria.
 */
export const useInvoicesMetaStore = create<
  InvoicesMetaStoreState & InvoicesMetaStoreActions
>((set) => ({
  // Initialize 'storeInvoicesPaginationMeta', 'storeInvoicesCriteria' and 'handleOnPageChange'
  // with default values of null
  storeInvoicesPaginationMeta: null,
  storeInvoicesCriteria: null,
  storeInvoicesSortMeta: null,
  handleOnPageChange: undefined,

  /**
   * Implementation for 'setStoreInvoicesPaginationMeta' action.
   * Updates the 'storeInvoicesPaginationMeta' field in the state.
   *
   * @param storeInvoicesPaginationMeta - The new value for 'storeInvoicesPaginationMeta'
   */
  setStoreInvoicesPaginationMeta: (storeInvoicesPaginationMeta) => {
    set(() => ({storeInvoicesPaginationMeta}));
  },

  /**
   * Implementation for 'setStoreInvoicesCriteria' action.
   * Updates the 'storeInvoicesCriteria' field in the state.
   *
   * @param storeInvoicesCriteria - The new value for 'storeInvoicesCriteria'
   */
  setStoreInvoicesCriteria: (storeInvoicesCriteria) => {
    set(() => ({storeInvoicesCriteria}));
  },

  /**
   * Implementation for 'setStoreInvoicesSortMeta' action.
   * Updates the 'storeInvoicesSortMeta' field in the state.
   *
   * @param storeInvoicesSortMeta - The new value for 'storeInvoicesSortMeta'
   */
  setStoreInvoicesSortMeta: (storeInvoicesSortMeta) => {
    set(() => ({storeInvoicesSortMeta}));
  },

  /**
   * Implementation for 'setHandleOnPageChange' action.
   * Updates the 'handleOnPageChange' field in the state.
   *
   * @param handleOnPageChange - The new value for 'handleOnPageChange'
   */
  setHandleOnPageChange: (handleOnPageChange) => {
    set(() => ({handleOnPageChange}));
  },
}));
