import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  suppliersDetailsKeys,
  type SupplierDetailsType,
} from "@/modules/imremit";

// Defining a type based on the function `getSuppliersList`
type QueryFnType = typeof getSuppliersList;

// Function to search getSuppliersList
export function getSuppliersList(externalId?: string) {
  return kyApiFn<SupplierDetailsType[]>(
    `api/e-payable/management/${externalId}/supplier-details`,
    "get"
  );
}

// Custom hook to fetch and cache suppliers list based on the `participant id` data using react-query
export function useGetSuppliersList(
  externalId?: string, // The search query string
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: suppliersDetailsKeys.byId(externalId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getSuppliersList(externalId), // Setting the function to be used for fetching data
    enabled: !!externalId, // Setting the query to be enabled only if the search query string is not empty
  });
}
