// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

import {type SRSettingsTableColumnsType} from "@/modules/statement-recon";

interface SRSettingsTableColumnsStoreState {
  storeSRSettingsTableColumns: SRSettingsTableColumnsType | null;
  setStoreSRSettingsTableColumns: (
    storeSRSettingsTableColumns: SRSettingsTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for SRSettings table columns
export const useSRSettingsTableColumnsStore = create(
  persist<SRSettingsTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeSRSettingsTableColumns: null,

      // Getter function to retrieve the current state
      getStoreSRSettingsTableColumns: () => get().storeSRSettingsTableColumns,

      // Setter function to update the state
      setStoreSRSettingsTableColumns: (storeSRSettingsTableColumns) => {
        set(() => ({storeSRSettingsTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "SRSettings-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useSRSettingsTableColumns = () =>
  useSRSettingsTableColumnsStore((state) => {
    return {
      storeSRSettingsTableColumns: state.storeSRSettingsTableColumns,
    };
  });
