import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import type {RunTypeType} from "@/lib/stores/dupes-run-type-store";
import type {
  DupesComparisonCountsType,
  DupesComparisonTypeEnum,
} from "@/modules/duplicate-payments";
import {dupesComparisonCountsKeys} from "@/modules/duplicate-payments";

type ComparisonCountsQueryFnType = typeof getAllComparisonCountsByExternalId;
type ComparisonAmountsQueryFnType =
  typeof getComparisonAmountsByExternalIdAndComparisonType;

// Defining a function to fetch data from API
export function getAllComparisonCountsByExternalId(
  externalId?: string,
  dupesRunType?: RunTypeType,
  limitToLast90Days?: boolean
) {
  // Fetch data from API using ky
  return kyApiFn<DupesComparisonCountsType>(
    `duplicate-payments/v1/dashboard/${
      externalId ? externalId : ""
    }/counts?run_type=${
      dupesRunType ? dupesRunType : ""
    }&limit_to_last_90_days=${limitToLast90Days ? "true" : "false"}`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useAllDupesInitialInvoiceComparisons(
  externalId?: string,
  dupesRunType?: RunTypeType,
  limitToLast90Days?: boolean,
  config?: QueryConfig<ComparisonCountsQueryFnType>
) {
  return useQuery<ExtractFnReturnType<ComparisonCountsQueryFnType>>({
    ...config,
    queryKey: dupesComparisonCountsKeys.countsByExternalIdRunType(
      externalId,
      dupesRunType,
      limitToLast90Days
    ),
    queryFn: () =>
      getAllComparisonCountsByExternalId(
        externalId,
        dupesRunType,
        limitToLast90Days
      ),
    enabled: !!externalId && !!dupesRunType,
  });
}

export function getComparisonAmountsByExternalIdAndComparisonType(
  comparisonType: DupesComparisonTypeEnum,
  externalId?: string,
  dupesRunType?: RunTypeType,
  limitToLast90Days?: boolean
) {
  return kyApiFn<number>(
    `duplicate-payments/v1/dashboard/${
      externalId ? externalId : ""
    }/amount?comparison_type=${comparisonType}&run_type=${
      dupesRunType ? dupesRunType : ""
    }&limit_to_last_90_days=${limitToLast90Days ? "true" : "false"}`,
    "get"
  );
}

export function useComparisonAmountsByExternalIdAndComparisonType(
  comparisonType: DupesComparisonTypeEnum,
  externalId?: string,
  dupesRunType?: RunTypeType,
  limitToLast90Days?: boolean,
  config?: QueryConfig<ComparisonAmountsQueryFnType>
) {
  return useQuery<ExtractFnReturnType<ComparisonAmountsQueryFnType>>({
    ...config,
    queryKey: [
      dupesComparisonCountsKeys.amountsByExternalIdAndComparisonType(
        comparisonType,
        externalId,
        dupesRunType,
        limitToLast90Days
      ),
      comparisonType,
    ],
    queryFn: () =>
      getComparisonAmountsByExternalIdAndComparisonType(
        comparisonType,
        externalId,
        dupesRunType,
        limitToLast90Days
      ),
    enabled: !!externalId,
  });
}
