// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type ProxyPayIMRemitLiteTableColumnsType} from "@/modules/imremit-lite";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface ProxyPayIMRemitLiteTableColumnsStoreState {
  storeProxyPayIMRemitLiteTableColumns: ProxyPayIMRemitLiteTableColumnsType | null;
  setStoreProxyPayIMRemitLiteTableColumns: (
    storeProxyPayIMRemitLiteTableColumns: ProxyPayIMRemitLiteTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for ProxyPayIMRemitLite table columns
export const useProxyPayIMRemitLiteTableColumnsStore = create(
  persist<ProxyPayIMRemitLiteTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeProxyPayIMRemitLiteTableColumns: null,

      // Getter function to retrieve the current state
      getStoreProxyPayIMRemitLiteTableColumns: () =>
        get().storeProxyPayIMRemitLiteTableColumns,

      // Setter function to update the state
      setStoreProxyPayIMRemitLiteTableColumns: (
        storeProxyPayIMRemitLiteTableColumns
      ) => {
        set(() => ({storeProxyPayIMRemitLiteTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "ProxyPayIMRemitLite-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useProxyPayIMRemitLiteTableColumns = () =>
  useProxyPayIMRemitLiteTableColumnsStore((state) => {
    return {
      storeProxyPayIMRemitLiteTableColumns:
        state.storeProxyPayIMRemitLiteTableColumns,
    };
  });
