import {createRoute} from "@tanstack/react-router";

import {RoutesDirectory} from "@/lib/routes/directory";
import {
  AddSupplierScriptLitePage,
  EditSupplierScriptLitePage,
  imREmitLiteRootRoute,
  SupplierScriptManagementPage,
  ViewSupplierScriptLitePage,
} from "@/modules/imremit-lite";

export const imREmitLiteSupplierScriptRoute = createRoute({
  path: RoutesDirectory.SUPPLIER_SCRIPT_MANAGEMENT,
  component: SupplierScriptManagementPage,
  getParentRoute: () => imREmitLiteRootRoute,
});

export const imREmitLiteAddSupplierScriptRoute = createRoute({
  path: `${RoutesDirectory.SUPPLIER_SCRIPT_MANAGEMENT}/$moduleName/$supplierId${RoutesDirectory.CREATE}/$paymentId`,
  component: AddSupplierScriptLitePage,
  getParentRoute: () => imREmitLiteRootRoute,
});

export const imREmitLiteEditSupplierScriptRoute = createRoute({
  path: `${RoutesDirectory.SUPPLIER_SCRIPT_MANAGEMENT}/$moduleName/$supplierId${RoutesDirectory.EDIT}/$paymentId`,
  component: EditSupplierScriptLitePage,
  getParentRoute: () => imREmitLiteRootRoute,
});

export const imREmitLiteViewSupplierScriptRoute = createRoute({
  path: `${RoutesDirectory.SUPPLIER_SCRIPT_MANAGEMENT}/$moduleName/$supplierId${RoutesDirectory.VIEW}/$paymentId`,
  component: ViewSupplierScriptLitePage,
  getParentRoute: () => imREmitLiteRootRoute,
});
