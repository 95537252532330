import {z} from "zod";

export const paymentManagementAuthorizationsSchema = z.object({
  authorizationDetailId: z.string().nullable().optional(),
  authorizationDateTime: z.string().nullable().optional(),
  authorizationStatus: z.string().nullable().optional(),
  merchantName: z.string().nullable().optional(),
  merchantCategoryCode: z.number().nullable().optional(),
  authorizationAmount: z.number().nullable().optional(),
  declineReason: z.string().nullable().optional(),
});

export type paymentManagementAuthorizations = z.infer<
  typeof paymentManagementAuthorizationsSchema
>;
