import {useEffect, useMemo, useState} from "react";

import {useFormContext} from "react-hook-form";

import {Card, CardContent, CardHeader, CardTitle} from "@/components/ui/card";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {FancyMultiSelect} from "@/components/craft/fancy-multi-select";
import {InfiniteMultiSelect} from "@/components/craft/infinite-multi-select";

import {
  type MultiSelectDatumType,
  type PaymentFilterFormType,
} from "@/modules/admin";
import {useSelectInfiniteCustomers} from "@/modules/customers";

// Move status options outside the component
const imREmitStatuses = [
  {value: "Paid - Pending Verification", label: "Paid - Pending Verification"},
  {value: "Delivered", label: "Delivered"},
  {value: "Closed", label: "Closed"},
  {value: "Failed", label: "Failed"},
  {value: "Declined", label: "Declined"},
  {value: "Error", label: "Error"},
  {value: "In Progress", label: "In Progress"},
  {value: "Open", label: "Open"},
];

const imREmitLiteStatuses = [
  {value: "Open", label: "Open"},
  {value: "In Progress", label: "In Progress"},
  {value: "Payment Already Taken", label: "Payment Already Taken"},
  {value: "Invoice Amount Mismatch", label: "Invoice Amount Mismatch"},
  {value: "Escalation With Bank", label: "Escalation With Bank"},
  {value: "Failed", label: "Failed"},
  {value: "Declined", label: "Declined"},
  {value: "Closed", label: "Closed"},
  {value: "Error", label: "Error"},
  {value: "Invoice Number Mismatch", label: "Invoice Number Mismatch"},
];

// Add this mapping near the top of the file, after the existing const declarations
const quarterToMonths = {
  Q1: [
    {value: "Jan", label: "Jan"},
    {value: "Feb", label: "Feb"},
    {value: "Mar", label: "Mar"},
  ],
  Q2: [
    {value: "Apr", label: "Apr"},
    {value: "May", label: "May"},
    {value: "Jun", label: "Jun"},
  ],
  Q3: [
    {value: "Jul", label: "Jul"},
    {value: "Aug", label: "Aug"},
    {value: "Sep", label: "Sep"},
  ],
  Q4: [
    {value: "Oct", label: "Oct"},
    {value: "Nov", label: "Nov"},
    {value: "Dec", label: "Dec"},
  ],
};

export function MISPaymentsReceivedForm({
  showStatus = true,
}: {
  showStatus?: boolean;
}) {
  const {control, setValue, watch} = useFormContext<PaymentFilterFormType>();

  // Watch the 'module' field to determine selected module
  const selectedModule = watch("module");

  // Compute the status options based on the selected module
  const statusOptions = useMemo(() => {
    if (selectedModule === "imREmit") {
      return imREmitStatuses;
    } else if (selectedModule === "imREmit_lite") {
      return imREmitLiteStatuses;
    } else {
      // 'both' or undefined, combine all statuses and remove duplicates
      const allStatusesMap = new Map<string, string>();
      [...imREmitStatuses, ...imREmitLiteStatuses].forEach((status) => {
        allStatusesMap.set(status.value, status.label);
      });
      return Array.from(allStatusesMap.entries()).map(([value, label]) => ({
        value,
        label,
      }));
    }
  }, [selectedModule]);

  const quarterValue = watch("quarter");
  const selectedQuarters = useMemo(() => quarterValue || [], [quarterValue]);

  // Create year options based on quarter selection
  const yearOptions = useMemo(() => {
    const options = ["previous", "current"];
    if (selectedQuarters.length === 1) {
      options.push("both");
    }
    return options;
  }, [selectedQuarters]);

  // Add this to watch the year value
  const selectedYear = watch("year");

  // Add this to determine if fields should be disabled
  const shouldDisableFields = selectedYear === "both";

  // Add this to compute available months based on selected quarters
  const availableMonths = useMemo(() => {
    if (!quarterValue?.length) {
      return [
        {value: "Jan", label: "Jan"},
        {value: "Feb", label: "Feb"},
        {value: "Mar", label: "Mar"},
        {value: "Apr", label: "Apr"},
        {value: "May", label: "May"},
        {value: "Jun", label: "Jun"},
        {value: "Jul", label: "Jul"},
        {value: "Aug", label: "Aug"},
        {value: "Sep", label: "Sep"},
        {value: "Oct", label: "Oct"},
        {value: "Nov", label: "Nov"},
        {value: "Dec", label: "Dec"},
      ];
    }

    return quarterValue
      .flatMap(
        (quarter) =>
          quarterToMonths[quarter.value as keyof typeof quarterToMonths]
      )
      .sort((a, b) => {
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ];
        return months.indexOf(a.value) - months.indexOf(b.value);
      });
  }, [quarterValue]);

  // Add this effect to reset months when quarters change
  useEffect(() => {
    const currentMonths = watch("month") || [];
    const availableMonthValues = availableMonths.map((month) => month.value);

    // Filter out months that aren't in the available months
    const validMonths = currentMonths.filter((month) =>
      availableMonthValues.includes(month.value)
    );

    // If the filtered array is different from the current months, update the form
    if (validMonths.length !== currentMonths.length) {
      setValue("month", validMonths);
    }
  }, [quarterValue, availableMonths, setValue, watch]);

  // Add these new states and queries
  const [customerSearch, setCustomerSearch] = useState("");
  const {
    data: infiniteCustomersData,
    fetchNextPage: fetchNextCustomers,
    hasNextPage: hasNextCustomersPage,
    isFetching: isLoadingCustomers,
    isFetchingNextPage: isFetchingNextCustomers,
  } = useSelectInfiniteCustomers(customerSearch);

  // Transform infinite data into options
  const customerOptions = useMemo(() => {
    if (!infiniteCustomersData?.pages) return [];

    return infiniteCustomersData.pages.reduce<
      Array<{label: string; value: string}>
    >((acc, page) => {
      const pageCustomers = page.content.map((customer) => ({
        label: customer.buyerName,
        value: customer.externalId.toString(),
      }));
      return [...acc, ...pageCustomers];
    }, []);
  }, [infiniteCustomersData?.pages]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>MIS Filter Settings</CardTitle>
      </CardHeader>
      <CardContent>
        <form className="flex flex-col justify-center gap-4">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <FormField
              control={control}
              name="year"
              render={({field}) => (
                <FormItem className="mt-2 flex flex-col">
                  <FormLabel htmlFor="year">Year:</FormLabel>
                  <Select
                    value={field.value}
                    onValueChange={(value) => {
                      // If switching away from "both" and it's no longer a valid option, default to "current"
                      if (value === "both" && selectedQuarters.length !== 1) {
                        field.onChange("current");
                      } else {
                        field.onChange(value);
                      }
                    }}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select Year..." />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {yearOptions.map((year) => (
                        <SelectItem
                          key={year}
                          value={year}
                          onSelect={() => {
                            setValue(
                              "year",
                              year as "previous" | "current" | "both"
                            );
                          }}
                        >
                          {year}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>

                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Module Field - Add disabled prop */}
            <FormField
              control={control}
              name="module"
              render={({field}) => (
                <FormItem className="mt-2 flex flex-col">
                  <FormLabel htmlFor="module">Module:</FormLabel>
                  <Select
                    value={field.value}
                    disabled={shouldDisableFields}
                    onValueChange={field.onChange}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select module..." />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {["imREmit", "imREmit_lite", "both"].map((module) => (
                        <SelectItem
                          key={module}
                          value={module}
                          onSelect={() => {
                            setValue(
                              "module",
                              module as "imREmit" | "imREmit_lite" | "both"
                            );
                            // Optionally reset status field when module changes
                            setValue("status", []);
                          }}
                        >
                          {module}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>

                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
            {/* Customers Field */}
            <FormField
              name="customers"
              render={({field}) => (
                <FormItem className="flex flex-col">
                  <FormLabel htmlFor="customers" showMandatoryAsterisk>
                    Customers:
                  </FormLabel>
                  <FormControl>
                    <InfiniteMultiSelect
                      {...field}
                      options={customerOptions}
                      placeholder="Select customers..."
                      searchPlaceholder="Search customers..."
                      isLoading={isLoadingCustomers}
                      isFetchingNextPage={isFetchingNextCustomers}
                      hasNextPage={hasNextCustomersPage}
                      disabled={shouldDisableFields}
                      onSearch={setCustomerSearch}
                      onLoadMore={() => void fetchNextCustomers()}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Month Field */}
            <FormField
              name="month"
              render={({field}) => (
                <FormItem className="flex flex-col">
                  <FormLabel htmlFor="month">Month:</FormLabel>
                  <FormControl>
                    <FancyMultiSelect
                      {...field}
                      name="month"
                      value={field.value as MultiSelectDatumType[]}
                      multiSelectData={availableMonths}
                      disabled={shouldDisableFields}
                      onChange={field.onChange}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Status Field - Only render if showStatus is true */}
            {showStatus && (
              <FormField
                name="status"
                render={({field}) => (
                  <FormItem className="flex flex-col">
                    <FormLabel htmlFor="status">Payment Status:</FormLabel>
                    <FormControl>
                      <FancyMultiSelect
                        {...field}
                        name="status"
                        value={field.value as MultiSelectDatumType[]}
                        multiSelectData={statusOptions}
                        disabled={shouldDisableFields}
                        onChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            {/* Quarter Field */}
            <FormField
              name="quarter"
              render={({field}) => (
                <FormItem className="flex flex-col">
                  <FormLabel htmlFor="quarter">Quarter:</FormLabel>
                  <FormControl>
                    <FancyMultiSelect
                      {...field}
                      name="quarter"
                      value={field.value as MultiSelectDatumType[]}
                      multiSelectData={[
                        {value: "Q1", label: "Q1"},
                        {value: "Q2", label: "Q2"},
                        {value: "Q3", label: "Q3"},
                        {value: "Q4", label: "Q4"},
                      ]}
                      disabled={
                        shouldDisableFields ||
                        (watch("year") === "both" &&
                          (field.value as MultiSelectDatumType[]).length === 1)
                      }
                      onChange={(newValue) => {
                        // If year is "both", only allow one quarter
                        const selectedYear = watch("year");
                        if (selectedYear === "both" && newValue.length > 1) {
                          // Keep only the most recently added quarter
                          field.onChange([newValue[newValue.length - 1]]);
                        } else {
                          field.onChange(newValue);
                        }
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </form>
      </CardContent>
    </Card>
  );
}
