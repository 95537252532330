import {Link, useParams} from "@tanstack/react-router";
import {ArrowRightIcon} from "lucide-react";

import {buttonVariants} from "@/components/ui/button";
import {Card, CardContent, CardHeader} from "@/components/ui/card";
import {Separator} from "@/components/ui/separator";
import {Skeleton} from "@/components/ui/skeleton";
import {Heading2, Heading3, Paragraph} from "@/components/ui/typography";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {cn} from "@/lib/utils";
import {useAllRequiredAction, type KeycloakUserType} from "@/modules/admin";
import {useGetUserByIdGroup} from "@/modules/admin/api/keycloak-api/get-user-by-id";
import {KeycloakRoleEnum, useAuthorization} from "@/modules/auth";

import {formatDate} from "@/utils/functions";

export function ViewUserPage() {
  const {userId} = useParams({
    from: "/app/admin/user-management/$userId/view",
  });

  const searchUserQuery = useGetUserByIdGroup(userId);
  const {data: searchUserData, isPending} = searchUserQuery;

  const {checkPoliciesAccess} = useAuthorization();
  const authorizedStatus = checkPoliciesAccess([
    KeycloakRoleEnum.READ_USER_MANAGEMENT,
  ]);

  if (authorizedStatus === false) {
    return <NotAuthorizedPage />;
  }

  if (isPending || !searchUserData) {
    return <LoadingSkeleton />;
  }

  return <UserProfile userData={searchUserData} />;
}

// Loading skeleton for improved user experience during data fetch
function LoadingSkeleton() {
  return (
    <>
      <Heading3>Loading User Profile...</Heading3>
      <Card className="mt-6 p-6">
        <Skeleton className="h-8 w-1/4" />
        <Skeleton className="mt-4 h-6 w-1/3" />
        <Separator className="my-6" />
        <Skeleton className="mb-2 h-6 w-full" />
        <Skeleton className="mb-2 h-6 w-full" />
        <Skeleton className="mb-2 h-6 w-full" />
        <Skeleton className="mb-2 h-6 w-full" />
        <Skeleton className="mb-2 h-6 w-full" />
        <Skeleton className="mb-2 h-6 w-full" />
        <Skeleton className="mb-2 h-6 w-full" />
      </Card>
    </>
  );
}

// Main User Profile component with structured layout
function UserProfile({userData}: {userData: KeycloakUserType}) {
  return (
    <section className="p-4">
      <div className="mb-6 flex w-full items-center justify-between">
        <Heading3>User Profile</Heading3>
        <BackToListLink />
      </div>
      <Card className="p-6">
        <CardHeader className="mb-6 flex flex-col gap-4 md:flex-row md:items-center">
          <div>
            <Heading2 className="text-primary-600">
              {userData.username || "N/A"}
            </Heading2>
            <Paragraph className="text-muted">
              ID: {userData.id || "N/A"}
            </Paragraph>
          </div>
        </CardHeader>
        <CardContent>
          <UserBasicInfo userData={userData} />
          <Separator className="my-6" />
          <UserAccessInfo access={userData.access} />
        </CardContent>
      </Card>
    </section>
  );
}

// Display basic information in a profile-friendly format
function UserBasicInfo({userData}: {userData: KeycloakUserType}) {
  const allRequiredActionQuery = useAllRequiredAction();
  const allRequiredActionData = allRequiredActionQuery.data;

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3">
      <UserDetail label="Email" value={userData.email || "N/A"} />
      <UserDetail label="First Name" value={userData.firstName || "N/A"} />
      <UserDetail label="Last Name" value={userData.lastName || "N/A"} />
      <UserDetail
        label="Email Verified"
        value={userData.emailVerified ? "Yes" : "No"}
      />
      <UserDetail
        label="Created Date"
        value={formatDate(new Date(userData.createdTimestamp))}
      />
      <UserDetail label="TOTP Enabled" value={userData.totp ? "Yes" : "No"} />
      <UserDetail label="Enabled" value={userData.enabled ? "Yes" : "No"} />
      <UserDetail
        label="Not Before"
        value={
          userData.notBefore
            ? new Date(userData.notBefore).toISOString().split("T")[0]
            : "N/A"
        }
      />
      <UserDetail
        label="Required User Action"
        value={
          userData.requiredActions.length > 0
            ? userData.requiredActions
                .map((actionId) => {
                  return allRequiredActionData?.find(
                    (action) => action.providerId === actionId
                  )?.name;
                })
                .join(",")
            : "N/A"
        }
      />
    </div>
  );
}

// Display user access information
function UserAccessInfo({access}: {access: KeycloakUserType["access"]}) {
  return (
    <div>
      <Heading3 className="mb-4">Access Permissions</Heading3>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
        <UserDetail label="View Access" value={access.view ? "Yes" : "No"} />
        <UserDetail label="Map Roles" value={access.mapRoles ? "Yes" : "No"} />
        <UserDetail
          label="Impersonate"
          value={access.impersonate ? "Yes" : "No"}
        />
        <UserDetail label="Manage" value={access.manage ? "Yes" : "No"} />
      </div>
    </div>
  );
}

// Component for each user detail line
function UserDetail({label, value}: {label: string; value: string}) {
  return (
    <Paragraph className="text-lg">
      <strong>{label}: </strong>
      {value}
    </Paragraph>
  );
}

// Reusable Back to List link with improved accessibility
function BackToListLink() {
  return (
    <Link
      to="/app/admin/user-management"
      className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
    >
      <ArrowRightIcon className="size-4" />
      <span>Back to list</span>
    </Link>
  );
}
