// eslint-disable

import {Card} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {Paragraph} from "@/components/ui/typography";

import {ExpandHelpItem} from "@/modules/help";

export const invoiceTrackerHelpItems = [
  {
    title: "About",
    content: (
      <>
        <Paragraph>
          <span className="text-2xl font-extrabold text-theme">
            Invoice Tracker
          </span>{" "}
          - Gives the Customer the ability to view invoice Status details in one
          area, 24/7. This data is transmitted by the Customer to Iteration
          Matrix on a frequent basis (daily weekly, etc.)
        </Paragraph>
      </>
    ),
  },
  {
    title: "Navigation",
    content: (
      <>
        <Paragraph>
          When you login to the IM Portal, the landing page will be{" "}
          <span className="underline">Invoice Tracker</span>.
        </Paragraph>
        <Paragraph>
          If for any reason you need to navigate back to this page, simply hover
          over the “IT” Icon on the left and click “Invoice Tracker”.
        </Paragraph>
        <ExpandHelpItem
          imagePath="/images/help-section/invoice-tracker/navigation.png"
          altText="Navigation"
          width="w-full"
        />
      </>
    ),
  },
  {
    title: "Invoice Table",
    content: (
      <>
        <Paragraph>
          The invoice table outputs details based on the search criteria that is
          provided by the user.
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/invoice-tracker/invoice-table.png"
          altText="invoice-table"
        />
      </>
    ),
  },
  {
    title: "Column Views",
    content: (
      <>
        <Paragraph>
          You can modify the columns that you would like to see in the “Invoice
          Table” output by clicking on the “Column Views” drop-down and
          selecting/un-selecting the columns.
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/invoice-tracker/column-views-1.png"
          altText="column views "
        />
        <ExpandHelpItem
          width="mx-auto w-1/2"
          imagePath="/images/help-section/invoice-tracker/column-views-2.png"
          altText="column views "
        />
      </>
    ),
  },
  {
    title: "Filter by Supplier",
    content: (
      <>
        <Paragraph>
          To use the Filter by Supplier - simply start typing either the
          “Supplier Name” or “Supplier ID”
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/invoice-tracker/filter-by-supplier-1.png"
          altText="column views "
        />
        <Paragraph className="pt-2 font-bold">Examples:</Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/invoice-tracker/filter-by-supplier-2.png"
          altText="column views "
        />
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/invoice-tracker/filter-by-supplier-3.png"
          altText="column views "
        />
      </>
    ),
  },
  {
    title: "Filter by Invoice Date",
    content: (
      <>
        <Paragraph>
          To use the Filter by Invoice Date - simply input the “Start” and “End”
          date.
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/invoice-tracker/filter-by-invoice-date-1.png"
          altText="column views "
        />
        <Paragraph className="pt-2 font-bold">Examples:</Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/invoice-tracker/filter-by-invoice-date-2.png"
          altText="column views "
        />
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/invoice-tracker/filter-by-invoice-date-3.png"
          altText="column views "
        />
      </>
    ),
  },
  {
    title: "Filter by Status",
    content: (
      <>
        <Paragraph>
          To Filter by Status, simply click on the “status” drop-down button,
          which will allow you to filter the output results based on any of the
          following 7 statuses:
        </Paragraph>
        <ExpandHelpItem
          width="mx-auto w-1/2"
          imagePath="/images/help-section/invoice-tracker/filter-by-status-1.png"
          altText="column views "
        />
        <Card>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="font-bold">Invoice Status</TableHead>
                <TableHead>Description</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell className="font-bold">Pending Approval</TableCell>
                <TableCell>Invoice is pending approval for payment.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Approved</TableCell>
                <TableCell>
                  Invoice is approved and is pending for payment.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Paid</TableCell>
                <TableCell>
                  Invoice has been paid. Refer to the payment number.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Exception</TableCell>
                <TableCell>
                  Invoice has discrepancies, which has caused an exception in
                  the Customers ERP system.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Void</TableCell>
                <TableCell>
                  Invoice has been voided and will not be paid.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Deleted</TableCell>
                <TableCell>
                  Invoice has been deleted and will not be paid.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Cancelled</TableCell>
                <TableCell>
                  Invoice has been cancelled and will not be paid.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </>
    ),
  },
  {
    title: "Advanced Search",
    content: (
      <>
        <Paragraph>
          Using the “Advanced Search” option will allow the user to customize
          their search criteria even more based on the follow data points:
        </Paragraph>
        <ExpandHelpItem
          width="mx-auto w-1/2"
          imagePath="/images/help-section/invoice-tracker/advanced-search-1.png"
          altText="column views "
        />
        <Card>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="font-bold">Search Property</TableHead>
                <TableHead>Description</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell className="font-bold">Invoice Amount</TableCell>
                <TableCell>Filter by invoice amount.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Payment Number</TableCell>
                <TableCell>Filter by Payment Number.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">PO Number</TableCell>
                <TableCell>Filter by PO Number.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">ERP Unique ID</TableCell>
                <TableCell>Filter by ERP Unique ID.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Payment Date</TableCell>
                <TableCell>Filter by Payment Date.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Invoice Number</TableCell>
                <TableCell>Filter by Invoice Number.</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </>
    ),
  },
  {
    title: "Saved Search",
    content: (
      <>
        <Paragraph>
          You can also save any of your search criteria filters, which can then
          be used at anytime. To use this option, click on the Save Search
          button. This is a good option to use IF you need to re-check the
          something more than once, thus saving you time from re-entering the
          same search criteria filters everytime.
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/invoice-tracker/saved-search-1.png"
          altText="column views "
        />
        <Paragraph className="pt-2">
          When the “Save Search” button is selected, a pop-up will appear
          providing you will a summary of the search criteria details. You will
          then need to enter in a name of this filter and then click on “Save
          Search Criteria”
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/invoice-tracker/saved-search-2.png"
          altText="column views "
        />
        <Paragraph className="pt-2">
          You will then be able to access any saved searches by clicking the
          “Saved Searches” drop-down at the top right corner of the page.
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/invoice-tracker/saved-search-3.png"
          altText="column views "
        />
      </>
    ),
  },
  {
    title: "Export",
    content: (
      <>
        <Paragraph>
          You can also export the results by clicking on the “Export” Button.
          This will show a pop where you need to provide an Email Address and
          any message to recipient. The system then creates an excel file and
          sends it to the recipient via email.
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/invoice-tracker/export-1.png"
          altText="column views "
        />
      </>
    ),
  },
];

export const imREmitHelpItems = [
  {
    title: "About",
    content: (
      <>
        <Paragraph>
          <span className="text-2xl font-extrabold text-theme">ImREmit</span> -
          Gives the Customer the ability to view payment status information as
          it flows through our system.
        </Paragraph>
      </>
    ),
  },
  {
    title: "Navigation",
    content: (
      <>
        <Paragraph>To Access imREmit - Click on the “RE” Icon.</Paragraph>
        <ExpandHelpItem
          width="mx-auto w-1/2"
          imagePath="/images/help-section/im-remit/imremit.png"
          altText="Navigation "
        />
      </>
    ),
  },
  {
    title: "imREmit Dashboard",
    content: (
      <>
        <Paragraph>
          The imREmit Dashboard provides a “high-level” status overview of
          payments that fall under the corresponding cards below. These cards
          provide the Customer with a quick view of any payments that would
          require attention, which the Iteration Matrix team are working on.
        </Paragraph>

        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/imremit-dashboard.png"
          altText="imREmit Dashboard"
        />
        <Card>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead className="font-bold">Dashboard Cards</TableHead>
                <TableHead>Description</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell className="font-bold">
                  Payables ending in the next 7 days{" "}
                </TableCell>
                <TableCell>
                  Payments that are still open where the Virtual CC expiry date
                  is in the next 7 days{" "}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">
                  Payables Missed in the past 30 days{" "}
                </TableCell>
                <TableCell>
                  Payments that are still open where the Virtual CC expiry date
                  is within the past 30 days.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">
                  Payables with transaction matching issues{" "}
                </TableCell>
                <TableCell>
                  Payments where the reconciled amount does not match with the
                  total payment amount.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">
                  Payables with Declines in the past 7 days.{" "}
                </TableCell>
                <TableCell>
                  Payments where a transaction(s) have been declined.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
        <Paragraph>
          **Note: Any payments that do not lie in the above categories would not
          show on this page. An example would be payments that have already been
          fully taken and reconciled.
        </Paragraph>
        <Paragraph>
          On the right side of this page, you will notice a “Customer
          Information” box. This is read-only and is only for informational
          purposes only.
        </Paragraph>
      </>
    ),
  },
  {
    title: "Payment Management",
    content: (
      <>
        <Paragraph>
          This page provides you with full visibility of all payments (and the
          corresponding status) that have been processed within our system.
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/payment-management.png"
          altText="payment-management"
        />
      </>
    ),
  },
  {
    title: "Column Views ",
    content: (
      <>
        <Paragraph>
          You can modify the columns that you would like to see in the “Payments
          Table” output by clicking on the “Column Views” drop-down and
          selecting/un-selecting the columns.
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/column-views-1.png"
          altText="column views "
        />
        <ExpandHelpItem
          width="mx-auto w-1/2"
          imagePath="/images/help-section/im-remit/column-views-2.png"
          altText="column views "
        />
      </>
    ),
  },

  {
    title: "Filter by Supplier",
    content: (
      <>
        <Paragraph>
          To use the Filter by Supplier - simply start typing either the
          “Supplier Name” or “Supplier ID”.
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/filter-by-supplier-1.png"
          altText="filter-by-supplier"
        />
        <Paragraph>Example:</Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/filter-by-supplier-2.png"
          altText="filter-by-supplier"
        />
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/filter-by-supplier-3.png"
          altText="filter-by-supplier"
        />
      </>
    ),
  },
  {
    title: "Filter by Start/End Date",
    content: (
      <>
        <Paragraph>
          To use the Filter by Start/End Date - simply enter in the date range.
          The date provided coincides to when Iteration Matrix received the
          payment details from the Customer.
        </Paragraph>

        <Paragraph>Example:</Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/filter-by-startend-date-1.png"
          altText="filter-by-startend-date"
        />
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/filter-by-startend-date-2.png"
          altText="filter-by-startend-date"
        />
      </>
    ),
  },
  {
    title: "Filter by Payment Number",
    content: (
      <>
        <Paragraph>
          To use the Filter by Payment Number - simply enter in the desired
          Payment Number
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/filter-by-payment-number.png"
          altText="filter-by-payment-number"
        />
      </>
    ),
  },
  {
    title: "Filter by Account Number",
    content: (
      <>
        <Paragraph>
          To use the Filter by Account Number - simply enter in the account
          number. Please note that you will only be able to utilize this filter
          if account number is provided by the Customer on the Payment File.
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/filter-by-account-number.png"
          altText="filter-by-account-numbe"
        />
      </>
    ),
  },
  {
    title: "Filter by Remittance Method",
    content: (
      <>
        <Paragraph>
          To use the Filter by Remittance Method - simply select from the
          drop-down. Remittance Method lets you know how the supplier takes
          there payments.
        </Paragraph>

        <Paragraph className="pt-2">
          <ul className="list-disc pl-5">
            <li>Pay-By-Web</li>
            <li>Pay-By-Phone </li>
            <li>Pay-By-Email </li>
          </ul>
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/filter-by-remittance-method-1.png"
          altText="filter-by-remittance-method"
        />
        <Paragraph className="pt-2">Example:</Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/filter-by-remittance-method-2.png"
          altText="filter-by-remittance-method"
        />
      </>
    ),
  },
  {
    title: "Filter by Status",
    content: (
      <>
        <Paragraph>
          To Filter by Status, simply click on the “status” drop-down button,
          which will allow you to filter the output results based on any of the
          following 11 statuses:
        </Paragraph>
        <ExpandHelpItem
          width="mx-auto w-1/2"
          imagePath="/images/help-section/im-remit/filter-by-status.png"
          altText="filter-by-status"
        />
        <Card>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Payment Status</TableHead>
                <TableHead>Description</TableHead>
                <TableHead>Additional Comments</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell className="font-bold">Open</TableCell>
                <TableCell>
                  Payment file successfully processed on IM side.
                </TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Open</TableCell>
                <TableCell>
                  Successfully created the card on the payment provider side.
                </TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Open</TableCell>
                <TableCell>Successfully modified the payment</TableCell>
                <TableCell>
                  When Iteration Matrix changes the payment details from
                  single-multi authorization.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">In Progress</TableCell>
                <TableCell>
                  Payment is being processed by Agent/Script.
                </TableCell>
                <TableCell>
                  When Iteration Matrix is currently working on taking the
                  payment. This is for “Pay-By-Phone” and “Pay-By-Web”
                  suppliers.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">
                  Paid - Pending Verification
                </TableCell>
                <TableCell>
                  Payment has been processed - awaiting reconciliation.
                </TableCell>
                <TableCell>
                  When a “Pay-By Phone” or “Pay-By-Web” payment has been taken
                  and we are awaiting a recon file from the bank to validate the
                  payment has been posted.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">
                  Paid - Pending Verification
                </TableCell>
                <TableCell>Bank Reconciliation File not received.</TableCell>
                <TableCell>
                  When a “Pay-By Phone” or “Pay-By-Web” payment has been taken
                  and we have not received a recon file from the bank within the
                  allocated timeframe.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Closed</TableCell>
                <TableCell>
                  Received the recon with partial amount the payment is still
                  open.
                </TableCell>
                <TableCell>
                  Received recon file from the bank, however not all
                  transactions have been posted as yet.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Closed</TableCell>
                <TableCell>
                  Payment has been closed successfully with exact match.
                </TableCell>
                <TableCell>
                  Received recon file from the bank and all transactions for
                  that payment are present.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Delivered</TableCell>
                <TableCell>
                  Successfully created the bank file for the customer and
                  delivered.
                </TableCell>
                <TableCell>
                  When Iteration Matrix has transmitted a Customer Recon File.
                  <Paragraph>
                    *Only for Customers that have opted-in to receive a Recon
                    File for fully taken payments.
                  </Paragraph>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Delivered</TableCell>
                <TableCell>
                  Successfully created the partial pay bank file for the
                  customer and delivered.
                </TableCell>
                <TableCell>
                  When Iteration Matrix has transmitted a Customer Recon File.
                  <Paragraph>
                    *Only for Customers that opted-in to receive a Recon File
                    for payments even though the full amount has not been taken
                    yet.
                  </Paragraph>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Failed</TableCell>
                <TableCell>Supplier is not active.</TableCell>
                <TableCell>
                  When a payment has been received from the Customer, where the
                  Supplier is not active in our system.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Failed</TableCell>
                <TableCell>
                  Payment failed on the payment provider side.
                </TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Blocked</TableCell>
                <TableCell>
                  Payment manually closed by the account manager.
                </TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </>
    ),
  },
  {
    title: "Actions",
    content: (
      <>
        <Paragraph>
          If you would like to see additional information for a particular
          Payment Number – simply click the button under Actions column and
          select “View Payment Details.”
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/actions-1.png"
          altText="actions"
        />
        <ExpandHelpItem
          width="mx-auto w-1/2"
          imagePath="/images/help-section/im-remit/actions-2.png"
          altText="actions"
        />
        <Paragraph className="pt-2">
          If you click the “View Payment Details” button, a new window will
          appear for viewing additional detail for the payment.
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/actions-3.png"
          altText="actions"
        />
        <Paragraph className="pt-2">
          At the bottom of the page, there are 4 tabs that you can altTexter
          between.
        </Paragraph>
        <Paragraph className="pt-2">
          <ul className="list-disc pl-5">
            <li>
              General information: This information is based on what we have
              sent to the bank. It is not important for the Customer.
              <ExpandHelpItem
                width="w-full"
                imagePath="/images/help-section/im-remit/actions-4.png"
                altText="actions"
              />
            </li>
            <li>
              Invoices: The invoices that are tied to this payment number is
              shown.{" "}
              <ExpandHelpItem
                width="w-full"
                imagePath="/images/help-section/im-remit/actions-5.png"
                altText="actions"
              />
              <Paragraph>
                There is an option to view any invoice comments, which will only
                be populated IF the customer has provided this detail on the
                Payment File.{" "}
              </Paragraph>
              <ExpandHelpItem
                width="w-full"
                imagePath="/images/help-section/im-remit/actions-6.png"
                altText="actions"
              />
            </li>
            <li>
              Transactions: Payments taken and posted will be listed here, along
              with the transaction details.
              <ExpandHelpItem
                width="w-full"
                imagePath="/images/help-section/im-remit/actions-7.png"
                altText="actions"
              />
            </li>
            <li>
              Authorizations and Declines: As suppliers take payments (run the
              card), the authorization details will show up under this tab for
              the transactions. The transactions will show up on this page
              initially and then after about 24-72 hours (about 3 days) when the
              transactions get posted, they will be displayed under the
              Transactions tab.
            </li>
            <li>
              History: This tab shows the flow of payment processing from the
              time Iteration Matrix has received the payment file, up until
              reconciliation is completed.
              <ExpandHelpItem
                width="w-full"
                imagePath="/images/help-section/im-remit/actions-8.png"
                altText="actions"
              />
              <Paragraph>
                At the top right of the page, you will notice 2 buttons:
                Comments and Block Payment
              </Paragraph>
              <ExpandHelpItem
                width="w-full"
                imagePath="/images/help-section/im-remit/actions-9.png"
                altText="actions"
              />
            </li>
            <li>
              Comments: Allows you to view comments our payments team has left
              regarding this payment. You also have the ability to make your own
              comments as well.
              <ExpandHelpItem
                width="w-full"
                imagePath="/images/help-section/im-remit/actions-10.png"
                altText="actions"
              />
            </li>
            <li>
              Block Payment: Allows you to block payments that are currently
              under “Open” status. This will change the payment status to
              “Blocked”.
              <ExpandHelpItem
                width="mx-auto w-1/2"
                imagePath="/images/help-section/im-remit/actions-11.png"
                altText="actions"
              />
            </li>
          </ul>
        </Paragraph>
      </>
    ),
  },
  {
    title: "Supplier Management",
    content: (
      <>
        <Paragraph>
          This page provides you with information on your suppliers that we
          currently have loaded in our system.
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/supplier-management.png"
          altText="supplier-management"
        />
      </>
    ),
  },
  {
    title: "Filter by Supplier",
    content: (
      <>
        <Paragraph>
          To use the Filter by Supplier - simply start typing either the
          “Supplier Name” or “Supplier ID”.
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/filter-by-supplier-sm-1.png"
          altText="filter-by-supplier-sup-management"
        />
        <Paragraph>Example:</Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/filter-by-supplier-sm-2.png"
          altText="filter-by-supplier-sup-management"
        />
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/filter-by-supplier-sm-3.png"
          altText="filter-by-supplier-sup-management"
        />
      </>
    ),
  },

  {
    title: "Filter by Status ",
    content: (
      <>
        <Paragraph>
          To Filter by Status, simply click on the “status” drop-down button,
          which will allow you to filter the output results based on any of the
          following 6 statuses:
        </Paragraph>
        <ExpandHelpItem
          width="mx-auto w-1/2"
          imagePath="/images/help-section/im-remit/filter-by-status-sm.png"
          altText="filter-by-status-sup-management"
        />
        <Card>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Invoice Status</TableHead>
                <TableHead>Description</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow>
                <TableCell className="font-bold">Onboarding Pending</TableCell>
                <TableCell>
                  Have not started or are in the process of onboarding the
                  supplier.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Declined Enrollment</TableCell>
                <TableCell>Supplier has declined the enrollment.</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Approval Pending</TableCell>
                <TableCell>
                  Supplier has agreed to be onboarded and the details are being
                  reviewed by the Program Manager.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Active</TableCell>
                <TableCell>
                  Program Manager has approved the onboarding details, and we
                  are ready to receive the supplier payments via the Customer
                  Payment File.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Form Declined</TableCell>
                <TableCell>
                  Program Manager has declined the onboarding details and is
                  given back to the onboarding team.
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="font-bold">Inactive</TableCell>
                <TableCell>
                  A supplier that was in Active status and needs to be
                  unenrolled from the program.
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </>
    ),
  },
  {
    title: "Actions",
    content: (
      <>
        <Paragraph>
          To view the supplier details, simply click the button under Actions
          column and select “View Supplier Details.”
        </Paragraph>
        <ExpandHelpItem
          width="mx-auto w-1/2"
          imagePath="/images/help-section/im-remit/actions-sm-1.png"
          altText="actions-sup-management"
        />
        <ExpandHelpItem
          width="mx-auto w-1/2"
          imagePath="/images/help-section/im-remit/actions-sm-2.png"
          altText="actions-sup-management"
        />
        <Paragraph>
          If you click the “View Supplier Details” button, a new window will
          appear for viewing additional detail for the supplier.
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/actions-sm-3.png"
          altText="actions-sup-management"
        />
      </>
    ),
  },
  {
    title: "File Processing",
    content: (
      <>
        <Paragraph>
          This page shows a list of all the “Inbound” and “Outbound” files that
          we have received and transmitted between the Customer, Bank, and
          Iteration Matrix. It also shows how many records are in the file and
          which ones were successful or failed.
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/file-processing.png"
          altText="file-processing"
        />
      </>
    ),
  },
  {
    title: "Filter by Filename ",
    content: (
      <>
        <Paragraph>
          To use the filter by filename, simply type in the filename.
        </Paragraph>
        <ExpandHelpItem
          width="w-full"
          imagePath="/images/help-section/im-remit/filter-by-filename.png"
          altText="filter-by-filename"
        />
      </>
    ),
  },
  {
    title: "Filter by Column Views",
    content: (
      <>
        <Paragraph>
          You can modify the columns that you would like to see in the output by
          clicking on the “Column Views” drop-down and selecting/un-selecting
          the columns.
        </Paragraph>
        <ExpandHelpItem
          width="mx-auto w-1/2"
          imagePath="/images/help-section/im-remit/filter-by-column-views.png"
          altText="filter-by-column-views"
        />
      </>
    ),
  },
];
export const duplicateToolHelpItems = [{title: "", content: <></>}];
export const statementReconHelpItems = [{title: "", content: <></>}];
export const adminHelpItems = [{title: "", content: <></>}];
