import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {PaymentMethodRowActions} from "@/modules/admin";
import {type PaymentMethodName} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";

export const paymentMethodTableColumns: ColumnDef<PaymentMethodName>[] = [
  {
    accessorKey: "providerName",
    header: ({column}) => (
      <DataTableColumnHeader
        column={column}
        title="
        Payment provider name
      "
      />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("providerName") ? (
          row.getValue("providerName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "paymentMethodName",
    header: ({column}) => (
      <DataTableColumnHeader
        column={column}
        title="
        Payment method name
      "
      />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("paymentMethodName") ? (
          row.getValue("paymentMethodName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "customerPaymentMethodName",
    header: ({column}) => (
      <DataTableColumnHeader
        column={column}
        title="
     Customer payment method name
      "
      />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("customerPaymentMethodName") ? (
          row.getValue("customerPaymentMethodName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },

  {
    accessorKey: "customerPaymentMethodDescription",
    header: ({column}) => (
      <DataTableColumnHeader
        column={column}
        title="
    Description
      "
      />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("customerPaymentMethodDescription") ? (
          row.getValue("customerPaymentMethodDescription")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },

  {
    id: "actions",
    header: () => <Paragraph className="text-right">Actions</Paragraph>,
    cell: ({row}) => (
      <div className="flex justify-end">
        <PaymentMethodRowActions row={row} />
      </div>
    ),
  },
];
