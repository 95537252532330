import {
  MutationCache,
  QueryClient,
  // type DefaultOptions,
  type UseMutationOptions,
  type UseQueryOptions,
} from "@tanstack/react-query";
import {type HTTPError} from "ky";

// const queryConfig: DefaultOptions = {
//   queries: {
//     refetchOnWindowFocus: true,
//     networkMode: "online",
//     staleTime: 1000 * 60,
//   },
//   mutations: {
//     useErrorBoundary: false,
//   },
// };

export const queryClient: QueryClient = new QueryClient({
  mutationCache: new MutationCache({
    onSettled: () => {
      if (queryClient.isMutating() === 1) {
        return queryClient.invalidateQueries();
      }
    },
  }),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ExtractFnReturnType<FnType extends (...args: any) => any> = Awaited<
  ReturnType<FnType>
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QueryConfig<QueryFnType extends (...args: any) => any> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
  "queryKey" | "queryFn"
>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MutationConfig<MutationFnType extends (...args: any) => any> =
  UseMutationOptions<
    ExtractFnReturnType<MutationFnType>,
    HTTPError,
    Parameters<MutationFnType>[0]
  >;
