import {z} from "zod";

// Define the Client Session Count schema
export const KCClientSessionCountSchema = z.object({
  count: z.number(),
});

// Define the ProtocolMapper schema
export const KCProtocolMapperSchema = z.object({
  id: z.string(),
  name: z.string(),
  protocol: z.string(),
  protocolMapper: z.string(),
  consentRequired: z.boolean(),
  config: z.record(z.string(), z.string()),
});

// Define the Access schema
export const KCAccessSchema = z.object({
  view: z.boolean(),
  configure: z.boolean(),
  manage: z.boolean(),
});

// Define the Client schema
export const KCClientSchema = z.object({
  id: z.string(),
  clientId: z.string(),
  name: z.string().nullable(), // name can be null
  rootUrl: z.string().nullable(), // rootUrl can be null
  baseUrl: z.string().nullable(), // baseUrl can be null
  description: z.string().nullable().optional(), // description is optional and can be null
  surrogateAuthRequired: z.boolean(),
  enabled: z.boolean(),
  alwaysDisplayInConsole: z.boolean(),
  clientAuthenticatorType: z.string(),
  redirectUris: z.array(z.string()),
  webOrigins: z.array(z.string()),
  notBefore: z.number(),
  bearerOnly: z.boolean(),
  consentRequired: z.boolean(),
  standardFlowEnabled: z.boolean(),
  implicitFlowEnabled: z.boolean(),
  directAccessGrantsEnabled: z.boolean(),
  serviceAccountsEnabled: z.boolean(),
  publicClient: z.boolean(),
  frontchannelLogout: z.boolean(),
  protocol: z.string(),
  attributes: z.record(z.string(), z.string()).optional(), // attributes can be optional
  authenticationFlowBindingOverrides: z
    .record(z.string(), z.string())
    .optional(), // authenticationFlowBindingOverrides can be optional
  fullScopeAllowed: z.boolean(),
  nodeReRegistrationTimeout: z.number(),
  protocolMappers: z.array(KCProtocolMapperSchema).optional(), // protocolMappers can be optional
  defaultClientScopes: z.array(z.string()),
  optionalClientScopes: z.array(z.string()),
  access: KCAccessSchema,
});

// Define the Root schema
export const KCCLientsSchema = z.array(KCClientSchema);

// Define the types based on the schemas
export type KeycloakClientSessionCountType = z.infer<
  typeof KCClientSessionCountSchema
>;
export type KeycloakClientType = z.infer<typeof KCClientSchema>;
export type KeycloakClientsType = z.infer<typeof KCCLientsSchema>;
