import {useEffect, useMemo, useState} from "react";

import {useMutation} from "@tanstack/react-query";
import {useNavigate} from "@tanstack/react-router";
import {ArrowRightIcon, Trash2Icon} from "lucide-react";

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {Button} from "@/components/ui/button";
import {Heading2, Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {queryClient} from "@/lib/query-client";
import {
  SupplierScriptsTimeline,
  SupplierScriptUploadDropzone,
  useGetSupplierScripts,
  type ModuleTypeUnion,
} from "@/modules/imremit";
import {deleteSupplierScriptFn} from "@/modules/landing";

interface EditGenericSupplierScriptPageProps {
  supplierId: string;
  moduleName: string;
  paymentId: string;
}

export function EditGenericSupplierScriptPage({
  supplierId,
  moduleName,
  paymentId,
}: EditGenericSupplierScriptPageProps) {
  const navigate = useNavigate();

  const [currentSupplier, setCurrentSupplier] = useState<string | number>("");
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [csvHeaders, setCsvHeaders] = useState<string[]>([]);

  //supplier script page url
  const supplierScriptPageUrl = `/app/${
    moduleName as ModuleTypeUnion
  }/supplier-script-management` as const;

  //proxy pay dashboard view page url
  const proxyPayDashboardViewPageUrl = `/app/${
    moduleName as ModuleTypeUnion
  }/proxy-pay-dashboard/$paymentId/view` as const;

  useEffect(() => {
    setCurrentSupplier(supplierId);
  }, [supplierId]);

  const deleteSupplierScriptMutation = useMutation({
    mutationFn: (supplier: {supplierId: number}) => {
      return deleteSupplierScriptFn(supplier.supplierId);
    },
    onSuccess: (response) => {
      console.log("deleteSupplierScriptResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Supplier script deleted successfully",
      });

      queryClient.clear();
      void navigate({
        to: supplierScriptPageUrl,
        replace: true,
      });
    },

    onError: (error: unknown) => {
      console.error("errorMessage", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to delete supplier script",
      });
    },
  });

  const searchSupplierScriptsQuery = useGetSupplierScripts(
    currentSupplier.toString()
  );
  const searchSupplierScriptsQueryData = useMemo(() => {
    if (!searchSupplierScriptsQuery.data?.content) return [];

    return searchSupplierScriptsQuery.data.content;
  }, [searchSupplierScriptsQuery]);

  const supplierScripts = useMemo(() => {
    const existingScripts = searchSupplierScriptsQueryData.map(
      (supplierScript, i) => ({
        id: i,
        ...supplierScript,
      })
    );

    const csvScripts = csvData.map((row, i) => {
      const parsedScript: {[key: string]: string} = {};
      csvHeaders.forEach((header, index) => {
        parsedScript[header] = row[index];
      });
      return {
        id: i + existingScripts.length,
        supplierScriptId: "-",
        supplierId: 0,
        sequenceId: 0,
        fieldName:
          parsedScript["Field Name"] || parsedScript["FIELDNAME"] || "",
        fieldValue: parsedScript["X-Path"] || parsedScript["FIELDVALUE"] || "",
        pageCategory:
          parsedScript["Page Name"] || parsedScript["PAGECATEGORY"] || "",
        fieldType:
          parsedScript["Field Type"] || parsedScript["FIELDTYPE"] || "",
        position: parsedScript["Position"] || parsedScript["POSITION"] || "",
        separator: parsedScript["Separator"] || parsedScript["SEPARATOR"] || "",
        dropDownValue:
          parsedScript["Default Value"] || parsedScript["DROPDOWNVALUE"] || "",
        tableName:
          parsedScript["Table Name"] || parsedScript["TABLENAME"] || "",
        tableColumnName:
          parsedScript["Table Col. Name"] ||
          parsedScript["TABLECOLUMNNAME"] ||
          "",
        timer: parsedScript["Timer"] || parsedScript["TIMER"] || "",
        captchaTimer: 0,
        paymentTimer: 0,
        dateFormat:
          parsedScript["Date Format"] || parsedScript["DATEFORMAT"] || "",
        loginSkip: null,
        jsCheck: parsedScript["Js Check"] || parsedScript["JSCHECK"] || "",
        sequenceIdentifier:
          parsedScript["Login Sequence"] ||
          parsedScript["SEQUENCEIDENTIFIER"] ||
          "",
        findElementBy:
          parsedScript["Find Element By"] ||
          parsedScript["FINDELEMENTBY"] ||
          "",
      };
    });

    return [...existingScripts, ...csvScripts];
  }, [searchSupplierScriptsQueryData, csvData, csvHeaders]);

  const handleCsvData = (data: string[][], headers: string[]) => {
    setCsvData(data.slice(1)); // Remove header row from data
    setCsvHeaders(headers);
  };

  return (
    <section
      className="flex flex-col gap-4"
      aria-label="This is the Supplier Script Management level three subroute. View your customer's
      Supplier Script Management data here."
    >
      <header className="flex flex-col gap-4 lg:flex-row lg:items-center lg:justify-between">
        <div className="flex flex-col">
          <Heading2>Edit Supplier Script</Heading2>
        </div>

        <div className="flex flex-col gap-2 lg:flex-row lg:items-center">
          <SupplierScriptUploadDropzone onDrop={handleCsvData} />
          <Button
            variant="secondary"
            onClick={() => {
              paymentId === "0"
                ? void navigate({
                    to: supplierScriptPageUrl,
                    replace: true,
                  })
                : void navigate({
                    to: proxyPayDashboardViewPageUrl,
                    replace: true,
                    params: {paymentId: paymentId},
                  });
            }}
          >
            Back to list
            <ArrowRightIcon className="ml-2 size-4" />
          </Button>

          {supplierScripts.length > 0 && (
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  className="whitespace-nowrap"
                  disabled={currentSupplier === ""}
                >
                  <span className="sr-only">Delete Script</span>
                  <Trash2Icon className="mr-2" />
                  Delete Script
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>
                    Are you sure you want to delete this supplier data?
                  </AlertDialogTitle>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>No</AlertDialogCancel>
                  <Button
                    className="whitespace-nowrap"
                    disabled={currentSupplier === ""}
                    onClick={() => {
                      deleteSupplierScriptMutation.mutate({
                        supplierId: Number(currentSupplier),
                      });
                    }}
                  >
                    Yes
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          )}
        </div>
      </header>
      <section>
        {supplierScripts.length > 0 && (
          <SupplierScriptsTimeline
            supplierId={Number(currentSupplier)}
            supplierScriptItems={supplierScripts}
            setCurrentSupplier={setCurrentSupplier}
            module={moduleName}
          />
        )}

        {supplierScripts.length === 0 && (
          <div className="flex h-full flex-col items-center justify-center">
            <Paragraph className="text-center">
              No supplier scripts found.
            </Paragraph>
          </div>
        )}
      </section>
    </section>
  );
}
