import {create} from "zustand";

import {type CustomerType} from "@/modules/customers";

// Defining the shape of the state object for the CustomerStore
// In this case, the state will consist of an array of Customer objects
interface ImRemitStatementReconCustomersStoreState {
  imRemitStatementReconStoreCustomer: CustomerType | null;
}

// Defining the actions that can be performed on the CustomerStore state
// In this case, there's only one action, updateImRemitStatementReconStoreCustomer, which takes an array of Customer objects
interface ImRemitStatementReconCustomerStoreActions {
  updateImRemitStatementReconStoreCustomer: (
    imRemitStatementReconStoreCustomer: ImRemitStatementReconCustomersStoreState["imRemitStatementReconStoreCustomer"]
  ) => void;
}

// Creating the Zustand store using the create function
// The store is named useImRemitStatementReconCustomerStore, and it uses the types defined above for its state and actions
// The state begins with an empty imRemitStatementReconStoreCustomer array
// The updateImRemitStatementReconStoreCustomer action uses Zustand's set function to update the state
export const useImRemitStatementReconCustomerStore = create<
  ImRemitStatementReconCustomersStoreState &
    ImRemitStatementReconCustomerStoreActions
>((set) => ({
  // Initial state of the store. An empty array of imRemitStatementReconStoreCustomer.
  imRemitStatementReconStoreCustomer: null,

  // Action to update the imRemitStatementReconStoreCustomer array in the store.
  // This function takes a new imRemitStatementReconStoreCustomer array and uses the set function to update the state.
  updateImRemitStatementReconStoreCustomer: (
    imRemitStatementReconStoreCustomer
  ) => {
    set(() => ({imRemitStatementReconStoreCustomer}));
  },
}));
