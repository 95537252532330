// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type SupplierManagementAdminTableColumnsType} from "@/modules/admin";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface SupplierManagementAdminTableColumnsStoreState {
  storeSupplierManagementAdminTableColumns: SupplierManagementAdminTableColumnsType | null;
  setStoreSupplierManagementAdminTableColumns: (
    storeSupplierManagementAdminTableColumns: SupplierManagementAdminTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for SupplierManagementAdmin table columns
export const useSupplierManagementAdminTableColumnsStore = create(
  persist<SupplierManagementAdminTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeSupplierManagementAdminTableColumns: null,

      // Getter function to retrieve the current state
      getStoreSupplierManagementAdminTableColumns: () =>
        get().storeSupplierManagementAdminTableColumns,

      // Setter function to update the state
      setStoreSupplierManagementAdminTableColumns: (
        storeSupplierManagementAdminTableColumns
      ) => {
        set(() => ({storeSupplierManagementAdminTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "SupplierManagementAdmin-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useSupplierManagementAdminTableColumns = () =>
  useSupplierManagementAdminTableColumnsStore((state) => {
    return {
      storeSupplierManagementAdminTableColumns:
        state.storeSupplierManagementAdminTableColumns,
    };
  });
