import {Badge} from "@/components/ui/badge";
import {Card} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {Heading3} from "@/components/ui/typography";
import {TableSkeleton} from "@/components/craft/table";

import {useGetUserSessions} from "@/modules/admin";

interface UserSessionsTableProps {
  userId: string;
}

export function UserSessionsTable({userId}: UserSessionsTableProps) {
  const userSessionsQuery = useGetUserSessions(userId);

  return (
    <section className="flex flex-col gap-2">
      <Heading3>User Sessions</Heading3>
      <Card>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Username</TableHead>
              <TableHead>IP Address</TableHead>
              <TableHead>Access Start</TableHead>
              <TableHead>Last Accessed</TableHead>
              <TableHead>Remember Me</TableHead>
            </TableRow>
          </TableHeader>
          {userSessionsQuery.isError ? (
            <TableBody>
              <TableRow>
                <TableCell className="h-24 text-center" colSpan={5}>
                  Error loading data.
                </TableCell>
              </TableRow>
            </TableBody>
          ) : !userSessionsQuery.isSuccess ? (
            <TableSkeleton
              columnsLength={5}
              numRowsPerPage={1}
              omittedColumns={{}}
            />
          ) : userSessionsQuery.data.length ? (
            <TableBody>
              {userSessionsQuery.data.map((session) => (
                <TableRow key={session.id}>
                  <TableCell>{session.username}</TableCell>
                  <TableCell>{session.ipAddress}</TableCell>
                  <TableCell>{new Date(session.start).toISOString()}</TableCell>
                  <TableCell>
                    {new Date(session.lastAccess).toISOString()}
                  </TableCell>
                  <TableCell>
                    {session.rememberMe ? (
                      <Badge>Yes</Badge>
                    ) : (
                      <Badge variant="destructive">No</Badge>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell className="h-24 text-center" colSpan={5}>
                  No user sessions found.
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Card>
    </section>
  );
}
