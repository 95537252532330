import {useMemo} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate} from "@tanstack/react-router";
import {ArrowRightIcon, UserPlus2Icon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button, buttonVariants} from "@/components/ui/button";
import {Form} from "@/components/ui/form";
import {Separator} from "@/components/ui/separator";
import {Heading2, Heading4, Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {cn} from "@/lib/utils";
import {
  addCustomerFn,
  AddCustomerFormSchema,
  GenericCustomerForm,
  ModuleSubscriptionForm,
} from "@/modules/admin";
import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useKeyCloakInstanceStore,
} from "@/modules/auth";

import {KeycloakRoles} from "@/utils/constants";

export interface CustomerAPIMutatingProps {
  isMutating?: boolean;
}

/**
 * AddCustomerFormProps Interface
 *
 * @interface AddCustomerFormProps
 * @property {boolean} isMutating - Indicates whether the mutation is in progress.
 * @property {Function} onSubmit - The function to be called upon form submission.
 */
interface AddCustomerFormProps {
  isMutating: boolean;
  onSubmit: (data: z.infer<typeof AddCustomerFormSchema>) => void;
}

/**
 * AddCustomerForm Component
 *
 * This component renders the AddCustomerForm form and handles its behavior.
 *
 * @param {AddCustomerFormProps} props - Properties passed to the component
 * @returns {JSX.Element} - Rendered component
 */

function AddCustomerForm({isMutating, onSubmit}: AddCustomerFormProps) {
  const storeKeyCloakInstance =
    useKeyCloakInstanceStore().storeKeyCloakInstance;

  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );

  const addCustomerFormMethods = useForm<z.infer<typeof AddCustomerFormSchema>>(
    {
      resolver: zodResolver(AddCustomerFormSchema),
      defaultValues: {
        epayManagerAssigned: KeycloakRoles.ProgramManager,
        status: "active",
        modifiedBy: parsedUserInfo?.name as string,

        customerProfile: {
          buyerEpayManager: KeycloakRoles.ProgramManager,
          buyerZeroDollarFlag: false,
          buyerProfileAccountNumberMapping: false,
          buyerProfileAccountNumberMasking: false,
          buyerProfileOrgId: "",
          buyerProfileCountry: "usa",
        },

        moduleSubscriptions: [],
      },
    }
  );

  const {
    formState: {errors},
  } = addCustomerFormMethods;

  console.log("errors", errors);

  return (
    <section>
      <div className="mb-4 flex flex-col-reverse gap-4 md:flex-row md:justify-between">
        <div className="flex flex-col">
          <Heading2>Create Customer</Heading2>
          <Paragraph>
            Use this form to create a new customer. Once created, you can view
            and edit the customer details.
          </Paragraph>
        </div>
        <Link
          to="/app/admin/customer-management"
          className={cn(buttonVariants({variant: "secondary"}), "mb-4 gap-2")}
        >
          Back to list
          <span className="sr-only">Back to list</span>
          <ArrowRightIcon className="size-4" />
        </Link>
      </div>
      <Form {...addCustomerFormMethods}>
        <form onSubmit={addCustomerFormMethods.handleSubmit(onSubmit)}>
          <div className="mb-8 rounded-md border border-border bg-root p-4">
            <GenericCustomerForm isMutating={isMutating} methodType="POST" />
            <Separator className="my-5" />
            <Heading4 className="mb-4">Module Subscription</Heading4>

            <ModuleSubscriptionForm
              isMutating={isMutating}
              submitButton={
                <Button
                  disabled={isMutating}
                  className="gap-2 whitespace-nowrap bg-success-foreground text-root hover:bg-success-foreground/80 active:bg-success-foreground/80"
                  type="submit"
                >
                  <span className="sr-only">Submit form</span>
                  Create customer <UserPlus2Icon className="size-6" />
                </Button>
              }
            />
          </div>
        </form>
      </Form>
    </section>
  );
}

export function AddCustomerPage() {
  const navigate = useNavigate();

  const addCustomerMutation = useMutation({
    mutationFn: async (data: z.infer<typeof AddCustomerFormSchema>) => {
      // remove properties that are not needed
      const {
        modules,
        aliasSwitch,

        ...rest
      } = data;

      console.log("Removed fields: ", {
        modules,
        aliasSwitch,
      });

      return addCustomerFn(rest);
    },

    onSuccess: (createCustomerResponse) => {
      console.log("createCustomerResponse", createCustomerResponse);

      toast({
        variant: "success",
        title: "Success!",
        description: "Customer created successfully",
      });

      void navigate({to: "/app/admin/customer-management", replace: true});
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to create customer",
      });
    },
  });

  // Render AddCustomerForm component
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.CREATE_CUSTOMER_MANAGEMENT]}
    >
      <AddCustomerForm
        isMutating={addCustomerMutation.isPending}
        onSubmit={addCustomerMutation.mutate}
      />
    </AccessPolicyWrapper>
  );
}
