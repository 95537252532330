import {z} from "zod";

export const alertManagementSchema = z.array(
  z.object({
    alertDetailId: z.number(),
    alertLevelId: z.number(),
    alertLevel: z.string(),
    alertType: z.string(),
    alertSeverity: z.string(),
    alertSource: z.string(),
    alertRecipient: z.string(),
    alertContent: z.string(),
  })
);

export const addAlertManagementSchema = z.object({
  alertLevel: z.string(),
  alertType: z.string(),
  alertSeverity: z.string(),
  alertSource: z.string(),
  alertRecipient: z.string(),
  alertContent: z.string(),
});

export type AddAlertManagementType = z.infer<typeof addAlertManagementSchema>;
export type AlertManagementType = z.infer<typeof alertManagementSchema>;
