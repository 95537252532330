import {Link} from "@tanstack/react-router";
import {MoveRightIcon} from "lucide-react";

import {buttonVariants} from "@/components/ui/button";
import {Card, CardContent} from "@/components/ui/card";
import {Heading2, Paragraph} from "@/components/ui/typography";

import {cn} from "@/lib/utils";

// /**
//  * AddSuppliersFormProps Interface
//  *
//  * @interface AddSuppliersFormProps
//  * @property {boolean} isMutating - Indicates whether the mutation is in progress.
//  * @property {Function} onSubmit - The function to be called upon form submission.
//  */
// interface AddSuppliersFormProps {
//   isMutating: boolean;
//   onSubmit: (data: z.infer<typeof addAdminSupplierFormSchema>) => void;
// }

// /**
//  * AddSuppliersForm Component
//  *
//  * This component renders the suppliers form and handles its behavior.
//  *
//  * @param {AddSuppliersFormProps} props - Properties passed to the component
//  * @returns {JSX.Element} - Rendered component
//  */
// function AddSuppliersForm({isMutating, onSubmit}: AddSuppliersFormProps) {
//   const form = useForm<z.infer<typeof addAdminSupplierFormSchema>>({
//     resolver: zodResolver(addAdminSupplierFormSchema),
//   });

//   //Render the form
//   return (
//     <Form {...form}>
//       <div className="flex flex-col pt-4">
//         <Heading2>Generic Supplier Onboard</Heading2>
//         <Paragraph>
//           Create supplier and select supplier module subscriptions.
//         </Paragraph>
//       </div>

//       <form onSubmit={form.handleSubmit(onSubmit)}>
//         <div className="mb-10 grid grid-cols-1 gap-4 pt-7 md:grid-cols-2 xl:grid-cols-4">
//           <FormField
//             control={form.control}
//             name="taxCode"
//             render={() => (
//               <FormItem>
//                 <FormLabel>T.I.N. :</FormLabel>
//                 <FormControl>
//                   <Input
//                     placeholder="Enter TIN "
//                     disabled={isMutating}
//                     aria-disabled={isMutating}
//                     autoCapitalize="none"
//                     autoComplete="taxCode"
//                     autoCorrect="off"
//                     {...form.register("taxCode")}
//                   />
//                 </FormControl>
//                 <FormMessage />
//               </FormItem>
//             )}
//           />

//           <FormField
//             control={form.control}
//             name="name"
//             render={() => (
//               <FormItem>
//                 <FormLabel>Name :</FormLabel>
//                 <FormControl>
//                   <Input
//                     disabled={isMutating}
//                     aria-disabled={isMutating}
//                     autoCapitalize="none"
//                     autoComplete="name"
//                     autoCorrect="off"
//                     placeholder="Enter Name"
//                     {...form.register("name")}
//                   />
//                 </FormControl>
//                 <FormMessage />
//               </FormItem>
//             )}
//           />

//           <FormField
//             name="number"
//             render={() => (
//               <FormItem>
//                 <FormLabel htmlFor="number">Number:</FormLabel>
//                 <FormControl>
//                   <Input
//                     type="number"
//                     min="0"
//                     disabled={isMutating}
//                     aria-disabled={isMutating}
//                     autoCapitalize="none"
//                     autoComplete="number"
//                     autoCorrect="off"
//                     placeholder="Enter number..."
//                     {...form.register("number")}
//                   />
//                 </FormControl>
//                 <FormMessage />
//               </FormItem>
//             )}
//           />

//           <FormField
//             name="externalId"
//             render={() => (
//               <FormItem>
//                 <FormLabel htmlFor="externalId">External ID:</FormLabel>
//                 <FormControl>
//                   <Input
//                     disabled={isMutating}
//                     aria-disabled={isMutating}
//                     autoCapitalize="none"
//                     autoComplete="externalId"
//                     autoCorrect="off"
//                     placeholder="Enter External ID..."
//                     {...form.register("externalId")}
//                   />
//                 </FormControl>
//                 <FormMessage />
//               </FormItem>
//             )}
//           />

//           <FormField
//             control={form.control}
//             name="corporateName"
//             render={() => (
//               <FormItem>
//                 <FormLabel>Corporate Name :</FormLabel>
//                 <FormControl>
//                   <Input
//                     disabled={isMutating}
//                     aria-disabled={isMutating}
//                     autoCapitalize="none"
//                     autoComplete="corporateName"
//                     autoCorrect="off"
//                     placeholder="Enter Corporate Name"
//                     {...form.register("corporateName")}
//                   />
//                 </FormControl>
//                 <FormMessage />
//               </FormItem>
//             )}
//           />

//           <FormField
//             control={form.control}
//             name="address1"
//             render={() => (
//               <FormItem>
//                 <FormLabel>Address One:</FormLabel>
//                 <FormControl>
//                   <Input
//                     disabled={isMutating}
//                     aria-disabled={isMutating}
//                     autoCapitalize="none"
//                     autoComplete="address1"
//                     autoCorrect="off"
//                     placeholder="Enter Address 1 "
//                     {...form.register("address1")}
//                   />
//                 </FormControl>
//                 <FormMessage />
//               </FormItem>
//             )}
//           />

//           <FormField
//             control={form.control}
//             name="address2"
//             render={() => (
//               <FormItem>
//                 <FormLabel>Address Two:</FormLabel>
//                 <FormControl>
//                   <Input
//                     disabled={isMutating}
//                     aria-disabled={isMutating}
//                     autoCapitalize="none"
//                     autoComplete="address2"
//                     autoCorrect="off"
//                     placeholder="Enter Address 2"
//                     {...form.register("address2")}
//                   />
//                 </FormControl>
//                 <FormMessage />
//               </FormItem>
//             )}
//           />

//           <FormField
//             control={form.control}
//             name="city"
//             render={() => (
//               <FormItem>
//                 <FormLabel>City :</FormLabel>
//                 <FormControl>
//                   <Input
//                     disabled={isMutating}
//                     aria-disabled={isMutating}
//                     autoCapitalize="none"
//                     autoComplete="city"
//                     autoCorrect="off"
//                     placeholder="Enter City"
//                     {...form.register("city")}
//                   />
//                 </FormControl>
//                 <FormMessage />
//               </FormItem>
//             )}
//           />

//           <FormField
//             control={form.control}
//             name="state"
//             render={({field}) => (
//               <div className="space-y-2">
//                 <FormLabel>State :</FormLabel>
//                 <FormItem>
//                   <Popover>
//                     <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
//                       <Command>
//                         <CommandGroup {...form.register("state")}>
//                           {stateOptionsUSA.map((state, i) => (
//                             <CommandItem
//                               key={i}
//                               value={state.value}
//                               onSelect={(value) => {
//                                 field.onChange(value);
//                               }}
//                             >
//                               <CheckIcon
//                                 className={cn(
//                                   "mr-2 size-4",
//                                   field.value &&
//                                     field.value.toUpperCase() === state.value
//                                     ? "opacity-100"
//                                     : "opacity-0"
//                                 )}
//                               />
//                               {state.label}
//                             </CommandItem>
//                           ))}
//                         </CommandGroup>
//                       </Command>
//                     </PopoverContent>
//                     <PopoverTrigger asChild>
//                       <FormControl>
//                         <Button
//                           variant="outline"
//                           role="combobox"
//                           className={cn(
//                             "justify-between",
//                             !field.value && "text-accent-foreground",
//                             "w-full"
//                           )}
//                         >
//                           {field.value
//                             ? stateOptionsUSA.find(
//                                 (item) =>
//                                   item.value.toLowerCase() === field.value
//                               )?.label
//                             : "Select State"}
//                           <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
//                         </Button>
//                       </FormControl>
//                     </PopoverTrigger>
//                   </Popover>
//                   <FormMessage />
//                 </FormItem>
//               </div>
//             )}
//           />

//           <FormField
//             control={form.control}
//             name="country"
//             render={({field}) => (
//               <div className="space-y-2">
//                 <FormLabel>Country :</FormLabel>
//                 <FormItem>
//                   <Popover>
//                     <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
//                       <Command>
//                         <CommandGroup {...form.register("country")}>
//                           {countrySupplierSelectOptions.map((country, i) => (
//                             <CommandItem
//                               key={i}
//                               value={country.value}
//                               onSelect={(value) => {
//                                 field.onChange(value);
//                               }}
//                             >
//                               <CheckIcon
//                                 className={cn(
//                                   "mr-2 size-4",
//                                   field.value &&
//                                     field.value.toUpperCase() === country.value
//                                     ? "opacity-100"
//                                     : "opacity-0"
//                                 )}
//                               />
//                               {country.label}
//                             </CommandItem>
//                           ))}
//                         </CommandGroup>
//                       </Command>
//                     </PopoverContent>
//                     <PopoverTrigger asChild>
//                       <FormControl>
//                         <Button
//                           variant="outline"
//                           role="combobox"
//                           className={cn(
//                             "justify-between",
//                             !field.value && "text-accent-foreground",
//                             "w-full"
//                           )}
//                         >
//                           {field.value
//                             ? countrySupplierSelectOptions.find(
//                                 (item) =>
//                                   item.value.toLowerCase() === field.value
//                               )?.label
//                             : "Select Country"}
//                           <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
//                         </Button>
//                       </FormControl>
//                     </PopoverTrigger>
//                   </Popover>
//                   <FormMessage />
//                 </FormItem>
//               </div>
//             )}
//           />

//           <FormField
//             control={form.control}
//             name="zipCode"
//             render={() => (
//               <FormItem>
//                 <FormLabel>Zip Code :</FormLabel>
//                 <FormControl>
//                   <Input
//                     type="number"
//                     min="0"
//                     disabled={isMutating}
//                     aria-disabled={isMutating}
//                     autoCapitalize="none"
//                     autoComplete="zipCode"
//                     autoCorrect="off"
//                     placeholder="Enter Zip Code "
//                     {...form.register("zipCode")}
//                   />
//                 </FormControl>
//                 <FormMessage />
//               </FormItem>
//             )}
//           />

//           <FormField
//             name="contactName"
//             render={() => (
//               <FormItem>
//                 <FormLabel htmlFor="contactName">Contact name:</FormLabel>
//                 <FormControl>
//                   <Input
//                     disabled={isMutating}
//                     aria-disabled={isMutating}
//                     autoCapitalize="none"
//                     autoComplete="contactName"
//                     autoCorrect="off"
//                     placeholder="Enter contact name..."
//                     {...form.register("contactName")}
//                   />
//                 </FormControl>
//                 <FormMessage />
//               </FormItem>
//             )}
//           />
//           <FormField
//             name="phoneNo"
//             render={() => (
//               <FormItem>
//                 <FormLabel htmlFor="phoneNo">Phone Number:</FormLabel>
//                 <FormControl>
//                   <Input
//                     type="number"
//                     min="0"
//                     disabled={isMutating}
//                     aria-disabled={isMutating}
//                     autoCapitalize="none"
//                     autoComplete="phoneNo"
//                     autoCorrect="off"
//                     placeholder="Enter phone number..."
//                     {...form.register("phoneNo")}
//                   />
//                 </FormControl>
//                 <FormMessage />
//               </FormItem>
//             )}
//           />
//           <FormField
//             name="emailAddress"
//             render={() => (
//               <FormItem>
//                 <FormLabel htmlFor="emailAddress">Email Address:</FormLabel>
//                 <FormControl>
//                   <Input
//                     disabled={isMutating}
//                     aria-disabled={isMutating}
//                     autoCapitalize="none"
//                     autoComplete="emailAddress"
//                     autoCorrect="off"
//                     placeholder="Enter email address..."
//                     {...form.register("emailAddress")}
//                   />
//                 </FormControl>
//                 <FormMessage />
//               </FormItem>
//             )}
//           />

//           <FormField
//             control={form.control}
//             name="remitAddress1"
//             render={() => (
//               <FormItem>
//                 <FormLabel>Remit Address One:</FormLabel>
//                 <FormControl>
//                   <Input
//                     disabled={isMutating}
//                     aria-disabled={isMutating}
//                     autoCapitalize="none"
//                     autoComplete="remitAddress1"
//                     autoCorrect="off"
//                     placeholder="Enter Remit Address 1 "
//                     {...form.register("remitAddress1")}
//                   />
//                 </FormControl>
//                 <FormMessage />
//               </FormItem>
//             )}
//           />

//           <FormField
//             control={form.control}
//             name="remitAddress2"
//             render={() => (
//               <FormItem>
//                 <FormLabel>Remit Address Two:</FormLabel>
//                 <FormControl>
//                   <Input
//                     disabled={isMutating}
//                     aria-disabled={isMutating}
//                     autoCapitalize="none"
//                     autoComplete="remitAddress2"
//                     autoCorrect="off"
//                     placeholder="Enter Remit Address 2"
//                     {...form.register("remitAddress2")}
//                   />
//                 </FormControl>
//                 <FormMessage />
//               </FormItem>
//             )}
//           />

//           <FormField
//             control={form.control}
//             name="remitCity"
//             render={() => (
//               <FormItem>
//                 <FormLabel>Remit City :</FormLabel>
//                 <FormControl>
//                   <Input
//                     disabled={isMutating}
//                     aria-disabled={isMutating}
//                     autoCapitalize="none"
//                     autoComplete="remitCity"
//                     autoCorrect="off"
//                     placeholder="Enter Remit City"
//                     {...form.register("remitCity")}
//                   />
//                 </FormControl>
//                 <FormMessage />
//               </FormItem>
//             )}
//           />

//           <FormField
//             control={form.control}
//             name="remitState"
//             render={({field}) => (
//               <div className="space-y-2">
//                 <FormLabel>Remit State :</FormLabel>
//                 <FormItem>
//                   <Popover>
//                     <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
//                       <Command>
//                         <CommandGroup {...form.register("remitState")}>
//                           {stateOptionsUSA.map((remitState, i) => (
//                             <CommandItem
//                               key={i}
//                               value={remitState.value}
//                               onSelect={(value) => {
//                                 field.onChange(value);
//                               }}
//                             >
//                               <CheckIcon
//                                 className={cn(
//                                   "mr-2 size-4",
//                                   field.value &&
//                                     field.value.toUpperCase() ===
//                                       remitState.value
//                                     ? "opacity-100"
//                                     : "opacity-0"
//                                 )}
//                               />
//                               {remitState.label}
//                             </CommandItem>
//                           ))}
//                         </CommandGroup>
//                       </Command>
//                     </PopoverContent>
//                     <PopoverTrigger asChild>
//                       <FormControl>
//                         <Button
//                           variant="outline"
//                           role="combobox"
//                           className={cn(
//                             "justify-between",
//                             !field.value && "text-accent-foreground",
//                             "w-full"
//                           )}
//                         >
//                           {field.value
//                             ? stateOptionsUSA.find(
//                                 (item) =>
//                                   item.value.toLowerCase() === field.value
//                               )?.label
//                             : "Select Remit State"}
//                           <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
//                         </Button>
//                       </FormControl>
//                     </PopoverTrigger>
//                   </Popover>
//                   <FormMessage />
//                 </FormItem>
//               </div>
//             )}
//           />

//           <FormField
//             control={form.control}
//             name="remitCountry"
//             render={({field}) => (
//               <div className="space-y-2">
//                 <FormLabel>Remit Country :</FormLabel>
//                 <FormItem>
//                   <Popover>
//                     <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
//                       <Command>
//                         <CommandGroup {...form.register("remitCountry")}>
//                           {countrySupplierSelectOptions.map(
//                             (remitCountry, i) => (
//                               <CommandItem
//                                 key={i}
//                                 value={remitCountry.value}
//                                 onSelect={(value) => {
//                                   field.onChange(value);
//                                 }}
//                               >
//                                 <CheckIcon
//                                   className={cn(
//                                     "mr-2 size-4",
//                                     field.value &&
//                                       field.value.toUpperCase() ===
//                                         remitCountry.value
//                                       ? "opacity-100"
//                                       : "opacity-0"
//                                   )}
//                                 />
//                                 {remitCountry.label}
//                               </CommandItem>
//                             )
//                           )}
//                         </CommandGroup>
//                       </Command>
//                     </PopoverContent>
//                     <PopoverTrigger asChild>
//                       <FormControl>
//                         <Button
//                           variant="outline"
//                           role="combobox"
//                           className={cn(
//                             "justify-between",
//                             !field.value && "text-accent-foreground",
//                             "w-full"
//                           )}
//                         >
//                           {field.value
//                             ? countrySupplierSelectOptions.find(
//                                 (item) =>
//                                   item.value.toLowerCase() === field.value
//                               )?.label
//                             : "Select Remit Country"}
//                           <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
//                         </Button>
//                       </FormControl>
//                     </PopoverTrigger>
//                   </Popover>
//                   <FormMessage />
//                 </FormItem>
//               </div>
//             )}
//           />

//           <FormField
//             control={form.control}
//             name="remitZipCode"
//             render={() => (
//               <FormItem>
//                 <FormLabel>Remit Zip Code :</FormLabel>
//                 <FormControl>
//                   <Input
//                     type="number"
//                     min="0"
//                     disabled={isMutating}
//                     aria-disabled={isMutating}
//                     autoCapitalize="none"
//                     autoComplete="remitZipCode"
//                     autoCorrect="off"
//                     placeholder="Enter Remit Zip Code "
//                     {...form.register("remitZipCode")}
//                   />
//                 </FormControl>
//                 <FormMessage />
//               </FormItem>
//             )}
//           />
//         </div>
//         <div className="flex w-full justify-end">
//           <Button
//             type="submit"
//             className="gap-2 bg-success-foreground hover:bg-success-foreground/80 active:bg-success-foreground/80"
//             disabled={isMutating}
//             aria-disabled={isMutating}
//           >
//             Create Supplier
//             {isMutating ? (
//               <Spinner size="xs" />
//             ) : (
//               <PackagePlusIcon className="size-6" />
//             )}
//           </Button>
//         </div>
//       </form>
//     </Form>
//   );
// }

// /**
//  * AddNewSupplierPage Component
//  *
//  * This component renders the suppliers form
//  */
export function AddNewSupplierPage() {
  // Initialize Tanstack Query Mutation for Refreshing Suppliers

  return (
    <section>
      <div className="mb-4 flex flex-col-reverse gap-4 md:flex-row md:justify-between">
        <div className="flex flex-col">
          <Heading2>Supplier Onboarding</Heading2>
          <Paragraph>Fill out supplier information here.</Paragraph>
        </div>
        <Link
          to="/app/admin/supplier-management"
          className={cn(buttonVariants({variant: "secondary"}), "mb-4 gap-2")}
        >
          <span className="sr-only">Suppliers list</span>
          Suppliers List
          <MoveRightIcon className="size-6" />
        </Link>
      </div>

      <Card>
        <CardContent>
          {/* <AddSuppliersForm
            isMutating={addSupplierMutation.isPending}
            onSubmit={addSupplierMutation.mutate}
          /> */}
        </CardContent>
      </Card>
    </section>
  );
}
