import {useEffect} from "react";

import {Heading2, Paragraph} from "@/components/ui/typography";

import {StatementReconRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore, useStatementReconCustomerStore} from "@/lib/stores";
import {SelectCustomerSectionStatementRecon} from "@/modules/customers";
import {
  SRListDataTable,
  srListTableColumns,
  useSRStatementList,
} from "@/modules/statement-recon";

/**
 * Custom Hook: Manage the active route for the Statement Recon result page
 */
function useStatementReconStatementListRoute() {
  const {setStatementReconRoute} = useRoutesStore();

  useEffect(() => {
    // Set the active route based on predefined route schema
    setStatementReconRoute(
      StatementReconRoutesSchema.Values["/app/statement-recon/file-history"]
    );
  }, [setStatementReconRoute]);
}

export function StatementReconStatementListPage() {
  useStatementReconStatementListRoute();

  const {statementReconStoreCustomer} = useStatementReconCustomerStore();

  const externalId = statementReconStoreCustomer?.externalId || "";

  // TODO: Add externalId to the query when by externalId is implemented
  //const externalId = statementReconStoreCustomer?.externalId || "";
  const StatementListSRQuery = useSRStatementList(externalId);
  const StatementListSRData = StatementListSRQuery.data?.content || [];

  if (!statementReconStoreCustomer) {
    return <SelectCustomerSectionStatementRecon />;
  }

  return (
    <>
      <div className="flex flex-col">
        <Heading2>Statement List Page</Heading2>
        <Paragraph>Details of your statements here.</Paragraph>
      </div>
      <SRListDataTable
        columns={srListTableColumns}
        data={StatementListSRData}
        isSuccess={StatementListSRQuery.isSuccess}
        isPending={StatementListSRQuery.isPending}
        isError={StatementListSRQuery.isError}
      />
    </>
  );
}
