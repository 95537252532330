import {useState} from "react";

import {Link, Navigate, useParams} from "@tanstack/react-router";
import {
  CopyIcon,
  EyeOffIcon,
  LucideEdit,
  MessagesSquareIcon,
} from "lucide-react";

import {Badge} from "@/components/ui/badge";
import {Button} from "@/components/ui/button";
import {Card, CardContent, CardFooter, CardHeader} from "@/components/ui/card";
import {Separator} from "@/components/ui/separator";
import {Skeleton} from "@/components/ui/skeleton";
import {Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {useImRemitLiteCustomerStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {KeycloakRoleEnum, useAuthorization} from "@/modules/auth";
import {useGetCustomerProfileById} from "@/modules/customers";
import {
  getStatusClass,
  PaymetMethodType,
  ProxyPayDetailsFooter,
  RevealCardDetails,
  statusVariants,
  useGetProxyPayDetailsById,
  useSearchProxypay,
} from "@/modules/imremit";
import {
  NewProxyPayManagementLiteMultiView,
  ProxyPayCommentsData,
  ProxyPayProcessPaymentLite,
} from "@/modules/imremit-lite";

import {FallbackMessages} from "@/utils/constants";

/**
 * Custom hook for fetching customer profile data based on a participant ID.
 *
 * This hook uses `useGetCustomerProfileById` to retrieve customer profile information.
 * It returns an object containing the raw data of the customer profile, along with
 * flags indicating the state of the fetch operation, such as pending, fetched, and error states.
 *
 * @param {string} externalId - The unique identifier for the participant. This ID is used
 *                                 to fetch the corresponding customer profile data.
 *
 * @returns {{
 *   imCustomerProfileRawData: any,
 *   imCustomerProfileIsPending: boolean,
 *   imCustomerProfileAreFetched: boolean,
 *   imCustomerProfileError: boolean
 * }} An object containing the following properties:
 *
 * | Property                   | Type    | Description                                          |
 * |----------------------------|---------|------------------------------------------------------|
 * | imCustomerProfileRawData   | any     | The raw data of the customer profile as returned by `useGetCustomerProfileById`. |
 * | imCustomerProfileIsPending| boolean | A flag indicating if the customer profile data is still being fetched. |
 * | imCustomerProfileAreFetched| boolean | A flag indicating if the customer profile data has been fetched. |
 * | imCustomerProfileError     | boolean | A flag indicating if there was an error while fetching the customer profile data. |
 */
function useFetchCustomerProfileQuery(externalId?: string) {
  const {
    data: imCustomerProfileRawData,
    isPending: imCustomerProfileIsPending,
    // isError: imCustomerProfileError,
  } = useGetCustomerProfileById(externalId?.toString() || "");

  return {
    imCustomerProfileRawData,
    imCustomerProfileIsPending,
  };
}

// Defining the ProxyPaymentDetails component
export function ProxyPaymentDetails() {
  const {paymentId} = useParams({
    from: "/app/imremit-lite/proxy-pay-dashboard/$paymentId/view",
  });

  /**
   * @type {{imRemitLiteStoreCustomer: {externalId: string} | undefined}}
   */
  const {imRemitLiteStoreCustomer} = useImRemitLiteCustomerStore();

  //Card reveal button action state
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  /**
   * @type {string | undefined}
   */
  const externalId = imRemitLiteStoreCustomer?.externalId;

  //Fetch proxyPayViewQuery using custom hook `useGetProxyPayDetailsById`
  const proxyPayViewQuery = useGetProxyPayDetailsById(paymentId.toString());

  // initialize the above proxyPayViewData in a use memo hook
  const proxyPayViewData = proxyPayViewQuery.data?.content;

  const allPaymentManagementQuery = useSearchProxypay(
    proxyPayViewData?.externalId
  );
  const allPaymentManagementData = allPaymentManagementQuery.data?.content;

  // Fetching customer profile data
  const {imCustomerProfileRawData, imCustomerProfileIsPending} =
    useFetchCustomerProfileQuery(externalId);

  const {checkPoliciesAccess} = useAuthorization();

  // Handle unauthorized access
  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_PROXYPAY_DASHBOARD])) {
    return <NotAuthorizedPage />;
  }

  //for `process` , `re-process` & `add & update script ` button visibility status.
  const buttonStatuses: boolean =
    (proxyPayViewData?.paymentMode === "Pay By Web" &&
      proxyPayViewData.customerStatus != "Paid" &&
      proxyPayViewData.paymentRequestPaymentMode === "") ||
    proxyPayViewData?.paymentRequestPaymentMode === "AUTOMATION_PAY_BY_WEB"
      ? true
      : false;

  //Disable the `process` & `Re-process` button if the below condition is TRUE
  const disabledProcessButton: boolean =
    proxyPayViewData?.statusName == "Paid-Verification Pending" ||
    proxyPayViewData?.statusName == "Auto Proxy Payment Success" ||
    proxyPayViewData?.customerStatus == "Paid - Pending Verification" ||
    proxyPayViewData?.customerStatus == "Blocked" ||
    proxyPayViewData?.customerStatus == "Delivered" ||
    proxyPayViewData?.customerStatus == "Closed" ||
    proxyPayViewData?.customerStatus == "Duplicate"
      ? true
      : false;

  //Reason paragraph visibility status.
  const reasonParagraphStatus: boolean =
    proxyPayViewData?.customerStatus != "Open" &&
    proxyPayViewData?.customerStatus != "Paid - Pending Verification" &&
    proxyPayViewData?.customerStatus != "Paid" &&
    proxyPayViewData?.customerStatus != "Delivered" &&
    proxyPayViewData?.customerStatus != "Closed" &&
    proxyPayViewData?.paymentRequestPaymentMode === "AUTOMATION_PAY_BY_WEB"
      ? true
      : false;

  const isPaymentIdInSavedPayments = allPaymentManagementData?.some(
    (item) => item.paymentDetailId === paymentId
  );

  if (!isPaymentIdInSavedPayments && proxyPayViewQuery.isError) {
    return <Navigate to="/app/imremit-lite/proxy-pay-dashboard" />;
  }

  return (
    <section>
      <NewProxyPayManagementLiteMultiView />
      <Card>
        <CardHeader className="flex gap-2">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2">
            <div className="grid grid-cols-3 text-lg">
              <Paragraph className="flex flex-col text-lg">
                <strong>Payment Number: </strong>
                {proxyPayViewQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  proxyPayViewData?.paymentNumber ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>

              <Paragraph className="flex flex-col text-lg">
                <strong>Status: </strong>
                {proxyPayViewQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : proxyPayViewData?.customerStatus ? (
                  <span
                    className={cn(
                      statusVariants({
                        statusColour: getStatusClass(
                          proxyPayViewData.customerStatus
                        ),
                      })
                    )}
                  >
                    {proxyPayViewData.customerStatus}
                  </span>
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )}
              </Paragraph>

              <Paragraph className="flex flex-col text-lg">
                {reasonParagraphStatus ? (
                  <>
                    <strong>Reason: </strong>
                    {proxyPayViewQuery.isPending ? (
                      <Skeleton className="h-6 w-full" />
                    ) : proxyPayViewData?.errorMessage ? (
                      proxyPayViewData.errorMessage
                    ) : (
                      <Badge variant="destructive">
                        {FallbackMessages.NO_DATA}
                      </Badge>
                    )}
                  </>
                ) : (
                  ""
                )}
              </Paragraph>
            </div>

            <Paragraph className="flex justify-end gap-2 text-lg">
              {buttonStatuses && proxyPayViewData && (
                <ProxyPayProcessPaymentLite
                  proxyPayViewData={proxyPayViewData}
                  isPending={proxyPayViewQuery.isPending}
                  disabledProcessButton={disabledProcessButton}
                />
              )}

              {buttonStatuses && proxyPayViewData?.scriptFlag == true && (
                <Link
                  to="/app/imremit-lite/supplier-script-management/$moduleName/$supplierId/edit/$paymentId"
                  params={{
                    moduleName: "imremit-lite",
                    supplierId: proxyPayViewData.supplierId.toString(),
                    paymentId: proxyPayViewData.paymentDetailId,
                  }}
                >
                  <Button
                    variant="default"
                    className="gap-2 whitespace-nowrap bg-neutral text-foreground hover:bg-neutral/80 active:bg-neutral/90"
                    disabled={proxyPayViewQuery.isPending}
                  >
                    <span className="sr-only">Update Script</span>
                    <LucideEdit className="size-4" />
                    <Paragraph>Update Script</Paragraph>
                  </Button>
                </Link>
              )}

              {buttonStatuses && proxyPayViewData?.scriptFlag == false && (
                <Link
                  to="/app/imremit-lite/supplier-script-management/$moduleName/$supplierId/create/$paymentId"
                  params={{
                    moduleName: "imremit-lite",
                    supplierId: proxyPayViewData.supplierId.toString(),
                    paymentId: proxyPayViewData.paymentDetailId,
                  }}
                >
                  <Button
                    variant="default"
                    className="gap-2 whitespace-nowrap bg-neutral text-foreground hover:bg-neutral/80 active:bg-neutral/90"
                  >
                    <span className="sr-only">Add Script</span>
                    <LucideEdit className="size-4" />
                    <Paragraph>Add Script</Paragraph>
                  </Button>
                </Link>
              )}

              {proxyPayViewQuery.isPending ? (
                <Skeleton className="h-6 w-full" />
              ) : proxyPayViewData?.paymentMode === "Pay By Web" &&
                proxyPayViewData.customerStatus != "Paid" &&
                proxyPayViewData.customerStatus !=
                  "Paid - Pending Verification" ? (
                <PaymetMethodType
                  paymentType={proxyPayViewData.paymentRequestPaymentMode}
                  paymentId={proxyPayViewData.paymentDetailId}
                  scriptFlag={proxyPayViewData.scriptFlag}
                  disabled={
                    !checkPoliciesAccess([
                      KeycloakRoleEnum.UPDATE_PAY_BY_WEB_LITE,
                    ])
                  }
                />
              ) : (
                ""
              )}

              {proxyPayViewData ? (
                <ProxyPayCommentsData proxyPayViewData={proxyPayViewData} />
              ) : (
                <Button variant="outline" disabled>
                  <span className="sr-only">Comments</span>
                  <MessagesSquareIcon className="mr-2 size-4" />
                  <Paragraph>Comments</Paragraph>
                </Button>
              )}
            </Paragraph>
          </div>
        </CardHeader>

        <CardContent>
          <Separator className="my-4" />
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
            <div className="grid grid-cols-1">
              <Paragraph className="flex flex-col text-lg">
                <strong>Amount </strong>
                {proxyPayViewQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : proxyPayViewData?.totalAmountSent ? (
                  `$ ${proxyPayViewData.totalAmountSent.toString()} (USD)`
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )}
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Outstanding Balance: </strong>
                {proxyPayViewQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : proxyPayViewData?.balanceAmount ? (
                  `$ ${proxyPayViewData.balanceAmount.toString()} (USD)`
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )}
                <small>Transactions may take upto 3 days to post.</small>
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Amount Taken: </strong>
                {proxyPayViewQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : proxyPayViewData?.amountTaken ? (
                  `$ ${proxyPayViewData.amountTaken.toString()} (USD)`
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )}
              </Paragraph>
            </div>
            <div className="grid grid-cols-1">
              <Paragraph className="flex flex-col text-lg">
                <strong>Authorization Type </strong>
                {proxyPayViewQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : proxyPayViewData?.singleTranExactAuth ? (
                  "Single Authorization"
                ) : (
                  "Multi Authorization"
                )}
              </Paragraph>
              {/* <Paragraph className="flex flex-col text-lg">
                <strong>Card Number: </strong>
                {proxyPayViewQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  proxyPayViewData?.cardNumber ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph> */}
              {proxyPayViewData &&
              proxyPayViewData.customerStatus !==
                "Paid - Pending Verification" &&
              // proxyPayViewData.remittanceType == "Pay By Web" &&
              checkPoliciesAccess([KeycloakRoleEnum.READ_CARD_DETAILS_LITE]) ? (
                <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                  {isButtonClicked ? (
                    <RevealCardDetails
                      paymentDetailId={proxyPayViewData.paymentDetailId}
                    />
                  ) : (
                    <Button
                      className="gap-2"
                      size="sm"
                      variant="outline"
                      onClick={() => {
                        setIsButtonClicked(true);
                      }}
                    >
                      <EyeOffIcon className="size-4" />
                      <Paragraph>Reveal Card Details</Paragraph>
                    </Button>
                  )}
                </div>
              ) : (
                <Paragraph className="flex flex-col text-lg">
                  <strong>Card Number: </strong>
                  {proxyPayViewQuery.isPending ? (
                    <Skeleton className="h-6 w-full" />
                  ) : (
                    proxyPayViewData?.cardNumber ?? (
                      <Badge variant="destructive">
                        {FallbackMessages.NO_DATA}
                      </Badge>
                    )
                  )}
                </Paragraph>
              )}
              {!isButtonClicked && (
                <>
                  <Paragraph className="flex flex-col text-lg">
                    <strong>Security Code: </strong>
                    {proxyPayViewQuery.isPending ? (
                      <Skeleton className="h-6 w-full" />
                    ) : (
                      proxyPayViewData?.securityCode ?? (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )
                    )}
                  </Paragraph>
                  <Paragraph className="flex flex-col text-lg">
                    <strong>Expiration Date: </strong>
                    {proxyPayViewQuery.isPending ? (
                      <Skeleton className="h-6 w-full" />
                    ) : (
                      proxyPayViewData?.expirationDate ?? (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )
                    )}
                  </Paragraph>
                </>
              )}

              <Paragraph className="flex flex-col text-lg">
                <strong>Zip Code: </strong>
                {imCustomerProfileIsPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  imCustomerProfileRawData?.content.zip
                )}
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Payment Zip Code / Postal Code: </strong>
                {proxyPayViewQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  proxyPayViewData?.paymentZip ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
            </div>
            <div className="grid grid-cols-1">
              <Paragraph className="flex flex-col text-lg">
                <strong>Bank </strong>
                {proxyPayViewQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  proxyPayViewData?.providerName ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Sent Date:</strong>
                {proxyPayViewQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  proxyPayViewData?.initiatedDate ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Payment End Date:</strong>
                {proxyPayViewQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  proxyPayViewData?.endDate ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Remittance method:</strong>
                {proxyPayViewQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  proxyPayViewData?.paymentMode ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
            </div>

            <div>
              <Paragraph className="flex flex-col text-lg">
                <strong>Remittance Delivery </strong>
                Contact Details
              </Paragraph>
              {proxyPayViewQuery.isPending ? (
                <div className="space-y-2">
                  <Skeleton className="h-4 w-[250px]" />
                  <Skeleton className="h-4 w-[250px]" />
                  <Skeleton className="h-4 w-[200px]" />
                </div>
              ) : (
                proxyPayViewData?.paymentMode == "Pay By Web" && (
                  <>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>User ID:</strong>
                      {proxyPayViewData.supplierUserId || "-"}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Web URL:</strong>
                      <span>
                        <span className="align-super">
                          {proxyPayViewData.proxyWebUrl
                            ? proxyPayViewData.proxyWebUrl.substring(0, 30)
                            : "-"}
                        </span>
                        {proxyPayViewData.proxyWebUrl ? (
                          <Button
                            size="sm"
                            className="w-10"
                            variant="ghost"
                            onClick={() => {
                              if (proxyPayViewData.proxyWebUrl) {
                                void navigator.clipboard.writeText(
                                  proxyPayViewData.proxyWebUrl
                                );
                                toast({
                                  description: "Copied to clipboard!",
                                });
                              }
                            }}
                          >
                            <CopyIcon />
                          </Button>
                        ) : (
                          ""
                        )}
                      </span>
                    </Paragraph>
                  </>
                )
              )}

              {proxyPayViewQuery.isPending
                ? ""
                : proxyPayViewData?.paymentMode == "Pay By Phone" && (
                    <>
                      <Paragraph className="flex flex-col text-lg">
                        <strong>Contact Name:</strong>
                        {proxyPayViewData.remittanceName || "-"}
                      </Paragraph>
                      <Paragraph className="flex flex-col text-lg">
                        <strong>Contact Phone:</strong>
                        {proxyPayViewData.remittancePhoneNumber || "-"}
                      </Paragraph>
                    </>
                  )}

              {proxyPayViewQuery.isPending
                ? ""
                : proxyPayViewData?.paymentMode == "Pay By Email" && (
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Remittance Email:</strong>
                      {proxyPayViewData.remittanceName || "-"}
                    </Paragraph>
                  )}
            </div>
          </div>
          <Separator className="my-4" />
          <div className="grid grid-cols-1 gap-4 pt-3 md:grid-cols-2 xl:grid-cols-3">
            <Paragraph className="text-lg">
              <strong>Supplier: </strong>
              {proxyPayViewQuery.isPending ? (
                <Skeleton className="h-6 w-full" />
              ) : (
                proxyPayViewData?.supplierName ?? (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )
              )}
            </Paragraph>
            <Paragraph className="text-lg">
              <strong>Supplier ID: </strong>
              {proxyPayViewQuery.isPending ? (
                <Skeleton className="h-6 w-full" />
              ) : (
                proxyPayViewData?.supplierNumber ?? (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )
              )}
            </Paragraph>
            <Paragraph className="text-lg">
              <strong>Payee Name: </strong>
              {proxyPayViewQuery.isPending ? (
                <Skeleton className="h-6 w-full" />
              ) : (
                proxyPayViewData?.facilityName ?? (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )
              )}
            </Paragraph>
          </div>
        </CardContent>

        <CardFooter className="mt-4 flex">
          <ProxyPayDetailsFooter
            paymentMode={proxyPayViewData?.paymentMode || ""}
            id={proxyPayViewData?.paymentDetailId || ""}
          />
        </CardFooter>
      </Card>
    </section>
  );
}
