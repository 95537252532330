import {z} from "zod";

import {cronIntervalRegex, windowStartAndEndTimeRegex} from "@/utils/constants";

export const suppliersDetailsSchema = z.object({
  supplierId: z.number(),
  customerName: z.string().nullable().optional(),
  supplierNumber: z.string().nullable().optional(),
  supplierName: z.string().nullable().optional(),
  enrolled: z.boolean().nullable().optional(),

  clientKey: z.string().nullable().optional(),
  websiteKey: z.string().nullable().optional(),

  taxId: z.string().nullable().optional(),
  supplierEmail: z.string().nullable().optional(),
  onboardStatus: z.string().nullable().optional(),
  address1: z.string().nullable().optional(),
  address2: z.string().nullable().optional(),
  address3: z.string().nullable().optional(),
  address4: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  state: z.string().nullable().optional(),
  zip: z.string().nullable().optional(),
  locationCode: z.string().nullable().optional(),
  phoneNumber: z.string().nullable().optional(),
  country: z.string().nullable().optional(),
  contactName: z.string().nullable().optional(),
  declinedReason: z.string().nullable().optional(),
  contactEmail: z.string().nullable().optional(),

  declinedDate: z.string().nullable().optional(),
  enrolledDate: z.string().nullable().optional(),
  supplierSingleUseFlag: z.boolean().nullable().optional(),
  supplierCardOnFileFlag: z.boolean().nullable().optional(),
  supplierPaymentCounterFlag: z.boolean().nullable().optional(),
  supplierBufferLimitAmount: z.number().nullable().optional(),
  supplierCardLimitAmount: z.number().nullable().optional(),
  internalEmailAlertFlag: z.boolean().nullable().optional(),

  createTimestamp: z.string().nullable().optional(),
  lastEditTimestamp: z.string().nullable().optional(),

  paymentMode: z.string().nullable().optional(),

  scriptPaymentLimiter: z.boolean().nullable().optional(),
  paymentLimitNumber: z.number().nullable().optional(),

  loginCredentialDTOs: z
    .array(
      z.object({
        credentialId: z.string().max(140).nullable().optional(),
        userId: z.string().nullable().optional(),
        password: z.string(),
        scriptOptions: z.boolean().default(false).nullable(),
        paymentFileNameIdentifier: z.string().nullable().optional(),
      })
    )
    .optional()
    .nullable(),
  proxyPayUrl: z.string().nullable().optional(),
  userId: z.string().nullable().optional(),
  password: z.string().nullable().optional(),
  remittanceEmail: z.string().nullable().optional(),
  remittanceName: z.string().nullable().optional(),
  remittancePhoneNumber: z.string().nullable().optional(),
  w9formUrl: z.string().nullable().optional(),

  maxAmountPerTransaction: z.number().nullable().optional(),
  minAmountPerTransaction: z.number().nullable().optional(),
  amountTransactionLimitPerDay: z.number().nullable().optional(),
  noOfTransactionPerDay: z.number().nullable().optional(),

  paymentRestrictions: z.boolean().nullable().optional(),
  selfRegisteredFlag: z.boolean().nullable().optional(),
  scriptFlag: z.boolean().nullable().optional(),
  authorizationType: z.string().nullable().optional(),
  enableScriptFlagField: z.boolean().optional().nullable(),

  batchSize: z.number().nullable().optional(),
  cronInterval: z.string().optional().nullable(),
  maintenanceWindowStart: z.string().optional().nullable(),
  maintenanceWindowEnd: z.string().optional().nullable(),
  appvanceScenarioUrl: z.string().optional().nullable(),
  appvanceAuthUrl: z.string().nullable().optional(),
  appvanceProcessingFlag: z.boolean().optional().nullable(),

  mfaEmail: z.string().nullable().optional(),
  mfaPassword: z.string().nullable().optional(),
});

export const suppliersDetailsLiteSchema = z.object({
  batchSize: z.number().nullable().optional(),
  cronInterval: z
    .string()
    .refine(
      (val) => val === "" || cronIntervalRegex.test(val),

      {
        message: "Invalid cron interval format",
      }
    )
    .optional()
    .nullable(),
  maintenanceWindowStart: z
    .string()
    .refine(
      (val) => val === "" || windowStartAndEndTimeRegex.test(val),

      {
        message: "Invalid window start time format",
      }
    )
    .optional()
    .nullable(),
  maintenanceWindowEnd: z
    .string()
    .refine(
      (val) => val === "" || windowStartAndEndTimeRegex.test(val),

      {
        message: "Invalid window end time format",
      }
    )
    .optional()
    .nullable(),
  supplierId: z.number(),
  customerName: z.string().nullable().optional(),
  supplierNumber: z.string().nullable().optional(),
  supplierName: z.string().nullable().optional(),
  enrolled: z.boolean().nullable().optional(),

  clientKey: z.string().nullable().optional(),
  websiteKey: z.string().nullable().optional(),

  taxId: z.string().nullable().optional(),
  supplierEmail: z.string().nullable().optional(),
  onboardStatus: z.string().nullable().optional(),
  address1: z.string().nullable().optional(),
  address2: z.string().nullable().optional(),
  address3: z.string().nullable().optional(),
  address4: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  state: z.string().nullable().optional(),
  zip: z.string().nullable().optional(),
  locationCode: z.string().nullable().optional(),
  phoneNumber: z.string().nullable().optional(),
  country: z.string().nullable().optional(),
  contactName: z.string().nullable().optional(),
  declinedReason: z.string().nullable().optional(),
  contactEmail: z.string().nullable().optional(),

  declinedDate: z.string().nullable().optional(),
  enrolledDate: z.string().nullable().optional(),
  supplierSingleUseFlag: z.boolean().nullable().optional(),
  supplierCardOnFileFlag: z.boolean().nullable().optional(),
  supplierPaymentCounterFlag: z.boolean().nullable().optional(),
  supplierBufferLimitAmount: z.number().nullable().optional(),

  createTimestamp: z.string().nullable().optional(),
  lastEditTimestamp: z.string().nullable().optional(),

  paymentMode: z.string().nullable().optional(),

  scriptPaymentLimiter: z.boolean().nullable().optional(),
  paymentLimitNumber: z.number().nullable().optional(),

  proxyPayUrl: z.string().nullable().optional(),
  remittanceName: z.string().nullable().optional(),
  remittancePhoneNumber: z.string().nullable().optional(),
  w9formUrl: z.string().nullable().optional(),

  maxAmountPerTransaction: z.number().nullable().optional(),
  minAmountPerTransaction: z.number().nullable().optional(),
  amountTransactionLimitPerDay: z.number().nullable().optional(),
  noOfTransactionPerDay: z.number().nullable().optional(),

  paymentRestrictions: z.boolean().nullable().optional(),
  selfRegisteredFlag: z.boolean().nullable().optional(),
  scriptFlag: z.boolean().nullable().optional(),
  universalAcceptor: z.boolean().nullable().optional(),
  universalAcceptorComments: z.string().nullable().optional(),
  doesSupplierTakeFees: z.boolean().nullable().optional(),
  supplierFeesComments: z.string().nullable().optional(),
  dataRequirementsToMakePayment: z.string().nullable().optional(),
  department: z.string().nullable().optional(),
  authorizationType: z.string().nullable().optional(),
  additionalPaymentRestrictions: z.string().nullable().optional(),
  confirmationOfPaymentEmail: z.string().nullable().optional(),
  contactPhone: z.string().nullable().optional(),

  userId: z.string().nullable().optional(),
  password: z.string().nullable().optional(),
  enableScriptFlagField: z.boolean().optional().nullable(),
  appvanceScenarioUrl: z.string().optional().nullable(),
  appvanceAuthUrl: z.string().nullable().optional(),
  appvanceProcessingFlag: z.boolean().optional().nullable(),
});

export const suppliersCriteriaSchema = z.object({
  customerName: z.string(),
  supplierId: z.array(z.number()).nullable(),
  supplierNumber: z.string().nullable(),
  supplierEmail: z.string().nullable(),
  zip: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  onboardStatus: z.string().nullable(),
  lastEditTimestamp: z.string().nullable(),
});

export type SuppliersCriteriaType = z.infer<typeof suppliersCriteriaSchema>;

export type SupplierDetailsType = z.infer<typeof suppliersDetailsSchema>;
export type SupplierDetailsLiteType = z.infer<
  typeof suppliersDetailsLiteSchema
>;
