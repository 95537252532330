import type * as React from "react";

import {cva, type VariantProps} from "class-variance-authority";

import {cn} from "@/lib/utils";

const badgeVariants = cva(
  "inline-flex text-center items-center border rounded-full px-2.5 py-0.5 text-xs font-bold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 capitalize max-w-fit whitespace-nowrap",
  {
    variants: {
      variant: {
        default: "bg-primary hover:bg-primary/80 border-transparent text-root",
        secondary:
          "bg-secondary hover:bg-secondary/80 border-transparent text-foreground",
        // keep this one until we finish migrating from destructive
        destructive:
          "bg-secondary hover:bg-secondary/80 border-transparent text-foreground",
        success:
          "group success border-transparent bg-neutral-foreground text-foreground",
        outline: "text-foreground",
        danger:
          "bg-destructive-foreground border-transparent text-root hover:bg-destructive-foreground/80 hover:text-foreground/80 active:bg-destructive/90 active:text-foreground/90",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({className, variant, ...props}: BadgeProps) {
  return (
    <span className={cn(badgeVariants({variant}), className)} {...props} />
  );
}

export {Badge, badgeVariants};
