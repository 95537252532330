import KcAdminClient from "@keycloak/keycloak-admin-client";

export const kcAdminClient = new KcAdminClient({
  baseUrl: import.meta.env.VITE_KEYCLOAK_URL
    ? (import.meta.env.VITE_KEYCLOAK_URL as string)
    : "https://ntauth.iterationm.com",
  realmName: import.meta.env.VITE_KEYCLOAK_REALM
    ? (import.meta.env.VITE_KEYCLOAK_REALM as string)
    : "dev",
});
