import {useEffect} from "react";

import {Outlet} from "@tanstack/react-router";

import {ReturnToTop} from "@/components/craft/return-to-top";

import {HelpRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";

export function HelpOutlet() {
  const {setHelpRoute} = useRoutesStore();

  useEffect(() => {
    setHelpRoute(HelpRoutesSchema.Values["/app/help"]);
  }, [setHelpRoute]);

  return (
    <article role="region" aria-roledescription="This is the Help page.">
      <Outlet />
      <ReturnToTop />
    </article>
  );
}
