import {Navigate, useNavigate} from "@tanstack/react-router";

export function DupesDashboardRedirect() {
  // Get the navigate function from the router
  const navigate = useNavigate();

  void navigate({
    to: "/app/duplicate-payments/dashboard",
  });

  return <Navigate to="/app/duplicate-payments/dashboard" />;
}
