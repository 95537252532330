import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {BadgeCheck, X} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Spinner} from "@/components/ui/spinner";
import {Switch} from "@/components/ui/switch";
import {toast} from "@/components/ui/use-toast";

import {
  keycloakUserResetPasswordFormSchema,
  resetUserCerdentialsFn,
} from "@/modules/admin";

interface UserResetPasswordProps {
  userId: string;
  resetDialogOpen: boolean;
  setResetDialogOpen: (open: boolean) => void;
}

export function UserResetPassword({
  userId,
  resetDialogOpen,
  setResetDialogOpen,
}: UserResetPasswordProps) {
  // Declaring the state for comment dialog

  const userResetPasswordForm = useForm<
    z.infer<typeof keycloakUserResetPasswordFormSchema>
  >({
    resolver: zodResolver(keycloakUserResetPasswordFormSchema),
    defaultValues: {
      type: "password",
      temporary: false,
    },
  });

  const resetUserMutation = useMutation({
    mutationFn: (data: z.infer<typeof keycloakUserResetPasswordFormSchema>) => {
      return resetUserCerdentialsFn(data, userId);
    },
    onSuccess: (responseData) => {
      console.log("responseData", responseData);
      setResetDialogOpen(false);
      toast({
        variant: "success",
        title: "Success!",
        description: "Password successfully updated.",
      });
    },
    onError: (error: unknown) => {
      console.log("error:", error);
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      setResetDialogOpen(false);
      console.error("errorMessage", errorMessage);
      //204 : noContent consider as success - TODO need to refactor
      errorMessage === "Unexpected end of JSON input"
        ? toast({
            variant: "success",
            title: "Success!",
            description: "Password successfully updated.",
          })
        : toast({
            variant: "destructive",
            title: "Error!",
            description: "Failed to reset password",
          });
    },
  });

  const isMutating = resetUserMutation.isPending;

  function onSubmit(data: z.infer<typeof keycloakUserResetPasswordFormSchema>) {
    resetUserMutation.mutate(data);
  }

  return (
    <>
      <Dialog open={resetDialogOpen} onOpenChange={setResetDialogOpen}>
        <DialogTrigger>
          <div className="relative flex cursor-pointer select-none items-center justify-between rounded-sm text-sm  outline-none transition-colors hover:cursor-pointer focus:bg-destructive  data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50">
            Reset Password
            <span className="sr-only"> Reset Password</span>
          </div>
        </DialogTrigger>
        <DialogContent removeCloseButton>
          <Form {...userResetPasswordForm}>
            <form onSubmit={userResetPasswordForm.handleSubmit(onSubmit)}>
              <DialogHeader>
                <DialogTitle>Reset Password </DialogTitle>
                <Button
                  type="button"
                  variant={"ghost"}
                  className="absolute right-4 top-4 h-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
                  onClick={() => {
                    setResetDialogOpen(false);
                  }}
                >
                  <X className="text-default h-4 w-4" />
                  <span className="sr-only">Close</span>
                </Button>
                <DialogDescription>Update your password !</DialogDescription>
              </DialogHeader>
              <div className="mt-4 flex items-center gap-2 space-x-2">
                <div className="grid flex-1 gap-2">
                  <FormField
                    control={userResetPasswordForm.control}
                    name="value"
                    render={({field}) => (
                      <FormItem>
                        <FormLabel htmlFor="value" showMandatoryAsterisk>
                          Password
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="password"
                            disabled={isMutating}
                            aria-disabled={isMutating}
                            placeholder="Password"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={userResetPasswordForm.control}
                    name="cvalue"
                    render={({field}) => (
                      <FormItem>
                        <FormLabel htmlFor="cvalue" showMandatoryAsterisk>
                          New password confirmation
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="password"
                            disabled={isMutating}
                            aria-disabled={isMutating}
                            placeholder="New password confirmation"
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={userResetPasswordForm.control}
                    name="temporary"
                    render={({field}) => (
                      <FormItem className="flex flex-row items-center justify-between gap-2 rounded-lg border p-4">
                        <div className="space-y-0.5">
                          <FormLabel className="text-base">Temporary</FormLabel>
                          <FormDescription>
                            If enabled, the user must change the password on
                            next login
                          </FormDescription>
                        </div>
                        <FormControl>
                          <Switch
                            className="data-[state=checked]:bg-green-500 data-[state=unchecked]:bg-red-500"
                            checked={field.value}
                            onCheckedChange={field.onChange}
                          />
                        </FormControl>
                        <div className="space-y-0.5">
                          {userResetPasswordForm.watch("temporary")
                            ? "On"
                            : "Off"}
                        </div>
                      </FormItem>
                    )}
                  />
                </div>
              </div>

              <DialogFooter className="mt-4 sm:justify-end">
                <DialogClose asChild>
                  <Button
                    type="submit"
                    disabled={isMutating}
                    aria-disabled={isMutating}
                    variant="secondary"
                    className=" gap-2"
                  >
                    {isMutating ? (
                      <Spinner size="xs" />
                    ) : (
                      <BadgeCheck className="size-5" />
                    )}
                    Save
                  </Button>
                </DialogClose>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
}
