import {useEffect, useMemo} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {useNavigate} from "@tanstack/react-router";
import {
  CheckIcon,
  ChevronsUpDownIcon,
  MoveLeftIcon,
  MoveRightIcon,
  SaveIcon,
} from "lucide-react";
import {useForm, useFormContext} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {Card, CardContent} from "@/components/ui/card";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Spinner} from "@/components/ui/spinner";
import {Heading2} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {cn} from "@/lib/utils";
import {
  addCustomerPaymentMethodFn,
  AddPaymentMethodFormSchema,
  CustomerOnboardingTabs,
  PaymentMethodDataTable,
  paymentMethodTableColumns,
} from "@/modules/admin";
import {useKeyCloakInstanceStore} from "@/modules/auth";
import {
  useGetPaymentMethodNames,
  useGetPaymentProviders,
  useGetProviderPaymentMethodNames,
} from "@/modules/imremit";

import {FallbackMessages, ModuleNames} from "@/utils/constants";

/**
 * APIProps Interface
 *
 * @interface APIProps
 * @property {object} allPaymentProviders - Gets the payment providers optional.
 * @property {object} allProviderPaymentMethodNames - Gets the payment method names optional
 * @property {boolean} isMutating - Gets the customer ID.
 */
interface APIProps {
  isMutating: boolean;
  externalId?: string;
}

/**
 * Transforms a payment method item to a select option format.
 * @param item - The payment method item with id and optional name.
 * @returns An object with `value` as string and `label`.
 */
function toPaymentProviderOption(item: {
  providerId: number;
  providerName?: string;
}) {
  return {
    value: item.providerId.toString(),
    label: item.providerName ?? FallbackMessages.NOT_APPLICABLE,
  };
}

/**
 * Transforms a payment method item to a select option format.
 * @param item - The payment method item with id and optional name.
 * @returns An object with `value` as string and `label`.
 */
function toProviderPaymentMethodNameOption(item: {
  paymentMethodId: number;
  methodName?: string;
}) {
  return {
    value: item.paymentMethodId.toString(),
    label: item.methodName ?? FallbackMessages.NOT_APPLICABLE,
  };
}

/**
 * Custom hook for fetching and memoizing payment providers.
 * @returns A memoized array of payment providers options.
 */
export function usePaymentProviders() {
  const {data} = useGetPaymentProviders();

  // Memoizes the transformed payment providers data for performance optimization
  return useMemo(
    () => data?.content.map(toPaymentProviderOption) ?? [],
    [data]
  );
}

/**
 * Custom hook for fetching and memoizing payment method names.
 * @param externalId - Optional participant ID to filter payment methods.
 * @returns A memoized array of payment method names options.
 */
export function useProviderPaymentMethodNames(
  externalId?: string,
  providerSelected?: string
) {
  const {data} = useGetPaymentMethodNames(externalId?.toString());

  const providerId =
    providerSelected ?? data?.content[0]?.providerId ?? undefined;

  const {data: providerPaymentMethodNames} = useGetProviderPaymentMethodNames(
    providerId?.toString()
  );

  // Memoizes the transformed payment providers data for performance optimization
  return useMemo(
    () =>
      providerPaymentMethodNames?.content.map(
        toProviderPaymentMethodNameOption
      ) ?? [],
    [providerPaymentMethodNames]
  );
}

/**
 * PaymentMethodFormProps Interface
 *
 * @interface PaymentMethodFormProps
 * @property {number} externalId - Gets the customer ID.
 * @property {string} module -  Gets the module name
 */
interface PaymentMethodFormProps {
  externalId: string;
  module: string;
}

/**
 * PaymentMethodForm Component
 *
 * This component renders the PaymentMethodForm form and handles its behavior.
 *
 * @param {PaymentMethodFormProps} props - Properties passed to the component
 * @returns {JSX.Element} - Rendered component
 */

export function PaymentMethodForm({
  externalId,
  module,
}: PaymentMethodFormProps) {
  const storeKeyCloakInstance =
    useKeyCloakInstanceStore().storeKeyCloakInstance;

  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );

  // Define form using react-hook-form
  const addPaymentMethodForm = useForm<
    z.infer<typeof AddPaymentMethodFormSchema>
  >({
    defaultValues: {
      modifiedBy: parsedUserInfo?.name as string,
    },
    mode: "onChange",
    resolver: zodResolver(AddPaymentMethodFormSchema),
  });

  const navigate = useNavigate();
  const params = {
    externalId: externalId.toString(),
    module,
  };

  // const allPaymentMethods = usePaymentMethods(externalId);

  const PaymentMethodQuery = useGetPaymentMethodNames(externalId);
  const PaymentMethodData = useMemo(
    () => PaymentMethodQuery.data?.content ?? [],
    [PaymentMethodQuery.data]
  );

  const addPaymentMethodMutation = useAddPaymentMethod(externalId);

  const {
    setValue,
    // formState: {errors},
  } = addPaymentMethodForm;
  // console.log(errors, "errors");

  useEffect(() => {
    if (PaymentMethodData[0]?.providerId) {
      setValue("providerId", PaymentMethodData[0]?.providerId.toString());
    }
  }, [setValue, PaymentMethodData]);

  function handleAdd(data: z.infer<typeof AddPaymentMethodFormSchema>) {
    addPaymentMethodMutation.mutate(data);
  }

  return (
    <section>
      <div className="mb-2 flex w-full flex-col items-start justify-between gap-2 lg:flex-row lg:items-center">
        <Heading2 className="flex gap-2">Payment Method</Heading2>
        <CustomerOnboardingTabs />
      </div>

      <Card>
        <CardContent>
          <Form {...addPaymentMethodForm}>
            <form onSubmit={addPaymentMethodForm.handleSubmit(handleAdd)}>
              <div className="mb-5 mt-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4">
                <PaymentProviderNameField
                  isMutating={
                    addPaymentMethodMutation.isPending ||
                    PaymentMethodData.length > 0
                  }
                />
                <ProviderPaymentMethodNamesField
                  isMutating={addPaymentMethodMutation.isPending}
                  externalId={externalId}
                />

                <CustomerPaymentMethodNamesField
                  isMutating={addPaymentMethodMutation.isPending}
                />
                <CustomerPaymentMethodDescriptionFormField
                  isMutating={addPaymentMethodMutation.isPending}
                />
              </div>
              <div className="flex items-center justify-end space-x-2 p-2">
                <Button
                  type="submit"
                  disabled={addPaymentMethodMutation.isPending}
                  aria-disabled={addPaymentMethodMutation.isPending}
                  variant="affirmative"
                  className="col-span-1 gap-2"
                >
                  {addPaymentMethodMutation.isPending ? (
                    <Spinner size="xs" />
                  ) : (
                    ""
                  )}
                  Save Payment Method
                  <SaveIcon className="size-4" />
                </Button>
              </div>
            </form>
          </Form>
          <PaymentMethodDataTable
            columns={paymentMethodTableColumns}
            data={PaymentMethodData}
            isSuccess={PaymentMethodQuery.isSuccess}
            isPending={PaymentMethodQuery.isPending}
            isError={PaymentMethodQuery.isError}
          />
          <div className="flex items-center justify-end space-x-2 p-2">
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                void navigate({
                  to: "/app/admin/customer-management/imremit/$externalId/onboarding/email-configuration",
                  params,
                  replace: true,
                });
              }}
            >
              <span className="sr-only">Previous page</span>
              <MoveLeftIcon className="mr-2" />
              Previous
            </Button>
            <Button
              disabled={PaymentMethodData.length == 0}
              type="button"
              onClick={() => {
                module === ModuleNames.imremit &&
                  void navigate({
                    to: "/app/admin/customer-management/imremit/$externalId/onboarding/participant-register",
                    params,
                    replace: true,
                  });
              }}
            >
              Next
              <MoveRightIcon className="ml-2" />
              <span className="sr-only">Next page</span>
            </Button>
          </div>
        </CardContent>
      </Card>
    </section>
  );
}

/**
 * Custom hook for performing the 'Add Payment Method' mutation.
 * This hook abstracts the mutation logic and side effects like toast notifications.
 *
 * @param {number | undefined} externalId - The ID of the customer.
 * @returns A mutation object with methods to trigger the mutation.
 */
function useAddPaymentMethod(externalId: string) {
  return useMutation({
    mutationFn: (data: z.infer<typeof AddPaymentMethodFormSchema>) => {
      // Perform the mutation using the addPaymentMethodFn function
      return addCustomerPaymentMethodFn(data, externalId);
    },
    onSuccess: (response) => {
      // Handle successful mutation
      console.log("addPaymentMethodResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Payment method added successfully",
      });
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add payment method",
      });
    },
  });
}

// Form Fields

export function PaymentProviderNameField({isMutating}: APIProps) {
  const {control, setValue} = useFormContext();

  // Use custom hooks to fetch and memoize options

  const allPaymentProviders = usePaymentProviders();
  return (
    <FormField
      control={control}
      name="providerId"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="providerId" showMandatoryAsterisk>
            Payment provider name:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allPaymentProviders.find(
                        (paymentProvider) =>
                          paymentProvider.value === field.value
                      )?.label
                    : "Select Payment Provider Name..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Payment Provider..." />
                <CommandEmpty>No Payment Provider found.</CommandEmpty>
                <CommandGroup>
                  {allPaymentProviders.map((paymentProvider) => (
                    <CommandItem
                      key={paymentProvider.value}
                      value={paymentProvider.value}
                      onSelect={(value) => {
                        setValue("providerId", value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          paymentProvider.value.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {paymentProvider.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function ProviderPaymentMethodNamesField({
  isMutating,
  externalId,
}: APIProps) {
  const {control, setValue, watch} = useFormContext();

  const providerSelected = watch("providerId") as string | undefined;
  const allProviderPaymentMethodNames = useProviderPaymentMethodNames(
    externalId,
    providerSelected
  );
  return (
    <FormField
      control={control}
      name="paymentMethod"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="paymentMethod" showMandatoryAsterisk>
            Payment method name:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allProviderPaymentMethodNames.find(
                        (paymentMethod) => paymentMethod.value === field.value
                      )?.label
                    : "Select Payment Method..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Payment Method..." />
                <CommandEmpty>No Payment Method found.</CommandEmpty>
                <CommandGroup>
                  {allProviderPaymentMethodNames.map((paymentMethod) => (
                    <CommandItem
                      key={paymentMethod.value}
                      value={paymentMethod.label}
                      onSelect={() => {
                        setValue("paymentMethod", paymentMethod.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          paymentMethod.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {paymentMethod.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function CustomerPaymentMethodNamesField({
  isMutating,
}: {
  isMutating: boolean;
}) {
  const {control, register} = useFormContext();
  return (
    <FormField
      control={control}
      name="customerPaymentMethodName"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerPaymentMethodName">
            Customer payment method name:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter customer payment method name..."
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              {...register("customerPaymentMethodName")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function CustomerPaymentMethodDescriptionFormField({
  isMutating,
}: {
  isMutating: boolean;
}) {
  const {control, register} = useFormContext();

  return (
    <FormField
      control={control}
      name="customerPaymentMethodDescription"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerPaymentMethodDescription">
            Description:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the description..."
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              {...register("customerPaymentMethodDescription")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
