import {z} from "zod";

export const PaymentManagementCommentSchema = z.object({
  commentId: z.number(),
  dateTime: z.string(),
  userId: z.string(),
  comments: z.string(),
  failedCount: z.number().optional(),
  markAsRead: z.boolean(),
});

export type PaymentManagementCommentType = z.infer<
  typeof PaymentManagementCommentSchema
>;
