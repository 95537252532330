import {useParams} from "@tanstack/react-router";

import {DuplicatePaymentsOnboardingForm} from "@/modules/admin";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";

export function CustomerOnboardingDuplicatePaymentsPage() {
  const {externalId} = useParams({
    from: "/app/admin/customer-management/duplicate-payments/$externalId/onboarding",
  });

  // Render OnboardCustomerForm component
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.UPDATE_CUSTOMER_MANAGEMENT]}
    >
      <DuplicatePaymentsOnboardingForm externalId={externalId} />
    </AccessPolicyWrapper>
  );
}
