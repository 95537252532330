import {create} from "zustand";

import {type PaginationMetaType} from "@/lib/ky";
import {type PaymentManagementFormCriteriaType} from "@/modules/imremit";

import {type SortMetaType} from "@/utils/query-params-generator";

/**
 * Interface: PaymentManagementMetaStoreState
 * Defines the shape of the state for the PaymentManagementPaginationMetaStore
 */
interface PaymentManagementMetaStoreState {
  /**
   * storePaymentManagementPaginationMeta
   * Holds the pagination metadata related to PaymentManagement.
   */
  storePaymentManagementPaginationMeta: PaginationMetaType | null;

  /**
   * storePaymentManagementCriteria
   * Holds the criteria by which PaymentManagement are filtered.
   */
  storePaymentManagementCriteria: PaymentManagementFormCriteriaType | null;

  /**
   * storePaymentManagementSortMeta
   * Holds the sort metadata related to PaymentManagement.
   */
  storePaymentManagementSortMeta: SortMetaType | null;

  /**
   * handleOnPageChange
   * Holds the function to handle page change.
   * This is used to update the page number in the store.
   * This is used in the PaymentManagementDataTable component.
   *
   * @param pageNumber - The new page number
   * @returns void
   *
   * @example
   */
  handleOnPageChange?: (pageNumber: number) => Promise<void>;
}

/**
 * Interface: PaymentManagementMetaStoreActions
 * Defines the set of actions for updating the PaymentManagementMetaStoreState.
 */
interface PaymentManagementMetaStoreActions {
  /**
   * Action: setStorePaymentManagementPaginationMeta
   * Updates the 'storePaymentManagementPaginationMeta' field in the state.
   *
   * @param storePaymentManagementPaginationMeta - The new value for 'storePaymentManagementPaginationMeta'
   */
  setStorePaymentManagementPaginationMeta: (
    storePaymentManagementPaginationMeta: PaymentManagementMetaStoreState["storePaymentManagementPaginationMeta"]
  ) => void;

  /**
   * Action: setStorePaymentManagementCriteria
   * Updates the 'storePaymentManagementCriteria' field in the state.
   *
   * @param storePaymentManagementCriteria - The new value for 'storePaymentManagementCriteria'
   */
  setStorePaymentManagementCriteria: (
    storePaymentManagementCriteria: PaymentManagementMetaStoreState["storePaymentManagementCriteria"]
  ) => void;

  /**
   * Action: setStorePaymentManagementSortMeta
   * Updates the 'storePaymentManagementSortMeta' field in the state.
   *
   * @param storePaymentManagementSortMeta - The new value for 'storePaymentManagementSortMeta'
   */
  setStorePaymentManagementSortMeta: (
    storePaymentManagementSortMeta: PaymentManagementMetaStoreState["storePaymentManagementSortMeta"]
  ) => void;

  /**
   * Action: setHandleOnPageChange
   * Updates the 'handleOnPageChange' field in the state.
   *
   * @param handleOnPageChange - The new value for 'handleOnPageChange'
   */
  setHandleOnPageChange: (
    handleOnPageChange: (pageNumber: number) => Promise<void>
  ) => void;
}

/**
 * Function: usePaymentManagementMetaStore
 * Creates a Zustand store to handle file processing pagination metadata and criteria.
 */
export const usePaymentManagementMetaStore = create<
  PaymentManagementMetaStoreState & PaymentManagementMetaStoreActions
>((set) => ({
  // Initialize 'storePaymentManagementPaginationMeta', 'storePaymentManagementCriteria' and 'handleOnPageChange'
  // with default values of null
  storePaymentManagementPaginationMeta: null,
  storePaymentManagementCriteria: null,
  storePaymentManagementSortMeta: null,
  handleOnPageChange: undefined,

  /**
   * Implementation for 'setStorePaymentManagementPaginationMeta' action.
   * Updates the 'storePaymentManagementPaginationMeta' field in the state.
   *
   * @param storePaymentManagementPaginationMeta - The new value for 'storePaymentManagementPaginationMeta'
   */
  setStorePaymentManagementPaginationMeta: (
    storePaymentManagementPaginationMeta
  ) => {
    set(() => ({storePaymentManagementPaginationMeta}));
  },

  /**
   * Implementation for 'setStorePaymentManagementCriteria' action.
   * Updates the 'storePaymentManagementCriteria' field in the state.
   *
   * @param storePaymentManagementCriteria - The new value for 'storePaymentManagementCriteria'
   */
  setStorePaymentManagementCriteria: (storePaymentManagementCriteria) => {
    set(() => ({storePaymentManagementCriteria}));
  },

  /**
   * Implementation for 'setStorePaymentManagementSortMeta' action.
   * Updates the 'storePaymentManagementSortMeta' field in the state.
   *
   * @param storePaymentManagementSortMeta - The new value for 'storePaymentManagementSortMeta'
   */
  setStorePaymentManagementSortMeta: (storePaymentManagementSortMeta) => {
    set(() => ({storePaymentManagementSortMeta}));
  },

  /**
   * Implementation for 'setHandleOnPageChange' action.
   * Updates the 'handleOnPageChange' field in the state.
   *
   * @param handleOnPageChange - The new value for 'handleOnPageChange'
   */
  setHandleOnPageChange: (handleOnPageChange) => {
    set(() => ({handleOnPageChange}));
  },
}));
