import {z} from "zod";

import {phoneRegex} from "@/utils/constants";

export const addAdminSupplierFormSchema = z.object({
  taxCode: z.string().min(1, {message: "Tax Code is required"}),
  name: z.string().min(1, {message: "Name is required"}),
  number: z.string().regex(phoneRegex, "Invalid Phone Number!"),
  externalId: z.string().min(1, {message: "External ID is required"}),
  corporateName: z.string().min(1, {message: "Corporate Name is required"}),
  address1: z.string().min(1, {message: "Address  is required"}),
  address2: z.string().optional(),
  city: z.string().min(1, {message: "City is required"}),
  state: z.string(),
  country: z.string(),
  zipCode: z.string().min(1, {message: "Zip code  is required"}),
  contactName: z.string().min(1, {message: "Contact name  is required"}),
  phoneNo: z.string().regex(phoneRegex, "Invalid Phone number!"),
  emailAddress: z.string().email(),
  remitAddress1: z.string().min(1, {message: "Remit address  is required"}),
  remitAddress2: z.string().optional(),
  remitCity: z.string().min(1, {message: "Remit City is required"}),
  remitState: z.string(),
  remitCountry: z.string(),
  remitZipCode: z.string().min(1, {message: "Remit zipcode is required"}),
});

export type AddAdminSupplierFormType = z.infer<
  typeof addAdminSupplierFormSchema
>;
