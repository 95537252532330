import {useMutation} from "@tanstack/react-query";
import {useNavigate} from "@tanstack/react-router";
import {type Row} from "@tanstack/react-table";
import {
  BanIcon,
  FileEditIcon,
  FileTextIcon,
  GripVerticalIcon,
} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  // DropdownMenuRadioGroup,
  // DropdownMenuRadioItem,
  // DropdownMenuSeparator,
  // DropdownMenuShortcut,
  // DropdownMenuSub,
  // DropdownMenuSubContent,
  // DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {toast} from "@/components/ui/use-toast";

import {useImRemitLiteCustomerStore} from "@/lib/stores";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";
import type {controlBatchProcessingType} from "@/modules/customers";
import {
  controlBatchProcessingDataFn,
  useGetBatchProcessingFlag,
} from "@/modules/customers";
import {
  SupplierManagementDeleteRow,
  suppliersDetailsSchema,
} from "@/modules/imremit";

/**
 * Props for SupplierManagementRowActions component.
 * @typedef {Object} SupplierManagementRowActionsProps
 * @template TData - The type of data for the row.
 * @property {Row<TData>} row - The data row.
 */
interface SupplierManagementRowActionsProps<TData> {
  row: Row<TData>;
}

/**
 * Represents the actions for a payment file row.
 * This component provides a dropdown menu for each row with various actions like edit, copy, favorite, and delete.
 *
 * @template TData - The data type for the table row.
 * @param {SupplierManagementRowActionsProps<TData>} props - The props for the component.
 * @returns {JSX.Element} The SupplierManagementRowActions component.
 */
export function SupplierManagementRowActions<TData>({
  row,
}: SupplierManagementRowActionsProps<TData>) {
  // Get the navigate function from the router
  const navigate = useNavigate();

  const {imRemitLiteStoreCustomer} = useImRemitLiteCustomerStore();
  const externalId = imRemitLiteStoreCustomer?.externalId;

  // Parses the row data using the schema defined for payment mapping list.
  const supplierDetailRows = suppliersDetailsSchema.parse(row.original);

  // Set the params for the routes
  const params = {supplierId: supplierDetailRows.supplierId.toString()};

  const batchProcessing = useGetBatchProcessingFlag(
    externalId ?? "",
    supplierDetailRows.supplierId.toString()
  );
  const batchProcessingFlagIsTrue = batchProcessing.data?.content.flag;

  const saveBatchProcessingMutation = useMutation({
    mutationFn: ({
      buyerExternalId,
      supplierId,
      flag,
    }: controlBatchProcessingType) => {
      return controlBatchProcessingDataFn(
        buyerExternalId,
        supplierId,
        flag.toString()
      );
    },
    onSuccess: (response) => {
      console.log("saveBatchProcessingResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Batch processing updated successfully",
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update batch processing",
      });
    },
  });

  return (
    <DropdownMenu>
      {/* Trigger button for the dropdown menu */}
      <DropdownMenuTrigger asChild>
        <Button className="flex bg-theme hover:bg-neutral data-[state=open]:bg-secondary">
          <GripVerticalIcon className="size-6 bg-inherit" />
        </Button>
      </DropdownMenuTrigger>

      {/* Content of the dropdown menu */}
      <DropdownMenuContent align="end" className="min-w-fit">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        {/* List of actions in the dropdown menu */}
        <AccessPolicyWrapper
          unauthorizedFallback={null}
          policyActions={[KeycloakRoleEnum.UPDATE_SUPPLIER_MANAGEMENT_LITE]}
        >
          <DropdownMenuItem
            className="flex justify-between gap-4"
            onSelect={() => {
              void navigate({
                to: "/app/imremit-lite/supplier-management/$supplierId/edit",
                params,
                replace: true,
              });
            }}
          >
            <span className="sr-only">Edit Supplier Details</span>
            Edit Supplier Details
            <FileEditIcon className="size-4" />
          </DropdownMenuItem>
        </AccessPolicyWrapper>
        <DropdownMenuItem
          className="flex justify-between gap-4"
          onSelect={() => {
            void navigate({
              to: "/app/imremit-lite/supplier-management/$supplierId/view",
              params,
              replace: true,
            });
          }}
        >
          View Supplier Details
          <span className="sr-only">View Supplier Details</span>
          <FileTextIcon className="size-4" />
        </DropdownMenuItem>
        <AccessPolicyWrapper
          policyActions={[KeycloakRoleEnum.UPDATE_CONTROL_BATCH_PROCESSING]}
          unauthorizedFallback={null}
        >
          {batchProcessingFlagIsTrue === true ? (
            <DropdownMenuItem
              className="mb-1 flex justify-between gap-4 bg-destructive text-destructive-foreground hover:bg-destructive/90 hover:text-destructive-foreground/90"
              onSelect={() => {
                saveBatchProcessingMutation.mutate({
                  buyerExternalId: externalId ?? "",
                  supplierId: supplierDetailRows.supplierId.toString(),
                  flag: false,
                });
              }}
            >
              Stop Batch Processing
              <BanIcon className="ml-2 size-4" />
              <span className="sr-only">Stop Batch Processing</span>
            </DropdownMenuItem>
          ) : null}
        </AccessPolicyWrapper>
        <AccessPolicyWrapper
          unauthorizedFallback={null}
          policyActions={[KeycloakRoleEnum.DELETE_SUPPLIER_MANAGEMENT_LITE]}
        >
          <SupplierManagementDeleteRow
            externalId={externalId}
            supplierId={supplierDetailRows.supplierId}
          />
        </AccessPolicyWrapper>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
