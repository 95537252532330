import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {type BankAuditMappingFileSpecsType} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";

export const responseFileColumns: ColumnDef<BankAuditMappingFileSpecsType>[] = [
  {
    accessorKey: "masterMappingName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Master Mapping Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("masterMappingName") ? (
          row.getValue("masterMappingName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "mappingName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Mapping Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("mappingName") ? (
          row.getValue("mappingName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "order",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Order" />
    ),
    cell: ({row}) => <div className="w-fit">{row.getValue("order")}</div>,
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "headerName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Header Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("headerName") ? (
          row.getValue("headerName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "dataLength",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Data Length" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("dataLength") ? (
          row.getValue("dataLength")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "defaultValue",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Default Value" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("defaultValue") ? (
          row.getValue("defaultValue")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "dateFormatValue",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Date Format Value" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("dateFormatValue") ? (
          row.getValue("dateFormatValue")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "delimiterTypeId",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Delimiter Type Id" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("delimiterTypeId") ? (
          row.getValue("delimiterTypeId")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "isDecimalField",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Is Decimal Field" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("isDecimalField") ? (
          row.getValue("isDecimalField")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },

  {
    accessorKey: "tableName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Table Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("tableName") ? (
          row.getValue("tableName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  // {
  //   id: "actions",
  //   cell: ({row}) => <PaymentFileRowActions row={row} />,
  // },
];
