import {z} from "zod";

export const controlBatchProcessingSchema = z.object({
  buyerExternalId: z.string(),
  supplierId: z.string(),
  flag: z.boolean(),
});

export type controlBatchProcessingType = z.infer<
  typeof controlBatchProcessingSchema
>;
