import {Card} from "@/components/ui/card";
import {Skeleton} from "@/components/ui/skeleton";

import {cn} from "@/lib/utils";

/**
 * Props for LoadingSkeletonCard component.
 */
interface LoadingSkeletonProps {
  /**
   * The number of skeleton lines to display.
   */
  lineCount: number;

  /**
   * Additional CSS class names for styling the card.
   */
  classNames?: string;

  /**
   * CSS class for controlling the height of each skeleton line.
   */
  lineHeight?: string;

  /**
   * CSS class for controlling the width of each skeleton line.
   */
  lineWidth?: string;
}

/**
 * LoadingSkeletonCard is a reusable component that displays a loading skeleton.
 * It can be customized with a variable number of lines and specific styles.
 *
 * @param {LoadingSkeletonProps} props The properties for the component.
 * @returns {JSX.Element} The JSX element for the loading skeleton card.
 */
export function LoadingSkeletonCard({
  lineCount,
  classNames = "",
  lineHeight = "h-8",
  lineWidth = "w-full",
}: LoadingSkeletonProps): JSX.Element {
  return (
    // Render a card with specified class names and padding
    <Card className={cn(classNames, "flex flex-col gap-y-4 p-4")}>
      {Array.from({length: lineCount}, (_, index) => (
        // Render each skeleton line with specified height and width
        <Skeleton key={index} className={cn(lineHeight, lineWidth)} />
      ))}
    </Card>
  );
}
