import {useEffect} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {CheckIcon, ChevronsUpDownIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import type z from "zod";

import {Button} from "@/components/ui/button";
import {Card, CardContent} from "@/components/ui/card";
import {Command, CommandGroup, CommandItem} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Label} from "@/components/ui/label";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {RadioGroup, RadioGroupItem} from "@/components/ui/radio-group";

import {cn} from "@/lib/utils";
import {
  keycloakGroupSchema,
  RoleGroupForm,
  useAllRealmGroups,
  userGroupsCustomer,
  userGroupsIM,
  userGroupsSupplier,
} from "@/modules/admin";

type UserGroup = {
  label: string;
  value: string;
};

/**
 * @typedef {Object} UserGroup
 * @property {string} label - The label of the user group.
 * @property {string} value - The value of the user group.
 */

/**
 * MasterRoleSettingsForm component for managing role settings.
 * @returns {JSX.Element} JSX representation of the component.
 */

export function MasterRoleSettingsForm() {
  const form = useForm<z.infer<typeof keycloakGroupSchema>>({
    resolver: zodResolver(keycloakGroupSchema),
  });

  const {type} = form.watch();
  const isIM = type === "IM",
    isSupplier = type === "supplier",
    isCustomer = type === "customer";

  const roleMap: UserGroup[] = isIM
    ? userGroupsIM
    : isSupplier
    ? userGroupsSupplier
    : isCustomer
    ? userGroupsCustomer
    : [];

  const allGroups = useAllRealmGroups();
  const selectedGroupName = form.getValues("role");

  const selectedGroupID = (allGroups.data || []).find(
    (group) => group.name === selectedGroupName
  )?.id;

  const roleType = form.watch("type");
  useEffect(() => {
    form.setValue("role", "");
  }, [form, roleType]);

  const isMutating = allGroups.isPending;
  return (
    <>
      <Card>
        <CardContent>
          <Form {...form}>
            <div className="mb-5 mt-5 grid grid-cols-1 gap-4 space-x-2 md:grid-cols-6">
              <FormField
                control={form.control}
                name="type"
                render={({field}) => (
                  <FormItem className="space-y-4">
                    <Label showMandatoryAsterisk>Role Groups</Label>
                    <FormControl>
                      <RadioGroup
                        disabled={isMutating}
                        className="flex flex-col space-y-1"
                        onValueChange={field.onChange}
                      >
                        <div className="flex items-center space-x-2">
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="IM" />
                            </FormControl>
                            <FormLabel className="font-normal">IM</FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="supplier" />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Supplier
                            </FormLabel>
                          </FormItem>
                          <FormItem className="flex items-center space-x-3 space-y-0">
                            <FormControl>
                              <RadioGroupItem value="customer" />
                            </FormControl>
                            <FormLabel className="font-normal">
                              Customer
                            </FormLabel>
                          </FormItem>
                        </div>
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="role"
              render={({field}) => (
                <div className="space-y-2">
                  <FormItem>
                    <Popover>
                      <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                        <Command>
                          <CommandGroup>
                            {roleMap.map((role, i) => (
                              <CommandItem
                                key={i}
                                value={role.value}
                                onSelect={() => {
                                  field.onChange(role.value);
                                }}
                              >
                                <CheckIcon
                                  className={cn(
                                    "mr-2 size-4",
                                    field.value && field.value === role.value
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                                {role.label}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </Command>
                      </PopoverContent>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            disabled={isMutating || !roleMap.length}
                            variant="outline"
                            role="combobox"
                            className={cn(
                              "justify-between",
                              !field.value && "text-accent-foreground",
                              "w-[260px]"
                            )}
                          >
                            {!roleMap.length
                              ? "Select Role Group"
                              : field.value
                              ? roleMap.find(
                                  (item) => item.value === field.value
                                )?.label
                              : "Select Role"}
                            <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                </div>
              )}
            />
            {selectedGroupID && !isMutating && (
              <RoleGroupForm groupId={selectedGroupID} />
            )}
          </Form>
        </CardContent>
      </Card>
    </>
  );
}
