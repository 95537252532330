import {useQuery} from "@tanstack/react-query";
import {z} from "zod";

import {keycloakKyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {keycloakGroupsQueryKeys} from "@/modules/admin";

const keycloakActionSchemachema = z.object({
  id: z.string(),
  name: z.string(),
  path: z.string(),
});

type keycloakActionSchemaType = z.infer<typeof keycloakActionSchemachema>;

// Defining a type based on the function `getAllRealmGroups`
type QueryFnType = typeof getAllRealmGroups;

// Function to get all realm user groups
export function getAllRealmGroups() {
  const realm =
    (import.meta.env.VITE_KEYCLOAK_REALM as string | undefined) ?? "dev";
  return keycloakKyApiFn<keycloakActionSchemaType[]>(
    `admin/realms/${realm}/groups`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useAllRealmGroups(config?: QueryConfig<QueryFnType>) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: keycloakGroupsQueryKeys.all,
    queryFn: () => getAllRealmGroups(),
  });
}
