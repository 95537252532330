import {create} from "zustand";

import {type PaginationMetaType} from "@/lib/ky";
import {type FileProcessingType as FileProcessingCriteriaType} from "@/modules/imremit";

import {type SortMetaType} from "@/utils/query-params-generator";

/**
 * Interface: FileProcessingMetaStoreState
 * Defines the shape of the state for the FileProcessingPaginationMetaStore
 */
interface FileProcessingMetaStoreState {
  /**
   * storeFileProcessingPaginationMeta
   * Holds the pagination metadata related to fileProcessing.
   */
  storeFileProcessingPaginationMeta: PaginationMetaType | null;

  /**
   * storeFileProcessingCriteria
   * Holds the criteria by which fileProcessing are filtered.
   */
  storeFileProcessingCriteria: FileProcessingCriteriaType | null;

  /**
   * storeFileProcessingSortMeta
   * Holds the sort metadata related to fileProcessing.
   */
  storeFileProcessingSortMeta: SortMetaType | null;

  /**
   * handleOnPageChange
   * Holds the function to handle page change.
   * This is used to update the page number in the store.
   * This is used in the FileProcessingDataTable component.
   *
   * @param pageNumber - The new page number
   * @returns void
   *
   * @example
   */
  handleOnPageChange?: (pageNumber: number) => Promise<void>;
}

/**
 * Interface: FileProcessingMetaStoreActions
 * Defines the set of actions for updating the FileProcessingMetaStoreState.
 */
interface FileProcessingMetaStoreActions {
  /**
   * Action: setStoreFileProcessingPaginationMeta
   * Updates the 'storeFileProcessingPaginationMeta' field in the state.
   *
   * @param storeFileProcessingPaginationMeta - The new value for 'storeFileProcessingPaginationMeta'
   */
  setStoreFileProcessingPaginationMeta: (
    storeFileProcessingPaginationMeta: FileProcessingMetaStoreState["storeFileProcessingPaginationMeta"]
  ) => void;

  /**
   * Action: setStoreFileProcessingCriteria
   * Updates the 'storeFileProcessingCriteria' field in the state.
   *
   * @param storeFileProcessingCriteria - The new value for 'storeFileProcessingCriteria'
   */
  setStoreFileProcessingCriteria: (
    storeFileProcessingCriteria: FileProcessingMetaStoreState["storeFileProcessingCriteria"]
  ) => void;

  /**
   * Action: setStoreFileProcessingSortMeta
   * Updates the 'storeFileProcessingSortMeta' field in the state.
   *
   * @param storeFileProcessingSortMeta - The new value for 'storeFileProcessingSortMeta'
   */
  setStoreFileProcessingSortMeta: (
    storeFileProcessingSortMeta: FileProcessingMetaStoreState["storeFileProcessingSortMeta"]
  ) => void;

  /**
   * Action: setHandleOnPageChange
   * Updates the 'handleOnPageChange' field in the state.
   *
   * @param handleOnPageChange - The new value for 'handleOnPageChange'
   */
  setHandleOnPageChange: (
    handleOnPageChange: (pageNumber: number) => Promise<void>
  ) => void;
}

/**
 * Function: useFileProcessingMetaStore
 * Creates a Zustand store to handle file processing pagination metadata and criteria.
 */
export const useFileProcessingMetaStore = create<
  FileProcessingMetaStoreState & FileProcessingMetaStoreActions
>((set) => ({
  // Initialize 'storeFileProcessingPaginationMeta', 'storeFileProcessingCriteria' and 'handleOnPageChange'
  // with default values of null
  storeFileProcessingPaginationMeta: null,
  storeFileProcessingCriteria: null,
  storeFileProcessingSortMeta: null,
  handleOnPageChange: undefined,

  /**
   * Implementation for 'setStoreFileProcessingPaginationMeta' action.
   * Updates the 'storeFileProcessingPaginationMeta' field in the state.
   *
   * @param storeFileProcessingPaginationMeta - The new value for 'storeFileProcessingPaginationMeta'
   */
  setStoreFileProcessingPaginationMeta: (storeFileProcessingPaginationMeta) => {
    set(() => ({storeFileProcessingPaginationMeta}));
  },

  /**
   * Implementation for 'setStoreFileProcessingCriteria' action.
   * Updates the 'storeFileProcessingCriteria' field in the state.
   *
   * @param storeFileProcessingCriteria - The new value for 'storeFileProcessingCriteria'
   */
  setStoreFileProcessingCriteria: (storeFileProcessingCriteria) => {
    set(() => ({storeFileProcessingCriteria}));
  },

  /**
   * Implementation for 'setStoreFileProcessingSortMeta' action.
   * Updates the 'storeFileProcessingSortMeta' field in the state.
   *
   * @param storeFileProcessingSortMeta - The new value for 'storeFileProcessingSortMeta'
   */
  setStoreFileProcessingSortMeta: (storeFileProcessingSortMeta) => {
    set(() => ({storeFileProcessingSortMeta}));
  },

  /**
   * Implementation for 'setHandleOnPageChange' action.
   * Updates the 'handleOnPageChange' field in the state.
   *
   * @param handleOnPageChange - The new value for 'handleOnPageChange'
   */
  setHandleOnPageChange: (handleOnPageChange) => {
    set(() => ({handleOnPageChange}));
  },
}));
