import {format} from "date-fns";

import {Badge} from "@/components/ui/badge";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {Paragraph} from "@/components/ui/typography";
import {LoadingSection} from "@/components/craft/loading-section";

import {
  useGetInvoiceStatusHistory,
  type InvoiceStatusHistory,
} from "@/modules/invoices";

import {FallbackMessages} from "@/utils/constants";

/**
 * Props for InvoicesStatusHistoryTable Component
 */
interface InvoicesStatusHistoryTableProps {
  invoiceId: number; // Identifier for the invoice
}

/**
 * Props for HistoryRow Component
 */
interface HistoryRowProps {
  invoiceStatusHistory: InvoiceStatusHistory; // Status history for an invoice
}

/**
 * HistoryRow Component
 *
 * Renders a single row within the InvoicesStatusHistoryTable.
 * Each row represents the history of changes for a particular invoice.
 *
 * @param {HistoryRowProps} invoiceStatusHistory - The status history of the invoice
 */
const HistoryRow: React.FC<HistoryRowProps> = ({invoiceStatusHistory}) => (
  <TableRow>
    <TableCell>
      {invoiceStatusHistory.date ? (
        format(new Date(invoiceStatusHistory.date), "MMMM dd, yyyy")
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>
    <TableCell>
      {invoiceStatusHistory.status || FallbackMessages.NOT_APPLICABLE}
    </TableCell>
    <TableCell>
      {invoiceStatusHistory.changedBy || FallbackMessages.NOT_APPLICABLE}
    </TableCell>
    <TableCell>
      {invoiceStatusHistory.paymentNo || FallbackMessages.NOT_APPLICABLE}
    </TableCell>
  </TableRow>
);

/**
 * NoStatusHistoryMessage Component
 *
 * Displays a message when there is no status history available.
 */
const NoStatusHistoryMessage: React.FC = () => (
  <Paragraph className="p-12 text-center">
    No status history available.
  </Paragraph>
);

/**
 * InvoicesStatusHistoryTable Component
 *
 * Fetches and displays the history of status changes for an invoice.
 * Uses the useGetInvoiceStatusHistory custom hook for data fetching.
 *
 * @param {InvoicesStatusHistoryTableProps} invoiceId - The identifier for the invoice
 */
export const InvoicesStatusHistoryTable: React.FC<
  InvoicesStatusHistoryTableProps
> = ({invoiceId}) => {
  const {data: invoiceStatusHistory, isPending} =
    useGetInvoiceStatusHistory(invoiceId);

  // Display the loading section while fetching data
  if (isPending) return <LoadingSection />;

  // Handle scenarios where no history is available
  if (!invoiceStatusHistory || invoiceStatusHistory.content.length === 0) {
    return <NoStatusHistoryMessage />;
  }

  return (
    <Table className="w-full">
      <TableCaption>Invoice Status History</TableCaption>
      <TableHeader>
        <TableRow>
          <TableHead>Date</TableHead>
          <TableHead>Status</TableHead>
          <TableHead>User</TableHead>
          <TableHead className="text-right">Payment Number</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {invoiceStatusHistory.content.map((status, index) => (
          <HistoryRow key={index} invoiceStatusHistory={status} />
        ))}
      </TableBody>
    </Table>
  );
};
