import {z} from "zod";

export const adminReportSchema = z.object({
  supplierName: z.string().nullable(),
  taxCode: z.string().nullable(),
  session: z.number().nullable(),
  avgLength: z.number().nullable(),
  lastLogin: z.string().nullable(),
});

export type AdminReportType = z.infer<typeof adminReportSchema>;

export const adminReportAdminTableColumnsSchema = z.object({
  supplierName: z.boolean(),
  taxCode: z.boolean(),
  session: z.boolean(),
  avgLength: z.boolean(),
  lastLogin: z.boolean(),
});
export type AdminReportAdminTableColumnsType = z.infer<
  typeof adminReportAdminTableColumnsSchema
>;
