import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate} from "@tanstack/react-router";
import {debounce} from "lodash";
import {
  ArrowRightIcon,
  CheckIcon,
  ChevronsUpDownIcon,
  InfoIcon,
  UserPlus2Icon,
} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button, buttonVariants} from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {Separator} from "@/components/ui/separator";
import {Skeleton} from "@/components/ui/skeleton";
import {Spinner} from "@/components/ui/spinner";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {Heading2, Heading3, Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {FancyMultiSelect} from "@/components/craft/fancy-multi-select";
import {
  CommandEmptyFuzzy,
  CommandFuzzy,
  CommandGroupFuzzy,
  CommandInputFuzzy,
  CommandItemFuzzy,
} from "@/components/craft/fuzzy-search-command";

import {useCustomerStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {useAllRealmGroups} from "@/modules/admin";
import {createUserFn} from "@/modules/admin/api/keycloak-api/create-user-api";
import {GenericUserForm} from "@/modules/admin/components/forms/generic-user-form";
// import type {keycloakRoleDropdownAdditions} from "@/modules/admin/types/keycloak-user-types";
import {keycloakCreateUserFormSchema} from "@/modules/admin/types/keycloak-user-types";
import {
  AccessPolicyWrapper,
  calculateUserRoles,
  KeycloakRoleEnum,
  useKeyCloakInstanceStore,
  useUserInfo,
} from "@/modules/auth";
import {
  useGetAllSuppliersByCustomerFuzzy,
  useSelectInfiniteCustomers,
} from "@/modules/customers";

import {
  FallbackMessages,
  KeycloakRoles,
  KeycloakRolesWithBackslash,
} from "@/utils/constants";

/**
 * Transforms a group item to a select option format.
 * @param item - The group item with id and optional name.
 * @returns An object with `value` as string and `label`.
 */
function toGroupOption(item: {id: string; path?: string}) {
  return {
    value: item.path ?? FallbackMessages.NOT_APPLICABLE,
    label: item.path ?? FallbackMessages.NOT_APPLICABLE,
  };
}

/**
 * Custom hook for fetching and memoizing groups.
 * @returns A memoized array of user group options.
 */
function useGroupMethods() {
  const {data} = useAllRealmGroups();
  // Memoizes the transformed payment method data for performance optimization
  return useMemo(() => data?.map(toGroupOption) ?? [], [data]);
}

/**
 * CreateUserFormProps Interface
 *
 * @interface CreateUserFormProps
 * @property {boolean} isMutating - Indicates whether the mutation is in progress.
 * @property {Function} onSubmit - The function to be called upon form submission.
 */
interface CreateUserFormProps {
  isMutating: boolean;
  onSubmit: (data: z.infer<typeof keycloakCreateUserFormSchema>) => void;
}

/**
 * CreateUserForm Component
 *
 * This component renders the CreateUserForm form and handles its behavior.
 *
 * @param {CreateUserFormProps} props - Properties passed to the component
 * @returns {JSX.Element} - Rendered component
 */

// Define the type for group data
interface Group {
  value: string;
  label: string;
}

function CreateUserForm({isMutating, onSubmit}: CreateUserFormProps) {
  const {storeCustomer} = useCustomerStore();
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const [search, setSearch] = useState("");
  const infiniteCustomersQuery = useSelectInfiniteCustomers(search);
  // Extract the `customer` field from the tokenParsed object
  const parsedUserInfoRole = storeKeyCloakInstance?.tokenParsed;
  // Check if the user is a customer admin
  const isCustomerAdmin = parsedUserInfoRole?.realm_access?.roles.includes(
    "name:customer-admin"
  );

  const isSupplierAdmin = parsedUserInfoRole?.realm_access?.roles.includes(
    "name:supplier-admin"
  );

  // Define form using react-hook-form
  const createUserForm = useForm<z.infer<typeof keycloakCreateUserFormSchema>>({
    defaultValues: {
      enabled: true,
      emailVerified: true,
      // Customer Admins can only create users with the customer-user role and Supplier Admins can only create users with the supplier-user role
      groups: isCustomerAdmin
        ? [
            {
              value: KeycloakRolesWithBackslash.CustomerUser,
              label: KeycloakRoles.CustomerUser,
            },
          ]
        : isSupplierAdmin
        ? [
            {
              value: KeycloakRolesWithBackslash.SupplierUser,
              label: KeycloakRoles.SupplierUser,
            },
          ]
        : [],
      requiredActions: [],
      credentials: {
        type: "password",
        value: "",
        cvalue: "",
        temporary: false,
      },
    },
    resolver: zodResolver(keycloakCreateUserFormSchema),
  });
  const formFields = createUserForm.watch();

  const selectedCustomerValue = formFields.selectedCustomerForSupplier
    ? formFields.selectedCustomerForSupplier.toUpperCase()
    : "";

  //Fuzzy Supplier List
  const getSupplierByCustomerIdQuery = useGetAllSuppliersByCustomerFuzzy(
    selectedCustomerValue,
    search
  );

  const rawSuppliersData = getSupplierByCustomerIdQuery.data?.content;

  const supplierData = useMemo(() => {
    if (!rawSuppliersData) return [];
    return rawSuppliersData.map((supplier) => ({
      label: supplier.supplier_name,
      value: supplier.supplier_id.toString(),
    }));
  }, [rawSuppliersData]);

  // Format the customer data to match the structure needed for the FancyMultiSelect component.
  const customersData = useMemo(() => {
    if (!infiniteCustomersQuery.data?.pages) return [];

    return infiniteCustomersQuery.data.pages.reduce<
      Array<{label: string; value: string}>
    >((acc, page) => {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!page?.content) return acc;
      return [
        ...acc,
        ...page.content.map((customer) => ({
          label: customer.buyerName,
          value: customer.externalId.toString(),
        })),
      ];
    }, []);
  }, [infiniteCustomersQuery.data?.pages]);

  // Use useRef to keep a stable reference to the debounced function
  const debouncedSetSearchRef = useRef(
    debounce((value: string) => {
      setSearch(value);
    }, 100)
  );

  useEffect(() => {
    // Store the current debounced function in a local variable
    const debouncedFunction = debouncedSetSearchRef.current;

    // Cleanup function to cancel debounced calls on unmount
    return () => {
      debouncedFunction.cancel();
    };
  }, []);

  // Wrap the debounced function in a callback to avoid creating a new instance on every render
  const handleSearchChange = useCallback((value: string) => {
    setSearch(value);
    debouncedSetSearchRef.current(value);
  }, []);

  useEffect(() => {
    handleSearchChange(search);
  }, [search, handleSearchChange]);

  const [roleOptions, setRoleOptions] = React.useState<Group[]>([]);
  const {parsedUserInfo} = useUserInfo();
  const currentSupplier = parsedUserInfo?.supplier as string;

  const allGroups = useGroupMethods();
  // Utility function to filter and format group data with type safety
  const formatGroupData = (
    groups: Group[],
    filterFn: (value: Group["value"]) => boolean
  ): Group[] => {
    return groups
      .filter((group) => filterFn(group.value))
      .map((group) => ({
        value: group.value,
        // remove slashes, replace hyphens with spaces and capitalize first letter of each word
        label: group.label
          .replace(/\//g, "")
          .replace(/-/g, " ")
          .replace(/\b\w/g, (c) => c.toUpperCase()),
      }));
  };

  const allGroupsMultiSelectData = useMemo<Group[]>(() => {
    return formatGroupData(
      allGroups,
      (value) => value !== KeycloakRolesWithBackslash.BaseRole
    );
  }, [allGroups]);

  const allCustomersMultiSelectData = useMemo<Group[]>(() => {
    return formatGroupData(allGroups, (value) =>
      [
        KeycloakRolesWithBackslash.CustomerReviewer as string,
        KeycloakRolesWithBackslash.CustomerAdmin as string,
        KeycloakRolesWithBackslash.CustomerUser as string,
        KeycloakRolesWithBackslash.CustomerSuperAdmin as string,
      ].includes(value)
    );
  }, [allGroups]);

  const customerSuperAdminMultiSelectData = useMemo<Group[]>(() => {
    return formatGroupData(allGroups, (value) =>
      [
        KeycloakRolesWithBackslash.CustomerAdmin as string,
        KeycloakRolesWithBackslash.CustomerUser as string,
      ].includes(value)
    );
  }, [allGroups]);

  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  useEffect(() => {
    if (userRoleDisplay === KeycloakRoles.Management) {
      setRoleOptions(allGroupsMultiSelectData);
    } else if (
      userRoleDisplay === KeycloakRoles.ProjectManager ||
      userRoleDisplay === KeycloakRoles.ProgramManager
    ) {
      setRoleOptions(allCustomersMultiSelectData);
    } else if (userRoleDisplay === KeycloakRoles.CustomerSuperAdmin) {
      setRoleOptions(customerSuperAdminMultiSelectData);
    } else {
      setRoleOptions([]);
    }
  }, [
    userRoleDisplay,
    allGroupsMultiSelectData,
    allCustomersMultiSelectData,
    customerSuperAdminMultiSelectData,
  ]);

  const internalRoles = [
    "name:management",
    "name:program-manager",
    "name:project-manager",
  ];
  const isInternalRole = internalRoles.some((role) =>
    parsedUserInfo?.realm_access?.roles.includes(role)
  );

  const {setValue} = createUserForm;

  useEffect(() => {
    if (storeCustomer?.buyerName) {
      setValue("customer", storeCustomer.buyerName.trim());
    } else {
      setValue("customer", "");
    }
  }, [storeCustomer?.buyerName, setValue]);

  useEffect(() => {
    if (currentSupplier) {
      setValue("supplier", currentSupplier);
    } else {
      setValue("supplier", "");
    }
  }, [currentSupplier, setValue]);

  const watchSelectedRole = createUserForm.watch("groups");

  useEffect(() => {
    if (isInternalRole) {
      if (
        watchSelectedRole.some((role) =>
          [
            "Customer User",
            "Customer Admin",
            "Customer Reviewer",
            "Customer Super Admin",
          ].includes(role.label)
        )
      ) {
        setValue("selectedCustomerForSupplier", "");
        setValue("supplier", "");
      } else if (
        watchSelectedRole.some((role) =>
          ["Supplier User", "Supplier Admin", "Supplier Enablement"].includes(
            role.label
          )
        )
      ) {
        setValue("customer", "");
      }
    }
  }, [isInternalRole, watchSelectedRole, setValue]);

  //display form error
  console.log(createUserForm.formState.errors);
  return (
    <section>
      <div className="mb-4 flex flex-col-reverse gap-4 md:flex-row md:justify-between">
        <div className="md:w-1/2">
          <Heading2>Create User</Heading2>
          <Paragraph>
            Fill in the form below to create a new user in the system.
          </Paragraph>
        </div>
        <div className="flex justify-end gap-2 space-x-2 md:w-1/2">
          <Link
            to="/app/admin/user-management"
            className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
          >
            Back to users list
            <span className="sr-only">Back to list</span>
            <ArrowRightIcon className="size-4" />
          </Link>
        </div>
      </div>
      <Form {...createUserForm}>
        <form onSubmit={createUserForm.handleSubmit(onSubmit)}>
          <GenericUserForm
            isMutating={isMutating}
            groupsField={
              <>
                <Heading3 className="mb-4">User Permissions</Heading3>
                <FormField
                  name="groups"
                  render={({field}) => (
                    <FormItem>
                      <div className="flex space-x-2">
                        <FormLabel htmlFor="groups">Roles:</FormLabel>
                        <TooltipProvider delayDuration={200}>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <div className="cursor-pointer">
                                <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                              </div>
                            </TooltipTrigger>
                            <TooltipContent>
                              <div className="flex justify-between space-x-4">
                                Roles where the user has membership.
                              </div>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                      <FormControl>
                        <FancyMultiSelect
                          {...field}
                          multiSelectData={roleOptions}
                          customWidth="370px"
                          disabled={!roleOptions.length}
                          placeholder={
                            !roleOptions.length
                              ? "No user roles..."
                              : "Search user roles..."
                          }
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            }
            selectCustomerOrSupplier={
              <>
                {watchSelectedRole.some((role) =>
                  [
                    "Customer User",
                    "Customer Admin",
                    "Customer Reviewer",
                    "Customer Super Admin",
                  ].includes(role.label)
                ) ? (
                  <>
                    <Heading3 className="mb-4">Select Customer:</Heading3>
                    <FormField
                      control={createUserForm.control}
                      name="customer"
                      render={({field}) => (
                        <FormItem className="flex flex-col space-y-0">
                          <FormLabel
                            className="pb-2"
                            htmlFor="imREmitCustomer"
                            showMandatoryAsterisk
                          >
                            Select Customer:
                          </FormLabel>
                          <Select
                            value={field.value}
                            onValueChange={(value) => {
                              field.onChange(value);
                              const selectedCustomer = customersData.find(
                                (customer) => customer.label === value
                              );
                              createUserForm.setValue(
                                "selectedCustomerForSupplier",
                                selectedCustomer?.value ?? ""
                              );
                            }}
                            onOpenChange={(open) => {
                              // Prevent focus when opening the select
                              if (open) {
                                document.activeElement instanceof HTMLElement &&
                                  document.activeElement.blur();
                              }
                            }}
                          >
                            <FormControl>
                              <SelectTrigger className="h-10 min-w-80 max-w-96">
                                <SelectValue placeholder="Select customer...">
                                  {field.value}
                                </SelectValue>
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <div className="w-full border-b">
                                <Input
                                  placeholder="Search customers (min. 3 characters)..."
                                  defaultValue={search}
                                  className="rounded-none border-0 focus-visible:ring-0"
                                  onKeyDown={(e) => {
                                    e.stopPropagation();
                                  }}
                                  onChange={(e) => {
                                    handleSearchChange(e.target.value);
                                  }}
                                />
                              </div>
                              <div
                                className="max-h-[300px] overflow-auto overscroll-contain"
                                onScroll={(e) => {
                                  const target = e.target as HTMLDivElement;

                                  if (
                                    !infiniteCustomersQuery.isFetchingNextPage &&
                                    infiniteCustomersQuery.hasNextPage &&
                                    target.scrollHeight - target.scrollTop <=
                                      target.clientHeight * 1.5
                                  ) {
                                    void infiniteCustomersQuery.fetchNextPage();
                                  }
                                }}
                              >
                                {search && search.length < 3 ? (
                                  <div className="p-2 text-center text-gray-500">
                                    Please enter at least 3 characters to search
                                  </div>
                                ) : infiniteCustomersQuery.isFetching &&
                                  customersData.length === 0 ? (
                                  <div className="space-y-2 p-2">
                                    <Skeleton className="h-8 w-full" />
                                    <Skeleton className="h-8 w-full" />
                                    <Skeleton className="h-8 w-full" />
                                  </div>
                                ) : (
                                  customersData.map((customer) => (
                                    <SelectItem
                                      key={customer.value}
                                      value={customer.label}
                                    >
                                      {customer.label}
                                    </SelectItem>
                                  ))
                                )}
                                {infiniteCustomersQuery.hasNextPage &&
                                  infiniteCustomersQuery.isFetchingNextPage && (
                                    <div className="flex justify-center p-2">
                                      <Spinner size="sm" />
                                    </div>
                                  )}
                              </div>
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </>
                ) : watchSelectedRole.some((role) =>
                    [
                      "Supplier User",
                      "Supplier Admin",
                      "Supplier Enablement",
                    ].includes(role.label)
                  ) ? (
                  <div className="space-y-4">
                    <Separator className="my-4" />
                    <Heading3 className="mb-4">Search for Supplier</Heading3>
                    <div className="grid grid-cols-1 gap-6 md:grid-cols-1 xl:grid-cols-4">
                      <FormField
                        control={createUserForm.control}
                        name="customer"
                        render={({field}) => (
                          <FormItem className="flex flex-col space-y-0">
                            <FormLabel
                              className="pb-2"
                              htmlFor="imREmitCustomer"
                              showMandatoryAsterisk
                            >
                              Select Customer:
                            </FormLabel>
                            <Select
                              value={field.value}
                              onValueChange={(value) => {
                                field.onChange(value);
                                const selectedCustomer = customersData.find(
                                  (customer) => customer.label === value
                                );
                                createUserForm.setValue(
                                  "selectedCustomerForSupplier",
                                  selectedCustomer?.value ?? ""
                                );
                              }}
                              onOpenChange={(open) => {
                                // Prevent focus when opening the select
                                if (open) {
                                  document.activeElement instanceof
                                    HTMLElement &&
                                    document.activeElement.blur();
                                }
                              }}
                            >
                              <FormControl>
                                <SelectTrigger className="h-10 min-w-80 max-w-96">
                                  <SelectValue placeholder="Select customer...">
                                    {field.value}
                                  </SelectValue>
                                </SelectTrigger>
                              </FormControl>
                              <SelectContent>
                                <div className="w-full border-b">
                                  <Input
                                    placeholder="Search customers (min. 3 characters)..."
                                    defaultValue={search}
                                    className="rounded-none border-0 focus-visible:ring-0"
                                    onKeyDown={(e) => {
                                      e.stopPropagation();
                                    }}
                                    onChange={(e) => {
                                      handleSearchChange(e.target.value);
                                    }}
                                  />
                                </div>
                                <div
                                  className="max-h-[300px] overflow-auto overscroll-contain"
                                  onScroll={(e) => {
                                    const target = e.target as HTMLDivElement;

                                    if (
                                      !infiniteCustomersQuery.isFetchingNextPage &&
                                      infiniteCustomersQuery.hasNextPage &&
                                      target.scrollHeight - target.scrollTop <=
                                        target.clientHeight * 1.5
                                    ) {
                                      void infiniteCustomersQuery.fetchNextPage();
                                    }
                                  }}
                                >
                                  {search && search.length < 3 ? (
                                    <div className="p-2 text-center text-gray-500">
                                      Please enter at least 3 characters to
                                      search
                                    </div>
                                  ) : infiniteCustomersQuery.isFetching &&
                                    customersData.length === 0 ? (
                                    <div className="space-y-2 p-2">
                                      <Skeleton className="h-8 w-full" />
                                      <Skeleton className="h-8 w-full" />
                                      <Skeleton className="h-8 w-full" />
                                    </div>
                                  ) : (
                                    customersData.map((customer) => (
                                      <SelectItem
                                        key={customer.value}
                                        value={customer.label}
                                      >
                                        {customer.label}
                                      </SelectItem>
                                    ))
                                  )}
                                  {infiniteCustomersQuery.hasNextPage &&
                                    infiniteCustomersQuery.isFetchingNextPage && (
                                      <div className="flex justify-center p-2">
                                        <Spinner size="sm" />
                                      </div>
                                    )}
                                </div>
                              </SelectContent>
                            </Select>
                            <FormMessage />
                          </FormItem>
                        )}
                      />

                      <FormField
                        name="supplier"
                        render={({field}) => {
                          return (
                            <FormItem className="flex flex-col">
                              <FormLabel showMandatoryAsterisk>
                                Search for Supplier:
                              </FormLabel>
                              <Popover>
                                <PopoverTrigger asChild>
                                  <FormControl>
                                    <Button
                                      variant="outline"
                                      role="combobox"
                                      className={cn(
                                        "w-2ull h-10 min-w-72 justify-between gap-2 bg-root capitalize",
                                        !field.value && "text-accent-foreground"
                                      )}
                                      disabled={
                                        !formFields.selectedCustomerForSupplier
                                          ?.length
                                      }
                                    >
                                      {field.value
                                        ? supplierData.find(
                                            (supplier) =>
                                              supplier.value.toLowerCase() ===
                                              field.value
                                          )?.label ?? "Search supplier..."
                                        : "Search supplier..."}

                                      <ChevronsUpDownIcon className="size-4 shrink-0 opacity-50" />
                                    </Button>
                                  </FormControl>
                                </PopoverTrigger>
                                <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                                  <CommandFuzzy>
                                    <CommandInputFuzzy
                                      placeholder="Start typing..."
                                      setSearch={handleSearchChange}
                                    />
                                    {/* 🔍 Show empty state when no suppliers are found */}
                                    {supplierData.length === 0 ? (
                                      <div className="p-2 text-center text-gray-500">
                                        No suppliers found
                                      </div>
                                    ) : (
                                      <>
                                        <CommandEmptyFuzzy>
                                          No suppliers found.
                                        </CommandEmptyFuzzy>
                                        <CommandGroupFuzzy>
                                          {supplierData.map((supplier) => (
                                            <CommandItemFuzzy
                                              key={supplier.value}
                                              value={supplier.value}
                                              onSelect={(selectedValue) => {
                                                field.onChange(selectedValue);
                                              }}
                                            >
                                              <CheckIcon
                                                className={cn(
                                                  "mr-2 size-4",
                                                  supplier.value.toLowerCase() ===
                                                    field.value
                                                    ? "opacity-100"
                                                    : "opacity-0"
                                                )}
                                              />
                                              {supplier.label}
                                            </CommandItemFuzzy>
                                          ))}
                                        </CommandGroupFuzzy>
                                      </>
                                    )}
                                  </CommandFuzzy>
                                </PopoverContent>
                              </Popover>
                              <FormMessage />
                            </FormItem>
                          );
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </>
            }
            saveButton={
              <div className="flex w-full flex-row justify-end gap-2">
                <Button
                  className="gap-2 bg-success-foreground text-white hover:bg-success-foreground/80"
                  type="submit"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                >
                  <span className="sr-only">Create User</span>
                  Create User
                  <UserPlus2Icon className="size-4" />
                </Button>
              </div>
            }
          />
        </form>
      </Form>
    </section>
  );
}

export function CreateUserPage() {
  const addRealmUserMutation = useAddRealmUser();

  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.CREATE_USER_MANAGEMENT]}
    >
      <CreateUserForm
        isMutating={addRealmUserMutation.isPending}
        onSubmit={addRealmUserMutation.mutate}
      />
    </AccessPolicyWrapper>
  );
}

/**
 * Custom hook for performing the 'Add KeyCloak User' mutation.
 * This hook abstracts the mutation logic and side effects like toast notifications.
 *
 * @returns A mutation object with methods to trigger the mutation.
 */
export function useAddRealmUser() {
  const navigate = useNavigate();
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const {parsedUserInfo} = useUserInfo();
  const currentSupplier = parsedUserInfo?.supplier as string;
  const currentCustomer = parsedUserInfo?.customer as string;
  const parsedUserInfoRole = storeKeyCloakInstance?.tokenParsed;
  // Check if the user is a customer admin
  const isCustomerAdmin = parsedUserInfoRole?.realm_access?.roles.includes(
    "name:customer-admin"
  );

  const isSupplierAdmin = parsedUserInfoRole?.realm_access?.roles.includes(
    "name:supplier-admin"
  );
  return useMutation({
    mutationFn: (data: z.infer<typeof keycloakCreateUserFormSchema>) => {
      // Remove data.crendentials.cvalue from the request
      // as it is not needed for the createUserFn
      const {
        credentials,
        rdpIpaddress,
        customer,
        supplier,
        groups,
        selectedCustomerForSupplier,
        ...rest
      } = data;

      console.log(selectedCustomerForSupplier);
      const isSupplierGroupSelected = groups.some(
        (group) =>
          group.value === KeycloakRolesWithBackslash.SupplierAdmin ||
          group.value === KeycloakRolesWithBackslash.SupplierEnablement ||
          group.value === KeycloakRolesWithBackslash.SupplierUser
      );

      const isCustomerGroupSelected = groups.some(
        (group) =>
          group.value === KeycloakRolesWithBackslash.CustomerAdmin ||
          group.value === KeycloakRolesWithBackslash.CustomerReviewer ||
          group.value === KeycloakRolesWithBackslash.CustomerUser ||
          group.value === KeycloakRolesWithBackslash.CustomerSuperAdmin
      );
      // Create a new object without the cvalue property
      const userReq = {
        ...rest,
        credentials: [
          {
            type: credentials.type,
            value: credentials.value.trim(),
            temporary: credentials.temporary,
          },
        ],
        // all users are assigned the base role by default
        groups: [
          ...groups.map((group) => group.value),
          KeycloakRolesWithBackslash.BaseRole,
        ],
        attributes: {
          rdpIpaddress: [rdpIpaddress ?? ""],

          customer: isSupplierGroupSelected
            ? [""]
            : isCustomerGroupSelected
            ? isCustomerAdmin
              ? [currentCustomer]
              : [customer]
            : [""],

          supplier: isSupplierGroupSelected
            ? isSupplierAdmin
              ? [currentSupplier]
              : [supplier]
            : [""],
        },
      };

      // Perform the mutation using the createUserFn function
      return createUserFn(userReq);
    },
    onSuccess: (response) => {
      // Handle successful mutation
      console.log("createRealmUserResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "User created successfully",
      });

      void navigate({
        to: "/app/admin/user-management",
        replace: true,
      });
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to create user",
      });
    },
  });
}
