import {createRouter} from "@tanstack/react-router";

import {notFoundRootRoute, notFoundRoute} from "@/lib/routes/errors";
import {
  authorizedNotFoundRootRoute,
  authorizedRootRoute,
  rootRoute,
} from "@/lib/routes/root";
import {
  adminAlertManagementRoute,
  adminCreatePIDMappingRoute,
  adminCreateUserRoute,
  adminCustomerManagementCreateRoute,
  adminCustomerManagementDuplicatePaymentsOnboardingRoute,
  adminCustomerManagementEditDetailsRoute,
  adminCustomerManagementImREmitECOnboardingRoute,
  adminCustomerManagementImREmitPMOnboardingRoute,
  adminCustomerManagementImREmitPROnboardingRoute,
  adminCustomerManagementImREmitRCOnboardingRoute,
  adminCustomerManagementImREmitSCOnboardingRoute,
  adminCustomerManagementOnboardDupesRoute,
  adminCustomerManagementOnboardImRemitRoute,
  adminCustomerManagementOnboardStatementReconRoute,
  adminCustomerManagementRoute,
  adminCustomerManagementViewDetailsRoute,
  adminCustomerModuleManagementRoute,
  adminEditPIDMappingRoute,
  adminEditUserRoute,
  adminMasterRoleSettingsRoute,
  adminNotFoundRoute,
  adminRootRoute,
  adminSearchPIDMappingRoute,
  adminSupplierOnboardingRoute,
  adminUserManagementRoute,
  adminUserRoleRelationRoute,
  adminViewUserRoute,
  adReportsRoute,
  createSuppliersRoute,
  editSuppliersRoute,
  misPaymentsReceivedRoute,
  supplierManagementRoute,
} from "@/modules/admin";
import {
  dupesTrackerRoute,
  duplicatePaymentRootRoute,
  duplicatePaymentsCriteriaSettingsRoute,
  duplicatePaymentsDashboardRedirectRoute,
  duplicatePaymentsDashboardRoute,
  duplicatePaymentsNotFoundRoute,
} from "@/modules/duplicate-payments";
import {
  electronicInvoicingNotFoundRoute,
  electronicInvoicingPageRoute,
  electronicInvoicingRootRoute,
} from "@/modules/electronic-invoicing";
import {helpNotFoundRoute, helpRootRoute, helpRoute} from "@/modules/help";
import {
  addimREmitMappingRoute,
  imREmitAddPaymentRoute,
  imREmitAddSupplierRoute,
  imREmitAddSupplierScriptDirectRoute,
  imREmitAddSupplierScriptRoute,
  imREmitDashboardRoute,
  imREmitEditPaymentRoute,
  imREmitEditSupplierRoute,
  imREmitEditSupplierScriptRoute,
  imREmitFileProcessingRoute,
  imREmitFilterPaymentRoute,
  imREmitMappingRoute,
  imREmitNotFoundRoute,
  imREmitPaymentManagementRedirectRoute,
  imREmitPaymentManagementRoute,
  imREmitProxyPayRedirectRoute,
  imREmitProxyPayRoute,
  imREmitRemittanceManagementRedirectRoute,
  imREmitRemittanceManagementRoute,
  imREmitRootRoute,
  imREmitSupplierManagementRoute,
  imREmitSupplierScriptRoute,
  imREmitViewPaymentRoute,
  imREmitViewProxyPaymentRootRoute,
  imREmitViewRemittanceRoute,
  imREmitViewSupplierRoute,
  imREmitViewSupplierScriptRoute,
  pdfUploadRoute,
  pdfUploadViewRoute,
} from "@/modules/imremit";
import {
  imREmitLiteAddSupplierRoute,
  imREmitLiteAddSupplierScriptRoute,
  // imREmitLiteCustomerManagementRoute,
  imREmitLiteDashboardRoute,
  imREmitLiteEditSupplierRoute,
  imREmitLiteEditSupplierScriptRoute,
  // imREmitLiteFileProcessingRedirectRoute,
  // imREmitLiteFileProcessingRoute,
  imREmitLiteFilterPaymentRoute,
  imREmitLiteNotFoundRoute,
  imREmitLitePaymentManagementRedirectRoute,
  imREmitLitePaymentManagementRoute,
  imREmitLitePaymentViewRootRoute,
  imREmitLiteProxyPayRedirectRoute,
  imREmitLiteProxyPayRoute,
  imREmitLiteRootRoute,
  imREmitLiteSupplierManagementRoute,
  imREmitLiteSupplierScriptRoute,
  imREmitLiteViewProxyPaymentRootRoute,
  imREmitLiteViewSupplierRoute,
  imREmitLiteViewSupplierScriptRoute,
} from "@/modules/imremit-lite";
import {
  createInvoiceTrackerMappingRoute,
  invoiceTrackerMappingRoute,
  invoiceTrackerNotFoundRoute,
  invoiceTrackerRedirectRoute,
  invoiceTrackerRootRoute,
  invoiceTrackerRoute,
} from "@/modules/invoices";
import {
  landingRootRoute,
  privacyRootRoute,
  privacyRoute,
  termsRootRoute,
  termsRoute,
} from "@/modules/landing";
import {
  profileNotFoundRoute,
  profileRootRoute,
  profileRoute,
} from "@/modules/profile";
import {
  settingsNotFoundRoute,
  settingsRootRoute,
  settingsRoute,
} from "@/modules/settings";
import {
  statementReconciliationRoute,
  statementReconDashboardRedirectRoute,
  // statementReconDashboardRoute,
  statementReconFileUploadRoute,
  statementReconGroupRoute,
  statementReconMappingRoute,
  statementReconNotFoundRoute,
  statementReconResultRoute,
  statementReconResultsRoute,
  statementReconRootRoute,
  statementReconStatementDetailRoute,
  statementReconStatementListRoute,
  statementReconStatementSettingsRoute,
  statementReconSummaryRoute,
} from "@/modules/statement-recon";

const routeTree = rootRoute.addChildren([
  landingRootRoute,
  // loginRootRoute.addChildren([loginRoute]),
  // registerRootRoute.addChildren([registerRoute]),
  // forgotPasswordRootRoute.addChildren([forgotPasswordRoute]),
  // resetPasswordRootRoute.addChildren([resetPasswordRoute]),
  // singleSignOnRootRoute.addChildren([singleSignOnRoute]),
  // supplierRegisterRootRoute.addChildren([supplierRegisterRoute]),
  privacyRootRoute.addChildren([privacyRoute]),
  termsRootRoute.addChildren([termsRoute]),
  authorizedRootRoute.addChildren([
    adminRootRoute.addChildren([
      adminCustomerManagementOnboardImRemitRoute,
      adminCustomerManagementOnboardDupesRoute,
      adminCustomerManagementOnboardStatementReconRoute,
      adminCustomerManagementEditDetailsRoute,
      adminCustomerManagementCreateRoute,
      adminCustomerManagementImREmitPMOnboardingRoute,
      adminCustomerManagementImREmitPROnboardingRoute,
      adminCustomerManagementImREmitECOnboardingRoute,
      adminCustomerManagementImREmitSCOnboardingRoute,
      adminCustomerManagementImREmitRCOnboardingRoute,
      adminCustomerManagementRoute,
      adminCustomerManagementViewDetailsRoute,
      adminCustomerManagementDuplicatePaymentsOnboardingRoute,
      adminSearchPIDMappingRoute,
      adminCreatePIDMappingRoute,
      adminEditPIDMappingRoute,
      adminMasterRoleSettingsRoute,
      adminUserRoleRelationRoute,
      adminCustomerModuleManagementRoute,
      adminAlertManagementRoute,
      adminUserManagementRoute,
      adminCreateUserRoute,
      adminEditUserRoute,
      adminViewUserRoute,
      adminSupplierOnboardingRoute,
      adReportsRoute,
      supplierManagementRoute,
      createSuppliersRoute,
      editSuppliersRoute,
      misPaymentsReceivedRoute,
      adminNotFoundRoute,
    ]),
    duplicatePaymentRootRoute.addChildren([
      duplicatePaymentsDashboardRedirectRoute,
      duplicatePaymentsDashboardRoute,
      duplicatePaymentsCriteriaSettingsRoute,
      duplicatePaymentsNotFoundRoute,
      dupesTrackerRoute,
    ]),
    statementReconRootRoute.addChildren([
      statementReconDashboardRedirectRoute,
      // statementReconDashboardRoute,
      statementReconFileUploadRoute,
      statementReconStatementDetailRoute,
      statementReconStatementSettingsRoute,
      statementReconStatementListRoute,
      statementReconNotFoundRoute,
      statementReconGroupRoute,
      statementReconResultsRoute,
      statementReconSummaryRoute,
      statementReconResultRoute,
      statementReconMappingRoute,
      statementReconciliationRoute,
    ]),
    imREmitRootRoute.addChildren([
      imREmitDashboardRoute,
      imREmitFileProcessingRoute,
      imREmitPaymentManagementRoute,
      imREmitPaymentManagementRedirectRoute,
      imREmitRemittanceManagementRoute,
      imREmitRemittanceManagementRedirectRoute,
      imREmitViewRemittanceRoute,
      imREmitAddPaymentRoute,
      imREmitViewPaymentRoute,
      imREmitEditPaymentRoute,
      imREmitFilterPaymentRoute,
      imREmitViewProxyPaymentRootRoute,
      imREmitProxyPayRoute,
      imREmitProxyPayRedirectRoute,
      imREmitSupplierManagementRoute,
      imREmitAddSupplierRoute,
      imREmitViewSupplierRoute,
      imREmitEditSupplierRoute,
      imREmitSupplierScriptRoute,
      imREmitAddSupplierScriptRoute,
      imREmitEditSupplierScriptRoute,
      imREmitViewSupplierScriptRoute,
      imREmitAddSupplierScriptDirectRoute,
      pdfUploadRoute,
      pdfUploadViewRoute,
      addimREmitMappingRoute,
      imREmitMappingRoute,
      imREmitNotFoundRoute,
    ]),
    imREmitLiteRootRoute.addChildren([
      imREmitLiteDashboardRoute,
      imREmitLitePaymentManagementRoute,
      imREmitLitePaymentManagementRedirectRoute,
      // imREmitLiteCustomerManagementRoute,
      imREmitLiteSupplierManagementRoute,
      imREmitLiteAddSupplierRoute,
      imREmitLiteEditSupplierRoute,
      imREmitLiteViewSupplierRoute,
      imREmitLitePaymentViewRootRoute,
      imREmitLiteFilterPaymentRoute,
      imREmitLiteProxyPayRoute,
      imREmitLiteProxyPayRedirectRoute,
      imREmitLiteViewProxyPaymentRootRoute,
      imREmitLiteSupplierScriptRoute,
      imREmitLiteAddSupplierScriptRoute,
      imREmitLiteEditSupplierScriptRoute,
      imREmitLiteViewSupplierScriptRoute,
      // imREmitLiteFileProcessingRoute,
      // imREmitLiteFileProcessingRedirectRoute,
      imREmitLiteNotFoundRoute,
    ]),
    invoiceTrackerRootRoute.addChildren([
      invoiceTrackerRoute,
      invoiceTrackerRedirectRoute,
      invoiceTrackerMappingRoute,
      createInvoiceTrackerMappingRoute,
      invoiceTrackerNotFoundRoute,
    ]),
    electronicInvoicingRootRoute.addChildren([
      electronicInvoicingPageRoute,
      electronicInvoicingNotFoundRoute,
    ]),
    helpRootRoute.addChildren([helpRoute, helpNotFoundRoute]),
    settingsRootRoute.addChildren([settingsRoute, settingsNotFoundRoute]),
    profileRootRoute.addChildren([profileRoute, profileNotFoundRoute]),
    authorizedNotFoundRootRoute,
  ]),
  notFoundRootRoute.addChildren([notFoundRoute]),
]);

export const router = createRouter({
  routeTree,
  defaultPreload: "intent",
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}
