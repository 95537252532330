import {z} from "zod";

export const paymentManagementIMRemitLiteTableColumnsSchema = z.object({
  orgId: z.boolean(),
  supplierName: z.boolean(),
  supplierNumber: z.boolean(),
  facilityName: z.boolean(),
  paymentNumber: z.boolean(),
  accountNumber: z.boolean(),
  totalAmountSent: z.boolean(),
  postingDate: z.boolean(),
  customerStatus: z.boolean(),
  statusName: z.boolean(),
  description: z.boolean(),
  descriptionDetail: z.boolean(),
  paymentDetailId: z.boolean(),
  initiatedDate: z.boolean(),
  endDate: z.boolean(),
  methodName: z.boolean(),
  amountTaken: z.boolean(),
  balanceAmount: z.boolean(),
  userId: z.boolean(),
  agentName: z.boolean(),
  remittanceMethod: z.boolean(),
});
export type PaymentManagementIMRemitLiteTableColumnsType = z.infer<
  typeof paymentManagementIMRemitLiteTableColumnsSchema
>;
