import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {proxyPayQueryKeys, type ProxyPaySchemaType} from "@/modules/imremit";

// Defining a type based on the function `searchProxyPay`
type QueryFnType = typeof searchProxyPay;

// Function to search proxy payment
export function searchProxyPay(
  externalId?: string,
  searchQueryString?: string
) {
  // Fetch proxy payment from API using ky
  return kyApiFn<ProxyPaySchemaType[]>(
    `api/e-payable/management/${externalId}/proxy-pay-details?${searchQueryString}`,
    "get"
  );
  // TODO: validate data
}

// Custom hook to fetch and cache proxy payment data using react-query
export function useSearchProxypay(
  externalId?: string, // The Participant ID
  searchQueryString?: string, // The search query string
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    // eslint-disable-next-line
    queryKey: proxyPayQueryKeys.byExternalIdAndSearchQuery(
      externalId?.toString(),
      searchQueryString
    ), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => searchProxyPay(externalId, searchQueryString), // Setting the function to be used for fetching data
    enabled: !!externalId,
    // TODO: add onSuccess and onError handlers
  });
}
