import {createRoute} from "@tanstack/react-router";

import {RoutesDirectory} from "@/lib/routes/directory";
import {
  statementReconRootRoute,
  StatementReconStatementDetailsPage,
} from "@/modules/statement-recon";

export const statementReconStatementDetailRoute = createRoute({
  path: `${RoutesDirectory.STATEMENT}/$statementId${RoutesDirectory.DETAILS}`,
  component: StatementReconStatementDetailsPage,
  getParentRoute: () => statementReconRootRoute,
});
