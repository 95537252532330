// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type CustomerManagementIMRemitLiteTableColumnsType} from "@/modules/imremit-lite";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface CustomerManagementIMRemitLiteTableColumnsStoreState {
  storeCustomerManagementIMRemitLiteTableColumns: CustomerManagementIMRemitLiteTableColumnsType | null;
  setStoreCustomerManagementIMRemitLiteTableColumns: (
    storeCustomerManagementIMRemitLiteTableColumns: CustomerManagementIMRemitLiteTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for CustomerManagementIMRemitLite table columns
export const useCustomerManagementIMRemitLiteTableColumnsStore = create(
  persist<CustomerManagementIMRemitLiteTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeCustomerManagementIMRemitLiteTableColumns: null,

      // Getter function to retrieve the current state
      getStoreCustomerManagementIMRemitLiteTableColumns: () =>
        get().storeCustomerManagementIMRemitLiteTableColumns,

      // Setter function to update the state
      setStoreCustomerManagementIMRemitLiteTableColumns: (
        storeCustomerManagementIMRemitLiteTableColumns
      ) => {
        set(() => ({storeCustomerManagementIMRemitLiteTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "CustomerManagementIMRemitLite-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useCustomerManagementIMRemitLiteTableColumns = () =>
  useCustomerManagementIMRemitLiteTableColumnsStore((state) => {
    return {
      storeCustomerManagementIMRemitLiteTableColumns:
        state.storeCustomerManagementIMRemitLiteTableColumns,
    };
  });
