import {useEffect, useMemo} from "react";

import {Label} from "@radix-ui/react-label";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {Heading2, Paragraph} from "@/components/ui/typography";

import {DuplicatePaymentRoutesSchema} from "@/lib/routes/types";
import {useDupesCustomerStore, useRoutesStore} from "@/lib/stores";
import type {RunTypeType} from "@/lib/stores/dupes-run-type-store";
import {useDupesRunTypeStore} from "@/lib/stores/dupes-run-type-store";
import {calculateUserRoles, useKeyCloakInstanceStore} from "@/modules/auth";
import {
  SelectCustomerFormDupes,
  SelectCustomerSectionDupes,
} from "@/modules/customers";
import {DupesTrackerTabs} from "@/modules/duplicate-payments";

import {FallbackMessages, KeycloakRoles} from "@/utils/constants";

function useDupesTrackerPageRoute() {
  const {setDuplicatePaymentRoute} = useRoutesStore();

  useEffect(() => {
    // Set the active route based on predefined route schema
    setDuplicatePaymentRoute(
      DuplicatePaymentRoutesSchema.Values["/app/duplicate-payments/tracker"]
    );
  }, [setDuplicatePaymentRoute]);
}

export function DupesTrackerPage() {
  useDupesTrackerPageRoute();

  // Extract the `tokenParsed` field from the store
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  // Extract the `customer` field from the tokenParsed object
  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );

  const userRoleDisplay = !parsedUserInfo
    ? FallbackMessages.NOT_APPLICABLE
    : calculateUserRoles(parsedUserInfo.realm_access?.roles);

  // Run Type State Store
  const {dupesRunType, updateDupesRunType} = useDupesRunTypeStore();

  // useDupesCustomerStore is used to get the current customer details
  const {dupesStoreCustomer} = useDupesCustomerStore();

  // If there is no customer selected, the SelectCustomerSectionDupes component is rendered
  if (!dupesStoreCustomer) {
    return <SelectCustomerSectionDupes />;
  }

  return (
    <section>
      <div className="flex justify-between pb-2">
        <div className="flex flex-col">
          <Heading2>Duplicate Payments Tracker</Heading2>
          <Paragraph>
            Welcome to the duplicate payments tracker. Here you can view and
            manage duplicate payments.
          </Paragraph>
        </div>

        <div className="flex gap-2">
          <div
            className={
              userRoleDisplay &&
              !userRoleDisplay.includes(KeycloakRoles.Management)
                ? "invisible"
                : ""
            }
          >
            <Label htmlFor="runtype">Run Type</Label>
            <Select
              value={dupesRunType?.toString()}
              onValueChange={(type: RunTypeType) => {
                updateDupesRunType(type);
              }}
            >
              <SelectTrigger className="w-[180px] bg-white">
                <SelectValue placeholder="Select Run type" />
              </SelectTrigger>
              <SelectContent className="bg-white">
                <SelectItem value="OneTime">One Time Run</SelectItem>
                <SelectItem value="Initial">Initial Run</SelectItem>
                <SelectItem value="Daily">Daily Run</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <SelectCustomerFormDupes requireLabel />
        </div>
      </div>
      <DupesTrackerTabs externalId={dupesStoreCustomer.externalId} />
    </section>
  );
}
