import {cva} from "class-variance-authority";

import {Skeleton} from "@/components/ui/skeleton";

const SKELETON_VARIANT_CLASSES = {
  size: {
    default: "h-4",
    lg: "h-6",
    xl: "h-8",
  },
  width: {
    default: "w-full",
    xl: "w-96",
    lg: "w-80",
    md: "w-36",
  },
  colour: {
    default: "bg-zinc-300/50",
  },
};

const skeletonVariants = cva("flex flex-col gap-4", {
  variants: SKELETON_VARIANT_CLASSES,
  defaultVariants: {
    size: "default",
    width: "default",
    colour: "default",
  },
});

export function SupplierScriptSkeleton() {
  return (
    <section className="grid grid-cols-2 gap-4">
      <div className="flex flex-col justify-items-start gap-2">
        <Skeleton className={skeletonVariants({size: "xl", width: "xl"})} />
        <Skeleton />
      </div>

      <div className="flex flex-row justify-items-end gap-2">
        <Skeleton className={skeletonVariants({size: "xl", width: "lg"})} />
        <Skeleton className={skeletonVariants({size: "xl", width: "xl"})} />
        <Skeleton className={skeletonVariants({size: "xl", width: "md"})} />
      </div>
    </section>
  );
}
