import {CopySlashIcon, FileCogIcon, FileSearchIcon} from "lucide-react";

import {SubrouteNavigation} from "@/components/craft/subroute-navigation";

import {DuplicatePaymentRoutesSchema} from "@/lib/routes/types";
import {KeycloakRoleEnum} from "@/modules/auth";

// Define an array with the data for each route button. This includes the 'to' route,
// the 'icon' to be displayed, and the 'label' for the button.
// This array holds the configuration for each SubrouteNavigationButton
// Each object in the array represents a button in the navigation menu
const routes = [
  {
    to: DuplicatePaymentRoutesSchema.Values[
      "/app/duplicate-payments/dashboard"
    ],
    icon: <CopySlashIcon className="h-10 w-10 pl-2" />,
    label: "Duplicates Dashboard",
    actionRole: KeycloakRoleEnum.READ_DUPES_DASHBOARD,
  },
  {
    to: DuplicatePaymentRoutesSchema.Values["/app/duplicate-payments/tracker"],
    icon: <FileSearchIcon className="h-10 w-10 pl-2" />,
    label: "Duplicates Tracker",
    actionRole: KeycloakRoleEnum.READ_DUPES_TRACKER,
  },
  {
    to: DuplicatePaymentRoutesSchema.Values[
      "/app/duplicate-payments/criteria-settings"
    ],
    icon: <FileCogIcon className="h-10 w-10 pl-2" />,
    label: "Criteria Settings",
    actionRole: KeycloakRoleEnum.READ_DUPES_CRITERIA,
  },
];

interface DuplicatePaymentsSubrouteNavigationProps {
  layout?: "horizontal" | "vertical";
}

// This component renders the SubrouteNavigation component with the routes array
// as a prop. It also passes the 'navigationAccessibleLabel' and
// 'navigationAriaLabelledby' props to the SubrouteNavigation component.
export function DuplicatePaymentsSubrouteNavigation(
  {layout}: DuplicatePaymentsSubrouteNavigationProps = {layout: "horizontal"}
) {
  return (
    <SubrouteNavigation
      routes={routes}
      layout={layout}
      navigationAccessibleLabel="DuplicatePayments Subroute Navigation"
      navigationAriaLabelledby="duplicatePaymentsSubrouteNavHeader"
    />
  );
}
