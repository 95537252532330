// Importing the 'Route' class from '@tanstack/react-router'. This is used for defining
// individual routes within your application.
// Importing the 'GenericError' component. This component will be displayed when
// there's a generic error in your application.

// Importing the root route configuration from the application's route configuration.
// This will serve as the base route for all the routes in your application.
import {createRoute} from "@tanstack/react-router";

// Importing the 'ModuleNotFound' component. This component will be rendered when
// a route doesn't match any of the defined routes in your application.
import {ModuleNotFound} from "@/components/layout/not-found.tsx";

// Importing the 'RootOutlet' component. This component is a placeholder where
// other components can be rendered.
import {RootOutlet} from "@/lib/config/outlets";
import {RoutesDirectory} from "@/lib/routes/directory";
import {rootRoute} from "@/lib/routes/root";

// Defining the 'notFoundRootRoute'. This route uses the wildcard '*' path to match
// all routes not matched by other routes in your application. It uses the 'RootOutlet'
// component as its base, meaning other components will be rendered within it.
// The parent of this route is set as the 'rootRoute'.
export const notFoundRootRoute = createRoute({
  path: RoutesDirectory.CATCH_ALL,
  component: RootOutlet,
  getParentRoute: () => rootRoute,
});

// Defining the 'notFoundRoute'. This is the route that gets rendered when a user
// navigates to a route that doesn't exist in your application. It renders the 'ModuleNotFound'
// component and its parent route is the 'notFoundRootRoute'.
export const notFoundRoute = createRoute({
  path: RoutesDirectory.ROOT,
  component: ModuleNotFound,
  getParentRoute: () => notFoundRootRoute,
});
