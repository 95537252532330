import {useEffect, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {MessageSquarePlusIcon, PencilIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {Spinner} from "@/components/ui/spinner";
import {Textarea} from "@/components/ui/textarea";
import {toast} from "@/components/ui/use-toast";

import {useKeyCloakInstanceStore} from "@/modules/auth";
import {
  SupplierCommentsFormSchema,
  updateSupplierCommentFn,
} from "@/modules/imremit";

import {KeycloakRoles} from "@/utils/constants";

interface SupplierCommentUpdateProps {
  comments: string;
  commentId: number;
}

export function SupplierCommentUpdate({
  comments,
  commentId,
}: SupplierCommentUpdateProps) {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const [open, setOpen] = useState<boolean>(false);

  const supplierCommentsFormMethods = useForm<
    z.infer<typeof SupplierCommentsFormSchema>
  >({
    defaultValues: {
      userId: storeKeyCloakInstance?.tokenParsed?.name as string,
      role: KeycloakRoles.ProgramManager,
      comments: comments,
    },
    resolver: zodResolver(SupplierCommentsFormSchema),
  });

  const saveSupplierCommentMutation = useMutation({
    mutationFn: (data: z.infer<typeof SupplierCommentsFormSchema>) => {
      return updateSupplierCommentFn(data, commentId);
    },

    onSuccess: (response) => {
      supplierCommentsFormMethods.reset();
      console.log("saveSupplierCommentResponse", response);
      setOpen(false);
      toast({
        variant: "success",
        title: "Success!",
        description: "Comment updated successfully",
      });
    },
    onError: (error: unknown) => {
      console.error("error", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update comment",
      });
    },
  });
  const {isPending: isMutating} = saveSupplierCommentMutation;

  function onSubmit(data: z.infer<typeof SupplierCommentsFormSchema>) {
    saveSupplierCommentMutation.mutate(data);
  }

  const {
    formState: {errors},
  } = supplierCommentsFormMethods;

  console.log(errors);

  useEffect(() => {
    if (comments) {
      supplierCommentsFormMethods.setValue("comments", comments);
    }
  }, [comments, supplierCommentsFormMethods]);

  return (
    <Dialog open={open}>
      <DialogTrigger asChild>
        <Button
          variant="default"
          onClick={() => {
            setOpen(true);
          }}
        >
          <PencilIcon className="mr-2 size-4" />
          Update
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[550px]" removeCloseButton>
        <DialogHeader>
          <DialogTitle>Edit Comment</DialogTitle>
        </DialogHeader>
        <Form {...supplierCommentsFormMethods}>
          <form
            className="grid w-full gap-4"
            onSubmit={supplierCommentsFormMethods.handleSubmit(onSubmit)}
          >
            <FormField
              control={supplierCommentsFormMethods.control}
              name="comments"
              render={() => (
                <FormItem>
                  <FormControl>
                    <Textarea
                      id="comments"
                      placeholder="Type your comment here."
                      disabled={isMutating}
                      aria-disabled={isMutating}
                      autoCapitalize="none"
                      autoComplete="comments"
                      autoCorrect="off"
                      {...supplierCommentsFormMethods.register("comments")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className="">
              <Button
                type="button"
                size="xs"
                disabled={isMutating}
                aria-disabled={isMutating}
                variant="input"
                className="gap-2"
                onClick={() => {
                  setOpen(false);
                }}
              >
                Close
              </Button>
              <Button
                type="submit"
                disabled={isMutating}
                aria-disabled={isMutating}
                variant="default"
                size="xs"
                className="gap-2"
              >
                {isMutating ? (
                  <Spinner size="xs" />
                ) : (
                  <MessageSquarePlusIcon className="size-4" />
                )}
                Update comment
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
