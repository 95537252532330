// Import required libraries and modules
import React from "react";

import {CheckIcon, PlusCircleIcon, XCircleIcon, XIcon} from "lucide-react";
import {z} from "zod";

import {Badge} from "@/components/ui/badge";
import {Button} from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {ScrollArea} from "@/components/ui/scroll-area";
import {Separator} from "@/components/ui/separator";

import {cn} from "@/lib/utils";

/**
 * Defines the schema for faceted filter data using Zod.
 *
 * @typedef {Object} FacetedFilterDatum
 * @property {string} label - The label for the filter option.
 * @property {string} value - The unique value representing the filter option.
 */

export const facetedFilterDatumSchema = z.object({
  label: z.string(),
  value: z.string(),
  // icon: z.string(),
});

// Infer a TypeScript type from the Zod schema
export type FacetedFilterDatum = z.infer<typeof facetedFilterDatumSchema>;

interface DataTableFacetedFilterProps {
  facetedFilterData: FacetedFilterDatum[];
  title?: string;
  disabled?: boolean;
  value: FacetedFilterDatum[];
  onChange: (value: FacetedFilterDatum[]) => void;
  maxCount?: number;
  modalPopover?: boolean;
  asChild?: boolean;
  className?: string;
}

/**
 * A functional component for rendering a faceted filter in a data table.
 *
 * @component
 * @param {DataTableFacetedFilterProps} props - The component's props.
 * @param {React.Ref<HTMLDivElement>} ref - A React ref to the component's root element.
 */
export const DataTableFacetedFilter = React.forwardRef<
  HTMLButtonElement,
  DataTableFacetedFilterProps
>(
  (
    {
      facetedFilterData,
      onChange,
      value,
      title = "Select options",
      maxCount = 3,
      modalPopover = false,
      ...props
    },
    ref
  ) => {
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);

    const valueSet = new Set(value.map((v) => v.value));

    const toggleOption = (optionValue: string) => {
      const option = facetedFilterData.find((o) => o.value === optionValue);
      if (!option) return;

      const newSelectedValues = valueSet.has(optionValue)
        ? value.filter((v) => v.value !== optionValue)
        : [...value, option];
      onChange(newSelectedValues);
    };

    const handleClear = () => {
      onChange([]);
    };

    const clearExtraOptions = () => {
      onChange(value.slice(0, maxCount));
    };

    const toggleAll = () => {
      if (value.length === facetedFilterData.length) {
        handleClear();
      } else {
        onChange(facetedFilterData);
      }
    };

    return (
      <Popover
        open={isPopoverOpen}
        modal={modalPopover}
        onOpenChange={setIsPopoverOpen}
      >
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            {...props}
            disabled={props.disabled ?? false}
            variant="outline"
            size="sm"
            className="h-8 whitespace-nowrap border-dashed bg-root"
            onClick={() => {
              setIsPopoverOpen((prev) => !prev);
            }}
          >
            {value.length > 0 ? (
              <div className="flex w-full items-center justify-between">
                <div className="flex space-x-2">
                  {value.slice(0, maxCount).map((selectedOption) => {
                    const {value: selectedValue, label} = selectedOption;
                    return (
                      <Badge
                        key={selectedValue}
                        variant="secondary"
                        className="mr-2"
                      >
                        {label}
                        <XCircleIcon
                          className="ml-2 h-4 w-4 cursor-pointer"
                          onClick={(event) => {
                            event.stopPropagation();
                            toggleOption(selectedValue);
                          }}
                        />
                      </Badge>
                    );
                  })}
                  {value.length > maxCount && (
                    <Badge
                      variant="secondary"
                      className={cn("rounded-sm px-1 font-normal ")}
                    >
                      {`+ ${String(value.length - maxCount)} more`}
                      <XCircleIcon
                        className="ml-2 h-4 w-4 cursor-pointer"
                        onClick={(event) => {
                          event.stopPropagation();
                          clearExtraOptions();
                        }}
                      />
                    </Badge>
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <XIcon
                    className="mx-2 h-4 cursor-pointer text-muted-foreground"
                    onClick={(event) => {
                      event.stopPropagation();
                      handleClear();
                    }}
                  />
                  <Separator
                    orientation="vertical"
                    className="flex h-full min-h-6"
                  />
                  <PlusCircleIcon className="mr-2 size-4" />
                </div>
              </div>
            ) : (
              <div className="mx-auto flex w-full items-center justify-between">
                <PlusCircleIcon className="mr-2 size-4" />

                {title}
              </div>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="p-0"
          align="start"
          onEscapeKeyDown={() => {
            setIsPopoverOpen(false);
          }}
        >
          <Command>
            <CommandInput placeholder={title} />
            <ScrollArea className="max-h-[300px] min-h-[100px]">
              <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup>
                  <CommandItem
                    key="all"
                    className="cursor-pointer"
                    onSelect={toggleAll}
                  >
                    <div
                      className={cn(
                        "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                        value.length === facetedFilterData.length
                          ? "bg-primary text-primary-foreground"
                          : "opacity-50 [&_svg]:invisible"
                      )}
                    >
                      <CheckIcon className="h-4 w-4" />
                    </div>
                    <span>(Select All)</span>
                  </CommandItem>
                  {facetedFilterData.map((option) => {
                    const isSelected = valueSet.has(option.value);
                    return (
                      <CommandItem
                        key={option.value}
                        className="cursor-pointer"
                        onSelect={() => {
                          toggleOption(option.value);
                        }}
                      >
                        <div
                          className={cn(
                            "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                            isSelected
                              ? "bg-primary text-primary-foreground"
                              : "opacity-50 [&_svg]:invisible"
                          )}
                        >
                          <CheckIcon className="h-4 w-4" />
                        </div>

                        <span>{option.label}</span>
                      </CommandItem>
                    );
                  })}
                </CommandGroup>
              </CommandList>
            </ScrollArea>
            <CommandSeparator />
            <CommandGroup>
              <div className="flex items-center justify-between">
                {value.length > 0 && (
                  <>
                    <CommandItem
                      className="flex-1 cursor-pointer justify-center"
                      onSelect={handleClear}
                    >
                      Clear
                    </CommandItem>
                    <Separator
                      orientation="vertical"
                      className="flex h-full min-h-6"
                    />
                  </>
                )}
                <CommandItem
                  className="max-w-full flex-1 cursor-pointer justify-center"
                  onSelect={() => {
                    setIsPopoverOpen(false);
                  }}
                >
                  Close
                </CommandItem>
              </div>
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    );
  }
);

DataTableFacetedFilter.displayName = "DataTableFacetedFilter";
