import {z} from "zod";

import {multiSelectDatumSchema} from "@/components/craft/fancy-multi-select";

import {
  ipv4Pattern,
  ipv4WithPortPattern,
  IPv4withURLPattern,
  ipv6Pattern,
  ipv6WithPortPattern,
  IPv6withURLPattern,
  KeycloakRolesWithBackslash,
} from "@/utils/constants";

export const keycloakUserSchema = z.object({
  attributes: z
    .object({
      supplier: z.string().nullable().optional(),
      customer: z.string().nullable().optional(),
    })
    .optional()
    .nullable(),
  id: z.string(), // 🆔 Validates that 'id' is properly formatted as a string.
  createdTimestamp: z.number(), // ⏰ Ensures 'createdTimestamp' is a number representing a timestamp.
  username: z.string(), // 📛 Confirms 'username' is a non-empty string.
  enabled: z.boolean(), // 🔛 Checks that 'enabled' is a boolean (true or false).
  totp: z.boolean(), // 🔐 Verifies 'totp' (Time-based One-Time Password) as a boolean.
  emailVerified: z.boolean(), // ✉️ Ensures 'emailVerified' status is a boolean.
  firstName: z.string(), // 📝 Validates 'firstName' as a proper string.
  lastName: z.string(), // 📝 Validates 'lastName' as a proper string.
  email: z.string().email().nullable().optional(), // 📧 Checks that 'email' is a valid email format.
  disableableCredentialTypes: z.array(z.string()), // 🔑 Validates a list of credential types that can be disabled.
  requiredActions: z.array(z.string()), // 📋 Ensures a list of required actions is provided as strings.
  notBefore: z.number(), // 🕒 Validates 'notBefore' as a numeric value representing a time constraint.
  access: z.object({
    manageGroupMembership: z.boolean(), // 🧑‍🤝‍🧑 Validates permissions to manage group memberships.
    view: z.boolean(), // 👀 Checks permission for viewing capabilities.
    mapRoles: z.boolean(), // 🗺️ Confirms ability to map roles.
    impersonate: z.boolean(), // 👤 Verifies permission to impersonate.
    manage: z.boolean(), // 🛠️ Validates manage permissions.
  }),
  rdpIpaddress: z.string().optional().nullable(), // 🌐 Validates 'rdpIpaddress' as a string.
});

export type KeycloakUserType = z.infer<typeof keycloakUserSchema>;

export const keycloakUserCredentialSchema = z.object({
  id: z.string(),
  type: z.string(),
  value: z.string(),
  createdDate: z.number(),
  credentialData: z.object({
    hashIterations: z.number(),
    algorithm: z.string(),
    additionalParameters: z.record(z.unknown()),
  }),
});
export type KeycloakUserCredentialType = z.infer<
  typeof keycloakUserCredentialSchema
>;

export const keycloakUserGroupSchema = z.object({
  id: z.string(),
  name: z.string(),
  path: z.string(),
});
export type KeycloakUserGroupType = z.infer<typeof keycloakUserGroupSchema>;

export const keycloakGroupSchema = z.object({
  role: z.string(),
  type: z.string(),
});
export type KeycloakGroupType = z.infer<typeof keycloakGroupSchema>;

export const keycloakUserRolesSchema = z.object({
  realmMappings: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      description: z.string(),
      composite: z.boolean(),
      clientRole: z.boolean(),
      containerId: z.string(),
    })
  ),
});
export type KeycloakUserRolesType = z.infer<typeof keycloakUserRolesSchema>;

export const keycloakUserManagementColumnsSchema = z.object({
  id: z.boolean(),
  username: z.boolean(),
  email: z.boolean(),
  firstName: z.boolean(),
  lastName: z.boolean(),
  emailVerified: z.boolean(),
  enabled: z.boolean(),
  createdTimestamp: z.boolean(),
});

export type KeycloakUserManagementColumnsType = z.infer<
  typeof keycloakUserManagementColumnsSchema
>;

export const keycloakUserResetPasswordFormSchema = z
  .object({
    type: z.string(),
    value: z.string().min(4),
    cvalue: z.string().min(4),
    temporary: z.boolean(),
  })
  .superRefine(({cvalue, value}, ctx) => {
    if (cvalue !== value) {
      ctx.addIssue({
        code: "custom",
        message: "Password and confirmation does not match",
        path: ["cvalue"],
      });
    }
  });

export type keycloakUserResetPasswordFormType = z.infer<
  typeof keycloakUserResetPasswordFormSchema
>;
export const keycloakCreateUserFormSchema = z
  .object({
    // username: z.string().min(1).max(140),
    username: z
      .string()
      .min(1)
      .max(140)
      .refine((s) => !s.includes(" "), "Username must not contain spaces!"),
    email: z.string().email(),
    firstName: z.string().min(1, "Please enter your first name").max(140),
    lastName: z.string().max(140).optional().nullable(),
    requiredActions: z.array(z.string()).optional().nullable(),
    emailVerified: z.boolean().default(false).optional().nullable(),
    groups: z.array(multiSelectDatumSchema),
    attributes: z.record(z.unknown()).optional().nullable(),
    enabled: z.boolean().default(true).optional().nullable(),
    rdpIpaddress: z
      .string()
      .refine(
        (val) =>
          val === "" ||
          ipv4Pattern.test(val) ||
          ipv6Pattern.test(val) ||
          ipv4WithPortPattern.test(val) ||
          IPv4withURLPattern.test(val) ||
          ipv6WithPortPattern.test(val) ||
          IPv6withURLPattern.test(val),
        {
          message: "Invalid IP address format",
        }
      )
      .optional()
      .nullable(),
    customer: z.string(),
    supplier: z.string(),
    credentials: keycloakUserResetPasswordFormSchema,
    selectedCustomerForSupplier: z.string().optional().nullable(),
  })
  .superRefine(({groups, customer, supplier}, ctx) => {
    const isCustomerAdmin = groups.some(
      (group) =>
        group.value.trim() === KeycloakRolesWithBackslash.CustomerAdmin ||
        group.value.trim() === KeycloakRolesWithBackslash.CustomerReviewer ||
        group.value.trim() === KeycloakRolesWithBackslash.CustomerUser ||
        group.value.trim() === KeycloakRolesWithBackslash.CustomerSuperAdmin
    );

    if (isCustomerAdmin && !customer) {
      ctx.addIssue({
        code: "custom",
        message: "Please select customer!",
        path: ["customer"],
      });
    }

    const isSupplierAdmin = groups.some(
      (group) =>
        group.value.trim() === KeycloakRolesWithBackslash.SupplierAdmin ||
        group.value.trim() === KeycloakRolesWithBackslash.SupplierEnablement ||
        group.value.trim() === KeycloakRolesWithBackslash.SupplierUser
    );

    if (isSupplierAdmin && !supplier) {
      ctx.addIssue({
        code: "custom",
        message: "Please select supplier!",
        path: ["supplier"],
      });

      if (!customer) {
        ctx.addIssue({
          code: "custom",
          message: "Please select customer!",
          path: ["customer"],
        });
      }
    }
  });

const customAttributesSchema = z.record(z.array(z.string()));

export const keycloakCreateUserSchema = z.object({
  username: z.string().min(1).max(140),
  email: z.string().email(),
  firstName: z.string().max(140),
  lastName: z.string().max(140).optional().nullable(),
  requiredActions: z.array(z.string()).optional().nullable(),
  emailVerified: z.boolean().default(false).optional().nullable(),
  groups: z.array(z.string()).optional().nullable(),
  enabled: z.boolean().default(true).optional().nullable(),
  credentials: z.array(
    z.object({
      type: z.string(),
      value: z.string().min(4),
      temporary: z.boolean(),
    })
  ),
  attributes: customAttributesSchema.optional(),
});

export type KeycloakCreateUserTypeDTO = z.infer<
  typeof keycloakCreateUserSchema
>;
