import {kyApiFn} from "@/lib/ky";
import type {ExportSupplierDataFormDTO} from "@/modules/imremit";

// export Supplier Data
export const exportSupplierDataFn = (
  exportSupplierDataFormDTO: ExportSupplierDataFormDTO
) =>
  kyApiFn("api/e-payable/management/supplier-details/export", "post", {
    json: exportSupplierDataFormDTO,
  });
