import {z} from "zod";

export const dupesCustomerSettingsSchema = z.object({
  customer_id: z.string(),
  buyer_external_id: z.string(),
  initial_run_cutoff_percent: z.number().min(1).max(100).nullable(),
  initial_run_months: z.number().nullable(),
  initial_run_subdivisions: z.number().nullable(),
  run_type: z.string().nullable(),
  run_id: z.string().nullable(),
  run_date: z.string().nullable(),
  run_started: z.boolean().nullable(),
  run_ended: z.boolean().nullable(),
  stopped: z.boolean().nullable(),
  additional_criteria: z.string().nullable(),
  cluster_id: z.string().nullable(),
  start_time: z.coerce.date().nullable(),
  end_time: z.coerce.date().nullable(),
  total_time: z.string().nullable(),
  comparison_records: z.string().nullable(),
  notes: z.string().nullable(),
  block_maybe: z.boolean().nullable(),
  block_different_vendor_maybe: z.boolean().nullable(),
  block_likely: z.boolean().nullable(),
  block_different_vendor_likely: z.boolean().nullable(),
  initial_run_end_date: z.coerce.date().nullable(),
  initial_run_start_date: z.coerce.date().nullable(),
  field_value: z.number().nullable(),
});

export type DupesCustomerSettingsType = z.infer<
  typeof dupesCustomerSettingsSchema
>;

export const dupesPaymentSettingsSchema = z.object({
  initial_run_cutoff_percent: z.number().min(1).max(100).nullable(),
  initial_run_months: z.number().nullable().optional(),
  initial_run_subdivisions: z.number().nullable().optional(),
  initial_run_start_date: z.coerce.date().nullable(),
  initial_run_end_date: z.coerce.date().nullable(),
  block_likely: z.boolean().nullable(),
  block_maybe: z.boolean().nullable(),
  block_different_vendor_likely: z.boolean().nullable(),
  block_different_vendor_maybe: z.boolean().nullable(),
  notes: z.string().nullable(),
  field_value: z.number().nullable().optional(),
});

export type DupesPaymentSettingsType = z.infer<
  typeof dupesPaymentSettingsSchema
>;
