import {
  paymentFileColumns,
  PaymentFileDataTable,
  usePaymentFileDetails,
} from "@/modules/imremit";

/**
 * PaymentFileList Component
 *
 * This component provides the report for Payment File Mapping
 * and rendering the payment file report table.
 */
export function PaymentFileList() {
  // Fetch the payment report data using the custom hook `usePaymentFileDetails`
  const searchPaymentFileResponse = usePaymentFileDetails();

  // Destructure the `data` property, focusing on its `content` attribute
  const paymentFiletData = searchPaymentFileResponse.data?.content || [];

  // Render the  payment file report table when the  payment file data available
  return (
    <PaymentFileDataTable
      columns={paymentFileColumns}
      data={paymentFiletData}
      isPending={searchPaymentFileResponse.isPending}
      isSuccess={searchPaymentFileResponse.isSuccess}
      isError={searchPaymentFileResponse.isError}
    />
  );
}
