import {Link} from "@tanstack/react-router";
import {cva} from "class-variance-authority";
import {CircleOffIcon, FileSpreadsheetIcon} from "lucide-react";

import {Button, buttonVariants} from "@/components/ui/button";
import {Separator} from "@/components/ui/separator";
import {Skeleton} from "@/components/ui/skeleton";
import {Heading3, Paragraph} from "@/components/ui/typography";
import {LoadingSection} from "@/components/craft/loading-section";

import {cn} from "@/lib/utils";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";
import {useImREmitDashboard} from "@/modules/imremit";

import {getPastDateRange} from "@/utils/functions";

// Define custom button variants for dashboard number alerts
const dashboardNumberAlertVariants = cva(
  "pb-4 text-center text-4xl font-bold",
  {
    variants: {
      colorThreshold: {
        low: "text-accent-foreground",
        medium: "text-secondary-foreground",
        high: "text-destructive-foreground",
      },
    },
    defaultVariants: {
      colorThreshold: "low",
    },
  }
);

type ImREmitPaymentFilterType = {
  status?: string;
  dateRange?: {
    startDate: number;
    endDate: number;
  };
};

// Define the prop types for the ImREmitDashboardCard component
// The top property is for the main headline
// The middle property is an object containing top and bottom text parts for subheadlines
// The bottom property is for some smaller, less emphasized text
interface ImREmitDashboardCardProps {
  top?: number;
  middle?: {
    top: string;
    bottom?: string;
  };
  bottom?: React.ReactNode;
  filterOptions: ImREmitPaymentFilterType;
  policyName: KeycloakRoleEnum;
}

// Create a functional component for the ImREmitDashboardCard
// It accepts props of ImREmitDashboardCardProps type and deconstructs them in the parameter list
// Inside the component, we define a layout and display the prop values with some conditional rendering
// If some prop values are not provided, Skeleton components are used to maintain the layout
export function ImREmitDashboardCard({
  top,
  middle,
  bottom,
  policyName,
}: ImREmitDashboardCardProps) {
  function getColorThreshold(value: number) {
    if (value === 0) return "low";
    if (value > 0 && value < 10) return "medium";
    return "high";
  }

  return (
    <AccessPolicyWrapper
      policyActions={[policyName]}
      unauthorizedFallback={null}
    >
      <div className="min-w-full rounded-lg border bg-root p-4 shadow-sm">
        <div className="flex h-full w-full flex-col justify-between">
          <div className="flex flex-col">
            {top || top === 0 ? (
              <Paragraph
                className={cn(
                  dashboardNumberAlertVariants({
                    colorThreshold: getColorThreshold(top),
                  })
                )}
              >
                {top}
              </Paragraph>
            ) : (
              <Skeleton className="my-2 h-6 w-full" />
            )}
            {middle ? (
              <Heading3 className="text-2xl font-bold">{middle.top}</Heading3>
            ) : (
              <Skeleton className="h-6 w-full" />
            )}
            {middle ? (
              <Paragraph className="text-sm font-medium uppercase text-accent-foreground">
                {middle.bottom}
              </Paragraph>
            ) : (
              <Skeleton className="h-6 w-full" />
            )}
          </div>
          <Separator className="mt-2" />
          {bottom ? (
            <>{bottom}</>
          ) : (
            <Button size="sm" aria-disabled disabled>
              <LoadingSection sectionSize="xs" />
            </Button>
          )}
        </div>
      </div>
    </AccessPolicyWrapper>
  );
}

// This function is a helper function to create card props.
// It accepts topData which is used to provide the 'top' prop,
// topText which is used for 'middle.top', and bottomText used for 'middle.bottom'.
// If topData is undefined, "No Records" is shown in the 'bottom' prop, else "Click to Review" is shown.
const createCardProps = (
  policyName: KeycloakRoleEnum,
  filterOptions: ImREmitPaymentFilterType,
  topData: number | undefined,
  topText: string,
  bottomText?: string,
  methodType?: string
): ImREmitDashboardCardProps => ({
  policyName,
  top: topData,
  middle: {
    top: topText,
    bottom: bottomText,
  },
  bottom: topData ? (
    <Link
      to="/app/imremit/payment-management/$pmPage/payments/$methodType"
      params={{methodType: methodType || "", pmPage: "1"}}
      className={cn(
        buttonVariants({size: "sm"}),
        "mt-4 gap-2 bg-success-foreground text-sm font-medium uppercase text-root hover:bg-success-foreground/70 active:bg-success-foreground/80"
      )}
      // search={{
      //   dateRange: {
      //     startDate: filterOptions.dateRange?.startDate,
      //     endDate: filterOptions.dateRange?.endDate,
      //   },
      // }}
    >
      <FileSpreadsheetIcon className="size-4" />
      <span className="sr-only">Review </span>
      Review
    </Link>
  ) : (
    <Button
      className="mt-4 gap-2 text-sm font-medium uppercase"
      size="sm"
      aria-disabled
      disabled
    >
      <CircleOffIcon className="size-4" />
      <span className="sr-only">No records</span>
      No records
    </Button>
  ),
  filterOptions,
});

// Define the prop types for the ImREmitDashboardCards component
interface ImREmitDashboardCardsProps {
  externalId: string;
}

// This function component fetches dashboard data and creates an array of cards using that data.
// It uses the createCardProps helper function to create the card props.
export function ImREmitDashboardCards({
  externalId,
}: ImREmitDashboardCardsProps) {
  // Fetch the dashboard data using the useImREmitDashboard hook.

  const imREmitDashboardQuery = useImREmitDashboard(externalId);

  // Deconstruct the fetched data content.
  const imREmitDashboardData = imREmitDashboardQuery.data?.content;
  // Create card props for each specific data point fetched, to be passed into the ImREmitDashboardCard component.
  const cardProps: ImREmitDashboardCardProps[] = [
    createCardProps(
      KeycloakRoleEnum.READ_IMREMIT_DASHBOARD_ADMIN,
      {
        dateRange: {
          startDate: getPastDateRange(5).startDate.getTime(),
          endDate: getPastDateRange(5).endDate.getTime(),
        },
      },
      imREmitDashboardData?.pendingPayments,
      "Payables pending for",
      "more than 5 days",
      "filter-pending-payable"
    ),
    createCardProps(
      KeycloakRoleEnum.READ_IMREMIT_DASHBOARD,
      {
        dateRange: {
          startDate: getPastDateRange(7).startDate.getTime(),
          endDate: getPastDateRange(7).endDate.getTime(),
        },
      },
      imREmitDashboardData?.endingPayableInNext7Days,
      "Payables ending in",
      "the next 7 days",
      "filter-ending-payable"
    ),
    createCardProps(
      KeycloakRoleEnum.READ_IMREMIT_DASHBOARD,
      {
        dateRange: {
          startDate: getPastDateRange(30).startDate.getTime(),
          endDate: getPastDateRange(30).endDate.getTime(),
        },
      },
      imREmitDashboardData?.missedPayableInPast30Days,
      "Payables missed in",
      "the past 30 days",
      "filter-missed-payable"
    ),
    createCardProps(
      KeycloakRoleEnum.READ_IMREMIT_DASHBOARD,
      {},
      imREmitDashboardData?.payableWithTransactionMatchingIssue,
      "Payables with transaction matching issues",
      "",
      "filter-matching-issue-payable"
    ),
    createCardProps(
      KeycloakRoleEnum.READ_IMREMIT_DASHBOARD,
      {},
      imREmitDashboardData?.declinedPaymentInPast7Days,
      "Payables with declines in",
      "the past 7 days",
      "filter-declined-payable"
    ),
    createCardProps(
      KeycloakRoleEnum.READ_IMREMIT_DASHBOARD_ADMIN,
      {},
      imREmitDashboardData?.paymentWithErrorOnIMSide,
      "Failed payments on IM",
      "iM Error",
      "filter-error-payable"
    ),
    createCardProps(
      KeycloakRoleEnum.READ_IMREMIT_DASHBOARD_ADMIN,
      {},
      imREmitDashboardData?.payableWithErrorOnProviderSide,
      "Failed payments on provider",
      "Payment Provider Error",
      "filter-provider-issue-payable"
    ),
  ];

  // Map over each item in cardProps to create a ImREmitDashboardCard
  // Note the use of the spread operator (...) to pass all the properties of the props object as props to the card
  return (
    <>
      {cardProps.map((props, i) => (
        <ImREmitDashboardCard key={i} {...props} />
      ))}
    </>
  );
}
