import {create} from "zustand";

import {type CustomerType} from "@/modules/customers";

// Defining the shape of the state object for the CustomerStore
// In this case, the state will consist of an array of Customer objects
interface DupesCustomersStoreState {
  dupesStoreCustomer: CustomerType | null;
}

// Defining the actions that can be performed on the CustomerStore state
// In this case, there's only one action, updateDupesStoreCustomer, which takes an array of Customer objects
interface DupesCustomerStoreActions {
  updateDupesStoreCustomer: (
    dupesStoreCustomer: DupesCustomersStoreState["dupesStoreCustomer"]
  ) => void;
}

// Creating the Zustand store using the create function
// The store is named useDupesCustomerStore, and it uses the types defined above for its state and actions
// The state begins with an empty dupesStoreCustomer array
// The updateDupesStoreCustomer action uses Zustand's set function to update the state
export const useDupesCustomerStore = create<
  DupesCustomersStoreState & DupesCustomerStoreActions
>((set) => ({
  // Initial state of the store. An empty array of dupesStoreCustomer.
  dupesStoreCustomer: null,

  // Action to update the dupesStoreCustomer array in the store.
  // This function takes a new dupesStoreCustomer array and uses the set function to update the state.
  updateDupesStoreCustomer: (dupesStoreCustomer) => {
    set(() => ({dupesStoreCustomer}));
  },
}));
