import {create} from "zustand";

import {type CustomerType} from "@/modules/customers";

// Defining the shape of the state object for the CustomerStore
// In this case, the state will consist of an array of Customer objects
interface StatementReconCustomersStoreState {
  statementReconStoreCustomer: CustomerType | null;
}

// Defining the actions that can be performed on the CustomerStore state
// In this case, there's only one action, updateStatementReconStoreCustomer, which takes an array of Customer objects
interface StatementReconCustomerStoreActions {
  updateStatementReconStoreCustomer: (
    statementReconStoreCustomer: StatementReconCustomersStoreState["statementReconStoreCustomer"]
  ) => void;
}

// Creating the Zustand store using the create function
// The store is named useStatementReconCustomerStore, and it uses the types defined above for its state and actions
// The state begins with an empty statementReconStoreCustomer array
// The updateStatementReconStoreCustomer action uses Zustand's set function to update the state
export const useStatementReconCustomerStore = create<
  StatementReconCustomersStoreState & StatementReconCustomerStoreActions
>((set) => ({
  // Initial state of the store. An empty array of statementReconStoreCustomer.
  statementReconStoreCustomer: null,

  // Action to update the statementReconStoreCustomer array in the store.
  // This function takes a new statementReconStoreCustomer array and uses the set function to update the state.
  updateStatementReconStoreCustomer: (statementReconStoreCustomer) => {
    set(() => ({statementReconStoreCustomer}));
  },
}));
