import {useEffect, useMemo, useState} from "react";

import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  type ColumnDef,
  type ColumnFiltersState,
  type SortingState,
  type VisibilityState,
} from "@tanstack/react-table";

import {Card} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableFooterRow,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {Pagination} from "@/components/craft/pagination";
import {TableSkeleton} from "@/components/craft/table";

import {
  usePaymentManagementIMRemitLiteTableColumns,
  usePaymentManagementIMRemitLiteTableColumnsStore,
  usePreferencesStore,
} from "@/lib/stores";
import {
  PaymentManagementDataTableToolbar,
  usePaymentManagementMetaStore,
  type PaymentManagementIMRemitLiteTableColumnsType,
} from "@/modules/imremit-lite";

/**
 * Custom hook for managing omitted columns
 * @returns {Object} - Object containing the current state of omitted columns
 */
const useOmittedColumns = () => {
  // Get the current state of the omitted columns from the store
  const {storePaymentManagementIMRemitLiteTableColumns} =
    usePaymentManagementIMRemitLiteTableColumns();
  // Memoize the omitted columns so that it is not re-calculated on every render
  return useMemo(() => {
    // If there is no state in the store, return an empty object
    return (
      storePaymentManagementIMRemitLiteTableColumns || {
        paymentDetailId: false,
        accountNumber: false,
        statusName: false,
        remittanceMethod: false,
        endDate: false,
        agentName: false,
        methodName: false,
        descriptionDetail: false,
        amountTaken: false,
        balanceAmount: false,
        userId: false,
        remittanceType: false,
      }
    );
  }, [storePaymentManagementIMRemitLiteTableColumns]);
};

// Defining the types of props this component accepts. The PaymentManagementDataTable component is generic over
// the type of data each row in the table represents (TData) and the type of the values of each cell in the table (TValue).
// The `columns` prop is an array of `ColumnDef`, which represents the configuration for each column in the table.
// The `data` prop is an array of the data that the table will display.
interface PaymentManagementDataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  isPending?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
}

/**
 * The main functional component that renders a data table for payment management.
 *
 * @function
 * @param {PaymentManagementDataTableProps<TData, TValue>} props - The props that the component receives.
 * @template TData - The type of data each row in the table represents.
 * @template TValue - The type of the values of each cell in the table.
 * @param {ColumnDef<TData, TValue>[]} props.columns - Configuration for each column in the table.
 * @param {TData[]} props.data - Data that the table will display.
 * @param {boolean} [props.isSuccess] - Whether the table has successfully loaded data.
 * @param {boolean} [props.isError] - Whether the table has an error.
 * @returns {JSX.Element} - The JSX to render the component.
 */
export function PaymentManagementDataTable<TData, TValue>({
  columns,
  data,
  isSuccess,
  isError,
}: PaymentManagementDataTableProps<TData, TValue>) {
  // Accessing the pagination meta and page change handler from the store.
  const {storePaymentManagementPaginationMeta, handleOnPageChange} =
    usePaymentManagementMetaStore();

  // Using state to maintain the sorting state for the table.
  const [sorting, setSorting] = useState<SortingState>([]);

  /** State for storing the table columns */
  const {setStorePaymentManagementIMRemitLiteTableColumns} =
    usePaymentManagementIMRemitLiteTableColumnsStore();
  // Custom hook to manage omitted columns
  const omittedColumns = useOmittedColumns();
  const [columnVisibility, setColumnVisibility] =
    useState<VisibilityState>(omittedColumns);
  /** Update the store when the column visibility changes */
  useEffect(() => {
    setStorePaymentManagementIMRemitLiteTableColumns(
      columnVisibility as PaymentManagementIMRemitLiteTableColumnsType
    );
  }, [columnVisibility, setStorePaymentManagementIMRemitLiteTableColumns]);
  // Using state to maintain the column filters state for the table.
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  // Using state to maintain the row selection state for the table.
  const [rowSelection, setRowSelection] = useState({});

  // The `usePreferencesStore` hook from the `@/lib/stores/preferences-store` module provides an API for interacting with the preferences store.
  // We are using it to get the number of rows per page to display in the table.
  const {numRowsPerPage} = usePreferencesStore();

  // useReactTable is a custom hook from the `@tanstack/react-table` package that provides an API for interacting with the table.
  // It takes an object that includes data, column configurations, a row model getter, a sorting change handler,
  // another row model getter for sorted data, and a state object.
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination: {
        pageSize: parseInt(numRowsPerPage),
        pageIndex: 0,
      },
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  // The return statement is rendering the actual Table component.
  // The Table, TableHeader, TableBody, TableRow, and TableCell components come from your custom @/components/ui/data-table module.
  // If there are no rows to display, a single row with a "No results" message is displayed.
  return (
    <div className="space-y-4">
      <Pagination
        paginationMeta={storePaymentManagementPaginationMeta}
        onPageChange={handleOnPageChange}
      />
      <PaymentManagementDataTableToolbar table={table} />
      <Card>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          {isError ? (
            <TableBody>
              <TableRow>
                <TableCell
                  className="h-24 text-center"
                  colSpan={columns.length}
                >
                  Error loading data.
                </TableCell>
              </TableRow>
            </TableBody>
          ) : !isSuccess ? (
            <TableSkeleton
              columnsLength={columns.length}
              omittedColumns={omittedColumns}
              numRowsPerPage={parseInt(numRowsPerPage)}
            />
          ) : (
            <TableBody>
              {table.getRowModel().rows.length ? (
                // We are checking if there are rows to display by evaluating `table.getRowModel().rows?.length`
                // If there are rows to display, we map over the rows to render each one as a `TableRow` component.
                // Each row gets a unique key, which is its id.
                // We are also adding a 'selected' state to the row if it is selected.
                // Then for each row, we render its cells. Each cell is rendered as a `TableCell` component with unique key.
                // The content of a cell is determined by the `cell.column.columnDef.cell` function, which is called with the cell's context.
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                // If there are no rows to display, we render a single row with a `StyledTableCell` that spans all columns.
                // The content of the cell is a "No results." message.
                <TableRow>
                  <TableCell
                    className="h-24 text-center"
                    colSpan={columns.length}
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableFooterRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableFooterRow>
            ))}
          </TableFooter>
        </Table>
      </Card>

      <Pagination
        paginationMeta={storePaymentManagementPaginationMeta}
        onPageChange={handleOnPageChange}
      />
    </div>
  );
}
