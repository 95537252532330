import {useEffect} from "react";

import {Link, useParams} from "@tanstack/react-router";
import {ArrowRightIcon} from "lucide-react";

import {buttonVariants} from "@/components/ui/button";
import {Heading2} from "@/components/ui/typography";

import {StatementReconRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {
  SRDetailsDataTable,
  srDetailsTableColumns,
  useSRStatementDetailsByStatementID,
} from "@/modules/statement-recon";

/**
 * Custom Hook: Manage the active route for the Statement Recon result page
 */
function useStatementReconStatementDetailsRoute() {
  const {setStatementReconRoute} = useRoutesStore();

  useEffect(() => {
    // Set the active route based on predefined route schema
    setStatementReconRoute(
      StatementReconRoutesSchema.Values["/app/statement-recon/file-history"]
    );
  }, [setStatementReconRoute]);
}

export function StatementReconStatementDetailsPage() {
  useStatementReconStatementDetailsRoute();

  const {statementId} = useParams({
    from: "/app/statement-recon/statement/$statementId/details",
  });
  const StatementDetailsSRQuery =
    useSRStatementDetailsByStatementID(statementId);
  const StatementDetailsSRData = StatementDetailsSRQuery.data?.content || [];

  return (
    <>
      <div className="mb-4 flex flex-col-reverse gap-4 md:flex-row md:justify-between">
        <div className="flex gap-4">
          <Heading2>Statement Recon Statement Details Page</Heading2>
        </div>

        <Link
          to="/app/statement-recon/statement/list"
          className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
        >
          Back to list
          <span className="sr-only">Back to list</span>
          <ArrowRightIcon className="size-4" />
        </Link>
      </div>
      <SRDetailsDataTable
        columns={srDetailsTableColumns}
        data={StatementDetailsSRData}
        isSuccess={StatementDetailsSRQuery.isSuccess}
        isPending={StatementDetailsSRQuery.isPending}
        isError={StatementDetailsSRQuery.isError}
      />
    </>
  );
}
