import {useState} from "react";

import {useMutation} from "@tanstack/react-query";
import {BanIcon, Trash2Icon, X} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
} from "@/components/ui/dropdown-menu";
import {toast} from "@/components/ui/use-toast";
import {LoadingSection} from "@/components/craft/loading-section";

import {deleteSupplierFn} from "@/modules/imremit";

export function SupplierManagementDeleteRow({
  externalId,
  supplierId,
}: {
  externalId?: string;
  supplierId: number;
}) {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  console.log("supplierId", supplierId);
  console.log("externalId", externalId);

  const deleteSupplierMutation = useMutation({
    mutationFn: (supplier: {supplierId: number}) =>
      deleteSupplierFn(supplier.supplierId),
    onSuccess: (response) => {
      console.log("deleteSupplierResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Supplier deleted successfully",
      });
      setDialogOpen(false);
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to delete supplier",
      });
    },
  });

  const handleDelete = (supplierId: number) => {
    deleteSupplierMutation.mutate({
      supplierId,
    });
  };

  const isMutating = deleteSupplierMutation.isPending;

  return (
    <>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        className="w-full gap-10 bg-destructive text-destructive-foreground"
        onSelect={(e) => {
          e.preventDefault();
        }}
      >
        <Dialog open={dialogOpen}>
          <DialogTrigger
            onClick={() => {
              setDialogOpen(true);
            }}
          >
            Delete Supplier
          </DialogTrigger>
          <DialogContent removeCloseButton>
            <DialogHeader>
              <DialogTitle>Are you sure?</DialogTitle>
              <DialogDescription>
                Do you want to delete this supplier? This cannot be undone.
              </DialogDescription>

              <Button
                variant={"ghost"}
                className="absolute right-4 top-4 h-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
                onClick={() => {
                  setDialogOpen(false);
                }}
              >
                <X className="text-default h-4 w-4" />
                <span className="sr-only">Close</span>
              </Button>
            </DialogHeader>
            <DialogFooter>
              <DialogClose asChild>
                <Button
                  aria-disabled={isMutating}
                  disabled={isMutating}
                  variant="secondary"
                  className="gap-2"
                  onClick={() => {
                    setDialogOpen(false);
                  }}
                >
                  <BanIcon className="size-4" />
                  <span className="sr-only">Cancel</span>
                  Cancel
                </Button>
              </DialogClose>
              <Button
                variant="destructive"
                className="flex items-center gap-2"
                aria-disabled={isMutating}
                disabled={isMutating}
                onClick={() => {
                  handleDelete(supplierId);
                }}
              >
                {isMutating ? (
                  <LoadingSection sectionSize="xs" />
                ) : (
                  <Trash2Icon className="size-4" />
                )}
                <span className="sr-only">Delete</span>
                Delete
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
      </DropdownMenuItem>
    </>
  );
}
