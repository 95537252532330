import {createRoute} from "@tanstack/react-router";

import {PageNotFound} from "@/components/layout/not-found";

import {RoutesDirectory} from "@/lib/routes/directory";
import {authorizedRootRoute} from "@/lib/routes/root";
import {
  StatementReconDashboardRedirect,
  StatementReconOutlet,
} from "@/modules/statement-recon";

export const statementReconRootRoute = createRoute({
  path: RoutesDirectory.STATEMENT_RECON,
  component: StatementReconOutlet,
  getParentRoute: () => authorizedRootRoute,
});

export const statementReconDashboardRedirectRoute = createRoute({
  path: RoutesDirectory.ROOT,
  component: StatementReconDashboardRedirect,
  getParentRoute: () => statementReconRootRoute,
});

export const statementReconNotFoundRoute = createRoute({
  // all routes from /app that are not defined will be redirected to this route
  path: RoutesDirectory.CATCH_ALL,
  component: PageNotFound,
  getParentRoute: () => statementReconRootRoute,
});
