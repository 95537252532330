import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  imREmitDashboardQueryKeys,
  type ImREmitDashboard,
} from "@/modules/imremit";

// Defining a type based on the function `fetchImREmitDashboard`
type QueryFnType = typeof fetchImREmitDashboard;

// Function to search imremits
export function fetchImREmitDashboard(externalId?: string) {
  // Fetch imremits from API using ky
  return kyApiFn<ImREmitDashboard>(
    `api/e-payable/management/${externalId}/dashboard`,
    "get"
  );
  // TODO: validate data
  // return imremitSchema.parse(data);
}

// Custom hook to fetch and cache imremits data using react-query
export function useImREmitDashboard(
  externalId?: string, // The "externalId" to be used for fetching data
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: imREmitDashboardQueryKeys.byExternalId(externalId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => fetchImREmitDashboard(externalId), // Setting the function to be used for fetching data
    enabled: !!externalId, // Only fetch data if externalId is not empty
    // TODO: add onSuccess and onError handlers
  });
}
