import {createRoute} from "@tanstack/react-router";

import {RoutesDirectory} from "@/lib/routes/directory";
import {
  AddSupplierPage,
  EditSupplierDetailsPage,
  imREmitLiteRootRoute,
  SupplierManagementPage,
  ViewSupplierDetailsPage,
} from "@/modules/imremit-lite";

export const imREmitLiteSupplierManagementRoute = createRoute({
  path: RoutesDirectory.SUPPLIER_MANAGEMENT,
  component: SupplierManagementPage,
  getParentRoute: () => imREmitLiteRootRoute,
});

export const imREmitLiteAddSupplierRoute = createRoute({
  path: `${RoutesDirectory.SUPPLIER_MANAGEMENT}${RoutesDirectory.CREATE}`,
  component: AddSupplierPage,
  getParentRoute: () => imREmitLiteRootRoute,
});

export const imREmitLiteViewSupplierRoute = createRoute({
  path: `${RoutesDirectory.SUPPLIER_MANAGEMENT}/$supplierId${RoutesDirectory.VIEW}`,
  component: ViewSupplierDetailsPage,
  getParentRoute: () => imREmitLiteRootRoute,
});

export const imREmitLiteEditSupplierRoute = createRoute({
  path: `${RoutesDirectory.SUPPLIER_MANAGEMENT}/$supplierId${RoutesDirectory.EDIT}`,
  component: EditSupplierDetailsPage,
  getParentRoute: () => imREmitLiteRootRoute,
});
