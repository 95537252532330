import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {customerQueryKeys, type CustomerProfileType} from "@/modules/customers";

type QueryFnType = typeof getCustomerProfileById;

export function getCustomerProfileById(externalId: string) {
  const data = kyApiFn<CustomerProfileType>(
    `api/e-payable/management/${externalId}/customer-profile`,
    "get"
  );
  return data;
}

export function useGetCustomerProfileById(
  externalId: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: customerQueryKeys.byExternalId(externalId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getCustomerProfileById(externalId), // Setting the function to be used for fetching data
    enabled: !!externalId, // Setting the query to be enabled only if the search id string is not empty
  });
}
