import {useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {ParentSize} from "@visx/responsive";
import {FilterIcon, TablePropertiesIcon} from "lucide-react";
import {useForm} from "react-hook-form";

import {Button} from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {Form} from "@/components/ui/form";
import {Heading2, Paragraph} from "@/components/ui/typography";

import type {MISPaymentsDifferencesFilterFormType} from "@/modules/admin";
import {
  MISPaymentsDifferencesDataTable,
  MISPaymentsDifferencesForm,
  MISPaymentsDifferencesGraph,
  misPaymentsDifferencesTableColumns,
  paymentFilterFormSchema,
  useGetMISDifferencesPayments,
} from "@/modules/admin";

/**
 * 🔍 Custom hook to generate query parameters based on form fields
 * Generates the query string for API calls depending on user inputs
 */
function useMISQueryParams(
  formFields: MISPaymentsDifferencesFilterFormType
): string {
  const {customers} = formFields;

  if (!customers) return "";

  const params = new URLSearchParams();

  // 🗓️ Append buyerExternalIds
  if (customers.length > 0) {
    const buyerExternalIdValues = customers.map((b) =>
      encodeURIComponent(b.value)
    );
    params.append("buyerExternalIds", buyerExternalIdValues.join(","));
  }

  return params.toString(); // 🚀 Return the generated query string
}

/**
 * 🌐 Main component for rendering the MIS Payments Received page
 * Contains the form and the data table
 */
export function MISPaymentsDifferencesPage() {
  // 📝 Initialize form using react-hook-form and zod validation
  const misPaymentsReceivedFormMethods =
    useForm<MISPaymentsDifferencesFilterFormType>({
      defaultValues: {
        customers: [],
      },
      mode: "onChange",
      resolver: zodResolver(paymentFilterFormSchema),
    });

  // Destructure watch first
  const {watch} = misPaymentsReceivedFormMethods;

  // 🔍 Watch the form values to dynamically generate query params
  const formValues = watch(); // Get the current form values
  const queryParams = useMISQueryParams(formValues); // 🔄 Generate query string based on form state

  // 📡 Fetch payments received data based on the query params
  const {data, isSuccess, isError} = useGetMISDifferencesPayments(queryParams);

  const paymentDifferences = useMemo(
    () => data?.content || [],
    [data?.content]
  ); // 🗂️ Memoize paymentDifferences to prevent unnecessary re-renders

  const isFormValid = formValues.customers && formValues.customers.length > 0;

  // Add this state near the top of the component
  const [isTableOpen, setIsTableOpen] = useState(false);

  // 🖼️ Render the main page content
  return (
    <Dialog>
      <Collapsible open={isTableOpen} onOpenChange={setIsTableOpen}>
        <section className="space-y-4">
          {/* 🔍 Header section with title and customer selection */}
          <div className="flex flex-col gap-y-4 md:flex-row md:items-center md:justify-between">
            <div className="flex flex-col gap-y-2">
              <Heading2>MIS Percentage Growth</Heading2> {/* 🏷️ Main heading */}
              <Paragraph>
                Please select a customer in filters to view the percentage
                growth.
              </Paragraph>
              <div className="flex items-center gap-x-2">
                <CollapsibleTrigger asChild>
                  {isTableOpen ? (
                    <Button className="gap-2" variant="secondary">
                      Close Table <TablePropertiesIcon className="size-4" />
                    </Button>
                  ) : (
                    <Button className="gap-2">
                      Open Table <TablePropertiesIcon className="size-4" />
                    </Button>
                  )}
                </CollapsibleTrigger>
                <DialogTrigger asChild>
                  <Button className="gap-2">
                    Adjust Filters <FilterIcon className="size-4" />
                  </Button>
                </DialogTrigger>
              </div>
            </div>
          </div>

          <DialogContent className="min-w-full">
            <DialogHeader>
              <DialogTitle>Adjust Filters</DialogTitle>
            </DialogHeader>

            <DialogDescription>
              Adjust the filters to narrow down the payments displayed.
            </DialogDescription>

            {/* 📑 Form to filter payments */}
            <Form {...misPaymentsReceivedFormMethods}>
              <MISPaymentsDifferencesForm />
              {/* 🛠️ Render the form for filtering payments */}
            </Form>

            <DialogClose asChild>
              <DialogFooter className="hover:cursor-pointer hover:text-destructive-foreground">
                Close the dialog to view results.
              </DialogFooter>
            </DialogClose>
          </DialogContent>

          {/* Wrap the table in Collapsible components */}
          <Collapsible open={isTableOpen} onOpenChange={setIsTableOpen}>
            <CollapsibleContent>
              <MISPaymentsDifferencesDataTable
                columns={misPaymentsDifferencesTableColumns}
                data={paymentDifferences}
                isSuccess={!isFormValid ? !isFormValid : isSuccess}
                isError={isError}
              />
            </CollapsibleContent>
          </Collapsible>

          {/* 📊 Card to display the percentage growth graph */}
          <Card>
            <CardHeader>
              <CardTitle>Percentage Growth Graph</CardTitle>{" "}
              {/* 🏷️ Card title */}
              <CardDescription>
                This graph displays the percentage growth of payments received
                and posted. Payments received are in purple and payments posted
                are in yellow.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <ParentSize>
                {({width}) => (
                  <MISPaymentsDifferencesGraph
                    data={paymentDifferences} // 📈 Data to display in the graph
                    width={width} // 🔄 Responsive width
                    height={720} // 📏 Fixed height
                  />
                )}
              </ParentSize>
            </CardContent>
          </Card>
        </section>
      </Collapsible>
    </Dialog>
  );
}
