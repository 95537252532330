// Constants for managing React Query keys related to invoices 📜
export const invoiceQueryKeys = {
  // Base key for fetching all invoices, serving as the foundation for more specific queries
  allInvoices: ["invoices"] as const, // 📂

  // Function to generate query keys for invoice searches, appending 'search-query' to the base key
  searchQuery: () => [...invoiceQueryKeys.allInvoices, "search-query"] as const, // 🔍

  // Function to create query keys for fetching invoices based on specific search criteria, enhancing searchQuery with a dynamic parameter
  bySearch: (search?: string) =>
    [...invoiceQueryKeys.searchQuery(), search] as const, // 🕵️

  // Function to generate query keys for accessing detailed information about specific invoices, extending 'allInvoices' with 'detailsQuery'
  detailsQuery: () =>
    [...invoiceQueryKeys.allInvoices, "detailsQuery"] as const, // 📋

  // Function to create query keys for retrieving detailed information about an invoice by its ID, further extending 'detailsQuery'
  byId: (invoiceId: number) =>
    [...invoiceQueryKeys.detailsQuery(), invoiceId] as const, // 🆔
};

// Constants for managing React Query keys related to saved invoice searches 💾
export const savedInvoiceSearchQueryKeys = {
  // Base key for accessing all saved invoice searches, serving as a foundational key for user-specific searches
  allInvoiceSavedSearches: ["allInvoiceSavedSearches"] as const, // 🗂️

  // Function to generate query keys for accessing saved searches by a specific user, appending the user's ID to 'allInvoiceSavedSearches'
  byUser: (userId?: string) =>
    [...savedInvoiceSearchQueryKeys.allInvoiceSavedSearches, userId] as const, // 👤
};

// Constants for managing React Query keys related to invoice status histories 📊
export const invoiceStatusHistoryQueryKeys = {
  // Base key for accessing all invoice status histories, forming the basis for ID-specific status queries
  allInvoiceStatusHistories: ["allInvoiceStatusHistories"] as const, // 📈

  // Function to generate query keys for accessing the status history of a specific invoice by its ID, enhancing 'allInvoiceStatusHistories' with the invoice ID
  byId: (invoiceId: number) =>
    [
      ...invoiceStatusHistoryQueryKeys.allInvoiceStatusHistories,
      invoiceId,
    ] as const, // 🔢
};

// Constants for query keys related to Invoice mappings 📂
export const InvoiceMappingKeys = {
  // Base query key for Invoice mappings
  all: ["InvoiceMapping"] as const,

  // Generates a query key for fetching all Invoice mappings
  allInvoiceMapping: () =>
    [...InvoiceMappingKeys.all, "allInvoiceMapping"] as const,
};
