import {z} from "zod";

export const DupeInvoiceSchema = z.object({
  invoice_id: z.string().or(z.number()),
  invoice_number: z.string(),
  invoice_payment_number: z.string().nullable(),
  invoice_date: z.union([z.string(), z.date()]).nullable(),
  invoice_payment_date: z.union([z.string(), z.date()]).nullable().optional(), // temporaily optional
  supplier_number: z.string().nullable(),
  supplier_name: z.string().nullable(),
  facility_name: z.string().nullable(),
  facility_number: z.string().nullable(),
  invoice_status: z.string().nullable(),
  invoice_paid_amount: z.string().or(z.number()).nullable(),
  invoice_amount: z.string().or(z.number()).nullable(),
  erp_unique_id: z.string().nullable(),
  invoice_po_number: z.string().nullable(),
  suffix: z.string().nullable(),
  compared: z.boolean().nullable(),
  credit_pair: z.boolean().nullable(),
});

export const DupeTrackerSchema = DupeInvoiceSchema.extend({
  dupe_invoices: z.array(DupeInvoiceSchema),
});

export const DupeTrackerResponseSchema = z.array(DupeTrackerSchema);

export type DupeTrackerType = z.infer<typeof DupeTrackerSchema>;
export type DupeTrackerResponseType = z.infer<typeof DupeTrackerResponseSchema>;
