import {z} from "zod";

export const fileProcessingIMRemitLiteTableColumnsSchema = z.object({
  packageSummaryId: z.boolean(),
  fileName: z.boolean(),
  formatName: z.boolean(),
  processStatus: z.boolean(),
  received: z.boolean(),
  totalCount: z.boolean(),
  processCount: z.boolean(),
  failCount: z.boolean(),
});
export type FileProcessingIMRemitLiteTableColumnsType = z.infer<
  typeof fileProcessingIMRemitLiteTableColumnsSchema
>;
