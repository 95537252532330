import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate} from "@tanstack/react-router";
import {SaveIcon, XIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import type * as z from "zod";

import {Button} from "@/components/ui/button";
import {Card, CardContent} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Label} from "@/components/ui/label";
import {Spinner} from "@/components/ui/spinner";
import {toast} from "@/components/ui/use-toast";

import {
  reconMappingFormSchema,
  saveReconFileFn,
  useMappingDocumentTypeStore,
} from "@/modules/imremit";

export function ReconFileAddMapping() {
  const navigate = useNavigate();

  const {storeDocumentType} = useMappingDocumentTypeStore();

  const form = useForm<z.infer<typeof reconMappingFormSchema>>({
    defaultValues: {
      documentTypeId: storeDocumentType?.documentTypeId.toString() || "",
    },
    resolver: zodResolver(reconMappingFormSchema),
  });

  // Initialize Tanstack Query Mutation for Refreshing ReconFile
  const saveReconFileMutation = useMutation({
    mutationFn: saveReconFileFn,
    onSuccess: (response) => {
      form.reset();
      console.log("saveReconFileMutationResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Recon file mapping added successfully",
      });
      void navigate({to: "/app/imremit/mapping", replace: true});
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add recon file mapping",
      });
    },
  });
  const isMutating = saveReconFileMutation.isPending;

  function onSubmit(data: z.infer<typeof reconMappingFormSchema>) {
    saveReconFileMutation.mutate(data);
  }

  return (
    <>
      <Card>
        <CardContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="mb-5 mt-5 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-6">
                <FormField
                  control={form.control}
                  name="masterMappingName"
                  render={() => (
                    <FormItem>
                      <Label showMandatoryAsterisk>Master Mapping Name :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          {...form.register("masterMappingName")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="supplierNumber"
                  render={({field}) => (
                    <FormItem>
                      <Label showMandatoryAsterisk>Supplier Number :</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="relationId"
                  render={({field}) => (
                    <FormItem>
                      <Label showMandatoryAsterisk>Relation ID :</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="transactionId"
                  render={({field}) => (
                    <FormItem>
                      <Label showMandatoryAsterisk>Transaction ID :</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="transactionDate"
                  render={({field}) => (
                    <FormItem>
                      <Label showMandatoryAsterisk>Transaction Date :</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="postingDate"
                  render={({field}) => (
                    <FormItem>
                      <Label showMandatoryAsterisk>Posting Date :</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="transactionAmount"
                  render={({field}) => (
                    <FormItem>
                      <Label showMandatoryAsterisk>Transaction Amount :</Label>
                      <FormControl>
                        <Input
                          type="number"
                          step="any"
                          placeholder=""
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="cardNumber"
                  render={({field}) => (
                    <FormItem>
                      <Label>Card Number :</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="transactionType"
                  render={({field}) => (
                    <FormItem>
                      <Label>Transaction Type :</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="merchantName"
                  render={({field}) => (
                    <FormItem>
                      <Label>Merchant Name :</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="originalAmount"
                  render={({field}) => (
                    <FormItem>
                      <Label>Original Amount :</Label>
                      <FormControl>
                        <Input
                          type="number"
                          step="any"
                          placeholder=""
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="customData1"
                  render={({field}) => (
                    <FormItem>
                      <Label>Recon Custom Field1 :</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="customData2"
                  render={({field}) => (
                    <FormItem>
                      <Label>Recon Custom Field2 :</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="customData3"
                  render={({field}) => (
                    <FormItem>
                      <Label>Recon Custom Field3 :</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="udf1"
                  render={({field}) => (
                    <FormItem>
                      <Label>UDF 1 :</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="udf2"
                  render={({field}) => (
                    <FormItem>
                      <Label>UDF 2 :</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="udf3"
                  render={({field}) => (
                    <FormItem>
                      <Label>UDF 3:</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="udf4"
                  render={({field}) => (
                    <FormItem>
                      <Label>UDF 4:</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="udf5"
                  render={({field}) => (
                    <FormItem>
                      <Label>UDF 5:</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="orderMerchantName"
                  render={({field}) => (
                    <FormItem>
                      <Label>Order Merchant Name:</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="documentTypeId"
                  render={(field) => (
                    <FormItem>
                      <FormControl>
                        <Input placeholder="" {...field} className="hidden" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="space-x-2 p-2 text-right">
                <Link className="mb-4" to="/app/imremit/mapping">
                  <Button
                    type="button"
                    variant="input"
                    className="col-span-1 gap-2"
                  >
                    <span className="sr-only">Cancel</span>
                    <XIcon className="size-4" />
                    Cancel
                  </Button>
                </Link>
                <Button
                  type="submit"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="affirmative"
                  className="col-span-1 gap-2"
                >
                  <span className="sr-only">Save</span>
                  <SaveIcon className="size-4" />
                  {isMutating ? <Spinner size="xs" /> : ""} Save
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </>
  );
}
