import {useMemo} from "react";

import {PopoverClose} from "@radix-ui/react-popover";
import {Link} from "@tanstack/react-router";
import {
  BellIcon,
  BellOffIcon,
  BellRingIcon,
  ExternalLinkIcon,
} from "lucide-react";

import {Badge} from "@/components/ui/badge";
import {Button} from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import {Label} from "@/components/ui/label";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {Heading3, Heading4, Paragraph} from "@/components/ui/typography";

import {useFailedAlerts, type FailedAlertsType} from "@/modules/imremit";

import {formatDate} from "@/utils/functions";

/**
 * The props for the FailedAlertsInfo component.
 * @typedef {Object} FailedAlertProps
 * @property {FailedAlertsType} [failedAlerts] - The data related to failed alerts.
 */
interface FailedAlertProps {
  failedAlerts: FailedAlertsType | undefined;
}

/**
 * Renders information related to failed alerts.
 * @param {FailedAlertProps} props - The properties of the component.
 * @returns {JSX.Element} The JSX component.
 */
function FailedAlertsInfo({failedAlerts}: FailedAlertProps): JSX.Element {
  // Parsing and memoizing failed alerts data
  const parsedFailedAlerts: FailedAlertsType = useMemo(() => {
    return failedAlerts || []; // Handle an array
  }, [failedAlerts]);

  return (
    <section className="flex flex-col items-center gap-y-1">
      <Popover>
        <TooltipProvider delayDuration={150} skipDelayDuration={300}>
          <Tooltip>
            <TooltipTrigger asChild>
              <PopoverTrigger asChild>
                <Button
                  className="relative p-1 hover:bg-muted active:bg-neutral-foreground/60"
                  variant="ghost"
                >
                  {parsedFailedAlerts.length ? (
                    <BellRingIcon className="size-8 text-theme hover:text-neutral-foreground active:text-neutral-foreground" />
                  ) : (
                    <BellIcon className="size-8 text-theme hover:text-neutral-foreground active:text-neutral-foreground" />
                  )}

                  {!!parsedFailedAlerts.length && (
                    <Badge
                      className="absolute right-[-12px] top-[-6px]"
                      variant="destructive"
                    >
                      <Paragraph className="p-0 font-bold">
                        {parsedFailedAlerts.length}
                      </Paragraph>
                    </Badge>
                  )}
                </Button>
              </PopoverTrigger>
            </TooltipTrigger>
            <TooltipContent>
              <TooltipArrow />
              <Heading4 className="mb-1">Failed Alerts:</Heading4>
              <Paragraph>
                Current alerts:{" "}
                <span className="font-bold">{parsedFailedAlerts.length}</span>
              </Paragraph>
              <Paragraph className="text-xs font-semibold">
                Click to show
              </Paragraph>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        <PopoverPortal>
          <PopoverContent
            className="w-90 rounded bg-root p-2"
            align="end"
            forceMount
          >
            <PopoverArrow />
            <div className="p-2 text-center">
              <Label className="text-foreground">Failed Alerts</Label>
            </div>
            <Command className="bg-root">
              <CommandInput placeholder="Enter Payment Number" />
              <CommandList>
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup>
                  {parsedFailedAlerts.map((payment, index) => (
                    <div key={index}>
                      <CommandItem
                        className="flex cursor-pointer flex-col text-foreground"
                        style={{background: "#fff"}}
                      >
                        <div className="flex items-center justify-between">
                          <div>
                            <Label className="text-foreground">
                              Payment Number:
                            </Label>
                            <Label className="text-foreground">
                              {payment.paymentDetailPaymentNumber}
                            </Label>
                            <div className="text-xs text-gray-500">
                              Last Updated Date/Time:{" "}
                              {formatDate(
                                new Date(payment.updatedTimestamp),
                                true
                              )}
                            </div>
                          </div>
                          <PopoverClose asChild>
                            <Link
                              className="ml-2 rounded-full p-2 outline-none hover:bg-success-foreground/80 hover:text-foreground active:bg-success-foreground/90"
                              to="/app/imremit/proxy-pay-dashboard/$paymentId/view/$reProcess"
                              params={{
                                paymentId: payment.paymentDetailId,
                                reProcess: "true",
                              }}
                            >
                              <ExternalLinkIcon className="size-4 rounded-full bg-transparent" />
                            </Link>
                          </PopoverClose>
                        </div>
                      </CommandItem>
                      {index < parsedFailedAlerts.length - 1 && (
                        <CommandSeparator />
                      )}
                    </div>
                  ))}
                </CommandGroup>
              </CommandList>
            </Command>
          </PopoverContent>
        </PopoverPortal>
      </Popover>
      <Paragraph className="text-xs font-semibold">Failed Alerts</Paragraph>
    </section>
  );
}

/**
 * The main component for displaying Failed Alerts section.
 * @returns {JSX.Element} The JSX component.
 */
export function FailedAlertsSection(): JSX.Element {
  const {data, error} = useFailedAlerts();
  const failedAlerts = data?.content;

  if (error) {
    return <ErrorDisplay />;
  }

  return <FailedAlertsInfo failedAlerts={failedAlerts} />;
}

/**
 * Displays an error message when failed alerts data cannot be fetched.
 * @returns {JSX.Element} The JSX component for displaying errors.
 */
function ErrorDisplay(): JSX.Element {
  return (
    <section className="flex flex-col items-center gap-y-1">
      <TooltipProvider delayDuration={150} skipDelayDuration={300}>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              className="cursor-not-allowed p-1 hover:bg-destructive-foreground/40"
              variant="ghost"
            >
              <BellOffIcon className="size-8 text-destructive-foreground hover:text-destructive-foreground/80" />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <TooltipArrow />
            <Heading3 className="mb-1">Failed Alerts:</Heading3>
            <Paragraph>
              Current alerts: <span className="font-bold">Unable to fetch</span>
            </Paragraph>
            <Paragraph className="text-xs font-semibold">
              Please try again later
            </Paragraph>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <Paragraph className="text-xs font-semibold">Failed Alerts</Paragraph>
    </section>
  );
}
