import {type KeycloakTokenParsed} from "keycloak-js";
import {FileWarningIcon} from "lucide-react";

import {Skeleton} from "@/components/ui/skeleton";
import {Paragraph} from "@/components/ui/typography";
import {PageLoadingSkeleton} from "@/components/craft/page-loading-skeleton";

import {calculateUserRoles, useKeyCloakInstanceStore} from "@/modules/auth";
import {
  SelectCustomerFormImRemit,
  useFilteredCustomers,
} from "@/modules/customers";

import {KeycloakRoles, ModuleNames} from "@/utils/constants";

/**
 * Props for CustomerNotFound component.
 */
interface CustomerNotFoundProps {
  customerName: string;
}

type KeycloakTokenWithCustomerType = KeycloakTokenParsed & {
  customer: string;
};

/**
 * Custom hook to manage and provide customer data.
 * @returns An object containing user and customer data along with state flags.
 */
export function useCustomerData() {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const parsedUserInfo =
    storeKeyCloakInstance?.tokenParsed as KeycloakTokenWithCustomerType;

  const {
    data: rawCustomersData,
    isPending: customersArePending,
    isFetched: customersAreFetched,
    isError: customersAreError,
  } = useFilteredCustomers(ModuleNames.imremit);

  // Determine if the current customer is not found in the fetched data
  const customerNotFound =
    parsedUserInfo.customer &&
    !rawCustomersData?.content.find(
      (customer: {buyerName: string}) =>
        customer.buyerName.toLowerCase().trim() ===
        parsedUserInfo.customer.toLowerCase().trim()
    );

  return {
    parsedUserInfo,
    customersArePending,
    customersAreFetched,
    customersAreError,
    customerNotFound,
  };
}

/**
 * CustomerSkeletonLoader is a React component that displays a set of skeleton loading placeholders.
 * This component is designed to be used as a placeholder while customer-related data is loading.
 * It renders two skeletons with predefined dimensions.
 *
 * @component
 * @returns {JSX.Element} A container with two skeleton loading placeholders.
 *
 * @example
 * // Usage:
 * <CustomerSkeletonLoader />
 */
export const CustomerSkeletonLoader = () => {
  return (
    <div className="flex justify-end">
      <div className="space-y-3">
        <Skeleton className="h-6 w-[250px]" />
        <Skeleton className="h-6 w-[300px]" />
      </div>
    </div>
  );
};

/**
 * Component to display an error message when fetching customers fails.
 */
function ErrorFetchingCustomers(): JSX.Element {
  return (
    <section>
      <Paragraph className="pt-12 text-center font-semibold">
        Error fetching customers
      </Paragraph>
    </section>
  );
}

/**
 * Component to display a warning when a specific customer is not found.
 * @param {CustomerNotFoundProps} props - The props for the component.
 */
export function CustomerNotFound({
  customerName,
}: CustomerNotFoundProps): JSX.Element {
  return (
    <section>
      <div className="flex justify-center gap-2 pt-16">
        <FileWarningIcon className="size-6 text-destructive-foreground" />
        <Paragraph>
          Customer{" "}
          <span className="font-semibold text-destructive-foreground">
            {customerName}
          </span>{" "}
          is not found/onboarded for the imREmit module. Please contact your
          administrator.
        </Paragraph>
        <FileWarningIcon className="size-6 text-destructive-foreground" />
      </div>
    </section>
  );
}

export function NoCustomerAssigned(): JSX.Element {
  return (
    <section>
      <div className="flex justify-center gap-2 pt-16">
        <FileWarningIcon className="size-6 text-destructive-foreground" />
        <Paragraph>
          Customer not assigned. Please contact your administrator.
        </Paragraph>
        <FileWarningIcon className="size-6 text-destructive-foreground" />
      </div>
    </section>
  );
}

/**
 * Component representing the section where a user can select a customer.
 * It handles various states like loading, error, customer not found, and successful fetch.
 */
export function SelectCustomerSectionImremit(): JSX.Element | null {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";
  const {
    parsedUserInfo,
    customersArePending,
    customersAreFetched,
    customersAreError,
    customerNotFound,
  } = useCustomerData();

  // Display a SkeletonLoader when data is still loading
  // if (customersArePending && !customersAreFetched) {
  //   return <CustomerSkeletonLoader />;
  // }

  // Display an error message if there is an error fetching customers
  if (customersAreError) {
    return <ErrorFetchingCustomers />;
  }

  // Display a message if the specific customer is not found
  if (customerNotFound && parsedUserInfo.customer) {
    return <CustomerNotFound customerName={parsedUserInfo.customer} />;
  }

  // Display a message if the user is a customer user or customer admin and no customer is assigned
  if (
    !customerNotFound &&
    (userRoleDisplay === KeycloakRoles.CustomerUser ||
      userRoleDisplay === KeycloakRoles.CustomerAdmin ||
      userRoleDisplay === KeycloakRoles.CustomerReviewer ||
      userRoleDisplay === KeycloakRoles.CustomerSuperAdmin)
  ) {
    return <NoCustomerAssigned />;
  }

  // Return nothing if a customer is already selected
  if (parsedUserInfo.customer) {
    return null;
  }

  // Default case: display the form to select a customer
  return (
    <section className="flex flex-col">
      <div className="flex flex-1 justify-end">
        {customersArePending && !customersAreFetched ? (
          <CustomerSkeletonLoader />
        ) : (
          <SelectCustomerFormImRemit requireLabel />
        )}
      </div>
      {customersArePending && !customersAreFetched ? (
        <div className="pt-12">
          <PageLoadingSkeleton variant="default" />
        </div>
      ) : (
        <div className="self-center justify-self-center pt-12">
          <Paragraph className="text-center font-semibold">
            Please select customer
          </Paragraph>
        </div>
      )}
    </section>
  );
}
