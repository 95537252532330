import {useMemo} from "react";

import {type ColumnDef} from "@tanstack/react-table";
import {format} from "date-fns";

import {Badge} from "@/components/ui/badge";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {GetKeycloakUserName} from "@/modules/admin";
import {
  ImremitPaymentHistoryDataTable,
  usePaymentManagementHistory,
  type imREmitPaymentId,
  type paymentManagementHistory,
} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";

// Definition of payment history columns for the datatable
export const paymentHistoryColumns: ColumnDef<paymentManagementHistory>[] = [
  {
    accessorKey: "changeDate",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Date" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("changeDate") ? (
          format(new Date(row.getValue("changeDate")), "yyyy-MM-dd")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "changeTime",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Time" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("changeTime") ? (
          row.getValue("changeTime")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "historyInterface",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Interface" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("historyInterface") ? (
          row.getValue("historyInterface")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "modifiedBy",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Modified By" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("modifiedBy") ? (
          <GetKeycloakUserName userId={row.getValue("modifiedBy")} />
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "paymentStatusValue",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment Status Value" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("paymentStatusValue") ? (
          row.getValue("paymentStatusValue")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "comments",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Comments" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("comments") ? (
          row.getValue("comments")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
];

export function ImREmitPaymentHistory({id}: imREmitPaymentId) {
  const paymentHistoryQuery = usePaymentManagementHistory(id.toString());

  const paymentHistoryData = useMemo(() => {
    return paymentHistoryQuery.data?.content ?? [];
  }, [paymentHistoryQuery.data?.content]);

  // Rendering the table
  return (
    <ImremitPaymentHistoryDataTable
      columns={paymentHistoryColumns}
      data={paymentHistoryData}
      isPending={paymentHistoryQuery.isPending}
      isSuccess={paymentHistoryQuery.isSuccess}
      isError={paymentHistoryQuery.isError}
    />
  );
}
