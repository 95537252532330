import {createRoute} from "@tanstack/react-router";

import {RoutesDirectory} from "@/lib/routes/directory";
import {
  statementReconRootRoute,
  StatementReconStatementSettingsPage,
} from "@/modules/statement-recon";

export const statementReconStatementSettingsRoute = createRoute({
  path: RoutesDirectory.SETTINGS,
  component: StatementReconStatementSettingsPage,
  getParentRoute: () => statementReconRootRoute,
});
