import {useState} from "react";

import {useMutation} from "@tanstack/react-query";
import {useNavigate} from "@tanstack/react-router";
import {type Row} from "@tanstack/react-table";
import {PackageXIcon} from "lucide-react";
import type {z} from "zod";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {Button} from "@/components/ui/button";
import {Skeleton} from "@/components/ui/skeleton";
import {toast} from "@/components/ui/use-toast";

import type {CustomerFormDTO, CustomerFormSchema} from "@/modules/admin";
import {
  CustomerModuleUpdateSubscription,
  editCustomerFn,
} from "@/modules/admin";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";
//import {CustomerModuleUpdateSubscription} from "@/modules/admin";
import {customerFetchSchema, useGetCustomerById} from "@/modules/customers";

import {ModuleNames} from "@/utils/constants";

/**
 * Props for CustomerManagementRowActions component.
 * @typedef {Object} CustomerManagementRowActionsProps
 * @template TData - The type of data for the row.
 * @property {Row<TData>} row - The data row.
 */
interface CustomerManagementRowActionsProps<TData> {
  row: Row<TData>;
}

/**
 * Represents the actions for a payment file row.
 * This component provides a dropdown menu for each row with various actions like edit, copy, favorite, and delete.
 *
 * @template TData - The data type for the table row.
 * @param {CustomerManagementRowActionsProps<TData>} props - The props for the component.
 * @returns {JSX.Element} The CustomerManagementRowActions component.
 */
export function CustomerRoleManagementRowActions<TData>({
  row,
}: CustomerManagementRowActionsProps<TData>) {
  // Get the navigate function from the router
  const navigate = useNavigate();

  // Parses the row data using the schema defined for payment mapping list.
  const customerRows = customerFetchSchema.parse(row.original);

  // Set the params for the routes
  const params = {
    externalId: customerRows.externalId
      ? customerRows.externalId.toString()
      : "",
    modules: customerRows.modules,
  };

  return (
    <>
      <CustomerModuleUpdateSubscription customerRows={customerRows} />
      {params.modules &&
        params.modules.map((module) => {
          switch (module.moduleName) {
            case ModuleNames.imremit:
              if (module.moduleOnboarded) {
                return (
                  <UnOnboardCustomer
                    externalId={params.externalId}
                    moduleId={module.moduleId}
                    moduleName={module.moduleName}
                  />
                );
              } else {
                return (
                  <AccessPolicyWrapper
                    unauthorizedFallback={null}
                    policyActions={[KeycloakRoleEnum.UPDATE_CUSTOMER_ONBOARD]}
                  >
                    <Button
                      key={module.moduleId}
                      className="m-2 flex w-64 justify-between gap-4 p-6"
                      variant="default"
                      onClick={() => {
                        void navigate({
                          to: "/app/admin/customer-management/imremit/$externalId/onboarding/payment-method",
                          params,
                          replace: true,
                        });
                      }}
                    >
                      imREmit Onboard Pending
                      <span className="sr-only">imREmit Customer</span>
                    </Button>
                  </AccessPolicyWrapper>
                );
              }

            case ModuleNames.duplicatePayments:
              if (module.moduleOnboarded) {
                return (
                  <UnOnboardCustomer
                    externalId={params.externalId}
                    moduleId={module.moduleId}
                    moduleName={module.moduleName}
                  />
                );
              } else {
                return (
                  <Button
                    key={module.moduleId}
                    className="m-2 flex w-64 justify-between gap-4 p-6"
                    variant="default"
                    onClick={() => {
                      void navigate({
                        to: "/app/admin/customer-management/duplicate-payments/$externalId/onboarding",

                        params,
                        replace: true,
                      });
                    }}
                  >
                    Duplicate Payments Onboard Pending
                    <span className="sr-only">Dupes Customer</span>
                  </Button>
                );
              }

            case ModuleNames.imremitLite:
              return null;
            // if (module.moduleOnboarded) {
            //   return (
            //     <UnOnboardCustomer
            //       externalId={params.externalId}
            //       moduleId={module.moduleId}
            //       moduleName={module.moduleName}
            //     />
            //   );
            // } else {
            //   return (
            //     <Button
            //       key={module.moduleId}
            //       className="m-2 flex w-64 justify-between gap-4 p-6"
            //       variant="default"
            //       onClick={(e) => {
            //         e.preventDefault();
            //       }}
            //     >
            //       imREmit Lite Onboard Pending
            //       <span className="sr-only">imREmit Lite Customer</span>
            //     </Button>
            //   );
            // }

            default:
              return null;
          }
        })}
    </>
  );
}

function UnOnboardCustomer({
  externalId,
  moduleId,
  moduleName,
}: {
  externalId: string;
  moduleId: number;
  moduleName: string;
}) {
  const [unOnBoardDialogOpen, setUnOnBoardDialogOpen] = useState(false);

  const moduleNameCleaned = moduleName.replace(/_/g, " ");

  const {
    data,
    isFetched: customerDetailsAreFetched,
    isError,
    error,
  } = useGetCustomerById(externalId);

  const unOnboardCustomerMutation = useMutation({
    mutationFn: (data: z.infer<typeof CustomerFormSchema>) => {
      return editCustomerFn(data);
    },

    onSuccess: (editCustomerResponse) => {
      console.log("unOnbaordCustomerResponse", editCustomerResponse);

      toast({
        variant: "success",
        title: "SUCCESS!",
        description: "Module Un-Onboarded successfully",
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to Un-Onboard module",
      });
    },
  });

  if (isError) {
    console.error("Error fetching customer details: ", error);
    throw new Error("Error fetching customer details: ", error);
  }

  return (
    <AlertDialog
      open={unOnBoardDialogOpen}
      onOpenChange={setUnOnBoardDialogOpen}
    >
      <AlertDialogTrigger asChild>
        <Button
          className="m-2 flex w-64 justify-between gap-4 p-6"
          variant="destructive"
          onClick={(e) => {
            e.preventDefault();
            setUnOnBoardDialogOpen(true);
          }}
        >
          Un-onboard {moduleNameCleaned} Module
          <span className="sr-only">Un-onboard {moduleNameCleaned} Module</span>
          <PackageXIcon className="size-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Are you sure you want to Un-Onboard {moduleNameCleaned} Module for{" "}
            {customerDetailsAreFetched && data ? (
              data.content.buyerName
            ) : (
              <Skeleton className="h-6 w-24" />
            )}
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            onClick={
              customerDetailsAreFetched && data
                ? () => {
                    unOnboardCustomerMutation.mutate({
                      ...data.content,
                      //  Pass our own moduleSubscription here with updated object
                      moduleSubscriptions: data.content.moduleSubscriptions.map(
                        (module) => {
                          if (module.moduleId === moduleId) {
                            return {
                              moduleId: module.moduleId,
                              moduleName: module.moduleName,
                              moduleOnboarded: false,
                              buyerId: module.buyerId,
                            };
                          }
                          return module;
                        }
                      ),
                    } as CustomerFormDTO);
                    setUnOnBoardDialogOpen(false);
                  }
                : () => {
                    console.log("Customer details are not fetched yet.");
                  }
            }
          >
            Un-Onboard {moduleName} Module
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
