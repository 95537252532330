import {z} from "zod";

export const OrganizationsSchema = z.object({
  participantRegisterId: z.number(),
  orgId: z.string(),
  userId: z.string(),
  groupId: z.string(),
  facilityName: z.string(),
  futureUse1: z.string(),
  futureUse2: z.string(),
});

export type OrganizationsSchema = z.infer<typeof OrganizationsSchema>;
