import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {delimiterTypesKeys, type delimiterTypesType} from "@/modules/imremit";

//Defining a type based on the function `getAllDelimiterTypes`
type QueryFnType = typeof getAllDelimiterTypes;

//Funciton to get the delimiters list
export function getAllDelimiterTypes() {
  // Fetch data from API using ky
  return kyApiFn<delimiterTypesType[]>(`api/e-payable/delimiter-types`, "get");
}

// Custom hook to fetch and cache delimiter type data using react-query
export function useDelimiterTypesDetails(config?: QueryConfig<QueryFnType>) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: delimiterTypesKeys.allDelimiterTypes(),
    queryFn: () => getAllDelimiterTypes(),
  });
}
