import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  proxyPayDetailsQueryKeys,
  type PaymentCommentsFormDTO,
  type ProxyPayViewPageSchema,
} from "@/modules/imremit";

// Defining a type based on the function `getProxyPayDetailsById`
type QueryFnType = typeof getProxyPayDetailsById;

// Function to search proxypay
export function getProxyPayDetailsById(proxyPayId: string) {
  return kyApiFn<ProxyPayViewPageSchema>(
    `api/e-payable/management/proxy-pay/${proxyPayId}`,
    "get"
  );
  // TODO: validate data
  // return ProxyPayViewPageSchema.parse(data);
}

// Custom hook to fetch and cache proxypay data using react-query
export function useGetProxyPayDetailsById(
  proxyPayId: string, // The search id string
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: proxyPayDetailsQueryKeys.byProxyPayId(proxyPayId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getProxyPayDetailsById(proxyPayId), // Setting the function to be used for fetching data
    enabled: !!proxyPayId, // Setting the query to be enabled only if the search id string is not empty
  });
}

// Update Payment Type
export const updatePaymentTypeFn = (
  PaymentCommentDTO: PaymentCommentsFormDTO,
  paymentId: string,
  paymentType: string
) =>
  kyApiFn(
    `api/e-payable/management/payment-details/${paymentId}/payment-mode?paymentMode=${paymentType}`,
    "put",
    {
      json: PaymentCommentDTO,
    }
  );
