// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type FileProcessingIMRemitPremiumTableColumnsType} from "@/modules/imremit";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface FileProcessingIMRemitPremiumTableColumnsStoreState {
  storeFileProcessingIMRemitPremiumTableColumns: FileProcessingIMRemitPremiumTableColumnsType | null;
  setStoreFileProcessingIMRemitPremiumTableColumns: (
    storeFileProcessingIMRemitPremiumTableColumns: FileProcessingIMRemitPremiumTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for FileProcessingIMRemitPremium table columns
export const useFileProcessingIMRemitPremiumTableColumnsStore = create(
  persist<FileProcessingIMRemitPremiumTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeFileProcessingIMRemitPremiumTableColumns: null,

      // Getter function to retrieve the current state
      getStoreFileProcessingIMRemitPremiumTableColumns: () =>
        get().storeFileProcessingIMRemitPremiumTableColumns,

      // Setter function to update the state
      setStoreFileProcessingIMRemitPremiumTableColumns: (
        storeFileProcessingIMRemitPremiumTableColumns
      ) => {
        set(() => ({storeFileProcessingIMRemitPremiumTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "FileProcessingIMRemitPremium-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useFileProcessingIMRemitPremiumTableColumns = () =>
  useFileProcessingIMRemitPremiumTableColumnsStore((state) => {
    return {
      storeFileProcessingIMRemitPremiumTableColumns:
        state.storeFileProcessingIMRemitPremiumTableColumns,
    };
  });
