export enum RoutesDirectory {
  // landing routes
  ROOT = "/",
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgot-password",
  RESET_PASSWORD = "/reset-password",
  REGISTER = "/register",
  SUPPLIER_SELF_REGISTER = "/supplier-self-register",
  SINGLE_SIGN_ON = "/single-sign-on",
  TERMS_OF_SERVICE = "/terms-of-service",
  PRIVACY_POLICY = "/privacy-policy",
  HELP = "/help",

  // error routes
  NOT_FOUND = "/not-found",
  ERROR = "/error",
  REDIRECT = "/redirect",
  CATCH_ALL = "*",

  // action routes
  VIEW = "/view",
  EDIT = "/edit",
  CREATE = "/create",
  ONBOARD = "/onboarding",
  PAYMENTS = "/payments",
  LIST = "/list",
  PDF_UPLOAD = "/pdf-upload",
  UPLOAD = "/upload",
  DETAILS = "/details",
  FILE_HISTORY = "/file-history",
  SEARCH = "/search",
  GROUP = "/group",
  MAPPING = "/mapping",
  DASHBOARD = "/dashboard",
  TRACKER = "/tracker",
  SUMMARY = "/summary",
  RESULT = "/result",
  CREDENTIALS = "/credentials",
  ROLE_MAPPING = "/role-mapping",
  GROUP_MAPPING = "/group-mapping",
  STATEMENT = "/statement",

  // authorized app routes
  APP = "/app",

  // top level route
  INVOICES = "/invoices",
  ELECTRONIC_INVOICING = "/electronic-invoicing",
  IMREMIT = "/imremit",
  IMREMIT_LITE = "/imremit-lite",
  DUPLICATE_PAYMENT = "/duplicate-payments",
  STATEMENT_RECON = "/statement-recon",
  ADMIN = "/admin",
  SETTINGS = "/settings",
  PROFILE = "/profile",

  // sub routes
  TRACKER_DASHBOARD = "/duplicate-tracker-dashboard",
  PAYMENT_MANAGEMENT = "/payment-management",
  REMITTANCE_MANAGEMENT = "/remittance-management",
  SUPPLIER_MANAGEMENT = "/supplier-management",
  CUSTOMER_MANAGEMENT = "/customer-management",
  CUSTOMER_ONBOARDING = "/customer-onboarding",
  FILE_PROCESSING = "/file-processing",
  STATEMENT_RECON_SEARCH_STATEMENT = "/search-statements",
  STATEMENT_RECON_WORKFLOW = "/workflow",
  STATEMENT_RECON_EMAIL_SUBMISSION = "/email-submission",
  STATEMENT_RECON_EMAIL_ALERTS = "/email-alerts",
  PROXY_PAY_DASHBOARD = "/proxy-pay-dashboard",
  SUPPLIER_SCRIPT_MANAGEMENT = "/supplier-script-management",
  REPORTS = "/reports",
  USER_MANAGEMENT = "/user-management",
  SUPPLIER_ONBOARDING = "/supplier-onboarding",
  MASTER_ROLE_SETTINGS = "/master-role-settings",
  USER_ROLE_RELATION = "/user-role-relation",
  CUSTOMER_MODULE_MANAGEMENT = "/customer-module-management",
  ALERT_MANAGEMENT = "/alert-management",
  IMREMIT_MAPPING = "/imremit-mapping",
  PID_MAPPING = "/pid-mapping",
  ADMIN_REPORT = "/admin-report",
  CRITERIA_SETTINGS = "/criteria-settings",
  SUPPLIERS = "/suppliers",
  PAYMENT_METHOD = "/payment-method",
  PARTICIPANT_REGISTER = "/participant-register",
  EMAIL_CONFIGURATION = "/email-configuration",
  SFTP_CONFIGURATION = "/sftp-configuration",
  RUNNER_CONFIGURATION = "/runner-configuration",
  FILE_DETAILS = "/file-details",
  RECONCILIATION = "/reconciliation",
  MIS = "/mis",
  PAYMENTS_RECEIVED = "/payments-received",
}
