// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

import {type alertsTableColumnsType} from "@/modules/admin";

interface AlertsTableColumnsStoreState {
  storeAlertsTableColumns: alertsTableColumnsType | null;
  setStoreAlertsTableColumns: (
    storeAlertsTableColumns: alertsTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for Alerts table columns
export const useAlertsTableColumnsStore = create(
  persist<AlertsTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeAlertsTableColumns: null,

      // Getter function to retrieve the current state
      getStoreAlertsTableColumns: () => get().storeAlertsTableColumns,

      // Setter function to update the state
      setStoreAlertsTableColumns: (storeAlertsTableColumns) => {
        set(() => ({storeAlertsTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "Alerts-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useAlertsTableColumns = () =>
  useAlertsTableColumnsStore((state) => {
    return {
      storeAlertsTableColumns: state.storeAlertsTableColumns,
    };
  });
