import {useEffect, useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {SquarePenIcon} from "lucide-react";
import {useForm} from "react-hook-form";

import {Button} from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {Spinner} from "@/components/ui/spinner";
import {toast} from "@/components/ui/use-toast";

import {
  createDupesExemptionCharacter,
  dupesExemptionCharacterSchema,
  updateDupesExemptionCharacter,
  type DupesExemptionCharacterType,
} from "@/modules/duplicate-payments";

interface DupesCreateCriteriaFormProps {
  exemptionTypeId: string;
  exemptionFormLabel: string;
  exemptionEntryType: string;
  exemptionEntryValues: string;
  externalId: string;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  creditPairLogicValue: string;
  numericCheckValue: string;
  addEditLabel: string; // Use the enum here
  creditPairLogicExemptionId?: number | null;
  numericCheckValueExemptionId?: number | null;
}

interface KeyValuePair {
  key: string;
  value: string;
}

export function DupesCreateCriteriaForm({
  exemptionTypeId,
  exemptionFormLabel,
  exemptionEntryType,
  exemptionEntryValues,
  externalId,
  setOpenDialog,
  creditPairLogicValue,
  numericCheckValue,
  addEditLabel,
  creditPairLogicExemptionId,
  numericCheckValueExemptionId,
}: DupesCreateCriteriaFormProps) {
  const [keyValueArray, setKeyValueArray] = useState<KeyValuePair[]>([]);
  useMemo(() => {
    const parsedArray = exemptionEntryValues.split("||").map((pair) => {
      const [key, value] = pair.split("|");
      return {key, value};
    });

    setKeyValueArray(parsedArray);
  }, [exemptionEntryValues]);

  const dupesExcludeCharacterFormMethods = useForm<DupesExemptionCharacterType>(
    {
      resolver: zodResolver(dupesExemptionCharacterSchema),
      defaultValues: {
        exemption_id:
          exemptionTypeId === "14"
            ? creditPairLogicExemptionId
            : exemptionTypeId === "24"
            ? numericCheckValueExemptionId
            : null,
        field_id: "1",
        buyer_external_id: externalId,
        user_id: "1", // 🛠️ TODO: dynamically fill this
        last_modified_user_id: "1", // 🛠️ TODO: dynamically fill this
        type_id: exemptionTypeId,
        exemption_type_id: exemptionTypeId,
        customer_id: "1", // 🛠️ TODO: dynamically fill this
      },
    }
  );

  const {setValue} = dupesExcludeCharacterFormMethods;

  useEffect(() => {
    if (exemptionTypeId === "14") {
      setValue("character", creditPairLogicValue);
    } else if (exemptionTypeId === "24") {
      setValue("character", numericCheckValue);
    }
  }, [creditPairLogicValue, numericCheckValue, setValue, exemptionTypeId]);

  //Add Criteria Settings
  const addDupesCreateCriteriaMutation = useMutation({
    mutationFn: (data: DupesExemptionCharacterType) => {
      // if (exemptionTypeId !== "14") {
      //   // generate new unique id
      //   // TODO: Fix this to be a real unique id
      // }
      data.exemption_id = Math.floor(Math.random() * 1000);

      return createDupesExemptionCharacter(externalId, data);
    },
    onSuccess: (response) => {
      console.log("addDupesCreateCriteriaMutationResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Criteria setting added successfully",
      });
      setOpenDialog(false);
    },
    onError: (error: unknown) => {
      console.error("error", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add criteria setting",
      });
    },
  });

  //Edit Criteria Settings
  const editDupesCreateCriteriaMutation = useMutation({
    mutationFn: (data: DupesExemptionCharacterType) =>
      updateDupesExemptionCharacter(externalId, data),
    onSuccess: (response) => {
      console.log("editDupesCreateCriteriaMutationResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Criteria setting updated successfully",
      });
      setOpenDialog(false);
    },
    onError: (error: unknown) => {
      console.error("error", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update criteria setting",
      });
    },
  });

  return (
    <Form {...dupesExcludeCharacterFormMethods}>
      <form
        className="flex flex-col gap-4"
        onSubmit={dupesExcludeCharacterFormMethods.handleSubmit((data) => {
          const characterValue =
            dupesExcludeCharacterFormMethods.getValues("character");

          if (!characterValue) {
            toast({
              variant: "destructive",
              title: "Unable to update Exclusion Character!",
              description: "Character value is required!",
            });
            return;
          }

          const mutation =
            addEditLabel === "Add Criteria"
              ? addDupesCreateCriteriaMutation
              : editDupesCreateCriteriaMutation;
          mutation.mutate(data);
        })}
      >
        {exemptionEntryType === "select" ? (
          <FormField
            control={dupesExcludeCharacterFormMethods.control}
            name="character"
            render={({field}) => (
              <FormItem>
                <FormLabel htmlFor="character" showMandatoryAsterisk>
                  {exemptionFormLabel}:
                </FormLabel>
                <Select
                  defaultValue={
                    exemptionTypeId == "14"
                      ? creditPairLogicValue
                      : numericCheckValue
                  }
                  onValueChange={field.onChange}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select character" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {keyValueArray.map((pair, index) => (
                      <SelectItem key={index} value={pair.key}>
                        {pair.value}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        ) : (
          <FormField
            name="character"
            render={() => (
              <FormItem>
                <FormLabel htmlFor="character" showMandatoryAsterisk>
                  {exemptionFormLabel}:
                </FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    placeholder={`Enter character...`}
                    disabled={addDupesCreateCriteriaMutation.isPending}
                    aria-disabled={addDupesCreateCriteriaMutation.isPending}
                    {...dupesExcludeCharacterFormMethods.register("character")}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        )}

        <Button
          type="submit"
          className="gap-2"
          disabled={dupesExcludeCharacterFormMethods.formState.isSubmitting}
        >
          Submit
          {(
            addEditLabel === "Add Criteria"
              ? addDupesCreateCriteriaMutation.isPending
              : editDupesCreateCriteriaMutation.isPending
          ) ? (
            <Spinner size="xs" />
          ) : (
            <SquarePenIcon className="size-4" />
          )}
        </Button>
      </form>
    </Form>
  );
}
