import {Spinner} from "@/components/ui/spinner";
import {Paragraph} from "@/components/ui/typography";

// This function returns a generic error UI that can be reused throughout our application.
export function GenericLoadingPage() {
  return (
    <article
      className="flex flex-1 flex-col items-center justify-center bg-background"
      role="alert" // ARIA role for representing an error condition.
      aria-label="Error section" // ARIA label to describe the section
    >
      <section className="flex max-w-2xl flex-col items-center justify-center gap-6 bg-background p-8">
        <img
          className="max-w-80"
          src="/images/new_im_logo.png"
          alt="Iteration Matrix"
        />
        <Spinner size="lg" />
        <Paragraph>Loading...</Paragraph>
      </section>
    </article>
  );
}
