import {z} from "zod";

export const dupesCriteriaSettingsCharactersSchema = z.object({
  allCharacters: z.array(
    z.object({
      character: z.string(),
    })
  ),
});

export type dupesCriteriaSettingsCharactersType = z.infer<
  typeof dupesCriteriaSettingsCharactersSchema
>;

export const dupesCriteriaSettingsSchema = z.object({
  character: z.string().min(1, {message: "Character is required."}),
});

export type dupesCriteriaSettingsType = z.infer<
  typeof dupesCriteriaSettingsSchema
>;
