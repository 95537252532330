import {useMemo} from "react";

import type {z} from "zod";

import type {ProxypayFormSchema} from "@/modules/imremit";
import {useGetOrganizationsList, useGetSuppliersList} from "@/modules/imremit";

import {parseAndFormatDate} from "@/utils/functions";

/**
 * Transforms the form fields into a structure suitable for API calls.
 *
 * @param {object} formFields - The form fields to transform.
 * @returns {object} Transformed form fields.
 */
export function useProxypayCriteriaFields(
  formFields: z.infer<typeof ProxypayFormSchema>
) {
  // Destructure form fields for easier access and manipulation.
  const {
    customer,
    supplierId,
    orgId,
    startDate,
    endDate,
    paymentNumber,
    paymentMode,
    paymentStatus,
  } = formFields;

  return useMemo(() => {
    return {
      customer: customer ? customer : null,
      supplierId: supplierId ? supplierId : null,
      orgId: orgId ? orgId : null,
      fromDate: startDate ? parseAndFormatDate(startDate.toString()) : null,
      toDate: endDate ? parseAndFormatDate(endDate.toString()) : null,
      paymentNumber: paymentNumber ? paymentNumber : null,
      paymentMode: paymentMode ? paymentMode : null,
      statusId: paymentStatus.flatMap((statusObject) =>
        statusObject.value.split(",").map((id) => parseInt(id))
      ),
    };
  }, [
    customer,
    supplierId,
    orgId,
    startDate,
    endDate,
    paymentNumber,
    paymentMode,
    paymentStatus,
  ]);
}

/**
 * Custom hook to fetch and parse population data, including suppliers.
 *
 * @param string  externalId
 * @returns {{
 *   suppliersData: {label: string, value: string}[],
 * }} - Object containing parsed suppliersData
 */
export function useFetchAndParseProxyPayPopulationData(externalId?: string) {
  // /**
  //  * @type {{imREmitStoreCustomer: {externalId: string} | undefined}}
  //  */
  // const {imREmitStoreCustomer} = useImRemitCustomerStore();

  // /**
  //  * @type {string | undefined}
  //  */
  // const externalId = imREmitStoreCustomer?.externalId;

  /**
   * @type {{data: {content: any[]} | undefined}}
   */
  const getSupplierByCustomerIdQuery = useGetSuppliersList(externalId);

  /**
   * @type {any[] | undefined}
   */
  const rawSuppliersData = getSupplierByCustomerIdQuery.data?.content;

  /**
   * @type {{label: string, value: string}[]}
   */
  const suppliersData = useMemo(() => {
    if (!rawSuppliersData) return [];

    return rawSuppliersData
      .map((supplier) => {
        if (
          supplier.paymentMode === "Pay By Email" ||
          supplier.paymentMode === null
        )
          return null;

        return {
          label: `${supplier.supplierNumber ? supplier.supplierNumber : ""} - ${
            supplier.supplierName ? supplier.supplierName : ""
          }`,
          value: supplier.supplierId.toString(),
        };
      })
      .filter(Boolean);
  }, [rawSuppliersData]);

  /**
   * @type {{data: {content: any[]} | undefined}}
   */
  const getOrganizationsByCustomerIdQuery = useGetOrganizationsList(externalId);

  /**
   * @type {any[] | undefined}
   */
  const rawOrganizationssData = getOrganizationsByCustomerIdQuery.data?.content;

  /**
   * @type {{label: string, value: string}[]}
   */
  const organizationsData = useMemo(() => {
    if (!rawOrganizationssData) return [];

    return rawOrganizationssData
      .map((organization) => {
        return {
          label: organization.orgId,
          value: organization.orgId.toString(),
        };
      })
      .filter(Boolean);
  }, [rawOrganizationssData]);

  // remove duplicate organizations
  const organizationsList = organizationsData.filter(
    (organization, index, self) =>
      index ===
      self.findIndex(
        (t) => t.label === organization.label && t.value === organization.value
      )
  );

  return {
    suppliersData,
    organizationsList,
  };
}
