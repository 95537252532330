import {z} from "zod";

export const emailConfigurationSchema = z.object({
  emailConfigId: z.string(),
  notificationTypeId: z.string(),
  alertSend: z.boolean(),
  participantId: z.string(),
  recipientEmails: z.array(z.string()),
});

export type EmailConfiguration = z.infer<typeof emailConfigurationSchema>;

export const notificationTypeSchema = z.object({
  notificationTypeId: z.number(),
  subject: z.string(),
});

export type NotificationType = z.infer<typeof notificationTypeSchema>;

export const emailConfigurationColumnsSchema = z.object({
  notificationTypeId: z.boolean(),
  alertSend: z.boolean(),
  participantId: z.boolean(),
  recipientEmails: z.boolean(),
});

export type EmailConfigurationColumnsType = z.infer<
  typeof emailConfigurationColumnsSchema
>;
