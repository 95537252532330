import {type KeycloakTokenParsed} from "keycloak-js";
import {FileWarningIcon} from "lucide-react";

import {Paragraph} from "@/components/ui/typography";
import {PageLoadingSkeleton} from "@/components/craft/page-loading-skeleton";

import {calculateUserRoles, useKeyCloakInstanceStore} from "@/modules/auth";
import {
  CustomerSkeletonLoader,
  SelectCustomerFormDupes,
  useFilteredCustomers,
} from "@/modules/customers";

import {KeycloakRoles, ModuleNames} from "@/utils/constants";

/**
 * Props for CustomerNotFound component.
 */
interface CustomerNotFoundProps {
  customerName: string;
}

type KeycloakTokenWithCustomerType = KeycloakTokenParsed & {
  customer: string;
};

/**
 * Custom hook to manage and provide customer data.
 * @returns An object containing user and customer data along with state flags.
 */
function useCustomerDataDupes() {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const parsedUserInfo =
    storeKeyCloakInstance?.tokenParsed as KeycloakTokenWithCustomerType;

  const {
    data: imREmitCustomersRawData,
    isPending: imREmitCustomersArePending,
    isFetched: imREmitCustomersAreFetched,
    isError: imREmitCustomersError,
  } = useFilteredCustomers(ModuleNames.duplicatePayments);

  // Determine if the current customer is not found in the fetched data
  const customerNotFound =
    parsedUserInfo.customer &&
    !imREmitCustomersRawData?.content.find(
      (customer: {buyerName: string}) =>
        customer.buyerName.toLowerCase().trim() ===
        parsedUserInfo.customer.toLowerCase().trim()
    );

  return {
    parsedUserInfo,
    imREmitCustomersArePending,
    imREmitCustomersAreFetched,
    imREmitCustomersError,
    customerNotFound,
  };
}

/**
 * Component to display an error message when fetching customers fails.
 */
function ErrorFetchingCustomersDupes(): JSX.Element {
  return (
    <section>
      <Paragraph className="pt-12 text-center font-semibold">
        Error fetching customers
      </Paragraph>
    </section>
  );
}

/**
 * Component to display a warning when a specific customer is not found.
 * @param {CustomerNotFoundProps} props - The props for the component.
 */
function CustomerNotFound({customerName}: CustomerNotFoundProps): JSX.Element {
  return (
    <section>
      <div className="flex justify-center gap-2 pt-16">
        <FileWarningIcon className="size-6 text-destructive-foreground" />
        <Paragraph>
          Customer{" "}
          <span className="font-semibold text-destructive-foreground">
            {customerName}
          </span>{" "}
          is not found/onboarded for the duplicate payments module. Please
          contact your administrator.
        </Paragraph>
        <FileWarningIcon className="size-6 text-destructive-foreground" />
      </div>
    </section>
  );
}

function NoCustomerAssigned(): JSX.Element {
  return (
    <section>
      <div className="flex justify-center gap-2 pt-16">
        <FileWarningIcon className="size-6 text-destructive-foreground" />
        <Paragraph>
          Customer not assigned. Please contact your administrator.
        </Paragraph>
        <FileWarningIcon className="size-6 text-destructive-foreground" />
      </div>
    </section>
  );
}
/**
 * Component representing the section where a user can select a customer (lite).
 * It handles various states like loading, error, customer not found, and successful fetch.
 */
export function SelectCustomerSectionDupes(): JSX.Element | null {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";
  const {
    parsedUserInfo,
    imREmitCustomersArePending,
    imREmitCustomersAreFetched,
    imREmitCustomersError,
    customerNotFound,
  } = useCustomerDataDupes();

  // Display an error message if there is an error fetching customers
  if (imREmitCustomersError) {
    return <ErrorFetchingCustomersDupes />;
  }

  // Display a message if the specific customer is not found
  if (customerNotFound && parsedUserInfo.customer) {
    return <CustomerNotFound customerName={parsedUserInfo.customer} />;
  }

  // Return nothing if a customer is already selected
  if (parsedUserInfo.customer) {
    return null;
  }

  // Display a message if the user is a customer user/ customer admin/ customer reviewer or customer super admin and no customer is assigned
  if (
    !customerNotFound &&
    (userRoleDisplay === KeycloakRoles.CustomerUser ||
      userRoleDisplay === KeycloakRoles.CustomerAdmin ||
      userRoleDisplay === KeycloakRoles.CustomerReviewer ||
      userRoleDisplay === KeycloakRoles.CustomerSuperAdmin)
  ) {
    return <NoCustomerAssigned />;
  }

  // Default case: display the form to select a customer
  return (
    <section className="flex flex-col">
      <div className="flex flex-1 justify-end">
        {imREmitCustomersArePending && !imREmitCustomersAreFetched ? (
          <CustomerSkeletonLoader />
        ) : (
          <SelectCustomerFormDupes requireLabel />
        )}
      </div>
      {imREmitCustomersArePending && !imREmitCustomersAreFetched ? (
        <div className="pt-12">
          <PageLoadingSkeleton variant="default" />
        </div>
      ) : (
        <div className="self-center justify-self-center pt-12">
          <Paragraph className="text-center font-semibold">
            Please select customer
          </Paragraph>
        </div>
      )}
    </section>
  );
}
