import {
  BadgeDollarSignIcon,
  CoinsIcon,
  // FileCog,
  FileJson2Icon,
  LayoutTemplateIcon,
  TruckIcon,
  // UserSquareIcon,
} from "lucide-react";

import {SubrouteNavigation} from "@/components/craft/subroute-navigation";

import {ImREmitLiteRoutesSchema} from "@/lib/routes/types";
import {KeycloakRoleEnum} from "@/modules/auth";

// Define an array with the data for each route button. This includes the 'to' route,
// the 'icon' to be displayed, and the 'label' for the button.
// This array holds the configuration for each SubrouteNavigationButton
// Each object in the array represents a button in the navigation menu
const routes = [
  {
    to: ImREmitLiteRoutesSchema.Values["/app/imremit-lite/dashboard"],
    icon: <LayoutTemplateIcon className="h-10 w-10 pl-2" />,
    label: "imREmit Lite Dashboard",
    actionRole: KeycloakRoleEnum.READ_IMREMIT_LITE_DASHBOARD,
  },
  {
    to: ImREmitLiteRoutesSchema.Values["/app/imremit-lite/payment-management"],
    icon: <CoinsIcon className="h-10 w-10 pl-2" />,
    label: "Payment Management",
    actionRole: KeycloakRoleEnum.READ_PAYMENT_MANAGEMENT_LITE,
  },
  {
    to: ImREmitLiteRoutesSchema.Values["/app/imremit-lite/proxy-pay-dashboard"],
    icon: <BadgeDollarSignIcon className="h-10 w-10 pl-2" />,
    label: "Proxy Pay Dashboard",
    fontSize: "sm" as const,
    actionRole: KeycloakRoleEnum.READ_PROXYPAY_DASHBOARD_LITE,
  },
  // {
  //   to: ImREmitLiteRoutesSchema.Values["/app/imremit-lite/customer-management"],
  //   icon: <UserSquareIcon className="h-10 w-10 pl-2" />,
  //   label: "Customer Management",
  //   actionRole: KeycloakRoleEnum.READ_CUSTOMER_MANAGEMENT_LITE,
  // },
  {
    to: ImREmitLiteRoutesSchema.Values["/app/imremit-lite/supplier-management"],
    icon: <TruckIcon className="h-10 w-10 pl-2" />,
    label: "Supplier Management",
    actionRole: KeycloakRoleEnum.READ_SUPPLIER_MANAGEMENT_LITE,
  },
  {
    to: ImREmitLiteRoutesSchema.Values[
      "/app/imremit-lite/supplier-script-management"
    ],
    icon: <FileJson2Icon className="h-10 w-10 pl-2" />,
    label: "Supplier Script Management",
    actionRole: KeycloakRoleEnum.READ_SUPPLIER_SCRIPT_MANAGEMENT_LITE,
  },
  // {
  //   to: ImREmitLiteRoutesSchema.Values["/app/imremit-lite/file-processing"],
  //   icon: <FileCog className="h-10 w-10 pl-2" />,
  //   label: "File Processing",
  //   actionRole: KeycloakRoleEnum.READ_FILE_PROCESSING_LITE,
  // },
];

interface ImREmitLiteSubrouteNavigationProps {
  layout?: "horizontal" | "vertical";
}

// This component renders the SubrouteNavigation component with the routes array
// as a prop. It also passes the 'navigationAccessibleLabel' and
// 'navigationAriaLabelledby' props to the SubrouteNavigation component.
export function ImREmitLiteSubrouteNavigation(
  {layout}: ImREmitLiteSubrouteNavigationProps = {layout: "horizontal"}
) {
  return (
    <SubrouteNavigation
      routes={routes}
      layout={layout}
      navigationAccessibleLabel="imREmit Lite Subroute Navigation"
      navigationAriaLabelledby="iMPaySubrouteNavHeader"
    />
  );
}
