import {useParams} from "@tanstack/react-router";

import {ParticipantRegisterForm} from "@/modules/admin";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";

import {ModuleNames} from "@/utils/constants";

export function CustomerOnboardingImREmitParticipantRegisterPage() {
  const {externalId} = useParams({
    from: "/app/admin/customer-management/imremit/$externalId/onboarding/participant-register",
  });

  // Render OnboardCustomerForm component
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.UPDATE_CUSTOMER_MANAGEMENT]}
    >
      <ParticipantRegisterForm
        externalId={externalId}
        module={ModuleNames.imremit}
      />
    </AccessPolicyWrapper>
  );
}
