import {z} from "zod";

export const keycloakRoleSchema = z.object({
  clientRole: z.boolean(),
  composite: z.boolean(),
  containerId: z.string(),
  description: z.string(),
  id: z.string(),
  name: z.string(),
});

export type KeycloakRoleType = z.infer<typeof keycloakRoleSchema>;

export const roleToGroupDTO = z.array(keycloakRoleSchema);

export type RoleToGroupDTO = z.infer<typeof roleToGroupDTO>;

export const keycloakGroupRolesSchema = z.object({
  realmMappings: z.array(keycloakRoleSchema),
});

export type KeycloakGroupRolesType = z.infer<typeof keycloakGroupRolesSchema>;
