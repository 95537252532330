import {createRoute} from "@tanstack/react-router";

import {RoutesDirectory} from "@/lib/routes/directory";
import {
  ImREmitCreateMapping,
  ImREmitMapping,
  imREmitRootRoute,
} from "@/modules/imremit";

export const imREmitMappingRoute = createRoute({
  path: RoutesDirectory.MAPPING,
  component: ImREmitMapping,
  getParentRoute: () => imREmitRootRoute,
});

export const addimREmitMappingRoute = createRoute({
  path: `${RoutesDirectory.MAPPING}${RoutesDirectory.CREATE}`,
  component: ImREmitCreateMapping,
  getParentRoute: () => imREmitRootRoute,
});
