import {z} from "zod";

export const fileProcessingSchema = z.object({
  received: z.string(),
  packageSummaryId: z.string(),
  fileName: z.string(),
  formatName: z.string(),
  processStatus: z.string(),
  totalCount: z.number(),
  processCount: z.number(),
  failCount: z.number(),
});
export type FileProcessingType = z.infer<typeof fileProcessingSchema>;

export const fileProcessingIMRemitPremiumTableColumnsSchema = z.object({
  packageSummaryId: z.boolean(),
  fileName: z.boolean(),
  formatName: z.boolean(),
  processStatus: z.boolean(),
  received: z.boolean(),
  totalCount: z.boolean(),
  processCount: z.boolean(),
  failCount: z.boolean(),
});
export type FileProcessingIMRemitPremiumTableColumnsType = z.infer<
  typeof fileProcessingIMRemitPremiumTableColumnsSchema
>;
