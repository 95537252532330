// import {useParams} from "@tanstack/react-router";

// import {SFTPConfigurationForm} from "@/modules/admin/components/forms/customer-management-forms/sftp-configuration-form";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";

export function CustomerOnboardingImREmitSFTPConfigurationPage() {
  // const {externalId} = useParams({
  //   from: "/app/admin/customer-management/imremit/$externalId/onboarding/sftp-configuration",
  // });
  // Render OnboardCustomerForm component
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.UPDATE_CUSTOMER_MANAGEMENT]}
    >
      {/* <SFTPConfigurationForm externalId={externalId} /> */}
    </AccessPolicyWrapper>
  );
}
