import {z} from "zod";

export const imRemitCustomer = z.object({
  participantId: z.number(),
  externalId: z.string(),
  businessName: z.string(),
  accountNumberMapping: z.boolean(),
});

export type ImRemitCustomerType = z.infer<typeof imRemitCustomer>;
