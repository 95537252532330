import {useEffect} from "react";

import {Heading2, Paragraph} from "@/components/ui/typography";

import {AdminRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";

// import {UserModuleRelationForm} from "@/modules/admin";

/**
 * UserRoleConfiguration Component
 *
 * This component provides the interface for administrators to configure
 * different user roles within the application.
 * It presents a series of forms, each dedicated to specific user roles.
 */
export function UserModuleConfiguration() {
  // Use the custom hook for manipulating route data
  const {setAdminRoute} = useRoutesStore();

  // useEffect to set the admin route upon component mount
  useEffect(() => {
    // Define the active admin route for User Role Configuration using the route schema
    setAdminRoute(AdminRoutesSchema.Values["/app/admin/user-role-relation"]);
  }, [setAdminRoute]);

  // Render the User Role Configuration Interface
  return (
    <section role="presentation">
      <div className="mb-2 flex flex-col">
        <Heading2>User Role Relation</Heading2>
        <Paragraph>
          Modify or add user role relationships within your organization here.
        </Paragraph>
      </div>
      {/* <UserModuleRelationForm /> */}
    </section>
  );
}
