// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type FileProcessingIMRemitLiteTableColumnsType} from "@/modules/imremit-lite";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface FileProcessingIMRemitLiteTableColumnsStoreState {
  storeFileProcessingIMRemitLiteTableColumns: FileProcessingIMRemitLiteTableColumnsType | null;
  setStoreFileProcessingIMRemitLiteTableColumns: (
    storeFileProcessingIMRemitLiteTableColumns: FileProcessingIMRemitLiteTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for FileProcessingIMRemitLite table columns
export const useFileProcessingIMRemitLiteTableColumnsStore = create(
  persist<FileProcessingIMRemitLiteTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeFileProcessingIMRemitLiteTableColumns: null,

      // Getter function to retrieve the current state
      getStoreFileProcessingIMRemitLiteTableColumns: () =>
        get().storeFileProcessingIMRemitLiteTableColumns,

      // Setter function to update the state
      setStoreFileProcessingIMRemitLiteTableColumns: (
        storeFileProcessingIMRemitLiteTableColumns
      ) => {
        set(() => ({storeFileProcessingIMRemitLiteTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "FileProcessingIMRemitLite-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useFileProcessingIMRemitLiteTableColumns = () =>
  useFileProcessingIMRemitLiteTableColumnsStore((state) => {
    return {
      storeFileProcessingIMRemitLiteTableColumns:
        state.storeFileProcessingIMRemitLiteTableColumns,
    };
  });
