import {useEffect, useMemo} from "react";

import {Tabs, TabsContent, TabsList, TabsTrigger} from "@/components/ui/tabs";
import {Heading2, Paragraph} from "@/components/ui/typography";

import {StatementReconRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore, useStatementReconCustomerStore} from "@/lib/stores";
import {calculateUserRoles, useKeyCloakInstanceStore} from "@/modules/auth";
import {
  SelectCustomerFormStatementRecon,
  SelectCustomerSectionStatementRecon,
  SelectSupplierSection,
} from "@/modules/customers";
import {
  StatementReconFileStatus,
  StatementSearchDataTable,
  statementSearchTableColumns,
  useStatementFileByExternalId,
} from "@/modules/statement-recon";

import {KeycloakRoles} from "@/utils/constants";

/**
 * Custom Hook: Manage the active route for the Statement Recon FileHistory page
 */
function useStatementReconStatementSearchRoute() {
  const {setStatementReconRoute} = useRoutesStore();

  useEffect(() => {
    // 📍 Set the active route based on the predefined route schema
    setStatementReconRoute(
      StatementReconRoutesSchema.Values["/app/statement-recon/file-history"]
    );
  }, [setStatementReconRoute]);
}

/**
 * Custom Hook: Fetch data required for the Statement Recon Statement Search Page
 */
function useStatementReconData() {
  const {statementReconStoreCustomer} = useStatementReconCustomerStore();
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  // 📍 Extract the external ID from the customer store
  const externalId = statementReconStoreCustomer?.externalId || "";

  // 📍 Fetch statement files based on the external ID
  const filesByExternalIdQuery = useStatementFileByExternalId(externalId);
  const filesByExternalIdData = filesByExternalIdQuery.data?.content || [];

  // 📍 Calculate the user role display value
  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  // 📍 Memoize the supplier user check to prevent unnecessary re-renders
  const isASupplierUser = useMemo(
    () =>
      userRoleDisplay === KeycloakRoles.SupplierUser ||
      userRoleDisplay === KeycloakRoles.SupplierAdmin ||
      userRoleDisplay === KeycloakRoles.SupplierEnablement,
    [userRoleDisplay]
  );

  return {
    statementReconStoreCustomer,
    filesByExternalIdData,
    filesByExternalIdQuery,
    isASupplierUser,
  };
}

/**
 * Component: Statement Recon Statement Search Page
 */
export function StatementReconStatementSearchPage() {
  // 📍 Manage the active route using the custom hook
  useStatementReconStatementSearchRoute();

  // 📍 Fetch the necessary data using the custom hook
  const {
    statementReconStoreCustomer,
    filesByExternalIdData,
    filesByExternalIdQuery,
    isASupplierUser,
  } = useStatementReconData();

  // 📍 Conditional rendering based on customer and supplier user status
  if (!statementReconStoreCustomer) {
    return isASupplierUser ? (
      <SelectSupplierSection />
    ) : (
      <SelectCustomerSectionStatementRecon />
    );
  }

  // Filtered data based on the tab
  const allStatements = filesByExternalIdData;
  const pendingStatements = filesByExternalIdData.filter(
    (file) =>
      file.statement_file_status === StatementReconFileStatus.UPLOADED ||
      file.statement_file_status === StatementReconFileStatus.MAPPED ||
      file.statement_file_status === StatementReconFileStatus.OCR_PENDING
  );
  const errorStatements = filesByExternalIdData.filter(
    (file) =>
      file.statement_file_status === StatementReconFileStatus.RECONCILE_ERROR
  );
  const reconciledStatements = filesByExternalIdData.filter(
    (file) =>
      file.statement_file_status ===
      StatementReconFileStatus.STATEMENT_RECONCILED
  );
  const processedStatements = filesByExternalIdData.filter(
    (file) =>
      file.statement_file_status ===
      StatementReconFileStatus.STATEMENT_PROCESSED
  );

  return (
    <section className="space-y-2">
      <div className="flex justify-between gap-4">
        <div className="flex flex-col">
          <Heading2>Statement Search</Heading2>
          <Paragraph>
            Search through your previously uploaded statements and view summary
            and details.
          </Paragraph>
        </div>
        <SelectCustomerFormStatementRecon />
      </div>

      <Tabs defaultValue="all-statements">
        <TabsList className="max-w-fit">
          <TabsTrigger value="all-statements">All Statements</TabsTrigger>
          <TabsTrigger value="pending-statements">
            Pending Statements
          </TabsTrigger>
          <TabsTrigger value="error-statements">Error Statements</TabsTrigger>
          <TabsTrigger value="processed-statements">
            Processed Statements
          </TabsTrigger>
          <TabsTrigger value="reconciled-statements">
            Reconciled Statements
          </TabsTrigger>
        </TabsList>
        <TabsContent value="all-statements">
          <StatementSearchDataTable
            columns={statementSearchTableColumns}
            data={allStatements}
            isSuccess={filesByExternalIdQuery.isSuccess}
            isPending={filesByExternalIdQuery.isPending}
            isError={filesByExternalIdQuery.isError}
          />
        </TabsContent>
        <TabsContent value="pending-statements">
          <StatementSearchDataTable
            columns={statementSearchTableColumns}
            data={pendingStatements}
            isSuccess={filesByExternalIdQuery.isSuccess}
            isPending={filesByExternalIdQuery.isPending}
            isError={filesByExternalIdQuery.isError}
          />
        </TabsContent>
        <TabsContent value="error-statements">
          <StatementSearchDataTable
            columns={statementSearchTableColumns}
            data={errorStatements}
            isSuccess={filesByExternalIdQuery.isSuccess}
            isPending={filesByExternalIdQuery.isPending}
            isError={filesByExternalIdQuery.isError}
          />
        </TabsContent>
        <TabsContent value="processed-statements">
          <StatementSearchDataTable
            columns={statementSearchTableColumns}
            data={processedStatements}
            isSuccess={filesByExternalIdQuery.isSuccess}
            isPending={filesByExternalIdQuery.isPending}
            isError={filesByExternalIdQuery.isError}
          />
        </TabsContent>
        <TabsContent value="reconciled-statements">
          <StatementSearchDataTable
            columns={statementSearchTableColumns}
            data={reconciledStatements}
            isSuccess={filesByExternalIdQuery.isSuccess}
            isPending={filesByExternalIdQuery.isPending}
            isError={filesByExternalIdQuery.isError}
          />
        </TabsContent>
      </Tabs>
    </section>
  );
}
