import {useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {type Table} from "@tanstack/react-table";
import {CheckIcon, ChevronsUpDownIcon, ListRestartIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import {z} from "zod";

import {Button} from "@/components/ui/button";
import {Command, CommandGroup, CommandItem} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {
  DataTableFacetedFilter,
  DataTablePagination,
  DataTableViewOptions,
} from "@/components/craft/data-table";
import {DatePicker} from "@/components/craft/date-pickers";
import {
  FancyMultiSelect,
  multiSelectDatumSchema,
} from "@/components/craft/fancy-multi-select";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

import {useImRemitLiteCustomerStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {useGetSuppliersList} from "@/modules/imremit";

const statusData = [
  {label: "Form Declined", value: "FORM_DECLINED"},
  {label: "Active", value: "ACTIVE"},
  {label: "Inactive", value: "INACTIVE"},
  {label: "Onboarding Pending", value: "ONBOARDING_PENDING"},
  {label: "Declined Enrollment", value: "DECLINED_ENROLLMENT"},
  {label: "Approval Pending", value: "APPROVAL_PENDING"},
];

const options = [
  {value: "true", label: "Yes"},
  {value: "false", label: "No"},
];

/**
 * Props for SupplierManagementDataTableToolbar component.
 *
 * @template TData - The type of data used in the table.
 * @typedef {Object} SupplierManagementDataTableToolbarProps
 * @property {Table<TData>} table - The table instance to interact with.
 */
interface SupplierManagementDataTableToolbarProps<TData> {
  table: Table<TData>;
  isPending?: boolean;
  isErrored?: boolean;
}

const SupplierManagementFormSchema = z.object({
  lastEditTimestamp: z.string().nullable().optional(),
  suppliers: z.array(multiSelectDatumSchema),
  universalAcceptor: z.string().optional(),
});

/**
 * Toolbar component for supplier management data table.
 * Includes pagination, search input, filter components, and view options.
 *
 * @template TData - The type of data displayed in the table.
 * @param {SupplierManagementDataTableToolbarProps<TData>} props - The component props.
 * @returns {JSX.Element} The rendered JSX element.
 */
export function SupplierManagementDataTableToolbar<TData>({
  table,
  isPending,
  isErrored,
}: SupplierManagementDataTableToolbarProps<TData>) {
  const supplierManagementToolbarFormMethods = useForm<
    z.infer<typeof SupplierManagementFormSchema>
  >({
    defaultValues: {
      lastEditTimestamp: undefined,
      suppliers: [],
    },
    mode: "onChange",
    resolver: zodResolver(SupplierManagementFormSchema),
  });

  const formFields = supplierManagementToolbarFormMethods.watch();

  // State for the global filter input
  const [globalFilterInput, setGlobalFilterInput] = useState("");
  // State for the specific filter input
  // const [phoneNoFilterInput, setPhoneNoFilterInput] = useState("");
  // const [zipCodeFilterInput, setZipCodeFilterInput] = useState("");

  // Determine if the table is currently filtered
  const isFiltered =
    table.getState().columnFilters.length > 0 ||
    !!table.getState().globalFilter;

  const {imRemitLiteStoreCustomer} = useImRemitLiteCustomerStore();

  /**
   * @type {string | undefined}
   */
  const externalId = imRemitLiteStoreCustomer?.externalId;

  /**
   * @type {{data: {content: any[]} | undefined}}
   */
  const getSupplierByCustomerIdQuery = useGetSuppliersList(externalId);

  /**
   * @type {any[] | undefined}
   */
  const rawSuppliersData = getSupplierByCustomerIdQuery.data?.content;

  /**
   * @type {{label: string, value: string}[]}
   */
  const suppliersData = useMemo(() => {
    if (!rawSuppliersData) return [];
    return rawSuppliersData.map((supplier) => ({
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      label: supplier.supplierNumber + "-" + supplier.supplierName,
      value: supplier.supplierNumber?.toString() || "",
    }));
  }, [rawSuppliersData]);

  //Columns other names
  const columnsOtherName = [
    {key: "customerName", value: "Customer Name"},
    {key: "supplierNumber", value: "Supplier ID"},
    {key: "supplierName", value: "Supplier Name"},
    {key: "taxId", value: "Tax ID"},
    {key: "phoneNumber", value: "Phone Number"},
    {key: "supplierEmail", value: "Supplier Email"},
    {key: "onboardStatus", value: "Status"},
    {key: "paymentMode", value: "Remittance Method"},
    {key: "selfRegisteredFlag", value: "Self Registered"},
    {key: "lastEditTimestamp", value: "Updated Date"},
    {key: "universalAcceptor", value: "Universal Acceptor"},
  ];
  return (
    <>
      <DataTablePagination
        table={table}
        isPending={isPending}
        isErrored={isErrored}
      />
      <Form {...supplierManagementToolbarFormMethods}>
        <div className="flex items-center justify-between gap-2">
          <div className="flex flex-1 flex-wrap items-center gap-y-2 space-x-2">
            <RowsPreferenceSelect />

            <Input
              placeholder="Search all entries..."
              className="h-10 w-[150px] lg:w-[256px]"
              value={globalFilterInput}
              onChange={(event) => {
                setGlobalFilterInput(event.target.value);
                table.setGlobalFilter(event.target.value);
              }}
            />

            <FormField
              name="suppliers"
              render={({field}) => (
                <FormItem className="flex flex-col">
                  <FormControl>
                    <FancyMultiSelect
                      {...field}
                      multiSelectData={suppliersData}
                      disabled={!suppliersData.length}
                      customWidth="fit"
                      placeholder={
                        !suppliersData.length
                          ? "No suppliers..."
                          : "Search suppliers..."
                      }
                      onChange={(selectedItems) => {
                        field.onChange(selectedItems);
                        console.log(selectedItems);
                        // Loop through each selected item and set filter value
                        // Extract the values from the selected items
                        const supplierNameOrIdFilterInput = selectedItems.map(
                          (item) => item.value
                        );

                        // Set the filter value with the new selection
                        table
                          .getColumn("supplierNumber")
                          ?.setFilterValue(
                            supplierNameOrIdFilterInput.join(",")
                          );
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />

            <FormField
              name="lastEditTimestamp"
              render={({field}) => (
                <FormItem className="flex flex-col">
                  <DatePicker
                    {...field}
                    placeholder="updated date..."
                    disabled={
                      formFields.lastEditTimestamp
                        ? {
                            before: new Date("1970-01-01"),
                            after: new Date("9999-01-01"),
                          }
                        : undefined
                    }
                    onChange={(date) => {
                      field.onChange(date);
                      const filteredDate = new Date(date);
                      filteredDate.setDate(date.getDate() + 1);
                      table
                        .getColumn("lastEditTimestamp")
                        ?.setFilterValue(
                          filteredDate.toISOString().substring(0, 10)
                        );
                    }}
                  />
                </FormItem>
              )}
            />

            <FormField
              control={supplierManagementToolbarFormMethods.control}
              name="universalAcceptor"
              render={({field}) => (
                <div className="space-y-2">
                  <FormItem>
                    <Popover>
                      <PopoverContent className="w-[260px] p-0">
                        <Command>
                          <CommandGroup>
                            {options.map((data, i) => (
                              <CommandItem
                                key={i}
                                value={data.value.toUpperCase() || ""}
                                onSelect={(value) => {
                                  field.onChange(value.toUpperCase());
                                  table
                                    .getColumn("universalAcceptor")
                                    ?.setFilterValue(
                                      value === "true" ? true : false
                                    );
                                }}
                              >
                                <CheckIcon
                                  className={cn(
                                    "mr-2 size-4",
                                    data.value
                                      .toString()
                                      .trim()
                                      .toUpperCase() ===
                                      field.value
                                        ?.toString()
                                        .toUpperCase()
                                        .trim()
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                                {data.label}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </Command>
                      </PopoverContent>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            role="combobox"
                            className={cn(
                              "justify-between",
                              !field.value && "text-accent-foreground",
                              "w-[260px]"
                            )}
                          >
                            {field.value
                              ? options.find(
                                  (item) =>
                                    item.value.toString().toUpperCase() ===
                                    field.value?.toString().toUpperCase()
                                )?.label
                              : "Select Universal Acceptor..."}
                            <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                </div>
              )}
            />

            {isFiltered && (
              <Button
                variant="destructive"
                className="h-10 gap-2 px-2 hover:bg-destructive/70 hover:text-destructive-foreground/80 active:bg-destructive/80 active:text-destructive-foreground/80 lg:px-3"
                onClick={() => {
                  table.resetColumnFilters();
                  table.resetGlobalFilter();
                  setGlobalFilterInput("");

                  // setEmailFilterInput("");
                  // setPhoneNoFilterInput("");
                  // setZipCodeFilterInput("");
                  supplierManagementToolbarFormMethods.reset();
                }}
              >
                <span className="sr-only">Reset search filters</span>
                Search Reset
                <ListRestartIcon className="size-4" />
              </Button>
            )}
          </div>
          <div className="flex flex-col items-end gap-2 lg:flex-row">
            {table.getColumn("onboardStatus") && (
              <DataTableFacetedFilter
                column={table.getColumn("onboardStatus")}
                title="Status"
                options={statusData}
              />
            )}
            {/* <AdvancedSearch></AdvancedSearch> */}
            <DataTableViewOptions
              table={table}
              columnsOtherName={columnsOtherName}
            />
          </div>
        </div>
      </Form>
    </>
  );
}
