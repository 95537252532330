import {z} from "zod";

export const facilitiesSchema = z.object({
  participantRegisterId: z.number(),
  facilityName: z.string(),
  userId: z.string(),
  orgId: z.string(),
  groupId: z.string(),
  futureUse1: z.string(),
  futureUse2: z.string(),
  bankAccountNumber: z.string(),
});

export type Facilities = z.infer<typeof facilitiesSchema>;

export const FacilitiesColumnsSchema = z.object({
  participantRegisterId: z.boolean(),
  facilityName: z.boolean(),
  userId: z.boolean(),
  orgId: z.boolean(),
  groupId: z.boolean(),
  futureUse1: z.boolean(),
  futureUse2: z.boolean(),
  bankAccountNumber: z.boolean(),
});

export type FacilitiesColumnsType = z.infer<typeof FacilitiesColumnsSchema>;
