import {useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {useParams} from "@tanstack/react-router";
import {type Row} from "@tanstack/react-table";
import {
  FileEditIcon,
  GripVerticalIcon,
  PackageXIcon,
  Trash2Icon,
} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {CardContent, CardHeader, CardTitle} from "@/components/ui/card";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {Form} from "@/components/ui/form";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Separator} from "@/components/ui/separator";
import {Spinner} from "@/components/ui/spinner";
import {toast} from "@/components/ui/use-toast";
import {LoadingSection} from "@/components/craft/loading-section";

import {
  CustomerPaymentMethodDescriptionFormField,
  CustomerPaymentMethodNamesField,
  deleteCustomerPaymentMethodFn,
  PaymentProviderNameField,
  ProviderPaymentMethodNamesField,
  updateCustomerPaymentMethodFn,
  UpdatePaymentMethodFormSchema,
} from "@/modules/admin";
import {useKeyCloakInstanceStore} from "@/modules/auth";

interface PaymentMethodRow {
  customerPaymentMethodId: number;
  paymentMethodId: number | null;
  paymentMethodName: string | null;
  providerId: number | null;
  providerName: string | null;
  customerPaymentMethodName?: string | null;
  customerPaymentMethodDescription?: string | null;
}

interface PaymentMethodRowActionsProps<TData> {
  row: Row<TData>;
}

/**
 * Represents the actions for a payment file row.
 * This component provides a dropdown menu for each row with various actions like edit, copy, favorite, and delete.
 * @returns {JSX.Element} The PaymentMethodRowActions component.
 */
export function PaymentMethodRowActions<TData extends PaymentMethodRow>({
  row,
}: PaymentMethodRowActionsProps<TData>) {
  const {externalId} = useParams({
    from: "/app/admin/customer-management/imremit/$externalId/onboarding/payment-method",
  });

  const PaymentMethodRows = row.original;

  const [open, setOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button className="flex h-12 w-12 gap-2 bg-theme p-0 hover:bg-neutral hover:bg-theme/90 ">
            <span className="sr-only">Open menu</span>
            <GripVerticalIcon className="size-6 bg-inherit" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          align="end"
          className="z-50 min-w-fit overflow-hidden rounded-md border bg-root/70 p-1 text-foreground shadow-md backdrop-blur-xl backdrop-brightness-150 animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
        >
          <div className="px-2 py-1.5 text-sm font-semibold">Actions</div>
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger className="w-full">
              <div
                className="relative flex cursor-default select-none items-center justify-between gap-4 rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:cursor-pointer focus:bg-accent focus:text-accent-foreground data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50"
                onSelect={(e) => {
                  e.stopPropagation();
                }}
              >
                Update Payment
                <span className="sr-only">Update Payment Details</span>
                <FileEditIcon className="size-4" />
              </div>
            </DialogTrigger>
            <DialogContent className=" sm:min-w-[340px] md:min-w-[668px] lg:min-w-[780px] xl:min-w-[1080px] 2xl:min-w-[1118px]">
              <DialogHeader>
                <DialogTitle>Update form</DialogTitle>
                <DialogDescription>
                  <UpdatePaymentMethodForm
                    externalId={externalId}
                    PaymentMethodRows={PaymentMethodRows}
                    setOpen={setOpen}
                  />
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
          <Separator className="my-1 -ml-1 -mr-2 h-px bg-foreground" />
          <Dialog open={isDeleteOpen} onOpenChange={setIsDeleteOpen}>
            <DialogTrigger className="w-full">
              <div
                className="relative flex cursor-default select-none items-center justify-between gap-4 rounded-sm bg-destructive px-2 py-1.5 text-sm text-destructive-foreground outline-none transition-colors hover:cursor-pointer hover:bg-destructive/90 hover:text-destructive-foreground/90 focus:bg-accent focus:text-accent-foreground data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50"
                onSelect={(e) => {
                  e.stopPropagation();
                }}
              >
                Delete Item
                <span className="sr-only">Delete Item</span>
                <PackageXIcon className="size-4" />
              </div>
            </DialogTrigger>
            <DeletePaymentMethodById
              externalId={externalId}
              customerPaymentMethodId={PaymentMethodRows.customerPaymentMethodId.toString()}
              setIsDeleteOpen={setIsDeleteOpen}
            />
          </Dialog>
        </PopoverContent>
      </Popover>
      {/* <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button className="flex h-12 w-12 gap-2 bg-theme p-0 hover:bg-neutral hover:bg-theme/90 data-[state=open]:bg-secondary">
            <span className="sr-only">Open menu</span>
            <GripVerticalIcon className="size-6 bg-inherit" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="min-w-fit">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger>
              <DropdownMenuItem
                className="flex justify-between gap-4"
                onSelect={(e) => {
                  e.preventDefault();
                }}
              >
                Update Payment
                <span className="sr-only">Update Payment Details</span>
                <FileEditIcon className="size-4" />
              </DropdownMenuItem>
            </DialogTrigger>
            <DialogContent className=" sm:min-w-[340px] md:min-w-[668px] lg:min-w-[780px] xl:min-w-[1080px] 2xl:min-w-[1118px]">
              <DialogHeader>
                <DialogTitle>Update form</DialogTitle>
                <DialogDescription>
                  <UpdatePaymentMethodForm
                    externalId={externalId}
                    PaymentMethodRows={PaymentMethodRows}
                    setOpen={setOpen}
                  />
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
          <DropdownMenuSeparator />
          <Dialog>
            <DialogTrigger className="w-full">
              <DropdownMenuItem
                className="flex justify-between gap-4 bg-destructive text-destructive-foreground hover:bg-destructive/90 hover:text-destructive-foreground/90"
                onSelect={(e) => {
                  e.preventDefault();
                }}
              >
                Delete Item
                <span className="sr-only">Delete Item</span>
                <PackageXIcon className="size-4" />
              </DropdownMenuItem>
            </DialogTrigger>
            <DeletePaymentMethodById
              externalId={externalId}
              customerPaymentMethodId={PaymentMethodRows.customerPaymentMethodId.toString()}
            />
          </Dialog>
        </DropdownMenuContent>
      </DropdownMenu> */}
    </>
  );
}

/**
 * Custom hook for performing the 'update Payment Register' mutation.
 * This hook abstracts the mutation logic and side effects like toast notifications.
 *
 * @param {number | undefined} externalId - The ID of the customer.
 * @params {number | undefined} paymentMethodId - The ID of the Payment Register.
 * @returns A mutation object with methods to trigger the mutation.
 */
function useUpdatePaymentMethod(
  externalId: string,
  paymentMethodId: string,
  setOpen: (open: boolean) => void
) {
  return useMutation({
    mutationFn: (data: z.infer<typeof UpdatePaymentMethodFormSchema>) => {
      // Perform the mutation using the updateCustomerPaymentMethodFn function
      return updateCustomerPaymentMethodFn(data, externalId, paymentMethodId);
    },

    onSuccess: (response) => {
      // Handle successful mutation
      console.log("updatePaymentMethodResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Payment method updated successfully",
      });
      setOpen(false);
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update payment method",
      });
      setOpen(false);
    },
  });
}

/**
 * Custom hook for performing the 'delete Payment Register' mutation.
 * This hook abstracts the mutation logic and side effects like toast notifications.
 *
 * @param {number | undefined} externalId - The ID of the customer.
 * @params {number | undefined} paymentMethodId - The ID of the Payment Register.
 * @returns A mutation object with methods to trigger the mutation.
 */

function useDeletePaymentMethod(
  externalId: string,
  paymentMethodId: string,
  setIsDeleteOpen: (open: boolean) => void
) {
  return useMutation({
    mutationFn: () => {
      // Perform the mutation using the addPaymentMethodFn function
      return deleteCustomerPaymentMethodFn(externalId, paymentMethodId);
    },
    onSuccess: (response) => {
      // Handle successful mutation
      console.log("deletePaymentMethodResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Payment method deleted successfully",
      });

      setIsDeleteOpen(false);
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to delete payment method",
      });
      setIsDeleteOpen(false);
    },
  });
}

function UpdatePaymentMethodForm({
  externalId,
  PaymentMethodRows,
  setOpen,
}: {
  externalId: string;
  PaymentMethodRows: PaymentMethodRow;
  setOpen: (open: boolean) => void;
}) {
  const storeKeyCloakInstance =
    useKeyCloakInstanceStore().storeKeyCloakInstance;

  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );

  const updatePaymentMethodForm = useForm<
    z.infer<typeof UpdatePaymentMethodFormSchema>
  >({
    mode: "onChange",
    resolver: zodResolver(UpdatePaymentMethodFormSchema),
    defaultValues: {
      providerId: PaymentMethodRows.providerId?.toString() ?? "",
      paymentMethod: PaymentMethodRows.paymentMethodId?.toString() ?? "",
      customerPaymentMethodName: PaymentMethodRows.customerPaymentMethodName,
      customerPaymentMethodDescription:
        PaymentMethodRows.customerPaymentMethodDescription,
      modifiedBy: parsedUserInfo?.name as string,
    },
  });

  const updatePaymentMethodMutation = useUpdatePaymentMethod(
    externalId,
    PaymentMethodRows.customerPaymentMethodId.toString(),
    setOpen
  );

  return (
    <>
      <CardHeader>
        <CardTitle className="mb-5">Update Payment</CardTitle>
      </CardHeader>
      <div className="gap-y-4 sm:flex-row sm:gap-x-4">
        <CardContent>
          <Form {...updatePaymentMethodForm}>
            <form
              onSubmit={updatePaymentMethodForm.handleSubmit((data) => {
                updatePaymentMethodMutation.mutate(data);
              })}
            >
              <div className="mb-5 mt-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
                <PaymentProviderNameField isMutating={true} />
                <ProviderPaymentMethodNamesField
                  isMutating={updatePaymentMethodMutation.isPending}
                  externalId={externalId}
                />

                <CustomerPaymentMethodNamesField
                  isMutating={updatePaymentMethodMutation.isPending}
                />
                <CustomerPaymentMethodDescriptionFormField
                  isMutating={updatePaymentMethodMutation.isPending}
                />
              </div>
              <div className="flex items-center justify-end space-x-2 p-2">
                <Button
                  type="submit"
                  disabled={updatePaymentMethodMutation.isPending}
                  aria-disabled={updatePaymentMethodMutation.isPending}
                  variant="affirmative"
                >
                  {updatePaymentMethodMutation.isPending ? (
                    <Spinner size="xs" />
                  ) : (
                    ""
                  )}
                  Save
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </div>
    </>
  );
}

function DeletePaymentMethodById({
  externalId,
  customerPaymentMethodId,
  setIsDeleteOpen,
}: {
  externalId: string;
  customerPaymentMethodId: string;
  setIsDeleteOpen: (open: boolean) => void;
}) {
  const deletePaymentMethodMutation = useDeletePaymentMethod(
    externalId,
    customerPaymentMethodId,
    setIsDeleteOpen
  );

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogDescription>
          Do you want to delete the entry? Deleting this entry cannot be undone.
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <DialogClose asChild>
          <Button
            aria-disabled={deletePaymentMethodMutation.isPending}
            disabled={deletePaymentMethodMutation.isPending}
            variant="outline"
          >
            Cancel
          </Button>
        </DialogClose>
        <Button
          variant="destructive"
          className="flex items-center gap-2"
          aria-disabled={deletePaymentMethodMutation.isPending}
          disabled={deletePaymentMethodMutation.isPending}
          onClick={() => {
            deletePaymentMethodMutation.mutate();
          }}
        >
          {deletePaymentMethodMutation.isPending ? (
            <LoadingSection sectionSize="xs" />
          ) : (
            <Trash2Icon className="size-4" />
          )}
          Delete
        </Button>
      </DialogFooter>
    </DialogContent>
  );
}
