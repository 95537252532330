import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {type ReconMappingListType} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";

export const reconFileColumns: ColumnDef<ReconMappingListType>[] = [
  {
    accessorKey: "masterMappingName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Mapping Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("masterMappingName") ? (
          row.getValue("masterMappingName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "relationId",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Relation ID" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("relationId") ? (
          row.getValue("relationId")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "supplierNumber",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Supplier Number" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("supplierNumber") ? (
          row.getValue("supplierNumber")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "cardNumber",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Card Number" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("cardNumber") ? (
          row.getValue("cardNumber")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "transactionType",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Transaction Type" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("transactionType") ? (
          row.getValue("transactionType")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "transactionId",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Transaction ID" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("transactionId") ? (
          row.getValue("transactionId")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "transactionAmount",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Transaction Amount" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("transactionAmount") ? (
          row.getValue("transactionAmount")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "transactionDate",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Transaction Date" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("transactionDate") ? (
          <span>{row.getValue("transactionDate")}</span>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },

  {
    accessorKey: "postingDate",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Posting Date" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("postingDate") ? (
          <span>{row.getValue("postingDate")}</span>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },

  {
    accessorKey: "merchantName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Merchant Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("merchantName") ? (
          row.getValue("merchantName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
    // enableFiltering: false,
  },

  {
    accessorKey: "originalAmount",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Original Amount" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("originalAmount") ? (
          row.getValue("originalAmount")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
    // enableFiltering: false,
  },

  {
    accessorKey: "udf1",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="UDF1" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("udf1") ? (
          row.getValue("udf1")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
    // enableFiltering: false,
  },

  {
    accessorKey: "udf2",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="UDF2" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("udf2") ? (
          row.getValue("udf2")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
    // enableFiltering: false,
  },

  {
    accessorKey: "udf3",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="UDF3" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("udf3") ? (
          row.getValue("udf3")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
    // enableFiltering: false,
  },

  {
    accessorKey: "udf4",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="UDF4" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("udf4") ? (
          row.getValue("udf4")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
    // enableFiltering: false,
  },

  {
    accessorKey: "udf5",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="UDF5" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("udf5") ? (
          row.getValue("udf5")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
    // enableFiltering: false,
  },

  {
    accessorKey: "customData1",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Custom Data 1" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("customData1") ? (
          row.getValue("customData1")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
    // enableFiltering: false,
  },

  {
    accessorKey: "customData2",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Custom Data 2" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("customData2") ? (
          row.getValue("customData2")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
    // enableFiltering: false,
  },

  {
    accessorKey: "customData3",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Custom Data 3" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("customData3") ? (
          row.getValue("customData3")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
    // enableFiltering: false,
  },
  {
    accessorKey: "orderMerchantName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Order Merchant Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("orderMerchantName") ? (
          row.getValue("orderMerchantName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },

  // {
  //   id: "actions",
  //   cell: ({row}) => <PaymentFileRowActions row={row} />,
  // },
];
