import {useEffect, useMemo} from "react";

import {Link} from "@tanstack/react-router";
import {UserPlus2Icon} from "lucide-react";

import {buttonVariants} from "@/components/ui/button";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {Spinner} from "@/components/ui/spinner";
import {Heading2, Paragraph} from "@/components/ui/typography";

import {AdminRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {
  NewUserSessionsTable,
  useAllRealmClients,
  useAllRealmSessions,
  useAllRealmSessionsDetails,
  useAllRealmUsers,
  UserManagementDataTable,
  userManagementTableColumns,
} from "@/modules/admin";
import {
  AccessPolicyWrapper,
  calculateUserRoles,
  KeycloakRoleEnum,
  useKeyCloakInstanceStore,
  useUserInfo,
} from "@/modules/auth";
import {
  CustomerNotFound,
  NoCustomerAssigned,
  NoSupplierAssigned,
  SupplierNotFound,
  useAllCustomers,
  useGetAllSupplierWithOutFilter,
} from "@/modules/customers";

import {KeycloakRoles} from "@/utils/constants";

/**
 * Custom hook for setting the admin route in the store
 */
function useUserManagementRoute() {
  const {setAdminRoute} = useRoutesStore();

  useEffect(() => {
    setAdminRoute(AdminRoutesSchema.Values["/app/admin/user-management"]);
  }, [setAdminRoute]);
}

/**
 * Custom hook for getting the data needed for the User Management page
 *
 * @returns {Object} - Object containing the data needed for the User Management page
 */
function useGetUserManagementData() {
  const allRealmUsersQuery = useAllRealmUsers();
  const allRealmUsersData = allRealmUsersQuery.data || [];

  const allRealmClientsQuery = useAllRealmClients();
  const allRealmClientsData = allRealmClientsQuery.data || [];

  const nextecUIClientId = allRealmClientsData.find(
    (client) => client.clientId === "nextec-ui"
  )?.id;

  const nextecUISessionCountQuery = useAllRealmSessions(nextecUIClientId);
  const nextecUISessionCount = nextecUISessionCountQuery.data?.count || 0;

  const nextecUISessionsDetailsQuery =
    useAllRealmSessionsDetails(nextecUIClientId);
  const nextecUISessionsDetailsData = nextecUISessionsDetailsQuery.data || [];

  return {
    allRealmUsersQuery,
    allRealmUsersData,
    nextecUISessionCountQuery,
    nextecUISessionCount,
    nextecUISessionsDetailsQuery,
    nextecUISessionsDetailsData,
  };
}

/**
 * UserManagementPage Component
 *
 * This component serves as the central hub for managing users within the admin interface.
 * It provides the UI and mechanisms for adding, editing, and deleting users.
 */
export function UserManagementPage() {
  // Set the admin route in the store
  useUserManagementRoute();

  // Get the data needed for the User Management page
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const {
    allRealmUsersQuery,
    allRealmUsersData,
    nextecUISessionCountQuery,
    nextecUISessionCount,
    nextecUISessionsDetailsQuery,
    nextecUISessionsDetailsData,
  } = useGetUserManagementData();
  const {parsedUserInfo} = useUserInfo();
  const {data: suppliersRawData} = useGetAllSupplierWithOutFilter();
  const {
    data: rawCustomersData,
    isPending: customersArePending,
    isError: customersAreError,
  } = useAllCustomers();
  const currentCustomer = parsedUserInfo?.customer as string;
  const currentSupplier = parsedUserInfo?.supplier as string;

  // Determine if the current supplier is not found in the fetched data  const supplierNotFound =
  const supplierNotFound =
    currentSupplier &&
    !suppliersRawData?.content.find(
      (supplier) =>
        supplier.supplier_id.toLowerCase() ===
        currentSupplier.toLowerCase().trim()
    );
  // Determine if the current customer is not found in the fetched data
  const customerNotFound =
    currentCustomer &&
    !rawCustomersData?.content.find(
      (customer) =>
        customer.buyerName.toLowerCase().trim() ===
        currentCustomer.toLowerCase().trim()
    );

  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  const customerUsers = allRealmUsersData.filter(
    (user) => user.attributes?.customer == currentCustomer
  );

  const supplierUsers = allRealmUsersData.filter(
    (user) => user.attributes?.supplier == currentSupplier
  );

  const isASupplierUser = useMemo(
    () =>
      userRoleDisplay === KeycloakRoles.SupplierAdmin ||
      userRoleDisplay === KeycloakRoles.SupplierUser,
    [userRoleDisplay]
  );

  const isACustomerUser = useMemo(
    () =>
      userRoleDisplay === KeycloakRoles.CustomerAdmin ||
      userRoleDisplay === KeycloakRoles.CustomerUser ||
      userRoleDisplay === KeycloakRoles.CustomerReviewer ||
      userRoleDisplay === KeycloakRoles.CustomerSuperAdmin,
    [userRoleDisplay]
  );

  // If the customers are not pending and there is an error, throw an error
  if (!customersArePending && customersAreError) {
    throw new Error("Failed to fetch customers");
  }

  // Display a message if the specific supplier is not found
  if (supplierNotFound && currentSupplier && isASupplierUser) {
    return <SupplierNotFound supplierId={currentSupplier} />;
  }

  // Display a message if the user is a supplier user or supplier admin and no supplier is assigned
  if (!currentSupplier && isASupplierUser) {
    return <NoSupplierAssigned />;
  }

  // Display a message if the specific customer is not found
  if (customerNotFound && currentCustomer && isACustomerUser) {
    return <CustomerNotFound customerName={currentCustomer} />;
  }

  // Display a message if the user is a customer user or customer admin and no customer is assigned
  if (!currentCustomer && isACustomerUser) {
    return <NoCustomerAssigned />;
  }

  // Render the User Management Interface
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.READ_USER_MANAGEMENT]}
    >
      <section
        className="flex flex-1 flex-col md:flex-row md:justify-between"
        role="presentation"
        aria-roledescription="This section contains the User Management page. This page allows administrators to add, edit, and delete users."
      >
        <div className="mb-2 flex flex-col">
          <Heading2>User Management</Heading2>
          <Paragraph>Manage all realm users here.</Paragraph>
        </div>
        <div className="flex items-center gap-4">
          <HoverCard openDelay={50} closeDelay={150}>
            <HoverCardTrigger asChild>
              <Paragraph className="font-semibold underline hover:cursor-pointer hover:font-bold">
                Active Sessions:{" "}
                {nextecUISessionCountQuery.isPending ? (
                  <Spinner />
                ) : (
                  <strong className="text-theme hover:text-theme/80">
                    {nextecUISessionCount}
                  </strong>
                )}
              </Paragraph>
            </HoverCardTrigger>
            <HoverCardContent>
              <NewUserSessionsTable
                userSessions={nextecUISessionsDetailsData}
                isError={nextecUISessionsDetailsQuery.isError}
                isSuccess={nextecUISessionsDetailsQuery.isSuccess}
              />
            </HoverCardContent>
          </HoverCard>
          <AccessPolicyWrapper
            unauthorizedFallback={null}
            policyActions={[KeycloakRoleEnum.CREATE_USER_MANAGEMENT]}
          >
            <Link
              to="/app/admin/user-management/create"
              className={cn(buttonVariants(), "mb-4 gap-2")}
            >
              <span className="sr-only">Create User</span>
              Create User
              <UserPlus2Icon className="size-4" />
            </Link>
          </AccessPolicyWrapper>
        </div>
      </section>

      <UserManagementDataTable
        columns={userManagementTableColumns}
        isPending={allRealmUsersQuery.isPending}
        isSuccess={allRealmUsersQuery.isSuccess}
        isError={allRealmUsersQuery.isError}
        data={
          isACustomerUser
            ? customerUsers
            : isASupplierUser
            ? supplierUsers
            : allRealmUsersData
        }
      />
    </AccessPolicyWrapper>
  );
}
