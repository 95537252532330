import {useEffect} from "react";

import {useParams} from "@tanstack/react-router";

import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {ImREmitLiteRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";
import {KeycloakRoleEnum, useAuthorization} from "@/modules/auth";
import {AddGenericSupplierScriptPage} from "@/modules/imremit";

export function AddSupplierScriptLitePage() {
  const {setimREmitLiteRoute} = useRoutesStore();

  useEffect(() => {
    setimREmitLiteRoute(
      ImREmitLiteRoutesSchema.Values[
        "/app/imremit-lite/supplier-script-management"
      ]
    );
  }, [setimREmitLiteRoute]);

  const {supplierId, paymentId, moduleName} = useParams({
    from: "/app/imremit-lite/supplier-script-management/$moduleName/$supplierId/create/$paymentId",
  });

  const {checkPoliciesAccess} = useAuthorization();

  // // Handle unauthorized access
  if (
    !checkPoliciesAccess([
      KeycloakRoleEnum.CREATE_SUPPLIER_SCRIPT_MANAGEMENT_LITE,
    ])
  ) {
    return <NotAuthorizedPage />;
  }

  return (
    <AddGenericSupplierScriptPage
      supplierId={supplierId}
      moduleName={moduleName}
      paymentId={paymentId}
    />
  );
}
