import {useEffect, useMemo, useState} from "react";

import {Link, useParams} from "@tanstack/react-router";
import {ArrowLeftIcon, ArrowRightIcon, LogOutIcon} from "lucide-react";

import {Button, buttonVariants} from "@/components/ui/button";
import {Skeleton} from "@/components/ui/skeleton";
import {Spinner} from "@/components/ui/spinner";
import {Heading2} from "@/components/ui/typography";

import {useImRemitCustomerStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {useFetchSelectCustomerFormImRemit} from "@/modules/customers";
import {
  useGetPaymentDetails,
  usePaymentManagementMetaStore,
  useSearchimREmits,
  type ImREmitPaymentType,
} from "@/modules/imremit";

import {generateQueryParams} from "@/utils/query-params-generator";

/**
 * Custom Hook: Fetch and prepare payment management data.
 *
 * @returns {Object} - The content and metadata for the payment management, along with the API query object.
 */
function useFetchAndPreparePaymentManagement(externalId?: string) {
  //Define a array for three pages payment data
  const paymentsData = [];
  const multiviewPayments: ImREmitPaymentType[] = [];

  //payment mangement meta store
  const {storePaymentManagementCriteria, storePaymentManagementSortMeta} =
    usePaymentManagementMetaStore();

  // Temporary hack, allow up to 3000000 records to be fetched
  const hackPageSize = "1000000";

  //query params for single page
  const queryParams = generateQueryParams({
    params: storePaymentManagementCriteria || {},
    page: 0,
    size: hackPageSize,
    sort: storePaymentManagementSortMeta,
  });
  const searchPaymentManagementQuery = useSearchimREmits(
    externalId,
    queryParams
  );
  paymentsData.push(searchPaymentManagementQuery.data?.content);

  paymentsData.map((data) => {
    data?.map((data2) => {
      multiviewPayments.push(data2);
    });
  });

  const queriesPending = searchPaymentManagementQuery.isPending;

  return {multiviewPayments, queriesPending};
}

interface PaymentsMultiviewProps {
  externalId?: string;
}

/**
 * Custom Hook: Multiview
 * @returns {Object} - The next & previouse payment detail details.
 */
export function RemittanceMultiview({externalId}: PaymentsMultiviewProps) {
  const {paymentId, list_type} = useParams({
    from: "/app/imremit/remittance-management/$paymentId/view/$list_type",
  });

  // Declare a state variable for the index of the current payment
  const [index, setIndex] = useState(0);

  // Declare a state variable for the previouse & next payment
  const [previousePayment, setPreviousePaymentId] = useState("");
  const [nextPayment, setNextPaymentId] = useState("");

  // Fetch and prepare the Payment Management data
  const {multiviewPayments, queriesPending} =
    useFetchAndPreparePaymentManagement(externalId);

  useEffect(() => {
    if (multiviewPayments.length > 0) {
      const index = multiviewPayments.findIndex(
        (payment) => payment.paymentDetailId === paymentId
      );
      const currentIndex = index !== -1 ? index : 0;
      setIndex(currentIndex);
      setPreviousePaymentId(
        multiviewPayments[index - 1]?.paymentDetailId
          ? multiviewPayments[index - 1]?.paymentDetailId
          : ""
      );
      setNextPaymentId(multiviewPayments[index + 1]?.paymentDetailId);
    }

    return () => {
      setPreviousePaymentId("");
      setNextPaymentId("");
    };
  }, [multiviewPayments, paymentId]);

  return (
    <div className="mb-2 flex w-full flex-col items-start justify-between gap-2 lg:flex-row lg:items-center">
      <Heading2 className="flex gap-2">
        Viewing Payment:{" "}
        {queriesPending ? (
          <Skeleton className="h-8 w-20" />
        ) : (
          `${
            index ? (index + 1).toString() : "1"
          } of ${multiviewPayments.length.toString()}`
        )}
      </Heading2>

      <div className="flex w-full flex-col gap-2 lg:w-fit lg:flex-row">
        <Link
          to="/app/imremit/remittance-management/$paymentId/view/$list_type"
          params={{
            paymentId: previousePayment,
            list_type: list_type || "",
          }}
        >
          <Button
            size="sm"
            className="w-full gap-4 lg:w-60"
            disabled={!previousePayment || queriesPending}
          >
            {queriesPending ? (
              <Spinner size="xs" />
            ) : (
              <ArrowLeftIcon className="size-4" />
            )}
            Previous Payment
          </Button>
        </Link>
        <Link
          to="/app/imremit/remittance-management/$paymentId/view/$list_type"
          params={{
            paymentId: nextPayment,
            list_type: list_type || "",
          }}
        >
          <Button
            size="sm"
            className="w-full gap-4 lg:w-60"
            disabled={
              index === multiviewPayments.length - 1 ||
              queriesPending ||
              !nextPayment
            }
          >
            Next Payment
            {queriesPending ? (
              <Spinner size="xs" />
            ) : (
              <ArrowRightIcon className="size-4" />
            )}
          </Button>
        </Link>
        <Link
          to="/app/imremit/remittance-management"
          className={cn(
            buttonVariants({size: "sm", variant: "secondary"}),
            "w-full gap-4 lg:w-60"
          )}
        >
          <span className="sr-only">Remittance Management</span>
          Remittance Management
          <LogOutIcon className="size-4" />
        </Link>

        {/* {list_type === "all" ? (
          <Link
            to="/app/imremit/remittance-management"
            className={cn(
              buttonVariants({size: "sm", variant: "secondary"}),
              "w-full gap-4 lg:w-60"
            )}
          >
            <span className="sr-only">Remittance Management</span>
            Remittance Management
            <LogOutIcon className="size-4" />
          </Link>
        ) : (
          <Link
            to="/app/imremit/payment-management/$pmPage/payments/$methodType"
            className={cn(
              buttonVariants({size: "sm", variant: "secondary"}),
              "w-full gap-4 lg:w-60"
            )}
            params={{
              pmPage: "1",
              methodType: list_type || "",
            }}
          >
            <span className="sr-only">Payments List</span>
            Payments List
          </Link>
        )} */}
      </div>
    </div>
  );
}

export function NewRemittanceManagementMultiView() {
  const {paymentId, list_type} = useParams({
    from: "/app/imremit/remittance-management/$paymentId/view/$list_type",
  });
  const {imREmitStoreCustomer, updateImRemitStoreCustomer} =
    useImRemitCustomerStore();

  const searchimREmitQuery = useGetPaymentDetails(paymentId);
  const searchimREmitData = searchimREmitQuery.data?.content;

  const {imREmitCustomersContent} = useFetchSelectCustomerFormImRemit();

  const previousPaymentId = useMemo(() => {
    return searchimREmitData?.links?.find((link) => link.rel === "previous")
      ?.href;
  }, [searchimREmitData]);

  const nextPaymentId = useMemo(() => {
    return searchimREmitData?.links?.find((link) => link.rel === "next")?.href;
  }, [searchimREmitData]);

  // console.log("previousPaymentId", previousPaymentId);
  // console.log("nextPaymentId", nextPaymentId);

  const customerExternalId = searchimREmitData?.externalId;
  const updatedCustomer = imREmitCustomersContent.find(
    (customer) => customer.externalId === customerExternalId
  );

  if (!imREmitStoreCustomer && updatedCustomer) {
    updateImRemitStoreCustomer(updatedCustomer);
  }

  if (searchimREmitQuery.isError) {
    throw new Error("Error fetching payment details");
  }

  return (
    <section className="mb-2 flex w-full flex-col items-start justify-between gap-2 lg:flex-row lg:items-center">
      <div className="flex w-full flex-col gap-2 lg:w-fit lg:flex-row">
        <Link
          to="/app/imremit/remittance-management/$paymentId/view/$list_type"
          params={{
            paymentId: previousPaymentId || "",
            list_type: list_type || "",
          }}
        >
          <Button
            size="sm"
            className="w-full gap-4 lg:w-60"
            disabled={!previousPaymentId || searchimREmitQuery.isPending}
          >
            {searchimREmitQuery.isPending && customerExternalId ? (
              <Spinner size="xs" />
            ) : (
              <ArrowLeftIcon className="size-4" />
            )}
            Previous Payment
          </Button>
        </Link>
        <Link
          to="/app/imremit/remittance-management/$paymentId/view/$list_type"
          params={{
            paymentId: nextPaymentId || "",
            list_type: list_type || "",
          }}
        >
          <Button
            size="sm"
            className="w-full gap-4 lg:w-60"
            disabled={!nextPaymentId || searchimREmitQuery.isPending}
          >
            Next Payment
            {searchimREmitQuery.isPending && customerExternalId ? (
              <Spinner size="xs" />
            ) : (
              <ArrowRightIcon className="size-4" />
            )}
          </Button>
        </Link>
      </div>
      <Link
        to="/app/imremit/remittance-management"
        className={cn(
          buttonVariants({size: "sm", variant: "secondary"}),
          "w-full gap-4 lg:w-60"
        )}
      >
        <span className="sr-only">Remittance List</span>
        Remittance List
        <ArrowRightIcon className="size-4" />
      </Link>
    </section>
  );
}
