import {create} from "zustand";

import {type DocumentTypesType} from "@/modules/imremit";

// Defining the shape of the state object for the DocumentTypeStore
// In this case, the state will consist of an array of Document objects
interface DocumentTypeStoreState {
  storeDocumentType: DocumentTypesType | null;
}

// Defining the actions that can be performed on the DocumentTypeStore state
// In this case, there's only one action, updatestoreDocumentType, which takes an array of Document objects
interface CustomerStoreActions {
  updatestoreDocumentType: (
    storeDocumentType: DocumentTypeStoreState["storeDocumentType"]
  ) => void;
}

// Creating the Zustand store using the create function
// The store is named useMappingDocumentTypeStore, and it uses the types defined above for its state and actions
// The state begins with an empty storeDocumentType array
// The updatestoreDocumentType action uses Zustand's set function to update the state
export const useMappingDocumentTypeStore = create<
  DocumentTypeStoreState & CustomerStoreActions
>((set) => ({
  // Initial state of the store. An empty array of storeDocumentType.
  storeDocumentType: null,

  // Action to update the storeDocumentType array in the store.
  // This function takes a new storeDocumentType array and uses the set function to update the state.
  updatestoreDocumentType: (storeDocumentType) => {
    set(() => ({storeDocumentType}));
  },
}));
