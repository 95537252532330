import {format} from "date-fns";
import {CalendarIcon, PlusIcon, Trash2Icon} from "lucide-react";
import {useFieldArray, useFormContext} from "react-hook-form";

import {Button} from "@/components/ui/button";
import {Calendar} from "@/components/ui/calendar";
import {Card, CardContent} from "@/components/ui/card";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Label} from "@/components/ui/label";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {Separator} from "@/components/ui/separator";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {Textarea} from "@/components/ui/textarea";
import {Heading3, Heading4} from "@/components/ui/typography";

import {cn} from "@/lib/utils";
import {generateRandomUuid} from "@/modules/imremit";

export interface FieldValueProps {
  handleInputValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputValue: number | string;
  isMutating: boolean;
}

export interface InvoicesFormRow {
  invoiceId: number;
  invoiceNumber: string;
  invoiceDate: Date;
  invoiceAmountPaid: number;
  poNumber: string;
  invoiceTotalAmount: number;
  discountAmount: number;
  adjustmentAmount: number;
  comments: string;
}

const authorizationTypes = [
  {label: "Single", value: "single"},
  {label: "Multiple", value: "multiple"},
];

interface DefineFormProps {
  isMutating?: boolean;
  allPaymentMethods?: {value: string; label: string}[];
  allSuppliers?: {value: string; label: string}[];
}

export const invoicesTableHeaders = [
  "Invoice Number",
  "Invoice Date",
  "Amount Paid (USD)",
  "PO Number",
  "Invoice Amount (USD)",
  "Discount Amount (USD)",
  "Adjustment Amount (USD)",
  "comments",
];

function handleKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
  if (event.key === "Enter") {
    event.preventDefault();
  }
}

export function handleGeneratingDecimalValue(value: string) {
  return Number(parseFloat(value).toFixed(2));
}

export function DefineForm({isMutating}: DefineFormProps) {
  return (
    <Card className="mb-4 mt-4">
      <CardContent>
        <Heading3 className="mb-4 mt-4">Define Form</Heading3>
        <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
          <PayableTotalField isMutating={isMutating} />
          <InitiatedDateField isMutating={isMutating} />
          <EndDateField isMutating={isMutating} />
          <CreatedDateField />
          {/* <PaymentGroupField allPaymentMethods={allPaymentMethods} /> */}
          <AuthorizationTypeField isMutating={isMutating} />
        </div>
        <Separator className="mb-5" />
        <Heading4 className="mb-4">Invoices</Heading4>
        <div className="w-full">
          <InvoicesTable isMutating={isMutating} />
        </div>

        <Separator className="mb-5 mt-5" />

        <Heading4 className="mb-4">Controls</Heading4>
        <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
          <OverageToleranceField isMutating={isMutating} />
          <FullfillmentToleranceField isMutating={isMutating} />
        </div>

        <Separator className="mb-5" />
        <Heading4 className="mb-2">Custom Fields</Heading4>

        <div className="mb-5">Transaction Custom Fields</div>
        <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
          <PaymentNumberField isMutating={isMutating} />
          {/* TODO: Need to figure out ITP logic on when this field comes in */}
          {/* <AccountNumberField isMutating={isMutating} /> */}
          <Udf1Field isMutating={isMutating} />
          <Udf2Field isMutating={isMutating} />
          <Udf3Field isMutating={isMutating} />
        </div>
        <div className="mb-5">Payable Custom Fields</div>
      </CardContent>
    </Card>
  );
}

function PayableTotalField({isMutating}: DefineFormProps) {
  const {register} = useFormContext();

  return (
    <FormField
      name="totalAmountSent"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="totalAmountSent" showMandatoryAsterisk>
            Payable Total (USD):
          </FormLabel>
          <FormControl>
            <Input
              type="number"
              step="0.01"
              placeholder="Enter the payable total..."
              {...register("totalAmountSent", {
                required: true,
                setValueAs: (v) =>
                  v === "" ? undefined : parseFloat(v as string),
              })}
              disabled={isMutating}
              aria-disabled={isMutating}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function OverageToleranceField({isMutating}: DefineFormProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="overageToleranceIncrement"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="overageToleranceIncrement">
            Overage Tolerance:
          </FormLabel>
          <FormControl>
            <Input
              type="number"
              //step="0.01"
              placeholder="Enter the overage tolerance..."
              {...register("overageToleranceIncrement", {
                required: true,
                setValueAs: (v) =>
                  v === "" ? undefined : parseFloat(v as string),
              })}
              disabled={isMutating}
              aria-disabled={isMutating}
            />
          </FormControl>
          <FormDescription>
            Overage Tolerance %
            <span className="block">
              Maximum Amount allowed over Payable Amount
            </span>
          </FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function FullfillmentToleranceField({isMutating}: DefineFormProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="overageToleranceDecrease"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="overageToleranceDecrease">
            Fulfillment Tolerance:
          </FormLabel>
          <FormControl>
            <Input
              type="number"
              //step="0.01"
              placeholder="Enter the overage tolerance..."
              {...register("overageToleranceDecrease", {
                required: true,
                setValueAs: (v) =>
                  v === "" ? undefined : parseFloat(v as string),
              })}
              disabled={isMutating}
              aria-disabled={isMutating}
            />
          </FormControl>
          <FormDescription>
            Fulfillment Tolerance %
            <span className="block">
              AutoReconcile begins at this Transaction Total Amount
            </span>
          </FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

// function PaymentGroupField({allPaymentMethods}: DefineFormProps) {
//   const {watch} = useFormContext();
//   const paymentMethod = watch("paymentMethodId") as string;

//   const paymentMethodName = useMemo(() => {
//     if (paymentMethod) {
//       const paymentMethodObj = allPaymentMethods?.find(
//         (item) => item.value === paymentMethod
//       );
//       return paymentMethodObj?.label;
//     }
//     return "";
//   }, [paymentMethod, allPaymentMethods]);

//   return (
//     <div className="mt-2 grid w-full items-center gap-1.5">
//       <Label htmlFor="paymentGroup">Payment Group:</Label>
//       <Input
//         disabled
//         type="text"
//         name="paymentGroup"
//         id="paymentGroup"
//         value={paymentMethodName}
//       />
//     </div>
//   );
// }

function InitiatedDateField({isMutating}: DefineFormProps) {
  const {control} = useFormContext();
  return (
    <FormField
      control={control}
      name="initiatedDate"
      render={({field}) => (
        <FormItem className="mt-3 flex flex-col">
          <FormLabel showMandatoryAsterisk>Begin Date:</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="input"
                  className={cn(
                    "w-full pl-3 text-left font-normal",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value ? (
                    format(field.value as Date, "PPP")
                  ) : (
                    <span>Pick a date</span>
                  )}
                  <CalendarIcon className="ml-auto size-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent
              className="max-h-[var(--radix-popover-content-available-height)] w-[var(--radix-popover-trigger-width)] p-0"
              align="start"
            >
              <Calendar
                mode="single"
                selected={field.value as Date}
                disabled={(date) =>
                  date > new Date() || date < new Date("1900-01-01")
                }
                initialFocus
                onSelect={field.onChange}
              />
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function EndDateField({isMutating}: DefineFormProps) {
  const {control, watch} = useFormContext();
  return (
    <FormField
      control={control}
      name="endDate"
      render={({field}) => (
        <FormItem className="mt-3 flex flex-col">
          <FormLabel showMandatoryAsterisk>End Date:</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="input"
                  className={cn(
                    "w-full pl-3 text-left font-normal",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value ? (
                    format(field.value as Date, "PPP")
                  ) : (
                    <span>Pick a date</span>
                  )}
                  <CalendarIcon className="ml-auto size-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent
              className="max-h-[var(--radix-popover-content-available-height)] w-[var(--radix-popover-trigger-width)] p-0"
              align="start"
            >
              <Calendar
                mode="single"
                selected={field.value as Date}
                disabled={(date) =>
                  date < new Date() ||
                  date <= new Date(watch("initiatedDate") as Date)
                }
                initialFocus
                onSelect={field.onChange}
              />
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CreatedDateField() {
  return (
    <div className="mt-2 grid w-full items-center gap-1.5">
      <Label htmlFor="createDate">Create Date:</Label>
      <Input
        type="text"
        name="createDate"
        id="createDate"
        value={format(new Date(), "PPP")}
        disabled
      />
    </div>
  );
}

function AuthorizationTypeField({isMutating}: DefineFormProps) {
  const {
    setValue,
    formState: {errors},
  } = useFormContext();

  return (
    <div className="mt-2 grid w-full items-center gap-1.5">
      <Label
        htmlFor="singleTranExactAuth"
        className={
          errors.singleTranExactAuth ? "text-destructive-foreground" : ""
        }
        showMandatoryAsterisk
      >
        Authorization Type:
      </Label>
      <Select
        disabled={isMutating}
        aria-disabled={isMutating}
        onValueChange={(value) => {
          value === "single"
            ? setValue("singleTranExactAuth", true)
            : setValue("singleTranExactAuth", false);
        }}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select Authorization Type..." />
        </SelectTrigger>
        <SelectContent>
          {authorizationTypes.map((item) => (
            <SelectItem key={item.value} value={item.value}>
              {item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {errors.singleTranExactAuth && (
        <p className="text-sm font-medium text-destructive-foreground">
          Required
        </p>
      )}
    </div>
  );
}

function PaymentNumberField({isMutating}: DefineFormProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="paymentNumber"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="paymentNumber" showMandatoryAsterisk>
            Payment Number:
          </FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="text"
              placeholder="Enter the payment number..."
              {...register("paymentNumber")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

// function AccountNumberField({isMutating}: DefineFormProps) {
//   const {register} = useFormContext();
//   return (
//     <FormField
//       name="accountNumber"
//       render={() => (
//         <FormItem>
//           <FormLabel htmlFor="accountNumber">Account Number:</FormLabel>
//           <FormControl>
//             <Input
//               type="number" //step="0.01"
//               disabled={isMutating}
//               aria-disabled={isMutating}
//               placeholder="Enter the account number..."
//               {...register("accountNumber")}
//             />
//           </FormControl>
//           <FormMessage />
//         </FormItem>
//       )}
//     />
//   );
// }

function Udf1Field({isMutating}: DefineFormProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="udf1"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="udf1">UDF1:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the UDF1..."
              {...register("udf1")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function Udf2Field({isMutating}: DefineFormProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="udf2"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="udf2">UDF2:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the UDF2..."
              {...register("udf2")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function Udf3Field({isMutating}: DefineFormProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="udf3"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="udf3">UDF3:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the UDF3..."
              {...register("udf3")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function InvoicesTable({isMutating}: DefineFormProps) {
  const {
    control,
    register,
    watch,
    formState: {errors},
    setValue,
  } = useFormContext();
  const {fields, append, remove} = useFieldArray({
    control,
    name: "invoiceDetailRequests",
  });

  function handleInvoiceTotalCalculation(index: number) {
    const amountPaid = parseFloat(
      watch(
        `invoiceDetailRequests.${index.toString()}.invoiceAmountPaid`
      ) as string
    );
    const discountAmount = parseFloat(
      watch(
        `invoiceDetailRequests.${index.toString()}.discountAmount`
      ) as string
    );
    const adjustmentAmount = parseFloat(
      watch(
        `invoiceDetailRequests.${index.toString()}.adjustmentAmount`
      ) as string
    );
    const totalAmount = amountPaid + discountAmount + adjustmentAmount;
    setValue(
      `invoiceDetailRequests.${index.toString()}.invoiceTotalAmount`,
      handleGeneratingDecimalValue(totalAmount.toString())
    );
    const invoicesData = watch("invoiceDetailRequests") as InvoicesFormRow[];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (invoicesData) {
      const invoicesTotalAmountSentValue: number = invoicesData.reduce(
        (acc, curr) => acc + curr.invoiceAmountPaid,
        0
      );

      setValue(
        "totalAmountSent",
        handleGeneratingDecimalValue(invoicesTotalAmountSentValue.toString())
      );
    }
  }

  return (
    <>
      <Card>
        <CardContent>
          <Table className="mt-5">
            <TableCaption>
              {fields.length == 0 && <span> No Data.</span>}
            </TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="relative" showMandatoryAsterisk>
                  Invoice Number
                </TableHead>
                <TableHead showMandatoryAsterisk>Invoice Date</TableHead>
                <TableHead className="relative" showMandatoryAsterisk>
                  Amount Paid (USD)
                </TableHead>
                <TableHead className="relative">PO Number</TableHead>
                <TableHead className="relative" showMandatoryAsterisk>
                  Invoice Amount (USD)
                </TableHead>
                <TableHead className="relative">Discount Amount</TableHead>
                <TableHead className="relative">Adjustment Amount</TableHead>
                <TableHead className="relative" showMandatoryAsterisk>
                  Comments
                </TableHead>
                <TableHead className="text-right">Action</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {fields.map((field, index) => (
                <TableRow key={field.id}>
                  <TableCell className="w-80 font-medium">
                    <FormField
                      control={control}
                      name={`invoiceDetailRequests.${index.toString()}.invoiceNumber`}
                      render={() => (
                        <FormItem className="flex flex-col ">
                          <FormControl>
                            <Input
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              onKeyDown={handleKeyDown}
                              {...register(
                                `invoiceDetailRequests.${index.toString()}.invoiceNumber`,
                                {
                                  required: true,
                                }
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </TableCell>

                  <TableCell className="w-[32rem] font-medium">
                    <FormField
                      control={control}
                      name={`invoiceDetailRequests.${index.toString()}.invoiceDate`}
                      render={({field}) => (
                        <FormItem className="flex flex-col ">
                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant="input"
                                  disabled={isMutating}
                                  aria-disabled={isMutating}
                                  className={cn(
                                    "w-full pl-3 text-left font-normal",
                                    !field.value && "text-accent-foreground"
                                  )}
                                >
                                  {field.value ? (
                                    format(field.value as Date, "PPP")
                                  ) : (
                                    <span>Pick a date</span>
                                  )}
                                  <CalendarIcon className="ml-auto size-4 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent
                              className="w-auto p-0"
                              align="start"
                            >
                              <Calendar
                                mode="single"
                                selected={field.value as Date}
                                disabled={(date) =>
                                  date > new Date() ||
                                  date < new Date("1900-01-01")
                                }
                                initialFocus
                                onSelect={field.onChange}
                              />
                            </PopoverContent>
                          </Popover>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </TableCell>

                  <TableCell className="w-80 font-medium">
                    <FormField
                      control={control}
                      name={`invoiceDetailRequests.${index.toString()}.invoiceAmountPaid`}
                      render={() => (
                        <FormItem className="flex flex-col ">
                          <FormControl>
                            <Input
                              type="number"
                              step="0.01"
                              onKeyDown={handleKeyDown}
                              {...register(
                                `invoiceDetailRequests.${index.toString()}.invoiceAmountPaid`,
                                {
                                  required: true,
                                  setValueAs: (v) =>
                                    v === ""
                                      ? undefined
                                      : handleGeneratingDecimalValue(
                                          v as string
                                        ),
                                }
                              )}
                              onMouseOut={() => {
                                handleInvoiceTotalCalculation(index);
                              }}
                              onFocus={() => {
                                handleInvoiceTotalCalculation(index);
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </TableCell>

                  <TableCell className="w-80 font-medium">
                    <FormField
                      control={control}
                      name={`invoiceDetailRequests.${index.toString()}.poNumber`}
                      render={() => (
                        <FormItem className="flex flex-col ">
                          <FormControl>
                            <Input
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              onKeyDown={handleKeyDown}
                              {...register(
                                `invoiceDetailRequests.${index.toString()}.poNumber`,
                                {
                                  required: true,
                                }
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </TableCell>

                  <TableCell className="w-80 font-medium">
                    <FormField
                      control={control}
                      name={`invoiceDetailRequests.${index.toString()}.invoiceTotalAmount`}
                      render={() => (
                        <FormItem className="flex flex-col ">
                          <FormControl>
                            <Input
                              type="number"
                              step="0.01"
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              onKeyDown={handleKeyDown}
                              {...register(
                                `invoiceDetailRequests.${index.toString()}.invoiceTotalAmount`,
                                {
                                  required: true,
                                  setValueAs: (v) =>
                                    v === ""
                                      ? undefined
                                      : handleGeneratingDecimalValue(
                                          v as string
                                        ),
                                }
                              )}
                              onMouseOut={() => {
                                handleInvoiceTotalCalculation(index);
                              }}
                              onFocus={() => {
                                handleInvoiceTotalCalculation(index);
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </TableCell>

                  <TableCell className="w-80 font-medium">
                    <FormField
                      control={control}
                      name={`invoiceDetailRequests.${index.toString()}.discountAmount`}
                      render={() => (
                        <FormItem className="flex flex-col ">
                          <FormControl>
                            <Input
                              type="number"
                              step="0.01"
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              onKeyDown={handleKeyDown}
                              {...register(
                                `invoiceDetailRequests.${index.toString()}.discountAmount`,
                                {
                                  setValueAs: (v) =>
                                    v === ""
                                      ? undefined
                                      : handleGeneratingDecimalValue(
                                          v as string
                                        ),
                                  required: true,
                                }
                              )}
                              onMouseOut={() => {
                                handleInvoiceTotalCalculation(index);
                              }}
                              onFocus={() => {
                                handleInvoiceTotalCalculation(index);
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </TableCell>

                  <TableCell className="w-80 font-medium">
                    <FormField
                      control={control}
                      name={`invoiceDetailRequests.${index.toString()}.adjustmentAmount`}
                      render={() => (
                        <FormItem className="flex flex-col ">
                          <FormControl>
                            <Input
                              type="number"
                              step="0.01"
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              onKeyDown={handleKeyDown}
                              {...register(
                                `invoiceDetailRequests.${index.toString()}.adjustmentAmount`,

                                {
                                  setValueAs: (v) =>
                                    v === ""
                                      ? undefined
                                      : handleGeneratingDecimalValue(
                                          v as string
                                        ),
                                  required: true,
                                }
                              )}
                              onMouseOut={() => {
                                handleInvoiceTotalCalculation(index);
                              }}
                              onFocus={() => {
                                handleInvoiceTotalCalculation(index);
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </TableCell>

                  <TableCell className="w-80 font-medium">
                    <FormField
                      control={control}
                      name={`invoiceDetailRequests.${index.toString()}.comments`}
                      render={() => (
                        <FormItem>
                          <FormControl>
                            <Textarea
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              {...register(
                                `invoiceDetailRequests.${index.toString()}.comments`,
                                {
                                  required: true,
                                }
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </TableCell>

                  <TableCell className="text-right">
                    <Button
                      className="flex items-center gap-2 px-3"
                      variant="outline"
                      size="xs"
                      onClick={() => {
                        handleInvoiceTotalCalculation(index);
                        remove(index);
                      }}
                    >
                      <Trash2Icon className="size-4" />
                      <span className="sr-only">Delete</span>
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {errors.invoiceDetailRequests && (
            <p className="text-sm font-medium text-destructive-foreground">
              Please add at least 1 invoice
            </p>
          )}
          <div className="mb-5 mt-5 flex justify-end">
            <Button
              type="button"
              className="col-span-1 col-end-8 mt-5"
              disabled={isMutating}
              onClick={() => {
                append({
                  invoiceId: generateRandomUuid(),
                  invoiceNumber: "",
                  invoiceDate: new Date(),
                  invoiceAmountPaid: 0,
                  poNumber: "",
                  invoiceTotalAmount: 0,
                  discountAmount: 0,
                  adjustmentAmount: 0,
                  comments: "",
                });
              }}
            >
              <PlusIcon className="mr-2 size-4" /> Add
            </Button>
          </div>
        </CardContent>
      </Card>
    </>
  );
}
