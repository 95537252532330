import {kyApiFn} from "@/lib/ky";
import {type AddSupplierFormDTO} from "@/modules/imremit";

export const addSupplierFn = (
  addSupplierDTO: AddSupplierFormDTO,
  externalId: string
) =>
  kyApiFn(
    `api/e-payable/management/${externalId}/supplier-details/create`,
    "post",
    {
      json: addSupplierDTO,
    }
  );
