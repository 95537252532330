import {forwardRef, useCallback} from "react";

import type {VariantProps} from "class-variance-authority";
import {cva} from "class-variance-authority";
import {useDropzone, type Accept, type FileError} from "react-dropzone";

import {Paragraph} from "@/components/ui/typography";

import {cn} from "@/lib/utils";

// TODO: this is not good, please refactor this later
const uploadElementVariants = cva(
  "border-2 border-zinc-400 border-dashed rounded-md p-12 text-center cursor-pointer text-foreground bg-root font-semibold text-base outline-none transition-all hover:bg-theme/40 hover:border-theme hover:text-foreground",
  {
    variants: {
      hasFiles: {
        true: "border border-neutral-foreground text-theme hover:border-neutral-foreground hover:bg-neutral-foreground/60 hover:text-theme/80",
        false: "",
      },
      isHovered: {
        true: "border-neutral-foreground text-theme hover:border-neutral-foreground hover:bg-neutral-foreground/60 hover:text-theme/80",
        false: "",
      },
      isRejected: {
        true: "border-destructive bg-destructive text-destructive-foreground hover:border-destructive-foreground hover:bg-destructive/80 hover:text-destructive-foreground",
        false: "",
      },
    },
  }
);

type UploadElementProps = React.HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof uploadElementVariants>;

const UploadElement = forwardRef<HTMLDivElement, UploadElementProps>(
  ({className, hasFiles, isHovered, isRejected, ...props}, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          uploadElementVariants({hasFiles, isHovered, isRejected, className})
        )}
        {...props}
      />
    );
  }
);
UploadElement.displayName = "UploadElement";

// Define the UploadDropzoneProps type
interface UploadDropzoneProps {
  onDrop: (acceptedFiles: File[]) => void;
  accept?: Accept;
  minFiles?: number;
  maxFiles?: number;
  maxSize?: number;
  validator?: (file: File) => FileError | FileError[] | null;
}
// Define the UploadDropzone functional component
export const UploadDropzone: React.FC<UploadDropzoneProps> = ({
  onDrop,
  accept,
  minFiles = 0,
  maxFiles,
  maxSize,
  validator,
}) => {
  // Custom validation function for the dropzone
  const customValidator = useCallback(
    (file: File) => {
      if (validator) {
        return validator(file);
      }
      return null;
    },
    [validator]
  );

  // Get required props and state from useDropzone hook
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    onDrop,
    accept,
    minSize: minFiles,
    maxFiles,
    maxSize,
    validator: customValidator,
  });

  // Generate messages for accepted and rejected files
  const acceptedMessage = acceptedFiles.length
    ? `Accepted: ${acceptedFiles.map((file) => file.name).join(", ")}`
    : "";

  const rejectedMessage = fileRejections.length
    ? `Rejected: ${fileRejections
        .map((rejection) => rejection.file.name)
        .join(", ")}`
    : "";

  // Default message for the dropzone
  const defaultMessage =
    !maxFiles || maxFiles > 1
      ? "Drag and drop some files here, or click to select files"
      : "Drag and drop a file here, or click to select a file";

  return (
    <UploadElement
      {...getRootProps()}
      aria-label="File Dropzone"
      role="button"
      hasFiles={acceptedFiles.length > 0}
      isHovered={isDragActive}
      isRejected={isDragReject}
    >
      <input {...getInputProps()} />
      <Paragraph>
        {!acceptedMessage && !rejectedMessage && defaultMessage}
        {!rejectedMessage && acceptedMessage && acceptedMessage}
        {rejectedMessage && rejectedMessage}
      </Paragraph>
    </UploadElement>
  );
};
