import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  paymentFileMappingKeys,
  type PaymentMappingListType,
} from "@/modules/imremit";

//Defining a type based on the function `getAllPaymentFileMapping`
type QueryFnType = typeof getAllPaymentFileMapping;

//Funciton to get the Payment reports
export function getAllPaymentFileMapping() {
  // Fetch data from API using ky
  return kyApiFn<PaymentMappingListType[]>(
    `api/e-payable/admin/master-mapping/payment`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function usePaymentFileDetails(config?: QueryConfig<QueryFnType>) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: paymentFileMappingKeys.allPaymentFileMapping(),
    queryFn: () => getAllPaymentFileMapping(),
  });
}
