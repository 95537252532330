import {z} from "zod";

import {multiSelectDatumSchema} from "@/components/craft/fancy-multi-select";

export const proxyPayListSchema = z.object({
  id: z.number(),
  paymentDetailId: z.string(),
  paymentNumber: z.string(),
  customerStatus: z.string(),
  statusName: z.string(),
  description: z.string(),
  initiatedDate: z.string(),
  endDate: z.string(),
  supplierNumber: z.string(),
  supplierName: z.string(),
  totalAmountSent: z.number(),
  orgId: z.string(),
  methodName: z.string(),
  facilityName: z.string(),
  descriptionDetail: z.string(),
  amountTaken: z.number(),
  balanceAmount: z.number(),
  postingDate: z.string(),
  userId: z.string(),
  agentName: z.string(),
  accountNumber: z.string(),
  remittanceType: z.string(),
  remittanceMethod: z.string(),
});

export type ProxyPaySchemaType = z.infer<typeof proxyPayListSchema>;

export const ProxyPayDashboardSchema = z.object({
  outstandingPayByWebStatus: z.number(),
  failedPayByWebStatus: z.number(),
  inProgressPayByWebStatus: z.number(),
  outstandingPayByPhoneStatus: z.number(),
  failedPayByPhoneStatus: z.number(),
  inProgressPayByPhoneStatus: z.number(),
});

export type ProxyPayDashboardSchema = z.infer<typeof ProxyPayDashboardSchema>;

// PROXY PAY VIEW PAGE  SCHEMA
export const ProxyPayViewPageSchema = z.object({
  buyerId: z.number(),
  externalId: z.string(),
  outstandingPayByWebStatus: z.number(),
  paymentDetailId: z.string(),
  paymentNumber: z.string(),
  accountNumber: z.string(),
  customerStatus: z.string(),
  description: z.string(),
  statusName: z.string(),
  initiatedDate: z.string(),
  note: z.string(),
  endDate: z.string(),
  supplierNumber: z.string(),
  supplierName: z.string(),
  supplierId: z.number(),
  totalAmountSent: z.number(),
  amountTaken: z.number(),
  balanceAmount: z.number(),
  orgId: z.string(),
  facilityName: z.string(),
  methodName: z.string(),
  cardNumber: z.string(),
  expirationDate: z.string(),
  securityCode: z.string(),
  singleTranExactAuth: z.boolean(),
  isEditable: z.boolean(),
  commentsCount: z.number(),
  descriptionDetail: z.string(),
  paymentMode: z.string(),
  supplierUserId: z.string(),
  proxyWebUrl: z.string(),
  remittanceEmail: z.string(),
  remittanceName: z.string(),
  remittancePhoneNumber: z.string(),
  errorMessage: z.string(),
  paymentZip: z.string(),
  providerName: z.string(),
  scriptFlag: z.boolean(),
  paymentRequestPaymentMode: z.string(),
  remittanceType: z.string(),
});
export type ProxyPayViewPageSchema = z.infer<typeof ProxyPayViewPageSchema>;

// PAYMENT STATUSES SCHEMA
export const ProxyPayStatusSchema = z.object({
  paymentStatusId: z.string(),
  customerStatus: z.string(),
});
export type ProxyPayPaymentStatusesSchema = z.infer<
  typeof ProxyPayStatusSchema
>;

// COMMENTS FORM SCHEMA
export const PaymentCommentsFormSchema = z.object({
  userId: z.string(),
  comments: z
    .string()
    .min(2, {message: "comments min size is 2"})
    .refine((val) => val.trim().length > 0, {
      message: "Please ensure you have entered a comment.",
    }),
  role: z.string(),
});

// COMMENTS FORM SCHEMA - PROXY PAY MODULES
export const ProxyPayCommentsFormSchema = z.object({
  userId: z.string(),
  comments: z
    .string()
    .min(3, {message: "Comments min size is 3"})
    .max(2000, {message: "Comment must be under 2000 characters."})
    .refine((val) => val.trim().length > 0, {
      message: "Please ensure you have entered a comment.",
    }),
  role: z.string(),
  paymentStatus: z.string(),
});

// EXPORT PAYMENT COMMENTS
export const ExportPaymentDataFormSchema = z.object({
  emailId: z.string(),
  subject: z.string().min(1).max(140),
  message: z.string().min(1),
  participant: z.string(),
  customerName: z.string(),
  columnList: z.array(z.string()).nullable().optional(),
  columns: z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
      })
    )
    .optional(),
});

export type ExportPaymentDataFormDTO = z.infer<
  typeof ExportPaymentDataFormSchema
>;

export type PaymentCommentsFormDTO = z.infer<typeof PaymentCommentsFormSchema>;

// WEB PAYMENT HISTORY SCHEMA
export const WebPaymentHistorySchema = z.object({
  payByWebTransactionHistoryId: z.string(),
  paymentDetailId: z.string(),
  transactionStatus: z.string(),
  reason: z.string(),
  confirmationNumber: z.string(),
  amount: z.number(),
  transactionCreatedDate: z.string(),
  transactionUpdatedDate: z.string(),
  captchaFailedCount: z.number(),
  createTimestamp: z.string(),
  lastEditTimestamp: z.string(),
});

export type WebPaymentHistoryType = z.infer<typeof WebPaymentHistorySchema>;

export const proxyPayIMRemitPremiumTableColumnsSchema = z.object({
  orgId: z.boolean(),
  remittanceMethod: z.boolean(),
  supplierName: z.boolean(),
  supplierNumber: z.boolean(),
  facilityName: z.boolean(),
  paymentNumber: z.boolean(),
  totalAmountSent: z.boolean(),
  postingDate: z.boolean(),
  userId: z.boolean(),
  description: z.boolean(),
  statusName: z.boolean(),
});
export type ProxyPayIMRemitPremiumTableColumnsType = z.infer<
  typeof proxyPayIMRemitPremiumTableColumnsSchema
>;

//Dashboard Card Filter types `proxyDashbaordFilterSchema`
export const proxyDashbaordFilterSchema = z.object({
  statusName: z.string(),
  remitanceMethod: z.string(),
});

export type proxyDashbaordFilterType = z.infer<
  typeof proxyDashbaordFilterSchema
>;

export const ProxypayFormSchema = z.object({
  paymentStatus: z.array(multiSelectDatumSchema),
  customer: z.string(),
  supplierId: z.string(),
  orgId: z.string().optional(),
  startDate: z
    .date()
    .min(new Date("1970-01-01"), {
      message: "Date is out of range",
    })
    .optional(),
  endDate: z
    .date()
    .min(new Date("1970-01-01"), {
      message: "Date is out of range",
    })
    .optional(),
  paymentNumber: z.string().optional(),
  paymentMode: z.string(),
});

export type ProxypayFormType = z.infer<typeof ProxypayFormSchema>;

export const ProxypayCriteriaSchema = z.object({
  customer: z.string().nullable(),
  supplierId: z.string().nullable(),
  orgId: z.string().nullable(),
  fromDate: z.string().nullable(),
  toDate: z.string().nullable(),
  paymentNumber: z.string().nullable(),
  paymentMode: z.string().nullable(),
  statusId: z.array(z.number()).nullable(),
});

export type ProxypayCriteriaType = z.infer<typeof ProxypayCriteriaSchema>;
