import {z} from "zod";

export const statementReconGetGroupSchema = z.object({
  im_sr_supplier_group_id: z.string(),
  im_sr_supplier_group_name: z.string(),
  supplier_ids: z.string(),
  created_timestamp: z.string(),
});

export type StatementReconGetGroupType = z.infer<
  typeof statementReconGetGroupSchema
>;

export const statementReconAddGroupSchema = z.object({
  im_sr_supplier_group_name: z.string(),
  supplier_ids: z.array(z.string()),
  buyer_external_id: z.string(),
});

export type StatementReconAddGroupType = z.infer<
  typeof statementReconAddGroupSchema
>;
