import {kyApiFn} from "@/lib/ky";
import {type SupplierScriptItem} from "@/modules/imremit";

export const editSupplierScriptFn = (
  editSupplierScriptDTO: SupplierScriptItem[],
  supplierId: string,
  captchaTimer: string,
  paymentTimer: string
) => {
  // Convert empty strings to nulls in the editSupplierScriptDTO
  const modifiedDTO = editSupplierScriptDTO.map((item) => {
    const modifiedItem: Record<string, string | number | null | boolean> = {
      ...item,
    };
    for (const key in modifiedItem) {
      if (Object.prototype.hasOwnProperty.call(modifiedItem, key)) {
        if (modifiedItem[key] === "") {
          modifiedItem[key] = null;
        }
      }
    }
    return modifiedItem;
  });

  return kyApiFn(
    `api/e-payable/management/${supplierId}/supplier-script/create?&captchaTimer=${captchaTimer}&paymentTimer=${paymentTimer}`,
    "put",
    {
      json: modifiedDTO,
    }
  );
};

export const deleteSupplierScriptSequenceFn = (deleteScriptData: string[]) =>
  kyApiFn(`api/e-payable/management/supplier-scripts/delete`, "delete", {
    json: deleteScriptData,
  });
