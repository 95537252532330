import {kyApiFn} from "@/lib/ky";

export const deleteCustomerPaymentMethodFn = (
  externalId: string,
  paymentMethodId: string
) =>
  kyApiFn(
    `api/e-payable/management/${externalId}/methods/${paymentMethodId}`,
    "delete"
  );
