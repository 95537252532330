import {type Table} from "@tanstack/react-table";

import {DataTableViewOptions} from "@/components/craft/data-table";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

/**
 * Interface: DataTableToolbarProps
 * Defines the shape of the props for the DataTableToolbar component.
 * It expects the `table` object as a prop.
 * This is used in the InvoicesDataTable component.
 *
 * @param {Table<TData>} table - The table object
 * @param {() => void} handlePrint - The function to handle print
 * @returns {JSX.Element} - The JSX element
 */
interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  handlePrint: () => void;
}

/**
 * This is the main component, "PaymentManagementCardFilterDataTableToolbar". It expects the `table` object as a prop.
 *
 * @param {Table<TData>} table - The table object
 * @param {() => void} handlePrint - The function to handle print
 * @returns {JSX.Element} - The JSX element
 */
export function PaymentManagementCardFilterDataTableToolbar<TData>({
  table,
}: DataTableToolbarProps<TData>) {
  // Return the form elements and fields as JSX. It uses Form, FormItem, FormControl, and FormMessage
  // components to structure and style the form.

  //Columns other names
  const columnsOtherName = [
    {key: "orgId", value: "Org Id"},
    {key: "supplierName", value: "Supplier Name"},
    {key: "supplierNumber", value: "Supplier ID"},
    {key: "facilityName", value: "Facility Name"},
    {key: "paymentNumber", value: "Payment Number"},
    {key: "accountNumber", value: "Account Number"},
    {key: "totalAmountSent", value: "Sent Amount"},
    {key: "initiatedDate", value: "Sent Date"},
    {key: "customerStatus", value: "Status"},
    {key: "statusName", value: "Status Name"},
    {key: "description", value: "Status Description"},
    {key: "endDate", value: "End Date"},
    {key: "methodName", value: "Method Name"},
    {key: "descriptionDetail", value: "Description Detail"},
    {key: "amountTaken", value: "Amount Taken"},
    {key: "balanceAmount", value: "Balance Amount"},
    {key: "userId", value: "Agent Name"},
    {key: "remittanceMethod", value: "Remittance Method"},
    {key: "buyerAlias", value: "Customer Alias"},
  ];

  return (
    <>
      <div className="flex justify-between gap-4">
        <div className="flex flex-wrap items-end gap-2">
          <RowsPreferenceSelect />
        </div>

        <div className="flex flex-col justify-center gap-2 md:flex-row md:items-center">
          {/* <Button
            className="border-dashed border-success-foreground bg-success text-success-foreground hover:border-success-foreground hover:bg-success-foreground/40 hover:text-success-foreground active:bg-success-foreground/60 active:text-success-foreground"
            variant="outline"
            size="sm"
            type="button"
            onClick={handlePrint}
          >
            <span className="sr-only">Print table</span>
            <PrinterIcon className="mr-2 size-4" />
            Print
          </Button> */}
          {/* <Button
            className="border-dashed border-neutral-foreground bg-neutral text-neutral-foreground hover:border-neutral-foreground hover:bg-neutral-foreground/40 hover:text-neutral-foreground active:bg-neutral-foreground/60 active:text-neutral-foreground"
            variant="outline"
            size="sm"
            type="button"
          >
            <span className="sr-only">Export data</span>
            <FolderOutputIcon className="mr-2 size-4" />
            Export
          </Button> */}
          <DataTableViewOptions
            table={table}
            columnsOtherName={columnsOtherName}
          />
        </div>
      </div>
    </>
  );
}
