import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {imREmitQueryKeys, type ImREmitPaymentType} from "@/modules/imremit";

// Defining a type based on the function `searchImREmits`
type QueryFnType = typeof searchImREmits;

// Function to search imremits
export function searchImREmits(
  externalId?: string,
  searchQueryString?: string
) {
  // Fetch imremits from API using ky
  // TODO: create search params instead of string
  return kyApiFn<ImREmitPaymentType[]>(
    // NOTE: need to change customerIdQuery to externalId (externalId)
    `api/e-payable/management/${externalId}/payment-details?${searchQueryString}`,
    "get"
  );
  // TODO: validate data
  // return imremitSchema.parse(data);
}

// Custom hook to fetch and cache imremits data using react-query
export function useSearchimREmits(
  externalId?: string, // The search query string
  searchQueryString?: string, // The search query string
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: imREmitQueryKeys.bySearch(externalId, searchQueryString), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => searchImREmits(externalId, searchQueryString), // Setting the function to be used for fetching data
    // TODO: add onSuccess and onError handlers
    enabled: !!externalId && !!searchQueryString, // Only fetch data if externalId is not empty
  });
}
