import {kyApiFn} from "@/lib/ky";
import {type UpdatePaymentMethodFormDTO} from "@/modules/admin";

export const updateCustomerPaymentMethodFn = (
  updateCustomerPaymentMethodDTO: UpdatePaymentMethodFormDTO,
  externalId: string,
  paymentMethodId: string
) =>
  kyApiFn(
    `api/e-payable/management/${externalId}/methods/${paymentMethodId}`,
    "put",
    {
      json: updateCustomerPaymentMethodDTO,
    }
  );
