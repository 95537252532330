import {useEffect} from "react";

import {Outlet} from "@tanstack/react-router";

import {Heading1, Paragraph} from "@/components/ui/typography";
import {ReturnToTop} from "@/components/craft/return-to-top";

import {ProfileRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";

export function ProfileOutlet() {
  const {setProfileRoute} = useRoutesStore();

  useEffect(() => {
    setProfileRoute(ProfileRoutesSchema.Values["/app/profile"]);
  }, [setProfileRoute]);

  return (
    <article role="region" aria-roledescription="This is the profile page.">
      <section
        className="flex flex-row items-start justify-between"
        role="presentation"
      >
        <div className="mb-4 flex flex-col">
          <Heading1 className="self-start">Profile</Heading1>
          <Paragraph>
            This is the profile page. View your profile here.
          </Paragraph>
        </div>
      </section>

      <Outlet />

      <ReturnToTop />
    </article>
  );
}
