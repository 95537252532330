import {useQuery} from "@tanstack/react-query";

import {keycloakKyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  keycloakUsersByRoleQueryKeys,
  type KeycloakUserType,
} from "@/modules/admin";

// Defining a type based on the function `getUsersByRole`
type QueryFnType = typeof getUsersByRole;

// Function to get all realm users
export function getUsersByRole(roleRroupId: string) {
  const realm =
    (import.meta.env.VITE_KEYCLOAK_REALM as string | undefined) ?? "dev";
  return keycloakKyApiFn<KeycloakUserType[]>(
    `admin/realms/${realm}/groups/${roleRroupId}/members`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useGetUsersByRoleGroup(
  roleRroupId: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: keycloakUsersByRoleQueryKeys.roleRroupId(roleRroupId),
    queryFn: () => getUsersByRole(roleRroupId),
    enabled: !!roleRroupId,
  });
}
