import {z} from "zod";

export const CommentSchema = z.object({
  commentId: z.number(),
  dateTime: z.date(),
  userId: z.string(),
  comments: z.string(),
  failedCount: z.number(),
  markAsRead: z.boolean(),
});

export type CommentType = z.infer<typeof CommentSchema>;
