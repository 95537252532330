import {useEffect, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate, useParams} from "@tanstack/react-router";
// import {cva} from "class-variance-authority";
import {format} from "date-fns";
import {
  // BadgeInfo,
  CalendarIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronsUpDownIcon,
  SaveAllIcon,
  XCircleIcon,
} from "lucide-react";
import {useForm, useFormContext} from "react-hook-form";
import type * as z from "zod";

import {Button, buttonVariants} from "@/components/ui/button";
import {Calendar} from "@/components/ui/calendar";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Label} from "@/components/ui/label";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {RadioGroup, RadioGroupItem} from "@/components/ui/radio-group";
import {Separator} from "@/components/ui/separator";
import {Spinner} from "@/components/ui/spinner";
import {Switch} from "@/components/ui/switch";
import {Textarea} from "@/components/ui/textarea";
// import {
//   Tooltip,
//   TooltipArrow,
//   TooltipContent,
//   TooltipProvider,
//   TooltipTrigger,
// } from "@/components/ui/tooltip";
import {Heading3, Heading4} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {PasswordInput} from "@/components/craft/password-input";

import {cn} from "@/lib/utils";
import {useKeyCloakInstanceStore} from "@/modules/auth";
import {
  CommonForm,
  EditSupplierEnrolledFormLiteSchema,
  editSupplierEnrolledLiteFn,
  flagResetFn,
  type SupplierAPIMutatingProps,
} from "@/modules/imremit";
import {useGetSuppliersDetailsLite} from "@/modules/imremit-lite";

import {
  countrySupplierSelectOptions,
  provinceOptionsCanada,
  stateOptionsUSA,
} from "@/utils/constants";

const remittanceMethods = [
  {label: "Pay By Web", value: "Pay By Web"},
  {label: "Pay By Phone", value: "Pay By Phone"},
  {label: "Pay By Email", value: "Pay By Email"},
] as const;

export function EnrolledForm({children}: {children: React.ReactNode}) {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;
  const [bufferDisabled, setBufferDisabled] = useState(false);
  const changedBy = parsedUserInfo?.sub || "";
  const navigate = useNavigate();
  const {supplierId} = useParams({
    from: "/app/imremit-lite/supplier-management/$supplierId/edit",
  });

  // let email: string = "";

  // !parsedUserInfo
  //   ? (email = FallbackMessages.NOT_APPLICABLE)
  //   : (email = parsedUserInfo.email as string);

  const searchSupplierIDQuery = useGetSuppliersDetailsLite(Number(supplierId));

  const supplierManagementEnrolledFormMethods = useForm<
    z.infer<typeof EditSupplierEnrolledFormLiteSchema>
  >({
    defaultValues: {
      enrolled: true,
      changedBy: changedBy,
      saveAndContinue: true,
      paymentRestrictions: false,
      supplierSingleUseFlag: true,
      supplierCardOnFileFlag: false,
      supplierPaymentCounterFlag: false,
      universalAcceptor: false,
      doesSupplierTakeFees: false,
      scriptFlag: false,
      appvanceProcessingFlag: false,
      supplierBufferLimitAmount: 0,
      zip: null,
      phoneNumber: null,
      contactEmail: "",
      // maxAmountPerTransaction: 0.1,
      // minAmountPerTransaction: 0.1,
      // amountTransactionLimitPerDay: 1.0,
      // noOfTransactionPerDay: 1,
      enrolledDate: new Date(),
      // supplierContactName: changedBy,
      userId: "",
      password: "",
      contactPhone: null,
      country: "US",
    },
    mode: "onChange",
    resolver: zodResolver(EditSupplierEnrolledFormLiteSchema),
  });

  const {
    // formState: {errors},
    watch,
  } = supplierManagementEnrolledFormMethods;

  const remittanceMethod = watch("paymentMode");
  const paymentRestrictionsChecked = watch("paymentRestrictions");
  // const supplierCardOnFileFlag = watch("supplierCardOnFileFlag") as boolean;
  // const universalAcceptor = watch("universalAcceptor");

  // console.log("errors:", errors);
  useEffect(() => {
    if (
      searchSupplierIDQuery.data?.content.onboardStatus &&
      searchSupplierIDQuery.data.content.supplierCardOnFileFlag
    ) {
      if (
        searchSupplierIDQuery.data.content.onboardStatus.toUpperCase() ===
          "ACTIVE" &&
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        searchSupplierIDQuery.data?.content.supplierCardOnFileFlag
      ) {
        setBufferDisabled(true);
      }
    }
    if (searchSupplierIDQuery.data?.content.supplierName) {
      supplierManagementEnrolledFormMethods.setValue(
        "supplierName",
        searchSupplierIDQuery.data.content.supplierName
      );
    }

    if (searchSupplierIDQuery.data?.content.supplierNumber) {
      supplierManagementEnrolledFormMethods.setValue(
        "supplierNumber",
        searchSupplierIDQuery.data.content.supplierNumber
      );
    }

    if (searchSupplierIDQuery.data?.content.supplierEmail) {
      supplierManagementEnrolledFormMethods.setValue(
        "supplierEmail",
        searchSupplierIDQuery.data.content.supplierEmail
      );
    }
    if (searchSupplierIDQuery.data?.content.phoneNumber) {
      supplierManagementEnrolledFormMethods.setValue(
        "phoneNumber",
        searchSupplierIDQuery.data.content.phoneNumber
      );
    }
    if (searchSupplierIDQuery.data?.content.enrolled) {
      supplierManagementEnrolledFormMethods.setValue(
        "enrolled",
        searchSupplierIDQuery.data.content.enrolled
      );
    }
    if (searchSupplierIDQuery.data?.content.enrolledDate) {
      supplierManagementEnrolledFormMethods.setValue(
        "enrolledDate",
        new Date(searchSupplierIDQuery.data.content.enrolledDate)
      );
    }
    if (searchSupplierIDQuery.data?.content.supplierSingleUseFlag) {
      supplierManagementEnrolledFormMethods.setValue(
        "supplierSingleUseFlag",
        searchSupplierIDQuery.data.content.supplierSingleUseFlag
      );
    }
    if (searchSupplierIDQuery.data?.content.supplierCardOnFileFlag) {
      supplierManagementEnrolledFormMethods.setValue(
        "supplierCardOnFileFlag",
        searchSupplierIDQuery.data.content.supplierCardOnFileFlag
      );
    }
    if (searchSupplierIDQuery.data?.content.supplierPaymentCounterFlag) {
      supplierManagementEnrolledFormMethods.setValue(
        "supplierPaymentCounterFlag",
        searchSupplierIDQuery.data.content.supplierPaymentCounterFlag
      );
    }
    if (searchSupplierIDQuery.data?.content.supplierBufferLimitAmount) {
      supplierManagementEnrolledFormMethods.setValue(
        "supplierBufferLimitAmount",
        searchSupplierIDQuery.data.content.supplierBufferLimitAmount
      );
    }

    if (searchSupplierIDQuery.data?.content.scriptFlag) {
      supplierManagementEnrolledFormMethods.setValue(
        "scriptFlag",
        searchSupplierIDQuery.data.content.scriptFlag
      );
    }
    if (searchSupplierIDQuery.data?.content.proxyPayUrl) {
      supplierManagementEnrolledFormMethods.setValue(
        "proxyPayUrl",
        searchSupplierIDQuery.data.content.proxyPayUrl
      );
    }

    if (searchSupplierIDQuery.data?.content.userId) {
      supplierManagementEnrolledFormMethods.setValue(
        "userId",
        searchSupplierIDQuery.data.content.userId
      );
    }
    if (searchSupplierIDQuery.data?.content.password) {
      supplierManagementEnrolledFormMethods.setValue(
        "password",
        searchSupplierIDQuery.data.content.password
      );
    }

    if (searchSupplierIDQuery.data?.content.clientKey) {
      supplierManagementEnrolledFormMethods.setValue(
        "clientKey",
        searchSupplierIDQuery.data.content.clientKey
      );
    }
    if (searchSupplierIDQuery.data?.content.websiteKey) {
      supplierManagementEnrolledFormMethods.setValue(
        "websiteKey",
        searchSupplierIDQuery.data.content.websiteKey
      );
    }
    if (searchSupplierIDQuery.data?.content.contactName) {
      supplierManagementEnrolledFormMethods.setValue(
        "remittanceName",
        searchSupplierIDQuery.data.content.contactName
      );
    }
    if (searchSupplierIDQuery.data?.content.remittancePhoneNumber) {
      supplierManagementEnrolledFormMethods.setValue(
        "remittancePhoneNumber",
        searchSupplierIDQuery.data.content.remittancePhoneNumber
      );
    }
    if (searchSupplierIDQuery.data?.content.paymentMode) {
      supplierManagementEnrolledFormMethods.setValue(
        "paymentMode",
        searchSupplierIDQuery.data.content.paymentMode
      );
    }
    if (searchSupplierIDQuery.data?.content.paymentRestrictions) {
      supplierManagementEnrolledFormMethods.setValue(
        "paymentRestrictions",
        searchSupplierIDQuery.data.content.paymentRestrictions
      );
    }
    if (searchSupplierIDQuery.data?.content.minAmountPerTransaction) {
      supplierManagementEnrolledFormMethods.setValue(
        "minAmountPerTransaction",
        searchSupplierIDQuery.data.content.minAmountPerTransaction
      );
    }
    if (searchSupplierIDQuery.data?.content.maxAmountPerTransaction) {
      supplierManagementEnrolledFormMethods.setValue(
        "maxAmountPerTransaction",
        searchSupplierIDQuery.data.content.maxAmountPerTransaction
      );
    }
    if (searchSupplierIDQuery.data?.content.amountTransactionLimitPerDay) {
      supplierManagementEnrolledFormMethods.setValue(
        "noOfTransactionPerDay",
        searchSupplierIDQuery.data.content.amountTransactionLimitPerDay
      );
    }
    if (searchSupplierIDQuery.data?.content.noOfTransactionPerDay) {
      supplierManagementEnrolledFormMethods.setValue(
        "amountTransactionLimitPerDay",
        searchSupplierIDQuery.data.content.noOfTransactionPerDay
      );
    }
    if (searchSupplierIDQuery.data?.content.contactName) {
      supplierManagementEnrolledFormMethods.setValue(
        "supplierContactName",
        searchSupplierIDQuery.data.content.contactName
      );
    }
    if (searchSupplierIDQuery.data?.content.contactEmail) {
      supplierManagementEnrolledFormMethods.setValue(
        "contactEmail",
        searchSupplierIDQuery.data.content.contactEmail
      );
    }
    if (searchSupplierIDQuery.data?.content.taxId) {
      supplierManagementEnrolledFormMethods.setValue(
        "taxId",
        searchSupplierIDQuery.data.content.taxId
      );
    }
    if (searchSupplierIDQuery.data?.content.address1) {
      supplierManagementEnrolledFormMethods.setValue(
        "address1",
        searchSupplierIDQuery.data.content.address1
      );
    }
    if (searchSupplierIDQuery.data?.content.address2) {
      supplierManagementEnrolledFormMethods.setValue(
        "address2",
        searchSupplierIDQuery.data.content.address2
      );
    }
    if (searchSupplierIDQuery.data?.content.address3) {
      supplierManagementEnrolledFormMethods.setValue(
        "address3",
        searchSupplierIDQuery.data.content.address3
      );
    }
    if (searchSupplierIDQuery.data?.content.address4) {
      supplierManagementEnrolledFormMethods.setValue(
        "address4",
        searchSupplierIDQuery.data.content.address4
      );
    }
    if (searchSupplierIDQuery.data?.content.country) {
      supplierManagementEnrolledFormMethods.setValue(
        "country",
        searchSupplierIDQuery.data.content.country
      );
    }
    if (searchSupplierIDQuery.data?.content.state) {
      supplierManagementEnrolledFormMethods.setValue(
        "state",
        searchSupplierIDQuery.data.content.state
      );
    }
    if (searchSupplierIDQuery.data?.content.city) {
      supplierManagementEnrolledFormMethods.setValue(
        "city",
        searchSupplierIDQuery.data.content.city
      );
    }
    if (searchSupplierIDQuery.data?.content.zip) {
      supplierManagementEnrolledFormMethods.setValue(
        "zip",
        searchSupplierIDQuery.data.content.zip
      );
    }
    if (searchSupplierIDQuery.data?.content.locationCode) {
      supplierManagementEnrolledFormMethods.setValue(
        "locationCode",
        searchSupplierIDQuery.data.content.locationCode
      );
    }
    if (searchSupplierIDQuery.data?.content.authorizationType) {
      supplierManagementEnrolledFormMethods.setValue(
        "authorizationType",
        searchSupplierIDQuery.data.content.authorizationType
          ? searchSupplierIDQuery.data.content.authorizationType
          : "Single"
      );
    }
    if (searchSupplierIDQuery.data?.content.universalAcceptor) {
      supplierManagementEnrolledFormMethods.setValue(
        "universalAcceptor",
        searchSupplierIDQuery.data.content.universalAcceptor
      );
    }
    if (searchSupplierIDQuery.data?.content.universalAcceptorComments) {
      supplierManagementEnrolledFormMethods.setValue(
        "universalAcceptorComments",
        searchSupplierIDQuery.data.content.universalAcceptorComments
      );
    }
    if (searchSupplierIDQuery.data?.content.doesSupplierTakeFees) {
      supplierManagementEnrolledFormMethods.setValue(
        "doesSupplierTakeFees",
        searchSupplierIDQuery.data.content.doesSupplierTakeFees
      );
    }
    if (searchSupplierIDQuery.data?.content.supplierFeesComments) {
      supplierManagementEnrolledFormMethods.setValue(
        "supplierFeesComments",
        searchSupplierIDQuery.data.content.supplierFeesComments
      );
    }
    if (searchSupplierIDQuery.data?.content.dataRequirementsToMakePayment) {
      supplierManagementEnrolledFormMethods.setValue(
        "dataRequirementsToMakePayment",
        searchSupplierIDQuery.data.content.dataRequirementsToMakePayment
      );
    }
    if (searchSupplierIDQuery.data?.content.department) {
      supplierManagementEnrolledFormMethods.setValue(
        "department",
        searchSupplierIDQuery.data.content.department
      );
    }
    if (searchSupplierIDQuery.data?.content.additionalPaymentRestrictions) {
      supplierManagementEnrolledFormMethods.setValue(
        "additionalPaymentRestrictions",
        searchSupplierIDQuery.data.content.additionalPaymentRestrictions
      );
    }

    if (searchSupplierIDQuery.data?.content.confirmationOfPaymentEmail) {
      supplierManagementEnrolledFormMethods.setValue(
        "confirmationOfPaymentEmail",
        searchSupplierIDQuery.data.content.confirmationOfPaymentEmail
      );
    }
    if (searchSupplierIDQuery.data?.content.contactPhone) {
      supplierManagementEnrolledFormMethods.setValue(
        "contactPhone",
        searchSupplierIDQuery.data.content.contactPhone
      );
    }
    if (searchSupplierIDQuery.data?.content.batchSize) {
      supplierManagementEnrolledFormMethods.setValue(
        "batchSize",
        searchSupplierIDQuery.data.content.batchSize
      );
    }
    if (searchSupplierIDQuery.data?.content.cronInterval) {
      supplierManagementEnrolledFormMethods.setValue(
        "cronInterval",
        searchSupplierIDQuery.data.content.cronInterval
      );
    }
    if (searchSupplierIDQuery.data?.content.maintenanceWindowStart) {
      supplierManagementEnrolledFormMethods.setValue(
        "maintenanceWindowStart",
        searchSupplierIDQuery.data.content.maintenanceWindowStart
      );
    }
    if (searchSupplierIDQuery.data?.content.maintenanceWindowEnd) {
      supplierManagementEnrolledFormMethods.setValue(
        "maintenanceWindowEnd",
        searchSupplierIDQuery.data.content.maintenanceWindowEnd
      );
    }
    if (searchSupplierIDQuery.data?.content.appvanceScenarioUrl) {
      supplierManagementEnrolledFormMethods.setValue(
        "appvanceScenarioUrl",
        searchSupplierIDQuery.data.content.appvanceScenarioUrl
      );
    }
    if (searchSupplierIDQuery.data?.content.appvanceProcessingFlag) {
      supplierManagementEnrolledFormMethods.setValue(
        "appvanceProcessingFlag",
        searchSupplierIDQuery.data.content.appvanceProcessingFlag
      );
    }
    if (searchSupplierIDQuery.data?.content.appvanceAuthUrl) {
      supplierManagementEnrolledFormMethods.setValue(
        "appvanceAuthUrl",
        searchSupplierIDQuery.data.content.appvanceAuthUrl
      );
    }
  }, [
    searchSupplierIDQuery.data,
    supplierManagementEnrolledFormMethods,
    supplierId,
  ]);

  const saveSupplierMutation = useMutation({
    mutationFn: (data: z.infer<typeof EditSupplierEnrolledFormLiteSchema>) => {
      // console.log("data:", data);
      return editSupplierEnrolledLiteFn(data, Number(supplierId));
    },
    onSuccess: (response) => {
      supplierManagementEnrolledFormMethods.reset();
      console.log("saveSupplierResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Supplier updated successfully",
      });
      void navigate({
        to: "/app/imremit-lite/supplier-management",
        replace: true,
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update supplier",
      });
    },
  });
  const isMutating = saveSupplierMutation.isPending;

  function onSubmit(data: z.infer<typeof EditSupplierEnrolledFormLiteSchema>) {
    saveSupplierMutation.mutate(data);
  }

  return (
    <>
      <Form {...supplierManagementEnrolledFormMethods}>
        <form
          onSubmit={supplierManagementEnrolledFormMethods.handleSubmit(
            onSubmit
          )}
        >
          <Heading3 className="mb-4">Supplier Details</Heading3>
          <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
            <CommonForm isMutating={isMutating} />
            {children}
          </div>
          <Separator className="mb-5" />
          <Heading4 className="mb-4">Supplier Details</Heading4>
          <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
            <EnrolledDateField isMutating={isMutating} />
            <CardTypeField
              isMutating={isMutating}
              bufferDisabled={bufferDisabled}
            />
            <DepartmentField isMutating={isMutating} />
            <DataRequirementsToMakePaymentField isMutating={isMutating} />
            <UniversalAcceptorCommentsField isMutating={isMutating} />
            <DoesSupplierTakeFeesField isMutating={isMutating} />
            <PaymentModeField isMutating={isMutating} />

            {remittanceMethod === "Pay By Web" && (
              <>
                <ProxyPayUrlField isMutating={isMutating} />
                <UserIdField isMutating={isMutating} />
                <PasswordField isMutating={isMutating} />
                <ClientKeyField isMutating={isMutating} />
                <WebsiteKeyField isMutating={isMutating} />
                {/* If the `enableScriptFlagField` property is not exist,then the deafult value will be as TRUE. */}
                <ConfirmationOfPaymentEmail
                  isMutating={isMutating}
                  // enableScriptFlagField={
                  //   searchSupplierIDQuery.data?.content.enableScriptFlagField
                  //     ? searchSupplierIDQuery.data.content.enableScriptFlagField
                  //     : true
                  // }
                />
              </>
            )}
            {remittanceMethod === "Pay By Phone" && (
              <>
                <RemittanceNameField isMutating={isMutating} />
                <RemittancePhoneNumberField isMutating={isMutating} />
              </>
            )}
            <PaymentRestrictionsField isMutating={isMutating} />

            {(remittanceMethod === "Pay By Web" ||
              remittanceMethod === "Pay By Phone") &&
              paymentRestrictionsChecked && (
                <>
                  <MinAmountPerTransactionField isMutating={isMutating} />
                  <MaxAmountPerTransactionField isMutating={isMutating} />
                  <NoOfTransactionPerDayField isMutating={isMutating} />
                  <AmountTransactionLimitPerDayField isMutating={isMutating} />
                  <AdditionalPaymentRestrictionsField isMutating={isMutating} />
                </>
              )}
          </div>
          <>
            <Separator className="mb-5" />
            <Heading4 className="mb-4">Supplier Profile</Heading4>

            <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
              <SupplierContactNameField isMutating={isMutating} />
              <ContactEmailField
                isMutating={isMutating}
                isMandatory={remittanceMethod === "Pay By Email"}
              />
              <ContactPhoneField isMutating={isMutating} />
              <TaxIdField isMutating={isMutating} />
              <Address1Field isMutating={isMutating} />
              <Address2Field isMutating={isMutating} />
              <Address3Field isMutating={isMutating} />
              <Address4Field isMutating={isMutating} />
              <CountryField isMutating={isMutating} />
              <StateField isMutating={isMutating} />
              <CityField isMutating={isMutating} />
              <ZipField isMutating={isMutating} />
              <LocationCodeField isMutating={isMutating} />
              <BatchSizeField isMutating={isMutating} />
              <CronIntervalField isMutating={isMutating} />
              <WindowStartField isMutating={isMutating} />
              <WindowEndField isMutating={isMutating} />
            </div>
            <div className="flex w-full flex-row justify-end gap-2">
              <Link
                to="/app/imremit-lite/supplier-management"
                className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
              >
                <span className="sr-only">Cancel</span>
                <XCircleIcon className="size-4" />
                Cancel
              </Link>

              <Button
                className="gap-2 bg-success-foreground text-white hover:bg-success-foreground/80"
                type="submit"
                aria-disabled={isMutating}
                disabled={isMutating}
                onClick={() => {
                  supplierManagementEnrolledFormMethods.setValue(
                    "saveAndContinue",
                    true
                  );
                  supplierManagementEnrolledFormMethods.handleSubmit(onSubmit);
                }}
              >
                <span className="sr-only">Submit form</span>
                Save and continue{" "}
                {isMutating ? (
                  <Spinner className="size-4" />
                ) : (
                  <SaveAllIcon className="size-4" />
                )}
              </Button>
              <Button
                className="gap-2 bg-success-foreground text-white hover:bg-success-foreground/80"
                type="submit"
                aria-disabled={isMutating}
                disabled={isMutating}
                onClick={() => {
                  supplierManagementEnrolledFormMethods.setValue(
                    "saveAndContinue",
                    false
                  );
                  supplierManagementEnrolledFormMethods.handleSubmit(onSubmit);
                }}
              >
                <span className="sr-only">Submit form</span>
                Save and submit
                {isMutating ? (
                  <Spinner className="size-4" />
                ) : (
                  <CheckCircleIcon className="size-4" />
                )}
              </Button>
            </div>
          </>
        </form>
      </Form>
    </>
  );
}

function EnrolledDateField({isMutating}: SupplierAPIMutatingProps) {
  const {control} = useFormContext();

  return (
    <FormField
      control={control}
      name="enrolledDate"
      render={({field}) => (
        <FormItem className="mt-3 flex flex-col">
          <FormLabel showMandatoryAsterisk>Enrolled Date:</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="input"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  className={cn(
                    "w-full pl-3 text-left font-normal",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value ? (
                    format(field.value as Date, "PPP")
                  ) : (
                    <span>Pick a date</span>
                  )}
                  <CalendarIcon className="ml-auto size-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent
              className="max-h-[var(--radix-popover-content-available-height)] w-[var(--radix-popover-trigger-width)] p-0"
              align="start"
            >
              <Calendar
                mode="single"
                selected={field.value as Date}
                disabled={(date) =>
                  date > new Date() || date < new Date("1900-01-01")
                }
                initialFocus
                onSelect={field.onChange}
              />
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CardTypeField({
  isMutating,
  bufferDisabled,
}: {
  isMutating: boolean;
  bufferDisabled: boolean;
}) {
  const {watch, setValue} = useFormContext();

  const supplierCardOnFileFlag = watch("supplierCardOnFileFlag") as boolean;
  //const supplierSingleUseFlag = watch("supplierSingleUseFlag") as boolean;
  const authorizationType = watch("authorizationType") as string;

  const supplierPaymentCounterFlag = watch(
    "supplierPaymentCounterFlag"
  ) as boolean;

  const {supplierId} = useParams({
    from: "/app/imremit-lite/supplier-management/$supplierId/edit",
  });

  const counterFlagValue: string = supplierPaymentCounterFlag
    ? "true"
    : "false";

  const flagResetMutation = useMutation({
    mutationFn: () => {
      return flagResetFn(supplierId, counterFlagValue);
    },
    onSuccess: (response) => {
      console.log("flagResetResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Flag reset successfully",
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to reset flag",
      });
    },
  });
  const flagResetIsMutation = flagResetMutation.isPending;

  return (
    <>
      <div className="grid grid-cols-1 gap-2">
        <RadioGroup>
          <Label showMandatoryAsterisk>Authorization Type:</Label>
          <div className="flex items-center space-x-2">
            <RadioGroupItem
              value="single-auth"
              disabled={isMutating}
              aria-disabled={isMutating}
              id="single-auth"
              checked={authorizationType === "Single" ? true : false}
              onClick={() => {
                setValue("authorizationType", "Single");
              }}
            />
            <Label htmlFor="single-auth"> Single</Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem
              value="multi-auth"
              disabled={isMutating}
              aria-disabled={isMutating}
              id="multi-auth"
              checked={authorizationType === "Multi" ? true : false}
              onClick={() => {
                setValue("authorizationType", "Multi");
              }}
            />
            <Label htmlFor="multi-auth"> Multi </Label>
          </div>
        </RadioGroup>

        {/* <RadioGroup>
          <Label showMandatoryAsterisk>Card Type:</Label>

          <div className="flex items-center space-x-2">
            <RadioGroupItem
              value="single-use-card"
              disabled={isMutating}
              aria-disabled={isMutating}
              id="single-use-card"
              checked={supplierSingleUseFlag}
              onClick={() => {
                setValue("supplierSingleUseFlag", true);
                setValue("supplierCardOnFileFlag", false);
              }}
            />
            <Label htmlFor="single-use-card"> Single Use Card </Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem
              value="card-on-file"
              disabled={isMutating}
              aria-disabled={isMutating}
              id="card-on-file"
              checked={supplierCardOnFileFlag}
              onClick={() => {
                setValue("supplierSingleUseFlag", false);
                setValue("supplierCardOnFileFlag", true);
              }}
            />
            <Label htmlFor="card-on-file"> Card On File </Label>
          </div>
        </RadioGroup> */}

        {supplierCardOnFileFlag && (
          <div className="grid gap-2">
            <Button
              type="button"
              variant="secondary"
              size="xs"
              className="!mt-[10px]"
              aria-disabled={isMutating}
              disabled={
                isMutating || flagResetIsMutation
                // || !supplierPaymentCounterFlag
              }
              onClick={() => {
                flagResetMutation.mutate();
              }}
            >
              Flag Reset
              {flagResetIsMutation ? <Spinner className="size-4" /> : ""}
            </Button>
            <BufferLimitAmountField
              isMutating={isMutating}
              bufferDisabled={bufferDisabled}
            />
          </div>
        )}
      </div>
    </>
  );
}

function BufferLimitAmountField({
  isMutating,
  bufferDisabled,
}: {
  isMutating: boolean;
  bufferDisabled: boolean;
}) {
  const {register} = useFormContext();

  return (
    <FormField
      name="supplierBufferLimitAmount"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="supplierBufferLimitAmount">
            Card Limit Buffer:
          </FormLabel>
          <FormControl>
            <Input
              type="number"
              disabled={isMutating || bufferDisabled}
              aria-disabled={isMutating}
              placeholder="Enter the card limit buffer..."
              {...register("supplierBufferLimitAmount", {
                setValueAs: (v) =>
                  v === "" ? undefined : parseInt(v as string, 10),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function PaymentModeField({isMutating}: SupplierAPIMutatingProps) {
  const {control} = useFormContext();

  return (
    <FormField
      control={control}
      name="paymentMode"
      render={({field}) => (
        <div className="space-y-2">
          <FormItem>
            <FormLabel htmlFor="paymentMode" showMandatoryAsterisk>
              Remittance Method:
            </FormLabel>
            <Popover>
              <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                <Command>
                  <CommandGroup>
                    {remittanceMethods.map((remittanceMethods, i) => (
                      <CommandItem
                        key={i}
                        value={remittanceMethods.value}
                        onSelect={(value) => {
                          field.onChange(
                            value
                              .toLowerCase()
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")
                          );
                        }}
                      >
                        <CheckIcon
                          className={cn(
                            "mr-2 size-4",
                            typeof field.value === "string" &&
                              typeof remittanceMethods.value === "string" &&
                              field.value.toUpperCase() ===
                                remittanceMethods.value.toUpperCase()
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {remittanceMethods.label}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </Command>
              </PopoverContent>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    variant="outline"
                    disabled={isMutating}
                    aria-disabled={isMutating}
                    role="combobox"
                    className={cn(
                      "justify-between",
                      !field.value && "text-accent-foreground",
                      "w-full"
                    )}
                  >
                    {field.value
                      ? remittanceMethods.find(
                          (item) =>
                            (item.value as string).toUpperCase() ===
                            (field.value as string).toUpperCase()
                        )?.label
                      : "Select remittance method"}
                    <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
            </Popover>
            <FormMessage />
          </FormItem>
        </div>
      )}
    />
  );
}

function ProxyPayUrlField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="proxyPayUrl"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="proxyPayUrl" showMandatoryAsterisk>
            Web URL:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the web url..."
              {...register("proxyPayUrl")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function UserIdField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="userId"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="userId">User ID:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the user id..."
              {...register("userId")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function PasswordField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();

  return (
    <FormField
      name="password"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="password">Password:</FormLabel>
          <FormControl>
            <div className="flex w-full space-x-2">
              <PasswordInput
                disabled={isMutating}
                aria-disabled={isMutating}
                placeholder="Enter the password..."
                {...register("password")}
              />
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ClientKeyField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="clientKey"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="clientKey">Client Key:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the client key..."
              {...register("clientKey", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function WebsiteKeyField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="websiteKey"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="websiteKey">Website Key:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the website key..."
              {...register("websiteKey", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

interface ConfirmationOfPaymentEmailProps {
  isMutating?: boolean;
  // enableScriptFlagField?: boolean;
}

function ConfirmationOfPaymentEmail({
  isMutating,
}: // enableScriptFlagField,
ConfirmationOfPaymentEmailProps) {
  const {control, watch, setValue, register} = useFormContext();
  const scriptFlag = watch("scriptFlag") as boolean;
  const appvanceProcessingFlag = watch("appvanceProcessingFlag") as boolean;
  // console.log("enableScriptFlagField:", enableScriptFlagField);

  /**
   * Variants for the help icon.
   * @typedef {Object} HELP_ICON_VARIANTS
   */
  // const HELP_ICON_VARIANTS = {
  //   helpChat: {
  //     isOpen:
  //       "text-success-foreground hover:text-destructive-foreground active:text-destructive-foreground",
  //     default:
  //       "text-theme hover:text-neutral-foreground active:neutral-foreground",
  //   },
  // };

  /**
   * Class Variance Authority setup for help icon.
   */
  // const helpIconVariants = cva("size-4", {
  //   variants: HELP_ICON_VARIANTS,
  //   defaultVariants: {
  //     helpChat: "default",
  //   },
  // });

  return (
    <div className="grid grid-cols-3 gap-4">
      <RadioGroup>
        <div className="flex">
          <Label showMandatoryAsterisk>Supplier Script:</Label>
          {/* {enableScriptFlagField && (
            <TooltipProvider delayDuration={50} skipDelayDuration={0}>
              <section className="flex flex-col  px-1">
                <Tooltip>
                  <TooltipTrigger>
                    <BadgeInfo className={cn(helpIconVariants())} />
                  </TooltipTrigger>
                  <TooltipContent>
                    <TooltipArrow />
                    <Paragraph>
                      Since we have open payments for this supplier,so we can't
                      update this field.
                    </Paragraph>
                  </TooltipContent>
                </Tooltip>
              </section>
            </TooltipProvider>
          )} */}
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value="script-no"
            disabled={isMutating} //|| enableScriptFlagField
            aria-disabled={isMutating}
            id="script-no"
            checked={!scriptFlag}
            onClick={() => {
              setValue("scriptFlag", false);
            }}
          />
          <Label htmlFor="script-no"> No </Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value="script-yes"
            disabled={isMutating} //|| enableScriptFlagField
            aria-disabled={isMutating}
            id="script-yes"
            checked={scriptFlag}
            onClick={() => {
              setValue("scriptFlag", true);
            }}
          />
          <Label htmlFor="script-yes"> Yes</Label>
        </div>
      </RadioGroup>
      {scriptFlag && (
        <>
          <FormField
            control={control}
            name="confirmationOfPaymentEmail"
            render={() => (
              <FormItem className="col-span-2">
                <FormLabel
                  htmlFor="confirmationOfPaymentEmail"
                  showMandatoryAsterisk
                >
                  Confirmation Of Payment Email:
                </FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    disabled={isMutating}
                    aria-disabled={isMutating}
                    placeholder="Enter the confirmation..."
                    {...register("confirmationOfPaymentEmail", {
                      setValueAs: (value): string | null =>
                        typeof value === "string" && value.trim() === ""
                          ? null
                          : (value as string),
                    })}
                  />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
          <Separator className="col-span-3" />
          <Heading4 className="col-span-3">Appvance</Heading4>
          <FormField
            name="appvanceProcessingFlag"
            render={({field}) => (
              <FormItem className="flex flex-col space-y-2 pt-1">
                <FormLabel showMandatoryAsterisk>
                  Appvance Processing Flag:
                </FormLabel>
                <FormControl className="flex flex-row items-center">
                  <Switch
                    checked={field.value as boolean}
                    onCheckedChange={field.onChange}
                    {...register("appvanceProcessingFlag")}
                    aria-readonly
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            name="appvanceAuthUrl"
            render={() => (
              <FormItem className="col-span-2">
                <FormLabel htmlFor="appvanceAuthUrl" showMandatoryAsterisk>
                  Appvance Authorization URL:
                </FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    disabled={isMutating}
                    aria-disabled={isMutating}
                    placeholder="Enter the appvance authorization URL..."
                    {...register("appvanceAuthUrl", {
                      setValueAs: (value): string | null =>
                        typeof value === "string" && value.trim() === ""
                          ? null
                          : (value as string),
                    })}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {appvanceProcessingFlag && (
            <FormField
              name="appvanceScenarioUrl"
              render={() => (
                <FormItem className="col-span-2">
                  <FormLabel
                    htmlFor="appvanceScenarioUrl"
                    showMandatoryAsterisk
                  >
                    Appvance Scenario URL:
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      disabled={isMutating}
                      aria-disabled={isMutating}
                      placeholder="Enter the appvance scenario URL..."
                      {...register("appvanceScenarioUrl", {
                        setValueAs: (value): string | null =>
                          typeof value === "string" && value.trim() === ""
                            ? null
                            : (value as string),
                      })}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
        </>
      )}
    </div>
  );
}

function RemittanceNameField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="remittanceName"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="remittanceName" showMandatoryAsterisk>
            Contact Name:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the contact name..."
              {...register("remittanceName")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function RemittancePhoneNumberField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="remittancePhoneNumber"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="remittancePhoneNumber" showMandatoryAsterisk>
            Contact Phone:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the contact phone..."
              {...register("remittancePhoneNumber")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function PaymentRestrictionsField({isMutating}: SupplierAPIMutatingProps) {
  const {watch, setValue} = useFormContext();
  const paymentRestrictions = watch("paymentRestrictions") as boolean;

  return (
    <>
      <div className="gap-2">
        <RadioGroup>
          <Label showMandatoryAsterisk>
            Are there any Payment Restrictions:
          </Label>
          <div className="space-x-2">
            <RadioGroupItem
              value="restrictions-no"
              disabled={isMutating}
              aria-disabled={isMutating}
              id="restrictions-no"
              checked={!paymentRestrictions}
              onClick={() => {
                setValue("paymentRestrictions", false);
              }}
            />
            <Label htmlFor="restrictions-no"> No </Label>
          </div>
          <div className="space-x-2">
            <RadioGroupItem
              value="restrictions-yes"
              disabled={isMutating}
              aria-disabled={isMutating}
              id="restrictions-yes"
              checked={paymentRestrictions}
              onClick={() => {
                setValue("paymentRestrictions", true);
              }}
            />
            <Label htmlFor="restrictions-yes"> Yes</Label>
          </div>
        </RadioGroup>
      </div>
    </>
  );
}

function MinAmountPerTransactionField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="minAmountPerTransaction"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="minAmountPerTransaction">
            Minimum Amount Per Transaction:
          </FormLabel>
          <FormControl>
            <Input
              type="number"
              step=".01"
              pattern="^\d*(\.\d{0,2})?$"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter minimum amount per transaction..."
              {...register("minAmountPerTransaction", {
                setValueAs: (v) =>
                  v === "" ? undefined : parseFloat(v as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function MaxAmountPerTransactionField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="maxAmountPerTransaction"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="maxAmountPerTransaction">
            Maximum Amount Per Transaction:
          </FormLabel>
          <FormControl>
            <Input
              type="number"
              step=".01"
              pattern="^\d*(\.\d{0,2})?$"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter maximum amount per transaction..."
              {...register("maxAmountPerTransaction", {
                setValueAs: (v) =>
                  v === "" ? undefined : parseFloat(v as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function NoOfTransactionPerDayField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="noOfTransactionPerDay"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="noOfTransactionPerDay">
            Maximum Number of Transactions Per Day:
          </FormLabel>
          <FormControl>
            <Input
              type="number"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter maximum transactions per day..."
              {...register("noOfTransactionPerDay", {
                setValueAs: (v) =>
                  v === "" ? undefined : parseInt(v as string, 10),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function AmountTransactionLimitPerDayField({
  isMutating,
}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="amountTransactionLimitPerDay"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="amountTransactionLimitPerDay">
            Total Transaction Amount Per Day:
          </FormLabel>
          <FormControl>
            <Input
              type="number"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter total transactions per day..."
              {...register("amountTransactionLimitPerDay", {
                setValueAs: (v) =>
                  v === "" ? undefined : parseInt(v as string, 10),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function SupplierContactNameField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="supplierContactName"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="supplierContactName" showMandatoryAsterisk>
            Contact Name:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the contact name..."
              {...register("supplierContactName")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ContactEmailField({
  isMutating,
  isMandatory,
}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="contactEmail"
      render={() => (
        <FormItem>
          <FormLabel
            htmlFor="contactEmail"
            showMandatoryAsterisk={!!isMandatory}
          >
            Contact Email:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the contact email..."
              {...register("contactEmail")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ContactPhoneField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="contactPhone"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="contactPhone">Contact Phone:</FormLabel>
          <FormControl>
            <Input
              type="number"
              min="0"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the contact phone..."
              {...register("contactPhone")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function TaxIdField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="taxId"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="taxId" showMandatoryAsterisk>
            Tax ID:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the tax ID..."
              {...register("taxId")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function Address1Field({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="address1"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="address1">Address 1:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the address 1..."
              {...register("address1", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function Address2Field({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="address2"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="address2">Address 2:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the address 2..."
              {...register("address2", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function Address3Field({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="address3"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="address3">Address 3:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the address 3..."
              {...register("address3", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function Address4Field({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="address4"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="address4">Address 4:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the address 4..."
              {...register("address4", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CountryField({isMutating}: SupplierAPIMutatingProps) {
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="country"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="country">Country:</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? countrySupplierSelectOptions.find(
                        (country) => country.value === field.value
                      )?.label
                    : "Select Country..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search countries..." />
                <CommandEmpty>No countries found.</CommandEmpty>
                <CommandGroup>
                  {countrySupplierSelectOptions.map((regionOption) => (
                    <CommandItem
                      key={regionOption.value}
                      value={regionOption.value}
                      onSelect={(value) => {
                        setValue("country", value.toUpperCase());
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          regionOption.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {regionOption.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function StateField({isMutating}: SupplierAPIMutatingProps) {
  const {control, setValue, watch} = useFormContext();
  const formFields = watch();

  const stateOptions =
    formFields.country === "US"
      ? stateOptionsUSA
      : formFields.country === "CA"
      ? provinceOptionsCanada
      : [];

  // Disable the entire component when stateOptions is empty
  if (stateOptions.length === 0) {
    return (
      <FormField
        name="state"
        render={() => (
          <FormItem className="mt-2 flex flex-col">
            <FormLabel htmlFor="state">State:</FormLabel>

            <Button
              variant="outline"
              disabled={isMutating || stateOptions.length === 0}
              aria-disabled={isMutating}
              className={cn("justify-between text-accent-foreground")}
            >
              {"Please select country first"}
            </Button>
          </FormItem>
        )}
      />
    );
  }

  return (
    <FormField
      control={control}
      name="state"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="state">State:</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? stateOptions.find((state) => state.value === field.value)
                        ?.label
                    : "Select State..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search states..." />
                <CommandEmpty>No states found.</CommandEmpty>
                <CommandGroup>
                  {stateOptions.map((regionOption) => (
                    <CommandItem
                      key={regionOption.value}
                      value={regionOption.label}
                      onSelect={() => {
                        setValue("state", regionOption.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          regionOption.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {regionOption.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CityField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="city"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="city">City:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the city..."
              {...register("city", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ZipField({isMutating}: SupplierAPIMutatingProps) {
  const {register, watch} = useFormContext();

  const formFields = watch();
  const currentCountry = formFields.country as string;

  const currentLabel =
    currentCountry === "US"
      ? "Zip code:"
      : currentCountry === "CA"
      ? "Postal code:"
      : "Zip code:";

  return (
    <FormField
      name="zip"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="zip" showMandatoryAsterisk>
            {currentLabel}
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the zip..."
              {...register("zip")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function LocationCodeField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="locationCode"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="locationCode">Location Code:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the location code..."
              {...register("locationCode", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function UniversalAcceptorCommentsField({isMutating}: {isMutating: boolean}) {
  const {control, watch, setValue, register} = useFormContext();
  const universalAcceptor = watch("universalAcceptor") as boolean;

  return (
    <div className="grid grid-cols-2 gap-4">
      <RadioGroup>
        <Label showMandatoryAsterisk>Universal Acceptor:</Label>
        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value="accept-no"
            disabled={isMutating}
            aria-disabled={isMutating}
            id="accept-no"
            checked={!universalAcceptor}
            onClick={() => {
              setValue("universalAcceptor", false);
            }}
          />
          <Label htmlFor="accept-no"> No </Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value="accept-yes"
            disabled={isMutating}
            aria-disabled={isMutating}
            id="accept-yes"
            checked={universalAcceptor}
            onClick={() => {
              setValue("universalAcceptor", true);
            }}
          />
          <Label htmlFor="accept-yes"> Yes</Label>
        </div>
      </RadioGroup>
      {universalAcceptor && (
        <FormField
          control={control}
          name="universalAcceptorComments"
          render={() => (
            <FormItem>
              <FormLabel htmlFor="universalAcceptorComments">
                Comments (Universal Acceptor):
              </FormLabel>
              <FormControl>
                <Textarea
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  placeholder="Enter comments..."
                  {...register("universalAcceptorComments")}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
      )}
    </div>
  );
}

function DoesSupplierTakeFeesField({isMutating}: {isMutating: boolean}) {
  const {control, watch, setValue, register} = useFormContext();
  const doesSupplierTakeFees = watch("doesSupplierTakeFees") as boolean;

  return (
    <div className="grid grid-cols-2 gap-4">
      <RadioGroup>
        <Label showMandatoryAsterisk>Does Supplier take fees?:</Label>
        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value="fees-no"
            disabled={isMutating}
            aria-disabled={isMutating}
            id="fees-no"
            checked={!doesSupplierTakeFees}
            onClick={() => {
              setValue("doesSupplierTakeFees", false);
            }}
          />
          <Label htmlFor="fees-no"> No </Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value="fees-yes"
            disabled={isMutating}
            aria-disabled={isMutating}
            id="fees-yes"
            checked={doesSupplierTakeFees}
            onClick={() => {
              setValue("doesSupplierTakeFees", true);
            }}
          />
          <Label htmlFor="fees-yes"> Yes</Label>
        </div>
      </RadioGroup>
      {doesSupplierTakeFees && (
        <FormField
          control={control}
          name="supplierFeesComments"
          render={() => (
            <FormItem>
              <FormLabel htmlFor="supplierFeesComments" showMandatoryAsterisk>
                Comments (Supplier Fees):
              </FormLabel>
              <FormControl>
                <Textarea
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  placeholder="Enter comments..."
                  {...register("supplierFeesComments")}
                />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
      )}
    </div>
  );
}

function DepartmentField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="department"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="department">Department:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the department..."
              {...register("department")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function DataRequirementsToMakePaymentField({
  isMutating,
}: SupplierAPIMutatingProps) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="dataRequirementsToMakePayment"
      render={() => (
        <FormItem>
          <FormLabel
            htmlFor="dataRequirementsToMakePayment"
            showMandatoryAsterisk
          >
            Data Requirements To Make Payment :
          </FormLabel>
          <FormControl>
            <Textarea
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter requirement..."
              {...register("dataRequirementsToMakePayment")}
            />
          </FormControl>

          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function AdditionalPaymentRestrictionsField({
  isMutating,
}: SupplierAPIMutatingProps) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="additionalPaymentRestrictions"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="additionalPaymentRestrictions">
            Additional Payment Restrictions :
          </FormLabel>
          <FormControl>
            <Textarea
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter additional restrictions..."
              {...register("additionalPaymentRestrictions")}
            />
          </FormControl>

          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function BatchSizeField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="batchSize"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="batchSize">Proxy Pay Batch Size:</FormLabel>
          <FormControl>
            <Input
              type="number"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the batch size..."
              {...register("batchSize", {
                required: true,
                setValueAs: (v) =>
                  v === "" ? undefined : parseFloat(v as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CronIntervalField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="cronInterval"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="cronInterval">Proxy Pay Cron Interval:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the cron interval..."
              {...register("cronInterval", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function WindowStartField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="maintenanceWindowStart"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="maintenanceWindowStart">
            Maintenance Window Start Time:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the maintenance window start..."
              {...register("maintenanceWindowStart", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function WindowEndField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="maintenanceWindowEnd"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="maintenanceWindowEnd">
            Maintenance Window End Time:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the maintenance window end..."
              {...register("maintenanceWindowEnd", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
