import {kyApiFn} from "@/lib/ky";
import {type AddParticipantRegisterFormDTO} from "@/modules/admin";

export const addCustomerParticipantRegisterFn = (
  addCustomerParticipantRegisterDTO: AddParticipantRegisterFormDTO,
  externalId: string
) =>
  kyApiFn(`api/e-payable/management/${externalId}/organizations`, "post", {
    json: addCustomerParticipantRegisterDTO,
  });
