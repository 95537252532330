import {useEffect, useState} from "react";

import {CheckIcon, ChevronsUpDownIcon, InfoIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {Input} from "@/components/ui/input";
import {Label} from "@/components/ui/label";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

import {cn} from "@/lib/utils";

export interface OptionProps {
  label: string;
  value: string;
}

export interface ScriptFieldSelectProps {
  isMutating: boolean;
  handleInputSelectChange: (script: OptionProps, scriptType: string) => void;
  inputValue: string;
  allItems: OptionProps[];
  defaultInputValue?: string;
  isDisabled?: boolean;
  requireValue?: boolean;
}

export interface ScriptFieldValueProps {
  isMutating: boolean;
  handleInputValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  inputValue: string;
  isDisabled?: boolean;
  requireValue?: boolean;
}

export function PaymentTimerField({
  isMutating,
  allItems,
  handleInputSelectChange,
  inputValue,
  defaultInputValue, // Add defaultInputValue parameter
}: ScriptFieldSelectProps) {
  return (
    <div className="grid w-full items-center gap-1.5">
      <div className="flex items-center gap-x-1">
        <Label htmlFor="paymentTimer" showMandatoryAsterisk>
          Payment Timer :
        </Label>
        <TooltipProvider delayDuration={200}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="cursor-pointer">
                <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <div className="flex justify-between space-x-4">
                For multiple payments to process at
                <br />
                a time, we can specify wait between
                <br />
                from one payment to next payment.
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            disabled={isMutating}
            aria-disabled={isMutating}
            role="combobox"
            className={cn(
              "justify-between",
              !inputValue && "text-accent-foreground"
            )}
          >
            {inputValue || defaultInputValue
              ? allItems.find(
                  (item) => item.value === (inputValue || defaultInputValue)
                )?.label
              : "Select Timer Value..."}
            <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
          <Command>
            <CommandInput placeholder="Search Timer Values..." />
            <CommandEmpty>No Timer Values found.</CommandEmpty>
            <CommandGroup>
              {allItems.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={() => {
                    handleInputSelectChange(item, "paymentTimer");
                  }}
                >
                  <CheckIcon
                    className={cn(
                      "mr-2 size-4",
                      item.value === inputValue ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export function CaptchaTimerField({
  isMutating,
  allItems,
  handleInputSelectChange,
  inputValue,
  defaultInputValue, // Add defaultInputValue parameter
}: ScriptFieldSelectProps) {
  return (
    <div className="grid w-full items-center gap-1.5">
      <div className="flex items-center gap-x-1">
        <Label htmlFor="captchaTimer" showMandatoryAsterisk>
          Captcha Timer :
        </Label>
        <TooltipProvider delayDuration={200}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="cursor-pointer">
                <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <div className="flex justify-between space-x-4">
                To solve the captcha we need to
                <br />
                specify time, so that it will wait for the
                <br />
                specified amount of time.
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            disabled={isMutating}
            aria-disabled={isMutating}
            className={cn(
              "justify-between",
              !inputValue && "text-accent-foreground"
            )}
          >
            {inputValue || defaultInputValue
              ? allItems.find(
                  (item) => item.value === (inputValue || defaultInputValue)
                )?.label
              : "Select Captcha Timer Value..."}
            <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
          <Command>
            <CommandInput placeholder="Search Captcha Timer Values..." />
            <CommandEmpty>No Captcha Timer Values found.</CommandEmpty>
            <CommandGroup>
              {allItems.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={() => {
                    handleInputSelectChange(item, "captchaTimer");
                  }}
                >
                  <CheckIcon
                    className={cn(
                      "mr-2 size-4",
                      item.value === inputValue ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export function SkipLoginField({
  isMutating,
  allItems,
  handleInputSelectChange,
  inputValue,
  defaultInputValue, // Add defaultInputValue parameter
}: ScriptFieldSelectProps) {
  return (
    <div className="grid w-full items-center gap-1.5">
      <div className="flex items-center gap-x-1">
        <Label htmlFor="loginSkip">Skip Login :</Label>
      </div>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            disabled={isMutating}
            aria-disabled={isMutating}
            className={cn(
              "justify-between",
              !inputValue && "text-accent-foreground"
            )}
          >
            {inputValue || defaultInputValue
              ? allItems.find(
                  (item) => item.value === (inputValue || defaultInputValue)
                )?.label
              : "Select..."}
            <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
          <Command>
            <CommandGroup>
              {allItems.map((item) => (
                <CommandItem
                  key={item.value}
                  value={item.value}
                  onSelect={() => {
                    handleInputSelectChange(item, "loginSkip");
                  }}
                >
                  <CheckIcon
                    className={cn(
                      "mr-2 size-4",
                      item.value === inputValue ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {item.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}

export function PageCategoryField({
  isMutating,
  inputValue,
  handleInputValueChange,
  requireValue = false,
}: ScriptFieldValueProps) {
  return (
    <div className="grid w-full items-center gap-1.5">
      <Label htmlFor="pageCategory" showMandatoryAsterisk={requireValue}>
        Page Name:
      </Label>

      <Input
        value={inputValue}
        disabled={isMutating}
        aria-disabled={isMutating}
        type="text"
        name="pageCategory"
        id="pageCategory"
        placeholder="Enter the Page Name..."
        onChange={handleInputValueChange}
      />
      {requireValue && !inputValue && (
        <span className="text-xs text-red-500">Page Name is mandatory.</span>
      )}
    </div>
  );
}

export function FieldNameField({
  isMutating,
  inputValue,
  handleInputValueChange,
  requireValue = false,
}: ScriptFieldValueProps) {
  return (
    <div className="grid w-full items-center gap-1.5">
      <Label htmlFor="fieldName" showMandatoryAsterisk>
        Field Name:
      </Label>
      <Input
        value={inputValue}
        disabled={isMutating}
        aria-disabled={isMutating}
        type="text"
        name="fieldName"
        id="fieldName"
        placeholder="Enter the Field Name..."
        onChange={handleInputValueChange}
      />
      {requireValue && inputValue === "" && (
        <span className="text-xs text-red-500">Field Name is mandatory.</span>
      )}
    </div>
  );
}

export function XPathField({
  isMutating,
  inputValue,
  handleInputValueChange,
  requireValue = false,
  isDisabled,
}: ScriptFieldValueProps) {
  return (
    <div className="grid w-full items-center gap-1.5">
      <Label htmlFor="fieldValue" showMandatoryAsterisk={requireValue}>
        X Path:
      </Label>
      <Input
        value={inputValue}
        disabled={isDisabled || isMutating}
        aria-disabled={isDisabled || isMutating}
        type="text"
        name="fieldValue"
        id="fieldValue"
        placeholder="Enter the X Path..."
        onChange={handleInputValueChange}
      />
      {requireValue && inputValue === "" && (
        <span className="text-xs text-red-500">X Path is mandatory.</span>
      )}
    </div>
  );
}

export function DefaultValueField({
  isMutating,
  inputValue,
  isDisabled,
  handleInputValueChange,
  requireValue = false,
}: ScriptFieldValueProps) {
  const effectiveInputValue = isDisabled ? "" : inputValue;
  return (
    <div className="grid w-full items-center gap-1.5">
      <Label htmlFor="dropDownValue" showMandatoryAsterisk={requireValue}>
        Default Value:
      </Label>
      <Input
        value={effectiveInputValue}
        disabled={isDisabled}
        aria-disabled={isMutating}
        type="text"
        name="dropDownValue"
        id="dropDownValue"
        placeholder="Enter the Default Value..."
        onChange={handleInputValueChange}
      />
      {!isDisabled && requireValue && effectiveInputValue === "" && (
        <span className="text-xs text-red-500">
          Default Value is mandatory.
        </span>
      )}
    </div>
  );
}

export function PositionField({
  isMutating,
  inputValue,
  isDisabled,
  handleInputValueChange,
}: ScriptFieldValueProps) {
  const effectiveInputValue = isDisabled ? "" : inputValue;

  return (
    <div className="grid w-full items-center gap-1.5">
      <div className="flex items-center gap-x-1">
        <Label htmlFor="position">Position:</Label>
        <TooltipProvider delayDuration={200}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="cursor-pointer">
                <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <div className="flex justify-between space-x-4">
                Position will start from 0.
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <Input
        value={effectiveInputValue}
        disabled={isDisabled}
        aria-disabled={isMutating}
        type="number"
        name="position"
        id="position"
        min="0"
        placeholder="Enter the Position..."
        onChange={handleInputValueChange}
      />
    </div>
  );
}

export function SeparatorField({
  isMutating,
  inputValue,
  isDisabled,
  handleInputValueChange,
}: ScriptFieldValueProps) {
  return (
    <div className="grid w-full items-center gap-1.5">
      <Label htmlFor="separator">Separator:</Label>
      <Input
        value={inputValue}
        disabled={isDisabled}
        aria-disabled={isMutating}
        type="text"
        name="separator"
        id="separator"
        placeholder="Enter the Separator..."
        onChange={handleInputValueChange}
      />
    </div>
  );
}

export function SequenceIdentifierField({
  isMutating,
  inputValue,
  handleInputValueChange,
}: ScriptFieldValueProps) {
  return (
    <div className="grid w-full items-center gap-1.5">
      <Label htmlFor="sequenceIdentifier">Login Sequence:</Label>
      <Input
        value={inputValue}
        disabled={isMutating}
        aria-disabled={isMutating}
        type="text"
        name="sequenceIdentifier"
        id="sequenceIdentifier"
        placeholder="Enter the login sequence..."
        onChange={handleInputValueChange}
      />
    </div>
  );
}

export function JsCheckField({
  isMutating,
  inputValue,
  isDisabled,
  handleInputValueChange,
}: ScriptFieldValueProps) {
  const effectiveInputValue = isDisabled ? "" : inputValue;

  return (
    <div className="grid w-full items-center gap-1.5">
      <Label htmlFor="jsCheck">Js Check:</Label>
      <Input
        value={effectiveInputValue}
        disabled={isDisabled}
        aria-disabled={isMutating}
        type="text"
        name="jsCheck"
        id="jsCheck"
        placeholder="Enter the Js Check..."
        onChange={handleInputValueChange}
      />
    </div>
  );
}

export function FindElementByField({
  allItems,
  handleInputSelectChange,
  inputValue,
  isDisabled,
  requireValue = false,
}: ScriptFieldSelectProps) {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    inputValue
  );

  useEffect(() => {
    setSelectedValue(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (isDisabled) {
      setSelectedValue("");
    }
  }, [isDisabled]);
  return (
    <div className="grid w-full items-center gap-1.5">
      <Label htmlFor="findElementBy" showMandatoryAsterisk={requireValue}>
        Find Element By:
      </Label>
      <Select
        value={selectedValue}
        disabled={isDisabled}
        onValueChange={(selectedValue) => {
          const selectedItem = allItems.find(
            (item) => item.value === selectedValue
          );
          if (selectedItem) {
            handleInputSelectChange(selectedItem, "findElementBy");
          }
        }}
      >
        <SelectTrigger>
          <SelectValue>
            <span>
              {inputValue ? (
                allItems.find(
                  (item) =>
                    item.value.toLowerCase() === inputValue.toLowerCase()
                )?.label // Display the label of the selected item
              ) : (
                <>
                  Select Find Element By
                  {isDisabled ? "" : <span className="text-red-500">*</span>}
                </>
              )}
            </span>
          </SelectValue>
        </SelectTrigger>
        <SelectContent className="max-h-[200px] overflow-y-auto">
          {allItems.map((item, i) => (
            <SelectItem key={i} value={item.value}>
              {item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {!isDisabled &&
        requireValue &&
        (selectedValue === "" || selectedValue === undefined) && (
          <span className="text-xs text-red-500">
            Find Element By is mandatory.
          </span>
        )}
    </div>
  );
}

export function TableNamesField({
  allItems,
  handleInputSelectChange,
  inputValue,
  isDisabled,
  requireValue = false,
}: ScriptFieldSelectProps) {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    inputValue
  );

  useEffect(() => {
    setSelectedValue(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (isDisabled) {
      setSelectedValue("");
    }
  }, [isDisabled]);
  return (
    <div className="grid w-full items-center gap-1.5">
      <Label htmlFor="tableName" showMandatoryAsterisk={requireValue}>
        Table Names:
      </Label>
      <Select
        value={selectedValue}
        disabled={isDisabled}
        onValueChange={(selectedValue) => {
          const selectedItem = allItems.find(
            (item) => item.value === selectedValue
          );
          if (selectedItem) {
            handleInputSelectChange(selectedItem, "tableName");
          }
        }}
      >
        <SelectTrigger>
          <SelectValue>
            <span>
              {inputValue ? (
                allItems.find(
                  (item) =>
                    item.value.toLowerCase() === inputValue.toLowerCase()
                )?.label // Display the label of the selected item
              ) : (
                <>
                  Select Table Names
                  {isDisabled ? "" : <span className="text-red-500">*</span>}
                </>
              )}
            </span>
          </SelectValue>
        </SelectTrigger>
        <SelectContent className="max-h-[200px] overflow-y-auto">
          {allItems.map((item, i) => (
            <SelectItem key={i} value={item.value}>
              {item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {!isDisabled &&
        requireValue &&
        (selectedValue === "" || selectedValue === undefined) && (
          <span className="text-xs text-red-500">Table Name is mandatory.</span>
        )}
    </div>
  );
}

export function TableColumnNamesField({
  allItems,
  handleInputSelectChange,
  inputValue,
  isDisabled,
  requireValue = false,
}: ScriptFieldSelectProps) {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    inputValue
  );

  useEffect(() => {
    setSelectedValue(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (isDisabled) {
      setSelectedValue("");
    }
  }, [isDisabled]);
  return (
    <div className="grid w-full items-center gap-1.5">
      <Label htmlFor="tableColumnName" showMandatoryAsterisk={requireValue}>
        Table Column Name:
      </Label>
      <Select
        value={selectedValue}
        disabled={isDisabled}
        onValueChange={(selectedValue) => {
          const selectedItem = allItems.find(
            (item) => item.value === selectedValue
          );
          if (selectedItem) {
            handleInputSelectChange(selectedItem, "tableColumnName");
          }
        }}
      >
        <SelectTrigger>
          <SelectValue>
            <span>
              {inputValue ? (
                allItems.find(
                  (item) =>
                    item.value.toLowerCase() === inputValue.toLowerCase()
                )?.label // Display the label of the selected item
              ) : (
                <>
                  Select Table Column Name
                  {isDisabled ? "" : <span className="text-red-500">*</span>}
                </>
              )}
            </span>
          </SelectValue>
        </SelectTrigger>
        <SelectContent className="max-h-[200px] overflow-y-auto">
          {allItems.map((item, i) => (
            <SelectItem key={i} value={item.value}>
              {item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {!isDisabled &&
        requireValue &&
        (selectedValue === "" || selectedValue === undefined) && (
          <span className="text-xs text-red-500">
            Table Column Name is mandatory.
          </span>
        )}
    </div>
  );
}

export function DateFormatField({
  allItems,
  handleInputSelectChange,
  inputValue,
  isDisabled,
}: ScriptFieldSelectProps) {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    inputValue
  );

  useEffect(() => {
    setSelectedValue(inputValue);
  }, [inputValue]);

  useEffect(() => {
    if (isDisabled) {
      setSelectedValue("");
    }
  }, [isDisabled]);

  return (
    <div className="grid w-full items-center gap-1.5">
      <Label htmlFor="dateFormat">Date Format:</Label>
      <Select
        value={selectedValue}
        disabled={isDisabled}
        onValueChange={(selectedValue) => {
          const selectedItem = allItems.find(
            (item) => item.value === selectedValue
          );
          if (selectedItem && !isDisabled) {
            handleInputSelectChange(selectedItem, "dateFormat");
          }
        }}
      >
        <SelectTrigger>
          <SelectValue>
            <span>
              {selectedValue ? (
                allItems.find((item) => item.value === selectedValue)?.label // Display the label of the selected item
              ) : (
                <>Select Date Format</>
              )}
            </span>
          </SelectValue>
        </SelectTrigger>
        <SelectContent className="max-h-[200px] overflow-y-auto">
          {allItems.map((item, i) => (
            <SelectItem key={i} value={item.value}>
              {item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}

export function TimerField({
  allItems,
  handleInputSelectChange,
  inputValue,

  requireValue = false,
}: ScriptFieldSelectProps) {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    inputValue
  );

  useEffect(() => {
    setSelectedValue(inputValue);
  }, [inputValue]);

  return (
    <div className="grid w-full items-center gap-1.5">
      <div className="flex items-center gap-x-1">
        <Label htmlFor="timer" showMandatoryAsterisk>
          Timer:
        </Label>
        <TooltipProvider delayDuration={200}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="cursor-pointer">
                <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <div className="flex justify-between space-x-4">
                Since we are using automation, we need to <br />
                specify time, so that it will wait until <br />
                the page load for the specified amount of time.
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
      <Select
        value={selectedValue}
        onValueChange={(selectedValue) => {
          const selectedItem = allItems.find(
            (item) => item.value === selectedValue
          );
          if (selectedItem) {
            handleInputSelectChange(selectedItem, "timer");
          }
        }}
      >
        <SelectTrigger>
          <SelectValue>
            <span>
              {inputValue ? (
                allItems.find((item) => item.value === inputValue)?.label // Display the label of the selected item
              ) : (
                <>
                  Select Timer
                  <span className="text-red-500">*</span>
                </>
              )}
            </span>
          </SelectValue>
        </SelectTrigger>
        <SelectContent className="max-h-[200px] overflow-y-auto">
          {allItems.map((item, i) => (
            <SelectItem key={i} value={item.value}>
              {item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {requireValue && inputValue === "" && (
        <span className="text-xs text-red-500">Timer is mandatory.</span>
      )}
    </div>
  );
}

export function FieldTypesField({
  allItems,
  handleInputSelectChange,
  inputValue,
  isMutating,
  requireValue = false,
}: ScriptFieldSelectProps) {
  return (
    <div className="grid w-full items-center gap-1.5">
      <Label htmlFor="fieldType" showMandatoryAsterisk>
        Field Types:
      </Label>
      <Select
        value={inputValue}
        disabled={isMutating}
        onValueChange={(selectedValue) => {
          const selectedItem = allItems.find(
            (item) => item.value === selectedValue
          );
          if (selectedItem) {
            handleInputSelectChange(selectedItem, "fieldType");
          }
        }}
      >
        <SelectTrigger>
          <SelectValue>
            <span>
              {inputValue ? (
                allItems.find((item) => item.value === inputValue)?.label // Display the label of the selected item
              ) : (
                <>
                  Select Field Type
                  <span className="text-red-500">*</span>
                </>
              )}
            </span>
          </SelectValue>
        </SelectTrigger>
        <SelectContent className="max-h-[200px] overflow-y-auto">
          {allItems.map((item, i) => (
            <SelectItem key={i} value={item.value}>
              {item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {requireValue && (inputValue === "" || inputValue === "select") && (
        <span className="text-xs text-red-500">Field Type is mandatory.</span>
      )}
    </div>
  );
}
