import {cva, type VariantProps} from "class-variance-authority";

import {cn} from "@/lib/utils";

const SKELETON_VARIANT_CLASSES = {
  size: {
    xs: "h-2",
    sm: "h-3",
    md: "h-4",
    lg: "h-6",
    xl: "h-8",
    xxl: "h-12",
    default: "h-4",
  },
  colour: {
    background: "bg-zinc-200/50",
    foreground: "bg-zinc-300/60",
    default: "bg-zinc-300/50",
  },
};

const skeletonVariants = cva("flex animate-pulse rounded-md w-full", {
  variants: SKELETON_VARIANT_CLASSES,
  defaultVariants: {
    size: "default",
    colour: "default",
  },
});

export interface SkeletonProps
  extends VariantProps<typeof skeletonVariants>,
    React.HTMLAttributes<HTMLDivElement> {}

/**
 * Skeleton component for indicating loading or placeholder content.
 *
 * @param {SkeletonProps} props - The component's properties.
 * @returns {JSX.Element} The Skeleton component.
 */
function Skeleton({size, colour, className, ...props}: SkeletonProps) {
  return (
    <span
      className={cn(skeletonVariants({size, colour}), className)}
      {...props}
    />
  );
}

/**
 * Skeleton component for charts.
 *
 * @param {ChartSkeletonProps} props - The component's properties.
 * @returns {JSX.Element} The ChartSkeleton component.
 */
function ChartSkeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn("ml-20 flex flex-col items-center gap-4 pt-2", className)}
      {...props}
    >
      <div className="align-center h-8 w-1/2 animate-pulse rounded bg-zinc-200" />
      <svg width={700} height={420} aria-label="Bar Chart">
        <g>
          <rect
            x={20}
            y={Math.floor(Math.random() * 191) + 10}
            width={120}
            height={700}
            fill="#D1D5DB"
            className="animate-pulse"
          />
          <rect
            x={150}
            y={Math.floor(Math.random() * 191) + 10}
            width={120}
            height={700}
            fill="#D1D5DB"
            className="animate-pulse"
          />
          <rect
            x={280}
            y={Math.floor(Math.random() * 191) + 10}
            width={120}
            height={700}
            fill="#D1D5DB"
            className="animate-pulse"
          />
          <rect
            x={410}
            y={Math.floor(Math.random() * 191) + 10}
            width={120}
            height={700}
            fill="#D1D5DB"
            className="animate-pulse"
          />
        </g>
      </svg>
    </div>
  );
}

export {Skeleton, ChartSkeleton};
