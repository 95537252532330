import {useCallback, useEffect, useState} from "react";

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {Button} from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

import {SupplierScriptDisplay, SupplierScriptForm} from "@/modules/imremit";
import type {OptionProps, SupplierScript} from "@/modules/imremit";

// Defining a type that extends SupplierScript with an additional 'id' field
export type SupplierScriptItem = SupplierScript & {
  id: number;
};

export function ExpandableScriptItem({
  item,
  index,
  isMutating,
  draggableSupplierScriptItems,
  setdraggableSupplierScriptItems,
  setDeletedSupplierScriptIds,
}: {
  item: SupplierScript;
  index: number;
  isMutating: boolean;
  draggableSupplierScriptItems: SupplierScriptItem[];
  setdraggableSupplierScriptItems: React.Dispatch<
    React.SetStateAction<SupplierScriptItem[]>
  >;
  deletedSupplierScriptIds: string[];
  setDeletedSupplierScriptIds: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const deleteSupplierScript = (
    indexToDelete: number,
    supplierScriptId: string
  ) => {
    const updatedSupplierScriptItems = draggableSupplierScriptItems.filter(
      (_, index) => index !== indexToDelete
    );
    const updatedItemsWithSequenceIds = updatedSupplierScriptItems.map(
      (item, index) => ({
        ...item,
        sequenceId: index + 1,
      })
    );
    setdraggableSupplierScriptItems(updatedItemsWithSequenceIds);
    setDeletedSupplierScriptIds((prevIds) => [...prevIds, supplierScriptId]);
  };

  const [fieldNameValue, setFieldNameValue] = useState<string>(item.fieldName);
  const [fieldValue, setFieldValue] = useState<string>(item.fieldValue);
  const [pageCategoryValue, setpageCategoryValue] = useState<string>(
    item.pageCategory
  );
  const [fieldTypeValue, setFieldTypeValue] = useState<string>(item.fieldType);
  const [tableNameValue, setTableNameValue] = useState<string>(item.tableName);
  const [tableColumnNameValue, setTableColumnNameValue] = useState<string>(
    item.tableColumnName
  );
  const [positionValue, setPositionValue] = useState<string>(item.position);
  const [dropDownValue, setDropDownValue] = useState<string>(
    item.dropDownValue
  );
  const [separatorValue, setSeparatorValue] = useState<string>(item.separator);
  const [dateFormatValue, setDateFormatValue] = useState<string>(
    item.dateFormat
  );
  const [timerValue, setTimerValue] = useState<string>(item.timer.toString());
  const [sequenceIdentifierValue, setSequenceIdentifierValue] =
    useState<string>(item.sequenceIdentifier);
  const [jsCheckValue, setJsCheckValue] = useState<string>(item.jsCheck);
  const [findElementByValue, setFindElementByValue] = useState<string>(
    item.findElementBy
  );
  const [dialogOpenEdit, setDialogOpenEdit] = useState(false);

  const updateSupplierScript = useCallback(() => {
    setDialogOpenEdit(false);
    const updatedSupplierScriptItem = {
      id: index,
      supplierScriptId: item.supplierScriptId,
      supplierId: item.supplierId,
      sequenceId: item.sequenceId,
      fieldName: fieldNameValue,
      fieldValue: fieldValue,
      pageCategory: pageCategoryValue,
      dropDownValue: dropDownValue,
      fieldType: fieldTypeValue,
      position: positionValue,
      separator: separatorValue,
      tableName: tableNameValue,
      tableColumnName: tableColumnNameValue,
      captchaTimer: item.captchaTimer,
      paymentTimer: item.paymentTimer,
      loginSkip: item.loginSkip,
      dateFormat: dateFormatValue,
      timer: timerValue,
      sequenceIdentifier: sequenceIdentifierValue,
      jsCheck: jsCheckValue,
      findElementBy: findElementByValue,
    };

    // update the item in the draggableSupplierScriptItems array without set
    const updatedSupplierScriptItems = [...draggableSupplierScriptItems];
    updatedSupplierScriptItems[index] = updatedSupplierScriptItem;
    setdraggableSupplierScriptItems(updatedSupplierScriptItems);
  }, [
    index,
    draggableSupplierScriptItems,
    setdraggableSupplierScriptItems,
    fieldNameValue,
    fieldValue,
    pageCategoryValue,
    fieldTypeValue,
    tableNameValue,
    tableColumnNameValue,
    positionValue,
    separatorValue,
    dateFormatValue,
    timerValue,
    dropDownValue,
    sequenceIdentifierValue,
    jsCheckValue,
    findElementByValue,
    item,
  ]);

  useEffect(() => {
    setTableNameValue("");
    setTableColumnNameValue("");
    setPositionValue("");
    setSeparatorValue("");
    setDateFormatValue("");
    setDropDownValue("");
    setSequenceIdentifierValue("");
    setJsCheckValue("");
    setFindElementByValue("");
    setFieldValue("");
  }, [fieldTypeValue]);

  const handleInputValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {name, value} = event.target;
      switch (name) {
        case "fieldName":
          setFieldNameValue(value);
          break;
        case "fieldValue":
          setFieldValue(value);
          break;
        case "pageCategory":
          setpageCategoryValue(value);
          break;
        case "fieldType":
          setFieldTypeValue(value);
          break;
        case "position":
          setPositionValue(value);
          break;
        case "separator":
          setSeparatorValue(value);
          break;
        case "sequenceIdentifier":
          setSequenceIdentifierValue(value);
          break;
        case "jsCheck":
          setJsCheckValue(value);
          break;
        case "dropDownValue":
          setDropDownValue(value);
          break;
        default:
          break;
      }
    },
    []
  );

  const handleInputSelectChange = useCallback(
    (script: OptionProps, scriptType: string) => {
      switch (scriptType) {
        case "tableName":
          setTableNameValue(script.value);
          setTableColumnNameValue("");
          break;
        case "tableColumnName":
          setTableColumnNameValue(script.value);
          break;
        case "fieldType":
          setFieldTypeValue(script.value);
          break;
        case "dateFormat":
          setDateFormatValue(script.value);
          break;
        case "timer":
          setTimerValue(script.value);
          break;
        case "findElementBy":
          setFindElementByValue(script.value);
          break;
        default:
          break;
      }
    },
    []
  );

  return (
    <div>
      <div className="flex flex-col">
        <div className="flex w-full flex-wrap">
          <SupplierScriptDisplay label="Page name" value={item.pageCategory} />
          <SupplierScriptDisplay label="Field name" value={item.fieldName} />
          <SupplierScriptDisplay label="X-Path" value={item.fieldValue} />
          <SupplierScriptDisplay label="Field type" value={item.fieldType} />
          <SupplierScriptDisplay label="Table Name" value={item.tableName} />
          <SupplierScriptDisplay
            label="Table Column Name"
            value={item.tableColumnName}
          />
          <SupplierScriptDisplay label="Position" value={item.position} />
          <SupplierScriptDisplay
            label="Default Value"
            value={item.dropDownValue}
          />
          <SupplierScriptDisplay label="Separator" value={item.separator} />
          <SupplierScriptDisplay label="Date format" value={item.dateFormat} />
          <SupplierScriptDisplay
            label="Login Sequence"
            value={item.sequenceIdentifier}
          />
          <SupplierScriptDisplay label="Timer" value={item.timer} />
          <SupplierScriptDisplay label="Js Check" value={item.jsCheck} />
          <SupplierScriptDisplay
            label="Find Element By"
            value={item.findElementBy}
          />
        </div>
      </div>
      <div className="flex flex-row gap-2">
        <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
          <Dialog open={dialogOpenEdit}>
            <DialogTrigger asChild>
              <Button
                className="mt-2"
                size="sm"
                onClick={() => {
                  setDialogOpenEdit(true);
                  setFieldNameValue(item.fieldName);
                  setFieldValue(item.fieldValue);
                  setpageCategoryValue(item.pageCategory);
                  setFieldTypeValue(item.fieldType);
                  setTableNameValue(item.tableName);
                  setTableColumnNameValue(item.tableColumnName);
                  setPositionValue(item.position);
                  setSeparatorValue(item.separator);
                  setDateFormatValue(item.dateFormat);
                  setTimerValue(item.timer.toString());
                  setDropDownValue(item.dropDownValue);
                  setSequenceIdentifierValue(item.sequenceIdentifier);
                  setJsCheckValue(item.jsCheck);
                  setFindElementByValue(item.findElementBy);
                }}
              >
                Edit
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[900px]" removeCloseButton>
              <DialogHeader>
                <DialogTitle>Edit Supplier Script</DialogTitle>
                <DialogDescription>
                  Add your changes to the supplier script here.
                </DialogDescription>
              </DialogHeader>
              <div className="borderorder mb-8 rounded-md border bg-root p-4">
                <div className="mb-10 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
                  <SupplierScriptForm
                    isMutating={isMutating}
                    handleInputValueChange={handleInputValueChange}
                    handleInputSelectChange={handleInputSelectChange}
                    pageCategoryValue={pageCategoryValue}
                    fieldNameValue={fieldNameValue}
                    fieldValue={fieldValue}
                    fieldTypeValue={fieldTypeValue}
                    tableNameValue={tableNameValue}
                    tableColumnNameValue={tableColumnNameValue}
                    positionValue={positionValue}
                    dropDownValue={dropDownValue}
                    separatorValue={separatorValue}
                    dateFormatValue={dateFormatValue}
                    sequenceIdentifierValue={sequenceIdentifierValue}
                    timerValue={timerValue}
                    jsCheckValue={jsCheckValue}
                    findElementByValue={findElementByValue}
                  />
                </div>
              </div>
              <DialogFooter>
                <DialogFooter>
                  <Button
                    className="mr-2"
                    variant={"secondary"}
                    onClick={() => {
                      setDialogOpenEdit(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    onClick={() => {
                      if (timerValue === "") {
                        return;
                      } else if (
                        (fieldTypeValue.trim() === "alert" ||
                          fieldTypeValue.trim() === "dropdown") &&
                        dropDownValue.trim() === ""
                      ) {
                        return;
                      } else if (
                        fieldTypeValue !== "payment_loop_start" &&
                        fieldTypeValue !== "payment_loop_end" &&
                        pageCategoryValue.trim() === ""
                      ) {
                        return;
                      } else if (
                        fieldNameValue.trim() === "" ||
                        fieldTypeValue.trim() === "" ||
                        fieldTypeValue.trim() === "select"
                      ) {
                        return;
                      } else if (
                        fieldTypeValue !== "iframe_exit" &&
                        fieldTypeValue !== "handle_window" &&
                        fieldTypeValue !== "switch_to_previous_tab" &&
                        fieldTypeValue !== "get_reCAPTCHA_v2_response" &&
                        fieldTypeValue !== "insert_reCAPTCHA_v2_response" &&
                        fieldTypeValue !== "input_invoice_numbers_amounts" &&
                        fieldTypeValue !== "payment_loop_start" &&
                        fieldTypeValue !== "payment_loop_end" &&
                        fieldTypeValue !== "click_link_text" &&
                        fieldValue.trim() === ""
                      ) {
                        return;
                      } else if (
                        (fieldTypeValue === "input" ||
                          fieldTypeValue === "dropdown" ||
                          fieldTypeValue === "date_picker_table" ||
                          fieldTypeValue === "label_text") &&
                        (tableNameValue.trim() === "" ||
                          tableNameValue.trim() === "select" ||
                          tableColumnNameValue.trim() === "")
                      ) {
                        return;
                      } else if (
                        (fieldTypeValue === "input" ||
                          fieldTypeValue === "dropdown" ||
                          fieldTypeValue === "date_picker_table" ||
                          fieldTypeValue === "error" ||
                          fieldTypeValue === "confirmation_number" ||
                          fieldTypeValue === "alert" ||
                          fieldTypeValue === "pop_up_msg_ok_yes" ||
                          fieldTypeValue === "checkBox" ||
                          fieldTypeValue === "captcha_checkbox" ||
                          fieldTypeValue === "captcha_image" ||
                          fieldTypeValue === "captcha_image_value" ||
                          fieldTypeValue === "button" ||
                          fieldTypeValue === "label_text" ||
                          fieldTypeValue === "iframe" ||
                          fieldTypeValue === "login_button" ||
                          fieldTypeValue === "scroll_to_element" ||
                          fieldTypeValue === "switch_to_tab" ||
                          fieldTypeValue === "switch_to_window" ||
                          fieldTypeValue === "switch_to_previous_tab" ||
                          fieldTypeValue === "search_invoices_in_div") &&
                        findElementByValue.trim() === ""
                      ) {
                        return;
                      } else {
                        updateSupplierScript();
                      }
                    }}
                  >
                    Save
                  </Button>
                </DialogFooter>
              </DialogFooter>
            </DialogContent>
          </Dialog>
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button
                className="mt-2 hover:bg-destructive/80"
                variant="destructive"
                size="sm"
              >
                <span className="sr-only">Delete</span>
                Delete
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>
                  Are you sure you want to delete this script?
                </AlertDialogTitle>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel>No</AlertDialogCancel>
                <Button
                  className="whitespace-nowrap"
                  onClick={() => {
                    deleteSupplierScript(index, item.supplierScriptId);
                  }}
                >
                  Yes
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </div>
      </div>
    </div>
  );
}
