// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type ProxyPayIMRemitPremiumTableColumnsType} from "@/modules/imremit";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface ProxyPayIMRemitPremiumTableColumnsStoreState {
  storeProxyPayIMRemitPremiumTableColumns: ProxyPayIMRemitPremiumTableColumnsType | null;
  setStoreProxyPayIMRemitPremiumTableColumns: (
    storeProxyPayIMRemitPremiumTableColumns: ProxyPayIMRemitPremiumTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for ProxyPayIMRemitPremium table columns
export const useProxyPayIMRemitPremiumTableColumnsStore = create(
  persist<ProxyPayIMRemitPremiumTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeProxyPayIMRemitPremiumTableColumns: null,

      // Getter function to retrieve the current state
      getStoreProxyPayIMRemitPremiumTableColumns: () =>
        get().storeProxyPayIMRemitPremiumTableColumns,

      // Setter function to update the state
      setStoreProxyPayIMRemitPremiumTableColumns: (
        storeProxyPayIMRemitPremiumTableColumns
      ) => {
        set(() => ({storeProxyPayIMRemitPremiumTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "ProxyPayIMRemitPremium-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useProxyPayIMRemitPremiumTableColumns = () =>
  useProxyPayIMRemitPremiumTableColumnsStore((state) => {
    return {
      storeProxyPayIMRemitPremiumTableColumns:
        state.storeProxyPayIMRemitPremiumTableColumns,
    };
  });
