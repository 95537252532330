import {kyApiFn} from "@/lib/ky";
import {type SRUploadMetaType} from "@/modules/statement-recon";

export function createUploadMetaSR(data: SRUploadMetaType) {
  return kyApiFn<SRUploadMetaType>(
    `statement-recon/v1/statement-file`,
    "post",
    {json: data}
  );
}
