import {z} from "zod";

export const statementReconGetDetailsSchema = z.object({
  created_timestamp: z.string(),
  updated_timestamp: z.string().optional().nullable(),
  id: z.string(),
  im_sr_match_type: z.string(),
  invoice_amount: z.string(),
  invoice_date: z.string(),
  invoice_paid_amount: z.string().nullable(),
  invoice_payment_date: z.string().nullable(),
  facility_id: z.string(),
  invoice_id: z.string(),
  supplier_id: z.string(),
  invoice_erp_unique_id: z.string().nullable(),
  invoice_number: z.string(),
  invoice_payment_number: z.string(),
  po_number: z.string(),
  statement_id: z.string(),
  supplier_name: z.string(),
  supplier_number: z.string(),
  invoice_payment_method: z.string(),
  payment_term: z.string().nullable(),
  void_id: z.string().nullable(),
  void_date: z.string().nullable(),
  im_sr_customer_statement_id: z.string(),
  buyer_external_id: z.string(),
  buyer_name: z.string(),
  invoice_po_number: z.string().nullable(),
  buyer_id: z.string(),
  invoice_status_id: z.string(),
  suffix: z.string().nullable(),
  compared: z.boolean(),
  credit_pair: z.boolean(),
  cancel_id: z.string().nullable(),
  cancel_date: z.string().nullable(),
  invoice_type: z.string().nullable(),
  payment_status: z.string().nullable(),
  insert_date: z.date(),
  update_date: z.date(),
  im_sr_statement_id: z.string(),
  st_invoice_number: z.string(),
  st_invoice_date: z.string(),
  st_invoice_amount: z.string(),
  st_po_number: z.string(),
  isreconciled: z.boolean(),
});

export type StatementReconGetDetailsType = z.infer<
  typeof statementReconGetDetailsSchema
>;

export const statementReconGetDetailsColumnsSchema = z.object({
  invoice_amount: z.boolean(),
  invoice_date: z.boolean(),
  invoice_paid_amount: z.boolean(),
  invoice_payment_date: z.boolean(),
  created_timestamp: z.boolean(),
  facility_id: z.boolean(),
  invoice_id: z.boolean(),
  supplier_id: z.boolean(),
  updated_timestamp: z.boolean(),
  invoice_erp_unique_id: z.boolean(),
  invoice_number: z.boolean(),
  invoice_payment_method: z.boolean(),
  invoice_payment_number: z.boolean(),
  invoice_po_number: z.boolean(),
  buyer_id: z.boolean(),
  invoice_status_id: z.boolean(),
  suffix: z.boolean(),
  compared: z.boolean(),
  credit_pair: z.boolean(),
  cancel_id: z.boolean(),
  cancel_date: z.boolean(),
  invoice_type: z.boolean(),
  payment_status: z.boolean(),
  insert_date: z.boolean(),
  update_date: z.boolean(),
  payment_term: z.boolean(),
  void_id: z.boolean(),
  void_date: z.boolean(),
  im_sr_customer_statement_id: z.boolean(),
  buyer_external_id: z.boolean(),
  buyer_name: z.boolean(),
  supplier_number: z.boolean(),
  supplier_name: z.boolean(),
  im_sr_statement_id: z.boolean(),
  st_invoice_number: z.boolean(),
  st_invoice_date: z.boolean(),
  st_invoice_amount: z.boolean(),
  st_po_number: z.boolean(),
  isreconciled: z.boolean(),
  im_sr_match_type: z.boolean(),
});

export type StatementReconGetDetailsColumnsType = z.infer<
  typeof statementReconGetDetailsColumnsSchema
>;
