import {useEffect, useMemo, useRef, useState} from "react";

import {useMutation} from "@tanstack/react-query";
import {useParams} from "@tanstack/react-router";
import {LucideRotateCcw} from "lucide-react";

import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import {Button} from "@/components/ui/button";
import {Spinner} from "@/components/ui/spinner";
import {Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {calculateUserRoles, useKeyCloakInstanceStore} from "@/modules/auth";
import {
  paymentAutoProcessFn,
  paymentProcessFn,
  useWebPaymentHistory,
  type PaymentCommentsFormDTO,
  type ProxyPayViewPageSchema,
} from "@/modules/imremit";

interface ProxyPayCommentsDataProps {
  proxyPayViewData: ProxyPayViewPageSchema;
  isPending: boolean;
  disabledProcessButton: boolean;
}

export function ProxyPayProcessPayment({
  proxyPayViewData,
  isPending,
  disabledProcessButton,
}: ProxyPayCommentsDataProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogCount, setDialogCount] = useState(0);
  const {reProcess} = useParams({
    from: "/app/imremit/proxy-pay-dashboard/$paymentId/view/$reProcess",
  });

  /**
   * @returns {string}
   * Fetching logged user role
   */
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  /**
   * @returns {Object}
   * Payload for the payment process / re-process
   */
  const processPaymentData: PaymentCommentsFormDTO = {
    role: userRoleDisplay,
    userId: storeKeyCloakInstance?.tokenParsed?.name as string,
    comments: "Process the payment & update the status!",
  };

  const processStatus =
    proxyPayViewData.customerStatus == "Open" && proxyPayViewData.errorMessage
      ? "Re-Process"
      : proxyPayViewData.customerStatus == "Open"
      ? "Process"
      : "Re-Process";

  const processPayment: boolean = processStatus === "Process" ? true : false;

  /**
   * @default {boolean} - NULL
   */

  // Extract the `rdpIpaddress` field from the tokenParsed object
  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;
  const rdpId: string =
    (parsedUserInfo?.rdpIpaddress as string) || "http://10.183.111.12:4444";

  /**
   * Re-process Payment
   * @type {{
   *   isPending: boolean,
   *   mutate: Function
   * }}
   */
  const paymentProcess = useMutation({
    mutationFn: () =>
      paymentProcessFn(
        processPaymentData,
        processPayment,
        proxyPayViewData.paymentDetailId,
        rdpId
      ),
    onSuccess: (response) => {
      console.log("paymentProcessResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Payment processed successfully",
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to process payment",
      });
    },
  });

  /**
   * @type {boolean}
   */
  const isMutating = paymentProcess.isPending;

  // Process the payment when the `reProcess` is true.
  if (reProcess === "true" && !isMutating && !isDialogOpen && dialogCount < 3) {
    if (dialogCount < 3) {
      // setIsDialogOpen(true);
      setDialogCount((prevCount) => prevCount + 1);
    } else {
      // setIsDialogOpen(false);
    }
  }

  // Roles array
  const userRoles = userRoleDisplay.split(",");

  /**
   * @returns {Object}
   * Payload for the payment auto re-process
   */
  const autoProcessPaymentData: PaymentCommentsFormDTO = {
    role: userRoles[0],
    userId: storeKeyCloakInstance?.tokenParsed?.name as string,
    comments: "Auto Re-process payment & update the status!",
  };

  // Get the `payByWebTransactionHistoryId` id from web-payment-history to re-process the payment.
  const paymentHistoryQuery = useWebPaymentHistory(
    proxyPayViewData.paymentDetailId
  );

  const payByWebTransactionHistoryId = useMemo(() => {
    const responseData = paymentHistoryQuery.data?.content ?? [];

    const result = responseData
      .filter((resp) => {
        return (
          resp.reason === "Captcha is Failed" ||
          resp.reason === "Supplier Login Failed"
        );
      })
      .map((respData) => respData.payByWebTransactionHistoryId);

    const lastIndex = result.length - 1;
    console.log("tnx-id", result[lastIndex]);

    return result[lastIndex]; // return the `payByWebTransactionHistoryId` last index data
  }, [paymentHistoryQuery.data?.content]);

  /**
   * Auto Re-process - Captcha Faield
   * payByWebTransactionHistoryId - payment failed reason should be `Captcha is Failed` | `Supplier Login Failed`
   * customerStatus - `Open`
   */
  const paymentAutoProcess = useMutation({
    mutationFn: () =>
      paymentAutoProcessFn(
        autoProcessPaymentData,
        payByWebTransactionHistoryId,
        rdpId
      ),
    onSuccess: (response) => {
      console.log("paymentAutoProcessResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Payment processed successfully",
      });
      setIsDialogOpen(false);
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      setIsDialogOpen(false);

      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to process payment",
      });
    },
  });

  const paymentAutoProcessRef = useRef(paymentAutoProcess.mutate);

  useEffect(() => {
    if (payByWebTransactionHistoryId && proxyPayViewData.scriptFlag && rdpId) {
      setIsDialogOpen(true);
      paymentAutoProcessRef.current();
    }
  }, [payByWebTransactionHistoryId, proxyPayViewData.scriptFlag, rdpId]);

  return (
    <>
      {proxyPayViewData.scriptFlag &&
        proxyPayViewData.statusName != "In Progress Payment" && (
          <Button
            className="bg-theme text-root hover:bg-theme/80 active:bg-theme/90"
            disabled={
              isPending ||
              disabledProcessButton ||
              isMutating ||
              dialogCount >= 3
            }
            onClick={() => {
              paymentProcess.mutate();
            }}
          >
            <span className="sr-only">{processStatus}</span>
            {isMutating ? (
              <Spinner size="xs" />
            ) : (
              <LucideRotateCcw className="mr-2 size-4" />
            )}

            <Paragraph>{processStatus}</Paragraph>
          </Button>
        )}

      <AlertDialog open={isDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle className="flex gap-2 text-center">
              <Paragraph>Re-processing your payment .</Paragraph>
              {paymentAutoProcess.isPending ? <Spinner size="sm" /> : ""}
            </AlertDialogTitle>
          </AlertDialogHeader>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
