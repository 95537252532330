// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type invoiceMappingTableColumnsType} from "@/modules/invoices";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface InvoiceMappingTableColumnsStoreState {
  storeInvoiceMappingTableColumns: invoiceMappingTableColumnsType | null;
  setStoreInvoiceMappingTableColumns: (
    storeInvoiceMappingTableColumns: invoiceMappingTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for InvoiceMapping table columns
export const useInvoiceMappingTableColumnsStore = create(
  persist<InvoiceMappingTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeInvoiceMappingTableColumns: null,

      // Getter function to retrieve the current state
      getStoreInvoiceMappingTableColumns: () =>
        get().storeInvoiceMappingTableColumns,

      // Setter function to update the state
      setStoreInvoiceMappingTableColumns: (storeInvoiceMappingTableColumns) => {
        set(() => ({storeInvoiceMappingTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "admin-mapping-payment-file-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useInvoiceMappingTableColumns = () =>
  useInvoiceMappingTableColumnsStore((state) => {
    return {
      storeInvoiceMappingTableColumns: state.storeInvoiceMappingTableColumns,
    };
  });
