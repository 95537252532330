import {useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {useParams} from "@tanstack/react-router";
import {format} from "date-fns";
import {
  CheckIcon,
  ChevronsUpDownIcon,
  InfoIcon,
  LucideBadgeCheck,
  LucideBadgeX,
  MessageSquarePlusIcon,
  MessagesSquareIcon,
  ScaleIcon,
  XIcon,
} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {Card} from "@/components/ui/card";
import {Command, CommandGroup, CommandItem} from "@/components/ui/command";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {Label} from "@/components/ui/label";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {ScrollArea} from "@/components/ui/scroll-area";
import {Spinner} from "@/components/ui/spinner";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {Textarea} from "@/components/ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {cn} from "@/lib/utils";
import {GetKeycloakUserName} from "@/modules/admin";
import {
  AccessPolicyWrapper,
  calculateUserRoles,
  KeycloakRoleEnum,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {
  paymentSubmittedFn,
  ProxyPayCommentsFormSchema,
  usePaymentManagementComments,
  type PaymentManagementCommentType,
  type ProxyPayViewPageSchema,
} from "@/modules/imremit";

import {
  CustomerStatus,
  PaymentModes,
  PaymentRequestPaymentMode,
} from "@/utils/constants";

interface ProxyPayCommentsProps {
  proxyPayComments: PaymentManagementCommentType;
}
interface ProxyPayCommentsDataProps {
  proxyPayViewData: ProxyPayViewPageSchema;
}

const closePaymentStatusList: string[] = [
  CustomerStatus.Declined,
  CustomerStatus.InvoiceAmountMismatch,
  CustomerStatus.InvoiceNumberMismatch,
  CustomerStatus.PaymentAlreadyTaken,
  CustomerStatus.PartialPaymentAlreadyTaken,
  CustomerStatus.EscalationWithBank,
  CustomerStatus.InProgress,
];

const escalationWithBankStatusList: string[] = [
  CustomerStatus.Declined,
  CustomerStatus.InvoiceAmountMismatch,
  CustomerStatus.InvoiceNumberMismatch,
  CustomerStatus.PaymentAlreadyTaken,
  CustomerStatus.PartialPaymentAlreadyTaken,
];

const failedProcessStatus = [
  {value: "DECLINED", label: "Declined"},
  {value: "INVOICE_AMOUNT_MISMATCH", label: "Invoice Amount Mismatch"},
  {value: "INVOICE_NUMBER_MISMATCH", label: "Invoice Number Mismatch"},
  {value: "PAYMENT_ALREADY_TAKEN", label: "Payment Already Taken"},
  {
    value: "PARTIAL_PAYMENT_ALREADY_TAKEN",
    label: "Partial Payment Already Taken",
  },
];
export function ProxyPayCommentsData({
  proxyPayViewData,
}: ProxyPayCommentsDataProps) {
  // Declaring the state for comment dialog
  const [openDialog, setOpenDialog] = useState(false);

  //Fetching logged user role
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  //Get user role
  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  //Get comments
  const {data: proxyPayComments} = usePaymentManagementComments(
    proxyPayViewData.paymentDetailId
  );

  const {paymentId} = useParams({
    from: "/app/imremit-lite/proxy-pay-dashboard/$paymentId/view",
  });

  const proxyPaymentCommentsFormMethods = useForm<
    z.infer<typeof ProxyPayCommentsFormSchema>
  >({
    defaultValues: {
      userId: storeKeyCloakInstance?.tokenParsed?.sub as string,
      role: userRoleDisplay.split(",")[0],
      paymentStatus: "",
    },
    resolver: zodResolver(ProxyPayCommentsFormSchema),
  });

  const paymentSubmittedMutation = useMutation({
    mutationFn: (data: z.infer<typeof ProxyPayCommentsFormSchema>) => {
      const {paymentStatus, ...filteredData} = data;
      let payStatus = "";

      if (!paymentStatus) {
        // if status is not set in the form
        if (
          proxyPayViewData.paymentMode === PaymentModes.PayByPhone ||
          proxyPayViewData.paymentRequestPaymentMode ===
            PaymentRequestPaymentMode.ManualPBW
        ) {
          if (
            proxyPayViewData.customerStatus === CustomerStatus.Paid ||
            proxyPayViewData.customerStatus ===
              CustomerStatus.PaidPendingVerification
          ) {
            payStatus =
              proxyPayViewData.statusName === "Failed to Reconcile"
                ? "RECON_FAILED"
                : "";
          }
        }

        if (
          proxyPayViewData.customerStatus === CustomerStatus.ReOpened ||
          proxyPayViewData.customerStatus === CustomerStatus.Open
        ) {
          payStatus = "IN_PROGRESS";
        }
      } else {
        payStatus = paymentStatus;
      }

      return paymentSubmittedFn(filteredData, paymentId, payStatus);
    },
    onSuccess: (response) => {
      proxyPaymentCommentsFormMethods.reset();
      console.log("paymentSubmittedResponse", response);

      toast({
        variant: "success",
        title: "Success!",
        description: "Comment added successfully",
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add comment",
      });
    },
  });

  const isMutating = paymentSubmittedMutation.isPending;

  /**
   * Handles payment submit form submission.
   *
   * @param {z.infer<typeof ProxyPayCommentsFormSchema>} data - Form data.
   * @returns {void}
   */
  function paymentSubmit(data: z.infer<typeof ProxyPayCommentsFormSchema>) {
    const {comments, ...rest} = data;

    const processedData = {
      ...rest,
      comments: comments.trim(),
    };
    paymentSubmittedMutation.mutate(processedData);
  }

  if (!proxyPayComments)
    return (
      <Button variant="outline" disabled>
        <span className="sr-only">Comments</span>
        <MessagesSquareIcon className="mr-2 size-4" />
        <Paragraph>Comments</Paragraph>
      </Button>
    );
  const {content: unsortedProxyPayComments} = proxyPayComments;
  const sortedProxyPayComments = unsortedProxyPayComments.sort(
    (a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
  );

  return (
    <div className="flex flex-col gap-4 pt-0 md:flex-row md:justify-between">
      <Dialog open={openDialog} onOpenChange={setOpenDialog}>
        <DialogTrigger asChild>
          <Button
            variant="outline"
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            <span className="sr-only">Comments</span>
            <MessagesSquareIcon className="mr-2 size-4" />
            <Paragraph>Comments</Paragraph>
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[800px]" removeCloseButton>
          <DialogHeader>
            <DialogTitle>Comments</DialogTitle>
            <Button
              variant={"ghost"}
              className="absolute right-4 top-4 h-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              <XIcon className="text-default h-4 w-4" />
              <span className="sr-only">Close</span>
            </Button>
          </DialogHeader>
          <Card>
            <ScrollArea className="max-h-[320px] rounded-lg">
              <Table>
                <TableCaption>
                  {sortedProxyPayComments.length == 0 && "No Comments Found!"}
                </TableCaption>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[100px]">Date/Time</TableHead>
                    <TableHead>Username</TableHead>
                    <TableHead>Comment</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {sortedProxyPayComments.map((proxyPayComments, index) => (
                    <ProxypayComments
                      key={index}
                      proxyPayComments={proxyPayComments}
                    />
                  ))}
                </TableBody>
              </Table>
            </ScrollArea>
          </Card>

          <Form {...proxyPaymentCommentsFormMethods}>
            <form
              onSubmit={proxyPaymentCommentsFormMethods.handleSubmit((data) => {
                const {comments, ...rest} = data;

                const processedData = {
                  ...rest,
                  comments: comments.trim(),
                };
                paymentSubmittedMutation.mutate(processedData); // Submit the data
              })}
            >
              <Label className="flex space-x-2">
                <Paragraph>Add a comment:</Paragraph>
                {proxyPayViewData.paymentMode === PaymentModes.PayByWeb &&
                proxyPayViewData.customerStatus === CustomerStatus.Failed ? (
                  <TooltipProvider delayDuration={200}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="cursor-pointer">
                          <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <div className="flex justify-between space-x-4">
                          For manual payment add key word : <br /> Manual
                          payment done#*****(space)
                          <br /> Type keyword : man or # it will auto fill the
                          whole keyword.
                        </div>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ) : (
                  ""
                )}
              </Label>
              <FormField
                name="comments"
                render={() => (
                  <FormItem>
                    <FormControl>
                      <Textarea
                        id="comments"
                        placeholder="Type your comment here."
                        disabled={isMutating}
                        aria-disabled={isMutating}
                        autoCapitalize="none"
                        autoComplete="comments"
                        autoCorrect="off"
                        {...proxyPaymentCommentsFormMethods.register(
                          "comments"
                        )}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="grid grid-cols-3 justify-end gap-4 pt-4">
                {/* Resolve Payment */}
                {(proxyPayViewData.paymentMode === PaymentModes.PayByPhone ||
                  (proxyPayViewData.paymentMode === PaymentModes.PayByWeb &&
                    proxyPayViewData.paymentRequestPaymentMode !=
                      PaymentRequestPaymentMode.AutomaticPBW)) &&
                  proxyPayViewData.statusName === "Failed to Reconcile" && (
                    <AccessPolicyWrapper
                      unauthorizedFallback={null}
                      policyActions={[
                        KeycloakRoleEnum.READ_PROXYPAY_RESOLVE_PAYMENT_BTN,
                      ]}
                    >
                      <Button
                        className="mb-2"
                        aria-label="Resolve Payment"
                        type="button"
                        variant="secondary"
                        aria-disabled={isMutating}
                        disabled={
                          isMutating ||
                          !proxyPaymentCommentsFormMethods.formState.isValid
                        }
                        onClick={proxyPaymentCommentsFormMethods.handleSubmit(
                          (data) => {
                            paymentSubmit({
                              ...data,
                              paymentStatus: "PAID",
                            });
                          }
                        )}
                      >
                        {isMutating ? (
                          <Spinner size="xs" />
                        ) : (
                          <LucideBadgeCheck className="mr-2 size-4" />
                        )}
                        Resolve Payment
                      </Button>
                    </AccessPolicyWrapper>
                  )}

                {/* Close Payment Button */}
                {closePaymentStatusList.includes(
                  proxyPayViewData.customerStatus
                ) && (
                  <Button
                    className="mb-2 gap-2"
                    aria-label="Payment Closed"
                    type="button"
                    variant="destructive"
                    aria-disabled={isMutating}
                    disabled={
                      isMutating ||
                      !proxyPaymentCommentsFormMethods.formState.isValid
                    }
                    onClick={proxyPaymentCommentsFormMethods.handleSubmit(
                      (data) => {
                        paymentSubmit({
                          ...data,
                          paymentStatus: "CLOSED",
                        });
                      }
                    )}
                  >
                    {isMutating ? (
                      <Spinner size="xs" />
                    ) : (
                      <LucideBadgeX className="size-4" />
                    )}
                    Close Payment
                  </Button>
                )}
                {/* Escalate with Bank Button */}
                {escalationWithBankStatusList.includes(
                  proxyPayViewData.customerStatus
                ) && (
                  <Button
                    className="mb-2 gap-2"
                    aria-label="Escalate with Bank"
                    type="button"
                    variant="secondary"
                    aria-disabled={isMutating}
                    disabled={
                      isMutating ||
                      !proxyPaymentCommentsFormMethods.formState.isValid
                    }
                    onClick={proxyPaymentCommentsFormMethods.handleSubmit(
                      (data) => {
                        paymentSubmit({
                          ...data,
                          paymentStatus: "ESCALATION_WITH_BANK",
                        });
                      }
                    )}
                  >
                    {isMutating ? (
                      <Spinner size="xs" />
                    ) : (
                      <ScaleIcon className="size-4" />
                    )}
                    Escalate with Bank
                  </Button>
                )}
                {/* Status Select */}
                {proxyPayViewData.customerStatus ===
                  CustomerStatus.InProgress &&
                proxyPayViewData.paymentMode != PaymentModes.PayByEmail &&
                proxyPayViewData.paymentRequestPaymentMode !=
                  PaymentRequestPaymentMode.AutomaticPBW ? (
                  <FormField
                    name="paymentStatus"
                    render={({field}) => (
                      <FormItem className="flex flex-col">
                        <Popover>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                aria-disabled={isMutating}
                                variant="outline"
                                role="combobox"
                                disabled={
                                  isMutating ||
                                  !proxyPaymentCommentsFormMethods.formState
                                    .isValid
                                }
                                className={cn(
                                  "justify-between",

                                  !field.value && "text-accent-foreground"
                                )}
                              >
                                {field.value
                                  ? failedProcessStatus.find(
                                      (status) => status.value === field.value
                                    )?.label
                                  : "Select Status..."}
                                <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                          <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                            <Command>
                              <CommandGroup>
                                {failedProcessStatus.map((status) => (
                                  <CommandItem
                                    key={status.value}
                                    value={status.label}
                                    onSelect={() => {
                                      proxyPaymentCommentsFormMethods.setValue(
                                        "paymentStatus",
                                        status.value
                                      );
                                    }}
                                  >
                                    <CheckIcon
                                      className={cn(
                                        "mr-2 size-4",
                                        status.value === field.value
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    {status.label}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </Command>
                          </PopoverContent>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ) : null}
                <AccessPolicyWrapper
                  unauthorizedFallback={null}
                  policyActions={[
                    KeycloakRoleEnum.CREATE_ADD_PROXY_PAYMENT_COMMENT_LITE,
                  ]}
                >
                  <Button
                    type="submit"
                    aria-disabled={isMutating}
                    variant="default"
                    className="gap-2"
                    disabled={
                      isMutating ||
                      !proxyPaymentCommentsFormMethods.formState.isValid
                    }
                  >
                    {isMutating ? (
                      <Spinner size="xs" />
                    ) : (
                      <MessageSquarePlusIcon className="size-4" />
                    )}
                    Add comment
                  </Button>
                </AccessPolicyWrapper>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

/**
 * @type {React.FC<ProxyPayCommentsProps>}
 */
const ProxypayComments: React.FC<ProxyPayCommentsProps> = ({
  proxyPayComments,
}) => (
  <TableRow>
    <TableCell className="font-medium">
      {format(new Date(proxyPayComments.dateTime), "MMMM dd, yyyy kk:mm:ss")}
    </TableCell>
    <TableCell>
      {<GetKeycloakUserName userId={proxyPayComments.userId} />}
    </TableCell>
    <TableCell>
      {proxyPayComments.comments.length > 40 ? (
        <CommentsHover comments={proxyPayComments.comments} />
      ) : (
        proxyPayComments.comments
      )}
    </TableCell>
  </TableRow>
);

interface CommentsHoverProps {
  comments: string;
}
/**
 * @type {React.FC<CommentsHoverProps>}
 */
const CommentsHover: React.FC<CommentsHoverProps> = ({comments}) => (
  <HoverCard>
    <HoverCardTrigger asChild>
      <Button variant="link">{comments.slice(0, 40)}...</Button>
    </HoverCardTrigger>
    <HoverCardContent className="h-[150px] w-[300px] overflow-scroll">
      {comments}
    </HoverCardContent>
  </HoverCard>
);
