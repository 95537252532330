import {useQuery} from "@tanstack/react-query";

import {keycloakKyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  keycloakUserSessionsQueryKeys,
  type keycloakUserResetPasswordFormType,
  type KeycloakUserSessionType,
} from "@/modules/admin";

// Defining a type based on the function `getUserSessions`
type QueryFnType = typeof getUserSessions;

// Function to get all realm users
export function getUserSessions(userId: string) {
  const realm =
    (import.meta.env.VITE_KEYCLOAK_REALM as string | undefined) ?? "dev";
  return keycloakKyApiFn<KeycloakUserSessionType[]>(
    `admin/realms/${realm}/users/${userId}/sessions`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useGetUserSessions(
  userId: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: keycloakUserSessionsQueryKeys.byUserId(userId),
    queryFn: () => getUserSessions(userId),
    enabled: !!userId,
  });
}

// Function to remove user sessions
export const removeUserSessionsFn = (userId: string) => {
  const realm =
    (import.meta.env.VITE_KEYCLOAK_REALM as string | undefined) ?? "dev";

  return keycloakKyApiFn(
    `admin/realms/${realm}/users/${userId}/logout`,
    "post"
  );
};

// Function to reset the user cerdentials
export const resetUserCerdentialsFn = (
  resetUserDTO: keycloakUserResetPasswordFormType,
  userId: string
) => {
  const realm =
    (import.meta.env.VITE_KEYCLOAK_REALM as string | undefined) ?? "dev";

  //Request DTO
  const requestUserDTOData = {
    type: resetUserDTO.type,
    value: resetUserDTO.value,
    temporary: resetUserDTO.temporary,
  };

  return keycloakKyApiFn(
    `admin/realms/${realm}/users/${userId}/reset-password`,
    "put",
    {
      json: requestUserDTOData,
    }
  );
};

export const updateUserGroupsFn = (userId: string, groupId: string) => {
  const realm =
    (import.meta.env.VITE_KEYCLOAK_REALM as string | undefined) ?? "dev";

  return keycloakKyApiFn(
    `admin/realms/${realm}/users/${userId}/groups/${groupId}`,
    "put"
  );
};
