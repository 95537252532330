import {useCallback, useEffect, useMemo} from "react";

import {useNavigate, useParams} from "@tanstack/react-router";

import {Heading2, Paragraph} from "@/components/ui/typography";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import type {PaginationMetaType} from "@/lib/ky";
import {queryClient} from "@/lib/query-client";
import {ImREmitLiteRoutesSchema} from "@/lib/routes/types";
import {
  useImRemitLiteCustomerStore,
  usePreferencesStore,
  useRoutesStore,
} from "@/lib/stores";
import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useAuthorization,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {
  SelectCustomerFormLite,
  SelectCustomerSectionLite,
} from "@/modules/customers";
import {
  imREmitQueryKeys,
  searchImREmits,
  useSearchimREmits,
} from "@/modules/imremit";
import {
  PaymentManagementDataTable,
  PaymentManagementExportDataLite,
  useGetPaymentManagementColumns,
  usePaymentManagementMetaStore,
} from "@/modules/imremit-lite";

import {FallbackMessages} from "@/utils/constants";
import {replacePageQueryParam} from "@/utils/functions";
import {generateQueryParams} from "@/utils/query-params-generator";

/**
 * Custom Hook: Manage the active route for the payment managment dashboard.
 */
function useManagePaymentManagementRoute() {
  const {setimREmitLiteRoute} = useRoutesStore();

  useEffect(() => {
    // Set the active route based on predefined route schema
    setimREmitLiteRoute(
      ImREmitLiteRoutesSchema.Values["/app/imremit-lite/payment-management"]
    );
  }, [setimREmitLiteRoute]);
}

/**
 * Custom Hook: Manage pagination and user preferences for the payment management dashbaord.
 *
 * @param {(pageNumber: number) => Promise<void>} setPageIndex - Function to update the current page index.
 * @returns {Object} - Number of rows per page and the current criteria for storing payment management data.
 */
function usePaymentManagementPaginationAndPreferences(
  setPageIndex: (pageNumber: number) => Promise<void>
) {
  const {numRowsPerPage} = usePreferencesStore();
  const {
    storePaymentManagementCriteria,
    storePaymentManagementPaginationMeta,
    storePaymentManagementSortMeta,
    setHandleOnPageChange,
  } = usePaymentManagementMetaStore();

  useEffect(() => {
    // Reset the page index if it is out of bounds
    if (storePaymentManagementPaginationMeta) {
      if (
        storePaymentManagementPaginationMeta.pageNumber < 0 ||
        storePaymentManagementPaginationMeta.pageNumber + 1 >
          storePaymentManagementPaginationMeta.totalPages
      ) {
        void setPageIndex(0);
      }
    }

    // Set the handler for page change events
    setHandleOnPageChange(setPageIndex);
  }, [
    setHandleOnPageChange,
    storePaymentManagementPaginationMeta,
    setPageIndex,
  ]);

  return {
    numRowsPerPage,
    storePaymentManagementCriteria,
    storePaymentManagementSortMeta,
  };
}

/**
 * Custom Hook: Fetch and prepare payment management data.
 *
 * @param {string} queryParams - The query parameters for the API request.
 * @returns {Object} - The content and metadata for the payment management, along with the API query object.
 */
function useFetchAndPreparePaymentManagement(queryParams: string) {
  const {storePaymentManagementPaginationMeta} =
    usePaymentManagementMetaStore();

  const {imRemitLiteStoreCustomer} = useImRemitLiteCustomerStore();
  const externalId = imRemitLiteStoreCustomer?.externalId;
  const searchPaymentManagementQuery = useSearchimREmits(
    externalId,
    queryParams
  );

  const paymentManagementContent = useMemo(() => {
    if (!searchPaymentManagementQuery.data) {
      return [];
    }
    return searchPaymentManagementQuery.data.content;
  }, [searchPaymentManagementQuery.data]);

  const paymentManagementPaginationMeta = useMemo(() => {
    if (!searchPaymentManagementQuery.data) {
      return storePaymentManagementPaginationMeta;
    }

    return searchPaymentManagementQuery.data.meta;
  }, [searchPaymentManagementQuery.data, storePaymentManagementPaginationMeta]);

  // Prefetch the next page of payment management
  useEffect(() => {
    if (paymentManagementPaginationMeta) {
      // Create the query params for the next page
      const nextPageQueryParams = replacePageQueryParam(
        queryParams,
        (paymentManagementPaginationMeta.pageNumber + 1).toString()
      );

      // Prefetch the next page of payment management
      void queryClient.prefetchQuery({
        queryKey: imREmitQueryKeys.bySearch(externalId, nextPageQueryParams),
        queryFn: () => searchImREmits(externalId, nextPageQueryParams),
      });
    }
  }, [queryParams, paymentManagementPaginationMeta, externalId]);

  return {
    paymentManagementContent,
    paymentManagementPaginationMeta,
    searchPaymentManagementQuery,
  };
}

/**
 * Custom Hook: Manage payment management pagination metadata.
 *
 * @param {PaginationMetaType | null} paymentManagementPaginationMeta - The pagination metadata for PaymentManagement.
 */
function useManagePaymentManagementPaginationMeta(
  paymentManagementPaginationMeta: PaginationMetaType | null
) {
  const {setStorePaymentManagementPaginationMeta} =
    usePaymentManagementMetaStore();

  useEffect(() => {
    // Update the pagination metadata in the store if available
    if (paymentManagementPaginationMeta) {
      setStorePaymentManagementPaginationMeta(paymentManagementPaginationMeta);
    }
  }, [
    paymentManagementPaginationMeta,
    setStorePaymentManagementPaginationMeta,
  ]);
}

/**
 * PaymentManagementPage Component
 *
 * This component serves as the main interface for payment management.
 * It manages local state, performs route setup, and data fetching.
 * It conditionally renders child components based on data availability and state.
 */
export function PaymentManagementPage() {
  // Fetch the page number from the URL params
  const {pmPage} = useParams({
    from: "/app/imremit-lite/payment-management/$pmPage/dashboard",
  });

  const navigate = useNavigate();

  const handleNavigationPagination = useCallback(
    (pageNumber: number) =>
      navigate({
        to: "/app/imremit-lite/payment-management/$pmPage/dashboard",
        params: {
          pmPage: (pageNumber + 1).toString(),
        },
      }),
    [navigate]
  );

  // Fetch customer data from the customer store
  const {imRemitLiteStoreCustomer} = useImRemitLiteCustomerStore();

  const paymentManagementPageNumber = useMemo(() => {
    // Reset the page index if it is out of bounds
    if (!pmPage || parseInt(pmPage) <= 0 || isNaN(parseInt(pmPage))) {
      void handleNavigationPagination(0);
      return 0;
    }

    return parseInt(pmPage) - 1;
  }, [pmPage, handleNavigationPagination]);

  // Fetch user preferences and pagination criteria
  const {
    numRowsPerPage,
    storePaymentManagementCriteria,
    storePaymentManagementSortMeta,
  } = usePaymentManagementPaginationAndPreferences(handleNavigationPagination);

  const searchPaymentManagementQueryParams = useMemo(
    () =>
      generateQueryParams({
        params: storePaymentManagementCriteria || {},
        page: paymentManagementPageNumber,
        size: numRowsPerPage,
        sort: storePaymentManagementSortMeta,
      }),
    [
      numRowsPerPage,
      paymentManagementPageNumber,
      storePaymentManagementCriteria,
      storePaymentManagementSortMeta,
    ]
  );

  // Fetch and prepare the Payment Management data
  const {
    paymentManagementContent,
    paymentManagementPaginationMeta,
    searchPaymentManagementQuery,
  } = useFetchAndPreparePaymentManagement(searchPaymentManagementQueryParams);

  // Manage the active route for this page
  useManagePaymentManagementRoute();

  // Update pagination metadata in the store
  useManagePaymentManagementPaginationMeta(paymentManagementPaginationMeta);

  const paymentManagementColumns = useGetPaymentManagementColumns(
    searchPaymentManagementQueryParams
  );

  const {checkPoliciesAccess} = useAuthorization();

  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;
  let userEmail: string = "";

  !parsedUserInfo
    ? (userEmail = FallbackMessages.NOT_APPLICABLE)
    : (userEmail = parsedUserInfo.email as string);

  // Handle unauthorized access
  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_PAYMENT_MANAGEMENT_LITE])) {
    return <NotAuthorizedPage />;
  }

  // If the customer is not yet selected, render the SelectCustomerSectionLite
  if (!imRemitLiteStoreCustomer) {
    return <SelectCustomerSectionLite />;
  }

  const tableColumns = paymentManagementColumns
    .filter((column) => column.id !== "actions")
    .map((column) => {
      // @ts-expect-error - ignore TypeScript error for assignment
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      let value = column.accessorKey;
      switch (value) {
        case "orgId":
          value = "organizationId";
          break;
        case "endDate":
          value = "postDate";
          break;
        case "initiatedDate":
          value = "sentDate";
          break;
        case "statusName":
          value = "status";
          break;
        case "description":
          value = "statusDescription";
          break;
        case "totalAmountSent":
          value = "sentAmount";
          break;
        default:
          break;
      }

      return {
        label: value as string,
        value: value as string,
      };
    });

  /**
   * Render PaymentManagementViews
   *
   * Once all data is available, render the main PaymentManagementViews component.
   * Pass relevant props for rendering payment management functionalities.
   *
   * | Prop                | Type            | Description                                                   |
   * |---------------------|-----------------|---------------------------------------------------------------|
   * | imREmits            | Object          | Data content for imREmit payments                             |
   * | paymentViewState    | Object          | Local state object for managing the payment view              |
   * | paymentViewDispatch | Function        | Dispatch function to update the local state for payment view  |
   */
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.READ_PAYMENT_MANAGEMENT_LITE]}
    >
      <section className="flex items-center justify-between">
        <div className="flex flex-col">
          <Heading2>Payment Management</Heading2>
          <Paragraph>Manage your imREmit lite payments here.</Paragraph>
        </div>

        <div className="flex gap-2">
          <SelectCustomerFormLite />
          <AccessPolicyWrapper
            unauthorizedFallback={null}
            policyActions={[
              KeycloakRoleEnum.CREATE_PAYMENT_MANAGEMENT_EXPORT_LITE,
            ]}
          >
            <PaymentManagementExportDataLite
              userEmail={userEmail}
              tableColumns={tableColumns}
            />
          </AccessPolicyWrapper>
        </div>
      </section>
      <PaymentManagementDataTable
        columns={paymentManagementColumns}
        data={paymentManagementContent}
        isPending={searchPaymentManagementQuery.isPending}
        isSuccess={searchPaymentManagementQuery.isSuccess}
        isError={searchPaymentManagementQuery.isError}
      />
    </AccessPolicyWrapper>
  );
}
