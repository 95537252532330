import {useEffect} from "react";

import {useQuery} from "@tanstack/react-query";
import {Link, useParams} from "@tanstack/react-router";
import axios from "axios";
import {ArrowRightIcon} from "lucide-react";

import {buttonVariants} from "@/components/ui/button";
import {Heading2, Paragraph} from "@/components/ui/typography";

import {ImREmitRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import type {APIPDFResponseType} from "@/modules/imremit";
import {
  UploadedPDFsDataTable,
  uploadedPDFTableColumns,
} from "@/modules/imremit";

export function ViewPDFUpload() {
  const {setimREmitRoute} = useRoutesStore();

  useEffect(() => {
    setimREmitRoute(ImREmitRoutesSchema.Values["/app/imremit/pdf-upload"]);
  }, [setimREmitRoute]);

  const {externalId} = useParams({
    from: "/app/imremit/pdf-upload/$fileId/$externalId",
  });

  const uploadedPDFsQuery = useQuery({
    queryKey: ["uploadedPDFs", externalId],
    queryFn: async () => {
      const baseURL = (() => {
        switch (import.meta.env.VITE_KEYCLOAK_REALM as string) {
          case "prod":
            return "invoicetracker";
          case "dev":
            return "ntdevtracker";
          case "qa":
            return "ntqatracker";
          case "staging":
            return "ntstagingtracker";
          default:
            return "ntdevtracker";
        }
      })();

      const {data}: APIPDFResponseType = await axios.get(
        `https://${baseURL}.iterationm.com/pdf-ocr/v1/${externalId}`
      );

      console.log(data);
      return data;
    },
  });

  const uploadedPDFs = uploadedPDFsQuery.data?.data.content || [];

  return (
    <section className="flex flex-col gap-4">
      <div className="flex justify-between gap-4">
        <div className="flex flex-col">
          <Heading2>View Uploaded PDFs</Heading2>
          <Paragraph>
            This is the view uploaded PDF page. You will be able to view your
            uploaded PDF here.
          </Paragraph>
        </div>
        <Link
          to="/app/imremit/pdf-upload"
          className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
        >
          Back to PDF upload
          <span className="sr-only">Back to PDF upload</span>
          <ArrowRightIcon className="size-4" />
        </Link>
      </div>
      <UploadedPDFsDataTable
        columns={uploadedPDFTableColumns}
        data={uploadedPDFs}
        isSuccess={uploadedPDFsQuery.isSuccess}
        isPending={uploadedPDFsQuery.isPending}
        isError={uploadedPDFsQuery.isError}
      />
    </section>
  );
}
