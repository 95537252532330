import {kyApiFn} from "@/lib/ky";
import {type UpdateParticipantRegisterFormDTO} from "@/modules/admin";

export const updateCustomerParticipantRegisterFn = (
  updateCustomerParticipantRegisterDTO: UpdateParticipantRegisterFormDTO,
  externalId: string,
  participantRegisterId: number
) =>
  kyApiFn(
    `api/e-payable/management/${externalId}/organizations/${participantRegisterId}`,
    "put",
    {
      json: updateCustomerParticipantRegisterDTO,
    }
  );
