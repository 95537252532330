import {useMemo, useState} from "react";

import {useMutation} from "@tanstack/react-query";
import {useNavigate} from "@tanstack/react-router";
import {
  CaptionsOffIcon,
  FilePlus2Icon,
  MoveLeftIcon,
  MoveRightIcon,
} from "lucide-react";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {Card, CardContent, CardHeader} from "@/components/ui/card";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {Label} from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {Separator} from "@/components/ui/separator";
import {Heading2, Heading3} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import type {CustomerFormDTO, CustomerFormSchema} from "@/modules/admin";
import {
  AddPaymentRunnerConfigForm,
  AddReconRunnerConfigForm,
  allRunnerConfigsTableColumns,
  AuditRunnerConfigForm,
  CustomerOnboardingTabs,
  CustomerRunnerConfigEnum,
  editCustomerFn,
  EditReconRunnerConfigForm,
  RunnerConfigsDataTable,
  TempRunnerConfigForm,
} from "@/modules/admin";
import {useGetCustomerById} from "@/modules/customers";
import type {ImREmitDelimiter} from "@/modules/imremit";
import {
  useDateFormatDetails,
  useGetImREmitDelimiters,
  useGetImREmitDocumentFormats,
  useGetImREmitRunnerTypes,
  useGetRunnerConfig,
} from "@/modules/imremit";

import {ModuleNames} from "@/utils/constants";

export interface RunnerTypeProps {
  value?: CustomerRunnerConfigEnum;
  setValue: (value: CustomerRunnerConfigEnum | null) => void;
}

/**
 * Transforms a runner type item to a select option format.
 * @param item - The runner type item with id and optional name.
 * @returns An object with `value` as string and `label`.
 */
function toRunnerTypeOption(item: {
  batchRunnerTypeId: number;
  runnerTypeName: string;
}) {
  return {
    value: item.batchRunnerTypeId.toString(),
    label: item.runnerTypeName,
  };
}

/**
 * Transforms a date format item to a select option format.
 * @param item - The date format item with id and optional name.
 * @returns An object with `value` as string and `label`.
 */
export function toDateFormatOption(item: {
  dateFormatId: number;
  dateFormatValue: string;
}) {
  return {
    value: item.dateFormatId.toString(),
    label: item.dateFormatValue,
  };
}

/**
 * Transforms a document format item to a select option format.
 * @param item - The document format item with id and optional name.
 * @returns An object with `value` as string and `label`.
 */
function toDocumentFormatOption(item: {
  documentFormatId: number;
  formatName: string;
}) {
  return {
    value: item.documentFormatId.toString(),
    label: item.formatName,
  };
}

/**
 * Transforms a delimiter types item to a select option format.
 * @param item - The delimiter types item with id and optional name.
 * @returns An object with `value` as string and `label`.
 */
function toDelimiterTypeOption(item: ImREmitDelimiter) {
  return {
    value: item.delimiterTypeId?.toString(),
    label: item.typeValue + "-" + item.value,
  };
}

/**
 * Custom hook for fetching and memoizing runner types.
 * @returns A memoized array of runner types options.
 */
export function useRunnerTypes() {
  const {data} = useGetImREmitRunnerTypes();
  // Memoizes the transformed runner types data for performance optimization
  return useMemo(() => data?.content.map(toRunnerTypeOption) ?? [], [data]);
}

/**
 * Custom hook for fetching and memoizing date formats.
 * @returns A memoized array of date formats options.
 */
export function useDateFormats() {
  const {data} = useDateFormatDetails();
  // Memoizes the transformed date formats data for performance optimization
  return useMemo(() => data?.content.map(toDateFormatOption) ?? [], [data]);
}

/**
 * Custom hook for fetching and memoizing document formats.
 * @returns A memoized array of document formats options.
 */
export function useDocumentFormats(format: string) {
  const {data} = useGetImREmitDocumentFormats(format);
  // Memoizes the transformed document formats data for performance optimization
  return useMemo(() => data?.content.map(toDocumentFormatOption) ?? [], [data]);
}

/**
 * Custom hook for fetching and memoizing delimiter types.
 * @returns A memoized array of delimiter types options.
 */
export function useDelimiterTypes() {
  const {data} = useGetImREmitDelimiters();
  // Memoizes the transformed delimiter types data for performance optimization
  return useMemo(() => data?.content.map(toDelimiterTypeOption) ?? [], [data]);
}

/**
 * RunnerConfigFormProps Interface
 *
 * @interface RunnerConfigFormProps
 * @property {number} externalId - Gets the customer ID.
 * @property {string} module -  Gets the module name
 */
interface RunnerConfigFormProps {
  externalId: string;
  module: string;
}

/**
 * RunnerConfigForm Component
 *
 * This component renders the RunnerConfigForm form and handles its behavior.
 *
 * @param {RunnerConfigFormProps} props - Properties passed to the component
 * @returns {JSX.Element} - Rendered component
 */

export function RunnerConfigForm({externalId, module}: RunnerConfigFormProps) {
  const [runnerValue, setRunnerValue] =
    useState<CustomerRunnerConfigEnum | null>(null);
  const [isConfigFormOpen, setIsConfigFormOpen] = useState(false);

  const navigate = useNavigate();
  const params = {
    externalId: externalId.toString(),
    module,
  };

  // Get all runner configs
  const allRunnerConfigsQuery = useGetRunnerConfig(externalId);
  const allRunnerConfigs = allRunnerConfigsQuery.data?.content ?? [];

  const getRunnerName = (runnerValue: CustomerRunnerConfigEnum | null) => {
    switch (runnerValue) {
      case CustomerRunnerConfigEnum.PAYMENT_RUNNER:
        return "Payment Runner";
      case CustomerRunnerConfigEnum.RECON_RUNNER:
        return "Recon Runner";
      case CustomerRunnerConfigEnum.AUDIT_RUNNER:
        return "Audit Runner";
      case CustomerRunnerConfigEnum.TEMP_RUNNER:
        return "Temp Runner";
      default:
        return "";
    }
  };

  const runnerName = getRunnerName(runnerValue);

  const reconRunnerConfigExists = allRunnerConfigs.some(
    (config) =>
      config.batchRunnerType === parseInt(CustomerRunnerConfigEnum.RECON_RUNNER)
  );

  return (
    <Collapsible
      open={isConfigFormOpen}
      onOpenChange={(isOpen) => {
        setIsConfigFormOpen(isOpen);
      }}
    >
      <section className="mb-2 flex w-full flex-col items-start justify-between gap-2 xl:flex-row xl:items-center">
        <Heading2 className="flex gap-2">Runner Configuration</Heading2>
        <CustomerOnboardingTabs />
      </section>
      <Card>
        <CardContent>
          <div className="mb-6 mt-4 flex justify-between">
            <RunnerTypeField setValue={setRunnerValue} />
            {!!runnerValue && (
              <CollapsibleTrigger asChild>
                {isConfigFormOpen ? (
                  <Button
                    type="button"
                    variant="secondary"
                    className="flex items-center gap-2"
                  >
                    <span className="sr-only">Close {runnerName} Config</span>
                    Close {runnerName} Config
                    <CaptionsOffIcon className="size-4" />
                  </Button>
                ) : (
                  <Button type="button" className="flex items-center gap-2">
                    <span className="sr-only">Add {runnerName} Config</span>
                    Open {runnerName} Config
                    <FilePlus2Icon className="size-4" />
                  </Button>
                )}
              </CollapsibleTrigger>
            )}
          </div>
          <CollapsibleContent>
            {runnerValue === CustomerRunnerConfigEnum.PAYMENT_RUNNER && (
              <AddPaymentRunnerConfigForm externalId={externalId} />
            )}
            {runnerValue === CustomerRunnerConfigEnum.RECON_RUNNER &&
              (reconRunnerConfigExists ? (
                <EditReconRunnerConfigForm externalId={externalId} />
              ) : (
                <AddReconRunnerConfigForm externalId={externalId} />
              ))}
            {runnerValue === CustomerRunnerConfigEnum.AUDIT_RUNNER && (
              <AuditRunnerConfigForm externalId={externalId} />
            )}
            {runnerValue === CustomerRunnerConfigEnum.TEMP_RUNNER && (
              <TempRunnerConfigForm externalId={externalId} />
            )}

            <Separator />
          </CollapsibleContent>

          <Card className="mt-4">
            <CardHeader>
              <Heading3>Saved Runner Configurations</Heading3>
            </CardHeader>
            <CardContent>
              <RunnerConfigsDataTable
                columns={allRunnerConfigsTableColumns}
                data={allRunnerConfigs}
                isSuccess={allRunnerConfigsQuery.isSuccess}
                isPending={allRunnerConfigsQuery.isPending}
                isError={allRunnerConfigsQuery.isError}
              />
            </CardContent>
          </Card>

          <div className="flex items-center justify-end space-x-2 p-2">
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                module === ModuleNames.imremit &&
                  void navigate({
                    to: "/app/admin/customer-management/imremit/$externalId/onboarding/participant-register",
                    params,
                    replace: true,
                  });
              }}
            >
              <span className="sr-only">Previous page</span>
              <MoveLeftIcon className="mr-2" />
              Previous
            </Button>

            {/* Updates imREmit module onboarded to true. can be reused for dupes */}
            <UpdateModuleSubscription
              externalId={externalId}
              dataLength={allRunnerConfigs.length}
              moduleId={93}
            />
          </div>
        </CardContent>
      </Card>
    </Collapsible>
  );
}

function RunnerTypeField({setValue}: RunnerTypeProps) {
  const allRunnerTypes = useRunnerTypes();

  return (
    <div className="mt-2 flex w-64 flex-col space-y-2">
      <Label
        className="text-sm font-semibold leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
        htmlFor="batchRunnerType"
        showMandatoryAsterisk
      >
        Runner Type:
      </Label>

      <Select
        onValueChange={(value: CustomerRunnerConfigEnum) => {
          setValue(value);
        }}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select Runner Type..." />
        </SelectTrigger>
        <SelectContent>
          {allRunnerTypes.map((item) => (
            <SelectItem key={item.value} value={item.value}>
              {item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}

function UpdateModuleSubscription({
  externalId,
  dataLength,
  moduleId,
}: {
  externalId: string;
  dataLength: number;
  moduleId: number;
}) {
  const {
    data,
    isFetched: customerDetailsAreFetched,
    isError,
    error,
  } = useGetCustomerById(externalId);

  const updateCustomerModuleSubscriptionMutation = useMutation({
    mutationFn: (data: z.infer<typeof CustomerFormSchema>) => {
      return editCustomerFn(data);
    },

    onSuccess: (editCustomerModuleSubscriptionResponse) => {
      console.log(
        "editCustomerModuleSubscriptionResponse",
        editCustomerModuleSubscriptionResponse
      );
      toast({
        variant: "success",
        title: "Success!",
        description: "Module subscription updated successfully",
      });
    },
    onError: (error: unknown) => {
      console.error("Error during mutation:", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update module subscription",
      });
    },
  });

  const navigate = useNavigate();

  if (isError) {
    console.error("Error fetching customer details: ", error);
    throw new Error("Error fetching customer details: ", error);
  }

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (customerDetailsAreFetched && data) {
      const updatedData = {
        ...data.content,
        moduleSubscriptions: data.content.moduleSubscriptions.map((module) => {
          if (module.moduleId === moduleId) {
            return {
              moduleId: module.moduleId,
              moduleName: module.moduleName,
              moduleOnboarded: true,
              buyerId: module.buyerId,
            };
          }
          return module;
        }),
      };

      updateCustomerModuleSubscriptionMutation.mutate(
        updatedData as CustomerFormDTO
      );

      void navigate({
        to: "/app/admin/customer-management",
        replace: true,
      });
    } else {
      console.log("Customer details are not fetched yet.");
    }
  };

  return (
    <Button
      disabled={dataLength === 0 || !customerDetailsAreFetched || !data}
      type="button"
      onClick={handleClick}
    >
      Complete
      <MoveRightIcon className="ml-2" />
      <span className="sr-only">Next page</span>
    </Button>
  );
}
