import {useEffect, useState} from "react";

import {Link} from "@tanstack/react-router";
import {cva} from "class-variance-authority";
import {FileQuestionIcon, HelpCircleIcon} from "lucide-react";

import {Button, buttonVariants} from "@/components/ui/button";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {Heading4, Paragraph} from "@/components/ui/typography";
import {LoadingSection} from "@/components/craft/loading-section";

import {cn} from "@/lib/utils";

/**
 * Variants for the help button.
 * @typedef {Object} HELP_BUTTON_VARIANTS
 */
const HELP_BUTTON_VARIANTS = {
  helpChat: {
    isOpen: "hover:bg-destructive active:bg-destructive-foreground/50",
    default: "hover:bg-muted active:bg-neutral-foreground/50",
  },
};

/**
 * Variants for the help icon.
 * @typedef {Object} HELP_ICON_VARIANTS
 */
const HELP_ICON_VARIANTS = {
  helpChat: {
    isOpen:
      "text-success-foreground hover:text-destructive-foreground active:text-destructive-foreground",
    default:
      "text-theme hover:text-neutral-foreground active:neutral-foreground",
  },
};

/**
 * Class Variance Authority setup for help button.
 */
const helpButtonVariants = cva("p-1 hover:cursor-pointer", {
  variants: HELP_BUTTON_VARIANTS,
  defaultVariants: {
    helpChat: "default",
  },
});

/**
 * Class Variance Authority setup for help icon.
 */
const helpIconVariants = cva("size-8", {
  variants: HELP_ICON_VARIANTS,
  defaultVariants: {
    helpChat: "default",
  },
});

/**
 * Sets the display style of a DOM element.
 * @param {HTMLElement | null} element - The DOM element to set the display style of.
 * @param {string} displayStyle - The CSS display style.
 */
const setChatDisplay = (element: HTMLElement | null, displayStyle: string) => {
  if (element) {
    element.style.display = displayStyle;
  }
};

/**
 * A React component for managing a Help Chat Section.
 * Provides options to toggle and load live chat.
 */
export function HelpChatSection() {
  const [isHelpChatOpen, setIsHelpChatOpen] = useState(false);
  const [isHelpChatLoading, setIsHelpChatLoading] = useState(false);

  /**
   * Toggles the help chat open/close state.
   */
  const handleToggleHelpChat = () => {
    setIsHelpChatOpen((prev) => !prev);
  };

  /**
   * React effect to manage the live chat state.
   */
  useEffect(() => {
    let chatWidgetDiv = document.getElementById("chat-widget-container");

    /**
     * Loads chat script from a source URL and injects it into the DOM.
     */
    const loadChatScript = () => {
      setIsHelpChatLoading(true);
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://saleschat.co/widget.js?_id=6112817068e95";
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => {
        chatWidgetDiv = document.getElementById("chat-widget-container");
        setChatDisplay(chatWidgetDiv, "block");
        setIsHelpChatLoading(false);
      };
    };

    isHelpChatOpen
      ? chatWidgetDiv
        ? setChatDisplay(chatWidgetDiv, "block")
        : loadChatScript()
      : setChatDisplay(chatWidgetDiv, "none");
  }, [isHelpChatOpen]);

  return (
    <TooltipProvider delayDuration={50} skipDelayDuration={0}>
      <section className="flex flex-col items-center px-1">
        <Tooltip>
          <TooltipTrigger asChild>
            {isHelpChatLoading ? (
              <LoadingSection sectionSize="sm" />
            ) : (
              <Button
                variant="ghost"
                aria-disabled={isHelpChatLoading}
                disabled={isHelpChatLoading}
                className={cn(
                  helpButtonVariants({
                    helpChat: isHelpChatOpen ? "isOpen" : "default",
                  })
                )}
                onClick={handleToggleHelpChat}
              >
                <HelpCircleIcon
                  className={cn(
                    helpIconVariants({
                      helpChat: isHelpChatOpen ? "isOpen" : "default",
                    })
                  )}
                />
              </Button>
            )}
          </TooltipTrigger>
          <TooltipContent>
            <TooltipArrow />
            <Heading4 className="mb-1">Help Chat</Heading4>
            <Paragraph>
              Press to{" "}
              {isHelpChatOpen ? (
                <span className="font-bold">close</span>
              ) : (
                <span className="font-bold">open</span>
              )}{" "}
              live help chat.
            </Paragraph>
          </TooltipContent>
        </Tooltip>
        <Paragraph className="text-xs font-semibold">Live Chat</Paragraph>
      </section>
    </TooltipProvider>
  );
}

export function HelpSection() {
  return (
    <TooltipProvider delayDuration={50} skipDelayDuration={0}>
      <section className="flex flex-col items-center px-1">
        <Tooltip>
          <TooltipTrigger>
            <Link
              to="/app/help"
              className={cn(
                buttonVariants({variant: "ghost"}),
                helpButtonVariants()
              )}
            >
              <FileQuestionIcon className={cn(helpIconVariants())} />
            </Link>
          </TooltipTrigger>
          <TooltipContent>
            <TooltipArrow />
            <Heading4 className="mb-1">Help Page</Heading4>
            <Paragraph>Press to go to help page.</Paragraph>
          </TooltipContent>
        </Tooltip>
        <Paragraph className="text-xs font-semibold">Help Page</Paragraph>
      </section>
    </TooltipProvider>
  );
}
