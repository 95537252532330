import {
  auditFileColumns,
  BankFileDataTable,
  useAuditFileDetails,
  type BankAuditMappingFileSpecsType,
} from "@/modules/imremit";

/**
 * AuditFileList Component
 *
 * This component provides the report for Audit File Mapping
 * and rendering the Audit File Mapping  report table.
 */
export function AuditFileList() {
  // Fetch the Audit File Mapping report data using the custom hook `useAuditFileDetails`
  const searchAuditFileResponse = useAuditFileDetails();

  // Destructure the `data` property, focusing on its `content` attribute
  const auditFiletData = searchAuditFileResponse.data?.content || [];

  /**
   * `auditFileAllData` array is holding all the `fileSpecs[]` data in single array- not a good approch
   * This is temporary basis, will update in future
   * */
  const auditFileAllData: BankAuditMappingFileSpecsType[] = [];
  auditFiletData.map((data) => {
    data.fileSpecs.map((fileSpecsData) => {
      fileSpecsData.masterMappingName = data.masterMappingName;
      auditFileAllData.push(fileSpecsData);
    });
  });

  // Render the  Audit File Mapping report table when the  data available
  return (
    <BankFileDataTable
      columns={auditFileColumns}
      data={auditFileAllData}
      isPending={searchAuditFileResponse.isPending}
      isSuccess={searchAuditFileResponse.isSuccess}
      isError={searchAuditFileResponse.isError}
    />
  );
}
