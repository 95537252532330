import {
  AlertCircleIcon,
  BarChart4Icon,
  FileCogIcon,
  PackageIcon,
  UserCogIcon,
  UserSquareIcon,
} from "lucide-react";

import {SubrouteNavigation} from "@/components/craft/subroute-navigation";

import {AdminRoutesSchema} from "@/lib/routes/types";
import {KeycloakRoleEnum} from "@/modules/auth";

// Define an array with the data for each route button. This includes the 'to' route,
// the 'icon' to be displayed, and the 'label' for the button.
// This array holds the configuration for each SubrouteNavigationButton
// Each object in the array represents a button in the navigation menu
const routes = [
  {
    to: AdminRoutesSchema.Values["/app/admin/user-management"],
    icon: <UserCogIcon className="h-10 w-10 pl-2" />,
    label: "User Management",
    actionRole: KeycloakRoleEnum.READ_USER_MANAGEMENT,
  },
  {
    to: AdminRoutesSchema.Values["/app/admin/master-role-settings"],
    icon: <FileCogIcon className="h-10 w-10 pl-2" />,
    label: "Master Role Settings",
    actionRole: KeycloakRoleEnum.READ_MASTER_ROLE_SETTINGS,
  },
  {
    to: AdminRoutesSchema.Values["/app/admin/customer-management"],
    icon: <UserSquareIcon className="h-10 w-10 pl-2" />,
    label: "Customer Management",
    actionRole: KeycloakRoleEnum.READ_CUSTOMER_MANAGEMENT,
  },
  {
    to: AdminRoutesSchema.Values["/app/admin/customer-module-management"],
    icon: <PackageIcon className="h-10 w-10 pl-2" />,
    label: "Customer Module Management",
    actionRole: KeycloakRoleEnum.READ_CUSTOMER_MODULE_MANAGEMENT,
    fontSize: "sm" as const,
  },
  {
    to: AdminRoutesSchema.Values["/app/admin/alert-management"],
    icon: <AlertCircleIcon className="h-10 w-10 pl-2" />,
    label: "Alert Management",
    actionRole: KeycloakRoleEnum.READ_ALERT_MANAGEMENT,
  },
  {
    to: AdminRoutesSchema.Values["/app/admin/mis"],
    icon: <BarChart4Icon className="h-10 w-10 pl-2" />,
    label: "MGMT Information System",
    actionRole: KeycloakRoleEnum.READ_CUSTOMER_MANAGEMENT,
  },
  // {
  //   to: AdminRoutesSchema.Values["/app/admin/user-role-relation"],
  //   icon: <UsersIcon className="h-10 w-10 pl-2" />,
  //   label: "User Role Relation",
  //   actionRole: KeycloakRoleEnum.READ_USER_ROLE_MANAGEMENT,
  // },
];

interface AdminSubrouteNavigationProps {
  layout?: "horizontal" | "vertical";
}

// This component renders the SubrouteNavigation component with the routes array
// as a prop. It also passes the 'navigationAccessibleLabel' and
// 'navigationAriaLabelledby' props to the SubrouteNavigation component.
export function AdminSubrouteNavigation(
  {layout}: AdminSubrouteNavigationProps = {layout: "horizontal"}
) {
  return (
    <SubrouteNavigation
      routes={routes}
      layout={layout}
      navigationAccessibleLabel="Admin Subroute Navigation"
      navigationAriaLabelledby="adminSubrouteNavHeader"
    />
  );
}
