import {useEffect, useMemo} from "react";

import {Link, useParams} from "@tanstack/react-router";
import {ArrowRightIcon} from "lucide-react";

import {buttonVariants} from "@/components/ui/button";
import {Heading2, Paragraph} from "@/components/ui/typography";

import {StatementReconRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore, useStatementReconCustomerStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {calculateUserRoles, useKeyCloakInstanceStore} from "@/modules/auth";
import {
  SelectCustomerSectionStatementRecon,
  SelectSupplierSection,
} from "@/modules/customers";
import {
  ReconciliationResultsDataTable,
  srFileHistoryTableColumns,
  useReconciliationResultByFileId,
} from "@/modules/statement-recon";

import {KeycloakRoles} from "@/utils/constants";

/**
 * Custom Hook: Manage the active route for the Statement Recon FileHistory page
 */
function useStatementReconStatementSearchRoute() {
  const {setStatementReconRoute} = useRoutesStore();

  useEffect(() => {
    // Set the active route based on predefined route schema
    setStatementReconRoute(
      StatementReconRoutesSchema.Values["/app/statement-recon/file-history"]
    );
  }, [setStatementReconRoute]);
}

export function StatementReconiliationResultsPage() {
  useStatementReconStatementSearchRoute();

  const {fileId} = useParams({
    from: "/app/statement-recon/file-history/$fileId/result",
  });

  const {statementReconStoreCustomer} = useStatementReconCustomerStore();
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  const FileHistorySRQuery = useReconciliationResultByFileId(fileId);
  const FileHistorySRData = FileHistorySRQuery.data?.content || [];

  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  const isASupplierUser = useMemo(
    () => userRoleDisplay === KeycloakRoles.SupplierEnablement,
    [userRoleDisplay]
  );

  if (!statementReconStoreCustomer) {
    if (isASupplierUser) {
      return <SelectSupplierSection />;
    } else {
      return <SelectCustomerSectionStatementRecon />;
    }
  }

  return (
    <>
      <div className="flex items-center justify-between gap-4 pb-2">
        <div className="flex flex-col">
          <Heading2>Statement Recon Result</Heading2>
          <Paragraph>
            View the reconciliation results here for the selected statement.
          </Paragraph>
        </div>
        <Link
          to="/app/statement-recon/file-history"
          className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
        >
          Back to statement search
          <span className="sr-only">Back to statement search</span>
          <ArrowRightIcon className="size-4" />
        </Link>
      </div>

      <ReconciliationResultsDataTable
        columns={srFileHistoryTableColumns}
        data={FileHistorySRData}
        isSuccess={FileHistorySRQuery.isSuccess}
        isPending={FileHistorySRQuery.isPending}
        isError={FileHistorySRQuery.isError}
      />
    </>
  );
}
