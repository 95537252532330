import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  paymentManagementCommentsKeys,
  type PaymentManagementCommentType,
} from "@/modules/imremit";

type QueryFnType = typeof getPaymentManagementComments;

// Function to search failedAlerts
export function getPaymentManagementComments(id?: string) {
  return kyApiFn<PaymentManagementCommentType[]>(
    `api/e-payable/payment-details/${id}/comments`,
    "get"
  );
  // TODO: validate data
}

// Custom hook to fetch and cache failedAlerts data using react-query
export function usePaymentManagementComments(
  id?: string,
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: paymentManagementCommentsKeys.byId(id), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getPaymentManagementComments(id), // Setting the function to be used for fetching data
    enabled: !!id, // Setting the query to be enabled only if the search query string is not empty
  });
}
