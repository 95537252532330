import {z} from "zod";

const moduleSchema = z.object({
  moduleId: z.number(),
  moduleName: z
    .string()
    .min(1, "Module Name is required")
    .max(140, "Module Name must not exceed 140 characters."),
  buyerId: z.number(),
  moduleOnboarded: z.boolean().nullable().default(false),
});

export const customerSchema = z.object({
  id: z.number(),
  externalId: z.string(),
  buyerName: z.string(),
  status: z.string(),
  zipCode: z.string().nullish(),
  billingType: z.string().nullish(),
  paymentType: z.string().nullish(),
  settlementTerms: z.string().nullish(),
  paymentMethod: z.string().nullish(),
  cycleDate: z.string().nullish(),
  invoiceDay: z.string().nullish(),
  contactName: z.string().nullish(),
  address: z.string().nullish(),
  state: z.string().nullish(),
  city: z.string().nullish(),
  phone: z.string().nullish(),
  email: z.string().nullish(),
  bill: z.string().nullish(),
  mccTemplateOptions: z.string().nullish(),
  reportingTool: z.string().nullish(),
  organizationId: z.string().nullish(),
  accountNumberMasking: z.boolean().nullish(),
  accountNumberMapping: z.boolean().nullish(),
  responseFileDataMasking: z.string().nullish(),
  connectivityMethod: z.string().nullish(),
  pidmapping: z.string().nullish(),
  multiselectpidmapping: z.string().nullish(),
  country: z.string().nullish(),
  epayManagerAssigned: z.string().nullish(),

  // MODULE SUBSCRIPTION
  modules: z.array(moduleSchema).optional(),

  //CUSTOMER PROFILE
  customerProfile: z.object({
    buyerProfileAccountNumberMasking: z.boolean().nullish(),
    buyerProfileAddress1: z.string().nullish(),
    buyerProfileAddress2: z.string().nullish(),
    buyerProfileAddress3: z.string().nullish(),
    buyerProfileZip: z.string().max(140).nullish(),
    buyerProfileCountry: z.string().max(2).nullish(),
    pidMapping: z.string().nullish(),
    multiSelectPidMapping: z.string().nullish(),
    buyerProfileBill: z.string().nullish(),
    buyerProfileBillingType: z.string().nullish(),
    buyerEpayMail: z.string().nullish(),
    buyerEpayMailPassword: z.string().max(140).nullish(),
    buyerZeroDollarFlag: z.boolean().nullish(),
    buyerProfilePaymentMethod: z.string().nullish(),
    buyerProfilePaymentType: z.string().nullish(),
    buyerProfileInvoiceDay: z.string().nullish(),
    buyerProfileMccTemplate: z.string().nullish(),
    buyerProfileAssociation: z.string().nullish(),
    buyerProfileCompanyName: z.string().nullish(),
    buyerProfileConnectivityMethod: z.string().nullish(),
    buyerProfileContactName: z.string().nullish(),
    buyerProfileCycleDate: z.string().nullish(),
    buyerProfileEmail: z.string().nullish(),
    buyerProfilePhone: z.string().nullish(),
    buyerProfileCompanyType: z.string().nullish(),
    buyerProfileSettlementTerms: z.string().nullish(),
    buyerProfileOptions: z.string().nullish(),
    buyerProfileReportingTool: z.string().nullish(),
    buyerProfileResponseDataMasking: z.string().nullish(),
    buyerProfileAccountNumberMapping: z.boolean().nullish(),
    buyerProfileOrgId: z.string().nullish(),
    buyerEpayManager: z.string().nullish(),
    buyerCity: z.string().nullish(),
    buyerState: z.string().nullish(),
  }),
});

export const customerFetchSchema = z.object({
  id: z.number(),
  externalId: z.string().nullable().optional(),
  buyerName: z.string(),
  status: z.string(),
  zipCode: z.string().nullable().optional(),
  billingType: z.string().nullable().optional(),
  paymentType: z.string().nullable().optional(),
  settlementTerms: z.string().nullable().optional(),
  paymentMethod: z.string().nullable().optional(),
  cycleDate: z.string().nullable().optional(),
  invoiceDay: z.string().nullable().optional(),
  contactName: z.string().nullable().optional(),
  address: z.string().nullable().optional(),
  state: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  phone: z.string().nullable().optional(),
  email: z.string().nullable().optional(),
  bill: z.string().nullable().optional(),
  mccTemplateOptions: z.string().nullable().optional(),
  reportingTool: z.string().nullable().optional(),
  organizationId: z.string().nullable().optional(),
  accountNumberMasking: z.boolean().nullable().optional(),
  responseFileDataMasking: z.string().nullable().optional(),
  connectivityMethod: z.string().nullable().optional(),
  pidmapping: z.string().nullable().optional(),
  multiselectpidmapping: z.string().nullable().optional(),
  country: z.string().nullable().optional(),

  // MODULE SUBSCRIPTION
  modules: z.array(moduleSchema).optional(),
});

export type CustomerType = z.infer<typeof customerSchema>;

export const customerProfileSchema = z.object({
  participantId: z.number(),
  profileId: z.number(),
  accountNumberMapping: z.boolean(),

  programType: z.string().optional(),
  billingType: z.string().optional(),
  paymentType: z.string().optional(),
  settlementTerms: z.string().optional(),
  paymentMethod: z.string().optional(),
  cycleDate: z.string().optional(),
  invoiceDay: z.string().optional(),
  association: z.string().optional(),
  contactName: z.string().optional(),

  address1: z.string().optional(),
  address2: z.string().optional(),
  address3: z.string().optional(),
  state: z.string().optional(),
  city: z.string().optional(),
  country: z.string().optional(),

  mccTemplateOptions: z.string().optional(),
  options: z.string().optional(),
  reportingTool: z.string().optional(),
  organizationId: z.string().optional(),
  connectivityMethod: z.string().optional(),

  phone: z.string().optional(),
  bill: z.string().optional(),
  // enum these later
  accountNumberMasking: z.string().optional(),
  responseFileDataMasking: z.string().optional(),
  zip: z.string().optional(),
  email: z.string().optional(),
  customerEpayEmailPassword: z.string().optional(),
  customerZeroDollarFlag: z.boolean().optional(),
  customerEpayManager: z.string().optional(),
});

export type CustomerProfileType = z.infer<typeof customerProfileSchema>;

export const customerOnboardingStatusSchema = z.object({
  externalId: z.string(),
  buyerName: z.string(),
  modules: z.array(
    z.object({
      moduleId: z.number(),
      moduleName: z.string(),
      buyerId: z.number(),
      moduleOnboarded: z.boolean(),
    })
  ),
});

export type CustomerOnboardingStatusType = z.infer<
  typeof customerOnboardingStatusSchema
>;
