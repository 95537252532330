import {useFormContext} from "react-hook-form";

import {Card, CardContent} from "@/components/ui/card";
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Separator} from "@/components/ui/separator";
import {Textarea} from "@/components/ui/textarea";
import {Heading3, Heading4} from "@/components/ui/typography";

// FormFields
export const NotifyFormFields = ["recipientEmailAddress", "note"];

export function NotifyForm({isMutating}: {isMutating: boolean}) {
  return (
    <>
      <Card className="mb-4 mt-4">
        <CardContent>
          <Heading3 className="mb-4 mt-4">Notify Form</Heading3>
          <Heading4 className="mb-4">Notifications</Heading4>
          <div>
            Enter the recipient email address in the text box to whom the
            payment notification is to be send. Semicolons can be used to
            separate multiple message recipients.
          </div>
          <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
            <RecipientEmailAddressFormField isMutating={isMutating} />
          </div>
          <Separator className="mb-5" />
          <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
            <NotesFormField isMutating={isMutating} />
          </div>
        </CardContent>
      </Card>
    </>
  );
}

function RecipientEmailAddressFormField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="recipientEmailId"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="recipientEmailId">
            Recipient Email Address:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the Recipient Email Address..."
              {...register("recipientEmailId")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function NotesFormField({isMutating}: {isMutating: boolean}) {
  const {control, register} = useFormContext();

  return (
    <FormField
      control={control}
      name="note"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="note">Notes: </FormLabel>
          <FormControl>
            <Textarea
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the note..."
              {...register("note")}
            />
          </FormControl>
          <FormDescription>Notes (2,000 Character Limit)</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
