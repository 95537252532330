export const leadingTrailing = [
  {label: "Trailing", value: "trailing"},
  {label: "Leading", value: "leading"},
  {label: "Both", value: "both"},
];

export const leadingTrailingCharacters = [
  {label: "!", value: "!"},
  {label: "#", value: "#"},
  {label: "$", value: "$"},
  {label: "%", value: "%"},
  {label: "&", value: "&"},
  {label: "'", value: "'"},
  {label: "(", value: "("},
  {label: ")", value: ")"},
  {label: "*", value: "*"},
  {label: "+", value: "+"},
  {label: ",", value: ","},
  {label: "-", value: "-"},
  {label: ".", value: "."},
  {label: "/", value: "/"},
  {label: "0", value: "0"},
];

export const character = [
  {label: "!", value: "!"},
  {label: "#", value: "#"},
  {label: "$", value: "$"},
  {label: "%", value: "%"},
  {label: "&", value: "&"},
  {label: "'", value: "'"},
  {label: "(", value: "("},
  {label: ")", value: ")"},
  {label: "*", value: "*"},
  {label: "+", value: "+"},
  {label: ",", value: ","},
  {label: "-", value: "-"},
  {label: ".", value: "."},
  {label: "/", value: "/"},
];
