import {z} from "zod";

export const dupesOneTimeDupesSchema = z.object({
  id: z.string(),
  supplier_participant_tax_code: z.string(),
  invoice_number: z.string(),
  buyer_external_id: z.string(),
  facility_name: z.string(),
  invoice_date: z.coerce.date().nullable(),
  invoice_amount: z.string(),
  invoice_status: z.string(),
  payment_date: z.coerce.date().nullable(),
  facility_id: z.string().nullable(),
  vendor_number: z.string(),
  invoice_payment_number: z.string().nullable(),
  invoice_paid_amount: z.string().nullable(),
  po_number: z.string().nullable(),
  suffix: z.string().nullable(),
  compared: z.boolean(),
  credit_pair: z.boolean(),
  vendor_name: z.string(),
  erp_unique_id: z.string().nullable(),
});

export type DupesOneTimeDupesType = z.infer<typeof dupesOneTimeDupesSchema>;

export const dupesOneTimeGroupSchema = dupesOneTimeDupesSchema.extend({
  matches: z.array(dupesOneTimeDupesSchema),
});

export type DupesOneTimeGroupType = z.infer<typeof dupesOneTimeGroupSchema>;

export const DupesOneTimeDupesLikelyhoodGroupsSchema = z.object({
  likely: z.array(dupesOneTimeGroupSchema),
  maybe: z.array(dupesOneTimeGroupSchema),
  differentSupplierLikely: z.array(dupesOneTimeGroupSchema),
  differentSupplierMaybe: z.array(dupesOneTimeGroupSchema),
});

export type DupesOneTimeDupesLikelyhoodGroupsType = z.infer<
  typeof DupesOneTimeDupesLikelyhoodGroupsSchema
>;

export const dupesOneTimeDupesGroupedCountSchema = z.object({
  likelyCount: z.number(),
  maybeCount: z.number(),
  differentSupplierLikelyCount: z.number(),
  differentSupplierMaybeCount: z.number(),
});

export type DupesOneTimeDupesGroupedCountType = z.infer<
  typeof dupesOneTimeDupesGroupedCountSchema
>;

export const oneTimeDupesSchema = z.object({
  id: z.string(),
  supplier_participant_tax_code: z.string().nullable(),
  invoice_number: z.string(),
  buyer_external_id: z.string(),
  facility_name: z.string().nullable(),
  invoice_date: z.string().nullable(),
  invoice_amount: z.string(),
  invoice_status: z.string().nullable(),
  payment_date: z.string().nullable(),
  facility_id: z.string().nullable(),
  vendor_number: z.string().nullable(),
  invoice_payment_number: z.string().nullable(),
  invoice_paid_amount: z.string().nullable(),
  po_number: z.string().nullable(),
  suffix: z.string().nullable(),
  compared: z.boolean(),
  credit_pair: z.boolean(),
  vendor_name: z.string().nullable(),
  erp_unique_id: z.string().nullable(),
});

export type OneTimeDupesType = z.infer<typeof oneTimeDupesSchema>;

export const oneTimeDupesGroupedSchema = oneTimeDupesSchema.extend({
  dupe_invoices: z.array(oneTimeDupesSchema),
});

export type OneTimeDupesGroupedType = z.infer<typeof oneTimeDupesGroupedSchema>;
