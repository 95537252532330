import {z} from "zod";

export const keycloakUserSessionSchema = z.object({
  id: z.string(),
  username: z.string(),
  ipAddress: z.string(),
  lastAccess: z.number(),
  start: z.number(),
  userId: z.string(),
  rememberMe: z.boolean(),
});

export type KeycloakUserSessionType = z.infer<typeof keycloakUserSessionSchema>;
