// Define constants to manage query keys within the Admin Report context
export const adminReportQueryKeys = {
  // Key for retrieving the complete list of admin reports 📄
  // This constant represents the base query key used to fetch all reports.
  allReports: ["all-reports"] as const,

  // Function to construct query keys for performing search operations within admin reports 🔍
  // It appends 'search-query' to the base 'all-reports' key for search-related queries.
  searchQuery: () =>
    [...adminReportQueryKeys.allReports, "search-query"] as const,

  // Function to generate query keys for fetching admin reports based on specific search criteria 🕵️‍♂️
  // It utilizes the searchQuery key and appends a dynamic search parameter for targeted queries.
  bySearch: (search?: string) =>
    [...adminReportQueryKeys.searchQuery(), search] as const,
};

// Define constants to manage query keys within the Alert Management context
export const alertManagementQueryKeys = {
  // A list that stores the key for accessing all alerts
  all: ["alertManagement"] as const,
};

// Define constants for managing query keys related to Keycloak user operations
export const keycloakUsersQueryKeys = {
  // Base key for all Keycloak user-related queries 🗝️
  // This serves as the foundational key from which all other Keycloak user query keys are derived.
  all: ["all-keycloak-users"] as const,
};

// Define constants for managing query keys related to Keycloak realm session operations
export const keycloakRealmSessionsQueryKeys = {
  // Base key for all Keycloak realm session-related queries 🕐
  all: ["keycloak-realm-sessions"] as const,
  // Function to generate query keys for fetching Keycloak realm sessions based on a specific client ID 🆔
  byClientId: (clientId?: string) =>
    [...keycloakRealmSessionsQueryKeys.all, clientId] as const,
};

export const keycloakRealmSessionsDetailsQueryKeys = {
  // Base key for all Keycloak realm session details-related queries 🕐
  all: ["keycloak-realm-sessions-details"] as const,
  // Function to generate query keys for fetching Keycloak realm session details based on a specific client ID 🆔
  byClientId: (clientId?: string) =>
    [...keycloakRealmSessionsDetailsQueryKeys.all, clientId] as const,
};

// Define constants for managing query keys related to Keycloak realm client operations
export const keycloakRealmClientsQueryKeys = {
  // Base key for all Keycloak realm client-related queries 🕐
  all: ["keycloak-realm-clients"] as const,
};

// Define constants for managing query keys related to Keycloak groups operations
export const keycloakGroupsQueryKeys = {
  // Base key for all Keycloak groups-related queries 🗝️
  // This serves as the foundational key from which all other Keycloak groups query keys are derived.
  all: ["all-keycloak-groups"] as const,
};

//  Define constants for managing query keys related to Keycloak Required Action
export const keycloakRequiredActionQueryKeys = {
  all: ["all-required-actions"] as const,
};

export const keycloakRealmRolesQueryKeys = {
  // Base key for all Keycloak roles-related queries 🗝️
  // This serves as the foundational key from which all other Keycloak groups query keys are derived.
  all: ["all-keycloak-roles"] as const,
};

export const keycloakGroupRolesQueryKeys = {
  // Base key for all Keycloak roles-related queries 🗝️
  // This serves as the foundational key from which all other Keycloak groups query keys are derived.
  all: ["all-keycloak-group-roles"] as const,

  // Function to create query keys for retrieving group details by an groupId, extending 'detailsById'
  byGroupId: (groupId: string) =>
    [...keycloakGroupRolesQueryKeys.all, groupId] as const, // 🔖
};

export const keycloakUserSessionsQueryKeys = {
  // Base key for all Keycloak user session-related queries 🕐
  all: ["keycloak-user-sessions"] as const,
  // Function to generate query keys for fetching Keycloak user sessions based on a specific user ID 🆔
  byUserId: (userId: string) =>
    [...keycloakUserSessionsQueryKeys.all, userId] as const,
};

export const keycloakUsersByRoleQueryKeys = {
  // Base key for all Keycloak user session-related queries 🕐
  all: ["keycloak-users-by-role"] as const,
  // Function to generate query keys for fetching Keycloak users list based on a specific role group ID 🆔
  roleRroupId: (roleRroupId: string) =>
    [...keycloakUserSessionsQueryKeys.all, roleRroupId] as const,
};

export const keycloakUserGroupsQueryKeys = {
  // Base key for all Keycloak user group-related queries 🕐
  all: ["keycloak-user-groups"] as const,
  // Function to generate query keys for fetching Keycloak user groups based on a specific user ID 🆔
  byUserId: (userId: string) =>
    [...keycloakUserGroupsQueryKeys.all, userId] as const,
};

export const notificationTypeKeys = {
  // A list that stores the key for accessing all notificationTypes
  all: ["notificationTypes"] as const,

  // A function that returns a list of keys to access notificationTypes based on a search
  searches: () => [...notificationTypeKeys.all, "search"] as const,

  // A function that returns a list of keys to access notificationTypes by a specific search criteria
  bySearch: (search?: string) =>
    [...notificationTypeKeys.searches(), search] as const,

  // A function that returns a list of keys to access detailed information about notificationTypes
  details: () => [...notificationTypeKeys.all, "details"] as const,

  // A function that returns a list of keys to access notificationTypes by a specific ID
  byId: (id: number) => [...notificationTypeKeys.details(), id] as const,
};

export const emailConfigurationsKeys = {
  // Get by externalId
  byCustomerId: (externalId: string) =>
    ["emailConfigurations", "byCustomerId", externalId] as const,
};

export const sftpConfigurationsKeys = {
  // A list that stores the key for accessing all sftpConfigurations
  all: ["sftpConfigurations"] as const,
  byCustomerId: (externalId?: string) =>
    [...sftpConfigurationsKeys.all, "byCustomerId", externalId] as const,
};

export const misPaymentsReceivedQueryKeys = {
  all: ["mis-payments-received"] as const,
  byFilter: (filter?: string) =>
    [...misPaymentsReceivedQueryKeys.all, filter] as const,
};

export const misPaymentsDifferencesQueryKeys = {
  all: ["mis-payments-differences"] as const,
  byFilter: (filter?: string) =>
    [...misPaymentsDifferencesQueryKeys.all, filter] as const,
};
