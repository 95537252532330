import {z} from "zod";

export const AddImREmitFormSchema = z.object({
  modifiedBy: z.string().nullable(),
  comments: z.string().max(140).optional(),
  currency: z.string(),
  initiatedDate: z.date().min(new Date("1970-01-01"), {
    message: "Date is out of range",
  }),
  endDate: z.date().min(new Date("1970-01-01"), {
    message: "Date is out of range",
  }),
  merchantCategoryCode: z.string().max(140).optional().nullable(),
  note: z.string().max(2000),
  paymentMethodId: z.string().optional().nullable(),
  providerMethodId: z.string(),
  paymentNumber: z
    .string()
    .regex(/^[a-zA-Z0-9]*$/, "Only alphanumeric characters are allowed.")
    .min(1)
    .max(140),
  accountNumber: z.string().nullable().optional(),
  singleTranExactAuth: z.boolean(),
  supplierDetailId: z.string(),
  facilityId: z.string().nullable(),
  totalAmountSent: z
    .number()
    .min(1, "Payable total must be greater than 0")
    .max(9999999999),
  udf1: z.string().max(140).nullable(),
  udf2: z.string().max(140).nullable(),
  udf3: z.string().max(140).nullable(),

  // OLD API does not take these three fields
  overageToleranceIncrement: z.number().max(9999999999).nullable().optional(),
  overageToleranceDecrease: z.number().max(9999999999).nullable().optional(),
  recipientEmailId: z.string().max(140).nullable().optional(),

  ///-> Invoice
  invoiceDetailRequests: z.array(
    z.object({
      invoiceId: z.string().optional().nullable(),
      invoiceNumber: z.string().max(140).nullable(),
      invoiceDate: z.date().min(new Date("1970-01-01"), {
        message: "Date is out of range",
      }),
      invoiceAmountPaid: z.number().max(9999999999).multipleOf(0.01).nullable(),
      poNumber: z.string().optional().nullable(),
      invoiceTotalAmount: z
        .number()
        .max(9999999999)
        .multipleOf(0.01)
        .nullable(),
      discountAmount: z.number().max(9999999999).nullable().optional(),
      adjustmentAmount: z.number().max(9999999999).nullable().optional(),
      comments: z
        .string()
        .min(1, "Please enter a comment!")
        .max(200, "Comment must not exceed 200 characters.")
        .optional(),
    })
  ),
});

export const EditImREmitFormSchema = z
  .object({
    role: z.string().nullable(),
    modifiedBy: z.string().optional(),
    accountNumber: z
      .string()
      .min(1, "Account number is required")
      .max(140, "Account number must not exceed 140 characters.")
      .nullable()
      .optional(),
    totalAmountSent: z
      .union([
        z.string().transform((x) => x.replace(/[^0-9.-]+/g, "")),
        z.number(),
      ])
      .pipe(z.coerce.number().min(0.01).max(999999999))
      .nullable()
      .optional(),
    endDate: z.date().min(new Date("1970-01-01"), {
      message: "Date is out of range",
    }),
    singleTranExactAuth: z.boolean().default(true).optional(),
    singleTransactionLimitAmount: z
      .number()
      .max(9999999999)
      .nullable()
      .optional(),
    dailyTransactionLimitAmount: z
      .number()
      .max(9999999999)
      .nullable()
      .optional(),
    dailyTransactionLimitNumber: z
      .number()
      .max(9999999999)
      .nullable()
      .optional(),
    monthlyTransactionLimitNumber: z
      .number()
      .max(9999999999)
      .nullable()
      .optional(),
    paymentRequestId: z
      .string()
      .min(1, "Payment Request ID is required")
      .max(140, "Payment Request ID must not exceed 140 characters.")
      .optional(),
    udf1: z
      .string()
      .max(140, "UDF1 must not exceed 140 characters.")
      .nullable()
      .optional(),
    udf2: z
      .string()
      .max(140, "UDF2 must not exceed 140 characters.")
      .nullable()
      .optional(),
    udf3: z
      .string()
      .max(140, "UDF3 must not exceed 140 characters.")
      .nullable()
      .optional(),
    merchantCategoryCode: z
      .string()
      .min(1, "Merchant Category Code is required")
      .max(140, "Merchant Category Code must not exceed 140 characters.")
      .optional(),

    invoiceDetailRequests: z.array(
      z.object({
        invoiceId: z.string().nullable().optional(),
        invoiceNumber: z
          .string()
          .min(1, "Invoice Number is required")
          .max(140, "Invoice Number must not exceed 140 characters."),
        invoiceDate: z.date().min(new Date("1970-01-01"), {
          message: "Date is out of range",
        }),
        invoiceAmountPaid: z
          .number()
          .max(9999999999)
          .multipleOf(0.01)
          .nullable(),
        poNumber: z.string().optional().nullable(),
        invoiceTotalAmount: z
          .number()
          .max(9999999999)
          .multipleOf(0.01)
          .nullable(),
        discountAmount: z.number().max(9999999999).nullable().optional(),
        adjustmentAmount: z.number().max(9999999999).nullable().optional(),
        comments: z.string().optional().nullable(),
      })
    ),
    notes: z.string().optional().nullable(),
  })
  .superRefine(({invoiceDetailRequests}, ctx) => {
    if (invoiceDetailRequests.length === 0) {
      ctx.addIssue({
        code: "custom",
        message: "Please add at least one invoice.",
        path: ["invoiceDetailRequests"],
      });
    }
  });

export type EditImREmitFormDTO = z.infer<typeof EditImREmitFormSchema>;

export type AddImREmitFormDTO = z.infer<typeof AddImREmitFormSchema>;
