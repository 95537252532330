// Constants for managing query keys related to imREmit Lite services 🌐
export const imREmitLiteQueryKeys = {
  // Key for accessing the complete list of imREmit Lite entries, serving as the base for more specific queries
  allImREmitLite: ["imremits-lite"] as const, // 📋

  // Function to generate query keys for conducting searches within imREmit Lite, extending 'allImREmitLite' with 'search'
  searches: () => [...imREmitLiteQueryKeys.allImREmitLite, "search"] as const, // 🔍

  // Function to create query keys for fetching imREmit Lite entries based on a specific external identifier, enhancing 'searches' with dynamic criteria
  byExternalId: (externalId?: string) =>
    [...imREmitLiteQueryKeys.searches(), externalId] as const, // 🔖

  // Function to generate query keys for accessing detailed information about imREmit Lite entries, appending 'details' to 'allImREmitLite'
  details: () => [...imREmitLiteQueryKeys.allImREmitLite, "details"] as const, // 📄

  // Function to create query keys for retrieving detailed information about a specific imREmit Lite entry by ID, extending 'details'
  byId: (id: number) => [...imREmitLiteQueryKeys.details(), id] as const, // 🆔
};

// Constants for query keys related to imREmit Lite supplier details management 🛠️
export const imREmitLiteSupplierDetailsQueryKeys = {
  // Key for accessing all supplier details within imREmit Lite, acting as a foundational key for more specific queries
  allImREmitLiteSupplierDetails: ["suppliersDetails"] as const, // 🗂️

  // Function to generate query keys for supplier details searches, appending 'searchQuery' to 'allImREmitLiteSupplierDetails'
  searchQuery: () =>
    [
      ...imREmitLiteSupplierDetailsQueryKeys.allImREmitLiteSupplierDetails,
      "searchQuery",
    ] as const, // 🔍

  // Function to create query keys for fetching supplier details based on specific search criteria, utilizing 'searchQuery' for dynamic searches
  bySearch: (searchQuery?: string) =>
    [
      ...imREmitLiteSupplierDetailsQueryKeys.searchQuery(),
      searchQuery,
    ] as const, // 🕵️

  // Function to generate query keys for accessing detailed information about supplier details, extending 'allImREmitLiteSupplierDetails' with 'detailsById'
  detailsById: () =>
    [
      ...imREmitLiteSupplierDetailsQueryKeys.allImREmitLiteSupplierDetails,
      "detailsById",
    ] as const, // 📄

  // Function to create query keys for retrieving detailed supplier details by a specific external ID, enhancing 'detailsById'
  byId: (externalId?: string) =>
    [...imREmitLiteSupplierDetailsQueryKeys.detailsById(), externalId] as const, // 🔖
};

// Constants for query keys related to imREmit Lite suppliers list management 📦
export const suppliersListQueryKeys = {
  // Key for accessing the complete list of suppliers in imREmit Lite, serving as a base for further queries
  allImREmitLiteSupplierLists: ["supplierList"] as const, // 📋

  // Function to generate query keys for conducting supplier list searches, incorporating an external ID into the base key
  searchQuery: (externalId?: string) =>
    [
      ...suppliersListQueryKeys.allImREmitLiteSupplierLists,
      "searchQuery",
      externalId,
    ] as const, // 🔍

  // Function to create query keys for fetching supplier list entries based on specific search criteria, extending 'searchQuery' with additional parameters
  bySearch: (externalId?: string, module?: string) =>
    [...suppliersListQueryKeys.searchQuery(externalId), module] as const, // 🕵️
};
