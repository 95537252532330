import {useEffect, useMemo} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {type Table} from "@tanstack/react-table";
import {CheckIcon, ChevronsUpDownIcon, ListRestartIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {toast} from "@/components/ui/use-toast";
import {AdvancedSearch} from "@/components/craft/advanced-search";
import {DataTableViewOptions} from "@/components/craft/data-table";
import {DatePicker} from "@/components/craft/date-pickers";
import {Pagination} from "@/components/craft/pagination";
import {DataTableFacetedFilter} from "@/components/craft/table";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

import {useImRemitCustomerStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {
  ProxypayFormSchema,
  useFetchAndParseProxyPayPopulationData,
  useGetProxyPayStatuses,
  useProxyDashboardFilterStore,
  useProxypayCriteriaFields,
  useProxypayMetaStore,
  useSearchProxypay,
} from "@/modules/imremit";

import {generateQueryString} from "@/utils/query-params";

// This is the main component, "ProxypayDataTableToolbar". It expects the `table` object as a prop.
interface DataTableToolbarProps<TData> {
  table: Table<TData>;
}

export function ProxyPayDataTableToolbar<TData>({
  table,
}: DataTableToolbarProps<TData>) {
  // Accessing the pagination meta and page change handler from the store.
  const {
    storeProxypayPaginationMeta,
    setStoreProxypayCriteria,
    handleOnPageChange,
  } = useProxypayMetaStore();

  // Accessing data from store `useProxyDashboardFilterStore`
  const {storeProxyDashboardFilter} = useProxyDashboardFilterStore();

  // Accessing and updating the customer store
  const {imREmitStoreCustomer} = useImRemitCustomerStore();

  // Initializing the form with default values and validation resolver
  const proxypayToolbarFormMethods = useForm<
    z.infer<typeof ProxypayFormSchema>
  >({
    defaultValues: {
      supplierId: "",
      paymentStatus: [],
      paymentNumber: "",
      startDate: undefined,
      endDate: undefined,
      paymentMode: "",
      orgId: "",
    },
    mode: "onChange",
    resolver: zodResolver(ProxypayFormSchema),
  });

  // Static list of remittance methods
  const remittanceMethods = [
    {value: "", label: "All"},
    {value: "3", label: "Pay by Phone"},
    {value: "2", label: "Pay by Web"},
  ] as const;

  // Destructuring to get form state and watching for changes in form fields
  const {
    formState: {isDirty},
    setValue,
  } = proxypayToolbarFormMethods;
  const formFields = proxypayToolbarFormMethods.watch();

  // const {dirtyFields} = form.formState;

  // console.log("dirtyFields", dirtyFields);

  const criteriaFields = useProxypayCriteriaFields(formFields);

  // Effect hook to set the search criteria for storing proxy-pay.
  useEffect(() => {
    setStoreProxypayCriteria(criteriaFields);
  }, [criteriaFields, setStoreProxypayCriteria]);

  // Generating a search query string based on form fields
  const searchQuery = useMemo(
    () => generateQueryString({formFields}),
    [formFields]
  );

  // Fetching proxypay data based on debounced search query
  useSearchProxypay(imREmitStoreCustomer?.externalId.toString(), searchQuery);

  // Fetching supplier data
  const {suppliersData, organizationsList} =
    useFetchAndParseProxyPayPopulationData(
      imREmitStoreCustomer?.externalId.toString()
    );

  // Fetching payment statuses
  const paymentStatusesData = useGetProxyPayStatuses().data?.content;
  // Mapping payment statuses for UI display
  const statusesData = useMemo(
    () =>
      paymentStatusesData?.map((status) => ({
        label: status.customerStatus,
        value: status.paymentStatusId,
      })) ?? [],
    [paymentStatusesData]
  );

  useEffect(() => {
    if (storeProxyDashboardFilter) {
      setValue(
        "paymentStatus",
        statusesData
          .filter((result) => {
            return storeProxyDashboardFilter.statusName === result.label;
          })
          .map((res) => {
            return {label: res.label, value: res.value};
          }),
        {shouldDirty: true}
      );

      setValue("paymentMode", storeProxyDashboardFilter.remitanceMethod, {
        shouldDirty: true,
      });
    }
  }, [storeProxyDashboardFilter, statusesData, setValue]);

  useEffect(() => {
    if (formFields.startDate && !formFields.endDate) {
      proxypayToolbarFormMethods.setError("endDate", {
        type: "manual",
        message: "Please enter an end date.",
      });
    }

    if (formFields.endDate && !formFields.startDate) {
      proxypayToolbarFormMethods.setError("startDate", {
        type: "manual",
        message: "Please enter a start date.",
      });
    }
  }, [proxypayToolbarFormMethods, formFields.startDate, formFields.endDate]);

  // Handling form submission
  function onSubmit(data: z.infer<typeof ProxypayFormSchema>) {
    // Displaying a toast notification with submitted data
    toast({
      title: "You submitted the following values:",
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data, null, 2)}</code>
        </pre>
      ),
    });
  }

  //Columns other names
  const columnsOtherName = [
    {key: "orgId", value: "Organization ID"},
    {key: "supplierName", value: "Supplier Name"},
    {key: "supplierNumber", value: "Supplier ID"},
    {key: "facilityName", value: "Facility Name"},
    {key: "paymentNumber", value: "Payment Number"},
    {key: "remittanceMethod", value: "Remittance Method"},
    {key: "totalAmountSent", value: "Sent Amount"},
    {key: "initiatedDate", value: "Sent Date"},
    {key: "userId", value: "Agent Name"},
    {key: "customerStatus", value: "Status"},
    {key: "description", value: "Status Description"},
  ];
  // Return the form elements and fields as JSX. It uses Form, FormItem, FormControl, and FormMessage
  // components to structure and style the form.
  return (
    <>
      <Pagination
        paginationMeta={storeProxypayPaginationMeta}
        onPageChange={handleOnPageChange}
      />
      <Form {...proxypayToolbarFormMethods}>
        <form onSubmit={proxypayToolbarFormMethods.handleSubmit(onSubmit)}>
          <div className="flex justify-between gap-4">
            <div className="flex flex-wrap items-end gap-2">
              <RowsPreferenceSelect />

              <FormField
                control={proxypayToolbarFormMethods.control}
                name="supplierId"
                render={({field}) => (
                  <FormItem>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            role="combobox"
                            className={cn(
                              "h-10 min-w-64 max-w-md justify-between gap-2 overflow-hidden bg-root capitalize",
                              !field.value && "text-accent-foreground"
                            )}
                          >
                            {field.value
                              ? suppliersData.find(
                                  (item) => item?.value === field.value
                                )?.label
                              : field.value === ""
                              ? "All"
                              : "Select Supplier..."}
                            <ChevronsUpDownIcon className="size-4 shrink-0 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0 ">
                        <Command>
                          <CommandInput placeholder="Search Supplier Name or ID" />
                          <CommandList>
                            <CommandEmpty>No supplier found.</CommandEmpty>
                            <CommandGroup>
                              <CommandItem
                                value=""
                                onSelect={() => {
                                  field.onChange("");
                                }}
                              >
                                <CheckIcon
                                  className={cn(
                                    "mr-2 size-4",
                                    "" === field.value
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                                All
                              </CommandItem>
                              {suppliersData.map((supplierOption, i) => (
                                <CommandItem
                                  key={i}
                                  value={supplierOption?.label}
                                  onSelect={() => {
                                    field.onChange(supplierOption?.value);
                                  }}
                                >
                                  <CheckIcon
                                    className={cn(
                                      "mr-2 size-4",
                                      supplierOption?.value
                                        .trim()
                                        .toLowerCase() ===
                                        field.value.toLowerCase().trim()
                                        ? "opacity-100"
                                        : "opacity-0"
                                    )}
                                  />
                                  {supplierOption?.label}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </CommandList>
                        </Command>
                      </PopoverContent>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                name="startDate"
                render={({field}) => (
                  <FormItem className="flex flex-col">
                    <DatePicker
                      {...field}
                      placeholder="Start Date"
                      disabled={
                        formFields.endDate
                          ? {
                              before: new Date("1970-01-01"),
                              after: formFields.endDate,
                            }
                          : undefined
                      }
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="endDate"
                render={({field}) => (
                  <FormItem className="flex flex-col">
                    <DatePicker
                      {...field}
                      placeholder="End Date"
                      disabled={
                        formFields.startDate
                          ? {
                              before: formFields.startDate,
                              after: new Date("9999-01-01"),
                            }
                          : undefined
                      }
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              {isDirty && (
                <Button
                  className="border-destructive-foreground/60 bg-destructive text-destructive-foreground hover:border-destructive-foreground/80 hover:bg-destructive-foreground/40 hover:text-destructive-foreground active:bg-destructive-foreground/60 active:text-destructive-foreground"
                  variant="outline"
                  onClick={() => {
                    proxypayToolbarFormMethods.reset();
                  }}
                >
                  <ListRestartIcon className="mr-2 size-4" />
                  Reset
                </Button>
              )}
            </div>

            <div className="flex flex-col items-end gap-2 lg:flex-row">
              <AdvancedSearch>
                <div className="grid grid-cols-1 gap-x-2 gap-y-2 lg:grid-cols-2 lg:gap-x-4 lg:gap-y-4 xl:grid-cols-3">
                  <FormField
                    name="paymentNumber"
                    render={() => (
                      <FormItem>
                        <FormLabel htmlFor="paymentNumber">
                          Payment Number
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            placeholder="Enter payment number"
                            {...proxypayToolbarFormMethods.register(
                              "paymentNumber"
                            )}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <FormField
                    control={proxypayToolbarFormMethods.control}
                    name="paymentMode"
                    render={({field}) => (
                      <div className="space-y-2">
                        <FormLabel htmlFor="paymentMode">
                          Remittance Method:
                        </FormLabel>
                        <FormItem>
                          <Popover>
                            <PopoverContent className="w-[260px] p-0">
                              <Command>
                                <CommandGroup>
                                  {remittanceMethods.map(
                                    (remittanceMethod, i) => (
                                      <CommandItem
                                        key={i}
                                        value={remittanceMethod.value || ""}
                                        onSelect={(value) => {
                                          field.onChange(value);
                                        }}
                                      >
                                        <CheckIcon
                                          className={cn(
                                            "mr-2 size-4",
                                            remittanceMethod.value.toString() ===
                                              field.value.toString()
                                              ? "opacity-100"
                                              : "opacity-0"
                                          )}
                                        />
                                        {remittanceMethod.label}
                                      </CommandItem>
                                    )
                                  )}
                                </CommandGroup>
                              </Command>
                            </PopoverContent>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant="outline"
                                  role="combobox"
                                  className={cn(
                                    "justify-between",
                                    !field.value && "text-accent-foreground",
                                    "w-[260px]"
                                  )}
                                >
                                  {field.value
                                    ? remittanceMethods.find(
                                        (item) => item.value === field.value
                                      )?.label
                                    : "All"}
                                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                          </Popover>
                          <FormMessage />
                        </FormItem>
                      </div>
                    )}
                  />
                  <FormField
                    control={proxypayToolbarFormMethods.control}
                    name="orgId"
                    render={({field}) => (
                      <div className="space-y-2">
                        <FormLabel htmlFor="orgId">Organization ID:</FormLabel>
                        <FormItem>
                          <Popover>
                            <PopoverContent className="w-[260px] p-0">
                              <Command>
                                <CommandGroup>
                                  <CommandItem
                                    value=""
                                    onSelect={() => {
                                      field.onChange("");
                                    }}
                                  >
                                    <CheckIcon
                                      className={cn(
                                        "mr-2 size-4",
                                        "" === field.value
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    All
                                  </CommandItem>
                                  {organizationsList.map((data, i) => (
                                    <CommandItem
                                      key={i}
                                      value={data.value.toUpperCase() || ""}
                                      onSelect={(value) => {
                                        field.onChange(value.toUpperCase());
                                      }}
                                    >
                                      <CheckIcon
                                        className={cn(
                                          "mr-2 size-4",
                                          data.value
                                            .toString()
                                            .trim()
                                            .toUpperCase() ===
                                            field.value
                                              ?.toString()
                                              .toUpperCase()
                                              .trim()
                                            ? "opacity-100"
                                            : "opacity-0"
                                        )}
                                      />
                                      {data.label}
                                    </CommandItem>
                                  ))}
                                </CommandGroup>
                              </Command>
                            </PopoverContent>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant="outline"
                                  role="combobox"
                                  className={cn(
                                    "justify-between",
                                    !field.value && "text-accent-foreground",
                                    "w-[260px]"
                                  )}
                                >
                                  {field.value
                                    ? organizationsList.find(
                                        (item) =>
                                          item.value
                                            .toString()
                                            .toUpperCase() ===
                                          field.value?.toString().toUpperCase()
                                      )?.label
                                    : "All"}
                                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                          </Popover>
                          <FormMessage />
                        </FormItem>
                      </div>
                    )}
                  />
                </div>
              </AdvancedSearch>
              <FormField
                name="paymentStatus"
                render={({field}) => (
                  <FormItem className="flex flex-col">
                    <FormControl>
                      <DataTableFacetedFilter
                        {...field}
                        facetedFilterData={statusesData}
                        title="Status"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DataTableViewOptions
                table={table}
                columnsOtherName={columnsOtherName}
              />
            </div>
          </div>
        </form>
      </Form>
    </>
  );
}
