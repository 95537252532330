import {useState} from "react";

import {Skeleton} from "@/components/ui/skeleton";
import {LoadingSection} from "@/components/craft/loading-section";

export function ElectronicInvoicingIframe() {
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  return (
    <>
      {!iframeLoaded && (
        <Skeleton className="flex h-[80vh] items-center justify-center">
          <LoadingSection sectionSize="lg" />
        </Skeleton>
      )}
      <iframe
        className="h-[80vh] w-full rounded-lg shadow-sm"
        title="Electronic Invoicing"
        src="https://iterationm.filebound.com/"
        onLoad={handleIframeLoad}
      />
    </>
  );
}
