import {z} from "zod";
import {create} from "zustand";

// This is a Zod schema for a TypeScript enum.
export const runTypeSchema = z.enum(["OneTime", "Initial", "Daily"]);

// This defines the TypeScript type for numRows using the Zod schema.
export type RunTypeType = z.infer<typeof runTypeSchema>;

// Defining the shape of the state object for the CustomerStore
// In this case, the state will consist of an array of Customer objects
interface DupesRunTypeStoreState {
  dupesRunType: RunTypeType | undefined;
}

// Defining the actions that can be performed on the CustomerStore state
// In this case, there's only one action, updateDupesRunType, which takes an array of Customer objects
interface DupesRunTypeStoreActions {
  updateDupesRunType: (
    dupesRunType: DupesRunTypeStoreState["dupesRunType"]
  ) => void;
}

export const useDupesRunTypeStore = create<
  DupesRunTypeStoreState & DupesRunTypeStoreActions
>((set) => ({
  // Initial state of the store. A dupesRunType.
  // Default Daily
  dupesRunType: "Daily",

  // Action to update the dupesRunType in the store.
  // This function takes a new dupesRunType  and uses the set function to update the state.
  updateDupesRunType: (dupesRunType) => {
    set(() => ({dupesRunType}));
  },
}));
