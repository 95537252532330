import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  responseFileMappingKeys,
  type bankAuditMappingListType,
} from "@/modules/imremit";

//Defining a type based on the function `getAllResponseDetails`
type QueryFnType = typeof getAllResponseDetails;

//Funciton to get the response file reports
export function getAllResponseDetails() {
  // Fetch data from API using ky
  return kyApiFn<bankAuditMappingListType[]>( // TODO need to change the schema for response file
    `api/e-payable/admin/master-mapping/response`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useResponseFileDetails(config?: QueryConfig<QueryFnType>) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: responseFileMappingKeys.allResponseFileMapping(),
    queryFn: () => getAllResponseDetails(),
  });
}
