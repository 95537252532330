import {Navigate, useNavigate} from "@tanstack/react-router";

export function StatementReconDashboardRedirect() {
  // Get the navigate function from the router
  const navigate = useNavigate();

  void navigate({
    to: "/app/statement-recon/file-history",
  });

  return <Navigate to="/app/statement-recon/file-history" />;
}
