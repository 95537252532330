/* eslint-disable */
import ky, {type HTTPError, type Options} from "ky";
import {z} from "zod";

import {toast} from "@/components/ui/use-toast";

import {numRowsSchema} from "@/lib/stores";
import {useTokenStore} from "@/modules/auth";

import {CLIENT_ERRORS, SERVER_CLIENT_ERRORS} from "@/utils/constants";

// 🚀 Establish base API configurations using environment variables or defaults
const API_BASE_URL: string =
  import.meta.env.VITE_API_BASE_URL ?? "https://ntdevapi.iterationm.com";
const KEYCLOAK_API_BASE_URL: string =
  import.meta.env.VITE_KEYCLOAK_URL ?? "https://ntauth.iterationm.com";
const TIMEOUT: number = Number(import.meta.env.VITE_API_TIMEOUT) || 30000;

// 📚 Define HTTP methods as enums to prevent hard-coding and errors
const ApiMethods = z.enum(["get", "post", "put", "delete", "patch"]);

// 🚨 Define the structure of error responses from the API
interface ErrorResponse {
  code: number;
  message: string;
  error?: string[];
}

// ⚙️ Configure the API client with default settings and custom hooks
export const apiClient = ky.create({
  prefixUrl: API_BASE_URL,
  timeout: TIMEOUT,
  retry: {
    limit: 1,
    methods: ApiMethods.options,
    statusCodes: [408, 500, 502, 503, 504],
  },
  hooks: {
    beforeError: [handleBeforeError],
    beforeRequest: [authorizeRequest],
    afterResponse: [handleAfterResponse],
  },
});

export const keycloakApiClient = ky.create({
  prefixUrl: KEYCLOAK_API_BASE_URL,
  timeout: TIMEOUT,
  retry: {
    limit: 1,
    methods: ApiMethods.options,
    statusCodes: [408, 500, 502, 503, 504],
  },
  hooks: {
    beforeError: [handleBeforeError],
    beforeRequest: [authorizeRequest],
    afterResponse: [handleAfterKeycloakResponse],
  },
});

// 🛑 Custom error handler that utilizes async/await for handling HTTP errors gracefully
async function handleBeforeError(error: HTTPError): Promise<HTTPError> {
  // ℹ️ Destructure the response object from the error
  const {response} = error;

  // 🧐 Parse and determine the appropriate error message from the response
  const parseErrorResponse = async (response: Response): Promise<string> => {
    const contentType = response.headers.get("Content-Type");

    // 📝 Parse JSON response for error details if available
    if (contentType?.includes("application/json")) {
      try {
        const errorData: ErrorResponse | {errorMessage?: string} =
          await response.json();

        // 📢 Show the 'errorMessage' if it exists in the response object
        if ("errorMessage" in errorData && errorData.errorMessage) {
          return errorData.errorMessage;
        }

        // Handle errors based on the ErrorResponse structure
        if ("code" in errorData && "message" in errorData) {
          // Provide a more user-friendly message by checking custom error messages first
          return CLIENT_ERRORS[errorData.code]
            ? CLIENT_ERRORS[errorData.code]
            : `${errorData.message}`;
        }

        // Fallback to a user-friendly default error message if no specific error data is found
        return "Oops! Something went wrong. Please try again in a moment.";
      } catch (jsonError) {
        console.error("Error parsing JSON from response", jsonError);
        return "There was a problem processing the information. Please refresh the page or contact support.";
      }
    }

    // Handle non-JSON responses (unexpected errors)
    return (
      SERVER_CLIENT_ERRORS[response.status] ??
      "An issue occurred. Please refresh the page or reach out to support if it persists."
    );
  };

  // 🚨 Set the error message based on the response data
  error.message = await parseErrorResponse(response);
  return error;
}

// 🔑 Inject authorization token into API requests if available
function authorizeRequest(request: Request): void {
  const token = useTokenStore.getState().storeToken;
  if (token) {
    request.headers.set("Authorization", `Bearer ${token}`);
  }
}

// 🔑 Inject Keycloak authorization token into API requests if available
// async function keycloakRequestAuthorization(request: Request): Promise<void> {
//   const username = import.meta.env.VITE_KEYCLOAK_USERNAME ?? "admin";
//   const password = import.meta.env.VITE_KEYCLOAK_PASSWORD ?? "admin";

//   const params = new URLSearchParams({
//     grant_type: "password",
//     client_id: "admin-cli",
//     username,
//     password,
//   });

//   try {
//     const token: {access_token?: string} = await ky
//       .post("realms/master/protocol/openid-connect/token", {
//         prefixUrl: KEYCLOAK_API_BASE_URL,
//         headers: {
//           "Content-Type": "application/x-www-form-urlencoded",
//         },
//         body: params,
//       })
//       .json();

//     if (token.access_token) {
//       request.headers.set("Authorization", `Bearer ${token.access_token}`);
//     } else {
//       // Handling case where access token might be missing in the response
//       console.error("Failed to retrieve access token from Keycloak.");
//       throw new Error(
//         "Authentication failed: No access token provided by Keycloak."
//       );
//     }
//   } catch (error: unknown) {
//     // General error handling for network issues or other unexpected errors
//     console.error("Authentication error with Keycloak:", error);
//     throw new Error(
//       `Authentication request failed: ${error || "Unknown error"}`
//     );
//   }
// }

// 🚨 Define the structure of error responses from the API
interface HandleAfterErrorResponse {
  timestamp: Date;
  code?: number;
  error: string;
  message: string;
  status: string | number;
}

// 📩 Handle responses after fetching data, including error management
async function handleAfterResponse(
  request: Request,
  _options: Options,
  response: Response
): Promise<Response | undefined> {
  // ℹ️ Return the response if successful
  if (response.ok) return response;

  // 🚨 Parse and handle the error response if not successful
  const responseBody: HandleAfterErrorResponse = await response.json();

  // ℹ️ Handle 404 errors separately to display a different notification
  // if (response.status === 404) {
  //   handleApi404s(
  //     responseBody.code,
  //     responseBody.error?.join(", ") || responseBody.message
  //   );
  // } else {
  //   handleGetReqError(
  //     responseBody.status,
  //     responseBody.error?.join(", ") || responseBody.message
  //   );
  // }

  // TODO: once BE team fixes 404 error response, we can use the above code
  if (response.status !== 404) {
    // This handles all of the errors that are not 404 and are not GET requests
    if (request.method !== "GET") {
      if (Array.isArray(responseBody.error)) {
        // handleGetReqError(responseBody.code, responseBody.error.join(", "));
        throw new Error(`${responseBody.error.join(", ")}`);
      } else {
        // handleGetReqError(responseBody.code, responseBody.message);
        throw new Error(`${responseBody.timestamp}`);
      }
    } else {
      // This handles all of the errors that are not 404 and are GET requests
      if (Array.isArray(responseBody.error)) {
        handleGetReqError(
          responseBody.code ? responseBody.code : responseBody.status,
          responseBody.error.join(", ")
        );
      } else {
        handleGetReqError(
          responseBody.code ? responseBody.code : responseBody.status,
          responseBody.error
        );
      }
    }
  } else {
    // This handles 404 errors, it's gonna be a GET request, because 404s are USUALLY for GET requests
    if (request.method === "GET") {
      handleGetReqError(
        responseBody.code ? responseBody.code : responseBody.status,
        responseBody.error || responseBody.message
      );
    } else {
      throw new Error(`${responseBody.error || responseBody.message}`);
    }
  }
}

async function handleAfterKeycloakResponse(
  _request: Request,
  _options: Options,
  response: Response
): Promise<Response | undefined> {
  console.log("keycloak response: ", response);

  if (response.status !== 401) {
    return response;
  }

  // ℹ️ Return the response if successful
  // if (response.ok) return response;

  // 🚨 Parse and handle the error response if not successful
  // const responseBody: ErrorResponse = await response.json();

  // ℹ️ Handle 404 errors separately to display a different notification
  // if (response.status === 404) {
  //   handleApi404s(
  //     responseBody.code,
  //     responseBody.error?.join(", ") || responseBody.message
  //   );
  // } else {
  //   handleGetReqError(
  //     responseBody.code,
  //     responseBody.error?.join(", ") || responseBody.message
  //   );
  // }
}

// 📊 Schema for pagination metadata using Zod for type safety
export const PaginationMetaSchema = z.object({
  first: z.boolean(),
  last: z.boolean(),
  numberOfElements: z.number(),
  pageNumber: z.number(),
  pageSize: numRowsSchema,
  totalElements: z.number(),
  totalPages: z.number(),
});

// 📈 Type for pagination metadata inferred from the schema
export type PaginationMetaType = z.infer<typeof PaginationMetaSchema>;

// 📝 API response types detailing the structure of typical server responses
export interface ApiResponseDataType<T> {
  message: string;
  content: T;
  meta: PaginationMetaType | null;
}

// 🚨 Display error notifications using a toast system
function handleGetReqError(
  errorCode: string | number,
  errorMessage: string = ""
): void {
  console.error("errorCode", errorCode);
  console.error("errorMessage", errorMessage);
  toast({
    variant: "destructive",
    title: "Error!",
    description: `Unable to process request.`,
    duration: 99999999999,
  });
}

export interface ApiResponseType<T> {
  timestamp: Date;
  code: number;
  status: string;
  message: string;
  data: ApiResponseDataType<T>;
}

// ℹ️ Display informational notifications for 404 errors
// function handleApi404s(
//   errorCode: number,
//   errorMessage: string = "No results found!"
// ): void {
//   toast({
//     variant: "info",
//     title: "Not Found",
//     description: `${errorCode} - ${errorMessage}`,
//     duration: 99999999999,
//   });
// }

// 🌐 Generic function to make API calls using the pre-configured ky instance
export async function kyApiFn<T>(
  endpoint: string,
  method: z.infer<typeof ApiMethods>,
  options?: Options
): Promise<Pick<ApiResponseDataType<T>, "message" | "content" | "meta">> {
  const response = await apiClient(endpoint, {
    method,
    ...options,
  });

  const responseData: ApiResponseType<T> = await response.json();
  const {content, meta} = responseData.data;

  return {message: responseData.message, content, meta};
}

// 🌐 Generic function to make Keycloak API calls using the pre-configured ky instance
export async function keycloakKyApiFn<T>(
  endpoint: string,
  method: z.infer<typeof ApiMethods>,
  options?: Options
): Promise<T> {
  const response = await keycloakApiClient(endpoint, {
    method,
    ...options,
  });

  // const responseData: T = await response.json();
  // return responseData;

  // TODO: THIS IS A TEMPORARY WORKAROUND FIX FOR KEYCLOAK'S EMPTY POST RESPONSES

  try {
    const responseData: T = await response.json();
    return responseData;
  } catch (error) {
    return response as unknown as T;
  }
}
