import {Link} from "@tanstack/react-router";

import {buttonVariants} from "@/components/ui/button";
import {Heading1, Heading2, Paragraph} from "@/components/ui/typography";

import {cn} from "@/lib/utils";

export function LandingInfo() {
  return (
    <>
      <div className="flex items-end gap-2 pb-2">
        <Heading1 className="text-theme">Welcome to </Heading1>
        <img
          className="w-24 object-contain"
          src="/images/im_favicon_2.png"
          alt="Iteration Matrix Logo"
        />
      </div>
      <Paragraph className="pb-2">
        Unleash your company's potential through our Procure2Pay solutions for
        financial management and operational efficiency.
      </Paragraph>
      <Heading2 className="text-theme">Customer Benefits</Heading2>
      <Paragraph className="pb-2">
        Our payment programs boost department rebates, complemented by
        convenient phone and web payment options. Benefit from our tools for
        efficient statement reconciliation and duplicate payment prevention,
        along with credit and duplicate payment recovery services.
      </Paragraph>
      <Paragraph className="pb-2">
        Give your suppliers 24/7 invoice status access through our IM Portal,
        plus free late payment follow-up services. Transform paper invoices into
        digital formats, reducing manual work and cutting invoice processing
        costs by up to 80%. With Iteration Matrix, streamline your financial
        operations and improve supplier relationships effortlessly. Join us in
        achieving financial excellence!
      </Paragraph>
      <Heading2 className="text-theme">Supplier Benefits</Heading2>
      <Paragraph className="pb-2">
        Unlock the full potential of your invoicing process with Iteration
        Matrix! Speed up invoice delivery with our cutting-edge IM OCR
        submission tool. Effortlessly track invoice and payment statuses in
        real-time. Embrace the convenience of electronic invoice payments.
        Simplify your financial operations with our automated statement
        reconciliation feature. Stay ahead of the curve by proactively
        identifying and resolving invoice exceptions.
      </Paragraph>
      <Paragraph>
        To view our terms of service{" "}
        <Link
          to="/terms-of-service"
          className={cn(
            buttonVariants({size: "sm", variant: "link"}),
            "mt-4 max-h-4 p-0 text-lg font-semibold text-root"
          )}
        >
          <span className="sr-only">click here</span>
          click here.
        </Link>
      </Paragraph>
    </>
  );
}
