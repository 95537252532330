import {useEffect} from "react";

import {Heading1} from "@/components/ui/typography";

import {AdminRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";

/**
 * AdminReport Component
 *
 * This component provides the report for supplier usage & invoices loaded
 * and rendering the Admin report table.
 */
export function AdminReportPage() {
  // Fetch the admin report data using the custom hook `useAdminReports`
  // const searchAdminReportResponse = useAdminReports();

  // Destructure the `data` property, focusing on its `content` attribute
  // const adminReportData = searchAdminReportResponse.data?.content;

  // Custom hook for manipulating route data
  const {setAdminRoute} = useRoutesStore();

  // React `useEffect` hook to set the active admin route when the component mounts
  useEffect(() => {
    // Update the active route for Admin Report using the predefined route schema
    setAdminRoute(AdminRoutesSchema.Values["/app/admin/admin-report"]);
  }, [setAdminRoute]);

  // Display the loading section until data is available
  // if (!adminReportData) return <LoadingSection sectionSize="full" />;

  // Render the admin report table when the admin data available
  return (
    <section role="presentation">
      <Heading1 className="mb-4">Admin Report</Heading1>
      {/* <AdminReportDataTable
        columns={adminReportColumns}
        data={adminReportData}
      /> */}
    </section>
  );
}
