// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type GetPDFUploadType} from "@/modules/imremit";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface UploadedPDFTableColumnsStoreState {
  storeUploadedPDFTableColumns: GetPDFUploadType | null;
  setStoreUploadedPDFTableColumns: (
    storeUploadedPDFTableColumns: GetPDFUploadType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for UploadedPDF table columns
export const useUploadedPDFTableColumnsStore = create(
  persist<UploadedPDFTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeUploadedPDFTableColumns: null,

      // Getter function to retrieve the current state
      getStoreUploadedPDFTableColumns: () => get().storeUploadedPDFTableColumns,

      // Setter function to update the state
      setStoreUploadedPDFTableColumns: (storeUploadedPDFTableColumns) => {
        set(() => ({storeUploadedPDFTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "uploaded-pdf-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useUploadedPDFTableColumns = () =>
  useUploadedPDFTableColumnsStore((state) => {
    return {
      storeUploadedPDFTableColumns: state.storeUploadedPDFTableColumns,
    };
  });
