import {createRoute} from "@tanstack/react-router";

import {RoutesDirectory} from "@/lib/routes/directory";
import {ImREmitDashboardPage, imREmitRootRoute} from "@/modules/imremit";

export const imREmitDashboardRoute = createRoute({
  path: RoutesDirectory.DASHBOARD,
  component: ImREmitDashboardPage,
  getParentRoute: () => imREmitRootRoute,
});
