import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {supplierKeys, type SupplierType} from "@/modules/imremit";

// Defining a type based on the function `getAllSuppliers`
type QueryFnType = typeof getAllSuppliers;

// Function to search getAllSuppliers
export function getAllSuppliers() {
  return kyApiFn<SupplierType[]>(`supplier/v1/getallsuppliers`, "get");
}

// Custom hook to fetch and cache suppliers list based on the `participant id` data using react-query
export function useGetAllSuppliers(
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: supplierKeys.all(), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getAllSuppliers(), // Setting the function to be used for fetching data
  });
}
