import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  bankFileMappingKeys,
  type bankAuditMappingListType,
} from "@/modules/imremit";

//Defining a type based on the function `getAllBankFileMapping`
type QueryFnType = typeof getAllBankFileMapping;

//Funciton to get the bank file reports
export function getAllBankFileMapping() {
  // Fetch data from API using ky
  return kyApiFn<bankAuditMappingListType[]>(
    `api/e-payable/admin/master-mapping/bank`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useBankFileDetails(config?: QueryConfig<QueryFnType>) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: bankFileMappingKeys.allBankFileMapping(),
    queryFn: () => getAllBankFileMapping(),
  });
}
