import {useMemo} from "react";

import {Link, useRouter} from "@tanstack/react-router";
import {cva} from "class-variance-authority";
import {FolderCogIcon, LandmarkIcon, UserSquare2Icon} from "lucide-react";

import adminIcon from "@/assets/icons/account-management-icon.svg";
import duplicatePaymentsIcon from "@/assets/icons/duplicate-payment-prevention-icon.svg";
import imREmitIcon from "@/assets/icons/imremit-icon.svg";
import imREmitLiteIcon from "@/assets/icons/imremit-lite-icon.svg";
import electronicInvoicingIcon from "@/assets/icons/intelligent-invoicing-icon.svg";
import invoiceTrackerIcon from "@/assets/icons/invoice-tracker-icon.svg";
import statementReconIcon from "@/assets/icons/statement-reconcilliation-icon.svg";
import {Button} from "@/components/ui/button";
import {
  HoverCard,
  HoverCardArrow,
  HoverCardContent,
  HoverCardPortal,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {Heading3, Paragraph} from "@/components/ui/typography";

import {type RoutesType} from "@/lib/routes/types";
import {useRoutePersistence} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {AdminSubrouteNavigation} from "@/modules/admin";
import {
  AccessPolicyWrapper,
  AuthButton,
  KeycloakRoleEnum,
} from "@/modules/auth";
import {DuplicatePaymentsSubrouteNavigation} from "@/modules/duplicate-payments";
import {ImREmitSubrouteNavigation} from "@/modules/imremit";
import {ImREmitLiteSubrouteNavigation} from "@/modules/imremit-lite";
import {InvoicesSubrouteNavigation} from "@/modules/invoices";
import {ProfileSubrouteNavigation} from "@/modules/profile";
import {SettingsSubrouteNavigation} from "@/modules/settings";
import {StatementReconSubrouteNavigation} from "@/modules/statement-recon";

// Define the variants for the sidebar item
const sidebarItemVariants = cva(
  "w-full h-full flex flex-col justify-center items-center transition-colors text-center text-foreground no-underline px-1 md:h-16 hover:bg-accent/70 active:bg-neutral",
  {
    variants: {
      variant: {
        active: "bg-neutral hover:bg-neutral",
        inactive: "bg-root",
      },
    },
    defaultVariants: {
      variant: "inactive",
    },
  }
);

function SidebarHoverNavigation(sidebarItemLabel: string) {
  switch (sidebarItemLabel) {
    case "Invoice Tracker":
      return (
        <HoverCardPortal>
          <HoverCardContent className="max-w-sm" side="right">
            <HoverCardArrow />
            <Heading3 className="text-foreground">Invoice Tracker</Heading3>
            <Paragraph>
              Access the Invoice Tracker. Track your invoices and payments here.
            </Paragraph>
            <section
              aria-label="Invoice Tracker subroute navigation"
              className="pt-4"
            >
              <InvoicesSubrouteNavigation layout="vertical" />
            </section>
          </HoverCardContent>
        </HoverCardPortal>
      );
    case "Electronic Invoicing":
      return (
        <HoverCardPortal>
          <HoverCardContent className="max-w-sm" side="right">
            <HoverCardArrow />
            <Heading3 className="text-foreground">
              Electronic Invoicing
            </Heading3>
            <Paragraph>
              Access the Intelligent Invoicing module. Manage your invoices
              electronically.
            </Paragraph>
            <section
              aria-label="Intelligent Invoicing subroute navigation"
              className="pt-4"
            >
              <Button
                className="w-full justify-between py-6 text-base"
                onClick={() => {
                  window.open(
                    "https://iterationm.filebound.com/",
                    "Intelligent Invoicing Portal",
                    "width=1280,height=720"
                  );
                }}
              >
                <span className="sr-only">Intelligent Invoicing Portal</span>
                Intelligent Invoicing Portal
                <LandmarkIcon className="size-8" />
              </Button>
            </section>
          </HoverCardContent>
        </HoverCardPortal>
      );
    case "imREmit":
      return (
        <HoverCardPortal>
          <HoverCardContent className="max-w-sm" side="right">
            <HoverCardArrow />
            <Heading3 className="text-foreground">
              imREmit{" "}
              {/* <span className="text-xl uppercase text-success-foreground">
                &#40;premium&#41;
              </span> */}
            </Heading3>
            <Paragraph>
              Access the imREmit premium module. Manage your remittances here.
            </Paragraph>
            <section aria-label="ImREmit subroute navigation" className="pt-4">
              <ImREmitSubrouteNavigation layout="vertical" />
            </section>
          </HoverCardContent>
        </HoverCardPortal>
      );
    case "imREmit Lite":
      return (
        <HoverCardPortal>
          <HoverCardContent className="max-w-sm" side="right">
            <HoverCardArrow />
            <Heading3 className="text-foreground">
              imREmit{" "}
              <span className="text-xl uppercase text-success-foreground">
                &#40;lite&#41;
              </span>
            </Heading3>
            <Paragraph>
              Access the imREmit Lite module. Manage your remittances here.
            </Paragraph>
            <section
              aria-label="ImREmit Lite subroute navigation"
              className="pt-4"
            >
              <ImREmitLiteSubrouteNavigation layout="vertical" />
            </section>
          </HoverCardContent>
        </HoverCardPortal>
      );
    case "Duplicate Payments":
      return (
        <HoverCardPortal>
          <HoverCardContent className="max-w-sm" side="right">
            <HoverCardArrow />
            <Heading3 className="text-foreground">Duplicate Payments</Heading3>
            <Paragraph>
              Discover and view suspected duplicate payments here.
            </Paragraph>
            <section
              aria-label="Statement Recon subroute navigation"
              className="pt-4"
            >
              <DuplicatePaymentsSubrouteNavigation layout="vertical" />
            </section>
          </HoverCardContent>
        </HoverCardPortal>
      );
    case "Statement Recon":
      return (
        <HoverCardPortal>
          <HoverCardContent className="max-w-sm" side="right">
            <HoverCardArrow />
            <Heading3 className="text-foreground">Statement Recon</Heading3>
            <Paragraph>
              Access the Statement Recon module. Perform statement
              reconciliation here.
            </Paragraph>
            <section
              aria-label="Statement Recon subroute navigation"
              className="pt-4"
            >
              <StatementReconSubrouteNavigation layout="vertical" />
            </section>
          </HoverCardContent>
        </HoverCardPortal>
      );
    case "Admin":
      return (
        <HoverCardPortal>
          <HoverCardContent className="max-w-sm" side="right">
            <HoverCardArrow />
            <Heading3 className="text-foreground">Admin</Heading3>
            <Paragraph>
              iM user administration module for managing customers, suppliers,
              buyers and their permissions.
            </Paragraph>
            <section aria-label="Admin subroute navigation" className="pt-4">
              <AdminSubrouteNavigation layout="vertical" />
            </section>
          </HoverCardContent>
        </HoverCardPortal>
      );
    case "Settings":
      return (
        <HoverCardPortal>
          <HoverCardContent className="max-w-sm" side="right">
            <HoverCardArrow />
            <Heading3 className="text-foreground">Settings</Heading3>
            <Paragraph>
              Access the Settings module. Manage your settings here.
            </Paragraph>
            <section aria-label="Settings subroute navigation" className="pt-4">
              <SettingsSubrouteNavigation layout="vertical" />
            </section>
          </HoverCardContent>
        </HoverCardPortal>
      );
    case "Profile":
      return (
        <HoverCardPortal>
          <HoverCardContent className="max-w-sm" side="right">
            <HoverCardArrow />
            <Heading3 className="text-foreground">User Profile</Heading3>
            <Paragraph>
              Access the User Profile module. Manage your user profile here.
            </Paragraph>
            <section aria-label="Profile subroute navigation" className="pt-4">
              <ProfileSubrouteNavigation layout="vertical" />
            </section>
          </HoverCardContent>
        </HoverCardPortal>
      );

    default:
      return (
        <HoverCardPortal>
          <HoverCardContent className="max-w-sm" side="right">
            <HoverCardArrow />
            <Heading3 className="text-foreground">Oops!!</Heading3>
            <Paragraph>Something went wrong. Please try again later.</Paragraph>
          </HoverCardContent>
        </HoverCardPortal>
      );
  }
}

// Define SidebarItemProps which will be the type of props for the SidebarItem component
interface SidebarItemProps {
  label: string;
  icon: React.ReactNode;
  routeId: RoutesType;
}

// SidebarItem is a functional component that displays a single item in the sidebar.
function SidebarItem({label, icon, routeId}: SidebarItemProps) {
  // Get pathname of user's current route
  const {pathname} = useRouter().latestLocation;

  const {
    invoiceTrackerRoute,
    electronicInvoicingRoute,
    imREmitRoute,
    imREmitLiteRoute,
    duplicatePaymentRoute,
    statementReconRoute,
    // tradingPartnerRoute,
    // supplierManagementRoute,
    adminRoute,
    settingsRoute,
    profileRoute,
    // customerOnboardingRoute,
  } = useRoutePersistence();

  // Temporary implementation for testing
  function getTopLevelPathname(routeUrl: RoutesType) {
    switch (routeUrl) {
      case "/app/invoices/tracker":
        return invoiceTrackerRoute ? invoiceTrackerRoute : "/app/invoices";
      case "/app/electronic-invoicing":
        return electronicInvoicingRoute
          ? electronicInvoicingRoute
          : "/app/electronic-invoicing";
      case "/app/imremit":
        return imREmitRoute ? imREmitRoute : "/app/imremit/dashboard";
      case "/app/imremit-lite":
        return imREmitLiteRoute
          ? imREmitLiteRoute
          : "/app/imremit-lite/dashboard";
      case "/app/duplicate-payments":
        return duplicatePaymentRoute
          ? duplicatePaymentRoute
          : "/app/duplicate-payments/dashboard";
      case "/app/statement-recon":
        return statementReconRoute
          ? statementReconRoute
          : "/app/statement-recon/upload";
      case "/app/admin":
        return adminRoute ? adminRoute : "/app/admin";
      case "/app/settings":
        return settingsRoute ? settingsRoute : "/app/settings";
      case "/app/profile":
        return profileRoute ? profileRoute : "/app/profile";
      default:
        return "/app/invoices";
    }
  }

  const variantMemo = useMemo(() => {
    const module = pathname.split("/");
    const routeIdmodule = routeId.split("/");
    if (module[2] === routeIdmodule[2]) {
      return "active";
    } else {
      return "inactive";
    }
  }, [pathname, routeId]);

  // The link contains the icon and label of the route.
  return (
    <HoverCard openDelay={125} closeDelay={50}>
      <HoverCardTrigger asChild>
        <Link
          to={getTopLevelPathname(routeId)}
          preload="intent"
          className={cn(
            sidebarItemVariants({
              variant: variantMemo,
            })
          )}
        >
          {icon}
        </Link>
      </HoverCardTrigger>
      {SidebarHoverNavigation(label)}
    </HoverCard>
  );
}

// Sidebar is a functional component that displays a list of sidebar items.
export function Sidebar() {
  // The Sidebar component renders a wrapper (SidebarWrapper) which contains all the sidebar items.
  return (
    <aside className="fixed top-0 hidden min-h-full bg-root shadow-md md:flex md:w-16 md:flex-col md:justify-between md:pt-20">
      <section className="flex flex-col">
        {
          // TEMPORARY
          <>
            <AccessPolicyWrapper
              policyActions={[KeycloakRoleEnum.READ_INVOICES]}
              unauthorizedFallback={null}
            >
              <SidebarItem
                label="Invoice Tracker"
                routeId="/app/invoices/tracker"
                icon={
                  <img
                    src={invoiceTrackerIcon}
                    className="size-10"
                    alt="Invoice Tracker Icon"
                  />
                }
              />
            </AccessPolicyWrapper>
            <AccessPolicyWrapper
              unauthorizedFallback={null}
              policyActions={[KeycloakRoleEnum.READ_IMREMIT]}
            >
              <SidebarItem
                label="imREmit"
                routeId="/app/imremit"
                icon={
                  <img
                    src={imREmitIcon}
                    className="size-10"
                    alt="imREmit Icon"
                  />
                }
              />
            </AccessPolicyWrapper>

            <AccessPolicyWrapper
              unauthorizedFallback={null}
              policyActions={[KeycloakRoleEnum.READ_IMREMIT_LITE]}
            >
              <SidebarItem
                label="imREmit Lite"
                routeId="/app/imremit-lite"
                icon={
                  <img
                    src={imREmitLiteIcon}
                    className="size-10"
                    alt="imREmit Lite Icon"
                  />
                }
              />
            </AccessPolicyWrapper>

            <AccessPolicyWrapper
              policyActions={[KeycloakRoleEnum.READ_ELECTRONIC_INVOICING]}
              unauthorizedFallback={null}
            >
              <SidebarItem
                label="Electronic Invoicing"
                routeId="/app/electronic-invoicing"
                icon={
                  <button
                    className="cursor-pointer border-none bg-transparent p-0"
                    tabIndex={0} // Make the button focusable
                    onClick={() => {
                      window.open(
                        "https://iterationm.filebound.com/",
                        "Intelligent Invoicing Portal",
                        "width=1280,height=720"
                      );
                    }}
                    onKeyDown={(event) => {
                      // Handle Enter and Space key to mimic click behavior
                      if (event.key === "Enter" || event.key === "Space") {
                        window.open(
                          "https://iterationm.filebound.com/",
                          "Intelligent Invoicing Portal",
                          "width=1280,height=720"
                        );
                      }
                    }}
                  >
                    <img
                      src={electronicInvoicingIcon}
                      className="size-10" // Ensure this class or its replacement handles the image sizing appropriately
                      alt="Electronic Invoicing Icon"
                    />
                  </button>
                }
              />
            </AccessPolicyWrapper>
            <AccessPolicyWrapper
              unauthorizedFallback={null}
              policyActions={[KeycloakRoleEnum.READ_DUPLICATE_PAYMENTS]}
            >
              <SidebarItem
                label="Duplicate Payments"
                routeId="/app/duplicate-payments"
                icon={
                  <img
                    src={duplicatePaymentsIcon}
                    className="size-10"
                    alt="Duplicate Payments Icon"
                  />
                }
              />
            </AccessPolicyWrapper>
            <AccessPolicyWrapper
              unauthorizedFallback={null}
              policyActions={[KeycloakRoleEnum.READ_STATEMENT_RECON]}
            >
              <SidebarItem
                label="Statement Recon"
                routeId="/app/statement-recon"
                icon={
                  <img
                    src={statementReconIcon}
                    className="size-10"
                    alt="Statement Recon Icon"
                  />
                }
              />
            </AccessPolicyWrapper>
            <AccessPolicyWrapper
              unauthorizedFallback={null}
              policyActions={[KeycloakRoleEnum.READ_ADMIN]}
            >
              <SidebarItem
                label="Admin"
                routeId="/app/admin"
                icon={
                  <img src={adminIcon} className="size-10" alt="Admin Icon" />
                }
              />
            </AccessPolicyWrapper>
          </>
        }
      </section>
      <section className="flex flex-col">
        <SidebarItem
          label="Profile"
          icon={<UserSquare2Icon className="size-8" />}
          routeId="/app/profile"
        />
        <SidebarItem
          label="Settings"
          icon={<FolderCogIcon className="size-8" />}
          routeId="/app/settings"
        />
        <AuthButton />
      </section>
    </aside>
  );
}
