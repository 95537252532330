import {Badge} from "@/components/ui/badge";
import {Card} from "@/components/ui/card";
import {Paragraph} from "@/components/ui/typography";

import type {SupplierScript} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";

export function ViewScriptItem({item}: {item: SupplierScript}) {
  const renderScriptItem = (
    label: string,
    value: string | number | undefined
  ) => (
    <div className="flex w-1/3 px-2 py-1">
      <Paragraph className="mr-2 font-semibold">{label}</Paragraph>
      <Paragraph className="whitespace-wrap break-all">
        {value !== undefined ? (
          value
        ) : (
          <Badge variant="destructive">{FallbackMessages.NOT_APPLICABLE}</Badge>
        )}
      </Paragraph>
    </div>
  );

  return (
    <Card className="mb-2 space-y-1 p-2">
      <div className="flex w-full flex-wrap">
        {renderScriptItem("Supplier Script Id:", item.supplierScriptId)}
        {renderScriptItem("Sequence Id:", item.sequenceId)}
        {renderScriptItem("Login Sequence:", item.sequenceIdentifier)}
      </div>

      <div className="flex w-full flex-wrap">
        {renderScriptItem("Payment Timer:", item.paymentTimer)}
        {renderScriptItem("Captcha Timer:", item.captchaTimer)}
        {renderScriptItem("Timer:", item.timer)}
      </div>

      <div className="flex w-full flex-wrap">
        {renderScriptItem("Field Type:", item.fieldType)}
        {renderScriptItem("Table Name:", item.tableName)}
        {renderScriptItem("Table Column Name:", item.tableColumnName)}
      </div>

      <div className="flex w-full flex-wrap">
        {renderScriptItem("Position:", item.position)}
        {renderScriptItem("Default Value:", item.dropDownValue)}
        {renderScriptItem("Separator:", item.separator)}
      </div>

      <div className="flex w-full flex-wrap">
        {renderScriptItem("X-Path:", item.fieldValue)}
        {renderScriptItem("Field Name:", item.fieldName)}
        {renderScriptItem("Date Format:", item.dateFormat)}
      </div>

      <div className="flex w-full flex-wrap">
        {renderScriptItem("Js Check:", item.jsCheck)}
        {renderScriptItem("Find Element By:", item.findElementBy)}
        {renderScriptItem("Page Name:", item.pageCategory)}
      </div>
    </Card>
  );
}
