// Define the validation schema using zod library.

import {z} from "zod";

import {multiSelectDatumSchema} from "@/components/craft/fancy-multi-select";

import {phoneRegex, zipCodeRegex} from "@/utils/constants";

// This schema is used to validate and coerce the input values from the form.

export const SearchSuppliersFormSchema = z.object({
  customerName: z.string(),
  supplierId: z.array(multiSelectDatumSchema),
  supplierEmail: z.string().email("Please enter a valid email address."),
  zip: z.string().regex(zipCodeRegex, "Please enter a valid ZIP code."),
  phoneNumber: z
    .string()
    .regex(phoneRegex, "Please enter a valid phone number."),
  onboardStatus: z.string().optional(),
  lastEditTimestamp: z.string().nullable(),
  supplierNumber: z.string().optional(),
});
