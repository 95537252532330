import {useEffect, useState} from "react";

import {CheckIcon, ChevronsUpDownIcon, InfoIcon} from "lucide-react";
import {useFormContext} from "react-hook-form";

import {Button} from "@/components/ui/button";
import {Checkbox} from "@/components/ui/checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {TimePicker} from "@/components/ui/time-picker/time-picker";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {Heading3, Paragraph} from "@/components/ui/typography";
import {FancyMultiSelect} from "@/components/craft/fancy-multi-select";

import {cn} from "@/lib/utils";
import {
  CustomerRunnerConfigEnum,
  useDateFormats,
  useDelimiterTypes,
  useDocumentFormats,
  usePaymentProviders,
} from "@/modules/admin";

// Helper function to handle trimming
const trimValue = (value: string) => (value || "").trim();

export function PaymentProviderField({isMutating}: {isMutating: boolean}) {
  const allPaymentProviders = usePaymentProviders();
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="paymentProvider"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="paymentProvider" showMandatoryAsterisk>
            Payment Provider:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allPaymentProviders.find(
                        (paymentProvider) =>
                          paymentProvider.value === field.value
                      )?.label
                    : "Select Payment Provider..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Payment Providers..." />
                <CommandEmpty>No Payment Providers found.</CommandEmpty>
                <CommandGroup>
                  {allPaymentProviders.map((paymentProvider) => (
                    <CommandItem
                      key={paymentProvider.value}
                      value={paymentProvider.label}
                      onSelect={() => {
                        setValue("paymentProvider", paymentProvider.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          paymentProvider.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {paymentProvider.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function DocumentFormatField({isMutating}: {isMutating: boolean}) {
  const allDocumentFormats = useDocumentFormats(
    CustomerRunnerConfigEnum.PAYMENT_RUNNER
  );
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="documentFormat"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="documentFormat" showMandatoryAsterisk>
            Document Format:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "min-w-64 justify-between gap-2 whitespace-nowrap",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDocumentFormats.find(
                        (documentFormat) => documentFormat.value === field.value
                      )?.label
                    : "Select Document Format..."}
                  <ChevronsUpDownIcon className="size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Document Format..." />
                <CommandEmpty>No Document Format found.</CommandEmpty>
                <CommandGroup>
                  {allDocumentFormats.map((documentFormat) => (
                    <CommandItem
                      key={documentFormat.value}
                      value={documentFormat.label}
                      onSelect={() => {
                        setValue("documentFormat", documentFormat.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          documentFormat.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {documentFormat.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function DateFormatField({
  isMutating,
  isISO,
}: {
  isMutating: boolean;
  isISO: boolean;
}) {
  const allDateFormats = useDateFormats();
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="dateFormat"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="dateFormat" showMandatoryAsterisk>
            Date Format:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating || isISO}
                  aria-disabled={isMutating || isISO}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDateFormats.find(
                        (dateFormat) => dateFormat.value === field.value
                      )?.label
                    : "Select Date Format..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Date Format..." />
                <CommandEmpty>No Date Formats found.</CommandEmpty>
                <CommandGroup>
                  {allDateFormats.map((dateFormat) => (
                    <CommandItem
                      key={dateFormat.value}
                      value={dateFormat.label}
                      onSelect={() => {
                        setValue("dateFormat", dateFormat.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          dateFormat.value.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {dateFormat.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function DelimiterTypeField({
  isMutating,
  isISO,
}: {
  isMutating: boolean;
  isISO: boolean;
}) {
  const allDelimiterTypes = useDelimiterTypes();
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="delimiterType"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="delimiterType" showMandatoryAsterisk>
            Delimiter Type:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating || isISO}
                  aria-disabled={isMutating || isISO}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDelimiterTypes.find(
                        (delimiterType) => delimiterType.value === field.value
                      )?.label
                    : "Select Delimiter Type..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Delimiter Type..." />
                <CommandEmpty>No Delimiter Type found.</CommandEmpty>
                <CommandGroup>
                  {allDelimiterTypes.map((delimiterType) => (
                    <CommandItem
                      key={delimiterType.value}
                      value={delimiterType.label}
                      onSelect={() => {
                        setValue("delimiterType", delimiterType.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          delimiterType.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {delimiterType.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function FileNameContainerField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="fileNameContainer"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="fileNameContainer" showMandatoryAsterisk>
            File Name Container:
          </FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="text"
              placeholder="Enter the file name container..."
              {...register("fileNameContainer", {
                setValueAs: trimValue,
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function ValidMinLengthField({
  isMutating,
  isISO,
}: {
  isMutating: boolean;
  isISO: boolean;
}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="validMinimumLength"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="validMinimumLength" showMandatoryAsterisk>
            Valid Mini Length:
          </FormLabel>
          <FormControl>
            <Input
              disabled={isMutating || isISO}
              aria-disabled={isMutating || isISO}
              type="number"
              min={5}
              placeholder="Enter valid mini length..."
              {...register("validMinimumLength", {
                setValueAs: (v) =>
                  v === "" ? undefined : parseInt(v as string, 10),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function DenormalizeDocumentFormatField({
  isMutating,
}: {
  isMutating: boolean;
}) {
  const allDenormalizeDocumentFormats = useDocumentFormats("2");
  const {control, setValue} = useFormContext();

  // Find the default document format
  const defaultDocumentFormat = allDenormalizeDocumentFormats.find(
    (format) => format.label === "Create Order Request"
  )?.value;

  // Set the default value for the form field
  useEffect(() => {
    if (defaultDocumentFormat) {
      setValue("documentFormatDenormalize", defaultDocumentFormat);
    }
  }, [defaultDocumentFormat, setValue]);

  return (
    <FormField
      control={control}
      name="documentFormatDenormalize"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="documentFormatDenormalize">
            Denormalize Document Format:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "min-w-64 justify-between gap-2 whitespace-nowrap",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDenormalizeDocumentFormats.find(
                        (documentFormat) => documentFormat.value === field.value
                      )?.label
                    : "Select Document Format..."}
                  <ChevronsUpDownIcon className="size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Document Format..." />
                <CommandEmpty>No Document Format found.</CommandEmpty>
                <CommandGroup>
                  {allDenormalizeDocumentFormats.map((documentFormat) => (
                    <CommandItem
                      key={documentFormat.value}
                      value={documentFormat.label}
                      onSelect={() => {
                        setValue(
                          "documentFormatDenormalize",
                          documentFormat.value
                        );
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          documentFormat.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {documentFormat.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function FirstRowHeaderField({
  isMutating,
  isISO,
}: {
  isMutating: boolean;
  isISO: boolean;
}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="firstRowHeader"
      render={({field}) => (
        <FormItem className="flex w-56 items-end justify-between gap-4">
          <FormLabel htmlFor="firstRowHeader" showMandatoryAsterisk>
            First Row Header:
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating || isISO}
              aria-disabled={isMutating || isISO}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("firstRowHeader")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function CustomMappingField({
  isMutating,
  isISO,
}: {
  isMutating: boolean;
  isISO: boolean;
}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="customMapping"
      render={({field}) => (
        <FormItem className="flex w-56 items-end justify-between gap-4">
          <FormLabel htmlFor="customMapping">Custom Mapping:</FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating || isISO}
              aria-disabled={isMutating || isISO}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("customMapping")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function IsRecordTypeRequiredField({
  isMutating,
  isISO,
}: {
  isMutating: boolean;
  isISO: boolean;
}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="isRecordTypeRequired"
      render={({field}) => (
        <FormItem className="flex w-56 items-end justify-between gap-4">
          <FormLabel htmlFor="isRecordTypeRequired">
            Record Type Required:
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating || isISO}
              aria-disabled={isMutating || isISO}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("isRecordTypeRequired")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function ResponseFileRequiredField({isMutating}: {isMutating: boolean}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="responseRequired"
      render={({field}) => (
        <FormItem className="flex w-56 items-end justify-between gap-4">
          <FormLabel htmlFor="responseRequired">
            Response File Required:
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating}
              aria-disabled={isMutating}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("responseRequired")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function IsPaymentFileInternalAlertRequiredField({
  isMutating,
}: {
  isMutating: boolean;
}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="isPaymentFileInternalAlertRequired"
      render={({field}) => (
        <FormItem className="flex w-56 justify-between gap-4">
          <FormLabel
            className="flex gap-2 pt-1"
            htmlFor="isPaymentFileInternalAlertRequired"
          >
            <Paragraph>Payment File Alerts:</Paragraph>
            <Popover>
              <PopoverTrigger asChild>
                <InfoIcon className="size-4 text-blue-500" />
              </PopoverTrigger>
              <PopoverContent className="w-80">
                <div className="pb-4">
                  <Heading3>Payment File Alerts</Heading3>
                </div>
                <div className="flex flex-col gap-4">
                  <Paragraph>
                    Select if you want to receive alerts for payment files not
                    being received.
                  </Paragraph>
                  <Paragraph>
                    Payment File Frequency: Select the frequency on when a
                    Payment File will be received by the Customer (Payment Run
                    Cycle)
                  </Paragraph>
                  <Paragraph>
                    Expected Time: Select the expected time the Customer will
                    transmit a Payment File. If there is no specific time, then
                    select “Not Specified”.
                  </Paragraph>
                  <Paragraph>
                    Note: An Alert Email will be triggered if a payment file has
                    not been received within 2 hours of the specified time.
                    Otherwise, an alert email will be triggered in 24 hours.
                  </Paragraph>
                </div>
              </PopoverContent>
            </Popover>
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating}
              aria-disabled={isMutating}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("isPaymentFileInternalAlertRequired")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function ResponseFooterRequiredField({
  isMutating,
  isISO,
}: {
  isMutating: boolean;
  isISO: boolean;
}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="isResponseFooterRequired"
      render={({field}) => (
        <FormItem>
          <FormLabel className="mr-4" htmlFor="isResponseFooterRequired">
            Response Footer Required:
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating || isISO}
              aria-disabled={isMutating || isISO}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("isResponseFooterRequired")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function ResponseHeaderDateFormatField({
  isMutating,
  isISO,
}: {
  isMutating: boolean;
  isISO: boolean;
}) {
  const allDateFormats = useDateFormats();
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="responseHeaderDateFormat"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="responseHeaderDateFormat">
            Response Header Date Format:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating || isISO}
                  aria-disabled={isMutating || isISO}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDateFormats.find(
                        (dateFormat) => dateFormat.value === field.value
                      )?.label
                    : "Select Date Format..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Date Format..." />
                <CommandEmpty>No Date Formats found.</CommandEmpty>
                <CommandGroup>
                  {allDateFormats.map((dateFormat) => (
                    <CommandItem
                      key={dateFormat.value}
                      value={dateFormat.label}
                      onSelect={() => {
                        setValue("responseHeaderDateFormat", dateFormat.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          dateFormat.value.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {dateFormat.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function FileNamePartField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="fileNamePart"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="fileNamePart">Response File Name Part:</FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter Response File Name Part..."
              {...register("fileNamePart", {
                setValueAs: trimValue,
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function PaymentFileFrequencyField({isMutating}: {isMutating: boolean}) {
  const {setValue, watch} = useFormContext();
  const fileFrequencies = [
    {value: "MONDAY", label: "Monday"},
    {value: "TUESDAY", label: "Tuesday"},
    {value: "WEDNESDAY", label: "Wednesday"},
    {value: "THURSDAY", label: "Thursday"},
    {value: "FRIDAY", label: "Friday"},
    {value: "SATURDAY", label: "Saturday"},
    {value: "SUNDAY", label: "Sunday"},
  ];
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const paymentFileFrequencies = watch("paymentFileFrequencies") as string[];

  useEffect(() => {
    setSelectedValues(paymentFileFrequencies);
    setValue("paymentFileFrequency", paymentFileFrequencies.join(","));
  }, [paymentFileFrequencies, setValue]);

  const handleSelectionChange = (
    selectedItems: {value: string; label: string}[]
  ) => {
    const selectedValues = selectedItems.map((item) => item.value);
    setSelectedValues(selectedValues);
    const selectedValuesString = selectedValues.join(",");
    setValue("paymentFileFrequency", selectedValuesString);
  };
  return (
    <FormField
      name="paymentFileFrequency"
      render={({field}) => (
        <FormItem>
          <div className="flex space-x-2">
            <FormLabel htmlFor="paymentFileFrequency" showMandatoryAsterisk>
              Payment File Frequency
            </FormLabel>
            <TooltipProvider delayDuration={200}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="cursor-pointer">
                    <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="pb-4">
                    <Heading3>Payment File Alerts</Heading3>
                  </div>
                  <div className="flex flex-col gap-4">
                    <Paragraph>
                      Select if you want to receive alerts for payment files not
                      being received.
                    </Paragraph>
                    <Paragraph>
                      Payment File Frequency: Select the frequency on when a
                      Payment File will be received by the Customer (Payment Run
                      Cycle)
                    </Paragraph>
                    <Paragraph>
                      Expected Time: Select the expected time the Customer will
                      transmit a Payment File. If there is no specific time,
                      then select “Not Specified”.
                    </Paragraph>
                    <Paragraph>
                      Note: An Alert Email will be triggered if a payment file
                      has not been received within 2 hours of the specified
                      time. Otherwise, an alert email will be triggered in 24
                      hours.
                    </Paragraph>
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <FormControl>
            <FancyMultiSelect
              disabled={isMutating}
              multiSelectData={fileFrequencies}
              name="payment file frequency"
              placeholder="Select payment file frequency..."
              value={fileFrequencies.filter((item) =>
                selectedValues.includes(item.value)
              )}
              onChange={(selectedItems: {value: string; label: string}[]) => {
                field.onChange(selectedItems);
                handleSelectionChange(selectedItems);
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function ExpectedTimeField() {
  const {setValue, watch} = useFormContext();
  const [notSpecifiedIsChecked, setIsChecked] = useState(false);

  const paymentFileExpectedTime = watch("paymentFileExpectedTime") as string;

  useEffect(() => {
    if (notSpecifiedIsChecked) {
      setValue("paymentFileExpectedTime", null);
    }
  }, [notSpecifiedIsChecked, setValue]);

  const handleCheckboxChange = (newValue: boolean) => {
    setIsChecked(newValue);
  };

  return (
    <FormField
      name="paymentFileExpectedTime"
      render={() => (
        <FormItem>
          <div className="flex space-x-2">
            <FormLabel htmlFor="paymentFileExpectedTime" showMandatoryAsterisk>
              Expected Time:
            </FormLabel>
            <TooltipProvider delayDuration={200}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="cursor-pointer">
                    <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="pb-4">
                    <Heading3>Payment File Alerts</Heading3>
                  </div>
                  <div className="flex flex-col gap-4">
                    <Paragraph>
                      Select if you want to receive alerts for payment files not
                      being received.
                    </Paragraph>
                    <Paragraph>
                      Payment File Frequency: Select the frequency on when a
                      Payment File will be received by the Customer (Payment Run
                      Cycle)
                    </Paragraph>
                    <Paragraph>
                      Expected Time: Select the expected time the Customer will
                      transmit a Payment File. If there is no specific time,
                      then select “Not Specified”.
                    </Paragraph>
                    <Paragraph>
                      Note: An Alert Email will be triggered if a payment file
                      has not been received within 2 hours of the specified
                      time. Otherwise, an alert email will be triggered in 24
                      hours.
                    </Paragraph>
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <FormControl>
            <TimePicker
              disabled={notSpecifiedIsChecked}
              dateValue={paymentFileExpectedTime}
              onChange={(value) => {
                const hours = value?.getHours();
                const minutes = value?.getMinutes();
                const formattedTime = `${
                  hours?.toString().padStart(2, "0") ?? "00"
                }:${minutes?.toString().padStart(2, "0") ?? "00"}`;
                setValue("paymentFileExpectedTime", formattedTime);
              }}
            />
          </FormControl>
          <FormMessage />
          <Checkbox
            checked={notSpecifiedIsChecked}
            onCheckedChange={handleCheckboxChange}
          />
          <FormLabel className="ml-2">Not Specified</FormLabel>
        </FormItem>
      )}
    />
  );
}
export function ExtensionField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="extension"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="extension">Response Extension:</FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter Response Extension..."
              {...register("extension")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function LineSeparatorField({
  isMutating,
  isISO,
}: {
  isMutating: boolean;
  isISO: boolean;
}) {
  const allDelimiterTypes = useDelimiterTypes();
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="lineSeparator"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="lineSeparator">
            Response Line Separator:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating || isISO}
                  aria-disabled={isMutating || isISO}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDelimiterTypes.find(
                        (lineSeparator) => lineSeparator.value === field.value
                      )?.label
                    : "Select Delimiter Type..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Delimiter Type..." />
                <CommandEmpty>No Delimiter Type found.</CommandEmpty>
                <CommandGroup>
                  {allDelimiterTypes.map((lineSeparator) => (
                    <CommandItem
                      key={lineSeparator.value}
                      value={lineSeparator.label}
                      onSelect={() => {
                        setValue("lineSeparator", lineSeparator.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          lineSeparator.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {lineSeparator.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function ResponseDataDateFormatField({
  isMutating,
  isISO,
}: {
  isMutating: boolean;
  isISO: boolean;
}) {
  const allDateFormats = useDateFormats();
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="responseDataDateFormat"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="responseDataDateFormat">
            Response Data Date Format:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating || isISO}
                  aria-disabled={isMutating || isISO}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDateFormats.find(
                        (dateFormat) => dateFormat.value === field.value
                      )?.label
                    : "Select Date Format..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Date Format..." />
                <CommandEmpty>No Date Formats found.</CommandEmpty>
                <CommandGroup>
                  {allDateFormats.map((dateFormat) => (
                    <CommandItem
                      key={dateFormat.value}
                      value={dateFormat.label}
                      onSelect={() => {
                        setValue("responseDataDateFormat", dateFormat.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          dateFormat.value.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {dateFormat.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function FileNameDateFormatField({isMutating}: {isMutating: boolean}) {
  const allDateFormats = useDateFormats();
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="fileNameDateFormat"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="fileNameDateFormat">
            Response File Name Date Format:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDateFormats.find(
                        (dateFormat) => dateFormat.value === field.value
                      )?.label
                    : "Select Date Format..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Date Format..." />
                <CommandEmpty>No Date Formats found.</CommandEmpty>
                <CommandGroup>
                  {allDateFormats.map((dateFormat) => (
                    <CommandItem
                      key={dateFormat.value}
                      value={dateFormat.label}
                      onSelect={() => {
                        setValue("fileNameDateFormat", dateFormat.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          dateFormat.value.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {dateFormat.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function RecordTypeLengthField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="recordTypeLength"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="recordTypeLength">Record Type Length:</FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="number"
              placeholder="Enter record type length..."
              {...register("recordTypeLength", {
                setValueAs: (v) =>
                  v === "" ? undefined : parseInt(v as string, 10),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function HeaderRecordTypeField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="headerRecordType"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="headerRecordType">Header Record Type:</FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="text"
              placeholder="Enter the header record type..."
              {...register("headerRecordType", {
                setValueAs: trimValue,
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function DetailRecordTypeField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="detailRecordType"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="detailRecordType">Detail Record Type:</FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="text"
              placeholder="Enter the detail record type..."
              {...register("detailRecordType", {
                setValueAs: trimValue,
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function CustomRecordTypeField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="customRecordType"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customRecordType">Custom Record Type:</FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="text"
              placeholder="Enter the custom record type..."
              {...register("customRecordType", {
                setValueAs: trimValue,
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function DataRecordTypeField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="dataRecordType"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="dataRecordType">Data Record Type:</FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="text"
              placeholder="Enter the data record type..."
              {...register("dataRecordType", {
                setValueAs: trimValue,
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function FooterRecordTypeField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="footerRecordType"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="footerRecordType">Footer Record Type:</FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="text"
              placeholder="Enter the footer record type..."
              {...register("footerRecordType", {
                setValueAs: trimValue,
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function CustomerResponseViaEmailField({
  isMutating,
}: {
  isMutating: boolean;
}) {
  const {control} = useFormContext();

  const emailOptions = [
    {
      label: "Email",
      value: "Email",
    },
    {
      label: "SFTP",
      value: "SFTP",
    },
    {
      label: "Email and SFTP",
      value: "EmailAndSFTP",
    },
  ];

  return (
    <FormField
      control={control}
      name="customerResponseViaEmail"
      render={({field}) => (
        <FormItem>
          <FormLabel className="mr-2" htmlFor="customerResponseViaEmail">
            Customer response file transfer:
          </FormLabel>

          <Select //eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            value={field.value}
            disabled={isMutating}
            aria-disabled={isMutating}
            onValueChange={field.onChange}
          >
            <FormControl>
              <SelectTrigger className="bg-white">
                <SelectValue placeholder="Select" />
              </SelectTrigger>
            </FormControl>
            <SelectContent className="bg-white">
              {emailOptions.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <FormMessage />
        </FormItem>
      )}
    />
  );
}
