import {useCallback} from "react";

import {Link} from "@tanstack/react-router";
import {cva} from "class-variance-authority";
import {ExternalLinkIcon} from "lucide-react";

import {Card, CardContent, CardHeader, CardTitle} from "@/components/ui/card";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {Skeleton} from "@/components/ui/skeleton";
import {Paragraph} from "@/components/ui/typography";

import {cn} from "@/lib/utils";
import {type DupesTrackerSearchFilterType} from "@/modules/duplicate-payments";

/**
 * 🎨 Define color variants for the DupesDashboardCards component using cva for dynamic class names.
 */
export const dupesColourVariants = cva("text-4xl font-bold", {
  variants: {
    variant: {
      good: "text-neutral", // 🟢 Good variant
      notsogood: "text-secondary", // 🟡 Not-so-good variant
      bad: "text-destructive-foreground", // 🔴 Bad variant
    },
  },
  defaultVariants: {
    variant: "good", // Default to good variant
  },
});

/**
 * 📝 Type definition for DupesCard component props.
 */
interface DupesCardProps {
  dupesCardTitle: string;
  dupesCardContent: React.ReactNode;
  dupesSearchFilter: DupesTrackerSearchFilterType;
}

/**
 * Renders a single DupesCard component.
 *
 * @param props - Component properties.
 * @returns Rendered DupesCard JSX element.
 */
function DupesCard({
  dupesCardTitle,
  dupesCardContent,
  dupesSearchFilter,
}: DupesCardProps) {
  // 🖼️ Render the DupesCard component structure.
  return (
    <Card>
      <CardHeader className="flex justify-between">
        <div className="flex items-center justify-between">
          <CardTitle className="text-xl">{dupesCardTitle}</CardTitle>
          <HoverCard openDelay={75} closeDelay={125}>
            <HoverCardTrigger asChild>
              <Link
                to="/app/duplicate-payments/tracker"
                className="ml-2 rounded-full p-2 outline-none hover:bg-success-foreground/80 hover:text-root active:bg-success-foreground/90 active:text-root"
                search={{dupesLikeliness: dupesSearchFilter.dupesLikeliness}}
              >
                <ExternalLinkIcon className="size-6 rounded-full bg-transparent text-inherit" />
              </Link>
            </HoverCardTrigger>
            <HoverCardContent className="max-w-prose translate-x-[-1.5rem]">
              <Paragraph>
                View all{" "}
                <span className="font-bold">
                  {dupesCardTitle.toUpperCase()}
                </span>{" "}
                duplicates.
              </Paragraph>
            </HoverCardContent>
          </HoverCard>
        </div>
      </CardHeader>
      <CardContent>{dupesCardContent}</CardContent>
    </Card>
  );
}

/**
 * 📊 Props for the DupesDashboardCards container component.
 */
interface DupesCardsProps {
  counts: {
    likelyCount: number; // 📈 Likely duplicates count
    maybeCount: number; // 🤔 Maybe duplicates count
    differentSupplierLikelyCount: number; // 🏭 Different supplier likely count
    differentSupplierMaybeCount: number; // 🏢 Different supplier maybe count
    differentAmtsAndDatesCount: number; // 💸 Different amounts and dates count
    differentDatesCount: number; // 📅 Different dates count
    differentSuppliersAndDatesCount: number; // 🏭📅 Different suppliers and dates count
    differentAmountsCount: number; // 💰 Different amounts count
    creditPairCount: number; // 💳 Credit pair count
  };
  amounts: {
    likelyAmount: number; // 💰 Likely duplicates amount
    maybeAmount: number; // 💸 Maybe duplicates amount
    differentSupplierLikelyAmount: number; // 💵 Different supplier likely amount
    differentSupplierMaybeAmount: number; // 💳 Different supplier maybe amount
    differentAmtsAndDatesAmount: number; // 💰💸 Different amounts and dates amount
    differentDatesAmount: number; // 💸 Different dates amount
    differentSuppliersAndDatesAmount: number; // 💵💸 Different suppliers and dates amount
    differentAmountsAmount: number; // 💳 Different amounts amount
    creditPairAmount: number; // 💳 Credit pair amount
  };
  isLoading: boolean; // ⏳ Loading state
}

/**
 * Component to render a set of DupesDashboardCards.
 *
 * @param props - Component properties.
 * @returns Rendered DupesDashboardCards JSX element.
 */
export function DupesDashboardCards({
  counts: {
    likelyCount,
    maybeCount,
    differentSupplierLikelyCount,
    differentSupplierMaybeCount,
    differentAmtsAndDatesCount,
    differentDatesCount,
    differentSuppliersAndDatesCount,
    differentAmountsCount,
    creditPairCount,
  },
  amounts: {
    likelyAmount,
    maybeAmount,
    differentSupplierLikelyAmount,
    differentSupplierMaybeAmount,
    differentAmtsAndDatesAmount,
    differentDatesAmount,
    differentSuppliersAndDatesAmount,
    differentAmountsAmount,
    creditPairAmount,
  },
  isLoading,
}: DupesCardsProps) {
  // 🧮 Calculate the maximum count value for dynamic scaling.
  const maxValue = Math.max(
    likelyCount,
    maybeCount,
    differentSupplierLikelyCount,
    differentSupplierMaybeCount,
    differentAmtsAndDatesCount,
    differentDatesCount,
    differentSuppliersAndDatesCount,
    differentAmountsCount
  );

  /**
   * 🎨 Function to determine the alert level based on a value.
   *
   * @param {number} value - The value to determine the alert level for.
   * @param {number} maxValue - The maximum value among counts for scaling.
   * @returns {string} - The color variant (good, notsogood, or bad) representing the alert level.
   */
  const getDupesAlertLevel = useCallback((value: number, maxValue: number) => {
    const goodThreshold = maxValue * 0.01; // 🟢 Good threshold
    const notSoGoodThreshold = maxValue * 0.5; // 🟡 Not-so-good threshold

    if (value <= goodThreshold) {
      return "good";
    } else if (value <= notSoGoodThreshold) {
      return "notsogood";
    } else {
      return "bad";
    }
  }, []);

  // 🖼️ Render the grid of DupesDashboardCards.
  return (
    <section className="mb-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {/* 💳 Likely Duplicates Card */}
      <DupesCard
        dupesSearchFilter={{dupesLikeliness: "likely"}}
        dupesCardTitle="Likely"
        dupesCardContent={
          isLoading ? (
            <Skeleton className="h-10 w-20" /> // ⏳ Show loading skeleton if data is loading
          ) : (
            <>
              <Paragraph
                className={cn(
                  dupesColourVariants({
                    variant: getDupesAlertLevel(likelyCount, maxValue), // 🎨 Determine color based on count
                  })
                )}
              >
                {likelyCount}
              </Paragraph>
              <Paragraph>
                <span className="font-bold">Invoices Amount Total: </span>
                {likelyAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </Paragraph>
            </>
          )
        }
      />
      {/* 💳 Maybe Duplicates Card */}
      <DupesCard
        dupesSearchFilter={{dupesLikeliness: "maybe"}}
        dupesCardTitle="Maybe"
        dupesCardContent={
          isLoading ? (
            <Skeleton className="h-10 w-20" /> // ⏳ Show loading skeleton if data is loading
          ) : (
            <>
              <Paragraph
                className={cn(
                  dupesColourVariants({
                    variant: getDupesAlertLevel(maybeCount, maxValue), // 🎨 Determine color based on count
                  })
                )}
              >
                {maybeCount}
              </Paragraph>
              <Paragraph>
                <span className="font-bold">Invoices Amount Total: </span>
                {maybeAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </Paragraph>
            </>
          )
        }
      />
      {/* 💳 Different Supplier Likely Duplicates Card */}
      <DupesCard
        dupesSearchFilter={{dupesLikeliness: "different-supplier-likely"}}
        dupesCardTitle="Different Supplier Likely"
        dupesCardContent={
          isLoading ? (
            <Skeleton className="h-10 w-20" /> // ⏳ Show loading skeleton if data is loading
          ) : (
            <>
              <Paragraph
                className={cn(
                  dupesColourVariants({
                    variant: getDupesAlertLevel(
                      differentSupplierLikelyCount,
                      maxValue
                    ), // 🎨 Determine color based on count
                  })
                )}
              >
                {differentSupplierLikelyCount}
              </Paragraph>
              <Paragraph>
                <span className="font-bold">Invoices Amount Total: </span>
                {differentSupplierLikelyAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </Paragraph>
            </>
          )
        }
      />
      {/* 💳 Different Supplier Maybe Duplicates Card */}
      <DupesCard
        dupesSearchFilter={{dupesLikeliness: "different-supplier-maybe"}}
        dupesCardTitle="Different Supplier Maybe"
        dupesCardContent={
          isLoading ? (
            <Skeleton className="h-10 w-20" /> // ⏳ Show loading skeleton if data is loading
          ) : (
            <>
              <Paragraph
                className={cn(
                  dupesColourVariants({
                    variant: getDupesAlertLevel(
                      differentSupplierMaybeCount,
                      maxValue
                    ), // 🎨 Determine color based on count
                  })
                )}
              >
                {differentSupplierMaybeCount}
              </Paragraph>
              <Paragraph>
                <span className="font-bold">Invoices Amount Total: </span>
                {differentSupplierMaybeAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </Paragraph>
            </>
          )
        }
      />
      {/* 💳 Different Amounts and Dates Duplicates Card */}
      <DupesCard
        dupesSearchFilter={{dupesLikeliness: "different-amounts-and-dates"}}
        dupesCardTitle="Different Amounts & Dates"
        dupesCardContent={
          isLoading ? (
            <Skeleton className="h-10 w-20" /> // ⏳ Show loading skeleton if data is loading
          ) : (
            <>
              <Paragraph
                className={cn(
                  dupesColourVariants({
                    variant: getDupesAlertLevel(
                      differentAmtsAndDatesCount,
                      maxValue
                    ), // 🎨 Determine color based on count
                  })
                )}
              >
                {differentAmtsAndDatesCount}
              </Paragraph>
              <Paragraph>
                <span className="font-bold">Invoices Amount Total: </span>
                {differentAmtsAndDatesAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </Paragraph>
            </>
          )
        }
      />
      {/* 💳 Different Dates Duplicates Card */}
      <DupesCard
        dupesSearchFilter={{dupesLikeliness: "different-dates"}}
        dupesCardTitle="Different Dates"
        dupesCardContent={
          isLoading ? (
            <Skeleton className="h-10 w-20" /> // ⏳ Show loading skeleton if data is loading
          ) : (
            <>
              <Paragraph
                className={cn(
                  dupesColourVariants({
                    variant: getDupesAlertLevel(differentDatesCount, maxValue), // 🎨 Determine color based on count
                  })
                )}
              >
                {differentDatesCount}
              </Paragraph>
              <Paragraph>
                <span className="font-bold">Invoices Amount Total: </span>
                {differentDatesAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </Paragraph>
            </>
          )
        }
      />
      {/* 💳 Different Suppliers and Dates Duplicates Card */}
      <DupesCard
        dupesSearchFilter={{dupesLikeliness: "different-suppliers-and-dates"}}
        dupesCardTitle="Different Suppliers & Dates"
        dupesCardContent={
          isLoading ? (
            <Skeleton className="h-10 w-20" /> // ⏳ Show loading skeleton if data is loading
          ) : (
            <>
              <Paragraph
                className={cn(
                  dupesColourVariants({
                    variant: getDupesAlertLevel(
                      differentSuppliersAndDatesCount,
                      maxValue
                    ), // 🎨 Determine color based on count
                  })
                )}
              >
                {differentSuppliersAndDatesCount}
              </Paragraph>
              <Paragraph>
                <span className="font-bold">Invoices Amount Total: </span>
                {differentSuppliersAndDatesAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </Paragraph>
            </>
          )
        }
      />
      {/* 💳 Different Amounts Duplicates Card */}
      <DupesCard
        dupesSearchFilter={{dupesLikeliness: "different-amounts"}}
        dupesCardTitle="Different Amounts"
        dupesCardContent={
          isLoading ? (
            <Skeleton className="h-10 w-20" /> // ⏳ Show loading skeleton if data is loading
          ) : (
            <>
              <Paragraph
                className={cn(
                  dupesColourVariants({
                    variant: getDupesAlertLevel(
                      differentAmountsCount,
                      maxValue
                    ), // 🎨 Determine color based on count
                  })
                )}
              >
                {differentAmountsCount}
              </Paragraph>
              <Paragraph>
                <span className="font-bold">Invoices Amount Total: </span>
                {differentAmountsAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </Paragraph>
            </>
          )
        }
      />
      {/* 💳 Credit Pair Duplicates Card */}
      <DupesCard
        dupesSearchFilter={{dupesLikeliness: "credit-pair"}}
        dupesCardTitle="Credit Pair"
        dupesCardContent={
          isLoading ? (
            <Skeleton className="h-10 w-20" /> // ⏳ Show loading skeleton if data is loading
          ) : (
            <>
              <Paragraph
                className={cn(
                  dupesColourVariants({
                    variant: "good", // 🎨 Credit pairs are always good
                  })
                )}
              >
                {creditPairCount}
              </Paragraph>
              <Paragraph>
                <span className="font-bold">Invoices Amount Total: </span>
                {creditPairAmount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </Paragraph>
            </>
          )
        }
      />
    </section>
  );
}
