import {zodResolver} from "@hookform/resolvers/zod";
import {PlusCircleIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import {z} from "zod";

import {Button} from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

// Define the schema for form validation using Zod
const AddPIDFormSchema = z.object({
  participantName: z.string().optional(),
});

/**
 * AddPIDForm Component
 *
 * This component serves as the form for adding new PID (Participant ID).
 * It uses Zod for form validation and react-hook-form for form state management.
 */
export function AddPIDForm() {
  // Initialize the form with Zod as the resolver for validation
  const form = useForm<z.infer<typeof AddPIDFormSchema>>({
    resolver: zodResolver(AddPIDFormSchema),
  });

  // Handle form submission
  function onSubmit(data: z.infer<typeof AddPIDFormSchema>) {
    toast({
      title: "You submitted the following values:",
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data, null, 2)}</code>
        </pre>
      ),
    });
  }

  // Render the form UI
  return (
    <Card>
      <CardHeader>
        <CardTitle>New PID</CardTitle>
        <CardDescription>
          This form allows administrators to add a new PID.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form
            className="flex flex-col space-y-6"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <Paragraph>
              <strong>External ID:</strong>{" "}
              <span className="uppercase">
                {Math.floor(Math.random() * Date.now()).toString(36)}
              </span>
            </Paragraph>
            <FormField
              name="participantName"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="participantName">
                    Participant Name:
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter participant's name"
                      {...form.register("participantName")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className="max-w-fit self-end" type="submit">
              Submit <PlusCircleIcon className="ml-2" />
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
