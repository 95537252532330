import {createRoute} from "@tanstack/react-router";

import {RoutesDirectory} from "@/lib/routes/directory";
import {
  imREmitLiteRootRoute,
  imremitLiteSearchFilterSchema,
  ProxyPayDashboardPage,
  ProxyPaymentDetails,
  ProxypayRedirect,
} from "@/modules/imremit-lite";

export const imREmitLiteProxyPayRoute = createRoute({
  path: `${RoutesDirectory.PROXY_PAY_DASHBOARD}/$ppPage${RoutesDirectory.DASHBOARD}`,
  component: ProxyPayDashboardPage,
  getParentRoute: () => imREmitLiteRootRoute,
  validateSearch: (search) => imremitLiteSearchFilterSchema.parse(search),
});

export const imREmitLiteProxyPayRedirectRoute = createRoute({
  path: RoutesDirectory.PROXY_PAY_DASHBOARD,
  component: ProxypayRedirect,
  getParentRoute: () => imREmitLiteRootRoute,
});

export const imREmitLiteViewProxyPaymentRootRoute = createRoute({
  path: `${RoutesDirectory.PROXY_PAY_DASHBOARD}/$paymentId${RoutesDirectory.VIEW}`,
  component: ProxyPaymentDetails,
  getParentRoute: () => imREmitLiteRootRoute,
});
