import {AnchorButton} from "@/components/ui/button";
import {Heading4, Paragraph, UnorderedList} from "@/components/ui/typography";

import {CONTACT_INFO} from "@/utils/constants";

export const privacyPolicyItems = [
  {
    title: "PURPOSE AND SCOPE",
    content: (
      <>
        <Paragraph>
          This Privacy Policy explains Iteration Matrix LP&apos;s online
          information practices and the choices you can make about the
          collection and use of personal information you submit or we collect:
        </Paragraph>
        <UnorderedList>
          <li>
            <Paragraph>
              online, through our websites, mobile applications, social media
              pages,
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              offline, in connection with sales, marketing, partner and supplier
              engagement,
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              from third party sources, including ad networks and lead brokers,
              and
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              through the use of Iteration Matrix LP's other online services and
              tools that link to this Privacy Policy (collectively, the
              "Services").
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              This Privacy Policy applies to Iteration Matrix LP and references
              to the entity responsible for the processing of your personal
              information, which generally is the entity that obtains your
              personal information. pages,
            </Paragraph>
          </li>
        </UnorderedList>
        <Paragraph>
          This Privacy Policy describes the types of personal information we
          obtain through the Services, how we may use that personal information,
          which whom we may share it, and how you may exercise your rights
          regarding our processing of such personal information. The Privacy
          Policy also describes the measures we take to safeguard the personal
          information we obtain and how you can contact us about our privacy
          practices.
        </Paragraph>
      </>
    ),
  },
  {
    title: "PERSONAL INFORMATION WE OBTAIN",
    content: (
      <>
        <Paragraph>
          The categories and types of personal information we may obtain through
          the Services include:
        </Paragraph>
        <UnorderedList>
          <li>
            <Paragraph>
              contact information (such as name, phone and fax number, email and
              postal address) for you or for others (e.g., principals in your
              business);
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              information used to create your online account (such as username,
              password and security questions and answer);
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              biographical and demographic information (such as date of birth,
              age, gender, job title/position, marital status and dependent,
              spousal and other family information);
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              commercial information, including purchase and customer service
              history;
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              financial information (such as payment information, including
              name, billing address and payment card details (i.e., card number,
              expiration date and security code); bank account information;
              financial statements; income and credit score);
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              commercial information, includingeolocation data (such as data
              derived from your IP address, country and zip code) and the
              precise geolocation of your mobile device where we have provided
              notice and choice, as appropriate; g purchase and customer service
              history;
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              contact information you provide about friends or other people you
              would like us to contact;
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              Internet or other electronic network activity information,
              including clickstream data and other information about your online
              activities (such as information about your devices, browsing
              actions and usage patterns), including across our websites and
              third-party websites, that we obtain through the use of cookies,
              web beacons and similar technologies; and
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              other personal information contained in content you submit to us.
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              In addition, we may obtain identifiers such as government-issued
              ID numbers and Tax ID numbers, such as U.S. Social Security
              numbers.
            </Paragraph>
            <Paragraph>
              Please note that providing personal information to us is voluntary
              on your part. If you choose not to provide us certain information,
              we may not be able to offer you certain products and Services, and
              you may not be able to access certain features of the Services.
            </Paragraph>
          </li>
        </UnorderedList>
      </>
    ),
  },
  {
    title: "HOW WE USE PERSONAL INFORMATION",
    content: (
      <>
        <Paragraph>We may use the personal information we obtain to:</Paragraph>
        <UnorderedList>
          <li>
            <Paragraph>provide and administer our Services;</Paragraph>
          </li>
          <li>
            <Paragraph>
              process and fulfill orders and keep you informed about the status
              of your order;
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              communicate about and administer our Services, events, programs
              and promotions (such as by sending alerts, promotional materials,
              newsletters and other marketing communications);
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              conduct and facilitate surveys, contests, focus groups and market
              research initiatives;
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              aggregate, manipulate, supplement and otherwise use the Personal
              Information in order to create aggregated, blinded analytics,
              including benchmarks (such as market research, trend analysis,
              financial analysis and customer segmentation);
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              engage in ad retargeting and evaluate the effectiveness of our
              marketing efforts (including through our participation in ad
              networks);
            </Paragraph>
          </li>
          <li>
            <Paragraph>provide customer support;</Paragraph>
          </li>
          <li>
            <Paragraph>
              process, evaluate and respond to requests, inquiries and
              applications;
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              create, administer and communicate with you about your account
              (including any purchases and payments);
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              conduct marketing and sales activities (including generating
              leads, pursuing marketing prospects, performing market research,
              determining and managing the effectiveness of our advertising and
              marketing campaigns and managing our brand);
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              operate, evaluate and improve our Services (such as by
              administering, enhancing and improving our tools/resources and
              Services; developing new Services);
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              perform accounting, auditing, payment processing, billing,
              reconciliation;
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              detect and prevent potentially illegal acts, violations of our
              policies, fraud and/or data security breaches;
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              maintain and enhance the safety and security of our Services,
              network information systems, information resources and employees;
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              create derivative works which shall be deemed the property of
              Iteration Matrix LP, in support of Iteration Matrix LP providing
              improved Services to its customers; and
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              for other purposes for which we have obtained your consent.
            </Paragraph>
          </li>
        </UnorderedList>
        <Paragraph>
          We may combine personal information we obtain through Services with
          other information for the purposes described above. We may aggregate
          or anonymize personal information and use it for the purposes
          described above and for other purposes to the extent permitted by laws
          applicable to us. You grant to Iteration Matrix LP the right and
          license to use your personal information for the purposes described
          above and for other purposes to the extent permitted by laws
          applicable to us.
        </Paragraph>
      </>
    ),
  },
  {
    title: "HOW WE DISCLOSE PERSONAL INFORMATION",
    content: (
      <>
        <Paragraph>
          The categories and types of personal information listed above under
          the Section titled 'PERSONAL INFORMATION WE OBTAIN' collected through
          the Services may be shared with the following third parties for the
          following purposes:
        </Paragraph>
        <UnorderedList>
          <li>
            <Paragraph>
              We may share with our affiliates and subsidiaries to provide joint
              content and Services; to help detect and prevent potentially
              illegal acts, violations of our policies, fraud and/or data
              security breaches; and, to guide decisions about our products,
              sites, applications, services, tools, and communications.
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              We may share your personal information with service providers
              under contract to perform services on our behalf (such as, but not
              limited to, fraud investigations, fulfilling orders, delivering
              packages, removing repetitive information from customer lists,
              software development, providing customer support, email delivery,
              marketing communications, bill collection, payment processing,
              site analytics and business operations).
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              We may disclose your personal information as required by law, such
              as to comply with a subpoena, law or other legal process, when we
              believe in good faith that disclosure is necessary to comply with
              such legal process, to establish, exercise or defend our legal
              rights, to protect your safety or the safety of others, to
              investigate fraud, or respond to a government request, or
              otherwise with your consent.
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              If we are involved in a merger, acquisition, joint venture,
              financing, reorganization, divestiture, dissolution, liquidation
              or sale of business or assets we may share personal information
              collected from and about users to one or more third parties
              involved in such transaction. In such case, we will require the
              relevant third parties to provide comparable levels of protection
              that Premier provides with respect to the personal information we
              share.
            </Paragraph>
          </li>
        </UnorderedList>
        <Paragraph>
          We do not sell your personal information to third parties for monetary
          value or other consideration.
        </Paragraph>
      </>
    ),
  },
  {
    title: "HOW WE PROTECT PERSONAL INFORMATION",
    content: (
      <Paragraph>
        We maintain reasonable administrative, technical and physical safeguards
        to protect against unauthorized access, disclosure, alteration or
        destruction of the personal information we collect through the Services.
        However, you should keep in mind that no Internet transmission is ever
        completely secure or error-free. While we endeavor to safeguard your
        personal information please take steps to prevent unauthorized access to
        your account and personal information. You can do this by, for example,
        selecting and protecting your password appropriately, limiting access to
        your device and browser, and signing out of your account at the end of
        your session.
      </Paragraph>
    ),
  },
  {
    title: "RETENTION OF PERSONAL INFORMATION",
    content: (
      <Paragraph>
        To the extent permitted by applicable law, we retain the personal
        information we collect about and from you for as long as necessary to
        fulfill a business purpose or comply with a legal request. We may also
        choose to anonymize the personal information you provide to us if we
        would like to retain it for longer periods of time.
      </Paragraph>
    ),
  },
  {
    title: "COOKIES",
    content: (
      <>
        <Paragraph className="mb-2">
          IM uses cookies (and other data collection technologies, such as web
          beacons and pixel tags) to provide all of the features and functions
          of the Services to you. By using the Services, you consent to the use
          of cookies. Our Cookies Policy, as outlined below, explains what
          cookies are, how we use cookies, how third parties we may partner with
          may use cookies on the Service, your choices regarding cookies and
          further information about cookies - see{" "}
          <AnchorButton
            className="h-4 p-0"
            href="https://www.allaboutcookies.org"
            variant="link"
          >
            allaboutcookies.org
          </AnchorButton>
        </Paragraph>
        <Heading4>What are Cookies?</Heading4>
        <Paragraph className="mb-2">
          Cookies are small pieces of text sent to your web browser by a website
          you visit. A cookie file is stored in your web browser and allows the
          service or a third-party to recognize you and make your next visit
          easier and the service more useful to you.
        </Paragraph>
        <Heading4>How IM Uses Cookies</Heading4>
        <Paragraph>
          When you use and access the Services, we may place a number of cookies
          files in your web browser. We use cookies for the following purposes:
          to enable certain functions of the Services, to provide analytics, to
          store your preferences, and to enable advertisement delivery,
          including behavioral advertising. We use both session and persistent
          cookies on the Services and we use different types of cookies to run
          the Services. We may also use essential cookies to authenticate users
          and prevent fraudulent use of user accounts.
        </Paragraph>
      </>
    ),
  },

  {
    title: "CHILDREN UNDER THE AGE OF 13",
    content: (
      <Paragraph>
        The Services are designed for a general audience and are not directed to
        children under the age of 13. We do not knowingly collect or solicit
        personal information of children under the age of 13 via the Services.
        If we become aware that a child has provided Premier with personal
        information without prior parental consent, we will remove all
        information provided by such underage user from our database.
      </Paragraph>
    ),
  },
  {
    title: "CHANGES TO THIS PRIVACY POLICY",
    content: (
      <>
        <Paragraph>
          Changes in this policy will be posted on this page so that you may
          always be aware of what information is being collected, how it is
          being used, and under what circumstances it is being disclosed. If we
          make material changes that would impact your use of the Services or
          your privacy, we will endeavor to notify you of the changes prior to
          the changes taking effect, such as by posting a notice directly on the
          Services, by sending an email notification (if you have provided your
          email address to us), or by any other reasonable method.
        </Paragraph>
        <Paragraph>
          This Privacy Policy was last updated on October 6, 2021.
        </Paragraph>
      </>
    ),
  },
  {
    title: "CONTACT US",
    content: (
      <>
        <Paragraph>
          For further questions regarding the Privacy Policy, your rights
          related to your personal data, or other concerns, please e-mail us at{" "}
          <AnchorButton
            className="h-4 p-0 font-semibold text-neutral-foreground"
            href={`mailto:${CONTACT_INFO.contactEmail}`}
            variant="link"
          >
            {CONTACT_INFO.contactEmail}
          </AnchorButton>{" "}
          or call <em>1-(888) 478-3456</em> available Monday - Friday, 8 a.m. -
          8 p.m. EST.
        </Paragraph>
        <Paragraph>
          You can also visit the{" "}
          {/* TODO: Create dedicated Contact Us page and link to it here */}
          <AnchorButton
            className="h-4 p-0 font-semibold text-neutral-foreground"
            href=""
            variant="link"
          >
            Contact Us
          </AnchorButton>{" "}
          page.
        </Paragraph>
      </>
    ),
  },
];
