import {useEffect, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {FileEditIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import {type z} from "zod";

import {Badge} from "@/components/ui/badge";
import {Button} from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {Form} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Spinner} from "@/components/ui/spinner";
import {Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {queryClient} from "@/lib/query-client";
import {useSavedSearchStore} from "@/lib/stores";
import {useKeyCloakInstanceStore} from "@/modules/auth";
import {customerQueryKeys, type CustomerType} from "@/modules/customers";
import {useGetAllSuppliers} from "@/modules/imremit";
import {
  SavedSearchDTOTypeSchema,
  updateSavedSearchFn,
  type SavedSearchCriteriaType,
  type SavedSearchDTOType,
} from "@/modules/invoices";

import {FallbackMessages} from "@/utils/constants";

/**
 * @typedef UpdateSavedSearchFormProps
 * @property {boolean} isUpdateSavedSearchFormOpen - Flag indicating if the dialog should be open
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setIsUpdateSavedSearchFormOpen - Function to set isUpdateSavedSearchFormOpen
 * @property {boolean} isMutating - Flag to indicate if a mutation operation is in progress
 * @property {(data: SavedSearchDTOType) => void} createSavedSearchOnSubmit - Function to handle the form submission
 * @property {SavedSearchCriteriaType} [rest] - Search criteria data
 */
type UpdateSavedSearchFormProps = {
  isUpdateSavedSearchFormOpen: boolean;
  setIsUpdateSavedSearchFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMutating: boolean;
  updateSavedSearchOnSubmit: (data: SavedSearchDTOType) => void;
} & SavedSearchCriteriaType;

/**
 * UpdateSavedSearchForm Component
 * @param {UpdateSavedSearchFormProps} props - The properties of the component
 */
function UpdateSavedSearchForm({
  isUpdateSavedSearchFormOpen,
  setIsUpdateSavedSearchFormOpen,
  isMutating,
  updateSavedSearchOnSubmit,
  ...rest
}: UpdateSavedSearchFormProps) {
  // Initialize custom hooks to interact with saved search and invoice meta stores.
  const {storeSavedSearch} = useSavedSearchStore();

  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  // Fetch existing customers and suppliers data
  const queriedCustomers =
    queryClient.getQueryData<{
      content: CustomerType[];
    }>(customerQueryKeys.allCustomers)?.content || [];
  const queriedSuppliers = useGetAllSuppliers().data?.content || [];

  // Initialize react-hook-form methods
  const savedInvoiceSearchFormMethods = useForm<
    z.infer<typeof SavedSearchDTOTypeSchema>
  >({
    defaultValues: {name: storeSavedSearch?.name},
    resolver: zodResolver(SavedSearchDTOTypeSchema),
  });

  const {setValue} = savedInvoiceSearchFormMethods;

  // Set the criteria values when the component mounts or the criteria changes
  useEffect(() => {
    setValue("listId", Number(storeSavedSearch?.listId));
    setValue("userId", storeKeyCloakInstance?.tokenParsed?.sub ?? null);
    setValue("criteria", rest);
  }, [
    rest,
    setValue,
    storeKeyCloakInstance?.tokenParsed?.sub,
    storeSavedSearch,
  ]);

  return (
    <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
      <Dialog
        open={isUpdateSavedSearchFormOpen}
        onOpenChange={setIsUpdateSavedSearchFormOpen}
      >
        <DialogTrigger asChild>
          <Button
            aria-label="Open saved search dialog"
            aria-disabled={isMutating}
            disabled={isMutating}
            className="gap-2 border-theme bg-theme text-root hover:bg-theme/80 hover:text-root active:bg-theme/90 active:text-root/90"
            variant="outline"
          >
            <FileEditIcon className="size-4" />
            Update Search
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <div className="flex items-center gap-2">
              <DialogTitle>Update Search Criteria</DialogTitle>
            </div>
          </DialogHeader>
          <Form {...savedInvoiceSearchFormMethods}>
            <form>
              <div className="flex flex-col gap-2">
                <div className="col-span-2 grid grid-cols-2 gap-2 pb-2">
                  <Paragraph>Customers: </Paragraph>
                  <div className="flex flex-wrap gap-2">
                    {rest.externalId && !!rest.externalId.length ? (
                      rest.externalId.map((id) => (
                        <Badge key={id} className="max-w-fit">
                          {
                            queriedCustomers.find(
                              (customer) => customer.externalId === id
                            )?.buyerName
                          }
                        </Badge>
                      ))
                    ) : (
                      <Badge variant="destructive">
                        {FallbackMessages.NO_DATA}
                      </Badge>
                    )}
                  </div>
                  <div className="flex flex-wrap gap-2">
                    <Paragraph>Suppliers: </Paragraph>
                    <label className="font-bold">
                      {rest.supplierId && !!rest.supplierId.length ? (
                        rest.supplierId.map((id) => (
                          <Badge key={id} className="max-w-fit">
                            {
                              queriedSuppliers.find(
                                (supplier) => supplier.id === id
                              )?.name
                            }
                          </Badge>
                        ))
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </label>
                  </div>
                  <div>
                    <Paragraph>Facilities: </Paragraph>
                    <div className="font-bold">
                      {rest.facilityId && !!rest.facilityId.length ? (
                        rest.facilityId
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-2">
                    <Paragraph>Status Id: </Paragraph>
                    <label className="font-bold">
                      {rest.status ? (
                        rest.status
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </label>
                  </div>
                  <div>
                    <Paragraph>Start Date: </Paragraph>
                    <div className="font-bold">
                      {rest.invoiceDateStart ? (
                        rest.invoiceDateStart
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </div>
                  </div>
                  <div>
                    <Paragraph>End Date: </Paragraph>
                    <div className="font-bold">
                      {rest.invoiceDateEnd ? (
                        rest.invoiceDateEnd
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </div>
                  </div>
                  <div>
                    <Paragraph>Payment Date: </Paragraph>
                    <div className="font-bold">
                      {rest.paymentDate ? (
                        rest.paymentDate
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </div>
                  </div>
                  <div>
                    <Paragraph>Payment Number: </Paragraph>
                    <div className="font-bold">
                      {rest.paymentNumber ? (
                        rest.paymentNumber
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </div>
                  </div>
                  <div>
                    {" "}
                    <Paragraph>Po Number: </Paragraph>
                    <div className="font-bold">
                      {rest.poNumber ? (
                        rest.poNumber
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </div>
                  </div>
                  <div>
                    <Paragraph>Invoice Number: </Paragraph>
                    <div className="font-bold">
                      {rest.invoiceNumber ? (
                        rest.invoiceNumber
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </div>
                  </div>
                  <div>
                    <Paragraph>ERP Unique Id: </Paragraph>
                    <div className="font-bold">
                      {rest.erpUniqueId ? (
                        rest.erpUniqueId
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </div>
                  </div>
                  <div>
                    <Paragraph>Invoice Amount: </Paragraph>
                    <div className="font-bold">
                      {rest.invoiceAmount ? (
                        rest.invoiceAmount
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </div>
                  </div>
                  <div>
                    <Paragraph>Invoice Date: </Paragraph>
                    <div className="font-bold">
                      {rest.invoiceDate ? (
                        rest.invoiceDate
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </div>
                  </div>
                </div>
                <Input
                  type="text"
                  placeholder="Enter name for your search criteria"
                  {...savedInvoiceSearchFormMethods.register("name")}
                />

                <Button
                  className="flex items-center gap-2 bg-neutral-foreground capitalize text-foreground hover:bg-neutral-foreground/80 active:bg-neutral-foreground/80"
                  aria-label="Update Search"
                  aria-disabled={isMutating}
                  disabled={isMutating}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    return savedInvoiceSearchFormMethods.handleSubmit(
                      (data: z.infer<typeof SavedSearchDTOTypeSchema>) => {
                        updateSavedSearchOnSubmit(data);
                      }
                    )();
                  }}
                >
                  <span className="sr-only">Update search criteria</span>
                  {isMutating ? (
                    <Spinner className="p-1" />
                  ) : (
                    <FileEditIcon className="size-4" />
                  )}
                  Update search criteria
                </Button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

/**
 * @typedef UpdateSavedSearchMutationLayerProps
 * @property {SavedSearchCriteriaType} criteriaFields - The search criteria
 */
interface UpdateSavedSearchMutationLayerProps {
  criteriaFields: SavedSearchCriteriaType;
}

/**
 * UpdateSavedSearchMutationLayer Component
 * @param {UpdateSavedSearchMutationLayerProps} props - The properties of the component
 */
export function UpdateSavedSearchMutationLayer({
  criteriaFields,
}: UpdateSavedSearchMutationLayerProps) {
  // Initialize custom hooks to interact with saved search and invoice meta stores.
  const {storeSavedSearch, updateStoreSavedSearch} = useSavedSearchStore();

  // Initialize State for Update Saved Search Form
  const [isUpdateSavedSearchFormOpen, setIsUpdateSavedSearchFormOpen] =
    useState(false);

  // Initialize Tanstack Query Mutation for Update Saved Search
  const updateSavedSearchMutation = useMutation({
    mutationFn: (data: z.infer<typeof SavedSearchDTOTypeSchema>) => {
      return updateSavedSearchFn(data, Number(storeSavedSearch?.listId));
    },
    onSuccess: (updateSavedSearchResponse) => {
      console.log("updateSavedSearchResponse", updateSavedSearchResponse);

      // Update the store with the new saved search data
      updateStoreSavedSearch({
        listId: storeSavedSearch?.listId ?? null,
        userId: storeSavedSearch?.userId ?? null,
        criteria: storeSavedSearch?.criteria ?? {
          status: null,
          externalId: null,
          supplierId: null,
          facilityId: null,
          invoiceDateStart: null,
          invoiceDateEnd: null,
          paymentDate: null,
          paymentNumber: null,
          poNumber: null,
          invoiceNumber: null,
          erpUniqueId: null,
          invoiceAmount: null,
          invoiceDate: null,
        },
        name: updateSavedSearchResponse.content.name,
      });

      // Close the create saved search form
      setIsUpdateSavedSearchFormOpen(false);

      toast({
        variant: "success",
        title: "Success!",
        description: "Search criteria updated successfully",
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update search criteria",
      });
    },
  });

  return (
    <UpdateSavedSearchForm
      isUpdateSavedSearchFormOpen={isUpdateSavedSearchFormOpen}
      setIsUpdateSavedSearchFormOpen={setIsUpdateSavedSearchFormOpen}
      isMutating={updateSavedSearchMutation.isPending}
      updateSavedSearchOnSubmit={updateSavedSearchMutation.mutate}
      {...criteriaFields}
    />
  );
}
