import {useEffect} from "react";

import {Heading2, Paragraph} from "@/components/ui/typography";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {ImREmitLiteRoutesSchema} from "@/lib/routes/types";
import {useImRemitLiteCustomerStore} from "@/lib/stores/customer-stores/imremit-lite-customers-store";
import {useRoutesStore} from "@/lib/stores/routes-store";
import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useAuthorization,
} from "@/modules/auth";
import {
  SelectCustomerFormLite,
  SelectCustomerSectionLite,
  type CustomerType,
} from "@/modules/customers";
import {type PaymentProvider} from "@/modules/imremit";
import {
  ImREmitLiteDashboardCards,
  ImREmitLiteDashboardCustomerCard,
} from "@/modules/imremit-lite";

/**
 * Props for the CustomerProps interface.
 * @property {CustomerType} imRemitLiteStoreCustomer - The currently selected customer's data.
 * @property {PaymentProvider[]} [paymentProviders] - Optional array of payment providers.
 */
export interface CustomerProps {
  imRemitLiteStoreCustomer: CustomerType;
  paymentProviders?: PaymentProvider[];
}

/**
 * Interface for the ImREmitLiteDashboardContent props.
 */
interface ImREmitLiteDashboardContentProps {
  customer: CustomerType;
}

/**
 * ImREmitLiteDashboardContent component for the ImREmitLite dashboard.
 * Renders the main content of the dashboard, including an overview of the customer's ImREmitLite payments.
 * @param {ImREmitLiteDashboardContentProps} props - Contains the customer data.
 * @returns {JSX.Element} JSX to render the main content section.
 */
function ImREmitLiteDashboardContent({
  customer,
}: ImREmitLiteDashboardContentProps) {
  return (
    <>
      {/* Section for the dashboard overview */}
      <header className="flex justify-between pb-2">
        <div className="flex flex-col">
          <Heading2>Your imREmit lite overview</Heading2>
          <Paragraph>
            Here you can find a quick overview of your imREmit lite payments.
          </Paragraph>
        </div>
        <SelectCustomerFormLite requireLabel />
      </header>

      <section className="flex flex-col items-start gap-4 xl:flex-row-reverse">
        {/* Individual flex item styling for self alignment */}
        <div className="w-full self-start 2xl:basis-1/4">
          <ImREmitLiteDashboardCustomerCard customer={customer} />
        </div>
        {/* Grid layout for dashboard cards with self-start to prevent stretching */}
        <div className="grid w-full grid-cols-1 gap-4 self-start md:grid-cols-1 xl:grid-cols-2 2xl:basis-3/4 2xl:grid-cols-3">
          <ImREmitLiteDashboardCards externalId={customer.externalId} />
        </div>
      </section>
    </>
  );
}

/**
 * Main functional component for the ImREmitLite Dashboard Page.
 * Utilizes hooks and components to manage and render the dashboard.
 * @returns {JSX.Element} JSX to render the complete dashboard page.
 */
export function ImREmitLiteDashboardPage() {
  const {setimREmitLiteRoute} = useRoutesStore();
  const {imRemitLiteStoreCustomer} = useImRemitLiteCustomerStore();

  useEffect(() => {
    setimREmitLiteRoute(
      ImREmitLiteRoutesSchema.Values["/app/imremit-lite/dashboard"]
    );
  }, [setimREmitLiteRoute]);

  const {checkPoliciesAccess} = useAuthorization();

  // Handle unauthorized access
  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_IMREMIT_LITE_DASHBOARD])) {
    return <NotAuthorizedPage />;
  }

  // If the customer is not yet selected, render the SelectCustomerSectionLite
  if (!imRemitLiteStoreCustomer) {
    return <SelectCustomerSectionLite />;
  }

  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.READ_IMREMIT_LITE_DASHBOARD]}
    >
      <ImREmitLiteDashboardContent customer={imRemitLiteStoreCustomer} />
    </AccessPolicyWrapper>
  );
}
