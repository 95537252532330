import {useCallback} from "react";

import {type Table} from "@tanstack/react-table";
import {
  ArrowLeftToLineIcon,
  ArrowRightToLineIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Paragraph} from "@/components/ui/typography";

/**
 * Props for DataTablePagination component.
 * @template TData - The type of data used in the table.
 */
interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  isPending?: boolean;
  isErrored?: boolean;
}

/**
 * Renders pagination controls for a data table.
 * @template TData - The type of data used in the table.
 * @param {DataTablePaginationProps<TData>} props - The component props.
 * @returns {JSX.Element} The pagination component.
 */
export function DataTablePagination<TData>({
  table,
  isPending,
  isErrored,
}: DataTablePaginationProps<TData>) {
  /**
   * Generates pagination buttons based on current page and total page count.
   * @returns {JSX.Element[]} An array of pagination button elements.
   */
  const generatePaginationButtons = useCallback(() => {
    const buttons = [];
    const pageCount = table.getPageCount();
    const currentPage = table.getState().pagination.pageIndex;

    // Handle single-page scenario
    if (pageCount <= 1) {
      return [
        <Button key={0} variant="affirmative" className="" disabled>
          1
        </Button>,
      ];
    }

    // Add the first page button
    buttons.push(
      <Button
        key={0}
        variant={currentPage === 0 ? "affirmative" : "outline"}
        className=""
        disabled={currentPage === 0}
        onClick={() => {
          table.setPageIndex(0);
        }}
      >
        1
      </Button>
    );

    // Calculate the range for middle page buttons
    const startPage = Math.max(currentPage - 1, 1);
    const endPage = Math.min(currentPage + 1, pageCount - 2);

    // Add ellipsis after the first page if there's a gap
    if (startPage > 1) {
      buttons.push(
        <span key="ellipsis-start" className="px-2">
          ...
        </span>
      );
    }

    // Add buttons for middle pages
    for (let i = startPage; i <= endPage; i++) {
      if (i !== 0 && i !== pageCount - 1) {
        buttons.push(
          <Button
            key={i}
            variant={i === currentPage ? "affirmative" : "outline"}
            className=""
            disabled={i === currentPage}
            onClick={() => {
              table.setPageIndex(i);
            }}
          >
            <span className="sr-only">Go to page {i + 1}</span>
            {i + 1}
          </Button>
        );
      }
    }

    // Add ellipsis before the last page if there's a gap
    if (endPage < pageCount - 2) {
      buttons.push(
        <span key="ellipsis-end" className="px-2">
          ...
        </span>
      );
    }

    // Add the last page button
    buttons.push(
      <Button
        key={pageCount - 1}
        variant={currentPage === pageCount - 1 ? "affirmative" : "outline"}
        className=""
        disabled={currentPage === pageCount - 1}
        onClick={() => {
          table.setPageIndex(pageCount - 1);
        }}
      >
        {pageCount}
      </Button>
    );

    return buttons;
  }, [table]);

  const paginationButtons = generatePaginationButtons();

  return (
    <>
      {/* <div className="flex-1 text-sm text-accent-foreground">
        {table.getFilteredSelectedRowModel().rows.length} of{" "}
        {table.getFilteredRowModel().rows.length} row(s) selected.
      </div> */}
      {/* Pagination controls */}
      <div className="flex items-center justify-between space-x-6 lg:space-x-8">
        {/* Previous page and first page buttons */}
        <div className="flex items-center space-x-2">
          {/* First page button */}
          <Button
            className="hidden active:bg-neutral disabled:active:bg-foreground lg:flex"
            disabled={!table.getCanPreviousPage()}
            onClick={() => {
              table.setPageIndex(0);
            }}
          >
            <span className="sr-only">Go to first page</span>
            <ArrowLeftToLineIcon className="size-4" />
          </Button>
          {/* Previous page button */}
          <Button
            variant="outline"
            className="active:bg-neutral disabled:active:bg-accent"
            disabled={!table.getCanPreviousPage()}
            onClick={() => {
              table.previousPage();
            }}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="size-4" />
          </Button>
          {paginationButtons}
          {/* Next page button */}
          <Button
            variant="outline"
            className="active:bg-neutral disabled:active:bg-accent"
            disabled={!table.getCanNextPage()}
            onClick={() => {
              table.nextPage();
            }}
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="size-4" />
          </Button>
          {/* Last page button */}
          <Button
            className="hidden active:bg-neutral disabled:active:bg-foreground lg:flex"
            disabled={!table.getCanNextPage()}
            onClick={() => {
              table.setPageIndex(table.getPageCount() - 1);
            }}
          >
            <span className="sr-only">Go to last page</span>
            <ArrowRightToLineIcon className="size-4" />
          </Button>
        </div>
        {/* Pagination info display */}
        <div className="flex max-w-fit flex-col items-end justify-center font-medium">
          {/* Page count display */}
          <Paragraph>
            <span className="font-semibold">Page: </span>
            {
              // If the table is pending, display a loading message
              isPending ? (
                <span>loading..</span>
              ) : // If the table has an error, display an error message
              isErrored ? (
                <span>Error</span>
              ) : (
                table.getState().pagination.pageIndex +
                1 +
                " of " +
                table.getPageCount()
              )
            }
          </Paragraph>
          {/* Entries range display */}
          <Paragraph>
            <span className="font-semibold">Viewing Entries: </span>
            {
              // If the table is pending, display a loading message
              isPending ? (
                <span>loading..</span>
              ) : // If the table has an error, display an error message
              isErrored ? (
                <span>Error</span>
              ) : (
                table.getState().pagination.pageIndex *
                  table.getState().pagination.pageSize +
                1 +
                " - " +
                Math.min(
                  (table.getState().pagination.pageIndex + 1) *
                    table.getState().pagination.pageSize,
                  table.getFilteredRowModel().rows.length
                ) +
                " of " +
                table.getFilteredRowModel().rows.length
              )
            }
          </Paragraph>
        </div>
      </div>
    </>
  );
}
