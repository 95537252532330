import type {OptionProps} from "@/modules/imremit";
import {
  DateFormatField,
  dateFormats,
  DefaultValueField,
  FieldNameField,
  fieldTypes,
  FieldTypesField,
  FindElementByField,
  findElementByValues,
  JsCheckField,
  PageCategoryField,
  PositionField,
  SeparatorField,
  SequenceIdentifierField,
  tableColumnNamesAddress,
  tableColumnNamesCustomerProfile,
  TableColumnNamesField,
  tableColumnNamesInvoiceDetail,
  tableColumnNamesInvoiceDetailAll,
  tableColumnNamesLoginCredential,
  tableColumnNamesPaymentDetail,
  tableColumnNamesPaymentRequest,
  tableColumnNamesPaymentResponse,
  tableColumnNamesSupplierDetail,
  tableNames,
  TableNamesField,
  TimerField,
  timerMethods,
  XPathField,
} from "@/modules/imremit";

function generateTableColumnNames(tableNameValue: string): OptionProps[] {
  switch (tableNameValue) {
    case "payment detail":
    case "Payment Detail":
      return tableColumnNamesPaymentDetail;
    case "payment request":
    case "Payment Request":
      return tableColumnNamesPaymentRequest;
    case "payment response":
    case "Payment Response":
      return tableColumnNamesPaymentResponse;
    case "supplier detail":
    case "Supplier Detail":
      return tableColumnNamesSupplierDetail;
    case "customer profile":
    case "Customer Profile":
      return tableColumnNamesCustomerProfile;
    case "invoice detail":
    case "Invoice Detail":
      return tableColumnNamesInvoiceDetail;
    case "invoice detail all":
    case "Invoice Detail All":
      return tableColumnNamesInvoiceDetailAll;
    case "address":
    case "Address":
      return tableColumnNamesAddress;
    case "login credential":
    case "Login Credential":
      return tableColumnNamesLoginCredential;
    default:
      return tableColumnNamesSupplierDetail;
  }
}

export interface ScriptFieldFormProps {
  isMutating: boolean;
  handleInputSelectChange: (script: OptionProps, scriptType: string) => void;
  handleInputValueChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  pageCategoryValue: string;
  fieldNameValue: string;
  fieldValue: string;
  fieldTypeValue: string;
  tableNameValue: string;
  tableColumnNameValue: string;
  positionValue: string;
  dropDownValue: string;
  separatorValue: string;
  dateFormatValue: string;
  sequenceIdentifierValue: string;
  timerValue: string;
  jsCheckValue: string;
  findElementByValue: string;
}

export const SupplierScriptForm: React.FC<ScriptFieldFormProps> = ({
  isMutating,
  handleInputValueChange,
  handleInputSelectChange,
  pageCategoryValue,
  fieldNameValue,
  fieldValue,
  fieldTypeValue,
  tableNameValue,
  tableColumnNameValue,
  positionValue,
  dropDownValue,
  separatorValue,
  dateFormatValue,
  sequenceIdentifierValue,
  timerValue,
  jsCheckValue,
  findElementByValue,
}) => (
  <>
    <PageCategoryField
      isMutating={isMutating}
      inputValue={pageCategoryValue}
      handleInputValueChange={handleInputValueChange}
      requireValue={
        fieldTypeValue !== "payment_loop_start" &&
        fieldTypeValue !== "payment_loop_end"
      }
    />
    <FieldNameField
      isMutating={isMutating}
      inputValue={fieldNameValue}
      handleInputValueChange={handleInputValueChange}
      requireValue={true}
    />
    <XPathField
      isMutating={isMutating}
      inputValue={fieldValue}
      handleInputValueChange={handleInputValueChange}
      isDisabled={
        fieldTypeValue === "iframe_exit" ||
        fieldTypeValue === "get_reCAPTCHA_v2_response"
      }
      requireValue={
        fieldTypeValue !== "click_link_text" &&
        fieldTypeValue !== "iframe_exit" &&
        fieldTypeValue !== "handle_window" &&
        fieldTypeValue !== "switch_to_previous_tab" &&
        fieldTypeValue !== "insert_reCAPTCHA_v2_response" &&
        fieldTypeValue !== "get_reCAPTCHA_v2_response" &&
        fieldTypeValue !== "payment_loop_start" &&
        fieldTypeValue !== "payment_loop_end" &&
        fieldTypeValue !== "input_invoice_numbers_amounts"
      }
    />
    <FieldTypesField
      isMutating={isMutating}
      inputValue={fieldTypeValue}
      handleInputSelectChange={handleInputSelectChange}
      allItems={fieldTypes}
      requireValue={true}
    />
    <TableNamesField
      isMutating={isMutating}
      inputValue={tableNameValue}
      handleInputSelectChange={handleInputSelectChange}
      allItems={tableNames}
      isDisabled={
        fieldTypeValue !== "input" &&
        fieldTypeValue !== "dropdown" &&
        fieldTypeValue !== "date_picker_table" &&
        fieldTypeValue !== "click_link_text" &&
        fieldTypeValue !== "label_text" &&
        fieldTypeValue !== "payment_loop_start" &&
        fieldTypeValue !== "payment_loop_end" &&
        fieldTypeValue !== "input_invoice_numbers_amounts" &&
        fieldTypeValue !== "search_invoices_in_div"
      }
      requireValue={
        fieldTypeValue === "input" ||
        fieldTypeValue === "dropdown" ||
        fieldTypeValue === "date_picker_table" ||
        fieldTypeValue === "label_text" ||
        fieldTypeValue === "search_invoices_in_div"
      }
    />
    <TableColumnNamesField
      isMutating={isMutating}
      inputValue={tableColumnNameValue}
      handleInputSelectChange={handleInputSelectChange}
      allItems={generateTableColumnNames(tableNameValue)}
      isDisabled={
        fieldTypeValue !== "input" &&
        fieldTypeValue !== "dropdown" &&
        fieldTypeValue !== "click_link_text" &&
        fieldTypeValue !== "date_picker_table" &&
        fieldTypeValue !== "label_text" &&
        fieldTypeValue !== "payment_loop_start" &&
        fieldTypeValue !== "payment_loop_end" &&
        fieldTypeValue !== "input_invoice_numbers_amounts" &&
        fieldTypeValue !== "search_invoices_in_div"
      }
      requireValue={
        fieldTypeValue === "input" ||
        fieldTypeValue === "dropdown" ||
        fieldTypeValue === "date_picker_table" ||
        fieldTypeValue === "label_text" ||
        fieldTypeValue === "search_invoices_in_div"
      }
    />
    <PositionField
      isMutating={isMutating}
      handleInputValueChange={handleInputValueChange}
      inputValue={positionValue}
      isDisabled={
        fieldTypeValue !== "input" &&
        fieldTypeValue !== "date_picker_table" &&
        fieldTypeValue !== "handle_window" &&
        fieldTypeValue !== "click_link_text" &&
        fieldTypeValue !== "payment_loop_start" &&
        fieldTypeValue !== "payment_loop_end" &&
        fieldTypeValue !== "input_invoice_numbers_amounts" &&
        fieldTypeValue !== "search_invoices_in_div"
      }
    />
    <DefaultValueField
      isMutating={isMutating}
      inputValue={dropDownValue}
      handleInputValueChange={handleInputValueChange}
      requireValue={fieldTypeValue === "alert" || fieldTypeValue === "dropdown"}
      isDisabled={
        fieldTypeValue !== "alert" &&
        fieldTypeValue !== "dropdown" &&
        fieldTypeValue !== "get_reCAPTCHA_v2_response" &&
        fieldTypeValue !== "payment_loop_start" &&
        fieldTypeValue !== "payment_loop_end" &&
        fieldTypeValue !== "input_invoice_numbers_amounts" &&
        fieldTypeValue !== "search_invoices_in_div"
      }
    />
    <SeparatorField
      isMutating={isMutating}
      inputValue={separatorValue}
      handleInputValueChange={handleInputValueChange}
      isDisabled={
        fieldTypeValue !== "input" &&
        fieldTypeValue !== "date_picker_table" &&
        fieldTypeValue !== "payment_loop_start" &&
        fieldTypeValue !== "click_link_text" &&
        fieldTypeValue !== "payment_loop_end" &&
        fieldTypeValue !== "input_invoice_numbers_amounts" &&
        fieldTypeValue !== "search_invoices_in_div"
      }
    />
    <DateFormatField
      isMutating={isMutating}
      inputValue={dateFormatValue}
      handleInputSelectChange={handleInputSelectChange}
      allItems={dateFormats}
      isDisabled={
        fieldTypeValue !== "date_picker_table" &&
        fieldTypeValue !== "dropdown" &&
        fieldTypeValue !== "payment_loop_start" &&
        fieldTypeValue !== "payment_loop_end" &&
        fieldTypeValue !== "input_invoice_numbers_amounts" &&
        fieldTypeValue !== "search_invoices_in_div"
      }
    />
    <SequenceIdentifierField
      isMutating={isMutating}
      inputValue={sequenceIdentifierValue}
      handleInputValueChange={handleInputValueChange}
      isDisabled={fieldTypeValue === "error"}
    />
    <TimerField
      isMutating={isMutating}
      inputValue={timerValue}
      handleInputSelectChange={handleInputSelectChange}
      allItems={timerMethods}
      isDisabled={fieldTypeValue === "error"}
      requireValue={true}
    />
    <JsCheckField
      isMutating={isMutating}
      inputValue={jsCheckValue}
      handleInputValueChange={handleInputValueChange}
      isDisabled={
        fieldTypeValue !== "input" &&
        fieldTypeValue !== "dropdown" &&
        fieldTypeValue !== "date_picker_table" &&
        fieldTypeValue !== "error" &&
        fieldTypeValue !== "confirmation_number" &&
        fieldTypeValue !== "alert" &&
        fieldTypeValue !== "pop_up_msg_ok_yes" &&
        fieldTypeValue !== "checkBox" &&
        fieldTypeValue !== "captcha_checkbox" &&
        fieldTypeValue !== "captcha_image" &&
        fieldTypeValue !== "captcha_image_value" &&
        fieldTypeValue !== "button" &&
        fieldTypeValue !== "label_text" &&
        fieldTypeValue !== "login_button" &&
        fieldTypeValue !== "payment_loop_start" &&
        fieldTypeValue !== "payment_loop_end" &&
        fieldTypeValue !== "input_invoice_numbers_amounts" &&
        fieldTypeValue !== "search_invoices_in_div"
      }
    />
    <FindElementByField
      isMutating={isMutating}
      inputValue={findElementByValue}
      handleInputSelectChange={handleInputSelectChange}
      allItems={findElementByValues}
      isDisabled={
        fieldTypeValue !== "input" &&
        fieldTypeValue !== "dropdown" &&
        fieldTypeValue !== "date_picker_table" &&
        fieldTypeValue !== "error" &&
        fieldTypeValue !== "confirmation_number" &&
        fieldTypeValue !== "alert" &&
        fieldTypeValue !== "pop_up_msg_ok_yes" &&
        fieldTypeValue !== "checkBox" &&
        fieldTypeValue !== "captcha_checkbox" &&
        fieldTypeValue !== "captcha_image" &&
        fieldTypeValue !== "captcha_image_value" &&
        fieldTypeValue !== "button" &&
        fieldTypeValue !== "label_text" &&
        fieldTypeValue !== "login_button" &&
        fieldTypeValue !== "iframe" &&
        fieldTypeValue !== "scroll_to_element" &&
        fieldTypeValue !== "switch_to_tab" &&
        fieldTypeValue !== "switch_to_window" &&
        fieldTypeValue !== "insert_reCAPTCHA_v2_response" &&
        fieldTypeValue !== "switch_to_previous_tab" &&
        fieldTypeValue !== "payment_loop_start" &&
        fieldTypeValue !== "payment_loop_end" &&
        fieldTypeValue !== "input_invoice_numbers_amounts" &&
        fieldTypeValue !== "search_invoices_in_div"
      }
      requireValue={
        fieldTypeValue === "input" ||
        fieldTypeValue === "dropdown" ||
        fieldTypeValue === "date_picker_table" ||
        fieldTypeValue === "error" ||
        fieldTypeValue === "confirmation_number" ||
        fieldTypeValue === "alert" ||
        fieldTypeValue === "pop_up_msg_ok_yes" ||
        fieldTypeValue === "checkBox" ||
        fieldTypeValue === "captcha_checkbox" ||
        fieldTypeValue === "captcha_image" ||
        fieldTypeValue === "captcha_image_value" ||
        fieldTypeValue === "button" ||
        fieldTypeValue === "label_text" ||
        fieldTypeValue === "login_button" ||
        fieldTypeValue === "iframe" ||
        fieldTypeValue === "scroll_to_element" ||
        fieldTypeValue === "switch_to_tab" ||
        fieldTypeValue === "switch_to_window" ||
        fieldTypeValue === "switch_to_previous_tab" ||
        fieldTypeValue === "search_invoices_in_div"
      }
    />
  </>
);
