// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type SupplierManagementIMRemitPremiumTableColumnsType} from "@/modules/imremit";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface SupplierManagementIMRemitPremiumTableColumnsStoreState {
  storeSupplierManagementIMRemitPremiumTableColumns: SupplierManagementIMRemitPremiumTableColumnsType | null;
  setStoreSupplierManagementIMRemitPremiumTableColumns: (
    storeSupplierManagementIMRemitPremiumTableColumns: SupplierManagementIMRemitPremiumTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for SupplierManagementIMRemitPremium table columns
export const useSupplierManagementIMRemitPremiumTableColumnsStore = create(
  persist<SupplierManagementIMRemitPremiumTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeSupplierManagementIMRemitPremiumTableColumns: null,

      // Getter function to retrieve the current state
      getStoreSupplierManagementIMRemitPremiumTableColumns: () =>
        get().storeSupplierManagementIMRemitPremiumTableColumns,

      // Setter function to update the state
      setStoreSupplierManagementIMRemitPremiumTableColumns: (
        storeSupplierManagementIMRemitPremiumTableColumns
      ) => {
        set(() => ({storeSupplierManagementIMRemitPremiumTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "SupplierManagementIMRemitPremium-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useSupplierManagementIMRemitPremiumTableColumns = () =>
  useSupplierManagementIMRemitPremiumTableColumnsStore((state) => {
    return {
      storeSupplierManagementIMRemitPremiumTableColumns:
        state.storeSupplierManagementIMRemitPremiumTableColumns,
    };
  });
