import {kyApiFn} from "@/lib/ky";
import {type responseMappingFormType} from "@/modules/imremit";

export const saveResponseFileFn = (
  responseMappingFormType: responseMappingFormType
) =>
  kyApiFn(`api/e-payable/admin/master-mapping/response`, "post", {
    // TODO: get rid of hard coded id
    json: responseMappingFormType,
  });
