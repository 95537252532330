import {type ColumnDef} from "@tanstack/react-table";
import {format} from "date-fns";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {GetKeycloakUserName} from "@/modules/admin";
import {type PaymentManagementCommentType} from "@/modules/imremit";
import {PaymentCommentsRowActions} from "@/modules/imremit-lite";

import {FallbackMessages} from "@/utils/constants";

// Definition of payment comments columns for the datatable
export const paymentCommentsColumns: ColumnDef<PaymentManagementCommentType>[] =
  [
    // {
    //   accessorKey: "commentId",
    //   header: ({column}) => (
    //     <DataTableColumnHeader column={column} title="Package ID" />
    //   ),
    //   cell: ({row}) => (
    //     <div className="w-fit">
    //       {row.getValue("commentId") ? (
    //         row.getValue("commentId")
    //       ) : (
    //         <Badge className="whitespace-nowrap" variant="destructive">{FallbackMessages.NO_DATA}</Badge>
    //       )}
    //     </div>
    //   ),
    //   // enableSorting: false,
    //   // enableHiding: false,
    // },
    {
      accessorKey: "userId",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="User" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("userId") ? (
            <GetKeycloakUserName userId={row.getValue("userId")} />
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "dateTime",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Date/Time" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("dateTime") ? (
            format(
              new Date(row.getValue("dateTime")),
              "EEEE MMMM do yyyy '@' HH:mm:ss"
            )
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "comments",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Comment" />
      ),
      cell: ({row}) => (
        <div className="w-full">
          {row.getValue("comments") ? (
            row.getValue("comments")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    // {
    //   accessorKey: "failedCount",
    //   header: ({column}) => (
    //     <DataTableColumnHeader column={column} title="Failed Count" />
    //   ),
    //   cell: ({row}) => (
    //     <div className="w-fit">
    //       {row.getValue("failedCount") ? (
    //         row.getValue("failedCount")
    //       ) : (
    //         <Badge className="whitespace-nowrap" variant="destructive">{FallbackMessages.NO_DATA}</Badge>
    //       )}
    //     </div>
    //   ),
    //   // enableSorting: false,
    //   // enableHiding: false,
    // },
    {
      accessorKey: "markAsRead",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Comment Read" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("markAsRead") ? (
            row.getValue("markAsRead")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      id: "actions",
      header: () => <Paragraph className="text-right">Actions</Paragraph>,
      cell: ({row}) => (
        <div className="flex w-[80] justify-end">
          <PaymentCommentsRowActions row={row} />
        </div>
      ),
    },
  ];
