import {cva} from "class-variance-authority";
import {BanIcon, CircleEllipsisIcon, Clock3Icon} from "lucide-react";

import {Card, CardContent, CardHeader, CardTitle} from "@/components/ui/card";
import {Skeleton} from "@/components/ui/skeleton";
import {Paragraph} from "@/components/ui/typography";

import {cn} from "@/lib/utils";
import {
  useProxyDashboardFilterStore,
  type ProxyPayDashboardSchema,
} from "@/modules/imremit";

interface proxyCardListProps {
  title: string;
  description: string;
  content: number;
  status: string;
  remitancemethod: string;
}

interface ProxyCardProps {
  ProxyCardTitle: string;
  ProxyCardDescription: React.ReactNode;
  ProxyCardContent: React.ReactNode;
  ProxyCardStatus: string;
  ProxyCardRemitanceMethod: string;
  isPendingStatus?: boolean;
}

//Define custom text variants for dashbaord count alert
const dashabordCountAlertVariants = cva("text-2xl font-bold", {
  variants: {
    colorThreshold: {
      outstanding: "text-success-foreground",
      inProgess: "text-secondary-foreground",
      failed: "text-destructive-foreground",
    },
  },
});

/**
 * Return card JSX for  proxypay card
 *
 * @param {string} ProxyCardTitle - Title of the card.
 * @param {React.ReactNode} ProxyCardDescription - Description of the card.
 * @param {React.ReactNode} ProxyCardContent - ProxyCardContent of the card.
 * @param {string} ProxyCardStatus - Status.
 * @param {string} ProxyCardRemitanceMethod - Remitance Type.
 * @param {boolean} isPendingStatus - Whether the card is pending data.
 */
function ProxyCard({
  ProxyCardTitle,
  ProxyCardDescription,
  ProxyCardContent,
  ProxyCardStatus,
  ProxyCardRemitanceMethod,
  isPendingStatus,
}: ProxyCardProps) {
  //`updatestoreProxyDashboardFilter` function to update the store
  const {updatestoreProxyDashboardFilter} = useProxyDashboardFilterStore();

  //Skeleton for card's
  const skeletonLoader = <Skeleton className="h-4 w-[100px]" />;

  function getColorThreshold(value: string) {
    if (value === "Outstanding") return "outstanding";
    if (value === "In Progress") return "inProgess";
    return "failed";
  }

  return (
    <Card
      className="cursor-pointer bg-root hover:border-theme hover:bg-muted"
      onClick={() => {
        updatestoreProxyDashboardFilter({
          statusName: ProxyCardStatus,
          remitanceMethod: ProxyCardRemitanceMethod,
        });
      }}
    >
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-md font-medium">{ProxyCardTitle}</CardTitle>
        {ProxyCardTitle == "Outstanding" ? (
          <Clock3Icon className="text-success-foreground" />
        ) : ProxyCardTitle == "In Progress" ? (
          <CircleEllipsisIcon className="text-secondary-foreground" />
        ) : (
          <BanIcon className="text-destructive-foreground" />
        )}
      </CardHeader>
      <CardContent>
        <div
          className={cn(
            dashabordCountAlertVariants({
              colorThreshold: getColorThreshold(ProxyCardTitle),
            })
          )}
        >
          {isPendingStatus ? skeletonLoader : ProxyCardContent}
        </div>
        <Paragraph className="text-xs text-accent-foreground">
          {ProxyCardDescription}
        </Paragraph>
      </CardContent>
    </Card>
  );
}

interface ProxyPayCardsProps {
  proxyPayDashboardData?: ProxyPayDashboardSchema;
  isPending?: boolean;
}

export function ProxyCards({
  proxyPayDashboardData,
  isPending,
}: ProxyPayCardsProps) {
  const proxyCardList: proxyCardListProps[] = [
    {
      title: "Outstanding",
      description: "Web Payments",
      content: proxyPayDashboardData
        ? proxyPayDashboardData.outstandingPayByWebStatus
        : 0,
      status: "Open",
      remitancemethod: "2",
    },
    {
      title: "In Progress",
      description: "Web Payments",
      content: proxyPayDashboardData
        ? proxyPayDashboardData.inProgressPayByWebStatus
        : 0,
      status: "In Progress",
      remitancemethod: "2",
    },
    {
      title: "Failed",
      description: "Web Payments",
      content: proxyPayDashboardData
        ? proxyPayDashboardData.failedPayByWebStatus
        : 0,
      status: "Failed",
      remitancemethod: "2",
    },
    {
      title: "Outstanding",
      description: "Phone Payments",
      content: proxyPayDashboardData
        ? proxyPayDashboardData.outstandingPayByPhoneStatus
        : 0,
      status: "Open",
      remitancemethod: "3",
    },
    {
      title: "In Progress",
      description: "Phone Payments",
      content: proxyPayDashboardData
        ? proxyPayDashboardData.inProgressPayByPhoneStatus
        : 0,
      status: "In Progress",
      remitancemethod: "3",
    },
    {
      title: "Failed",
      description: "Phone Payments",
      content: proxyPayDashboardData
        ? proxyPayDashboardData.failedPayByPhoneStatus
        : 0,
      status: "Failed",
      remitancemethod: "3",
    },
  ];

  return (
    <section className="mb-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6">
      {proxyCardList.map((props, i) => (
        <ProxyCard
          key={i}
          ProxyCardTitle={props.title}
          ProxyCardDescription={props.description}
          ProxyCardContent={props.content}
          ProxyCardStatus={props.status}
          ProxyCardRemitanceMethod={props.remitancemethod}
          isPendingStatus={isPending}
        />
      ))}
    </section>
  );
}
