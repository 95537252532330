import {z} from "zod";

export const paymentMethodNameSchema = z.object({
  customerPaymentMethodId: z.number(),
  customerPaymentMethodName: z.string().nullable().optional(),
  customerPaymentMethodDescription: z.string().nullable().optional(),
  paymentMethodId: z.number().nullable(),
  paymentMethodName: z.string().nullable(),
  providerId: z.number().nullable(),
  providerName: z.string().nullable(),
});

export type PaymentMethodName = z.infer<typeof paymentMethodNameSchema>;

export const paymentMethodNameColumnsSchema = z.object({
  customerPaymentMethodId: z.boolean(),
  customerPaymentMethodName: z.boolean(),
  customerPaymentMethodDescription: z.boolean(),
  paymentMethodId: z.boolean(),
  paymentMethodName: z.boolean(),
  providerId: z.boolean(),
  providerName: z.boolean(),
});
export type PaymentMethodNameColumnsType = z.infer<
  typeof paymentMethodNameColumnsSchema
>;
