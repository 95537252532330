import {useEffect, useMemo} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {Link} from "@tanstack/react-router";
import {ArrowRightIcon, CheckIcon, ChevronsUpDownIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import * as z from "zod";

import {Button, buttonVariants} from "@/components/ui/button";
import {Card, CardContent} from "@/components/ui/card";
import {Command, CommandGroup, CommandItem} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Heading2, Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {DatePicker} from "@/components/craft/date-pickers";

import {cn} from "@/lib/utils";

import {provinceOptionsCanada, stateOptionsUSA} from "@/utils/constants";

const countryList = [
  {label: "USA", value: "US"},
  {label: "Canada", value: "CA"},
];

const statusList = [
  {label: "New", value: "New"},
  {label: "Active", value: "Active"},
  {label: "Suspended", value: "Suspended"},
  {label: "Locked", value: "Locked"},
  {label: "Expired", value: "Expired"},
];

const paymentMethodList = [
  {label: "Invoice", value: "Invoice"},
  {label: "Online", value: "Online"},
];

const yesNoList = [
  {label: "Yes", value: "1"},
  {label: "No", value: "0"},
];

const FormSchema = z.object({
  registrationDate: z
    .date()
    .min(new Date("1970-01-01"), {
      message: "Date is out of range",
    })
    .optional(),
  zipCode: z.string(),
  taxCode: z.string(),
  country: z.string(),
  lawsonID: z.string(),
  status: z.string(),
  corporateName: z.string(),
  subscriptionExpiry: z
    .date()
    .min(new Date("1970-01-01"), {
      message: "Date is out of range",
    })
    .optional(),
  address1: z.string(),
  address2: z.string(),
  address3: z.string(),
  preferredPurchaseType: z.string(),
  autoInvoice: z.string(),
  autoInvoiceThreshold: z.string(),
  city: z.string(),
  state: z.string(),
  isIDSCustomer: z.string(),
});

export function EditSuppliersPage() {
  const editSuppliersFormMethods = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  function onSubmit(data: z.infer<typeof FormSchema>) {
    toast({
      title: "You submitted the following values:",
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data, null, 2)}</code>
        </pre>
      ),
    });
  }

  // const {errors} = editSuppliersFormMethods.formState;

  // console.log("errors", errors);

  const countryValue = editSuppliersFormMethods.watch("country");

  const stateOptions = useMemo(() => {
    if (countryValue === "ca") {
      return [...provinceOptionsCanada];
    } else {
      return [...stateOptionsUSA];
    }
  }, [countryValue]);

  // if state option changes, clear the state value
  useEffect(() => {
    editSuppliersFormMethods.setValue("state", "");
  }, [countryValue, editSuppliersFormMethods]);

  return (
    <section>
      <div className="mb-4 flex flex-col-reverse gap-4 md:flex-row md:justify-between">
        <div className="flex flex-col">
          <Heading2>Edit Supplier</Heading2>
          <Paragraph>Edit your supplier's details here.</Paragraph>
        </div>
        <Link
          to="/app/admin/supplier-management"
          className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
        >
          Back to list
          <span className="sr-only">Back to list</span>
          <ArrowRightIcon className="size-4" />
        </Link>
      </div>

      <Card>
        <CardContent>
          <Form {...editSuppliersFormMethods}>
            <form onSubmit={editSuppliersFormMethods.handleSubmit(onSubmit)}>
              <div className="mb-10 grid grid-cols-1 gap-4 pt-7 md:grid-cols-2 xl:grid-cols-4">
                <FormField
                  control={editSuppliersFormMethods.control}
                  name="registrationDate"
                  render={({field}) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Registered on :</FormLabel>
                      <DatePicker
                        {...field}
                        placeholder="Registered on"
                        disabled={undefined}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={editSuppliersFormMethods.control}
                  name="zipCode"
                  render={({field}) => (
                    <FormItem>
                      <FormLabel>Zip Code :</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter Zip Code " {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={editSuppliersFormMethods.control}
                  name="taxCode"
                  render={({field}) => (
                    <FormItem>
                      <FormLabel>T.I.N. :</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter TIN " {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={editSuppliersFormMethods.control}
                  name="country"
                  render={({field}) => (
                    <div className="space-y-2">
                      <FormLabel>Country :</FormLabel>
                      <FormItem>
                        <Popover>
                          <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                            <Command>
                              <CommandGroup>
                                {countryList.map((country, i) => (
                                  <CommandItem
                                    key={i}
                                    value={country.value}
                                    onSelect={(value) => {
                                      field.onChange(value);
                                    }}
                                  >
                                    <CheckIcon
                                      className={cn(
                                        "mr-2 size-4",
                                        field.value &&
                                          field.value.toUpperCase() ===
                                            country.value
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    {country.label}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </Command>
                          </PopoverContent>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant="outline"
                                role="combobox"
                                className={cn(
                                  "justify-between",
                                  !field.value && "text-accent-foreground",
                                  "w-full"
                                )}
                              >
                                {field.value
                                  ? countryList.find(
                                      (item) =>
                                        item.value.toLowerCase() === field.value
                                    )?.label
                                  : "Select Country"}
                                <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    </div>
                  )}
                />

                <FormField
                  control={editSuppliersFormMethods.control}
                  name="lawsonID"
                  render={({field}) => (
                    <FormItem>
                      <FormLabel>LawsonID :</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter LawsonID " {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={editSuppliersFormMethods.control}
                  name="status"
                  render={({field}) => (
                    <div className="space-y-2">
                      <FormLabel>Status :</FormLabel>
                      <FormItem>
                        <Popover>
                          <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                            <Command>
                              <CommandGroup>
                                {statusList.map((status, i) => (
                                  <CommandItem
                                    key={i}
                                    value={status.value}
                                    onSelect={(value) => {
                                      field.onChange(value);
                                    }}
                                  >
                                    <CheckIcon
                                      className={cn(
                                        "mr-2 size-4",
                                        field.value &&
                                          field.value ===
                                            status.value.toLowerCase()
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    {status.label}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </Command>
                          </PopoverContent>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant="outline"
                                role="combobox"
                                className={cn(
                                  "justify-between",
                                  !field.value && "text-accent-foreground",
                                  "w-full"
                                )}
                              >
                                {field.value
                                  ? statusList.find(
                                      (item) =>
                                        item.value.toLowerCase() === field.value
                                    )?.label
                                  : "Select Status"}
                                <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    </div>
                  )}
                />

                <FormField
                  control={editSuppliersFormMethods.control}
                  name="corporateName"
                  render={({field}) => (
                    <FormItem>
                      <FormLabel>Corporate Name :</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter Corporate Name" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={editSuppliersFormMethods.control}
                  name="subscriptionExpiry"
                  render={({field}) => (
                    <FormItem className="flex flex-col gap-2">
                      <FormLabel>Subscription Expires on :</FormLabel>
                      <DatePicker
                        {...field}
                        placeholder="Subscription Expires on"
                        disabled={undefined}
                      />
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={editSuppliersFormMethods.control}
                  name="preferredPurchaseType"
                  render={({field}) => (
                    <div className="space-y-2">
                      <FormLabel>Preferred Purchase Type :</FormLabel>
                      <FormItem>
                        <Popover>
                          <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                            <Command>
                              <CommandGroup>
                                {paymentMethodList.map((paymentMethod, i) => (
                                  <CommandItem
                                    key={i}
                                    value={paymentMethod.value}
                                    onSelect={(value) => {
                                      field.onChange(value);
                                    }}
                                  >
                                    <CheckIcon
                                      className={cn(
                                        "mr-2 size-4",
                                        field.value &&
                                          field.value ===
                                            paymentMethod.value.toLowerCase()
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    {paymentMethod.label}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </Command>
                          </PopoverContent>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant="outline"
                                role="combobox"
                                className={cn(
                                  "justify-between",
                                  !field.value && "text-accent-foreground",
                                  "w-full"
                                )}
                              >
                                {field.value
                                  ? paymentMethodList.find(
                                      (item) =>
                                        item.value.toLowerCase() === field.value
                                    )?.label
                                  : "Select preferred purchase type"}
                                <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    </div>
                  )}
                />

                <FormField
                  control={editSuppliersFormMethods.control}
                  name="address1"
                  render={({field}) => (
                    <FormItem>
                      <FormLabel>Address 1 :</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter Address 1 " {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={editSuppliersFormMethods.control}
                  name="address2"
                  render={({field}) => (
                    <FormItem>
                      <FormLabel>Address 2:</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter Address 2" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={editSuppliersFormMethods.control}
                  name="address3"
                  render={({field}) => (
                    <FormItem>
                      <FormLabel>Address 3:</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter Address 3" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={editSuppliersFormMethods.control}
                  name="autoInvoice"
                  render={({field}) => (
                    <div className="space-y-2">
                      <FormLabel>Auto Invoice :</FormLabel>
                      <FormItem>
                        <Popover>
                          <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                            <Command>
                              <CommandGroup>
                                {yesNoList.map((autoInvoice, i) => (
                                  <CommandItem
                                    key={i}
                                    value={autoInvoice.value}
                                    onSelect={(value) => {
                                      field.onChange(value);
                                    }}
                                  >
                                    <CheckIcon
                                      className={cn(
                                        "mr-2 size-4",
                                        field.value &&
                                          field.value.toUpperCase() ===
                                            autoInvoice.value
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    {autoInvoice.label}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </Command>
                          </PopoverContent>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant="outline"
                                role="combobox"
                                className={cn(
                                  "justify-between",
                                  !field.value && "text-accent-foreground",
                                  "w-full"
                                )}
                              >
                                {field.value
                                  ? yesNoList.find(
                                      (item) =>
                                        item.value.toLowerCase() === field.value
                                    )?.label
                                  : "Select Auto Invoice"}
                                <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    </div>
                  )}
                />

                <FormField
                  control={editSuppliersFormMethods.control}
                  name="autoInvoiceThreshold"
                  render={({field}) => (
                    <FormItem>
                      <FormLabel>Auto Invoice Threshold :</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter Auto Invoice Threshold"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={editSuppliersFormMethods.control}
                  name="city"
                  render={({field}) => (
                    <FormItem>
                      <FormLabel>City :</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter City" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={editSuppliersFormMethods.control}
                  name="state"
                  render={({field}) => (
                    <FormItem className="mt-2 flex flex-col">
                      <FormLabel>State:</FormLabel>
                      <Popover>
                        <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                          <Command>
                            <CommandGroup>
                              {stateOptions.map((regionOption) => (
                                <CommandItem
                                  key={regionOption.value}
                                  value={regionOption.label}
                                  onSelect={() => {
                                    field.onChange(regionOption.value);
                                  }}
                                >
                                  <CheckIcon
                                    className={cn(
                                      "mr-2 size-4",
                                      regionOption.value === field.value
                                        ? "opacity-100"
                                        : "opacity-0"
                                    )}
                                  />
                                  {regionOption.label}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </Command>
                        </PopoverContent>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant="outline"
                              role="combobox"
                              className={cn(
                                "justify-between",
                                !field.value && "text-accent-foreground",
                                "w-full"
                              )}
                            >
                              {field.value
                                ? stateOptionsUSA.find(
                                    (item) => item.value === field.value
                                  )?.label
                                : "Select State..."}
                              <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={editSuppliersFormMethods.control}
                  name="isIDSCustomer"
                  render={({field}) => (
                    <div className="space-y-2">
                      <FormLabel>IM Customer:</FormLabel>
                      <FormItem>
                        <Popover>
                          <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                            <Command>
                              <CommandGroup>
                                {yesNoList.map((imCustomer, i) => (
                                  <CommandItem
                                    key={i}
                                    value={imCustomer.value}
                                    onSelect={(value) => {
                                      field.onChange(value);
                                    }}
                                  >
                                    <CheckIcon
                                      className={cn(
                                        "mr-2 size-4",
                                        field.value &&
                                          field.value.toUpperCase() ===
                                            imCustomer.value
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    {imCustomer.label}
                                  </CommandItem>
                                ))}
                              </CommandGroup>
                            </Command>
                          </PopoverContent>
                          <PopoverTrigger asChild>
                            <FormControl>
                              <Button
                                variant="outline"
                                role="combobox"
                                className={cn(
                                  "justify-between",
                                  !field.value && "text-accent-foreground",
                                  "w-full"
                                )}
                              >
                                {field.value
                                  ? yesNoList.find(
                                      (item) =>
                                        item.value.toLowerCase() === field.value
                                    )?.label
                                  : "Select..."}
                                <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                              </Button>
                            </FormControl>
                          </PopoverTrigger>
                        </Popover>
                        <FormMessage />
                      </FormItem>
                    </div>
                  )}
                />
              </div>
              <div className="text-right">
                <Button type="submit">Submit</Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </section>
  );
}
