import {useState} from "react";

import {CheckIcon, ChevronsUpDownIcon} from "lucide-react";
import {useFormContext} from "react-hook-form";

import {Button} from "@/components/ui/button";
import {Card, CardContent} from "@/components/ui/card";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Label} from "@/components/ui/label";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {ScrollArea} from "@/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {Heading3} from "@/components/ui/typography";

import {cn} from "@/lib/utils";

import {currenciesOptions} from "@/utils/constants";

interface SelectProps {
  value: string;
  label: string;
}

interface SelectFormProps {
  allPaymentMethods?: {value: string; label: string; id: number}[];
  allSuppliers?: SelectProps[];
  allFacilities?: SelectProps[];
  isMutating: boolean;
  currenciesOptions?: SelectProps[];
}

export function SelectForm({
  isMutating,
  allFacilities,
  allPaymentMethods,
  allSuppliers,
}: SelectFormProps) {
  return (
    <Card className="mb-4 mt-4">
      <CardContent>
        <Heading3 className="mb-4 mt-4">Select Form</Heading3>
        <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
          <FacilityIdField
            isMutating={isMutating}
            allFacilities={allFacilities}
          />
          <CurrenciesField
            isMutating={isMutating}
            currenciesOptions={[...currenciesOptions]}
          />
          <PaymentMethodField
            isMutating={isMutating}
            allPaymentMethods={allPaymentMethods}
          />

          <SupplierField isMutating={isMutating} allSuppliers={allSuppliers} />
        </div>
      </CardContent>
    </Card>
  );
}

function FacilityIdField({isMutating, allFacilities}: SelectFormProps) {
  const {setValue, control} = useFormContext();

  return (
    <FormField
      control={control}
      name="facilityId"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="facilityId" showMandatoryAsterisk>
            Facility:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  className={cn(
                    "justify-between",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allFacilities?.find(
                        (facility) => facility.value === field.value
                      )?.label
                    : "Select Facility..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Facility..." />
                <CommandEmpty>No Facility found.</CommandEmpty>
                <CommandGroup>
                  <ScrollArea className="h-52">
                    {allFacilities?.map((facility) => (
                      <CommandItem
                        key={facility.value}
                        value={facility.label}
                        onSelect={() => {
                          setValue("facilityId", facility.value);
                        }}
                      >
                        <CheckIcon
                          className={cn(
                            "mr-2 size-4",
                            facility.value.toLowerCase() === field.value
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {facility.label}
                      </CommandItem>
                    ))}
                  </ScrollArea>
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function PaymentMethodField({isMutating, allPaymentMethods}: SelectFormProps) {
  const {
    setValue,
    formState: {errors},
  } = useFormContext();

  const [currentPaymentMethod, setCurrentPaymentMethod] = useState<string>("");

  return (
    <div className="mt-2 grid w-full items-center gap-1.5">
      <Label
        htmlFor="paymentMethodId"
        className={errors.paymentMethodId ? "text-destructive-foreground" : ""}
      >
        Payment Group:
      </Label>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            disabled={isMutating}
            aria-disabled={isMutating}
            variant="outline"
            role="combobox"
            className={cn(
              "justify-between",
              !currentPaymentMethod && "text-accent-foreground"
            )}
          >
            {currentPaymentMethod
              ? allPaymentMethods?.find(
                  (item) => item.value === currentPaymentMethod
                )?.label
              : "Select Payment Group..."}
            <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0 ">
          <Command>
            <CommandInput placeholder="Search Payment Group..." />

            <CommandEmpty>No Payment Method found.</CommandEmpty>
            <CommandGroup>
              {allPaymentMethods?.map((option) => (
                <CommandItem
                  key={option.value}
                  value={option.label}
                  onSelect={() => {
                    setCurrentPaymentMethod(option.value);
                    setValue("paymentMethodId", option.id.toString());
                    setValue("providerMethodId", option.id.toString());
                  }}
                >
                  <CheckIcon
                    className={cn(
                      "mr-2 size-4",
                      option.value.toLowerCase() === currentPaymentMethod
                        ? "opacity-100"
                        : "opacity-0"
                    )}
                  />
                  {option.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
      {errors.paymentMethodId && (
        <p className="text-sm font-medium text-destructive-foreground">
          Required
        </p>
      )}
    </div>
  );
}

function SupplierField({isMutating, allSuppliers}: SelectFormProps) {
  const {setValue, control} = useFormContext();

  return (
    <FormField
      control={control}
      name="supplierDetailId"
      render={({field}) => (
        <FormItem className={`mt-2 flex flex-col `}>
          <FormLabel htmlFor="supplierDetailId" showMandatoryAsterisk>
            Supplier:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  disabled={isMutating}
                  role="combobox"
                  className={cn(
                    "justify-between",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allSuppliers?.find(
                        (supplier) => supplier.value === field.value
                      )?.label
                    : "Select Supplier"}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search supplier..." />
                <CommandEmpty>No supplier found.</CommandEmpty>
                <CommandGroup>
                  {allSuppliers?.map((supplier) => (
                    <CommandItem
                      key={supplier.value}
                      value={supplier.label}
                      onSelect={() => {
                        setValue("supplierDetailId", supplier.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          supplier.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {supplier.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>

          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CurrenciesField({isMutating, currenciesOptions}: SelectFormProps) {
  const {
    setValue,
    formState: {errors},
  } = useFormContext();
  return (
    <div className="mt-2 grid w-full items-center gap-1.5">
      <Label
        htmlFor="currency"
        className={errors.currency ? "text-destructive-foreground" : ""}
        showMandatoryAsterisk
      >
        Currency:
      </Label>
      <Select
        disabled={isMutating}
        aria-disabled={isMutating}
        onValueChange={(value) => {
          value === "usd" && setValue("currency", "USD");
          value === "cad" && setValue("currency", "CAD");
        }}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder="Select Currency..." />
        </SelectTrigger>
        <SelectContent>
          {currenciesOptions?.map((item) => (
            <SelectItem key={item.value} value={item.value}>
              {item.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
      {errors.currency && (
        <p className="text-sm font-medium text-destructive-foreground">
          Required
        </p>
      )}
    </div>
  );
}
