import {useMemo} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate, useParams} from "@tanstack/react-router";
import {ArrowRightIcon, FileCogIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button, buttonVariants} from "@/components/ui/button";
import {Card, CardContent, CardFooter, CardHeader} from "@/components/ui/card";
import {Form} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {Spinner} from "@/components/ui/spinner";
import {Heading2, Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {LoadingSkeletonCard} from "@/components/craft/loading-skeleton-card";

import {cn} from "@/lib/utils";
import {useKeyCloakInstanceStore} from "@/modules/auth";
import {
  performStatementFileReconciliationFn,
  StatementReconciliationFormSchema,
  useSRMappingsByExternalId,
  useStatementFileByFileId,
} from "@/modules/statement-recon";

interface StatementReconReconciliationDataProps {
  fileId: string;
  externalId: string;
}

export function useStatementReconcilationData({
  fileId,
  externalId,
}: StatementReconReconciliationDataProps) {
  const statementFileQuery = useStatementFileByFileId(fileId);
  const statementFile = statementFileQuery.data?.content;
  const statementFilePending = statementFileQuery.isPending;

  const statementMappingQuery = useSRMappingsByExternalId(externalId);
  const statementMapping = statementMappingQuery.data?.content || [];
  const statementMappingPending = statementMappingQuery.isPending;

  return {
    statementFile,
    statementFilePending,
    statementMapping,
    statementMappingPending,
  };
}

export function StatementReconciliationPage() {
  const navigate = useNavigate();

  // Get the storeKeyCloakInstance from the useKeyCloakInstanceStore hook
  const storeKeyCloakInstance =
    useKeyCloakInstanceStore().storeKeyCloakInstance;

  // Get the parsedUserInfo and logoutFn from the useKeyCloakInstanceStore hook
  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );

  //Logged User Email ID
  const userEmailId: string = (parsedUserInfo as {email: string}).email || "";

  const {fileId, externalId} = useParams({
    from: "/app/statement-recon/upload/$fileId/reconciliation/$externalId",
  });

  const {
    statementFile,
    statementFilePending,
    statementMapping,
    statementMappingPending,
  } = useStatementReconcilationData({
    fileId,
    externalId,
  });

  const statementReonciliationFormMethods = useForm<
    z.infer<typeof StatementReconciliationFormSchema>
  >({
    resolver: zodResolver(StatementReconciliationFormSchema),
  });

  const {setValue, watch} = statementReonciliationFormMethods;

  const {statementMappingId} = watch();

  // Define the mutation for creating new file mapping 📝
  const triggerStatementReconciliationMutation = useMutation({
    // Define the mutation function
    mutationFn: async (
      data: z.infer<typeof StatementReconciliationFormSchema>
    ) => {
      const reqData = {
        file_id: data.statementFileId,
        mapping_id: data.statementMappingId,
        buyer_external_id: externalId,
        logged_user_email_id: userEmailId,
      };

      console.log("Reconciliation req data: ", reqData);

      return performStatementFileReconciliationFn(reqData);
    },

    // Handle the mutation success 🎉
    onSuccess: (triggerReconciliationResponse) => {
      console.log(
        "triggerReconciliationResponse",
        triggerReconciliationResponse
      );

      // const triggerReconciliationResponseData =
      //   triggerReconciliationResponse.content[0];

      toast({
        variant: "success",
        title: "Success!",
        description: "Statement reconciled successfully",
      });

      // void updateStatementFileStatusFn(
      //   triggerReconciliationResponseData.statement_file_id.toString(),
      //   3 //reconciled
      // );

      // Redirect to the statement search page
      void navigate({
        to: "/app/statement-recon/file-history",
        replace: true,
      });
    },

    // Handle the mutation error 🚨
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to reconcile statement",
      });
    },
  });

  if (statementFilePending || statementMappingPending) {
    return <LoadingSkeletonCard lineCount={5} />;
  }

  return (
    <Form {...statementReonciliationFormMethods}>
      <form
        onSubmit={statementReonciliationFormMethods.handleSubmit((data) => {
          triggerStatementReconciliationMutation.mutate(data);
        })}
      >
        <section className="flex flex-col gap-4">
          <div className="flex justify-between gap-4">
            <div className="flex flex-col">
              <Heading2>Statement Reconciliation</Heading2>
              <Paragraph>
                This is the statement reconciliation page. Here you can view the
                statement files, statement mappings and trigger a
                reconciliation.
              </Paragraph>
            </div>

            <div className="flex gap-2">
              <Select
                onValueChange={(value) => {
                  setValue("statementMappingId", value);
                }}
              >
                <SelectTrigger className="max-w-fit bg-root">
                  <SelectValue placeholder="Select mapping..." />
                </SelectTrigger>
                <SelectContent>
                  {statementMapping.map((mapping) => (
                    <SelectItem
                      key={mapping.saved_mapping_id}
                      value={mapping.saved_mapping_id.toString()}
                    >
                      {mapping.saved_mapping_name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Link
                to="/app/statement-recon/upload/$fileId/mapping/$externalId"
                className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
                params={{
                  fileId,
                  externalId,
                }}
              >
                Back to mapping
                <span className="sr-only">Back to mapping</span>
                <ArrowRightIcon className="size-4" />
              </Link>
            </div>
          </div>

          <Card>
            <CardHeader>
              <Heading2>Reconciliation Form</Heading2>
              <Paragraph>
                Please select a mapping to reconcile the statement file.
              </Paragraph>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 pb-6 lg:grid-cols-2">
                <Paragraph>
                  <strong>Statement File:</strong>{" "}
                  {statementFile?.user_statement_file_name}
                </Paragraph>
                <Paragraph>
                  <strong>Statement Uploaded:</strong>{" "}
                  {statementFile?.created_timestamp}
                </Paragraph>
                <Paragraph>
                  <strong>Statement Uploaded By:</strong>{" "}
                  {statementFile?.statement_uploaded_by}
                </Paragraph>
                <Paragraph>
                  <strong>Statement File Type:</strong>{" "}
                  {statementFile?.statement_file_mime_type}
                </Paragraph>
              </div>

              <Paragraph>
                <strong>Statement Mapping:</strong>{" "}
                {statementMappingId
                  ? statementMapping.find(
                      (mapping) =>
                        mapping.saved_mapping_id.toString() ===
                        statementMappingId
                    )?.saved_mapping_name
                  : "Please select a mapping..."}
              </Paragraph>
            </CardContent>
            <CardFooter>
              <Button
                className="gap-2"
                disabled={
                  !statementMappingId ||
                  triggerStatementReconciliationMutation.isPending
                }
                onClick={() => {
                  setValue(
                    "statementFileId",
                    statementFile?.statement_file_id.toString() || ""
                  );
                }}
              >
                {triggerStatementReconciliationMutation.isPending ? (
                  <Spinner size="xs" />
                ) : null}
                <span className="sr-only">Reconcile Statement</span>
                Reconcile Statement
                <FileCogIcon className="size-4" />
              </Button>
            </CardFooter>
          </Card>
        </section>
      </form>
    </Form>
  );
}
