import {kyApiFn} from "@/lib/ky";
import {type AddPaymentMethodFormDTO} from "@/modules/admin";

export const addCustomerPaymentMethodFn = (
  addCustomerPaymentMethodDTO: AddPaymentMethodFormDTO,
  externalId: string
) =>
  kyApiFn(`api/e-payable/management/${externalId}/methods`, "post", {
    json: addCustomerPaymentMethodDTO,
  });
