import {z} from "zod";

export const customerManagementIMRemitLiteTableColumnsSchema = z.object({
  id: z.boolean(),
  buyerName: z.boolean(),
  email: z.boolean(),
  status: z.boolean(),
  billingType: z.boolean(),
  paymentType: z.boolean(),
  paymentMethod: z.boolean(),
  settlementTerms: z.boolean(),
  invoiceDay: z.boolean(),
  contactName: z.boolean(),
  address: z.boolean(),
  phone: z.boolean(),
});
export type CustomerManagementIMRemitLiteTableColumnsType = z.infer<
  typeof customerManagementIMRemitLiteTableColumnsSchema
>;
