import {z} from "zod";

export const imREmitDashboardSchema = z.object({
  pendingPayments: z.number(),
  endingPayableInNext7Days: z.number(),
  missedPayableInPast30Days: z.number(),
  payableWithTransactionMatchingIssue: z.number(),
  declinedPaymentInPast7Days: z.number(),
  paymentWithErrorOnIMSide: z.number(),
  payableWithErrorOnProviderSide: z.number(),
});

export type ImREmitDashboard = z.infer<typeof imREmitDashboardSchema>;
