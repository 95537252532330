import {useEffect, useMemo} from "react";

import {Label} from "@radix-ui/react-label";
import {format, parseISO} from "date-fns";
import {SlidersHorizontalIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {Heading2, Paragraph} from "@/components/ui/typography";

import {DuplicatePaymentRoutesSchema} from "@/lib/routes/types";
import {useDupesCustomerStore, useRoutesStore} from "@/lib/stores";
import type {RunTypeType} from "@/lib/stores/dupes-run-type-store";
import {useDupesRunTypeStore} from "@/lib/stores/dupes-run-type-store";
import {calculateUserRoles, useKeyCloakInstanceStore} from "@/modules/auth";
import {
  SelectCustomerFormDupes,
  SelectCustomerSectionDupes,
} from "@/modules/customers";
import {
  DupesComparisonTypeEnum,
  DupesDashboardCards,
  DupesDashboardPaymentSettings,
  DupesRunHistoryDataTable,
  dupesRunHistoryTableColumns,
  useAllDupesInitialInvoiceComparisons,
  useComparisonAmountsByExternalIdAndComparisonType,
  useGetDupesRunHistoryByExternalId,
} from "@/modules/duplicate-payments";

import {FallbackMessages, KeycloakRoles} from "@/utils/constants";

// 🪄 Custom hook to organize data fetching and state management for the dashboard.
function useDuplicatePaymentData() {
  const {dupesStoreCustomer} = useDupesCustomerStore();
  const {setDuplicatePaymentRoute} = useRoutesStore();

  // Extract the `tokenParsed` field from the store
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  // Extract the `customer` field from the tokenParsed object
  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );

  const roleDisplay = !parsedUserInfo
    ? FallbackMessages.NOT_APPLICABLE
    : calculateUserRoles(parsedUserInfo.realm_access?.roles);

  const limitToLast90Days =
    roleDisplay && roleDisplay.includes(KeycloakRoles.Management)
      ? false
      : true;

  const {dupesRunType} = useDupesRunTypeStore();

  // 🛣 Setting up the route once the component mounts.
  useEffect(() => {
    setDuplicatePaymentRoute(
      DuplicatePaymentRoutesSchema.Values["/app/duplicate-payments/dashboard"]
    );
  }, [setDuplicatePaymentRoute]);
  useAllDupesInitialInvoiceComparisons;
  // 📈 Fetching the history of duplicate payment runs.
  const dupesRunHistoryQuery = useGetDupesRunHistoryByExternalId(
    dupesStoreCustomer?.externalId
  );

  // 🧮 Calculating duplicate payment counts.
  const oneTimeDupesCountQuery = useAllDupesInitialInvoiceComparisons(
    dupesStoreCustomer?.externalId,
    dupesRunType,
    limitToLast90Days
  );

  const counts = {
    likelyCount: oneTimeDupesCountQuery.data?.content.likely || 0,
    maybeCount: oneTimeDupesCountQuery.data?.content.maybe || 0,
    differentSupplierLikelyCount:
      oneTimeDupesCountQuery.data?.content.differentSupplierLikely || 0,
    differentSupplierMaybeCount:
      oneTimeDupesCountQuery.data?.content.differentSupplierMaybe || 0,
    differentAmtsAndDatesCount:
      oneTimeDupesCountQuery.data?.content.differentAmountAndDifferentDate || 0,
    differentDatesCount:
      oneTimeDupesCountQuery.data?.content.differentDates || 0,
    differentSuppliersAndDatesCount:
      oneTimeDupesCountQuery.data?.content.differentSuppliersAndDates || 0,
    differentAmountsCount:
      oneTimeDupesCountQuery.data?.content.differentAmounts || 0,
    creditPairCount: oneTimeDupesCountQuery.data?.content.creditPair || 0,
  };

  const likelyAmountQuery = useComparisonAmountsByExternalIdAndComparisonType(
    DupesComparisonTypeEnum.LIKELY,
    dupesStoreCustomer?.externalId,
    dupesRunType,
    limitToLast90Days
  );

  const maybeAmountQuery = useComparisonAmountsByExternalIdAndComparisonType(
    DupesComparisonTypeEnum.MAYBE,
    dupesStoreCustomer?.externalId,
    dupesRunType,
    limitToLast90Days
  );

  const differentSupplierLikelyAmountQuery =
    useComparisonAmountsByExternalIdAndComparisonType(
      DupesComparisonTypeEnum.DIFFERENT_SUPPLIER_LIKELY,
      dupesStoreCustomer?.externalId,
      dupesRunType,
      limitToLast90Days
    );

  const differentSupplierMaybeAmountQuery =
    useComparisonAmountsByExternalIdAndComparisonType(
      DupesComparisonTypeEnum.DIFFERENT_SUPPLIER_MAYBE,
      dupesStoreCustomer?.externalId,
      dupesRunType,
      limitToLast90Days
    );

  const differentAmtsAndDatesAmountQuery =
    useComparisonAmountsByExternalIdAndComparisonType(
      DupesComparisonTypeEnum.DIFFERENT_AMOUNTS_AND_DATES,
      dupesStoreCustomer?.externalId,
      dupesRunType,
      limitToLast90Days
    );

  const differentDatesQuery = useComparisonAmountsByExternalIdAndComparisonType(
    DupesComparisonTypeEnum.DIFFERENT_DATES,
    dupesStoreCustomer?.externalId,
    dupesRunType,
    limitToLast90Days
  );

  const differentSuppliersAndDatesQuery =
    useComparisonAmountsByExternalIdAndComparisonType(
      DupesComparisonTypeEnum.DIFFERENT_SUPPLIERS_AND_DATES,
      dupesStoreCustomer?.externalId,
      dupesRunType,
      limitToLast90Days
    );

  const differentAmtsQuery = useComparisonAmountsByExternalIdAndComparisonType(
    DupesComparisonTypeEnum.DIFFERENT_AMOUNTS,
    dupesStoreCustomer?.externalId,
    dupesRunType,
    limitToLast90Days
  );

  const creditPairAmountQuery =
    useComparisonAmountsByExternalIdAndComparisonType(
      DupesComparisonTypeEnum.CREDIT_PAIR,
      dupesStoreCustomer?.externalId,
      dupesRunType,
      limitToLast90Days
    );

  const amounts = {
    likelyAmount: likelyAmountQuery.data?.content || 0,
    maybeAmount: maybeAmountQuery.data?.content || 0,
    differentSupplierLikelyAmount:
      differentSupplierLikelyAmountQuery.data?.content || 0,
    differentSupplierMaybeAmount:
      differentSupplierMaybeAmountQuery.data?.content || 0,
    differentAmtsAndDatesAmount:
      differentAmtsAndDatesAmountQuery.data?.content || 0,
    differentDatesAmount: differentDatesQuery.data?.content || 0,
    differentSuppliersAndDatesAmount:
      differentSuppliersAndDatesQuery.data?.content || 0,
    differentAmountsAmount: differentAmtsQuery.data?.content || 0,
    creditPairAmount: creditPairAmountQuery.data?.content || 0,
  };

  const amountsPending =
    likelyAmountQuery.isPending ||
    maybeAmountQuery.isPending ||
    differentSupplierLikelyAmountQuery.isPending ||
    differentSupplierMaybeAmountQuery.isPending ||
    differentAmtsAndDatesAmountQuery.isPending ||
    differentDatesQuery.isPending ||
    differentSuppliersAndDatesQuery.isPending ||
    differentAmtsQuery.isPending ||
    creditPairAmountQuery.isPending;

  // 📦 Packaging all necessary data for the component.
  return {
    dupesStoreCustomer,
    counts,
    dupesRunHistoryQuery,
    oneTimeDupesCountQuery,
    amounts,
    amountsPending,
  };
}

// 🖥 Main component for the Duplicate Payments Dashboard.
export function DupesDashboardPage() {
  // Run Type State Store
  const {dupesRunType, updateDupesRunType} = useDupesRunTypeStore();

  // Extract the `tokenParsed` field from the store
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  // Extract the `customer` field from the tokenParsed object
  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );

  const limitToLast90Days = !parsedUserInfo
    ? FallbackMessages.NOT_APPLICABLE
    : calculateUserRoles(parsedUserInfo.realm_access?.roles);

  const {
    dupesStoreCustomer,
    counts,
    dupesRunHistoryQuery,
    oneTimeDupesCountQuery,
    amounts,
    amountsPending,
  } = useDuplicatePaymentData();

  // 🚫 Early return if no customer is selected, prompting selection.
  if (!dupesStoreCustomer) {
    return <SelectCustomerSectionDupes />;
  }

  const dupesRunHistoryContent = dupesRunHistoryQuery.data?.content || [];
  const lastRun = dupesRunHistoryContent[dupesRunHistoryContent.length - 1];

  return (
    <Collapsible defaultOpen={!parsedUserInfo?.customer}>
      <section className="flex flex-col justify-between">
        <div className="flex flex-col justify-between pb-2 lg:flex-row">
          <div className="flex flex-col">
            <Heading2>Duplicate Payments Dashboard </Heading2>
            <Paragraph>
              Welcome to the Duplicate Payments Dashboard. Here you can view
              your duplicate payment history and adjust settings for duplicates
              analysis.
            </Paragraph>
            <Paragraph className="font-semibold">
              Last run:{" "}
              <span className="text-lg">
                {!!dupesRunHistoryContent.length && lastRun.run_date
                  ? format(parseISO(lastRun.run_date), "MMMM dd, yyyy")
                  : "N/A"}
              </span>
            </Paragraph>
          </div>
          {parsedUserInfo?.customer ? (
            <CollapsibleTrigger asChild>
              <Button className="gap-2 data-[state=open]:border-secondary data-[state=open]:bg-secondary data-[state=open]:text-secondary-foreground">
                <span className="sr-only">Duplicate Payment Settings</span>
                Duplicate Payment Settings
                <SlidersHorizontalIcon className="size-4" />
              </Button>
            </CollapsibleTrigger>
          ) : null}

          <div className="flex gap-2">
            <div
              className={
                limitToLast90Days &&
                !limitToLast90Days.includes(KeycloakRoles.Management)
                  ? "invisible"
                  : ""
              }
            >
              <Label htmlFor="runtype">Run Type</Label>
              <Select
                value={dupesRunType?.toString()}
                onValueChange={(type: RunTypeType) => {
                  updateDupesRunType(type);
                }}
              >
                <SelectTrigger className="w-[180px] bg-root">
                  <SelectValue placeholder="Select Run type" />
                </SelectTrigger>
                <SelectContent className="bg-root">
                  <SelectItem value="Daily">Daily Run</SelectItem>
                  <SelectItem value="OneTime">One Time Run</SelectItem>
                  <SelectItem value="Initial">Initial Run</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <SelectCustomerFormDupes requireLabel />
          </div>
        </div>
        {/* 💳 Displaying summary cards for duplicate payment data. */}
        <DupesDashboardCards
          counts={counts}
          amounts={amounts}
          isLoading={oneTimeDupesCountQuery.isPending || amountsPending}
        />
        <div className="flex flex-col gap-y-4">
          {/* 🔧 Settings for payment analysis and adjustments. */}
          <CollapsibleContent>
            <DupesDashboardPaymentSettings
              externalId={dupesStoreCustomer.externalId}
            />
          </CollapsibleContent>

          {/* 📊 Data table for displaying the history of duplicate payment runs. */}
          <DupesRunHistoryDataTable
            columns={dupesRunHistoryTableColumns}
            data={dupesRunHistoryContent}
            isPending={dupesRunHistoryQuery.isPending}
            isSuccess={dupesRunHistoryQuery.isSuccess}
            isError={dupesRunHistoryQuery.isError}
          />
        </div>
      </section>
    </Collapsible>
  );
}
