import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  suppliersDetailsKeys,
  type SupplierDetailsLiteType,
} from "@/modules/imremit";

// Defining a type based on the function `searchSuppliersDetailsLite`
type QueryFnType = typeof getSuppliersDetailsLite;

// Function to search suppliersDetails
export function getSuppliersDetailsLite(supplierId?: number) {
  return kyApiFn<SupplierDetailsLiteType>(
    /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
    `api/e-payable/management/supplier-details/${supplierId}`,
    "get"
  );
}

// Custom hook to fetch and cache suppliersDetails data using react-query
export function useGetSuppliersDetailsLite(
  supplierId?: number, // The search query string
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: suppliersDetailsKeys.bySupplierId(supplierId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getSuppliersDetailsLite(supplierId), // Setting the function to be used for fetching data
    enabled: !!supplierId, // Setting the query to be enabled only if the search query string is not empty
  });
}
