import {useState} from "react";

import type {Table} from "@tanstack/react-table";
import {ListRestartIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import {
  DataTablePagination,
  DataTableViewOptions,
} from "@/components/craft/data-table";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

interface PaymentFileToolbarProps<TData> {
  table: Table<TData>;
  isPending?: boolean;
  isErrored?: boolean;
}

export function InvoiceMappingToolbar<TData>({
  table,
  isPending,
  isErrored,
}: PaymentFileToolbarProps<TData>) {
  const [globalFilterInput, setGlobalFilterInput] = useState("");

  const isFiltered =
    table.getState().columnFilters.length > 0 ||
    !!table.getState().globalFilter;

  //Columns other names
  const columnsOtherName = [
    {key: "configId", value: "Config ID"},
    {key: "taxId", value: "Tax ID"},
    {key: "facilityName", value: "Facility Name"},
    {key: "invoiceDate", value: "Invoice Date"},
    {key: "invoiceAmount", value: "Invoice Amount"},
    {key: "status", value: "Status"},
    {key: "paymentDate", value: "Payment Date"},
    {key: "facilityId", value: "Facility ID"},
    {key: "vendorNumber", value: "Vendor Number"},
    {key: "invoicePaidAmount", value: "Invoice Paid Amount"},
    {key: "poNumber", value: "PO Number"},
    {key: "suffix", value: "Suffix"},
    {key: "cancelDate", value: "Cancel Date"},
    {key: "invoiceType", value: "Invoice Type"},
    {key: "erpUniqueId", value: "ERP Unique ID"},
    {key: "paymentStatus", value: "Payment Status"},
    {key: "vendorName", value: "Vendor Name"},
    {key: "paymentTerms", value: "Payment Terms"},
    {key: "paymentMethod", value: "Payment Method"},
    {key: "invoiceNo", value: "Invoice Number"},
    {key: "paymentNumber", value: "Payment Number"},
    {key: "dueDate", value: "Due Date"},
    {key: "cancelId", value: "Cancel ID"},
    {key: "voidDate", value: "Void Date"},
    {key: "voidId", value: "Void ID"},
    {key: "vendorGroup", value: "Vendor Group"},
    {key: "vendorLocationCode", value: "Vendor Location Code"},
    {key: "operatingUnit", value: "Operating Unit"},
    {key: "createDate", value: "Create Date"},
    {key: "paymentType", value: "Payment Type"},
    {key: "voucherLineNo", value: "Voucher Line Number"},
    {key: "voucherLineAmount", value: "Voucher Line Amount"},
    {key: "discountDate", value: "Discount Date"},
    {key: "discountAmount", value: "Discount Amount"},
    {key: "salesAmount", value: "Sales Amount"},
    {key: "freightAmount", value: "Freight Amount"},
    {key: "miscAmount", value: "Miscellaneous Amount"},
    {key: "origin", value: "Origin"},
    {key: "grossAmount", value: "Gross Amount"},
    {key: "futureUse1", value: "Future Use 1"},
    {key: "futureUse2", value: "Future Use 2"},
    {key: "futureUse3", value: "Future Use 3"},
    {key: "futureUse4", value: "Future Use 4"},
    {key: "futureUse5", value: "Future Use 5"},
  ];
  return (
    <>
      <DataTablePagination
        table={table}
        isPending={isPending}
        isErrored={isErrored}
      />
      <div className="flex items-center justify-between">
        <div className="flex flex-1 items-center space-x-2">
          <RowsPreferenceSelect />
          <Input
            placeholder="Search all entries..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={globalFilterInput}
            onChange={(event) => {
              setGlobalFilterInput(event.target.value);
              table.setGlobalFilter(event.target.value);
            }}
          />
          {isFiltered && (
            <Button
              variant="destructive"
              className="h-10 gap-2 px-2 hover:bg-destructive/70 hover:text-destructive-foreground/80 active:bg-destructive/80 active:text-destructive-foreground/80 lg:px-3"
              onClick={() => {
                table.resetColumnFilters();
                table.resetGlobalFilter();
                setGlobalFilterInput("");
              }}
            >
              <span className="sr-only">Reset search filters</span>
              Search Reset
              <ListRestartIcon className="size-4" />
            </Button>
          )}
        </div>
        <DataTableViewOptions
          table={table}
          columnsOtherName={columnsOtherName}
        />
      </div>
    </>
  );
}
