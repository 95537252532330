// import {useCallback} from "react";

import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {
  HoverCard,
  HoverCardArrow,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {formatAmount} from "@/modules/admin";
import {InvoicesStatusHistoryTable, type Invoice} from "@/modules/invoices";

import {FallbackMessages, invoiceStatuses} from "@/utils/constants";

export function useGetInvoiceColumns() {
  // Get the store functions for sorting PaymentManagement

  const invoiceColumns: ColumnDef<Invoice>[] = [
    {
      accessorKey: "taxCode",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Tax Code" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("taxCode") ? (
            row.getValue("taxCode")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "invoiceDate",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Invoice Date" />
      ),
      cell: ({row}) => {
        return (
          <Paragraph>
            {row.original.invoiceDate ? (
              row.original.invoiceDate
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>
        );
      },
    },

    {
      accessorKey: "invoiceAmount",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Invoice Amount" />
      ),
      cell: ({row}) => {
        const amountValue = row.original.invoiceAmount;

        // Check if empty and return "no data" if empty
        const formattedAmount = amountValue ? (
          formatAmount(amountValue)
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );

        return <Paragraph>{formattedAmount}</Paragraph>;
      },
    },

    {
      accessorKey: "paymentDate",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Payment Date" />
      ),
      cell: ({row}) => {
        return (
          <Paragraph>
            {row.original.paymentDate ? (
              row.original.paymentDate
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>
        );
      },
    },

    {
      accessorKey: "facilityName",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Facility Name" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("facilityName") ? (
            row.getValue("facilityName")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "facilityNumber",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Facility ID" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("facilityNumber") ? (
            row.getValue("facilityNumber")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "invoiceNo",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Invoice Number" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("invoiceNo") ? (
            row.getValue("invoiceNo")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "status",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),
      cell: ({row}) => {
        if (!row.original.status) {
          return <Paragraph>{FallbackMessages.NOT_APPLICABLE}</Paragraph>;
        }

        const statusObject = invoiceStatuses.find(
          (status) => status.label === row.original.status
        );

        if (!statusObject) {
          return <Paragraph>{row.original.status}</Paragraph>;
        }

        const IconComponent = statusObject.icon;

        return (
          <HoverCard openDelay={100} closeDelay={250}>
            <HoverCardTrigger>
              <Badge className="flex items-center gap-2 uppercase hover:opacity-70">
                <IconComponent />
                {row.original.status}
              </Badge>
            </HoverCardTrigger>
            <HoverCardContent className="p-0">
              <HoverCardArrow />
              <InvoicesStatusHistoryTable invoiceId={row.original.id} />
            </HoverCardContent>
          </HoverCard>
        );
      },
    },

    {
      accessorKey: "supplierName",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Supplier Name" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("supplierName") ? (
            row.getValue("supplierName")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "customerName",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Customer Name" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("customerName") ? (
            row.getValue("customerName")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "paymentNumber",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Payment Number" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("paymentNumber") ? (
            row.getValue("paymentNumber")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "paidAmount",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Paid Amount" />
      ),
      cell: ({row}) => {
        const amountValue = row.original.paidAmount;

        // Check if empty and return "no data" if empty
        const formattedAmount = amountValue ? (
          formatAmount(amountValue)
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );

        return <Paragraph>{formattedAmount}</Paragraph>;
      },
    },

    {
      accessorKey: "erpUniqueId",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="ERP Unique ID" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("erpUniqueId") ? (
            row.getValue("erpUniqueId")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "poNumber",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="PO Number" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("poNumber") ? (
            row.getValue("poNumber")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "paymentMethod",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Payment Method" />
      ),
      cell: ({row}) => {
        if (!row.original.paymentMethod) {
          return <Paragraph>{FallbackMessages.NOT_APPLICABLE}</Paragraph>;
        }

        return (
          <Badge className="uppercase hover:opacity-70">
            {row.original.paymentMethod}
          </Badge>
        );
      },
    },

    // Adding a custom column for actions with a custom cell renderer
    // {
    //   id: "actions",
    //   cell: ({row}) => <InvoiceDataTableRowActions row={row} />,
    // },
  ];
  return invoiceColumns;
}
