import {z} from "zod";

// TODO: use this type for the forms as well
export const imREmitPaymentSchema = z.object({
  buyerAlias: z.string().nullable(),
  paymentDetailId: z.string(),
  externalId: z.string(),
  customerStatus: z.string().nullable(),
  statusName: z
    .enum([
      "Paid",
      "Delivered",
      "Closed",
      "Failed",
      "Error",
      "PaidPendingVerification",
      "Duplicate",
      "In Progress",
      "Blocked",
      "Open",
      "Partially Paid",
    ])
    .nullable(),
  accountNumber: z.string().nullable(),
  providerName: z.string().nullable(),
  cardNumber: z.string().nullable(),
  singleTranExactAuth: z.string().nullable(),
  expirationDate: z.string().nullable(),
  securityCode: z.string().nullable(),
  supplierName: z.string().nullable(),
  supplierId: z.string().nullable(),
  paymentNumber: z.string().nullable(),
  facilityName: z.string().nullable(),
  description: z.string().nullable(),
  initiatedDate: z.string().nullable(),
  endDate: z.string().nullable(),
  supplierNumber: z.string().nullable(),
  totalAmountSent: z.number().nullable(),
  orgId: z.string().nullable(),
  methodName: z.string().nullable(),
  descriptionDetail: z.string().nullable(),
  amountTaken: z.number().nullable(),
  balanceAmount: z.number().nullable(),
  postingDate: z.string().nullable(),
  userId: z.string().nullable(),
  agentName: z.string().nullable(),
  remittanceMethod: z.string().nullable(),
  isEditable: z.boolean().nullable(),
  paymentZip: z.string().nullable(),
  remittanceType: z.string().nullable(),
  isReprocess: z.boolean().nullable(),
  supplierOnboardStatus: z.string().nullable(),
  links: z
    .array(z.object({href: z.string(), rel: z.enum(["next", "previous"])}))
    .nullable()
    .optional(),
});

export type ImREmitPaymentType = z.infer<typeof imREmitPaymentSchema>;

const blockPaymentFormSchema = z.object({
  userId: z.string(),
  comments: z.string(),
  role: z.string(),
});
export type blockPaymentType = z.infer<typeof blockPaymentFormSchema>;
