import {useEffect, useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate, useParams} from "@tanstack/react-router";
import {format} from "date-fns";
import {toZonedTime} from "date-fns-tz";
import {
  ArrowRightIcon,
  CalendarIcon,
  CheckCircleIcon,
  PlusIcon,
  Trash2Icon,
} from "lucide-react";
import {useFieldArray, useForm, useFormContext} from "react-hook-form";
import type * as z from "zod";

import {Button, buttonVariants} from "@/components/ui/button";
import {Calendar} from "@/components/ui/calendar";
import {Card, CardContent} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Label} from "@/components/ui/label";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Switch} from "@/components/ui/switch";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {Textarea} from "@/components/ui/textarea";
import {Heading2, Heading3, Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {LoadingSkeletonCard} from "@/components/craft/loading-skeleton-card";

import {cn} from "@/lib/utils";
import {
  AccessPolicyWrapper,
  calculateUserRoles,
  KeycloakRoleEnum,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import type {InvoicesFormRow} from "@/modules/imremit";
import {
  editImREmitFn,
  EditImREmitFormSchema,
  useGetPaymentDetails,
  useGetSupplierDetails,
  usePaymentManagementGenInfo,
  usePaymentManagementInvoices,
} from "@/modules/imremit";

import {FallbackMessages, KeycloakRoles} from "@/utils/constants";

export function generateRandomUuid() {
  function generateSegment() {
    return Math.random().toString(16).substring(2, 6);
  }

  return (
    generateSegment() +
    "-" +
    generateSegment() +
    "-" +
    generateSegment() +
    "-" +
    generateSegment() +
    "-" +
    generateSegment() +
    generateSegment() +
    generateSegment()
  );
}

/**
 * EditImREmitFormProps Interface
 *
 * @interface EditImREmitFormProps
 * * @property {string} paymentId - Indicates paymentId.
 * @property {boolean} isMutating - Indicates whether the mutation is in progress.
 * @property {Function} onSubmit - The function to be called upon form submission.
 */
interface EditImREmitFormProps {
  paymentId: string;
  isMutating: boolean;
  onSubmit: (data: z.infer<typeof EditImREmitFormSchema>) => void;
}

/**
 * AddImREmitForm Component
 *
 * This component renders the AddImREmitForm form and handles its behavior.
 *
 * @param {EditImREmitFormProps} props - Properties passed to the component
 * @returns {JSX.Element} - Rendered component
 */

function EditPaymentDetailsForm({
  paymentId,
  isMutating,
  onSubmit,
}: EditImREmitFormProps) {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;

  //Loged User ID
  const changedBy = parsedUserInfo?.sub ? parsedUserInfo.sub : "";

  // strip parsedUserInfo?.sub of hyphens and alphabets and cut it to 15 characters
  // if it is not available, return "Not Applicable"
  // if it is available, return the value

  const userRoleDisplay = !parsedUserInfo
    ? FallbackMessages.NOT_APPLICABLE
    : calculateUserRoles(parsedUserInfo.realm_access?.roles);

  const form = useForm<z.infer<typeof EditImREmitFormSchema>>({
    defaultValues: {
      modifiedBy: changedBy,
      role: userRoleDisplay,
      singleTranExactAuth: false,
      endDate: new Date(),
      invoiceDetailRequests: [],
    },
    mode: "onChange",
    resolver: zodResolver(EditImREmitFormSchema),
  });

  const {
    formState: {errors},
    watch,
  } = form;

  const isSingleAuth = watch().singleTranExactAuth;

  console.log("errors", errors);

  return (
    <section>
      <div className="mb-4 flex flex-col-reverse gap-4 md:flex-row md:justify-between">
        <div className="flex gap-4">
          <Heading2>Edit Payment</Heading2>
        </div>

        <Link
          to="/app/imremit/payment-management"
          className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
        >
          Back to list
          <span className="sr-only">Back to list</span>
          <ArrowRightIcon className="size-4" />
        </Link>
      </div>

      <div className="mb-8 rounded-md border border-border bg-root p-4">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Payments id={paymentId} isMutating={isMutating} />
            <Invoices id={paymentId} isMutating={isMutating} />
            <VelocityControls
              id={paymentId}
              isMutating={isMutating}
              isSingleAuth={isSingleAuth}
            />
            <div className="flex w-full flex-row justify-end gap-2">
              <Button
                disabled={isMutating}
                aria-disabled={isMutating}
                className="gap-2 bg-success-foreground text-white hover:bg-success-foreground/80"
                type="submit"
              >
                <span className="sr-only">Submit form</span>
                Save <CheckCircleIcon className="size-4" />
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </section>
  );
}

export function EditPaymentDetailsPage() {
  const {paymentId} = useParams({
    from: "/app/imremit/payment-management/$paymentId/edit",
  });

  const editImREmitMutation = useEditImREmit(paymentId);

  return (
    <AccessPolicyWrapper
      policyActions={[
        KeycloakRoleEnum.UPDATE_PAYMENT_MANAGEMENT,
        KeycloakRoleEnum.UPDATE_PAYMENT_AUTHORIZATION,
      ]}
    >
      <EditPaymentDetailsForm
        isMutating={editImREmitMutation.isPending}
        paymentId={paymentId}
        onSubmit={editImREmitMutation.mutate}
      />
    </AccessPolicyWrapper>
  );
}

function useEditImREmit(paymentId: number | string) {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data: z.infer<typeof EditImREmitFormSchema>) => {
      // Perform the mutation using the addImREmitFn function
      return editImREmitFn(data, paymentId);
    },
    onSuccess: (response) => {
      // Handle successful mutation
      console.log("updateImREmitResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Payment updated successfully",
      });
      void navigate({to: "/app/imremit/payment-management", replace: true});
    },
    onError: (error) => {
      console.log("error", error);
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update payment",
      });
    },
  });
}

function Payments({id, isMutating}: {id: string; isMutating: boolean}) {
  const {data, isPending, error} = useGetPaymentDetails(id.toString());
  const paymentManagementInfo = data?.content;

  const {setValue, register, control, watch} = useFormContext();

  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  // Extract the `customer` field from the tokenParsed object
  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );

  const roleDisplay = !parsedUserInfo
    ? FallbackMessages.NOT_APPLICABLE
    : calculateUserRoles(parsedUserInfo.realm_access?.roles);

  const isEPayablesAdmin =
    roleDisplay && roleDisplay.includes(KeycloakRoles.EPayablesAdmin)
      ? true
      : false;

  useEffect(() => {
    if (paymentManagementInfo?.endDate) {
      setValue("endDate", new Date(paymentManagementInfo.endDate));
    }
    if (paymentManagementInfo?.totalAmountSent) {
      setValue("totalAmountSent", paymentManagementInfo.totalAmountSent);
    }
    if (paymentManagementInfo?.accountNumber) {
      setValue("accountNumber", paymentManagementInfo.accountNumber);
    }

    if (paymentManagementInfo?.singleTranExactAuth) {
      setValue(
        "singleTranExactAuth",
        paymentManagementInfo.singleTranExactAuth
      );
    }
  }, [paymentManagementInfo, setValue]);

  if (isPending) {
    return <LoadingSkeletonCard classNames="mt-4" lineCount={8} />;
  }

  if (error || !paymentManagementInfo) {
    return <div>Payment info could not be loaded</div>;
  }

  return (
    <>
      <Card className="mb-4 mt-4">
        <CardContent>
          <Heading3 className="mb-4 mt-4">Payment Details</Heading3>
          <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
            <FormField
              name="totalAmountSent"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="totalAmountSent">
                    Payable Total (USD):
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      step="0.01" // 🟢 Allows increments of 0.01
                      disabled={isMutating || isEPayablesAdmin}
                      aria-disabled={isMutating || isEPayablesAdmin}
                      placeholder="Enter the payable total..."
                      {...register("totalAmountSent", {
                        setValueAs: (value) => {
                          // 🟢 If the value is an empty string, return undefined
                          if (value === "") {
                            return undefined;
                          }
                          // 🟢 Parse the value as a float and round it to two decimal places
                          const parsedValue = parseFloat(value as string);
                          return isNaN(parsedValue)
                            ? undefined
                            : Math.round(parsedValue * 100) / 100;
                        },
                      })}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="mt-2 grid w-full items-center gap-1.5">
              <Label htmlFor="supplierName">Payee Name:</Label>
              <Input
                type="text"
                name="supplierName"
                id="supplierName"
                value={
                  paymentManagementInfo.facilityName
                    ? paymentManagementInfo.facilityName
                    : ""
                }
                disabled
              />
            </div>
            <div className="mt-2 grid w-full items-center gap-1.5">
              <Label htmlFor="supplierName">Supplier Name:</Label>
              <Input
                type="text"
                name="supplierName"
                id="supplierName"
                value={
                  paymentManagementInfo.supplierName
                    ? paymentManagementInfo.supplierName
                    : ""
                }
                disabled
              />
            </div>
            <div className="mt-2 grid w-full items-center gap-1.5">
              <Label htmlFor="supplierId">Supplier ID:</Label>
              <Input
                type="text"
                name="supplierId"
                id="supplierId"
                value={
                  paymentManagementInfo.supplierId
                    ? paymentManagementInfo.supplierId
                    : ""
                }
                disabled
              />
            </div>
            <div className="mt-2 grid w-full items-center gap-1.5">
              <Label htmlFor="initiatedDate">Begin Date:</Label>
              <Input
                type="text"
                name="initiatedDate"
                id="initiatedDate"
                value={
                  paymentManagementInfo.initiatedDate
                    ? paymentManagementInfo.initiatedDate.toString()
                    : ""
                }
                disabled
              />
            </div>
            <FormField
              control={control}
              name="endDate"
              render={({field}) => (
                <FormItem className="mt-3 flex flex-col">
                  <FormLabel showMandatoryAsterisk>End Date:</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="input"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          className={cn(
                            "w-full pl-3 text-left font-normal",
                            !field.value && "text-accent-foreground"
                          )}
                        >
                          {field.value ? (
                            format(
                              toZonedTime(
                                new Date(field.value as string),
                                "UTC"
                              ),
                              "PPP"
                            )
                          ) : (
                            <span>Pick a date</span>
                          )}
                          <CalendarIcon className="ml-auto size-4 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent
                      className="max-h-[var(--radix-popover-content-available-height)] w-[var(--radix-popover-trigger-width)] p-0"
                      align="start"
                    >
                      <Calendar
                        mode="single"
                        selected={field.value as Date}
                        disabled={(date) => date < new Date("1900-01-01")}
                        initialFocus
                        onSelect={field.onChange}
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mt-2 grid w-full items-center gap-1.5">
              <Label htmlFor="initiatedDate">Create Date:</Label>
              <Input
                type="text"
                name="initiatedDate"
                id="initiatedDate"
                value={
                  paymentManagementInfo.initiatedDate
                    ? paymentManagementInfo.initiatedDate.toString()
                    : ""
                }
                disabled
              />
            </div>
            <div className="mt-2 grid w-full items-center gap-1.5">
              <Label htmlFor="methodName">Payment Group:</Label>
              <Input
                type="text"
                name="methodName"
                id="methodName"
                value={
                  paymentManagementInfo.methodName
                    ? paymentManagementInfo.methodName
                    : ""
                }
                disabled
              />
            </div>

            <FormField
              control={control}
              name="singleTranExactAuth"
              render={({field}) => (
                <FormItem>
                  <FormLabel className="mr-2" htmlFor="singleTranExactAuth">
                    Authorization Type:
                  </FormLabel>
                  <FormControl>
                    <AccessPolicyWrapper
                      policyActions={[
                        KeycloakRoleEnum.UPDATE_PAYMENT_AUTHORIZATION,
                      ]}
                      unauthorizedFallback={
                        <Switch
                          checked={field.value as boolean}
                          disabled={true}
                          aria-disabled={true}
                        />
                      }
                    >
                      <Switch
                        checked={field.value as boolean}
                        disabled={isMutating}
                        aria-disabled={isMutating}
                        onCheckedChange={field.onChange}
                        {...register("singleTranExactAuth")}
                      />
                    </AccessPolicyWrapper>
                  </FormControl>
                  <FormDescription>
                    <Paragraph>
                      {watch("singleTranExactAuth") ? "Single" : "Multiple"}
                    </Paragraph>
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="mt-2 grid w-full items-center gap-1.5">
              <Label htmlFor="cardNumber">Card Number:</Label>
              <Input
                type="text"
                name="cardNumber"
                id="cardNumber"
                value={
                  paymentManagementInfo.cardNumber
                    ? paymentManagementInfo.cardNumber
                    : ""
                }
                disabled
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  );
}

function Invoices({id, isMutating}: {id: string; isMutating: boolean}) {
  const {data, isPending} = usePaymentManagementInvoices(id.toString());
  const paymentManagementInvoicesInfo = data?.content;

  const {
    setValue,
    register,
    control,
    watch,
    formState: {errors},
  } = useFormContext();
  const {fields, append, remove} = useFieldArray({
    control,
    name: "invoiceDetailRequests",
  });

  const [invoicesCount, setInvoicesCount] = useState(0);

  useEffect(() => {
    if (paymentManagementInvoicesInfo) {
      const formattedInvoices = paymentManagementInvoicesInfo.map(
        (invoice) => ({
          ...invoice,
          invoiceId: invoice.invoiceId.toString(), // Convert invoiceId to string
          invoiceDate: new Date(invoice.invoiceDate), // Convert invoiceDate to Date
        })
      );
      setInvoicesCount(formattedInvoices.length);
      setValue("invoiceDetailRequests", formattedInvoices);
    }
  }, [paymentManagementInvoicesInfo, setInvoicesCount, setValue]);

  if (isPending) {
    return <LoadingSkeletonCard classNames="mt-4" lineCount={8} />;
  }
  // if (error || !paymentManagementInvoicesInfo) {

  //   toast({
  //     variant: "destructive",
  //     title: "No Data Found",
  //     duration: 99999999999,
  //     description: 'Invoices info could not be loaded',
  //   });
  // }

  function handleInvoiceTotalCalculation(index: number) {
    const amountPaid = parseFloat(
      watch(
        `invoiceDetailRequests.${index.toString()}.invoiceAmountPaid`
      ) as string
    );
    const discountAmount = parseFloat(
      watch(
        `invoiceDetailRequests.${index.toString()}.discountAmount`
      ) as string
    );
    const adjustmentAmount = parseFloat(
      watch(
        `invoiceDetailRequests.${index.toString()}.adjustmentAmount`
      ) as string
    );
    const totalAmount = amountPaid + discountAmount + adjustmentAmount;
    setValue(
      `invoiceDetailRequests.${index.toString()}.invoiceTotalAmount`,
      totalAmount
    );
    const invoicesData = watch("invoiceDetailRequests") as InvoicesFormRow[];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (invoicesData) {
      const invoicesTotalAmountSentValue: number = invoicesData.reduce(
        (acc, curr) => acc + curr.invoiceAmountPaid,
        0
      );
      // correct invoicesTotalAmountSentValue to 2 decimal places
      setValue("totalAmountSent", invoicesTotalAmountSentValue);
    }
  }

  return (
    <>
      <Card className="mb-4 mt-4">
        <CardContent>
          <Heading3 className="mb-4 mt-4">Invoices</Heading3>
          <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-1 xl:grid-cols-1">
            <Table className="mt-5">
              <TableCaption>
                {fields.length == 0 && <p> No Data.</p>}
              </TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead className="relative" showMandatoryAsterisk>
                    Invoice Number
                  </TableHead>
                  <TableHead showMandatoryAsterisk>Invoice Date</TableHead>
                  <TableHead className="relative" showMandatoryAsterisk>
                    Amount Paid (USD)
                  </TableHead>
                  <TableHead className="relative">PO Number</TableHead>
                  <TableHead className="relative" showMandatoryAsterisk>
                    Invoice Amount (USD)
                  </TableHead>
                  <TableHead className="relative">Discount Amount</TableHead>
                  <TableHead className="relative">Adjustment Amount</TableHead>
                  <TableHead className="relative" showMandatoryAsterisk>
                    Comments{" "}
                  </TableHead>
                  <TableHead className="text-right">Action</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {fields.map((field, index) => (
                  <TableRow key={field.id}>
                    <TableCell className="w-80 font-medium">
                      <FormField
                        control={control}
                        name={`invoiceDetailRequests.${index.toString()}.invoiceNumber`}
                        render={() => (
                          <FormItem className="flex flex-col ">
                            <FormControl>
                              <Input
                                disabled={isMutating || index < invoicesCount}
                                aria-disabled={isMutating}
                                {...register(
                                  `invoiceDetailRequests.${index.toString()}.invoiceNumber`,
                                  {
                                    required: true,
                                  }
                                )}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </TableCell>

                    <TableCell className="w-[32rem] font-medium">
                      <FormField
                        control={control}
                        name={`invoiceDetailRequests.${index.toString()}.invoiceDate`}
                        render={({field}) => (
                          <FormItem className="flex flex-col ">
                            <Popover>
                              <PopoverTrigger asChild>
                                <FormControl>
                                  <Button
                                    variant="input"
                                    aria-disabled={isMutating}
                                    disabled={
                                      isMutating || index < invoicesCount
                                    }
                                    className={cn(
                                      "w-full pl-3 text-left font-normal",
                                      !field.value && "text-accent-foreground"
                                    )}
                                  >
                                    {field.value ? (
                                      format(
                                        new Date(field.value as string),
                                        "PPP"
                                      ).toString()
                                    ) : (
                                      <span>Pick a date</span>
                                    )}
                                    <CalendarIcon className="ml-auto size-4 opacity-50" />
                                  </Button>
                                </FormControl>
                              </PopoverTrigger>
                              <PopoverContent
                                className="w-auto p-0"
                                align="start"
                              >
                                <Calendar
                                  mode="single"
                                  selected={field.value as Date}
                                  disabled={(date) =>
                                    date > new Date() ||
                                    date < new Date("1900-01-01")
                                  }
                                  initialFocus
                                  onSelect={field.onChange}
                                />
                              </PopoverContent>
                            </Popover>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </TableCell>

                    <TableCell className="w-80 font-medium">
                      <FormField
                        control={control}
                        name={`invoiceDetailRequests.${index.toString()}.invoiceAmountPaid`}
                        render={() => (
                          <FormItem className="flex flex-col ">
                            <FormControl>
                              <Input
                                disabled={isMutating || index < invoicesCount}
                                type="number"
                                step="0.01"
                                {...register(
                                  `invoiceDetailRequests.${index.toString()}.invoiceAmountPaid`,
                                  {
                                    required: true,
                                    setValueAs: (v) =>
                                      v === ""
                                        ? undefined
                                        : parseFloat(v as string),
                                  }
                                )}
                                onMouseOut={() => {
                                  handleInvoiceTotalCalculation(index);
                                }}
                                onFocus={() => {
                                  handleInvoiceTotalCalculation(index);
                                }}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </TableCell>

                    <TableCell className="w-80 font-medium">
                      <FormField
                        control={control}
                        name={`invoiceDetailRequests.${index.toString()}.poNumber`}
                        render={() => (
                          <FormItem className="flex flex-col ">
                            <FormControl>
                              <Input
                                disabled={isMutating || index < invoicesCount}
                                aria-disabled={isMutating}
                                {...register(
                                  `invoiceDetailRequests.${index.toString()}.poNumber`,
                                  {
                                    required: true,
                                  }
                                )}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </TableCell>

                    <TableCell className="w-80 font-medium">
                      <FormField
                        control={control}
                        name={`invoiceDetailRequests.${index.toString()}.invoiceTotalAmount`}
                        render={() => (
                          <FormItem className="flex flex-col ">
                            <FormControl>
                              <Input
                                type="number"
                                step="0.01"
                                disabled={isMutating || index < invoicesCount}
                                aria-disabled={isMutating}
                                {...register(
                                  `invoiceDetailRequests.${index.toString()}.invoiceTotalAmount`,
                                  {
                                    required: true,
                                    setValueAs: (v) =>
                                      v === ""
                                        ? undefined
                                        : parseFloat(v as string),
                                  }
                                )}
                                onMouseOut={() => {
                                  handleInvoiceTotalCalculation(index);
                                }}
                                onFocus={() => {
                                  handleInvoiceTotalCalculation(index);
                                }}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </TableCell>

                    <TableCell className="w-80 font-medium">
                      <FormField
                        control={control}
                        name={`invoiceDetailRequests.${index.toString()}.discountAmount`}
                        render={() => (
                          <FormItem className="flex flex-col ">
                            <FormControl>
                              <Input
                                type="number"
                                step="0.01"
                                disabled={isMutating || index < invoicesCount}
                                aria-disabled={isMutating}
                                {...register(
                                  `invoiceDetailRequests.${index.toString()}.discountAmount`,
                                  {
                                    setValueAs: (v) =>
                                      v === ""
                                        ? undefined
                                        : parseFloat(v as string),
                                    required: true,
                                  }
                                )}
                                onMouseOut={() => {
                                  handleInvoiceTotalCalculation(index);
                                }}
                                onFocus={() => {
                                  handleInvoiceTotalCalculation(index);
                                }}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </TableCell>

                    <TableCell className="w-80 font-medium">
                      <FormField
                        control={control}
                        name={`invoiceDetailRequests.${index.toString()}.adjustmentAmount`}
                        render={() => (
                          <FormItem className="flex flex-col ">
                            <FormControl>
                              <Input
                                type="number"
                                step="0.01"
                                disabled={isMutating || index < invoicesCount}
                                aria-disabled={isMutating}
                                {...register(
                                  `invoiceDetailRequests.${index.toString()}.adjustmentAmount`,

                                  {
                                    setValueAs: (v) =>
                                      v === ""
                                        ? undefined
                                        : parseFloat(v as string),
                                    required: true,
                                  }
                                )}
                                onMouseOut={() => {
                                  handleInvoiceTotalCalculation(index);
                                }}
                                onFocus={() => {
                                  handleInvoiceTotalCalculation(index);
                                }}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </TableCell>

                    <TableCell className="w-80 font-medium">
                      <FormField
                        control={control}
                        name={`invoiceDetailRequests.${index.toString()}.comments`}
                        render={() => (
                          <FormItem>
                            <FormControl>
                              <Textarea
                                disabled={isMutating || index < invoicesCount}
                                aria-disabled={isMutating}
                                {...register(
                                  `invoiceDetailRequests.${index.toString()}.comments`,
                                  {
                                    required: true,
                                  }
                                )}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </TableCell>

                    <TableCell className="text-right">
                      <Button
                        disabled={isMutating || index < invoicesCount}
                        className="flex items-center gap-2 px-3"
                        variant="outline"
                        size="xs"
                        onClick={() => {
                          remove(index);
                          handleInvoiceTotalCalculation(index);
                        }}
                      >
                        <Trash2Icon className="size-4" />
                        <span className="sr-only">Delete</span>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          {errors.invoiceDetailRequests && (
            <div className="text-red-500">Please add at least one invoice</div>
          )}
          <div className="mb-5 mt-5 flex justify-end">
            <Button
              type="button"
              className="col-span-1 col-end-8 mt-5"
              disabled={isMutating}
              onClick={() => {
                append({
                  // invoiceId: generateRandomUuid(),
                  invoiceNumber: "",
                  invoiceDate: new Date(),
                  invoiceAmountPaid: 0,
                  poNumber: "",
                  invoiceTotalAmount: 0,
                  discountAmount: 0,
                  adjustmentAmount: 0,
                  comments: "",
                });
              }}
            >
              <PlusIcon className="mr-2 size-4" /> Add
            </Button>
          </div>
        </CardContent>
      </Card>
    </>
  );
}

interface VelocityControlsProps {
  id: string;
  isMutating: boolean;
  isSingleAuth?: boolean;
}

function VelocityControls({
  id,
  isMutating,
  isSingleAuth,
}: VelocityControlsProps) {
  const {data, isPending, error} = usePaymentManagementGenInfo(id);
  const paymentManagementGenInfo = data?.content;
  const {setValue, register} = useFormContext();

  const {data: paymentData} = useGetPaymentDetails(id.toString());
  const paymentManagementInfo = paymentData?.content;
  const {searchSupplierIDData} = useGetSupplierDetails(
    Number(paymentManagementInfo?.supplierId)
  );

  const cardOnFileFlag = searchSupplierIDData?.supplierCardOnFileFlag;

  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  // Extract the `customer` field from the tokenParsed object
  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );

  const roleDisplay = !parsedUserInfo
    ? FallbackMessages.NOT_APPLICABLE
    : calculateUserRoles(parsedUserInfo.realm_access?.roles);

  const isEPayablesAdmin =
    roleDisplay && roleDisplay.includes(KeycloakRoles.EPayablesAdmin)
      ? true
      : false;

  useEffect(() => {
    if (paymentManagementGenInfo?.singleTransactionLimitAmount) {
      setValue(
        "singleTransactionLimitAmount",
        paymentManagementGenInfo.singleTransactionLimitAmount
      );
    }

    if (paymentManagementGenInfo?.dailyTransactionLimitAmount) {
      setValue(
        "dailyTransactionLimitAmount",
        paymentManagementGenInfo.dailyTransactionLimitAmount
      );
    }

    if (!isSingleAuth) {
      if (
        paymentManagementGenInfo?.dailyTransactionLimitNumber &&
        paymentManagementGenInfo.dailyTransactionLimitNumber !== 1
      ) {
        setValue(
          "dailyTransactionLimitNumber",
          paymentManagementGenInfo.dailyTransactionLimitNumber
        );
      } else {
        if (!cardOnFileFlag) {
          setValue("dailyTransactionLimitNumber", 1000);
        } else {
          setValue("dailyTransactionLimitNumber", 1);
        }
      }
    } else {
      setValue("dailyTransactionLimitNumber", 1);
    }

    if (!isSingleAuth) {
      if (
        paymentManagementGenInfo?.monthlyTransactionLimitNumber &&
        paymentManagementGenInfo.monthlyTransactionLimitNumber !== 1
      ) {
        setValue(
          "monthlyTransactionLimitNumber",
          paymentManagementGenInfo.monthlyTransactionLimitNumber
        );
      } else {
        if (!cardOnFileFlag) {
          setValue("monthlyTransactionLimitNumber", 1000);
        } else {
          setValue("monthlyTransactionLimitNumber", 1);
        }
      }
    } else {
      setValue("monthlyTransactionLimitNumber", 1);
    }

    if (paymentManagementGenInfo?.paymentRequestId) {
      setValue("paymentRequestId", paymentManagementGenInfo.paymentRequestId);
    }
    if (paymentManagementGenInfo?.udf1) {
      setValue("udf1", paymentManagementGenInfo.udf1);
    }
    if (paymentManagementGenInfo?.udf2) {
      setValue("udf2", paymentManagementGenInfo.udf2);
    }
    if (paymentManagementGenInfo?.udf3) {
      setValue("udf3", paymentManagementGenInfo.udf3);
    }
    if (paymentManagementGenInfo?.payableDescription) {
      setValue(
        "merchantCategoryCode",
        paymentManagementGenInfo.payableDescription
      );
    }
  }, [paymentManagementGenInfo, setValue, isSingleAuth, cardOnFileFlag]);

  if (isPending) {
    return <LoadingSkeletonCard classNames="mt-4" lineCount={8} />;
  }

  if (error || !paymentManagementGenInfo) {
    return <div>General info could not be loaded</div>;
  }

  return (
    <>
      <Card className="mb-4 mt-4">
        <CardContent>
          <Heading3 className="mb-4 mt-4">Velocity Controls</Heading3>
          <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
            <FormField
              name="singleTransactionLimitAmount"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="singleTransactionLimitAmount">
                    Single Transaction Limit Amount (USD):
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      step="0.01"
                      disabled={isMutating || isEPayablesAdmin}
                      aria-disabled={isMutating || isEPayablesAdmin}
                      placeholder="Enter the single transaction limit amount..."
                      {...register("singleTransactionLimitAmount", {
                        setValueAs: (v) =>
                          v === "" ? undefined : parseFloat(v as string),
                      })}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="dailyTransactionLimitAmount"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="dailyTransactionLimitAmount">
                    Daily Transaction Limit Amount (USD):
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      step="0.01"
                      disabled={isMutating || isEPayablesAdmin}
                      aria-disabled={isMutating || isEPayablesAdmin}
                      placeholder="Enter the daily transaction limit amount..."
                      {...register("dailyTransactionLimitAmount", {
                        setValueAs: (v) =>
                          v === "" ? undefined : parseFloat(v as string),
                      })}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="mt-2 grid w-full items-center gap-1.5">
              <Label htmlFor="dailyTransactionLimitNumber">
                Daily Transaction Limit Number:
              </Label>
              <Input
                type="number"
                disabled={isSingleAuth || isMutating || isEPayablesAdmin}
                aria-disabled={isSingleAuth || isMutating || isEPayablesAdmin}
                placeholder="Enter the daily transaction limit number..."
                {...register("dailyTransactionLimitNumber", {
                  setValueAs: (v) =>
                    v === "" ? undefined : parseInt(v as string, 10),
                })}
              />
            </div>

            <div className="mt-2 grid w-full items-center gap-1.5">
              <Label htmlFor="monthlyTransactionLimitNumber">
                Monthly Transaction Limit Number:
              </Label>
              <Input
                type="number"
                disabled={isSingleAuth || isMutating || isEPayablesAdmin}
                aria-disabled={isSingleAuth || isMutating || isEPayablesAdmin}
                placeholder="Enter the daily transaction limit number..."
                {...register("monthlyTransactionLimitNumber", {
                  setValueAs: (v) =>
                    v === "" ? undefined : parseInt(v as string, 10),
                })}
              />
            </div>
          </div>
          <Heading3 className="mb-4">Custom Fields</Heading3>
          <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
            <FormField
              name="paymentRequestId"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="paymentRequestId">
                    Account Number:
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      disabled={isMutating || isEPayablesAdmin}
                      aria-disabled={isMutating || isEPayablesAdmin}
                      placeholder="Enter the account number..."
                      {...register("paymentRequestId")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="udf1"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="udf1">UDF1:</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      disabled={isMutating || isEPayablesAdmin}
                      aria-disabled={isMutating || isEPayablesAdmin}
                      placeholder="Enter udf1..."
                      {...register("udf1")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="udf2"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="udf2">UDF2:</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      disabled={isMutating || isEPayablesAdmin}
                      aria-disabled={isMutating || isEPayablesAdmin}
                      placeholder="Enter udf2..."
                      {...register("udf2")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              name="udf3"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="udf3">UDF3:</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      disabled={isMutating || isEPayablesAdmin}
                      aria-disabled={isMutating || isEPayablesAdmin}
                      placeholder="Enter udf3..."
                      {...register("udf3")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <Heading3 className="mb-4">Notes</Heading3>
          <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
            <FormField
              name="notes"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="notes">Notes:</FormLabel>
                  <FormControl>
                    <Textarea
                      disabled={isMutating || isEPayablesAdmin}
                      aria-disabled={isMutating || isEPayablesAdmin}
                      placeholder="Enter notes..."
                      {...register("notes")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </CardContent>
      </Card>
    </>
  );
}
