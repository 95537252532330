import {useQuery} from "@tanstack/react-query";
import {z} from "zod";

import {keycloakKyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {keycloakRealmRolesQueryKeys} from "@/modules/admin";

const keycloakRealmRolesSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string(),
  composite: z.boolean(),
  clientRole: z.boolean(),
  containerId: z.string(),
});

type keycloakRealmRolesType = z.infer<typeof keycloakRealmRolesSchema>;

// Defining a type based on the function `getAllRealmRoles`
type QueryFnType = typeof getAllRealmRoles;

// Function to get all realm user Roles
export function getAllRealmRoles() {
  const realm =
    (import.meta.env.VITE_KEYCLOAK_REALM as string | undefined) ?? "dev";
  return keycloakKyApiFn<keycloakRealmRolesType[]>(
    `admin/realms/${realm}/roles`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useAllRealmRoles(config?: QueryConfig<QueryFnType>) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: keycloakRealmRolesQueryKeys.all,
    queryFn: () => getAllRealmRoles(),
  });
}
