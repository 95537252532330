// Constants for query keys related to suppliers data management 🧑‍💼
export const supplierQueryKeys = {
  // Key for retrieving the complete list of suppliers, serving as a foundational query key
  allSuppliers: ["suppliers"] as const, // 📋

  // Function to generate query keys for suppliers search operations, extending 'allSuppliers' with 'searchQuery'
  searchQuery: () =>
    [...supplierQueryKeys.allSuppliers, "searchQuery"] as const, // 🔍

  // Function to create query keys for fetching suppliers based on specific search terms, utilizing 'searchQuery' for dynamic searches
  bySearch: (search?: string) =>
    [...supplierQueryKeys.searchQuery(), search] as const, // 🕵️

  // Function to generate query keys for accessing detailed suppliers information, appending 'detailsById' to the 'allSuppliers' key
  detailsById: () =>
    [...supplierQueryKeys.allSuppliers, "detailsById"] as const, // 📄

  // Function to create query keys for retrieving suppliers details by an external identifier, extending 'detailsById'
  byExternalId: (externalId: string) =>
    [...supplierQueryKeys.detailsById(), externalId] as const, // 🔖
};

export const supplierByExternalIdFuzzyQueryKeys = {
  // A list that stores the key for accessing all suppliers
  all: ["suppliersByCustomerFuzzy"] as const,

  // A function that returns a list of keys to access suppliers based on a search
  searches: (externalId?: string) =>
    [...supplierByExternalIdFuzzyQueryKeys.all, "search", externalId] as const,

  // A function that returns a list of keys to access suppliers by a specific search criteria
  bySearch: (externalId?: string, searchQueryString?: string) =>
    [
      ...supplierByExternalIdFuzzyQueryKeys.searches(externalId),
      searchQueryString,
    ] as const,

  // A function that returns a list of keys to access detailed information about suppliers
  details: () =>
    [...supplierByExternalIdFuzzyQueryKeys.all, "details"] as const,

  // A function that returns a list of keys to access suppliers by a specific ID with details
  byId: (externalId?: string) =>
    [...supplierByExternalIdFuzzyQueryKeys.details(), externalId] as const,

  byFilter: (externalId?: string, filter?: string) =>
    [
      ...supplierByExternalIdFuzzyQueryKeys.byId(externalId),
      "filter",
      filter,
    ] as const,
};
