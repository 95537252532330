import {createRoute} from "@tanstack/react-router";

import {RoutesDirectory} from "@/lib/routes/directory";
import {
  StatementReconiliationResultsPage,
  StatementReconiliationSummaryPage,
  statementReconRootRoute,
  StatementReconStatementSearchPage,
} from "@/modules/statement-recon";

export const statementReconResultsRoute = createRoute({
  path: RoutesDirectory.FILE_HISTORY,
  component: StatementReconStatementSearchPage,
  getParentRoute: () => statementReconRootRoute,
});

export const statementReconSummaryRoute = createRoute({
  path: `${RoutesDirectory.FILE_HISTORY}/$fileId${RoutesDirectory.SUMMARY}`,
  component: StatementReconiliationSummaryPage,
  getParentRoute: () => statementReconRootRoute,
});

export const statementReconResultRoute = createRoute({
  path: `${RoutesDirectory.FILE_HISTORY}/$fileId${RoutesDirectory.RESULT}`,
  component: StatementReconiliationResultsPage,
  getParentRoute: () => statementReconRootRoute,
});
