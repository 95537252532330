import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTrigger,
} from "@/components/ui/dialog";

interface ExpandHelpItemProps {
  imagePath: string;
  altText: string;
  width: string;
}
export function ExpandHelpItem({
  imagePath,
  altText,
  width,
}: ExpandHelpItemProps) {
  return (
    <Dialog>
      <DialogTrigger>
        <img
          src={imagePath}
          alt={altText}
          className={`p-2 transition duration-300 ease-in-out hover:opacity-75 ${width}`}
        />
      </DialogTrigger>
      <DialogContent className="flex h-auto w-full max-w-screen-2xl items-center justify-center pt-2">
        <DialogDescription>
          <img src={imagePath} alt={altText} />
        </DialogDescription>
      </DialogContent>
    </Dialog>
  );
}
