// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type CustomerModuleManagementAdminTableColumnsType} from "@/modules/admin";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface CustomerModuleManagementAdminTableColumnsStoreState {
  storeCustomerModuleManagementAdminTableColumns: CustomerModuleManagementAdminTableColumnsType | null;
  setStoreCustomerModuleManagementAdminTableColumns: (
    storeCustomerModuleManagementAdminTableColumns: CustomerModuleManagementAdminTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for CustomerModuleManagementAdmin table columns
export const useCustomerModuleManagementAdminTableColumnsStore = create(
  persist<CustomerModuleManagementAdminTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeCustomerModuleManagementAdminTableColumns: null,

      // Getter function to retrieve the current state
      getStoreCustomerModuleManagementAdminTableColumns: () =>
        get().storeCustomerModuleManagementAdminTableColumns,

      // Setter function to update the state
      setStoreCustomerModuleManagementAdminTableColumns: (
        storeCustomerModuleManagementAdminTableColumns
      ) => {
        set(() => ({storeCustomerModuleManagementAdminTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "admin-customer-module-management-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useCustomerModuleManagementAdminTableColumns = () =>
  useCustomerModuleManagementAdminTableColumnsStore((state) => {
    return {
      storeCustomerModuleManagementAdminTableColumns:
        state.storeCustomerModuleManagementAdminTableColumns,
    };
  });
