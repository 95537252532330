import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  proxyPayDashboardQueryKeys,
  type ProxyPayDashboardSchema,
} from "@/modules/imremit";

// Defining a type based on the function `searchProxyPayDashboard`
type QueryFnType = typeof searchProxyPayDashboard;

// Function to search proxy payment dashbaord
export function searchProxyPayDashboard(externalId: string) {
  // Fetch proxy payment from API using ky
  return kyApiFn<ProxyPayDashboardSchema>(
    `api/e-payable/management/${externalId}/proxy-pay-dashboard`,
    "get"
  );
  // TODO: validate data
}

// Custom hook to fetch and cache proxy payment dashbaord data using react-query
export function useSearchProxypayDashboard(
  externalId: string, // The Participant ID
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: proxyPayDashboardQueryKeys.byExternalId(externalId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => searchProxyPayDashboard(externalId), // Setting the function to be used for fetching data
    enabled: !!externalId,
    // TODO: add onSuccess and onError handlers
  });
}
