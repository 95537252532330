import {z} from "zod";

export const dupesInitialInvoiceComparisonsSchema = z.object({
  id: z.string(),
  base_invoice_id: z.string(),
  dup_invoice_id: z.string(),
  invoice_no_pos_width_match: z.number(),
  invoice_amt_pos_width_match: z.number(),
  invoice_no_char_match: z.number(),
  invoice_date_match: z.boolean(),
  po_number_match: z.boolean(),
  vendor_id_match: z.boolean(),
  invoice_no_char_diff: z.string(),
  invoice_no_numeric_match: z.boolean(),
  invoice_no_numeric_diff: z.string(),
  facility_id_match: z.boolean(),
  credit_invoice_match: z.boolean(),
  payment_match: z.boolean(),
  payment_found: z.boolean(),
  comparison_type: z.string(),
  different_vendor_type: z.string().nullable(),
  one_time_dup: z.boolean(),
  created_date: z.string(),
  run_id: z.string(),
  snowball_flag: z.boolean(),
  base_invoice_id_history: z.string().nullable(),
  erp_unique_id: z.string().nullable(),
  dup_voucher: z.string().nullable(),
  dup_payment: z.string().nullable(),
});

export type DupesInitialInvoiceComparisonsType = z.infer<
  typeof dupesInitialInvoiceComparisonsSchema
>;

export type InitialInvoiceComparisonsType = z.infer<
  typeof dupesInitialInvoiceComparisonsSchema
>;

export enum DupesComparisonTypeEnum {
  LIKELY = "1",
  MAYBE = "2",
  DIFFERENT_AMOUNTS_AND_DATES = "3",
  CREDIT_PAIR = "4",
  DIFFERENT_SUPPLIER_LIKELY = "5",
  DIFFERENT_SUPPLIER_MAYBE = "6",
  DIFFERENT_DATES = "7",
  DIFFERENT_SUPPLIERS_AND_DATES = "8",
  DIFFERENT_AMOUNTS = "9",
}

export const initialInvoiceComparisonCountsSchema = z.object({
  likely: z.number(),
  maybe: z.number(),
  differentSupplierLikely: z.number(),
  differentSupplierMaybe: z.number(),
  differentAmountAndDifferentDate: z.number(),
  differentDates: z.number(),
  differentSuppliersAndDates: z.number(),
  differentAmounts: z.number(),
  creditPair: z.number(),
});

export type DupesComparisonCountsType = z.infer<
  typeof initialInvoiceComparisonCountsSchema
>;
