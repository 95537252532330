import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import type {RunTypeType} from "@/lib/stores/dupes-run-type-store";
import {
  dupesTrackerKeys,
  type DupeTrackerResponseType,
} from "@/modules/duplicate-payments";

//Defining a type based on the function `getDupesTracker`
type QueryFnType = typeof getDupesTracker;

// Defining a function to fetch data from API
export function getDupesTracker(
  externalId?: string,
  comparisonType?: string,
  dupesRunType?: RunTypeType,
  limitToLast90Days?: boolean
) {
  const endPointType =
    dupesRunType === "OneTime"
      ? "one-time"
      : dupesRunType === "Initial"
      ? "initial"
      : "daily";

  // Fetch data from API using ky
  return kyApiFn<DupeTrackerResponseType>(
    `duplicate-payments/v1/tracker/${endPointType}/${
      externalId || ""
    }/comparison-type/${comparisonType || ""}?limit_to_last_90_days=${
      limitToLast90Days ? "true" : "false"
    }`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useDupesTracker(
  externalId?: string,
  comparisonType?: string,
  dupesRunType?: RunTypeType,
  limitToLast90Days?: boolean,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: dupesTrackerKeys.byExternalIdAndComparisonType(
      externalId,
      comparisonType,
      dupesRunType,
      limitToLast90Days
    ),
    queryFn: () =>
      getDupesTracker(
        externalId,
        comparisonType,
        dupesRunType,
        limitToLast90Days
      ),
    enabled: !!externalId && !!comparisonType,
  });
}
