import {useEffect, useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate, useParams} from "@tanstack/react-router";
import {ArrowRightIcon, UserCog2Icon, XCircleIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Badge} from "@/components/ui/badge";
import {Button, buttonVariants} from "@/components/ui/button";
import {Card, CardHeader} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Separator} from "@/components/ui/separator";
import {Switch} from "@/components/ui/switch";
import {Heading2, Heading4, Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import type {MultiSelectDatum} from "@/components/craft/fancy-multi-select";
import {LoadingSkeletonCard} from "@/components/craft/loading-skeleton-card";

import {cn} from "@/lib/utils";
import {
  BuyerAliasesField,
  editCustomerFn,
  GenericCustomerForm,
  UpdateCustomerFormSchema,
} from "@/modules/admin";
import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {useGetCustomerById} from "@/modules/customers";

import {KeycloakRoles, ModuleNames} from "@/utils/constants";

/**
 * Custom hook to fetch customer details by customer ID.
 *
 * This hook uses `useGetCustomerById` to retrieve customer details and memoizes the relevant data.
 * It provides an easy-to-use interface for components that need to access customer data.
 *
 * @param {number} externalId - The unique identifier for the customer.
 * @returns {Object} An object containing:
 *   - searchCustomerIDData: The customer's detailed data, or null if not available.
 *   - customerDetailsIsPending: Boolean indicating if the customer details request is pending.
 *   - customerDetailsAreFetched: Boolean indicating if the customer details have been fetched.
 *   - isError: Boolean indicating if there was an error in fetching data.
 *   - error: The error object, if an error occurred.
 */
export function useGetCustomerDetails(externalId: string) {
  // Utilize the useGetCustomerById hook to fetch customer data.
  const {
    data,
    isPending: customerDetailsIsPending,
    isFetched: customerDetailsAreFetched,
    isError,
    error,
  } = useGetCustomerById(externalId);

  // Memoize the customer's detailed data for optimization.
  // This ensures that the data is recalculated only when 'data' changes.
  const searchCustomerIDData = useMemo(() => {
    // Extract the 'content' field from the data, default to null if not available.
    return data?.content || null;
  }, [data]);

  // Return the structured object containing the customer data and status flags.
  return {
    searchCustomerIDData,
    customerDetailsIsPending,
    customerDetailsAreFetched,
    isError,
    error,
  };
}

/**
 * EditCustomerFormProps Interface
 *
 * @interface EditCustomerFormProps
 * @property {boolean} isMutating - Indicates whether the mutation is in progress.
 * @property {Function} onSubmit - The function to be called upon form submission.
 */
interface EditCustomerFormProps {
  externalId: string;
  isMutating: boolean;
  onSubmit: (data: z.infer<typeof UpdateCustomerFormSchema>) => void;
}

/**
 * EditCustomerForm Component
 *
 * This component renders the EditCustomerForm form and handles its behavior.
 *
 * @param {EditCustomerFormProps} props - Properties passed to the component
 * @returns {JSX.Element} - Rendered component
 */

function EditCustomerForm({
  externalId,
  isMutating,
  onSubmit,
}: EditCustomerFormProps) {
  const storeKeyCloakInstance =
    useKeyCloakInstanceStore().storeKeyCloakInstance;
  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );

  const editCustomerFormMethods = useForm<
    z.infer<typeof UpdateCustomerFormSchema>
  >({
    resolver: zodResolver(UpdateCustomerFormSchema),
    defaultValues: {
      status: "active",
      epayManagerAssigned: KeycloakRoles.ProgramManager,
      modifiedBy: parsedUserInfo?.name as string,
      customerProfile: {
        buyerId: 0,
        buyerEpayManager: KeycloakRoles.ProgramManager,
        modifiedBy: parsedUserInfo?.name as string,
        buyerZeroDollarFlag: false,
        buyerProfileAccountNumberMapping: false,
        buyerProfileResponseDataMasking: "yes",
        buyerProfileAccountNumberMasking: false,
        buyerProfileOrgId: "",
        buyerProfileCountry: "usa",
      },
    },
  });

  const [savedModules, setSavedModules] = useState<
    {value: string; label: string}[]
  >([]);

  const {
    searchCustomerIDData,
    customerDetailsIsPending,
    customerDetailsAreFetched,
    // TODO: add error handling toasts
    // isError,
    // error,
  } = useGetCustomerDetails(externalId);

  const {
    setValue,
    formState: {errors},
  } = editCustomerFormMethods;

  console.log("errors", errors);

  useEffect(() => {
    if (searchCustomerIDData) {
      editCustomerFormMethods.reset(searchCustomerIDData);
    }

    if (searchCustomerIDData?.customerProfile.buyerProfileCompanyType) {
      setValue(
        "companyType",
        searchCustomerIDData.customerProfile.buyerProfileCompanyType
      );
    }
    if (searchCustomerIDData?.customerProfile.buyerProfileMccTemplate) {
      setValue(
        "mccTemplateOptions",
        searchCustomerIDData.customerProfile.buyerProfileMccTemplate
      );
    }
    if (searchCustomerIDData?.customerProfile.buyerEpayManager) {
      setValue(
        "epayManagerAssigned",
        searchCustomerIDData.customerProfile.buyerEpayManager ??
          KeycloakRoles.ProgramManager
      );
    }
    if (parsedUserInfo?.name) {
      setValue("modifiedBy", parsedUserInfo.name as string);
      setValue("customerProfile.modifiedBy", parsedUserInfo.name as string);
    }
    if (
      searchCustomerIDData?.buyerAliases &&
      searchCustomerIDData.buyerAliases.length > 0
    ) {
      setValue("aliasSwitch", true);
      setValue("buyerAliases", searchCustomerIDData.buyerAliases);
    }
    if (searchCustomerIDData?.moduleSubscriptions) {
      const moduleSubscriptions = searchCustomerIDData.moduleSubscriptions;

      setValue("moduleSubscriptions", moduleSubscriptions);
      if (moduleSubscriptions[0] && moduleSubscriptions[0].moduleId) {
        setValue("modules", moduleSubscriptions[0].moduleId.toString());

        setSavedModules(
          moduleSubscriptions.map(
            (module: {moduleId: number; moduleName: string}) => {
              return {
                value: String(module.moduleId),
                label: module.moduleName,
              };
            }
          ) as {value: string; label: string}[]
        );
      }
    }
  }, [
    setValue,
    searchCustomerIDData,
    editCustomerFormMethods,
    externalId,
    parsedUserInfo,
  ]);

  const aliasSwitch = editCustomerFormMethods.getValues(
    "aliasSwitch"
  ) as boolean;

  useEffect(() => {
    if (!aliasSwitch) {
      editCustomerFormMethods.setValue("buyerAliases", []);
    }
  }, [setValue, aliasSwitch, editCustomerFormMethods]);

  if (customerDetailsIsPending) {
    return <LoadingSkeletonCard lineCount={9} />;
  }

  if (!searchCustomerIDData) {
    return (
      <section>
        <Card>
          <CardHeader className="flex gap-2">
            <div className="mb-4 flex flex-col-reverse gap-4 md:flex-row md:justify-between">
              <div className="flex flex-col">
                <Heading2>Edit Customer</Heading2>
                <Paragraph>Edit customer details here.</Paragraph>
              </div>
              <Link to="/app/admin/customer-management">
                <Button variant="secondary">
                  Back to list
                  <ArrowRightIcon className="ml-2 size-4" />
                </Button>
              </Link>
            </div>

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
              <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                <Paragraph className="text-lg">
                  <strong className="text-lg font-bold ">
                    {" "}
                    Customer not found{" "}
                  </strong>
                </Paragraph>
              </div>
            </div>
          </CardHeader>
        </Card>
      </section>
    );
  }

  if (customerDetailsAreFetched) {
    return (
      <section>
        <div className="mb-2 flex w-full flex-col items-start justify-between gap-2 lg:flex-row lg:items-center">
          <Heading2 className="flex gap-2">Onboarding</Heading2>
        </div>
        <Form {...editCustomerFormMethods}>
          <form onSubmit={editCustomerFormMethods.handleSubmit(onSubmit)}>
            <div className="mb-8 rounded-md border border-border bg-root p-4">
              <GenericCustomerForm isMutating={isMutating} methodType="PUT" />
              <Separator className="my-5" />
              <Heading4 className="mb-4">
                Module Subscription{" "}
                <span className="pl-1 text-destructive-foreground">*</span>
              </Heading4>

              <div className="grid w-full grid-cols-2 items-center gap-2">
                <div className="group min-h-10 rounded-md border border-input bg-root px-3 py-2 text-sm ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
                  <div
                    className={`flex flex-wrap gap-1 ${
                      savedModules.length ? "mb-2" : ""
                    }`}
                  >
                    {savedModules.map((item: MultiSelectDatum) => {
                      return (
                        <Badge
                          key={item.value}
                          className="py-1 pl-2 pr-1"
                          role="listitem"
                        >
                          <Paragraph className="text-xs font-semibold text-root">
                            {item.label}
                          </Paragraph>
                        </Badge>
                      );
                    })}
                  </div>
                </div>
              </div>
              {savedModules.some(
                (module) => module.label === ModuleNames.imremitLite
              ) && (
                <>
                  <Separator className="my-5" />
                  <Heading4 className="mb-4">Customer Aliases</Heading4>
                  <section className="grid grid-cols-1 gap-2">
                    <div className="flex items-start gap-x-4">
                      <FormField
                        name="aliasSwitch"
                        render={(field) => (
                          <FormItem className="flex w-1/4 flex-col gap-y-2 md:w-1/2 xl:w-1/3 2xl:w-1/4">
                            <FormLabel
                              htmlFor="aliasSwitch"
                              showMandatoryAsterisk
                            >
                              Toggle Alias Mapping:
                            </FormLabel>
                            <FormControl>
                              <Switch
                                checked={aliasSwitch}
                                onCheckedChange={(checked) => {
                                  setValue("aliasSwitch", checked);
                                }}
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      {aliasSwitch && (
                        <FormField
                          name="aliasMapping"
                          render={() => (
                            <FormItem className="flex w-1/4 flex-col gap-y-2 pb-6 md:w-1/2 xl:w-2/3 2xl:w-1/4">
                              <FormLabel
                                htmlFor="aliasMapping"
                                showMandatoryAsterisk
                              >
                                Customer Aliases:
                              </FormLabel>
                              <FormControl>
                                <BuyerAliasesField
                                  isMutating={isMutating}
                                  aliasSwitch={aliasSwitch}
                                  getBuyerAliases={
                                    searchCustomerIDData.buyerAliases || []
                                  }
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      )}
                    </div>
                  </section>
                </>
              )}
              <div className="flex w-full flex-row justify-end gap-2">
                <Link
                  to="/app/admin/customer-management"
                  className={cn(
                    buttonVariants({variant: "secondary"}),
                    "gap-2"
                  )}
                >
                  <span className="sr-only">Cancel</span>
                  <XCircleIcon className="size-4" />
                  Cancel
                </Link>
                <Button
                  className="gap-2 whitespace-nowrap bg-success-foreground text-root hover:bg-success-foreground/80 active:bg-success-foreground/80"
                  type="submit"
                  disabled={isMutating}
                >
                  <span className="sr-only">Submit form</span>
                  Save and Continue <UserCog2Icon className="size-6" />
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </section>
    );
  }
}

export function EditCustomerDetailsPage() {
  const {externalId} = useParams({
    from: "/app/admin/customer-management/$externalId/edit",
  });

  const navigate = useNavigate();

  const editCustomerMutation = useMutation({
    mutationFn: (data: z.infer<typeof UpdateCustomerFormSchema>) => {
      const {modules, aliasSwitch, ...rest} = data;

      console.log("Removed fields", {
        modules,
        aliasSwitch,
      });

      return editCustomerFn(rest);
    },

    onSuccess: (editCustomerResponse) => {
      console.log("editCustomerResponse", editCustomerResponse);

      toast({
        variant: "success",
        title: "Success!",
        description: "Customer edited successfully",
      });

      void navigate({to: "/app/admin/customer-management", replace: true});
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to edit customer",
      });
    },
  });

  // Render AddCustomerForm component
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.UPDATE_CUSTOMER_MANAGEMENT]}
    >
      <EditCustomerForm
        externalId={externalId}
        isMutating={editCustomerMutation.isPending}
        onSubmit={editCustomerMutation.mutate}
      />
    </AccessPolicyWrapper>
  );
}
