import {useMemo} from "react";

import {useSearch} from "@tanstack/react-router";

import {Tabs, TabsContent, TabsList, TabsTrigger} from "@/components/ui/tabs";

import type {RunTypeType} from "@/lib/stores/dupes-run-type-store";
import {useDupesRunTypeStore} from "@/lib/stores/dupes-run-type-store";
import {calculateUserRoles, useKeyCloakInstanceStore} from "@/modules/auth";
import {
  DupesComparisonTypeEnum,
  DupesTrackerDataTable,
  dupesTrackerRoute,
  dupesTrackerTableColumns,
  useDupesTracker,
} from "@/modules/duplicate-payments";

import {FallbackMessages, KeycloakRoles} from "@/utils/constants";

interface DupesTrackerTabsProps {
  externalId?: string;
}

interface useGetDefaultViewsProps {
  externalId?: string;
  dupesRunType?: RunTypeType;
}

function useGetDefaultViews({
  externalId,
  dupesRunType,
}: useGetDefaultViewsProps) {
  // Extract the `tokenParsed` field from the store
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  // Extract the `customer` field from the tokenParsed object
  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );

  const roleDisplay = !parsedUserInfo
    ? FallbackMessages.NOT_APPLICABLE
    : calculateUserRoles(parsedUserInfo.realm_access?.roles);

  const limitToLast90Days =
    roleDisplay && roleDisplay.includes(KeycloakRoles.Management)
      ? false
      : true;

  const likelyDefaultViewQuery = useDupesTracker(
    externalId,
    DupesComparisonTypeEnum.LIKELY,
    dupesRunType,
    limitToLast90Days
  );

  const maybeDefaultViewQuery = useDupesTracker(
    externalId,
    DupesComparisonTypeEnum.MAYBE,
    dupesRunType,
    limitToLast90Days
  );

  const diffSupplierLikelyDefaultViewQuery = useDupesTracker(
    externalId,
    DupesComparisonTypeEnum.DIFFERENT_SUPPLIER_LIKELY,
    dupesRunType,
    limitToLast90Days
  );

  const diffSupplierMaybeDefaultViewQuery = useDupesTracker(
    externalId,
    DupesComparisonTypeEnum.DIFFERENT_SUPPLIER_MAYBE,
    dupesRunType,
    limitToLast90Days
  );

  const diffAmtsAndDatesDefaultViewQuery = useDupesTracker(
    externalId,
    DupesComparisonTypeEnum.DIFFERENT_AMOUNTS_AND_DATES,
    dupesRunType,
    limitToLast90Days
  );

  const diffDatesDefaultViewQuery = useDupesTracker(
    externalId,
    DupesComparisonTypeEnum.DIFFERENT_DATES,
    dupesRunType,
    limitToLast90Days
  );

  const diffSuppliersAndDatesDefaultViewQuery = useDupesTracker(
    externalId,
    DupesComparisonTypeEnum.DIFFERENT_SUPPLIERS_AND_DATES,
    dupesRunType,
    limitToLast90Days
  );

  const diffAmtsDefaultViewQuery = useDupesTracker(
    externalId,
    DupesComparisonTypeEnum.DIFFERENT_AMOUNTS,
    dupesRunType,
    limitToLast90Days
  );

  const creditPairDefaultViewQuery = useDupesTracker(
    externalId,
    DupesComparisonTypeEnum.CREDIT_PAIR,
    dupesRunType,
    limitToLast90Days
  );

  return {
    likelyDefaultViewQuery,
    maybeDefaultViewQuery,
    diffSupplierLikelyDefaultViewQuery,
    diffSupplierMaybeDefaultViewQuery,
    diffAmtsAndDatesDefaultViewQuery,
    diffDatesDefaultViewQuery,
    diffSuppliersAndDatesDefaultViewQuery,
    diffAmtsDefaultViewQuery,
    creditPairDefaultViewQuery,
  };
}

export function DupesTrackerTabs({externalId}: DupesTrackerTabsProps) {
  const {dupesLikeliness} = useSearch({from: dupesTrackerRoute.id});

  // Run Type State Store
  const {dupesRunType} = useDupesRunTypeStore();

  const {
    likelyDefaultViewQuery,
    maybeDefaultViewQuery,
    diffSupplierLikelyDefaultViewQuery,
    diffSupplierMaybeDefaultViewQuery,
    diffAmtsAndDatesDefaultViewQuery,
    diffDatesDefaultViewQuery,
    diffSuppliersAndDatesDefaultViewQuery,
    diffAmtsDefaultViewQuery,
    creditPairDefaultViewQuery,
  } = useGetDefaultViews({externalId, dupesRunType});

  const likelyData = likelyDefaultViewQuery.data?.content || [];
  const maybeData = maybeDefaultViewQuery.data?.content || [];
  const diffSupplierLikelyData =
    diffSupplierLikelyDefaultViewQuery.data?.content || [];
  const diffSupplierMaybeData =
    diffSupplierMaybeDefaultViewQuery.data?.content || [];
  const diffAmtsAndDatesData =
    diffAmtsAndDatesDefaultViewQuery.data?.content || [];
  const diffDatesData = diffDatesDefaultViewQuery.data?.content || [];
  const diffSuppliersAndDatesData =
    diffSuppliersAndDatesDefaultViewQuery.data?.content || [];
  const diffAmtsData = diffAmtsDefaultViewQuery.data?.content || [];
  const creditPairData = creditPairDefaultViewQuery.data?.content || [];

  console.log("likelyData", likelyData);
  console.log("maybeData", maybeData);
  console.log("diffSupplierLikelyData", diffSupplierLikelyData);
  console.log("diffSupplierMaybeData", diffSupplierMaybeData);
  console.log("diffAmtsAndDatesData", diffAmtsAndDatesData);
  console.log("diffDatesData", diffDatesData);
  console.log("diffSuppliersAndDatesData", diffSuppliersAndDatesData);
  console.log("diffAmtsData", diffAmtsData);
  console.log("creditPairData", creditPairData);

  return (
    <Tabs
      defaultValue={dupesLikeliness ? dupesLikeliness : "likely"}
      className="w-full"
    >
      <TabsList className="max-w-fit">
        <TabsTrigger value="likely">Likely</TabsTrigger>
        <TabsTrigger value="maybe">Maybe</TabsTrigger>
        <TabsTrigger value="different-supplier-likely">
          Diff. Supplier Likely
        </TabsTrigger>
        <TabsTrigger value="different-supplier-maybe">
          Diff. Supplier Maybe
        </TabsTrigger>
        <TabsTrigger value="different-amounts-and-dates">
          Diff. Amounts & Dates
        </TabsTrigger>
        <TabsTrigger value="different-dates">Diff. Dates</TabsTrigger>
        <TabsTrigger value="different-suppliers-and-dates">
          Diff. Suppliers & Dates
        </TabsTrigger>
        <TabsTrigger value="different-amounts">Diff. Amounts</TabsTrigger>
        <TabsTrigger value="credit-pair">Credit Pair</TabsTrigger>
      </TabsList>

      <TabsContent value="likely">
        <DupesTrackerDataTable
          columns={dupesTrackerTableColumns}
          data={likelyData}
          isPending={likelyDefaultViewQuery.isPending}
          isSuccess={likelyDefaultViewQuery.isSuccess}
          isError={likelyDefaultViewQuery.isError}
        />
      </TabsContent>
      <TabsContent value="maybe">
        <DupesTrackerDataTable
          columns={dupesTrackerTableColumns}
          data={maybeData}
          isPending={maybeDefaultViewQuery.isPending}
          isSuccess={maybeDefaultViewQuery.isSuccess}
          isError={maybeDefaultViewQuery.isError}
        />
      </TabsContent>
      <TabsContent value="different-supplier-likely">
        <DupesTrackerDataTable
          columns={dupesTrackerTableColumns}
          data={diffSupplierLikelyData}
          isPending={diffSupplierLikelyDefaultViewQuery.isPending}
          isSuccess={diffSupplierLikelyDefaultViewQuery.isSuccess}
          isError={diffSupplierLikelyDefaultViewQuery.isError}
        />
      </TabsContent>
      <TabsContent value="different-supplier-maybe">
        <DupesTrackerDataTable
          columns={dupesTrackerTableColumns}
          data={diffSupplierMaybeData}
          isPending={diffSupplierMaybeDefaultViewQuery.isPending}
          isSuccess={diffSupplierMaybeDefaultViewQuery.isSuccess}
          isError={diffSupplierMaybeDefaultViewQuery.isError}
        />
      </TabsContent>
      <TabsContent value="different-amounts-and-dates">
        <DupesTrackerDataTable
          columns={dupesTrackerTableColumns}
          data={diffAmtsAndDatesData}
          isPending={diffAmtsAndDatesDefaultViewQuery.isPending}
          isSuccess={diffAmtsAndDatesDefaultViewQuery.isSuccess}
          isError={diffAmtsAndDatesDefaultViewQuery.isError}
        />
      </TabsContent>
      <TabsContent value="different-dates">
        <DupesTrackerDataTable
          columns={dupesTrackerTableColumns}
          data={diffDatesData}
          isPending={diffDatesDefaultViewQuery.isPending}
          isSuccess={diffDatesDefaultViewQuery.isSuccess}
          isError={diffDatesDefaultViewQuery.isError}
        />
      </TabsContent>
      <TabsContent value="different-suppliers-and-dates">
        <DupesTrackerDataTable
          columns={dupesTrackerTableColumns}
          data={diffSuppliersAndDatesData}
          isPending={diffSuppliersAndDatesDefaultViewQuery.isPending}
          isSuccess={diffSuppliersAndDatesDefaultViewQuery.isSuccess}
          isError={diffSuppliersAndDatesDefaultViewQuery.isError}
        />
      </TabsContent>
      <TabsContent value="different-amounts">
        <DupesTrackerDataTable
          columns={dupesTrackerTableColumns}
          data={diffAmtsData}
          isPending={diffAmtsDefaultViewQuery.isPending}
          isSuccess={diffAmtsDefaultViewQuery.isSuccess}
          isError={diffAmtsDefaultViewQuery.isError}
        />
      </TabsContent>
      <TabsContent value="credit-pair">
        <DupesTrackerDataTable
          columns={dupesTrackerTableColumns}
          data={creditPairData}
          isPending={creditPairDefaultViewQuery.isPending}
          isSuccess={creditPairDefaultViewQuery.isSuccess}
          isError={creditPairDefaultViewQuery.isError}
        />
      </TabsContent>
    </Tabs>
  );
}
