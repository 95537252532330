import {useCallback, useEffect, useMemo} from "react";

import {useNavigate, useParams} from "@tanstack/react-router";

import {Heading2, Paragraph} from "@/components/ui/typography";

import type {PaginationMetaType} from "@/lib/ky";
import {queryClient} from "@/lib/query-client";
import {ImREmitLiteRoutesSchema} from "@/lib/routes/types";
import {
  useImRemitLiteCustomerStore,
  usePreferencesStore,
  useRoutesStore,
} from "@/lib/stores";
import {SelectCustomerFormLite} from "@/modules/customers";
import {
  ProxyCards,
  proxyPayQueryKeys,
  searchProxyPay,
  useProxypayMetaStore,
  useSearchProxypay,
  useSearchProxypayDashboard,
} from "@/modules/imremit";
import {
  ProxyPayDataTable,
  useGetProxypayDashboardColumns,
} from "@/modules/imremit-lite";

import {replacePageQueryParam} from "@/utils/functions";
import {generateQueryParams} from "@/utils/query-params-generator";

/**
 * Custom Hook: Manage the active route for the proxy pay dashboard.
 */
function useManageProxypayRoute() {
  const {setimREmitLiteRoute} = useRoutesStore();

  useEffect(() => {
    // Set the active route based on predefined route schema
    setimREmitLiteRoute(
      ImREmitLiteRoutesSchema.Values["/app/imremit-lite/proxy-pay-dashboard"]
    );
  }, [setimREmitLiteRoute]);
}

/**
 * Custom Hook: Manage pagination and user preferences for the payment management dashbaord.
 *
 * @param {(pageNumber: number) => Promise<void>} setPageIndex - Function to update the current page index.
 * @returns {Object} - Number of rows per page and the current criteria for storing payment management data.
 */
function useProxypayPaginationAndPreferences(
  setPageIndex: (pageNumber: number) => Promise<void>
) {
  const {numRowsPerPage} = usePreferencesStore();
  const {
    storeProxypayCriteria,
    storeProxypayPaginationMeta,
    storeProxypaySortMeta,
    setHandleOnPageChange,
  } = useProxypayMetaStore();

  useEffect(() => {
    // Reset the page index if it is out of bounds
    if (storeProxypayPaginationMeta) {
      if (
        storeProxypayPaginationMeta.pageNumber < 0 ||
        storeProxypayPaginationMeta.pageNumber + 1 >
          storeProxypayPaginationMeta.totalPages
      ) {
        void setPageIndex(0);
      }
    }

    // Set the handler for page change events
    setHandleOnPageChange(setPageIndex);
  }, [setHandleOnPageChange, storeProxypayPaginationMeta, setPageIndex]);

  return {
    numRowsPerPage,
    storeProxypayCriteria,
    storeProxypaySortMeta,
  };
}

/**
 * Custom Hook: Fetch and prepare payment management data.
 *
 * @param {string} queryParams - The query parameters for the API request.
 * @returns {Object} - The content and metadata for the payment management, along with the API query object.
 */
function useFetchAndPrepareProxypay(queryParams: string) {
  const {storeProxypayPaginationMeta} = useProxypayMetaStore();

  const {imRemitLiteStoreCustomer} = useImRemitLiteCustomerStore();
  const externalId = imRemitLiteStoreCustomer?.externalId;

  //Same End-point for both premium & lite - based on the customer the data will fetch.
  const searchProxypayQuery = useSearchProxypay(
    externalId?.toString(),
    queryParams
  );

  const proxypayContent = useMemo(() => {
    if (!searchProxypayQuery.data) {
      return [];
    }
    return searchProxypayQuery.data.content;
  }, [searchProxypayQuery.data]);

  const proxypayPaginationMeta = useMemo(() => {
    if (!searchProxypayQuery.data) {
      return storeProxypayPaginationMeta;
    }

    return searchProxypayQuery.data.meta;
  }, [searchProxypayQuery.data, storeProxypayPaginationMeta]);

  // Prefetch the next page of payment management
  useEffect(() => {
    if (proxypayPaginationMeta) {
      // Create the query params for the next page
      const nextPageQueryParams = replacePageQueryParam(
        queryParams,
        (proxypayPaginationMeta.pageNumber + 1).toString()
      );

      // Prefetch the next page of payment management
      void queryClient.prefetchQuery({
        queryKey: proxyPayQueryKeys.byExternalIdAndSearchQuery(
          externalId?.toString(),
          nextPageQueryParams
        ),
        queryFn: () =>
          searchProxyPay(externalId?.toString(), nextPageQueryParams),
      });
    }
  }, [queryParams, proxypayPaginationMeta, externalId]);

  return {
    proxypayContent,
    proxypayPaginationMeta,
    searchProxypayQuery,
  };
}

/**
 * Custom Hook: Manage payment management pagination metadata.
 *
 * @param {PaginationMetaType | null} proxypayPaginationMeta - The pagination metadata for Proxypay.
 */
function useManageProxypayPaginationMeta(
  proxypayPaginationMeta: PaginationMetaType | null
) {
  const {setStoreProxypayPaginationMeta} = useProxypayMetaStore();

  useEffect(() => {
    // Update the pagination metadata in the store if available
    if (proxypayPaginationMeta) {
      setStoreProxypayPaginationMeta(proxypayPaginationMeta);
    }
  }, [proxypayPaginationMeta, setStoreProxypayPaginationMeta]);
}

/**
 * Custom hook for managing the proxy Proxypay state and authorization.
 * @returns {Object} The state and utility functions for the  Proxypay Page.
 */
export function useCustomerManagementPage() {
  // Fetch the page number from the URL params
  const {ppPage} = useParams({
    from: "/app/imremit-lite/proxy-pay-dashboard/$ppPage/dashboard",
  });

  const navigate = useNavigate();
  const handleNavigationPagination = useCallback(
    (pageNumber: number) =>
      navigate({
        to: "/app/imremit-lite/proxy-pay-dashboard/$ppPage/dashboard",
        params: {
          ppPage: (pageNumber + 1).toString(),
        },
      }),
    [navigate]
  );

  // Fetch customer data from the customer store
  const {imRemitLiteStoreCustomer} = useImRemitLiteCustomerStore();

  const proxypayPageNumber = useMemo(() => {
    // Reset the page index if it is out of bounds
    if (!ppPage || parseInt(ppPage) <= 0 || isNaN(parseInt(ppPage))) {
      void handleNavigationPagination(0);
      return 0;
    }

    return parseInt(ppPage) - 1;
  }, [ppPage, handleNavigationPagination]);

  // Fetch user preferences and pagination criteria
  const {numRowsPerPage, storeProxypayCriteria, storeProxypaySortMeta} =
    useProxypayPaginationAndPreferences(handleNavigationPagination);

  const searchProxypayQueryParams = useMemo(
    () =>
      generateQueryParams({
        params: storeProxypayCriteria || {},
        page: proxypayPageNumber,
        size: numRowsPerPage,
        sort: storeProxypaySortMeta,
      }),
    [
      numRowsPerPage,
      proxypayPageNumber,
      storeProxypayCriteria,
      storeProxypaySortMeta,
    ]
  );

  // Fetch dashboard data using the custom hook
  const proxyPayDashboardQuery = useSearchProxypayDashboard(
    imRemitLiteStoreCustomer?.externalId.toString() || ""
  );
  const proxyPayDashboardData = proxyPayDashboardQuery.data?.content;

  // Return the state and utility functions
  return {
    searchProxypayQueryParams,
    proxyPayDashboardData,
    proxyPayDashboardQuery,
  };
}

/**
 * The ProxyPayDashboardView component renders the dashboard for Proxy Pay.
 * It displays the loading state, no data/error state, and the proxy pay cards and data table.
 *
 * @param {ProxyPayDashboardProps} props - The props for the component.
 * @returns {JSX.Element} - The JSX element for the Proxy Pay Dashboard.
 */
export function ProxyPayDashboardView() {
  // Get the search customer query and data
  const {
    searchProxypayQueryParams,
    proxyPayDashboardData,
    proxyPayDashboardQuery,
  } = useCustomerManagementPage();

  // Fetch and prepare the Payment Management data
  const {proxypayContent, proxypayPaginationMeta, searchProxypayQuery} =
    useFetchAndPrepareProxypay(searchProxypayQueryParams);

  // Manage the active route for this page
  useManageProxypayRoute();

  // Update pagination metadata in the store
  useManageProxypayPaginationMeta(proxypayPaginationMeta);

  const proxypayColumns = useGetProxypayDashboardColumns();

  // Main render block
  return (
    <section>
      <div className="mb-4 flex flex-col items-center justify-between gap-4 md:flex-row">
        {/* Heading and overview section */}
        <div className="flex flex-col">
          <Heading2>Proxy Pay Dashboard</Heading2>
          <Paragraph>Manage your imREmit proxy-pay payments here.</Paragraph>
        </div>

        <div className="flex gap-2">
          <SelectCustomerFormLite />
        </div>
      </div>
      {/* Proxy Cards displaying dashboard data */}
      <ProxyCards
        proxyPayDashboardData={proxyPayDashboardData}
        isPending={proxyPayDashboardQuery.isPending}
      />
      {/* Data table with proxy pay data */}
      <ProxyPayDataTable
        columns={proxypayColumns}
        data={proxypayContent}
        isPending={searchProxypayQuery.isPending}
        isSuccess={searchProxypayQuery.isSuccess}
        isError={searchProxypayQuery.isError}
      />
    </section>
  );
}
