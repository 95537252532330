import {z} from "zod";

import {multiSelectDatumSchema} from "@/components/craft/fancy-multi-select";

export const MISPaymentsMonthlySchema = z.object({
  year: z.number(),
  month: z.string(),
  total_amount: z.number(),
  ytd_total: z.number(),
});

export const MISPaymentsQuarterlySchema = z.object({
  year: z.number(),
  quarter: z.string(),
  quarter_total: z.number(),
});

export const MISPaymentsReceivedSchema = z.object({
  buyer_external_id: z.string(),
  buyer_name: z.string(),
  gross_total: z.number(),
  monthly_data: z.array(MISPaymentsMonthlySchema),
  quarterly_totals: z.array(MISPaymentsQuarterlySchema),
});

export type MISPaymentsReceivedType = z.infer<typeof MISPaymentsReceivedSchema>;

export const MISPaymentsDifferencesSchema = z.object({
  year: z.number(),
  month: z.string(),
  payments_received: z.number(),
  payments_posted: z.number(),
  diff_received_value: z.number().nullable(),
  diff_received_percent: z.number().nullable(),
  diff_posted_value: z.number().nullable(),
  diff_posted_percent: z.number().nullable(),
});

export type MISPaymentsDifferencesType = z.infer<
  typeof MISPaymentsDifferencesSchema
>;

export type MultiSelectDatumType = z.infer<typeof multiSelectDatumSchema>;

// Define valid months and quarters as sets
const validMonthsSet = new Set([
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]);

const validQuartersSet = new Set(["Q1", "Q2", "Q3", "Q4"]);

// Update allowedStatusesSet with all relevant statuses
const allowedStatusesSet = new Set([
  "Paid - Pending Verification",
  "Delivered",
  "Closed",
  "Failed",
  "Declined",
  "Error",
  "In Progress",
  "Open",
  "Payment Already Taken",
  "Invoice Amount Mismatch",
  "Escalation With Bank",
  "Invoice Number Mismatch",
]);

// Define the filter form schema
export const paymentFilterFormSchema = z.object({
  customers: multiSelectDatumSchema.array().optional(),
  year: z.enum(["previous", "current", "both"]).optional(),

  month: z
    .array(multiSelectDatumSchema)
    .optional()
    .refine(
      (months) => {
        if (!months) return true;
        return months.every((month) => validMonthsSet.has(month.value));
      },
      {
        message: "Invalid month provided. Accepted values are Jan to Dec.",
      }
    ),

  quarter: z
    .array(multiSelectDatumSchema)
    .optional()
    .refine(
      (quarters) => {
        if (!quarters) return true;
        return quarters.every((quarter) => validQuartersSet.has(quarter.value));
      },
      {
        message: "Invalid quarter provided. Accepted values are Q1 to Q4.",
      }
    ),

  status: z
    .array(multiSelectDatumSchema)
    .optional()
    .refine(
      (statuses) => {
        if (!statuses) return true;
        return statuses.every((status) => allowedStatusesSet.has(status.value));
      },
      {
        message: "Invalid payment status provided.",
      }
    ),

  module: z.enum(["imREmit", "imREmit_lite", "both"]).optional(),
});

export type PaymentFilterFormType = z.infer<typeof paymentFilterFormSchema>;

export const MISPaymentsDifferencesFilterFormSchema = z.object({
  customers: multiSelectDatumSchema.array().optional(),
});

export type MISPaymentsDifferencesFilterFormType = z.infer<
  typeof MISPaymentsDifferencesFilterFormSchema
>;
