import {useMemo} from "react";

import {Skeleton} from "@/components/ui/skeleton";
import {TableBody, TableCell, TableRow} from "@/components/ui/table";

interface TableSkeletonProps {
  columnsLength: number;
  omittedColumns: Record<string, boolean>;
  numRowsPerPage: number;
}

export function TableSkeleton({
  columnsLength,
  omittedColumns,
  numRowsPerPage,
}: TableSkeletonProps) {
  const tableBodySkeleton = useMemo(() => {
    return (
      <TableBody>
        {Array.from({length: numRowsPerPage}, (_row, rowIndex) => (
          <TableRow key={rowIndex}>
            {Array.from(
              // calculate how many cells we need to render based on the number of columns
              // minus the number of omitted columns
              {
                length:
                  columnsLength -
                  Object.values(omittedColumns).filter((element) => !element)
                    .length,
              },
              (_cell, cellIndex) => (
                <TableCell
                  key={`${cellIndex}-${rowIndex}`}
                  className="px-2 py-6"
                >
                  <Skeleton
                    size="xl"
                    colour={rowIndex % 2 === 0 ? "background" : "foreground"}
                  />
                </TableCell>
              )
            )}
          </TableRow>
        ))}
      </TableBody>
    );
  }, [columnsLength, omittedColumns, numRowsPerPage]);

  return tableBodySkeleton;
}
