import {useEffect, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate} from "@tanstack/react-router";
import {
  CheckIcon,
  ChevronsUpDownIcon,
  InfoIcon,
  PlusIcon,
  SaveIcon,
  Trash2Icon,
  XIcon,
} from "lucide-react";
import {useFieldArray, useForm} from "react-hook-form";
import type * as z from "zod";

import {Button} from "@/components/ui/button";
import {Card, CardContent} from "@/components/ui/card";
import {Command, CommandGroup, CommandItem} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Label} from "@/components/ui/label";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {RadioGroup, RadioGroupItem} from "@/components/ui/radio-group";
import {Spinner} from "@/components/ui/spinner";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {FancyMultiSelect} from "@/components/craft/fancy-multi-select";

import {cn} from "@/lib/utils";
import {
  bankMappingFormSchema,
  saveBankFileBAI2Fn,
  saveBankFileFn,
  useDateFormatDetails,
  useDelimiterTypesDetails,
  useMappingDocumentTypeStore,
} from "@/modules/imremit";

const lengthTypeOptions = [
  {label: "BEFORE_SPACE", value: "BEFORE_SPACE"},
  {label: "AFTER_SPACE", value: "AFTER_SPACE"},
  {label: "LEADING_ZEROS", value: "LEADING_ZEROS"},
];

const continuationRecordOptions = [
  {label: "Payment Number", value: "Payment Number"},
  {label: "Bank Account Number", value: "Bank Account Number"},
  {label: "Location Name", value: "Location Name"},
  {label: "Supplier Name", value: "Supplier Name"},
  {label: "Supplier Number", value: "Supplier Number"},
];

interface Option {
  label: string;
  value: string;
}

export function BankFileAddMapping() {
  const navigate = useNavigate();

  const {storeDocumentType} = useMappingDocumentTypeStore();

  //get delimiter type from custom hook `useDelimiterTypesDetails`
  const delimiterTypeResponse = useDelimiterTypesDetails();
  const delimiterTypeData = delimiterTypeResponse.data?.content;

  //get date format from custom hook `useDateFormatDetails`
  const DateFormatResponse = useDateFormatDetails();
  const DateFormatData = DateFormatResponse.data?.content;

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const handleChange = (selectedOptions: Option[]) => {
    // Extract only the values from the selected options
    const values = selectedOptions.map((option) => option.value);
    setSelectedValues(values);
  };

  const bankForm = useForm<z.infer<typeof bankMappingFormSchema>>({
    defaultValues: {
      documentTypeId: storeDocumentType?.documentTypeId.toString() || "",
      bankMappingRequests: [],
      continuationRecordFields: [],
    },
    resolver: zodResolver(bankMappingFormSchema),
  });

  // Initialize Tanstack Query Mutation for Refreshing Bank File
  const saveBankFileMutation = useMutation({
    mutationFn: saveBankFileFn,

    onSuccess: (response) => {
      bankForm.reset();
      console.log("saveBankFileMutationResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Bank file mapping added successfully",
      });
      void navigate({to: "/app/imremit/mapping", replace: true});
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add bank file mapping",
      });
    },
  });

  // Initialize Tanstack Query Mutation for Refreshing Bank File
  const saveBankFileBAI2Mutation = useMutation({
    mutationFn: saveBankFileBAI2Fn,

    onSuccess: (response) => {
      bankForm.reset();
      console.log("saveBankFileBAI2MutationResponse", response);
      toast({
        variant: "default",
        title: "Success!",
        description: "Bank file mapping added successfully",
      });
      void navigate({to: "/app/imremit/mapping", replace: true});
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add bank file mapping",
      });
    },
  });

  const isMutating =
    saveBankFileMutation.isPending || saveBankFileBAI2Mutation.isPending;

  const {fields, append, remove} = useFieldArray({
    control: bankForm.control,
    name: "bankMappingRequests",
  });

  const isBAI2 = bankForm.watch("type") === "BAI2";

  function onSubmit(data: z.infer<typeof bankMappingFormSchema>) {
    const newData = {...data, fundsType: "V"};
    if (isBAI2) {
      saveBankFileBAI2Mutation.mutate(newData);
    } else {
      saveBankFileMutation.mutate(data);
    }
  }

  const {setValue} = bankForm;

  useEffect(() => {
    if (isBAI2) {
      setValue("bankMappingRequests", []);
    }
  }, [isBAI2, setValue]);

  console.log(bankForm.watch("continuationRecordFields"));
  return (
    <>
      <Card>
        <CardContent>
          <Form {...bankForm}>
            <form onSubmit={bankForm.handleSubmit(onSubmit)}>
              <div className="mb-5 mt-5 grid grid-cols-1 gap-4 space-x-2 md:grid-cols-6">
                <FormField
                  control={bankForm.control}
                  name="masterMappingName"
                  render={({field}) => (
                    <FormItem className="flex flex-col md:col-span-2 xl:col-span-2 2xl:col-span-1">
                      <Label showMandatoryAsterisk>Master Mapping Name :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          autoCapitalize="none"
                          autoComplete="masterMappingName"
                          autoCorrect="off"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={bankForm.control}
                  name="type"
                  render={({field}) => (
                    <FormItem className="space-y-4">
                      <Label showMandatoryAsterisk>Format </Label>
                      <FormControl>
                        <RadioGroup
                          className="flex flex-col space-y-1"
                          onValueChange={field.onChange}
                        >
                          <div className="flex items-center space-x-2">
                            <FormItem className="flex items-center space-x-3 space-y-0">
                              <FormControl>
                                <RadioGroupItem value="FIXED" />
                              </FormControl>
                              <FormLabel className="font-normal">
                                Fixed Length
                              </FormLabel>
                            </FormItem>
                            <FormItem className="flex items-center space-x-3 space-y-0">
                              <FormControl>
                                <RadioGroupItem value="CSV" />
                              </FormControl>
                              <FormLabel className="font-normal">CSV</FormLabel>
                            </FormItem>
                            <FormItem className="flex items-center space-x-3 space-y-0">
                              <FormControl>
                                <RadioGroupItem value="BAI2" />
                              </FormControl>
                              <FormLabel className="font-normal">
                                BAI2
                              </FormLabel>
                            </FormItem>
                          </div>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={bankForm.control}
                  name="documentTypeId"
                  render={({field}) => (
                    <FormItem>
                      <FormControl>
                        <Input placeholder="" {...field} className="hidden" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button
                  type="button"
                  disabled={isBAI2}
                  className={`col-span-1 col-end-8 mt-5 ${
                    isBAI2 ? "hidden" : ""
                  }`}
                  onClick={() => {
                    append({
                      order: "",
                      headerName: "",
                      dataLength: "",
                      delimiterTypeId: "",
                      dateFormatId: "",
                      defaultValue: "",
                      mappingName: "",
                      isDecimalField: true,
                      fixedLengthType: null,
                    });
                  }}
                >
                  <PlusIcon className="mr-2 size-4" /> Add
                </Button>
              </div>
              {isBAI2 ? (
                <>
                  <Paragraph className="text-xl font-bold">
                    01- File Header
                  </Paragraph>
                  <div className="mb-5 mt-5 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-6">
                    <FormField
                      control={bankForm.control}
                      name="receiverCode"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label htmlFor="receiverCode" showMandatoryAsterisk>
                            Receiver Code
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="01"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="receiverCode"
                              autoCorrect="off"
                              {...bankForm.register("receiverCode")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={bankForm.control}
                      name="receiverId"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <div className="flex space-x-2">
                            <Label htmlFor="receiverId" showMandatoryAsterisk>
                              Receiver ID
                            </Label>
                            <TooltipProvider delayDuration={200}>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <div className="cursor-pointer">
                                    <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <div className="flex justify-between space-x-4">
                                    Customer's Receiver ID
                                  </div>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                          <FormControl>
                            <Input
                              type="text"
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              autoCapitalize="none"
                              autoComplete="receiverId"
                              autoCorrect="off"
                              {...bankForm.register("receiverId")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={bankForm.control}
                      name="fileCreationTime"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label
                            htmlFor="fileCreationTime"
                            showMandatoryAsterisk
                          >
                            File Creation Time
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="HHMM"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="fileCreationTime"
                              autoCorrect="off"
                              {...bankForm.register("fileCreationTime")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={bankForm.control}
                      name="physicalRecordLength"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <div className="flex space-x-2">
                            <Label htmlFor="physicalRecordLength">
                              Physical Record Length
                            </Label>
                            <TooltipProvider delayDuration={200}>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <div className="cursor-pointer">
                                    <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <div className="flex justify-between space-x-4">
                                    Number of characters in a physical record.
                                    <br />
                                    Default is variable length records
                                  </div>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                          <FormControl>
                            <Input
                              type="text"
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              autoCapitalize="none"
                              autoComplete="physicalRecordLength"
                              autoCorrect="off"
                              {...bankForm.register("physicalRecordLength")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={bankForm.control}
                      name="baiVersion"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label htmlFor="baiVersion" showMandatoryAsterisk>
                            BAI Version
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="2"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="baiVersion"
                              autoCorrect="off"
                              {...bankForm.register("baiVersion")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={bankForm.control}
                      name="senderId"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <div className="flex space-x-2">
                            <Label htmlFor="senderId" showMandatoryAsterisk>
                              Sender ID
                            </Label>
                            <TooltipProvider delayDuration={200}>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <div className="cursor-pointer">
                                    <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <div className="flex justify-between space-x-4">
                                    9-digit bank routing number
                                  </div>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                          <FormControl>
                            <Input
                              type="text"
                              placeholder="[Bank Routing Number]"
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              autoCapitalize="none"
                              autoComplete="senderId"
                              autoCorrect="off"
                              {...bankForm.register("senderId")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={bankForm.control}
                      name="fileCreationDate"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label
                            htmlFor="fileCreationDate"
                            showMandatoryAsterisk
                          >
                            File Creation Date
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="YYMMDD"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="fileCreationDate"
                              autoCorrect="off"
                              {...bankForm.register("fileCreationDate")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={bankForm.control}
                      name="fileIdNumber"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label htmlFor="fileIdNumber" showMandatoryAsterisk>
                            File ID Number
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="fileIdNumber"
                              autoCorrect="off"
                              {...bankForm.register("fileIdNumber")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={bankForm.control}
                      name="blockSize"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <div className="flex space-x-2">
                            <Label htmlFor="blockSize">Block Size</Label>
                            <TooltipProvider delayDuration={200}>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <div className="cursor-pointer">
                                    <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <div className="flex justify-between space-x-4">
                                    Number of physical records in a block.
                                    <br />
                                    Default is variable block size.
                                  </div>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                          <FormControl>
                            <Input
                              type="text"
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              autoCapitalize="none"
                              autoComplete="blockSize"
                              autoCorrect="off"
                              {...bankForm.register("blockSize")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <Paragraph className="text-xl font-bold">
                    02- Group Header
                  </Paragraph>
                  <div className="mb-5 mt-5 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-6">
                    <FormField
                      control={bankForm.control}
                      name="groupHeaderRecordCode"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label
                            htmlFor="groupHeaderRecordCode"
                            showMandatoryAsterisk
                          >
                            Record Code
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="02"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="groupHeaderRecordCode"
                              autoCorrect="off"
                              {...bankForm.register("groupHeaderRecordCode")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={bankForm.control}
                      name="originatorId"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <div className="flex space-x-2">
                            <Label htmlFor="originatorId" showMandatoryAsterisk>
                              Originator ID
                            </Label>
                            <TooltipProvider delayDuration={200}>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <div className="cursor-pointer">
                                    <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <div className="flex justify-between space-x-4">
                                    9-digit bank routing number
                                  </div>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                          <FormControl>
                            <Input
                              type="text"
                              placeholder="[Bank Routing Number]"
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              autoCapitalize="none"
                              autoComplete="originatorId"
                              autoCorrect="off"
                              {...bankForm.register("originatorId")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={bankForm.control}
                      name="asOfDate"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label htmlFor="asOfDate" showMandatoryAsterisk>
                            As-of-Date (Post Date)
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="YYMMDD"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="asOfDate"
                              autoCorrect="off"
                              {...bankForm.register("asOfDate")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={bankForm.control}
                      name="groupHeaderCurrencyCode"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <div className="flex space-x-2">
                            <Label htmlFor="groupHeaderCurrencyCode">
                              Currency Code
                            </Label>
                            <TooltipProvider delayDuration={200}>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <div className="cursor-pointer">
                                    <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <div className="flex justify-between space-x-4">
                                    Currency codes are always on the Account
                                    Record (03 Record)
                                  </div>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                          <FormControl>
                            <Input
                              type="text"
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              autoCapitalize="none"
                              autoComplete="groupHeaderCurrencyCode"
                              autoCorrect="off"
                              {...bankForm.register("groupHeaderCurrencyCode")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={bankForm.control}
                      name="ultimateReceiverId"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <div className="flex space-x-2">
                            <Label
                              htmlFor="ultimateReceiverId"
                              showMandatoryAsterisk
                            >
                              Ultimate Receiver ID
                            </Label>
                            <TooltipProvider delayDuration={200}>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <div className="cursor-pointer">
                                    <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <div className="flex justify-between space-x-4">
                                    Customers Receiver ID (Same as Receiver ID
                                    in 01)
                                  </div>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                          <FormControl>
                            <Input
                              type="text"
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              autoCapitalize="none"
                              autoComplete="ultimateReceiverId"
                              autoCorrect="off"
                              {...bankForm.register("ultimateReceiverId")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={bankForm.control}
                      name="groupStatus"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label htmlFor="groupStatus" showMandatoryAsterisk>
                            Group Status
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              autoCapitalize="none"
                              autoComplete="groupStatus"
                              autoCorrect="off"
                              {...bankForm.register("groupStatus")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={bankForm.control}
                      name="asOfTime"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label htmlFor="asOfTime" showMandatoryAsterisk>
                            As-of Time
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="HHMM"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="asOfTime"
                              autoCorrect="off"
                              {...bankForm.register("asOfTime")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={bankForm.control}
                      name="asOfDateModifier"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <div className="flex space-x-2">
                            <Label htmlFor="asOfDateModifier">
                              As-of Date Modifier
                            </Label>
                            <TooltipProvider delayDuration={200}>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <div className="cursor-pointer">
                                    <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <div className="flex justify-between space-x-4">
                                    As-of-Date Modifier does not affect
                                    processing.
                                    <br />
                                    For reference only.
                                  </div>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                          <FormControl>
                            <Input
                              type="text"
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              autoCapitalize="none"
                              autoComplete="asOfDateModifier"
                              autoCorrect="off"
                              {...bankForm.register("asOfDateModifier")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <Paragraph className="text-xl font-bold">
                    03- Account Identifier & Summary Status
                  </Paragraph>
                  <div className="mb-5 mt-5 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-6">
                    <FormField
                      control={bankForm.control}
                      name="accountIdentifierRecordCode"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label
                            htmlFor="accountIdentifierRecordCode"
                            showMandatoryAsterisk
                          >
                            Record Code
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="03"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="accountIdentifierRecordCode"
                              autoCorrect="off"
                              {...bankForm.register(
                                "accountIdentifierRecordCode"
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={bankForm.control}
                      name="accountIdentifierCurrencyCode"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <div className="flex space-x-2">
                            <Label
                              htmlFor="accountIdentifierCurrencyCode"
                              showMandatoryAsterisk
                            >
                              Currency Code
                            </Label>
                            <TooltipProvider delayDuration={200}>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <div className="cursor-pointer">
                                    <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <div className="flex justify-between space-x-4">
                                    Default is group currency code. Use 'USD'
                                  </div>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                          <FormControl>
                            <Input
                              type="text"
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              autoCapitalize="none"
                              autoComplete="accountIdentifierCurrencyCode"
                              autoCorrect="off"
                              {...bankForm.register(
                                "accountIdentifierCurrencyCode"
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={bankForm.control}
                      name="customerAccountNumber"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <div className="flex space-x-2">
                            <Label
                              htmlFor="customerAccountNumber"
                              showMandatoryAsterisk
                            >
                              Customer Account Number
                            </Label>
                            <TooltipProvider delayDuration={200}>
                              <Tooltip>
                                <TooltipTrigger asChild>
                                  <div className="cursor-pointer">
                                    <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                                  </div>
                                </TooltipTrigger>
                                <TooltipContent>
                                  <div className="flex justify-between space-x-4">
                                    The field is alphanumeric and includes
                                    <br />
                                    significant leading zeroes.
                                    <br />
                                    Must not contain a comma “,” or slash “/”.
                                  </div>
                                </TooltipContent>
                              </Tooltip>
                            </TooltipProvider>
                          </div>
                          <FormControl>
                            <Input
                              type="text"
                              placeholder="[Bank Account Number]"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="customerAccountNumber"
                              autoCorrect="off"
                              {...bankForm.register("customerAccountNumber")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <Paragraph className="text-xl font-bold">
                    16- Transaction Detail
                  </Paragraph>
                  <div className="mb-5 mt-5 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-6">
                    <FormField
                      control={bankForm.control}
                      name="transactionDetailRecordType"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label
                            htmlFor="transactionDetailRecordType"
                            showMandatoryAsterisk
                          >
                            Record Type
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="16"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="transactionDetailRecordType"
                              autoCorrect="off"
                              {...bankForm.register(
                                "transactionDetailRecordType"
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={bankForm.control}
                      name="amount"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label htmlFor="amount" showMandatoryAsterisk>
                            Amount
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="[Transaction Amount]"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="amount"
                              autoCorrect="off"
                              {...bankForm.register("amount")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={bankForm.control}
                      name="transactionDetailValueDate"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label
                            htmlFor="transactionDetailValueDate"
                            showMandatoryAsterisk
                          >
                            Value Date
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="[Payment File Processing Date]"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="transactionDetailValueDate"
                              autoCorrect="off"
                              {...bankForm.register(
                                "transactionDetailValueDate"
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={bankForm.control}
                      name="bankReference"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label htmlFor="bankReference">Bank Reference</Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="[Bank Transaction ID]"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="bankReference"
                              autoCorrect="off"
                              {...bankForm.register("bankReference")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={bankForm.control}
                      name="typeCode"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label htmlFor="typeCode" showMandatoryAsterisk>
                            Type Code
                          </Label>
                          <FormControl>
                            <Input
                              type="text"
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              autoCapitalize="none"
                              autoComplete="typeCode"
                              autoCorrect="off"
                              {...bankForm.register("typeCode")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={bankForm.control}
                      name="fundsType"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label htmlFor="fundsType" showMandatoryAsterisk>
                            Funds Type
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="V"
                              defaultValue="V"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="fundsType"
                              autoCorrect="off"
                              {...bankForm.register("fundsType")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={bankForm.control}
                      name="transactionDetailValueTime"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label htmlFor="transactionDetailValueTime">
                            Value Time
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="[Payment File Processing Time]"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="transactionDetailValueTime"
                              autoCorrect="off"
                              {...bankForm.register(
                                "transactionDetailValueTime"
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={bankForm.control}
                      name="customerReference"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label
                            htmlFor="customerReference"
                            showMandatoryAsterisk
                          >
                            Customer Reference
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="[Payment Number]"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="customerReference"
                              autoCorrect="off"
                              {...bankForm.register("customerReference")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <Paragraph className="text-xl font-bold">
                    88- Continuation
                  </Paragraph>
                  <div className="mb-5 mt-5 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-6">
                    <FormField
                      name="continuationRecordFields"
                      render={({field}) => (
                        <FormItem className="flex flex-col pt-2">
                          <Label htmlFor="continuationRecordFields">
                            Select Additional Fields
                          </Label>
                          <FormControl>
                            <FancyMultiSelect
                              {...field}
                              multiSelectData={continuationRecordOptions}
                              disabled={!continuationRecordOptions.length}
                              placeholder="Select Additional Fields..."
                              value={continuationRecordOptions.filter(
                                (option) =>
                                  selectedValues.includes(option.value)
                              )}
                              isFullWidth
                              onChange={(selectedOptions: Option[]) => {
                                handleChange(selectedOptions);
                                field.onChange(
                                  selectedOptions.map((option) => option.value)
                                );
                              }}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <Paragraph className="text-xl font-bold">
                    49- Account Trailer
                  </Paragraph>
                  <div className="mb-5 mt-5 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-6">
                    <FormField
                      control={bankForm.control}
                      name="accountTrailerRecordCode"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label
                            htmlFor="accountTrailerRecordCode"
                            showMandatoryAsterisk
                          >
                            Record Code
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="49"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="accountTrailerRecordCode"
                              autoCorrect="off"
                              {...bankForm.register("accountTrailerRecordCode")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={bankForm.control}
                      name="accountTrailerNumberOfRecords"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label
                            htmlFor="accountTrailerNumberOfRecords"
                            showMandatoryAsterisk
                          >
                            Number Of Records
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="accountTrailerNumberOfRecords"
                              autoCorrect="off"
                              {...bankForm.register(
                                "accountTrailerNumberOfRecords"
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={bankForm.control}
                      name="accountControlTotal"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label htmlFor="accountControlTotal">
                            Account Control Total
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="accountControlTotal"
                              autoCorrect="off"
                              {...bankForm.register("accountControlTotal")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <Paragraph className="text-xl font-bold">
                    98- Group Trailer
                  </Paragraph>
                  <div className="mb-5 mt-5 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-6">
                    <FormField
                      control={bankForm.control}
                      name="numberOfAccounts"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label
                            htmlFor="numberOfAccounts"
                            showMandatoryAsterisk
                          >
                            Number of Accounts
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="98"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="numberOfAccounts"
                              autoCorrect="off"
                              {...bankForm.register("numberOfAccounts")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={bankForm.control}
                      name="groupTrailerValueDate"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label
                            htmlFor="groupTrailerValueDate"
                            showMandatoryAsterisk
                          >
                            Value Date
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="groupTrailerValueDate"
                              autoCorrect="off"
                              {...bankForm.register("groupTrailerValueDate")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      control={bankForm.control}
                      name="groupTrailerNumberOfRecords"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label htmlFor="groupTrailerNumberOfRecords">
                            Number of Records
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="groupTrailerNumberOfRecords"
                              autoCorrect="off"
                              {...bankForm.register(
                                "groupTrailerNumberOfRecords"
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={bankForm.control}
                      name="groupTrailerValueTime"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label htmlFor="groupTrailerValueTime">
                            Value Time
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="groupTrailerValueTime"
                              autoCorrect="off"
                              {...bankForm.register("groupTrailerValueTime")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <Paragraph className="text-xl font-bold">
                    99- File Trailer
                  </Paragraph>
                  <div className="mb-5 mt-5 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-6">
                    <FormField
                      control={bankForm.control}
                      name="fileTrailerRecordCode"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label
                            htmlFor="fileTrailerRecordCode"
                            showMandatoryAsterisk
                          >
                            Record Code
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              placeholder="99"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="fileTrailerRecordCode"
                              autoCorrect="off"
                              {...bankForm.register("fileTrailerRecordCode")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={bankForm.control}
                      name="numberOfGroups"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label htmlFor="numberOfGroups" showMandatoryAsterisk>
                            Number Of Groups
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="numberOfGroups"
                              autoCorrect="off"
                              {...bankForm.register("numberOfGroups")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={bankForm.control}
                      name="fileControlTotal"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label htmlFor="fileControlTotal">
                            File Control Total
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="fileControlTotal"
                              autoCorrect="off"
                              {...bankForm.register("fileControlTotal")}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={bankForm.control}
                      name="fileTrailerNumberOfRecords"
                      render={() => (
                        <FormItem className="flex flex-col">
                          <Label htmlFor="fileTrailerNumberOfRecords">
                            Number Of Records
                          </Label>

                          <FormControl>
                            <Input
                              type="text"
                              disabled={true}
                              aria-disabled={true}
                              autoCapitalize="none"
                              autoComplete="fileTrailerNumberOfRecords"
                              autoCorrect="off"
                              {...bankForm.register(
                                "fileTrailerNumberOfRecords"
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </>
              ) : (
                <Table>
                  <TableCaption>
                    {fields.length == 0 && <p> No Data.</p>}
                  </TableCaption>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="relative" showMandatoryAsterisk>
                        Mapping Name
                      </TableHead>
                      <TableHead className="relative" showMandatoryAsterisk>
                        Order
                      </TableHead>
                      <TableHead className="relative" showMandatoryAsterisk>
                        Header Name
                      </TableHead>
                      <TableHead className="relative" showMandatoryAsterisk>
                        Data Length
                      </TableHead>
                      <TableHead>Delimiter Type</TableHead>
                      <TableHead>Date Format</TableHead>
                      <TableHead>Default Value</TableHead>
                      <TableHead className="relative" showMandatoryAsterisk>
                        Decimal Field
                      </TableHead>
                      <TableHead>Length Type</TableHead>
                      <TableHead className="text-right">Action</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {fields.map((item, index) => {
                      return (
                        <TableRow key={item.id}>
                          <TableCell className="w-80 font-medium">
                            <FormField
                              control={bankForm.control}
                              name={
                                `bankMappingRequests.${index.toString()}.mappingName` as `bankMappingRequests.${number}.mappingName`
                              }
                              render={() => (
                                <FormItem className="flex flex-col ">
                                  <FormControl>
                                    <Input
                                      placeholder="Mapping Name"
                                      {...bankForm.register(
                                        `bankMappingRequests.${index.toString()}.mappingName` as `bankMappingRequests.${number}.mappingName`,
                                        {
                                          required: true,
                                        }
                                      )}
                                      className="transition-all duration-300 focus:w-48 focus:outline-none"
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </TableCell>

                          <TableCell className="w-80 font-medium">
                            <FormField
                              control={bankForm.control}
                              name={
                                `bankMappingRequests.${index.toString()}.order` as `bankMappingRequests.${number}.order`
                              }
                              render={() => (
                                <FormItem className="flex flex-col ">
                                  <FormControl>
                                    <Input
                                      type="number"
                                      max="20"
                                      placeholder="Order"
                                      {...bankForm.register(
                                        `bankMappingRequests.${index.toString()}.order` as `bankMappingRequests.${number}.order`,
                                        {
                                          required: true,
                                        }
                                      )}
                                      className="transition-all duration-300 focus:w-48 focus:outline-none"
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </TableCell>

                          <TableCell className="w-80 font-medium">
                            <FormField
                              control={bankForm.control}
                              name={
                                `bankMappingRequests.${index.toString()}.headerName` as `bankMappingRequests.${number}.headerName`
                              }
                              render={() => (
                                <FormItem className="flex flex-col ">
                                  <FormControl>
                                    <Input
                                      placeholder="Header Name"
                                      {...bankForm.register(
                                        `bankMappingRequests.${index.toString()}.headerName` as `bankMappingRequests.${number}.headerName`,
                                        {
                                          required: true,
                                        }
                                      )}
                                      className="transition-all duration-300 focus:w-48 focus:outline-none"
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </TableCell>

                          <TableCell className="w-80 font-medium">
                            <FormField
                              control={bankForm.control}
                              name={
                                `bankMappingRequests.${index.toString()}.dataLength` as `bankMappingRequests.${number}.dataLength`
                              }
                              render={() => (
                                <FormItem className="flex flex-col ">
                                  <FormControl>
                                    <Input
                                      type="number"
                                      placeholder="Data Length"
                                      {...bankForm.register(
                                        `bankMappingRequests.${index.toString()}.dataLength` as `bankMappingRequests.${number}.dataLength`,
                                        {
                                          required: true,
                                        }
                                      )}
                                      className="transition-all duration-300 focus:w-48 focus:outline-none"
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </TableCell>

                          <TableCell className="w-80 font-medium">
                            <FormField
                              control={bankForm.control}
                              name={
                                `bankMappingRequests.${index.toString()}.delimiterTypeId` as `bankMappingRequests.${number}.delimiterTypeId`
                              }
                              render={({field}) => (
                                <Popover>
                                  <PopoverContent className="w-[260px] p-0">
                                    <Command>
                                      <CommandGroup>
                                        {delimiterTypeData?.map(
                                          (delimiterType, i) => (
                                            <CommandItem
                                              key={i}
                                              value={delimiterType.delimiterTypeId.toString()}
                                              onSelect={(delimiterTypeId) => {
                                                field.onChange(delimiterTypeId);
                                              }}
                                            >
                                              <CheckIcon
                                                className={cn(
                                                  "mr-2 size-4",
                                                  delimiterType.delimiterTypeId.toString() ===
                                                    field.value
                                                    ? "opacity-100"
                                                    : "opacity-0"
                                                )}
                                              />
                                              {delimiterType.value}
                                            </CommandItem>
                                          )
                                        )}
                                      </CommandGroup>
                                    </Command>
                                  </PopoverContent>
                                  <PopoverTrigger asChild>
                                    <FormControl>
                                      <Button
                                        variant="outline"
                                        role="combobox"
                                        className={cn(
                                          "justify-between",
                                          !field.value &&
                                            "text-accent-foreground",
                                          "w-[260px]"
                                        )}
                                      >
                                        {field.value ? (
                                          delimiterTypeData?.find(
                                            (delimiterType) =>
                                              delimiterType.delimiterTypeId
                                                .toString()
                                                .toLowerCase() === field.value
                                          )?.value
                                        ) : (
                                          <>Select delimiter type ID...</>
                                        )}

                                        <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                      </Button>
                                    </FormControl>
                                  </PopoverTrigger>
                                </Popover>
                              )}
                            />
                          </TableCell>

                          <TableCell className="w-80 font-medium">
                            <FormField
                              control={bankForm.control}
                              name={
                                `bankMappingRequests.${index.toString()}.dateFormatId` as `bankMappingRequests.${number}.dateFormatId`
                              }
                              render={({field}) => (
                                <Popover>
                                  <PopoverContent className="w-[180px] p-0">
                                    <Command>
                                      <CommandGroup>
                                        {DateFormatData?.map(
                                          (dateFormat, i) => (
                                            <CommandItem
                                              key={i}
                                              value={dateFormat.dateFormatId.toString()}
                                              onSelect={(dateFormatId) => {
                                                field.onChange(dateFormatId);
                                              }}
                                            >
                                              <CheckIcon
                                                className={cn(
                                                  "mr-2 size-4",
                                                  dateFormat.dateFormatId.toString() ===
                                                    field.value
                                                    ? "opacity-100"
                                                    : "opacity-0"
                                                )}
                                              />
                                              {dateFormat.dateFormatValue}
                                            </CommandItem>
                                          )
                                        )}
                                      </CommandGroup>
                                    </Command>
                                  </PopoverContent>
                                  <PopoverTrigger asChild>
                                    <FormControl>
                                      <Button
                                        variant="outline"
                                        role="combobox"
                                        className={cn(
                                          "justify-between",
                                          !field.value &&
                                            "text-accent-foreground",
                                          "w-[180px]"
                                        )}
                                      >
                                        {field.value ? (
                                          DateFormatData?.find(
                                            (dateFormat) =>
                                              dateFormat.dateFormatId
                                                .toString()
                                                .toLowerCase() === field.value
                                          )?.dateFormatValue
                                        ) : (
                                          <>Select date format ID...</>
                                        )}

                                        <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                      </Button>
                                    </FormControl>
                                  </PopoverTrigger>
                                </Popover>
                              )}
                            />
                          </TableCell>

                          <TableCell className="w-80 font-medium">
                            <FormField
                              control={bankForm.control}
                              name={
                                `bankMappingRequests.${index.toString()}.defaultValue` as `bankMappingRequests.${number}.defaultValue`
                              }
                              render={() => (
                                <FormItem className="flex flex-col">
                                  <FormControl>
                                    <Input
                                      placeholder="Default Value"
                                      {...bankForm.register(
                                        `bankMappingRequests.${index.toString()}.defaultValue` as `bankMappingRequests.${number}.defaultValue`,
                                        {
                                          required: true,
                                          setValueAs: (value): string | null =>
                                            typeof value === "string" &&
                                            value.trim() === ""
                                              ? null
                                              : (value as string),
                                        }
                                      )}
                                      className="transition-all duration-300 focus:w-48 focus:outline-none"
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </TableCell>

                          <TableCell className="w-80 font-medium">
                            <FormField
                              control={bankForm.control}
                              name={
                                `bankMappingRequests.${index.toString()}.isDecimalField` as `bankMappingRequests.${number}.isDecimalField`
                              }
                              render={({field}) => (
                                <FormItem className="space-y-4">
                                  <FormControl>
                                    <RadioGroup
                                      className="flex flex-col space-y-1"
                                      defaultValue="1"
                                      onValueChange={(value) => {
                                        field.onChange(value === "1");
                                      }}
                                    >
                                      <div className="flex items-center space-x-2">
                                        <FormItem className="flex items-center space-x-3 space-y-0">
                                          <FormControl>
                                            <RadioGroupItem value="1" />
                                          </FormControl>
                                          <FormLabel className="font-normal">
                                            Yes
                                          </FormLabel>
                                        </FormItem>
                                        <FormItem className="flex items-center space-x-3 space-y-0">
                                          <FormControl>
                                            <RadioGroupItem value="0" />
                                          </FormControl>
                                          <FormLabel className="font-normal">
                                            No
                                          </FormLabel>
                                        </FormItem>
                                      </div>
                                    </RadioGroup>
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </TableCell>

                          <TableCell className="w-80 font-medium">
                            <FormField
                              control={bankForm.control}
                              name={
                                `bankMappingRequests.${index.toString()}.fixedLengthType` as `bankMappingRequests.${number}.fixedLengthType`
                              }
                              render={({field}) => (
                                <Popover>
                                  <PopoverContent className="w-[180px] p-0">
                                    <Command>
                                      <CommandGroup>
                                        {lengthTypeOptions.map(
                                          (lengthType, i) => (
                                            <CommandItem
                                              key={i}
                                              value={lengthType.value.toUpperCase()}
                                              onSelect={(value) => {
                                                field.onChange(
                                                  value.toUpperCase()
                                                );
                                              }}
                                            >
                                              <CheckIcon
                                                className={cn(
                                                  "mr-2 size-4",
                                                  lengthType.value.toUpperCase() ===
                                                    field.value
                                                    ? "opacity-100"
                                                    : "opacity-0"
                                                )}
                                              />
                                              {lengthType.label}
                                            </CommandItem>
                                          )
                                        )}
                                      </CommandGroup>
                                    </Command>
                                  </PopoverContent>
                                  <PopoverTrigger asChild>
                                    <FormControl>
                                      <Button
                                        variant="outline"
                                        role="combobox"
                                        className={cn(
                                          "justify-between",
                                          !field.value &&
                                            "text-accent-foreground",
                                          "w-[180px]"
                                        )}
                                      >
                                        {field.value ? (
                                          lengthTypeOptions.find(
                                            (lengthType) =>
                                              lengthType.value.toUpperCase() ===
                                              field.value
                                          )?.label
                                        ) : (
                                          <>Select length type...</>
                                        )}

                                        <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                      </Button>
                                    </FormControl>
                                  </PopoverTrigger>
                                </Popover>
                              )}
                            />
                          </TableCell>

                          <TableCell className="text-right">
                            <Button
                              className="flex items-center gap-2 px-3"
                              variant="outline"
                              size="xs"
                              onClick={() => {
                                remove(index);
                              }}
                            >
                              <Trash2Icon className="size-4" />
                              <span className="sr-only">Delete</span>
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              )}

              <div className="space-x-2 p-2 text-right">
                <Link className="mb-4" to="/app/imremit/mapping">
                  <Button
                    type="button"
                    variant="input"
                    className="col-span-1 gap-2"
                  >
                    <span className="sr-only">Cancel</span>
                    <XIcon className="size-4" />
                    Cancel
                  </Button>
                </Link>
                <Button
                  type="submit"
                  disabled={(isMutating || !fields.length) && !isBAI2}
                  aria-disabled={isMutating}
                  variant="affirmative"
                  className="col-span-1 gap-2"
                >
                  <span className="sr-only">Save</span>
                  <SaveIcon className="size-4" />
                  {isMutating ? <Spinner size="xs" /> : ""} Save
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </>
  );
}
