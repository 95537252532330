import type Keycloak from "keycloak-js";
import {create} from "zustand";

// Defining the shape of the state object for the KeyCloakInstanceStore
// In this case, the state will consist of an array of KeyCloakInstance objects
interface KeyCloakInstanceStoreState {
  storeKeyCloakInstance: Keycloak | null;
}

// Defining the actions that can be performed on the KeyCloakInstanceStore state
// In this case, there's only one action, updateStoreKeyCloakInstance, which takes an array of KeyCloakInstance objects
interface KeyCloakInstanceStoreActions {
  updateStoreKeyCloakInstance: (
    storeKeyCloakInstance: KeyCloakInstanceStoreState["storeKeyCloakInstance"]
  ) => void;
}

// Creating the Zustand store using the create function
// The store is named useKeyCloakInstanceStore, and it uses the types defined above for its state and actions
// The state begins with an empty storeKeyCloakInstance array
// The updateStoreKeyCloakInstance action uses Zustand's set function to update the state
export const useKeyCloakInstanceStore = create<
  KeyCloakInstanceStoreState & KeyCloakInstanceStoreActions
>((set) => ({
  // Initial state of the store. An empty array of storeKeyCloakInstance.
  storeKeyCloakInstance: null,

  // Action to update the storeKeyCloakInstance array in the store.
  // This function takes a new storeKeyCloakInstance array and uses the set function to update the state.
  updateStoreKeyCloakInstance: (storeKeyCloakInstance) => {
    set(() => ({storeKeyCloakInstance}));
  },
}));
