import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import type {StatementReconGetDetailsType} from "@/modules/statement-recon";
import {statementReconStatementDetailsKeys} from "@/modules/statement-recon";

//Defining a type based on the function `getSRStatementDetailsByStatementID`
type QueryFnType = typeof getSRStatementDetailsByStatementID;

// Defining a function to fetch data from API
export function getSRStatementDetailsByStatementID(statementId: string) {
  // Fetch data from API using ky
  return kyApiFn<StatementReconGetDetailsType[]>(
    `statement-recon/v1/statements/${statementId}`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useSRStatementDetailsByStatementID(
  statementId: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: statementReconStatementDetailsKeys.byStatementID(statementId), // Unique key for cache 🗝
    queryFn: () => getSRStatementDetailsByStatementID(statementId), // Data fetching function 📡
    enabled: !!statementId, // Condition to enable the query 🚦
  });
}
