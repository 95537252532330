import {useEffect, useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {useNavigate} from "@tanstack/react-router";
import {
  CheckIcon,
  ChevronsUpDownIcon,
  MoveLeftIcon,
  MoveRightIcon,
  PlusIcon,
  SaveIcon,
  Trash2Icon,
} from "lucide-react";
import {useFieldArray, useForm, useFormContext} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {Card, CardContent} from "@/components/ui/card";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Spinner} from "@/components/ui/spinner";
import {Table, TableBody, TableCell, TableRow} from "@/components/ui/table";
import {Heading2, Heading3, Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {cn} from "@/lib/utils";
import {
  addCustomerEmailConfigurationFn,
  CustomerOnboardingTabs,
  EmailConfigurationDataTable,
  EmailConfigurationFormSchema,
  emailConfigurationTableColumns,
  useGetEmailConfigurations,
  useGetNotificationTypes,
} from "@/modules/admin";

import {FallbackMessages} from "@/utils/constants";

interface APIProps {
  isMutating: boolean;
  externalId: string;
  defaultValue?: string;
}

/**
 * EmailConfigurationFormProps Interface
 *
 * @interface EmailConfigurationFormProps
 * @property {number} externalId - Gets the customer ID.
 */
interface EmailConfigurationFormProps {
  externalId: string;
}

/**
 * Transforms a notification type item to a select option format.
 * @param item - The notification type item with id and optional name.
 * @returns An object with `value` as string and `label`.
 */
function toNotificationTypeOption(item: {
  notificationTypeId: number;
  subject?: string;
}) {
  return {
    value: item.notificationTypeId.toString(),
    label: item.subject ?? FallbackMessages.NOT_APPLICABLE,
  };
}

/**
 * Custom hook for fetching and memoizing notification types.
 * @returns A memoized array of notification types options.
 */
export function useNotificationTypes() {
  const {data} = useGetNotificationTypes();

  // Memoizes the transformed notification types data for performance optimization
  return useMemo(
    () => data?.content.map(toNotificationTypeOption) ?? [],
    [data]
  );
}

/**
 * EmailConfigurationForm Component
 *
 * This component renders the EmailConfigurationForm form and handles its behavior.
 *
 * @param {EmailConfigurationFormProps} props - Properties passed to the component
 * @returns {JSX.Element} - Rendered component
 */

export function EmailConfigurationForm({
  externalId,
}: EmailConfigurationFormProps) {
  // Define form
  const addEmailConfigurationForm = useForm<
    z.infer<typeof EmailConfigurationFormSchema>
  >({
    defaultValues: {
      alertSend: true,
      participantId: externalId,
    },
    mode: "onChange",
    resolver: zodResolver(EmailConfigurationFormSchema),
  });

  // set Add Mutation function
  const addEmailConfigurationMutation = useAddEmailConfiguration();
  const notificationTypes = useNotificationTypes();
  function handleAddEmailConfiguration(
    data: z.infer<typeof EmailConfigurationFormSchema>
  ) {
    const {recipientEmails, notificationTypeId} = data;

    // Static emails to always include
    const staticEmails = [
      "ePay.Manager@IterationM.com",
      "Production.Support@IterationM.com",
    ];

    // Check for duplicate emails in recipientEmails and staticEmails
    const allEmails = [...recipientEmails, ...staticEmails];
    const emailSet = new Set(allEmails);
    if (emailSet.size < allEmails.length) {
      toast({
        variant: "destructive",
        title: "Duplicate emails found!",
        description: "Please remove any duplicate email addresses.",
      });
      return; // Exit early if duplicates are found
    }

    // Combine recipientEmails and staticEmails, ensuring no duplicates using Set
    const combinedEmails = Array.from(
      new Set([...recipientEmails, ...staticEmails])
    );

    // Create a new data object with the updated recipientEmails
    const updatedData = {
      ...data,
      recipientEmails: combinedEmails,
    };

    // Check if the notification label includes "Unsuccessful"
    const hasUnsuccessfulLabel =
      notificationTypes
        .find((type) => type.value === notificationTypeId)
        ?.label.includes("Unsuccessful") ?? false;

    // Check if recipientEmails has at least one email
    if (recipientEmails.length === 0 && !hasUnsuccessfulLabel) {
      toast({
        variant: "destructive",
        title: "Unable to save email configuration!",
        description: "Please ensure there is at least one added email.",
      });
      return; // Exit early if validation fails
    }

    // Conditionally mutate with updatedData or original data
    if (hasUnsuccessfulLabel) {
      addEmailConfigurationMutation.mutate(updatedData);
    } else {
      addEmailConfigurationMutation.mutate(data);
    }
  }

  // Get the email configurations query hook
  const EmailConfigurationQuery = useGetEmailConfigurations(externalId);

  const EmailConfigurationData = useMemo(
    () =>
      EmailConfigurationQuery.data?.content.map((data) => ({
        ...data,
        notificationName:
          notificationTypes.find((type) => {
            return type.value.toString() === data.notificationTypeId.toString();
          })?.label ?? "",
        recipientEmail: data.recipientEmails.join(", "),
      })) ?? [],
    [EmailConfigurationQuery.data, notificationTypes]
  );

  console.log("EmailConfigurationData", EmailConfigurationData);

  // Add Navigation params
  const navigate = useNavigate();
  const params = {
    externalId: externalId.toString(),
  };

  return (
    <section>
      <div className="mb-2 flex w-full flex-col items-start justify-between gap-2 lg:flex-row lg:items-center">
        <Heading2 className="flex gap-2">Email Configuration</Heading2>
        <CustomerOnboardingTabs />
      </div>

      <Card>
        <CardContent>
          <Form {...addEmailConfigurationForm}>
            <form
              onSubmit={addEmailConfigurationForm.handleSubmit(
                handleAddEmailConfiguration
              )}
            >
              <div className="mb-5 mt-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
                <NotificationTypesField
                  isMutating={addEmailConfigurationMutation.isPending}
                  externalId={externalId}
                />
                <RecipientEmailsField
                  isMutating={addEmailConfigurationMutation.isPending}
                />
              </div>
              <div className="flex items-center justify-end space-x-2 p-2">
                <Button
                  type="submit"
                  disabled={addEmailConfigurationMutation.isPending}
                  aria-disabled={addEmailConfigurationMutation.isPending}
                  variant="affirmative"
                  className="col-span-1 gap-2"
                >
                  {addEmailConfigurationMutation.isPending ? (
                    <Spinner size="xs" />
                  ) : (
                    ""
                  )}
                  Save Email Configuration
                  <SaveIcon className="size-4" />
                </Button>
              </div>
            </form>
          </Form>
          <EmailConfigurationDataTable
            columns={emailConfigurationTableColumns()}
            data={EmailConfigurationData}
            isSuccess={EmailConfigurationQuery.isSuccess}
            isPending={EmailConfigurationQuery.isPending}
            isError={EmailConfigurationQuery.isError}
          />
          <div className="flex items-center justify-end space-x-2 p-2">
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                void navigate({
                  to: "/app/admin/customer-management/$externalId/edit",
                  params,
                  replace: true,
                });
              }}
            >
              <span className="sr-only">Previous page</span>
              <MoveLeftIcon className="mr-2" />
              Previous
            </Button>
            <Button
              // disabled={EmailConfigurationData.length == 0}
              type="button"
              onClick={() => {
                void navigate({
                  to: "/app/admin/customer-management/imremit/$externalId/onboarding/payment-method",
                  params,
                  replace: true,
                });
              }}
            >
              Next
              <MoveRightIcon className="ml-2" />
              <span className="sr-only">Next page</span>
            </Button>
          </div>
        </CardContent>
      </Card>
    </section>
  );
}

/**
 * Custom hook for performing the 'Add Email Configuration' mutation.
 * This hook abstracts the mutation logic and side effects like toast notifications.
 * @param {string | undefined} externalId - The ID of the customer.
 * @returns A mutation object with methods to trigger the mutation.
 */
function useAddEmailConfiguration() {
  return useMutation({
    mutationFn: (data: z.infer<typeof EmailConfigurationFormSchema>) => {
      // Perform the mutation using the addEmailConfigurationFn function
      return addCustomerEmailConfigurationFn(data);
    },
    onSuccess: (response) => {
      // Handle successful mutation
      console.log("addEmailConfigurationResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Email configuration added successfully",
      });
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add email configuration",
      });
    },
  });
}

export function NotificationTypesField({
  isMutating,
  defaultValue,
  externalId,
}: APIProps) {
  const {control, setValue, watch} = useFormContext();

  // Get the available notification types
  const allNotificationTypes = useNotificationTypes();
  const [availableNotificationTypes, setAvailableNotificationTypes] =
    useState(allNotificationTypes);

  // Get the email configurations query hook
  const EmailConfigurationQuery = useGetEmailConfigurations(externalId);

  const EmailConfigurationData = useMemo(
    () => EmailConfigurationQuery.data?.content ?? [],
    [EmailConfigurationQuery.data]
  );

  // Use useEffect to filter notification types whenever EmailConfigurationQuery changes
  useEffect(() => {
    const filteredNotificationTypes = allNotificationTypes.filter(
      (notificationType) =>
        !EmailConfigurationData.some(
          (emailConfig) =>
            emailConfig.notificationTypeId.toString() === notificationType.value
        )
    );

    setAvailableNotificationTypes(filteredNotificationTypes);
  }, [EmailConfigurationData, allNotificationTypes]);

  useEffect(() => {
    if (defaultValue) {
      setValue("notificationTypeId", defaultValue.toString());
    }
  }, [defaultValue, setValue]);

  // Reset recipient emails when notificationTypeId changes
  useEffect(() => {
    const subscription = watch((_, {name}) => {
      if (name === "notificationTypeId") {
        setValue("recipientEmails", []); // Reset recipient emails
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [watch, setValue]);

  return (
    <FormField
      control={control}
      name="notificationTypeId"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="notificationTypeId" showMandatoryAsterisk>
            Email Subject:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {availableNotificationTypes.some(
                    (option) => option.value === field.value
                  ) &&
                  availableNotificationTypes.find(
                    (option) => option.value === field.value
                  )?.label
                    ? availableNotificationTypes.find(
                        (option) => option.value === field.value
                      )?.label
                    : "Select Email Subject..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Email Subject..." />
                <CommandEmpty>No Email Subjects found.</CommandEmpty>
                <CommandGroup>
                  {availableNotificationTypes.map((option) => (
                    <CommandItem
                      key={option.value}
                      value={option.label}
                      onSelect={() => {
                        setValue("notificationTypeId", option.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          option.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {option.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function RecipientEmailsField({
  isMutating,
  defaultEmails,
}: {
  isMutating: boolean;
  defaultEmails?: string[];
}) {
  const {
    control,
    register,
    setValue,
    watch,
    formState: {touchedFields},
  } = useFormContext();
  const allNotificationTypes = useNotificationTypes();
  const {fields, append, remove} = useFieldArray({
    control,
    name: "recipientEmails",
  });

  const recipientEmails = watch("recipientEmails") as string[];

  const hasUnsuccessfulLabel =
    allNotificationTypes
      .find((type) => type.value === watch("notificationTypeId"))
      ?.label.includes("Unsuccessful") ?? false;

  // Check if recipientEmails is an array and find empty fields
  const hasEmptyField = Array.isArray(recipientEmails)
    ? recipientEmails.some((email: string) => !email)
    : false;

  const staticEmails = useMemo(
    () => [
      {id: "1", email: "ePay.Manager@IterationM.com"},
      {id: "2", email: "Production.Support@IterationM.com"},
    ],
    []
  );

  // Set default values directly in the component
  useEffect(() => {
    if (defaultEmails && defaultEmails.length > 0) {
      // Filter out any static emails that are already in defaultEmails
      const filteredEmails = defaultEmails.filter(
        (email) =>
          !staticEmails.some((staticEmail) => staticEmail.email === email)
      );

      if (hasUnsuccessfulLabel) {
        setValue("recipientEmails", filteredEmails);
      } else {
        setValue("recipientEmails", defaultEmails);
      }
    }
  }, [setValue, defaultEmails, staticEmails, hasUnsuccessfulLabel]);

  return (
    <Card>
      <CardContent>
        <div className="mb-4 mt-4 flex items-center justify-between">
          <Heading3 className="flex items-center">Recipient Emails</Heading3>
          <Button
            type="button"
            disabled={isMutating || fields.length >= 10 || hasEmptyField}
            onClick={() => {
              append("");
            }}
          >
            <PlusIcon className="mr-2 size-4" /> Add
          </Button>
        </div>

        <Table>
          <TableBody>
            {/* Render static emails */}
            {hasUnsuccessfulLabel &&
              staticEmails.map((email) => (
                <TableRow key={email.id}>
                  <TableCell className="w-80 font-medium">
                    <FormItem className="flex flex-col">
                      <FormControl>
                        <Input
                          disabled={true} // Always disabled for static emails
                          value={email.email} // Display static email
                          placeholder="Enter the recipient email..."
                        />
                      </FormControl>
                    </FormItem>
                  </TableCell>
                  <TableCell className="text-right">
                    <Button
                      type="button"
                      className="flex items-center gap-2 px-3"
                      variant="outline"
                      size="xs"
                      disabled // Disabled for static emails
                    >
                      <Trash2Icon className="size-4" />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}

            {/* Render dynamic email fields */}
            {fields.map((field, index) => {
              const currentEmail = recipientEmails[index] || "";
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
              const isError =
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                touchedFields.recipientEmails?.[index] &&
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                !currentEmail;

              return (
                <TableRow key={field.id}>
                  <TableCell className="w-full font-medium">
                    <FormField
                      control={control}
                      name={`recipientEmails.${index.toString()}`}
                      render={() => (
                        <FormItem className="flex w-full flex-col">
                          <FormControl>
                            <Input
                              className="w-full"
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              placeholder="Enter the recipient email..."
                              {...register(
                                `recipientEmails.${index.toString()}`
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    {isError && (
                      <Paragraph className="mt-1 text-red-500">
                        Please fill in this email field.
                      </Paragraph>
                    )}
                  </TableCell>
                  <TableCell className="w-20 text-right align-top">
                    <Button
                      type="button"
                      className="flex h-10 items-center gap-2 px-3 hover:border-destructive-foreground hover:bg-destructive hover:text-destructive-foreground"
                      variant="outline"
                      onClick={(e) => {
                        e.preventDefault();
                        remove(index);
                      }}
                    >
                      <Trash2Icon className="size-4" />
                      <span className="sr-only">Delete</span>
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
