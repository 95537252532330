// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type DupesTrackerTableColumnsType} from "@/modules/duplicate-payments";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface DupesTrackerTableColumnsStoreState {
  storeDupesTrackerTableColumns: DupesTrackerTableColumnsType | null;
  setStoreDupesTrackerTableColumns: (
    storeDupesTrackerTableColumns: DupesTrackerTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for DupesTracker table columns
export const useDupesTrackerTableColumnsStore = create(
  persist<DupesTrackerTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeDupesTrackerTableColumns: null,

      // Getter function to retrieve the current state
      getStoreDupesTrackerTableColumns: () =>
        get().storeDupesTrackerTableColumns,

      // Setter function to update the state
      setStoreDupesTrackerTableColumns: (storeDupesTrackerTableColumns) => {
        set(() => ({storeDupesTrackerTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "DupesTracker-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useDupesTrackerTableColumns = () =>
  useDupesTrackerTableColumnsStore((state) => {
    return {
      storeDupesTrackerTableColumns: state.storeDupesTrackerTableColumns,
    };
  });
