import {z} from "zod";

export const statementFileSchema = z.object({
  buyer_external_id: z.string().nullable(),
  statement_file_id: z.number(),
  statement_file_location: z.string().nullable(),
  user_statement_file_name: z.string().nullable(),
  statement_file_name: z.string().nullable(),
  statement_file_size: z.string().nullable(),
  statement_file_mime_type: z.string().nullable(),
  statement_file_status: z.number().nullable(),
  statement_uploaded_by: z.string().nullable(),
  supplier_ids: z.string().nullable(),
  created_timestamp: z.string(),
  updated_timestamp: z.string().nullable(),
});

export type StatementFileType = z.infer<typeof statementFileSchema>;

export const statementSearchSupplierSchema = z.object({
  supplier_id: z.number().nullable(),
  supplier_name: z.string().nullable(),
});

export type StatementSearchSupplierType = z.infer<
  typeof statementSearchSupplierSchema
>;

export const StatementSearchTableSchema = statementFileSchema
  .omit({
    supplier_ids: true,
  })
  .extend({
    suppliers: z.array(statementSearchSupplierSchema),
  });

export type StatementSearchTableType = z.infer<
  typeof StatementSearchTableSchema
>;

export const ReconciledStatementResultSchema = z.object({
  user_statement_invoice_number: z.string(),
  user_statement_invoice_date: z.string(),
  user_statement_invoice_amount: z.string(),
  user_statement_po_number: z.string().nullable(),
  // enum this
  im_sr_match_type: z.string(),
  supplier_number: z.string().nullable(),
  supplier_name: z.string().nullable(),
  invoice_amount: z.string(),
  invoice_date: z.string(),
  invoice_paid_amount: z.string(),
  invoice_payment_date: z.string(),
  created_timestamp: z.string(),
  facility_id: z.string(),
  invoice_id: z.string(),
  supplier_id: z.string(),
  updated_timestamp: z.string().nullable(),
  invoice_erp_unique_id: z.string(),
  invoice_number: z.string(),
  invoice_payment_method: z.string(),
  invoice_payment_number: z.string(),
  invoice_po_number: z.string().nullable(),
  buyer_id: z.string(),
  invoice_status_id: z.string(),
  invoice_status_name: z.string(),
  suffix: z.string().nullable(),
  compared: z.boolean(),
  credit_pair: z.boolean(),
  cancel_id: z.string().nullable(),
  cancel_date: z.string().nullable(),
  invoice_type: z.string().nullable(),
  payment_status: z.string().nullable(),
  insert_date: z.string(),
  update_date: z.string(),
  payment_term: z.string().nullable(),
  void_id: z.string().nullable(),
  void_date: z.string().nullable(),
});

export type ReconciledStatementResultType = z.infer<
  typeof ReconciledStatementResultSchema
>;

export const ReconciledStatementResultColumnsSchema = z.object({
  user_statement_invoice_number: z.boolean(),
  user_statement_invoice_date: z.boolean(),
  user_statement_invoice_amount: z.boolean(),
  user_statement_po_number: z.boolean(),
  // enum this
  im_sr_match_type: z.boolean(),
  supplier_number: z.boolean(),
  supplier_name: z.boolean(),
  invoice_amount: z.boolean(),
  invoice_date: z.boolean(),
  invoice_paid_amount: z.boolean(),
  invoice_payment_date: z.boolean(),
  created_timestamp: z.boolean(),
  facility_id: z.boolean(),
  invoice_id: z.boolean(),
  supplier_id: z.boolean(),
  updated_timestamp: z.boolean(),
  invoice_erp_unique_id: z.boolean(),
  invoice_number: z.boolean(),
  invoice_payment_method: z.boolean(),
  invoice_payment_number: z.boolean(),
  invoice_po_number: z.boolean(),
  buyer_id: z.boolean(),
  invoice_status_id: z.boolean(),
  suffix: z.boolean(),
  compared: z.boolean(),
  credit_pair: z.boolean(),
  cancel_id: z.boolean(),
  cancel_date: z.boolean(),
  invoice_type: z.boolean(),
  payment_status: z.boolean(),
  insert_date: z.boolean(),
  update_date: z.boolean(),
  payment_term: z.boolean(),
  void_id: z.boolean(),
  void_date: z.boolean(),
});

export type ReconciledStatementResultColumnsType = z.infer<
  typeof ReconciledStatementResultColumnsSchema
>;
