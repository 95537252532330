import {createRoute} from "@tanstack/react-router";

import {RoutesDirectory} from "@/lib/routes/directory";
import {
  ImREmitLiteDashboardPage,
  imREmitLiteRootRoute,
} from "@/modules/imremit-lite";

export const imREmitLiteDashboardRoute = createRoute({
  path: RoutesDirectory.DASHBOARD,
  component: ImREmitLiteDashboardPage,
  getParentRoute: () => imREmitLiteRootRoute,
});
