import {Navigate, Outlet, useRouter} from "@tanstack/react-router";

import {Separator} from "@/components/ui/separator";
import {Heading1, Paragraph} from "@/components/ui/typography";
import {ReturnToTop} from "@/components/craft/return-to-top";

import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";
import {ImREmitLiteSubrouteNavigation} from "@/modules/imremit-lite";

// Define the ImREmitLiteOutlet component
export function ImREmitLiteOutlet() {
  // Get pathname of user's current route
  const {pathname} = useRouter().latestLocation;

  // If the current route pathname is "/app/imremit-lite",
  // navigate to "/app/imremit-lite/imremit-dashboard" without replacing the current entry in the history stack
  if (pathname === "/app/imremit-lite" || pathname === "/app/imremit-lite/") {
    return <Navigate to="/app/imremit-lite/dashboard" />;
  }

  // Return the JSX for the ImREmitLiteOutlet component
  return (
    <>
      <article
        className="w-full flex-1 bg-background"
        role="region"
        aria-roledescription="This is the imREmit level one subroute. View your customer's imREmit data here."
      >
        {/* This section will contain the heading and description, as well as the subroute navigation */}
        <section
          className="mb-2 flex flex-col justify-between gap-4 xl:flex-row xl:items-center"
          role="presentation"
        >
          {/* This div will contain the heading and description */}
          <div className="mb-2 flex flex-col">
            <Heading1>
              imREmit{" "}
              <span className="text-xl uppercase text-success-foreground">
                &#40;lite&#41;
              </span>
            </Heading1>{" "}
            {/* The heading of the page */}
            <Paragraph>
              This is the imREmit lite level one subroute. View your customer's
              remittance data here.
            </Paragraph>{" "}
            {/* The description of the page */}
          </div>
          {/* This will display the navigation for subroutes */}
          <ImREmitLiteSubrouteNavigation />
        </section>

        {/* Separator component to create a visual break in content */}
        <Separator className="mb-2" />

        {/* Outlet for nested routes to display their content */}
        <AccessPolicyWrapper
          policyActions={[KeycloakRoleEnum.READ_IMREMIT_LITE]}
        >
          <Outlet />
        </AccessPolicyWrapper>
      </article>
      <ReturnToTop />
    </>
  );
}
