import {useEffect, useState} from "react";

import {Link, useParams} from "@tanstack/react-router";
import {ArrowRightIcon, MessagesSquareIcon} from "lucide-react";
import {useFormContext} from "react-hook-form";

import {Button, buttonVariants} from "@/components/ui/button";
import {Label} from "@/components/ui/label";
import {RadioGroup, RadioGroupItem} from "@/components/ui/radio-group";
import {Heading2, Paragraph} from "@/components/ui/typography";
import {LoadingSkeletonCard} from "@/components/craft/loading-skeleton-card";

import {cn} from "@/lib/utils";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";
import {CommentsForm, useGetSuppliersDetails} from "@/modules/imremit";
import {DeclinedForm, EnrolledForm} from "@/modules/imremit-lite";

/**
 * EditSupplierFormProps Interface
 *
 * @interface EditSupplierFormProps
 * @property {boolean} isMutating - Indicates whether the mutation is in progress.
 * @property {Function} onSubmit - The function to be called upon form submission.
 */

/**
 * EditSupplierForm Component
 *
 * This component renders the password forgot form and handles its behavior.
 *
 * @param {EditSupplierFormProps} props - Properties passed to the component
 * @returns {JSX.Element} - Rendered component
 */

function EditSupplierDetailsForm() {
  const {supplierId} = useParams({
    from: "/app/imremit-lite/supplier-management/$supplierId/edit",
  });

  const [enrolledStatus, setEnrolledStatus] = useState("declined");

  const searchSupplierIDQuery = useGetSuppliersDetails(Number(supplierId));
  const searchSupplierData = searchSupplierIDQuery.data?.content;

  useEffect(() => {
    if (searchSupplierIDQuery.data?.content.enrolled) {
      const enrolled = searchSupplierIDQuery.data.content.enrolled as boolean;
      enrolled ? setEnrolledStatus("enrolled") : setEnrolledStatus("declined");
    }
  }, [searchSupplierIDQuery.data]);

  if (searchSupplierIDQuery.isPending) {
    return (
      <>
        <Heading2>Edit Supplier</Heading2>
        <Paragraph>Edit supplier details and enrollment status</Paragraph>
        <LoadingSkeletonCard classNames="mt-8" lineCount={14} />
      </>
    );
  }

  return (
    <section>
      <div className="mb-4 flex flex-col-reverse gap-4 md:flex-row md:justify-between">
        <div className="flex flex-col">
          <Heading2>Edit Supplier</Heading2>
          <Paragraph>Edit supplier details and enrollment status</Paragraph>
        </div>
        <div className="flex flex-grow justify-end gap-2">
          <div className="flex flex-col gap-2">
            {searchSupplierData ? (
              <CommentsForm id={supplierId} />
            ) : (
              <Button
                className="h-10 gap-2"
                size="sm"
                variant="outline"
                disabled={true}
              >
                <MessagesSquareIcon className="size-4" />
                <Paragraph>Comments</Paragraph>
              </Button>
            )}
          </div>
          <Link
            to="/app/imremit-lite/supplier-management"
            className={cn(buttonVariants({variant: "secondary"}), "gap-0")}
          >
            Back to list
            <span className="sr-only">Back to list</span>
            <ArrowRightIcon className="size-4" />
          </Link>
        </div>
      </div>

      <div className="mb-8 rounded-md border border-border bg-root p-4">
        {enrolledStatus === "enrolled" && (
          <EnrolledForm>
            <SupplierEnrollment
              enrolledStatus={enrolledStatus}
              setEnrolledStatus={setEnrolledStatus}
            />
          </EnrolledForm>
        )}
        {enrolledStatus === "declined" && (
          <DeclinedForm>
            <SupplierEnrollment
              enrolledStatus={enrolledStatus}
              setEnrolledStatus={setEnrolledStatus}
            />
          </DeclinedForm>
        )}
      </div>
    </section>
  );
}

export function EditSupplierDetailsPage() {
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.UPDATE_SUPPLIER_MANAGEMENT_LITE]}
    >
      <EditSupplierDetailsForm />
    </AccessPolicyWrapper>
  );
}

function SupplierEnrollment({
  enrolledStatus,
  setEnrolledStatus,
}: {
  enrolledStatus: string;

  setEnrolledStatus: (enrolledStatus: string) => void;
}) {
  const {setValue} = useFormContext();

  return (
    <div className="flex flex-row space-x-3 space-y-6">
      <RadioGroup>
        <Label showMandatoryAsterisk>Supplier Enrollment:</Label>

        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value="enrolled-yes"
            id="enrolled-yes"
            checked={enrolledStatus === "enrolled" ? true : false}
            onClick={() => {
              setEnrolledStatus("enrolled");
              setValue("enrolled", true);
            }}
          />
          <Label htmlFor="enrolled-yes"> Yes </Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value="declined-yes"
            id="declined-yes"
            checked={enrolledStatus === "declined" ? true : false}
            onClick={() => {
              setEnrolledStatus("declined");
              setValue("enrolled", false);
            }}
          />
          <Label htmlFor="declined-yes"> No </Label>
        </div>
      </RadioGroup>
    </div>
  );
}
