import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {type PaymentMappingListType} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";

export const paymentFileColumns: ColumnDef<PaymentMappingListType>[] = [
  {
    accessorKey: "masterMappingName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Mapping Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("masterMappingName") ? (
          row.getValue("masterMappingName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "paymentNumber",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment Number" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("paymentNumber") ? (
          row.getValue("paymentNumber")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "accountNumber",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Account Number" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("accountNumber") ? (
          row.getValue("accountNumber")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "totalAmountPaid",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Total Paid" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("totalAmountPaid") ? (
          row.getValue("totalAmountPaid")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "paymentDate",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment Date" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("paymentDate") ? (
          <span>{row.getValue("paymentDate")}</span>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "adjustmentAmount",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Adjustment Amount" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("adjustmentAmount") ? (
          <span>{row.getValue("adjustmentAmount")}</span>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "currency",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Currency" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("currency") ? (
          row.getValue("currency")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "paymentMethod",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment Method" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("paymentMethod") ? (
          row.getValue("paymentMethod")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "supplierNumber",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Supplier Number" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("supplierNumber") ? (
          row.getValue("supplierNumber")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "supplierName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Supplier Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("supplierName") ? (
          row.getValue("supplierName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "addressLine1",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Address Line1" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("addressLine1") ? (
          row.getValue("addressLine1")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "addressLine2",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Address Line2" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("addressLine2") ? (
          row.getValue("addressLine2")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "city",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="City" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("city") ? (
          row.getValue("city")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "state",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="State" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("state") ? (
          row.getValue("state")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "zip",
    header: ({column}) => <DataTableColumnHeader column={column} title="Zip" />,
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("zip") ? (
          row.getValue("zip")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "locationCode",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Location Code" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("locationCode") ? (
          row.getValue("locationCode")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "invoiceNumber",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Number" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoiceNumber") ? (
          row.getValue("invoiceNumber")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "invoiceDate",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Date" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoiceDate") ? (
          <span>{row.getValue("invoiceDate")}</span>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "invoiceAmount",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Amount" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoiceAmount") ? (
          row.getValue("invoiceAmount")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "discountAmount",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Discount Amount" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("discountAmount") ? (
          row.getValue("discountAmount")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "totalAmount",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Total Amount" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("totalAmount") ? (
          row.getValue("totalAmount")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "poNumber",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="PO Number" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("poNumber") ? (
          row.getValue("poNumber")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "invoiceType",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Type" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoiceType") ? (
          row.getValue("invoiceType")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "invoiceComments",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Comments" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoiceComments") ? (
          row.getValue("invoiceComments")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "udf1",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="UDF1" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("udf1") ? (
          row.getValue("udf1")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "udf2",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="UDF2" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("udf2") ? (
          row.getValue("udf2")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "udf3",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="UDF3" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("udf3") ? (
          row.getValue("udf3")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "paymentZip",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment Zip" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("paymentZip") ? (
          row.getValue("paymentZip")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },

  {
    accessorKey: "paymentFileIdentifier",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="File Identifier" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("paymentFileIdentifier") ? (
          row.getValue("paymentFileIdentifier")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "invoiceFutureUse1",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Future Use 1" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoiceFutureUse1") ? (
          row.getValue("invoiceFutureUse1")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  // {
  //   id: "actions",
  //   cell: ({row}) => <PaymentFileRowActions row={row} />,
  // },
];
