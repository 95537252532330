import {
  createContext,
  Fragment,
  useContext,
  useMemo,
  useState,
  type CSSProperties,
  type PropsWithChildren,
} from "react";

import {
  defaultDropAnimationSideEffects,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type Active,
  type DraggableSyntheticListeners,
  type DropAnimation,
  type UniqueIdentifier,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
} from "@dnd-kit/sortable";
import {CSS} from "@dnd-kit/utilities";
import {GripVerticalIcon} from "lucide-react"; // Importing an icon for drag handle.

import {Heading3} from "@/components/ui/typography"; // Importing a heading component.

// Type definition for individual sortable timeline items.
interface SortableTimelineItemProps {
  sortableItemId: UniqueIdentifier; // Unique identifier for the sortable item.
  sortableItemPosition: string; // Position or timestamp associated with the item.
  sortableItemTitle: string; // Title or description of the sortable item.
}

// Context type for the sortable item attributes.
interface SortableItemContextType {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attributes: Record<string, any>; // HTML attributes for the sortable item.
  listeners: DraggableSyntheticListeners; // Event listeners related to drag and drop.
  ref(node: HTMLElement | null): void; // Ref function to retrieve the reference to the DOM node.
}

// Creating a React context to share sortable item information.
const SortableTimelineContext = createContext<SortableItemContextType>({
  attributes: {},
  listeners: undefined,
  ref() {},
});

// Component for an individual sortable timeline item.
export function SortableTimelineItem({
  children,
  sortableItemId,
  sortableItemPosition,
  sortableItemTitle,
}: PropsWithChildren<SortableTimelineItemProps>) {
  // Hooks to manage drag-and-drop functionality for a specific item.
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({
    id: sortableItemId,
    transition: {
      duration: 150, // milliseconds
      easing: "cubic-bezier(0.25, 1, 0.5, 1)",
    },
  });

  // Memoizing the context values to prevent unnecessary re-renders.
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef]
  );

  // Styling for the draggable item, includes handling opacity during dragging.
  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  // Rendering the sortable item, with all required children and stylings.
  return (
    <SortableTimelineContext.Provider value={context}>
      <li ref={setNodeRef} className="mb-10 ml-4" style={style}>
        {/* Various visual elements for the sortable item, including time, heading, and any additional children. */}
        <div className="absolute -left-1.5 mt-1.5 h-3 w-3 rounded-full border border-white bg-success-foreground/60 dark:border-success-foreground dark:bg-success-foreground" />
        <time className="mb-1 text-sm font-normal leading-none text-success-foreground dark:text-success-foreground">
          {sortableItemPosition}
        </time>
        <Heading3 className="text-lg font-semibold dark:text-white">
          {sortableItemTitle}
        </Heading3>
        {children}
      </li>
    </SortableTimelineContext.Provider>
  );
}

// Component for a drag handle, using the context information from the parent sortable item.
export function DragHandle() {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const {attributes, listeners, ref} = useContext(SortableTimelineContext);

  return (
    <button
      className="rounded-full border border-muted bg-root hover:bg-neutral data-[state=open]:bg-secondary"
      {...attributes}
      {...listeners}
      ref={ref}
    >
      <span className="sr-only">Drag to reorder</span>
      <GripVerticalIcon className="m-1 size-6 bg-inherit" />
    </button>
  );
}

// Configuration for drop animation, including opacity side effect.
const dropAnimationConfig: DropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: "0.4",
      },
    },
  }),
};

// Type definition for overlay properties (empty in this case).
interface SortableTimelineOverlayProps {}

// Component to render the drag overlay with the configured drop animation.
export function SortableTimelineOverlay({
  children,
}: PropsWithChildren<SortableTimelineOverlayProps>) {
  return (
    <DragOverlay dropAnimation={dropAnimationConfig}>{children}</DragOverlay>
  );
}

// Type definition for timeline sortable items with unique identifiers.
interface TimelineSortableItem {
  id: UniqueIdentifier;
}

// Type definition for the sortable timeline component props.
interface SortableTimelineProps<T extends TimelineSortableItem> {
  items: T[]; // Array of sortable items.
  onChange(items: T[]): void; // Callback function when items are rearranged.
  renderItem(item: T): React.ReactNode; // Function to render individual items.
}

// Main component for the sortable timeline, using drag-and-drop functionality.
export function SortableTimeline<T extends TimelineSortableItem>({
  items,
  onChange,
  renderItem,
}: SortableTimelineProps<T>) {
  const [active, setActive] = useState<Active | null>(null); // State for active (dragged) item.
  const activeItem = useMemo(
    () => items.find((item) => item.id === active?.id),
    [active, items]
  );

  // Sensors for pointer and keyboard drag-and-drop interactions.
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Rendering the entire sortable timeline, including drag context, sortable context, and drag overlay.
  return (
    <DndContext
      sensors={sensors}
      onDragStart={({active}) => {
        setActive(active);
      }}
      onDragEnd={({active, over}) => {
        // Handling item reordering on drag end.
        if (over && active.id !== over.id) {
          const activeIndex = items.findIndex(({id}) => id === active.id);
          const overIndex = items.findIndex(({id}) => id === over.id);

          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext items={items}>
        {/* Rendering individual sortable items. */}
        <ol className="relative border-l border-foreground dark:border-gray-700">
          {items.map((item) => (
            <Fragment key={item.id}>{renderItem(item)}</Fragment>
          ))}
        </ol>
      </SortableContext>
      <SortableTimelineOverlay>
        {activeItem ? renderItem(activeItem) : null}
      </SortableTimelineOverlay>
    </DndContext>
  );
}

// Exposing Item and DragHandle components as static properties of SortableTimeline.
SortableTimeline.Item = SortableTimelineItem;
SortableTimeline.DragHandle = DragHandle;
