import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {Skeleton} from "@/components/ui/skeleton";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {RunnerConfigsRowActions} from "@/modules/admin";
import {
  useDateFormatDetails,
  useGetImREmitDelimiters,
  useGetImREmitDocumentFormats,
  useGetImREmitRunnerTypes,
  useGetPaymentProviders,
  type RunnerConfigFormType,
} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";

{
  /* eslint-disable */
}

export const allRunnerConfigsTableColumns: ColumnDef<RunnerConfigFormType>[] = [
  {
    accessorKey: "fileNameContainer",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="File Name Container" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap font-semibold">
        {row.getValue("fileNameContainer") ? (
          row.getValue("fileNameContainer")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "batchRunnerType",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Batch Runner Type" />
    ),
    cell: ({row}) => {
      const batchRunnerTypeQuery = useGetImREmitRunnerTypes();

      if (batchRunnerTypeQuery.isPending) {
        return <Skeleton size="lg" />;
      }

      if (batchRunnerTypeQuery.isError) {
        console.error(batchRunnerTypeQuery.error);
        return (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );
      }

      const batchRunnerTypeData = batchRunnerTypeQuery.data?.content || [];
      const getRunnerTypeName = (batchRunnerTypeId: number) => {
        const runnerType = batchRunnerTypeData.find(
          (type) => type.batchRunnerTypeId === batchRunnerTypeId
        );
        return runnerType?.runnerTypeName || null;
      };

      const batchRunnerType = getRunnerTypeName(
        row.getValue("batchRunnerType")
      );

      return (
        <div className="w-fit whitespace-nowrap">
          {batchRunnerType ? (
            <Badge className="whitespace-nowrap uppercase">
              {batchRunnerType}
            </Badge>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "paymentProvider",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment Provider" />
    ),
    cell: ({row}) => {
      const paymentProviderQuery = useGetPaymentProviders();

      if (paymentProviderQuery.isPending) {
        return <Skeleton size="lg" />;
      }

      if (paymentProviderQuery.isError) {
        console.error(paymentProviderQuery.error);
        return (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );
      }

      const paymentProviderData = paymentProviderQuery.data?.content || [];
      const getPaymentProviderName = (paymentProviderId: number) => {
        const provider = paymentProviderData.find(
          (provider) => provider.providerId === paymentProviderId
        );
        return provider?.providerName || null;
      };

      const paymentProvider = getPaymentProviderName(
        row.getValue("paymentProvider")
      );

      return (
        <div className="w-fit whitespace-nowrap">
          {paymentProvider ? (
            <Paragraph>{paymentProvider}</Paragraph>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "firstRowHeader",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="First Row Header" />
    ),
    cell: ({row}) => {
      const firstRowHeaderValue = row.getValue<boolean>("firstRowHeader");

      return (
        <div className="w-fit whitespace-nowrap">
          {firstRowHeaderValue ? (
            <Badge variant="success">Yes</Badge>
          ) : (
            <Badge variant="secondary">No</Badge>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "validMinimumLength",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Valid Minimum Length" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("validMinimumLength") ? (
          row.getValue("validMinimumLength")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "dateFormat",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Date Format" />
    ),
    cell: ({row}) => {
      const dateFormatQuery = useDateFormatDetails();

      if (dateFormatQuery.isPending) {
        return <Skeleton size="lg" />;
      }

      if (dateFormatQuery.isError) {
        console.error(dateFormatQuery.error);
        return (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );
      }

      const dateFormatData = dateFormatQuery.data?.content || [];
      const getDateFormatName = (dateFormatId: number) => {
        const format = dateFormatData.find(
          (format) => format.dateFormatId === dateFormatId
        );
        return format?.dateFormatValue || null;
      };

      const dateFormat = getDateFormatName(row.getValue("dateFormat"));

      return (
        <div className="w-fit whitespace-nowrap">
          {dateFormat ? (
            <Paragraph>{dateFormat}</Paragraph>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "delimiterType",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Delimiter Type" />
    ),
    cell: ({row}) => {
      const delimiterTypeQuery = useGetImREmitDelimiters();

      if (delimiterTypeQuery.isPending) {
        return <Skeleton size="lg" />;
      }

      if (delimiterTypeQuery.isError) {
        console.error(delimiterTypeQuery.error);
        return (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );
      }

      const delimiterTypeData = delimiterTypeQuery.data?.content || [];
      const getDelimiterTypeName = (delimiterTypeId: number) => {
        const delimiterType = delimiterTypeData.find(
          (type) => type.delimiterTypeId === delimiterTypeId
        );
        return delimiterType?.value || null;
      };

      const delimiterType = getDelimiterTypeName(row.getValue("delimiterType"));

      return (
        <div className="w-fit whitespace-nowrap">
          {delimiterType ? (
            <Paragraph>{delimiterType}</Paragraph>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "documentFormat",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Document Format" />
    ),
    cell: ({row}) => {
      const documentFormatQueries = [1, 2, 3, 4].map((id) =>
        useGetImREmitDocumentFormats(id.toString())
      );

      const documentFormatData = documentFormatQueries.flatMap(
        (query) => query.data?.content || []
      );

      const documentsArePending = documentFormatQueries.some(
        (query) => query.isPending
      );

      if (documentsArePending) {
        return <Skeleton size="lg" />;
      }

      const documentsAreError = documentFormatQueries.some(
        (query) => query.isError
      );

      if (documentsAreError) {
        documentFormatQueries.forEach((query) => {
          if (query.isError) {
            console.error(query.error);
          }
        });
        return (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );
      }

      const getDocumentFormatName = (documentFormatId: number) => {
        const format = documentFormatData.find(
          (format) => format.documentFormatId === documentFormatId
        );
        return format?.formatName || null;
      };

      const documentFormat = getDocumentFormatName(
        row.getValue("documentFormat")
      );

      return (
        <div className="w-fit whitespace-nowrap">
          {documentFormat ? (
            <Paragraph>{documentFormat}</Paragraph>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "documentFormatDenormalize",
    header: ({column}) => (
      <DataTableColumnHeader
        column={column}
        title="Document Format Denormalize"
      />
    ),
    cell: ({row}) => {
      const documentFormatQueries = [1, 2, 3, 4].map((id) =>
        useGetImREmitDocumentFormats(id.toString())
      );

      const documentFormatData = documentFormatQueries.flatMap(
        (query) => query.data?.content || []
      );

      const documentsArePending = documentFormatQueries.some(
        (query) => query.isPending
      );

      if (documentsArePending) {
        return <Skeleton size="lg" />;
      }

      const documentsAreError = documentFormatQueries.some(
        (query) => query.isError
      );

      if (documentsAreError) {
        documentFormatQueries.forEach((query) => {
          if (query.isError) {
            console.error(query.error);
          }
        });
        return (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );
      }

      const getDocumentFormatName = (documentFormatId: number) => {
        const format = documentFormatData.find(
          (format) => format.documentFormatId === documentFormatId
        );
        return format?.formatName || null;
      };

      const documentFormatDenormalize = getDocumentFormatName(
        row.getValue("documentFormatDenormalize")
      );

      return (
        <div className="w-fit whitespace-nowrap">
          {documentFormatDenormalize ? (
            <Paragraph>{documentFormatDenormalize}</Paragraph>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      );
    },
  },
  {
    id: "actions",
    header: () => <Paragraph className="text-right">Actions</Paragraph>,
    cell: ({row}) => (
      <div className="flex justify-end">
        <RunnerConfigsRowActions row={row} />
      </div>
    ),
  },
];
