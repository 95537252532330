import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {runnerConfigKeys, type RunnerConfigFormType} from "@/modules/imremit";

// Defining a type based on the function `getRunnerConfig`
type QueryFnType = typeof getRunnerConfig;

// Function to search getRunnerConfig
export function getRunnerConfig(externalId?: string) {
  const exId = externalId || "";
  return kyApiFn<RunnerConfigFormType[]>(
    `api/e-payable/management/${exId}/config`,
    "get"
  );
}

// Custom hook to fetch and cache Runner Config list based on the `participant id` data using react-query
export function useGetRunnerConfig(
  externalId?: string, // The search query string
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: runnerConfigKeys.byId(externalId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getRunnerConfig(externalId), // Setting the function to be used for fetching data
    enabled: !!externalId, // Setting the query to be enabled only if the search query string is not empty
  });
}
