import {kyApiFn} from "@/lib/ky";

// Function to approve the supplier
export function approveSupplierFn(supplierId: string, changedBy: string) {
  return kyApiFn(
    `api/e-payable/management/supplier-details/${supplierId}/approve?changedBy=${changedBy}`,
    "put"
  );
}

// Function to decline the supplier
export function declineSupplierFn(supplierId: string, changedBy: string) {
  return kyApiFn(
    `api/e-payable/management/supplier-details/${supplierId}/decline?changedBy=${changedBy}`,
    "put"
  );
}
