import {useQuery} from "@tanstack/react-query";

import {keycloakKyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {keycloakUsersQueryKeys, type KeycloakUserType} from "@/modules/admin";

// Defining a type based on the function `getAllRealmUsers`
type QueryFnType = typeof getAllRealmUsers;

// Function to get all realm users
export async function getAllRealmUsers() {
  const realm =
    (import.meta.env.VITE_KEYCLOAK_REALM as string | undefined) ?? "dev";
  const max = 100;
  let first = 0;
  let allUsers: KeycloakUserType[] = [];
  let hasMoreUsers = true; // Control the loop with a boolean flag

  while (hasMoreUsers) {
    const users = await keycloakKyApiFn<KeycloakUserType[]>(
      `admin/realms/${realm}/users?first=${String(first)}&max=${String(max)}`,
      "get"
    );

    if (users.length === 0) {
      hasMoreUsers = false; // Set flag to exit the loop if no more users
    } else {
      allUsers = [...allUsers, ...users];
      first += max; // Increment to get the next batch
    }
  }

  return allUsers;
}
// Custom hook to fetch and cache data using react-query
export function useAllRealmUsers(config?: QueryConfig<QueryFnType>) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: keycloakUsersQueryKeys.all,
    queryFn: () => getAllRealmUsers(),
  });
}
