import {useEffect, useMemo, useRef, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {useNavigate} from "@tanstack/react-router";
import {debounce} from "lodash";
import {MessageSquarePlusIcon, PencilIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import {z} from "zod";

import {Button} from "@/components/ui/button";
import {DialogFooter} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {Skeleton} from "@/components/ui/skeleton";
import {Spinner} from "@/components/ui/spinner";
import {Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {LoadingSection} from "@/components/craft/loading-section";

import {useFetchInfiniteCustomersByName} from "@/modules/customers";
import {updatePaymentCustomerMappingFn} from "@/modules/imremit-lite";

import {ModuleNames} from "@/utils/constants";

interface PaymentMappingTableIdProps {
  payment_detail_id?: string;
}

const SelectCustomerFormSchema = z.object({
  imREmitCustomer: z.string(),
});

// 🎯 Add minimum search length constant
const MIN_SEARCH_LENGTH = 3;

export function MapPaymentCustomerLite({
  payment_detail_id,
}: PaymentMappingTableIdProps) {
  const [externalId, setExternalId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const parentRef = useRef<HTMLDivElement>(null);
  const lastScrollTop = useRef(0);
  const navigate = useNavigate();

  // 🎯 Add debounced search handler
  const debouncedSearchChange = useMemo(
    () =>
      debounce((value: string) => {
        setSearchTerm(value);
      }, 300),
    []
  );

  // Add cleanup effect
  useEffect(() => {
    return () => {
      debouncedSearchChange.cancel();
    };
  }, [debouncedSearchChange]);

  const {
    data: infiniteData,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useFetchInfiniteCustomersByName(ModuleNames.imremitLite, searchTerm);

  // Update customer data processing
  const liteCustomersData = useMemo(() => {
    if (!infiniteData?.pages) return [];

    return infiniteData.pages
      .flatMap((page) => page.content)
      .filter((customer) => customer.status === "active")
      .map((customer) => ({
        label: customer.buyerName,
        value: customer.externalId.toUpperCase(),
      }));
  }, [infiniteData]);

  const mapPaymentFormMethods = useForm<
    z.infer<typeof SelectCustomerFormSchema>
  >({
    mode: "onChange", // validate the form on change
    resolver: zodResolver(SelectCustomerFormSchema), // use Zod for validation
  });

  const paymentCustomerMappingMutation = useMutation({
    mutationFn: () => {
      return updatePaymentCustomerMappingFn(
        payment_detail_id ?? "",
        externalId
      );
    },

    onSuccess: (response) => {
      mapPaymentFormMethods.reset();
      console.log("paymentCustomerMappingMutationResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Payment mapped to customer successfully",
      });
      void navigate({
        to: "/app/imremit-lite/payment-management",
        replace: true,
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to map payment to customer",
      });
    },
  });

  const {isPending: isMutating} = paymentCustomerMappingMutation;

  function onPaymentMappingSubmit() {
    paymentCustomerMappingMutation.mutate();
  }

  return (
    <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
      <Popover modal={true}>
        <PopoverTrigger asChild>
          <Button
            className="w-40 gap-2"
            size="sm"
            variant="default"
            disabled={isMutating}
          >
            <span className="sr-only">Map payment</span>
            {isMutating ? (
              <LoadingSection sectionSize="xs" />
            ) : (
              <PencilIcon className="size-4" />
            )}
            <Paragraph>Map payment</Paragraph>
          </Button>
        </PopoverTrigger>
        <PopoverContent align="end" className="w-[520px]">
          <Form {...mapPaymentFormMethods}>
            <form
              onSubmit={mapPaymentFormMethods.handleSubmit(() => {
                onPaymentMappingSubmit();
              })}
            >
              <FormField
                control={mapPaymentFormMethods.control}
                name="imREmitCustomer"
                render={({field}) => (
                  <FormItem className="mt-2 flex flex-col">
                    <FormLabel
                      className="pb-2"
                      htmlFor="imREmitCustomer"
                      showMandatoryAsterisk
                    >
                      Select Customer:
                    </FormLabel>
                    <Select
                      value={field.value}
                      onValueChange={(value) => {
                        const customer = liteCustomersData.find(
                          (c) => c.label === value
                        );
                        if (customer) {
                          setExternalId(customer.value);
                          field.onChange(value);
                        }
                      }}
                    >
                      <FormControl>
                        <SelectTrigger className="h-10 min-w-80 max-w-96">
                          <SelectValue placeholder="Select customer..." />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <div className="w-full border-b">
                          <Input
                            placeholder={`Search customers (min. ${MIN_SEARCH_LENGTH.toString()} characters)...`}
                            className="rounded-none border-0 focus-visible:ring-0"
                            onKeyDown={(e) => {
                              e.stopPropagation();
                            }}
                            onChange={(e) =>
                              debouncedSearchChange(e.target.value)
                            }
                          />
                        </div>
                        <div
                          ref={parentRef}
                          className="max-h-[300px] overflow-auto overscroll-contain"
                          onScroll={(e) => {
                            const target = e.target as HTMLDivElement;
                            const maxScroll =
                              target.scrollHeight - target.clientHeight;

                            // Check scroll boundaries
                            if (
                              (target.scrollTop <= 0 &&
                                target.scrollTop < lastScrollTop.current) ||
                              (target.scrollTop >= maxScroll &&
                                target.scrollTop > lastScrollTop.current)
                            ) {
                              target.scrollTop =
                                target.scrollTop <= 0 ? 0 : maxScroll;
                              e.preventDefault();
                              e.stopPropagation();
                            }

                            lastScrollTop.current = target.scrollTop;

                            // Infinite scroll logic
                            if (
                              !isFetchingNextPage &&
                              hasNextPage &&
                              target.scrollHeight - target.scrollTop <=
                                target.clientHeight * 1.5
                            ) {
                              void fetchNextPage();
                            }
                          }}
                        >
                          {searchTerm &&
                          searchTerm.length < MIN_SEARCH_LENGTH ? (
                            <div className="p-2 text-center text-gray-500">
                              Please enter at least {MIN_SEARCH_LENGTH}{" "}
                              characters to search
                            </div>
                          ) : isFetching && liteCustomersData.length === 0 ? (
                            <div className="space-y-2 p-2">
                              <Skeleton className="h-8 w-full" />
                              <Skeleton className="h-8 w-full" />
                              <Skeleton className="h-8 w-full" />
                            </div>
                          ) : (
                            liteCustomersData.map((customer) => (
                              <SelectItem
                                key={customer.value}
                                value={customer.label}
                              >
                                {customer.label}
                              </SelectItem>
                            ))
                          )}
                          {hasNextPage && isFetchingNextPage && (
                            <div className="flex justify-center p-2">
                              <Spinner size="sm" />
                            </div>
                          )}
                        </div>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DialogFooter className="pt-5">
                <Button
                  type="submit"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="default"
                  size="sm"
                  className="gap-2"
                >
                  {isMutating ? (
                    <Spinner size="xs" />
                  ) : (
                    <MessageSquarePlusIcon className="size-4" />
                  )}
                  Map Payment
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </PopoverContent>
      </Popover>
    </div>
  );
}
