import {type ColumnDef} from "@tanstack/react-table";
import {format} from "date-fns";

import {Badge} from "@/components/ui/badge";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import type {GetPDFUploadType} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";

export const uploadedPDFTableColumns: ColumnDef<GetPDFUploadType>[] = [
  {
    accessorKey: "file_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="File ID" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("file_id") ? (
          row.getValue("file_id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "file_location",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="File Location" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("file_location") ? (
          row.getValue("file_location")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "file_name",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="File Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("file_name") ? (
          row.getValue("file_name")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "file_size",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="File Size" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("file_size") ? (
          row.getValue("file_size")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "file_mime_type",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="File Mime Type" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("file_mime_type") ? (
          row.getValue("file_mime_type")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "file_status_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="File Status ID" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("file_status_id") ? (
          row.getValue("file_status_id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "payment_info",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment Info" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("payment_info") ? (
          row.getValue("payment_info")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "payment_links",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment Links" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("payment_links") ? (
          row.getValue("payment_links")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "linked_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Linked ID" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("linked_id") ? (
          row.getValue("linked_id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "uploaded_by",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Uploaded By" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("uploaded_by") ? (
          row.getValue("uploaded_by")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "buyer_external_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Buyer External ID" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("buyer_external_id") ? (
          row.getValue("buyer_external_id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "created_timestamp",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Created Timestamp" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("created_timestamp") ? (
          format(
            new Date(row.getValue("created_timestamp")),
            "MM/dd/yyyy HH:mm"
          )
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "updated_timestamp",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Updated Timestamp" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("updated_timestamp") ? (
          format(
            new Date(row.getValue("updated_timestamp")),
            "MM/dd/yyyy HH:mm"
          )
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
];
