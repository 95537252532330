import {useEffect, useMemo} from "react";

import {Link} from "@tanstack/react-router";
import {GitBranchPlusIcon} from "lucide-react";

import {buttonVariants} from "@/components/ui/button";
import {Heading2} from "@/components/ui/typography";

import {InvoiceTrackerRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {
  invoiceMappingColumns,
  InvoiceMappingDataTable,
  useInvoiceMappingDetails,
} from "@/modules/invoices";

/**
 * Custom Hook: Manage the active route for the Invoice Tracker Dashboard.
 */
export function useManageInvoicesMappingRoute() {
  const {setInvoiceTrackerRoute} = useRoutesStore();

  useEffect(() => {
    // Set the active route based on predefined route schema
    setInvoiceTrackerRoute(
      InvoiceTrackerRoutesSchema.Values["/app/invoices/mapping"]
    );
  }, [setInvoiceTrackerRoute]);
}

/**
 * Custom Hook: Fetch and prepare invoice mapping data.
 *
 * @returns {Object} - The content  for the invoice mapping, along with the API query object.
 */
function useFetchInvoiceMappingList() {
  const searchInvoiceMappingQuery = useInvoiceMappingDetails();

  console.log("searchInvoiceMappingQuery:", searchInvoiceMappingQuery);

  const invoiceMappingContent = useMemo(() => {
    if (!searchInvoiceMappingQuery.data) {
      return [];
    }
    return searchInvoiceMappingQuery.data.content;
  }, [searchInvoiceMappingQuery.data]);

  return {
    invoiceMappingContent,
    searchInvoiceMappingQuery,
  };
}

export function InvoicesMappingPage() {
  useManageInvoicesMappingRoute();
  // Fetch and prepare the invoice mapping data
  const {invoiceMappingContent, searchInvoiceMappingQuery} =
    useFetchInvoiceMappingList();

  return (
    <>
      <div className="flex justify-between gap-2">
        <Heading2>Invoices Mapping</Heading2>
        <Link
          to="/app/invoices/mapping/create"
          className={cn(buttonVariants(), "mb-4 gap-2")}
        >
          <span className="sr-only">Create Invoices Mapping</span>
          Create Invoices Mapping
          <GitBranchPlusIcon className="size-4" />
        </Link>
      </div>

      <InvoiceMappingDataTable
        columns={invoiceMappingColumns}
        data={invoiceMappingContent}
        isPending={searchInvoiceMappingQuery.isPending}
        isSuccess={searchInvoiceMappingQuery.isSuccess}
        isError={searchInvoiceMappingQuery.isError}
      />
    </>
  );
}
