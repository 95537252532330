import {kyApiFn} from "@/lib/ky";

export const importSupplierFn = (externalId: string, files: File) => {
  const formData = new FormData();
  formData.append("files", files);

  return kyApiFn(
    `api/e-payable/management/${externalId}/supplier-details/upload`,
    "post",
    {
      body: formData,
    }
  );
};
