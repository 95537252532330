import * as React from "react";

import {ClockIcon} from "lucide-react";

import {Label} from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {TimePickerInput} from "@/components/ui/time-picker/time-picker-input";
import {Paragraph} from "@/components/ui/typography";

interface TimePickerComponentProps {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
  disabled?: boolean;
}

export function TimePickerComponent({
  date,
  setDate,
  disabled,
}: TimePickerComponentProps) {
  const minuteRef = React.useRef<HTMLInputElement>(null);
  const hourRef = React.useRef<HTMLInputElement>(null);

  // State for AM/PM value
  const [amPm, setAmPm] = React.useState<"AM" | "PM">(() => {
    if (!date) return "AM";
    const hours = date.getHours();
    return hours >= 12 ? "PM" : "AM";
  });

  React.useEffect(() => {
    if (date) {
      const hours = date.getHours();
      const newAmPm = hours >= 12 ? "PM" : "AM";
      setAmPm(newAmPm);
    }
  }, [date]);

  // Update date with AM/PM
  const handleAmPmChange = React.useCallback(
    (newAmPm: "AM" | "PM") => {
      if (!date) return;
      const newDate = new Date(date);
      let hours = newDate.getHours();

      if (newAmPm === "PM" && amPm === "AM") {
        hours += 12;
      } else if (newAmPm === "AM" && amPm === "PM") {
        hours -= 12;
      }

      newDate.setHours(hours);
      setDate(newDate);
      setAmPm(newAmPm);
    },
    [date, amPm, setDate]
  );

  // Convert 24-hour date to 12-hour format for display
  const get12HourFormat = React.useCallback((hours: number) => {
    if (hours === 0) return 12; // 12 AM
    if (hours > 12) return hours - 12; // Convert PM hours to 12-hour format
    return hours; // Return AM hours as is
  }, []);

  // Format date to 'HH:MM' string
  const formatTime = React.useCallback((date: Date | undefined) => {
    if (!date) return "";
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    return `${formattedHours}:${formattedMinutes}`;
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-end gap-2">
        <div className="grid gap-1 text-center">
          <Label htmlFor="hours" className="text-xs">
            Hours
          </Label>
          <TimePickerInput
            ref={hourRef}
            picker="12hours"
            date={date}
            disabled={disabled}
            setDate={setDate}
            value={
              date
                ? String(get12HourFormat(date.getHours())).padStart(2, "0")
                : ""
            }
            onRightFocus={() => minuteRef.current?.focus()}
          />
        </div>
        <div className="grid gap-1 text-center">
          <Label htmlFor="minutes" className="text-xs">
            Minutes
          </Label>
          <TimePickerInput
            ref={minuteRef}
            picker="minutes"
            date={date}
            disabled={disabled}
            setDate={setDate}
            onLeftFocus={() => hourRef.current?.focus()}
          />
        </div>
        <div className="grid gap-1 text-center">
          <Label htmlFor="ampm" className="text-xs">
            AM/PM
          </Label>
          <Select
            value={amPm}
            disabled={disabled}
            onValueChange={handleAmPmChange}
          >
            <SelectTrigger className="w-full">
              <SelectValue>{amPm}</SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="AM">AM</SelectItem>
              <SelectItem value="PM">PM</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
      <div className="mt-2 flex flex-row items-center text-center">
        <ClockIcon className="mr-2 size-4" />
        <Paragraph className="mr-2 text-sm font-bold">Selected Time:</Paragraph>
        <Paragraph className="text-sm">{formatTime(date)}</Paragraph>
      </div>
    </div>
  );
}
