import {type ColumnDef} from "@tanstack/react-table";
import {z} from "zod";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {
  SRRowActions,
  type SRSettingModificationType,
} from "@/modules/statement-recon";

import {FallbackMessages} from "@/utils/constants";

export const srSettingsTableColumnsSchema = z.object({
  sr_settings_modification_id: z.boolean(),
  sr_settings_id: z.boolean(),
  sr_settings_modification_value: z.boolean(),
  sr_settings_leading_trailing_type: z.boolean(),
  created_timestamp: z.boolean(),
  updated_timestamp: z.boolean(),
  buyer_external_id: z.boolean(),
  modified_by: z.boolean(),
  supplier_id: z.boolean(),
});

export type SRSettingsTableColumnsType = z.infer<
  typeof srSettingsTableColumnsSchema
>;

export const srSettingsTableColumns: ColumnDef<SRSettingModificationType>[] = [
  {
    accessorKey: "sr_settings_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Settings ID" />
    ),
    cell: ({row}) => (
      <div className="min-w-36">
        {row.getValue("sr_settings_id") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("sr_settings_id")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "sr_settings_modification_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Settings Modification ID" />
    ),
    cell: ({row}) => (
      <div className="min-w-36">
        {row.getValue("sr_settings_modification_id") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("sr_settings_modification_id")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "sr_settings_name",
    header: "Settings Type",
    cell: ({row}) => {
      return (
        <div className="min-w-36">
          {row.getValue("sr_settings_name") ? (
            <div className="flex flex-col gap-y-2">
              <Paragraph className="font-semibold">
                {row.getValue("sr_settings_name")}
              </Paragraph>
            </div>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "sr_settings_modification_value",
    header: "Settings Modification Value",
    cell: ({row}) => {
      const modificationValue = row.getValue("sr_settings_modification_value");
      return (
        <div className="min-w-36">
          {modificationValue !== undefined && modificationValue !== null ? (
            <div className="flex flex-col gap-y-2">
              {modificationValue === "true" ? (
                <Badge variant={"success"}>True</Badge>
              ) : modificationValue === "false" ? (
                <Badge variant={"destructive"}>False</Badge>
              ) : (
                <Paragraph className="font-semibold">
                  {row.getValue("sr_settings_modification_value")}
                </Paragraph>
              )}
            </div>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      );
    },
  },
  {
    accessorKey: "sr_settings_leading_trailing_type",
    header: "Leading Trailing Type",

    cell: ({row}) => (
      <div className="min-w-36">
        {row.getValue("sr_settings_leading_trailing_type") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("sr_settings_leading_trailing_type")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "created_timestamp",
    header: "Created Date",

    cell: ({row}) => (
      <div className="min-w-36">
        {row.getValue("created_timestamp") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("created_timestamp")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "updated_timestamp",
    header: "Modified Date",

    cell: ({row}) => (
      <div className="min-w-36">
        {row.getValue("updated_timestamp") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("updated_timestamp")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "buyer_external_id",
    header: "Buyer External ID",

    cell: ({row}) => (
      <div className="min-w-36">
        {row.getValue("buyer_external_id") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("buyer_external_id")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "modified_by",
    header: "Modified By",

    cell: ({row}) => (
      <div className="min-w-36">
        {row.getValue("modified_by") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("modified_by")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "supplier_id",
    header: "Supplier ID",

    cell: ({row}) => (
      <div className="min-w-36">
        {row.getValue("supplier_id") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("supplier_id")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    id: "actions",
    header: () => <Paragraph className="text-right">Actions</Paragraph>,
    cell: ({row}) => (
      <div className="flex justify-end">
        <SRRowActions row={row} />
      </div>
    ),
  },
];
