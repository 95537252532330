import {Navigate, useNavigate} from "@tanstack/react-router";

export function PaymentManagementRedirect() {
  // Get the navigate function from the router
  const navigate = useNavigate();

  void navigate({
    to: "/app/imremit-lite/payment-management/$pmPage/dashboard",
    params: {
      pmPage: "1",
    },
  });

  return (
    <Navigate
      to="/app/imremit-lite/payment-management/$pmPage/dashboard"
      params={{
        pmPage: "1",
      }}
    />
  );
}
