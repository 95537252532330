import {Link, useParams} from "@tanstack/react-router";
import {MoveRightIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Heading1, Paragraph} from "@/components/ui/typography";

export function EditPIDMappingPage() {
  const {pid} = useParams({
    from: "/app/admin/pid-mapping/$pid/edit",
  });
  return (
    <section>
      <div className="flex flex-row justify-between">
        <Heading1>Edit PID Mapping Page</Heading1>
        <Link to="/app/admin/pid-mapping">
          <Button variant="secondary">
            Go back
            <MoveRightIcon className="ml-2" />
          </Button>
        </Link>
      </div>

      <Paragraph>PID Param: {pid}</Paragraph>
    </section>
  );
}
