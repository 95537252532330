import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import type {
  ReconciledStatementResultType,
  StatementReconGetSummaryType,
} from "@/modules/statement-recon";
import {
  StatementReconciliationSummaryKeys,
  StatementReconStatementListKeys,
} from "@/modules/statement-recon";

//Defining a type based on the function `getSRStatementList`
type QueryFnType = typeof getSRStatementList;
type getReconciliationResultByFileIdQueryFnType =
  typeof getReconciliationResultByFileId;

// Defining a function to fetch data from API
export function getSRStatementList(externalId: string) {
  // Fetch data from API using ky
  return kyApiFn<ReconciledStatementResultType[]>(
    `statement-recon/v1/reconciled/${externalId}`,
    "get"
  );
}

export function getReconciliationResultByFileId(fileId?: string) {
  return kyApiFn<ReconciledStatementResultType[]>(
    `statement-recon/v1/reconciled/statement/${fileId || ""}`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useSRStatementList(
  externalId: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: StatementReconStatementListKeys.byExternalID(externalId), // Unique key for cache 🗝
    queryFn: () => getSRStatementList(externalId), // Data fetching function 📡
    enabled: !!externalId, // Condition to enable the query 🚦
  });
}

export function useReconciliationResultByFileId(
  fileId?: string,
  config?: QueryConfig<getReconciliationResultByFileIdQueryFnType>
) {
  return useQuery<
    ExtractFnReturnType<getReconciliationResultByFileIdQueryFnType>
  >({
    ...config,
    queryKey: StatementReconStatementListKeys.byFileId(fileId), // Unique key for cache 🗝
    queryFn: () => getReconciliationResultByFileId(fileId), // Data fetching function 📡
    enabled: !!fileId, // Condition to enable the query 🚦
  });
}

/**
 * Statement Summary
 * Custom hook to fetch and cache data using react-query
 */

//Defining a type based on the function `getReconSummaryByFileId`
type getReconciliationSummaryByFileIdQueryFnType =
  typeof getReconSummaryByFileId;

export function getReconSummaryByFileId(file_id?: string) {
  return kyApiFn<StatementReconGetSummaryType>(
    `statement-recon/v1/reconciled/dashboard/${file_id || ""}`,
    "get"
  );
}

export function useReconSummaryByFileId(
  file_id?: string,
  config?: QueryConfig<getReconciliationSummaryByFileIdQueryFnType>
) {
  return useQuery<
    ExtractFnReturnType<getReconciliationSummaryByFileIdQueryFnType>
  >({
    ...config,
    queryKey: StatementReconciliationSummaryKeys.byFileId(file_id), // Unique key for cache 🗝
    queryFn: () => getReconSummaryByFileId(file_id), // Data fetching function 📡
    enabled: !!file_id, // Condition to enable the query 🚦
  });
}
