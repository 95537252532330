import {z} from "zod";

export const statementReconGetSummarySchema = z.object({
  matched: z.string(),
  invDateDiscrepancy: z.string(),
  invAmountDiscrepancy: z.string(),
  invDateAmountDiscrepancy: z.string(),
  noMatch: z.string(),
});

export type StatementReconGetSummaryType = z.infer<
  typeof statementReconGetSummarySchema
>;
