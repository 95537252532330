import {Skeleton} from "@/components/ui/skeleton";

import {cn} from "@/lib/utils";

interface LoadingSkeletonProps {
  /**
   * Additional CSS class names for styling the container.
   */
  classNames?: string;

  /**
   * The type of page layout to display
   * @default "default"
   */
  variant?: "default" | "article" | "dashboard" | "profile";
}

/**
 * PageLoadingSkeleton is a reusable component that displays a full page loading skeleton
 * with different layout variants to match common page structures.
 *
 * @param {LoadingSkeletonProps} props The properties for the component.
 * @returns {JSX.Element} The JSX element for the loading skeleton.
 */
export function PageLoadingSkeleton({
  classNames = "",
  variant = "default",
}: LoadingSkeletonProps): JSX.Element {
  const variants = {
    default: (
      <>
        <Skeleton className="h-12 w-full" /> {/* Header */}
        <div className="mt-8 space-y-6">
          <Skeleton className="h-8 w-[250px]" /> {/* Subheader */}
          <div className="space-y-4">
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-[90%]" />
            <Skeleton className="h-4 w-[80%]" />
          </div>
        </div>
      </>
    ),

    article: (
      <>
        <Skeleton className="h-14 w-[80%]" /> {/* Title */}
        <div className="mt-4 flex gap-3">
          <Skeleton className="h-8 w-8 rounded-full" /> {/* Author avatar */}
          <Skeleton className="h-8 w-[120px]" /> {/* Author name */}
        </div>
        <Skeleton className="mt-8 h-[300px] w-full" /> {/* Hero image */}
        <div className="mt-8 space-y-4">
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-[95%]" />
          <Skeleton className="h-4 w-[90%]" />
        </div>
      </>
    ),

    dashboard: (
      <>
        <div className="flex justify-between">
          <Skeleton className="h-10 w-[200px]" /> {/* Page title */}
          <Skeleton className="h-10 w-[100px]" /> {/* Action button */}
        </div>
        <div className="mt-8 grid grid-cols-3 gap-6">
          {[1, 2, 3].map((i) => (
            <Skeleton
              key={i}
              className="h-[120px] rounded-lg"
            /> /* Stats cards */
          ))}
        </div>
        <Skeleton className="mt-8 h-[400px] w-full" /> {/* Chart or table */}
      </>
    ),

    profile: (
      <>
        <div className="flex gap-6">
          <Skeleton className="h-32 w-32 rounded-full" />{" "}
          {/* Profile picture */}
          <div className="space-y-4">
            <Skeleton className="h-10 w-[200px]" /> {/* Name */}
            <Skeleton className="h-4 w-[150px]" /> {/* Title */}
          </div>
        </div>
        <div className="mt-8 grid grid-cols-2 gap-8">
          <div className="space-y-4">
            <Skeleton className="h-6 w-[150px]" /> {/* Section header */}
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-[90%]" />
          </div>
          <div className="space-y-4">
            <Skeleton className="h-6 w-[150px]" /> {/* Section header */}
            <Skeleton className="h-4 w-full" />
            <Skeleton className="h-4 w-[90%]" />
          </div>
        </div>
      </>
    ),
  };

  return <div className={cn("w-full", classNames)}>{variants[variant]}</div>;
}
