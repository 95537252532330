import {Navigate, useNavigate} from "@tanstack/react-router";

export function ProxypayRedirect() {
  // Get the navigate function from the router
  const navigate = useNavigate();

  void navigate({
    to: "/app/imremit-lite/proxy-pay-dashboard/$ppPage/dashboard",
    params: {
      ppPage: "1",
    },
  });

  return (
    <Navigate
      to="/app/imremit-lite/proxy-pay-dashboard/$ppPage/dashboard"
      params={{
        ppPage: "1",
      }}
    />
  );
}
