import {useCallback, type PropsWithChildren} from "react";

import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {useKeyCloakInstanceStore, type KeycloakRoleEnum} from "@/modules/auth";

/**
 * A custom hook for checking if a user has the required policy actions based on roles provided by Keycloak.
 * @returns {Object} An object with a checkPoliciesAccess function.
 */
export const useAuthorization = () => {
  // Extract the Keycloak instance from the store.
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  /**
   * A callback function that checks if the user's roles include any of the required policy actions.
   * @param {KeycloakRoleEnum[]} policyActions - An array of policy actions to check against user roles.
   * @returns {boolean|null} True if access is granted, false otherwise, or null if user roles are undefined.
   */
  const checkPoliciesAccess = useCallback(
    (policyActions: KeycloakRoleEnum[]) => {
      // Retrieve the roles from the Keycloak instance.
      const userPolicyActions = storeKeyCloakInstance?.realmAccess?.roles;
      // Check if any of the required policy actions are present in the user's roles.
      return userPolicyActions
        ? policyActions.some((policyAction) =>
            userPolicyActions.includes(policyAction)
          )
        : null; // Return null as a placeholder for a more explicit error state.
    },
    [storeKeyCloakInstance?.realmAccess?.roles]
  );

  return {checkPoliciesAccess};
};

// Types defining the shape of loading and error state props.
type WrapperLoadingStateType = {
  isLoading: boolean;
  loadingFallback?: React.ReactNode;
};

type WrapperErrorStateType = {
  isError: boolean;
  errorFallback?: React.ReactNode;
};

/**
 * Props for the AccessPolicyWrapper component.
 * @typedef {Object} AccessPolicyWrapperProps
 * @property {KeycloakRoleEnum[]} policyActions - Required policy actions to access the children components.
 * @property {WrapperLoadingStateType} [wrapperLoadingState] - Optional loading state configuration.
 * @property {WrapperErrorStateType} [wrapperErrorState] - Optional error state configuration.
 * @property {React.ReactNode} [unauthorizedFallback] - Optional fallback component for unauthorized access.
 */
interface AccessPolicyWrapperProps {
  policyActions: KeycloakRoleEnum[];
  wrapperLoadingState?: WrapperLoadingStateType;
  wrapperErrorState?: WrapperErrorStateType;
  unauthorizedFallback?: React.ReactNode;
}

/**
 * A component wrapper that controls access to its children based on the user's authorization.
 * Renders fallback components based on loading, error, or unauthorized access states.
 *
 * @param {AccessPolicyWrapperProps} props - The props for the wrapper component.
 * @returns {React.ReactNode} - A React node determined by the authorization and state props.
 */
export function AccessPolicyWrapper({
  children,
  policyActions,
  wrapperLoadingState = {isLoading: false},
  wrapperErrorState = {isError: false},
  unauthorizedFallback = <NotAuthorizedPage />,
}: PropsWithChildren<AccessPolicyWrapperProps>) {
  // Hook to determine if the user has the required policy actions.
  const {checkPoliciesAccess} = useAuthorization();

  // Destructure the state props for loading and error.
  const {isLoading, loadingFallback} = wrapperLoadingState;
  const {isError, errorFallback} = wrapperErrorState;

  // Render the loading fallback if the wrapper is in a loading state.
  if (isLoading) return loadingFallback;

  // Render the error fallback if the wrapper is in an error state.
  if (isError) return errorFallback;

  // Check if the user has access based on the required policy actions.
  const hasAccess = checkPoliciesAccess(policyActions);

  // Render the unauthorized fallback if the user does not have access, otherwise render the children.
  return hasAccess ? children : unauthorizedFallback;
}
