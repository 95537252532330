import {useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, Navigate, useParams} from "@tanstack/react-router";
import {format} from "date-fns";
import {
  ArrowLeftRightIcon,
  BanIcon,
  EyeOffIcon,
  FileEditIcon,
  HistoryIcon,
  InfoIcon,
  KeyIcon,
  MessageCircleIcon,
  MessageSquarePlusIcon,
  ReceiptIcon,
  RefreshCcw,
} from "lucide-react";
import {useForm} from "react-hook-form";
import {z} from "zod";

import {Badge} from "@/components/ui/badge";
import {Button, buttonVariants} from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {Label} from "@/components/ui/label";
import {Separator} from "@/components/ui/separator";
import {Skeleton} from "@/components/ui/skeleton";
import {Spinner} from "@/components/ui/spinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "@/components/ui/tabs";
import {Textarea} from "@/components/ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {queryClient} from "@/lib/query-client";
import {cn} from "@/lib/utils";
import {
  AccessPolicyWrapper,
  calculateUserRoles,
  KeycloakRoleEnum,
  useAuthorization,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {
  blockPaymentFn,
  getStatusClass,
  NewPaymentManagementMultiView,
  PaymentManagementCommentsData,
  RevealCardDetails,
  statusVariants,
  useGetPaymentDetails,
  usePaymentManagementAuthorizations,
  usePaymentManagementGenInfo,
  usePaymentManagementHistory,
  usePaymentManagementInvoices,
  usePaymentManagementTransactions,
  useSearchimREmits,
  type blockPaymentType,
  type paymentManagementAuthorizations,
  type paymentManagementGenInfo,
  type paymentManagementHistory,
  type paymentManagementInvoices,
  type paymentManagementTransactions,
} from "@/modules/imremit";

import {FallbackMessages, KeycloakRoles} from "@/utils/constants";
import {formatCurrency} from "@/utils/functions";

export interface PaymentManagementTableIdProps {
  id?: string;
}

interface PaymentManagementGenInfoProps {
  paymentManagementGenInfo: paymentManagementGenInfo;
}

interface PaymentManagementHistoryProps {
  paymentManagementHistory: paymentManagementHistory;
}

interface PaymentManagementInvoicesProps {
  paymentManagementInvoices: paymentManagementInvoices;
}

interface PaymentManagementAuthorizationsProps {
  paymentManagementAuthorizations: paymentManagementAuthorizations;
}

interface PaymentManagementTransactionsProps {
  paymentManagementTransactions: paymentManagementTransactions;
}

export const blockPaymentSchema = z.object({
  comment: z
    .string()
    .min(1, "Please enter a comment.")
    .max(2000, "Comment must not exceed 2000 characters.")
    .refine((val) => val.trim().length > 0, {
      message: "Please ensure you have entered a comment.",
    }),
});

/**
 * Custom Hook: Return block the payment jsx.
 */
export function BlockPaymentComponent() {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  const {paymentId} = useParams({
    from: "/app/imremit/payment-management/$paymentId/view/$list_type",
  });

  const blockPaymentForm = useForm<z.infer<typeof blockPaymentSchema>>({
    defaultValues: {
      comment: "",
    },
    resolver: zodResolver(blockPaymentSchema),
  });

  //Initialize tanstack query mutation for block the payment
  const blockPaymentMutation = useMutation({
    mutationFn: (blockPaymentPayload: blockPaymentType) => {
      return blockPaymentFn(blockPaymentPayload, paymentId);
    },
    onSuccess: (response) => {
      blockPaymentForm.reset();
      console.log("blockPaymentResponse", response);

      toast({
        variant: "success",
        title: "Success!",
        description: "Payment blocked successfully",
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";

      if (typeof error === "string") {
        errorMessage = error;
      }

      if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);

      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to block payment",
      });
    },
  });

  const isMutating = blockPaymentMutation.isPending;

  function onSubmit() {
    const blockPaymentPayload = {
      userId: storeKeyCloakInstance?.tokenParsed?.sub || "",
      comments: blockPaymentForm.watch("comment"),
      role:
        calculateUserRoles(
          storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
        ) || "",
    };
    blockPaymentMutation.mutate(blockPaymentPayload);
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          className="flex items-center gap-2 whitespace-nowrap"
          size="sm"
          variant="destructive"
        >
          <BanIcon className="size-4" />
          Block
        </Button>
      </DialogTrigger>
      <DialogContent>
        <Form {...blockPaymentForm}>
          <form
            className="space-y-2"
            onSubmit={blockPaymentForm.handleSubmit(onSubmit)}
          >
            <DialogHeader className="pb-2">
              <DialogTitle>Block Payment</DialogTitle>
            </DialogHeader>
            <div className="grid w-full space-y-2">
              <Label htmlFor="comment">
                Add a Comment (2,000 Character Limit)
              </Label>
              <FormField
                control={blockPaymentForm.control}
                name="comment"
                render={() => (
                  <FormItem className="flex flex-col ">
                    <FormControl>
                      <Textarea
                        placeholder="Type your comment here."
                        disabled={isMutating}
                        aria-disabled={isMutating}
                        autoCapitalize="none"
                        aria-placeholder="Type your comment here."
                        autoComplete="comment"
                        autoCorrect="off"
                        {...blockPaymentForm.register("comment")}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <small>
                <strong>Note:</strong> Once the payment is blocked, this action
                cannot be reversed!
              </small>
            </div>
            <DialogFooter>
              <Button
                type="submit"
                className="flex gap-2"
                disabled={isMutating}
                aria-disabled={isMutating}
              >
                <span className="sr-only">Proceed to block payment</span>
                <Paragraph>Proceed to block payment</Paragraph>
                {isMutating ? (
                  <Spinner size="xs" />
                ) : (
                  <MessageSquarePlusIcon className="size-4" />
                )}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}

// Defining the PaymentManagementDetails component
export function ViewPaymentDetailsPage() {
  //Fetching logged user role
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  const {paymentId} = useParams({
    from: "/app/imremit/payment-management/$paymentId/view/$list_type",
  });

  const paymentManagementGenInfoQuery = usePaymentManagementGenInfo(paymentId);
  const paymentManagementGenInfoData =
    paymentManagementGenInfoQuery.data?.content;

  const {isPending: genInfoPending} = paymentManagementGenInfoQuery;

  // Card reveal button action state
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const searchimREmitQuery = useGetPaymentDetails(paymentId);
  const searchimREmitData = searchimREmitQuery.data?.content;

  // console.log("searchimREmitData", searchimREmitData);

  const allPaymentManagementQuery = useSearchimREmits(
    searchimREmitData?.externalId
  );
  const allPaymentManagementData = allPaymentManagementQuery.data?.content;

  const {checkPoliciesAccess} = useAuthorization();

  // Handle unauthorized access
  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_PAYMENT_MANAGEMENT])) {
    return <NotAuthorizedPage />;
  }

  const isPaymentIdInSavedPayments = allPaymentManagementData?.some(
    (item) => item.paymentDetailId === paymentId
  );

  // If paymentId is not found in allPaymentManagementData, redirect to payment list page
  if (!isPaymentIdInSavedPayments && searchimREmitQuery.isError) {
    return <Navigate to="/app/imremit/payment-management" />;
  }

  return (
    <section>
      <NewPaymentManagementMultiView />
      <Card>
        <CardHeader className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
          <Paragraph className="flex flex-col text-lg">
            <strong>Payment Number: </strong>
            {searchimREmitQuery.isPending ? (
              <Skeleton className="h-6 w-full" />
            ) : (
              searchimREmitData?.paymentNumber ?? (
                <Badge className="whitespace-nowrap" variant="destructive">
                  {FallbackMessages.NO_DATA}
                </Badge>
              )
            )}
          </Paragraph>
          <Paragraph className="flex flex-col text-lg">
            <strong>Account Number: </strong>
            {genInfoPending ? (
              <Skeleton className="h-6 w-full" />
            ) : (
              paymentManagementGenInfoData?.paymentRequestId ?? (
                <Badge className="whitespace-nowrap" variant="destructive">
                  {FallbackMessages.NO_DATA}
                </Badge>
              )
            )}
          </Paragraph>
          <Paragraph className="flex flex-col text-lg">
            <strong>Status: </strong>
            {searchimREmitQuery.isPending ? (
              <Skeleton className="h-6 w-full" />
            ) : searchimREmitData?.customerStatus === "Failed" &&
              (userRoleDisplay === KeycloakRoles.CustomerAdmin ||
                userRoleDisplay === KeycloakRoles.CustomerUser ||
                userRoleDisplay === "Facility User" ||
                userRoleDisplay === "ViewOnly User") ? (
              "In Progress"
            ) : searchimREmitData?.customerStatus ? (
              <span
                className={cn(
                  statusVariants({
                    statusColour: getStatusClass(
                      searchimREmitData.customerStatus
                    ),
                  })
                )}
              >
                {searchimREmitData.customerStatus}
              </span>
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>

          <div className="flex w-full items-center justify-end gap-2">
            {(searchimREmitData?.customerStatus !== "Closed" ||
              searchimREmitData.description?.trim() ===
                "Received the recon with partial amount the payment is still open.") &&
              searchimREmitData?.customerStatus !== "Blocked" &&
              searchimREmitData?.customerStatus !== "Delivered" && (
                <AccessPolicyWrapper
                  policyActions={[KeycloakRoleEnum.UPDATE_PAYMENT_BLOCK]}
                  unauthorizedFallback={null}
                >
                  <BlockPaymentComponent />
                </AccessPolicyWrapper>
              )}
            <PaymentManagementCommentsData
              id={searchimREmitData?.paymentDetailId.toString()}
            />

            {searchimREmitData?.isEditable && (
              <Link
                to="/app/imremit/payment-management/$paymentId/edit"
                params={{paymentId}}
                className={cn(
                  buttonVariants({size: "sm"}),
                  "flex items-center gap-2"
                )}
              >
                <FileEditIcon className="size-4" />
                <span className="sr-only">Edit</span>
                <Paragraph>Edit</Paragraph>
              </Link>
            )}
          </div>
        </CardHeader>
        <Separator className="mb-2" />
        <CardContent>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
            <div className="grid grid-cols-1">
              <Paragraph className="flex flex-col text-lg">
                <strong>Amount: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : searchimREmitData &&
                  searchimREmitData.totalAmountSent !== null ? (
                  formatCurrency(searchimREmitData.totalAmountSent)
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )}
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Outstanding Balance: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : searchimREmitData &&
                  searchimREmitData.balanceAmount !== null ? (
                  formatCurrency(searchimREmitData.balanceAmount)
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )}
                <small>Transactions may take upto 3 days to post.</small>
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Amount Taken: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : searchimREmitData &&
                  searchimREmitData.amountTaken !== null ? (
                  formatCurrency(searchimREmitData.amountTaken)
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )}
              </Paragraph>
            </div>

            <div className="grid grid-cols-1">
              <Paragraph className="flex flex-col text-lg">
                <strong>Authorization Type: </strong>
                {searchimREmitData?.singleTranExactAuth != null
                  ? searchimREmitData.singleTranExactAuth
                    ? "Single Authorization"
                    : "Multi Authorization"
                  : ""}
              </Paragraph>

              {searchimREmitData &&
              searchimREmitData.customerStatus !==
                "Paid - Pending Verification" &&
              // searchimREmitData.remittanceType == "Pay By Web" &&
              checkPoliciesAccess([KeycloakRoleEnum.READ_CARD_DETAILS]) ? (
                <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                  {isButtonClicked ? (
                    <RevealCardDetails
                      paymentDetailId={searchimREmitData.paymentDetailId}
                    />
                  ) : (
                    <Button
                      className="gap-2"
                      size="sm"
                      variant="outline"
                      onClick={() => {
                        setIsButtonClicked(true);
                      }}
                    >
                      <EyeOffIcon className="size-4" />
                      <Paragraph>Reveal Card Details</Paragraph>
                    </Button>
                  )}
                </div>
              ) : (
                <Paragraph className="flex flex-col text-lg">
                  <strong>Card Number: </strong>
                  {searchimREmitQuery.isPending ? (
                    <Skeleton className="h-6 w-full" />
                  ) : (
                    searchimREmitData?.cardNumber ?? (
                      <Badge variant="destructive">
                        {FallbackMessages.NO_DATA}
                      </Badge>
                    )
                  )}
                </Paragraph>
              )}

              <Paragraph className="flex flex-col text-lg">
                <strong>Payment Zip Code / Postal Code : </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.paymentZip ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
            </div>

            <div className="grid grid-cols-1">
              <Paragraph className="flex flex-col text-lg">
                <strong>Bank: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.providerName ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>

              <Paragraph className="flex flex-col text-lg">
                <strong>Sent Date: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.initiatedDate ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>

              <Paragraph className="flex flex-col text-lg">
                <strong>Payment End Date: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.endDate ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>

              <Paragraph className="flex flex-col text-lg">
                <strong>Payment Method:</strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.methodName ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
            </div>
            <div className="grid grid-cols-1">
              <Paragraph className="flex flex-col text-lg">
                <strong>Supplier: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.supplierName ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Supplier Number: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.supplierNumber ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Supplier ID: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.supplierId ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Payee Name: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.facilityName ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
            </div>
          </div>
        </CardContent>
        <Separator className="mb-4" />
        <CardFooter>
          <Tabs defaultValue="generalInformation" className="w-full ">
            <TabsList className="grid h-12 w-full grid-cols-5">
              <TabsTrigger value="generalInformation">
                <InfoIcon className="p-1" />
                General Information
              </TabsTrigger>
              <TabsTrigger value="invoices">
                <ReceiptIcon className="p-1" />
                Invoices
              </TabsTrigger>
              <TabsTrigger value="transactions">
                <ArrowLeftRightIcon className="p-1" />
                Transactions
              </TabsTrigger>
              <TabsTrigger value="authorizationsAndDeclines">
                <KeyIcon className="p-1" />
                Authorizations and Declines
              </TabsTrigger>
              <TabsTrigger value="history">
                <HistoryIcon className="p-1" />
                History
              </TabsTrigger>
            </TabsList>

            <TabsContent value="generalInformation">
              <PaymentManagementGenInfoData
                id={searchimREmitData?.paymentDetailId.toString()}
              />
            </TabsContent>

            <TabsContent value="invoices">
              <Card>
                <CardHeader>
                  <CardDescription>
                    Changes to invoices do not affect the payable total. If
                    invoices have been added, they will appear at the bottom of
                    the table
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-2">
                  <PaymentManagementInvoicesTable
                    id={searchimREmitData?.paymentDetailId.toString()}
                  />
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="transactions">
              <PaymentManagementTransactionsTable
                id={searchimREmitData?.paymentDetailId.toString()}
              />
            </TabsContent>

            <TabsContent value="authorizationsAndDeclines">
              <Card>
                <CardHeader>
                  <CardDescription className="flex flex-row justify-between">
                    <Paragraph>
                      Authorization and Decline information is requested in
                      real-time for the past 7 days
                    </Paragraph>
                    {/* Refresh button should display only for Open status */}
                    {searchimREmitData?.customerStatus === "Open" && (
                      <Button
                        variant="outline"
                        size="sm"
                        className="gap-2"
                        onClick={() => {
                          void queryClient.invalidateQueries({
                            queryKey: [
                              "paymentManagementAuthorization",
                              "details",
                              searchimREmitData.paymentDetailId.toString(),
                            ],
                          });
                        }}
                      >
                        <RefreshCcw className="size-4" />
                        <span className="sr-only"> Refresh</span>
                        Refresh
                      </Button>
                    )}
                  </CardDescription>
                </CardHeader>
                <CardContent className="space-y-2">
                  <PaymentManagementAuthorizationsTable
                    id={searchimREmitData?.paymentDetailId.toString()}
                  />
                </CardContent>
              </Card>
            </TabsContent>

            <TabsContent value="history">
              <PaymentManagementHistoryTable
                id={searchimREmitData?.paymentDetailId.toString()}
              />
            </TabsContent>
          </Tabs>
        </CardFooter>
      </Card>
    </section>
  );
}

const PaymentManagementTransactions: React.FC<
  PaymentManagementTransactionsProps
> = ({paymentManagementTransactions}) => (
  <TableRow>
    {/* <TableCell>
      {paymentManagementTransactions.transactionDate
        ? format(
            new Date(paymentManagementTransactions.transactionDate),
            "MMMM dd, yyyy"
          ) // Formatting the date
        : (
          <Badge className="whitespace-nowrap" variant="destructive">{FallbackMessages.NO_DATA}</Badge>
        )}
    </TableCell> */}
    <TableCell>
      {/* 🌟 Format and display transaction date, with fallback for missing data */}
      {paymentManagementTransactions.transactionDate !== null &&
      paymentManagementTransactions.transactionDate !== undefined ? (
        paymentManagementTransactions.transactionDate
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>

    <TableCell>
      {/* 🌟 Display transaction ID, with fallback for missing data */}
      {paymentManagementTransactions.transactionId !== null &&
      paymentManagementTransactions.transactionId !== undefined ? (
        paymentManagementTransactions.transactionId
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>

    <TableCell>
      {/* 🌟 Display merchant name, with fallback for missing data */}
      {paymentManagementTransactions.merchantName !== null &&
      paymentManagementTransactions.merchantName !== undefined ? (
        paymentManagementTransactions.merchantName
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>

    <TableCell>
      {/* 🌟 Format and display posting date, with fallback for missing data */}
      {paymentManagementTransactions.postingDate !== null &&
      paymentManagementTransactions.postingDate !== undefined ? (
        format(
          new Date(
            paymentManagementTransactions.postingDate.replace(/-/g, "/")
          ),
          "MMMM dd, yyyy"
        )
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>

    <TableCell>
      {/* 🌟 Format and display transaction amount, with fallback for missing data */}
      {paymentManagementTransactions.transactionAmount !== null &&
      paymentManagementTransactions.transactionAmount !== undefined ? (
        formatCurrency(paymentManagementTransactions.transactionAmount)
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>

    <TableCell>
      {/* 🌟 Format and display original amount, with fallback for missing data */}
      {paymentManagementTransactions.originalAmount !== null &&
      paymentManagementTransactions.originalAmount !== undefined ? (
        formatCurrency(paymentManagementTransactions.originalAmount)
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>
  </TableRow>
);

export function PaymentManagementTransactionsTable({
  id,
}: PaymentManagementTableIdProps) {
  const paymentManagementTransactionsQuery =
    usePaymentManagementTransactions(id);

  const paymentManagementTransactionsData =
    paymentManagementTransactionsQuery.data?.content;

  const {isPending, isError} = paymentManagementTransactionsQuery;

  // if (!paymentManagementTransactions) return <LoadingSection />;
  // const {content: status} = paymentManagementTransactions;

  // Rendering the table
  return (
    <Card>
      <Table className="w-full">
        <TableHeader>
          <TableRow>
            <TableHead>Transaction Date</TableHead>
            <TableHead>Transaction ID</TableHead>
            <TableHead>Merchant Name</TableHead>
            <TableHead>Post date</TableHead>
            <TableHead>Settlement Amount</TableHead>
            <TableHead>Original Amount</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isPending ? (
            <TableRow>
              <TableCell className="h-24 text-center" colSpan={8}>
                <div className="space-y-4">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-full" />
                </div>
              </TableCell>
            </TableRow>
          ) : isError ? (
            <TableRow>
              <TableCell className="h-24 text-center" colSpan={6}>
                <Paragraph>
                  <strong>Oops!</strong>
                </Paragraph>
              </TableCell>
            </TableRow>
          ) : paymentManagementTransactionsData &&
            paymentManagementTransactionsData.length > 0 ? (
            paymentManagementTransactionsData.map(
              (paymentManagementTransactions, index) => (
                <PaymentManagementTransactions
                  key={index}
                  paymentManagementTransactions={paymentManagementTransactions}
                />
              )
            )
          ) : (
            <TableRow>
              <TableCell className="h-24 text-center" colSpan={6}>
                <Paragraph>
                  <strong>No Data Available!</strong>
                </Paragraph>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Card>
  );
}

const PaymentManagementAuthorizations: React.FC<
  PaymentManagementAuthorizationsProps
> = ({paymentManagementAuthorizations}) => (
  <TableRow>
    {/* <TableCell>
      {paymentManagementAuthorizations.authorizationDateTime
        ? format(
            new Date(paymentManagementAuthorizations.authorizationDateTime),
            "MMMM dd, yyyy"
          ) // Formatting the date
        : (
          <Badge className="whitespace-nowrap" variant="destructive">{FallbackMessages.NO_DATA}</Badge>
        )}
    </TableCell> */}
    <TableCell>
      {/* 🌟 Format and display authorization date and time, with fallback for missing data */}
      {paymentManagementAuthorizations.authorizationDateTime !== null &&
      paymentManagementAuthorizations.authorizationDateTime !== undefined ? (
        paymentManagementAuthorizations.authorizationDateTime
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>

    <TableCell>
      {/* 🌟 Display merchant name, with fallback for missing data */}
      {paymentManagementAuthorizations.merchantName !== null &&
      paymentManagementAuthorizations.merchantName !== undefined ? (
        paymentManagementAuthorizations.merchantName
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>

    <TableCell>
      {/* 🌟 Display merchant category code, with fallback for missing data */}
      {paymentManagementAuthorizations.merchantCategoryCode !== null &&
      paymentManagementAuthorizations.merchantCategoryCode !== undefined ? (
        paymentManagementAuthorizations.merchantCategoryCode
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>

    <TableCell>
      {/* 🌟 Format and display authorization amount, with fallback for missing data */}
      {paymentManagementAuthorizations.authorizationAmount !== null &&
      paymentManagementAuthorizations.authorizationAmount !== undefined ? (
        formatCurrency(paymentManagementAuthorizations.authorizationAmount)
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>

    <TableCell>
      {/* 🌟 Display authorization status, with fallback for missing data */}
      {paymentManagementAuthorizations.authorizationStatus !== null &&
      paymentManagementAuthorizations.authorizationStatus !== undefined ? (
        paymentManagementAuthorizations.authorizationStatus
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>

    <TableCell>
      {/* 🌟 Display decline reason, with fallback for missing data */}
      {paymentManagementAuthorizations.declineReason !== null &&
      paymentManagementAuthorizations.declineReason !== undefined ? (
        paymentManagementAuthorizations.declineReason
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>
  </TableRow>
);

export function PaymentManagementAuthorizationsTable({
  id,
}: PaymentManagementTableIdProps) {
  const paymentManagementAuthorizationsQuery =
    usePaymentManagementAuthorizations(id);
  const paymentManagementAuthorizationsData =
    paymentManagementAuthorizationsQuery.data?.content;

  const {isPending, isError, isRefetching} =
    paymentManagementAuthorizationsQuery;

  // Rendering the table
  return (
    <Card>
      <Table className="w-full">
        <TableHeader>
          <TableRow>
            <TableHead>Date/Time</TableHead>
            <TableHead>Merchant</TableHead>
            <TableHead>MCC</TableHead>
            <TableHead>Amount (USD)</TableHead>
            <TableHead>Type/Status</TableHead>
            <TableHead>Reason</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isPending || isRefetching ? (
            <TableRow>
              <TableCell className="h-24 text-center" colSpan={6}>
                <div className="space-y-4">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-full" />
                </div>
              </TableCell>
            </TableRow>
          ) : isError ? (
            <TableRow>
              <TableCell className="h-24 text-center" colSpan={6}>
                <Paragraph>
                  <strong>Oops!</strong>
                </Paragraph>
              </TableCell>
            </TableRow>
          ) : paymentManagementAuthorizationsData &&
            paymentManagementAuthorizationsData.length > 0 ? (
            paymentManagementAuthorizationsData.map(
              (paymentManagementAuthorizations, index) => (
                <PaymentManagementAuthorizations
                  key={index}
                  paymentManagementAuthorizations={
                    paymentManagementAuthorizations
                  }
                />
              )
            )
          ) : (
            <TableRow>
              <TableCell className="h-24 text-center" colSpan={6}>
                <Paragraph>
                  <strong>No Authorizations Available!</strong>
                </Paragraph>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Card>
  );
}

const PaymentManagementInvoices: React.FC<PaymentManagementInvoicesProps> = ({
  paymentManagementInvoices,
}) => (
  <TableRow>
    <TableCell className="whitespace-nowrap">
      <div className="flex items-center">
        {paymentManagementInvoices.invoiceNumber}
        <TooltipProvider delayDuration={200}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="cursor-pointer">
                <MessageCircleIcon className="ml-2 size-4 text-theme" />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <div className="flex justify-between space-x-4">
                {paymentManagementInvoices.comments
                  ? paymentManagementInvoices.comments
                  : "This invoice has no comments"}
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </TableCell>

    <TableCell className="whitespace-nowrap">
      {paymentManagementInvoices.invoiceDate}
    </TableCell>

    <TableCell className="whitespace-nowrap">
      {/* 🌟 Display PO number, with fallback for missing data */}
      {paymentManagementInvoices.poNumber !== null &&
      paymentManagementInvoices.poNumber !== undefined ? (
        paymentManagementInvoices.poNumber
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>

    <TableCell className="whitespace-nowrap">
      {formatCurrency(paymentManagementInvoices.invoiceTotalAmount)}
    </TableCell>

    <TableCell className="whitespace-nowrap">
      {formatCurrency(paymentManagementInvoices.discountAmount)}
    </TableCell>

    <TableCell className="whitespace-nowrap">
      {formatCurrency(paymentManagementInvoices.adjustmentAmount)}
    </TableCell>

    <TableCell className="whitespace-nowrap">
      {formatCurrency(paymentManagementInvoices.invoiceAmountPaid)}
    </TableCell>
  </TableRow>
);

export function PaymentManagementInvoicesTable({
  id,
}: PaymentManagementTableIdProps) {
  const paymentManagementInvoicesQuery = usePaymentManagementInvoices(id);

  const paymentManagementInvoicesData =
    paymentManagementInvoicesQuery.data?.content;

  const {isPending, isError} = paymentManagementInvoicesQuery;

  // Rendering the table
  return (
    <Card>
      <Table className="w-full">
        <TableHeader>
          <TableRow>
            <TableHead>Invoice Number</TableHead>
            <TableHead>Invoice Date</TableHead>
            <TableHead>PO Number</TableHead>
            <TableHead>Invoice Amount (USD)</TableHead>
            <TableHead>Discount Amount (USD)</TableHead>
            <TableHead>Adjustment Amount (USD)</TableHead>
            <TableHead>Amount Paid (USD)</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isPending ? (
            <TableRow>
              <TableCell className="h-24 text-center" colSpan={8}>
                <div className="space-y-4">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-full" />
                </div>
              </TableCell>
            </TableRow>
          ) : isError ? (
            <TableRow>
              <TableCell className="h-24 text-center" colSpan={8}>
                <Paragraph>
                  <strong>Oops!</strong>
                </Paragraph>
              </TableCell>
            </TableRow>
          ) : paymentManagementInvoicesData &&
            paymentManagementInvoicesData.length > 0 ? (
            paymentManagementInvoicesData.map(
              (paymentManagementInvoices, index) => (
                <PaymentManagementInvoices
                  key={index}
                  paymentManagementInvoices={paymentManagementInvoices}
                />
              )
            )
          ) : (
            <TableRow>
              <TableCell className="h-24 text-center" colSpan={8}>
                <Paragraph>
                  <strong>No Invoices Available!</strong>
                </Paragraph>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Card>
  );
}

const PaymentManagementHistory: React.FC<PaymentManagementHistoryProps> = ({
  paymentManagementHistory,
}) => (
  <TableRow>
    <TableCell>
      {/* 🌟 Format and display change date, with fallback for missing data */}
      {paymentManagementHistory.changeDate !== null &&
      paymentManagementHistory.changeDate !== undefined ? (
        paymentManagementHistory.changeDate
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>

    <TableCell>
      {/* 🌟 Display change time, with fallback for missing data */}
      {paymentManagementHistory.changeTime !== null &&
      paymentManagementHistory.changeTime !== undefined ? (
        paymentManagementHistory.changeTime.toString()
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>

    <TableCell>
      {/* 🌟 Display history interface, with fallback for missing data */}
      {paymentManagementHistory.historyInterface !== null &&
      paymentManagementHistory.historyInterface !== undefined ? (
        paymentManagementHistory.historyInterface
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>

    <TableCell>
      {/* 🌟 Display modified by, with fallback for missing data */}
      {paymentManagementHistory.modifiedBy !== null &&
      paymentManagementHistory.modifiedBy !== undefined ? (
        paymentManagementHistory.modifiedBy
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>

    <TableCell>
      {/* 🌟 Display payment status value, with fallback for missing data */}
      {paymentManagementHistory.paymentStatusValue !== null &&
      paymentManagementHistory.paymentStatusValue !== undefined ? (
        <Badge>{paymentManagementHistory.paymentStatusValue}</Badge>
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>

    <TableCell>
      {/* 🌟 Display comments, with fallback for missing data */}
      {paymentManagementHistory.comments !== null &&
      paymentManagementHistory.comments !== undefined ? (
        paymentManagementHistory.comments
      ) : (
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      )}
    </TableCell>
  </TableRow>
);

export function PaymentManagementHistoryTable({
  id,
}: PaymentManagementTableIdProps) {
  const paymentManagementHistoryQuery = usePaymentManagementHistory(id);
  const paymentManagementHistoryData =
    paymentManagementHistoryQuery.data?.content;

  const isPending = paymentManagementHistoryQuery.isPending;
  const isError = paymentManagementHistoryQuery.isError;

  // Rendering the table
  return (
    <Card>
      <Table className="w-full">
        <TableHeader>
          <TableRow>
            <TableHead>Date</TableHead>
            <TableHead>Time</TableHead>
            <TableHead>Interface</TableHead>
            <TableHead>Modified By</TableHead>
            <TableHead>Payment Status Value</TableHead>
            <TableHead>Comments</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {isPending ? (
            <TableRow>
              <TableCell className="h-24 text-center" colSpan={6}>
                <div className="space-y-4">
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-full" />
                  <Skeleton className="h-4 w-full" />
                </div>
              </TableCell>
            </TableRow>
          ) : isError ? (
            <TableRow>
              <TableCell className="h-24 text-center" colSpan={6}>
                <Paragraph>
                  <strong>Oops!</strong>
                </Paragraph>
              </TableCell>
            </TableRow>
          ) : paymentManagementHistoryData &&
            paymentManagementHistoryData.length > 0 ? (
            paymentManagementHistoryData.map(
              (paymentManagementHistory, index) => (
                <PaymentManagementHistory
                  key={index}
                  paymentManagementHistory={paymentManagementHistory}
                />
              )
            )
          ) : (
            <TableRow>
              <TableCell className="h-24 text-center" colSpan={6}>
                <Paragraph>
                  <strong>No Data Available!</strong>
                </Paragraph>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Card>
  );
}

const PaymentManagementGenInfo: React.FC<PaymentManagementGenInfoProps> = ({
  paymentManagementGenInfo,
}) => (
  <Card>
    <div className="pl-6 pt-6 font-bold text-foreground">
      <Label className="text-base text-theme">Controls</Label>
    </div>

    <CardContent>
      <Paragraph className="mb-4 grid grid-cols-1">
        <strong className="font-bold">Payable MCCG:</strong>{" "}
        {paymentManagementGenInfo.merchantCategoryCode !== null &&
        paymentManagementGenInfo.merchantCategoryCode !== undefined ? (
          paymentManagementGenInfo.merchantCategoryCode
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </Paragraph>

      <div className="font-bold text-foreground">
        <Label className="text-base text-theme">Velocity Controls</Label>
      </div>

      {/* Using grid to manage layout responsively */}
      <div className="mb-4 grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
        <Paragraph>
          <strong className="font-bold">
            Single Transaction Limit Amount (USD):
          </strong>{" "}
          {paymentManagementGenInfo.singleTransactionLimitAmount !== null &&
          paymentManagementGenInfo.singleTransactionLimitAmount !==
            undefined ? (
            formatCurrency(
              paymentManagementGenInfo.singleTransactionLimitAmount
            )
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </Paragraph>
        <Paragraph>
          <strong className="font-bold">
            Daily Transaction Limit Amount (USD):
          </strong>{" "}
          {paymentManagementGenInfo.dailyTransactionLimitAmount !== null &&
          paymentManagementGenInfo.dailyTransactionLimitAmount !== undefined ? (
            formatCurrency(paymentManagementGenInfo.dailyTransactionLimitAmount)
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </Paragraph>
        <Paragraph>
          <strong className="font-bold">Daily Transaction Limit Number:</strong>{" "}
          {paymentManagementGenInfo.dailyTransactionLimitNumber !== null &&
          paymentManagementGenInfo.dailyTransactionLimitNumber !== undefined ? (
            paymentManagementGenInfo.dailyTransactionLimitNumber
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </Paragraph>
        <Paragraph>
          <strong className="font-bold">
            Monthly Transaction Limit Number:
          </strong>{" "}
          {paymentManagementGenInfo.monthlyTransactionLimitNumber !== null &&
          paymentManagementGenInfo.monthlyTransactionLimitNumber !==
            undefined ? (
            paymentManagementGenInfo.monthlyTransactionLimitNumber
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </Paragraph>
      </div>

      <div className="font-bold text-foreground">
        <Label className="text-base text-theme">
          Transaction Custom Fields
        </Label>
      </div>

      {/* Adjusted grid layout for custom fields */}
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
        <Paragraph>
          <strong className="font-bold">UDF 1:</strong>{" "}
          {paymentManagementGenInfo.udf1 !== null &&
          paymentManagementGenInfo.udf1 !== undefined ? (
            paymentManagementGenInfo.udf1
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </Paragraph>
        <Paragraph>
          <strong className="font-bold">UDF 2:</strong>{" "}
          {paymentManagementGenInfo.udf2 !== null &&
          paymentManagementGenInfo.udf2 !== undefined ? (
            paymentManagementGenInfo.udf2
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </Paragraph>
        <Paragraph>
          <strong className="font-bold">UDF 3:</strong>{" "}
          {paymentManagementGenInfo.udf3 !== null &&
          paymentManagementGenInfo.udf3 !== undefined ? (
            paymentManagementGenInfo.udf3
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </Paragraph>
      </div>

      <div className="flex flex-col pt-4 font-bold text-foreground">
        <Label className="text-base text-theme">Payable Custom Fields</Label>
        <Badge className="whitespace-nowrap" variant="destructive">
          {FallbackMessages.NO_DATA}
        </Badge>
      </div>
    </CardContent>
  </Card>
);

export function PaymentManagementGenInfoData({
  id,
}: PaymentManagementTableIdProps) {
  const paymentManagementGenInfoQuery = usePaymentManagementGenInfo(id);
  const paymentManagementGenInfoData =
    paymentManagementGenInfoQuery.data?.content;

  const {isPending, isError} = paymentManagementGenInfoQuery;

  if (isPending) {
    return (
      <Card>
        <CardContent className="mt-4 space-y-2">
          <Skeleton className="h-4 w-1/2" />
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-full" />
          <Skeleton className="h-4 w-full" />
        </CardContent>
      </Card>
    );
  }

  if (isError) {
    <div className="flex gap-4 text-justify">
      Oops! General info could not be loaded.
    </div>;
  }

  if (paymentManagementGenInfoData) {
    return (
      <PaymentManagementGenInfo
        paymentManagementGenInfo={paymentManagementGenInfoData}
      />
    );
  }
}
