import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {notificationTypeKeys, type NotificationType} from "@/modules/admin";

// Defining a type based on the function `searchNotificationTypes`
type QueryFnType = typeof getNotificationTypes;

// Function to search notificationTypes
export function getNotificationTypes() {
  return kyApiFn<NotificationType[]>(`api/e-payable/notification-types`, "get");
}

// Custom hook to fetch and cache notificationTypes data using react-query
export function useGetNotificationTypes(
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: notificationTypeKeys.all, // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getNotificationTypes(), // Setting the function to be used for fetching data
  });
}
