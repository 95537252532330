import {useCallback, useEffect, useMemo, useRef, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {type Table} from "@tanstack/react-table";
import {debounce} from "lodash";
import {ListRestartIcon, Trash2Icon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {toast} from "@/components/ui/use-toast";
import {AdvancedSearch} from "@/components/craft/advanced-search";
import {DataTableViewOptions} from "@/components/craft/data-table";
import {DatePicker} from "@/components/craft/date-pickers";
import {FancyMultiSelect} from "@/components/craft/fancy-multi-select";
import {InfiniteMultiSelect} from "@/components/craft/infinite-multi-select";
import {Pagination} from "@/components/craft/pagination";
import {DataTableFacetedFilter} from "@/components/craft/table";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

import {useCustomerStore, useSavedSearchStore} from "@/lib/stores";
import {useSelectInfiniteCustomers} from "@/modules/customers";
import type {SuppliersList} from "@/modules/invoices";
import {
  CreateSavedSearchMutationLayer,
  deleteSavedSearchFn,
  // ExportData,
  SearchInvoicesFormSchema,
  UpdateSavedSearchMutationLayer,
  useCriteriaFields,
  useFetchAndParsePopulationData,
  useInvoicesMetaStore,
  useSavedSearchLogic,
  useSetSelectedSavedSearch,
} from "@/modules/invoices";
import {useParsedSuppliers} from "@/modules/statement-recon";

/**
 * Interface: DataTableToolbarProps
 * Defines the shape of the props for the DataTableToolbar component.
 * It expects the `table` object as a prop.
 * This is used in the InvoicesDataTable component.
 *
 * @param {Table<TData>} table - The table object
 * @param {() => void} handlePrint - The function to handle print
 * @returns {JSX.Element} - The JSX element
 */
interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  handlePrint: () => void;
}

/**
 * This is the main component, "InvoiceDataTableToolbar". It expects the `table` object as a prop.
 *
 * @param {Table<TData>} table - The table object
 * @param {() => void} handlePrint - The function to handle print
 * @returns {JSX.Element} - The JSX element
 */
export function InvoiceDataTableToolbar<TData>({
  table,
}: // handlePrint,
DataTableToolbarProps<TData>) {
  // Hook to retrieve the current customer's data from the ImRemit customer store.
  const {storeCustomer} = useCustomerStore();
  // Define the default values for the search form
  const invoiceSearchDefaultValues = {
    customers: [],
    suppliers: [],
    facilities: [],
    status: [],
    invoiceDateStart: undefined,
    invoiceDateEnd: undefined,
    invoiceAmount: "",
    paymentNumber: "",
    poNumber: "",
    erpUniqueId: "",
    paymentDate: undefined,
    invoiceNumber: "",
  };

  // Initialize useForm hook provided by react-hook-form with default values, mode, and resolver.
  // It uses the schema defined earlier to validate the form values.
  const searchInvoicesFormMethods = useForm<
    z.infer<typeof SearchInvoicesFormSchema>
  >({
    defaultValues: invoiceSearchDefaultValues,
    mode: "onChange",
    resolver: zodResolver(SearchInvoicesFormSchema),
  });

  // Initialize custom hooks to interact with saved search and invoice meta stores.
  const {storeSavedSearch, updateStoreSavedSearch} = useSavedSearchStore();

  const {
    storeInvoicesPaginationMeta,
    setStoreInvoicesCriteria,
    handleOnPageChange,
  } = useInvoicesMetaStore();

  // Destructure formState to get 'isDirty', it is true if user has interacted with any of the fields.
  // Also, 'watch' is used to observe changes in form field values.
  const {
    formState: {dirtyFields},
    setValue,
    resetField,
  } = searchInvoicesFormMethods;

  useEffect(() => {
    // Set customer to default value if in customer view
    if (storeCustomer) {
      setValue(
        "customers",
        [
          {
            label: storeCustomer.buyerName,
            value: storeCustomer.externalId,
          },
        ],
        {shouldDirty: false}
      );
    }
  }, [storeCustomer, setValue]);

  const isFormActuallyDirty = Object.keys(dirtyFields).length > 0;

  // Observe changes in form fields using the 'watch' method.
  const formFields = searchInvoicesFormMethods.watch();
  const criteriaFields = useCriteriaFields(formFields);

  const {savedSearches} = useSavedSearchLogic();
  //Fuzzy Supplier List
  const [search, setSearch] = useState("");
  const [suppliersData, setSuppliersData] = useState<SuppliersList>([]);
  // Use useRef to keep a stable reference to the debounced function
  const debouncedSetSearchRef = useRef(
    debounce((value: string) => {
      setSearch(value);
    }, 300)
  );

  useEffect(() => {
    // Store the current debounced function in a local variable
    const debouncedFunction = debouncedSetSearchRef.current;

    // Cleanup function to cancel debounced calls on unmount
    return () => {
      debouncedFunction.cancel();
    };
  }, []);

  // Wrap the debounced function in a callback to avoid creating a new instance on every render
  const handleSearchChange = useCallback((value: string) => {
    debouncedSetSearchRef.current(value);
  }, []);

  // Call useParsedSuppliers directly
  const {supplierData} = useParsedSuppliers(
    search,
    formFields.customers[0]?.value || ""
  );

  useEffect(() => {
    handleSearchChange(search);
  }, [search, handleSearchChange]);

  // Effect hook to reset the 'facilities' field if no customers are selected.
  useEffect(() => {
    if (formFields.customers.length > 0) {
      setSuppliersData(supplierData); // Use supplierData from the hook
    } else {
      resetField("facilities");
      resetField("suppliers");
      // Optionally, clear suppliers data if no customers are selected
      setSuppliersData([]);
    }
  }, [resetField, formFields.customers, search, supplierData]);

  // Effect hook to set the search criteria for storing invoices.
  useEffect(() => {
    setStoreInvoicesCriteria(criteriaFields);
  }, [criteriaFields, setStoreInvoicesCriteria]);

  const {customersData, facilitiesData, statusData} =
    useFetchAndParsePopulationData(formFields);

  useSetSelectedSavedSearch(
    storeSavedSearch?.criteria ?? null,
    setValue,
    customersData,
    suppliersData,
    facilitiesData,
    statusData
  );

  // Effect hook to set the search criteria for storing invoices.
  useEffect(() => {
    setStoreInvoicesCriteria(criteriaFields);
  }, [criteriaFields, setStoreInvoicesCriteria]);

  // The onSubmit function is called when the form is submitted.
  // It displays a toast notification with the submitted data.
  function onSubmit(data: z.infer<typeof SearchInvoicesFormSchema>) {
    searchInvoicesFormMethods.reset(invoiceSearchDefaultValues);
    console.log("Searching invoice with: ", data);

    // Effect hook to submit the form when the selectedSavedSearch changes
  }

  // Initialize the deleteSavedSearchMutation hook to delete the saved search.
  const deleteSavedSearchMutation = useMutation({
    mutationFn: deleteSavedSearchFn,
    onSuccess: (deleteSavedSearchResponse) => {
      console.log("deleteSavedSearchResponse", deleteSavedSearchResponse);

      // Reset the form to default values
      searchInvoicesFormMethods.reset(invoiceSearchDefaultValues);

      toast({
        variant: "success",
        title: "Success!",
        description: "Saved search deleted successfully",
      });
    },
    onError: (error) => {
      console.error("errorMessage", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to delete saved search",
      });
    },
  });

  const [customerSearch, setCustomerSearch] = useState("");

  // Replace the existing customer fetching with infinite query
  const {
    data: infiniteCustomersData,
    fetchNextPage: fetchNextCustomers,
    hasNextPage: hasNextCustomersPage,
    isFetching: isLoadingCustomers,
    isFetchingNextPage: isFetchingNextCustomers,
  } = useSelectInfiniteCustomers(customerSearch);

  // Transform infinite data into options
  const customerOptions = useMemo(() => {
    if (!infiniteCustomersData?.pages) return [];

    return infiniteCustomersData.pages.reduce<
      Array<{label: string; value: string}>
    >((acc, page) => {
      const pageCustomers =
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        page.content?.map((customer) => ({
          label: customer.buyerName,
          value: customer.externalId.toString(),
        })) ?? [];
      return [...acc, ...pageCustomers];
    }, []);
  }, [infiniteCustomersData?.pages]);

  // Return the form elements and fields as JSX. It uses Form, FormItem, FormControl, and FormMessage
  // components to structure and style the form.

  //Columns other names
  const columnsOtherName = [
    {key: "taxCode", value: "TIN / Tax Code"},
    {key: "invoiceDate", value: "Invoice Date"},
    {key: "invoiceAmount", value: "Invoice Amount"},
    {key: "paymentDate", value: "Payment Date"},
    {key: "facilityName", value: "Facility Name"},
    {key: "facilityNumber", value: "Facility ID"},
    {key: "invoiceNo", value: "Invoice Number"},
    {key: "status", value: "Invoice Status"},
    {key: "supplierName", value: "Supplier Name"},
    {key: "customerName", value: "Customer Name"},
    {key: "paymentNumber", value: "Payment Number"},
    {key: "paidAmount", value: "Paid Amount"},
    {key: "erpUniqueId", value: "ERP Unique ID"},
    {key: "poNumber", value: "PO Number"},
    {key: "paymentMethod", value: "Payment Method"},
  ];

  const checkSavedSearchObject = Object.keys(savedSearches ?? {}).length > 0;
  return (
    <>
      <Pagination
        paginationMeta={storeInvoicesPaginationMeta}
        onPageChange={handleOnPageChange}
      />
      <Form {...searchInvoicesFormMethods}>
        <form onSubmit={searchInvoicesFormMethods.handleSubmit(onSubmit)}>
          <div className="flex justify-between gap-4">
            <div className="flex flex-wrap items-end gap-2">
              <RowsPreferenceSelect />

              {storeCustomer ? null : (
                <FormField
                  name="customers"
                  render={({field}) => (
                    <FormItem className="flex flex-col">
                      <FormControl>
                        <InfiniteMultiSelect
                          {...field}
                          options={customerOptions}
                          disabled={!!storeCustomer}
                          placeholder="Select customers..."
                          searchPlaceholder="Search customers..."
                          isLoading={isLoadingCustomers}
                          isFetchingNextPage={isFetchingNextCustomers}
                          hasNextPage={hasNextCustomersPage}
                          onSearch={setCustomerSearch}
                          onLoadMore={() => void fetchNextCustomers()}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}

              <FormField
                name="suppliers"
                render={({field}) => (
                  <FormItem className="flex flex-col">
                    <FormControl>
                      <FancyMultiSelect
                        {...field}
                        setSearch={setSearch}
                        multiSelectData={suppliersData}
                        customWidth="96"
                        disabled={!formFields.customers.length}
                        placeholder={
                          !formFields.customers.length
                            ? "Select customer first..."
                            : "Type to search suppliers..."
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                name="invoiceDateStart"
                render={({field}) => (
                  <FormItem className="flex flex-col">
                    <DatePicker
                      {...field}
                      placeholder="Start Date"
                      disabled={
                        formFields.invoiceDateEnd
                          ? {
                              before: new Date("1970-01-01"),
                              after: formFields.invoiceDateEnd,
                            }
                          : undefined
                      }
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="invoiceDateEnd"
                render={({field}) => (
                  <FormItem className="flex flex-col">
                    <DatePicker
                      {...field}
                      placeholder="End Date"
                      disabled={
                        formFields.invoiceDateStart
                          ? {
                              before: formFields.invoiceDateStart,
                              after: new Date("9999-01-01"),
                            }
                          : undefined
                      }
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              {isFormActuallyDirty && (
                <Button
                  className="gap-2"
                  variant="secondary"
                  onClick={() => {
                    updateStoreSavedSearch(null);
                    searchInvoicesFormMethods.reset();

                    if (storeCustomer) {
                      setValue("customers", [
                        {
                          label: storeCustomer.buyerName,
                          value: storeCustomer.externalId,
                        },
                      ]);
                    }
                  }}
                >
                  <span className="sr-only">Reset search</span>
                  <ListRestartIcon className="h-4 w-4" />
                  Reset
                </Button>
              )}
              {!storeSavedSearch && isFormActuallyDirty && (
                <CreateSavedSearchMutationLayer
                  criteriaFields={criteriaFields}
                />
              )}
              {storeSavedSearch &&
                isFormActuallyDirty &&
                checkSavedSearchObject && (
                  <>
                    {/* Update Saved search*/}
                    <UpdateSavedSearchMutationLayer
                      criteriaFields={criteriaFields}
                    />

                    <Button
                      className="gap-2"
                      variant="destructive"
                      aria-disabled={deleteSavedSearchMutation.isPending}
                      disabled={deleteSavedSearchMutation.isPending}
                      onClick={() => {
                        deleteSavedSearchMutation.mutate(
                          Number(storeSavedSearch.listId)
                        );
                        updateStoreSavedSearch(null);
                        searchInvoicesFormMethods.reset();
                      }}
                    >
                      <span className="sr-only">Delete search</span>
                      <Trash2Icon className="h-4 w-4" />
                      Delete Search
                    </Button>
                  </>
                )}
            </div>
            <div className="flex flex-col items-end gap-2 lg:flex-row">
              {/* <ExportData
                totalRecords={storeInvoicesPaginationMeta?.totalElements}
                formFields={formFields}
              /> */}
              {/* <Button
                className="border-dashed border-success-foreground bg-success px-3 text-success-foreground hover:border-success-foreground hover:bg-success-foreground/40 hover:text-success-foreground active:bg-success-foreground/60 active:text-success-foreground"
                variant="outline"
                size="xs"
                type="button"
                onClick={handlePrint}
              >
                <span className="sr-only">Print table</span>
                <PrinterIcon className="mr-2 h-4 w-4" />
                Print
              </Button> */}

              <AdvancedSearch>
                <div className="grid grid-cols-1 gap-x-2 gap-y-2 lg:grid-cols-2 lg:gap-x-4 lg:gap-y-4 xl:grid-cols-3 2xl:grid-cols-4">
                  <FormField
                    name="invoiceAmount"
                    render={() => (
                      <FormItem>
                        <FormLabel htmlFor="invoiceAmount">
                          Invoice Amount
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            step="any"
                            placeholder="Enter invoice amount"
                            // {...searchInvoicesFormMethods.register(
                            //   "invoiceAmount",
                            //   {
                            //     setValueAs: (v) =>
                            //       v === ""
                            //         ? (
                            //             formFields.invoiceAmount || ""
                            //           ).toString()
                            //         : parseFloat(v as string).toFixed(2),
                            //   }
                            // )}
                            {...searchInvoicesFormMethods.register(
                              "invoiceAmount"
                            )}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="paymentNumber"
                    render={() => (
                      <FormItem>
                        <FormLabel htmlFor="paymentNumber">
                          Payment Number
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            placeholder="Enter payment number"
                            maxLength={50}
                            {...searchInvoicesFormMethods.register(
                              "paymentNumber"
                            )}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="facilities"
                    render={({field}) => (
                      <FormItem className="flex flex-col pt-2">
                        <FormLabel htmlFor="facilities">Facilities</FormLabel>
                        <FormControl>
                          <FancyMultiSelect
                            {...field}
                            multiSelectData={facilitiesData}
                            disabled={!formFields.customers.length}
                            isFullWidth
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="poNumber"
                    render={() => (
                      <FormItem>
                        <FormLabel htmlFor="poNumber">PO Number</FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            placeholder="Enter PO Number"
                            {...searchInvoicesFormMethods.register("poNumber")}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="erpUniqueId"
                    render={() => (
                      <FormItem>
                        <FormLabel htmlFor="erpUniqueId">
                          ERP Unique ID
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            placeholder="Enter ERP UNIQUE ID"
                            {...searchInvoicesFormMethods.register(
                              "erpUniqueId"
                            )}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="paymentDate"
                    render={({field}) => (
                      <FormItem className="flex flex-col pt-2">
                        <FormLabel htmlFor="paymentDate">
                          Payment Date
                        </FormLabel>

                        <DatePicker
                          {...field}
                          placeholder="Payment Date"
                          disabled={
                            formFields.paymentDate
                              ? {
                                  before: new Date("1970-01-01"),
                                  after: formFields.paymentDate,
                                }
                              : undefined
                          }
                        />
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    name="invoiceNumber"
                    render={() => (
                      <FormItem>
                        <FormLabel htmlFor="invoiceNumber">
                          Invoice Number
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            placeholder="Enter invoice number"
                            {...searchInvoicesFormMethods.register(
                              "invoiceNumber"
                            )}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </AdvancedSearch>
              <FormField
                name="status"
                render={({field}) => (
                  <FormItem className="flex flex-col">
                    <FormControl>
                      <DataTableFacetedFilter
                        {...field}
                        facetedFilterData={statusData}
                        title="Status"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DataTableViewOptions
                table={table}
                columnsOtherName={columnsOtherName}
              />
            </div>
          </div>
        </form>
      </Form>
    </>
  );
}
