/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import {type ColumnDef} from "@tanstack/react-table";
import {format} from "date-fns";
import {z} from "zod";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {type DupesRunHistoryType} from "@/modules/duplicate-payments";

import {FallbackMessages} from "@/utils/constants";

export const dupesRunHistoryTableColumnsSchema = z.object({
  // id: z.boolean(),
  // buyer_external_id: z.boolean(),
  cutoff_percent: z.boolean(),
  run_months: z.boolean(),
  run_type: z.boolean(),
  run_id: z.boolean(),
  run_date: z.boolean(),
  start_time: z.boolean(),
  end_time: z.boolean(),
  // total_time: z.boolean(),
  // cluster_id: z.boolean(),
  comparison_records: z.boolean(),
  notes: z.boolean(),
  initial_run_start_date: z.boolean(),
  initial_run_end_date: z.boolean(),
});

export type DupesRunHistoryTableColumnsType = z.infer<
  typeof dupesRunHistoryTableColumnsSchema
>;

export const dupesRunHistoryTableColumns: ColumnDef<DupesRunHistoryType>[] = [
  // {
  //   accessorKey: "id",
  //   header: ({column}) => <DataTableColumnHeader column={column} title="ID" />,
  //   cell: ({row}) => (
  //     <div className="w-auto">
  //       {row.getValue("id") ? (
  //         <div className="flex flex-col gap-y-2">
  //           <Paragraph className="font-semibold">{row.getValue("id")}</Paragraph>
  //         </div>
  //       ) : (
  //         <Badge className="whitespace-nowrap" variant="destructive">{FallbackMessages.NO_DATA}</Badge>
  //       )}
  //     </div>
  //   ),
  //   // enableSorting: false,
  //   // enableHiding: false,
  // },
  // {
  //   accessorKey: "buyer_external_id",
  //   header: ({column}) => (
  //     <DataTableColumnHeader column={column} title="External ID" />
  //   ),
  //   cell: ({row}) => (
  //     <div className="w-auto">
  //       {row.getValue("buyer_external_id") ? (
  //         <div className="flex flex-col gap-y-2">
  //           <Paragraph className="font-semibold">
  //             {row.getValue("buyer_external_id")}
  //           </Paragraph>
  //         </div>
  //       ) : (
  //         <Badge className="whitespace-nowrap" variant="destructive">{FallbackMessages.NO_DATA}</Badge>
  //       )}
  //     </div>
  //   ),
  //   // enableSorting: false,
  //   // enableHiding: false,
  // },
  {
    accessorKey: "run_type",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Run Type" />
    ),
    cell: ({row}) => (
      <div className="min-w-36">
        {row.getValue("run_type") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("run_type")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "run_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Run ID" />
    ),
    cell: ({row}) => (
      <div className="w-auto">
        {row.getValue("run_id") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("run_id")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "cutoff_percent",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Cutoff Percent" />
    ),
    cell: ({row}) => (
      <div className="w-auto">
        {row.getValue("cutoff_percent") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("cutoff_percent")}%
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "run_months",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Run Months" />
    ),
    cell: ({row}) => (
      <div className="w-auto">
        {row.getValue("run_months") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("run_months")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "run_date",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Run Date" />
    ),
    cell: ({row}) => (
      <div className="w-auto">
        {row.getValue("run_date") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {format(new Date(row.getValue("run_date")), "MM/dd/yyyy")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "start_time",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Start Time" />
    ),
    cell: ({row}) => (
      <div className="min-w-fit">
        {row.getValue("start_time") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {new Date(row.getValue("start_time")).toLocaleString("en-US", {
                timeZoneName: "short",
              })}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "end_time",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="End Time" />
    ),
    cell: ({row}) => (
      <div className="min-w-fit">
        {row.getValue("end_time") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {new Date(row.getValue("end_time")).toLocaleString("en-US", {
                timeZoneName: "short",
              })}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  // {
  //   accessorKey: "total_time",
  //   header: ({column}) => (
  //     <DataTableColumnHeader column={column} title="Total Time" />
  //   ),
  //   cell: ({row}) => (
  //     <div className="w-auto">
  //       {row.getValue("total_time") ? (
  //         <div className="flex flex-col gap-y-2">
  //           <Paragraph className="font-semibold">
  //             {row.getValue("total_time")}
  //           </Paragraph>
  //         </div>
  //       ) : (
  //         <Badge className="whitespace-nowrap" variant="destructive">{FallbackMessages.NO_DATA}</Badge>
  //       )}
  //     </div>
  //   ),
  //   // enableSorting: false,
  //   // enableHiding: false,
  // },
  // {
  //   accessorKey: "cluster_id",
  //   header: ({column}) => (
  //     <DataTableColumnHeader column={column} title="Cluster ID" />
  //   ),
  //   cell: ({row}) => (
  //     <div className="w-auto">
  //       {row.getValue("cluster_id") ? (
  //         <div className="flex flex-col gap-y-2">
  //           <Paragraph className="font-semibold">
  //             {row.getValue("cluster_id")}
  //           </Paragraph>
  //         </div>
  //       ) : (
  //         <Badge className="whitespace-nowrap" variant="destructive">{FallbackMessages.NO_DATA}</Badge>
  //       )}
  //     </div>
  //   ),
  //   // enableSorting: false,
  //   // enableHiding: false,
  // },
  {
    accessorKey: "comparison_records",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Comparison Records" />
    ),
    cell: ({row}) => (
      <div className="w-auto">
        {row.getValue("comparison_records") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("comparison_records")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "notes",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Notes" />
    ),
    cell: ({row}) => (
      <div className="w-auto">
        {row.getValue("notes") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("notes")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "initial_run_start_date",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Initial Run Start" />
    ),
    cell: ({row}) => (
      <div className="w-auto">
        {row.getValue("initial_run_start_date") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {format(
                new Date(row.getValue("initial_run_start_date")),
                "MM/dd/yyyy"
              )}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "initial_run_end_date",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Initial Run End" />
    ),
    cell: ({row}) => (
      <div className="w-auto">
        {row.getValue("initial_run_end_date") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {format(
                new Date(row.getValue("initial_run_end_date")),
                "MM/dd/yyyy"
              )}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },

  // TODO: Add actions column if necessary
  // {
  //   id: "actions",
  //   header: () => <Paragraph className="text-right">Actions</Paragraph>,
  //   cell: ({row}) => (
  //     <div className="flex justify-end">
  //       <DupesRunHistoryRowActions row={row} />
  //     </div>
  //   ),
  // },
];
