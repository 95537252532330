import {kyApiFn} from "@/lib/ky";
import {type ExportSupplierScriptsDataFormDTO} from "@/modules/imremit";

export const exportSupplierScriptsDataFn = (
  ExportSupplierScriptsDataFormDTO: ExportSupplierScriptsDataFormDTO,
  supplierScriptId: string
) =>
  kyApiFn(
    `api/e-payable/management/supplier-script/${supplierScriptId}/export`,
    "post",
    {
      json: ExportSupplierScriptsDataFormDTO,
    }
  );
