import {useCallback, useEffect, useMemo} from "react";

import {useNavigate, useParams} from "@tanstack/react-router";

import {type PaginationMetaType} from "@/lib/ky";
import {queryClient} from "@/lib/query-client";
import {InvoiceTrackerRoutesSchema} from "@/lib/routes/types";
import {usePreferencesStore, useRoutesStore} from "@/lib/stores";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";
import {
  invoiceQueryKeys,
  InvoicesDataTable,
  searchInvoicesFn,
  useGetInvoiceColumns,
  useInvoicesMetaStore,
  useSearchInvoices,
} from "@/modules/invoices";

import {replacePageQueryParam} from "@/utils/functions";
import {generateQueryParams} from "@/utils/query-params-generator";

/**
 * Custom Hook: Manage the active route for the Invoice Tracker Dashboard.
 */
function useManageInvoiceRoute() {
  const {setInvoiceTrackerRoute} = useRoutesStore();

  useEffect(() => {
    // Set the active route based on predefined route schema
    setInvoiceTrackerRoute(
      InvoiceTrackerRoutesSchema.Values["/app/invoices/tracker"]
    );
  }, [setInvoiceTrackerRoute]);
}

/**
 * Custom Hook: Manage pagination and user preferences for the Invoice Tracker Dashboard.
 *
 * @param {(pageNumber: number) => Promise<void>} setPageIndex - Function to update the current page index.
 * @returns {Object} - Number of rows per page and the current criteria for storing invoices.
 */
function useInvoicePaginationAndPreferences(
  setPageIndex: (pageNumber: number) => Promise<void>
) {
  const {numRowsPerPage} = usePreferencesStore();
  const {
    storeInvoicesCriteria,
    storeInvoicesPaginationMeta,
    storeInvoicesSortMeta,
    setHandleOnPageChange,
  } = useInvoicesMetaStore();

  useEffect(() => {
    // Reset the page index if it is out of bounds
    if (storeInvoicesPaginationMeta) {
      if (
        storeInvoicesPaginationMeta.pageNumber < 0 ||
        storeInvoicesPaginationMeta.pageNumber + 1 >
          storeInvoicesPaginationMeta.totalPages
      ) {
        void setPageIndex(0);
      }
    }

    // Set the handler for page change events
    setHandleOnPageChange(setPageIndex);
  }, [setHandleOnPageChange, storeInvoicesPaginationMeta, setPageIndex]);

  return {
    numRowsPerPage,
    storeInvoicesCriteria,
    storeInvoicesSortMeta,
  };
}

/**
 * Custom Hook: Fetch and prepare invoice data.
 *
 * @param {string} queryParams - The query parameters for the API request.
 * @returns {Object} - The content and metadata for the invoices, along with the API query object.
 */
function useFetchAndPrepareInvoices(queryParams: string) {
  const {storeInvoicesPaginationMeta} = useInvoicesMetaStore();
  const searchInvoicesQuery = useSearchInvoices(queryParams);

  const invoicesContent = useMemo(() => {
    if (!searchInvoicesQuery.data) {
      return [];
    }
    return searchInvoicesQuery.data.content;
  }, [searchInvoicesQuery.data]);

  const invoicesPaginationMeta = useMemo(() => {
    if (!searchInvoicesQuery.data) {
      return storeInvoicesPaginationMeta;
    }

    return searchInvoicesQuery.data.meta;
  }, [searchInvoicesQuery.data, storeInvoicesPaginationMeta]);

  // Prefetch the next page of invoices
  useEffect(() => {
    if (invoicesPaginationMeta) {
      // Create the query params for the next page
      const nextPageQueryParams = replacePageQueryParam(
        queryParams,
        (invoicesPaginationMeta.pageNumber + 1).toString()
      );

      // Prefetch the next page of invoices
      void queryClient.prefetchQuery({
        queryKey: invoiceQueryKeys.bySearch(nextPageQueryParams),
        queryFn: () => searchInvoicesFn(nextPageQueryParams),
      });
    }
  }, [queryParams, invoicesPaginationMeta]);

  return {
    invoicesContent,
    invoicesPaginationMeta,
    searchInvoicesQuery,
  };
}

/**
 * Custom Hook: Manage invoice pagination metadata.
 *
 * @param {PaginationMetaType | null} invoicesPaginationMeta - The pagination metadata for invoices.
 */
function useManageInvoicePaginationMeta(
  invoicesPaginationMeta: PaginationMetaType | null
) {
  const {setStoreInvoicesPaginationMeta} = useInvoicesMetaStore();

  useEffect(() => {
    // Update the pagination metadata in the store if available
    if (invoicesPaginationMeta) {
      setStoreInvoicesPaginationMeta(invoicesPaginationMeta);
    }
  }, [invoicesPaginationMeta, setStoreInvoicesPaginationMeta]);
}

/**
 * Main Component: Invoice Tracker Dashboard Page
 * This component serves as the primary interface for tracking invoices.
 *
 * @returns {React.Element} - The rendered component.
 */
export function InvoiceTrackerDashboardPage() {
  // Fetch the page number from the URL params
  const {invoicesPage} = useParams({
    from: "/app/invoices/tracker/$invoicesPage/dashboard",
  });

  const navigate = useNavigate();

  const handleNavigationPagination = useCallback(
    (pageNumber: number) =>
      navigate({
        to: "/app/invoices/tracker/$invoicesPage/dashboard",
        params: {
          invoicesPage: (pageNumber + 1).toString(),
        },
      }),
    [navigate]
  );

  const invoicesPageNumber = useMemo(() => {
    // Reset the page index if it is out of bounds
    if (
      !invoicesPage ||
      parseInt(invoicesPage) <= 0 ||
      isNaN(parseInt(invoicesPage))
    ) {
      void handleNavigationPagination(0);
      return 0;
    }

    return parseInt(invoicesPage) - 1;
  }, [invoicesPage, handleNavigationPagination]);

  // Fetch user preferences and pagination criteria
  const {numRowsPerPage, storeInvoicesCriteria, storeInvoicesSortMeta} =
    useInvoicePaginationAndPreferences(handleNavigationPagination);

  // Generate API query parameters based on the current state
  const searchInvoicesQueryParams = useMemo(
    () =>
      generateQueryParams({
        params: storeInvoicesCriteria || {},
        page: invoicesPageNumber,
        size: numRowsPerPage,
        sort: storeInvoicesSortMeta,
      }),
    [
      numRowsPerPage,
      invoicesPageNumber,
      storeInvoicesCriteria,
      storeInvoicesSortMeta,
    ]
  );

  // Fetch and prepare the invoice data
  const {invoicesContent, invoicesPaginationMeta, searchInvoicesQuery} =
    useFetchAndPrepareInvoices(searchInvoicesQueryParams);

  // Manage the active route for this page
  useManageInvoiceRoute();

  // Update pagination metadata in the store
  useManageInvoicePaginationMeta(invoicesPaginationMeta);

  // Get the invoice columns
  const invoiceColumns = useGetInvoiceColumns();

  // Render the invoice data table
  return (
    <AccessPolicyWrapper policyActions={[KeycloakRoleEnum.READ_INVOICES]}>
      <InvoicesDataTable
        columns={invoiceColumns}
        data={invoicesContent}
        isPending={searchInvoicesQuery.isPending}
        isSuccess={searchInvoicesQuery.isSuccess}
        isError={searchInvoicesQuery.isError}
      />
    </AccessPolicyWrapper>
  );
}
