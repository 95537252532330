import {useState} from "react";

import {type Table} from "@tanstack/react-table";
import {ListRestartIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import {
  DataTableFacetedFilter,
  DataTablePagination,
  DataTableViewOptions,
} from "@/components/craft/data-table";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

import {
  billingTypes,
  paymentMethodTypes,
  paymentTypes,
  settlementTermsTypes,
  statusTypes,
} from "@/modules/admin";

/**
 * Props for CustomerManagementDataTableToolbar component.
 *
 * @template TData - The type of data used in the table.
 * @typedef {Object} CustomerManagementDataTableToolbarProps
 * @property {Table<TData>} table - The table instance to interact with.
 */
interface CustomerManagementDataTableToolbarProps<TData> {
  table: Table<TData>;
  isPending?: boolean;
  isErrored?: boolean;
}

/**
 * Toolbar component for customer management data table.
 * Includes pagination, search input, filter components, and view options.
 *
 * @template TData - The type of data displayed in the table.
 * @param {CustomerManagementDataTableToolbarProps<TData>} props - The component props.
 * @returns {JSX.Element} The rendered JSX element.
 */
export function CustomerManagementDataTableToolbar<TData>({
  table,
  isPending,
  isErrored,
}: CustomerManagementDataTableToolbarProps<TData>) {
  // State for the global filter input
  const [globalFilterInput, setGlobalFilterInput] = useState("");
  // State for the specific filter input for mapping names
  const [mappingNameFilterInput, setMappingNameFilterInput] = useState("");

  // Determine if the table is currently filtered
  const isFiltered =
    table.getState().columnFilters.length > 0 ||
    !!table.getState().globalFilter;

  //Columns other names
  const columnsOtherName = [
    {key: "id", value: "ID"},
    {key: "buyerName", value: "Customer Name"},
    {key: "status", value: "Onboarding Status"},
    {key: "billingType", value: "Billing Type"},
    {key: "paymentType", value: "Payment Type"},
    {key: "paymentMethod", value: "Payment Method"},
    {key: "settlementTerms", value: "Settlement Terms"},
    {key: "invoiceDay", value: "Invoice Day"},
    {key: "contactName", value: "Contact"},
    {key: "address", value: "Address"},
    {key: "phone", value: "Phone"},
    {key: "email", value: "Email"},
    {key: "bill", value: "Bill"},
    {key: "mccTemplateOptions", value: "MCC Options"},
    {key: "reportingTool", value: "Reporting Tool"},
    {key: "organizationId", value: "Organization ID"},
    {key: "accountNumberMasking", value: "Account Masking"},
    {key: "responseFileDataMasking", value: "Response Masking"},
    {key: "connectivityMethod", value: "Connectivity Method"},
    {key: "pidmapping", value: "PID Mapping"},
    {key: "multiselectpidmapping", value: "Multi Mapping"},
  ];

  return (
    <>
      <DataTablePagination
        table={table}
        isPending={isPending}
        isErrored={isErrored}
      />
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-1 flex-wrap items-center gap-y-2 space-x-2">
          <RowsPreferenceSelect />
          <Input
            placeholder="Search all entries..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={globalFilterInput}
            onChange={(event) => {
              setGlobalFilterInput(event.target.value);
              table.setGlobalFilter(event.target.value);
            }}
          />
          <Input
            placeholder="Search buyer names..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={mappingNameFilterInput}
            onChange={(event) => {
              setMappingNameFilterInput(event.target.value);
              table.getColumn("buyerName")?.setFilterValue(event.target.value);
            }}
          />
          {isFiltered && (
            <Button
              variant="destructive"
              className="h-10 gap-2 px-2 hover:bg-destructive/70 hover:text-destructive-foreground/80 active:bg-destructive/80 active:text-destructive-foreground/80 lg:px-3"
              onClick={() => {
                table.resetColumnFilters();
                table.resetGlobalFilter();
                setGlobalFilterInput("");
                setMappingNameFilterInput("");
              }}
            >
              <span className="sr-only">Reset search filters</span>
              Search Reset
              <ListRestartIcon className="size-4" />
            </Button>
          )}
        </div>
        <div className="flex flex-wrap items-center space-x-2">
          {table.getColumn("status") && (
            <DataTableFacetedFilter
              column={table.getColumn("status")}
              title="Status"
              options={statusTypes}
            />
          )}
          {table.getColumn("billingType") && (
            <DataTableFacetedFilter
              column={table.getColumn("billingType")}
              title="Billing"
              options={billingTypes}
            />
          )}
          {table.getColumn("paymentType") && (
            <DataTableFacetedFilter
              column={table.getColumn("paymentType")}
              title="Payment Type"
              options={paymentTypes}
            />
          )}
          {table.getColumn("paymentMethod") && (
            <DataTableFacetedFilter
              column={table.getColumn("paymentMethod")}
              title="Payment Method"
              options={paymentMethodTypes}
            />
          )}
          {table.getColumn("settlementTerms") && (
            <DataTableFacetedFilter
              column={table.getColumn("settlementTerms")}
              title="Settlement"
              options={settlementTermsTypes}
            />
          )}

          <DataTableViewOptions
            table={table}
            columnsOtherName={columnsOtherName}
          />
        </div>
      </div>
    </>
  );
}
