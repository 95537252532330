import {z} from "zod";

export const savedSearchCriteria = z.object({
  externalId: z.array(z.string()).nullable(),
  supplierId: z.array(z.number()).nullable(),
  status: z.array(z.number()).nullable(), // TODO: pass in shared status enum here
  facilityId: z.array(z.number()).nullable(),
  invoiceDateStart: z.string().nullable(),
  invoiceDateEnd: z.string().nullable(),
  paymentDate: z.string().nullable(),
  paymentNumber: z.string().nullable(),
  poNumber: z.string().nullable(),
  invoiceNumber: z.string().nullable(),
  erpUniqueId: z.string().nullable(),
  invoiceAmount: z.string().nullable(),
  invoiceDate: z.string().nullable(),
});

export type SavedSearchCriteriaType = z.infer<typeof savedSearchCriteria>;

export const SavedSearchDTOTypeSchema = z.object({
  name: z.string().min(3, "Name must be at least 3 characters").max(40),
  listId: z.number().nullable(),
  userId: z.string().nullable(),
  criteria: savedSearchCriteria,
});

export type SavedSearchDTOType = z.infer<typeof SavedSearchDTOTypeSchema>;
