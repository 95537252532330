// import {Tabs, TabsContent, TabsList, TabsTrigger} from "@/components/ui/tabs";
import {Heading1, Paragraph} from "@/components/ui/typography";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

/**
 * SettingsPage Component
 *
 * This component serves as the Settings Page, offering a tabbed interface.
 * It provides two tabs: "Reset Password" and "Rows Preference".
 * - "Reset Password" tab contains a form to reset the user's password.
 * - "Rows Preference" tab allows the user to select their row preference in tables.
 */
export function SettingsPage() {
  return (
    <section>
      <Heading1>Rows Preference</Heading1>
      <Paragraph className="mb-2">Select your rows preference here.</Paragraph>
      <RowsPreferenceSelect />
    </section>
  );
}
