/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import {type ColumnDef} from "@tanstack/react-table";
import {z} from "zod";

import {Badge} from "@/components/ui/badge";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {Heading3, Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";
import {
  DupesCriteriaRowActions,
  type DupesExemptionCharacterWithTypesType,
} from "@/modules/duplicate-payments";

import {FallbackMessages} from "@/utils/constants";

export const dupesCriteriaSettingsTableColumnsSchema = z.object({
  type: z.boolean(),
  type_key: z.boolean(),
  description: z.boolean(),
  weight: z.boolean(),
  character: z.boolean(),
  created_date: z.boolean(),
  modified_date: z.boolean(),
});

export type DupesCriteriaSettingsTableColumnsType = z.infer<
  typeof dupesCriteriaSettingsTableColumnsSchema
>;

export const dupesCriteriaSettingsTableColumns: ColumnDef<DupesExemptionCharacterWithTypesType>[] =
  [
    {
      accessorKey: "type",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Criteria Type" />
      ),
      cell: ({row}) => (
        <div className="min-w-36">
          {row.getValue("type") ? (
            <div className="flex flex-col gap-y-2">
              <Paragraph className="font-semibold">
                {row.getValue("type")}
              </Paragraph>
            </div>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "character",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Character" />
      ),
      cell: ({row}) => (
        <div className="min-w-36">
          {row.getValue("character") ? (
            <Paragraph className="text-lg font-bold">
              {row.getValue("character")}
            </Paragraph>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "type_key",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Type Key" />
      ),
      cell: ({row}) => (
        <div className="min-w-36">
          {row.getValue("type_key") ? (
            <Badge>
              <Paragraph className="font-semibold">
                {row.getValue("type_key")}
              </Paragraph>
            </Badge>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "weight",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Weight" />
      ),
      cell: ({row}) => (
        <div className="min-w-36">
          {row.getValue("weight") ? (
            <div className="flex flex-col gap-y-2">
              <Paragraph className="font-semibold">
                {row.getValue("weight")}
              </Paragraph>
            </div>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "description",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Description" />
      ),
      cell: ({row}) => (
        <div className="min-w-36">
          {row.getValue("description") ? (
            <HoverCard openDelay={50} closeDelay={350}>
              <HoverCardTrigger asChild>
                <Paragraph className="cursor-pointer font-bold underline hover:text-theme">
                  Hover for more info
                </Paragraph>
              </HoverCardTrigger>
              <HoverCardContent className="max-w-prose translate-x-[-1.5rem]">
                <Heading3 className="mb-4 text-foreground">
                  Criteria Description
                </Heading3>
                <Paragraph className="font-semibold">
                  {row.getValue("description")}
                </Paragraph>
              </HoverCardContent>
            </HoverCard>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "created_date",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Date Created" />
      ),
      cell: ({row}) => (
        <div className="w-auto">
          {row.getValue("created_date") ? (
            <div className="flex flex-col gap-y-2">
              <Paragraph className="font-semibold">
                {new Date(row.getValue("created_date")).toLocaleString(
                  "en-US",
                  {
                    timeZoneName: "short",
                  }
                )}
              </Paragraph>
            </div>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "modified_date",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Date Modified" />
      ),
      cell: ({row}) => (
        <div className="w-auto">
          {row.getValue("modified_date") ? (
            <div className="flex flex-col gap-y-2">
              <Paragraph className="font-semibold">
                {new Date(row.getValue("modified_date")).toLocaleString(
                  "en-US",
                  {
                    timeZoneName: "short",
                  }
                )}
              </Paragraph>
            </div>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      id: "actions",
      header: () => (
        <AccessPolicyWrapper
          unauthorizedFallback={null}
          policyActions={[KeycloakRoleEnum.UPDATE_DUPES_CRITERIA]}
        >
          <Paragraph className="text-right">Actions</Paragraph>
        </AccessPolicyWrapper>
      ),
      cell: ({row}) => (
        <AccessPolicyWrapper
          unauthorizedFallback={null}
          policyActions={[KeycloakRoleEnum.UPDATE_DUPES_CRITERIA]}
        >
          <div className="flex justify-end">
            <DupesCriteriaRowActions row={row} />
          </div>
        </AccessPolicyWrapper>
      ),
    },
  ];
