import {z} from "zod";

export const srUploadMetaSchema = z.object({
  statement_file_id: z.string().optional(),
  buyer_external_id: z.string(),
  statement_file_location: z.string(),
  user_statement_file_name: z.string(),
  statement_file_name: z.string(),
  statement_file_size: z.string(),
  statement_file_mime_type: z.string(),
  // TODO: enum this
  statement_file_status: z.number(),
  statement_uploaded_by: z.string(),
});

export type SRUploadMetaType = z.infer<typeof srUploadMetaSchema>;

export const srUploadMetaResponseSchema = z.object({
  destinationPath: z.string(),
  mimeType: z.string(),
  originalName: z.string(),
  size: z.number(),
});

export type SRUploadMetaResponseType = z.infer<
  typeof srUploadMetaResponseSchema
>;

export const srCompleteMetaResponseSchema = z.object({
  timestamp: z.string(),
  code: z.number(),
  message: z.string(),
  data: z.object({
    content: z.object({
      message: z.string(),
      file: z.object({
        originalName: z.string(),
        mimeType: z.string(),
        size: z.number(),
        destinationPath: z.string(),
      }),
    }),
    meta: z.null(),
  }),
});

export type SRCompleteMetaResponseType = z.infer<
  typeof srCompleteMetaResponseSchema
>;
