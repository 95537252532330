import {ServerCrashIcon} from "lucide-react";
import type {ZodIssue} from "zod";

import {AnchorButton} from "@/components/ui/button";
import {Heading1, Paragraph} from "@/components/ui/typography";

import {useErrorStore} from "@/lib/stores/zod-error-store";

import {CONTACT_INFO} from "@/utils/constants";

/**
 * Reusable Error Message component that displays a generic or Zod-specific error message.
 * This component accepts error code, a message, and a contact email to display relevant information.
 *
 * @param {string} errorCode - The code associated with the error.
 * @param {string} message - The error message explaining what went wrong.
 * @param {string} contactEmail - The email address users can contact for support.
 */
function ErrorMessage({
  errorCode,
  message,
  defaultMessage,
  contactEmail,
}: {
  errorCode: string;
  message: string;
  contactEmail: string;
  defaultMessage: string;
}) {
  return (
    <article
      className="flex flex-1 flex-col items-center justify-center"
      role="alert"
      aria-label="Error section"
    >
      <section className="flex max-w-2xl flex-col items-center justify-center gap-6 rounded-md border bg-white p-8 shadow-2xl">
        <ServerCrashIcon
          className="text-destructive-foreground"
          size={100}
          aria-hidden="true"
        />
        <div className="text-center">
          <Heading1 className="font-mono font-bold">
            OOPS! SOMETHING WENT WRONG.
          </Heading1>
          <div className="mb-4 font-mono text-2xl font-bold text-purple-600">
            {errorCode} - {message}
          </div>
          <Paragraph className="mb-4 font-mono text-xl text-black">
            {defaultMessage}
          </Paragraph>

          <Paragraph className="mb-2 font-mono text-black">
            Please try again later or{" "}
            <AnchorButton
              className="p-0  text-indigo-500 hover:text-neutral-foreground active:text-neutral-foreground"
              href={`mailto:${contactEmail}`}
              variant="link"
            >
              contact support
            </AnchorButton>{" "}
            or call us at{" "}
            <AnchorButton
              className=" p-0  text-indigo-500 hover:text-neutral-foreground active:text-neutral-foreground"
              href="tel:+1234567890"
              variant="link"
            >
              1-(888)-478-3456
            </AnchorButton>
            .
          </Paragraph>
        </div>
      </section>
    </article>
  );
}

/**
 * The GenericError component renders an error page with an appropriate message based on the error type.
 * It handles both generic errors and specific Zod issues (validation errors).
 *
 * @param {Error} error - The error object that contains the error message and potential code for Zod errors.
 *
 * @returns {JSX.Element} - Renders an ErrorMessage component with the relevant error code and message.
 */
export function GenericError({error}: {error: Error}) {
  console.error("An error occurred:", error.message);

  const {getErrorMessage} = useErrorStore();

  // Type guard to ensure error is a ZodIssue
  const isZodIssue = (error: unknown): error is ZodIssue => {
    return typeof error === "object" && error !== null && "code" in error;
  };

  const contactEmail = CONTACT_INFO.contactEmail;
  const defaultMessage =
    "The server received an invalid response from the upstream server.";

  if (!isZodIssue(error)) {
    // If it's not a ZodIssue, render a generic error
    return (
      <ErrorMessage
        errorCode="UNKNOWN"
        message=""
        defaultMessage={defaultMessage}
        contactEmail={contactEmail}
      />
    );
  }

  // If it's a Zod issue, retrieve the message and code
  const {code, message} = getErrorMessage(error);

  return (
    <ErrorMessage
      errorCode={code}
      message={message}
      defaultMessage={defaultMessage}
      contactEmail={contactEmail}
    />
  );
}

// This function wraps the GenericError UI within a main content section.
// It can be used for error boundaries or error handling at the top level.
export function ErrorBoundaryPage({error}: {error: Error}) {
  return (
    <main
      className="flex min-h-screen"
      role="main"
      aria-label="Main content with error message"
    >
      <GenericError error={error} /> {/* Pass the error to GenericError */}
    </main>
  );
}
