import {z, ZodIssueCode, ZodParsedType, type ZodErrorMap} from "zod";

// Define the custom error type
interface CustomError {
  message: string;
  code: string;
}

// Explicitly define the return type of `globalErrorMap` as CustomError
export const globalErrorMap: ZodErrorMap = (issue, _ctx): CustomError => {
  // Specify that it returns `CustomError`
  let message: string;
  let code: string;

  switch (issue.code) {
    case ZodIssueCode.invalid_type:
      code = "ERR_INVALID_TYPE";
      if (issue.received === ZodParsedType.undefined) {
        message = "This field is required.";
      } else {
        message = `Expected type ${issue.expected}, but received ${issue.received}.`;
      }
      break;

    case ZodIssueCode.unrecognized_keys:
      code = "ERR_UNRECOGNIZED_KEYS";
      message = `Unexpected field(s) provided: ${issue.keys.join(", ")}.`;
      break;

    case ZodIssueCode.invalid_union:
      code = "ERR_INVALID_UNION";
      message = "Input does not match any of the allowed types.";
      break;

    case ZodIssueCode.invalid_enum_value:
      code = "ERR_INVALID_ENUM_VALUE";
      message = `Invalid value provided. Expected one of: ${issue.options.join(
        ", "
      )}.`;
      break;

    case ZodIssueCode.too_small:
      code = "ERR_TOO_SMALL";
      if (issue.type === "string") {
        message = `Input must be at least ${String(
          issue.minimum
        )} character(s) long.`;
      } else if (issue.type === "array") {
        message = `Array must contain at least ${String(
          issue.minimum
        )} item(s).`;
      } else {
        message = `Value is too small. Minimum allowed is ${String(
          issue.minimum
        )}.`;
      }
      break;

    case ZodIssueCode.too_big:
      code = "ERR_TOO_BIG";
      if (issue.type === "string") {
        message = `Input must be at most ${String(
          issue.maximum
        )} character(s) long.`;
      } else if (issue.type === "array") {
        message = `Array must contain at most ${String(
          issue.maximum
        )} item(s).`;
      } else {
        message = `Value is too large. Maximum allowed is ${String(
          issue.maximum
        )}.`;
      }
      break;

    case ZodIssueCode.invalid_string:
      code = "ERR_INVALID_STRING";
      if (issue.validation === "email") {
        message = "Invalid email address";
      } else if (issue.validation === "url") {
        message = "Invalid URL";
      } else {
        message = "Invalid string";
      }
      break;

    case ZodIssueCode.invalid_date:
      code = "ERR_INVALID_DATE";
      message = "Invalid date";
      break;

    case ZodIssueCode.invalid_arguments:
      code = "ERR_INVALID_ARGUMENTS";
      message = "Invalid function arguments";
      break;

    case ZodIssueCode.custom:
      code = "ERR_CUSTOM";
      message = issue.message || "Invalid input";
      break;

    case ZodIssueCode.invalid_intersection_types:
      code = "ERR_INVALID_INTERSECTION";
      message = "Intersection types validation failed";
      break;

    case ZodIssueCode.not_multiple_of:
      code = "ERR_NOT_MULTIPLE_OF";
      message = `Number must be a multiple of ${issue.multipleOf.toString()}`;
      break;

    case ZodIssueCode.not_finite:
      code = "ERR_NOT_FINITE";
      message = "Number must be finite";
      break;

    default:
      code = "ERR_UNKNOWN";
      message = _ctx.defaultError || "An unexpected error occurred.";
      return {message, code}; // Return here instead of assertNever
  }

  return {message, code}; // Ensure that this returns `message` and `code`
};

// Set the custom error map globally
z.setErrorMap(globalErrorMap);
