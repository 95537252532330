import {Outlet, useNavigate, useRouter} from "@tanstack/react-router";

import {Separator} from "@/components/ui/separator";
import {Heading1, Paragraph} from "@/components/ui/typography";
import {ReturnToTop} from "@/components/craft/return-to-top";

import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";
import {DuplicatePaymentsSubrouteNavigation} from "@/modules/duplicate-payments";

// Exported DuplicatePaymentOutlet functional component
export function DuplicatePaymentsOutlet() {
  // Get pathname of user's current route
  const {pathname} = useRouter().latestLocation;

  // Get the navigate function from the router
  const navigate = useNavigate();

  // If the current route pathname is "/app/duplicate-payments",
  // navigate to "/app/duplicate-payments/imremit-dashboard" without replacing the current entry in the history stack
  if (
    pathname === "/app/duplicate-payments" ||
    pathname === "/app/duplicate-payments/"
  ) {
    void navigate({
      to: "/app/duplicate-payments/dashboard",
    });
  }

  return (
    <>
      <article
        className="w-full flex-1 bg-background"
        role="region"
        aria-roledescription="This is the Duplicate Payments level one subroute. View your customer's Duplicate Payments data here."
      >
        {/* This section will contain the heading and description, as well as the subroute navigation */}
        <section
          className="mb-2 flex flex-col justify-between gap-4 xl:flex-row xl:items-center"
          role="presentation"
        >
          {/* This div will contain the heading and description */}
          <div className="mb-2 flex flex-col">
            <Heading1>Duplicate Payments</Heading1>{" "}
            {/* The heading of the page */}
            <Paragraph>
              Prevent potential duplicate payments &#8208; before they hit
              payment processing!
            </Paragraph>
            {/* The description of the page */}
          </div>
          {/* This will display the navigation for subroutes */}
          <DuplicatePaymentsSubrouteNavigation />
        </section>

        {/* Separator component to create a visual break in content */}
        <Separator className="mb-2" />

        {/* Outlet for nested routes to display their content */}
        <AccessPolicyWrapper
          policyActions={[KeycloakRoleEnum.READ_DUPLICATE_PAYMENTS]}
        >
          <Outlet />
        </AccessPolicyWrapper>
      </article>
      <ReturnToTop />
    </>
  );
}
