import {useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {MessageSquarePlusIcon, MessagesSquareIcon, XIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {Label} from "@/components/ui/label";
import {Spinner} from "@/components/ui/spinner";
import {Textarea} from "@/components/ui/textarea";
import {Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {LoadingSection} from "@/components/craft/loading-section";

import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {
  paymentCommentsColumns,
  PaymentCommentsDataTable,
  PaymentCommentsFormSchema,
  savePaymentCommentFn,
  usePaymentManagementComments,
} from "@/modules/imremit";

import {KeycloakRoles} from "@/utils/constants";

interface PaymentManagementTableIdProps {
  id?: string;
}

// 🆕 Custom hook to fetch and process payment comments data
function usePaymentCommentsData(id?: string) {
  // 📥 Fetch payment comments data
  const paymentManagementCommentsQuery = usePaymentManagementComments(id);

  // 📊 Sort comments by date in descending order
  const paymentManagementCommentsData = useMemo(() => {
    const comments = paymentManagementCommentsQuery.data?.content ?? [];
    return comments.sort(
      (a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
    );
  }, [paymentManagementCommentsQuery.data?.content]);

  // ⏳ Check if comments data is loading
  const {isPending, isSuccess, isError} = paymentManagementCommentsQuery;

  return {
    paymentManagementCommentsData,
    isPending,
    isSuccess,
    isError,
  };
}

export function PaymentManagementCommentsData({
  id,
}: PaymentManagementTableIdProps) {
  // 🔑 Retrieve Keycloak instance for user authentication
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  // 💬 State to control the comments dialog visibility
  const [openDialog, setOpenDialog] = useState(false);

  // 🆕 Use custom hook to get payment comments data
  const {paymentManagementCommentsData, isPending, isSuccess, isError} =
    usePaymentCommentsData(id);

  // 📝 Initialize form methods for adding comments
  const paymentCommentsFormMethods = useForm<
    z.infer<typeof PaymentCommentsFormSchema>
  >({
    defaultValues: {
      userId: storeKeyCloakInstance?.tokenParsed?.sub as string,
      // TODO: Remove hardcoded role and fetch actual role
      role: KeycloakRoles.ProgramManager,
    },
    resolver: zodResolver(PaymentCommentsFormSchema),
  });

  // 💾 Mutation to save a new payment comment
  const savePaymentCommentMutation = useMutation({
    mutationFn: (data: z.infer<typeof PaymentCommentsFormSchema>) =>
      savePaymentCommentFn(data, id ?? ""),
    onSuccess: (response) => {
      // ✅ Reset form on successful submission
      paymentCommentsFormMethods.reset();
      console.log("savePaymentCommentMutationResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Payment comment added successfully",
      });
    },
    onError: (error) => {
      // 🚨 Handle errors and display a toast message
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add payment comment",
      });
    },
  });

  // 📨 Handler for form submission
  const onPaymentCommentSubmit = (
    data: z.infer<typeof PaymentCommentsFormSchema>
  ) => {
    const {comments, ...rest} = data;

    const processedData = {
      ...rest,
      comments: comments.trim(),
    };
    savePaymentCommentMutation.mutate(processedData);
  };

  // 🔄 Check if mutation is in progress
  const {isPending: isMutating} = savePaymentCommentMutation;

  return (
    <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
      <Dialog open={openDialog}>
        <DialogTrigger asChild>
          <Button
            className="gap-2"
            size="sm"
            variant="outline"
            disabled={isPending}
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            <span className="sr-only">Comments</span>
            {isPending ? (
              <LoadingSection sectionSize="xs" />
            ) : (
              <MessagesSquareIcon className="size-4" />
            )}
            <Paragraph>Comments</Paragraph>
          </Button>
        </DialogTrigger>
        <DialogContent
          className="overflow-scroll sm:min-w-[640px] md:min-w-[768px] lg:min-w-[1024px] xl:min-w-[1280px] 2xl:min-w-[1378px]"
          removeCloseButton
        >
          <DialogHeader>
            <DialogTitle>imREmit Comments</DialogTitle>
            <Button
              variant="ghost"
              className="absolute right-4 top-4 h-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none"
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              <XIcon className="size-4" />
              <span className="sr-only">Close</span>
            </Button>
          </DialogHeader>
          {/* 🔒 Access control for adding comments */}
          <AccessPolicyWrapper
            policyActions={[KeycloakRoleEnum.CREATE_ADD_PAYMENT_COMMENT]}
            unauthorizedFallback={null}
          >
            <Form {...paymentCommentsFormMethods}>
              <form
                className="grid w-full gap-1.5"
                onSubmit={paymentCommentsFormMethods.handleSubmit(
                  onPaymentCommentSubmit
                )}
              >
                <Label htmlFor="comments">Add a comment:</Label>
                <FormField
                  name="comments"
                  render={() => (
                    <FormItem>
                      <FormControl>
                        <Textarea
                          id="comments"
                          placeholder="Type your comment here."
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          autoCapitalize="none"
                          autoComplete="comments"
                          autoCorrect="off"
                          {...paymentCommentsFormMethods.register("comments")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <DialogFooter className="sm:justify-end">
                  <Button
                    type="button"
                    size="sm"
                    className="gap-2"
                    variant="secondary"
                    onClick={() => {
                      setOpenDialog(false);
                    }}
                  >
                    <XIcon className="size-4" />
                    <span className="sr-only">Close Comments</span>
                    Close Comments
                  </Button>
                  <Button
                    type="submit"
                    disabled={isMutating}
                    aria-disabled={isMutating}
                    variant="default"
                    size="sm"
                    className="gap-2"
                  >
                    {isMutating ? (
                      <Spinner size="xs" />
                    ) : (
                      <MessageSquarePlusIcon className="size-4" />
                    )}{" "}
                    Add comment
                  </Button>
                </DialogFooter>
              </form>
            </Form>
          </AccessPolicyWrapper>
          {/* 📃 Display the comments data table */}
          <PaymentCommentsDataTable
            columns={paymentCommentsColumns}
            data={paymentManagementCommentsData}
            isPending={isPending}
            isSuccess={isSuccess}
            isError={isError}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
