import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  misPaymentsReceivedQueryKeys,
  type MISPaymentsReceivedType,
} from "@/modules/admin";

// Defining a type based on the function `getMISReceivedPayments`
type QueryFnType = typeof getMISReceivedPayments;

// Function to search getMISReceivedPayments
export const getMISReceivedPayments = (queryParams?: string) =>
  kyApiFn<MISPaymentsReceivedType[]>(
    `mis/v1/received?${queryParams ? queryParams : ""}`,
    "get"
  );

// Custom hook to fetch and cache payments received list based on the `participant id` data using react-query
export function useGetMISReceivedPayments(
  queryParams?: string,
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: misPaymentsReceivedQueryKeys.byFilter(queryParams), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getMISReceivedPayments(queryParams), // Setting the function to be used for fetching data
    enabled: !!queryParams, // Setting the query to be enabled only if the search query string is not empty
  });
}
