import {useEffect, useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {CheckIcon, ChevronsUpDownIcon} from "lucide-react";
import {useForm, useFormContext} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {Checkbox} from "@/components/ui/checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Spinner} from "@/components/ui/spinner";
import {toast} from "@/components/ui/use-toast";

import {cn} from "@/lib/utils";
import {
  addCustomerRunnerConfigFn,
  AuditTempRunnerConfigFormSchema,
  CustomerRunnerConfigEnum,
  updateCustomerRunnerConfigFn,
  useDateFormats,
  useDelimiterTypes,
  useDocumentFormats,
  usePaymentProviders,
} from "@/modules/admin";
import {useKeyCloakInstanceStore} from "@/modules/auth";
import {useGetRunnerConfigs} from "@/modules/imremit";

export function TempRunnerConfigForm({externalId}: {externalId: string}) {
  const searchAuditTempRunnerConfigQuery = useGetRunnerConfigs(externalId, "4");

  const storeKeyCloakInstance =
    useKeyCloakInstanceStore().storeKeyCloakInstance;
  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );

  const auditTempRunnerConfigFormMethods = useForm<
    z.infer<typeof AuditTempRunnerConfigFormSchema>
  >({
    mode: "onChange",
    defaultValues: {
      batchRunnerType: "4",
      firstRowHeader: false,
      skipFooterCount: 1,
      skipHeaderCount: 1,
      modifiedBy: parsedUserInfo?.name as string,
    },
    resolver: zodResolver(AuditTempRunnerConfigFormSchema),
  });

  const [handleSave, setHandleSave] = useState("add");

  console.log(
    "searchAuditTempRunnerConfigQuery",
    searchAuditTempRunnerConfigQuery.data
  );

  useEffect(() => {
    if (searchAuditTempRunnerConfigQuery.data?.content[0].id) {
      auditTempRunnerConfigFormMethods.setValue(
        "id",
        searchAuditTempRunnerConfigQuery.data.content[0].id
      );
      setHandleSave("update");
    }

    if (searchAuditTempRunnerConfigQuery.data?.content[0].paymentProvider) {
      auditTempRunnerConfigFormMethods.setValue(
        "paymentProvider",
        searchAuditTempRunnerConfigQuery.data.content[0].paymentProvider.toString()
      );
    }

    if (searchAuditTempRunnerConfigQuery.data?.content[0].dateFormat) {
      auditTempRunnerConfigFormMethods.setValue(
        "dateFormat",
        searchAuditTempRunnerConfigQuery.data.content[0].dateFormat.toString()
      );
    }
    if (searchAuditTempRunnerConfigQuery.data?.content[0].delimiterType) {
      auditTempRunnerConfigFormMethods.setValue(
        "delimiterType",
        searchAuditTempRunnerConfigQuery.data.content[0].delimiterType.toString()
      );
    }
    if (searchAuditTempRunnerConfigQuery.data?.content[0].documentFormat) {
      auditTempRunnerConfigFormMethods.setValue(
        "documentFormat",
        searchAuditTempRunnerConfigQuery.data.content[0].documentFormat.toString()
      );
    }
    if (searchAuditTempRunnerConfigQuery.data?.content[0].fileNameContainer) {
      auditTempRunnerConfigFormMethods.setValue(
        "fileNameContainer",
        searchAuditTempRunnerConfigQuery.data.content[0].fileNameContainer
      );
    }
    if (searchAuditTempRunnerConfigQuery.data?.content[0].firstRowHeader) {
      auditTempRunnerConfigFormMethods.setValue(
        "firstRowHeader",
        searchAuditTempRunnerConfigQuery.data.content[0].firstRowHeader
      );
    }
    if (searchAuditTempRunnerConfigQuery.data?.content[0].validMinimumLength) {
      auditTempRunnerConfigFormMethods.setValue(
        "validMinimumLength",
        searchAuditTempRunnerConfigQuery.data.content[0].validMinimumLength
      );
    }
    if (searchAuditTempRunnerConfigQuery.data?.content[0].skipHeaderCount) {
      auditTempRunnerConfigFormMethods.setValue(
        "skipHeaderCount",
        searchAuditTempRunnerConfigQuery.data.content[0].skipHeaderCount
      );
    }
    if (searchAuditTempRunnerConfigQuery.data?.content[0].skipFooterCount) {
      auditTempRunnerConfigFormMethods.setValue(
        "skipFooterCount",
        searchAuditTempRunnerConfigQuery.data.content[0].skipFooterCount
      );
    }
  }, [searchAuditTempRunnerConfigQuery.data, auditTempRunnerConfigFormMethods]);

  const addAuditTempRunnerConfigMutation =
    useAuditTempAddRunnerConfig(externalId);
  function handleAdd(data: z.infer<typeof AuditTempRunnerConfigFormSchema>) {
    addAuditTempRunnerConfigMutation.mutate(data);
  }

  const auditTempRunnerConfigId =
    auditTempRunnerConfigFormMethods.getValues("id");
  const updateAuditTempRunnerConfigMutation = useAuditTempUpdateRunnerConfig(
    externalId,
    auditTempRunnerConfigId || 0
  );
  function handleUpdate(data: z.infer<typeof AuditTempRunnerConfigFormSchema>) {
    updateAuditTempRunnerConfigMutation.mutate(data);
  }

  return (
    <Form {...auditTempRunnerConfigFormMethods}>
      <form
        onSubmit={auditTempRunnerConfigFormMethods.handleSubmit(
          handleSave === "add" ? handleAdd : handleUpdate
        )}
      >
        <div className="mb-5 mt-5 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <PaymentProviderField
            isMutating={
              handleSave === "add"
                ? addAuditTempRunnerConfigMutation.isPending
                : updateAuditTempRunnerConfigMutation.isPending
            }
          />
          <DocumentFormatField
            isMutating={
              handleSave === "add"
                ? addAuditTempRunnerConfigMutation.isPending
                : updateAuditTempRunnerConfigMutation.isPending
            }
          />

          <DateFormatField
            isMutating={
              handleSave === "add"
                ? addAuditTempRunnerConfigMutation.isPending
                : updateAuditTempRunnerConfigMutation.isPending
            }
          />

          <DelimiterTypeField
            isMutating={
              handleSave === "add"
                ? addAuditTempRunnerConfigMutation.isPending
                : updateAuditTempRunnerConfigMutation.isPending
            }
          />

          <FileNameContainerField
            isMutating={
              handleSave === "add"
                ? addAuditTempRunnerConfigMutation.isPending
                : updateAuditTempRunnerConfigMutation.isPending
            }
          />

          <ValidMinLengthField
            isMutating={
              handleSave === "add"
                ? addAuditTempRunnerConfigMutation.isPending
                : updateAuditTempRunnerConfigMutation.isPending
            }
          />

          <SkipHeaderCountField
            isMutating={
              handleSave === "add"
                ? addAuditTempRunnerConfigMutation.isPending
                : updateAuditTempRunnerConfigMutation.isPending
            }
          />
          <SkipFooterCountField
            isMutating={
              handleSave === "add"
                ? addAuditTempRunnerConfigMutation.isPending
                : updateAuditTempRunnerConfigMutation.isPending
            }
          />

          <div className="mb-8 block rounded-md border border-border bg-root p-4">
            <FirstRowHeaderField
              isMutating={
                handleSave === "add"
                  ? addAuditTempRunnerConfigMutation.isPending
                  : updateAuditTempRunnerConfigMutation.isPending
              }
            />
          </div>
        </div>

        <div className="flex items-center justify-end space-x-2 p-2">
          <Button
            type="submit"
            variant="affirmative"
            disabled={
              handleSave === "add"
                ? addAuditTempRunnerConfigMutation.isPending
                : updateAuditTempRunnerConfigMutation.isPending
            }
            aria-disabled={
              handleSave === "add"
                ? addAuditTempRunnerConfigMutation.isPending
                : updateAuditTempRunnerConfigMutation.isPending
            }
          >
            {handleSave === "add" &&
            addAuditTempRunnerConfigMutation.isPending ? (
              <Spinner size="xs" />
            ) : (
              ""
            )}
            {handleSave === "update" &&
            updateAuditTempRunnerConfigMutation.isPending ? (
              <Spinner size="xs" />
            ) : (
              ""
            )}
            Save
          </Button>
        </div>
      </form>
    </Form>
  );
}

/**
 * Custom hook for performing the 'Add Payment Runner Config' mutation.
 * This hook abstracts the mutation logic and side effects like toast notifications.
 *
 * @param {number | undefined} externalId - The ID of the customer.
 * @returns A mutation object with methods to trigger the mutation.
 */
function useAuditTempAddRunnerConfig(externalId: string) {
  return useMutation({
    mutationFn: (data: z.infer<typeof AuditTempRunnerConfigFormSchema>) => {
      // Perform the mutation using the addRunnerConfigFn function
      return addCustomerRunnerConfigFn(data, externalId);
    },
    onSuccess: (response) => {
      // Handle successful mutation
      console.log("addAuditTempRunnerConfigResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Runner config added successfully",
      });
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add runner config",
      });
    },
  });
}

/**
 * Custom hook for performing the 'Update Audit Runner Config' mutation.
 * This hook abstracts the mutation logic and side effects like toast notifications.
 *
 * @param {number | undefined} externalId - The ID of the customer.
 * @returns A mutation object with methods to trigger the mutation.
 */
function useAuditTempUpdateRunnerConfig(
  externalId: string,
  runnerConfigId: number
) {
  return useMutation({
    mutationFn: (data: z.infer<typeof AuditTempRunnerConfigFormSchema>) => {
      // Perform the mutation using the updateRunnerConfigFn function
      return updateCustomerRunnerConfigFn(data, externalId, runnerConfigId);
    },
    onSuccess: (response) => {
      // Handle successful mutation
      console.log("updateAuditTempRunnerConfigResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Runner config updated successfully",
      });
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update runner config",
      });
    },
  });
}

function PaymentProviderField({isMutating}: {isMutating: boolean}) {
  const allPaymentProviders = usePaymentProviders();
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="paymentProvider"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="paymentProvider" showMandatoryAsterisk>
            Payment Provider:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allPaymentProviders.find(
                        (paymentProvider) =>
                          paymentProvider.value === field.value
                      )?.label
                    : "Select Payment Provider..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Payment Providers..." />
                <CommandEmpty>No Payment Providers found.</CommandEmpty>
                <CommandGroup>
                  {allPaymentProviders.map((paymentProvider) => (
                    <CommandItem
                      key={paymentProvider.value}
                      value={paymentProvider.value}
                      onSelect={(value) => {
                        setValue("paymentProvider", value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          paymentProvider.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {paymentProvider.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function DocumentFormatField({isMutating}: {isMutating: boolean}) {
  const allDocumentFormats = useDocumentFormats(
    CustomerRunnerConfigEnum.PAYMENT_RUNNER
  );
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="documentFormat"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="documentFormat" showMandatoryAsterisk>
            Document Format:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "min-w-64 justify-between gap-2 whitespace-nowrap",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDocumentFormats.find(
                        (documentFormat) => documentFormat.value === field.value
                      )?.label
                    : "Select Document Format..."}
                  <ChevronsUpDownIcon className="size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Document Format..." />
                <CommandEmpty>No Document Format found.</CommandEmpty>
                <CommandGroup>
                  {allDocumentFormats.map((documentFormat) => (
                    <CommandItem
                      key={documentFormat.value}
                      value={documentFormat.value}
                      onSelect={(value) => {
                        setValue("documentFormat", value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          documentFormat.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {documentFormat.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function DateFormatField({isMutating}: {isMutating: boolean}) {
  const allDateFormats = useDateFormats();
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="dateFormat"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="dateFormat" showMandatoryAsterisk>
            Date Format:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDateFormats.find(
                        (dateFormat) => dateFormat.value === field.value
                      )?.label
                    : "Select Date Format..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Date Format..." />
                <CommandEmpty>No Date Formats found.</CommandEmpty>
                <CommandGroup>
                  {allDateFormats.map((dateFormat) => (
                    <CommandItem
                      key={dateFormat.value}
                      value={dateFormat.value}
                      onSelect={(value) => {
                        setValue("dateFormat", value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          dateFormat.value.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {dateFormat.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function DelimiterTypeField({isMutating}: {isMutating: boolean}) {
  const allDelimiterTypes = useDelimiterTypes();
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="delimiterType"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="delimiterType" showMandatoryAsterisk>
            Delimiter Type:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? allDelimiterTypes.find(
                        (delimiterType) => delimiterType.value === field.value
                      )?.label
                    : "Select Delimiter Type..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search Delimiter Type..." />
                <CommandEmpty>No Delimiter Type found.</CommandEmpty>
                <CommandGroup>
                  {allDelimiterTypes.map((delimiterType) => (
                    <CommandItem
                      key={delimiterType.value}
                      value={delimiterType.value}
                      onSelect={(value) => {
                        setValue("delimiterType", value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          delimiterType.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {delimiterType.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function FileNameContainerField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="fileNameContainer"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="fileNameContainer" showMandatoryAsterisk>
            File Name Container:
          </FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="text"
              placeholder="Enter the file name container..."
              {...register("fileNameContainer")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ValidMinLengthField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="validMinimumLength"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="validMinimumLength" showMandatoryAsterisk>
            Valid Mini Length:
          </FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="number"
              placeholder="Enter valid mini length..."
              {...register("validMinimumLength", {
                setValueAs: (v) =>
                  v === "" ? undefined : parseInt(v as string, 10),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function FirstRowHeaderField({isMutating}: {isMutating: boolean}) {
  const {register, control} = useFormContext();
  return (
    <FormField
      control={control}
      name="firstRowHeader"
      render={({field}) => (
        <FormItem>
          <FormLabel
            className="mr-2"
            htmlFor="firstRowHeader"
            showMandatoryAsterisk
          >
            First Row Header:
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating}
              aria-disabled={isMutating}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("firstRowHeader")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function SkipFooterCountField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();

  return (
    <FormField
      name="skipFooterCount"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="skipFooterCount">Skip Footer Count:</FormLabel>
          <FormControl>
            <Input
              type="number"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter skip footer count..."
              {...register("skipFooterCount", {
                setValueAs: (v) =>
                  v === "" ? undefined : parseInt(v as string, 10),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function SkipHeaderCountField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();

  return (
    <FormField
      name="skipHeaderCount"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="skipHeaderCount">Skip Header Count:</FormLabel>
          <FormControl>
            <Input
              type="number"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter skip header count..."
              {...register("skipHeaderCount", {
                setValueAs: (v) =>
                  v === "" ? undefined : parseInt(v as string, 10),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
