import {useEffect, useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {FilePenLineIcon, SaveIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {Card, CardContent, CardHeader} from "@/components/ui/card";
import {Form} from "@/components/ui/form";
import {Separator} from "@/components/ui/separator";
import {Spinner} from "@/components/ui/spinner";
import {Heading3} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {
  addCustomerRunnerConfigFn,
  CustomerResponseViaEmailField,
  CustomerRunnerConfigEnum,
  CustomMappingField,
  CustomRecordTypeField,
  DataRecordTypeField,
  DateFormatField,
  DelimiterTypeField,
  DenormalizeDocumentFormatField,
  DetailRecordTypeField,
  DocumentFormatField,
  ExpectedTimeField,
  ExtensionField,
  FileNameContainerField,
  FileNameDateFormatField,
  FileNamePartField,
  FirstRowHeaderField,
  FooterRecordTypeField,
  HeaderRecordTypeField,
  IsPaymentFileInternalAlertRequiredField,
  IsRecordTypeRequiredField,
  LineSeparatorField,
  PaymentFileFrequencyField,
  PaymentProviderField,
  PaymentRunnerConfigFormSchema,
  RecordTypeLengthField,
  ResponseDataDateFormatField,
  ResponseFileRequiredField,
  ResponseFooterRequiredField,
  ResponseHeaderDateFormatField,
  updateCustomerRunnerConfigFn,
  useDocumentFormats,
  ValidMinLengthField,
} from "@/modules/admin";
import {useKeyCloakInstanceStore} from "@/modules/auth";
import {useGetAllPaymentRunnerConfigs} from "@/modules/imremit";

interface PaymentRunnerConfigFormProps {
  externalId: string;
}

interface EditPaymentRunnerConfigFormProps
  extends PaymentRunnerConfigFormProps {
  paymentRunnerConfigId: number;
  setEditDialogOpen: (isOpen: boolean) => void;
}

export function AddPaymentRunnerConfigForm({
  externalId,
}: PaymentRunnerConfigFormProps) {
  const storeKeyCloakInstance =
    useKeyCloakInstanceStore().storeKeyCloakInstance;
  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );
  const addPaymentRunnerConfigForm = useForm<
    z.infer<typeof PaymentRunnerConfigFormSchema>
  >({
    mode: "onChange",
    defaultValues: {
      batchRunnerType: CustomerRunnerConfigEnum.PAYMENT_RUNNER,
      firstRowHeader: false,
      customMapping: false,
      responseRequired: false,
      isRecordTypeRequired: false,
      isPaymentFileInternalAlertRequired: false,
      isResponseFooterRequired: false,
      paymentFileFrequencies: [],
      validMinimumLength: 5,
      modifiedBy: parsedUserInfo?.name as string,
    },
    resolver: zodResolver(PaymentRunnerConfigFormSchema),
  });

  const {setValue} = addPaymentRunnerConfigForm;

  const [isISO, setIsISO] = useState(false);

  // Handle document format changes (ISO logic) 📝
  const formatId = addPaymentRunnerConfigForm.watch("documentFormat");
  const allDocumentFormats = useDocumentFormats("1");

  useEffect(() => {
    const selectedFormatId = Number(formatId);
    const selectedFormat = allDocumentFormats.find(
      (doc) => doc.value === selectedFormatId.toString()
    );

    // Check if it's an ISO format 📄
    setIsISO(selectedFormat?.label.includes("ISO") || false);
  }, [formatId, allDocumentFormats]);

  // Handle specific behavior when ISO format is selected 🔧
  useEffect(() => {
    if (isISO) {
      setValue("dateFormat", "9");
      setValue("firstRowHeader", false);
      setValue("customMapping", false);
      setValue("isRecordTypeRequired", false);
      setValue("delimiterType", "");
      setValue("validMinimumLength", 5);
      setValue("responseDataDateFormat", "9");
      setValue("isResponseFooterRequired", false);
      setValue("lineSeparator", "");
      setValue("responseHeaderDateFormat", "");
    }
  }, [isISO, setValue]);

  // Watch certain form fields for conditional rendering 🕵️‍♂️
  const isRecordTypeRequired = addPaymentRunnerConfigForm.watch(
    "isRecordTypeRequired"
  );
  const isResponseRequired =
    addPaymentRunnerConfigForm.watch("responseRequired");
  const isInternalAlertRequired = addPaymentRunnerConfigForm.watch(
    "isPaymentFileInternalAlertRequired"
  );

  // Reset record type fields if not required ✂️
  useEffect(() => {
    if (!isRecordTypeRequired) {
      setValue("recordTypeLength", 0);
      setValue("headerRecordType", null);
      setValue("detailRecordType", null);
      setValue("customRecordType", null);
      setValue("dataRecordType", null);
      setValue("footerRecordType", null);
    }
  }, [isRecordTypeRequired, setValue]);

  // Handle mutation for saving Payment Runner Config 💾
  const savePaymentRunnerConfigMutation = useMutation({
    mutationFn: (data: z.infer<typeof PaymentRunnerConfigFormSchema>) => {
      return addCustomerRunnerConfigFn(data, externalId);
    },
    onSuccess: (response) => {
      console.log("Create runner config response: ", response);
      // Reset form after successful save
      addPaymentRunnerConfigForm.reset();
      toast({
        variant: "success",
        title: "Success!",
        description: "Runner config added successfully",
      });
    },
    onError: (error: unknown) => {
      const errorMessage =
        typeof error === "string"
          ? error
          : (error instanceof Error && error.message) || "An error occurred";
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add runner config",
      });
    },
  });

  // Handle form submission 🚀
  function onPaymentRunnerConfigSubmit(
    data: z.infer<typeof PaymentRunnerConfigFormSchema>
  ) {
    savePaymentRunnerConfigMutation.mutate(data);
  }

  const {isPending: isMutating} = savePaymentRunnerConfigMutation;

  return (
    <>
      <Form {...addPaymentRunnerConfigForm}>
        <form
          onSubmit={addPaymentRunnerConfigForm.handleSubmit((data) => {
            onPaymentRunnerConfigSubmit(data); // Submit form 📝
          })}
        >
          <section className="my-6 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <PaymentProviderField isMutating={isMutating} />
            <DocumentFormatField isMutating={isMutating} />
            <DateFormatField isISO={isISO} isMutating={isMutating} />
            <DelimiterTypeField isISO={isISO} isMutating={isMutating} />
            <FileNameContainerField isMutating={isMutating} />
            <ValidMinLengthField isISO={isISO} isMutating={isMutating} />
            <DenormalizeDocumentFormatField isMutating={isMutating} />
          </section>

          <Card>
            <CardHeader>
              <Heading3>Runner Details</Heading3>
            </CardHeader>
            <CardContent className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3">
              <FirstRowHeaderField isISO={isISO} isMutating={isMutating} />
              <CustomMappingField isISO={isISO} isMutating={isMutating} />
              <IsRecordTypeRequiredField
                isISO={isISO}
                isMutating={isMutating}
              />
              <ResponseFileRequiredField isMutating={isMutating} />
              <IsPaymentFileInternalAlertRequiredField
                isMutating={isMutating}
              />
            </CardContent>
          </Card>

          {/* Conditional rendering based on form state */}
          {isRecordTypeRequired && (
            <section className="my-6 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              <RecordTypeLengthField isMutating={isMutating} />
              <HeaderRecordTypeField isMutating={isMutating} />
              <DetailRecordTypeField isMutating={isMutating} />
              <CustomRecordTypeField isMutating={isMutating} />
              <DataRecordTypeField isMutating={isMutating} />
              <FooterRecordTypeField isMutating={isMutating} />
            </section>
          )}

          {isResponseRequired && (
            <div className="my-6 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              <ResponseFooterRequiredField
                isISO={isISO}
                isMutating={isMutating}
              />
              <ResponseHeaderDateFormatField
                isISO={isISO}
                isMutating={isMutating}
              />
              <FileNamePartField isMutating={isMutating} />
              <ExtensionField isMutating={isMutating} />
              <LineSeparatorField isISO={isISO} isMutating={isMutating} />
              <ResponseDataDateFormatField
                isISO={isISO}
                isMutating={isMutating}
              />
              <FileNameDateFormatField isMutating={isMutating} />
              <CustomerResponseViaEmailField isMutating={isMutating} />
            </div>
          )}

          {isInternalAlertRequired && (
            <div className="mb-5 mt-5 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              <PaymentFileFrequencyField isMutating={isMutating} />
              <ExpectedTimeField />
            </div>
          )}

          {/* Submit button */}
          <div className="flex items-center justify-end space-x-2 py-4">
            <Button
              type="submit"
              className="gap-2"
              variant="affirmative"
              disabled={isMutating}
              aria-disabled={isMutating}
            >
              {isMutating ? <Spinner size="xs" /> : ""}
              <SaveIcon className="size-4" />
              Add Payment Runner Config
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
}

/**
 * EditPaymentRunnerConfigForm Component
 *
 * This component renders the form for editing a Payment Runner configuration.
 *
 * @param {EditPaymentRunnerConfigFormProps} props - Properties passed to the component.
 * @returns {JSX.Element} - Rendered component.
 */
export function EditPaymentRunnerConfigForm({
  externalId,
  paymentRunnerConfigId,
  setEditDialogOpen,
}: EditPaymentRunnerConfigFormProps) {
  const storeKeyCloakInstance =
    useKeyCloakInstanceStore().storeKeyCloakInstance;
  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );

  // Set up form using `react-hook-form` with Zod validation 🚀
  const editPaymentRunnerConfigForm = useForm<
    z.infer<typeof PaymentRunnerConfigFormSchema>
  >({
    mode: "onChange", // Validation mode on change
    defaultValues: {
      batchRunnerType: CustomerRunnerConfigEnum.PAYMENT_RUNNER,
      firstRowHeader: false,
      customMapping: false,
      responseRequired: false,
      isRecordTypeRequired: false,
      isPaymentFileInternalAlertRequired: false,
      isResponseFooterRequired: false,
      paymentFileFrequencies: [],
      validMinimumLength: 5,
      modifiedBy: parsedUserInfo?.name as string,
    },
    resolver: zodResolver(PaymentRunnerConfigFormSchema),
  });

  const {setValue, reset} = editPaymentRunnerConfigForm;

  // Get Payment Runner config from server via `react-query` 📡
  const paymentRunnerConfigQuery = useGetAllPaymentRunnerConfigs(externalId);

  // ISO flag state 🏷
  const [isISO, setIsISO] = useState(false);

  // find runner config data by paymentRunnerConfigId
  const paymentRunnerConfigData = paymentRunnerConfigQuery.data?.content.find(
    (config) => config.id === paymentRunnerConfigId
  );

  useEffect(() => {
    if (paymentRunnerConfigData) {
      // Transform data 🛠️
      const transformedData = {
        ...paymentRunnerConfigData,
        batchRunnerType: CustomerRunnerConfigEnum.PAYMENT_RUNNER,
        paymentProvider:
          paymentRunnerConfigData.paymentProvider?.toString() || "",
        dateFormat: paymentRunnerConfigData.dateFormat?.toString() || "",
        delimiterType: paymentRunnerConfigData.delimiterType?.toString() || "",
        isResponseFooterRequired:
          paymentRunnerConfigData.isResponseFooterRequired ?? false,
        lineSeparator: paymentRunnerConfigData.lineSeparator?.toString() || "",
        responseHeaderDateFormat:
          paymentRunnerConfigData.responseHeaderDateFormat?.toString() || "",
        documentFormat:
          paymentRunnerConfigData.documentFormat?.toString() || "",
        fileNameContainer:
          paymentRunnerConfigData.fileNameContainer?.toString() || "",
        firstRowHeader: paymentRunnerConfigData.firstRowHeader ?? false,
        validMinimumLength: paymentRunnerConfigData.validMinimumLength ?? 5,
        documentFormatDenormalize:
          paymentRunnerConfigData.documentFormatDenormalize?.toString() || "",
        customMapping: paymentRunnerConfigData.customMapping ?? false,
        recordTypeLength: paymentRunnerConfigData.recordTypeLength ?? 0,
        headerRecordType:
          paymentRunnerConfigData.headerRecordType?.toString() || "",
        dataRecordType:
          paymentRunnerConfigData.dataRecordType?.toString() || "",
        detailRecordType:
          paymentRunnerConfigData.detailRecordType?.toString() || "",
        customRecordType:
          paymentRunnerConfigData.customRecordType?.toString() || "",
        footerRecordType:
          paymentRunnerConfigData.footerRecordType?.toString() || "",
        responseRequired: paymentRunnerConfigData.responseRequired ?? false,
        responseDataDateFormat:
          paymentRunnerConfigData.responseDataDateFormat?.toString() || "",
        fileNameDateFormat:
          paymentRunnerConfigData.fileNameDateFormat?.toString() || "",
        fileNamePart: paymentRunnerConfigData.fileNamePart?.toString() || "",
        extension: paymentRunnerConfigData.extension?.toString() || "",
        isRecordTypeRequired:
          paymentRunnerConfigData.isRecordTypeRequired ?? false,
        isPaymentFileInternalAlertRequired:
          paymentRunnerConfigData.isPaymentFileInternalAlertRequired ?? false,
        paymentFileFrequency:
          paymentRunnerConfigData.paymentFileFrequency?.toString() || "",
        paymentFileExpectedTime:
          paymentRunnerConfigData.paymentFileExpectedTime?.toString() || "",
        customerResponseViaEmail:
          paymentRunnerConfigData.customerResponseViaEmail?.toString() || "",
        paymentFileFrequencies: paymentRunnerConfigData.paymentFileFrequency
          ? paymentRunnerConfigData.paymentFileFrequency.split(",") // Handle as array 🗂️
          : [],
      };

      // Reset form with transformed data 🔄
      reset(transformedData);
    }
  }, [paymentRunnerConfigQuery.data?.content, paymentRunnerConfigData, reset]);

  // Handle document format changes (ISO logic) 📝
  const formatId = editPaymentRunnerConfigForm.watch("documentFormat");
  const allDocumentFormats = useDocumentFormats("1");

  useEffect(() => {
    const selectedFormatId = Number(formatId);
    const selectedFormat = allDocumentFormats.find(
      (doc) => doc.value === selectedFormatId.toString()
    );

    // Check if it's an ISO format 📄
    setIsISO(selectedFormat?.label.includes("ISO") || false);
  }, [formatId, allDocumentFormats]);

  // Handle specific behavior when ISO format is selected 🔧
  useEffect(() => {
    if (isISO) {
      setValue("dateFormat", "9");
      setValue("firstRowHeader", false);
      setValue("customMapping", false);
      setValue("isRecordTypeRequired", false);
      setValue("delimiterType", "");
      setValue("validMinimumLength", 5);
      setValue("responseDataDateFormat", "9");
      setValue("isResponseFooterRequired", false);
      setValue("lineSeparator", "");
      setValue("responseHeaderDateFormat", "");
    }
  }, [isISO, setValue]);

  // Watch certain form fields for conditional rendering 🕵️‍♂️
  const isRecordTypeRequired = editPaymentRunnerConfigForm.watch(
    "isRecordTypeRequired"
  );
  const isResponseRequired =
    editPaymentRunnerConfigForm.watch("responseRequired");
  const isInternalAlertRequired = editPaymentRunnerConfigForm.watch(
    "isPaymentFileInternalAlertRequired"
  );

  // Reset record type fields if not required ✂️
  // useEffect(() => {
  //   console.log("isRecordTypeRequired: ", isRecordTypeRequired);
  //   if (!isRecordTypeRequired) {
  //     console.log("Resetting record type fields");
  //     setValue("recordTypeLength", 0);
  //     setValue("headerRecordType", null);
  //     setValue("detailRecordType", null);
  //     setValue("customRecordType", null);
  //     setValue("dataRecordType", null);
  //     setValue("footerRecordType", null);
  //   }
  // }, [isRecordTypeRequired, setValue]);

  const updatePaymentRunnerConfigMutation = useMutation({
    mutationFn: (data: z.infer<typeof PaymentRunnerConfigFormSchema>) => {
      return updateCustomerRunnerConfigFn(
        data,
        externalId,
        paymentRunnerConfigId
      );
    },
    onSuccess: (response) => {
      console.log("Update runner config response: ", response);
      editPaymentRunnerConfigForm.reset();
      toast({
        variant: "success",
        title: "Success!",
        description: "Runner config updated successfully",
      });
      setEditDialogOpen(false);
    },
    onError: (error: unknown) => {
      const errorMessage =
        typeof error === "string"
          ? error
          : (error instanceof Error && error.message) || "An error occurred";
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update runner config",
      });
    },
  });

  // Handle form submission 🚀
  function onPaymentRunnerConfigSubmit(
    data: z.infer<typeof PaymentRunnerConfigFormSchema>
  ) {
    console.log("Submitting data: ", data);
    updatePaymentRunnerConfigMutation.mutate({
      ...data,
      modifiedBy: parsedUserInfo?.name as string,
    });
  }

  const {isPending: isMutating} = updatePaymentRunnerConfigMutation;

  return (
    <>
      <Heading3 className="mb-4">Edit Payment Runner Config</Heading3>
      <Form {...editPaymentRunnerConfigForm}>
        <form
          onSubmit={editPaymentRunnerConfigForm.handleSubmit((data) => {
            onPaymentRunnerConfigSubmit(data); // Submit form 📝
          })}
        >
          <section className="my-6 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            <PaymentProviderField isMutating={isMutating} />
            <DocumentFormatField isMutating={isMutating} />
            <DateFormatField isISO={isISO} isMutating={isMutating} />
            <DelimiterTypeField isISO={isISO} isMutating={isMutating} />
            <FileNameContainerField isMutating={isMutating} />
            <ValidMinLengthField isISO={isISO} isMutating={isMutating} />
            <DenormalizeDocumentFormatField isMutating={isMutating} />
          </section>

          <Card>
            <CardHeader>
              <Heading3>Runner Details</Heading3>
            </CardHeader>
            <CardContent className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3">
              <FirstRowHeaderField isISO={isISO} isMutating={isMutating} />
              <CustomMappingField isISO={isISO} isMutating={isMutating} />
              <IsRecordTypeRequiredField
                isISO={isISO}
                isMutating={isMutating}
              />
              <ResponseFileRequiredField isMutating={isMutating} />
              <IsPaymentFileInternalAlertRequiredField
                isMutating={isMutating}
              />
            </CardContent>
          </Card>

          {/* Conditional rendering based on form state */}
          {isRecordTypeRequired && (
            <>
              <section className="my-6 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                <RecordTypeLengthField isMutating={isMutating} />
                <HeaderRecordTypeField isMutating={isMutating} />
                <DetailRecordTypeField isMutating={isMutating} />
                <CustomRecordTypeField isMutating={isMutating} />
                <DataRecordTypeField isMutating={isMutating} />
                <FooterRecordTypeField isMutating={isMutating} />
              </section>
              <Separator className="my-4" />
            </>
          )}

          {isResponseRequired && (
            <div className="my-6 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              <ResponseFooterRequiredField
                isISO={isISO}
                isMutating={isMutating}
              />
              <ResponseHeaderDateFormatField
                isISO={isISO}
                isMutating={isMutating}
              />
              <FileNamePartField isMutating={isMutating} />
              <ExtensionField isMutating={isMutating} />
              <LineSeparatorField isISO={isISO} isMutating={isMutating} />
              <ResponseDataDateFormatField
                isISO={isISO}
                isMutating={isMutating}
              />
              <FileNameDateFormatField isMutating={isMutating} />
              <CustomerResponseViaEmailField isMutating={isMutating} />
            </div>
          )}

          {isInternalAlertRequired && (
            <div className="mb-5 mt-5 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
              <PaymentFileFrequencyField isMutating={isMutating} />
              <ExpectedTimeField />
            </div>
          )}

          {/* Submit button */}
          <div className="flex items-center justify-end space-x-2 py-4">
            <Button
              type="submit"
              className="gap-2"
              variant="affirmative"
              disabled={isMutating}
              aria-disabled={isMutating}
            >
              {isMutating ? (
                <Spinner size="xs" />
              ) : (
                <FilePenLineIcon className="size-4" />
              )}
              Edit Payment Runner Config
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
}
