/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {
  CustomerManagementRowActions,
  CustomerOnboardingStatusTable,
  renderBillingTypeBadge,
  renderPaymentMethod,
  renderPaymentType,
  renderSettlementTerms,
} from "@/modules/admin";
import {type CustomerType} from "@/modules/customers";

import {FallbackMessages} from "@/utils/constants";

export const customerManagementTableColumns: ColumnDef<CustomerType>[] = [
  {
    accessorKey: "id",
    header: ({column}) => <DataTableColumnHeader column={column} title="ID" />,
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("id") ? (
          row.getValue("id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    enableSorting: true,
  },
  {
    accessorKey: "buyerName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Customer Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("buyerName") ? (
          row.getValue("buyerName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    enableSorting: true,
  },
  {
    accessorKey: "status",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Onboarding Status" />
    ),
    cell: ({row}) => {
      if (!row.original.status) {
        return (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );
      }

      return <CustomerOnboardingStatusTable id={row.original.externalId} />;
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    enableSorting: true,
    sortingFn: "text",
  },
  {
    accessorKey: "billingType",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Billing Type" />
    ),
    cell: ({row}) => {
      if (!row.original.billingType) {
        return (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );
      }

      return renderBillingTypeBadge(row.original.billingType);
    },

    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    enableSorting: true,
  },
  {
    accessorKey: "paymentType",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment Type" />
    ),
    cell: ({row}) => {
      if (!row.original.paymentType) {
        return (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );
      }

      return renderPaymentType(row.original.paymentType);
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    enableSorting: true,
  },
  {
    accessorKey: "paymentMethod",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment Method" />
    ),
    cell: ({row}) => {
      if (!row.original.paymentMethod) {
        return (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );
      }

      return renderPaymentMethod(row.original.paymentMethod);
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    enableSorting: true,
  },
  {
    accessorKey: "settlementTerms",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Settlement Terms" />
    ),
    cell: ({row}) => {
      if (!row.original.settlementTerms) {
        return (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );
      }

      return renderSettlementTerms(row.original.settlementTerms);
    },
    filterFn: (row, id, value) => {
      return value.includes(row.getValue(id));
    },
    enableSorting: true,
  },
  {
    accessorKey: "invoiceDay",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Day" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoiceDay") ? (
          <Paragraph className="uppercase">
            {row.getValue("invoiceDay")}
          </Paragraph>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    enableSorting: true,
  },

  {
    accessorKey: "contactName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Contact" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("contactName") ? (
          row.getValue("contactName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    enableSorting: true,
  },

  {
    accessorKey: "address",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Address" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("address") ? (
          row.getValue("address")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    enableSorting: true,
  },

  {
    accessorKey: "phone",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Phone" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("phone") ? (
          row.getValue("phone")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    enableSorting: true,
  },

  {
    accessorKey: "email",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Email" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("email") ? (
          row.getValue("email")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    enableSorting: true,
  },

  {
    accessorKey: "bill",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Bill" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("bill") ? (
          row.getValue("bill")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    enableSorting: true,
  },

  {
    accessorKey: "mccTemplateOptions",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="MCC Options" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("mccTemplateOptions") ? (
          row.getValue("mccTemplateOptions")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    enableSorting: true,
  },

  {
    accessorKey: "reportingTool",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Reporting Tool" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("reportingTool") ? (
          row.getValue("reportingTool")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    enableSorting: true,
  },

  {
    accessorKey: "organizationId",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Organization ID" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("organizationId") ? (
          row.getValue("organizationId")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    enableSorting: true,
  },

  {
    accessorKey: "accountNumberMasking",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Account Masking" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("accountNumberMasking") ? (
          row.getValue("accountNumberMasking")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    enableSorting: true,
  },

  {
    accessorKey: "responseFileDataMasking",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Response Masking" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("responseFileDataMasking") ? (
          row.getValue("responseFileDataMasking")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    enableSorting: true,
  },

  {
    accessorKey: "connectivityMethod",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Connectivity Method" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("connectivityMethod") ? (
          row.getValue("connectivityMethod")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    enableSorting: true,
  },

  {
    accessorKey: "pidmapping",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="PID Mapping" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("pidmapping") ? (
          row.getValue("pidmapping")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    enableSorting: true,
  },

  {
    accessorKey: "multiselectpidmapping",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Multi Mapping" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("multiselectpidmapping") ? (
          row.getValue("multiselectpidmapping")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    enableSorting: true,
  },
  // {
  //   id: "actions",
  //   header: () => (
  //     <Paragraph className="text-right">Customer Actions</Paragraph>
  //   ),
  //   cell: ({row}) => {
  //     if (row.original.status === "active") {
  //       return (
  //         <div className="flex justify-end gap-2">
  //           <Link
  //             to="/app/admin/customer-management/$externalId/edit"
  //             params={{externalId: row.original.id.toString()}}
  //             className={cn(
  //               buttonVariants({size: "sm"}),
  //               "gap-2 bg-amber-500 hover:bg-amber-500/80 active:bg-amber-600/80"
  //             )}
  //           >
  //             <Paragraph className="font-sans text-xs font-semibold text-white">
  //               Edit
  //             </Paragraph>
  //             <span className="sr-only">Edit Customer</span>
  //             <Edit3Icon className="size-4" />
  //           </Link>
  //           <Link
  //             to="/app/admin/customer-management/$externalId/view"
  //             params={{externalId: row.original.id.toString()}}
  //             className={cn(
  //               buttonVariants({size: "sm"}),
  //               "gap-2 bg-neutral-foreground hover:bg-neutral-foreground/80 active:bg-neutral-foreground/80"
  //             )}
  //           >
  //             <Paragraph className="font-sans text-xs font-semibold text-white">
  //               Profile
  //             </Paragraph>
  //             <span className="sr-only">Customer Profile</span>
  //             <FileTextIcon className="size-4" />
  //           </Link>
  //         </div>
  //       );
  //     }

  //     return (
  //       <div className="flex gap-2">
  //         {row.original.status !== "active" &&
  //           row.original.moduleSubscriptions &&
  //           row.original.moduleSubscriptions.length > 0 &&
  //           row.original.moduleSubscriptions.map(
  //             (moduleSubscription, index) => {
  //               if (moduleSubscription.moduleName === "imremit") {
  //                 return (
  //                   <Link
  //                     key={index}
  //                     to="/app/admin/customer-management/imremit/$externalId/onboarding"
  //                     params={{externalId: row.original.id.toString()}}
  //                     className={cn(
  //                       buttonVariants({size: "sm"}),
  //                       "gap-2 bg-success-foreground hover:bg-success-foreground/80 active:bg-success-foreground/80"
  //                     )}
  //                   >
  //                     <Paragraph className="font-sans text-xs font-semibold text-white">
  //                       Onboard
  //                     </Paragraph>
  //                     <span className="sr-only">Onboard Customer</span>
  //                     <PackagePlusIcon className="size-4" />
  //                   </Link>
  //                 );
  //               }
  //               // TODO: Add other modules here
  //             }
  //           )}
  //       </div>
  //     );
  //   },
  // },

  {
    id: "actions",
    header: () => <Paragraph className="text-right">Actions</Paragraph>,
    cell: ({row}) => (
      <div className="flex justify-end">
        <CustomerManagementRowActions row={row} />
      </div>
    ),
  },
];
