import {create} from "zustand";

import {type PaginationMetaType} from "@/lib/ky";
import {type ImREmitPaymentType as ImREmitPaymentTypeCriteriaType} from "@/modules/imremit";

import {type SortMetaType} from "@/utils/query-params-generator";

/**
 * Interface: PaymentMgmtCardFilterMetaStoreState
 * Defines the shape of the state for the PaymentMgmtCardFilterPaginationMetaStore
 */
interface PaymentMgmtCardFilterMetaStoreState {
  /**
   * storePaymentMgmtCardFilterPaginationMeta
   * Holds the pagination metadata related to PaymentMgmtCardFilter.
   */
  storePaymentMgmtCardFilterPaginationMeta: PaginationMetaType | null;

  /**
   * storePaymentMgmtCardFilterCriteria
   * Holds the criteria by which PaymentMgmtCardFilter are filtered.
   */
  storePaymentMgmtCardFilterCriteria: ImREmitPaymentTypeCriteriaType | null;

  /**
   * storePaymentMgmtCardFilterSortMeta
   * Holds the sort metadata related to PaymentMgmtCardFilter.
   */
  storePaymentMgmtCardFilterSortMeta: SortMetaType | null;

  /**
   * handleOnPageChange
   * Holds the function to handle page change.
   * This is used to update the page number in the store.
   * This is used in the PaymentMgmtCardFilterDataTable component.
   *
   * @param pageNumber - The new page number
   * @returns void
   *
   * @example
   */
  handleOnPageChange?: (pageNumber: number) => Promise<void>;
}

/**
 * Interface: PaymentMgmtCardFilterMetaStoreActions
 * Defines the set of actions for updating the PaymentMgmtCardFilterMetaStoreState.
 */
interface PaymentMgmtCardFilterMetaStoreActions {
  /**
   * Action: setStorePaymentMgmtCardFilterPaginationMeta
   * Updates the 'storePaymentMgmtCardFilterPaginationMeta' field in the state.
   *
   * @param storePaymentMgmtCardFilterPaginationMeta - The new value for 'storePaymentMgmtCardFilterPaginationMeta'
   */
  setStorePaymentMgmtCardFilterPaginationMeta: (
    storePaymentMgmtCardFilterPaginationMeta: PaymentMgmtCardFilterMetaStoreState["storePaymentMgmtCardFilterPaginationMeta"]
  ) => void;

  /**
   * Action: setStorePaymentMgmtCardFilterCriteria
   * Updates the 'storePaymentMgmtCardFilterCriteria' field in the state.
   *
   * @param storePaymentMgmtCardFilterCriteria - The new value for 'storePaymentMgmtCardFilterCriteria'
   */
  setStorePaymentMgmtCardFilterCriteria: (
    storePaymentMgmtCardFilterCriteria: PaymentMgmtCardFilterMetaStoreState["storePaymentMgmtCardFilterCriteria"]
  ) => void;

  /**
   * Action: setStorePaymentMgmtCardFilterSortMeta
   * Updates the 'storePaymentMgmtCardFilterSortMeta' field in the state.
   *
   * @param storePaymentMgmtCardFilterSortMeta - The new value for 'storePaymentMgmtCardFilterSortMeta'
   */
  setStorePaymentMgmtCardFilterSortMeta: (
    storePaymentMgmtCardFilterSortMeta: PaymentMgmtCardFilterMetaStoreState["storePaymentMgmtCardFilterSortMeta"]
  ) => void;

  /**
   * Action: setHandleOnPageChange
   * Updates the 'handleOnPageChange' field in the state.
   *
   * @param handleOnPageChange - The new value for 'handleOnPageChange'
   */
  setHandleOnPageChange: (
    handleOnPageChange: (pageNumber: number) => Promise<void>
  ) => void;
}

/**
 * Function: usePaymentMgmtCardFilterMetaStore
 * Creates a Zustand store to handle file processing pagination metadata and criteria.
 */
export const usePaymentMgmtCardFilterMetaStore = create<
  PaymentMgmtCardFilterMetaStoreState & PaymentMgmtCardFilterMetaStoreActions
>((set) => ({
  // Initialize 'storePaymentMgmtCardFilterPaginationMeta', 'storePaymentMgmtCardFilterCriteria' and 'handleOnPageChange'
  // with default values of null
  storePaymentMgmtCardFilterPaginationMeta: null,
  storePaymentMgmtCardFilterCriteria: null,
  storePaymentMgmtCardFilterSortMeta: null,
  handleOnPageChange: undefined,

  /**
   * Implementation for 'setStorePaymentMgmtCardFilterPaginationMeta' action.
   * Updates the 'storePaymentMgmtCardFilterPaginationMeta' field in the state.
   *
   * @param storePaymentMgmtCardFilterPaginationMeta - The new value for 'storePaymentMgmtCardFilterPaginationMeta'
   */
  setStorePaymentMgmtCardFilterPaginationMeta: (
    storePaymentMgmtCardFilterPaginationMeta
  ) => {
    set(() => ({storePaymentMgmtCardFilterPaginationMeta}));
  },

  /**
   * Implementation for 'setStorePaymentMgmtCardFilterCriteria' action.
   * Updates the 'storePaymentMgmtCardFilterCriteria' field in the state.
   *
   * @param storePaymentMgmtCardFilterCriteria - The new value for 'storePaymentMgmtCardFilterCriteria'
   */
  setStorePaymentMgmtCardFilterCriteria: (
    storePaymentMgmtCardFilterCriteria
  ) => {
    set(() => ({storePaymentMgmtCardFilterCriteria}));
  },

  /**
   * Implementation for 'setStorePaymentMgmtCardFilterSortMeta' action.
   * Updates the 'storePaymentMgmtCardFilterSortMeta' field in the state.
   *
   * @param storePaymentMgmtCardFilterSortMeta - The new value for 'storePaymentMgmtCardFilterSortMeta'
   */
  setStorePaymentMgmtCardFilterSortMeta: (
    storePaymentMgmtCardFilterSortMeta
  ) => {
    set(() => ({storePaymentMgmtCardFilterSortMeta}));
  },

  /**
   * Implementation for 'setHandleOnPageChange' action.
   * Updates the 'handleOnPageChange' field in the state.
   *
   * @param handleOnPageChange - The new value for 'handleOnPageChange'
   */
  setHandleOnPageChange: (handleOnPageChange) => {
    set(() => ({handleOnPageChange}));
  },
}));
