/* eslint-disable */

import {type ColumnDef} from "@tanstack/react-table";
import {z} from "zod";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {MISPaymentsDifferencesType} from "@/modules/admin";

import {FallbackMessages} from "@/utils/constants";

// 💰 Formatter for currency values in USD
const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const misPaymentsDifferencesTableColumnsSchema = z.object({
  year: z.boolean(),
  month: z.boolean(),
  payments_received: z.boolean(),
  payments_posted: z.boolean(),
  diff_received_value: z.boolean(),
  diff_received_percent: z.boolean(),
  diff_posted_value: z.boolean(),
  diff_posted_percent: z.boolean(),
});

export type misPaymentsDifferencesColumnsType = z.infer<
  typeof misPaymentsDifferencesTableColumnsSchema
>;

export const misPaymentsDifferencesTableColumns: ColumnDef<MISPaymentsDifferencesType>[] =
  [
    {
      accessorKey: "year",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Year" />
      ),
      cell: ({row}) => (
        <div className="w-auto">
          {row.getValue("year") ? (
            <div className="flex flex-col gap-y-2">
              <Paragraph className="font-bold">
                {row.getValue("year")}
              </Paragraph>
            </div>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "month",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Month" />
      ),
      cell: ({row}) => (
        <div className="w-auto">
          {row.getValue("month") ? (
            <div className="flex flex-col gap-y-2">
              <Paragraph className="font-bold">
                {row.getValue("month")}
              </Paragraph>
            </div>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "payments_received",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Payments Received" />
      ),
      cell: ({row}) => (
        <div className="w-auto">
          {row.getValue("payments_received") ? (
            <div className="flex flex-col gap-y-2">
              <Paragraph className="font-bold">
                {currencyFormatter.format(row.getValue("payments_received"))}
              </Paragraph>
            </div>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "diff_received_value",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Diff Received Value" />
      ),
      cell: ({row}) => (
        <div className="w-auto">
          {row.getValue("diff_received_value") ? (
            <div className="flex flex-col gap-y-2">
              <Paragraph className="font-bold">
                {currencyFormatter.format(row.getValue("diff_received_value"))}
              </Paragraph>
            </div>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "diff_received_percent",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Diff Received Percent" />
      ),
      cell: ({row}) => (
        <div className="w-auto">
          {row.getValue("diff_received_percent") ? (
            <div className="flex flex-col gap-y-2">
              <Paragraph className="font-bold">
                {Number(row.getValue("diff_received_percent")).toFixed(2)}%
              </Paragraph>
            </div>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "payments_posted",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Payments Posted" />
      ),
      cell: ({row}) => (
        <div className="w-auto">
          {row.getValue("payments_posted") ? (
            <div className="flex flex-col gap-y-2">
              <Paragraph className="font-bold">
                {currencyFormatter.format(row.getValue("payments_posted"))}
              </Paragraph>
            </div>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "diff_posted_value",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Diff Posted Value" />
      ),
      cell: ({row}) => (
        <div className="w-auto">
          {row.getValue("diff_posted_value") ? (
            <div className="flex flex-col gap-y-2">
              <Paragraph className="font-bold">
                {currencyFormatter.format(row.getValue("diff_posted_value"))}
              </Paragraph>
            </div>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "diff_posted_percent",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Diff Posted Percent" />
      ),
      cell: ({row}) => (
        <div className="w-auto">
          {row.getValue("diff_posted_percent") ? (
            <div className="flex flex-col gap-y-2">
              <Paragraph className="font-bold">
                {Number(row.getValue("diff_posted_percent")).toFixed(2)}%
              </Paragraph>
            </div>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    // {
    //   id: "actions",
    //   header: () => <Paragraph className="text-right">Actions</Paragraph>,
    //   cell: ({row}) => (
    //     <div className="flex justify-end">
    //       <DupesTrackerRowActions row={row} />
    //     </div>
    //   ),
    // },
  ];
