import {createRoute} from "@tanstack/react-router";
import {z} from "zod";

import {PageNotFound} from "@/components/layout/not-found";

import {RoutesDirectory} from "@/lib/routes/directory";
import {authorizedRootRoute} from "@/lib/routes/root";
import {
  DupesCriteriaSettingsPage,
  DupesDashboardPage,
  DupesDashboardRedirect,
  DupesTrackerPage,
  DuplicatePaymentsOutlet,
} from "@/modules/duplicate-payments";

export const duplicatePaymentRootRoute = createRoute({
  path: RoutesDirectory.DUPLICATE_PAYMENT,
  component: DuplicatePaymentsOutlet,
  getParentRoute: () => authorizedRootRoute,
});

export const duplicatePaymentsDashboardRedirectRoute = createRoute({
  path: RoutesDirectory.ROOT,
  component: DupesDashboardRedirect,
  getParentRoute: () => duplicatePaymentRootRoute,
});

export const duplicatePaymentsDashboardRoute = createRoute({
  path: RoutesDirectory.DASHBOARD,
  component: DupesDashboardPage,
  getParentRoute: () => duplicatePaymentRootRoute,
});

export const dupesTrackerSearchFilterSchema = z.object({
  dupesLikeliness: z
    .enum([
      "likely",
      "maybe",
      "different-supplier-likely",
      "different-supplier-maybe",
      "different-amounts-and-dates",
      "different-dates",
      "different-suppliers-and-dates",
      "different-amounts",
      "credit-pair",
    ])
    .optional(),
});

export type DupesTrackerSearchFilterType = z.infer<
  typeof dupesTrackerSearchFilterSchema
>;

export const dupesTrackerRoute = createRoute({
  path: RoutesDirectory.TRACKER,
  component: DupesTrackerPage,
  getParentRoute: () => duplicatePaymentRootRoute,
  validateSearch: (search) => dupesTrackerSearchFilterSchema.parse(search),
});

export const duplicatePaymentsCriteriaSettingsRoute = createRoute({
  path: RoutesDirectory.CRITERIA_SETTINGS,
  component: DupesCriteriaSettingsPage,
  getParentRoute: () => duplicatePaymentRootRoute,
});

export const duplicatePaymentsNotFoundRoute = createRoute({
  // all routes from /app that are not defined will be redirected to this route
  path: RoutesDirectory.CATCH_ALL,
  component: PageNotFound,
  getParentRoute: () => duplicatePaymentRootRoute,
});
