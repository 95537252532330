import {z} from "zod";

export const suppliersDetailsSchema = z.object({
  supplierId: z.number(),
  customerName: z.string().optional(),
  supplierNumber: z.string().optional(),
  supplierName: z.string().optional(),
  enrolled: z.boolean().optional(),

  clientKey: z.string().optional(),
  websiteKey: z.string().optional(),

  taxId: z.string().optional(),
  supplierEmail: z.string().optional(),
  onboardStatus: z.string().optional(),
  address1: z.string().optional(),
  address2: z.string().optional(),
  address3: z.string().optional(),
  address4: z.string().optional(),
  city: z.string().optional(),
  state: z.string().optional(),
  zip: z.string().optional(),
  locationCode: z.string().optional(),
  phoneNumber: z.string().optional(),
  country: z.string().optional(),
  contactName: z.string().optional(),
  declinedReason: z.string().optional(),
  contactEmail: z.string().optional(),

  declinedDate: z.string().optional(),
  enrolledDate: z.string().optional(),
  supplierSingleUseFlag: z.boolean().optional(),
  supplierCardOnFileFlag: z.boolean().optional(),
  supplierPaymentCounterFlag: z.boolean().optional(),
  supplierBufferLimitAmount: z.number().optional(),

  createTimestamp: z.string().optional(),
  lastEditTimestamp: z.string().optional(),

  paymentMode: z.string().optional(),
  userId: z.string().optional(),
  password: z.string().optional(),
  proxyPayUrl: z.string().optional(),
  remittanceEmail: z.string().optional(),
  remittanceName: z.string().optional(),
  remittancePhoneNumber: z.string().optional(),
  w9formUrl: z.string().optional(),

  maxAmountPerTransaction: z.number().optional(),
  minAmountPerTransaction: z.number().optional(),
  amountTransactionLimitPerDay: z.number().optional(),
  noOfTransactionPerDay: z.number().optional(),

  paymentRestrictions: z.boolean().optional(),
  selfRegisteredFlag: z.boolean().optional(),
  scriptFlag: z.boolean().optional(),
  universalAcceptor: z.boolean().optional(),
});

export type SupplierDetailsType = z.infer<typeof suppliersDetailsSchema>;
