import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {facilityQueryKeys, type Facilities} from "@/modules/facilities";

// Defining a type based on the function `getAllFacility`
type QueryFnType = typeof getFacilitiesByCustomers;

// Function to fetch facilities by customerIds
export function getFacilitiesByCustomers(customerIds: string[]) {
  // Joining all customerIds into a string separated by comma
  const customerIdsString = customerIds.join(",");

  // Fetch all facility from API using ky
  const data = kyApiFn<Facilities[]>(
    `api/e-payable/management/${customerIdsString}/organizations`,
    "get"
  );
  // TODO: validate data
  // return facilitySchema.parse(data);
  return data; // Returning the fetched data
}

// Custom hook to fetch and cache facilities by customerIds data using react-query
export function useGetAllFacilitiesByCustomer(
  customerIds: string[],
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: facilityQueryKeys.byCustomerIds(customerIds), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getFacilitiesByCustomers(customerIds), // Setting the function to be used for fetching data
    enabled: !!customerIds.length, // Setting the query to be enabled only if customerIds is not empty  (i.e. customerIds.length > 0)
    // TODO: add onSuccess and onError handlers
  });
}
