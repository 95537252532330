import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  proxyPayStatusListKeys,
  type ProxyPayPaymentStatusesSchema,
} from "@/modules/imremit";

// Defining a type based on the function `getProxyPayAllStatuses`
type QueryFnType = typeof getProxyPayAllStatuses;

// Function to search proxypay
export function getProxyPayAllStatuses() {
  return kyApiFn<ProxyPayPaymentStatusesSchema[]>(
    `api/e-payable/management/proxy-pay-statuses`,
    "get"
  );
  // TODO: validate data
  // return ProxyPayViewPageSchema.parse(data);
}

// Custom hook to fetch and cache proxypay payment status using react-query
export function useGetProxyPayStatuses(
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: proxyPayStatusListKeys.all, // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getProxyPayAllStatuses(), // Setting the function to be used for fetching data
  });
}
