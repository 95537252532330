import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate} from "@tanstack/react-router";
import {InfoIcon, SaveIcon, XIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import type * as z from "zod";

import {Button} from "@/components/ui/button";
import {Card, CardContent} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Label} from "@/components/ui/label";
import {RadioGroup, RadioGroupItem} from "@/components/ui/radio-group";
import {Spinner} from "@/components/ui/spinner";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {toast} from "@/components/ui/use-toast";

import {
  paymentMappingFormSchema,
  savePaymentFileFn,
  useGetISOPaymentFileMapping,
  useMappingDocumentTypeStore,
} from "@/modules/imremit";

export function PaymentFileAddMapping() {
  const navigate = useNavigate();

  const {storeDocumentType} = useMappingDocumentTypeStore();

  // Initialize useForm with zodResolver for validation
  const form = useForm<z.infer<typeof paymentMappingFormSchema>>({
    defaultValues: {
      documentTypeId: storeDocumentType?.documentTypeId.toString() || "",
      type: "FIXED",
      paymentNumber: null,
      supplierName: null,
      invoiceAmount: null,
      invoiceNumber: null,
      paymentMethod: null,
      supplierNumber: null,
      invoiceDate: null,
      accountNumber: null,
      paymentDate: null,
      city: null,
      locationCode: null,
      invoiceType: null,
      udf1: null,
      udf2: null,
      udf3: null,
      totalAmountPaid: null,
      currency: null,
      paymentZip: null,
      discountAmount: null,
      poNumber: null,
      addressLine1: null,
      addressLine2: null,
      state: null,
      zip: null,
      totalAmount: null,
      invoiceComments: null,
      paymentFileIdentifier: null,
      adjustmentAmount: null,
      invoiceFutureUse1: null,
    },
    resolver: zodResolver(paymentMappingFormSchema),
  });

  const isoPaymentMappingQuery = useGetISOPaymentFileMapping();

  // Initialize Tanstack Query Mutation for Refreshing PaymentFile
  const savePaymentFileMutation = useMutation({
    mutationFn: savePaymentFileFn,
    onSuccess: (response) => {
      form.reset();
      console.log("savePaymentFileMutationResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Payment file mapping added successfully",
      });
      void navigate({to: "/app/imremit/mapping", replace: true});
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add payment file mapping",
      });
    },
  });
  const isMutating = savePaymentFileMutation.isPending;
  const isISO = form.watch("type") === "ISO";

  function onSubmit(data: z.infer<typeof paymentMappingFormSchema>) {
    if (isISO) {
      if (isoPaymentMappingQuery.isSuccess) {
        const isoPaymentMapping = isoPaymentMappingQuery.data.content;

        console.log("isoPaymentMapping:", isoPaymentMapping);

        const isoFormData = {
          ...data,
          paymentNumber: isoPaymentMapping.paymentNumber,
          totalAmountPaid: isoPaymentMapping.totalAmountPaid,
          paymentDate: isoPaymentMapping.paymentDate,
          currency: isoPaymentMapping.currency,
          paymentMethod: isoPaymentMapping.paymentMethod,
          supplierNumber: isoPaymentMapping.supplierNumber,
          supplierName: isoPaymentMapping.supplierName,
          addressLine1: isoPaymentMapping.addressLine1,
          addressLine2: isoPaymentMapping.addressLine2,
          locationCode: isoPaymentMapping.locationCode,
          invoiceNumber: isoPaymentMapping.invoiceNumber,
          invoiceDate: isoPaymentMapping.invoiceDate,
          invoiceAmount: isoPaymentMapping.invoiceAmount,
          invoiceType: isoPaymentMapping.invoiceType,
          totalAmount: isoPaymentMapping.totalAmount,
          udf1: isoPaymentMapping.udf1,
          udf2: isoPaymentMapping.udf2,
          udf3: isoPaymentMapping.udf3,
          paymentFileIdentifier: isoPaymentMapping.paymentFileIdentifier,
          discountAmount: isoPaymentMapping.discountAmount,
          invoiceFutureUse1: isoPaymentMapping.invoiceFutureUse1,
        };

        isoFormData.masterMappingName = data.masterMappingName;
        isoFormData.documentTypeId = data.documentTypeId;
        isoFormData.type = data.type;

        // console.log("isoFormData:", isoFormData);
        savePaymentFileMutation.mutate(isoFormData);
      }
    } else {
      const {zip, ...rest} = data;

      const processedData = {
        ...rest,
        zip: zip?.trim() === "" ? null : zip,
      };
      savePaymentFileMutation.mutate(processedData);
    }
  }

  return (
    <>
      <Card>
        <CardContent>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <div className="mb-5 mt-5 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-6">
                <FormField
                  control={form.control}
                  name="masterMappingName"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label showMandatoryAsterisk>Master Mapping Name :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          autoCapitalize="none"
                          autoComplete="masterMappingName"
                          autoCorrect="off"
                          {...form.register("masterMappingName")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="type"
                  render={({field}) => (
                    <FormItem className="space-y-4">
                      <Label showMandatoryAsterisk>Format </Label>
                      <FormControl>
                        <RadioGroup
                          className="flex flex-col space-y-1"
                          value={field.value}
                          onValueChange={field.onChange}
                        >
                          <div className="flex items-center space-x-2">
                            <FormItem className="flex items-center space-x-1 space-y-0">
                              <FormControl>
                                <RadioGroupItem value="FIXED" />
                              </FormControl>
                              <Label className="font-normal">
                                Fixed Length
                              </Label>
                            </FormItem>
                            <FormItem className="flex items-center space-x-1 space-y-0">
                              <FormControl>
                                <RadioGroupItem value="CSV" />
                              </FormControl>
                              <Label className="font-normal">CSV</Label>
                            </FormItem>
                            <FormItem className="flex items-center space-x-1 space-y-0">
                              <FormControl>
                                <RadioGroupItem value="ISO" />
                              </FormControl>
                              <Label className="font-normal">ISO</Label>
                            </FormItem>
                            <FormItem className="flex items-center space-x-1 space-y-0">
                              <FormControl>
                                <RadioGroupItem value="XLSX" />
                              </FormControl>
                              <Label className="font-normal">.xlsx</Label>
                            </FormItem>
                          </div>
                        </RadioGroup>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="paymentNumber"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label showMandatoryAsterisk>Payment Number :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="paymentNumber"
                          autoCorrect="off"
                          {...form.register("paymentNumber")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="supplierName"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label showMandatoryAsterisk>Supplier Name :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="supplierName"
                          autoCorrect="off"
                          {...form.register("supplierName")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="invoiceAmount"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label showMandatoryAsterisk>Invoice Amount :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="invoiceAmount"
                          autoCorrect="off"
                          {...form.register("invoiceAmount")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="invoiceNumber"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label showMandatoryAsterisk>Invoice Number :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="invoiceNumber"
                          autoCorrect="off"
                          {...form.register("invoiceNumber")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="paymentMethod"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Payment Method :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="paymentMethod"
                          autoCorrect="off"
                          {...form.register("paymentMethod")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="supplierNumber"
                  render={() => (
                    <FormItem className="flex flex-col">
                      <Label showMandatoryAsterisk>Supplier Number :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="supplierNumber"
                          autoCorrect="off"
                          {...form.register("supplierNumber")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="invoiceDate"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label showMandatoryAsterisk>Invoice Date :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="invoiceDate"
                          autoCorrect="off"
                          {...form.register("invoiceDate")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="accountNumber"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Account Number :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="accountNumber"
                          autoCorrect="off"
                          {...form.register("accountNumber")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="paymentDate"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Payment Date :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="paymentDate"
                          autoCorrect="off"
                          {...form.register("paymentDate")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="city"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>City :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="city"
                          autoCorrect="off"
                          {...form.register("city")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="locationCode"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Location Code :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="locationCode"
                          autoCorrect="off"
                          {...form.register("locationCode")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="invoiceType"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Invoice Type :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="invoiceType"
                          autoCorrect="off"
                          {...form.register("invoiceType")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="udf1"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Payment Customer Field 1 :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="udf1"
                          autoCorrect="off"
                          {...form.register("udf1")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="udf2"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Payment Customer Field 2 :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="udf2"
                          autoCorrect="off"
                          {...form.register("udf2")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="udf3"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Payment Customer Field 3 :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="udf3"
                          autoCorrect="off"
                          {...form.register("udf3")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="totalAmountPaid"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Total Amount Paid :</Label>
                      <FormControl>
                        <Input
                          type="number"
                          step="any"
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="totalAmountPaid"
                          autoCorrect="off"
                          {...form.register("totalAmountPaid")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="currency"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Currency :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="currency"
                          autoCorrect="off"
                          {...form.register("currency")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="paymentZip"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Payment Zip Code/ Postal Code :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="paymentZip"
                          autoCorrect="off"
                          {...form.register("paymentZip")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="discountAmount"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Discount Amount :</Label>
                      <FormControl>
                        <Input
                          type="number"
                          step="any"
                          placeholder=""
                          disabled={isMutating || isISO}
                          aria-disabled={isMutating || isISO}
                          autoCapitalize="none"
                          autoComplete="discountAmount"
                          autoCorrect="off"
                          {...form.register("discountAmount")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="poNumber"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>PO Number :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="poNumber"
                          autoCorrect="off"
                          {...form.register("poNumber")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="addressLine1"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Address Line One :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="addressLine1"
                          autoCorrect="off"
                          {...form.register("addressLine1")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="addressLine2"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Address Line Two :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="addressLine2"
                          autoCorrect="off"
                          {...form.register("addressLine2")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="state"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>State :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="state"
                          autoCorrect="off"
                          {...form.register("state")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="zip"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>ZIP :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="zip"
                          autoCorrect="off"
                          {...form.register("zip")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="totalAmount"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Total Amount :</Label>
                      <FormControl>
                        <Input
                          type="number"
                          step="any"
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="totalAmount"
                          autoCorrect="off"
                          {...form.register("totalAmount")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="invoiceComments"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Invoice Comments :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="invoiceComments"
                          autoCorrect="off"
                          {...form.register("invoiceComments")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="paymentFileIdentifier"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label className="flex space-x-2">
                        <div>Payment File Identifier :</div>
                        <TooltipProvider delayDuration={200}>
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <div className="cursor-pointer">
                                <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                              </div>
                            </TooltipTrigger>
                            <TooltipContent>
                              <div className="flex justify-between space-x-4">
                                Map the column that will be used to check
                                <br />
                                keywords within the consolidated payment file to
                                <br />
                                identify the payment group or facility/location
                                <br />
                                (assuming there is more than 1).
                              </div>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="paymentFileIdentifier"
                          autoCorrect="off"
                          {...form.register("paymentFileIdentifier")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="adjustmentAmount"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Adjustment Amount :</Label>
                      <FormControl>
                        <Input
                          type="number"
                          step="any"
                          placeholder=""
                          disabled={isISO || isMutating}
                          aria-disabled={isISO || isMutating}
                          autoCapitalize="none"
                          autoComplete="adjustmentAmount"
                          autoCorrect="off"
                          {...form.register("adjustmentAmount")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="invoiceFutureUse1"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <Label>Invoice Future Use 1 :</Label>
                      <FormControl>
                        <Input
                          placeholder=""
                          disabled={isMutating || isISO}
                          aria-disabled={isMutating || isISO}
                          autoCapitalize="none"
                          autoComplete="invoiceFutureUse1"
                          autoCorrect="off"
                          {...form.register("invoiceFutureUse1")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="documentTypeId"
                  render={({field}) => (
                    <FormItem>
                      <FormControl>
                        <Input placeholder="" {...field} className="hidden" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              <div className="space-x-2 p-2 text-right">
                <Link className="mb-4" to="/app/imremit/mapping">
                  <Button
                    type="button"
                    variant="input"
                    className="col-span-1 gap-2"
                  >
                    <span className="sr-only">Cancel</span>
                    <XIcon className="size-4" />
                    Cancel
                  </Button>
                </Link>
                <Button
                  type="submit"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="affirmative"
                  className="col-span-1 gap-2"
                >
                  <span className="sr-only">Save</span>
                  <SaveIcon className="size-4" />
                  {isMutating ? <Spinner size="xs" /> : ""} Save
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </>
  );
}
