import {kyApiFn} from "@/lib/ky";
import {type RunnerConfigFormDTO} from "@/modules/admin";

export const addCustomerRunnerConfigFn = (
  addCustomerRunnerConfigDTO: RunnerConfigFormDTO,
  externalId: string
) =>
  kyApiFn(`api/e-payable/management/${externalId}/config`, "post", {
    json: addCustomerRunnerConfigDTO,
  });
