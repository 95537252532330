import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  paymentManagementGenInfoKeys,
  type paymentManagementGenInfo,
} from "@/modules/imremit";

type QueryFnType = typeof getPaymentManagementGenInfo;

// Function to search failedAlerts
export function getPaymentManagementGenInfo(id?: string) {
  return kyApiFn<paymentManagementGenInfo>(
    `api/e-payable/payment-details/${id}/general-information`,
    "get"
  );
  // TODO: validate data
}

// Custom hook to fetch and cache failedAlerts data using react-query
export function usePaymentManagementGenInfo(
  id?: string,
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: paymentManagementGenInfoKeys.byId(id), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getPaymentManagementGenInfo(id), // Setting the function to be used for fetching data
    enabled: !!id, // Setting the query to be enabled only if the search query string is not empty
  });
}
