import {useEffect} from "react";

import {HelpCircleIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {
  Heading2,
  Heading3,
  Paragraph,
  UnorderedList,
} from "@/components/ui/typography";

import {AdminRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";
import {MasterRoleSettingsForm} from "@/modules/admin";

/**
 * UserRoleConfiguration Component
 *
 * This component provides the interface for administrators to configure
 * different user roles within the application.
 * It presents a series of forms, each dedicated to specific user roles.
 */
export function MasterRoleConfiguration() {
  // Use the custom hook for manipulating route data
  const {setAdminRoute} = useRoutesStore();

  // useEffect to set the admin route upon component mount
  useEffect(() => {
    // Define the active admin route for User Role Configuration using the route schema
    setAdminRoute(AdminRoutesSchema.Values["/app/admin/master-role-settings"]);
  }, [setAdminRoute]);

  // Render the User Role Configuration Interface
  return (
    <section role="presentation">
      <section className="flex flex-row justify-between">
        <div className="mb-2 flex flex-col">
          <Heading2>Master Role Settings</Heading2>
          <Paragraph>
            Change the user role access and permissions within your organization
            here.
          </Paragraph>
        </div>
        <div>
          <HoverCard openDelay={150} closeDelay={300}>
            <HoverCardTrigger asChild>
              <Button variant="ghost">
                <HelpCircleIcon className="text-theme" />
              </Button>
            </HoverCardTrigger>
            <HoverCardContent className="max-w-prose translate-x-[-1.5rem]">
              <Heading3 className="mb-4 text-foreground">
                Role Settings Help
              </Heading3>
              <Paragraph>
                In this section you will be able to manage all the master roles
                and its respective privileges. You have 3 role groups here:
              </Paragraph>
              <UnorderedList>
                <li>iM</li>
                <li>Customer</li>
                <li>Supplier</li>
              </UnorderedList>
              <Paragraph className="mb-4">
                Under IM group you will be able to manage its fixed roles like
                Management, Supplier User and Support CSR. You will be able to
                assign different pages/modules to each role under this group and
                also be able to discharge the pages/modules from each role.
                Under Customer group you will be able to manage its fixed roles
                like Customer Admin, Customer User, Facility User and View Only
                User.
              </Paragraph>
              <Paragraph>
                You will be able to assign different pages/modules to each role
                under this group and also be able to discharge the pages/modules
                from each role. Under Supplier group you will be able to manage
                its fixed roles like Supplier Admin and User. You will be able
                to assign different pages/modules to each role under this group
                and also be able to discharge the pages/modules from each role.
              </Paragraph>
            </HoverCardContent>
          </HoverCard>
        </div>
      </section>
      <MasterRoleSettingsForm />
    </section>
  );
}
