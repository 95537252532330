import * as React from "react";

import * as LabelPrimitive from "@radix-ui/react-label";
import {cva, type VariantProps} from "class-variance-authority";

import {cn} from "@/lib/utils";

// Define label variants using class-variance-authority (cva)
const labelVariants = cva(
  "text-sm font-semibold leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
);

/**
 * Props for the Label component.
 *
 * @interface LabelProps
 * @extends {React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>}
 * @extends {VariantProps<typeof labelVariants>}
 */
interface LabelProps
  extends React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>,
    VariantProps<typeof labelVariants> {
  /**
   * Indicates whether to show a mandatory asterisk (*) next to the label.
   *
   * @type {boolean}
   * @default false
   */
  showMandatoryAsterisk?: boolean;
}

/**
 * A customizable label component.
 *
 * @component
 * @param {LabelProps} props - The component's props.
 * @param {React.Ref<typeof LabelPrimitive.Root>} ref - A React ref to the component's root element.
 * @returns {JSX.Element} The rendered Label component.
 */
const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  LabelProps
>(({className, showMandatoryAsterisk = false, ...props}, ref) => (
  <LabelPrimitive.Root
    ref={ref}
    className={cn(labelVariants(), className)}
    {...props}
  >
    {props.children}
    {showMandatoryAsterisk && (
      <span className="pl-1 text-destructive-foreground">*</span>
    )}
  </LabelPrimitive.Root>
));

// Set display name for the component
Label.displayName = LabelPrimitive.Root.displayName;

// Export the Label component
export {Label};
