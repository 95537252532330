import {useNavigate} from "@tanstack/react-router";
import {FileTextIcon, GripVerticalIcon, MapIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

/**
 * Props for StatementSearchRowActions component.
 * @typedef {Object} StatementSearchRowActionsProps
 * @paymentId String - The type of data for the row.
 */
interface StatementSearchRowActionsProps {
  statement_file_status: number;
  statement_file_id: number;
}

/**
 * Represents the actions for a payment file row.
 * This component provides a dropdown menu for each row with various actions like edit, copy, favorite, and delete.
 * @returns {JSX.Element} The StatementSearchRowActions component.
 */
export function StatementSearchRowActions({
  statement_file_status,
  statement_file_id,
}: StatementSearchRowActionsProps) {
  // Get the navigate function from the router
  const navigate = useNavigate();

  return (
    <>
      <DropdownMenu>
        {/* Trigger button for the dropdown menu */}
        <DropdownMenuTrigger asChild>
          <Button className="flex bg-theme hover:bg-neutral data-[state=open]:bg-secondary">
            <GripVerticalIcon className="size-6 bg-inherit" />
          </Button>
        </DropdownMenuTrigger>

        {/* Content of the dropdown menu */}
        <DropdownMenuContent align="end" className="min-w-fit">
          <DropdownMenuItem
            className="flex justify-between gap-4"
            disabled={
              statement_file_status !== 3 && statement_file_status !== 6
            }
            onSelect={() => {
              void navigate({
                to: "/app/statement-recon/file-history/$fileId/summary",
                params: {fileId: statement_file_id.toString()},
                replace: true,
              });
            }}
          >
            View Mapping Summary
            <span className="sr-only">View Mapping Details</span>
            <MapIcon className="size-4" />
          </DropdownMenuItem>
          <DropdownMenuItem
            className="flex justify-between gap-4"
            disabled={
              statement_file_status !== 3 && statement_file_status !== 6
            }
            onSelect={() => {
              void navigate({
                to: "/app/statement-recon/file-history/$fileId/result",
                params: {fileId: statement_file_id.toString()},
                replace: true,
              });
            }}
          >
            View Reconciliation Results
            <span className="sr-only">View Statement Details</span>
            <FileTextIcon className="size-4" />
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
