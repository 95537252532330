import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  InvoiceMappingKeys,
  type InvoicesMappingFormType,
} from "@/modules/invoices";

//Defining a type based on the function `getAllInvoiceMapping`
type QueryFnType = typeof getAllInvoiceMapping;

//Funciton to get the Payment reports
export function getAllInvoiceMapping() {
  // Fetch data from API using ky
  return kyApiFn<InvoicesMappingFormType[]>(`invoice/v1/file/mapping`, "get");
  // return kyApiFn<InvoicesMappingFormType[]>(`customer/v1/customers`, "get");
}

// Custom hook to fetch and cache data using react-query
export function useInvoiceMappingDetails(config?: QueryConfig<QueryFnType>) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: InvoiceMappingKeys.allInvoiceMapping(),
    queryFn: () => getAllInvoiceMapping(),
  });
}
