/* eslint-disable */

import {useRef, useState} from "react";

import {AxisBottom, AxisLeft} from "@visx/axis";
import {GlyphCircle} from "@visx/glyph";
import {Group} from "@visx/group";
import {scaleBand, scaleLinear} from "@visx/scale";
import {Bar, LinePath} from "@visx/shape";

import {Button} from "@/components/ui/button";
import {Checkbox} from "@/components/ui/checkbox";

import type {MISPaymentsReceivedType} from "@/modules/admin";

interface AggregatedMonthlyData {
  month: string; // e.g., "Jan"
  year: number;
  total_amount: number;
}

interface MISPaymentsReceivedGraphProps {
  data: MISPaymentsReceivedType[];
  width: number;
  height: number;
}

// Accessors
const getX = (d: AggregatedMonthlyData) => `${d.month} ${d.year}`; // Get month and year as string
const getY = (d: AggregatedMonthlyData) => d.total_amount; // Get total amount

export function MISPaymentsReceivedGraph({
  data,
  width,
  height,
}: MISPaymentsReceivedGraphProps) {
  const [showTrendline, setShowTrendline] = useState<boolean>(true);

  // Aggregate the data per month across all buyers
  const aggregatedData = aggregateMonthlyData(data);
  const maxYValue = Math.max(...aggregatedData.map(getY));
  const yDomainMax = maxYValue > 0 ? maxYValue : 1;

  // Calculate the left margin based on the number of digits in the max value
  const maxDigits = Math.ceil(Math.log10(yDomainMax + 1));
  const digitWidth = 8; // Approximate width per digit in pixels
  const leftMargin = Math.max(60, maxDigits * digitWidth + 20); // Minimum 60px, +20px for padding

  // Define margins with dynamic left margin
  const margin = {top: 20, right: 20, bottom: 50, left: leftMargin};
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // Create a ref to store the container element
  const containerRef = useRef<HTMLDivElement | null>(null);

  // Set up scales for the X and Y axes
  const xScale = scaleBand<string>({
    domain: aggregatedData.map(getX),
    range: [0, xMax],
    padding: 0.2,
  });

  // Create Y-axis scale
  const yScale = scaleLinear<number>({
    domain: [0, yDomainMax],
    nice: true,
    range: [yMax, 0],
  });

  // Calculate the trendline points based on the data
  const trendlineData = getTrendlinePoints(aggregatedData, xScale, yScale);

  // Add this function to handle the download
  const handleDownload = () => {
    if (!containerRef.current) return;

    const svg = containerRef.current.querySelector("svg");
    if (!svg) return;

    // Create a serialized SVG string
    const svgData = new XMLSerializer().serializeToString(svg);
    const svgBlob = new Blob([svgData], {type: "image/svg+xml;charset=utf-8"});

    // Create canvas
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    // Create image from SVG
    const img = new Image();
    img.onload = () => {
      if (!ctx) return;
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, width, height);
      ctx.drawImage(img, 0, 0);

      // Convert to PNG and download
      const pngUrl = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "payments-received-graph.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
    img.src = URL.createObjectURL(svgBlob);
  };

  return (
    <section>
      <div className="flex items-center justify-between gap-x-4">
        <Button onClick={handleDownload} variant="outline" size="sm">
          Download Graph
        </Button>

        {/* Add legend */}
        <div className="flex items-center gap-x-4">
          <div className="flex items-center gap-x-2">
            <div className="h-3 w-3 bg-[#C900FF]" />
            <span className="text-sm">Payments</span>
          </div>
          <div className="flex items-center gap-x-2">
            <div className="h-3 w-3 bg-[#FFB500]" />
            <span className="text-sm">Trend</span>
          </div>
        </div>

        <div className="flex items-center gap-x-2">
          <span>Show Trendline</span>
          <Checkbox
            checked={showTrendline}
            onCheckedChange={() => {
              setShowTrendline(!showTrendline);
            }}
          />
        </div>
      </div>

      {/* SVG container */}
      <div ref={containerRef} style={{position: "relative"}}>
        <svg width={width} height={height}>
          <Group left={margin.left} top={margin.top}>
            {/* Render the bars for the data */}
            {aggregatedData.map((d) => {
              const xValue = xScale(getX(d));
              const barWidth = xScale.bandwidth();
              const yValue = yScale(getY(d));
              const barHeight = yMax - yValue;

              return (
                <Bar
                  key={`bar-${getX(d)}`}
                  x={xValue}
                  y={yValue}
                  width={barWidth}
                  height={barHeight}
                  fill="#C900FF"
                />
              );
            })}

            {/* Render the trendline */}
            {showTrendline && (
              <>
                <LinePath
                  data={trendlineData}
                  x={(d) => d.x}
                  y={(d) => d.y}
                  stroke="#FFB500"
                  strokeWidth={2}
                />
                {/* Render points on the trendline */}
                {trendlineData.map((point, i) => (
                  <GlyphCircle
                    key={`trend-point-${i}`}
                    left={point.x}
                    top={point.y}
                    size={64}
                    fill="#FFB500"
                    stroke="#fff"
                    strokeWidth={1}
                  />
                ))}
              </>
            )}

            {/* X-axis */}
            <AxisBottom
              top={yMax}
              scale={xScale}
              tickFormat={(d) => d}
              tickLabelProps={() => ({
                fill: "#000",
                fontSize: 11,
                textAnchor: "middle",
              })}
            />
            {/* Y-axis */}
            <AxisLeft
              scale={yScale}
              tickLabelProps={() => ({
                fill: "#000",
                fontSize: 11,
                textAnchor: "end",
                dy: "0.33em",
              })}
            />
          </Group>
        </svg>
      </div>
    </section>
  );
}

// Helper function to calculate the trendline points
function getTrendlinePoints(
  data: AggregatedMonthlyData[],
  xScale: any,
  yScale: any
) {
  const indices = data.map((_, i) => i); // Indexes for data points

  // Calculate the mean of the X values (indexes) and Y values (amounts)
  const xMean = indices.reduce((a, b) => a + b, 0) / indices.length;
  const yMean = data.reduce((sum, d) => sum + getY(d), 0) / data.length;

  // Calculate slope and intercept for the trendline using linear regression formula
  const numerator = indices.reduce(
    (sum, x, i) => sum + (x - xMean) * (getY(data[i]) - yMean),
    0
  );
  const denominator = indices.reduce(
    (sum, x) => sum + Math.pow(x - xMean, 2),
    0
  );

  const slope = denominator !== 0 ? numerator / denominator : 0;
  const intercept = yMean - slope * xMean;

  // Generate trendline points based on the calculated slope and intercept
  const trendlinePoints = indices.map((x) => {
    const xValue = xScale(getX(data[x]))! + xScale.bandwidth() / 2;
    return {
      x: xValue,
      y: yScale(slope * x + intercept),
    };
  });

  return trendlinePoints;
}

// Helper function to aggregate data per month across all buyers
function aggregateMonthlyData(
  data: MISPaymentsReceivedType[]
): AggregatedMonthlyData[] {
  const aggregation: {[key: string]: number} = {};

  data.forEach((buyer) => {
    buyer.monthly_data.forEach((monthlyData) => {
      const key = `${monthlyData.month} ${monthlyData.year}`;
      if (aggregation[key]) {
        aggregation[key] += monthlyData.total_amount;
      } else {
        aggregation[key] = monthlyData.total_amount;
      }
    });
  });

  // Convert the aggregation object into an array
  const aggregatedData = Object.keys(aggregation).map((key) => {
    const [month, yearStr] = key.split(" ");
    return {
      month: month,
      year: parseInt(yearStr),
      total_amount: aggregation[key],
    };
  });

  // Sort the aggregatedData by year and month
  const monthOrder = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  aggregatedData.sort((a, b) => {
    const yearDiff = a.year - b.year;
    if (yearDiff !== 0) {
      return yearDiff;
    }
    return monthOrder.indexOf(a.month) - monthOrder.indexOf(b.month);
  });

  return aggregatedData;
}
