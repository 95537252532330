import {useQuery} from "@tanstack/react-query";

import {keycloakKyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import type {KeycloakUserSessionType} from "@/modules/admin";
import {keycloakRealmSessionsDetailsQueryKeys} from "@/modules/admin";

// Defining a type based on the function `getAllRealmSessionsDetails`
type QueryFnType = typeof getAllRealmSessionsDetails;

// Function to get all realm sessions
export function getAllRealmSessionsDetails(clientId?: string) {
  const realm =
    (import.meta.env.VITE_KEYCLOAK_REALM as string | undefined) ?? "dev";
  return keycloakKyApiFn<KeycloakUserSessionType[]>(
    `admin/realms/${realm}/clients/${clientId ? clientId : ""}/user-sessions`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useAllRealmSessionsDetails(
  clientId?: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: keycloakRealmSessionsDetailsQueryKeys.byClientId(clientId),
    queryFn: () => getAllRealmSessionsDetails(clientId),
    enabled: !!clientId,
  });
}
