import {z} from "zod";

export const failedAlertsSchema = z.array(
  z.object({
    paymentDetailPaymentNumber: z.string(),
    payByWebTransactionHistoryId: z.string(),
    paymentDetailId: z.string(),
    updatedTimestamp: z.string(),
    paymentDetailInitiatedDate: z.string(),
  })
);

export type FailedAlertsType = z.infer<typeof failedAlertsSchema>;
