import {useEffect} from "react";

import {FilePlusIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {Heading1} from "@/components/ui/typography";

import {AdminRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";
import {RegistrationLinkForm} from "@/modules/admin";

export function SupplierOnboardingPage() {
  const {setAdminRoute} = useRoutesStore();

  useEffect(() => {
    setAdminRoute(AdminRoutesSchema.Values["/app/admin/supplier-onboarding"]);
  }, [setAdminRoute]);

  return (
    <Collapsible className="space-y-6" asChild>
      <section
        role="presentation"
        aria-roledescription="This section contains the Supplier Onboarding page. This page allows administrators to generate self registration links for suppliers."
      >
        <div className="flex flex-1 flex-col md:flex-row md:justify-between">
          <Heading1>Supplier Onboarding</Heading1>
          <CollapsibleTrigger asChild>
            <Button className="mt-4 md:mt-0">
              Add Supplier <FilePlusIcon className="ml-2" />
            </Button>
          </CollapsibleTrigger>
        </div>

        <CollapsibleContent asChild>
          <RegistrationLinkForm />
        </CollapsibleContent>
      </section>
    </Collapsible>
  );
}
