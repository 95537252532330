import {useEffect, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {HelpCircleIcon, SaveIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import {type z} from "zod";

import {Badge} from "@/components/ui/badge";
import {Button} from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {Form} from "@/components/ui/form";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {Input} from "@/components/ui/input";
import {Spinner} from "@/components/ui/spinner";
import {Heading3, Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {queryClient} from "@/lib/query-client";
import {useKeyCloakInstanceStore} from "@/modules/auth";
import {customerQueryKeys, type CustomerType} from "@/modules/customers";
import {useGetAllSuppliers} from "@/modules/imremit";
import {
  createSavedSearchFn,
  SavedSearchDTOTypeSchema,
  type SavedSearchCriteriaType,
  type SavedSearchDTOType,
} from "@/modules/invoices";

import {FallbackMessages} from "@/utils/constants";

/**
 * @typedef CreateSavedSearchFormProps
 * @property {boolean} isCreateSavedSearchFormOpen - Flag indicating if the dialog should be open
 * @property {React.Dispatch<React.SetStateAction<boolean>>} setIsCreateSavedSearchFormOpen - Function to set isCreateSavedSearchFormOpen
 * @property {boolean} isMutating - Flag to indicate if a mutation operation is in progress
 * @property {(data: SavedSearchDTOType) => void} createSavedSearchOnSubmit - Function to handle the form submission
 * @property {SavedSearchCriteriaType} [rest] - Search criteria data
 */
type CreateSavedSearchFormProps = {
  isCreateSavedSearchFormOpen: boolean;
  setIsCreateSavedSearchFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMutating: boolean;
  createSavedSearchOnSubmit: (data: SavedSearchDTOType) => void;
} & SavedSearchCriteriaType;

/**
 * CreateSavedSearchForm Component
 * @param {CreateSavedSearchFormProps} props - The properties of the component
 */
function CreateSavedSearchForm({
  isCreateSavedSearchFormOpen,
  setIsCreateSavedSearchFormOpen,
  isMutating,
  createSavedSearchOnSubmit,
  ...rest
}: CreateSavedSearchFormProps) {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  // Fetch existing customers and suppliers data
  const queriedCustomers =
    queryClient.getQueryData<{
      content: CustomerType[];
    }>(customerQueryKeys.allCustomers)?.content || [];
  const queriedSuppliers = useGetAllSuppliers().data?.content || [];

  // Initialize react-hook-form methods
  const savedInvoiceSearchFormMethods = useForm<
    z.infer<typeof SavedSearchDTOTypeSchema>
  >({
    resolver: zodResolver(SavedSearchDTOTypeSchema),
  });

  const {
    formState: {errors},
    setValue,
  } = savedInvoiceSearchFormMethods;

  // Set the criteria values when the component mounts or the criteria changes
  useEffect(() => {
    setValue("listId", null);
    setValue("userId", storeKeyCloakInstance?.tokenParsed?.sub ?? null);
    setValue("criteria", rest);
  }, [rest, setValue, storeKeyCloakInstance?.tokenParsed?.sub]);

  return (
    <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
      <Dialog
        open={isCreateSavedSearchFormOpen}
        onOpenChange={setIsCreateSavedSearchFormOpen}
      >
        <DialogTrigger asChild>
          <Button
            aria-label="Open saved search dialog"
            aria-disabled={isMutating}
            disabled={isMutating}
            className="gap-2 border-theme bg-theme text-root hover:border-foreground/80 hover:bg-theme/70 hover:text-root active:bg-theme/90 active:text-root"
            variant="outline"
          >
            <SaveIcon className="size-4" />
            <span className="sr-only">Save Search</span>
            Save Search
          </Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <div className="flex items-center gap-2">
              <DialogTitle>Save Search Criteria</DialogTitle>
              <HoverCard openDelay={150} closeDelay={300}>
                <HoverCardTrigger asChild>
                  <Button
                    className="p-0 hover:bg-transparent"
                    size="xs"
                    variant="ghost"
                  >
                    <span className="sr-only">Hover for help</span>
                    <HelpCircleIcon className="text-theme" />
                  </Button>
                </HoverCardTrigger>
                <HoverCardContent className="max-w-prose translate-x-[-1.5rem]">
                  <Heading3 className="text-foreground">
                    Saving your search criteria
                  </Heading3>
                  <Paragraph>
                    Please review your search criteria before giving your search
                    a name and saving. You can always edit your saved search
                    later.
                  </Paragraph>
                </HoverCardContent>
              </HoverCard>
            </div>
          </DialogHeader>
          <Form {...savedInvoiceSearchFormMethods}>
            <form>
              <div className="flex flex-col gap-2">
                <div className="grid grid-cols-2 gap-2 pb-2">
                  <Paragraph>Customers: </Paragraph>
                  <div className="flex flex-wrap gap-2">
                    {rest.externalId && !!rest.externalId.length ? (
                      rest.externalId.map((id) => (
                        <Badge key={id} className="max-w-fit">
                          {
                            queriedCustomers.find(
                              (customer) => customer.externalId === id
                            )?.buyerName
                          }
                        </Badge>
                      ))
                    ) : (
                      <Badge variant="destructive">
                        {FallbackMessages.NO_DATA}
                      </Badge>
                    )}
                  </div>
                  <Paragraph>
                    Suppliers:{" "}
                    <span className="font-bold">
                      {rest.supplierId && !!rest.supplierId.length ? (
                        rest.supplierId.map((id) => (
                          <Badge key={id} className="max-w-fit">
                            {
                              queriedSuppliers.find(
                                (supplier) => supplier.id === id
                              )?.name
                            }
                          </Badge>
                        ))
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </span>
                  </Paragraph>
                  <Paragraph>
                    Status Id:{" "}
                    <span className="font-bold">
                      {rest.status ? (
                        rest.status
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </span>
                  </Paragraph>
                  <Paragraph>
                    Facilities:{" "}
                    <span className="font-bold">
                      {rest.facilityId && !!rest.facilityId.length ? (
                        rest.facilityId
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </span>
                  </Paragraph>
                  <Paragraph>
                    Start Date:{" "}
                    <span className="font-bold">
                      {rest.invoiceDateStart ? (
                        rest.invoiceDateStart
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </span>
                  </Paragraph>
                  <Paragraph>
                    End Date:{" "}
                    <span className="font-bold">
                      {rest.invoiceDateEnd ? (
                        rest.invoiceDateEnd
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </span>
                  </Paragraph>
                  <Paragraph>
                    Payment Date:{" "}
                    <span className="font-bold">
                      {rest.paymentDate ? (
                        rest.paymentDate
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </span>
                  </Paragraph>
                  <Paragraph>
                    Payment Number:{" "}
                    <span className="font-bold">
                      {rest.paymentNumber ? (
                        rest.paymentNumber
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </span>
                  </Paragraph>
                  <Paragraph>
                    Po Number:{" "}
                    <span className="font-bold">
                      {rest.poNumber ? (
                        rest.poNumber
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </span>
                  </Paragraph>
                  <Paragraph>
                    Invoice Number:{" "}
                    <span className="font-bold">
                      {rest.invoiceNumber ? (
                        rest.invoiceNumber
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </span>
                  </Paragraph>
                  <Paragraph>
                    ERP Unique Id:{" "}
                    <span className="font-bold">
                      {rest.erpUniqueId ? (
                        rest.erpUniqueId
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </span>
                  </Paragraph>
                  <Paragraph>
                    Invoice Amount:{" "}
                    <span className="font-bold">
                      {rest.invoiceAmount ? (
                        rest.invoiceAmount
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </span>
                  </Paragraph>
                  <Paragraph>
                    Invoice Date:{" "}
                    <span className="font-bold">
                      {rest.invoiceDate ? (
                        rest.invoiceDate
                      ) : (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )}
                    </span>
                  </Paragraph>
                </div>
                <Input
                  type="text"
                  placeholder="Enter name for your search criteria"
                  {...savedInvoiceSearchFormMethods.register("name")}
                />
                {errors.name && (
                  <span className="mt-1 text-sm text-red-500">
                    {errors.name.message}
                  </span>
                )}
                <Button
                  className="flex items-center gap-2 bg-success-foreground capitalize hover:bg-success-foreground/80 active:bg-success-foreground/80"
                  aria-label="Save Search"
                  aria-disabled={isMutating}
                  disabled={isMutating}
                  size="xs"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    return savedInvoiceSearchFormMethods.handleSubmit(
                      createSavedSearchOnSubmit
                    )();
                  }}
                >
                  <span className="sr-only">Save search criteria</span>
                  {isMutating ? (
                    <Spinner className="p-1" />
                  ) : (
                    <SaveIcon className="size-4" />
                  )}
                  Save search criteria
                </Button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

/**
 * @typedef CreateSavedSearchMutationLayerProps
 * @property {SavedSearchCriteriaType} criteriaFields - The search criteria
 */
interface CreateSavedSearchMutationLayerProps {
  criteriaFields: SavedSearchCriteriaType;
}

/**
 * CreateSavedSearchMutationLayer Component
 * @param {CreateSavedSearchMutationLayerProps} props - The properties of the component
 */
export function CreateSavedSearchMutationLayer({
  criteriaFields,
}: CreateSavedSearchMutationLayerProps) {
  // Initialize State for Create Saved Search Form
  const [isCreateSavedSearchFormOpen, setIsCreateSavedSearchFormOpen] =
    useState(false);

  // Initialize Tanstack Query Mutation for Create Saved Search
  const createSavedSearchMutation = useMutation({
    mutationFn: createSavedSearchFn,
    onSuccess: (createaSavedsearchResponse) => {
      console.log("createaSavedsearchResponse", createaSavedsearchResponse);

      // Close the create saved search form
      setIsCreateSavedSearchFormOpen(false);

      toast({
        variant: "success",
        title: "Success!",
        description: "Search criteria saved successfully",
      });
    },
    onError: (error) => {
      console.error("errorMessage", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to save search criteria",
      });
    },
  });

  return (
    <CreateSavedSearchForm
      isCreateSavedSearchFormOpen={isCreateSavedSearchFormOpen}
      setIsCreateSavedSearchFormOpen={setIsCreateSavedSearchFormOpen}
      isMutating={createSavedSearchMutation.isPending}
      createSavedSearchOnSubmit={createSavedSearchMutation.mutate}
      {...criteriaFields}
    />
  );
}
