import {Outlet, useNavigate, useRouter} from "@tanstack/react-router";

import {Separator} from "@/components/ui/separator";
import {Heading1, Paragraph} from "@/components/ui/typography";
import {ReturnToTop} from "@/components/craft/return-to-top";

import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";
import {StatementReconSubrouteNavigation} from "@/modules/statement-recon";

export function StatementReconOutlet() {
  // Get pathname of user's current route
  const {pathname} = useRouter().latestLocation;

  // Get the navigate function from the router
  const navigate = useNavigate();

  // If the current route pathname is "/app/statement-recon",
  // navigate to "/app/statement-recon/list" without replacing the current entry in the history stack
  if (
    pathname === "/app/statement-recon" ||
    pathname === "/app/statement-recon/"
  ) {
    void navigate({
      to: "/app/statement-recon/upload",
    });
  }

  return (
    <Layout>
      <AccessPolicyWrapper
        policyActions={[KeycloakRoleEnum.READ_STATEMENT_RECON]}
      >
        <Outlet />
      </AccessPolicyWrapper>
    </Layout>
  );
}

function Layout({children}: {children: React.ReactNode}) {
  return (
    <article
      className="w-full flex-1 bg-background"
      role="region"
      aria-roledescription="This is the Statement Recon level one subroute."
    >
      <section
        className="mb-2 flex flex-col justify-between gap-4 xl:flex-row xl:items-center"
        role="presentation"
      >
        <div className="mb-2 flex flex-col">
          <Heading1>Statement Recon</Heading1>{" "}
          <Paragraph>Perform statement reconcilliation.</Paragraph>
        </div>
        <StatementReconSubrouteNavigation />
      </section>
      <Separator className="mb-2" />
      {children}
      <ReturnToTop />
    </article>
  );
}
