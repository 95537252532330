import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {CustomerRoleManagementRowActions} from "@/modules/admin";
import {type CustomerType} from "@/modules/customers";

import {FallbackMessages} from "@/utils/constants";

export const customerModuleManagementTableColumns: ColumnDef<CustomerType>[] = [
  {
    accessorKey: "id",
    header: ({column}) => <DataTableColumnHeader column={column} title="ID" />,
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("id") ? (
          row.getValue("id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "buyerName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Customer Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("buyerName") ? (
          row.getValue("buyerName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  // {
  //   accessorKey: "status",
  //   header: ({column}) => (
  //     <div className="flex justify-center">
  //       <DataTableColumnHeader column={column} title="Onboarding Status" />
  //     </div>
  //   ),
  //   cell: ({row}) => {
  //     if (!row.original.status) {
  //       return (
  //         <div className="flex justify-center">
  //           <Paragraph>{FallbackMessages.NOT_APPLICABLE}</Paragraph>
  //         </div>
  //       );
  //     }

  //     const statusObject = statusTypes.find(
  //       (status) => status.value === row.original.status
  //     );

  //     if (!statusObject) {
  //       return (
  //         <div className="flex justify-center">
  //           <Paragraph>{row.original.status}</Paragraph>
  //         </div>
  //       );
  //     }

  //     const IconComponent = statusObject.icon;
  //     const statusReceived = row.original.status === "active";

  //     return (
  //       <div className="flex justify-center">
  //         {statusReceived ? (
  //           <Badge variant="success">
  //             <span className="mr-2">
  //               <IconComponent />
  //             </span>
  //             {row.original.status}
  //           </Badge>
  //         ) : (
  //           <Badge variant="secondary">
  //             <span className="mr-2">
  //               <IconComponent />
  //             </span>
  //             {statusObject.label}
  //           </Badge>
  //         )}
  //       </div>
  //     );
  //   },
  // },
  {
    id: "actions",
    header: () => <Paragraph className="text-right">Actions</Paragraph>,
    cell: ({row}) => (
      <div className="flex justify-end">
        <CustomerRoleManagementRowActions row={row} />
      </div>
    ),
  },
];
