import {useEffect, useMemo, useState} from "react";

import type {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
} from "@tanstack/react-table";
import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {Paragraph} from "@/components/ui/typography";
import {DataTablePagination} from "@/components/craft/data-table";
import {TableSkeleton} from "@/components/craft/table";

import {
  useFileProcessingIMRemitPremiumTableColumns,
  useFileProcessingIMRemitPremiumTableColumnsStore,
  usePreferencesStore,
} from "@/lib/stores";
import {
  FileProcessingDataTableToolbar,
  type FileProcessingIMRemitPremiumTableColumnsType,
} from "@/modules/imremit";

/**
 * Custom hook for managing omitted columns
 * @returns {Object} - Object containing the current state of omitted columns
 */
const useOmittedColumns = () => {
  // Get the current state of the omitted columns from the store
  const {storeFileProcessingIMRemitPremiumTableColumns} =
    useFileProcessingIMRemitPremiumTableColumns();
  // Memoize the omitted columns so that it is not re-calculated on every render
  return useMemo(() => {
    // If there is no state in the store, return default omitted columns
    return storeFileProcessingIMRemitPremiumTableColumns || {};
  }, [storeFileProcessingIMRemitPremiumTableColumns]);
};

interface FileProcessingDataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  isPending?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
}

export function FileProcessingDataTable<TData, TValue>({
  columns,
  data,
  isPending,
  isSuccess,
  isError,
}: FileProcessingDataTableProps<TData, TValue>) {
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);

  // Custom hook to manage omitted columns
  const omittedColumns = useOmittedColumns();
  const [columnVisibility, setColumnVisibility] =
    useState<VisibilityState>(omittedColumns);

  /** State for storing the table columns */
  const {setStoreFileProcessingIMRemitPremiumTableColumns} =
    useFileProcessingIMRemitPremiumTableColumnsStore();

  /** Update the store when the column visibility changes */
  useEffect(() => {
    setStoreFileProcessingIMRemitPremiumTableColumns(
      columnVisibility as FileProcessingIMRemitPremiumTableColumnsType
    );
  }, [columnVisibility, setStoreFileProcessingIMRemitPremiumTableColumns]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  // The `usePreferencesStore` hook from the `@/lib/stores` module provides an API for interacting with the preferences store.
  // We are using it to get the number of rows per page to display in the table.
  const {numRowsPerPage} = usePreferencesStore();

  // Set the page size when the number of rows per page changes
  useEffect(() => {
    table.setPageSize(Number(numRowsPerPage));
  }, [numRowsPerPage, table]);

  return (
    <section className="space-y-2">
      <FileProcessingDataTableToolbar
        table={table}
        isPending={isPending}
        isErrored={isError}
      />
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          {isError ? (
            <TableBody>
              <TableRow>
                <TableCell
                  className="h-24 text-center"
                  colSpan={columns.length}
                >
                  Error loading data.
                </TableCell>
              </TableRow>
            </TableBody>
          ) : !isSuccess ? (
            <TableSkeleton
              columnsLength={columns.length}
              omittedColumns={omittedColumns}
              numRowsPerPage={parseInt(numRowsPerPage)}
            />
          ) : (
            <TableBody>
              {table.getRowModel().rows.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-20">
                    <Paragraph className="text-center font-semibold">
                      No results found.
                    </Paragraph>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableFooter>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </section>
  );
}
