import {useEffect, useMemo, useState} from "react";

import {CheckIcon, ChevronsUpDownIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {Label} from "@/components/ui/label";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Heading2} from "@/components/ui/typography";

import {StatementReconRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore, useStatementReconCustomerStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {
  AccessPolicyWrapper,
  calculateUserRoles,
  KeycloakRoleEnum,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import type {KeycloakTokenWithSupplierType} from "@/modules/customers";
import {
  SelectCustomerBySupplierForm,
  SelectCustomerFormStatementRecon,
  SelectCustomerSectionStatementRecon,
  SelectSupplierSection,
} from "@/modules/customers";
import {useGetAllSuppliersDetails} from "@/modules/imremit";
import {
  SRSettingsCards,
  SRSettingsDataTable,
  srSettingsTableColumns,
  useSettingsByExternalId,
  useSettingsByExternalIdAndSupplierId,
} from "@/modules/statement-recon";

import {FallbackMessages, KeycloakRoles} from "@/utils/constants";

/**
 * Custom Hook: Manage the active route for the Statement Recon Settings page
 */
function useStatementReconStatementSettingsRoute() {
  const {setStatementReconRoute} = useRoutesStore();

  useEffect(() => {
    // Set the active route based on predefined route schema
    setStatementReconRoute(
      StatementReconRoutesSchema.Values["/app/statement-recon/settings"]
    );
  }, [setStatementReconRoute]);
}

export function StatementReconStatementSettingsPage() {
  useStatementReconStatementSettingsRoute();
  const [currentSupplier, setCurrentSupplier] = useState<string | number>("");
  const {statementReconStoreCustomer} = useStatementReconCustomerStore();
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  const srSettingsByExternalIdQuery = useSettingsByExternalId(
    statementReconStoreCustomer?.externalId || ""
  );

  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";
  const parsedUserInfo =
    storeKeyCloakInstance?.tokenParsed as KeycloakTokenWithSupplierType;

  const isASupplierUser = useMemo(
    () =>
      userRoleDisplay === KeycloakRoles.SupplierUser ||
      userRoleDisplay === KeycloakRoles.SupplierAdmin ||
      userRoleDisplay === KeycloakRoles.SupplierEnablement,
    [userRoleDisplay]
  );
  const srSettingsByExternalIdAndSupplierIdQuery =
    useSettingsByExternalIdAndSupplierId(
      statementReconStoreCustomer?.externalId || "",
      isASupplierUser ? parsedUserInfo.supplier : currentSupplier.toString()
    );

  const srSettingsContent = srSettingsByExternalIdQuery.data?.content || [];
  const srSettingsBySupplierContent =
    srSettingsByExternalIdAndSupplierIdQuery.data?.content || [];

  useEffect(() => {
    // Reset current supplier when customer changes
    setCurrentSupplier("");
  }, [statementReconStoreCustomer]);

  const supplierQuery = useGetAllSuppliersDetails(
    statementReconStoreCustomer && statementReconStoreCustomer.externalId
      ? statementReconStoreCustomer.externalId.toString()
      : statementReconStoreCustomer?.id.toString()
  );

  const availableSuppliersByCustomer = useMemo(() => {
    if (!statementReconStoreCustomer || !supplierQuery.data?.content) return [];

    const suppliers = supplierQuery.data.content
      .filter((supplierData) => supplierData.onboardStatus !== "INACTIVE")
      .map((supplierData) => {
        const {supplierId, supplierName, supplierNumber} = supplierData;

        return {
          value: supplierId,
          label: `${String(supplierNumber)} - ${
            supplierName ?? FallbackMessages.NOT_APPLICABLE
          }`, // 📝 Using String() and nullish coalescing for type safety
        };
      });

    // Add "All suppliers" option at the beginning of the list
    return [{value: "", label: "All suppliers"}, ...suppliers];
  }, [statementReconStoreCustomer, supplierQuery]);

  if (!statementReconStoreCustomer) {
    if (isASupplierUser) {
      return <SelectSupplierSection />;
    } else {
      return <SelectCustomerSectionStatementRecon />;
    }
  }

  console.log("parsedUserInfo.supplier", parsedUserInfo.supplier);
  return (
    <section className="flex flex-col gap-4">
      <div className="flex items-center justify-between gap-4">
        <Heading2>Statement Recon Settings</Heading2>
        <div className="flex flex-col gap-2 md:flex-row md:gap-4">
          <div className="pt-6" />
          <div className="w-full md:w-auto">
            {isASupplierUser ? (
              <SelectCustomerBySupplierForm
                supplierId={parsedUserInfo.supplier}
                requireLabel
              />
            ) : (
              <SelectCustomerFormStatementRecon requireLabel />
            )}
          </div>
          <div className="mb-2 flex flex-col space-y-2">
            {isASupplierUser ? (
              <div />
            ) : (
              <div className="mb-2 flex flex-col space-y-2">
                <Popover>
                  <Label>Select Supplier:</Label>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      role="combobox"
                      id="supplier-popover"
                      disabled={availableSuppliersByCustomer.length === 0}
                      className={cn(
                        "justify-between",
                        !currentSupplier && "text-accent-foreground",
                        "w-[420px]"
                      )}
                    >
                      {currentSupplier
                        ? availableSuppliersByCustomer.find(
                            (item) => item.value === currentSupplier
                          )?.label
                        : "Select Supplier..."}
                      <ChevronsUpDownIcon className="size-4 shrink-0 opacity-50" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                    <Command>
                      <CommandInput placeholder="Search Supplier Name or ID" />
                      <CommandList>
                        <CommandEmpty>No supplier found.</CommandEmpty>
                        <CommandGroup>
                          {availableSuppliersByCustomer.map(
                            (supplierOption, i) => (
                              <CommandItem
                                key={i}
                                value={supplierOption.label}
                                onSelect={() => {
                                  setCurrentSupplier(supplierOption.value);
                                }}
                              >
                                <CheckIcon
                                  className={cn(
                                    "mr-2 size-4",
                                    supplierOption.value
                                      .toString()
                                      .toLowerCase()
                                      .trim() ===
                                      currentSupplier
                                        .toString()
                                        .toLowerCase()
                                        .trim()
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                                {supplierOption.label}
                              </CommandItem>
                            )
                          )}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
              </div>
            )}
          </div>
        </div>
      </div>
      <AccessPolicyWrapper
        unauthorizedFallback={null}
        policyActions={[KeycloakRoleEnum.CREATE_SR_SETTINGS]}
      >
        <SRSettingsCards
          externalId={statementReconStoreCustomer.externalId}
          supplierId={currentSupplier}
        />
      </AccessPolicyWrapper>
      <SRSettingsDataTable
        columns={srSettingsTableColumns}
        isPending={srSettingsByExternalIdQuery.isPending}
        isSuccess={srSettingsByExternalIdQuery.isSuccess}
        isError={srSettingsByExternalIdQuery.isError}
        data={
          currentSupplier || parsedUserInfo.supplier
            ? srSettingsBySupplierContent
            : srSettingsContent
        }
      />
    </section>
  );
}
