import {useEffect, useMemo} from "react";

import {Outlet, useNavigate, useRouter} from "@tanstack/react-router";

import {Toaster} from "@/components/ui/toaster";
import {LandingInfo} from "@/components/layout/landing-info";
import {GenericLoadingPage} from "@/components/layout/loading-page";
import {Footer} from "@/components/layout/site-footer";
import {Header} from "@/components/layout/site-header";
import {Sidebar} from "@/components/layout/site-sidebar";
import {TailwindIndicator} from "@/components/layout/tailwind-indicator";

import {
  useCustomerStore,
  useDupesCustomerStore,
  useImRemitCustomerStore,
  useImRemitLiteCustomerStore,
  useRoutePersistence,
  useStatementReconCustomerStore,
} from "@/lib/stores";
import {useKeyCloakAuth, useKeyCloakInstanceStore} from "@/modules/auth";
import {useGetCustomerByName} from "@/modules/customers";

// Defining our main app outlet component. Everything will be rendered out of here.
// The 'Toaster' component is placed in context here, which is used to display toast notifications.
// The 'TailwindIndicator' component is also placed in context here, which is used to display
// the current Tailwind breakpoint in dev.
export function RootOutlet() {
  // Extract the `tokenParsed` field from the store
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  // Extract the `customer` field from the tokenParsed object
  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;

  const {
    data: customersByNameRawData,
    isPending: customersByNameArePending,
    isError: customersByNameError,
  } = useGetCustomerByName(parsedUserInfo?.customer as string | undefined);

  const customersByNameContent = useMemo(() => {
    return customersByNameRawData?.content || [];
  }, [customersByNameRawData]);

  // Extract the `isLogin` and `token` fields from the custom hook
  const [isLogin, token] = useKeyCloakAuth();

  // Extract the `updateImRemitStoreCustomer` function from the custom hook
  const {updateStoreCustomer} = useCustomerStore();
  const {updateImRemitStoreCustomer} = useImRemitCustomerStore();
  const {updateDupesStoreCustomer} = useDupesCustomerStore();
  const {updateImRemitLiteStoreCustomer} = useImRemitLiteCustomerStore();
  const {updateStatementReconStoreCustomer} = useStatementReconCustomerStore();

  // If the user is logged in and has a customer set in their token, update the store
  useEffect(() => {
    if (parsedUserInfo?.customer) {
      const updatedCustomer = customersByNameContent.find(
        (customer) =>
          customer.buyerName.toLowerCase().trim() ===
          (parsedUserInfo.customer as string).toLowerCase().trim()
      );

      if (updatedCustomer) {
        updateStoreCustomer(updatedCustomer);
      }
      const updatedDupesCustomer = customersByNameContent.find(
        (customer) =>
          customer.buyerName.toLowerCase().trim() ===
          (parsedUserInfo.customer as string).toLowerCase().trim()
      );

      if (updatedDupesCustomer) {
        updateDupesStoreCustomer(updatedDupesCustomer);
      }
      const updatedimREmitCustomer = customersByNameContent.find(
        (customer) =>
          customer.buyerName.toLowerCase().trim() ===
          (parsedUserInfo.customer as string).toLowerCase().trim()
      );

      if (updatedimREmitCustomer) {
        updateImRemitStoreCustomer(updatedimREmitCustomer);
      }
      const updatedimREmitLiteCustomer = customersByNameContent.find(
        (customer) =>
          customer.buyerName.toLowerCase().trim() ===
          (parsedUserInfo.customer as string).toLowerCase().trim()
      );

      if (updatedimREmitLiteCustomer) {
        updateImRemitLiteStoreCustomer(updatedimREmitLiteCustomer);
      }
      const updatedStatementReconCustomer = customersByNameContent.find(
        (customer) =>
          customer.buyerName.toLowerCase().trim() ===
          (parsedUserInfo.customer as string).toLowerCase().trim()
      );

      if (updatedStatementReconCustomer) {
        updateStatementReconStoreCustomer(updatedStatementReconCustomer);
      }
    }
  }, [
    parsedUserInfo?.customer,
    updateImRemitStoreCustomer,
    updateStoreCustomer,
    updateDupesStoreCustomer,
    updateImRemitLiteStoreCustomer,
    updateStatementReconStoreCustomer,
    customersByNameContent,
  ]);

  if (!customersByNameArePending && customersByNameError) {
    throw new Error("Failed to fetch customers");
  }

  const doWeNeedToFetchCustomers = !!parsedUserInfo?.customer;

  // If the user is not logged in, or the token is not present, or the customers are pending,
  if (
    !isLogin ||
    !token ||
    (doWeNeedToFetchCustomers && customersByNameArePending)
  ) {
    return (
      <main className="flex min-h-screen items-center justify-center">
        <GenericLoadingPage />
      </main>
    );
  }

  // Render application
  return (
    <article className="flex min-h-screen flex-col">
      <Outlet />
      <Toaster />
      <TailwindIndicator />
    </article>
  );
}

// Defining the 'LandingOutlet' component. This is a wrapper for any child components
// which will be rendered via the 'Outlet' component within a <main> HTML element
// with specific styles applied.
export function LandingOutlet() {
  return (
    <main className="grid flex-1 grid-cols-1 bg-background lg:grid-cols-2">
      <aside className="jus flex flex-col justify-center bg-foreground p-8 text-root xl:p-12">
        <LandingInfo />
      </aside>
      <article className="max-h-screen flex-1 overflow-y-auto">
        <Outlet />
      </article>
    </main>
  );
}

// Defining the 'AuthorizedOutlet' component. This component renders any child components
// through the 'Outlet' component, and appends the 'Header', 'Sidebar', and 'TailwindIndicator'
// components at the beginning, middle, and end, respectively.
export function AuthorizedOutlet() {
  const {lastVisited} = useRoutePersistence();

  // Get pathname of user's current route
  const {pathname} = useRouter().latestLocation;

  // Get the navigate function from the router
  const navigate = useNavigate();

  if (pathname === "/app") {
    void navigate({
      to: lastVisited ? lastVisited : "/app/invoices",
    });
  }

  return (
    <>
      <Header />
      <Sidebar />
      <main className="break-word flex-1 overflow-auto bg-background px-6 py-4 md:ml-16">
        <Outlet />
      </main>
      <Footer />
    </>
  );
}
