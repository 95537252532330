import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import type {
  AddAlertManagementType,
  AlertManagementType,
} from "@/modules/admin";
import {alertManagementQueryKeys} from "@/modules/admin";

type QueryFnType = typeof getAlertManagement;

// Function to search alertManagement
export function getAlertManagement() {
  return kyApiFn<AlertManagementType[]>(
    `api/e-payable/management/alert-details`,
    "get"
  );
  // TODO: validate data
}

// Custom hook to fetch and cache alertManagement data using react-query
export function useAlertManagement(
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: alertManagementQueryKeys.all, // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getAlertManagement(), // Setting the function to be used for fetching data
  });
}

export const addAlertManagementFn = (
  addAlertManagementDTO: AddAlertManagementType
) =>
  kyApiFn(`api/e-payable/management/alert-details/create`, "post", {
    json: addAlertManagementDTO,
  });
