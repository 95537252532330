import {z} from "zod";
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// This is a Zod schema for a TypeScript enum. Zod is a TypeScript-first schema declaration and validation library. It will ensure the numRowsPerPage value will only be one of "10", "25", "50", "100".
export const numRowsSchema = z.enum([
  "10",
  "25",
  "50",
  "100",
  "250",
  "500",
  "1000",
  "1000000",
]);

// This defines the TypeScript type for numRows using the Zod schema.
export type NumRowsType = z.infer<typeof numRowsSchema>;

// This is the TypeScript type for the state object in the PreferencesStore.
// It includes the last update time, the number of rows per page and a function to update the number of rows.
interface PreferencesStoreState {
  lastUpdated: Date | null;
  numRowsPerPage: NumRowsType;
  setNumRowsPerPage: (numRowsPerPage: NumRowsType) => void;
}

// This is a Zustand store. Zustand is a small, fast and scaleable bearbones state-management solution. It has a straightforward API that gets out of your way and doesn't require a lot of boilerplate code.
// Here we use the persist function, which is a Zustand middleware that adds persistence to the store. It saves the state to the localStorage each time it changes and retrieves it when the app loads.
// createJSONStorage is a custom JSON serializing storage engine for Zustand's persist middleware, which uses the localStorage for storage.
export const usePreferencesStore = create(
  persist<PreferencesStoreState>(
    // This is the function that will initialize the Zustand store. It accepts two arguments, set and get.
    // set is a function that you can use to update the state, and get is a function you can use to retrieve the current state.
    (set, get) => ({
      // The initial value for lastUpdated. It's null to indicate that the state hasn't been updated yet.
      lastUpdated: null,
      // The initial value for numRowsPerPage. It starts at "10".
      numRowsPerPage: numRowsSchema.Values["10"],
      // This function updates the numRowsPerPage and the lastUpdated values. It uses the set function to do that.
      setNumRowsPerPage: (numRowsPerPage) => {
        set(() => ({
          numRowsPerPage,
          lastUpdated: new Date(),
        }));
      },
      // This function returns the current value of numRowsPerPage. It uses the get function to do that.
      getNumRowsPerPage: () => get().numRowsPerPage,
    }),
    {
      // The key under which the Zustand persist middleware will save the state in the localStorage.
      name: "im-preferences-store",
      // The storage engine that Zustand's persist middleware will use.
      storage: createJSONStorage(() => localStorage),
    }
  )
);

// This code exports a custom hook named "usePreferences"
// It provides access to preferences related to the number of rows per page and the last update time
export const usePreferences = () => {
  // Retrieve the "numRowsPerPage" value from the preferences store using the "usePreferencesStore" hook
  const numRowsPerPage = usePreferencesStore((state) => state.numRowsPerPage);

  // Retrieve the "lastUpdated" value from the preferences store using the "usePreferencesStore" hook
  const lastUpdated = usePreferencesStore((state) => state.lastUpdated);

  // Return an object containing the retrieved values and function
  return {
    numRowsPerPage, // The current number of rows per page
    lastUpdated, // The timestamp of the last update
  };
};
