export const statementReconGroupingKeys = {
  // 🖥️📊 A list that stores the key for accessing all sr groupings displayed on the dashboard
  all: ["sr-grouping"] as const,
  // 🛍️🔧 A list that stores the key for accessing all grouping settings
  byExternalId: (buyer_external_id?: string) =>
    [...statementReconGroupingKeys.all, buyer_external_id] as const,
  // 🛍️🔧 A list that stores the key for accessing all groupings settings by group id
  byGroupingID: (groupID?: string) =>
    [...statementReconGroupingKeys.all, groupID] as const,
};

export const statementReconMappingKeys = {
  // 🖥️📊 A list that stores the key for accessing all sr mappings displayed on the dashboard
  all: ["sr-mapping"] as const,
  // 🛍️🔧 A list that stores the key for accessing all mapping settings by saved mapping id
  bySavedMappingID: (savedMappingID?: number) =>
    [...statementReconMappingKeys.all, savedMappingID] as const,
  // 🛍️🔧 A list that stores the key for accessing all mapping settings by external id
  byExternalID: (buyer_external_id?: string) =>
    [...statementReconMappingKeys.all, buyer_external_id] as const,
};

export const statementReconVendorFieldsKeys = {
  // 🖥️📊 A list that stores the key for accessing all sr mappings displayed on the dashboard
  all: ["sr-vendor-field"] as const,
};

export const statementReconDateFormatKeys = {
  // 🖥️📊 A list that stores the key for accessing all sr summary displayed on the dashboard
  all: ["sr-date-format"] as const,
};

export const statementFilesExternalKeys = {
  // 🖥️📊 A list that stores the key for accessing all sr file histories displayed on the dashboard
  all: ["sr-files-external"] as const,
  // 🛍️🔧 A list that stores the key for accessing all file histories by external id
  byExternalID: (buyer_external_id?: string) =>
    [...statementFilesExternalKeys.all, buyer_external_id] as const,
  // 🛍️🔧 A list that stores the key for accessing all file histories by file id
  byFileID: (fileID?: string) =>
    [...statementFilesExternalKeys.all, fileID] as const,
};

export const statementFilesKeys = {
  // 🖥️📊 A list that stores the key for accessing all sr file histories displayed on the dashboard
  all: ["sr-files"] as const,
  // 🛍️🔧 A list that stores the key for accessing all file histories by external id
  byExternalID: (buyer_external_id?: string) =>
    [...statementFilesKeys.all, buyer_external_id] as const,
  // 🛍️🔧 A list that stores the key for accessing all file histories by file id
  byFileID: (fileID?: string) => [...statementFilesKeys.all, fileID] as const,
};

export const statementFilesJSONKeys = {
  // 🖥️📊 A list that stores the key for accessing all sr file histories displayed on the dashboard
  all: ["sr-files-json"] as const,
  // 🛍️🔧 A list that stores the key for accessing all file histories by external id
  byExternalID: (buyer_external_id?: string) =>
    [...statementFilesJSONKeys.all, buyer_external_id] as const,
  // 🛍️🔧 A list that stores the key for accessing all file histories by file id
  byFileID: (fileID?: string) =>
    [...statementFilesJSONKeys.all, fileID] as const,
};

export const statementFilesJSONIDKeys = {
  // 🖥️📊 A list that stores the key for accessing all sr file histories displayed on the dashboard
  all: ["sr-files-jsonid"] as const,
  // 🛍️🔧 A list that stores the key for accessing all file histories by external id
  byExternalID: (buyer_external_id?: string) =>
    [...statementFilesJSONIDKeys.all, buyer_external_id] as const,
  // 🛍️🔧 A list that stores the key for accessing all file histories by file id
  byFileID: (fileID?: string) =>
    [...statementFilesJSONIDKeys.all, fileID] as const,
};

export const statementReconSettingsKeys = {
  // 🖥️📊 A list that stores the key for accessing all sr settings.
  all: ["sr-settings"] as const,
  // 🛍️🔧 A list that stores the key for accessing all file settings by external id
  byExternalId: (buyer_external_id?: string) =>
    [...statementReconSettingsKeys.all, buyer_external_id] as const,
  // 🛍️🔧 A list that stores the key for accessing all settings by external id and supplier id
  byExternalIdAndSupplierId: (
    buyer_external_id?: string,
    supplier_id?: string
  ) =>
    [
      ...statementReconSettingsKeys.all,
      buyer_external_id,
      supplier_id,
    ] as const,
};

export const StatementReconStatementListKeys = {
  all: ["sr-statement-list"] as const,
  byFileId: (file_id?: string) =>
    [...StatementReconStatementListKeys.all, file_id] as const,
  byExternalID: (buyer_external_id?: string) =>
    [...StatementReconStatementListKeys.all, buyer_external_id] as const,
};

export const StatementReconciliationSummaryKeys = {
  all: ["sr-statement-summary"] as const,
  byFileId: (file_id?: string) =>
    [...StatementReconciliationSummaryKeys.all, file_id] as const,
  byExternalID: (buyer_external_id?: string) =>
    [...StatementReconciliationSummaryKeys.all, buyer_external_id] as const,
};

export const statementReconStatementDetailsKeys = {
  // 🖥️📊 A list that stores the key for accessing all sr statement details displayed on the dashboard
  all: ["sr-statement-details"] as const,
  // 🛍️🔧 A list that stores the key for accessing all statement details by statement id
  byStatementID: (statementId?: string) =>
    [...statementReconStatementDetailsKeys.all, statementId] as const,
};
