import {MessageCircleIcon} from "lucide-react";

import {Skeleton} from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {Paragraph} from "@/components/ui/typography";

import {
  usePaymentManagementInvoices,
  type imREmitPaymentId,
  type paymentManagementInvoices,
} from "@/modules/imremit";

import {formatCurrency} from "@/utils/functions";

interface PaymentManagementInvoicesProps {
  paymentManagementInvoices: paymentManagementInvoices;
}

export function ImREmitInvoiceHistory({id}: imREmitPaymentId) {
  const paymentManagementInvoicesQuery = usePaymentManagementInvoices(
    id.toString()
  );

  const paymentManagementInvoicesData =
    paymentManagementInvoicesQuery.data?.content;

  const {isPending, isError} = paymentManagementInvoicesQuery;

  // Rendering the table
  return (
    <Table className="w-full">
      <TableHeader>
        <TableRow>
          <TableHead>Invoice Number</TableHead>
          <TableHead>Invoice Date</TableHead>
          <TableHead>PO Number</TableHead>
          <TableHead>Invoice Amount (USD)</TableHead>
          <TableHead>Discount Amount (USD)</TableHead>
          <TableHead>Adjustment Amount (USD)</TableHead>
          <TableHead>Amount Paid (USD)</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isPending ? (
          <TableRow>
            <TableCell className="h-24 text-center" colSpan={7}>
              <div className="space-y-4">
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-full" />
                <Skeleton className="h-4 w-full" />
              </div>
            </TableCell>
          </TableRow>
        ) : isError ? (
          <TableRow>
            <TableCell className="h-24 text-center" colSpan={7}>
              <Paragraph>
                <strong>Data not available !</strong>
              </Paragraph>
            </TableCell>
          </TableRow>
        ) : paymentManagementInvoicesData &&
          paymentManagementInvoicesData.length > 0 ? (
          paymentManagementInvoicesData.map(
            (paymentManagementInvoices, index) => (
              <PaymentManagementInvoices
                key={index}
                paymentManagementInvoices={paymentManagementInvoices}
              />
            )
          )
        ) : (
          <TableRow>
            <TableCell className="h-24 text-center" colSpan={8}>
              <Paragraph>
                <strong>No Invoices Available!</strong>
              </Paragraph>
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

const PaymentManagementInvoices: React.FC<PaymentManagementInvoicesProps> = ({
  paymentManagementInvoices,
}) => (
  <TableRow>
    <TableCell className="whitespace-nowrap">
      <div className="flex items-center">
        {paymentManagementInvoices.invoiceNumber}
        <TooltipProvider delayDuration={200}>
          <Tooltip>
            <TooltipTrigger asChild>
              <div className="cursor-pointer">
                <MessageCircleIcon className="ml-2 size-4 text-theme" />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              <div className="flex justify-between space-x-4">
                {paymentManagementInvoices.comments
                  ? paymentManagementInvoices.comments
                  : "This invoice has no comments"}
              </div>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </TableCell>
    <TableCell className="whitespace-nowrap">
      {paymentManagementInvoices.invoiceDate}
    </TableCell>
    <TableCell className="whitespace-nowrap">
      {paymentManagementInvoices.poNumber}
    </TableCell>
    <TableCell className="whitespace-nowrap">
      {/* Format invoiceTotalAmount as USD 💵 */}
      {formatCurrency(paymentManagementInvoices.invoiceTotalAmount)}
    </TableCell>
    <TableCell className="whitespace-nowrap">
      {/* Format discountAmount as USD 💵 */}
      {formatCurrency(paymentManagementInvoices.discountAmount)}
    </TableCell>
    <TableCell className="whitespace-nowrap">
      {/* Format adjustmentAmount as USD 💵 */}
      {formatCurrency(paymentManagementInvoices.adjustmentAmount)}
    </TableCell>
    <TableCell className="whitespace-nowrap">
      {/* Format invoiceAmountPaid as USD 💵 */}
      {formatCurrency(paymentManagementInvoices.invoiceAmountPaid)}
    </TableCell>
  </TableRow>
);
