import {type FilterSuppliersType} from "@/modules/imremit";

// Define a constant to store the main query keys for imremits
export const imREmitQueryKeys = {
  // A list that stores the key for accessing all imremits
  allImREmits: ["imremits"] as const,

  searches: (externalId?: string) =>
    [
      ...imREmitQueryKeys.allImREmits,
      "search-by-id-and-query",
      externalId,
    ] as const,

  // A function that returns a list of keys to access imremit dashboards by a specific search criteria
  bySearch: (externalId?: string, searchQueryString?: string) =>
    [...imREmitQueryKeys.searches(externalId), searchQueryString] as const,

  imREmitsById: () =>
    [...imREmitQueryKeys.allImREmits, "imREmitsById"] as const,

  // A function that returns a list of keys to access imremit dashboards by a specific ID
  byId: (id?: string) => [...imREmitQueryKeys.imREmitsById(), id] as const,
};

export const paymentCommentQueryKeys = {
  allPaymentComments: ["paymentComment"] as const,
  byPaymentId: (paymentId?: string) =>
    [
      ...paymentCommentQueryKeys.allPaymentComments,
      "byPaymentId",
      paymentId,
    ] as const,
};

export const supplierCommentQueryKeys = {
  allSupplierComments: ["supplierComment"] as const,
  bysupplierId: (supplierId?: string) =>
    [
      ...supplierCommentQueryKeys.allSupplierComments,
      "bysupplierId",
      supplierId,
    ] as const,
};

// Define a constant to store the main query keys for imremit dashboards
export const imREmitDashboardQueryKeys = {
  // A list that stores the key for accessing all imremit dashboards
  allImREmitDashboardItems: ["imremitDashboardItems"] as const,
  // A function that returns a list of keys to access imremit dashboards based on a search
  detailsByExternalId: () =>
    [
      ...imREmitDashboardQueryKeys.allImREmitDashboardItems,
      "detailsByExternalId",
    ] as const,
  // A function that returns a list of keys to access imremit dashboards by a specific search criteria
  byExternalId: (externalId?: string) =>
    [...imREmitDashboardQueryKeys.detailsByExternalId(), externalId] as const,
};

// Define a constant to store the main query keys for imremit delimiter
export const imREmitDelimiterQueryKeys = {
  // A list that stores the key for accessing all imremit delimiters
  all: ["imremitDelimiters"] as const,
};

// Define a constant to store the main query keys for imremit facility
export const facilityQueryKeys = {
  // A list that stores the key for accessing all imremit Facilities
  all: ["imremitFacilities"] as const,
};

// Define a constant to store the main query keys for imremit runner types
export const imREmitRunnerTypeQueryKeys = {
  // A list that stores the key for accessing all imremit runner types
  all: ["imremitRunnerTypes"] as const,
};

// Define a constant to store the main query keys for imremit document formats
export const imREmitDocumentFormatQueryKeys = {
  // A list that stores the key for accessing all imremit document formats
  all: ["imremitDocumentFormats"] as const,

  byFormatType: () =>
    [...imREmitDocumentFormatQueryKeys.all, "byFormatType"] as const, // 📄
  // Function to create query keys for retrieving document format details by an format type, extending 'format'
  byFormat: (format: string) =>
    [...imREmitDocumentFormatQueryKeys.byFormatType(), format] as const, // 🔖
};

// Define a constant to store the main query keys for imremit denormalize document formats
export const imREmitDenormalizeDocumentFormatQueryKeys = {
  // A list that stores the key for accessing all imremit denormalize document formats
  all: ["imremitDenormalizeDocumentFormats"] as const,
};

// Define a constant to store the main query keys for ISO payment file mappings
export const ISOPaymentFileMappingQueryKeys = {
  // A list that stores the key for accessing all ISO payment file mappings
  all: ["ISOPaymentFileMappings"] as const,
};

// Define a constant to store the main query keys for supplier scripts
export const imREmitSupplierScriptsQueryKey = {
  // A list that stores the key for accessing all supplier scripts
  all: ["imremitSupplierScripts"] as const,
  bySupplierId: (supplierId?: string) =>
    [
      ...imREmitSupplierScriptsQueryKey.all,
      "bySupplierId",
      supplierId,
    ] as const,
};

export const failedAlertQueryKeys = {
  // A list that stores the key for accessing all failedAlerts
  all: ["failedAlert"] as const,
};

export const fileProcessingDetailsQueryKeys = {
  all: ["fileProcessing"] as const,
  fileProcessingByExternalId: (externalId?: string) =>
    [...fileProcessingDetailsQueryKeys.all, "search", externalId] as const,
  // A function that returns a list of keys to access imremit dashboards by a specific search criteria
  byExternalId: (externalId?: string, searchQueryString?: string) =>
    [
      ...fileProcessingDetailsQueryKeys.fileProcessingByExternalId(externalId),
      searchQueryString,
    ] as const,
};

export const proxyPayQueryKeys = {
  // A list that stores the key for accessing all imremit dashboards
  all: ["proxy-pay"] as const,
  // A function that returns a list of keys to access imremit dashboards based on a search
  byExternalId: (externalId?: string) =>
    [...proxyPayQueryKeys.all, "byExternalIdAndQuery", externalId] as const,
  // A function that returns a list of keys to access imremit dashboards by a specific search criteria
  byExternalIdAndSearchQuery: (externalId?: string, searchQuery?: string) =>
    [...proxyPayQueryKeys.byExternalId(externalId), searchQuery] as const,
};

export const proxyPayDashboardQueryKeys = {
  // A list that stores the key for accessing all imremit dashboards
  all: ["proxypayDashboard"] as const,
  // A function that returns a list of keys to access imremit dashboards by a specific ID
  byExternalId: (externalId: string) =>
    [...proxyPayDashboardQueryKeys.all, "byExternalId", externalId] as const,
};

export const proxyPayDetailsQueryKeys = {
  // A list that stores the key for accessing all proxypay dashboards
  all: ["proxyPayDetails"] as const,
  // A function that returns a list of keys to access proxypay dashboards by a specific ID
  byProxyPayId: (proxyPayId: string) =>
    [...proxyPayDetailsQueryKeys.all, "byProxyPayId", proxyPayId] as const,
};

export const paymentManagementInvoiceQueryKeys = {
  all: ["paymentManagementInvoice"] as const,

  detailsById: () =>
    [...paymentManagementInvoiceQueryKeys.all, "detailsById"] as const,

  byId: (paymentId?: string) =>
    [...paymentManagementInvoiceQueryKeys.detailsById(), paymentId] as const,
};

export const paymentManagementTransactionKeys = {
  // A list that stores the key for accessing all paymentManagementTransaction
  all: ["paymentManagementTransaction"] as const,

  searches: () => [...paymentManagementTransactionKeys.all, "search"] as const,

  bySearch: (search?: string) =>
    [...paymentManagementTransactionKeys.searches(), search] as const,

  details: () => [...paymentManagementTransactionKeys.all, "details"] as const,

  byId: (id?: string) =>
    [...paymentManagementTransactionKeys.details(), id] as const,
};

export const paymentManagementAuthorizationKeys = {
  // A list that stores the key for accessing all paymentManagementAuthorization
  all: ["paymentManagementAuthorization"] as const,

  searches: () =>
    [...paymentManagementAuthorizationKeys.all, "search"] as const,

  bySearch: (search?: string) =>
    [...paymentManagementAuthorizationKeys.searches(), search] as const,

  details: () =>
    [...paymentManagementAuthorizationKeys.all, "details"] as const,

  byId: (id?: string) =>
    [...paymentManagementAuthorizationKeys.details(), id] as const,
};

export const paymentManagementHistoryKeys = {
  // A list that stores the key for accessing all paymentManagementHistory
  all: ["paymentManagementHistory"] as const,

  searches: () => [...paymentManagementHistoryKeys.all, "search"] as const,

  bySearch: (search?: string) =>
    [...paymentManagementHistoryKeys.searches(), search] as const,

  details: () => [...paymentManagementHistoryKeys.all, "details"] as const,

  byId: (id?: string) =>
    [...paymentManagementHistoryKeys.details(), id] as const,
};

export const paymentManagementGenInfoKeys = {
  // A list that stores the key for accessing all paymentManagementInvoice
  all: ["paymentManagementGenInfo"] as const,

  searches: () => [...paymentManagementGenInfoKeys.all, "search"] as const,

  bySearch: (search?: string) =>
    [...paymentManagementGenInfoKeys.searches(), search] as const,

  details: () => [...paymentManagementGenInfoKeys.all, "details"] as const,

  byId: (id?: string) =>
    [...paymentManagementGenInfoKeys.details(), id] as const,
};

export const paymentManagementCommentsKeys = {
  // A list that stores the key for accessing all paymentManagementComment
  all: ["paymentManagementComment"] as const,
  searches: () => [...paymentManagementCommentsKeys.all, "search"] as const,

  bySearch: (search?: string) =>
    [...paymentManagementCommentsKeys.searches(), search] as const,

  details: () => [...paymentManagementCommentsKeys.all, "details"] as const,

  byId: (id?: string) =>
    [...paymentManagementCommentsKeys.details(), id] as const,
};

export const supplierManagementCommentsKeys = {
  // A list that stores the key for accessing all supplierManagementComments
  all: ["supplierManagementComment"] as const,
  searches: () => [...supplierManagementCommentsKeys.all, "search"] as const,

  bySearch: (search?: string) =>
    [...supplierManagementCommentsKeys.searches(), search] as const,

  details: () => [...supplierManagementCommentsKeys.all, "details"] as const,

  byId: (id?: string) =>
    [...supplierManagementCommentsKeys.details(), id] as const,
};

export const organizationsKeys = {
  // A list that stores the key for accessing all organization with details
  all: ["organizationsList"] as const,

  // A function that returns a list of keys to access organization details based on a search
  searches: () => [...organizationsKeys.all, "search"] as const,

  // A function that returns a list of keys to access organization details by a specific search criteria
  bySearch: (search?: string) =>
    [...organizationsKeys.searches(), search] as const,

  // A function that returns a list of keys to access detailed information about organization details
  details: () => [...organizationsKeys.all, "details"] as const,

  // A function that returns a list of keys to access organization details by a specific ID with details
  byId: (externalId?: string) =>
    [...organizationsKeys.details(), externalId] as const,
};

export const runnerConfigKeys = {
  // A list that stores the key for accessing all runner configurations with details
  all: ["runnerConfigList"] as const,

  // A function that returns a list of keys to access detailed information about runner configurations details
  details: () => [...runnerConfigKeys.all, "details"] as const,

  // A function that returns a list of keys to access runner configurations details by a specific ID with details
  byId: (externalId?: string) =>
    [...runnerConfigKeys.details(), externalId] as const,
};

export const paymentRunnerConfigKeys = {
  all: ["paymentRunnerConfigList"] as const,
  details: () => [...paymentRunnerConfigKeys.all, "details"] as const,
  byExternalId: (externalId?: string) =>
    [...paymentRunnerConfigKeys.details(), externalId] as const,
  byRunnerConfigId: (externalId: string, runnerConfigId: number) =>
    [
      ...paymentRunnerConfigKeys.byExternalId(externalId),
      runnerConfigId,
    ] as const,
};

export const runnerConfigsKeys = {
  // A list that stores the key for accessing all runner configurations with details
  all: ["runnerConfigsList"] as const,
  byCustomerId: (externalId: string) =>
    [...runnerConfigsKeys.all, externalId] as const,

  byConfigId: (externalId: string, configId: string) =>
    [...runnerConfigsKeys.byCustomerId(externalId), configId] as const,
};

// Define a constant to store the main query keys for paymentProviders
export const paymentProviderKeys = {
  // A list that stores the key for accessing all paymentProviders
  all: ["paymentProviders"] as const,

  // A function that returns a list of keys to access paymentProviders based on a search
  searches: () => [...paymentProviderKeys.all, "search"] as const,

  // A function that returns a list of keys to access paymentProviders by a specific search criteria
  bySearch: (search: string) =>
    [...paymentProviderKeys.searches(), search] as const,

  // A function that returns a list of keys to access detailed information about paymentProviders
  details: () => [...paymentProviderKeys.all, "details"] as const,

  // A function that returns a list of keys to access paymentProviders by a specific ID
  byId: (id: number) => [...paymentProviderKeys.details(), id] as const,
};

// Define a constant to store the main query keys for paymentMethodName
export const paymentMethodNameKeys = {
  // A list that stores the key for accessing all paymentMethodNames
  all: ["paymentMethodName"] as const,

  byCustomerId: (externalId?: string) =>
    [...paymentMethodNameKeys.all, externalId] as const,
};

// Define a constant to store the main query keys for providerPaymentMethodName
export const providerPaymentMethodNameKeys = {
  // A list that stores the key for accessing all providerPaymentMethodNames
  all: ["providerPaymentMethodName"] as const,

  byProviderId: (providerId?: string) =>
    [...providerPaymentMethodNameKeys.all, providerId] as const,
};

// Define a constant to store the main query keys for suppliers
export const supplierKeys = {
  // A list that stores the key for accessing all suppliers
  base: ["suppliers"] as const,

  all: () => [...supplierKeys.base, "all"] as const,

  // A function that returns a list of keys to access suppliers based on a customerIds
  byCustomerIds: (customerIds: string[]) =>
    [...supplierKeys.base, "byCustomerIds", ...customerIds] as const,

  // A function that returns a list of keys to access suppliers based on a search
  searches: () => [...supplierKeys.base, "search"] as const,

  // A function that returns a list of keys to access suppliers by a specific search criteria
  bySearch: (search?: string) => [...supplierKeys.searches(), search] as const,

  // A function that returns a list of keys to access detailed information about suppliers
  details: () => [...supplierKeys.base, "details"] as const,

  // A function that returns a list of keys to access suppliers by a specific ID
  byId: (id: number) => [...supplierKeys.details(), id] as const,
};

// Define a constant to store the main query keys for saved searches
export const savedInvoiceSearchQueryKeys = {
  // A list that stores the key for accessing all saved searches
  all: ["searches"] as const,
};

export const suppliersDetailsKeys = {
  // A list that stores the key for accessing all suppliers with details
  all: ["suppliersDetails"] as const,

  // A function that returns a list of keys to access suppliers details based on a search
  searches: (externalId?: string) =>
    [...suppliersDetailsKeys.all, "search", externalId] as const,

  // A function that returns a list of keys to access suppliers details by a specific search criteria
  bySearch: (externalId?: string, searchQueryString?: string) =>
    [...suppliersDetailsKeys.searches(externalId), searchQueryString] as const,

  // A function that returns a list of keys to access detailed information about suppliers details
  details: () => [...suppliersDetailsKeys.all, "details"] as const,

  // A function that returns a list of keys to access suppliers details by a specific ID with details
  byId: (externalId?: string) =>
    [...suppliersDetailsKeys.details(), externalId] as const,

  bySupplierId: (supplierId?: number) =>
    [...suppliersDetailsKeys.all, "bySupplierId", supplierId] as const,

  byFilter: (
    externalId?: string,
    filter?: FilterSuppliersType,
    search?: string
  ) =>
    [
      ...suppliersDetailsKeys.byId(externalId),
      "filter",
      filter,
      ...suppliersDetailsKeys.bySearch(search),
    ] as const,
};

// Define a constant to store the main query keys for supplierProfile
export const supplierProfileKeys = {
  // A list that stores the key for accessing all supplierProfile
  all: ["supplierProfile"] as const,

  // A function that returns a list of keys to access supplierProfile based on a search
  searches: () => [...supplierProfileKeys.all, "search"] as const,

  // A function that returns a list of keys to access supplierProfile by a specific search criteria
  bySearch: (search?: string) =>
    [...supplierProfileKeys.searches(), search] as const,

  // A function that returns a list of keys to access detailed information about supplierProfile
  details: () => [...supplierProfileKeys.all, "details"] as const,

  // A function that returns a list of keys to access supplierProfile by a specific ID
  byId: (id: number) => [...supplierProfileKeys.details(), id] as const,
};

// Define a constant to store the main query keys for supplierStatus
export const supplierStatusKeys = {
  // A list that stores the key for accessing all supplierStatus
  all: ["supplierProfile"] as const,

  // A function that returns a list of keys to access supplierStatus based on a search
  searches: () => [...supplierStatusKeys.all, "search"] as const,

  // A function that returns a list of keys to access supplierStatus by a specific search criteria
  bySearch: (search?: string) =>
    [...supplierStatusKeys.searches(), search] as const,

  // A function that returns a list of keys to access detailed information about supplierStatus
  details: () => [...supplierStatusKeys.all, "details"] as const,

  // A function that returns a list of keys to access supplierStatus by a specific ID
  byId: (id: number) => [...supplierStatusKeys.details(), id] as const,
};

// Define a constant to store the main query keys for WebPaymentHistory
export const webPaymentHistoryKeys = {
  // A list that stores the key for accessing all webPaymentHistoryKeys
  all: ["webPaymentHistoryKeys"] as const,

  byId: (id?: string) => [...webPaymentHistoryKeys.all, id] as const,
};

export const suppliersListQueryKeys = {
  all: ["supplierList"] as const,
  searches: (externalId?: string) =>
    [...suppliersListQueryKeys.all, "search", externalId] as const,
  // A function that returns a list of keys to access imremit dashboards by a specific search criteria
  bySearch: (externalId?: string, module?: string) =>
    [...suppliersListQueryKeys.searches(externalId), module] as const,
};

// Define a constant to store the main query keys for payment cards filter keys
export const paymentManagementCardsFilterKeys = {
  // A list that stores the key for accessing all payment cards filter keys
  all: ["paymentManagementCardsFilterKeys"] as const,

  byCustomerId: (externalId?: string) =>
    [...paymentManagementCardsFilterKeys.all, externalId] as const,

  byMethodType: (externalId?: string, method?: string) =>
    [
      ...paymentManagementCardsFilterKeys.byCustomerId(externalId),
      method,
    ] as const,

  // A function that returns a list of keys to access imremit dashboards by a specific search criteria
  bySearch: (
    externalId?: string,
    methodType?: string,
    searchQueryString?: string
  ) =>
    [
      ...paymentManagementCardsFilterKeys.byMethodType(externalId, methodType),
      searchQueryString,
    ] as const,
};

// Define a constant to store the main query keys for proxyPayStatusList
export const proxyPayStatusListKeys = {
  // A list that stores the key for accessing all proxyPayStatusListKeys
  all: ["proxyPayStatusListKeys"] as const,

  byId: (id?: string) => [...proxyPayStatusListKeys.all, id] as const,
};

//Define a constant to store the main query keys for Reveal card details
export const revealCardDetailsKeys = {
  all: ["revealCardDetailsKeys"] as const,
  byPaymentId: (paymentId?: string) =>
    [...revealCardDetailsKeys.all, "byPaymentId", paymentId] as const,
};

// Define a constant to store the main query keys for paymentMethodName
export const uploadedPDFKeys = {
  // A list that stores the key for accessing all paymentMethodNames
  all: ["uploadedPDFKeys"] as const,

  byCustomerId: (externalId?: string) =>
    [...uploadedPDFKeys.all, externalId] as const,
};
