import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {invoiceQueryKeys, type InvoiceStatusType} from "@/modules/invoices";

// Defining a type based on the function `getAllCustomers`
type QueryFnType = typeof getAllInvoiceStatuses;

// Function to fetch all statuses
export function getAllInvoiceStatuses() {
  // Fetch all statuses from API using ky
  const data = kyApiFn<InvoiceStatusType[]>(`invoice/v1/status`, "get");
  // TODO: validate data
  // return statusSchema.parse(data);
  return data; // Returning the fetched data
}

// Custom hook to fetch and cache all statuses data using react-query
export function useAllnvoiceStatuses(config?: QueryConfig<QueryFnType>) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: invoiceQueryKeys.allInvoices, // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getAllInvoiceStatuses(), // Setting the function to be used for fetching data
    // TODO: add onSuccess and onError handlers
  });
}
