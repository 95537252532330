import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {documentTypesKeys, type DocumentTypesType} from "@/modules/imremit";

//Defining a type based on the function `getAllBankFileMapping`
type QueryFnType = typeof getAllDocmentTypes;

//Funciton to get the bank file reports
export function getAllDocmentTypes() {
  // Fetch data from API using ky
  return kyApiFn<DocumentTypesType[]>(`api/e-payable/document-types`, "get");
}

// Custom hook to fetch and cache documents type data using react-query
export function useDocumentTypesDetails(config?: QueryConfig<QueryFnType>) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: documentTypesKeys.allDocumentFormats(),
    queryFn: () => getAllDocmentTypes(),
  });
}
