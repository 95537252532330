import {useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate, useParams} from "@tanstack/react-router";
import {
  ArrowRightIcon,
  CheckIcon,
  ChevronsUpDownIcon,
  SaveIcon,
  TicketCheckIcon,
} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button, buttonVariants} from "@/components/ui/button";
import {Card, CardContent, CardHeader} from "@/components/ui/card";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {Heading2, Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {LoadingSkeletonCard} from "@/components/craft/loading-skeleton-card";

import {useStatementReconCustomerStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {
  addSRMappingFn,
  StatementMappingFormSchema,
  useSRDateFormats,
  useSRVendorFields,
  useStatementFileJsonById,
} from "@/modules/statement-recon";

export function useStatementMappingData() {
  const {statementReconStoreCustomer} = useStatementReconCustomerStore();

  const vendorFieldNamesQuery = useSRVendorFields();
  const vendorFieldNames = vendorFieldNamesQuery.data?.content || [];

  return {
    statementReconStoreCustomer,
    vendorFieldNames,
  };
}

export function StatementMappingPage() {
  const navigate = useNavigate();

  const {fileId, externalId} = useParams({
    from: "/app/statement-recon/upload/$fileId/mapping/$externalId",
  });

  // Use the custom hook to fetch all necessary data
  const {statementReconStoreCustomer, vendorFieldNames} =
    useStatementMappingData();

  // Define the mutation for creating new file mapping 📝
  const createFileMappingMutation = useMutation({
    // Define the mutation function
    mutationFn: async (data: z.infer<typeof StatementMappingFormSchema>) => {
      const reqData = {
        saved_mapping_name: data.statementMappingName,
        buyer_external_id: externalId,
        date_format_mapping: data.date_format_mapping,
        mapping_detail: {
          sr_saved_mappings: data.mappingDetail.map((detail) => ({
            vendor_field_id: detail.vendorFieldId,
            user_vendor_field_name: detail.userFieldName,
          })),
        },
      };

      console.log("reqData", reqData);

      return addSRMappingFn(reqData);
    },

    // Handle the mutation success 🎉
    onSuccess: (createFileMappingResponse) => {
      console.log("createFileMappingResponse", createFileMappingResponse);

      toast({
        variant: "success",
        title: "Success!",
        description: "Mapping created successfully",
      });

      // Navigate to the reconciliation page 🗺
      void navigate({
        to: "/app/statement-recon/upload/$fileId/reconciliation/$externalId",
        params: {
          fileId: fileId,
          externalId: externalId,
        },
        replace: true,
      });
    },

    // Handle the mutation error 🚨
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to create mapping",
      });
    },
  });

  const fileJsonQuery = useStatementFileJsonById(fileId);
  const {isPending: fileJsonIsPending, isError: fileJsonIsError} =
    fileJsonQuery;
  const fileJson = fileJsonQuery.data;

  //Get all date formats
  const allDateFormatsQuery = useSRDateFormats();

  const allDateFormatsData = useMemo(
    () => allDateFormatsQuery.data?.content || [],
    [allDateFormatsQuery.data?.content]
  );

  // Memoizes the transformed date formats data for performance optimization
  const allDateFormats = useMemo(
    () =>
      allDateFormatsData.map((format) => ({
        label: format.date_format,
        value: format.date_format_id.toString(),
      })),
    [allDateFormatsData]
  );

  const parsedFileJsonData = Object.entries(fileJson?.content.data ?? {});

  const statementConfigFormMethods = useForm<
    z.infer<typeof StatementMappingFormSchema>
  >({
    defaultValues: {
      mappingDetail: [],
    },
    // mode: "onChange",
    resolver: zodResolver(StatementMappingFormSchema),
  });

  const {setValue, setError, watch} = statementConfigFormMethods;

  const {mappingDetail} = watch();

  const [, setSelectedValues] = useState<string[]>([]); // Track selected vendor_field_ids
  // Handle selection change and validation for duplicate selections
  const handleValueChange = (selectValue: string, key: string) => {
    if (selectValue === "ignore") {
      return; // Do nothing if "ignore" is selected
    }

    const currentValues: {
      vendorFieldId: string;
      userFieldName: string;
    }[] = statementConfigFormMethods.getValues().mappingDetail;

    // Check if the same key (userFieldName) already exists in mappingDetail
    const existingMappingIndex = currentValues.findIndex(
      (detail) => detail.userFieldName === key
    );

    // Check if the selected value is already mapped to another key (to prevent duplicate vendorFieldId)
    const isDuplicateVendorField = currentValues.some(
      (detail) =>
        detail.vendorFieldId === selectValue && detail.userFieldName !== key
    );

    if (isDuplicateVendorField) {
      // Set error if a duplicate vendorFieldId is found for a different key
      setError("mappingDetail", {
        type: "manual",
        message: "Vendor Field IDs must be unique",
      });
    } else {
      statementConfigFormMethods.clearErrors("mappingDetail");

      // If the key already exists in mappingDetail, update the vendorFieldId
      if (existingMappingIndex !== -1) {
        currentValues[existingMappingIndex].vendorFieldId = selectValue;
      } else {
        // Otherwise, add the new mapping
        currentValues.push({
          vendorFieldId: selectValue,
          userFieldName: key,
        });
      }

      // Update the form value with the new or updated mapping
      setValue("mappingDetail", [...currentValues]);

      // Update the selectedValues to avoid duplicate vendorFieldId choices
      setSelectedValues((prev) => {
        const updatedValues = prev.filter((id) => id !== selectValue);
        return [...updatedValues, selectValue];
      });
    }
  };

  const handleSubmit = statementConfigFormMethods.handleSubmit(
    (data: z.infer<typeof StatementMappingFormSchema>) => {
      console.log("data", data);
      createFileMappingMutation.mutate(data);
    }
  );

  // Show loading skeleton if customer data is not available
  if (!statementReconStoreCustomer) {
    return void navigate({
      to: "/app/statement-recon/upload",
      replace: true,
    });
  }

  // Show loading skeleton if file data is not available
  if (fileJsonIsPending || fileJsonIsError) {
    return <LoadingSkeletonCard lineCount={5} />;
  }

  return (
    <section className="flex flex-col gap-4">
      <div className="flex justify-between gap-4">
        <div className="flex flex-col">
          <Heading2>Statement Mapping</Heading2>
          <Paragraph>
            This is the statement mapping page. It will be used to map the
            statement.
          </Paragraph>
        </div>
        <Link
          to="/app/statement-recon/upload"
          className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
        >
          Back to upload
          <span className="sr-only">Back to upload</span>
          <ArrowRightIcon className="size-4" />
        </Link>
      </div>

      <Form {...statementConfigFormMethods}>
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader>
              <div className="flex justify-between gap-4">
                <Heading2>Statement Mapping Form</Heading2>
                <div className="flex gap-2">
                  <Button
                    className="gap-2"
                    variant="secondary"
                    onClick={() => {
                      void navigate({
                        to: "/app/statement-recon/upload/$fileId/reconciliation/$externalId",
                        params: {
                          fileId: fileId,
                          externalId: externalId,
                        },
                        replace: true,
                      });
                    }}
                  >
                    <span className="sr-only">Use Existing Mapping</span>
                    Use Existing Mapping
                    <TicketCheckIcon className="size-4" />
                  </Button>
                  {mappingDetail.length > 0 && (
                    <Button type="submit" className="gap-2">
                      <span className="sr-only">Save Mapping</span>
                      Save Mapping
                      <SaveIcon className="size-4" />
                    </Button>
                  )}
                </div>
              </div>
              <div className="flex gap-4">
                <FormField
                  name="statementMappingName"
                  render={() => (
                    <FormItem>
                      <FormLabel
                        htmlFor="statementMappingName"
                        showMandatoryAsterisk
                      >
                        Mapping Name:
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          className="w-[320px]"
                          placeholder="Enter mapping name..."
                          {...statementConfigFormMethods.register(
                            "statementMappingName"
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  name="date_format_mapping"
                  render={({field}) => (
                    <FormItem className="mt-2 flex flex-col">
                      <FormLabel
                        htmlFor="date_format_mapping"
                        showMandatoryAsterisk
                      >
                        Date Format:
                      </FormLabel>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant="outline"
                              role="combobox"
                              className={cn(
                                "w-[320px] justify-between",

                                !field.value && "text-accent-foreground"
                              )}
                            >
                              {field.value
                                ? allDateFormats.find(
                                    (dateFormat) =>
                                      dateFormat.value === field.value
                                  )?.label
                                : "Select Date Format..."}
                              <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                          <Command>
                            <CommandInput placeholder="Search Date Format..." />
                            <CommandEmpty>No Date Formats found.</CommandEmpty>
                            <CommandGroup>
                              {allDateFormats.map((dateFormat) => (
                                <CommandItem
                                  key={dateFormat.value}
                                  value={dateFormat.value}
                                  onSelect={() => {
                                    setValue(
                                      "date_format_mapping",
                                      dateFormat.value
                                    );
                                  }}
                                >
                                  <CheckIcon
                                    className={cn(
                                      "mr-2 size-4",
                                      dateFormat.value.toLowerCase() ===
                                        field.value
                                        ? "opacity-100"
                                        : "opacity-0"
                                    )}
                                  />
                                  {dateFormat.label}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </Command>
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </CardHeader>
            <CardContent>
              <Card>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Column Name</TableHead>
                      <TableHead>Sample Data</TableHead>
                      <TableHead>Vendor Field</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {parsedFileJsonData.map(([key, value]) => (
                      <TableRow key={key}>
                        <TableCell>{key}</TableCell>
                        <TableCell>{value}</TableCell>
                        <TableCell>
                          {/* This is only for the custom error message */}
                          <FormField
                            name="mappingDetail"
                            render={() => (
                              <FormItem>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                          <Select
                            // onValueChange={(selectValue) => {
                            //   if (selectValue !== "ignore") {
                            //     setValue("mappingDetail", [
                            //       ...statementConfigFormMethods.getValues()
                            //         .mappingDetail,
                            //       {
                            //         vendorFieldId: selectValue,
                            //         userFieldName: key,
                            //       },
                            //     ]);
                            //   }
                            // }}

                            onValueChange={(selectValue) => {
                              handleValueChange(selectValue, key);
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue placeholder="Select Vendor Field..." />
                            </SelectTrigger>
                            <SelectContent>
                              {vendorFieldNames.map((vendorField, i) => (
                                <SelectItem
                                  key={i}
                                  value={vendorField.vendor_field_id}
                                >
                                  {vendorField.im_vendor_field_name}
                                </SelectItem>
                              ))}
                              <SelectItem value="ignore">
                                Ignore this field
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>
            </CardContent>
          </Card>
        </form>
      </Form>
    </section>
  );
}
