import {Outlet, useNavigate, useRouter} from "@tanstack/react-router";

import {Separator} from "@/components/ui/separator";
import {Heading1, Paragraph} from "@/components/ui/typography";
import {ReturnToTop} from "@/components/craft/return-to-top";

import {AdminSubrouteNavigation} from "@/modules/admin";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";

export function AdminOutlet() {
  // Get pathname of user's current route
  const {pathname} = useRouter().latestLocation;

  // Get the navigate function from the router
  const navigate = useNavigate();

  if (pathname === "/app/admin/" || pathname === "/app/admin") {
    void navigate({
      to: "/app/admin/user-management",
    });
  }

  return (
    <>
      <article
        className="w-full flex-1 bg-background"
        role="region"
        aria-roledescription="This is the Admin level one subroute. View your customer's Admin data here."
      >
        {/* This section will contain the heading and description, as well as the subroute navigation */}
        <section
          className="mb-2 flex flex-col justify-between gap-4 xl:flex-row xl:items-center"
          role="presentation"
        >
          {/* This div will contain the heading and description */}
          <div className="mb-2 flex flex-col">
            <Heading1>Admin</Heading1> {/* The heading of the page */}
            <Paragraph>
              This is the Admin level one subroute. View your customer's Admin
              data here.
            </Paragraph>{" "}
            {/* The description of the page */}
          </div>
          {/* This will display the navigation for subroutes */}
          <AdminSubrouteNavigation />
        </section>

        {/* Separator component to create a visual break in content */}
        <Separator className="mb-2" />

        {/* Outlet for nested routes to display their content */}
        <AccessPolicyWrapper policyActions={[KeycloakRoleEnum.READ_ADMIN]}>
          <Outlet />
        </AccessPolicyWrapper>
      </article>
      <ReturnToTop />
    </>
  );
}
