// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type ResponseMappingAdminTableColumnsType} from "@/modules/imremit";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface ResponseMappingAdminTableColumnsStoreState {
  storeResponseMappingAdminTableColumns: ResponseMappingAdminTableColumnsType | null;
  setStoreResponseMappingAdminTableColumns: (
    storeResponseMappingAdminTableColumns: ResponseMappingAdminTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for ResponseMappingAdmin table columns
export const useResponseMappingAdminTableColumnsStore = create(
  persist<ResponseMappingAdminTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeResponseMappingAdminTableColumns: null,

      // Getter function to retrieve the current state
      getStoreResponseMappingAdminTableColumns: () =>
        get().storeResponseMappingAdminTableColumns,

      // Setter function to update the state
      setStoreResponseMappingAdminTableColumns: (
        storeResponseMappingAdminTableColumns
      ) => {
        set(() => ({storeResponseMappingAdminTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "ResponseMappingAdmin-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useResponseMappingAdminTableColumns = () =>
  useResponseMappingAdminTableColumnsStore((state) => {
    return {
      storeResponseMappingAdminTableColumns:
        state.storeResponseMappingAdminTableColumns,
    };
  });
