// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type BankMappingAdminTableColumnsType} from "@/modules/imremit";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface BankMappingAdminTableColumnsStoreState {
  storeBankMappingAdminTableColumns: BankMappingAdminTableColumnsType | null;
  setStoreBankMappingAdminTableColumns: (
    storeBankMappingAdminTableColumns: BankMappingAdminTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for BankMappingAdmin table columns
export const useBankMappingAdminTableColumnsStore = create(
  persist<BankMappingAdminTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeBankMappingAdminTableColumns: null,

      // Getter function to retrieve the current state
      getStoreBankMappingAdminTableColumns: () =>
        get().storeBankMappingAdminTableColumns,

      // Setter function to update the state
      setStoreBankMappingAdminTableColumns: (
        storeBankMappingAdminTableColumns
      ) => {
        set(() => ({storeBankMappingAdminTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "BankMappingAdmin-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useBankMappingAdminTableColumns = () =>
  useBankMappingAdminTableColumnsStore((state) => {
    return {
      storeBankMappingAdminTableColumns:
        state.storeBankMappingAdminTableColumns,
    };
  });
