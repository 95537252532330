import {z} from "zod";

export const srDateFormatSchema = z.object({
  date_format_id: z.number(),
  format_name: z.string(),
  date_format: z.string(),
  description: z.string(),
  example_date: z.string(),
  created_at: z.date(),
});

export type srDateFormatSchemaType = z.infer<typeof srDateFormatSchema>;
