// Constants for query keys related to customer data management 🧑‍💼
export const customerQueryKeys = {
  // Key for retrieving the complete list of customers, serving as a foundational query key
  allCustomers: ["customers"] as const, // 📋

  // Function to generate query keys for customer search operations, extending 'allCustomers' with 'searchQuery'
  searchQuery: () =>
    [...customerQueryKeys.allCustomers, "searchQuery"] as const, // 🔍

  // Function to create query keys for fetching customers based on specific search terms, utilizing 'searchQuery' for dynamic searches
  bySearch: (search?: string) =>
    [...customerQueryKeys.searchQuery(), search] as const, // 🕵️

  // Function to generate query keys for accessing detailed customer information, appending 'detailsById' to the 'allCustomers' key
  detailsById: () =>
    [...customerQueryKeys.allCustomers, "detailsById"] as const, // 📄

  // Function to create query keys for retrieving customer details by an external identifier, extending 'detailsById'
  byExternalId: (externalId: string) =>
    [...customerQueryKeys.detailsById(), externalId] as const, // 🔖
};

export const infiniteCustomersQueryKeys = {
  infiniteCustomers: ["infinite-customers"] as const,
  byPage: (page: number) =>
    [...infiniteCustomersQueryKeys.infiniteCustomers, page] as const,
};

export const filteredCustomersQueryKeys = {
  filteredCustomers: ["filtered-customers"] as const,
  byName: (customerName?: string) =>
    [...filteredCustomersQueryKeys.filteredCustomers, customerName] as const,
  byModuleName: (moduleName: string) =>
    [...filteredCustomersQueryKeys.filteredCustomers, moduleName] as const,
  byNameAndModuleName: (moduleName: string, customerName?: string) =>
    [
      ...filteredCustomersQueryKeys.filteredCustomers,
      moduleName,
      customerName,
    ] as const,
};

export const customersByNameQueryKeys = {
  customersByName: ["customers-by-name"] as const,
  byName: (customerName?: string) =>
    [...customersByNameQueryKeys.customersByName, customerName] as const,
};

export const customersBySupplierQueryKeys = {
  // Key for accessing the list of all imRemit premium customers, serving as the base for further specific queries
  customersBySupplier: ["customers-by-supplier"] as const, // 📋

  // Function to generate query keys for accessing detailed information about imRemit premium customers, appending 'detailsById' to the base key
  detailsById: () =>
    [
      ...customersBySupplierQueryKeys.customersBySupplier,
      "detailsById",
    ] as const, // 📄

  // Function to create query keys for retrieving detailed information about a specific premium customer by their ID, extending 'detailsById'
  byId: (supplierId: string) =>
    [...customersBySupplierQueryKeys.detailsById(), supplierId] as const, // 🆔
};

// Constants for query keys related to modules assigned to customers 📦
export const customerModulesQueryKeys = {
  // Key for accessing the list of all customer modules, acting as a base for module-specific queries
  allCustomerModules: ["customerModules"] as const, // 🧩
};

export const customerOnboardingStatusKeys = {
  all: ["customerOnboardingStatus"] as const,
  byCustomerId: (externalId: string) =>
    [...customerOnboardingStatusKeys.all, "byCustomerId", externalId] as const,
};

export const batchProcessingFlagQueryKeys = {
  // Key for retrieving the complete batch process flag, serving as a foundational query key
  all: ["batchProcessingFlag"] as const, // 📋

  // Function to create query keys for retrieving batch process flag by externalId and supplierId
  byExternalIdAndSupplierId: (externalId: string, supplierId: string) =>
    [
      "batchProcessingFlag",
      "byExternalId",
      externalId,
      "bySupplierId",
      supplierId,
    ] as const,
};
