import {useQuery} from "@tanstack/react-query";

import {keycloakKyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  keycloakGroupRolesQueryKeys,
  type KeycloakGroupRolesType,
} from "@/modules/admin";

// Defining a type based on the function `getGroupRolesById`
type QueryFnType = typeof getGroupRolesById;

// Function to get all realm user Roles
export function getGroupRolesById(groupId: string) {
  const realm =
    (import.meta.env.VITE_KEYCLOAK_REALM as string | undefined) ?? "dev";
  return keycloakKyApiFn<KeycloakGroupRolesType>(
    `admin/realms/${realm}/groups/${groupId}/role-mappings`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useGetGroupRolesById(
  groupId: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: keycloakGroupRolesQueryKeys.byGroupId(groupId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getGroupRolesById(groupId), // Setting the function to be used for fetching data
    enabled: !!groupId, // Setting the condition to enable the query
  });
}
