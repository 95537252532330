import * as React from "react";

import type {DialogProps} from "@radix-ui/react-dialog";
import {Command as CommandPrimitive} from "cmdk";

import {Dialog, DialogContent} from "@/components/ui/dialog";

import {cn} from "@/lib/utils";

interface CommandInputFuzzyProps
  extends React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> {
  setSearch?: (search: string) => void;
}

const CommandFuzzy = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({className, ...props}, ref) => (
  <CommandPrimitive
    ref={ref}
    className={cn(
      "flex h-full w-full flex-col overflow-hidden rounded-md bg-root/80 font-sans text-foreground backdrop-blur-sm",
      className
    )}
    {...props}
  />
));
CommandFuzzy.displayName = CommandPrimitive.displayName;

type CommandDialogProps = DialogProps;

const CommandDialogFuzzy = ({children, ...props}: CommandDialogProps) => {
  return (
    <Dialog {...props}>
      <DialogContent className="overflow-hidden p-0 shadow-2xl">
        <CommandFuzzy className="[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-accent-foreground [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5">
          {children}
        </CommandFuzzy>
      </DialogContent>
    </Dialog>
  );
};

const CustomInput = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>((props, ref) => <input ref={ref} {...props} />);

CustomInput.displayName = "CustomInput";

const CommandInputFuzzy = React.forwardRef<
  HTMLInputElement,
  CommandInputFuzzyProps
>(({className, setSearch, ...props}, ref) => (
  <div className="flex items-center border-b px-3">
    <CustomInput
      ref={ref}
      className={cn(
        "flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-accent-foreground disabled:cursor-not-allowed disabled:opacity-50",
        className
      )}
      onChange={(e) => {
        if (setSearch) {
          setSearch(e.currentTarget.value);
        }
      }}
      {...props}
    />
  </div>
));

CommandInputFuzzy.displayName = CommandPrimitive.Input.displayName;

const CommandListFuzzy = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({className, ...props}, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cn("max-h-[300px] overflow-y-auto overflow-x-hidden", className)}
    {...props}
  />
));

CommandListFuzzy.displayName = CommandPrimitive.List.displayName;

const CommandEmptyFuzzy = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
  <CommandPrimitive.Empty
    ref={ref}
    className="py-6 text-center text-sm"
    {...props}
  />
));

CommandEmptyFuzzy.displayName = CommandPrimitive.Empty.displayName;

const CommandGroupFuzzy = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({className, ...props}, ref) => (
  <CommandPrimitive.Group
    ref={ref}
    className={cn(
      "max-h-64 overflow-y-auto overflow-x-hidden p-1 text-foreground [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-accent-foreground",
      className
    )}
    {...props}
  />
));

CommandGroupFuzzy.displayName = CommandPrimitive.Group.displayName;

const CommandSeparatorFuzzy = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({className, ...props}, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={cn("-mx-1 h-px bg-border", className)}
    {...props}
  />
));
CommandSeparatorFuzzy.displayName = CommandPrimitive.Separator.displayName;

const CommandItemFuzzy = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({className, ...props}, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cn(
      "relative flex cursor-pointer select-none items-center rounded-sm px-2 py-1.5 text-sm outline-none aria-selected:bg-accent aria-selected:text-accent-foreground data-[disabled]:pointer-events-none data-[disabled]:opacity-50",
      className
    )}
    {...props}
  />
));

CommandItemFuzzy.displayName = CommandPrimitive.Item.displayName;

const CommandShortcutFuzzy = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={cn(
        "ml-auto text-xs tracking-widest text-accent-foreground",
        className
      )}
      {...props}
    />
  );
};
CommandShortcutFuzzy.displayName = "CommandShortcut";

export {
  CommandFuzzy,
  CommandDialogFuzzy,
  CommandInputFuzzy,
  CommandListFuzzy,
  CommandEmptyFuzzy,
  CommandGroupFuzzy,
  CommandItemFuzzy,
  CommandShortcutFuzzy,
  CommandSeparatorFuzzy,
};
