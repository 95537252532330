import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {formatAmount, GetKeycloakUserName} from "@/modules/admin";
import {
  ProxyPayRowActions,
  RenderCustomerDescription,
  RenderCustomerStatus,
  renderRemittanceBadge,
  type ProxyPaySchemaType,
} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";

export function useGetProxypayColumns() {
  // Get the store functions for sorting PaymentManagement

  const PMColumns: ColumnDef<ProxyPaySchemaType>[] = [
    {
      accessorKey: "orgId",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Organization ID" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("orgId") ? (
            row.getValue("orgId")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "supplierName",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Supplier Name" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("supplierName") ? (
            row.getValue("supplierName")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "supplierNumber",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Supplier ID" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("supplierNumber") ? (
            row.getValue("supplierNumber")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "facilityName",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Facility Name" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("facilityName") ? (
            row.getValue("facilityName")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "paymentNumber",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Payment Number" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("paymentNumber") ? (
            row.getValue("paymentNumber")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "remittanceMethod",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Remittance Method" />
      ),
      cell: ({row}) => {
        if (!row.original.remittanceMethod) {
          return <Paragraph>{FallbackMessages.NO_DATA}</Paragraph>;
        }

        return renderRemittanceBadge(row.original.remittanceMethod);
      },
    },

    {
      accessorKey: "totalAmountSent",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Sent Amount" />
      ),
      cell: ({row}) => {
        const amountValue = row.original.totalAmountSent;

        // Check if empty and return "no data" if empty
        const formattedAmount = amountValue ? (
          formatAmount(amountValue)
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );

        return <Paragraph>{formattedAmount}</Paragraph>;
      },
    },
    {
      accessorKey: "initiatedDate",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Sent Date" />
      ),
      cell: ({row}) => {
        return (
          <Paragraph>
            {row.original.initiatedDate ? (
              row.original.initiatedDate
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>
        );
      },
    },

    {
      accessorKey: "userId",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Agent Name" />
      ),
      cell: ({row}) => {
        const userId = row.original.userId;

        if (!userId) {
          return (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          );
        }

        return <GetKeycloakUserName userId={userId} />;
      },
    },
    {
      accessorKey: "customerStatus",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),
      cell: ({row}) => {
        if (!row.original.customerStatus) {
          return (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          );
        }

        return RenderCustomerStatus(row.original.customerStatus);
      },
    },
    {
      accessorKey: "description",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Status Description" />
      ),
      cell: ({row}) => {
        if (!row.original.description) {
          return (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          );
        }

        return RenderCustomerDescription(
          row.original.customerStatus || "",
          row.original.description
        );
      },
    },
    {
      id: "actions",
      header: () => <Paragraph className="text-right">Actions</Paragraph>,
      cell: ({row}) => (
        <div className="flex w-[80] justify-end">
          <ProxyPayRowActions
            paymentId={row.original.paymentDetailId.toString()}
          />
        </div>
      ),
    },
  ];

  return PMColumns;
}
