import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {Heading1} from "@/components/ui/typography";
import {HomeButton} from "@/components/layout/home-button";

import {privacyPolicyItems} from "@/modules/landing";

// Creating a functional component named 'PrivacyPolicyPage'
export function PrivacyPolicyPage() {
  return (
    <article
      className="flex flex-1 flex-col p-8"
      role="region"
      aria-roledescription="This is the Privacy Policy page. View the privacy policy of using this website here."
    >
      <header className="mb-4 flex w-full justify-between gap-x-6">
        <Heading1>Privacy Policy</Heading1>
        <HomeButton />
      </header>
      <section className="flex flex-1 flex-col" role="presentation">
        <Accordion className="rounded-md bg-root p-4 shadow-md" type="multiple">
          {privacyPolicyItems.map((item, index) => (
            <AccordionItem key={`item-${index}`} value={`item-${index}`}>
              <AccordionTrigger>{item.title}</AccordionTrigger>
              <AccordionContent>{item.content}</AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </section>
    </article>
  );
}
