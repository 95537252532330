import {z} from "zod";

export const dupesRunHistorySchema = z.object({
  id: z.string(),
  buyer_external_id: z.string(),
  cutoff_percent: z.number(),
  run_months: z.number(),
  run_type: z.string(),
  run_id: z.string(),
  run_date: z.string(),
  start_time: z.string(),
  end_time: z.string(),
  total_time: z.string().nullable(),
  cluster_id: z.string().nullable().optional(),
  comparison_records: z.string(),
  notes: z.string(),
  initial_run_start_date: z.date().nullable(),
  initial_run_end_date: z.date().nullable(),
});

export type DupesRunHistoryType = z.infer<typeof dupesRunHistorySchema>;
