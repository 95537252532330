import {useEffect, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {FileSlidersIcon, HelpCircleIcon, SquarePenIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Badge} from "@/components/ui/badge";
import {Button} from "@/components/ui/button";
import {Card, CardContent, CardHeader} from "@/components/ui/card";
import {Checkbox} from "@/components/ui/checkbox";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {Input} from "@/components/ui/input";
import {Skeleton} from "@/components/ui/skeleton";
import {Spinner} from "@/components/ui/spinner";
import {Textarea} from "@/components/ui/textarea";
import {Heading2, Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {DatePicker} from "@/components/craft/date-pickers";

// import {LoadingSkeletonCard} from "@/components/craft/loading-skeleton-card";

import {
  createDupesCustomerSettings,
  dupesPaymentSettingsSchema,
  updateDupesCustomerSettings,
  useAllDupesCustomerSettings,
  type DupesCustomerSettingsType,
} from "@/modules/duplicate-payments";

import {FallbackMessages} from "@/utils/constants";

interface DupesDashboardPaymentSettingsProps {
  externalId: string;
}

export function DupesDashboardPaymentSettings({
  externalId,
}: DupesDashboardPaymentSettingsProps) {
  const [createFormDialogOpen, setCreateFormDialogOpen] = useState(false);
  const [editFormDialogOpen, setEditFormDialogOpen] = useState(false);
  const customerSettingsQuery = useAllDupesCustomerSettings(externalId);

  const createDupesCustomerSettingsMutation = useMutation({
    mutationFn: (data: z.infer<typeof dupesPaymentSettingsSchema>) => {
      return createDupesCustomerSettings(data);
    },
    onSuccess: (createDupesCustomerSettingsResponse) => {
      console.info(
        "createDupesCustomerSettingsResponse",
        createDupesCustomerSettingsResponse
      );
      // Close the form dialog
      setCreateFormDialogOpen(false);

      // Display a success toast
      toast({
        variant: "success",
        title: "Success!",
        description: "Duplicate payments settings added successfully",
      });
    },
    onError: (error) => {
      console.error("error", error);

      // Display an error toast
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add duplicate payments settings",
      });
    },
  });

  const editDupesCustomerSettingsMutation = useMutation({
    mutationFn: (data: z.infer<typeof dupesPaymentSettingsSchema>) => {
      return updateDupesCustomerSettings(externalId, data);
    },
    onSuccess: (editDupesCustomerSettingsResponse) => {
      console.info(
        "editDupesCustomerSettingsResponse",
        editDupesCustomerSettingsResponse
      );
      // Close the form dialog
      setEditFormDialogOpen(false);

      // Display a success toast
      toast({
        variant: "success",
        title: "Success!",
        description: "Duplicate payments settings updated successfully",
      });
    },
    onError: (error) => {
      console.error("error", error);

      // Display an error toast
      toast({
        variant: "destructive",
        title: "ERROR!",
        description: "Failed to update duplicate payments settings",
      });
    },
  });

  const createCustomerSettingsForm = useForm<DupesCustomerSettingsType>({
    // defaultValues: customerSettingsQuery.data?.content,
    resolver: zodResolver(dupesPaymentSettingsSchema),
    defaultValues: {
      block_likely: false,
      block_maybe: false,
      block_different_vendor_likely: false,
      block_different_vendor_maybe: false,
    },
  });

  const editCustomerSettingsForm = useForm<DupesCustomerSettingsType>({
    // defaultValues: customerSettingsQuery.data?.content,
    resolver: zodResolver(dupesPaymentSettingsSchema),
    defaultValues: {
      block_likely: false,
      block_maybe: false,
      block_different_vendor_likely: false,
      block_different_vendor_maybe: false,
    },
  });

  // Reset the form when the data is fetched
  useEffect(() => {
    if (customerSettingsQuery.isSuccess) {
      editCustomerSettingsForm.reset(customerSettingsQuery.data.content);
    }
  }, [
    customerSettingsQuery.isSuccess,
    customerSettingsQuery.data?.content,
    editCustomerSettingsForm,
  ]);

  const createFormFields = createCustomerSettingsForm.watch();
  const editFormFields = editCustomerSettingsForm.watch();

  if (
    customerSettingsQuery.data?.content &&
    !Object.keys(customerSettingsQuery.data.content).length
  ) {
    return (
      <Card>
        <CardHeader className="flex-row justify-between">
          <Heading2>Duplicate Payments Settings</Heading2>
          <Dialog
            open={createFormDialogOpen}
            onOpenChange={setCreateFormDialogOpen}
          >
            {/* <DialogTrigger asChild>
              <Button className="gap-2" disabled>
                Add Settings
                <span className="sr-only">Add Duplicate Payments Settings</span>
                <FileSlidersIcon className="size-4" />
              </Button>
            </DialogTrigger> */}
            <DialogContent className="sm:min-w-[640px] md:min-w-[768px] lg:min-w-[1024px]">
              <DialogHeader>
                <DialogTitle>Add Duplicate Payments Settings</DialogTitle>
                <DialogDescription>
                  Add the settings for duplicate payments here.
                </DialogDescription>
                <DialogClose />
              </DialogHeader>
              <Form {...createCustomerSettingsForm}>
                <form
                  className="flex flex-col gap-4"
                  onSubmit={createCustomerSettingsForm.handleSubmit((data) => {
                    createDupesCustomerSettingsMutation.mutate(data);
                  })}
                >
                  <FormField
                    name="initial_run_cutoff_percent"
                    render={() => (
                      <FormItem>
                        <FormLabel
                          htmlFor="initial_run_cutoff_percent"
                          showMandatoryAsterisk
                        >
                          Run cutoff percentage:
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="number"
                            placeholder="Enter cutoff percent..."
                            disabled={
                              createDupesCustomerSettingsMutation.isPending
                            }
                            aria-disabled={
                              createDupesCustomerSettingsMutation.isPending
                            }
                            {...createCustomerSettingsForm.register(
                              "initial_run_cutoff_percent",
                              {
                                setValueAs: (v) =>
                                  v === ""
                                    ? undefined
                                    : parseInt(v as string, 10),
                              }
                            )}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />

                  <div className="flex w-full gap-4">
                    <FormField
                      name="initial_run_start_date"
                      render={({field}) => (
                        <FormItem className="flex w-full flex-col">
                          <FormLabel
                            htmlFor="initial_run_start_date"
                            showMandatoryAsterisk
                          >
                            Run start date:
                          </FormLabel>
                          <DatePicker
                            {...field}
                            placeholder="Run start date"
                            value={
                              field.value
                                ? new Date(field.value as string)
                                : undefined
                            }
                            disabled={
                              createFormFields.initial_run_end_date
                                ? {
                                    before: new Date("1970-01-01"),
                                    after:
                                      createFormFields.initial_run_end_date,
                                  }
                                : undefined
                            }
                          />
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      name="initial_run_end_date"
                      render={({field}) => (
                        <FormItem className="flex w-full flex-col">
                          <FormLabel
                            htmlFor="initial_run_end_date"
                            showMandatoryAsterisk
                          >
                            Run end date:
                          </FormLabel>
                          <DatePicker
                            {...field}
                            placeholder="Run end Date"
                            value={
                              field.value
                                ? new Date(field.value as string)
                                : undefined
                            }
                            disabled={
                              createFormFields.initial_run_start_date
                                ? {
                                    before:
                                      createFormFields.initial_run_start_date,
                                    after: new Date("9999-01-01"),
                                  }
                                : undefined
                            }
                          />
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="flex w-full gap-4">
                    <FormField
                      name="initial_run_months"
                      render={() => (
                        <FormItem className="flex w-full flex-col">
                          <FormLabel
                            htmlFor="initial_run_months"
                            showMandatoryAsterisk
                          >
                            Run months:
                          </FormLabel>
                          <FormControl>
                            <Input
                              type="number"
                              placeholder="Enter run months..."
                              disabled={
                                createDupesCustomerSettingsMutation.isPending
                              }
                              aria-disabled={
                                createDupesCustomerSettingsMutation.isPending
                              }
                              {...editCustomerSettingsForm.register(
                                "initial_run_months",
                                {
                                  setValueAs: (v) =>
                                    v === ""
                                      ? undefined
                                      : parseInt(v as string, 10),
                                }
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      name="initial_run_subdivisions"
                      render={() => (
                        <FormItem className="flex w-full flex-col">
                          <FormLabel
                            htmlFor="initial_run_subdivisions"
                            showMandatoryAsterisk
                          >
                            Run subdivisions:
                          </FormLabel>
                          <FormControl>
                            <Input
                              type="number"
                              placeholder="Enter run subdivisions..."
                              disabled={
                                createDupesCustomerSettingsMutation.isPending
                              }
                              aria-disabled={
                                createDupesCustomerSettingsMutation.isPending
                              }
                              {...editCustomerSettingsForm.register(
                                "initial_run_subdivisions",
                                {
                                  setValueAs: (v) =>
                                    v === ""
                                      ? undefined
                                      : parseInt(v as string, 10),
                                }
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <FormField
                      name="block_likely"
                      render={({field}) => (
                        <FormItem className="flex flex-col">
                          <FormLabel htmlFor="block_likely">
                            Block Likely Payments:
                          </FormLabel>
                          <FormControl>
                            <Checkbox
                              checked={field.value as boolean}
                              disabled={
                                createDupesCustomerSettingsMutation.isPending
                              }
                              aria-disabled={
                                createDupesCustomerSettingsMutation.isPending
                              }
                              onCheckedChange={field.onChange}
                              {...createCustomerSettingsForm.register(
                                "block_likely"
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      name="block_maybe"
                      render={({field}) => (
                        <FormItem className="flex flex-col">
                          <FormLabel htmlFor="block_maybe">
                            Block Maybe Payments:
                          </FormLabel>
                          <FormControl>
                            <Checkbox
                              checked={field.value as boolean}
                              disabled={
                                createDupesCustomerSettingsMutation.isPending
                              }
                              aria-disabled={
                                createDupesCustomerSettingsMutation.isPending
                              }
                              onCheckedChange={field.onChange}
                              {...createCustomerSettingsForm.register(
                                "block_maybe"
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      name="block_different_vendor_likely"
                      render={({field}) => (
                        <FormItem className="flex flex-col">
                          <FormLabel htmlFor="block_different_vendor_likely">
                            Block Different Supplier Likely Payments:
                          </FormLabel>
                          <FormControl>
                            <Checkbox
                              checked={field.value as boolean}
                              disabled={
                                createDupesCustomerSettingsMutation.isPending
                              }
                              aria-disabled={
                                createDupesCustomerSettingsMutation.isPending
                              }
                              onCheckedChange={field.onChange}
                              {...createCustomerSettingsForm.register(
                                "block_different_vendor_likely"
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      name="block_different_vendor_maybe"
                      render={({field}) => (
                        <FormItem className="flex flex-col">
                          <FormLabel htmlFor="block_different_vendor_maybe">
                            Block Different Supplier Maybe Payments:
                          </FormLabel>
                          <FormControl>
                            <Checkbox
                              checked={field.value as boolean}
                              disabled={
                                createDupesCustomerSettingsMutation.isPending
                              }
                              aria-disabled={
                                createDupesCustomerSettingsMutation.isPending
                              }
                              onCheckedChange={field.onChange}
                              {...createCustomerSettingsForm.register(
                                "block_different_vendor_maybe"
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <FormField
                    name={`notes`}
                    render={() => (
                      <FormItem className="flex flex-col">
                        <FormLabel htmlFor="notes">Add Notes:</FormLabel>
                        <FormControl>
                          <Textarea
                            disabled={
                              createDupesCustomerSettingsMutation.isPending
                            }
                            aria-disabled={
                              createDupesCustomerSettingsMutation.isPending
                            }
                            {...createCustomerSettingsForm.register(`notes`)}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <DialogFooter className="pt-4">
                    <Button
                      type="submit"
                      className="gap-2"
                      disabled={
                        createCustomerSettingsForm.formState.isSubmitting
                      }
                    >
                      Submit Settings
                      {createDupesCustomerSettingsMutation.isPending ? (
                        <Spinner size="xs" />
                      ) : (
                        <SquarePenIcon className="size-4" />
                      )}
                    </Button>
                  </DialogFooter>
                </form>
              </Form>
            </DialogContent>
          </Dialog>
        </CardHeader>
        <CardContent>
          <Paragraph>
            Duplicate payment settings not yet configured for this customer.
            Please contact the administrator to configure the settings.
          </Paragraph>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card>
      <CardHeader>
        <div className="flex flex-col justify-end gap-4 lg:flex-row lg:items-center lg:justify-between">
          <Heading2>Duplicate Payments Settings</Heading2>
          <Dialog
            open={editFormDialogOpen}
            onOpenChange={setEditFormDialogOpen}
          >
            <DialogTrigger asChild>
              <Button className="gap-2">
                Edit Settings
                <span className="sr-only">
                  Edit Duplicate Payments Settings
                </span>
                <FileSlidersIcon className="size-4" />
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:min-w-[640px] md:min-w-[768px] lg:min-w-[1024px]">
              <DialogHeader>
                <DialogTitle>Edit Duplicate Payments Settings</DialogTitle>
                <DialogDescription>
                  Edit the settings for duplicate payments here.
                </DialogDescription>
                <DialogClose />
              </DialogHeader>
              <Form {...editCustomerSettingsForm}>
                <form
                  className="flex flex-col gap-4"
                  onSubmit={editCustomerSettingsForm.handleSubmit((data) => {
                    editDupesCustomerSettingsMutation.mutate(data);
                  })}
                >
                  <div className="flex w-full gap-4">
                    <FormField
                      name="initial_run_cutoff_percent"
                      render={() => (
                        <FormItem className="flex w-full flex-col">
                          <FormLabel
                            htmlFor="initial_run_cutoff_percent"
                            showMandatoryAsterisk
                          >
                            Run cutoff percentage:
                          </FormLabel>
                          <FormControl>
                            <Input
                              type="number"
                              placeholder="Enter cutoff percent..."
                              disabled={
                                editDupesCustomerSettingsMutation.isPending
                              }
                              aria-disabled={
                                editDupesCustomerSettingsMutation.isPending
                              }
                              {...editCustomerSettingsForm.register(
                                "initial_run_cutoff_percent",
                                {
                                  setValueAs: (v) =>
                                    v === ""
                                      ? undefined
                                      : parseInt(v as string, 10),
                                }
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />

                    <FormField
                      name="field_value"
                      render={() => (
                        <FormItem className="flex w-full flex-col">
                          <FormLabel htmlFor="field_value">
                            Field Value:
                          </FormLabel>
                          <FormControl>
                            <Input
                              type="number"
                              placeholder="Enter field value..."
                              disabled={
                                createDupesCustomerSettingsMutation.isPending
                              }
                              aria-disabled={
                                createDupesCustomerSettingsMutation.isPending
                              }
                              {...editCustomerSettingsForm.register(
                                "field_value",
                                {
                                  setValueAs: (v) =>
                                    v === ""
                                      ? undefined
                                      : parseInt(v as string, 10),
                                }
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="flex w-full gap-4">
                    <FormField
                      name="initial_run_start_date"
                      render={({field}) => (
                        <FormItem className="flex w-full flex-col">
                          <div className="flex w-full flex-row items-center justify-between ">
                            <FormLabel htmlFor="initial_run_start_date">
                              Run start date:
                            </FormLabel>
                            <HoverCard openDelay={75} closeDelay={125}>
                              <HoverCardTrigger asChild>
                                <Button
                                  className="pl-0 pr-0"
                                  variant="link"
                                  disabled
                                >
                                  <HelpCircleIcon className="size-4 text-theme" />
                                </Button>
                              </HoverCardTrigger>
                              <HoverCardContent className="max-w-prose translate-x-[-1.5rem]">
                                <Paragraph>
                                  The date from which the runner{" "}
                                  <span className="font-bold">starts</span>
                                </Paragraph>
                              </HoverCardContent>
                            </HoverCard>
                          </div>
                          <DatePicker
                            {...field}
                            placeholder="Run start date"
                            value={
                              field.value
                                ? new Date(field.value as string)
                                : undefined
                            }
                            disabled={
                              editFormFields.initial_run_end_date
                                ? {
                                    before: new Date("1970-01-01"),
                                    after: editFormFields.initial_run_end_date,
                                  }
                                : undefined
                            }
                          />
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      name="initial_run_end_date"
                      render={({field}) => (
                        <FormItem className="flex w-full flex-col">
                          <div className="flex w-full flex-row items-center justify-between ">
                            <FormLabel htmlFor="initial_run_end_date">
                              Run end date:
                            </FormLabel>
                            <HoverCard openDelay={75} closeDelay={125}>
                              <HoverCardTrigger asChild>
                                <Button
                                  className="pl-0 pr-0"
                                  variant="link"
                                  disabled
                                >
                                  <HelpCircleIcon className="size-4 text-theme" />
                                </Button>
                              </HoverCardTrigger>
                              <HoverCardContent className="max-w-prose translate-x-[-1.5rem]">
                                <Paragraph>
                                  The date on which the runner{" "}
                                  <span className="font-bold">ends</span>
                                </Paragraph>
                              </HoverCardContent>
                            </HoverCard>
                          </div>
                          <DatePicker
                            {...field}
                            placeholder="Run end Date"
                            value={
                              field.value
                                ? new Date(field.value as string)
                                : undefined
                            }
                            disabled={
                              editFormFields.initial_run_start_date
                                ? {
                                    before:
                                      editFormFields.initial_run_start_date,
                                    after: new Date("9999-01-01"),
                                  }
                                : undefined
                            }
                          />
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="flex w-full gap-4">
                    <FormField
                      name="initial_run_months"
                      render={() => (
                        <FormItem className="flex w-full flex-col">
                          <FormLabel
                            htmlFor="initial_run_months"
                            showMandatoryAsterisk
                          >
                            Run months:
                          </FormLabel>
                          <FormControl>
                            <Input
                              type="number"
                              placeholder="Enter run months..."
                              disabled={
                                editDupesCustomerSettingsMutation.isPending
                              }
                              aria-disabled={
                                editDupesCustomerSettingsMutation.isPending
                              }
                              {...editCustomerSettingsForm.register(
                                "initial_run_months",
                                {
                                  setValueAs: (v) =>
                                    v === ""
                                      ? undefined
                                      : parseInt(v as string, 10),
                                }
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      name="initial_run_subdivisions"
                      render={() => (
                        <FormItem className="flex w-full flex-col">
                          <FormLabel
                            htmlFor="initial_run_subdivisions"
                            showMandatoryAsterisk
                          >
                            Run subdivisions:
                          </FormLabel>
                          <FormControl>
                            <Input
                              type="number"
                              placeholder="Enter run subdivisions..."
                              disabled={
                                editDupesCustomerSettingsMutation.isPending
                              }
                              aria-disabled={
                                editDupesCustomerSettingsMutation.isPending
                              }
                              {...editCustomerSettingsForm.register(
                                "initial_run_subdivisions",
                                {
                                  setValueAs: (v) =>
                                    v === ""
                                      ? undefined
                                      : parseInt(v as string, 10),
                                }
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                    <FormField
                      name="block_likely"
                      render={({field}) => (
                        <FormItem className="flex flex-col">
                          <FormLabel htmlFor="block_likely">
                            Block Likely Payments:
                          </FormLabel>
                          <FormControl>
                            <Checkbox
                              checked={field.value as boolean}
                              disabled={
                                editDupesCustomerSettingsMutation.isPending
                              }
                              aria-disabled={
                                editDupesCustomerSettingsMutation.isPending
                              }
                              onCheckedChange={field.onChange}
                              {...editCustomerSettingsForm.register(
                                "block_likely"
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      name="block_maybe"
                      render={({field}) => (
                        <FormItem className="flex flex-col">
                          <FormLabel htmlFor="block_maybe">
                            Block Maybe Payments:
                          </FormLabel>
                          <FormControl>
                            <Checkbox
                              checked={field.value as boolean}
                              disabled={
                                editDupesCustomerSettingsMutation.isPending
                              }
                              aria-disabled={
                                editDupesCustomerSettingsMutation.isPending
                              }
                              onCheckedChange={field.onChange}
                              {...editCustomerSettingsForm.register(
                                "block_maybe"
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      name="block_different_vendor_likely"
                      render={({field}) => (
                        <FormItem className="flex flex-col">
                          <FormLabel htmlFor="block_different_vendor_likely">
                            Block Different Supplier Likely Payments:
                          </FormLabel>
                          <FormControl>
                            <Checkbox
                              checked={field.value as boolean}
                              disabled={
                                editDupesCustomerSettingsMutation.isPending
                              }
                              aria-disabled={
                                editDupesCustomerSettingsMutation.isPending
                              }
                              onCheckedChange={field.onChange}
                              {...editCustomerSettingsForm.register(
                                "block_different_vendor_likely"
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <FormField
                      name="block_different_vendor_maybe"
                      render={({field}) => (
                        <FormItem className="flex flex-col">
                          <FormLabel htmlFor="block_different_vendor_maybe">
                            Block Different Supplier Maybe Payments:
                          </FormLabel>
                          <FormControl>
                            <Checkbox
                              checked={field.value as boolean}
                              disabled={
                                editDupesCustomerSettingsMutation.isPending
                              }
                              aria-disabled={
                                editDupesCustomerSettingsMutation.isPending
                              }
                              onCheckedChange={field.onChange}
                              {...editCustomerSettingsForm.register(
                                "block_different_vendor_maybe"
                              )}
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <FormField
                    name={`notes`}
                    render={() => (
                      <FormItem className="flex flex-col">
                        <FormLabel htmlFor="notes">Add Notes:</FormLabel>
                        <FormControl>
                          <Textarea
                            disabled={
                              editDupesCustomerSettingsMutation.isPending
                            }
                            aria-disabled={
                              editDupesCustomerSettingsMutation.isPending
                            }
                            {...editCustomerSettingsForm.register(`notes`)}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <DialogFooter className="pt-4">
                    <Button
                      type="submit"
                      className="gap-2"
                      disabled={editCustomerSettingsForm.formState.isSubmitting}
                    >
                      Submit Settings
                      {editDupesCustomerSettingsMutation.isPending ? (
                        <Spinner size="xs" />
                      ) : (
                        <SquarePenIcon className="size-4" />
                      )}
                    </Button>
                  </DialogFooter>
                </form>
              </Form>
            </DialogContent>
          </Dialog>
        </div>
      </CardHeader>
      <CardContent className="grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5">
        {/* <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Customer ID: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : (
              customerSettingsQuery.data?.content.customer_id 
            )}
          </Paragraph>
        </div>
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Buyer External Id: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : (
              customerSettingsQuery.data?.content.buyer_external_id 
            )}
          </Paragraph>
        </div> */}
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">
            Initial Run Cutoff Percent:{" "}
          </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : customerSettingsQuery.data?.content
                .initial_run_cutoff_percent ? (
              customerSettingsQuery.data.content.initial_run_cutoff_percent
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>
        </div>
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Initial Run Months: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : customerSettingsQuery.data?.content.initial_run_months ? (
              customerSettingsQuery.data.content.initial_run_months
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>
        </div>
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">
            Initial Run Subdivisions:{" "}
          </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : customerSettingsQuery.data?.content.initial_run_subdivisions ? (
              customerSettingsQuery.data.content.initial_run_subdivisions
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>
        </div>
        {/* <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Run Type: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : customerSettingsQuery.data?.content.run_type ? (
              customerSettingsQuery.data.content.run_type
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">{FallbackMessages.NO_DATA}</Badge>
            )}
          </Paragraph>
        </div>
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Run ID: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : customerSettingsQuery.data?.content.run_id ? (
              customerSettingsQuery.data.content.run_id
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">{FallbackMessages.NO_DATA}</Badge>
            )}
          </Paragraph>
        </div> */}
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Run Date: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : customerSettingsQuery.data?.content.run_date ? (
              customerSettingsQuery.data.content.run_date
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>
        </div>
        {/* <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Run Started: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : (
              customerSettingsQuery.data?.content.run_started?.toString() 
            )}
          </Paragraph>
        </div>
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Run Ended: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : customerSettingsQuery.data?.content.run_ended ? (
              customerSettingsQuery.data.content.run_ended
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">{FallbackMessages.NO_DATA}</Badge>
            )}
          </Paragraph>
        </div> */}
        {/* <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Stopped: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : customerSettingsQuery.data?.content.stopped ? (
              customerSettingsQuery.data.content.stopped
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">{FallbackMessages.NO_DATA}</Badge>
            )}
          </Paragraph>
        </div> */}
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Additional Criteria: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : customerSettingsQuery.data?.content.additional_criteria ? (
              customerSettingsQuery.data.content.additional_criteria
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>
        </div>
        {/* <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Cluster ID: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : customerSettingsQuery.data?.content.cluster_id ? (
              customerSettingsQuery.data.content.cluster_id
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">{FallbackMessages.NO_DATA}</Badge>
            )}
          </Paragraph>
        </div> */}
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Start Time:</Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : customerSettingsQuery.data?.content.start_time ? (
              new Date(
                customerSettingsQuery.data.content.start_time
              ).toLocaleString("en-US", {timeZoneName: "short"}) // Convert Date to string 📅
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>
        </div>
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">End Time:</Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : customerSettingsQuery.data?.content.end_time ? (
              new Date(
                customerSettingsQuery.data.content.end_time
              ).toLocaleString("en-US", {timeZoneName: "short"}) // Convert Date to string 📅
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>
        </div>
        {/* <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Total Time: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : customerSettingsQuery.data?.content.total_time ? (
              customerSettingsQuery.data.content.total_time
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">{FallbackMessages.NO_DATA}</Badge>
            )}
          </Paragraph>
        </div> */}
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Comparison Records: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : customerSettingsQuery.data?.content.comparison_records ? (
              customerSettingsQuery.data.content.comparison_records
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>
        </div>
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Notes: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : customerSettingsQuery.data?.content.notes ? (
              customerSettingsQuery.data.content.notes
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>
        </div>
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Block Maybe: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : (
              <Badge>
                {customerSettingsQuery.data?.content.block_maybe?.toString()}
              </Badge>
            )}
          </Paragraph>
        </div>
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">
            Block Different Vendor Maybe:{" "}
          </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : (
              <Badge>
                {customerSettingsQuery.data?.content.block_different_vendor_maybe?.toString()}
              </Badge>
            )}
          </Paragraph>
        </div>
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Block Likely: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : (
              <Badge>
                {customerSettingsQuery.data?.content.block_likely?.toString()}
              </Badge>
            )}
          </Paragraph>
        </div>
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">
            Block Different Vendor Likely:{" "}
          </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : (
              <Badge>
                {customerSettingsQuery.data?.content.block_different_vendor_likely?.toString()}
              </Badge>
            )}
          </Paragraph>
        </div>
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Initial Run End Date: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : customerSettingsQuery.data?.content.initial_run_end_date ? (
              new Date(
                customerSettingsQuery.data.content.initial_run_end_date
              ).toLocaleString("en-US", {timeZoneName: "short"}) // Convert Date to string representation 📆
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>
        </div>
        <div className="grid grid-rows-2 border border-muted p-2">
          <Paragraph className="font-bold">Initial Run Start Date: </Paragraph>
          <Paragraph>
            {customerSettingsQuery.isPending ? (
              <Skeleton className="h-4 w-20" />
            ) : customerSettingsQuery.data?.content.initial_run_start_date ? (
              new Date(
                customerSettingsQuery.data.content.initial_run_start_date
              ).toLocaleString("en-US", {timeZoneName: "short"}) // Convert Date to string representation 📆
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>
        </div>
      </CardContent>
    </Card>
  );
}
