// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

import {type RunnerConfigsColumnsType} from "@/modules/admin";

// Interface defining the shape of the PaymentRunnerConfigTableColumnsStore state
// This state will consist of an array of PaymentRunnerConfigTableColumns objects
interface PaymentRunnerConfigTableColumnsStoreState {
  storeRunnerConfigTableColumns: RunnerConfigsColumnsType | null;
  setStoreRunnerConfigsTableColumns: (
    storeRunnerConfigTableColumns: RunnerConfigsColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for PaymentRunnerConfig table columns
export const useRunnerConfigTableColumnsStore = create(
  persist<PaymentRunnerConfigTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeRunnerConfigTableColumns: null,

      // Getter function to retrieve the current state
      getStorePaymentRunnerConfigTableColumns: () =>
        get().storeRunnerConfigTableColumns,

      // Setter function to update the state
      setStoreRunnerConfigsTableColumns: (storeRunnerConfigTableColumns) => {
        set(() => ({storeRunnerConfigTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "participant-register-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useRunnerConfigsTableColumns = () =>
  useRunnerConfigTableColumnsStore((state) => {
    return {
      storeRunnerConfigTableColumns: state.storeRunnerConfigTableColumns,
    };
  });
