// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type ReconciledStatementResultColumnsType} from "@/modules/statement-recon";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface SRFileHistoryTableColumnsStoreState {
  storeSRFileHistoryTableColumns: ReconciledStatementResultColumnsType | null;
  setStoreSRFileHistoryTableColumns: (
    storeSRFileHistoryTableColumns: ReconciledStatementResultColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for SRFileHistory table columns
export const useSRFileHistoryTableColumnsStore = create(
  persist<SRFileHistoryTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeSRFileHistoryTableColumns: null,

      // Getter function to retrieve the current state
      getStoreSRFileHistoryTableColumns: () =>
        get().storeSRFileHistoryTableColumns,

      // Setter function to update the state
      setStoreSRFileHistoryTableColumns: (storeSRFileHistoryTableColumns) => {
        set(() => ({storeSRFileHistoryTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "sr-recon-results-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useSRFileHistoryTableColumns = () =>
  useSRFileHistoryTableColumnsStore((state) => {
    return {
      storeSRFileHistoryTableColumns: state.storeSRFileHistoryTableColumns,
    };
  });
