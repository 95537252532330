// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type CustomerManagementAdminTableColumnsType} from "@/modules/admin";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface CustomerManagementAdminTableColumnsStoreState {
  storeCustomerManagementAdminTableColumns: CustomerManagementAdminTableColumnsType | null;
  setStoreCustomerManagementAdminTableColumns: (
    storeCustomerManagementAdminTableColumns: CustomerManagementAdminTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for CustomerManagementAdmin table columns
export const useCustomerManagementAdminTableColumnsStore = create(
  persist<CustomerManagementAdminTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeCustomerManagementAdminTableColumns: null,

      // Getter function to retrieve the current state
      getStoreCustomerManagementAdminTableColumns: () =>
        get().storeCustomerManagementAdminTableColumns,

      // Setter function to update the state
      setStoreCustomerManagementAdminTableColumns: (
        storeCustomerManagementAdminTableColumns
      ) => {
        set(() => ({storeCustomerManagementAdminTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "admin-customer-management-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useCustomerManagementAdminTableColumns = () =>
  useCustomerManagementAdminTableColumnsStore((state) => {
    return {
      storeCustomerManagementAdminTableColumns:
        state.storeCustomerManagementAdminTableColumns,
    };
  });
