// eslint ignore whole file
/* eslint-disable */

import {
  CheckCircleIcon,
  CircleDollarSignIcon,
  CircleSlash2Icon,
  DeleteIcon,
  FileXIcon,
  GlobeIcon,
  HourglassIcon,
  MailsIcon,
  PhoneCallIcon,
  ShieldAlertIcon,
  ShieldCheckIcon,
  ShieldMinusIcon,
  ShieldXIcon,
  ThumbsUpIcon,
  XCircleIcon,
} from "lucide-react";

// Default error message to display when no error message is provided.
export const DEFAULT_ERROR = "Something went wrong.";

// Default client server error messages to display
export const SERVER_CLIENT_ERRORS: Record<number, string> = {
  // Authentication and Authorization Errors (100-199)
  100: "Your credentials are invalid.",
  101: "Your password needs to be changed.",
  102: "Your session has expired. Please log in again.",
  103: "This account does not have the necessary permissions.",
  104: "Your API key is expired or invalid.",

  // Rate Limiting and Overuse Errors (300-399)
  300: "Too many requests have been sent from your IP address.",
  301: "Rate limit exceeded. Please slow down your requests.",
  302: "Your account has reached its API request limit.",
  303: "Your account has been temporarily suspended due to suspicious activity.",

  // Data Errors (400-499)
  400: "The provided data could not be validated.",
  401: "The requested data is not available.",
  402: "The provided data is inconsistent with our records.",
  403: "The data you are trying to access has been moved or deleted.",
  409: "Conflict. The request could not be completed due to a conflict.",
  422: "Unprocessable entity. The request was well-formed but unable to be followed due to semantic errors.",

  // Server Errors (500-599)
  500: "An internal server error occurred. Please try again later.",
  501: "The server is currently unavailable. Please try again later.",
  502: "A database error occurred. Please try again later.",
  503: "The server is currently under maintenance. Please try again later.",

  // Payment and Billing Errors (600-699)
  600: "Your payment information is invalid.",
  601: "Insufficient funds for this operation.",
  602: "Your account is overdue. Please update your payment information.",

  // Account Status Errors (700-799)
  700: "Your account is not active.",
  701: "Your account has been deactivated due to inactivity.",
  702: "Your account is not verified. Please verify your email.",

  // Conflict Errors (800-899)
  800: "The request could not be processed due to a conflict.",
  801: "A duplicate record exists.",

  // Other Errors (900-999)
  900: "An unknown error occurred. Please contact support.",
  901: "The requested feature is currently not supported.",
  902: "Service temporarily unavailable due to system update.",
  903: "The provided file format is not supported.",
};

// Default client error messages to display
export const CLIENT_ERRORS: Record<number, string> = {
  // Authentication and User Account Errors
  1000: "Your credentials are invalid.",
  1001: "Your password needs to be changed.",
  1002: "Maximum number of failed attempts exceeded.",
  1003: "Your account is not active.",
  1004: "Your account role has not been set.",
  1005: "The email you provided is already in use.",
  1006: "The username you provided is already in use.",

  // Data Validation Errors
  2000: "Provided data does not meet validation rules.",
  2001: "The date you provided is in the past.",
  2002: "The date you provided is in the future.",
  2003: "The file you uploaded is of an unsupported format.",
  2004: "The file you uploaded exceeds the size limit.",
  2005: "The input does not match the expected pattern.",

  // Business Rule Violations
  3000: "The requested operation exceeds your account limit.",
  3001: "The operation cannot be completed as it would result in a duplicate record.",
  3002: "This operation is not allowed for your account type.",
  3003: "You have exceeded the maximum number of requests allowed.",
  3004: "The operation could not be completed due to insufficient funds.",
  3005: "The operation could not be completed due to insufficient permissions.",

  // Application-specific Errors
  4000: "The requested feature is currently unavailable.",
  4001: "This feature has been deprecated.",
  4002: "The request could not be completed due to maintenance.",
  4003: "The provided API key is not valid.",
  4004: "The requested resource could not be found.",
  4005: "The operation could not be completed due to a conflict.",
};

export const CONTACT_INFO = {
  contactPhone: "1-(888)-478-3456",
  contactEmail: "ContactIM@iterationm.com",
  customerServiceEmail: "CustomerService@iterationm.com",
  supplierSupportEmail: "SupplierSupport@iterationm.com",
};

export const ROWS_PER_PAGE = ["5", "10", "25", "50", "100"] as const;

// Remittance Methods
export const remittanceMethods = [
  {
    value: "Pay By Email",
    label: "Pay By Email",
    icon: MailsIcon,
  },
  {
    value: "Pay By Phone",
    label: "Pay By Phone",
    icon: PhoneCallIcon,
  },
  {
    value: "Pay By Web",
    label: "Pay By Web",
    icon: GlobeIcon,
  },
];

// invoices
export const invoiceStatuses = [
  {
    value: "pending",
    label: "Pending",
    icon: HourglassIcon,
  },
  {
    value: "pending",
    label: "Pending Approval",
    icon: HourglassIcon,
  },
  {
    value: "approved",
    label: "Approved",
    icon: CheckCircleIcon,
  },
  {
    value: "paid",
    label: "Paid",
    icon: CircleDollarSignIcon,
  },
  {
    value: "exception",
    label: "Exception",
    icon: CircleSlash2Icon,
  },
  {
    value: "void",
    label: "Void",
    icon: XCircleIcon,
  },
  {
    value: "deleted",
    label: "Deleted",
    icon: FileXIcon,
  },
  {
    value: "cancelled",
    label: "Cancelled",
    icon: DeleteIcon,
  },
];

// SupplierStatus

export const supplierStatuses = [
  {
    value: "Active",
    label: "ACTIVE",
    variant: "success" as "success", // type assertion
    icon: ShieldCheckIcon,
  },
  {
    value: "Inactive",
    label: "INACTIVE",
    variant: "default" as "default",
    icon: ShieldMinusIcon,
  },
  {
    value: "Declined Enrollment",
    label: "DECLINED ENROLLMENT",
    variant: "danger" as "danger",
    icon: ShieldXIcon,
  },
  {
    value: "Approval Pending",
    label: "APPROVAL PENDING",
    variant: "secondary" as "secondary",
    icon: ShieldAlertIcon,
  },
  {
    value: "Onboarding Pending",
    label: "ONBOARDING PENDING",
    variant: "secondary" as "secondary",
    icon: ShieldAlertIcon,
  },
  {
    value: "Form Declined",
    label: "FORM DECLINED",
    variant: "danger" as "danger",
    icon: ShieldXIcon,
  },
];

//likelihoods
export const likelihoods = [
  {
    value: "maybe",
    label: "Maybe",
    icon: HourglassIcon,
  },
  {
    value: "likely",
    label: "Likely",
    icon: ThumbsUpIcon,
  },
  {
    value: "different-supplier-maybe",
    label: "Different Supplier Maybe",
    icon: HourglassIcon,
  },
  {
    value: "different-supplier-likely",
    label: "Different Supplier Likely",
    icon: ThumbsUpIcon,
  },
];

export enum FallbackMessages {
  NOT_APPLICABLE = "N/A",
  NO_DATA = "No data",
  NO_RESULTS = "No results found.",
  NO_RESULTS_FILTER = "No results found. Try adjusting your filters.",
  NO_RESULTS_SEARCH = "No results found. Try adjusting your search.",
  NO_RESULTS_SEARCH_FILTER = "No results found. Try adjusting your search or filters.",
  NO_RESULTS_SEARCH_FILTER_DATE = "No results found. Try adjusting your search, filters, or date range.",
  NO_RESULTS_SEARCH_DATE = "No results found. Try adjusting your search or date range.",
  NO_RESULTS_FILTER_DATE = "No results found. Try adjusting your filters or date range.",
  NO_RESULTS_DATE = "No results found. Try adjusting your date range.",
  NO_RESULTS_FILTER_SEARCH = "No results found. Try adjusting your filters or search.",
  NO_RESULTS_DATE_SEARCH = "No results found. Try adjusting your date range or search.",
  NO_RESULTS_FILTER_DATE_SEARCH = "No results found. Try adjusting your filters, date range, or search.",
  NO_RESULTS_FILTER_DATE_SEARCH_PAGINATION = "No results found. Try adjusting your filters, date range, search, or page number.",
  NO_RESULTS_FILTER_DATE_PAGINATION = "No results found. Try adjusting your filters, date range, or page number.",
  NO_RESULTS_FILTER_SEARCH_PAGINATION = "No results found. Try adjusting your filters, search, or page number.",
  NO_RESULTS_DATE_SEARCH_PAGINATION = "No results found. Try adjusting your date range, search, or page number.",
  NO_RESULTS_DATE_PAGINATION = "No results found. Try adjusting your date range or page number.",
  NO_RESULTS_SEARCH_PAGINATION = "No results found. Try adjusting your search or page number.",
  NO_RESULTS_FILTER_PAGINATION = "No results found. Try adjusting your filters or page number.",
  NO_RESULTS_SEARCH_FILTER_PAGINATION = "No results found. Try adjusting your search, filters, or page number.",
  NO_RESULTS_SEARCH_FILTER_DATE_PAGINATION = "No results found. Try adjusting your search, filters, date range, or page number.",
  NO_RESULTS_SEARCH_DATE_PAGINATION = "No results found. Try adjusting your search, date range, or page number.",
}

// regex patterns
export const phoneRegex = new RegExp(
  /^$|^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/
);

export const zipCodeRegex = new RegExp(/^\d{5}(-|\s)?(\d{4})?$/);

export const initialsRegex = /(\p{L}{1})\p{L}+/gu;

//Alphanumeric character and is followed by a combination of word characters, hyphens, or spaces.
export const mappingNameRegex = new RegExp(/^[A-Za-z0-9][\w- ]+$/);

/**
 * This regex pattern is used to match strings that follow the following format:
 * It can start with an optional '|', '*', or a combination of both, followed by zero or more digits.
 * It can have an optional '^' character followed by an optional single digit.
 * It must contain at least one or more digits.
 * It can end with an optional hyphen followed by one or more digits.
 * Examples of strings that would match this regex pattern include:
 * "123"
 * "|456"
 * "23423-3"
 * "^2^35-6789"
 */
export const paymentFieldRegex = new RegExp(
  /^((?:[|*]\d*)?(\^\d?)?(\d+))(?:-\d+)?$/
);

/**
 * This regex pattern is used to not allow the special charcters
 * ^ asserts the start of the string.
 * [a-zA-Z0-9] matches any letter (uppercase or lowercase) or digit.
 * * quantifier matches zero or more occurrences of the preceding character class.
 * $ asserts the end of the string.
 */
export const noSpecialCharctersRegex = /^[a-zA-Z0-9.\s]+$/;

/**
 * the regex ^\d+$ is used to match a string that consists entirely of one or more digits, with no other characters in between.
 * It would match strings like "12345" or "0" but not "abc123" or "12a34".
 */
export const reconRegex = new RegExp(/^\d+$/);

/**
 * the regular expression  will match a string that starts with one or
 * more characters that are either digits, uppercase letters, lowercase letters, whitespace characters, asterisks (*), or
 * forward slashes (/), and continues until the end of the string.
 * will not match strings that contain any other characters not specified in the character class.
 */
export const normalStrRegex = new RegExp(/^[0-9A-Za-z\s*/]+$/);
export const normalStrRegexBankFile = new RegExp(/^[0-9A-Za-z\s*#/]+$/);

// Regex pattern for validating a cron interval
export const cronIntervalRegex = new RegExp(/^(\d+)\s+(min(?:ute)?s?|hours?)$/);

// Regex pattern for window start and end time
export const windowStartAndEndTimeRegex = new RegExp(
  /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/
);

// Regex pattern for validating IPv4 addresses
export const ipv4Pattern =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

// Regex pattern for validating IPv6 addresses
export const ipv6Pattern = /^(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}$/;

// Regex pattern for validating IPv4 addresses with port
export const ipv4WithPortPattern =
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:\d{1,5})?$/;

// Regex pattern for validating IPv4 addresses with URL
export const IPv4withURLPattern =
  /^(http:\/\/)?(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:\d{1,5})?$/;

// Regex pattern for validating IPv6 addresses with port
export const ipv6WithPortPattern =
  /^(?:[a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}(?::\d{1,5})?$|^(?:[a-fA-F0-9]{1,4}:){1,7}:$|^:(?::[a-fA-F0-9]{1,4}){1,7}(?::\d{1,5})?$|^(?:[a-fA-F0-9]{1,4}:){1,6}:[a-fA-F0-9]{1,4}(?::\d{1,5})?$|^(?:[a-fA-F0-9]{1,4}:){1,5}(?::[a-fA-F0-9]{1,4}){1,2}(?::\d{1,5})?$|^(?:[a-fA-F0-9]{1,4}:){1,4}(?::[a-fA-F0-9]{1,4}){1,3}(?::\d{1,5})?$|^(?:[a-fA-F0-9]{1,4}:){1,3}(?::[a-fA-F0-9]{1,4}){1,4}(?::\d{1,5})?$|^(?:[a-fA-F0-9]{1,4}:){1,2}(?::[a-fA-F0-9]{1,4}){1,5}(?::\d{1,5})?$|^(?:[a-fA-F0-9]{1,4}:){1,1}(?::[a-fA-F0-9]{1,4}){1,6}(?::\d{1,5})?$|^:(?::[a-fA-F0-9]{1,4}){1,7}(?::\d{1,5})?$/;

// Regex pattern for validating IPv6 addresses with URL
export const IPv6withURLPattern =
  /^(http:\/\/)?\[([a-fA-F0-9]{1,4}:){7}[a-fA-F0-9]{1,4}\](?::\d{1,5})?$|^(http:\/\/)?\[([a-fA-F0-9]{1,4}:){1,7}:\](?::\d{1,5})?$|^(http:\/\/)?\[::([a-fA-F0-9]{1,4}:){1,7}\](?::\d{1,5})?$|^(http:\/\/)?\[([a-fA-F0-9]{1,4}:){1,6}:[a-fA-F0-9]{1,4}\](?::\d{1,5})?$|^(http:\/\/)?\[([a-fA-F0-9]{1,4}:){1,5}(?::[a-fA-F0-9]{1,4}){1,2}\](?::\d{1,5})?$|^(http:\/\/)?\[([a-fA-F0-9]{1,4}:){1,4}(?::[a-fA-F0-9]{1,4}){1,3}\](?::\d{1,5})?$|^(http:\/\/)?\[([a-fA-F0-9]{1,4}:){1,3}(?::[a-fA-F0-9]{1,4}){1,4}\](?::\d{1,5})?$|^(http:\/\/)?\[([a-fA-F0-9]{1,4}:){1,2}(?::[a-fA-F0-9]{1,4}){1,5}\](?::\d{1,5})?$|^(http:\/\/)?\[([a-fA-F0-9]{1,4}:){1,1}(?::[a-fA-F0-9]{1,4}){1,6}\](?::\d{1,5})?$|^(http:\/\/)?\[::(?::[a-fA-F0-9]{1,4}){1,7}\](?::\d{1,5})?$/;

// Define a URL pattern that allows for both valid URLs and empty strings.
// eslint-disable-next-line no-useless-escape
export const urlPattern =
  /^(?:(http:\/\/|https:\/\/)[a-z0-9]+([-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)?$/;

// URL that accepts IP address, hostname, ports and query parameters
export const appvanceURLPattern =
  /^(?:(http:\/\/|https:\/\/)(([a-z0-9]+([-\.]{1}[a-z0-9]+)*)|(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))(:[0-9]{1,5})?(\/.*)?)?$/;

// export const urlPatternWithIPAddressAndHostname =
//   /^(?:(http:\/\/|https:\/\/)[a-z0-9]+([-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)?$/;

export const emailPattern =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z0-9]+\.)+[a-zA-Z]{2,}))|(^$)/;

// form options
export const countrySelectOptions = [
  {
    value: "USA",
    label: "USA",
  },
  {
    value: "CA",
    label: "Canada",
  },
] as const;

export const countrySupplierSelectOptions = [
  {
    value: "US",
    label: "USA",
  },
  {
    value: "CA",
    label: "Canada",
  },
] as const;

export const stateOptionsUSA = [
  {
    value: "AL",
    label: "Alabama",
  },
  {
    value: "AK",
    label: "Alaska",
  },
  {
    value: "AS",
    label: "American Samoa",
  },
  {
    value: "AZ",
    label: "Arizona",
  },
  {value: "AR", label: "Arkansas"},
  {value: "CA", label: "California"},
  {value: "CO", label: "Colorado"},
  {value: "CT", label: "Connecticut"},
  {value: "DE", label: "Delaware"},
  {value: "DC", label: "District of Columbia"},
  {
    value: "FM",
    label: "Federated States of Micronesia",
  },
  {value: "FL", label: "Florida"},
  {value: "GA", label: "Georgia"},
  {value: "GU", label: "Guam"},
  {value: "HI", label: "Hawaii"},
  {value: "ID", label: "Idaho"},
  {value: "IL", label: "Illinois"},
  {value: "IN", label: "Indiana"},
  {value: "IA", label: "Iowa"},
  {value: "KS", label: "Kansas"},
  {value: "KY", label: "Kentucky"},
  {value: "LA", label: "Louisiana"},
  {value: "ME", label: "Maine"},
  {value: "MH", label: "Marshall Islands"},
  {value: "MD", label: "Maryland"},
  {value: "MA", label: "Massachusetts"},
  {value: "MI", label: "Michigan"},
  {value: "MN", label: "Minnesota"},
  {value: "MS", label: "Mississippi"},
  {value: "MO", label: "Missouri"},
  {value: "MT", label: "Montana"},
  {value: "NE", label: "Nebraska"},
  {value: "NV", label: "Nevada"},
  {value: "NH", label: "New Hampshire"},
  {value: "NJ", label: "New Jersey"},
  {value: "NM", label: "New Mexico"},
  {value: "NY", label: "New York"},
  {value: "NC", label: "North Carolina"},
  {value: "ND", label: "North Dakota"},
  {value: "MP", label: "Northern Mariana Islands"},
  {value: "OH", label: "Ohio"},
  {value: "OK", label: "Oklahoma"},
  {value: "OR", label: "Oregon"},
  {value: "PW", label: "Palau"},
  {value: "PA", label: "Pennsylvania"},
  {value: "PR", label: "Puerto Rico"},
  {value: "RI", label: "Rhode Island"},
  {value: "SC", label: "South Carolina"},
  {value: "SD", label: "South Dakota"},
  {value: "TN", label: "Tennessee"},
  {value: "TX", label: "Texas"},
  {value: "UT", label: "Utah"},
  {value: "VT", label: "Vermont"},
  {value: "VI", label: "Virgin Islands"},
  {value: "VA", label: "Virginia"},
  {value: "WA", label: "Washington"},
  {value: "WV", label: "West Virginia"},
  {value: "WI", label: "Wisconsin"},
  {value: "WY", label: "Wyoming"},
] as const;

export const provinceOptionsCanada = [
  {value: "AB", label: "Alberta"},
  {value: "BC", label: "British Columbia"},
  {value: "MB", label: "Manitoba"},
  {value: "NB", label: "New Brunswick"},
  {value: "NL", label: "Newfoundland and Labrador"},
  {value: "NT", label: "Northwest Territories"},
  {value: "NS", label: "Nova Scotia"},
  {value: "NU", label: "Nunavut"},
  {value: "ON", label: "Ontario"},
  {value: "PE", label: "Prince Edward Island"},
  {value: "QC", label: "Quebec"},
  {value: "SK", label: "Saskatchewan"},
  {value: "YT", label: "Yukon"},
] as const;

// TODO: add more currencies
export const currenciesOptions = [
  {value: "usd", label: "USD"},
  {value: "cad", label: "CAD"},
] as const;

export const supplierManagementAllStatuses = [
  {value: "", label: "All"},
  {value: "INACTIVE", label: "INACTIVE"},
  {value: "ACTIVE", label: "ACTIVE"},
  {value: "DECLINED_ENROLLMENT", label: "DECLINED ENROLLMENT"},
  {value: "APPROVAL_PENDING", label: "APPROVAL PENDING"},
  {value: "ONBOARDING_PENDING", label: "ONBOARDING PENDING"},
  {value: "FORM_DECLINED", label: "FORM DECLINED"},
];

// imRemit Payment List Types
export const listType = [
  {
    methodType: "filter-pending-payable",
    methodDescription: "Payables pending for more than 5 days",
  },
  {
    methodType: "filter-ending-payable",
    methodDescription: "Payables ending in the next 7 days",
  },
  {
    methodType: "filter-missed-payable",
    methodDescription: "Payables missed in the next 30 days",
  },
  {
    methodType: "filter-matching-issue-payable",
    methodDescription: "imRemit Payables with transaction matching issues",
  },
  {
    methodType: "filter-declined-payable",
    methodDescription: "Payables with declines in the past 7 days",
  },
  {
    methodType: "filter-error-payable",
    methodDescription: "Failed payments on IM",
  },
  {
    methodType: "filter-provider-issue-payable",
    methodDescription: "Failed payments on payment provider",
  },
];

// imRemit Lite Payment List Types
export const listTypeLite = [
  {
    methodType: "filter-open-payable-imRemitLite",
    methodDescription: "Payables Open for more than 5 days",
  },
  {
    methodType: "filter-ending-payable-imRemitLite",
    methodDescription: "Ending Payables in Next 7 Days",
  },
  {
    methodType: "filter-re-opened-payable-imRemitLite",
    methodDescription: "Re-Opened",
  },
  {
    methodType: "filter-declined-payable-imRemitLite",
    methodDescription: "Payables With Declines",
  },
  {
    methodType: "filter-invoice-amount-mismatch-payable-imRemitLite",
    methodDescription: "Invoice Amount Mismatch",
  },
  {
    methodType: "filter-invoice-number-mismatch-payable-imRemitLite",
    methodDescription: "Invoice Number Mismatch",
  },
  {
    methodType: "filter-payment-already-taken-payable-imRemitLite",
    methodDescription: "Payment Already Taken",
  },
  {
    methodType: "filter-partial-payment-already-taken-payable-imRemitLite",
    methodDescription: "Partial Payment Already Taken",
  },
];

// Keycloak role names
export const KeycloakRoles = {
  SupportCsr: "Support CSR",
  User: "User",
  Management: "Management",
  ProgramManager: "Program Manager",
  ProjectManager: "Project Manager",
  ImUser: "IM User",
  SupplierEnablement: "Supplier Enablement",
  EPayablesAdmin: "ePayables Admin",
  EPayablesUser: "ePayables User",
  CustomerSuperAdmin: "Customer Super Admin",
  CustomerAdmin: "Customer Admin",
  CustomerUser: "Customer User",
  CustomerReviewer: "Customer Reviewer",
  SupplierAdmin: "Supplier Admin",
  SupplierUser: "Supplier User",
} as const;

//Keycloak Role Names with a hypen
export const KeycloakRolesWithHyphen = {
  SupportCsr: "support-csr",
  User: "user",
  Management: "management",
  ProgramManager: "program-manager",
  ProjectManager: "project-manager",
  ImUser: "im-user",
  SupplierEnablement: "supplier-enablement",
  EPayablesAdmin: "epayables-admin",
  EPayablesUser: "epayables-user",
  CustomerSuperAdmin: "customer-super-admin",
  CustomerAdmin: "customer-admin",
  CustomerUser: "customer-user",
  CustomerReviewer: "customer-reviewer",
  SupplierAdmin: "supplier-admin",
  SupplierUser: "supplier-user",
} as const;

//Keycloak Role Names with a backslash
export const KeycloakRolesWithBackslash = {
  BaseRole: "/base-role",
  SupportCsr: "/support-csr",
  User: "/user",
  Management: "/management",
  ProgramManager: "/program-manager",
  ProjectManager: "/project-manager",
  ImUser: "/im-user",
  SupplierEnablement: "/supplier-enablement",
  EPayablesAdmin: "/epayables-admin",
  EPayablesUser: "/epayables-user",
  CustomerSuperAdmin: "/customer-super-admin",
  CustomerAdmin: "/customer-admin",
  CustomerUser: "/customer-user",
  CustomerReviewer: "/customer-reviewer",
  SupplierAdmin: "/supplier-admin",
  SupplierUser: "/supplier-user",
} as const;

//Module names
export const ModuleNames = {
  imremit: "imREmit",
  imremitLite: "imREmit_lite",
  duplicatePayments: "duplicate_payments",
  statementRecon: "statement_recon",
} as const;

export const InternalEmails = {
  internalEmailProxyEpay: "Proxy.ePay@IterationM.com",
} as const;

export const PaymentModes = {
  PayByWeb: "Pay By Web",
  PayByPhone: "Pay By Phone",
  PayByEmail: "Pay By Email",
} as const;

export const CustomerStatus = {
  Paid: "Paid",
  PaidPendingVerification: "Paid - Pending Verification",
  Open: "Open",
  ReOpened: "Re-Opened",
  Failed: "Failed",
  InProgress: "In Progress",
  Declined: "Declined",
  InvoiceAmountMismatch: "Invoice Amount Mismatch",
  InvoiceNumberMismatch: "Invoice Number Mismatch",
  PaymentAlreadyTaken: "Payment Already Taken",
  PartialPaymentAlreadyTaken: "Partial Payment Already Taken",
  EscalationWithBank: "Escalation With Bank",
} as const;

export const PaymentRequestPaymentMode = {
  AutomaticPBW: "AUTOMATION_PAY_BY_WEB",
  ManualPBW: "MANUAL_PAY_BY_WEB",
} as const;

export const SRSettingNames = {
  IgnoreLeadingTrailing: "ignore_leading_trailing",
  IgnoreSpecialCharacters: "ignore_special_characters",
  CreditPairLogic: "credit_pair_logic",
  InvoiceCharacterLimit: "statement_invoice_statement_character_limit",
  PotentialMatch: "potential_match",
  POMatch: "po_match",
  ReconNotification: "recon_notification",
} as const;
