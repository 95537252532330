import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {type InvoicesMappingFormType} from "@/modules/invoices";

import {FallbackMessages} from "@/utils/constants";

/**
 *
 * Columns List
 */
export const invoiceMappingColumnsList = {
  configId: "Config ID",
  taxId: "Tax ID",
  facilityName: "Facility Name",
  invoiceDate: "Invoice Date",
  invoiceAmount: "Invoice Amount",
  status: "Status",
  paymentDate: "Payment Date",
  facilityId: "Facility ID",
  vendorNumber: "Vendor Number",
  invoicePaidAmount: "Invoice Paid Amount",
  poNumber: "PO Number",
  suffix: "Suffix",
  cancelDate: "Cancel Date",
  invoiceType: "Invoice Type",
  erpUniqueId: "ERP Unique ID",
  paymentStatus: "Payment Status",
  vendorName: "Vendor Name",
  paymentTerms: "Payment Terms",
  paymentMethod: "Payment Method",
  invoiceNo: "Invoice Number",
  paymentNumber: "Payment Number",
  dueDate: "Due Date",
  cancelId: "Cancel ID",
  voidDate: "Void Date",
  voidId: "Void ID",
  vendorGroup: "Vendor Group",
  vendorLocationCode: "Vendor Location Code",
  operatingUnit: "Operating Unit",
  createDate: "Create Date",
  paymentType: "Payment Type",
  voucherLineNo: "Voucher Line Number",
  voucherLineAmount: "Voucher Line Amount",
  discountDate: "Discount Date",
  discountAmount: "Discount Amount",
  salesAmount: "Sales Amount",
  freightAmount: "Freight Amount",
  miscAmount: "Miscellaneous Amount",
  origin: "Origin",
  grossAmount: "Gross Amount",
  futureUse1: "Future Use 1",
  futureUse2: "Future Use 2",
  futureUse3: "Future Use 3",
  futureUse4: "Future Use 4",
  futureUse5: "Future Use 5",
} as const;

export const invoiceMappingColumns: ColumnDef<InvoicesMappingFormType>[] =
  Object.entries(invoiceMappingColumnsList).map(([key, value], index) => {
    return {
      accessorKey: key,
      header: ({column}) => (
        <DataTableColumnHeader key={index} column={column} title={value} />
      ),
      cell: ({row}) => (
        <div key={index} className="w-fit">
          {row.getValue(key) ? (
            row.getValue(key)
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    };
  });
