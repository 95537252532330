import {kyApiFn} from "@/lib/ky";
import {type InvoicesMappingFormType} from "@/modules/invoices";

export const saveInvoiceMappingFn = (
  InvoicesMappingFormType: InvoicesMappingFormType
) =>
  kyApiFn(`invoice/v1/file/mapping`, "post", {
    // TODO: get rid of hard coded id
    json: InvoicesMappingFormType,
  });
