import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {type FileProcessingType} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";
import {formatDate} from "@/utils/functions";

// Definition of file processing columns for the datatable
export const fileProcessingColumns: ColumnDef<FileProcessingType>[] = [
  {
    accessorKey: "packageSummaryId",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Package ID" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("packageSummaryId") ? (
          row.getValue("packageSummaryId")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "fileName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    cell: ({row}) => (
      <div className="w-64">
        {row.getValue("fileName") ? (
          row.getValue("fileName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "formatName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Format" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("formatName") ? (
          row.getValue("formatName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "processStatus",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Status" />
    ),
    cell: ({row}) => {
      if (!row.original.processStatus) {
        return (
          <Badge>
            <Paragraph>{FallbackMessages.NOT_APPLICABLE}</Paragraph>
          </Badge>
        );
      }
      const isProcessed = row.original.processStatus === "PROCESSED";
      return (
        <div>
          {isProcessed ? (
            <Badge variant="success">
              <Paragraph className="font-semibold uppercase">
                {typeof row.original.processStatus === "string"
                  ? row.original.processStatus.replace(/_/g, " ")
                  : ""}
              </Paragraph>
            </Badge>
          ) : (
            <Badge variant="destructive">
              <Paragraph className="font-semibold uppercase text-destructive-foreground">
                {typeof row.original.processStatus === "string"
                  ? row.original.processStatus.replace(/_/g, " ")
                  : ""}
              </Paragraph>
            </Badge>
          )}
        </div>
      );
    },
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "received",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Received" />
    ),
    cell: ({row}) => {
      const dateValue = row.original.received;
      const includeTime = true;

      return (
        <Paragraph>
          {dateValue ? (
            formatDate(new Date(row.original.received), includeTime)
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </Paragraph>
      );
    },
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "totalCount",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Totals" />
    ),
    cell: ({row}) => (
      <div className="max-w-fit">
        {row.getValue("totalCount") ? (
          row.getValue("totalCount")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "processCount",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Successes" />
    ),
    cell: ({row}) => (
      <div className="max-w-fit">
        {row.getValue("processCount") ? (
          row.getValue("processCount")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "failCount",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Failures" />
    ),
    cell: ({row}) => (
      <div className="max-w-fit">
        {row.getValue("failCount") ? (
          row.getValue("failCount")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
];
