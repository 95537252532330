import {useState} from "react";

import {type Table} from "@tanstack/react-table";
import {ListRestartIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import {
  DataTablePagination,
  DataTableViewOptions,
} from "@/components/craft/data-table";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

/**
 * Props for SRFileHistoryDataTableToolbar component.
 *
 * @template TData - The type of data used in the table.
 * @typedef {Object} SRFileHistoryDataTableToolbarProps
 * @property {Table<TData>} table - The table instance to interact with.
 */
interface SRFileHistoryDataTableToolbarProps<TData> {
  table: Table<TData>;
  isPending?: boolean;
  isErrored?: boolean;
}

/**
 * Toolbar component for SR search data table.
 * Includes pagination, search input, filter components, and view options.
 *
 * @template TData - The type of data displayed in the table.
 * @param {SRFileHistoryDataTableToolbarProps<TData>} props - The component props.
 * @returns {JSX.Element} The rendered JSX element.
 */
export function SRFileHistoryDataTableToolbar<TData>({
  table,
  isPending,
  isErrored,
}: SRFileHistoryDataTableToolbarProps<TData>) {
  // State for the global filter input
  const [globalFilterInput, setGlobalFilterInput] = useState("");

  // Determine if the table is currently filtered
  const isFiltered =
    table.getState().columnFilters.length > 0 ||
    !!table.getState().globalFilter;

  //Columns other names
  const columnsOtherName = [
    {key: "user_statement_invoice_number", value: "Statement Invoice #"},
    {key: "user_statement_invoice_date", value: "Statement Invoice Date"},
    {key: "user_statement_invoice_amount", value: "Statement Invoice Amount"},
    {key: "user_statement_po_number", value: "Statement PO Number"},
    {key: "im_sr_match_type", value: "Match Status"},
    {key: "supplier_number", value: "Supplier Number"},
    {key: "supplier_name", value: "Supplier Name"},
    {key: "invoice_status_name", value: "Invoice Status"},
    {key: "invoice_amount", value: "Invoice Amount"},
    {key: "invoice_date", value: "Invoice Date"},
    {key: "invoice_paid_amount", value: "Invoice Paid Amount"},
    {key: "invoice_payment_date", value: "Invoice Payment Date"},
    {key: "facility_id", value: "Facility ID"},
    {key: "invoice_id", value: "Invoice ID"},
    {key: "supplier_id", value: "Supplier ID"},
    {key: "created_timestamp", value: "Created Timestamp"},
    {key: "updated_timestamp", value: "Updated Timestamp"},
  ];
  return (
    <>
      <DataTablePagination
        table={table}
        isPending={isPending}
        isErrored={isErrored}
      />
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-1 flex-wrap items-center gap-y-2 space-x-2">
          <RowsPreferenceSelect />
          <Input
            placeholder="Search all entries..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={globalFilterInput}
            onChange={(event) => {
              setGlobalFilterInput(event.target.value);
              table.setGlobalFilter(event.target.value);
            }}
          />

          {isFiltered && (
            <Button
              variant="destructive"
              className="h-10 gap-2 px-2 hover:bg-destructive/70 hover:text-destructive-foreground/80 active:bg-destructive/80 active:text-destructive-foreground/80 lg:px-3"
              onClick={() => {
                table.resetColumnFilters();
                table.resetGlobalFilter();
                setGlobalFilterInput("");
              }}
            >
              <span className="sr-only">Reset search filters</span>
              Reset Filters
              <ListRestartIcon className="size-4" />
            </Button>
          )}
        </div>

        <DataTableViewOptions
          table={table}
          columnsOtherName={columnsOtherName}
        />
      </div>
    </>
  );
}
