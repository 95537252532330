import {useState} from "react";

import {type Table} from "@tanstack/react-table";
import {ListRestartIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import {
  DataTablePagination,
  DataTableViewOptions,
} from "@/components/craft/data-table";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

/**
 * Props for StatementSearchDataTableToolbar component.
 *
 * @template TData - The type of data used in the table.
 * @typedef {Object} StatementSearchDataTableToolbarProps
 * @property {Table<TData>} table - The table instance to interact with.
 */
interface StatementSearchDataTableToolbarProps<TData> {
  table: Table<TData>;
  isPending?: boolean;
  isErrored?: boolean;
}

/**
 * Toolbar component for SR search data table.
 * Includes pagination, search input, filter components, and view options.
 *
 * @template TData - The type of data displayed in the table.
 * @param {StatementSearchDataTableToolbarProps<TData>} props - The component props.
 * @returns {JSX.Element} The rendered JSX element.
 */
export function StatementSearchDataTableToolbar<TData>({
  table,
  isPending,
  isErrored,
}: StatementSearchDataTableToolbarProps<TData>) {
  // State for the global filter input
  const [globalFilterInput, setGlobalFilterInput] = useState("");
  // State for the specific filter input
  const [fileNameFilterInput, setFileNameFilterInput] = useState("");
  const [fileUpdatedByFilterInput, setFileUpdatedByFilterInput] = useState("");

  // Determine if the table is currently filtered
  const isFiltered =
    table.getState().columnFilters.length > 0 ||
    !!table.getState().globalFilter;

  //Columns other names
  const columnsOtherName = [
    {key: "user_statement_file_name", value: "File Name"},
    {key: "statement_file_status", value: "File Status"},
    {key: "statement_file_size", value: "File Size"},
    {key: "suppliers", value: "Supplier(s)"},
    {key: "statement_uploaded_by", value: "Uploaded By"},
    {key: "created_timestamp", value: "Uploaded On"},
  ];
  return (
    <>
      <DataTablePagination
        table={table}
        isPending={isPending}
        isErrored={isErrored}
      />
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-1 flex-wrap items-center gap-y-2 space-x-2">
          <RowsPreferenceSelect />
          <Input
            placeholder="Search all entries..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={globalFilterInput}
            onChange={(event) => {
              setGlobalFilterInput(event.target.value);
              table.setGlobalFilter(event.target.value);
            }}
          />
          <Input
            placeholder="Search by file name..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={fileNameFilterInput}
            onChange={(event) => {
              setFileNameFilterInput(event.target.value);
              table
                .getColumn("user_statement_file_name")
                ?.setFilterValue(event.target.value);
            }}
          />
          <Input
            placeholder="Search updated by..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={fileUpdatedByFilterInput}
            onChange={(event) => {
              setFileUpdatedByFilterInput(event.target.value);
              table
                .getColumn("statement_uploaded_by")
                ?.setFilterValue(event.target.value);
            }}
          />

          {isFiltered && (
            <Button
              variant="destructive"
              className="h-10 gap-2 px-2 hover:bg-destructive/70 hover:text-destructive-foreground/80 active:bg-destructive/80 active:text-destructive-foreground/80 lg:px-3"
              onClick={() => {
                table.resetColumnFilters();
                table.resetGlobalFilter();
                setGlobalFilterInput("");
                setFileNameFilterInput("");
                setFileUpdatedByFilterInput("");
              }}
            >
              <span className="sr-only">Reset search filters</span>
              Reset Filters
              <ListRestartIcon className="size-4" />
            </Button>
          )}
        </div>

        <DataTableViewOptions
          table={table}
          columnsOtherName={columnsOtherName}
        />
      </div>
    </>
  );
}
