import {useEffect, useMemo} from "react";

import {Link} from "@tanstack/react-router";
import {PlusIcon} from "lucide-react";

import {buttonVariants} from "@/components/ui/button";
import {Heading2, Paragraph} from "@/components/ui/typography";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {ImREmitRoutesSchema} from "@/lib/routes/types";
import {useImRemitCustomerStore, useRoutesStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useAuthorization,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {
  SelectCustomerFormImRemit,
  SelectCustomerSectionImremit,
} from "@/modules/customers";
import {
  SupplierManagementDataTable,
  SupplierManagementExportData,
  supplierManagementTableColumns,
  useGetAllSuppliersDetails,
} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";

/**
 * Custom Hook: Manage the active route for the Supplier Management page.
 */
function useSupplierManagementRoute() {
  const {setimREmitRoute} = useRoutesStore();

  useEffect(() => {
    // Set the active route based on predefined route schema
    setimREmitRoute(
      ImREmitRoutesSchema.Values["/app/imremit/supplier-management"]
    );
  }, [setimREmitRoute]);
}

function useSupplierManagementPage() {
  const {imREmitStoreCustomer} = useImRemitCustomerStore();
  const externalId = imREmitStoreCustomer?.externalId;
  const searchSuppliersQuery = useGetAllSuppliersDetails(
    externalId?.toString()
  );

  const suppliersContent = useMemo(() => {
    if (!searchSuppliersQuery.data) {
      return [];
    }
    return searchSuppliersQuery.data.content;
  }, [searchSuppliersQuery.data]);

  return {
    imREmitStoreCustomer,
    suppliersContent,
    searchSuppliersQuery,
  };
}

/**
 * Main Component: Suppliers Management Dashboard Page
 * This component serves as the primary interface for Supplier Management.
 *
 * @returns {React.Element} - The rendered component.
 */
export function SupplierManagementPage() {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;
  let userEmail: string = "";

  !parsedUserInfo
    ? (userEmail = FallbackMessages.NOT_APPLICABLE)
    : (userEmail = parsedUserInfo.email as string);
  const {imREmitStoreCustomer, suppliersContent, searchSuppliersQuery} =
    useSupplierManagementPage();

  const {checkPoliciesAccess} = useAuthorization();

  useSupplierManagementRoute();

  // Handle unauthorized access
  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_SUPPLIER_MANAGEMENT])) {
    return <NotAuthorizedPage />;
  }

  if (!imREmitStoreCustomer) {
    return <SelectCustomerSectionImremit />;
  }
  const tableColumns = supplierManagementTableColumns
    .filter((column) => column.id !== "actions")
    .map((column) => ({
      // @ts-expect-error - ignore type error
      label: column.accessorKey as string,
      // @ts-expect-error - ignore type error
      value: column.accessorKey as string,
    }));

  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.READ_SUPPLIER_MANAGEMENT]}
    >
      <section className="flex items-center justify-between">
        <div className="flex flex-col">
          <Heading2>Supplier Management</Heading2>
          <Paragraph>This page allows you to manage your suppliers.</Paragraph>
        </div>
        <div className="flex gap-2">
          <SelectCustomerFormImRemit />
          <AccessPolicyWrapper
            unauthorizedFallback={null}
            policyActions={[KeycloakRoleEnum.CREATE_SUPPLIER_MANAGEMENT_EXPORT]}
          >
            <SupplierManagementExportData
              externalId={[imREmitStoreCustomer.externalId]}
              userEmail={userEmail}
              tableColumns={tableColumns}
            />
          </AccessPolicyWrapper>
          <AccessPolicyWrapper
            policyActions={[KeycloakRoleEnum.CREATE_SUPPLIER_MANAGEMENT]}
            unauthorizedFallback={null}
          >
            <Link
              to="/app/imremit/supplier-management/create"
              className={cn(buttonVariants(), "gap-2")}
            >
              <PlusIcon className="size-4" />
              <span className="sr-only">Add Supplier</span>
              Add Supplier
            </Link>
          </AccessPolicyWrapper>
        </div>
      </section>

      <SupplierManagementDataTable
        columns={supplierManagementTableColumns}
        data={suppliersContent}
        isPending={searchSuppliersQuery.isPending}
        isSuccess={searchSuppliersQuery.isSuccess}
        isError={searchSuppliersQuery.isError}
      />
    </AccessPolicyWrapper>
  );
}
