import {createRoute} from "@tanstack/react-router";

import {RoutesDirectory} from "@/lib/routes/directory";
import {
  imREmitRootRoute,
  imremitSearchFilterSchema,
  ProxyPayDashboardPage,
  ProxyPaymentDetails,
  ProxypayRedirect,
} from "@/modules/imremit";

export const imREmitProxyPayRoute = createRoute({
  path: `${RoutesDirectory.PROXY_PAY_DASHBOARD}/$ppPage${RoutesDirectory.DASHBOARD}`,
  component: ProxyPayDashboardPage,
  getParentRoute: () => imREmitRootRoute,
  validateSearch: (search) => imremitSearchFilterSchema.parse(search),
});

export const imREmitProxyPayRedirectRoute = createRoute({
  path: RoutesDirectory.PROXY_PAY_DASHBOARD,
  component: ProxypayRedirect,
  getParentRoute: () => imREmitRootRoute,
});

export const imREmitViewProxyPaymentRootRoute = createRoute({
  path: `${RoutesDirectory.PROXY_PAY_DASHBOARD}/$paymentId${RoutesDirectory.VIEW}/$reProcess`,
  component: ProxyPaymentDetails,
  getParentRoute: () => imREmitRootRoute,
});
