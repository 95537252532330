import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {imREmitDashboardQueryKeys} from "@/modules/imremit";
import {type ImREmitLiteDashboard} from "@/modules/imremit-lite";

// Defining a type based on the function `fetchImREmitDashboard`
type QueryFnType = typeof fetchImREmitLiteDashboard;

// Function to search imremits
export function fetchImREmitLiteDashboard(externalId?: string) {
  // Fetch imremits from API using ky
  return kyApiFn<ImREmitLiteDashboard>(
    `api/e-payable/management/${externalId}/dashboardImRemitLite`, // NOTE: need to change externalId to externalId (externalId)
    "get"
  );
  // TODO: validate data
  // return imremitSchema.parse(data);
}

// Custom hook to fetch and cache imremits data using react-query
export function useImREmitLiteDashboard(
  externalId?: string, // The "externalId" to be used for fetching data
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: imREmitDashboardQueryKeys.byExternalId(externalId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => fetchImREmitLiteDashboard(externalId), // Setting the function to be used for fetching data
    enabled: !!externalId, // Only fetch data if externalId is not empty
    // TODO: add onSuccess and onError handlers
  });
}
