import {useMemo} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {type Table} from "@tanstack/react-table";
import {CheckIcon, ChevronsUpDownIcon, ListRestartIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import {z} from "zod";

import {Button} from "@/components/ui/button";
import {Command, CommandGroup, CommandItem} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {toast} from "@/components/ui/use-toast";
import {DataTableViewOptions} from "@/components/craft/data-table";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

import {cn} from "@/lib/utils";
import {useGetAllSuppliers} from "@/modules/imremit";

import {generateQueryString} from "@/utils/query-params";

const SuppliersListFormSchema = z.object({
  supplierName: z.string(),
  taxCode: z.string(),
});

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
}

export function SuppliersListDataTableToolbar<TData>({
  table,
}: DataTableToolbarProps<TData>) {
  const form = useForm<z.infer<typeof SuppliersListFormSchema>>({
    mode: "onChange",
    resolver: zodResolver(SuppliersListFormSchema),
  });

  const {
    formState: {isDirty},
  } = form;
  const formFields = form.watch();
  const searchQuery = useMemo(
    () => generateQueryString({formFields}),
    [formFields]
  );

  if (searchQuery) {
    // console.log("searchQuery:", searchQuery);
  }

  //Get all suppliers custom hook
  const searchSuppliersQuery = useGetAllSuppliers();
  const searchSuppliersData = searchSuppliersQuery.data?.content || [];

  function onSubmit(data: z.infer<typeof SuppliersListFormSchema>) {
    toast({
      title: "You submitted the following values:",
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data, null, 2)}</code>
        </pre>
      ),
    });
  }

  //Columns other names
  const columnsOtherName = [
    {key: "id", value: "ID"},
    {key: "name", value: "Supplier Name"},
    {key: "taxCode", value: "T.I.N."},
    {key: "groupId", value: "Status"},
  ];

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex justify-between gap-4">
            <div className="flex flex-wrap items-end gap-2">
              <RowsPreferenceSelect />
              <FormField
                control={form.control}
                name="supplierName"
                render={({field}) => (
                  <div className="space-y-2">
                    <FormItem>
                      <Popover>
                        <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                          <Command>
                            <CommandGroup>
                              {searchSuppliersData.map((supplier, i) => (
                                <CommandItem
                                  key={i}
                                  value={supplier.id.toString()}
                                  onSelect={(value) => {
                                    field.onChange(value);
                                  }}
                                >
                                  <CheckIcon
                                    className={cn(
                                      "mr-2 size-4",
                                      field.value &&
                                        field.value.toUpperCase() ===
                                          supplier.id.toString()
                                        ? "opacity-100"
                                        : "opacity-0"
                                    )}
                                  />
                                  {supplier.name}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </Command>
                        </PopoverContent>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant="outline"
                              role="combobox"
                              className={cn(
                                "justify-between",
                                !field.value && "text-accent-foreground",
                                "w-[260px]"
                              )}
                            >
                              {field.value
                                ? searchSuppliersData.find(
                                    (item) => item.id.toString() === field.value
                                  )?.name
                                : "Select supplier"}
                              <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  </div>
                )}
              />
              <FormField
                control={form.control}
                name="taxCode"
                render={({field}) => (
                  <div className="space-y-2">
                    <FormItem>
                      <Popover>
                        <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                          <Command>
                            <CommandGroup>
                              {searchSuppliersData.map((supplier, i) => (
                                <CommandItem
                                  key={i}
                                  value={supplier.taxCode.toString()}
                                  onSelect={(value) => {
                                    field.onChange(value);
                                  }}
                                >
                                  <CheckIcon
                                    className={cn(
                                      "mr-2 size-4",
                                      field.value &&
                                        field.value.toUpperCase() ===
                                          supplier.taxCode.toString()
                                        ? "opacity-100"
                                        : "opacity-0"
                                    )}
                                  />
                                  {supplier.taxCode}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </Command>
                        </PopoverContent>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant="outline"
                              role="combobox"
                              className={cn(
                                "justify-between",
                                !field.value && "text-accent-foreground",
                                "w-[260px]"
                              )}
                            >
                              {field.value
                                ? searchSuppliersData.find(
                                    (item) =>
                                      item.taxCode.toString() === field.value
                                  )?.taxCode
                                : "Select tax code"}
                              <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  </div>
                )}
              />

              {isDirty && (
                <Button
                  className="border-destructive-foreground/60 bg-destructive text-destructive-foreground hover:border-destructive-foreground/80 hover:bg-destructive-foreground/40 hover:text-destructive-foreground active:bg-destructive-foreground/60 active:text-destructive-foreground"
                  variant="outline"
                  onClick={() => {
                    form.reset();
                  }}
                >
                  <ListRestartIcon className="mr-2 size-4" />
                  Reset
                </Button>
              )}
            </div>

            <div className="flex flex-col justify-center gap-2 md:flex-row md:items-center">
              <DataTableViewOptions
                table={table}
                columnsOtherName={columnsOtherName}
              />
            </div>
          </div>
        </form>
      </Form>
    </>
  );
}
