import {create} from "zustand";

import {type PaginationMetaType} from "@/lib/ky";
import {type ProxypayCriteriaType} from "@/modules/imremit";

import {type SortMetaType} from "@/utils/query-params-generator";

/**
 * Interface: ProxypayMetaStoreState
 * Defines the shape of the state for the ProxypayPaginationMetaStore
 */
interface ProxypayMetaStoreState {
  /**
   * storeProxypayPaginationMeta
   * Holds the pagination metadata related to Proxypay.
   */
  storeProxypayPaginationMeta: PaginationMetaType | null;

  /**
   * storeProxypayCriteria
   * Holds the criteria by which Proxypay are filtered.
   */
  storeProxypayCriteria: ProxypayCriteriaType | null;

  /**
   * storeProxypaySortMeta
   * Holds the sort metadata related to Proxypay.
   */
  storeProxypaySortMeta: SortMetaType | null;

  /**
   * handleOnPageChange
   * Holds the function to handle page change.
   * This is used to update the page number in the store.
   * This is used in the ProxypayDataTable component.
   *
   * @param pageNumber - The new page number
   * @returns void
   *
   * @example
   */
  handleOnPageChange?: (pageNumber: number) => Promise<void>;
}

/**
 * Interface: ProxypayMetaStoreActions
 * Defines the set of actions for updating the ProxypayMetaStoreState.
 */
interface ProxypayMetaStoreActions {
  /**
   * Action: setStoreProxypayPaginationMeta
   * Updates the 'storeProxypayPaginationMeta' field in the state.
   *
   * @param storeProxypayPaginationMeta - The new value for 'storeProxypayPaginationMeta'
   */
  setStoreProxypayPaginationMeta: (
    storeProxypayPaginationMeta: ProxypayMetaStoreState["storeProxypayPaginationMeta"]
  ) => void;

  /**
   * Action: setStoreProxypayCriteria
   * Updates the 'storeProxypayCriteria' field in the state.
   *
   * @param storeProxypayCriteria - The new value for 'storeProxypayCriteria'
   */
  setStoreProxypayCriteria: (
    storeProxypayCriteria: ProxypayMetaStoreState["storeProxypayCriteria"]
  ) => void;

  /**
   * Action: setStoreProxypaySortMeta
   * Updates the 'storeProxypaySortMeta' field in the state.
   *
   * @param storeProxypaySortMeta - The new value for 'storeProxypaySortMeta'
   */
  setStoreProxypaySortMeta: (
    storeProxypaySortMeta: ProxypayMetaStoreState["storeProxypaySortMeta"]
  ) => void;

  /**
   * Action: setHandleOnPageChange
   * Updates the 'handleOnPageChange' field in the state.
   *
   * @param handleOnPageChange - The new value for 'handleOnPageChange'
   */
  setHandleOnPageChange: (
    handleOnPageChange: (pageNumber: number) => Promise<void>
  ) => void;
}

/**
 * Function: useProxypayMetaStore
 * Creates a Zustand store to handle file processing pagination metadata and criteria.
 */
export const useProxypayMetaStore = create<
  ProxypayMetaStoreState & ProxypayMetaStoreActions
>((set) => ({
  // Initialize 'storeProxypayPaginationMeta', 'storeProxypayCriteria' and 'handleOnPageChange'
  // with default values of null
  storeProxypayPaginationMeta: null,
  storeProxypayCriteria: null,
  storeProxypaySortMeta: null,
  handleOnPageChange: undefined,

  /**
   * Implementation for 'setStoreProxypayPaginationMeta' action.
   * Updates the 'storeProxypayPaginationMeta' field in the state.
   *
   * @param storeProxypayPaginationMeta - The new value for 'storeProxypayPaginationMeta'
   */
  setStoreProxypayPaginationMeta: (storeProxypayPaginationMeta) => {
    set(() => ({storeProxypayPaginationMeta}));
  },

  /**
   * Implementation for 'setStoreProxypayCriteria' action.
   * Updates the 'storeProxypayCriteria' field in the state.
   *
   * @param storeProxypayCriteria - The new value for 'storeProxypayCriteria'
   */
  setStoreProxypayCriteria: (storeProxypayCriteria) => {
    set(() => ({storeProxypayCriteria}));
  },

  /**
   * Implementation for 'setStoreProxypaySortMeta' action.
   * Updates the 'storeProxypaySortMeta' field in the state.
   *
   * @param storeProxypaySortMeta - The new value for 'storeProxypaySortMeta'
   */
  setStoreProxypaySortMeta: (storeProxypaySortMeta) => {
    set(() => ({storeProxypaySortMeta}));
  },

  /**
   * Implementation for 'setHandleOnPageChange' action.
   * Updates the 'handleOnPageChange' field in the state.
   *
   * @param handleOnPageChange - The new value for 'handleOnPageChange'
   */
  setHandleOnPageChange: (handleOnPageChange) => {
    set(() => ({handleOnPageChange}));
  },
}));
