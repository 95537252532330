/* eslint-disable */
import {useCallback, useMemo} from "react";

import {useWindowScroll} from "@uidotdev/usehooks"; // 📜 Utilizing a custom hook for tracking window scroll position.
import {debounce} from "lodash"; // ⏲️ Importing lodash's debounce function for optimizing event handling.
import {ChevronUpIcon} from "lucide-react"; // ↗️ Importing an icon component for visual representation of the action.

import {Button} from "@/components/ui/button"; // 🖲️ Importing a reusable Button component from the UI library.
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
// 💡 Importing Tooltip components for enhancing user experience with additional information.
import {Paragraph} from "@/components/ui/typography"; // 📝 Importing a Paragraph component for textual content.

/**
 * Defines the properties accepted by the ReturnToTop component.
 *
 * | Prop  | Type   | Description                                                  |
 * |-------|--------|--------------------------------------------------------------|
 * | delay | number | Optional delay in milliseconds for the debounce function.    |
 */
interface ReturnToTopButtonProps {
  delay?: number;
}

/**
 * Custom hook for handling the scroll-to-top functionality. 🔄
 *
 * @param {number} [delay=20] - Optional delay in milliseconds for the debounce function.
 * @returns {Object} - Contains the current vertical scroll position (`y`) and the function (`handleClick`) to scroll to the top.
 *
 * @example
 * ```ts
 * const { y, handleClick } = useReturnToTop();
 * ```
 */
function useReturnToTop(delay = 20) {
  const [{y}, scrollTo] = useWindowScroll(); // 📜 Hook to obtain current scroll position and scrollTo function.

  const debouncedScrollTo = debounce(scrollTo, delay); // ⏲️ Creating a debounced version of scrollTo to prevent excessive function calls.

  const handleClick = useCallback(() => {
    // 🖱️ Defining a click event handler to scroll to the page's top smoothly.
    debouncedScrollTo({left: 0, top: 0, behavior: "smooth"});
  }, [debouncedScrollTo]);

  return {y, handleClick};
}

/**
 * ReturnToTop React component that displays a button for scrolling back to the top of the page. 🚀
 *
 * @param {ReturnToTopButtonProps} props - Component properties.
 * @returns {JSX.Element | null} - Renders the button for returning to the top, or null if the scroll position is already at the top.
 */
export function ReturnToTop({delay}: ReturnToTopButtonProps) {
  const {y, handleClick} = useReturnToTop(delay); // Utilizing the custom hook for scroll functionality.

  const button = useMemo(
    () => (
      // Memoizing the button to avoid unnecessary re-renders. 🧠
      <TooltipProvider delayDuration={250} skipDelayDuration={350}>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="ghost"
              aria-label="Return to top"
              className="fixed bottom-6 right-4 cursor-pointer bg-transparent p-1 backdrop-blur-sm hover:bg-neutral/70"
              onClick={handleClick}
            >
              {/* 🚀 Providing an accessible label for screen readers. */}
              <span className="sr-only">Return to top</span>
              <ChevronUpIcon
                size={42}
                className="text-theme hover:text-theme/80"
              />
            </Button>
          </TooltipTrigger>
          <TooltipContent>
            <TooltipArrow />
            <Paragraph className="text-xs font-semibold">
              Return to top
            </Paragraph>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    ),
    [handleClick]
  );

  return y === 0 ? null : button; // Conditionally rendering the button based on the scroll position. 📌
}
