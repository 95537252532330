import {TabsContent, TabsList} from "@radix-ui/react-tabs";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {Tabs, TabsTrigger} from "@/components/ui/tabs";
import {Heading1, Paragraph} from "@/components/ui/typography";

import {
  adminHelpItems,
  duplicateToolHelpItems,
  imREmitHelpItems,
  invoiceTrackerHelpItems,
  statementReconHelpItems,
} from "@/modules/help";

// Creating a functional component named 'HelpPage'
export function HelpPage() {
  return (
    <article
      className="flex flex-1 flex-col "
      role="region"
      aria-roledescription="This is the help page. View the help of using this website here."
    >
      <header className="mb-4 flex w-full justify-between gap-x-6">
        <Heading1>Help</Heading1>
      </header>
      <section className="flex flex-1 flex-col" role="presentation">
        <Tabs defaultValue="invoice-tracker">
          <TabsList className="pb-2">
            <TabsTrigger value="invoice-tracker">Invoice Tracker</TabsTrigger>
            <TabsTrigger value="imremit">imREmit</TabsTrigger>
            <TabsTrigger value="duplicate-tool">Duplicate Tool</TabsTrigger>
            <TabsTrigger value="statement-recon">Statement Recon</TabsTrigger>
            <TabsTrigger value="admin">Admin</TabsTrigger>
          </TabsList>
          <TabsContent value="invoice-tracker">
            <Accordion
              className="rounded-md bg-root p-4 shadow-md"
              type="multiple"
            >
              {invoiceTrackerHelpItems.map((item, index) => (
                <AccordionItem
                  key={`item-${index.toString()}`}
                  value={`item-${index.toString()}`}
                >
                  <AccordionTrigger>{item.title}</AccordionTrigger>
                  <AccordionContent>{item.content}</AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </TabsContent>
          <TabsContent value="imremit">
            <Accordion
              className="rounded-md bg-root p-4 shadow-md"
              type="multiple"
            >
              {imREmitHelpItems.map((item, index) => (
                <AccordionItem
                  key={`item-${index.toString()}`}
                  value={`item-${index.toString()}`}
                >
                  <AccordionTrigger>{item.title}</AccordionTrigger>
                  <AccordionContent>{item.content}</AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </TabsContent>
          <TabsContent value="duplicate-tool">
            <Paragraph>
              <span className="text-2xl font-extrabold text-theme">
                Duplicate Tool
              </span>{" "}
              - Coming Soon
            </Paragraph>
            <Accordion
              className="rounded-md bg-root p-4 shadow-md"
              type="multiple"
            >
              {duplicateToolHelpItems.map((item, index) => (
                <AccordionItem
                  key={`item-${index.toString()}`}
                  value={`item-${index.toString()}`}
                >
                  <AccordionTrigger>{item.title}</AccordionTrigger>
                  <AccordionContent>{item.content}</AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </TabsContent>
          <TabsContent value="statement-recon">
            <Paragraph>
              <span className="text-2xl font-extrabold text-theme">
                Statement Recon
              </span>{" "}
              - Coming Soon
            </Paragraph>
            <Accordion
              className="rounded-md bg-root p-4 shadow-md"
              type="multiple"
            >
              {statementReconHelpItems.map((item, index) => (
                <AccordionItem
                  key={`item-${index.toString()}`}
                  value={`item-${index.toString()}`}
                >
                  <AccordionTrigger>{item.title}</AccordionTrigger>
                  <AccordionContent>{item.content}</AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </TabsContent>
          <TabsContent value="admin">
            <Paragraph>
              <span className="text-2xl font-extrabold text-theme">Admin</span>{" "}
              - Coming Soon
            </Paragraph>
            <Accordion
              className="rounded-md bg-root p-4 shadow-md"
              type="multiple"
            >
              {adminHelpItems.map((item, index) => (
                <AccordionItem
                  key={`item-${index.toString()}`}
                  value={`item-${index.toString()}`}
                >
                  <AccordionTrigger>{item.title}</AccordionTrigger>
                  <AccordionContent>{item.content}</AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </TabsContent>
        </Tabs>
      </section>
    </article>
  );
}
