import {useMemo} from "react";

import {
  ArrowLeftFromLineIcon,
  ArrowLeftIcon,
  ArrowRightFromLineIcon,
  ArrowRightIcon,
} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Skeleton} from "@/components/ui/skeleton";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {Paragraph} from "@/components/ui/typography";

import {type PaginationMetaType} from "@/lib/ky";

const DOTS = "...";

/**
 * Utility function to generate a range of numbers between `start` and `end`.
 *
 * @param {number} start - The start index for the range
 * @param {number} end - The end index for the range
 * @returns {number[]} Array containing numbers from `start` to `end`
 */
const range = (start: number, end: number): number[] => {
  return Array.from({length: end - start + 1}, (_, i) => start + i);
};

/**
 * Interface for usePagination hook props
 *
 * @typedef {Object} UsePaginationProps
 * @property {number} totalEntriesCount - Total number of items to paginate
 * @property {number} pageSize - Number of items per page
 * @property {number} [siblingCount=1] - Number of pages to show on either side of current page
 * @property {number} currentPage - Currently active page
 * @property {boolean} first - Whether current page is first
 * @property {boolean} last - Whether current page is last
 */
interface UsePaginationProps {
  totalPageCount: number;
  siblingCount?: number;
  currentPage: number;
  first: boolean;
  last: boolean;
}

/**
 * Type definition for pagination range.
 * Could contain page numbers or ellipsis represented by the constant DOTS.
 *
 * @typedef {Array.<(number|string)>} PaginationRangeType
 */
type PaginationRangeType = (number | typeof DOTS)[];

/**
 * Custom React hook to calculate pagination range.
 *
 * @param {UsePaginationProps} props - Configuration options for pagination.
 * @returns {PaginationRangeType} - Calculated pagination range.
 */
export const usePagination = ({
  totalPageCount, // Total number of pages.
  siblingCount = 1, // Number of pages to show on either side of the current page.
  currentPage, // Currently active page.
  first, // Whether the current page is the first page.
  last, // Whether the current page is the last page.
}: UsePaginationProps): PaginationRangeType => {
  return useMemo(() => {
    // Calculate the total number of page slots including siblings and boundary pages.
    const totalPageSlots = 2 * siblingCount + 5;

    // Case 1: When total pages is less than or equal to the number of slots.
    // In this case, return the range from 1 to totalPageCount.
    if (totalPageCount <= totalPageSlots) {
      return range(1, totalPageCount);
    }

    // Case 2: When the current page is the last page.
    // Start the range with 1 up to the second last slot, followed by DOTS, then the current page incremented by 1.
    if (last) {
      // NOTE: LOL this only works if the siblingCount is 1. Someone who is good at math, try to fix this.
      return [
        DOTS,
        ...range(
          currentPage - (siblingCount * 2 + 2),
          currentPage - siblingCount + 1
        ),
        currentPage + 1,
      ];
    }

    // Case 3: When the current page is closer to the start.
    // Return range from 1 to (totalPageSlots - 2), followed by DOTS and the totalPageCount.
    if (
      currentPage <=
      totalPageSlots - 2 - Math.ceil((totalPageSlots - 4) / 2)
    ) {
      return [...range(1, totalPageSlots - 2), DOTS, totalPageCount];
    }

    // Case 4: When the current page is either the first page or closer to the end.
    // Return 1, DOTS, and a range from (totalPageCount - (totalPageSlots - 3)) to totalPageCount.
    if (
      first ||
      currentPage >=
        totalPageCount -
          (totalPageSlots - 3 - Math.floor((totalPageSlots - 4) / 2))
    ) {
      return [
        1,
        DOTS,
        ...range(totalPageCount - (totalPageSlots - 3), totalPageCount),
      ];
    }

    // Calculate the starting and ending indices of the sibling pages on the left and right of the current page.
    const leftSiblingStart = Math.max(currentPage - siblingCount, 2) + 1;
    const rightSiblingEnd =
      Math.min(currentPage + siblingCount, totalPageCount - 1) + 1;

    // Default case: When the current page is neither close to the start nor the end.
    // Return 1, DOTS, range from leftSiblingStart to rightSiblingEnd, DOTS, and totalPageCount.
    return [
      1,
      DOTS,
      ...range(leftSiblingStart, rightSiblingEnd),
      DOTS,
      totalPageCount,
    ];
  }, [totalPageCount, siblingCount, currentPage, first, last]); // List of dependencies for useMemo.
};

/**
 * Interface for Pagination component props
 *
 * @typedef {Object} PaginationProps
 * @property {PaginationMetaType|null} paginationMeta - Meta data for pagination
 * @property {(pageNumber: number) => Promise<void>} [onPageChange] - Callback function to call when page changes
 */
interface PaginationProps {
  paginationMeta: PaginationMetaType | null;
  onPageChange?: (pageNumber: number) => Promise<void>;
}

/**
 * Function to generate pagination buttons based on provided meta data.
 *
 * @param {PaginationMetaType} paginationMeta - Meta information for pagination
 * @param {PaginationRangeType} paginationRange - Calculated pagination range
 * @param {(pageNumber: number) => Promise<void>} onPageChange - Callback for page changes
 * @returns {React.ReactNode[]} - Array of React components representing pagination buttons
 */
const generatePaginationButtons = (
  paginationMeta: PaginationMetaType,
  paginationRange: PaginationRangeType,
  onPageChange: (pageNumber: number) => Promise<void>
) => {
  const buttons = [];
  const {pageNumber, totalPages, first, last} = paginationMeta;

  // First and Previous buttons
  buttons.push(
    <Tooltip key="first">
      <TooltipTrigger asChild>
        <Button
          aria-disabled={first}
          disabled={first}
          onClick={() => {
            void onPageChange(0);
          }}
        >
          <ArrowLeftFromLineIcon className="size-4" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        <TooltipArrow />
        <Paragraph>First Page</Paragraph>
      </TooltipContent>
    </Tooltip>,
    <Tooltip key="previous">
      <TooltipTrigger asChild>
        <Button
          aria-disabled={first}
          disabled={first}
          onClick={() => {
            void onPageChange(pageNumber - 1);
          }}
        >
          <ArrowLeftIcon className="size-4" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        <TooltipArrow />
        <Paragraph>Previous Page</Paragraph>
      </TooltipContent>
    </Tooltip>
  );

  paginationRange.forEach((pageNumber, index) => {
    buttons.push(
      <Tooltip key={`page-${pageNumber}-${index}`}>
        <TooltipTrigger asChild>
          <Button
            disabled={typeof pageNumber !== "number"}
            aria-disabled={typeof pageNumber !== "number"}
            variant={
              typeof pageNumber === "number" &&
              pageNumber === paginationMeta.pageNumber + 1
                ? "affirmative"
                : "outline"
            }
            onClick={() => {
              if (typeof pageNumber === "number") {
                void onPageChange(pageNumber - 1);
              }
            }}
          >
            {pageNumber}
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <TooltipArrow />
          {typeof pageNumber === "number" && (
            <Paragraph>{`Page ${pageNumber}`}</Paragraph>
          )}
          {typeof pageNumber === "string" && <Paragraph>Ellipsis</Paragraph>}
        </TooltipContent>
      </Tooltip>
    );
  });

  // Next and Last buttons
  buttons.push(
    <Tooltip key="next">
      <TooltipTrigger asChild>
        <Button
          aria-disabled={last}
          disabled={last}
          onClick={() => {
            void onPageChange(pageNumber + 1);
          }}
        >
          <ArrowRightIcon className="size-4" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        <TooltipArrow />
        <Paragraph>Next Page</Paragraph>
      </TooltipContent>
    </Tooltip>,
    <Tooltip key="last">
      <TooltipTrigger asChild>
        <Button
          aria-disabled={last}
          disabled={last}
          onClick={() => {
            void onPageChange(totalPages - 1);
          }}
        >
          <ArrowRightFromLineIcon className="size-4" />
        </Button>
      </TooltipTrigger>
      <TooltipContent>
        <TooltipArrow />
        <Paragraph>Last Page</Paragraph>
      </TooltipContent>
    </Tooltip>
  );

  return buttons;
};

/**
 * Pagination component for displaying pagination controls and status.
 *
 * @component
 * @param {PaginationProps} props - Props for Pagination component
 */
export function Pagination({paginationMeta, onPageChange}: PaginationProps) {
  // Generate pagination range
  const paginationRange = usePagination({
    currentPage: paginationMeta?.pageNumber || 0,
    siblingCount: 1, // hardcoded for now because it doesn't work with any other config lmao
    totalPageCount: paginationMeta?.totalPages || 0,
    first: paginationMeta?.first || false,
    last: paginationMeta?.last || false,
  });

  // Memoize pagination buttons
  const memoizedPaginationButtons = useMemo(() => {
    // Show loading section if pagination meta or page change callback is not provided
    if (!paginationMeta || !onPageChange) {
      return null;
    }
    // Generate pagination buttons
    return generatePaginationButtons(
      paginationMeta,
      paginationRange,
      onPageChange
    );
  }, [paginationMeta, paginationRange, onPageChange]);

  // Show loading section if pagination meta or page change callback is not provided
  if (!paginationMeta || !onPageChange) {
    return (
      <section className="flex w-full justify-start">
        <div className="flex items-center justify-center gap-x-2">
          <Button aria-disabled disabled>
            <ArrowLeftFromLineIcon className="size-4" />
          </Button>
          <Button aria-disabled disabled>
            <ArrowLeftFromLineIcon className="size-4" />
          </Button>
          {Array.from({length: 8}, (_, index) => (
            <Skeleton
              key={`skeleton-${index}`}
              className="h-10 w-10 rounded-sm"
              colour={index % 2 === 0 ? "background" : "foreground"}
            />
          ))}
          <Button aria-disabled disabled>
            <ArrowRightFromLineIcon className="size-4" />
          </Button>
          <Button aria-disabled disabled>
            <ArrowRightFromLineIcon className="size-4" />
          </Button>
        </div>
      </section>
    );
  }

  // Show nothing if there are no pages
  if (paginationMeta.totalPages === 0) {
    return null;
  }

  return (
    <section className="flex w-full justify-between">
      <div className="flex items-center justify-center gap-x-2">
        <TooltipProvider delayDuration={300}>
          {memoizedPaginationButtons}
        </TooltipProvider>
      </div>
      <div className="flex flex-col">
        <Paragraph>
          Page:{" "}
          <span className="font-bold">{paginationMeta.pageNumber + 1}</span> of{" "}
          <span className="font-bold">{paginationMeta.totalPages}</span>
        </Paragraph>
        <Paragraph>
          Viewing Entries:{" "}
          <span className="font-bold">
            {paginationMeta.last
              ? paginationMeta.totalElements -
                paginationMeta.numberOfElements +
                1
              : paginationMeta.numberOfElements *
                  (paginationMeta.pageNumber + 1) -
                paginationMeta.numberOfElements +
                1}
          </span>{" "}
          to{" "}
          <span className="font-bold">
            {
              // Check if this is the last page
              paginationMeta.last
                ? paginationMeta.totalElements
                : Math.min(
                    (paginationMeta.pageNumber + 1) *
                      paginationMeta.numberOfElements,
                    paginationMeta.totalElements
                  )
            }
          </span>
        </Paragraph>
      </div>
    </section>
  );
}
