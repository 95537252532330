import {useEffect} from "react";

import {useParams} from "@tanstack/react-router";

import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {ImREmitRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";
import {KeycloakRoleEnum, useAuthorization} from "@/modules/auth";
import {EditGenericSupplierScriptPage} from "@/modules/imremit";

export function EditSupplierScriptPage() {
  const {setimREmitRoute} = useRoutesStore();

  useEffect(() => {
    setimREmitRoute(
      ImREmitRoutesSchema.Values["/app/imremit/supplier-script-management"]
    );
  }, [setimREmitRoute]);

  const {supplierId, paymentId, moduleName} = useParams({
    from: "/app/imremit/supplier-script-management/$moduleName/$supplierId/edit/$paymentId",
  });

  const {checkPoliciesAccess} = useAuthorization();

  // Handle unauthorized access
  if (
    !checkPoliciesAccess([KeycloakRoleEnum.UPDATE_SUPPLIER_SCRIPT_MANAGEMENT])
  ) {
    return <NotAuthorizedPage />;
  }

  return (
    <EditGenericSupplierScriptPage
      supplierId={supplierId}
      moduleName={moduleName}
      paymentId={paymentId}
    />
  );
}
