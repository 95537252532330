import {createRoute} from "@tanstack/react-router";

import {PageNotFound} from "@/components/layout/not-found";

import {RoutesDirectory} from "@/lib/routes/directory";
import {authorizedRootRoute} from "@/lib/routes/root";
import {
  AddCustomerPage,
  AddNewSupplierPage,
  AdminOutlet,
  AdminReportPage,
  AlertManagementPage,
  CreatePIDMappingPage,
  CreateUserPage,
  CustomerManagementPage,
  CustomerModuleConfiguration,
  CustomerOnboardingDuplicatePaymentsPage,
  CustomerOnboardingImREmitEmailConfigurationPage,
  CustomerOnboardingImREmitParticipantRegisterPage,
  CustomerOnboardingImREmitPaymentMethodPage,
  CustomerOnboardingImREmitRunnerConfigPage,
  CustomerOnboardingImREmitSFTPConfigurationPage,
  EditCustomerDetailsPage,
  EditPIDMappingPage,
  EditSuppliersPage,
  EditUserPage,
  MasterRoleConfiguration,
  MISPaymentsPage,
  // ImREmitCreateMapping,
  // ImREmitMapping,
  OnboardDupesPage,
  OnboardImRemitPage,
  OnboardStatementReconPage,
  SearchPIDMappingPage,
  SupplierManagementPage,
  SupplierOnboardingPage,
  UserManagementPage,
  UserModuleConfiguration,
  ViewCustomerDetailsPage,
  ViewUserPage,
} from "@/modules/admin";

export const adminRootRoute = createRoute({
  path: RoutesDirectory.ADMIN,
  component: AdminOutlet,
  getParentRoute: () => authorizedRootRoute,
});

export const adminCustomerManagementRoute = createRoute({
  path: RoutesDirectory.CUSTOMER_MANAGEMENT,
  component: CustomerManagementPage,
  getParentRoute: () => adminRootRoute,
});

export const adminCustomerManagementCreateRoute = createRoute({
  path: `${RoutesDirectory.CUSTOMER_MANAGEMENT}${RoutesDirectory.CREATE}`,
  component: AddCustomerPage,
  getParentRoute: () => adminRootRoute,
});

export const adminCustomerManagementImREmitPMOnboardingRoute = createRoute({
  path: `${RoutesDirectory.CUSTOMER_MANAGEMENT}/imremit/$externalId${RoutesDirectory.ONBOARD}${RoutesDirectory.PAYMENT_METHOD}`,
  component: CustomerOnboardingImREmitPaymentMethodPage,
  getParentRoute: () => adminRootRoute,
});

export const adminCustomerManagementImREmitPROnboardingRoute = createRoute({
  path: `${RoutesDirectory.CUSTOMER_MANAGEMENT}/imremit/$externalId${RoutesDirectory.ONBOARD}${RoutesDirectory.PARTICIPANT_REGISTER}`,
  component: CustomerOnboardingImREmitParticipantRegisterPage,
  getParentRoute: () => adminRootRoute,
});

export const adminCustomerManagementImREmitECOnboardingRoute = createRoute({
  path: `${RoutesDirectory.CUSTOMER_MANAGEMENT}/imremit/$externalId${RoutesDirectory.ONBOARD}${RoutesDirectory.EMAIL_CONFIGURATION}`,
  component: CustomerOnboardingImREmitEmailConfigurationPage,
  getParentRoute: () => adminRootRoute,
});

export const adminCustomerManagementImREmitSCOnboardingRoute = createRoute({
  path: `${RoutesDirectory.CUSTOMER_MANAGEMENT}/imremit/$externalId${RoutesDirectory.ONBOARD}${RoutesDirectory.SFTP_CONFIGURATION}`,
  component: CustomerOnboardingImREmitSFTPConfigurationPage,
  getParentRoute: () => adminRootRoute,
});

export const adminCustomerManagementImREmitRCOnboardingRoute = createRoute({
  path: `${RoutesDirectory.CUSTOMER_MANAGEMENT}/imremit/$externalId${RoutesDirectory.ONBOARD}${RoutesDirectory.RUNNER_CONFIGURATION}`,
  component: CustomerOnboardingImREmitRunnerConfigPage,
  getParentRoute: () => adminRootRoute,
});

export const adminCustomerManagementDuplicatePaymentsOnboardingRoute =
  createRoute({
    path: `${RoutesDirectory.CUSTOMER_MANAGEMENT}/duplicate-payments/$externalId${RoutesDirectory.ONBOARD}`,
    component: CustomerOnboardingDuplicatePaymentsPage,
    getParentRoute: () => adminRootRoute,
  });

export const adminCustomerManagementViewDetailsRoute = createRoute({
  path: `${RoutesDirectory.CUSTOMER_MANAGEMENT}/$externalId${RoutesDirectory.VIEW}`,
  component: ViewCustomerDetailsPage,
  getParentRoute: () => adminRootRoute,
});

export const adminCustomerManagementEditDetailsRoute = createRoute({
  path: `${RoutesDirectory.CUSTOMER_MANAGEMENT}/$externalId${RoutesDirectory.EDIT}`,
  component: EditCustomerDetailsPage,
  getParentRoute: () => adminRootRoute,
});

export const adminCustomerManagementOnboardImRemitRoute = createRoute({
  path: `${RoutesDirectory.CUSTOMER_MANAGEMENT}/$externalId${RoutesDirectory.ONBOARD}${RoutesDirectory.IMREMIT}`,
  component: OnboardImRemitPage,
  getParentRoute: () => adminRootRoute,
});

export const adminCustomerManagementOnboardDupesRoute = createRoute({
  path: `${RoutesDirectory.CUSTOMER_MANAGEMENT}/$externalId${RoutesDirectory.ONBOARD}${RoutesDirectory.DUPLICATE_PAYMENT}`,
  component: OnboardDupesPage,
  getParentRoute: () => adminRootRoute,
});

export const adminCustomerManagementOnboardStatementReconRoute = createRoute({
  path: `${RoutesDirectory.CUSTOMER_MANAGEMENT}/$externalId${RoutesDirectory.ONBOARD}${RoutesDirectory.STATEMENT_RECON}`,
  component: OnboardStatementReconPage,
  getParentRoute: () => adminRootRoute,
});

export const adminSearchPIDMappingRoute = createRoute({
  path: RoutesDirectory.PID_MAPPING,
  component: SearchPIDMappingPage,
  getParentRoute: () => adminRootRoute,
});

export const adminCreatePIDMappingRoute = createRoute({
  path: "/pid-mapping/create",
  component: CreatePIDMappingPage,
  getParentRoute: () => adminRootRoute,
});

export const adminEditPIDMappingRoute = createRoute({
  path: "/pid-mapping/$pid/edit",
  component: EditPIDMappingPage,
  getParentRoute: () => adminRootRoute,
});

export const adminMasterRoleSettingsRoute = createRoute({
  path: RoutesDirectory.MASTER_ROLE_SETTINGS,
  component: MasterRoleConfiguration,
  getParentRoute: () => adminRootRoute,
});

export const adminCustomerModuleManagementRoute = createRoute({
  path: RoutesDirectory.CUSTOMER_MODULE_MANAGEMENT,
  component: CustomerModuleConfiguration,
  getParentRoute: () => adminRootRoute,
});

export const adminAlertManagementRoute = createRoute({
  path: RoutesDirectory.ALERT_MANAGEMENT,
  component: AlertManagementPage,
  getParentRoute: () => adminRootRoute,
});

export const adminUserRoleRelationRoute = createRoute({
  path: RoutesDirectory.USER_ROLE_RELATION,
  component: UserModuleConfiguration,
  getParentRoute: () => adminRootRoute,
});

export const adminUserManagementRoute = createRoute({
  path: RoutesDirectory.USER_MANAGEMENT,
  component: UserManagementPage,
  getParentRoute: () => adminRootRoute,
});

export const adminCreateUserRoute = createRoute({
  path: `${RoutesDirectory.USER_MANAGEMENT}${RoutesDirectory.CREATE}`,
  component: CreateUserPage,
  getParentRoute: () => adminRootRoute,
});

export const adminEditUserRoute = createRoute({
  path: `${RoutesDirectory.USER_MANAGEMENT}/$userId${RoutesDirectory.EDIT}`,
  component: EditUserPage,
  getParentRoute: () => adminRootRoute,
});

export const adminViewUserRoute = createRoute({
  path: `${RoutesDirectory.USER_MANAGEMENT}/$userId${RoutesDirectory.VIEW}`,
  component: ViewUserPage,
  getParentRoute: () => adminRootRoute,
});

export const adminSupplierOnboardingRoute = createRoute({
  path: RoutesDirectory.SUPPLIER_ONBOARDING,
  component: SupplierOnboardingPage,
  getParentRoute: () => adminRootRoute,
});

export const adReportsRoute = createRoute({
  path: RoutesDirectory.ADMIN_REPORT,
  component: AdminReportPage,
  getParentRoute: () => adminRootRoute,
});

export const supplierManagementRoute = createRoute({
  path: RoutesDirectory.SUPPLIER_MANAGEMENT,
  component: SupplierManagementPage,
  getParentRoute: () => adminRootRoute,
});

export const createSuppliersRoute = createRoute({
  path: `${RoutesDirectory.SUPPLIER_MANAGEMENT}${RoutesDirectory.CREATE}`,
  component: AddNewSupplierPage,
  getParentRoute: () => adminRootRoute,
});

export const editSuppliersRoute = createRoute({
  path: `${RoutesDirectory.SUPPLIER_MANAGEMENT}/$supplierId${RoutesDirectory.EDIT}`,
  component: EditSuppliersPage,
  getParentRoute: () => adminRootRoute,
});

export const misPaymentsReceivedRoute = createRoute({
  path: RoutesDirectory.MIS,
  component: MISPaymentsPage,
  getParentRoute: () => adminRootRoute,
});

export const adminNotFoundRoute = createRoute({
  // all routes from /app that are not defined will be redirected to this route
  path: RoutesDirectory.CATCH_ALL,
  component: PageNotFound,
  getParentRoute: () => adminRootRoute,
});
