import {useEffect, useState} from "react";

import type {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
} from "@tanstack/react-table";
import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {ScrollArea} from "@/components/ui/scroll-area";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {Paragraph} from "@/components/ui/typography";
import {DataTablePagination} from "@/components/craft/data-table";
import {TableSkeleton} from "@/components/craft/table";

import {usePreferencesStore} from "@/lib/stores";
import {PaymentCommentsDataTableToolbar} from "@/modules/imremit";

interface PaymentCommentsDataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  isPending?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
}

export function PaymentCommentsDataTable<TData, TValue>({
  columns,
  data,
  isPending,
  isSuccess,
  isError,
}: PaymentCommentsDataTableProps<TData, TValue>) {
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [sorting, setSorting] = useState<SortingState>([]);

  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  // The `usePreferencesStore` hook from the `@/lib/stores` module provides an API for interacting with the preferences store.
  // We are using it to get the number of rows per page to display in the table.
  const {numRowsPerPage} = usePreferencesStore();

  // Set the page size when the number of rows per page changes
  useEffect(() => {
    table.setPageSize(Number(numRowsPerPage));
  }, [numRowsPerPage, table]);

  return (
    <section className="space-y-2">
      <PaymentCommentsDataTableToolbar
        table={table}
        isPending={isPending}
        isErrored={isError}
      />
      <ScrollArea className="max-h-[420px]">
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            {isError ? (
              <TableBody>
                <TableRow>
                  <TableCell
                    className="h-24 text-center"
                    colSpan={columns.length}
                  >
                    Error loading data.
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : !isSuccess ? (
              <TableSkeleton
                columnsLength={columns.length}
                omittedColumns={{}}
                numRowsPerPage={parseInt(numRowsPerPage)}
              />
            ) : (
              <TableBody>
                {table.getRowModel().rows.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && "selected"}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="h-20">
                      <Paragraph className="text-center font-semibold">
                        No results found.
                      </Paragraph>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
            <TableFooter>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} colSpan={header.colSpan}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableFooter>
          </Table>
        </div>
      </ScrollArea>

      <DataTablePagination table={table} />
    </section>
  );
}
