import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";

import {FallbackMessages} from "@/utils/constants";

interface SupplierScriptDisplayType {
  label: string;
  value?: string | number | null;
}

export const SupplierScriptDisplay: React.FC<SupplierScriptDisplayType> = ({
  label,
  value,
}) => {
  return (
    <Paragraph className="flex w-full flex-col border border-muted bg-root px-2 py-1 md:max-w-[calc(100%/4)] lg:max-w-[calc(100%/7)] xl:max-w-[calc(100%/7)]">
      <span className="font-semibold">{label}</span>
      <span className="whitespace-wrap mt-auto break-all">
        {value !== undefined && value !== "" ? (
          value
        ) : (
          <Badge variant="destructive">{FallbackMessages.NOT_APPLICABLE}</Badge>
        )}
      </span>
    </Paragraph>
  );
};
