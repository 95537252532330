import {Link} from "@tanstack/react-router";
import {HomeIcon} from "lucide-react";

import {buttonVariants} from "@/components/ui/button";

import {cn} from "@/lib/utils";

// This HomeButton function component is used to create a navigation button that routes the user back to the homepage.
export function HomeButton() {
  // Here we use the Link component from tanstack/router, which allows for client-side
  // routing, i.e. navigation between different parts of the app without a full page reload.
  return (
    <Link to="/" className={cn(buttonVariants({variant: "ghost"}), "text-xl")}>
      Home
      <HomeIcon className="ml-2" />
      <span className="sr-only">Go home</span>
    </Link>
  );
}
