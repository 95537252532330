import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  customerOnboardingStatusKeys,
  type CustomerOnboardingStatusType,
} from "@/modules/customers";

type QueryFnType = typeof getCustomerOnboardinStatus;

export function getCustomerOnboardinStatus(id: string) {
  return kyApiFn<CustomerOnboardingStatusType>(
    `customer/v1/customers/moduleinfo?externalId=${id}`,
    "get"
  );
}

export function useGetCustomerOnboardinStatus(
  id: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: customerOnboardingStatusKeys.byCustomerId(id), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getCustomerOnboardinStatus(id), // Setting the function to be used for fetching data
    enabled: !!id, // Setting the query to be enabled only if the search query string is not empty
  });
}
