import {useState} from "react";

import {FilePenLine, FilePlus2Icon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {Skeleton} from "@/components/ui/skeleton";

import type {
  DupesExemptionCharacterTypesType,
  DupesExemptionCharacterWithTypesType,
} from "@/modules/duplicate-payments";
import {
  DupesCreateCriteriaForm,
  useAllDupesExemptionCharacterTypes,
  useGetDupesCriteriaSettingsByExternalId,
} from "@/modules/duplicate-payments";

interface CreateCriteriaDialogProps {
  exemptionCharacterType: DupesExemptionCharacterTypesType;
  externalId: string;
  creditPairLogic: DupesExemptionCharacterWithTypesType | undefined;
  numericCheck: DupesExemptionCharacterWithTypesType | undefined;
}

/**
 *
 * @param exemptionCharacterType
 * @param creditPairLogic
 * @param numericCheck
 * @returns {string}
 */
const getAddEditLabel = (
  exemptionCharacterType: DupesExemptionCharacterTypesType,
  creditPairLogic: DupesExemptionCharacterWithTypesType | undefined,
  numericCheck: DupesExemptionCharacterWithTypesType | undefined
) => {
  const {exemption_type_id} = exemptionCharacterType;

  switch (exemption_type_id) {
    case "14":
      return creditPairLogic?.character ? "Edit Criteria" : "Add Criteria";
    case "24":
      return numericCheck?.character ? "Edit Criteria" : "Add Criteria";
    default:
      return "Add Criteria";
  }
};

function CreateCriteriaDialog({
  exemptionCharacterType,
  externalId,
  creditPairLogic,
  numericCheck,
}: CreateCriteriaDialogProps) {
  const [open, setOpen] = useState(false);

  const addEditLabel = getAddEditLabel(
    exemptionCharacterType,
    creditPairLogic,
    numericCheck
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="w-full gap-2">
          <span className="sr-only">{addEditLabel}</span>
          {addEditLabel}
          {addEditLabel === "Add Criteria" ? (
            <FilePlus2Icon className="size-4" />
          ) : (
            <FilePenLine className="size-4" />
          )}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{addEditLabel}</DialogTitle>
          <DialogDescription>
            {addEditLabel} for {exemptionCharacterType.type}
          </DialogDescription>
        </DialogHeader>
        <DupesCreateCriteriaForm
          exemptionTypeId={exemptionCharacterType.exemption_type_id}
          exemptionFormLabel={exemptionCharacterType.input_label}
          externalId={externalId}
          setOpenDialog={setOpen} // 👈 Pass setOpen to control dialog visibility
          addEditLabel={addEditLabel}
          creditPairLogicExemptionId={creditPairLogic?.exemption_id}
          numericCheckValueExemptionId={numericCheck?.exemption_id}
          creditPairLogicValue={
            creditPairLogic?.character ? creditPairLogic.character : ""
          }
          numericCheckValue={
            numericCheck?.character ? numericCheck.character : ""
          }
          exemptionEntryType={
            exemptionCharacterType.entry_type
              ? exemptionCharacterType.entry_type
              : ""
          }
          exemptionEntryValues={
            exemptionCharacterType.entry_values
              ? exemptionCharacterType.entry_values
              : ""
          }
        />
      </DialogContent>
    </Dialog>
  );
}

interface DupesCriteriaCardsProps {
  externalId: string;
}

export function DupesCriteriaCards({externalId}: DupesCriteriaCardsProps) {
  const allDupesExemptionCharacterTypesQuery =
    useAllDupesExemptionCharacterTypes();

  console.log(
    "allDupesExemptionCharacterTypesQuery:",
    allDupesExemptionCharacterTypesQuery
  );

  const exemptionCharacterTypesLoading =
    allDupesExemptionCharacterTypesQuery.isLoading;
  const exemptionCharacterTypesError =
    allDupesExemptionCharacterTypesQuery.isError;
  const exemptionCharacterTypesData =
    allDupesExemptionCharacterTypesQuery.data?.content || [];

  const allDupesCriteriaExemaptionTypesQuery =
    useGetDupesCriteriaSettingsByExternalId(externalId);

  const criteriaExemaptionTypesData =
    allDupesCriteriaExemaptionTypesQuery.data?.content || [];

  console.log("criteriaExemaptionTypesData", criteriaExemaptionTypesData);

  // Get the first item from the filtered arrays
  const creditPairLogic = criteriaExemaptionTypesData.find(
    (item) => item.type_id === "14"
  );

  const numericCheck = criteriaExemaptionTypesData.find(
    (item) => item.type_id === "24"
  );

  return (
    <section className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4">
      {exemptionCharacterTypesData.map((exemptionCharacterType) => {
        return (
          <Card
            key={exemptionCharacterType.exemption_type_id}
            className="flex flex-col"
          >
            <CardHeader className="text-xl font-bold">
              {exemptionCharacterTypesError ? (
                "Error loading data."
              ) : exemptionCharacterTypesLoading ? (
                <Skeleton className="h-6 w-full" />
              ) : (
                exemptionCharacterType.type
              )}
            </CardHeader>
            <CardContent>
              <CardDescription className="font-semibold">
                {exemptionCharacterTypesError ? (
                  "Error loading data."
                ) : exemptionCharacterTypesLoading ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  exemptionCharacterType.description ?? "No description"
                )}
              </CardDescription>
            </CardContent>
            <CardFooter className="mt-auto justify-end gap-2">
              <CreateCriteriaDialog
                exemptionCharacterType={exemptionCharacterType}
                externalId={externalId}
                creditPairLogic={creditPairLogic}
                numericCheck={numericCheck}
              />
            </CardFooter>
          </Card>
        );
      })}
    </section>
  );
}
