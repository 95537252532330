import {MapIcon, NewspaperIcon} from "lucide-react";

import {SubrouteNavigation} from "@/components/craft/subroute-navigation";

import {InvoiceTrackerRoutesSchema} from "@/lib/routes/types";
import {KeycloakRoleEnum} from "@/modules/auth";

// Define an array with the data for each route button. This includes the 'to' route,
// the 'icon' to be displayed, and the 'label' for the button.
// This array holds the configuration for each SubrouteNavigationButton
// Each object in the array represents a button in the navigation menu
const routes = [
  {
    to: InvoiceTrackerRoutesSchema.Values["/app/invoices/tracker"],
    icon: <NewspaperIcon className="h-10 w-10 pl-2" />,
    label: "Invoices Tracker",
    actionRole: KeycloakRoleEnum.READ_INVOICES,
  },
  {
    to: InvoiceTrackerRoutesSchema.Values["/app/invoices/mapping"],
    icon: <MapIcon className="h-10 w-10 pl-2" />,
    label: "Invoices Mapping",
    actionRole: KeycloakRoleEnum.READ_INVOICES,
  },
];

interface InvoicesSubrouteNavigationProps {
  layout?: "horizontal" | "vertical";
}

// This component renders the SubrouteNavigation component with the routes array
// as a prop. It also passes the 'navigationAccessibleLabel' and
// 'navigationAriaLabelledby' props to the SubrouteNavigation component.
export function InvoicesSubrouteNavigation(
  {layout}: InvoicesSubrouteNavigationProps = {layout: "horizontal"}
) {
  return (
    <SubrouteNavigation
      routes={routes}
      layout={layout}
      navigationAccessibleLabel="Invoices Subroute Navigation"
      navigationAriaLabelledby="invoicesSubrouteNavHeader"
    />
  );
}
