import {useEffect, useMemo, useState} from "react";

import {useMutation} from "@tanstack/react-query";
import {BotIcon, HandIcon, PencilIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {Spinner} from "@/components/ui/spinner";
import {Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {
  calculateUserRoles,
  useKeyCloakInstanceStore,
  useUserInfo,
} from "@/modules/auth";
import {
  updatePaymentTypeFn,
  type PaymentCommentsFormDTO,
} from "@/modules/imremit";

import {KeycloakRoles} from "@/utils/constants";

/**
 * Interface for `PaymetMethodType`
 */
interface PaymetMethodTypeProps {
  paymentType: string;
  paymentId: string;
  scriptFlag?: boolean;
  disabled: boolean;
}

/**
 * Custom Hook: Payment Method Type Drop Down
 * @param {string} paymentType - payment method type
 */
export function PaymetMethodType({
  paymentType,
  paymentId,
  scriptFlag,
  disabled,
}: PaymetMethodTypeProps) {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  //Set state for payment method
  const [paymentMethod, setPaymentMethod] = useState<string>("");

  // Get the parsedUserInfo and logoutFn from the useUserInfo hook
  const {parsedUserInfo} = useUserInfo();

  const userRoleDisplay = useMemo(
    () =>
      parsedUserInfo
        ? calculateUserRoles(parsedUserInfo.realm_access?.roles)
        : KeycloakRoles.ProgramManager,
    [parsedUserInfo]
  );

  const paymentTypeUpdateBody: PaymentCommentsFormDTO = {
    userId: storeKeyCloakInstance?.tokenParsed?.name as string,
    comments: `Payment Type Update!`,
    role: userRoleDisplay ? userRoleDisplay : KeycloakRoles.ProgramManager,
  };

  const updatePaymentType = useMutation({
    mutationFn: (paymentType: string) => {
      return updatePaymentTypeFn(paymentTypeUpdateBody, paymentId, paymentType);
    },
    onSuccess: (response) => {
      console.log("updatePaymentTypeResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Payment type updated successfully",
      });
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update payment type",
      });
    },
  });

  useEffect(() => {
    setPaymentMethod(paymentType);
  }, [paymentType, paymentId]);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger
          disabled={
            (paymentType === "MANUAL_PAY_BY_WEB" && scriptFlag === false) ||
            disabled
          }
          asChild
        >
          <Button
            variant="outline"
            className="gap-1 text-left"
            disabled={
              updatePaymentType.isPending ||
              (paymentType === "MANUAL_PAY_BY_WEB" && scriptFlag === false) ||
              disabled
            }
          >
            <span className="sr-only">Payment Method</span>
            {paymentMethod === "MANUAL_PAY_BY_WEB" ? (
              <>
                <HandIcon className="mr-2 size-4" />
                <Paragraph>Manual Pay By Web</Paragraph>
              </>
            ) : paymentMethod === "AUTOMATION_PAY_BY_WEB" ? (
              <>
                <BotIcon className="mr-2 size-4" />
                <Paragraph>Automation Pay By Web</Paragraph>
              </>
            ) : (
              <>
                <PencilIcon className="mr-2 size-4" />
                <Paragraph>Update Payment Method</Paragraph>
              </>
            )}
            {updatePaymentType.isPending ? <Spinner size="xs" /> : ""}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuCheckboxItem
            checked={paymentMethod === "MANUAL_PAY_BY_WEB" ? true : false}
            onCheckedChange={() => {
              setPaymentMethod("MANUAL_PAY_BY_WEB");
              updatePaymentType.mutate("MANUAL_PAY_BY_WEB");
            }}
          >
            Manual Pay By Web
          </DropdownMenuCheckboxItem>
          <DropdownMenuCheckboxItem
            checked={paymentMethod === "AUTOMATION_PAY_BY_WEB" ? true : false}
            onCheckedChange={() => {
              setPaymentMethod("AUTOMATION_PAY_BY_WEB");
              updatePaymentType.mutate("AUTOMATION_PAY_BY_WEB");
            }}
          >
            Automation Pay By Web
          </DropdownMenuCheckboxItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
