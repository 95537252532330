import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  supplierByExternalIdFuzzyQueryKeys,
  type SupplierType,
} from "@/modules/customers";

// Defining a type based on the function `getSuppliersByCustomerFuzzy`
type QueryFnType = typeof getSuppliersByCustomerFuzzy;

// Function to fetch all suppliers
export function getSuppliersByCustomerFuzzy(
  externalId: string,
  supplierFilter: string
) {
  // Fetch all suppliers from API using ky
  const data = kyApiFn<SupplierType[]>(
    `statement-recon/v1/suppliers/fuzzy/${externalId}?filter=${supplierFilter}`,
    "get"
  );
  // TODO: validate data
  // return supplierFetchSchema.parse(data);
  return data; // Returning the fetched data
}

// Custom hook to fetch and cache all supplier data using react-query
export function useGetAllSuppliersByCustomerFuzzy(
  externalId: string, // The search query string
  supplierFilter: string, // The search filter
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: supplierByExternalIdFuzzyQueryKeys.byFilter(
      externalId,
      supplierFilter
    ), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getSuppliersByCustomerFuzzy(externalId, supplierFilter), // Setting the function to be used for fetching data
    enabled: !!externalId && !!supplierFilter, // Setting the query to be enabled only if the `participant id` and `supplier filter` are present
  });
}
