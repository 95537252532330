import type {SetStateAction} from "react";
import {useEffect, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {HardDriveUploadIcon, SquarePenIcon, XIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {Form} from "@/components/ui/form";
import {Spinner} from "@/components/ui/spinner";
import {toast} from "@/components/ui/use-toast";
import {FancyMultiSelect} from "@/components/craft/fancy-multi-select";
import {LoadingSection} from "@/components/craft/loading-section";

import {
  CustomerModuleFormSchema,
  editCustomerModuleFn,
  useCustomerModules,
} from "@/modules/admin";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";
import type {customerFetchSchema} from "@/modules/customers";

export function CustomerModuleUpdateSubscription({
  customerRows,
}: {
  customerRows: z.infer<typeof customerFetchSchema>;
}) {
  const [openDialog, setOpenDialog] = useState(false);

  const editCustomerFormMethods = useForm<
    z.infer<typeof CustomerModuleFormSchema>
  >({
    defaultValues: {
      id: customerRows.id,
      buyerName: customerRows.buyerName,
      externalId: customerRows.externalId,
      status: customerRows.status,
      moduleSubscriptions: customerRows.modules,
    },
    resolver: zodResolver(CustomerModuleFormSchema),
  });

  const editCustomerMutation = useMutation({
    mutationFn: (data: z.infer<typeof CustomerModuleFormSchema>) =>
      editCustomerModuleFn(data),
    onSuccess: (response) => {
      editCustomerFormMethods.reset();
      console.log("response", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Subscription updated successfully",
      });
      setOpenDialog(false);
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update subscription",
      });
    },
  });

  const {modulesData} = useCustomerModules();
  const [selectedValues, setSelectedValues] = useState(modulesData);

  const handleSelect = (
    selectedModules: SetStateAction<{value: string; label: string}[]>
  ) => {
    setSelectedValues(selectedModules);
  };

  function onCustomerModuleUpdateSubmit(
    data: z.infer<typeof CustomerModuleFormSchema>
  ) {
    editCustomerMutation.mutate(data);
  }

  const {isPending: isMutating} = editCustomerMutation;

  useEffect(() => {
    if (customerRows.modules) {
      setSelectedValues(
        customerRows.modules.map((module) => ({
          value: module.moduleId.toString(),
          label: module.moduleName,
        }))
      );
    }
  }, [customerRows.modules]);

  return (
    <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
      <Dialog open={openDialog}>
        <DialogTrigger asChild>
          <AccessPolicyWrapper
            unauthorizedFallback={null}
            policyActions={[KeycloakRoleEnum.CREATE_CUSTOMER_MODULES]}
          >
            <Button
              className="m-2 flex w-64 justify-between gap-4 p-6"
              variant="secondary"
              disabled={isMutating}
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              <span className="sr-only">Update Subscription</span>
              Update Subscription
              {isMutating ? (
                <LoadingSection sectionSize="xs" />
              ) : (
                <HardDriveUploadIcon className="size-4" />
              )}
            </Button>
          </AccessPolicyWrapper>
        </DialogTrigger>
        <DialogContent removeCloseButton>
          <DialogHeader>
            <DialogTitle>Update Customer Module Subscription</DialogTitle>
            <Button
              variant={"ghost"}
              className="absolute right-4 top-4 h-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100"
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              <XIcon className="text-default h-4 w-4" />
              <span className="sr-only">Close</span>
            </Button>
          </DialogHeader>
          <Form {...editCustomerFormMethods}>
            <form
              className="grid w-full gap-1.5"
              onSubmit={editCustomerFormMethods.handleSubmit(
                onCustomerModuleUpdateSubmit
              )}
            >
              <FancyMultiSelect
                customWidth="w-full"
                value={selectedValues}
                disabled={isMutating}
                aria-disabled={isMutating}
                name="modules"
                multiSelectData={modulesData}
                onChange={handleSelect} // Update selected values
              />

              <DialogFooter className="sm:justify-end">
                <Button
                  type="submit"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="default"
                  size="sm"
                  className="gap-2"
                  onClick={() => {
                    const selectedModules = selectedValues.map((module) => ({
                      moduleId: parseInt(module.value),
                      moduleName: module.label,
                      buyerId: customerRows.id,
                      moduleOnboarded: false,
                    }));
                    const otherModules = modulesData
                      .filter(
                        (module) =>
                          !selectedValues.some(
                            (selected) => selected.value === module.value
                          )
                      )
                      .map((module) => ({
                        moduleId: parseInt(module.value),
                        moduleName: module.label,
                        moduleOnboarded: false,
                        removeModule: true,
                      }));

                    editCustomerFormMethods.setValue("moduleSubscriptions", [
                      ...selectedModules,
                      ...otherModules,
                    ]);
                  }}
                >
                  <span className="sr-only">Update</span>
                  Update
                  {isMutating ? (
                    <Spinner size="xs" />
                  ) : (
                    <SquarePenIcon className="size-4" />
                  )}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
