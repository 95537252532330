// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

import {type DupesCriteriaSettingsTableColumnsType} from "@/modules/duplicate-payments";

interface DupesCriteriaSettingsTableColumnsStoreState {
  storeDupesCriteriaSettingsTableColumns: DupesCriteriaSettingsTableColumnsType | null;
  setStoreDupesCriteriaSettingsTableColumns: (
    storeDupesCriteriaSettingsTableColumns: DupesCriteriaSettingsTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for DupesCriteriaSettings table columns
export const useDupesCriteriaSettingsTableColumnsStore = create(
  persist<DupesCriteriaSettingsTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeDupesCriteriaSettingsTableColumns: null,

      // Getter function to retrieve the current state
      getStoreDupesCriteriaSettingsTableColumns: () =>
        get().storeDupesCriteriaSettingsTableColumns,

      // Setter function to update the state
      setStoreDupesCriteriaSettingsTableColumns: (
        storeDupesCriteriaSettingsTableColumns
      ) => {
        set(() => ({storeDupesCriteriaSettingsTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "DupesCriteriaSettings-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useDupesCriteriaSettingsTableColumns = () =>
  useDupesCriteriaSettingsTableColumnsStore((state) => {
    return {
      storeDupesCriteriaSettingsTableColumns:
        state.storeDupesCriteriaSettingsTableColumns,
    };
  });
