import {useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {MailCheckIcon, MailsIcon, XIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Spinner} from "@/components/ui/spinner";
import {Textarea} from "@/components/ui/textarea";
import {Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {LoadingSection} from "@/components/craft/loading-section";
import {DataTableFacetedFilter} from "@/components/craft/table";

import {useImRemitCustomerStore} from "@/lib/stores";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";
import {
  exportPaymentDataFn,
  ExportPaymentDataFormSchema,
} from "@/modules/imremit";

export function PaymentManagementExportData({
  userEmail,
  tableColumns,
}: {
  userEmail?: string;
  tableColumns: {label: string; value: string}[];
}) {
  // Declaring the state for comment dialog
  const [openDialog, setOpenDialog] = useState(false);

  const exportPaymentsFormMethods = useForm<
    z.infer<typeof ExportPaymentDataFormSchema>
  >({
    defaultValues: {
      emailId: userEmail ?? "",
      columns: [],
    },
    resolver: zodResolver(ExportPaymentDataFormSchema),
  });

  const exportPaymentDataMutation = useMutation({
    mutationFn: (data: z.infer<typeof ExportPaymentDataFormSchema>) => {
      return exportPaymentDataFn(data);
    },

    onSuccess: (response) => {
      exportPaymentsFormMethods.reset();
      console.log("exportPaymentDataMutationResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Payment data exported successfully",
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to export payment data",
      });
    },
  });

  function onPaymentDataExportSubmit(
    data: z.infer<typeof ExportPaymentDataFormSchema>
  ) {
    exportPaymentDataMutation.mutate(data);
  }

  const {isPending: isMutating} = exportPaymentDataMutation;

  const {
    formState: {errors},
  } = exportPaymentsFormMethods;

  console.log(errors, "errors");

  const {imREmitStoreCustomer} = useImRemitCustomerStore();
  const externalId = imREmitStoreCustomer?.externalId;
  externalId
    ? exportPaymentsFormMethods.setValue("participant", externalId)
    : null;
  imREmitStoreCustomer?.buyerName
    ? exportPaymentsFormMethods.setValue(
        "customerName",
        imREmitStoreCustomer.buyerName
      )
    : null;

  return (
    <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
      <Dialog open={openDialog}>
        <DialogTrigger asChild>
          <AccessPolicyWrapper
            unauthorizedFallback={null}
            policyActions={[KeycloakRoleEnum.CREATE_PAYMENT_MANAGEMENT_EXPORT]}
          >
            <Button
              className="gap-2"
              // size="sm"
              variant="secondary"
              disabled={isMutating}
              onClick={() => {
                setOpenDialog(true);
              }}
            >
              <span className="sr-only">Export Data</span>
              <Paragraph>Export Data</Paragraph>
              {isMutating ? (
                <LoadingSection sectionSize="xs" />
              ) : (
                <MailCheckIcon className="size-4" />
              )}
            </Button>
          </AccessPolicyWrapper>
        </DialogTrigger>
        <DialogContent
          className="overflow-scroll sm:min-w-[640px] md:min-w-[768px] lg:min-w-[1024px] xl:min-w-[1280px] 2xl:min-w-[1378px]"
          removeCloseButton
        >
          <DialogHeader>
            <DialogTitle>Export Customer Payment Details</DialogTitle>
            <Button
              variant={"ghost"}
              className="absolute right-4 top-4 h-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              <XIcon className="text-default h-4 w-4" />
              <span className="sr-only">Close</span>
            </Button>
          </DialogHeader>
          <Form {...exportPaymentsFormMethods}>
            <form
              className="grid w-full gap-1.5"
              onSubmit={exportPaymentsFormMethods.handleSubmit((data) => {
                onPaymentDataExportSubmit(data);
              })}
            >
              <div className="mb-5 mt-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
                <FormField
                  name="customerName"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="customerName" showMandatoryAsterisk>
                        Customer Name
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder="Enter the customer name..."
                          disabled
                          aria-disabled
                          {...exportPaymentsFormMethods.register(
                            "customerName"
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  name="participant"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="participant" showMandatoryAsterisk>
                        External ID
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder="Enter the participant..."
                          disabled
                          aria-disabled
                          {...exportPaymentsFormMethods.register("participant")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="emailId"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="emailId" showMandatoryAsterisk>
                        Email ID
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder="Enter the email id..."
                          disabled
                          aria-disabled
                          {...exportPaymentsFormMethods.register("emailId")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  name="subject"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="subject" showMandatoryAsterisk>
                        Subject
                      </FormLabel>
                      <FormControl>
                        <Input
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          type="text"
                          placeholder="Enter the subject..."
                          {...exportPaymentsFormMethods.register("subject")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  name="message"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="message" showMandatoryAsterisk>
                        Message
                      </FormLabel>
                      <FormControl>
                        <Textarea
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter the message..."
                          {...exportPaymentsFormMethods.register("message")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <div className="mb-5 mt-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
                <FormField
                  name="columns"
                  render={({field}) => (
                    <FormItem className="flex flex-col">
                      <FormLabel htmlFor="columns">Columns to Export</FormLabel>
                      <FormControl>
                        <DataTableFacetedFilter
                          {...field}
                          facetedFilterData={tableColumns}
                          title="Click to select columns"
                        />
                      </FormControl>
                      <FormDescription>
                        <span className="italic">
                          If no columns are selected, all columns will be
                          exported.
                        </span>
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <DialogFooter className="sm:justify-end">
                <Button
                  type="button"
                  variant="input"
                  className="gap-2"
                  size="sm"
                  onClick={() => {
                    setOpenDialog(false);
                  }}
                >
                  <XIcon className="size-4" />
                  <span className="sr-only">Cancel</span>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="default"
                  size="sm"
                  className="gap-2"
                  onClick={() => {
                    const columnsToExport = exportPaymentsFormMethods.watch(
                      "columns"
                    ) as {label: string; value: string}[];

                    exportPaymentsFormMethods.setValue(
                      "columnList",
                      columnsToExport.map(
                        (column: {label: string; value: string}) => column.value
                      )
                    );
                  }}
                >
                  <span className="sr-only">Export to Email</span>
                  Export to Email
                  {isMutating ? (
                    <Spinner size="xs" />
                  ) : (
                    <MailsIcon className="size-4" />
                  )}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
