import {z} from "zod";

export const keycloakActionSchema = z.object({
  "read:imremit-dashboard-admin": z.boolean().optional(),
  "update:control-batch-processing": z.boolean().optional(),
  "read:proxypay-resolve-payment-btn": z.boolean().optional(),
  "read:failed-alerts": z.boolean().optional(),
  "read:admin": z.boolean().optional(),
  "read:customer-management": z.boolean().optional(),
  "create:customer-management": z.boolean().optional(),
  "delete:customer-management": z.boolean().optional(),
  "create:customer-modules": z.boolean().optional(),
  "update:customer-management": z.boolean().optional(),
  "update:customer-onboard": z.boolean().optional(),
  "read:master-role-settings": z.boolean().optional(),
  "read:user-management": z.boolean().optional(),
  "create:user-management": z.boolean().optional(),
  "delete:user-management": z.boolean().optional(),
  "update:user-management": z.boolean().optional(),
  "read:alert-management": z.boolean().optional(),
  "read:customer-module-management": z.boolean().optional(),
  "read:invoice-tracker": z.boolean().optional(),
  "read:imremit": z.boolean().optional(),
  "read:imremit-dashboard": z.boolean().optional(),
  "read:payment-management": z.boolean().optional(),
  "update:payment-management": z.boolean().optional(),
  "update:payment-reprocess": z.boolean().optional(),
  "read:card-details": z.boolean().optional(),
  "create:payment-management": z.boolean().optional(),
  "update:payment-block": z.boolean().optional(),
  "create:add-payment-comment": z.boolean().optional(),
  "update:payment-authorization": z.boolean().optional(),
  "create:payment-management-export": z.boolean().optional(),
  "read:file-processing": z.boolean().optional(),
  "read:proxypay-dashboard": z.boolean().optional(),
  "create:add-proxy-payment-comment": z.boolean().optional(),
  "update:pay-by-web": z.boolean().optional(),
  "read:supplier-management": z.boolean().optional(),
  "update:supplier-management": z.boolean().optional(),
  "delete:supplier-management": z.boolean().optional(),
  "create:supplier-management": z.boolean().optional(),
  "update:supplier-status": z.boolean().optional(),
  "create:supplier-management-export": z.boolean().optional(),
  "read:supplier-script-management": z.boolean().optional(),
  "create:supplier-script-management": z.boolean().optional(),
  "update:supplier-script-management": z.boolean().optional(),
  "read:imremit-mapping": z.boolean().optional(),
  "create:imremit-mapping": z.boolean().optional(),
  "read:imremit-lite": z.boolean().optional(),
  "read:imremit-lite-dashboard": z.boolean().optional(),
  "read:payment-management-lite": z.boolean().optional(),
  "update:payment-reprocess-lite": z.boolean().optional(),
  "update:payment-block-lite": z.boolean().optional(),
  "read:card-details-lite": z.boolean().optional(),
  "create:add-payment-comment-lite": z.boolean().optional(),
  "create:payment-management-export-lite": z.boolean().optional(),
  "update:move-payment": z.boolean().optional(),
  "read:proxypay-dashboard-lite": z.boolean().optional(),
  "create:add-proxy-payment-comment-lite": z.boolean().optional(),
  "update:pay-by-web-lite": z.boolean().optional(),
  "read:supplier-management-lite": z.boolean().optional(),
  "update:supplier-management-lite": z.boolean().optional(),
  "delete:supplier-management-lite": z.boolean().optional(),
  "create:supplier-management-lite": z.boolean().optional(),
  "update:supplier-status-lite": z.boolean().optional(),
  "create:supplier-management-export-lite": z.boolean().optional(),
  "read:supplier-script-management-lite": z.boolean().optional(),
  "create:supplier-script-management-lite": z.boolean().optional(),
  "update:supplier-script-management-lite": z.boolean().optional(),
  "read:duplicate-payments": z.boolean().optional(),
  "read:dupes-dashboard": z.boolean().optional(),
  "read:dupes-tracker": z.boolean().optional(),
  "read:dupes-criteria": z.boolean().optional(),
  "update:dupes-criteria": z.boolean().optional(),
  "create:dupes-criteria": z.boolean().optional(),
  "delete:dupes-criteria": z.boolean().optional(),
  "read:statement-recon": z.boolean().optional(),
  "read:sr-upload": z.boolean().optional(),
  "read:sr-search": z.boolean().optional(),
  "read:sr-settings": z.boolean().optional(),
  "create:sr-settings": z.boolean().optional(),
  "update:sr-settings": z.boolean().optional(),
  "read:electronic-invoicing": z.boolean().optional(),
  "create:remittance-management": z.boolean().optional(),
  "read:remittance-management": z.boolean().optional(),
  "update:remittance-management": z.boolean().optional(),
  "delete:remittance-management": z.boolean().optional(),
  "read:card-type": z.boolean().optional(),
  "update:flag-reset": z.boolean().optional(),
});

export type keycloakActionSchema = z.infer<typeof keycloakActionSchema>;
