import {z} from "zod";

export const supplierScriptManagementIMRemitPremiumSchema = z
  .object({
    sequenceId: z.string().min(1, "Sequence ID is required"),
    pageCategory: z.string().optional().nullable(),
    fieldName: z.string().min(1, "Field Name is required"),
    fieldValue: z.string().optional().nullable(),
    fieldType: z.string().min(1, "Select a Field Type"),
    tableName: z.string().optional().nullable(),
    tableColumnName: z.string().optional().nullable(),
    position: z.string().optional().nullable(),
    dropDownValue: z.string().optional().nullable(),
    separator: z.string().optional().nullable(),
    dateFormat: z.string().optional().nullable(),
    sequenceIdentifier: z.string().optional().nullable(),
    timer: z.string().min(1, "Select a Timer"),
    jsCheck: z.string().optional().nullable(),
    findElementBy: z.string().optional().nullable(),
  })
  .superRefine(
    (
      {
        fieldType,
        dateFormat,
        tableName,
        tableColumnName,
        findElementBy,
        dropDownValue,
        fieldValue,
        pageCategory,
      },
      ctx
    ) => {
      if (
        fieldType !== "iframe" &&
        fieldType !== "iframe_exit" &&
        fieldType !== "handle_window" &&
        fieldType !== "switch_to_previous_tab" &&
        fieldType !== "get_reCAPTCHA_v2_response" &&
        fieldType !== "insert_reCAPTCHA_v2_response" &&
        fieldType !== "input_invoice_numbers_amounts" &&
        fieldType !== "payment_loop_start" &&
        fieldType !== "payment_loop_end" &&
        fieldType !== "click_link_text" &&
        !fieldValue
      ) {
        ctx.addIssue({
          code: "custom",
          message: "XPath is required",
          path: ["fieldValue"],
        });
      }
      if (fieldType === "date_picker_table" && !dateFormat) {
        ctx.addIssue({
          code: "custom",
          message: "Select a date format",
          path: ["dateFormat"],
        });
      }
      if (
        (fieldType === "dropdown" || fieldType === "alert") &&
        !dropDownValue
      ) {
        ctx.addIssue({
          code: "custom",
          message: "Select a default value",
          path: ["dropDownValue"],
        });
      }
      if (
        fieldType !== "payment_loop_start" &&
        fieldType !== "payment_loop_end" &&
        !pageCategory
      ) {
        ctx.addIssue({
          code: "custom",
          message: "Select a page name",
          path: ["pageCategory"],
        });
      }
      if (
        fieldType !== "url" &&
        fieldType !== "iframe" &&
        fieldType !== "iframe_exit" &&
        fieldType !== "handle_window" &&
        fieldType !== "get_reCAPTCHA_v2_response" &&
        fieldType !== "insert_reCAPTCHA_v2_response" &&
        fieldType !== "payment_loop_start" &&
        fieldType !== "payment_loop_end" &&
        fieldType !== "input_invoice_numbers_amounts" &&
        fieldType !== "click_link_text" &&
        !findElementBy
      ) {
        ctx.addIssue({
          code: "custom",
          message: "Select a find element by",
          path: ["findElementBy"],
        });
      }
      if (
        (fieldType === "input" ||
          fieldType === "dropdown" ||
          fieldType === "date_picker_table" ||
          fieldType === "label_text" ||
          fieldType === "search_invoices_in_div") &&
        !tableName
      ) {
        ctx.addIssue({
          code: "custom",
          message: "Select a table name",
          path: ["tableName"],
        });
      }
      if (
        (fieldType === "input" ||
          fieldType === "dropdown" ||
          fieldType === "date_picker_table" ||
          fieldType === "label_text" ||
          fieldType === "search_invoices_in_div") &&
        !tableColumnName
      ) {
        ctx.addIssue({
          code: "custom",
          message: "Select a table column name",
          path: ["tableColumnName"],
        });
      }
    }
  );

export type SupplierScriptManagementIMRemitPremiumType = z.infer<
  typeof supplierScriptManagementIMRemitPremiumSchema
>;

export const supplierScriptManagementSchema = z.object({
  supplierScriptRequests: z.array(supplierScriptManagementIMRemitPremiumSchema),
});
export type SupplierScriptManagementType = z.infer<
  typeof supplierScriptManagementSchema
>;

export const supplierScriptData = z.array(
  supplierScriptManagementIMRemitPremiumSchema
);
export type SupplierScriptData = z.infer<typeof supplierScriptData>;

export const supplierScriptManagementIMRemitPremiumTableColumnsSchema =
  z.object({
    seqNo: z.boolean(),
    pageName: z.boolean(),
    fieldName: z.boolean(),
    fieldValue: z.boolean(),
    fieldType: z.boolean(),
    tableName: z.boolean(),
    tableColumnName: z.boolean(),
    position: z.boolean(),
    dropDownValue: z.boolean(),
    separator: z.boolean(),
    dateFormat: z.boolean(),
    sequenceIdentifier: z.boolean(),
    timer: z.boolean(),
    jsCheck: z.boolean(),
    findElementBy: z.boolean(),
  });
export type SupplierScriptManagementIMRemitPremiumTableColumnsType = z.infer<
  typeof supplierScriptManagementIMRemitPremiumTableColumnsSchema
>;
