import {useEffect, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate} from "@tanstack/react-router";
import {
  CheckIcon,
  ChevronsUpDownIcon,
  PlusIcon,
  SaveIcon,
  Trash2Icon,
  XIcon,
} from "lucide-react";
import {useFieldArray, useForm} from "react-hook-form";
import type * as z from "zod";

import {Button} from "@/components/ui/button";
import {Card, CardContent} from "@/components/ui/card";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Label} from "@/components/ui/label";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {RadioGroup, RadioGroupItem} from "@/components/ui/radio-group";
import {Spinner} from "@/components/ui/spinner";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {toast} from "@/components/ui/use-toast";

import {cn} from "@/lib/utils";
import {useDocumentFormats} from "@/modules/admin";
import {
  responseMappingFormSchema,
  saveResponseFileFn,
  useDateFormatDetails,
  useDelimiterTypesDetails,
  useMappingDocumentTypeStore,
} from "@/modules/imremit";

export function ResponseFileAddMapping() {
  // Get the navigate function from the router
  const navigate = useNavigate();

  const {storeDocumentType} = useMappingDocumentTypeStore();

  //get document format from custom hook `getAllDocumentFormat`
  //const documentFormatResponse = useDocumentFormatDetails();
  const allDocumentFormats = useDocumentFormats("1");

  //get delimiter type from custom hook `useDelimiterTypesDetails`
  const delimiterTypeResponse = useDelimiterTypesDetails();
  const delimiterTypeData = delimiterTypeResponse.data?.content;

  //get date format from custom hook `useDateFormatDetails`
  const DateFormatResponse = useDateFormatDetails();
  const DateFormatData = DateFormatResponse.data?.content;

  const responseForm = useForm<z.infer<typeof responseMappingFormSchema>>({
    defaultValues: {
      documentTypeId: storeDocumentType?.documentTypeId.toString() || "",
      responseMappingRequests: [],
    },
    resolver: zodResolver(responseMappingFormSchema),
  });

  const {fields, append, remove} = useFieldArray({
    control: responseForm.control,
    name: "responseMappingRequests",
    rules: {minLength: 1},
  });

  // Initialize Tanstack Query Mutation for Refreshing Response File
  const saveResponseFileMutation = useMutation({
    mutationFn: saveResponseFileFn,
    onSuccess: (response) => {
      responseForm.reset();
      console.log("saveResponseFileMutationResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Response file mapping added successfully",
      });
      void navigate({
        to: "/app/imremit/mapping",
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add response file mapping",
      });
    },
  });
  const isMutating = saveResponseFileMutation.isPending;

  function onSubmit(data: z.infer<typeof responseMappingFormSchema>) {
    saveResponseFileMutation.mutate(data);
  }

  const [isISO, setIsISO] = useState(false);

  const formatId = responseForm.watch("documentFormatId");

  useEffect(() => {
    const selectedFormatId: number = Number(formatId);
    const selectedFormat = allDocumentFormats.find(
      (doc) => doc.value === selectedFormatId.toString()
    );

    setIsISO(selectedFormat?.label.includes("ISO") || false);
  }, [formatId, allDocumentFormats]);

  const {setValue} = responseForm;

  useEffect(() => {
    if (isISO) {
      setValue("responseMappingRequests", []);
    }
  }, [isISO, setValue]);

  return (
    <>
      <Card>
        <CardContent>
          <Form {...responseForm}>
            <form onSubmit={responseForm.handleSubmit(onSubmit)}>
              <div className="mb-5 mt-5 grid grid-cols-1 gap-4 space-x-2 md:grid-cols-6">
                <FormField
                  control={responseForm.control}
                  name="masterMappingName"
                  render={({field}) => (
                    <FormItem className="flex flex-col md:col-span-2 xl:col-span-2 2xl:col-span-1">
                      <Label showMandatoryAsterisk>Master Mapping Name :</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={responseForm.control}
                  name="documentFormatId"
                  render={({field}) => (
                    <FormItem className="flex flex-col md:col-span-3 xl:col-span-2 2xl:col-span-1">
                      <FormLabel
                        htmlFor="documentFormatId"
                        showMandatoryAsterisk
                      >
                        Document Format
                      </FormLabel>

                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              disabled={isMutating}
                              aria-disabled={isMutating}
                              variant="outline"
                              role="combobox"
                              className={cn(
                                "min-w-64 justify-between gap-2 whitespace-nowrap",
                                !field.value && "text-accent-foreground"
                              )}
                            >
                              {field.value
                                ? allDocumentFormats.find(
                                    (documentFormat) =>
                                      documentFormat.value === field.value
                                  )?.label
                                : "Select Document Format..."}
                              <ChevronsUpDownIcon className="size-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                          <Command>
                            <CommandInput placeholder="Search Document Format..." />
                            <CommandEmpty>
                              No Document Format found.
                            </CommandEmpty>
                            <CommandGroup>
                              {allDocumentFormats.map((documentFormat) => (
                                <CommandItem
                                  key={documentFormat.value}
                                  value={documentFormat.label}
                                  onSelect={() => {
                                    setValue(
                                      "documentFormatId",
                                      documentFormat.value
                                    );
                                  }}
                                >
                                  <CheckIcon
                                    className={cn(
                                      "mr-2 size-4",
                                      documentFormat.value === field.value
                                        ? "opacity-100"
                                        : "opacity-0"
                                    )}
                                  />
                                  {documentFormat.label}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </Command>
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={responseForm.control}
                  name="documentTypeId"
                  render={({field}) => (
                    <FormItem>
                      <FormControl>
                        <Input placeholder="" {...field} className="hidden" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button
                  type="button"
                  className="col-span-1 col-end-8 mt-5"
                  disabled={isISO}
                  onClick={() => {
                    append({
                      order: "",
                      headerName: "",
                      dataLength: "",
                      delimiterTypeId: "",
                      dateFormatId: "",
                      defaultValue: "",
                      mappingName: "",
                      isDecimalField: true,
                      tableName: "",
                    });
                  }}
                >
                  <PlusIcon className="mr-2 size-4" /> Add
                </Button>
              </div>
              <Table>
                <TableCaption>
                  {fields.length === 0 && (
                    <>
                      {isISO ? (
                        <p>You cannot add data for ISO document formats.</p>
                      ) : (
                        <p>No Data.</p>
                      )}
                    </>
                  )}
                </TableCaption>

                <TableHeader>
                  <TableRow>
                    <TableHead className="relative" showMandatoryAsterisk>
                      Mapping Name
                    </TableHead>
                    <TableHead className="relative" showMandatoryAsterisk>
                      Order
                    </TableHead>
                    <TableHead className="relative" showMandatoryAsterisk>
                      Header Name
                    </TableHead>
                    <TableHead className="relative" showMandatoryAsterisk>
                      Data Length
                    </TableHead>
                    <TableHead>Delimiter Type</TableHead>
                    <TableHead>Date Format</TableHead>
                    <TableHead>Default Value</TableHead>
                    <TableHead className="relative" showMandatoryAsterisk>
                      Decimal Field
                    </TableHead>
                    <TableHead>Table Name</TableHead>
                    <TableHead className="text-right">Action</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {fields.map((item, index) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell className="w-80 font-medium">
                          <FormField
                            control={responseForm.control}
                            name={
                              `responseMappingRequests.${index.toString()}.mappingName` as `responseMappingRequests.${number}.mappingName`
                            }
                            render={() => (
                              <FormItem className="flex flex-col ">
                                <FormControl>
                                  <Input
                                    placeholder="Mapping Name"
                                    {...responseForm.register(
                                      `responseMappingRequests.${index.toString()}.mappingName` as `responseMappingRequests.${number}.mappingName`,
                                      {
                                        required: true,
                                      }
                                    )}
                                    className="transition-all duration-300 focus:w-48 focus:outline-none"
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TableCell>

                        <TableCell className="w-80 font-medium">
                          <FormField
                            control={responseForm.control}
                            name={
                              `responseMappingRequests.${index.toString()}.order` as `responseMappingRequests.${number}.order`
                            }
                            render={() => (
                              <FormItem className="flex flex-col ">
                                <FormControl>
                                  <Input
                                    type="number"
                                    min={0}
                                    placeholder="Order"
                                    {...responseForm.register(
                                      `responseMappingRequests.${index.toString()}.order` as `responseMappingRequests.${number}.order`,
                                      {
                                        required: true,
                                      }
                                    )}
                                    className="transition-all duration-300 focus:w-48 focus:outline-none"
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TableCell>

                        <TableCell className="w-80 font-medium">
                          <FormField
                            control={responseForm.control}
                            name={
                              `responseMappingRequests.${index.toString()}.headerName` as `responseMappingRequests.${number}.headerName`
                            }
                            render={() => (
                              <FormItem className="flex flex-col ">
                                <FormControl>
                                  <Input
                                    placeholder="Header Name"
                                    {...responseForm.register(
                                      `responseMappingRequests.${index.toString()}.headerName` as `responseMappingRequests.${number}.headerName`,
                                      {
                                        required: true,
                                      }
                                    )}
                                    className="transition-all duration-300 focus:w-48 focus:outline-none"
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TableCell>

                        <TableCell className="w-80 font-medium">
                          <FormField
                            control={responseForm.control}
                            name={
                              `responseMappingRequests.${index.toString()}.dataLength` as `responseMappingRequests.${number}.dataLength`
                            }
                            render={() => (
                              <FormItem className="flex flex-col ">
                                <FormControl>
                                  <Input
                                    type="number"
                                    min={0}
                                    placeholder="Data Length"
                                    {...responseForm.register(
                                      `responseMappingRequests.${index.toString()}.dataLength` as `responseMappingRequests.${number}.dataLength`,
                                      {
                                        required: true,
                                      }
                                    )}
                                    className="transition-all duration-300 focus:w-48 focus:outline-none"
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TableCell>

                        <TableCell className="w-80 font-medium">
                          <FormField
                            control={responseForm.control}
                            name={
                              `responseMappingRequests.${index.toString()}.delimiterTypeId` as `responseMappingRequests.${number}.delimiterTypeId`
                            }
                            render={({field}) => (
                              <Popover>
                                <PopoverContent className="w-[260px] p-0">
                                  <Command>
                                    <CommandGroup>
                                      {delimiterTypeData?.map(
                                        (delimiterType, i) => (
                                          <CommandItem
                                            key={i}
                                            value={delimiterType.delimiterTypeId.toString()}
                                            onSelect={(delimiterTypeId) => {
                                              field.onChange(delimiterTypeId);
                                            }}
                                          >
                                            <CheckIcon
                                              className={cn(
                                                "mr-2 size-4",
                                                delimiterType.delimiterTypeId.toString() ===
                                                  field.value
                                                  ? "opacity-100"
                                                  : "opacity-0"
                                              )}
                                            />
                                            {delimiterType.value}
                                          </CommandItem>
                                        )
                                      )}
                                    </CommandGroup>
                                  </Command>
                                </PopoverContent>
                                <PopoverTrigger asChild>
                                  <FormControl>
                                    <Button
                                      variant="outline"
                                      role="combobox"
                                      className={cn(
                                        "justify-between",
                                        !field.value &&
                                          "text-accent-foreground",
                                        "w-[260px]"
                                      )}
                                    >
                                      {field.value ? (
                                        delimiterTypeData?.find(
                                          (delimiterType) =>
                                            delimiterType.delimiterTypeId
                                              .toString()
                                              .toLowerCase() === field.value
                                        )?.value
                                      ) : (
                                        <>Select delimiter type ID...</>
                                      )}

                                      <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                    </Button>
                                  </FormControl>
                                </PopoverTrigger>
                              </Popover>
                            )}
                          />
                        </TableCell>

                        <TableCell className="w-80 font-medium">
                          <FormField
                            control={responseForm.control}
                            name={
                              `responseMappingRequests.${index.toString()}.dateFormatId` as `responseMappingRequests.${number}.dateFormatId`
                            }
                            render={({field}) => (
                              <Popover>
                                <PopoverContent className="w-[180px] p-0">
                                  <Command>
                                    <CommandGroup>
                                      {DateFormatData?.map((dateFormat, i) => (
                                        <CommandItem
                                          key={i}
                                          value={dateFormat.dateFormatId.toString()}
                                          onSelect={(dateFormatId) => {
                                            field.onChange(dateFormatId);
                                          }}
                                        >
                                          <CheckIcon
                                            className={cn(
                                              "mr-2 size-4",
                                              dateFormat.dateFormatId.toString() ===
                                                field.value
                                                ? "opacity-100"
                                                : "opacity-0"
                                            )}
                                          />
                                          {dateFormat.dateFormatValue}
                                        </CommandItem>
                                      ))}
                                    </CommandGroup>
                                  </Command>
                                </PopoverContent>
                                <PopoverTrigger asChild>
                                  <FormControl>
                                    <Button
                                      variant="outline"
                                      role="combobox"
                                      className={cn(
                                        "justify-between",
                                        !field.value &&
                                          "text-accent-foreground",
                                        "w-[180px]"
                                      )}
                                    >
                                      {field.value ? (
                                        DateFormatData?.find(
                                          (dateFormat) =>
                                            dateFormat.dateFormatId
                                              .toString()
                                              .toLowerCase() === field.value
                                        )?.dateFormatValue
                                      ) : (
                                        <>Select date format ID...</>
                                      )}

                                      <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                    </Button>
                                  </FormControl>
                                </PopoverTrigger>
                              </Popover>
                            )}
                          />
                        </TableCell>

                        <TableCell className="w-80 font-medium">
                          <FormField
                            control={responseForm.control}
                            name={
                              `responseMappingRequests.${index.toString()}.defaultValue` as `responseMappingRequests.${number}.defaultValue`
                            }
                            render={() => (
                              <FormItem className="flex flex-col">
                                <FormControl>
                                  <Input
                                    placeholder="Default Value"
                                    {...responseForm.register(
                                      `responseMappingRequests.${index.toString()}.defaultValue` as `responseMappingRequests.${number}.defaultValue`,
                                      {
                                        required: true,
                                        setValueAs: (value): string | null =>
                                          typeof value === "string" &&
                                          value.trim() === ""
                                            ? null
                                            : (value as string),
                                      }
                                    )}
                                    className="transition-all duration-300 focus:w-48 focus:outline-none"
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TableCell>

                        <TableCell className="w-80 font-medium">
                          <FormField
                            control={responseForm.control}
                            name={
                              `responseMappingRequests.${index.toString()}.isDecimalField` as `responseMappingRequests.${number}.isDecimalField`
                            }
                            render={({field}) => (
                              <FormItem className="space-y-4">
                                <FormControl>
                                  <RadioGroup
                                    className="flex flex-col space-y-1"
                                    defaultValue="1"
                                    onValueChange={(value) => {
                                      field.onChange(value === "1");
                                    }}
                                  >
                                    <div className="flex items-center space-x-2">
                                      <FormItem className="flex items-center space-x-3 space-y-0">
                                        <FormControl>
                                          <RadioGroupItem value="1" />
                                        </FormControl>
                                        <FormLabel className="font-normal">
                                          Yes
                                        </FormLabel>
                                      </FormItem>
                                      <FormItem className="flex items-center space-x-3 space-y-0">
                                        <FormControl>
                                          <RadioGroupItem value="no" />
                                        </FormControl>
                                        <FormLabel className="font-normal">
                                          No
                                        </FormLabel>
                                      </FormItem>
                                    </div>
                                  </RadioGroup>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TableCell>

                        <TableCell className="w-80 font-medium">
                          <FormField
                            control={responseForm.control}
                            name={
                              `responseMappingRequests.${index.toString()}.tableName` as `responseMappingRequests.${number}.tableName`
                            }
                            render={() => (
                              <FormItem className="flex flex-col ">
                                <FormControl>
                                  <Input
                                    placeholder="Table Name"
                                    {...responseForm.register(
                                      `responseMappingRequests.${index.toString()}.tableName` as `responseMappingRequests.${number}.tableName`,
                                      {
                                        required: true,
                                        setValueAs: (value): string | null =>
                                          typeof value === "string" &&
                                          value.trim() === ""
                                            ? null
                                            : (value as string),
                                      }
                                    )}
                                    className="transition-all duration-300 focus:w-48 focus:outline-none"
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TableCell>

                        <TableCell className="text-right">
                          <Button
                            className="flex items-center gap-2 px-3"
                            variant="outline"
                            size="xs"
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <Trash2Icon className="size-4" />
                            <span className="sr-only">Delete</span>
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>

              <div className="space-x-2 p-2 text-right">
                <Link className="mb-4" to="/app/imremit/mapping">
                  <Button
                    type="button"
                    variant="input"
                    className="col-span-1 gap-2"
                  >
                    <XIcon className="size-4" />
                    <span className="sr-only">Cancel</span>
                    Cancel
                  </Button>
                </Link>
                <Button
                  type="submit"
                  disabled={(isMutating || !fields.length) && !isISO}
                  aria-disabled={isMutating}
                  variant="affirmative"
                  className="col-span-1 gap-2"
                >
                  <SaveIcon className="size-4" />
                  <span className="sr-only">Save</span>
                  {isMutating ? <Spinner size="xs" /> : ""} Save
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </>
  );
}
