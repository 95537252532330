import type React from "react";
import {useEffect, useMemo, useRef, useState} from "react";

import {PlusIcon, Trash2Icon} from "lucide-react";
import {useFormContext} from "react-hook-form";

import {Button} from "@/components/ui/button";
import {Card, CardContent, CardFooter} from "@/components/ui/card";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Separator} from "@/components/ui/separator";
import {Skeleton} from "@/components/ui/skeleton";
import {Switch} from "@/components/ui/switch";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableRow,
} from "@/components/ui/table";
import {Heading4, Paragraph} from "@/components/ui/typography";
import {FancyMultiSelect} from "@/components/craft/fancy-multi-select";

import {useKeyCloakInstanceStore} from "@/modules/auth";
import {useGetCustomerModules} from "@/modules/customers";

import {ModuleNames} from "@/utils/constants";

export interface BuyerAliasesFieldProps {
  isMutating?: boolean;
  aliasSwitch?: boolean;
  getBuyerAliases?: string[];
}

export const ModuleSubscriptionFormFields = ["moduleSubscriptions"];
export const BuyerAliasesFormFields = ["buyerAliases"];

/**
 * Custom hook to fetch and format customer modules data.
 * @returns {Object} An object containing modules data, loading state, and error state.
 */
export function useCustomerModules() {
  const {data, isLoading, error} = useGetCustomerModules();

  const modulesData = useMemo(() => {
    // Transform the module data into a format suitable for the FancyMultiSelect component
    return (
      data?.content.map((module) => ({
        value: module.moduleId.toString(),
        label: module.moduleName.replace(/(\r\n|\n|\r)/gm, ""),
      })) ?? []
    );
  }, [data]);

  return {modulesData, isLoading, error};
}

// Typescript interface for the ModuleSubscriptionForm component's props
interface ModuleSubscriptionFormProps {
  id?: number;
  externalId?: string;
  isMutating: boolean;
  submitButton: React.ReactNode;
  isEdit?: boolean;
}

/**
 * Component for handling module subscriptions.
 * @param {ModuleSubscriptionFormProps} props - The props for the component.
 * @returns {React.ReactElement} The React component for module subscriptions.
 */
export function ModuleSubscriptionForm({
  id,
  isMutating,
  submitButton,
  isEdit,
}: ModuleSubscriptionFormProps) {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const parsedUserInfoRole = storeKeyCloakInstance?.tokenParsed;
  // Check if the user is a program manager
  const isProgramManager = parsedUserInfoRole?.realm_access?.roles.includes(
    "name:program-manager"
  );

  const {watch, setValue} = useFormContext();
  const [selectedModules, setSelectedModules] = useState<
    {value: string; label: string}[]
  >([]);
  const [aliasMapping, setAliasMapping] = useState<boolean>(false);
  const {modulesData, isLoading, error} = useCustomerModules();

  let filteredModulesData = modulesData;

  // If user is a program manager, filter out all modules from modulesData that are not Lite
  if (isProgramManager) {
    filteredModulesData = modulesData.filter(
      (module) => module.label === ModuleNames.imremitLite
    );
  }

  useEffect(() => {
    const moduleSubscriptions = watch("moduleSubscriptions") as
      | {moduleId: {toString: () => number}; moduleName: string}[]
      | undefined;

    if (Array.isArray(moduleSubscriptions)) {
      const selectedModules = moduleSubscriptions.map(
        (module: {moduleId: {toString: () => number}; moduleName: string}) => {
          return {
            value: String(module.moduleId),
            label: module.moduleName,
          };
        }
      ) as {value: string; label: string}[];
      setSelectedModules(selectedModules);
      setValue("moduleSubscriptions", moduleSubscriptions);
    }
  }, [watch, setSelectedModules, setValue]);

  useEffect(() => {
    //if the selectedModules array contains the label "Lite" then set the aliasMapping to true
    if (
      selectedModules
        .map((module) => module.label)
        .includes(ModuleNames.imremitLite)
    ) {
      setAliasMapping(true);
    } else {
      setAliasMapping(false);
      setValue("aliasSwitch", false);
    }
  }, [aliasMapping, selectedModules, setValue]);

  const aliasSwitch = watch("aliasSwitch") as boolean;

  useEffect(() => {
    if (!aliasSwitch) {
      setValue("buyerAliases", []);
    }
  }, [aliasSwitch, setValue]);

  // Render loading or error states if necessary
  if (isLoading) return <Skeleton className="h-8 w-full" />;
  if (error)
    return (
      <Paragraph className="font-semibold">Error loading modules</Paragraph>
    );

  // Main render return for the component
  return (
    <>
      <div className="grid grid-cols-1 gap-6 pb-6 md:grid-cols-2">
        <FormField
          name="moduleSubscriptions"
          render={() => (
            <FormItem>
              <FormLabel htmlFor="moduleSubscriptions" showMandatoryAsterisk>
                Module Subscription:
              </FormLabel>
              <FormControl>
                <FancyMultiSelect
                  customWidth="w-full"
                  value={selectedModules}
                  disabled={isMutating || isEdit}
                  aria-disabled={isMutating || isEdit}
                  name="modules"
                  multiSelectData={filteredModulesData}
                  onChange={(selectedModules) => {
                    setSelectedModules(selectedModules);
                    setValue(
                      "moduleSubscriptions",
                      selectedModules.map((module) => ({
                        moduleId: parseInt(module.value),
                        moduleName: module.label,
                        buyerId: id,
                        moduleOnboarded:
                          parseInt(module.value) === 97 ? true : false, //only lite has instant onboarded
                      }))
                    );
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </div>
      {aliasMapping && (
        <>
          <section className="grid grid-cols-1 gap-2">
            <Separator className="mt-2" />
            <Heading4 className="mb-4">Customer Aliases</Heading4>
            <div className="flex items-start gap-x-4">
              <FormField
                name="aliasSwitch"
                render={(field) => (
                  <FormItem className="flex w-1/4 flex-col gap-y-2 md:w-1/2 xl:w-1/3 2xl:w-1/4">
                    <FormLabel htmlFor="aliasSwitch" showMandatoryAsterisk>
                      Toggle Alias Mapping:
                    </FormLabel>
                    <FormControl>
                      <Switch
                        checked={aliasSwitch}
                        onCheckedChange={(checked) => {
                          setValue("aliasSwitch", checked);
                        }}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              {aliasSwitch && (
                <FormField
                  name="aliasMapping"
                  render={() => (
                    <FormItem className="flex w-1/4 flex-col gap-y-2 pb-6 md:w-1/2 xl:w-2/3 2xl:w-1/4">
                      <FormLabel htmlFor="aliasMapping" showMandatoryAsterisk>
                        Customer Aliases:
                      </FormLabel>
                      <FormControl>
                        <BuyerAliasesField
                          isMutating={isMutating}
                          aliasSwitch={aliasSwitch}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              )}
            </div>
          </section>
        </>
      )}
      <div className="mt-4 flex justify-end">{submitButton}</div>
    </>
  );
}

export function BuyerAliasesField({
  isMutating,
  aliasSwitch,
  getBuyerAliases,
}: BuyerAliasesFieldProps) {
  const {setValue} = useFormContext();
  const [buyerAliases, setBuyerAliases] = useState<string[]>([]);

  // Effect to clear buyer aliases when aliasSwitch is turned off
  useEffect(() => {
    if (!aliasSwitch) {
      setBuyerAliases([]); // 🧹 Clear all aliases if aliasSwitch is off
    }
  }, [aliasSwitch]);

  // Update form context whenever buyerAliases changes
  useEffect(() => {
    setValue("buyerAliases", buyerAliases);
  }, [buyerAliases, setValue]);

  const initialGetBuyerAliases = useRef(getBuyerAliases);

  useEffect(() => {
    setBuyerAliases(initialGetBuyerAliases.current ?? []);
  }, []);

  // Handle alias change
  const handleAliasChange = (index: number, value: string) => {
    setBuyerAliases((prevAliases) =>
      prevAliases.map((alias, i) => (i === index ? value : alias))
    );
  };

  // Add a new empty alias
  const handleAddAlias = () => {
    setBuyerAliases((prevAliases) => [...prevAliases, ""]); // ➕ Add an empty alias
  };

  // Remove alias by index
  const handleRemoveAlias = (index: number) => {
    setBuyerAliases((prevAliases) => prevAliases.filter((_, i) => i !== index));
  };

  return (
    <Card>
      <CardContent className="w-full">
        <Table>
          <TableCaption>
            {buyerAliases.length === 0 && <p>No Data.</p>}
          </TableCaption>
          <TableBody>
            {buyerAliases.map((alias, index) => (
              <TableRow key={index} className="hover:bg-inherit">
                <TableCell className="w-full font-medium">
                  <FormItem className="flex flex-col">
                    <FormControl>
                      <Input
                        disabled={isMutating}
                        aria-disabled={isMutating}
                        placeholder="Enter alias..."
                        value={alias}
                        onChange={(e) => {
                          handleAliasChange(index, e.target.value);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                </TableCell>
                <TableCell className="text-right">
                  <Button
                    className="flex items-center gap-2 px-3"
                    variant="outline"
                    size="xs"
                    type="button"
                    disabled={isMutating} // 🛑 Disable button if mutating
                    onClick={() => {
                      handleRemoveAlias(index);
                    }}
                  >
                    <Trash2Icon className="size-4" />
                    <span className="sr-only">Delete</span>
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
      <CardFooter className="justify-end">
        <Button
          type="button"
          className="col-span-1 col-end-8"
          disabled={isMutating || buyerAliases.length >= 10}
          onClick={handleAddAlias}
        >
          <PlusIcon className="mr-2 size-4" /> Add
        </Button>
      </CardFooter>
    </Card>
  );
}
