import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate, useParams} from "@tanstack/react-router";
import {ArrowRightIcon, CheckCheckIcon, MinusCircleIcon} from "lucide-react";

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {Button, buttonVariants} from "@/components/ui/button";
import {Card, CardContent, CardHeader} from "@/components/ui/card";
import {Separator} from "@/components/ui/separator";
import {Skeleton} from "@/components/ui/skeleton";
import {Heading2, Heading3, Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {LoadingSection} from "@/components/craft/loading-section";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {cn} from "@/lib/utils";
import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useAuthorization,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {
  approveSupplierFn,
  CommentsForm,
  declineSupplierFn,
  useGetSuppliersDetails,
  type SupplierDetailsType,
} from "@/modules/imremit";

export interface SupplierManagementApproveDeclineButtonsProps {
  searchSupplierData: SupplierDetailsType;
}

export function ViewSupplierDetailsPage() {
  const {supplierId} = useParams({
    from: "/app/imremit/supplier-management/$supplierId/view",
  });

  const {checkPoliciesAccess} = useAuthorization();
  const searchSupplierQuery = useGetSuppliersDetails(Number(supplierId));
  const searchSupplierData = searchSupplierQuery.data?.content;

  // Handle unauthorized access
  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_SUPPLIER_MANAGEMENT])) {
    return <NotAuthorizedPage />;
  }

  // Handle loading state
  if (!searchSupplierData || searchSupplierQuery.isPending) {
    return (
      <>
        <div className="mb-2 flex w-full flex-col items-start justify-between gap-2 md:flex-row md:items-center">
          <Heading3>View Supplier Details</Heading3>
          <Link
            to="/app/imremit/supplier-management"
            className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
          >
            Back to list
            <span className="sr-only">Back to list</span>
            <ArrowRightIcon className="size-4" />
          </Link>
        </div>
        <Card className="mt-8 flex flex-col gap-y-4 p-4">
          <Skeleton className="h-8 w-1/3" />
          <Skeleton className="h-8 w-full" />
          <Skeleton className="h-8 w-full" />
          <Skeleton className="h-8 w-full" />
          <Skeleton className="h-8 w-full" />
          <Skeleton className="h-8 w-full" />
          <Separator className="my-4" />
          <Skeleton className="h-8 w-full" />
          <Skeleton className="h-8 w-full" />
        </Card>
      </>
    );
  }

  return (
    <section>
      {
        <>
          <div className="mb-2 flex w-full flex-col items-start justify-between gap-2 md:flex-row md:items-center">
            <Heading3>View Supplier Details</Heading3>
            <Link
              to="/app/imremit/supplier-management"
              className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
            >
              Back to list
              <span className="sr-only">Back to list</span>
              <ArrowRightIcon className="size-4" />
            </Link>
          </div>
          <Card>
            <CardHeader className="flex gap-2">
              <div className="flex w-full items-center justify-between">
                <Heading2>
                  {searchSupplierData.customerName || "No customer name..."}
                </Heading2>
                <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                  <SupplierManagementApproveDeclineButtons
                    searchSupplierData={searchSupplierData}
                  />

                  <CommentsForm id={supplierId} />
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                <Paragraph className="flex flex-col text-lg">
                  <strong>Customer Name: </strong>
                  {searchSupplierData.customerName || "No customer name..."}
                </Paragraph>
                <Paragraph className="text-lg">
                  <strong>Supplier Name: </strong>
                  {searchSupplierData.supplierName ?? "No supplier name..."}
                </Paragraph>
                <Paragraph className="text-lg">
                  <strong>Supplier ID: </strong>
                  {searchSupplierData.supplierNumber || "No supplier ID..."}
                </Paragraph>
                <Paragraph className="flex flex-col text-lg">
                  <strong>Supplier Phone Number: </strong>
                  {searchSupplierData.phoneNumber || "No Phone Number..."}
                </Paragraph>
                <Paragraph className="flex flex-col text-lg">
                  <strong>Supplier Enrolled: </strong>
                  {searchSupplierData.enrolled === true
                    ? "Yes"
                    : searchSupplierData.enrolled === false
                    ? "No"
                    : "Not Available"}
                </Paragraph>
              </div>
            </CardHeader>

            <CardContent>
              <Separator className="my-4" />
              {searchSupplierData.enrolled === false && (
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                  <Paragraph className="flex flex-col text-lg">
                    <strong>Supplier Declined Reason: </strong>
                    {searchSupplierData.declinedReason ||
                      "No Declined Reason..."}
                  </Paragraph>
                  <Paragraph className="flex flex-col text-lg">
                    <strong>Supplier Declined Date: </strong>
                    {searchSupplierData.declinedDate || "No Declined Date..."}
                  </Paragraph>
                </div>
              )}

              {searchSupplierData.enrolled === true && (
                <>
                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Tax ID: </strong>
                      {searchSupplierData.taxId || "No tax id..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Email: </strong>
                      {searchSupplierData.supplierEmail ||
                        "No supplier email..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Onboarding Status: </strong>
                      {searchSupplierData.onboardStatus ||
                        "No onboard status..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Address 1: </strong>
                      {searchSupplierData.address1 || "No Address 1..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Address 2: </strong>
                      {searchSupplierData.address2 || "No Address 2..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Address 3: </strong>
                      {searchSupplierData.address3 || "No Address 3..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Address 4: </strong>
                      {searchSupplierData.address4 || "No Address 4..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier City: </strong>
                      {searchSupplierData.city || "No City..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier State: </strong>
                      {searchSupplierData.state || "No State..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Zip: </strong>
                      {searchSupplierData.zip || "No Zip..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Location Code: </strong>
                      {searchSupplierData.locationCode || "No Location Code..."}
                    </Paragraph>

                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Country: </strong>
                      {searchSupplierData.country || "No Country..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Contact Name: </strong>
                      {searchSupplierData.contactName || "No Contact Name..."}
                    </Paragraph>

                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Enrolled Date: </strong>
                      {searchSupplierData.enrolledDate || "No Enrolled Date..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Contact Email: </strong>
                      {searchSupplierData.contactEmail || "No Contact Email..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Create Timestamp: </strong>
                      {searchSupplierData.createTimestamp ||
                        "No Create Timestamp..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Last Edit Timestamp: </strong>
                      {searchSupplierData.lastEditTimestamp ||
                        "No Last Edit Timestamp..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Payment Mode: </strong>
                      {searchSupplierData.paymentMode || "No Payment Mode..."}
                    </Paragraph>

                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Proxy Pay URL: </strong>
                      {searchSupplierData.proxyPayUrl || "No Proxy Pay URL..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Remittance Email: </strong>
                      {searchSupplierData.remittanceEmail ||
                        "No Remittance Email..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Remittance Name: </strong>
                      {searchSupplierData.remittanceName ||
                        "No Remittance Name..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Remittance Phone Number: </strong>
                      {searchSupplierData.remittancePhoneNumber ||
                        "No Remittance Phone Number..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Max Amount Per Transaction: </strong>
                      {searchSupplierData.maxAmountPerTransaction ||
                        "No Max Amount Per Transaction..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Min Amount Per Transaction: </strong>
                      {searchSupplierData.minAmountPerTransaction ||
                        "No Min Amount Per Transaction..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>
                        Supplier Amount Transaction Limit Per Day:{" "}
                      </strong>
                      {searchSupplierData.amountTransactionLimitPerDay ||
                        "No Amount Transaction Limit Per Day..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier No Of Transaction Per Day: </strong>
                      {searchSupplierData.noOfTransactionPerDay ||
                        "No No Of Transaction Per Day..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Payment Restrictions: </strong>
                      {searchSupplierData.paymentRestrictions ||
                        "No Payment Restrictions..."}
                    </Paragraph>
                  </div>
                  <Separator className="my-4" />
                  <div className="grid grid-cols-1 gap-4 pt-3 md:grid-cols-2 xl:grid-cols-3">
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier W9 Form URL: </strong>
                      {searchSupplierData.w9formUrl || "No W9 Form URL..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Self Registered Flag: </strong>
                      {searchSupplierData.selfRegisteredFlag ||
                        "No Self Registered Flag..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Supplier Script Flag: </strong>
                      {searchSupplierData.scriptFlag || "No Script Flag..."}
                    </Paragraph>
                  </div>
                  <Separator className="my-4" />
                  <div className="grid grid-cols-1 gap-4 pt-3 md:grid-cols-2 xl:grid-cols-3">
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Proxy Pay Batch Size : </strong>
                      {searchSupplierData.batchSize ||
                        "No Proxy Pay Batch Size..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Proxy Pay Cron Interval : </strong>
                      {searchSupplierData.cronInterval ||
                        "No Proxy Pay Cron Interval..."}
                    </Paragraph>

                    <Paragraph className="flex flex-col text-lg">
                      <strong>Maintenance Window Start Time : </strong>
                      {searchSupplierData.maintenanceWindowStart ||
                        "No Maintenance Window Start Time..."}
                    </Paragraph>
                    <Paragraph className="flex flex-col text-lg">
                      <strong>Maintenance Window End Time : </strong>
                      {searchSupplierData.maintenanceWindowEnd ||
                        "No Maintenance Window End Time..."}
                    </Paragraph>
                  </div>
                </>
              )}
            </CardContent>
          </Card>
        </>
      }
    </section>
  );
}

function SupplierManagementApproveDeclineButtons({
  searchSupplierData,
}: SupplierManagementApproveDeclineButtonsProps) {
  const navigate = useNavigate();

  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;
  const changedBy = parsedUserInfo?.sub || "";

  const approveSupplierMutation = useMutation({
    mutationFn: () => {
      return approveSupplierFn(
        searchSupplierData.supplierId.toString(),
        changedBy
      );
    },
    onSuccess: (supplierUpdatesStatus) => {
      console.log("supplierUpdatesStatus", supplierUpdatesStatus);

      toast({
        variant: "success",
        title: "Success!",
        description: "Supplier approved successfully",
      });
      void navigate({to: "/app/imremit/supplier-management", replace: true});
    },
    onError: (error) => {
      console.error("errorMessage", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to approve supplier",
      });
    },
  });

  const declineSupplierMutation = useMutation({
    mutationFn: () => {
      return declineSupplierFn(
        searchSupplierData.supplierId.toString(),
        changedBy
      );
    },
    onSuccess: (response) => {
      console.log("supplierUpdatesStatus", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Supplier declined successfully",
      });
      void navigate({to: "/app/imremit/supplier-management", replace: true});
    },
    onError: (error) => {
      console.error("errorMessage", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to decline supplier",
      });
    },
  });

  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.UPDATE_SUPPLIER_STATUS]}
      unauthorizedFallback={null}
    >
      {searchSupplierData.onboardStatus === "APPROVAL_PENDING" && (
        <>
          {/* Approve Supplier */}
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button size="sm">
                <span className="sr-only">Approve</span>
                <CheckCheckIcon className="mr-2 size-4" />
                <Paragraph>Approve</Paragraph>
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure you ?</AlertDialogTitle>
                <AlertDialogDescription>
                  This will approve your supplier form & update your status as
                  <i> ACTIVE</i>.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel
                  aria-disabled={approveSupplierMutation.isPending}
                  disabled={approveSupplierMutation.isPending}
                >
                  No
                </AlertDialogCancel>
                <Button
                  aria-disabled={approveSupplierMutation.isPending}
                  disabled={approveSupplierMutation.isPending}
                  className="gap-2"
                  onClick={() => {
                    approveSupplierMutation.mutate();
                  }}
                >
                  {approveSupplierMutation.isPending ? (
                    <LoadingSection sectionSize="xs" />
                  ) : (
                    <CheckCheckIcon className="size-4" />
                  )}{" "}
                  Yes Approve
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>

          {/* Decline Supplier */}
          <AlertDialog>
            <AlertDialogTrigger asChild>
              <Button size="sm" variant="destructive">
                <span className="sr-only">Decline</span>
                <MinusCircleIcon className="mr-2 size-4" />
                <Paragraph>Decline</Paragraph>
              </Button>
            </AlertDialogTrigger>
            <AlertDialogContent>
              <AlertDialogHeader>
                <AlertDialogTitle>Are you sure you ?</AlertDialogTitle>
                <AlertDialogDescription>
                  This will decline your supplier form & update your status as
                  <i> FORM DECLINED</i>.
                </AlertDialogDescription>
              </AlertDialogHeader>
              <AlertDialogFooter>
                <AlertDialogCancel
                  aria-disabled={declineSupplierMutation.isPending}
                  disabled={declineSupplierMutation.isPending}
                >
                  No
                </AlertDialogCancel>
                <Button
                  aria-disabled={declineSupplierMutation.isPending}
                  disabled={declineSupplierMutation.isPending}
                  className="gap-2"
                  onClick={() => {
                    declineSupplierMutation.mutate();
                  }}
                >
                  {declineSupplierMutation.isPending ? (
                    <LoadingSection sectionSize="xs" />
                  ) : (
                    <MinusCircleIcon className="size-4" />
                  )}
                  {"  "}
                  Yes Decline
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialog>
        </>
      )}
    </AccessPolicyWrapper>
  );
}
