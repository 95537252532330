import {useNavigate, useParams} from "@tanstack/react-router";
import {type ColumnDef} from "@tanstack/react-table";
import {FileTextIcon, GripVerticalIcon} from "lucide-react";

import {Badge} from "@/components/ui/badge";
import {Button} from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {formatAmount, GetKeycloakUserName} from "@/modules/admin";
import {
  RenderCustomerDescription,
  RenderCustomerStatus,
  renderRemittanceBadge,
  type ImREmitPaymentType,
} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";

export function useGetPaymentManagementLiteFilterListColumns() {
  const {methodType} = useParams({
    from: "/app/imremit-lite/payment-management/$pmPage/payments/$methodType",
  });

  // Get the navigate function from the router
  const navigate = useNavigate();

  const PMColumns: ColumnDef<ImREmitPaymentType>[] = [
    {
      accessorKey: "supplierName",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Supplier Name
      "
        />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("supplierName") ? (
            row.getValue("supplierName")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "supplierNumber",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
    Supplier ID
      "
        />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("supplierNumber") ? (
            row.getValue("supplierNumber")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "facilityName",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Customer Name
      "
        />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("facilityName") ? (
            row.getValue("facilityName")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "buyerAlias",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Customer Alias
      "
        />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("buyerAlias") ? (
            row.getValue("buyerAlias")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "paymentNumber",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Payment Number
      "
        />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("paymentNumber") ? (
            row.getValue("paymentNumber")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "accountNumber",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Account Number
      "
        />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("accountNumber") ? (
            row.getValue("accountNumber")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "totalAmountSent",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Sent Amount
      "
        />
      ),
      cell: ({row}) => {
        const amountValue = row.original.totalAmountSent;

        // Check if empty and return "no data" if empty
        const formattedAmount = amountValue ? (
          formatAmount(amountValue)
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );

        return <Paragraph>{formattedAmount}</Paragraph>;
      },
    },
    {
      accessorKey: "initiatedDate",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Sent Date
      "
        />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("initiatedDate") ? (
            row.getValue("initiatedDate")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "customerStatus",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Status
      "
        />
      ),
      cell: ({row}) => {
        if (!row.original.customerStatus) {
          return (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          );
        }

        return RenderCustomerStatus(row.original.customerStatus);
      },
    },
    {
      accessorKey: "statusName",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Status Name
      "
        />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("statusName") ? (
            row.getValue("statusName")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "description",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Status Description
      "
        />
      ),
      cell: ({row}) => {
        if (!row.original.description) {
          return (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          );
        }

        return RenderCustomerDescription(
          row.original.customerStatus || "",
          row.original.description
        );
      },
    },

    {
      accessorKey: "descriptionDetail",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Description Detail" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("descriptionDetail") ? (
            row.getValue("descriptionDetail")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "amountTaken",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
    Amount Taken
      "
        />
      ),
      cell: ({row}) => {
        const amountValue = row.original.amountTaken;

        // Check if empty and return "no data" if empty
        const formattedAmount = amountValue ? (
          formatAmount(amountValue)
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );

        return <Paragraph>{formattedAmount}</Paragraph>;
      },
    },
    {
      accessorKey: "balanceAmount",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Balance Amount
      "
        />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("balanceAmount") ? (
            row.getValue("balanceAmount")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "userId",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Agent Name
      "
        />
      ),
      cell: ({row}) => {
        const userId = row.original.userId;

        if (!userId) {
          return (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          );
        }

        return <GetKeycloakUserName userId={userId} />;
      },
    },
    {
      accessorKey: "remittanceMethod",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Remittance Method
      "
        />
      ),
      cell: ({row}) => {
        if (!row.original.remittanceMethod) {
          return <Paragraph>{FallbackMessages.NO_DATA}</Paragraph>;
        }

        return renderRemittanceBadge(row.original.remittanceMethod);
      },
    },
    {
      id: "actions",
      header: () => <Paragraph className="text-right">Actions</Paragraph>,
      cell: ({row}) => {
        return (
          <div className="flex w-[80] justify-end">
            <DropdownMenu>
              {/* Trigger button for the dropdown menu */}
              <DropdownMenuTrigger asChild>
                <Button className="flex bg-theme hover:bg-neutral data-[state=open]:bg-secondary">
                  <GripVerticalIcon className="size-6 bg-inherit" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end" className="min-w-fit">
                <DropdownMenuItem
                  className="flex justify-between gap-4"
                  onSelect={() => {
                    void navigate({
                      to: "/app/imremit-lite/payment-management/$paymentId/view/$list_type",
                      params: {
                        paymentId: row.original.paymentDetailId.toString(),
                        list_type: methodType,
                      },
                      replace: true,
                    });
                  }}
                >
                  View Payment Details
                  <span className="sr-only">View Payment Details</span>
                  <FileTextIcon className="size-4" />
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        );
      },
    },
  ];

  return PMColumns;
}
