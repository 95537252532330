import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  auditFileMappingKeys,
  type bankAuditMappingListType,
} from "@/modules/imremit";

//Defining a type based on the function `getAllAuditFileMapping`
type QueryFnType = typeof getAllAuditFileMapping;

//Funciton to get the audit reports
export function getAllAuditFileMapping() {
  // Fetch data from API using ky
  return kyApiFn<bankAuditMappingListType[]>(
    `api/e-payable/admin/master-mapping/audit`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useAuditFileDetails(config?: QueryConfig<QueryFnType>) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: auditFileMappingKeys.allAuditFileMapping(),
    queryFn: () => getAllAuditFileMapping(),
  });
}
