// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type PaymentManagementIMRemitPremiumTableColumnsType} from "@/modules/imremit";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface PaymentManagementIMRemitPremiumTableColumnsStoreState {
  storePaymentManagementIMRemitPremiumTableColumns: PaymentManagementIMRemitPremiumTableColumnsType | null;
  setStorePaymentManagementIMRemitPremiumTableColumns: (
    storePaymentManagementIMRemitPremiumTableColumns: PaymentManagementIMRemitPremiumTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for PaymentManagementIMRemitPremium table columns
export const usePaymentManagementIMRemitPremiumTableColumnsStore = create(
  persist<PaymentManagementIMRemitPremiumTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storePaymentManagementIMRemitPremiumTableColumns: null,

      // Getter function to retrieve the current state
      getStorePaymentManagementIMRemitPremiumTableColumns: () =>
        get().storePaymentManagementIMRemitPremiumTableColumns,

      // Setter function to update the state
      setStorePaymentManagementIMRemitPremiumTableColumns: (
        storePaymentManagementIMRemitPremiumTableColumns
      ) => {
        set(() => ({storePaymentManagementIMRemitPremiumTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "PaymentManagementIMRemitPremium-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const usePaymentManagementIMRemitPremiumTableColumns = () =>
  usePaymentManagementIMRemitPremiumTableColumnsStore((state) => {
    return {
      storePaymentManagementIMRemitPremiumTableColumns:
        state.storePaymentManagementIMRemitPremiumTableColumns,
    };
  });
