import {FileUpIcon, ReceiptTextIcon, Settings2Icon} from "lucide-react";

import {SubrouteNavigation} from "@/components/craft/subroute-navigation";

import {StatementReconRoutesSchema} from "@/lib/routes/types";
import {KeycloakRoleEnum} from "@/modules/auth";

// Define an array with the data for each route button. This includes the 'to' route,
// the 'icon' to be displayed, and the 'label' for the button.
// This array holds the configuration for each SubrouteNavigationButton
// Each object in the array represents a button in the navigation menu
const routes = [
  {
    to: StatementReconRoutesSchema.Values["/app/statement-recon/upload"],
    icon: <FileUpIcon className="h-10 w-10 pl-2" />,
    label: "Statement Upload",
    actionRole: KeycloakRoleEnum.READ_SR_UPLOAD,
  },
  {
    to: StatementReconRoutesSchema.Values["/app/statement-recon/file-history"],
    icon: <ReceiptTextIcon className="h-10 w-10 pl-2" />,
    label: "Statement Search",
    actionRole: KeycloakRoleEnum.READ_SR_SEARCH,
  },
  {
    to: StatementReconRoutesSchema.Values["/app/statement-recon/settings"],
    icon: <Settings2Icon className="h-10 w-10 pl-2" />,
    label: "Settings",
    actionRole: KeycloakRoleEnum.READ_SR_SETTINGS,
  },
];

interface StatementReconSubrouteNavigationProps {
  layout?: "horizontal" | "vertical";
}

// This component renders the SubrouteNavigation component with the routes array
// as a prop. It also passes the 'navigationAccessibleLabel' and
// 'navigationAriaLabelledby' props to the SubrouteNavigation component.
export function StatementReconSubrouteNavigation(
  {layout}: StatementReconSubrouteNavigationProps = {layout: "horizontal"}
) {
  return (
    <SubrouteNavigation
      routes={routes}
      layout={layout}
      navigationAccessibleLabel="StatementRecon Subroute Navigation"
      navigationAriaLabelledby="statementReconSubrouteNavHeader"
    />
  );
}
