import {z} from "zod";

export const pdfUploadMetaSchema = z.object({
  file_id: z.number().optional(),
  file_location: z.string(),
  file_name: z.string(),
  file_size: z.string(),
  file_mime_type: z.string(),
  file_status_id: z.number(),
  payment_info: z.string(),
  payment_links: z.string(),
  linked_id: z.string(),
  uploaded_by: z.string(),
  buyer_external_id: z.string(),
});

export type PDFUploadMetaType = z.infer<typeof pdfUploadMetaSchema>;

export const pdfUploadMetaResponseSchema = z.object({
  file_id: z.number().optional(),
  file_location: z.string(),
  file_name: z.string(),
  file_size: z.string(),
  file_mime_type: z.string(),
  file_status_id: z.number(),
  payment_info: z.string().nullable(),
  payment_links: z.string(),
  linked_id: z.string().nullable(),
  uploaded_by: z.string(),
  buyer_external_id: z.string(),
});

export type PDFUploadMetaResponseType = z.infer<
  typeof pdfUploadMetaResponseSchema
>;

export const pdfCompleteMetaResponseSchema = z.object({
  timestamp: z.string().nullable(),
  code: z.number(),
  message: z.string().optional().nullable(),
  data: z.object({
    file_id: z.number().optional(),
    file_location: z.string(),
    file_name: z.string(),
    file_size: z.string(),
    file_mime_type: z.string(),
    file_status_id: z.number(),
    payment_info: z.string().nullable(),
    payment_links: z.string(),
    linked_id: z.string().nullable(),
    uploaded_by: z.string(),
    buyer_external_id: z.string(),
    created_timestamp: z.string().nullable(),
    updated_timestamp: z.string().nullable(),
  }),
});

export const getPDFUploadSchema = z.object({
  file_id: z.number().optional(),
  file_location: z.string(),
  file_name: z.string(),
  file_size: z.string(),
  file_mime_type: z.string(),
  file_status_id: z.number(),
  payment_info: z.string(),
  payment_links: z.string(),
  linked_id: z.string(),
  uploaded_by: z.string(),
  buyer_external_id: z.string(),
  created_timestamp: z.string(),
  updated_timestamp: z.string(),
});

// Define the schema for the entire API response
const apiPDFResponseSchema = z.object({
  timestamp: z.string(),
  code: z.number(),
  message: z.string(),
  data: z.object({
    data: z.object({
      content: z.array(getPDFUploadSchema),
    }),
  }),
});

export type GetPDFUploadType = z.infer<typeof getPDFUploadSchema>;
export type APIPDFResponseType = z.infer<typeof apiPDFResponseSchema>;
