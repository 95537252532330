import {createRoute} from "@tanstack/react-router";

import {PageNotFound} from "@/components/layout/not-found";

import {RoutesDirectory} from "@/lib/routes/directory";
import {authorizedRootRoute} from "@/lib/routes/root";
import {SettingsOutlet, SettingsPage} from "@/modules/settings";

export const settingsRootRoute = createRoute({
  path: RoutesDirectory.SETTINGS,
  component: SettingsOutlet,
  getParentRoute: () => authorizedRootRoute,
});

export const settingsRoute = createRoute({
  path: RoutesDirectory.ROOT,
  component: SettingsPage,
  getParentRoute: () => settingsRootRoute,
});

export const settingsNotFoundRoute = createRoute({
  path: RoutesDirectory.CATCH_ALL,
  component: PageNotFound,
  getParentRoute: () => settingsRootRoute,
});
