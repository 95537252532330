import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  customerModulesQueryKeys,
  type CustomerModulesType,
} from "@/modules/customers";

// Defining a type based on the function `getCustomerModules`
type QueryFnType = typeof getCustomerModules;

// Function to fetch all customers
export function getCustomerModules() {
  // Fetch all customers from API using ky
  // Always only fetches active modules (status=true)
  const data = kyApiFn<CustomerModulesType[]>(
    `customer/v1/modules?status=true`,
    "get"
  );
  // TODO: validate data
  // return customerSchema.parse(data);
  return data; // Returning the fetched data
}

// Custom hook to fetch and cache all customers data using react-query
export function useGetCustomerModules(config?: QueryConfig<QueryFnType>) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: customerModulesQueryKeys.allCustomerModules, // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getCustomerModules(), // Setting the function to be used for fetching data
    // TODO: add onSuccess and onError handlers
  });
}
