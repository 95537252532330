import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import type {
  CommentType,
  SupplierCommentsFormDTO,
  SupplierCommentsMarkAsRead,
} from "@/modules/imremit";
import {supplierCommentQueryKeys} from "@/modules/imremit";

type QueryFnType = typeof getSupplierComments;

// Get supplier comments
export function getSupplierComments(supplierId?: string) {
  return kyApiFn<CommentType[]>(
    `api/e-payable/payment-details/${supplierId}/supplier-comments`,
    "get"
  );
}

export function useGetSupplierComments(
  supplierId?: string, // The supplier id
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: supplierCommentQueryKeys.bysupplierId(supplierId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getSupplierComments(supplierId), // Setting the function to be used for fetching data
    enabled: !!supplierId, // Setting the query to be enabled only if the supplier id is not empty
  });
}

// Add supplier comments
export const saveSupplierCommentFn = (
  SupplierCommentDTO: SupplierCommentsFormDTO,
  supplierId: string
) =>
  kyApiFn(
    `api/e-payable/payment-details/${supplierId}/supplier-comments`,
    "post",
    {
      json: SupplierCommentDTO,
    }
  );

// Update supplier comments mark as read
export const updateSupplierCommentMarkAsReadFn = (
  SupplierCommentDTO: SupplierCommentsMarkAsRead,
  commentId: string,
  markAsRead: string
) =>
  kyApiFn(
    `api/e-payable/payment-details/${commentId}/supplier-comments?markAsRead=${markAsRead}`,
    "put",
    {
      json: SupplierCommentDTO,
    }
  );

// Update supplier comment
export const updateSupplierCommentFn = (
  SupplierCommentDTO: SupplierCommentsFormDTO,
  commentId: number
) =>
  kyApiFn(
    `api/e-payable/payment-details/${commentId}/supplier-comments`,
    "put",
    {
      json: SupplierCommentDTO,
    }
  );

// Delete supplier comment
// export const deleteSupplierCommentFn = (commentId: number) =>
//   kyApiFn(
//     `api/e-payable/payment-details/${commentId}/supplier-comments`,
//     "delete"
//   );
