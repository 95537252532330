import {kyApiFn} from "@/lib/ky";
import {type EditSupplierEnrolledFormLiteDTO} from "@/modules/imremit";

export const editSupplierEnrolledLiteFn = (
  editSupplierEnrolledDTO: EditSupplierEnrolledFormLiteDTO,
  supplierId: number
) =>
  kyApiFn(
    /* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */
    `api/e-payable/management/supplier-details/${supplierId}/edit`,
    "put",
    {
      json: editSupplierEnrolledDTO,
    }
  );
