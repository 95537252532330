import {Check, XCircle} from "lucide-react"; // Importing an icon from Lucide
import {useFormContext} from "react-hook-form";

import {Checkbox} from "@/components/ui/checkbox";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Separator} from "@/components/ui/separator";
import {Heading3} from "@/components/ui/typography";

import {useKeyCloakInstanceStore} from "@/modules/auth";

export function GenericUserForm({
  isMutating,
  groupsField,
  tOtpStatus,
  selectCustomerOrSupplier,
  saveButton,
  isEditing,
}: {
  isMutating: boolean;
  groupsField?: React.ReactNode;
  tOtpStatus?: boolean;
  selectCustomerOrSupplier?: React.ReactNode;
  saveButton: React.ReactNode;
  isEditing?: boolean;
}) {
  // Extract the `tokenParsed` field from the store
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  // Extract the `customer` field from the tokenParsed object
  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;
  // Check if the user is a customer admin
  const isCustomerAdmin = parsedUserInfo?.realm_access?.roles.includes(
    "name:customer-admin"
  );

  const internalRoles = [
    "name:management",
    "name:program-manager",
    "name:project-manager",
  ];
  const isInternalRole = internalRoles.some((role) =>
    parsedUserInfo?.realm_access?.roles.includes(role)
  );

  console.log("tOtpStatus", tOtpStatus);

  // Add this back in after 1.8.4.3
  // const isManagementRole =
  //   parsedUserInfo?.realm_access?.roles.includes("name:management");

  return (
    <div className="mb-8 rounded-md border border-border bg-root p-4">
      <Heading3 className="mb-4">User Details</Heading3>
      <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
        <UserNameField isMutating={isMutating} isEditing={isEditing} />
        <UserEmailField isMutating={isMutating} />
        <FirstNameField isMutating={isMutating} />
        <LastNameField isMutating={isMutating} />
        <RdpIpAddressField isMutating={isMutating} />
        {/* {isManagementRole && (
          <RequiredActionsField isMutating={isMutating} tOtpStatus={tOtpStatus} />
        )} */}
      </div>

      {groupsField && !isCustomerAdmin && (
        <>
          <Separator className="my-4" />
          {groupsField}
        </>
      )}
      {isInternalRole ? (
        <div className="mb-4">
          <Separator className="my-4" />
          {selectCustomerOrSupplier}
        </div>
      ) : (
        <Separator className="my-4" />
      )}
      <Heading3 className="mb-4">User Credentials</Heading3>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <PasswordField isMutating={isMutating} />
        <ConfirmPasswordField isMutating={isMutating} />
      </div>
      <div className="py-2">
        <TemporaryPasswordSwitch isMutating={isMutating} />
      </div>

      {saveButton}
    </div>
  );
}

function UserNameField({
  isMutating,
  isEditing,
}: {
  isMutating: boolean;
  isEditing?: boolean;
}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="username"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="username" showMandatoryAsterisk>
            Username:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              aria-disabled={isMutating}
              placeholder="Enter username..."
              disabled={isEditing}
              {...register("username")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function UserEmailField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="email"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="email" showMandatoryAsterisk>
            Email:
          </FormLabel>
          <FormControl>
            <Input
              type="email"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter user email..."
              {...register("email")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function TemporaryPasswordSwitch({isMutating}: {isMutating: boolean}) {
  const {control, register} = useFormContext();

  return (
    <FormField
      control={control}
      name="credentials.temporary"
      render={({field}) => (
        <FormItem>
          <FormLabel className="mr-2" htmlFor="credentials.temporary">
            Temporary:
          </FormLabel>
          <FormControl>
            <Checkbox
              disabled={isMutating}
              aria-disabled={isMutating}
              checked={field.value as boolean}
              onCheckedChange={field.onChange}
              {...register("credentials.temporary")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function FirstNameField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="firstName"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="firstName" showMandatoryAsterisk>
            First Name:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter first name..."
              {...register("firstName")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function LastNameField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="lastName"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="lastName">Last Name:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter last name..."
              {...register("lastName")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function RdpIpAddressField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="rdpIpaddress"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="rdpIpaddress">RDP IP Address:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter RDP IP Address..."
              {...register("rdpIpaddress")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function RequiredActionsField({
  isMutating,
  tOtpStatus,
}: {
  isMutating: boolean;
  tOtpStatus?: boolean;
}) {
  const {watch, setValue} = useFormContext<{requiredActions: string[]}>();

  // Watch for current 'requiredActions' value
  const requiredActions = watch("requiredActions", []);

  const handleCheckboxChange = (isChecked: boolean | "indeterminate") => {
    const selectedValues = isChecked
      ? [...requiredActions, "CONFIGURE_TOTP"]
      : requiredActions.filter((action) => action !== "CONFIGURE_TOTP");
    setValue("requiredActions", selectedValues);
  };

  return (
    <div className="grid grid-cols-4 gap-2">
      <FormField
        name="requiredActions"
        render={() => (
          <FormItem>
            <FormLabel htmlFor="requiredActions">Required Actions:</FormLabel>
            <FormControl>
              <div className="flex items-center space-x-2">
                <Checkbox
                  id="configureOtp"
                  checked={requiredActions.includes("CONFIGURE_TOTP")}
                  disabled={isMutating}
                  onCheckedChange={handleCheckboxChange}
                />
                <label
                  htmlFor="configureOtp"
                  className="cursor-pointer pt-1 text-sm"
                >
                  Configure OTP
                </label>
              </div>
            </FormControl>
          </FormItem>
        )}
      />

      {typeof tOtpStatus !== "undefined" && (
        <FormField
          name="tOtpStatus"
          render={() => (
            <FormItem>
              <FormLabel htmlFor="tOtpStatus">TOTP Status:</FormLabel>
              <FormControl>
                <div className="flex items-center space-x-2">
                  <span
                    className={`flex gap-2 rounded px-2 py-1 text-sm font-medium ${
                      tOtpStatus
                        ? "bg-green-100 text-green-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {tOtpStatus ? (
                      <Check className="text-green-500" size={16} />
                    ) : (
                      <XCircle className="text-red-500" size={16} />
                    )}
                    {tOtpStatus ? "Enabled" : "Disabled"}
                  </span>
                </div>
              </FormControl>
            </FormItem>
          )}
        />
      )}
    </div>
  );
}

export function PasswordField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="credentials.value"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="credentials.value" showMandatoryAsterisk>
            Password:
          </FormLabel>
          <FormControl>
            <Input
              type="password"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter user password..."
              {...register("credentials.value")}
              onInput={(e) => {
                e.currentTarget.value = e.currentTarget.value.replace(
                  /\s/g,
                  ""
                ); // Remove spaces
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function ConfirmPasswordField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="credentials.cvalue"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="credentials.cvalue" showMandatoryAsterisk>
            Confirm Password:
          </FormLabel>
          <FormControl>
            <Input
              type="password"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Confirm user password..."
              {...register("credentials.cvalue")}
              onInput={(e) => {
                e.currentTarget.value = e.currentTarget.value.replace(
                  /\s/g,
                  ""
                ); // Remove spaces
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
