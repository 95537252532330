import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import type {ExtractFnReturnType, QueryConfig} from "@/lib/query-client";
import type {controlBatchProcessingType} from "@/modules/customers";
import {batchProcessingFlagQueryKeys} from "@/modules/customers";

export const controlBatchProcessingDataFn = (
  externalId: string,
  supplierId: string,
  flag: string
) =>
  kyApiFn(
    `api/e-payable/management/batch-processing-flag/set/${externalId}/${supplierId}?flag=${flag}`,
    "post"
  );

// Defining a type based on the function `searchBatchProcessingFlag`
type QueryFnType = typeof getBatchProcessingFlag;

// Function to search BatchProcessingFlag
export function getBatchProcessingFlag(externalId: string, supplierId: string) {
  return kyApiFn<controlBatchProcessingType>(
    `api/e-payable/autopay-batch-processing/get/${externalId}/${supplierId}`,
    "get"
  );
}

// Custom hook to fetch and cache BatchProcessingFlag data using react-query
export function useGetBatchProcessingFlag(
  externalId: string, // The search query string
  supplierId: string,
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: batchProcessingFlagQueryKeys.byExternalIdAndSupplierId(
      externalId,
      supplierId
    ), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getBatchProcessingFlag(externalId, supplierId), // Setting the function to be used for fetching data
    enabled: !!externalId && !!supplierId,
  });
}
