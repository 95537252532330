import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {runnerConfigsKeys, type RunnerConfigsType} from "@/modules/imremit";

// Defining a type based on the function `getRunnerConfigs`
type QueryFnType = typeof getRunnerConfigs;

// Function to search getRunnerConfigs
export function getRunnerConfigs(externalId: string, configId: string) {
  return kyApiFn<RunnerConfigsType[]>(
    `api/e-payable/management/${externalId}/config/${configId}`,
    "get"
  );
}

// Custom hook to fetch and cache Runner Configs list based on the `participant id` data using react-query
export function useGetRunnerConfigs(
  externalId: string, // The externalId string
  configId: string, // The configId number
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: runnerConfigsKeys.byConfigId(externalId, configId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getRunnerConfigs(externalId, configId), // Setting the function to be used for fetching data
    enabled: !!externalId, // Setting the query to be enabled only if the search query string is not empty
  });
}
