import {useQuery} from "@tanstack/react-query";

import {keycloakKyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  keycloakUserGroupsQueryKeys,
  type KeycloakUserGroupType,
} from "@/modules/admin";

// Defining a type based on the function `getgroupByUserId`
type QueryFnType = typeof getgroupByUserId;

// Function to get all groups for user
export function getgroupByUserId(userId: string) {
  const realm =
    (import.meta.env.VITE_KEYCLOAK_REALM as string | undefined) ?? "dev";
  return keycloakKyApiFn<KeycloakUserGroupType[]>(
    `admin/realms/${realm}/users/${userId}/groups`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useGetgroupByUserId(
  userId: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: keycloakUserGroupsQueryKeys.byUserId(userId),
    queryFn: () => getgroupByUserId(userId),
    enabled: !!userId,
  });
}
