import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  proxyPayStatusListKeys,
  type ProxyPayPaymentStatusesSchema,
} from "@/modules/imremit";

// Defining a type based on the function `getProxyPayStatuses`
type QueryFnType = typeof getProxyPayStatuses;

// Function to search proxypay
export function getProxyPayStatuses(modtype?: string) {
  return kyApiFn<ProxyPayPaymentStatusesSchema[]>(
    `api/e-payable/payment-statuses${
      modtype === "lite" ? "?processType=imREmit_lite" : ""
    }`,
    "get"
  );
  // TODO: validate data
  // return ProxyPayViewPageSchema.parse(data);
}

// Custom hook to fetch and cache payment status using react-query for payment management
export function useGetProxyPayPaymentStatuses(
  moduleType?: string, // premium or lite
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: proxyPayStatusListKeys.byId(moduleType), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getProxyPayStatuses(moduleType), // Setting the function to be used for fetching data
    enabled: !!moduleType, // Setting the query to be enabled only if the search query string is not empty
  });
}
