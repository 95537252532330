import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  paymentCommentQueryKeys,
  type CommentType,
  type ExportPaymentDataFormDTO,
  type PaymentCommentsFormDTO,
} from "@/modules/imremit";

// Defining a type based on the function `searchImREmitRunnerTypes`
type QueryFnType = typeof getPaymentComments;

// Function to search imremits runnertypes
export function getPaymentComments(paymentId?: string) {
  paymentId = paymentId ?? "";
  return kyApiFn<CommentType[]>(
    `api/e-payable/payment-details/${paymentId.toString()}/comments`,
    "get"
  );
  // TODO: validate data
}

// Custom hook to fetch and cache imremits runnertypes data using react-query
export function useGetPaymentComments(
  paymentId?: string, // The payment id
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: paymentCommentQueryKeys.byPaymentId(paymentId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getPaymentComments(paymentId), // Setting the function to be used for fetching data
    enabled: !!paymentId, // Setting the query to be enabled only if the payment id is not empty
  });
}

export const savePaymentCommentFn = (
  PaymentCommentDTO: PaymentCommentsFormDTO,
  id: string
) =>
  kyApiFn(`api/e-payable/payment-details/${id}/comments`, "post", {
    json: PaymentCommentDTO,
  });

export const updatePaymentCommentFn = (
  PaymentCommentDTO: PaymentCommentsFormDTO,
  id: string
) =>
  kyApiFn(`api/e-payable/payment-details/${id}/comments`, "put", {
    json: PaymentCommentDTO,
  });

export const deletePaymentCommentFn = (commentId: number) =>
  kyApiFn(
    `api/e-payable/payment-details/${commentId.toString()}/comments`,
    "delete"
  );

// Update Comments & Status
export const paymentSubmittedFn = (
  PaymentCommentDTO: PaymentCommentsFormDTO,
  paymentId: string,
  paymentStatus: string
) =>
  kyApiFn(
    `api/e-payable/payment-details/${paymentId}/comments?status=${paymentStatus}`,
    "post",
    {
      json: PaymentCommentDTO,
    }
  );

// export Payment comments
export const exportPaymentDataFn = (
  ExportPaymentDataFormDTO: ExportPaymentDataFormDTO
) =>
  kyApiFn("api/e-payable/management/payment-details/export", "post", {
    json: ExportPaymentDataFormDTO,
  });
