import {format} from "date-fns";

import {Badge} from "@/components/ui/badge";
import {Card} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {Heading3} from "@/components/ui/typography";
import {TableSkeleton} from "@/components/craft/table";

import type {KeycloakUserSessionType} from "@/modules/admin";

interface UserSessionsTableProps {
  userSessions: KeycloakUserSessionType[];
  isError: boolean;
  isSuccess: boolean;
}

export function NewUserSessionsTable({
  userSessions,
  isError,
  isSuccess,
}: UserSessionsTableProps) {
  return (
    <section className="flex flex-col gap-2">
      <Heading3>User Sessions</Heading3>
      <Card>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Username</TableHead>
              <TableHead>IP Address</TableHead>
              <TableHead>Access Started</TableHead>
              <TableHead>Last Accessed</TableHead>
              <TableHead>Remember Me</TableHead>
            </TableRow>
          </TableHeader>
          {isError ? (
            <TableBody>
              <TableRow>
                <TableCell className="h-24 text-center" colSpan={5}>
                  Error loading data.
                </TableCell>
              </TableRow>
            </TableBody>
          ) : !isSuccess ? (
            <TableSkeleton
              columnsLength={5}
              numRowsPerPage={1}
              omittedColumns={{}}
            />
          ) : userSessions.length ? (
            <TableBody>
              {userSessions.map((session) => (
                <TableRow key={session.id}>
                  <TableCell>{session.username}</TableCell>
                  <TableCell>{session.ipAddress}</TableCell>
                  <TableCell>
                    {format(new Date(session.start), "PPpp")}
                  </TableCell>
                  <TableCell>
                    {format(new Date(session.lastAccess), "PPpp")}
                  </TableCell>
                  <TableCell>
                    {session.rememberMe ? (
                      <Badge>Yes</Badge>
                    ) : (
                      <Badge variant="destructive">No</Badge>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell className="h-24 text-center" colSpan={5}>
                  No user sessions found.
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </Card>
    </section>
  );
}
