import {useQuery} from "@tanstack/react-query";
import {z} from "zod";

import {keycloakKyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {keycloakRequiredActionQueryKeys} from "@/modules/admin";

const keycloakRequiredActionSchema = z.object({
  id: z.string(),
  alias: z.string(),
  name: z.string(),
  providerId: z.string(),
  enabled: z.boolean(),
  defaultAction: z.boolean(),
  priority: z.number(),
  config: z.object({}),
});

type keycloakRequiredActionSchemaType = z.infer<
  typeof keycloakRequiredActionSchema
>;

// Defining a type based on the function `getAllRequiredAction`
type QueryFnType = typeof getAllRequiredAction;

// Function to get all realm user groups
export function getAllRequiredAction() {
  const realm =
    (import.meta.env.VITE_KEYCLOAK_REALM as string | undefined) ?? "dev";
  return keycloakKyApiFn<keycloakRequiredActionSchemaType[]>(
    `admin/realms/${realm}/authentication/required-actions`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useAllRequiredAction(config?: QueryConfig<QueryFnType>) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: keycloakRequiredActionQueryKeys.all,
    queryFn: () => getAllRequiredAction(),
  });
}
