import {useMemo, useState} from "react";

import {Card, CardContent, CardHeader, CardTitle} from "@/components/ui/card";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {InfiniteMultiSelect} from "@/components/craft/infinite-multi-select";

import {useSelectInfiniteCustomers} from "@/modules/customers";

export function MISPaymentsDifferencesForm() {
  // Add these new states and queries
  const [customerSearch, setCustomerSearch] = useState("");
  const {
    data: infiniteCustomersData,
    fetchNextPage: fetchNextCustomers,
    hasNextPage: hasNextCustomersPage,
    isFetching: isLoadingCustomers,
    isFetchingNextPage: isFetchingNextCustomers,
  } = useSelectInfiniteCustomers(customerSearch);

  // Transform infinite data into options
  const customerOptions = useMemo(() => {
    if (!infiniteCustomersData?.pages) return [];

    return infiniteCustomersData.pages.reduce<
      Array<{label: string; value: string}>
    >((acc, page) => {
      const pageCustomers = page.content.map((customer) => ({
        label: customer.buyerName,
        value: customer.externalId.toString(),
      }));
      return [...acc, ...pageCustomers];
    }, []);
  }, [infiniteCustomersData?.pages]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>MIS Filter Settings</CardTitle>
      </CardHeader>
      <CardContent>
        <form className="grid grid-cols-1 items-center gap-4">
          <FormField
            name="customers"
            render={({field}) => (
              <FormItem className="flex flex-col">
                <FormLabel htmlFor="customers" showMandatoryAsterisk>
                  Customers:
                </FormLabel>
                <FormControl>
                  <InfiniteMultiSelect
                    {...field}
                    options={customerOptions}
                    placeholder="Select customers..."
                    searchPlaceholder="Search customers..."
                    isLoading={isLoadingCustomers}
                    isFetchingNextPage={isFetchingNextCustomers}
                    hasNextPage={hasNextCustomersPage}
                    onSearch={setCustomerSearch}
                    onLoadMore={() => void fetchNextCustomers()}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </CardContent>
    </Card>
  );
}
