import {Link, useRouterState} from "@tanstack/react-router";
import {ArrowRightIcon} from "lucide-react";

import {Button, buttonVariants} from "@/components/ui/button";

import {cn} from "@/lib/utils";

const Tabs = [
  // "sftp-configuration",
  "email-configuration",
  "payment-method",
  "participant-register",
  "runner-configuration",
];

export function CustomerOnboardingTabs() {
  const router = useRouterState();
  return (
    <div className="flex w-full flex-col gap-2 lg:w-fit lg:flex-row lg:flex-wrap">
      {Tabs.map((tab, index) => (
        <Button
          key={tab}
          size="sm"
          className="w-full gap-4 lg:w-60"
          variant={
            router.location.pathname.includes(tab) ? "affirmative" : "default"
          }
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <span className=" flex h-6 w-6 items-center justify-center rounded-full bg-white text-center text-accent-foreground">
            {index + 1}
          </span>
          {tab.replace(/-/g, " ").replace(/\b\w/g, (l) => l.toUpperCase())}
        </Button>
      ))}

      <Link
        to="/app/admin/customer-management"
        className={cn(
          buttonVariants({size: "sm", variant: "secondary"}),
          "w-full gap-4 lg:w-60"
        )}
      >
        <span className="sr-only">Back to list</span>
        Back to list
        <ArrowRightIcon className="size-4" />
      </Link>
    </div>
  );
}
