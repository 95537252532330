import {kyApiFn} from "@/lib/ky";
import {type EditSupplierEnrolledFormDTO} from "@/modules/imremit";

export const editSupplierEnrolledFn = (
  editSupplierEnrolledDTO: EditSupplierEnrolledFormDTO,
  supplierId: number
) =>
  kyApiFn(
    `api/e-payable/management/supplier-details/${supplierId}/edit`,
    "put",
    {
      json: editSupplierEnrolledDTO,
    }
  );
