import {useState} from "react";

import type {Table} from "@tanstack/react-table";
import {ListRestartIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import {
  DataTablePagination,
  // DataTableFacetedFilter,
  DataTableViewOptions,
} from "@/components/craft/data-table";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

// import {priorities, statuses} from "../data/data";

interface ReconFileToolbarProps<TData> {
  table: Table<TData>;
  isPending?: boolean;
  isErrored?: boolean;
}

export function ReconFileToolbar<TData>({
  table,
  isPending,
  isErrored,
}: ReconFileToolbarProps<TData>) {
  const [globalFilterInput, setGlobalFilterInput] = useState("");
  const [mappingNameFilterInput, setMappingNameFilterInput] = useState("");

  const isFiltered =
    table.getState().columnFilters.length > 0 ||
    !!table.getState().globalFilter;

  //Columns other names
  const columnsOtherName = [
    {key: "masterMappingName", value: "Mapping Name"},
    {key: "relationId", value: "Relation ID"},
    {key: "supplierNumber", value: "Supplier Number"},
    {key: "cardNumber", value: "Card Number"},
    {key: "transactionType", value: "Transaction Type"},
    {key: "transactionId", value: "Transaction ID"},
    {key: "transactionAmount", value: "Transaction Amount"},
    {key: "transactionDate", value: "Transaction Date"},
    {key: "postingDate", value: "Posting Date"},
    {key: "merchantName", value: "Merchant Name"},
    {key: "originalAmount", value: "Original Amount"},
    {key: "udf1", value: "UDF1"},
    {key: "udf2", value: "UDF2"},
    {key: "udf3", value: "UDF3"},
    {key: "udf4", value: "UDF4"},
    {key: "udf5", value: "UDF5"},
    {key: "customData1", value: "Custom Data 1"},
    {key: "customData2", value: "Custom Data 2"},
    {key: "customData3", value: "Custom Data 3"},
    {key: "orderMerchantName", value: "Order Merchant Name"},
  ];
  return (
    <>
      <DataTablePagination
        table={table}
        isPending={isPending}
        isErrored={isErrored}
      />
      <div className="flex items-center justify-between">
        <div className="flex flex-1 items-center space-x-2">
          <RowsPreferenceSelect />
          <Input
            placeholder="Search all entries..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={globalFilterInput}
            onChange={(event) => {
              setGlobalFilterInput(event.target.value);
              table.setGlobalFilter(event.target.value);
            }}
          />
          <Input
            placeholder="Search mapping names..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={mappingNameFilterInput}
            onChange={(event) => {
              table;
              setMappingNameFilterInput(event.target.value);
              table
                .getColumn("masterMappingName")
                ?.setFilterValue(event.target.value);
            }}
          />
          {/* {table.getColumn("status") && (
          <DataTableFacetedFilter
            column={table.getColumn("status")}
            title="Status"
            options={statuses}
          />
        )}
        {table.getColumn("priority") && (
          <DataTableFacetedFilter
            column={table.getColumn("priority")}
            title="Priority"
            options={priorities}
          />
        )} */}
          {isFiltered && (
            <Button
              variant="destructive"
              className="h-10 gap-2 px-2 hover:bg-destructive/70 hover:text-destructive-foreground/80 active:bg-destructive/80 active:text-destructive-foreground/80 lg:px-3"
              onClick={() => {
                table.resetColumnFilters();
                table.resetGlobalFilter();
                setGlobalFilterInput("");
                setMappingNameFilterInput("");
              }}
            >
              <span className="sr-only">Reset search filters</span>
              Search Reset
              <ListRestartIcon className="size-4" />
            </Button>
          )}
        </div>
        <DataTableViewOptions
          table={table}
          columnsOtherName={columnsOtherName}
        />
      </div>
    </>
  );
}
