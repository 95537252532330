import {kyApiFn} from "@/lib/ky";
import {type AddImREmitFormDTO} from "@/modules/imremit";

export const addImREmitFn = (
  addImREmitDTO: AddImREmitFormDTO,
  externalId?: string
) =>
  kyApiFn(`api/e-payable/management/${externalId}/create`, "post", {
    json: addImREmitDTO,
  });
