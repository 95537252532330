export const paymentManagementModule = [
  "create:payment-management",
  "read:payment-management",
  "update:payment-management",
  "update:payment-block-lite",
  "read:payment-management-lite",
  "read:failed-alerts",
  "update:payment-authorization",
  "create:remittance-management",
  "read:remittance-management",
  "update:remittance-management",
  "delete:remittance-management",
  "create:add-payment-comment-lite",
  "update:payment-block",
  "create:add-payment-comment",
  "update:payment-reprocess",
  "create:payment-management-export",
  "update:pay-by-web",
  "create:payment-management-export-lite",
  "update:payment-reprocess-lite",
  "update:move-payment",
  "update:pay-by-web-lite",
  "read:card-details",
  "read:card-details-lite",
];

export const userManagementModule = [
  "create:user-management",
  "read:user-management",
  "update:user-management",
  "delete:user-management",
];

export const electronicInvoicingModule = ["read:electronic-invoicing"];

export const fileProcessingModule = ["read:file-processing"];

export const IMRemitDashboardModule = [
  "read:imremit-lite-dashboard",
  "read:imremit-dashboard-admin",
  "read:imremit-dashboard",
  "create:imremit-mapping",
  "read:imremit",
  "read:imremit-lite",
];

export const proxyPayModule = [
  "read:proxypay-dashboard",
  "read:proxypay-dashboard-lite",
  "read:proxypay-resolve-payment-btn",
  "create:add-proxy-payment-comment-lite",
  "create:add-proxy-payment-comment",
];

export const invoiceTrackerModule = ["read:invoice-tracker"];

export const supplierManagementModule = [
  "create:supplier-management",
  "read:supplier-management",
  "update:supplier-management",
  "delete:supplier-management",
  "create:supplier-management-lite",
  "read:supplier-management-lite",
  "update:supplier-management-lite",
  "delete:supplier-management",
  "delete:supplier-management-lite",
  "update:supplier-status",
  "update:supplier-status-lite",
  "create:supplier-management-export-lite",
  "create:supplier-management-export",
  "read:card-type",
  "update:flag-reset",
];

export const customerManagementModule = [
  "read:customer-management",
  "update:customer-management",
  "update:control-batch-processing",
  "create:customer-management",
  "delete:customer-management",
  "create:customer-modules",
  "update:customer-onboard",
];

export const supplierScriptManagementModule = [
  "create:supplier-script-management",
  "update:supplier-script-management",
  "create:supplier-script-management-lite",
  "read:supplier-script-management",
  "update:supplier-script-management-lite",
  "read:supplier-script-management-lite",
];

export const duplicatePaymentsModule = [
  "read:dupes-tracker",
  "create:dupes-criteria",
  "read:dupes-criteria",
  "update:dupes-criteria",
  "delete:dupes-criteria",
  "read:dupes-dashboard",
  "read:duplicate-payments",
];

export const statementReconModule = [
  "read:statement-recon",
  "read:sr-upload",
  "read:sr-search",
  "read:sr-settings",
  "create:sr-settings",
  "update:sr-settings",
];

export const adminModule = [
  "read:admin",
  "read:master-role-settings",
  "read:alert-management",
  "read:customer-module-management",
];
