import {z} from "zod";

export const denormalizedocumentFormatSchema = z.object({
  id: z.number(),
  documentFormatName: z.string().nullable(),
  documentFormatValue: z.string().nullable(),
  documentFormatDescription: z.string().nullable(),
  documentFormatResponse: z.string().nullable(),
  documentTypeId: z.number().nullable(),
  masterMappingId: z.number().nullable(),
});
export type ImREmitDenormalizeDocumentFormat = z.infer<
  typeof denormalizedocumentFormatSchema
>;
