import {createRoute} from "@tanstack/react-router";

import {RoutesDirectory} from "@/lib/routes/directory";
import {
  AddSupplierScriptPage,
  EditSupplierScriptPage,
  imREmitRootRoute,
  SupplierScriptManagementPage,
  ViewSupplierScriptPage,
} from "@/modules/imremit";

export const imREmitSupplierScriptRoute = createRoute({
  path: RoutesDirectory.SUPPLIER_SCRIPT_MANAGEMENT,
  component: SupplierScriptManagementPage,
  getParentRoute: () => imREmitRootRoute,
});

export const imREmitAddSupplierScriptRoute = createRoute({
  path: `${RoutesDirectory.SUPPLIER_SCRIPT_MANAGEMENT}/$moduleName/$supplierId${RoutesDirectory.CREATE}/$paymentId`,
  component: AddSupplierScriptPage,
  getParentRoute: () => imREmitRootRoute,
});

export const imREmitEditSupplierScriptRoute = createRoute({
  path: `${RoutesDirectory.SUPPLIER_SCRIPT_MANAGEMENT}/$moduleName/$supplierId${RoutesDirectory.EDIT}/$paymentId`,
  component: EditSupplierScriptPage,
  getParentRoute: () => imREmitRootRoute,
});

export const imREmitAddSupplierScriptDirectRoute = createRoute({
  path: `${RoutesDirectory.SUPPLIER_SCRIPT_MANAGEMENT}/$supplierId${RoutesDirectory.CREATE}`,
  component: AddSupplierScriptPage,
  getParentRoute: () => imREmitRootRoute,
});

export const imREmitViewSupplierScriptRoute = createRoute({
  path: `${RoutesDirectory.SUPPLIER_SCRIPT_MANAGEMENT}/$moduleName/$supplierId${RoutesDirectory.VIEW}/$paymentId`,
  component: ViewSupplierScriptPage,
  getParentRoute: () => imREmitRootRoute,
});
