import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  misPaymentsDifferencesQueryKeys,
  type MISPaymentsDifferencesType,
} from "@/modules/admin";

// Defining a type based on the function `getMISDifferencesPayments`
type QueryFnType = typeof getMISDifferencesPayments;

// Function to search getMISDifferencesPayments
export const getMISDifferencesPayments = (queryParams?: string) =>
  kyApiFn<MISPaymentsDifferencesType[]>(
    `mis/v1/payment-differences?${queryParams ? queryParams : ""}`,
    "get"
  );

// Custom hook to fetch and cache payments received list based on the `participant id` data using react-query
export function useGetMISDifferencesPayments(
  queryParams?: string,
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: misPaymentsDifferencesQueryKeys.byFilter(queryParams), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getMISDifferencesPayments(queryParams), // Setting the function to be used for fetching data
    enabled: !!queryParams, // Setting the query to be enabled only if the search query string is not empty
  });
}
