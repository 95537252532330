import {useEffect, useMemo} from "react";

import {toZonedTime} from "date-fns-tz";
import {type UseFormSetValue} from "react-hook-form";
import type {z} from "zod";

import {type MultiSelectDatum} from "@/components/craft/fancy-multi-select";

import {useAllCustomers} from "@/modules/customers";
import {useGetAllFacilitiesByCustomer} from "@/modules/facilities";
import {useGetAllSuppliersByCustomerId} from "@/modules/imremit";
import type {
  SavedSearchCriteriaType,
  SearchInvoicesFormSchema,
} from "@/modules/invoices";
import {useAllnvoiceStatuses} from "@/modules/invoices";

import {parseAndFormatDate} from "@/utils/functions";

/**
 * Transforms the form fields into a structure suitable for API calls.
 *
 * @param {object} formFields - The form fields to transform.
 * @returns {object} Transformed form fields.
 */
export function useCriteriaFields(
  formFields: z.infer<typeof SearchInvoicesFormSchema>
) {
  // Destructure form fields for easier access and manipulation.
  const {
    customers,
    facilities,
    suppliers,
    status,
    invoiceDateEnd,
    erpUniqueId,
    invoiceAmount,
    invoiceNumber,
    paymentNumber,
    poNumber,
    invoiceDateStart,
    invoiceDate,
    paymentDate,
  } = formFields;

  return useMemo(() => {
    const criteriaFields = {
      externalId: customers.map((customer) => customer.value),
      supplierId: suppliers.map((supplier) => parseInt(supplier.value)),
      status: status.map((status) => parseInt(status.value)),
      facilityId: facilities.map((facility) => parseInt(facility.value)),
      invoiceDateStart: invoiceDateStart
        ? parseAndFormatDate(invoiceDateStart.toString())
        : null,
      invoiceDateEnd: invoiceDateEnd
        ? parseAndFormatDate(invoiceDateEnd.toString())
        : null,
      paymentNumber: paymentNumber ? paymentNumber.toString() : null,
      poNumber: poNumber ? poNumber.toString() : null,
      invoiceNumber: invoiceNumber ? invoiceNumber.toString() : null,
      erpUniqueId: erpUniqueId ? erpUniqueId : null,
      invoiceAmount: invoiceAmount ? invoiceAmount : null,
      invoiceDate: invoiceDate
        ? parseAndFormatDate(invoiceDate.toString())
        : null,
      paymentDate: paymentDate
        ? parseAndFormatDate(paymentDate.toString())
        : null,
    };

    // This is a stupid hack to search buyers by externalId
    // since they did not update the property name on BE after migrating to externalId
    return {
      buyerId: criteriaFields.externalId,
      ...criteriaFields,
    };
  }, [
    customers,
    facilities,
    suppliers,
    status,
    invoiceDateEnd,
    erpUniqueId,
    invoiceAmount,
    invoiceNumber,
    paymentNumber,
    poNumber,
    invoiceDateStart,
    invoiceDate,
    paymentDate,
  ]);
}

/**
 * Sets the selected saved search as the form value.
 *
 * @param {object | null} selectedSavedSearch - The selected saved search criteria.
 * @param {function} setValue - Function from react-hook-form to set form values.
 * @param {array} customersData - Array of customer data.
 * @param {array} suppliersData - Array of supplier data.
 * @param {array} facilitiesData - Array of facility data.
 * @param {array} statusData - Array of status data.
 */
export function useSetSelectedSavedSearch(
  selectedSavedSearch: SavedSearchCriteriaType | null,
  setValue: UseFormSetValue<z.infer<typeof SearchInvoicesFormSchema>>,
  customersData: MultiSelectDatum[],
  suppliersData: MultiSelectDatum[],
  facilitiesData: MultiSelectDatum[],
  statusData: MultiSelectDatum[]
) {
  // Effect hook to populate form fields based on the stored saved search criteria.
  useEffect(() => {
    if (selectedSavedSearch) {
      // Populate the 'customers' field if externalId exists in the saved search criteria.
      if (selectedSavedSearch.externalId) {
        setValue(
          "customers",
          selectedSavedSearch.externalId.map((buyer) => ({
            label: customersData
              .filter((cust) => {
                return cust.value === buyer.toString();
              })
              .map((res) => {
                return res.label;
              })
              .toString(),
            value: buyer.toString(),
          })),
          {shouldDirty: true}
        );
      }

      if (selectedSavedSearch.supplierId) {
        setValue(
          "suppliers",
          selectedSavedSearch.supplierId.map((suppl) => ({
            label: suppliersData
              .filter((supplier) => {
                return supplier.value === suppl.toString();
              })
              .map((res) => {
                return res.label;
              })
              .toString(),
            value: suppl.toString(),
          })),
          {shouldDirty: true}
        );
      }

      if (selectedSavedSearch.facilityId) {
        setValue(
          "facilities",
          selectedSavedSearch.facilityId.map((result) => ({
            label: facilitiesData
              .filter((facility) => {
                return facility.value === result.toString();
              })
              .map((res) => {
                return res.label;
              })
              .toString(),
            value: result.toString(),
          })),
          {shouldDirty: true}
        );
      }

      if (selectedSavedSearch.status) {
        setValue(
          "status",
          selectedSavedSearch.status.map((sts) => ({
            label: statusData
              .filter((statuses) => {
                return statuses.value === sts.toString();
              })
              .map((res) => {
                return res.label;
              })
              .toString(),
            value: sts.toString(),
          })),
          {shouldDirty: true}
        );
      }

      if (selectedSavedSearch.invoiceAmount !== null) {
        setValue("invoiceAmount", selectedSavedSearch.invoiceAmount, {});
      } else {
        setValue("invoiceAmount", "");
      }

      if (selectedSavedSearch.invoiceNumber !== null) {
        setValue("invoiceNumber", selectedSavedSearch.invoiceNumber, {});
      } else {
        setValue("invoiceNumber", "");
      }

      if (selectedSavedSearch.paymentNumber !== null) {
        setValue("paymentNumber", selectedSavedSearch.paymentNumber, {});
      } else {
        setValue("paymentNumber", "");
      }

      if (selectedSavedSearch.poNumber !== null) {
        setValue("poNumber", selectedSavedSearch.poNumber, {});
      } else {
        setValue("poNumber", "");
      }

      if (selectedSavedSearch.erpUniqueId !== null) {
        setValue("erpUniqueId", selectedSavedSearch.erpUniqueId, {});
      } else {
        setValue("erpUniqueId", "");
      }

      if (selectedSavedSearch.invoiceDate) {
        setValue("invoiceDate", new Date(selectedSavedSearch.invoiceDate));
      } else {
        setValue("invoiceDate", undefined);
      }

      if (selectedSavedSearch.paymentDate) {
        setValue("paymentDate", new Date(selectedSavedSearch.paymentDate));
      } else {
        setValue("paymentDate", undefined);
      }

      // Populate the 'invoiceDateStart' and 'invoiceDateEnd' if they exist in the saved search criteria.
      setValue(
        "invoiceDateStart",
        selectedSavedSearch.invoiceDateStart
          ? toZonedTime(new Date(selectedSavedSearch.invoiceDateStart), "UTC")
          : undefined
      );

      setValue(
        "invoiceDateEnd",
        selectedSavedSearch.invoiceDateEnd
          ? toZonedTime(new Date(selectedSavedSearch.invoiceDateEnd), "UTC")
          : undefined
      );
    }

    // Continue with other fields...
    // [Additional comments can be added here for further fields]
  }, [
    suppliersData,
    facilitiesData,
    customersData,
    setValue,
    selectedSavedSearch,
    statusData,
  ]);
}

/**
 * Fetches and parses data to populate the form.
 *
 * @param {object} formFields - The current form fields.
 * @returns {object} Formatted data for customers, suppliers, facilities, and statuses.
 */
export function useFetchAndParsePopulationData(
  formFields: z.infer<typeof SearchInvoicesFormSchema>
) {
  //STATUS//
  const allStatusQuery = useAllnvoiceStatuses();
  // Extract the raw data content from the query hooks.
  const rawStatusData = allStatusQuery.data?.content;

  // Format the customer data to match the structure needed for the FancyMultiSelect component.
  const statusData = useMemo(() => {
    if (!rawStatusData) return [];
    return rawStatusData.map((status) => ({
      label: status.status,
      value: status.id.toString(),
    }));
  }, [rawStatusData]);
  //STATUS//

  // Initialize query hooks to fetch all customers data
  const allCustomersQuery = useAllCustomers();

  // Extract the raw data content from the query hooks.
  const rawCustomersData = allCustomersQuery.data?.content;

  // Format the customer data to match the structure needed for the FancyMultiSelect component.
  const customersData = useMemo(() => {
    if (!rawCustomersData) return [];
    return rawCustomersData.map((customer) => ({
      label: customer.buyerName,
      value: customer.externalId.toString(),
    }));
  }, [rawCustomersData]);

  // Utilize React's useMemo hook to optimize performance by memoizing the derived data.
  // This will recompute the `selectedCustomers` only when `formFields.customers` changes.
  const selectedCustomers = useMemo(() => {
    return formFields.customers.map((customer) => customer.value);
  }, [formFields.customers]);

  // Execute the query to get all suppliers by customer ID.
  // The customer IDs are extracted from `formFields.customers` and joined into a comma-separated string.
  const getSupplierByCustomerIdQuery =
    useGetAllSuppliersByCustomerId(selectedCustomers);

  // Extract raw supplier data from the query response.
  const rawSuppliersData = getSupplierByCustomerIdQuery.data?.content;

  // Utilize React's useMemo hook to optimize performance by memoizing the derived data.
  // This will recompute the `suppliersData` only when `rawSuppliersData` changes.

  // Format raw suppliers data to match the data structure expected by the FancyMultiSelect component.
  const suppliersData = useMemo(() => {
    // If rawSuppliersData is null or undefined, return an empty array.
    if (!rawSuppliersData) return [];

    // Map over rawSuppliersData to transform it into the required format.
    return rawSuppliersData.map((supplier) => ({
      label: supplier.number + "-" + supplier.name, // Use the 'name' property for the label
      value: supplier.id.toString(), // Convert the 'id' property to string for the value
    }));
  }, [rawSuppliersData]); // Re-compute only when rawSuppliersData changes.

  // Execute the query to get all facilities by customer ID.
  const allFacilityQuery = useGetAllFacilitiesByCustomer(selectedCustomers);
  // Extract raw facility data from the query response.
  const rawFacilitiesData = allFacilityQuery.data?.content;

  // Format raw facilities data to match the data structure expected by the FancyMultiSelect component.
  const facilitiesData = useMemo(() => {
    // If rawFacilitiesData is null or undefined, return an empty array.
    if (!rawFacilitiesData) return [];

    // Map over rawFacilitiesData to transform it into the required format.
    return rawFacilitiesData.map((facility) => ({
      label: facility.facilityName, // Use the 'name' property for the label
      value: facility.participantRegisterId.toString(), // Convert the 'id' property to string for the value
    }));
  }, [rawFacilitiesData]); // Re-compute only when rawFacilitiesData changes.

  return {
    customersData,
    suppliersData,
    facilitiesData,
    statusData,
  };
}
