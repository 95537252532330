import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import type {
  StatementFileType,
  StatementReconciliationTriggerType,
  StatementSearchTableType,
} from "@/modules/statement-recon";
import {
  statementFilesExternalKeys,
  statementFilesJSONIDKeys,
  statementFilesKeys,
} from "@/modules/statement-recon";

//Defining a type based on the function `getStatementFileByExternalId`
type QueryFnType = typeof getStatementFileByExternalId;
type getStatementFileJsonByIdQueryFnType = typeof getStatementFileJsonById;
type getStatementFileByFileId = typeof getStatementFileByFileId;

// Defining a function to fetch data from API
export function getStatementFileByExternalId(externalID: string) {
  // Fetch data from API using ky
  return kyApiFn<StatementSearchTableType[]>(
    `statement-recon/v1/statement-file/${externalID}`,
    "get"
  );
}

export function getStatementFileByFileId(file_id?: string) {
  return kyApiFn<StatementFileType>(
    `statement-recon/v1/statement-file/${file_id ?? ""}/details`,
    "get"
  );
}

type StatementFileJsonType = {
  data: {[key: string]: string};
};

export function getStatementFileJsonById(file_id?: string) {
  return kyApiFn<StatementFileJsonType>(
    `statement-recon/v1/statement-file/${file_id ?? ""}/json`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useStatementFileByExternalId(
  externalID: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: statementFilesExternalKeys.byExternalID(externalID), // Unique key for cache 🗝
    queryFn: () => getStatementFileByExternalId(externalID), // Data fetching function 📡
    enabled: !!externalID, // Condition to enable the query 🚦
  });
}

export function useStatementFileByFileId(
  file_id: string,
  config?: QueryConfig<getStatementFileByFileId>
) {
  return useQuery<ExtractFnReturnType<getStatementFileByFileId>>({
    ...config,
    queryKey: statementFilesKeys.byFileID(file_id), // Unique key for cache 🗝
    queryFn: () => getStatementFileByFileId(file_id), // Data fetching function 📡
    enabled: !!file_id, // Condition to enable the query 🚦
  });
}

export function useStatementFileJsonById(
  file_id?: string,
  config?: QueryConfig<getStatementFileJsonByIdQueryFnType>
) {
  return useQuery<ExtractFnReturnType<getStatementFileJsonByIdQueryFnType>>({
    ...config,
    queryKey: statementFilesJSONIDKeys.byFileID(file_id), // Unique key for cache 🗝
    queryFn: () => getStatementFileJsonById(file_id), // Data fetching function 📡
    enabled: !!file_id, // Condition to enable the query 🚦
  });
}

// Defining a function to fetch data from API
export function performStatementFileReconciliationFn(
  data: StatementReconciliationTriggerType
) {
  return kyApiFn<StatementFileType[]>(
    `statement-recon/v1/statement-file/reconcile`,
    "post",
    {json: data}
  );
}

// Update statement file status
export function updateStatementFileStatusFn(fileId: string, status: number) {
  return kyApiFn<StatementFileType>(
    `statement-recon/v1/statement-file/${fileId}/status`,
    "put",
    {json: {status}}
  );
}
