import {useEffect, useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {
  ArrowUpFromLineIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronsUpDownIcon,
  CirclePlusIcon,
  CopyCheckIcon,
  CopyIcon,
  FileEditIcon,
  InfinityIcon,
  InfoIcon,
  Layers2Icon,
  PencilLineIcon,
  SquareSlashIcon,
} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {RadioGroup, RadioGroupItem} from "@/components/ui/radio-group";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {toast} from "@/components/ui/use-toast";

import {cn} from "@/lib/utils";
import {
  calculateUserRoles,
  useKeyCloakInstanceStore,
  useUserInfo,
} from "@/modules/auth";
import type {KeycloakTokenWithSupplierType} from "@/modules/customers";
import type {
  CreateSettingBodyType,
  SRSettingModificationSchema,
  UpdateCharacterSettingsBodyType,
} from "@/modules/statement-recon";
import {
  addSettingsModificationFn,
  character,
  leadingTrailing,
  leadingTrailingCharacters,
  SRSettingChangesSchema,
  updateSettingsModificationByModificationIdFn,
  updateSettingsModificationBySettingsIdFn,
  useSettings,
  useSettingsByExternalId,
  useSettingsByExternalIdAndSupplierId,
} from "@/modules/statement-recon";

import {KeycloakRoles, SRSettingNames} from "@/utils/constants";

interface StatementReconCardSettingsProps {
  externalId: string;
  supplierId: string | number;
}

export function SRSettingsCards({
  externalId,
  supplierId,
}: StatementReconCardSettingsProps) {
  const [settingsId, setSettingsId] = useState<number>(0);
  const [modificationId, setModificationId] = useState<string>("");
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const srSettingsByExternalIdQuery = useSettingsByExternalId(externalId);
  const srSettingsByExternalIdAndSupplierIdQuery =
    useSettingsByExternalIdAndSupplierId(externalId, supplierId.toString());
  const supplierUser =
    storeKeyCloakInstance?.tokenParsed as KeycloakTokenWithSupplierType;
  const srSettingsContent = useMemo(
    () => srSettingsByExternalIdQuery.data?.content || [],
    [srSettingsByExternalIdQuery.data]
  );

  const srSettingsBySupplierContent = useMemo(
    () => srSettingsByExternalIdAndSupplierIdQuery.data?.content || [],
    [srSettingsByExternalIdAndSupplierIdQuery.data]
  );

  useEffect(() => {
    const modificationId = srSettingsContent.find(
      (item) =>
        item.sr_settings_id === 4 && item.supplier_id === supplierId.toString()
    )?.sr_settings_modification_id;

    if (modificationId) {
      setModificationId(modificationId);
    }
  }, [settingsId, srSettingsContent, supplierId]);

  const doesSrSettingsIdExistBySupplier = srSettingsBySupplierContent.some(
    (setting) => setting.sr_settings_id === settingsId
  );
  const addSRBooleanSettingMutation = useMutation({
    mutationFn: (body: CreateSettingBodyType) => {
      console.log("Add boolean setting", body);
      return addSettingsModificationFn(body);
    },
    onSuccess: (response) => {
      console.log("addSRBooleanSettingResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Setting added successfully",
      });
    },
    onError: (error) => {
      console.error("errorMessage", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add setting",
      });
    },
  });

  const addSRCharacterSettingMutation = useMutation({
    mutationFn: (body: CreateSettingBodyType) =>
      addSettingsModificationFn(body),
    onSuccess: (response) => {
      console.log("addSRCharacterSettingResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Setting added successfully",
      });
    },
    onError: (error) => {
      console.error("errorMessage", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add setting",
      });
    },
  });

  const updateSRSettingBySettingsIdMutation = useMutation({
    mutationFn: (body: UpdateCharacterSettingsBodyType) =>
      updateSettingsModificationBySettingsIdFn(externalId, settingsId, body),
    onSuccess: (response) => {
      console.log("updateSRSettingBySettingsIdResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Setting updated successfully",
      });
    },
    onError: (error) => {
      console.error("errorMessage", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update setting",
      });
    },
  });

  const updateSRSettingByModIdMutation = useMutation({
    mutationFn: (body: UpdateCharacterSettingsBodyType) =>
      updateSettingsModificationByModificationIdFn(
        modificationId.toString() || "",
        body
      ),

    onSuccess: (response) => {
      console.log("updateSRSettingByModIdResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Setting updated successfully",
      });
    },
    onError: (error) => {
      console.error("errorMessage", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update setting",
      });
    },
  });

  const settingsQuery = useSettings();
  const settingsData = settingsQuery.data?.content || [];

  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  const isASupplierUser = useMemo(
    () => userRoleDisplay === KeycloakRoles.SupplierEnablement,
    [userRoleDisplay]
  );

  const settingsForm = useForm<z.infer<typeof SRSettingChangesSchema>>({
    defaultValues: {
      ignore_leading_trailing: "",
      ignore_special_characters: "",
      credit_pair_logic: "true",
      statement_invoice_statement_character_limit: 0,
      potential_match: "false",
      po_match: "false",
      recon_notification: "false",
    },
    resolver: zodResolver(SRSettingChangesSchema),
  });

  const handleFormSubmit = (event: {preventDefault: () => void}) => {
    event.preventDefault();
  };

  const {parsedUserInfo} = useUserInfo();

  const handleSettingsSubmit = (
    buyer_external_id: string,
    supplier_id: string,
    sr_settings_modification_value: string,
    sr_settings_id: number,
    sr_settings_leading_trailing_type?: string
  ) => {
    const modified_by = (parsedUserInfo?.name as string).toString();
    setSettingsId(sr_settings_id);

    let sr_settings_name = "";

    //set settings name based on settings id
    switch (sr_settings_id) {
      case 3:
        sr_settings_name = SRSettingNames.CreditPairLogic;
        break;
      case 5:
        sr_settings_name = SRSettingNames.PotentialMatch;
        break;
      case 6:
        sr_settings_name = SRSettingNames.POMatch;
        break;
      case 7:
        sr_settings_name = SRSettingNames.ReconNotification;
        break;
      default:
        sr_settings_name = "";
        break;
    }

    const supplierValue = isASupplierUser ? supplierUser.supplier : supplier_id;

    switch (sr_settings_id) {
      case 1: // Ignore Leading and Trailing
        if (
          !sr_settings_leading_trailing_type ||
          !sr_settings_modification_value
        ) {
          toast({
            variant: "destructive",
            title: "Please ensure trailing type and character is chosen!",
          });
        } else {
          addSRCharacterSettingMutation.mutate({
            buyer_external_id,
            sr_settings_name: SRSettingNames.IgnoreLeadingTrailing,
            supplier_id: supplierValue,
            sr_settings_modification_value,
            sr_settings_leading_trailing_type,
            modified_by,
            sr_settings_id,
          });
        }
        break;
      case 2: // Ignore Special Characters
        if (!sr_settings_modification_value) {
          toast({
            variant: "destructive",
            title: "Please ensure character is chosen!",
          });
        } else {
          addSRCharacterSettingMutation.mutate({
            buyer_external_id,
            sr_settings_name: SRSettingNames.IgnoreSpecialCharacters,
            supplier_id: supplierValue,
            sr_settings_modification_value,
            modified_by,
            sr_settings_id,
          });
        }
        break;
      case 3: // Credit Pair Logic
      case 5: // Potential Match
      case 6: // PO Match
      case 7: // Recon Notification
        addSRBooleanSettingMutation.mutate({
          buyer_external_id,
          supplier_id: supplierValue,
          sr_settings_name: sr_settings_name,
          sr_settings_modification_value,
          modified_by,
          sr_settings_id,
        });
        break;
      case 4: // Character Limit for Invoice Statements
        if (!sr_settings_modification_value) {
          toast({
            variant: "destructive",
            title: "Please ensure character limit is entered!",
          });
        } else {
          if (doesSrSettingsIdExistBySupplier) {
            if (modificationId && supplierId) {
              updateSRSettingByModIdMutation.mutate({
                sr_settings_modification_value,
                sr_settings_name: SRSettingNames.InvoiceCharacterLimit,
                modified_by,
                sr_settings_id,
              });
            } else {
              toast({
                variant: "destructive",
                title: "Please select a supplier!",
              });
            }
          } else {
            addSRCharacterSettingMutation.mutate({
              buyer_external_id,
              supplier_id: supplierValue,
              sr_settings_name: SRSettingNames.InvoiceCharacterLimit,
              sr_settings_modification_value,
              modified_by,
              sr_settings_id,
            });
          }
        }
        break;
      default:
        break;
    }
  };

  type SRSettingModification = z.infer<typeof SRSettingModificationSchema>[];
  type SRSettingModificationType = SRSettingModification;

  useEffect(() => {
    const getModificationValue = (
      content: SRSettingModificationType,
      sr_settings_id: number
    ) =>
      content.find((item) => item.sr_settings_id === sr_settings_id)
        ?.sr_settings_modification_value;

    const srSettingsValues = {
      srSettings3: getModificationValue(srSettingsContent, 3),
      srSettings4: getModificationValue(srSettingsContent, 4),
      srSettings5: getModificationValue(srSettingsContent, 5),
      srSettings6: getModificationValue(srSettingsContent, 6),
      srSettings7: getModificationValue(srSettingsContent, 7),
      srSettingsBySupplierId4: getModificationValue(
        srSettingsBySupplierContent,
        4
      ),
    };

    const resetValues: Record<string, string | number> = {
      credit_pair_logic: srSettingsValues.srSettings3 ?? "true",
      statement_invoice_statement_character_limit:
        srSettingsValues.srSettingsBySupplierId4 ??
        (supplierId ? 0 : srSettingsValues.srSettings4) ??
        0,
      potential_match: srSettingsValues.srSettings5 ?? "false",
      po_match: srSettingsValues.srSettings6 ?? "false",
      recon_notification: srSettingsValues.srSettings7 ?? "false",
    };

    settingsForm.reset(resetValues);
  }, [
    srSettingsContent,
    srSettingsBySupplierContent,
    supplierId,
    settingsForm,
  ]);

  const isMutating =
    addSRBooleanSettingMutation.isPending ||
    addSRCharacterSettingMutation.isPending ||
    updateSRSettingBySettingsIdMutation.isPending ||
    false; // Default to false if none are pending

  return (
    <Form {...settingsForm}>
      <form onSubmit={handleFormSubmit}>
        <section className="my-2 grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3">
          {settingsData.map((setting) => {
            switch (setting.sr_settings_id) {
              case "1":
                return (
                  <Card key={setting.sr_settings_id} className="flex flex-col">
                    <CardHeader className="text-xl font-bold">
                      <div className="flex items-center gap-2">
                        <SquareSlashIcon />
                        <span className="flex flex-row items-center text-base">
                          Ignore Leading and Trailing
                          <TooltipProvider delayDuration={200}>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <div className="cursor-pointer">
                                  <InfoIcon className="ml-2 size-4 text-neutral-foreground" />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent>
                                <div className="flex justify-between space-x-4">
                                  This setting can be applied to all suppliers
                                  or it can be supplier specific.
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </span>
                      </div>
                    </CardHeader>
                    <CardContent>
                      <CardDescription className="font-semibold">
                        {setting.sr_settings_description}
                      </CardDescription>
                      <FormField
                        control={settingsForm.control}
                        name={SRSettingNames.IgnoreLeadingTrailing}
                        render={({field}) => (
                          <FormItem className="mt-2 flex flex-col">
                            <Popover>
                              <PopoverTrigger asChild>
                                <FormControl>
                                  <Button
                                    variant="outline"
                                    role="combobox"
                                    className={cn(
                                      "justify-between",
                                      !field.value && "text-muted-foreground"
                                    )}
                                  >
                                    {field.value &&
                                    typeof field.value === "string"
                                      ? field.value.charAt(0).toUpperCase() +
                                        field.value.slice(1)
                                      : "Select type..."}
                                    <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                  </Button>
                                </FormControl>
                              </PopoverTrigger>
                              <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                                <Command>
                                  <CommandInput placeholder="Search type..." />
                                  <CommandEmpty>
                                    No character found.
                                  </CommandEmpty>
                                  <CommandGroup>
                                    {leadingTrailing.map((leadingTrailing) => (
                                      <CommandItem
                                        key={leadingTrailing.value}
                                        value={leadingTrailing.label}
                                        onSelect={(value: string) => {
                                          settingsForm.setValue(
                                            SRSettingNames.IgnoreLeadingTrailing,
                                            value
                                          );
                                        }}
                                      >
                                        <CheckIcon
                                          className={cn(
                                            "mr-2 size-4",
                                            leadingTrailing.value.toLowerCase() ===
                                              field.value
                                              ? "opacity-100"
                                              : "opacity-0"
                                          )}
                                        />
                                        {leadingTrailing.label}
                                      </CommandItem>
                                    ))}
                                  </CommandGroup>
                                </Command>
                              </PopoverContent>
                            </Popover>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                      <FormField
                        control={settingsForm.control}
                        name="ignore_leading_trailing_character"
                        render={({field}) => (
                          <FormItem className="mt-2 flex flex-col">
                            <Popover>
                              <PopoverTrigger asChild>
                                <FormControl>
                                  <Button
                                    variant="outline"
                                    role="combobox"
                                    className={cn(
                                      "justify-between",
                                      !field.value && "text-muted-foreground"
                                    )}
                                  >
                                    {field.value || "Select character..."}
                                    <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                  </Button>
                                </FormControl>
                              </PopoverTrigger>
                              <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                                <Command>
                                  <CommandInput placeholder="Search character..." />
                                  <CommandEmpty>
                                    No character found.
                                  </CommandEmpty>
                                  <CommandGroup>
                                    {leadingTrailingCharacters.map(
                                      (character) => (
                                        <CommandItem
                                          key={character.value}
                                          value={character.label}
                                          onSelect={(value: string) => {
                                            settingsForm.setValue(
                                              "ignore_leading_trailing_character",
                                              value
                                            );
                                          }}
                                        >
                                          <CheckIcon
                                            className={cn(
                                              "mr-2 size-4",
                                              character.value.toLowerCase() ===
                                                field.value
                                                ? "opacity-100"
                                                : "opacity-0"
                                            )}
                                          />
                                          {character.label}
                                        </CommandItem>
                                      )
                                    )}
                                  </CommandGroup>
                                </Command>
                              </PopoverContent>
                            </Popover>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </CardContent>
                    <CardFooter className="mt-auto">
                      <Button
                        type="button"
                        disabled={isMutating}
                        onClick={() => {
                          handleSettingsSubmit(
                            externalId,
                            supplierId.toString() || "",
                            settingsForm.getValues(
                              "ignore_leading_trailing_character"
                            ),
                            Number(setting.sr_settings_id),
                            settingsForm.getValues(
                              SRSettingNames.IgnoreLeadingTrailing
                            )
                          );
                        }}
                      >
                        Add Character
                        <CirclePlusIcon className="ml-2 size-4" />
                      </Button>
                    </CardFooter>
                  </Card>
                );

              case "2":
                return (
                  <Card key={setting.sr_settings_id} className="flex flex-col">
                    <CardHeader className="text-xl font-bold">
                      <div className="flex items-center gap-2">
                        <PencilLineIcon />
                        <span className="flex flex-row items-center text-base">
                          Ignore Special Characters
                          <TooltipProvider delayDuration={200}>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <div className="cursor-pointer">
                                  <InfoIcon className="ml-2 size-4 text-neutral-foreground" />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent>
                                <div className="flex justify-between space-x-4">
                                  This is a global setting that will be applied
                                  to all suppliers under this customer.
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </span>
                      </div>
                    </CardHeader>
                    <CardContent>
                      <CardDescription className="font-semibold">
                        {setting.sr_settings_description}
                      </CardDescription>
                      <FormField
                        control={settingsForm.control}
                        name={SRSettingNames.IgnoreSpecialCharacters}
                        render={({field}) => (
                          <FormItem className="mt-2 flex flex-col">
                            <Popover>
                              <PopoverTrigger asChild>
                                <FormControl>
                                  <Button
                                    variant="outline"
                                    role="combobox"
                                    className={cn(
                                      "justify-between",

                                      !field.value && "text-muted-foreground"
                                    )}
                                  >
                                    {field.value || "Select character..."}
                                    <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                  </Button>
                                </FormControl>
                              </PopoverTrigger>
                              <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                                <Command>
                                  <CommandInput placeholder="Search character..." />
                                  <CommandEmpty>
                                    No character found.
                                  </CommandEmpty>
                                  <CommandGroup>
                                    {character.map((character) => (
                                      <CommandItem
                                        key={character.value}
                                        value={character.label}
                                        onSelect={(value: string) => {
                                          settingsForm.setValue(
                                            SRSettingNames.IgnoreSpecialCharacters,
                                            value
                                          );
                                        }}
                                      >
                                        <CheckIcon
                                          className={cn(
                                            "mr-2 size-4",
                                            character.value.toLowerCase() ===
                                              field.value
                                              ? "opacity-100"
                                              : "opacity-0"
                                          )}
                                        />
                                        {character.label}
                                      </CommandItem>
                                    ))}
                                  </CommandGroup>
                                </Command>
                              </PopoverContent>
                            </Popover>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </CardContent>

                    <CardFooter className="mt-auto">
                      <Button
                        type="button"
                        disabled={isMutating}
                        onClick={() => {
                          handleSettingsSubmit(
                            externalId,
                            "",
                            settingsForm.getValues(
                              SRSettingNames.IgnoreSpecialCharacters
                            ),
                            Number(setting.sr_settings_id)
                          );
                        }}
                      >
                        Add Character
                        <CirclePlusIcon className="ml-2 size-4" />
                      </Button>
                    </CardFooter>
                  </Card>
                );
              case "4":
                return (
                  <Card key={setting.sr_settings_id} className="flex flex-col">
                    <CardHeader className="text-xl font-bold">
                      <div className="flex items-center gap-2">
                        <InfinityIcon />

                        <span className="flex flex-row items-center text-base">
                          Character Limit for Invoice Statements
                          <TooltipProvider delayDuration={200}>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <div className="cursor-pointer">
                                  <InfoIcon className="ml-2 size-4 text-neutral-foreground" />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent>
                                <div className="flex justify-between space-x-4">
                                  This setting is supplier specific.
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </span>
                      </div>
                    </CardHeader>
                    <CardContent>
                      <CardDescription className="font-semibold">
                        {setting.sr_settings_description}
                      </CardDescription>
                    </CardContent>
                    <FormField
                      control={settingsForm.control}
                      name={SRSettingNames.InvoiceCharacterLimit}
                      render={({field}) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              {...field}
                              className="mx-6 w-[360px]"
                              type="number"
                              placeholder="Enter character limit..."
                            />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <CardFooter className="mt-auto">
                      <Button
                        type="button"
                        disabled={isMutating}
                        onClick={() => {
                          handleSettingsSubmit(
                            externalId,
                            supplierId.toString() || "",
                            settingsForm
                              .getValues(SRSettingNames.InvoiceCharacterLimit)
                              .toString(),
                            Number(setting.sr_settings_id)
                          );
                        }}
                      >
                        Update Number
                        <FileEditIcon className="ml-2 size-4" />
                      </Button>
                    </CardFooter>
                  </Card>
                );
              default:
                return null;
            }
          })}
        </section>
        <section className="my-2 grid grid-cols-1 gap-3 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4">
          {settingsData.map((setting) => {
            switch (setting.sr_settings_id) {
              case "3":
                return (
                  <Card key={setting.sr_settings_id} className="flex flex-col">
                    <CardHeader className="text-xl font-bold">
                      <div className="flex items-center gap-2">
                        <Layers2Icon />
                        <span className="flex flex-row items-center text-base">
                          Credit Pair Logic
                          <TooltipProvider delayDuration={200}>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <div className="cursor-pointer">
                                  <InfoIcon className="ml-2 size-4 text-neutral-foreground" />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent>
                                <div className="flex justify-between space-x-4">
                                  This is a global setting that will be applied
                                  to all suppliers under this customer.
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </span>
                      </div>
                    </CardHeader>
                    <CardContent>
                      <CardDescription className="font-semibold">
                        {setting.sr_settings_description}
                      </CardDescription>
                      <FormField
                        control={settingsForm.control}
                        name={SRSettingNames.CreditPairLogic}
                        render={({field}) => (
                          <FormItem className="space-y-4">
                            <FormControl>
                              <RadioGroup
                                className="mt-4 flex flex-col space-y-1"
                                value={field.value}
                                onValueChange={field.onChange}
                              >
                                <div className="flex items-center space-x-2">
                                  <FormItem className="flex items-center space-x-3 space-y-0">
                                    <FormControl>
                                      <RadioGroupItem value="true" />
                                    </FormControl>
                                    <FormLabel className="font-normal">
                                      Enabled
                                    </FormLabel>
                                  </FormItem>
                                  <FormItem className="flex items-center space-x-3 space-y-0">
                                    <FormControl>
                                      <RadioGroupItem value="false" />
                                    </FormControl>
                                    <FormLabel className="font-normal">
                                      Disabled
                                    </FormLabel>
                                  </FormItem>
                                </div>
                              </RadioGroup>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </CardContent>
                    <CardFooter className="mt-auto">
                      <Button
                        type="button"
                        disabled={isMutating}
                        onClick={() => {
                          handleSettingsSubmit(
                            externalId,
                            "",
                            settingsForm.getValues(
                              SRSettingNames.CreditPairLogic
                            ),
                            Number(setting.sr_settings_id)
                          );
                        }}
                      >
                        Update Setting
                        <ArrowUpFromLineIcon className="ml-2 size-4" />
                      </Button>
                    </CardFooter>
                  </Card>
                );
              case "5":
                return (
                  <Card key={setting.sr_settings_id} className="flex flex-col">
                    <CardHeader className="text-xl font-bold">
                      <div className="flex items-center gap-2">
                        <CopyCheckIcon />

                        <span className="flex flex-row items-center text-base">
                          Potential Match
                          <TooltipProvider delayDuration={200}>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <div className="cursor-pointer">
                                  <InfoIcon className="ml-2 size-4 text-neutral-foreground" />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent>
                                <div className="flex justify-between space-x-4">
                                  This is a global setting that will be applied
                                  to all suppliers under this customer.
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </span>
                      </div>
                    </CardHeader>
                    <CardContent>
                      <CardDescription className="font-semibold">
                        {setting.sr_settings_description}
                      </CardDescription>
                      <FormField
                        control={settingsForm.control}
                        name={SRSettingNames.PotentialMatch}
                        render={({field}) => (
                          <FormItem className="space-y-4">
                            <FormControl>
                              <RadioGroup
                                className="mt-4 flex flex-col space-y-1"
                                value={field.value}
                                onValueChange={field.onChange}
                              >
                                <div className="flex items-center space-x-2">
                                  <FormItem className="flex items-center space-x-3 space-y-0">
                                    <FormControl>
                                      <RadioGroupItem value="true" />
                                    </FormControl>
                                    <FormLabel className="font-normal">
                                      Enabled
                                    </FormLabel>
                                  </FormItem>
                                  <FormItem className="flex items-center space-x-3 space-y-0">
                                    <FormControl>
                                      <RadioGroupItem value="false" />
                                    </FormControl>
                                    <FormLabel className="font-normal">
                                      Disabled
                                    </FormLabel>
                                  </FormItem>
                                </div>
                              </RadioGroup>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </CardContent>
                    <CardFooter className="mt-auto">
                      <Button
                        type="button"
                        disabled={isMutating}
                        onClick={() => {
                          handleSettingsSubmit(
                            externalId,
                            "",
                            settingsForm.getValues(
                              SRSettingNames.PotentialMatch
                            ),
                            Number(setting.sr_settings_id)
                          );
                        }}
                      >
                        Update Setting
                        <ArrowUpFromLineIcon className="ml-2 size-4" />
                      </Button>
                    </CardFooter>
                  </Card>
                );
              case "6":
                return (
                  <Card key={setting.sr_settings_id} className="flex flex-col">
                    <CardHeader className="text-xl font-bold">
                      <div className="flex items-center gap-2">
                        <CopyIcon />

                        <span className="flex flex-row items-center text-base">
                          PO Match
                          <TooltipProvider delayDuration={200}>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <div className="cursor-pointer">
                                  <InfoIcon className="ml-2 size-4 text-neutral-foreground" />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent>
                                <div className="flex justify-between space-x-4">
                                  This is a global setting that will be applied
                                  to all suppliers under this customer.
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </span>
                      </div>
                    </CardHeader>
                    <CardContent>
                      <CardDescription className="font-semibold">
                        {setting.sr_settings_description}
                      </CardDescription>
                      <FormField
                        control={settingsForm.control}
                        name={SRSettingNames.POMatch}
                        render={({field}) => (
                          <FormItem className="space-y-4">
                            <FormControl>
                              <RadioGroup
                                className="mt-4 flex flex-col space-y-1"
                                value={field.value}
                                onValueChange={field.onChange}
                              >
                                <div className="flex items-center space-x-2">
                                  <FormItem className="flex items-center space-x-3 space-y-0">
                                    <FormControl>
                                      <RadioGroupItem value="true" />
                                    </FormControl>
                                    <FormLabel className="font-normal">
                                      Enabled
                                    </FormLabel>
                                  </FormItem>
                                  <FormItem className="flex items-center space-x-3 space-y-0">
                                    <FormControl>
                                      <RadioGroupItem value="false" />
                                    </FormControl>
                                    <FormLabel className="font-normal">
                                      Disabled
                                    </FormLabel>
                                  </FormItem>
                                </div>
                              </RadioGroup>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </CardContent>
                    <CardFooter className="mt-auto">
                      <Button
                        type="button"
                        disabled={isMutating}
                        onClick={() => {
                          handleSettingsSubmit(
                            externalId,
                            "",
                            settingsForm.getValues(SRSettingNames.POMatch),
                            Number(setting.sr_settings_id)
                          );
                        }}
                      >
                        Update Setting
                        <ArrowUpFromLineIcon className="ml-2 size-4" />
                      </Button>
                    </CardFooter>
                  </Card>
                );
              case "7":
                return (
                  <Card key={setting.sr_settings_id} className="flex flex-col">
                    <CardHeader className="text-xl font-bold">
                      <div className="flex items-center gap-2">
                        <CheckCircleIcon />

                        <span className="flex flex-row items-center text-base">
                          Recon Notification
                          <TooltipProvider delayDuration={200}>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <div className="cursor-pointer">
                                  <InfoIcon className="ml-2 size-4 text-neutral-foreground" />
                                </div>
                              </TooltipTrigger>
                              <TooltipContent>
                                <div className="flex justify-between space-x-4">
                                  This is a global setting that will be applied
                                  to all suppliers under this customer.
                                </div>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </span>
                      </div>
                    </CardHeader>
                    <CardContent>
                      <CardDescription className="font-semibold">
                        {setting.sr_settings_description}
                      </CardDescription>
                      <FormField
                        control={settingsForm.control}
                        name={SRSettingNames.ReconNotification}
                        render={({field}) => (
                          <FormItem className="space-y-4">
                            <FormControl>
                              <RadioGroup
                                className="mt-4 flex flex-col space-y-1"
                                value={field.value}
                                onValueChange={field.onChange}
                              >
                                <div className="flex items-center space-x-2">
                                  <FormItem className="flex items-center space-x-3 space-y-0">
                                    <FormControl>
                                      <RadioGroupItem value="true" />
                                    </FormControl>
                                    <FormLabel className="font-normal">
                                      Enabled
                                    </FormLabel>
                                  </FormItem>
                                  <FormItem className="flex items-center space-x-3 space-y-0">
                                    <FormControl>
                                      <RadioGroupItem value="false" />
                                    </FormControl>
                                    <FormLabel className="font-normal">
                                      Disabled
                                    </FormLabel>
                                  </FormItem>
                                </div>
                              </RadioGroup>
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </CardContent>
                    <CardFooter className="mt-auto">
                      <Button
                        type="button"
                        disabled={isMutating}
                        onClick={() => {
                          handleSettingsSubmit(
                            externalId,
                            "",
                            settingsForm.getValues(
                              SRSettingNames.ReconNotification
                            ),
                            Number(setting.sr_settings_id)
                          );
                        }}
                      >
                        Update Setting
                        <ArrowUpFromLineIcon className="ml-2 size-4" />
                      </Button>
                    </CardFooter>
                  </Card>
                );
              default:
                return null;
            }
          })}
        </section>
      </form>
    </Form>
  );
}
