import {create} from "zustand";

// Defining the shape of the state object for the TokenStore
// In this case, the state will consist of an array of Token objects
interface TokenStoreState {
  storeToken: string | null;
}

// Defining the actions that can be performed on the TokenStore state
// In this case, there's only one action, updateStoreToken, which takes an array of Token objects
interface TokenStoreActions {
  updateStoreToken: (storeToken: TokenStoreState["storeToken"]) => void;
}

// Creating the Zustand store using the create function
// The store is named useTokenStore, and it uses the types defined above for its state and actions
// The state begins with an empty storeToken array
// The updateStoreToken action uses Zustand's set function to update the state
export const useTokenStore = create<TokenStoreState & TokenStoreActions>(
  (set) => ({
    // Initial state of the store. An empty array of storeToken.
    storeToken: null,

    // Action to update the storeToken array in the store.
    // This function takes a new storeToken array and uses the set function to update the state.
    updateStoreToken: (storeToken) => {
      set(() => ({storeToken}));
    },
  })
);
