import {z} from "zod";

export const dupesDashboardSchema = z.object({
  externalId: z.number(),
  likely: z.number(),
  maybe: z.number(),
  diffSupplierLikely: z.number(),
  diffSupplierMaybe: z.number(),
  sameInvWithDiffAmount: z.number(),
  sameInvWithDiffInvDate: z.number(),
  others: z.number(),
});

export type DupesDashboardType = z.infer<typeof dupesDashboardSchema>;
