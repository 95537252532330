// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type StatementFileType} from "@/modules/statement-recon";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface StatementSearchTableColumnsStoreState {
  storeStatementSearchTableColumns: StatementFileType | null;
  setStoreStatementSearchTableColumns: (
    storeStatementSearchTableColumns: StatementFileType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for StatementSearch table columns
export const useStatementSearchTableColumnsStore = create(
  persist<StatementSearchTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeStatementSearchTableColumns: null,

      // Getter function to retrieve the current state
      getStoreStatementSearchTableColumns: () =>
        get().storeStatementSearchTableColumns,

      // Setter function to update the state
      setStoreStatementSearchTableColumns: (
        storeStatementSearchTableColumns
      ) => {
        set(() => ({storeStatementSearchTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "sr-statement-search-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useStatementSearchTableColumns = () =>
  useStatementSearchTableColumnsStore((state) => {
    return {
      storeStatementSearchTableColumns: state.storeStatementSearchTableColumns,
    };
  });
