import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  customersBySupplierQueryKeys,
  type srCustomerSchemaType,
} from "@/modules/customers";

// Defining a type based on the function `getAllSuppliersByCustomer`
type QueryFnType = typeof getAllSuppliersByCustomer;

// Function to fetch all customers
export function getAllSuppliersByCustomer(supplierId: string) {
  // Fetch all customers from API using ky
  const data = kyApiFn<srCustomerSchemaType[]>(
    `statement-recon/v1/customers/${supplierId}`,
    "get"
  );
  return data; // Returning the fetched data
}

// Custom hook to fetch and cache all customers data using react-query
export function useAllSuppliersByCustomer(
  supplierId: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: customersBySupplierQueryKeys.byId(supplierId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getAllSuppliersByCustomer(supplierId), // Setting the function to be used for fetching data
    enabled: !!supplierId,
  });
}
