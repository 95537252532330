import {useCallback, useMemo} from "react";

import {Link} from "@tanstack/react-router";
import type {KeycloakTokenParsed} from "keycloak-js";
import {LogOutIcon, Settings2Icon, UserCircle2Icon} from "lucide-react";

import {Avatar, AvatarFallback, AvatarImage} from "@/components/ui/avatar";
import {Button} from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Tooltip,
  TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {Paragraph} from "@/components/ui/typography";
import {LoadingSection} from "@/components/craft/loading-section";

import {
  calculateInitials,
  calculateUserRoles,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {useGetAllSupplierWithOutFilter} from "@/modules/customers";

/**
 * @interface UserInfoProps
 * @description The properties accepted by the UserInfo component.
 *
 * | Name            | Type                | Description                          |
 * |-----------------|---------------------|--------------------------------------|
 * | parsedUserInfo  | KeycloakTokenParsed | The parsed user info from Keycloak.  |
 * | logoutFn        | () => void          | Function to log the user out.        |
 */
interface UserInfoProps {
  parsedUserInfo: KeycloakTokenParsed;
  logoutFn: () => void;
}

/**
 * @function UserInfo
 * @param {UserInfoProps} props The properties.
 * @description This component displays the information of a user.
 * It receives a user object as a prop and displays the user's full name,
 * role group, status, and an avatar.
 */
export function UserInfo({parsedUserInfo, logoutFn}: UserInfoProps) {
  const {data: suppliersRawData} = useGetAllSupplierWithOutFilter();
  const userInitials = useMemo(
    () => calculateInitials(parsedUserInfo.name as string),
    [parsedUserInfo]
  );

  const userRoleDisplay = useMemo(
    () => calculateUserRoles(parsedUserInfo.realm_access?.roles),
    [parsedUserInfo]
  );

  const supplierName = useMemo(() => {
    return suppliersRawData?.content.find(
      (supplier) => supplier.supplier_id === parsedUserInfo.supplier
    )?.supplier_name as string;
  }, [suppliersRawData, parsedUserInfo.supplier]);

  return (
    <>
      <div className="flex flex-col items-end justify-center">
        <Paragraph className="font-bold leading-5">
          {parsedUserInfo.name}
        </Paragraph>
        <Paragraph className="max-w-sm whitespace-pre-wrap text-right text-sm font-semibold leading-4 text-theme">
          {userRoleDisplay}
        </Paragraph>
        {parsedUserInfo.customer ? (
          <Paragraph className="font-semibold leading-5">
            Customer:{" "}
            <span className="font-bold uppercase text-neutral">
              {String(parsedUserInfo.customer)}
            </span>
          </Paragraph>
        ) : parsedUserInfo.supplier ? (
          <Paragraph className="font-semibold leading-5">
            Supplier:{" "}
            <span className="font-bold uppercase text-neutral">
              {supplierName}
            </span>
          </Paragraph>
        ) : (
          <Paragraph className="font-semibold leading-5">
            User status:{" "}
            <span className="font-bold uppercase text-neutral">
              {/* TODO: maybe replace this later? */}
              active
            </span>
          </Paragraph>
        )}
      </div>
      <div className="p-2">
        <TooltipProvider delayDuration={150} skipDelayDuration={300}>
          <DropdownMenu>
            <Tooltip>
              <TooltipTrigger asChild>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    className="relative h-14 w-14 rounded-full"
                  >
                    <Avatar className="h-14 w-14">
                      <AvatarImage
                      // TODO: Add user avatar upload
                      // src="https://github.com/chowjiaming.png"
                      // alt="@chowjiaming"
                      />
                      <AvatarFallback>{userInitials}</AvatarFallback>
                    </Avatar>
                  </Button>
                </DropdownMenuTrigger>
              </TooltipTrigger>
              <TooltipContent>
                <TooltipArrow />
                <Paragraph className="text-xs font-semibold leading-none">
                  Click here to access:{" "}
                  <span className="text-md font-bold">User Actions</span>
                </Paragraph>
              </TooltipContent>
            </Tooltip>

            <DropdownMenuContent className="w-56" align="end" forceMount>
              <DropdownMenuLabel className="font-normal">
                <div className="flex flex-col space-y-1">
                  <Paragraph className="text-sm font-semibold leading-none text-foreground">
                    {parsedUserInfo.name}
                  </Paragraph>
                  <Paragraph className="text-xs leading-none text-accent-foreground">
                    {parsedUserInfo.email}
                  </Paragraph>
                </div>
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <Link to="/app/profile">
                  <DropdownMenuItem className="cursor-pointer text-foreground">
                    Profile
                    <DropdownMenuShortcut>
                      <span className="sr-only">View profile</span>
                      <UserCircle2Icon />
                    </DropdownMenuShortcut>
                  </DropdownMenuItem>
                </Link>
                <Link to="/app/settings">
                  <DropdownMenuItem className="cursor-pointer text-foreground">
                    Settings
                    <DropdownMenuShortcut>
                      <span className="sr-only">Go to settings</span>
                      <Settings2Icon />
                    </DropdownMenuShortcut>
                  </DropdownMenuItem>
                </Link>
              </DropdownMenuGroup>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="cursor-pointer text-destructive-foreground focus:bg-destructive focus:text-destructive-foreground/80"
                onClick={() => {
                  logoutFn();
                }}
              >
                Logout
                <DropdownMenuShortcut>
                  <span className="sr-only">Logout</span>
                  <LogOutIcon />
                </DropdownMenuShortcut>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </TooltipProvider>
      </div>
    </>
  );
}

/**
 * @function useUserInfo
 * @description Custom hook to abstract the user info fetching logic.
 */
export function useUserInfo() {
  // Get the storeKeyCloakInstance from the useKeyCloakInstanceStore hook
  const storeKeyCloakInstance =
    useKeyCloakInstanceStore().storeKeyCloakInstance;

  // Get the parsedUserInfo and logoutFn from the useKeyCloakInstanceStore hook
  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );

  // Define the logout function
  const logoutFn = useCallback(() => {
    void storeKeyCloakInstance?.logout();
  }, [storeKeyCloakInstance]);

  return {parsedUserInfo, logoutFn};
}

/**
 * @function UserInfoCard
 * @description This component is responsible for fetching the user's data and
 * handling the loading and error states. Once the data is fetched, it renders
 * the UserInfo component.
 */
export function UserInfoCard() {
  // Get the parsedUserInfo and logoutFn from the useUserInfo hook
  const {parsedUserInfo, logoutFn} = useUserInfo();

  // If there is no user, show a loading indicator
  if (!parsedUserInfo) {
    return <LoadingSection sectionSize="xs" />;
  }

  // Render the UserInfo component with the parsedUserInfo and logoutFn
  return <UserInfo parsedUserInfo={parsedUserInfo} logoutFn={logoutFn} />;
}
