import {createRoute} from "@tanstack/react-router";

import {RoutesDirectory} from "@/lib/routes/directory";
import {
  StatementReconGroupPage,
  statementReconRootRoute,
} from "@/modules/statement-recon";

export const statementReconGroupRoute = createRoute({
  path: RoutesDirectory.GROUP,
  component: StatementReconGroupPage,
  getParentRoute: () => statementReconRootRoute,
});
