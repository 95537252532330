// import {useEffect} from "react";
import {Navigate} from "@tanstack/react-router";

// import {Outlet} from "@tanstack/react-router";

// import {Heading1, Paragraph} from "@/components/ui/typography";
// import {ReturnToTop} from "@/components/craft/return-to-top";

// import {ElectronicInvoicingRoutesSchema} from "@/lib/routes/types";
// import {useRoutesStore} from "@/lib/stores";
// import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";

export function ElectronicInvoicingOutlet() {
  // module temorary disabled, may come back later, redirect to app for now
  return <Navigate to="/app" replace />;
  // const {setElectronicInvoicingRoute} = useRoutesStore();

  // useEffect(() => {
  //   setElectronicInvoicingRoute(
  //     ElectronicInvoicingRoutesSchema.Values["/app/electronic-invoicing"]
  //   );
  // }, [setElectronicInvoicingRoute]);

  // return (
  //   <article
  //     className="flex flex-1 flex-col overflow-y-auto"
  //     role="region"
  //     aria-roledescription="This is the Electronic Invoicing level one subroute."
  //   >
  //     <section
  //       className="flex flex-row items-start justify-between"
  //       role="presentation"
  //     >
  //       <div className="mb-4 flex flex-col">
  //         <Heading1 className="self-start">Intelligent Invoicing</Heading1>
  //         <Paragraph>View your electronic invoices here.</Paragraph>
  //       </div>
  //     </section>

  //     <AccessPolicyWrapper
  //       policyActions={[KeycloakRoleEnum.READ_ELECTRONIC_INVOICING]}
  //     >
  //       <Outlet />
  //     </AccessPolicyWrapper>

  //     <ReturnToTop />
  //   </article>
  // );
}
