/* eslint-disable */

import {type NumRowsType} from "@/lib/stores";

/**
 * Interface for Sort Meta Data
 *
 * @interface SortMetaType
 *
 * @property {string} sortProperty - The property to sort by.
 * @property {"asc" | "desc"} sortOrder - The order to sort by.
 */
export interface SortMetaType {
  sortProperty: string;
  sortOrder: "asc" | "desc";
}

/**
 * Interface for API Meta Query Parameters
 *
 * @interface ApiMetaQueryParams
 *
 * @property {number} [page] - Optional page number for pagination
 * @property {NumRowsType} [size] - Optional row size for data fetching
 */
interface ApiMetaQueryParams {
  page?: number;
  size?: NumRowsType;
  sort: SortMetaType | null;
}

/**
 * Options for Query Parameters
 *
 * @type QueryParamsOptions
 *
 * @property {object} params - Object containing the query parameters key-value pairs.
 * @property {ApiMetaQueryParams} ApiMetaQueryParams - Additional meta parameters for the API query.
 */
type QueryParamsOptions = {
  params: Record<string, any>;
} & ApiMetaQueryParams;

/**
 * Function to generate query parameters for an API request.
 *
 * @function generateQueryParams
 *
 * @param {QueryParamsOptions} options - An object containing key-value pairs for query parameters and API meta parameters.
 * @param {Record<string, string | string[] | number | number[] | null>} options.params - The query parameters.
 * @param {number} [options.page=0] - The page number for pagination. Defaults to 0.
 * @param {NumRowsType} [options.size='10'] - The row size for data fetching. Defaults to '10'.
 *
 * @returns {string} The generated query parameters as a string.
 */
export function generateQueryParams({
  params,
  page = 0,
  size = "10",
  sort,
}: QueryParamsOptions): string {
  // Initializing URL Search Params object
  const query = new URLSearchParams();

  // Looping through the query parameters object
  for (const [key, value] of Object.entries(params)) {
    // Check if value is an array
    if (Array.isArray(value)) {
      // Loop through array and append each value to the query
      value.forEach((item) => {
        if (typeof item === "number") {
          query.append(key, item.toString());
        } else {
          query.append(key, item);
        }
      });
    }
    // Check if value is not null
    else if (value !== null) {
      query.append(key, value.toString());
    }
  }

  // Append page number to the query
  query.append("page", page.toString());
  // Append row size to the query
  query.append("size", size.toString());

  // Check if sort meta data is present
  if (sort) {
    // Append sort property to the query
    query.append("sort_by", `${sort.sortProperty},${sort.sortOrder}`);
  }

  // Return the generated query parameters as a string
  return query.toString();
}
