import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import type {
  GetSRMappingsByExternalIdResponseType,
  srAddMappingType,
  StatementReconGetVendorFieldsType,
} from "@/modules/statement-recon";
import {
  statementReconMappingKeys,
  statementReconVendorFieldsKeys,
} from "@/modules/statement-recon";

type QueryFnTypeByExternalID = typeof getSRMappingsByExternalId;

export function getSRMappingsByExternalId(externalID: string) {
  return kyApiFn<GetSRMappingsByExternalIdResponseType>(
    `statement-recon/v1/saved-mappings/customer/${externalID}`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useSRMappingsByExternalId(
  externalID: string,
  config?: QueryConfig<QueryFnTypeByExternalID>
) {
  return useQuery<ExtractFnReturnType<QueryFnTypeByExternalID>>({
    ...config,
    queryKey: statementReconMappingKeys.byExternalID(externalID), // Unique key for cache 🗝
    queryFn: () => getSRMappingsByExternalId(externalID), // Data fetching function 📡
    enabled: !!externalID, // Condition to enable the query 🚦
  });
}

//Defining a type based on the function `getSRMapping`
type QueryFnTypeVendorFields = typeof getSRVendorFields;

// Defining a function to fetch data from API
export function getSRVendorFields() {
  // Fetch data from API using ky
  return kyApiFn<StatementReconGetVendorFieldsType[]>(
    `statement-recon/v1/vendor-field-names`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useSRVendorFields(
  config?: QueryConfig<QueryFnTypeVendorFields>
) {
  return useQuery<ExtractFnReturnType<QueryFnTypeVendorFields>>({
    ...config,
    queryKey: statementReconVendorFieldsKeys.all,
    queryFn: getSRVendorFields,
  });
}

export function addSRMappingFn(data: srAddMappingType) {
  return kyApiFn<srAddMappingType>(
    `statement-recon/v1/saved-mappings`,
    "post",
    {json: data}
  );
}
