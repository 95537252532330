import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {imREmitQueryKeys, type ImREmitPaymentType} from "@/modules/imremit";

type QueryFnType = typeof getPaymentDetails;

// Function to search paymentDetails
export function getPaymentDetails(paymentId?: string) {
  return kyApiFn<ImREmitPaymentType>(
    `api/e-payable/management/payment-details/${paymentId}`,
    "get"
  );
}

// Custom hook to fetch and cache paymentDetails data using react-query
export function useGetPaymentDetails(
  paymentId?: string, // The search query string
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: imREmitQueryKeys.byId(paymentId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getPaymentDetails(paymentId), // Setting the function to be used for fetching data
    enabled: !!paymentId, // Setting the query to be enabled only if the search query string is not empty
  });
}
