import {useEffect} from "react";

import {Heading2, Paragraph} from "@/components/ui/typography";

import {AdminRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";
import {
  AlertsDataTable,
  alertsTableColumns,
  useAlertManagement,
} from "@/modules/admin";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";

/**
 * Custom hook for setting the admin route in the store
 */
function useAlertManagementRoute() {
  const {setAdminRoute} = useRoutesStore();

  useEffect(() => {
    setAdminRoute(AdminRoutesSchema.Values["/app/admin/alert-management"]);
  }, [setAdminRoute]);
}

/**
 * AlertManagementPage Component
 *
 * This component serves as the central hub for managing alerts within the admin interface.
 * It provides the UI and mechanisms for adding alerts.
 */
export function AlertManagementPage() {
  // Set the admin route in the store
  useAlertManagementRoute();

  const alertsQuery = useAlertManagement();
  const alertsContent = alertsQuery.data?.content || [];

  // Render the Alert Management Interface
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.READ_ALERT_MANAGEMENT]}
    >
      <section
        className="flex flex-1 flex-col md:flex-row md:justify-between"
        role="presentation"
        aria-roledescription="This section contains the Alert Management page. This page allows administrators to add and view alerts."
      >
        <div className="mb-2 flex flex-col">
          <Heading2>Alert Management</Heading2>
          <Paragraph>Manage all alerts here.</Paragraph>
        </div>
      </section>
      <AlertsDataTable
        columns={alertsTableColumns}
        data={alertsContent}
        isPending={alertsQuery.isPending}
        isSuccess={alertsQuery.isSuccess}
        isError={alertsQuery.isError}
      />
    </AccessPolicyWrapper>
  );
}
