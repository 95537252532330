import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {invoiceQueryKeys, type Invoice} from "@/modules/invoices";

// Defining a type based on the function `searchInvoicesFn`
type QueryFnType = typeof searchInvoicesFn;

// Function to search invoices
export function searchInvoicesFn(searchQueryString: string) {
  // // If no search query string is provided, fetch all invoices

  // Fetch invoices from API using ky
  return kyApiFn<Invoice[]>(`invoice/v1/invoices?${searchQueryString}`, "get");
  // TODO: validate data
  // return invoiceSchema.parse(data);
}

// Custom hook to fetch and cache invoices data using react-query
export function useSearchInvoices(
  searchQueryString: string, // The search query string
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: invoiceQueryKeys.bySearch(searchQueryString), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => searchInvoicesFn(searchQueryString), // Setting the function to be used for fetching data
    // Stale time is the time after which the data will be refetched
    // We set it to 5 seconds for pagination prefetching lagtime
    staleTime: 5000, // Setting the stale time to 5 seconds
    // TODO: add onSuccess and onError handlers
  });
}
