import {
  BadgeDollarSignIcon,
  CalendarDaysIcon,
  CalendarIcon,
  CheckCheckIcon,
  CircleDollarSignIcon,
  ClockIcon,
  CreditCardIcon,
  DollarSignIcon,
  PieChartIcon,
  SunriseIcon,
} from "lucide-react";

export const statusTypes = [
  {
    value: "active",
    label: "Active",
    icon: CheckCheckIcon,
  },
  {
    value: "onboarding_pending",
    label: "Pending",
    icon: ClockIcon,
  },
];

export const billingTypes = [
  {
    value: "daily",
    label: "Daily",
    icon: SunriseIcon,
  },
  {
    value: "weekly",
    label: "Weekly",
    icon: CalendarIcon,
  },
  {
    value: "monthly",
    label: "Monthly",
    icon: CalendarDaysIcon,
  },
  {
    value: "quarterly",
    label: "Quarterly",
    icon: PieChartIcon,
  },
];

export const paymentTypes = [
  {
    value: "virtualcc",
    label: "Virtual CC",
    icon: CreditCardIcon,
  },
  {
    value: "sua",
    label: "SUA",
    icon: DollarSignIcon,
  },
  {
    value: "sua2",
    label: "SUA2",
    icon: DollarSignIcon,
  },
];

export const paymentMethodTypes = [
  {
    value: "ach",
    label: "Ach",
    icon: CircleDollarSignIcon,
  },
  {
    value: "card",
    label: "Card",
    icon: CreditCardIcon,
  },
  {
    value: "wire",
    label: "Wire",
    icon: BadgeDollarSignIcon,
  },
  {
    value: "vc",
    label: "VC",
    icon: CreditCardIcon,
  },
  {
    value: "sua",
    label: "SUA",
    icon: DollarSignIcon,
  },
  {
    value: "sua2",
    label: "SUA2",
    icon: DollarSignIcon,
  },
  {
    value: "virtualcc",
    label: "Virtual CC",
    icon: CreditCardIcon,
  },
];

export const settlementTermsTypes = [
  {
    value: "10days",
    label: "10 Days",
    icon: CalendarDaysIcon,
  },
  {
    value: "15days",
    label: "15 Days",
    icon: CalendarDaysIcon,
  },
  {
    value: "30days",
    label: "30 Days",
    icon: CalendarDaysIcon,
  },
  {
    value: "60days",
    label: "60 Days",
    icon: CalendarDaysIcon,
  },
];
