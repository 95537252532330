import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

import type {
  AdminRoutesType,
  DuplicatePaymentRoutesType,
  ElectronicInvoicingRoutesType,
  HelpRoutesType,
  ImREmitLiteRoutesType,
  ImREmitRoutesType,
  InvoiceTrackerRoutesType,
  ProfileRoutesType,
  RoutesType,
  SettingsRoutesType,
  StatementReconRoutesType,
} from "@/lib/routes/types";

// Creating a type definition for our route store
interface RoutesStoreState {
  lastUpdated: Date | null; // The date when the route was last updated
  lastVisited: RoutesType | null; // The last visited route
  invoiceTrackerRoute: InvoiceTrackerRoutesType | null; // Configuration details for different routes,
  // and the corresponding setter functions
  setInvoiceTrackerRoute: (
    invoiceTrackerRoute: InvoiceTrackerRoutesType
  ) => void;
  electronicInvoicingRoute: ElectronicInvoicingRoutesType | null;
  setElectronicInvoicingRoute: (
    electronicInvoicingRoute: ElectronicInvoicingRoutesType
  ) => void;
  imREmitRoute: ImREmitRoutesType | null;
  setimREmitRoute: (imREmitRoute: ImREmitRoutesType) => void;
  imREmitLiteRoute: ImREmitLiteRoutesType | null;
  setimREmitLiteRoute: (imREmitRoute: ImREmitLiteRoutesType) => void;
  duplicatePaymentRoute: DuplicatePaymentRoutesType | null;
  setDuplicatePaymentRoute: (
    duplicatePaymentRoute: DuplicatePaymentRoutesType
  ) => void;
  statementReconRoute: StatementReconRoutesType | null;
  setStatementReconRoute: (
    statementReconRoute: StatementReconRoutesType
  ) => void;
  adminRoute: AdminRoutesType | null;
  setAdminRoute: (adminRoute: AdminRoutesType) => void;
  settingsRoute: SettingsRoutesType | null;
  setSettingsRoute: (settingsRoute: SettingsRoutesType) => void;
  profileRoute: ProfileRoutesType | null;
  setProfileRoute: (profileRoute: ProfileRoutesType) => void;
  helpRoute: HelpRoutesType | null;
  setHelpRoute: (helpRoute: HelpRoutesType) => void;
}

// Here we create the store using zustand. We wrap the store with the persist middleware
// so that the store's state persists across browser sessions.
// Inside the persist function we define the initial state of our store and how it should update.
// We also define the name of our store and specify that it should use localStorage for persistence.
export const useRoutesStore = create(
  persist<RoutesStoreState>(
    (set, get) => ({
      lastUpdated: null,
      lastVisited: null,
      invoiceTrackerRoute: null,
      // Setter functions to update the route states,
      // the last visited route, and the date when the update was made
      setInvoiceTrackerRoute: (invoiceTrackerRoute) => {
        set(() => ({
          invoiceTrackerRoute,
          lastVisited: invoiceTrackerRoute,
          lastUpdated: new Date(),
        }));
      },
      getElectronicInvoicingRoute: () => get().electronicInvoicingRoute,
      electronicInvoicingRoute: null,
      setElectronicInvoicingRoute: (electronicInvoicingRoute) => {
        set(() => ({
          electronicInvoicingRoute,
          lastVisited: electronicInvoicingRoute,
          lastUpdated: new Date(),
        }));
      },
      // Getter functions to retrieve the current state of the routes
      // and the last visited route
      getInvoiceTrackerRoute: () => get().invoiceTrackerRoute,
      imREmitRoute: null,
      setimREmitRoute: (imREmitRoute) => {
        set(() => ({
          imREmitRoute,
          lastVisited: imREmitRoute,
          lastUpdated: new Date(),
        }));
      },
      getImREmitRoute: () => get().imREmitRoute,
      imREmitLiteRoute: null,
      setimREmitLiteRoute: (imREmitLiteRoute) => {
        set(() => ({
          imREmitLiteRoute,
          lastVisited: imREmitLiteRoute,
          lastUpdated: new Date(),
        }));
      },
      getImREmitLiteRoute: () => get().imREmitLiteRoute,
      duplicatePaymentRoute: null,
      setDuplicatePaymentRoute: (duplicatePaymentRoute) => {
        set(() => ({
          duplicatePaymentRoute,
          lastVisited: duplicatePaymentRoute,
          lastUpdated: new Date(),
        }));
      },
      getDuplicatePaymentRoute: () => get().duplicatePaymentRoute,
      statementReconRoute: null,
      setStatementReconRoute: (statementReconRoute) => {
        set(() => ({
          statementReconRoute,
          lastVisited: statementReconRoute,
          lastUpdated: new Date(),
        }));
      },
      getStatementReconRoute: () => get().statementReconRoute,
      adminRoute: null,
      setAdminRoute: (adminRoute) => {
        set(() => ({
          adminRoute,
          lastVisited: adminRoute,
          lastUpdated: new Date(),
        }));
      },
      getAdminRoute: () => get().adminRoute,
      settingsRoute: null,
      setSettingsRoute: (settingsRoute) => {
        set(() => ({
          settingsRoute,
          lastVisited: settingsRoute,
          lastUpdated: new Date(),
        }));
      },
      getSettingsRoute: () => get().settingsRoute,
      profileRoute: null,
      setProfileRoute: (profileRoute) => {
        set(() => ({
          profileRoute,
          lastVisited: profileRoute,
          lastUpdated: new Date(),
        }));
      },
      getProfileRoute: () => get().profileRoute,
      helpRoute: null,
      setHelpRoute: (helpRoute) => {
        set(() => ({
          helpRoute,
          lastVisited: helpRoute,
          lastUpdated: new Date(),
        }));
      },
      getHelpRoute: () => get().helpRoute,
    }),
    {
      name: "routes-store",
      storage: createJSONStorage(() => localStorage),
    }
  )
);

// Function to retrieve the current state of the store
export const useRoutePersistence = () =>
  useRoutesStore((state) => {
    return {
      lastUpdated: state.lastUpdated,
      lastVisited: state.lastVisited,
      invoiceTrackerRoute: state.invoiceTrackerRoute,
      electronicInvoicingRoute: state.electronicInvoicingRoute,
      imREmitRoute: state.imREmitRoute,
      imREmitLiteRoute: state.imREmitLiteRoute,
      duplicatePaymentRoute: state.duplicatePaymentRoute,
      statementReconRoute: state.statementReconRoute,
      adminRoute: state.adminRoute,
      settingsRoute: state.settingsRoute,
      profileRoute: state.profileRoute,
      helpRoute: state.helpRoute,
    };
  });
