// import {useNavigate} from "@tanstack/react-router";
import {useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {type Row} from "@tanstack/react-table";
import {
  FileTextIcon,
  GripVerticalIcon,
  SquarePenIcon,
  Trash2Icon,
} from "lucide-react";
import {useForm} from "react-hook-form";

import {Button} from "@/components/ui/button";
import {CardContent, CardHeader, CardTitle} from "@/components/ui/card";
import {Dialog, DialogContent, DialogTrigger} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  // DropdownMenuRadioGroup,
  // DropdownMenuRadioItem,
  DropdownMenuSeparator,
  // DropdownMenuShortcut,
  // DropdownMenuSub,
  // DropdownMenuSubContent,
  // DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {Spinner} from "@/components/ui/spinner";
import {toast} from "@/components/ui/use-toast";

import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";
import type {DupesExemptionCharacterType} from "@/modules/duplicate-payments";
import {
  deleteDupesExemptionCharacter,
  dupesExemptionCharacterSchema,
  dupesExemptionCharacterWithTypesSchema,
  updateDupesExemptionCharacter,
} from "@/modules/duplicate-payments";

/**
 * Props for DupesCriteriaRowActions component.
 * @typedef {Object} DupesCriteriaRowActionsProps
 * @template TData - The type of data for the row.
 * @property {Row<TData>} row - The data row.
 */
interface DupesCriteriaRowActionsProps<TData> {
  row: Row<TData>;
}

interface DupesUpdateCriteriaFormProps {
  character: string;
  exemptionTypeId: string;
  exemption_id?: number | null;
  exemptionFormLabel: string;
  exemptionEntryType: string;
  exemptionEntryValues: string;
  externalId: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Represents the actions for a payment file row.
 * This component provides a dropdown menu for each row with various actions like edit, copy, favorite, and delete.
 *
 * @template TData - The data type for the table row.
 * @param {DupesCriteriaRowActionsProps<TData>} props - The props for the component.
 * @returns {JSX.Element} The DupesCriteriaRowActions component.
 */
export function DupesCriteriaRowActions<TData>({
  row,
}: DupesCriteriaRowActionsProps<TData>) {
  const [open, setOpen] = useState(false);

  // Get the navigate function from the router
  // const navigate = useNavigate();

  // Parses the row data using the schema defined for payment mapping list.
  const exemptionCharacterRow = dupesExemptionCharacterWithTypesSchema.parse(
    row.original
  );

  console.log("exemptionCharacterRow", exemptionCharacterRow);

  const deleteDupesCriteriaSetting = useMutation({
    mutationFn: () =>
      deleteDupesExemptionCharacter(
        exemptionCharacterRow.exemption_id?.toString() ?? ""
      ),
    onSuccess: (response) => {
      console.log("deleteDupesCriteriaSettingResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Criteria setting deleted successfully",
      });
    },
    onError: (error: unknown) => {
      console.error("error", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to delete criteria setting",
      });
    },
  });

  return (
    <DropdownMenu modal={false}>
      {/* Trigger button for the dropdown menu */}
      <DropdownMenuTrigger asChild>
        <Button className="flex h-12 w-12 gap-2 bg-theme p-0 hover:bg-neutral data-[state=open]:bg-secondary">
          <span className="sr-only">Open menu</span>
          <GripVerticalIcon className="size-6 bg-inherit" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" className="min-w-fit">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <AccessPolicyWrapper
          unauthorizedFallback={null}
          policyActions={[KeycloakRoleEnum.UPDATE_DUPES_CRITERIA]}
        >
          <DropdownMenuItem
            className="flex justify-between gap-4"
            onSelect={(e) => {
              e.preventDefault();
            }}
          >
            <UpdateDupesCriteriaSettings
              character={exemptionCharacterRow.character}
              exemptionTypeId={exemptionCharacterRow.exemption_type_id}
              exemption_id={exemptionCharacterRow.exemption_id}
              exemptionFormLabel={exemptionCharacterRow.input_label}
              externalId={exemptionCharacterRow.buyer_external_id}
              open={open}
              setOpen={setOpen}
              exemptionEntryType={
                exemptionCharacterRow.entry_type
                  ? exemptionCharacterRow.entry_type
                  : ""
              }
              exemptionEntryValues={
                exemptionCharacterRow.entry_values
                  ? exemptionCharacterRow.entry_values
                  : ""
              }
            />
          </DropdownMenuItem>
          <DropdownMenuSeparator />
        </AccessPolicyWrapper>
        <AccessPolicyWrapper
          unauthorizedFallback={null}
          policyActions={[KeycloakRoleEnum.DELETE_DUPES_CRITERIA]}
        >
          <DropdownMenuItem
            className="flex cursor-pointer justify-between gap-4 text-destructive-foreground focus:bg-destructive focus:text-destructive-foreground/80"
            onSelect={(e) => {
              e.preventDefault();
              deleteDupesCriteriaSetting.mutate();
            }}
          >
            Delete Criteria Setting
            <span className="sr-only">Delete Criteria Setting</span>
            <Trash2Icon className="size-4" />
          </DropdownMenuItem>
        </AccessPolicyWrapper>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

interface KeyValuePair {
  key: string;
  value: string;
}

function UpdateDupesCriteriaSettings({
  character,
  exemptionTypeId,
  exemption_id,
  exemptionFormLabel,
  exemptionEntryType,
  exemptionEntryValues,
  externalId,
  open,
  setOpen,
}: DupesUpdateCriteriaFormProps) {
  const [keyValueArray, setKeyValueArray] = useState<KeyValuePair[]>([]);
  useMemo(() => {
    const parsedArray = exemptionEntryValues.split("||").map((pair) => {
      const [key, value] = pair.split("|");
      return {key, value};
    });

    setKeyValueArray(parsedArray);
  }, [exemptionEntryValues]);

  const dupesExcludeCharacterFormMethods = useForm<DupesExemptionCharacterType>(
    {
      resolver: zodResolver(dupesExemptionCharacterSchema),
      defaultValues: {
        character,
        field_id: "1",
        buyer_external_id: externalId,
        user_id: "1", // 🛠️ TODO: dynamically fill this
        last_modified_user_id: "1", // 🛠️ TODO: dynamically fill this
        type_id: exemptionTypeId,
        exemption_type_id: exemptionTypeId,
        customer_id: "1", // 🛠️ TODO: dynamically fill this
        exemption_id,
      },
    }
  );

  const updateDupesCreateCriteriaMutation = useMutation({
    mutationFn: (data: DupesExemptionCharacterType) =>
      updateDupesExemptionCharacter(externalId, data),
    onSuccess: (response) => {
      console.log("updateDupesCreateCriteriaMutationResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Criteria setting updated successfully",
      });
      setOpen(false);
    },
    onError: (error: unknown) => {
      console.error("error", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update criteria setting",
      });
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <div className="relative flex cursor-pointer select-none items-center justify-between gap-4 rounded-sm py-1.5 text-sm outline-none transition-colors hover:cursor-pointer focus:bg-destructive data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50">
          Edit Criteria Setting
          <span className="sr-only">Edit Criteria Setting</span>
          <FileTextIcon className="size-4" />
        </div>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[700px]">
        <CardHeader>
          <CardTitle>Update Participant</CardTitle>
        </CardHeader>
        <div className="gap-y-4 sm:flex-row sm:gap-x-4">
          <CardContent>
            {/* form */}
            <Form {...dupesExcludeCharacterFormMethods}>
              <form
                className="flex flex-col gap-4"
                onSubmit={dupesExcludeCharacterFormMethods.handleSubmit(
                  (data) => {
                    updateDupesCreateCriteriaMutation.mutate(data);
                  }
                )}
              >
                {exemptionEntryType === "select" ? (
                  <FormField
                    name="character"
                    render={(field) => (
                      <FormItem>
                        <FormLabel htmlFor="character" showMandatoryAsterisk>
                          {exemptionFormLabel}:
                        </FormLabel>
                        <FormControl>
                          <Select
                            defaultValue={character ? character : ""}
                            onValueChange={field.field.onChange}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select character" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {keyValueArray.map((pair, index) => (
                                <SelectItem key={index} value={pair.key}>
                                  {pair.value}
                                </SelectItem>
                              ))}
                            </SelectContent>
                          </Select>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ) : (
                  <FormField
                    name="character"
                    render={() => (
                      <FormItem>
                        <FormLabel htmlFor="character" showMandatoryAsterisk>
                          {exemptionFormLabel}:
                        </FormLabel>
                        <FormControl>
                          <Input
                            type="text"
                            placeholder={`Enter character...`}
                            disabled={
                              updateDupesCreateCriteriaMutation.isPending
                            }
                            aria-disabled={
                              updateDupesCreateCriteriaMutation.isPending
                            }
                            {...dupesExcludeCharacterFormMethods.register(
                              "character"
                            )}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                )}

                <Button
                  type="submit"
                  className="gap-2"
                  disabled={
                    dupesExcludeCharacterFormMethods.formState.isSubmitting
                  }
                >
                  Submit
                  {updateDupesCreateCriteriaMutation.isPending ? (
                    <Spinner size="xs" />
                  ) : (
                    <SquarePenIcon className="size-4" />
                  )}
                </Button>
              </form>
            </Form>
          </CardContent>
        </div>
      </DialogContent>
    </Dialog>
  );
}
