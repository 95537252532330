export const userGroupsIM = [
  {label: "Management", value: "management"},
  {label: "ePayables User", value: "epayables-user"},
  {label: "Support CSR", value: "support-csr"},
  {label: "Program Manager", value: "program-manager"},
  {label: "ePayables Admin", value: "epayables-admin"},
  {label: "Project Manager", value: "project-manager"},
  {label: "IM User", value: "im-user"},
];

export const userGroupsSupplier = [
  {label: "Supplier Admin", value: "supplier-admin"},
  {label: "Supplier Enablement", value: "supplier-enablement"},
  {label: "Supplier User", value: "supplier-user"},
];

export const userGroupsCustomer = [
  {label: "Customer Admin", value: "customer-admin"},
  {label: "Customer User", value: "customer-user"},
  {label: "Customer Reviewer", value: "customer-reviewer"},
  {label: "Customer Super Admin", value: "customer-super-admin"},
];
