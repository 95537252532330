import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";

import {calculateUserRoles, useKeyCloakInstanceStore} from "@/modules/auth";

import {
  FallbackMessages,
  KeycloakRoles,
  remittanceMethods,
  supplierStatuses,
} from "@/utils/constants";

export function renderRemittanceBadge(remittanceMethod: string) {
  const remittanceObject = remittanceMethods.find(
    (method) => method.label === remittanceMethod
  );

  if (!remittanceObject) {
    return (
      <Badge className="whitespace-nowrap" variant="destructive">
        {FallbackMessages.NO_DATA}
      </Badge>
    );
  }

  const IconComponent = remittanceObject.icon;

  return (
    <div className="w-fit">
      <Badge className="whitespace-nowrap uppercase hover:opacity-70">
        <span className="mr-2">
          <IconComponent className="h-4" />
        </span>
        {remittanceMethod}
      </Badge>
    </div>
  );
}

export function renderSupplierStatus(status: string) {
  const currentStatus =
    typeof status === "string" ? status.replace(/_/g, " ") : "";

  const statusObject = supplierStatuses.find(
    (status) => status.label === currentStatus
  );

  if (!statusObject) {
    return (
      <Badge className="whitespace-nowrap" variant="destructive">
        {FallbackMessages.NO_DATA}
      </Badge>
    );
  }

  const IconComponent = statusObject.icon;

  return (
    <div className="w-48">
      <Badge
        variant={statusObject.variant}
        className="uppercase hover:opacity-70"
      >
        <span className="mr-1">
          <IconComponent className="h-4" />
        </span>
        {currentStatus}
      </Badge>
    </div>
  );
}

export function RenderCustomerStatus(status: string) {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  const customerRoles = [
    KeycloakRoles.CustomerAdmin,
    KeycloakRoles.CustomerUser,
    "Facility User",
    "ViewOnly User",
  ];

  const isCustomerRole = customerRoles.includes(userRoleDisplay);

  let displayStatus = status;

  if (status === "Failed" && isCustomerRole) {
    displayStatus = "In Progress";
  }

  if (!displayStatus) {
    return (
      <Badge className="whitespace-nowrap" variant="destructive">
        {FallbackMessages.NO_DATA}
      </Badge>
    );
  }

  return <Badge className="whitespace-nowrap">{displayStatus}</Badge>;
}

export function RenderCustomerDescription(status: string, description: string) {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  const isCustomerOrFacilityUser = [
    KeycloakRoles.CustomerAdmin,
    KeycloakRoles.CustomerUser,
    "Facility User",
    "ViewOnly User",
  ].includes(userRoleDisplay);

  let descriptionData = description;

  if (isCustomerOrFacilityUser) {
    if (status === "Failed") {
      descriptionData = "Payment is being processed by Agent/Script";
    } else if (
      description === "Script not configured, manual processing required"
    ) {
      descriptionData = "Successfully created card on payment provider side";
    }
  }

  return descriptionData ? (
    <Paragraph className="min-w-48">{descriptionData}</Paragraph>
  ) : (
    <Badge className="whitespace-nowrap" variant="destructive">
      {FallbackMessages.NO_DATA}
    </Badge>
  );
}
