import {kyApiFn} from "@/lib/ky";
import {type PaymentCommentsFormDTO} from "@/modules/imremit";

/**
 * Re-process payment
 * @param processPaymentData
 * @param processStatus
 * @param paymentDetailId
 * @param rdpId
 * @returns
 */
export const paymentProcessFn = (
  processPaymentData: PaymentCommentsFormDTO,
  processStatus: boolean,
  paymentDetailId: string,
  rdpId: string
) =>
  kyApiFn(
    `api/e-payable/management/payment-details/${paymentDetailId}/user?process=${processStatus.toString()}&headless=true&rdpId=${rdpId}`,
    "put",
    {
      json: processPaymentData,
    }
  );

/**
 * Auto Re-process -Captcha faield
 * @param processPaymentData
 * @param processStatus
 * @param payByWebTransactionHistoryId
 * @param rdpId
 * @returns
 */
export const paymentAutoProcessFn = (
  processPaymentData: PaymentCommentsFormDTO,
  payByWebTransactionHistoryId: string,
  rdpId: string
) =>
  kyApiFn(
    `api/e-payable/paybyweb-transaction/${payByWebTransactionHistoryId}?rdpId=${rdpId}`,
    "put",
    {
      json: processPaymentData,
    }
  );
