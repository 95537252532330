// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

import {type misPaymentsDifferencesColumnsType} from "@/modules/admin";

interface MISPaymentsDifferencesColumnsStoreState {
  storeMISPaymentsDifferencesColumns: misPaymentsDifferencesColumnsType | null;
  setStoreMISPaymentsDifferencesColumns: (
    storeMISPaymentsDifferencesColumns: misPaymentsDifferencesColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for Alerts table columns
export const useMISPaymentsDifferencesColumnsStore = create(
  persist<MISPaymentsDifferencesColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeMISPaymentsDifferencesColumns: null,

      // Getter function to retrieve the current state
      getStoreMISPaymentsDifferencesColumns: () =>
        get().storeMISPaymentsDifferencesColumns,

      // Setter function to update the state
      setStoreMISPaymentsDifferencesColumns: (
        storeMISPaymentsDifferencesColumns
      ) => {
        set(() => ({storeMISPaymentsDifferencesColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "Alerts-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useMISPaymentsDifferencesColumns = () =>
  useMISPaymentsDifferencesColumnsStore((state) => {
    return {
      storeMISPaymentsDifferencesColumns:
        state.storeMISPaymentsDifferencesColumns,
    };
  });
