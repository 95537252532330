import {z} from "zod";

import {multiSelectDatumSchema} from "@/components/craft/fancy-multi-select";

export const StatementReconGroupingFormSchema = z.object({
  supplierGroupName: z.string().max(80),
  suppliers: z.array(multiSelectDatumSchema),
});

export const StatementReconConfigFormSchema = z.object({
  suppliers: z.array(multiSelectDatumSchema),
});

export interface StatementColumnsDataType {
  header: string;
  data: string[];
}

export const StatementMappingFormSchema = z.object({
  statementMappingName: z.string().max(80).min(3),
  date_format_mapping: z.string(),
  mappingDetail: z.array(
    z.object({
      vendorFieldId: z.string(),
      userFieldName: z.string(),
    })
  ),
});

export const StatementReconciliationFormSchema = z.object({
  statementMappingId: z.string(),
  statementFileId: z.string(),
});

export const StatementReconciliationTriggerSchema = z.object({
  file_id: z.string(),
  mapping_id: z.string(),
  buyer_external_id: z.string(),
  logged_user_email_id: z.string(),
});

export type StatementReconciliationTriggerType = z.infer<
  typeof StatementReconciliationTriggerSchema
>;
