// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type PaymentMappingAdminTableColumnsType} from "@/modules/imremit";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface PaymentMappingAdminTableColumnsStoreState {
  storePaymentMappingAdminTableColumns: PaymentMappingAdminTableColumnsType | null;
  setStorePaymentMappingAdminTableColumns: (
    storePaymentMappingAdminTableColumns: PaymentMappingAdminTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for PaymentMappingAdmin table columns
export const usePaymentMappingAdminTableColumnsStore = create(
  persist<PaymentMappingAdminTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storePaymentMappingAdminTableColumns: null,

      // Getter function to retrieve the current state
      getStorePaymentMappingAdminTableColumns: () =>
        get().storePaymentMappingAdminTableColumns,

      // Setter function to update the state
      setStorePaymentMappingAdminTableColumns: (
        storePaymentMappingAdminTableColumns
      ) => {
        set(() => ({storePaymentMappingAdminTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "admin-mapping-payment-file-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const usePaymentMappingAdminTableColumns = () =>
  usePaymentMappingAdminTableColumnsStore((state) => {
    return {
      storePaymentMappingAdminTableColumns:
        state.storePaymentMappingAdminTableColumns,
    };
  });
