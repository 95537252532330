import {createRoute} from "@tanstack/react-router";

import {PageNotFound} from "@/components/layout/not-found";

import {RoutesDirectory} from "@/lib/routes/directory";
import {authorizedRootRoute} from "@/lib/routes/root";
import {
  CreateInvoicesMappingPage,
  InvoicesMappingPage,
  InvoicesRedirect,
  InvoiceTrackerDashboardPage,
  InvoiceTrackerOutlet,
} from "@/modules/invoices";

export const invoiceTrackerRootRoute = createRoute({
  path: RoutesDirectory.INVOICES,
  component: InvoiceTrackerOutlet,
  getParentRoute: () => authorizedRootRoute,
});

export const invoiceTrackerRedirectRoute = createRoute({
  path: RoutesDirectory.TRACKER,
  component: InvoicesRedirect,
  getParentRoute: () => invoiceTrackerRootRoute,
});

export const invoiceTrackerRoute = createRoute({
  path: `${RoutesDirectory.TRACKER}/$invoicesPage${RoutesDirectory.DASHBOARD}`,
  component: InvoiceTrackerDashboardPage,
  getParentRoute: () => invoiceTrackerRootRoute,
});

export const invoiceTrackerMappingRoute = createRoute({
  path: RoutesDirectory.MAPPING,
  component: InvoicesMappingPage,
  getParentRoute: () => invoiceTrackerRootRoute,
});

export const createInvoiceTrackerMappingRoute = createRoute({
  path: `${RoutesDirectory.MAPPING}${RoutesDirectory.CREATE}`,
  component: CreateInvoicesMappingPage,
  getParentRoute: () => invoiceTrackerRootRoute,
});

export const invoiceTrackerNotFoundRoute = createRoute({
  // all routes from /app that are not defined will be redirected to this route
  path: RoutesDirectory.CATCH_ALL,
  component: PageNotFound,
  getParentRoute: () => invoiceTrackerRootRoute,
});
