import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  paymentManagementInvoiceQueryKeys,
  type paymentManagementInvoices,
} from "@/modules/imremit";

type QueryFnType = typeof getPaymentManagementInvoices;

// Function to search failedAlerts
export function getPaymentManagementInvoices(id?: string) {
  return kyApiFn<paymentManagementInvoices[]>(
    `api/e-payable/payment-details/${id}/invoices`,
    "get"
  );
  // TODO: validate data
}

// Custom hook to fetch and cache failedAlerts data using react-query
export function usePaymentManagementInvoices(
  id?: string,
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: paymentManagementInvoiceQueryKeys.byId(id), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getPaymentManagementInvoices(id), // Setting the function to be used for fetching data
    enabled: !!id, // Setting the query to be enabled only if the search query string is not empty
  });
}
