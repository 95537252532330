/* eslint-disable */
import {useCallback, useEffect, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {debounce} from "lodash";
import {PlusIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {Card, CardContent, CardHeader} from "@/components/ui/card";
import {Dialog, DialogContent, DialogTrigger} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Heading2, Heading4, Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {FancyMultiSelect} from "@/components/craft/fancy-multi-select";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {StatementReconRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore, useStatementReconCustomerStore} from "@/lib/stores";
import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useAuthorization,
} from "@/modules/auth";
import {
  SelectCustomerFormStatementRecon,
  SelectCustomerSectionStatementRecon,
} from "@/modules/customers";
import {
  addSRGroupingsFn,
  StatementReconGroupingFormSchema,
  useParsedSuppliers,
  useSRGroupingsByExternalId,
  type StatementReconGetGroupType,
} from "@/modules/statement-recon";

/**
 * Custom Hook: Manage the active route for the Statement Recon grouping page
 */
function useStatementReconGroupRoute() {
  const {setStatementReconRoute} = useRoutesStore();

  useEffect(() => {
    // Set the active route based on predefined route schema
    setStatementReconRoute(
      StatementReconRoutesSchema.Values["/app/statement-recon/upload"]
    );
  }, [setStatementReconRoute]);
}

export function StatementReconGroupPage() {
  useStatementReconGroupRoute();

  const [openGroupingForm, setOpenGroupingForm] = useState(false);

  const {statementReconStoreCustomer} = useStatementReconCustomerStore();
  const {checkPoliciesAccess} = useAuthorization();

  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_SR_UPLOAD])) {
    return <NotAuthorizedPage />;
  }

  if (!statementReconStoreCustomer) {
    return <SelectCustomerSectionStatementRecon />;
  }

  return (
    <AccessPolicyWrapper policyActions={[KeycloakRoleEnum.READ_SR_UPLOAD]}>
      <section>
        <section className="flex justify-between gap-4">
          <div className="flex flex-col">
            <Heading2>Supplier Grouping Page</Heading2>
            <Paragraph>View and create your supplier groupings here.</Paragraph>
          </div>
          <div className="flex gap-4">
            <SelectCustomerFormStatementRecon />
            {/* TODO: New access permission type for adding supplier grouping */}
            <AccessPolicyWrapper
              policyActions={[KeycloakRoleEnum.READ_SR_UPLOAD]}
            >
              <Dialog
                open={openGroupingForm}
                onOpenChange={setOpenGroupingForm}
              >
                <DialogTrigger asChild>
                  <Button className="gap-2">
                    <PlusIcon className="size-4" />
                    <span className="sr-only">Add Supplier Grouping</span>
                    Add Supplier Grouping
                  </Button>
                </DialogTrigger>
                <DialogContent className="flex h-auto w-full max-w-screen-md items-center justify-center pt-2">
                  <CreateSupplierGroupingForm
                    externalId={statementReconStoreCustomer.externalId}
                    setOpenGroupingForm={setOpenGroupingForm}
                  />
                </DialogContent>
              </Dialog>
            </AccessPolicyWrapper>
          </div>
        </section>

        <StatementReconGroupPageContent />
      </section>
    </AccessPolicyWrapper>
  );
}

function StatementReconGroupPageContent() {
  const {statementReconStoreCustomer} = useStatementReconCustomerStore();
  const externalId = statementReconStoreCustomer?.externalId;

  const supplierGroupsByExternalIdQuery =
    useSRGroupingsByExternalId(externalId);

  if (supplierGroupsByExternalIdQuery.isPending) {
    return <div>Loading...</div>;
  }

  if (supplierGroupsByExternalIdQuery.isError) {
    return <div>Error...</div>;
  }

  if (!Array.isArray(supplierGroupsByExternalIdQuery.data.content)) {
    return (
      <div className="flex w-full justify-center py-4">
        <Paragraph>
          There are no supplier groupings found for{" "}
          {statementReconStoreCustomer?.buyerName ?? "this customer"}.
        </Paragraph>
      </div>
    );
  }

  return (
    <ShowSupplierGroupings
      supplierGroups={supplierGroupsByExternalIdQuery.data.content}
    />
  );
}

interface CreateSupplierGroupingFormProps {
  externalId: string;
  setOpenGroupingForm: (value: boolean) => void;
  suppliersForGrouping?: {
    value: string;
    label: string;
  }[];
}

export function CreateSupplierGroupingForm({
  externalId,
  setOpenGroupingForm,
  suppliersForGrouping,
}: CreateSupplierGroupingFormProps) {
  const supplierGroupingFormMethods = useForm<
    z.infer<typeof StatementReconGroupingFormSchema>
  >({
    defaultValues: {
      suppliers: suppliersForGrouping ? suppliersForGrouping : [],
    },
    mode: "onChange",
    resolver: zodResolver(StatementReconGroupingFormSchema),
  });

  // console.log(supplierData, suppliersLoading);

  const addSupplierGroupingMutation = useMutation({
    mutationFn: (data: z.infer<typeof StatementReconGroupingFormSchema>) => {
      const reqData = {
        im_sr_supplier_group_name: data.supplierGroupName,
        supplier_ids: data.suppliers.map((supplier) => supplier.value),
        buyer_external_id: externalId,
      };

      return addSRGroupingsFn(reqData);
    },
    onSuccess: (response) => {
      console.log("addSRGroupingsResponse", response);
      supplierGroupingFormMethods.reset();
      setOpenGroupingForm(false);

      toast({
        variant: "success",
        title: "Success!",
        description: "Supplier grouping added successfully",
      });
    },
    onError: (error) => {
      console.error("errorMessage", error);

      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add supplier grouping",
      });
    },
  });

  const handleAddSupplierGrouping = (
    data: z.infer<typeof StatementReconGroupingFormSchema>
  ) => {
    addSupplierGroupingMutation.mutate(data);
  };

  const [search, setSearch] = useState<string>("");

  // Memoize setSearch to ensure it's stable and doesn't change
  const stableSetSearch = useCallback((value: string) => setSearch(value), []);

  const debouncedSetSearch = useCallback(
    debounce((value: string) => {
      stableSetSearch(value);
    }, 300),
    [stableSetSearch] // Declare stableSetSearch as dependency
  );

  useEffect(() => {
    return () => {
      debouncedSetSearch.cancel();
    };
  }, [debouncedSetSearch]);

  const handleSearchChange = (value: string) => {
    debouncedSetSearch(value);
  };

  const {supplierData} = useParsedSuppliers(search, externalId);

  return (
    <section className="flex w-full flex-col gap-4">
      <Form {...supplierGroupingFormMethods}>
        <form
          className="flex w-full flex-col gap-4"
          onSubmit={supplierGroupingFormMethods.handleSubmit(
            handleAddSupplierGrouping
          )}
        >
          <div className="flex flex-col">
            <Heading2>Create Supplier Grouping</Heading2>
            <Paragraph>
              Create a new supplier grouping for the selected customer.
            </Paragraph>
          </div>
          <FormField
            control={supplierGroupingFormMethods.control}
            name="supplierGroupName"
            render={() => (
              <FormItem>
                <FormLabel>Supplier Group Name :</FormLabel>
                <FormControl>
                  <Input
                    // disabled={isMutating}
                    // aria-disabled={isMutating}
                    autoCapitalize="none"
                    autoComplete="supplierGroupName"
                    autoCorrect="off"
                    placeholder="Enter supplier group name..."
                    {...supplierGroupingFormMethods.register(
                      "supplierGroupName"
                    )}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="suppliers"
            render={({field}) => (
              <FormItem className="flex flex-col">
                <FormLabel htmlFor="suppliers" showMandatoryAsterisk>
                  Select suppliers:
                </FormLabel>
                <FormControl>
                  <FancyMultiSelect
                    {...field}
                    setSearch={handleSearchChange}
                    multiSelectData={supplierData}
                    customWidth="96"
                    placeholder={
                      !supplierData.length
                        ? "No suppliers found..."
                        : "Search suppliers..."
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button className="min-w-fit gap-2" type="submit">
            <PlusIcon className="size-4" />
            Add Grouping
          </Button>
        </form>
      </Form>
    </section>
  );
}

interface ShowSupplierGroupingsProps {
  supplierGroups: StatementReconGetGroupType[];
}

function ShowSupplierGroupings({supplierGroups}: ShowSupplierGroupingsProps) {
  return (
    <div className="my-4 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
      {supplierGroups.map((group, i) => (
        <Card key={i}>
          <CardHeader>
            <Heading4>Group Name: {group.im_sr_supplier_group_name}</Heading4>
          </CardHeader>
          <CardContent>
            <Paragraph>
              <span className="font-bold">Supplier IDs:</span>
              {group.supplier_ids}
            </Paragraph>
            <Paragraph>
              <span className="font-bold">Created At:</span>{" "}
              {group.created_timestamp}
            </Paragraph>
          </CardContent>
        </Card>
      ))}
    </div>
  );
}
