import {useEffect} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {Link} from "@tanstack/react-router";
import {ArrowRightIcon, CheckIcon, ChevronsUpDownIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button, buttonVariants} from "@/components/ui/button";
import {Command, CommandGroup, CommandItem} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Heading1, Paragraph} from "@/components/ui/typography";

import {cn} from "@/lib/utils";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";
import {
  AuditFileAddMapping,
  BankFileAddMapping,
  documentFormatSchema,
  PaymentFileAddMapping,
  ReconFileAddMapping,
  ResponseFileAddMapping,
  useDocumentTypesDetails,
  useMappingDocumentTypeStore,
} from "@/modules/imremit";

export function ImREmitCreateMapping() {
  // Extract the `document type` field from the store
  // and the `updateStoreDocumentType` function to update the store
  const {storeDocumentType, updatestoreDocumentType} =
    useMappingDocumentTypeStore();

  // Fetch the documents type data using the custom hook `useDocumentTypesDetails`
  const searchDocumentsTypeResponse = useDocumentTypesDetails();

  // Destructure the `data` property, focusing on its `content` attribute
  const documentTypeData = searchDocumentsTypeResponse.data?.content;

  const form = useForm<z.infer<typeof documentFormatSchema>>({
    mode: "onChange", // validate the form on change
    resolver: zodResolver(documentFormatSchema), // use Zod for validation
  });

  // Observe the field from the form
  const formFields = form.watch();

  useEffect(() => {
    const docType = formFields.documentTypeId;

    //Find the document type from useDocumentTypeDetails hook list
    const selectedDocumentType = documentTypeData?.find((data) => {
      return data.documentTypeId.toString() === docType;
    });

    if (selectedDocumentType) {
      // We call updatestoreDocumentType, passing in documentTypeId.
      // This updates the stored Document Type  to match the current form fields.
      updatestoreDocumentType(selectedDocumentType);
    }
  }, [updatestoreDocumentType, formFields.documentTypeId, documentTypeData]);

  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.CREATE_IMREMIT_MAPPING]}
    >
      <section>
        <header className="flex flex-col gap-4 lg:flex-row lg:items-center lg:justify-between">
          <div className="flex flex-col">
            <Heading1 className="self-start">Add Master Mapping</Heading1>
          </div>
          <div className="mt-4 flex flex-col gap-4 lg:flex-row">
            <Form {...form}>
              <form className="w-50 xl:mb-4">
                <FormField
                  control={form.control}
                  name="documentTypeId"
                  render={({field}) => (
                    <FormItem>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant="outline"
                              role="combobox"
                              className={cn(
                                "justify-between",
                                !field.value && "text-accent-foreground",
                                "w-[260px]"
                              )}
                            >
                              {storeDocumentType?.typeName ||
                                (field.value
                                  ? documentTypeData?.find(
                                      (item) =>
                                        item.documentTypeId.toString() ===
                                        field.value
                                    )?.typeName
                                  : "Select Configuration Type...")}
                              <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-[260px] p-0">
                          <Command>
                            <CommandGroup>
                              {documentTypeData &&
                                documentTypeData.map(
                                  (data, i) =>
                                    data.documentTypeId !== 2 && (
                                      <CommandItem
                                        key={i}
                                        value={data.documentTypeId.toString()}
                                        onSelect={(value) => {
                                          field.onChange(value);
                                        }}
                                      >
                                        <CheckIcon
                                          className={cn(
                                            "mr-2 size-4",
                                            data.documentTypeId.toString() ===
                                              field.value
                                              ? "opacity-100"
                                              : "opacity-0"
                                          )}
                                        />
                                        {data.typeName}
                                      </CommandItem>
                                    )
                                )}
                            </CommandGroup>
                          </Command>
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </form>
            </Form>

            <Link
              to="/app/imremit/mapping"
              className={cn(
                buttonVariants({variant: "secondary"}),
                "mb-4 gap-2"
              )}
            >
              Back to list
              <span className="sr-only">Back to list</span>
              <ArrowRightIcon className="size-4" />
            </Link>
          </div>
        </header>

        {/* If no document type is selected */}
        {!storeDocumentType && (
          <div className="flex w-full items-center justify-center">
            <Paragraph className="mt-8 font-semibold">
              Please select document type
            </Paragraph>
          </div>
        )}

        {/* Render the forms based on the confugration type */}
        {storeDocumentType?.documentTypeId == 1 && <PaymentFileAddMapping />}
        {storeDocumentType?.documentTypeId == 3 && <ReconFileAddMapping />}
        {storeDocumentType?.documentTypeId == 4 && <BankFileAddMapping />}
        {storeDocumentType?.documentTypeId == 5 && <AuditFileAddMapping />}
        {storeDocumentType?.documentTypeId == 6 && <ResponseFileAddMapping />}
      </section>
    </AccessPolicyWrapper>
  );
}
