import {useParams} from "@tanstack/react-router";

import {RunnerConfigForm} from "@/modules/admin";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";

import {ModuleNames} from "@/utils/constants";

export function CustomerOnboardingImREmitRunnerConfigPage() {
  const {externalId} = useParams({
    from: "/app/admin/customer-management/imremit/$externalId/onboarding/runner-configuration",
  });

  // Render OnboardCustomerForm component
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.UPDATE_CUSTOMER_MANAGEMENT]}
    >
      <RunnerConfigForm externalId={externalId} module={ModuleNames.imremit} />
    </AccessPolicyWrapper>
  );
}
