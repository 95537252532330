import {useInfiniteQuery, useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  filteredCustomersQueryKeys,
  type CustomerType,
} from "@/modules/customers";

// Defining a type based on the function `getFilteredCustomersByName`
type QueryFnType = typeof getFilteredCustomersByName;

// Function to fetch all customers
export function getFilteredCustomersByName(
  moduleName: string,
  customerName?: string,
  size?: number,
  page?: number
) {
  // Fetch all customers from API using ky
  const data = kyApiFn<CustomerType[]>(
    `customer/v1/customers?moduleName=${moduleName}${
      customerName ? `&customer_name=${customerName}` : ""
    }${page ? `&page=${page.toString()}` : ""}${
      size ? `&size=${size.toString()}` : ""
    }`,
    "get"
  );
  // TODO: validate data
  // return customerSchema.parse(data);
  return data; // Returning the fetched data
}

// Custom hook to fetch and cache all imRemit lite customers data using react-query
export function useFilteredCustomersByName(
  moduleName: string,
  customerName?: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: filteredCustomersQueryKeys.byNameAndModuleName(
      moduleName,
      customerName
    ), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getFilteredCustomersByName(moduleName, customerName), // Setting the function to be used for fetching data
    // TODO: add onSuccess and onError handlers
  });
}

// 🔄 Custom hook for infinite scrolling of customer data
// Uses react-query's useInfiniteQuery for efficient pagination
export function useFetchInfiniteCustomersByName(
  moduleName: string,
  customerName?: string
) {
  const {
    status, // 📊 Current status of the query
    data, // 💾 Paginated customer data
    error, // ⚠️ Any errors that occurred
    isFetching, // 🔄 Whether query is currently fetching
    isFetchingNextPage, // 📥 Whether next page is being fetched
    fetchNextPage, // ⏭️ Function to fetch next page of data
    hasNextPage, // ✨ Whether more pages are available
  } = useInfiniteQuery({
    queryKey: filteredCustomersQueryKeys.byNameAndModuleName(
      moduleName,
      customerName
    ),
    queryFn: (ctx) =>
      getFilteredCustomersByName(moduleName, customerName, 10, ctx.pageParam),
    getNextPageParam: (lastPage) => {
      // Check if we have received less items than requested (10)
      // or if we've reached the total number of pages
      if (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        !lastPage.content ||
        lastPage.content.length < 10 ||
        (lastPage.meta &&
          lastPage.meta.pageNumber >= lastPage.meta.totalPages - 1)
      ) {
        return undefined; // Return undefined to indicate no more pages
      }
      return lastPage.meta ? lastPage.meta.pageNumber + 1 : undefined;
    },
    initialPageParam: 0,
  });

  return {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  };
}
