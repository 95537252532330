import {createRoute} from "@tanstack/react-router";

import {RoutesDirectory} from "@/lib/routes/directory";
import {
  statementReconRootRoute,
  StatementReconStatementListPage,
} from "@/modules/statement-recon";

export const statementReconStatementListRoute = createRoute({
  path: `${RoutesDirectory.STATEMENT}${RoutesDirectory.LIST}`,
  component: StatementReconStatementListPage,
  getParentRoute: () => statementReconRootRoute,
});
