/**
 * Enum for Keycloak roles with specific permissions for various application features.
 * These roles are used to manage access control within the system.
 */
export enum KeycloakRoleEnum {
  // Not Mentioned in BDR but it's in the code
  // Grants read access to admin cards on IMRemit dashboard
  READ_IMREMIT_DASHBOARD_ADMIN = "read:imremit-dashboard-admin",

  // Grants update access to control batch processing.
  UPDATE_CONTROL_BATCH_PROCESSING = "update:control-batch-processing",

  //Grants read access to failed alerts.
  READ_FAILED_ALERTS = "read:failed-alerts",

  // Grants read access to remittance management features.
  READ_REMITTANCE_MANAGEMENT = "read:remittance-management",

  // Grant read access for resolve payment button on proxy-pay
  READ_PROXYPAY_RESOLVE_PAYMENT_BTN = "read:proxypay-resolve-payment-btn",

  // Mentioned in BDR
  // Grants read access to the admin module
  READ_ADMIN = "read:admin",

  // Grants read access to customer management
  READ_CUSTOMER_MANAGEMENT = "read:customer-management",

  // Grants create access to customer management
  CREATE_CUSTOMER_MANAGEMENT = "create:customer-management",

  // Grants delete access to customer management
  DELETE_CUSTOMER_MANAGEMENT = "delete:customer-management",

  // Grants create access to customer modules
  CREATE_CUSTOMER_MODULES = "create:customer-modules",

  // Grants update access to customer management
  UPDATE_CUSTOMER_MANAGEMENT = "update:customer-management",

  // Grants update access to customer onboard
  UPDATE_CUSTOMER_ONBOARD = "update:customer-onboard",

  // Grants read access to master role settings
  READ_MASTER_ROLE_SETTINGS = "read:master-role-settings",

  // Grants read access to user management
  READ_USER_MANAGEMENT = "read:user-management",

  // Grants create access to user management
  CREATE_USER_MANAGEMENT = "create:user-management",

  // Grants delete access to user management
  DELETE_USER_MANAGEMENT = "delete:user-management",

  // Grants update access to user management
  UPDATE_USER_MANAGEMENT = "update:user-management",

  // Grants read access to alert management
  READ_ALERT_MANAGEMENT = "read:alert-management",

  // Grants read access to customer module management
  READ_CUSTOMER_MODULE_MANAGEMENT = "read:customer-module-management",

  // Grants read access to invoice tracker
  READ_INVOICES = "read:invoice-tracker",

  // Grants read access to imremit
  READ_IMREMIT = "read:imremit",

  // Grants read access to imremit dashboard
  READ_IMREMIT_DASHBOARD = "read:imremit-dashboard",

  // Grants read access to payment management
  READ_PAYMENT_MANAGEMENT = "read:payment-management",

  // Grants update access to payment management
  UPDATE_PAYMENT_MANAGEMENT = "update:payment-management",

  // Grants update access to payment reprocess
  UPDATE_PAYMENT_REPROCESS = "update:payment-reprocess",

  // Grants read access to card details
  READ_CARD_DETAILS = "read:card-details",

  // Grants create access to payment management
  CREATE_PAYMENT_MANAGEMENT = "create:payment-management",

  // Grants update access to payment block
  UPDATE_PAYMENT_BLOCK = "update:payment-block",

  // Grants create access to add payment comment
  CREATE_ADD_PAYMENT_COMMENT = "create:add-payment-comment",

  // Grants update access to payment authorization
  UPDATE_PAYMENT_AUTHORIZATION = "update:payment-authorization",

  // Grants create access to payment management export
  CREATE_PAYMENT_MANAGEMENT_EXPORT = "create:payment-management-export",

  // Grants read access to file processing
  READ_FILE_PROCESSING = "read:file-processing",

  // Grants read access to proxypay dashboard
  READ_PROXYPAY_DASHBOARD = "read:proxypay-dashboard",

  // Grants create access to add proxy payment comment
  CREATE_ADD_PROXY_PAYMENT_COMMENT = "create:add-proxy-payment-comment",

  // Grants update access to pay by web
  UPDATE_PAY_BY_WEB = "update:pay-by-web",

  // Grants read access to supplier management
  READ_SUPPLIER_MANAGEMENT = "read:supplier-management",

  // Grants update access to supplier management
  UPDATE_SUPPLIER_MANAGEMENT = "update:supplier-management",

  // Grants delete access to supplier management
  DELETE_SUPPLIER_MANAGEMENT = "delete:supplier-management",

  // Grants create access to supplier management
  CREATE_SUPPLIER_MANAGEMENT = "create:supplier-management",

  // Grants update access to supplier status
  UPDATE_SUPPLIER_STATUS = "update:supplier-status",

  // Grants create access to supplier management export
  CREATE_SUPPLIER_MANAGEMENT_EXPORT = "create:supplier-management-export",

  // Grants read access to supplier script management
  READ_SUPPLIER_SCRIPT_MANAGEMENT = "read:supplier-script-management",

  // Grants create access to supplier script management
  CREATE_SUPPLIER_SCRIPT_MANAGEMENT = "create:supplier-script-management",

  // Grants update access to supplier script management
  UPDATE_SUPPLIER_SCRIPT_MANAGEMENT = "update:supplier-script-management",

  // Grants read access to imremit mapping
  READ_IMREMIT_MAPPING = "read:imremit-mapping",

  // Grants read access to pdf upload
  READ_PDF_UPLOAD = "read:pdf-upload",

  // Grants create access to imremit mapping
  CREATE_IMREMIT_MAPPING = "create:imremit-mapping",

  // Grants read access to imremit lite
  READ_IMREMIT_LITE = "read:imremit-lite",

  // Grants read access to imremit lite dashboard
  READ_IMREMIT_LITE_DASHBOARD = "read:imremit-lite-dashboard",

  // Grants read access to payment management lite
  READ_PAYMENT_MANAGEMENT_LITE = "read:payment-management-lite",

  // Grants update access to payment reprocess lite
  UPDATE_PAYMENT_REPROCESS_LITE = "update:payment-reprocess-lite",

  // Grants update access to payment block lite
  UPDATE_PAYMENT_BLOCK_LITE = "update:payment-block-lite",

  // Grants read access to card details lite
  READ_CARD_DETAILS_LITE = "read:card-details-lite",

  // Grants create access to add payment comment lite
  CREATE_ADD_PAYMENT_COMMENT_LITE = "create:add-payment-comment-lite",

  // Grants create access to payment management export lite
  CREATE_PAYMENT_MANAGEMENT_EXPORT_LITE = "create:payment-management-export-lite",

  // Grants update access to move payment
  UPDATE_MOVE_PAYMENT = "update:move-payment",

  // Grants read access to proxypay dashboard lite
  READ_PROXYPAY_DASHBOARD_LITE = "read:proxypay-dashboard-lite",

  // Grants create access to add proxy payment comment lite
  CREATE_ADD_PROXY_PAYMENT_COMMENT_LITE = "create:add-proxy-payment-comment-lite",

  // Grants update access to pay by web lite
  UPDATE_PAY_BY_WEB_LITE = "update:pay-by-web-lite",

  // Grants read access to supplier management lite
  READ_SUPPLIER_MANAGEMENT_LITE = "read:supplier-management-lite",

  // Grants update access to supplier management lite
  UPDATE_SUPPLIER_MANAGEMENT_LITE = "update:supplier-management-lite",

  // Grants delete access to supplier management lite
  DELETE_SUPPLIER_MANAGEMENT_LITE = "delete:supplier-management-lite",

  // Grants create access to supplier management lite
  CREATE_SUPPLIER_MANAGEMENT_LITE = "create:supplier-management-lite",

  // Grants update access to supplier status lite
  UPDATE_SUPPLIER_STATUS_LITE = "update:supplier-status-lite",

  // Grants create access to supplier management export lite
  CREATE_SUPPLIER_MANAGEMENT_EXPORT_LITE = "create:supplier-management-export-lite",

  // Grants read access to supplier script management lite
  READ_SUPPLIER_SCRIPT_MANAGEMENT_LITE = "read:supplier-script-management-lite",

  // Grants create access to supplier script management lite
  CREATE_SUPPLIER_SCRIPT_MANAGEMENT_LITE = "create:supplier-script-management-lite",

  // Grants update access to supplier script management lite
  UPDATE_SUPPLIER_SCRIPT_MANAGEMENT_LITE = "update:supplier-script-management-lite",

  // Grants read access to duplicate payments
  READ_DUPLICATE_PAYMENTS = "read:duplicate-payments",

  // Grants read access to dupes dashboard
  READ_DUPES_DASHBOARD = "read:dupes-dashboard",

  // Grants read access to dupes tracker
  READ_DUPES_TRACKER = "read:dupes-tracker",

  // Grants read access to dupes criteria
  READ_DUPES_CRITERIA = "read:dupes-criteria",

  // Grants update access to dupes criteria
  UPDATE_DUPES_CRITERIA = "update:dupes-criteria",

  // Grants create access to dupes criteria
  CREATE_DUPES_CRITERIA = "create:dupes-criteria",

  // Grants delete access to dupes criteria
  DELETE_DUPES_CRITERIA = "delete:dupes-criteria",

  // Grants read access to statement recon
  READ_STATEMENT_RECON = "read:statement-recon",

  // Grants read access to sr upload
  READ_SR_UPLOAD = "read:sr-upload",

  // Grants read access to sr search
  READ_SR_SEARCH = "read:sr-search",

  // Grants read access to sr settings
  READ_SR_SETTINGS = "read:sr-settings",

  // Grants create access to sr settings
  CREATE_SR_SETTINGS = "create:sr-settings",

  // Grants update access to sr settings
  UPDATE_SR_SETTINGS = "update:sr-settings",

  // Grants read access to electronic invoicing
  READ_ELECTRONIC_INVOICING = "read:electronic-invoicing",

  // Grants read access to view card type
  READ_CARD_TYPE = "read:card-type",

  // Grants update access for flag reset
  UPDATE_FLAG_RESET = "update:flag-reset",
}
