import {z} from "zod";

export const supplierSchema = z.object({
  groupId: z.number(),
  id: z.number(),
  taxCode: z.number(),
  name: z.string(),
  externalId: z.number().nullable(),
  number: z.string(),
  isActive: z.boolean(),
  buyerId: z.number().nullable(),
});

export type SupplierType = z.infer<typeof supplierSchema>;

export const newSupplierSchema = z.object({
  customerName: z.string(),
  supplierId: z.number(),
  supplierName: z.string(),
  taxId: z.string().nullable().optional(),
  phoneNumber: z.string().nullable().optional(),
  supplierEmail: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  state: z.string().nullable().optional(),
  zip: z.string().nullable().optional(),
  onboardStatus: z.string().nullable().optional(),
  selfRegisteredFlag: z.string().nullable().optional(),
  lastEditTimestamp: z.string().nullable().optional(),
});

export type NewSupplierType = z.infer<typeof newSupplierSchema>;

// COMMENTS FORM SCHEMA FOR SUPPLIER COMMENTS
export const SupplierCommentsFormSchema = z.object({
  userId: z.string(),
  comments: z
    .string()
    .min(1, {message: "comment is required"})
    .refine((val) => val.trim().length > 0, {
      message: "Please ensure you have entered a comment.",
    }),
  //.max(2000, {message: "Comment must be under 2000 characters."}),
  role: z.string(),
});

export type SupplierCommentsFormDTO = z.infer<
  typeof SupplierCommentsFormSchema
>;

// COMMENTS FORM SCHEMA FOR SUPPLIER COMMENTS -markAsRead
export const SupplierCommentsMarkAsRead = z.object({
  userId: z.string(),
  comments: z
    .string()
    .min(1, {message: "comment is required"})
    .max(2000, {message: "Comment must be under 2000 characters."})
    .refine((val) => val.trim().length > 0, {
      message: "Please ensure you have entered a comment.",
    }),
  commentId: z.number(),
  markAsRead: z.boolean(),
});

export type SupplierCommentsMarkAsRead = z.infer<
  typeof SupplierCommentsMarkAsRead
>;
