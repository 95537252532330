import type {Dispatch, SetStateAction} from "react";
import {useEffect, useState} from "react";

import {Link, useParams} from "@tanstack/react-router";
import {ArrowRightIcon, MessagesSquareIcon} from "lucide-react";
import {useFormContext} from "react-hook-form";

import {Button, buttonVariants} from "@/components/ui/button";
import {Label} from "@/components/ui/label";
import {RadioGroup, RadioGroupItem} from "@/components/ui/radio-group";
import {Heading2, Paragraph} from "@/components/ui/typography";
import {LoadingSkeletonCard} from "@/components/craft/loading-skeleton-card";

import {cn} from "@/lib/utils";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";
import {
  CommentsForm,
  DeclinedForm,
  EnrolledForm,
  useGetSuppliersDetails,
} from "@/modules/imremit";

/**
 * EditSupplierFormProps Interface
 *
 * @interface EditSupplierFormProps
 * @property {boolean} isMutating - Indicates whether the mutation is in progress.
 * @property {Function} onSubmit - The function to be called upon form submission.
 */

/**
 * EditSupplierForm Component
 *
 * This component renders the password forgot form and handles its behavior.
 *
 * @param {EditSupplierFormProps} props - Properties passed to the component
 * @returns {JSX.Element} - Rendered component
 */

function EditSupplierDetailsForm() {
  const {supplierId} = useParams({
    from: "/app/imremit/supplier-management/$supplierId/edit",
  });

  const searchSupplierIDQuery = useGetSuppliersDetails(Number(supplierId));
  const searchSupplierData = searchSupplierIDQuery.data?.content;

  const [enrolledStatus, setEnrolledStatus] = useState<"enrolled" | "declined">(
    "declined"
  );

  useEffect(() => {
    if (searchSupplierIDQuery.data?.content.enrolled) {
      const enrolled = searchSupplierIDQuery.data.content.enrolled as boolean;
      enrolled ? setEnrolledStatus("enrolled") : setEnrolledStatus("declined");
    }
  }, [searchSupplierIDQuery.data]);

  if (searchSupplierIDQuery.isPending) {
    return <LoadingSkeletonCard classNames="mt-8" lineCount={10} />;
  }

  return (
    <section>
      <div className="mb-4 flex flex-col-reverse gap-4 md:flex-row md:justify-between">
        <div className="flex flex-col">
          <Heading2>Edit Supplier</Heading2>
          <Paragraph>Edit supplier details and enrollment status</Paragraph>
        </div>
        <div className="flex flex-grow justify-end gap-2">
          <div className="flex flex-col gap-2">
            {searchSupplierData ? (
              <CommentsForm id={supplierId} />
            ) : (
              <Button
                className="h-10 gap-2"
                size="sm"
                variant="outline"
                disabled={true}
              >
                <MessagesSquareIcon className="size-4" />
                <Paragraph>Comments</Paragraph>
              </Button>
            )}
          </div>
          <Link
            to="/app/imremit/supplier-management"
            className={cn(buttonVariants({variant: "secondary"}), "gap-0")}
          >
            Back to list
            <span className="sr-only">Back to list</span>
            <ArrowRightIcon className="size-4" />
          </Link>
        </div>
      </div>

      <div className="mb-8 rounded-md border border-border bg-root p-4">
        {enrolledStatus === "enrolled" && (
          <EnrolledForm>
            <SupplierEnrollment
              enrolledStatus={enrolledStatus}
              setEnrolledStatus={setEnrolledStatus}
            />
          </EnrolledForm>
        )}
        {enrolledStatus === "declined" && (
          <DeclinedForm>
            <SupplierEnrollment
              enrolledStatus={enrolledStatus}
              setEnrolledStatus={setEnrolledStatus}
            />
          </DeclinedForm>
        )}
      </div>
    </section>
  );
}

/**
 * SupplierEnrollment component.
 * This component displays a checkbox for managing the enrollment status of a supplier.
 *
 * Props:
 * - enrolledStatus: The current enrollment status of the supplier.
 * - setEnrolledStatus: Function to update the enrollment status.
 *
 * @param {SupplierEnrollmentProps} props - Component props.
 * @returns {JSX.Element} The SupplierEnrollment component.
 */
interface SupplierEnrollmentProps {
  enrolledStatus: string;
  setEnrolledStatus: Dispatch<SetStateAction<"enrolled" | "declined">>;
}

function SupplierEnrollment({
  enrolledStatus,
  setEnrolledStatus,
}: SupplierEnrollmentProps): JSX.Element {
  const {setValue} = useFormContext();

  return (
    <div className="flex flex-row space-x-3 space-y-6">
      <RadioGroup>
        <Label showMandatoryAsterisk>Supplier Enrollment:</Label>

        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value="enrolled-yes"
            id="enrolled-yes"
            checked={enrolledStatus === "enrolled" ? true : false}
            onClick={() => {
              setEnrolledStatus("enrolled");
              setValue("enrolled", true);
            }}
          />
          <Label htmlFor="enrolled-yes"> Yes </Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value="declined-yes"
            id="declined-yes"
            checked={enrolledStatus === "declined" ? true : false}
            onClick={() => {
              setEnrolledStatus("declined");
              setValue("enrolled", false);
            }}
          />
          <Label htmlFor="declined-yes"> No </Label>
        </div>
      </RadioGroup>
    </div>
  );
}

/**
 * EditSupplierDetailsPage component.
 * This component wraps the EditSupplierDetailsForm component with an AccessPolicyWrapper.
 * It ensures that the user has the 'UPDATE_SUPPLIER_MANAGEMENT' role before allowing access to the form.
 *
 * @returns {JSX.Element} The EditSupplierDetailsPage component.
 */
export function EditSupplierDetailsPage() {
  return (
    // AccessPolicyWrapper checks if the user has the required Keycloak role
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.UPDATE_SUPPLIER_MANAGEMENT]}
    >
      {/* EditSupplierDetailsForm component for editing supplier details */}
      <EditSupplierDetailsForm />
    </AccessPolicyWrapper>
  );
}
