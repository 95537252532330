import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  imREmitDelimiterQueryKeys,
  type ImREmitDelimiter,
} from "@/modules/imremit";

// Defining a type based on the function `searchImREmitDelimiters`
type QueryFnType = typeof getImREmitDelimiters;

// Function to search imremits delimiters
export function getImREmitDelimiters() {
  return kyApiFn<ImREmitDelimiter[]>(`api/e-payable/delimiter-types`, "get");
  // TODO: validate data
}

// Custom hook to fetch and cache imremits delimiters data using react-query
export function useGetImREmitDelimiters(
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: imREmitDelimiterQueryKeys.all, // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getImREmitDelimiters(), // Setting the function to be used for fetching data
  });
}
