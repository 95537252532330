import {z} from "zod";

export const dupesDefaultSettingsSchema = z.object({
  settings_id: z.string(),
  field_id: z.string(),
  field_value: z.string(),
  user_id: z.string(),
  last_modified_user_id: z.string(),
  created_date: z.string(),
  modified_date: z.string(),
  settings_type_id: z.string(),
  customer_id: z.string(),
});

export type DupesDefaultSettingsType = z.infer<
  typeof dupesDefaultSettingsSchema
>;
