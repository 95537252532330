import {useEffect} from "react";

import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {ImREmitLiteRoutesSchema} from "@/lib/routes/types";
import {useImRemitLiteCustomerStore, useRoutesStore} from "@/lib/stores";
import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useAuthorization,
} from "@/modules/auth";
import {SelectCustomerSectionLite} from "@/modules/customers";
import {ProxyPayDashboardView} from "@/modules/imremit-lite";

export function ProxyPayDashboardPage() {
  const {setimREmitLiteRoute} = useRoutesStore();
  const {checkPoliciesAccess} = useAuthorization();
  const {imRemitLiteStoreCustomer} = useImRemitLiteCustomerStore();

  useEffect(() => {
    setimREmitLiteRoute(
      ImREmitLiteRoutesSchema.Values["/app/imremit-lite/proxy-pay-dashboard"]
    );
  }, [setimREmitLiteRoute]);

  // Handle unauthorized access
  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_PROXYPAY_DASHBOARD_LITE])) {
    return <NotAuthorizedPage />;
  }

  // If the customer is not yet selected, render the SelectCustomerSectionLite
  if (!imRemitLiteStoreCustomer) {
    return <SelectCustomerSectionLite />;
  }

  /**
   * Render ProxyPayDashboardView
   *
   * Once all data is available, render the main ProxyPayDashboardView component.
   * Pass relevant props for rendering payment management functionalities.
   *
   * | Prop                     | Type            | Description                                                   |
   * |--------------------------|-----------------|---------------------------------------------------------------|
   * | proxyPays                | Object          | Data content for proxy payments                               |
   */
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.READ_PROXYPAY_DASHBOARD]}
    >
      <ProxyPayDashboardView />
    </AccessPolicyWrapper>
  );
}
