import {
  BadgeDollarSignIcon,
  CoinsIcon,
  FileCog,
  FileJson2Icon,
  GitCompareIcon,
  LayoutTemplateIcon,
  TruckIcon,
  UploadCloudIcon,
} from "lucide-react";

import {SubrouteNavigation} from "@/components/craft/subroute-navigation";

import {ImREmitRoutesSchema} from "@/lib/routes/types";
import {KeycloakRoleEnum} from "@/modules/auth";

// Define an array with the data for each route button. This includes the 'to' route,
// the 'icon' to be displayed, and the 'label' for the button.
// This array holds the configuration for each SubrouteNavigationButton
// Each object in the array represents a button in the navigation menu
const routes = [
  {
    to: ImREmitRoutesSchema.Values["/app/imremit/dashboard"],
    icon: <LayoutTemplateIcon className="h-10 w-10 pl-2" />,
    label: "imREmit Dashboard",
    actionRole: KeycloakRoleEnum.READ_IMREMIT_DASHBOARD,
  },
  {
    to: ImREmitRoutesSchema.Values["/app/imremit/payment-management"],
    icon: <CoinsIcon className="h-10 w-10 pl-2" />,
    label: "Payment Management",
    actionRole: KeycloakRoleEnum.READ_PAYMENT_MANAGEMENT,
  },
  {
    to: ImREmitRoutesSchema.Values["/app/imremit/remittance-management"],
    icon: <CoinsIcon className="h-10 w-10 pl-2" />,
    label: "Remittance Management",
    actionRole: KeycloakRoleEnum.READ_REMITTANCE_MANAGEMENT,
  },
  {
    to: ImREmitRoutesSchema.Values["/app/imremit/file-processing"],
    icon: <FileCog className="h-10 w-10 pl-2" />,
    label: "File Processing",
    actionRole: KeycloakRoleEnum.READ_FILE_PROCESSING,
  },
  {
    to: ImREmitRoutesSchema.Values["/app/imremit/proxy-pay-dashboard"],
    icon: <BadgeDollarSignIcon className="h-10 w-10 pl-2" />,
    label: "Proxy Pay Dashboard",
    actionRole: KeycloakRoleEnum.READ_PROXYPAY_DASHBOARD,
  },
  {
    to: ImREmitRoutesSchema.Values["/app/imremit/supplier-management"],
    icon: <TruckIcon className="h-10 w-10 pl-2" />,
    label: "Supplier Management",
    actionRole: KeycloakRoleEnum.READ_SUPPLIER_MANAGEMENT,
  },
  {
    to: ImREmitRoutesSchema.Values["/app/imremit/supplier-script-management"],
    icon: <FileJson2Icon className="h-10 w-10 pl-2" />,
    label: "Supplier Script Management",
    actionRole: KeycloakRoleEnum.READ_SUPPLIER_SCRIPT_MANAGEMENT,
  },
  {
    to: ImREmitRoutesSchema.Values["/app/imremit/mapping"],
    icon: <GitCompareIcon className="h-10 w-10 pl-2" />,
    label: "imREmit Mapping",
    actionRole: KeycloakRoleEnum.READ_IMREMIT_MAPPING,
  },
  {
    to: ImREmitRoutesSchema.Values["/app/imremit/pdf-upload"],
    icon: <UploadCloudIcon className="h-10 w-10 pl-2" />,
    label: "PDF Upload",
    actionRole: KeycloakRoleEnum.READ_PDF_UPLOAD,
  },
];

interface ImREmitSubrouteNavigationProps {
  layout?: "horizontal" | "vertical";
}

// This component renders the SubrouteNavigation component with the routes array
// as a prop. It also passes the 'navigationAccessibleLabel' and
// 'navigationAriaLabelledby' props to the SubrouteNavigation component.
export function ImREmitSubrouteNavigation(
  {layout}: ImREmitSubrouteNavigationProps = {layout: "horizontal"}
) {
  return (
    <SubrouteNavigation
      routes={routes}
      layout={layout}
      navigationAccessibleLabel="imREmit Subroute Navigation"
      navigationAriaLabelledby="iMPaySubrouteNavHeader"
    />
  );
}
