import {Skeleton} from "@/components/ui/skeleton";
import {Paragraph} from "@/components/ui/typography";

import {useGetUserByIdGroup, type KeycloakUserType} from "@/modules/admin";

interface GetKeycloakUserName {
  userId: string;
}

/**
 * Get the user name from user id.
 *
 * @param {string} userId - The user Id.
 * @returns {JSX.Element} - The rendered user name or loading skeleton.
 */
export function GetKeycloakUserName({userId}: GetKeycloakUserName) {
  const {data: searchUserData, isPending} = useGetUserByIdGroup(userId);

  if (isPending) {
    return <Skeleton className="h-8 w-full" />;
  }

  const userName = getUserName(searchUserData);

  return <Paragraph className="whitespace-nowrap">{userName}</Paragraph>;
}

function getUserName(userData: KeycloakUserType | undefined): string {
  if (!userData) return "SYSTEM";

  const {firstName, lastName, username} = userData;
  if (firstName && lastName) return `${firstName} ${lastName}`;
  if (username) return username;
  return "SYSTEM";
}
