import {z} from "zod";

export const supplierManagementIMRemitPremiumTableColumnsSchema = z.object({
  customerName: z.boolean(),
  supplierId: z.boolean(),
  supplierName: z.boolean(),
  taxId: z.boolean(),
  phoneNumber: z.boolean(),
  supplierEmail: z.boolean(),
  city: z.boolean(),
  state: z.boolean(),
  zip: z.boolean(),
  onboardStatus: z.boolean(),
  selfRegisteredFlag: z.boolean(),
  lastEditTimestamp: z.boolean(),
});
export type SupplierManagementIMRemitPremiumTableColumnsType = z.infer<
  typeof supplierManagementIMRemitPremiumTableColumnsSchema
>;
