import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  fileProcessingDetailsQueryKeys,
  type FileProcessingType,
} from "@/modules/imremit";

// Defining a type based on the function `searchFileProcessing`
type QueryFnType = typeof getFileProcessing;

//Function to search File Processing
export function getFileProcessing(
  externalId?: string,
  searchQueryString?: string
) {
  return kyApiFn<FileProcessingType[]>(
    `api/e-payable/management/${externalId}/file-processing?${
      searchQueryString ?? ""
    }`,
    "get"
  );
}

export function useFileProcessing(
  externalId?: string, // The search query string
  searchQueryString?: string, // The search query string
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: fileProcessingDetailsQueryKeys.byExternalId(
      externalId,
      searchQueryString
    ), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getFileProcessing(externalId, searchQueryString), // Setting the function to be used for fetching data
    enabled: !!externalId, // Setting the query to be enabled only if the externalId are not empty
  });
}
