// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type SupplierManagementIMRemitLiteTableColumnsType} from "@/modules/imremit-lite";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface SupplierManagementIMRemitLiteTableColumnsStoreState {
  storeSupplierManagementIMRemitLiteTableColumns: SupplierManagementIMRemitLiteTableColumnsType | null;
  setStoreSupplierManagementIMRemitLiteTableColumns: (
    storeSupplierManagementIMRemitLiteTableColumns: SupplierManagementIMRemitLiteTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for SupplierManagementIMRemitLite table columns
export const useSupplierManagementIMRemitLiteTableColumnsStore = create(
  persist<SupplierManagementIMRemitLiteTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeSupplierManagementIMRemitLiteTableColumns: null,

      // Getter function to retrieve the current state
      getStoreSupplierManagementIMRemitLiteTableColumns: () =>
        get().storeSupplierManagementIMRemitLiteTableColumns,

      // Setter function to update the state
      setStoreSupplierManagementIMRemitLiteTableColumns: (
        storeSupplierManagementIMRemitLiteTableColumns
      ) => {
        set(() => ({storeSupplierManagementIMRemitLiteTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "SupplierManagementIMRemitLite-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useSupplierManagementIMRemitLiteTableColumns = () =>
  useSupplierManagementIMRemitLiteTableColumnsStore((state) => {
    return {
      storeSupplierManagementIMRemitLiteTableColumns:
        state.storeSupplierManagementIMRemitLiteTableColumns,
    };
  });
