// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type AuditMappingAdminTableColumnsType} from "@/modules/imremit";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface AuditMappingAdminTableColumnsStoreState {
  storeAuditMappingAdminTableColumns: AuditMappingAdminTableColumnsType | null;
  setStoreAuditMappingAdminTableColumns: (
    storeAuditMappingAdminTableColumns: AuditMappingAdminTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for AuditMappingAdmin table columns
export const useAuditMappingAdminTableColumnsStore = create(
  persist<AuditMappingAdminTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeAuditMappingAdminTableColumns: null,

      // Getter function to retrieve the current state
      getStoreAuditMappingAdminTableColumns: () =>
        get().storeAuditMappingAdminTableColumns,

      // Setter function to update the state
      setStoreAuditMappingAdminTableColumns: (
        storeAuditMappingAdminTableColumns
      ) => {
        set(() => ({storeAuditMappingAdminTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "AuditMappingAdmin-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useAuditMappingAdminTableColumns = () =>
  useAuditMappingAdminTableColumnsStore((state) => {
    return {
      storeAuditMappingAdminTableColumns:
        state.storeAuditMappingAdminTableColumns,
    };
  });
