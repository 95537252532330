import {useEffect} from "react";

import {FilePlus2Icon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {Heading2} from "@/components/ui/typography";

import {DuplicatePaymentRoutesSchema} from "@/lib/routes/types";
import {useDupesCustomerStore, useRoutesStore} from "@/lib/stores";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";
import {
  SelectCustomerFormDupes,
  SelectCustomerSectionDupes,
} from "@/modules/customers";
import {
  DupesCriteriaCards,
  DupesCriteriaSettingsDataTable,
  dupesCriteriaSettingsTableColumns,
  useGetDupesCriteriaSettingsByExternalId,
} from "@/modules/duplicate-payments";

/**
 * Initializes the route for Duplicate Payments Criteria Settings Page.
 * This custom hook encapsulates the logic for setting up the route,
 * making the component cleaner and more focused on rendering.
 */
function useInitDupesSettingsRoute() {
  const {setDuplicatePaymentRoute} = useRoutesStore();

  useEffect(() => {
    const routePath = "/app/duplicate-payments/criteria-settings";
    const routeConfig = DuplicatePaymentRoutesSchema.Values[routePath];

    // Set the Dupes Settings route 🛣️
    setDuplicatePaymentRoute(routeConfig);
  }, [setDuplicatePaymentRoute]);
}

/**
 * DupesCriteriaSettingsPage Component
 *
 * Serves as the interface for Dupes Settings, managing route setup and
 * rendering child components based on data availability and state.
 */
export function DupesCriteriaSettingsPage() {
  // Custom hook to initialize the Dupes Settings route
  useInitDupesSettingsRoute();

  const {dupesStoreCustomer} = useDupesCustomerStore();

  const dupesCriteriaSettingsByExternalIdQuery =
    useGetDupesCriteriaSettingsByExternalId(dupesStoreCustomer?.externalId);

  const dupesCriteriaSettingsContent =
    dupesCriteriaSettingsByExternalIdQuery.data?.content || [];

  // 🚫 Early return if no customer is selected, prompting selection.
  if (!dupesStoreCustomer) {
    return <SelectCustomerSectionDupes />;
  }

  return (
    <Collapsible>
      <section className="flex flex-col gap-4">
        <div className="flex items-center justify-between gap-4">
          <Heading2>Duplicate Payments Criteria Settings</Heading2>
          <div className="flex flex-col gap-2 md:flex-row md:gap-4">
            <AccessPolicyWrapper
              unauthorizedFallback={null}
              policyActions={[KeycloakRoleEnum.CREATE_DUPES_CRITERIA]}
            >
              <div className="pt-6">
                <CollapsibleTrigger asChild>
                  <Button className="gap-2 data-[state=open]:border-secondary data-[state=open]:bg-secondary data-[state=open]:text-secondary-foreground">
                    <span className="sr-only">Add criteria settings</span>
                    Add criteria settings
                    <FilePlus2Icon className="size-4" />
                  </Button>
                </CollapsibleTrigger>
              </div>
            </AccessPolicyWrapper>
            <div className="w-full md:w-auto">
              <SelectCustomerFormDupes requireLabel />
            </div>
          </div>
        </div>
        <CollapsibleContent>
          <DupesCriteriaCards externalId={dupesStoreCustomer.externalId} />
        </CollapsibleContent>
        <DupesCriteriaSettingsDataTable
          columns={dupesCriteriaSettingsTableColumns}
          data={dupesCriteriaSettingsContent}
          isPending={dupesCriteriaSettingsByExternalIdQuery.isPending}
          isSuccess={dupesCriteriaSettingsByExternalIdQuery.isSuccess}
          isError={dupesCriteriaSettingsByExternalIdQuery.isError}
        />
      </section>
    </Collapsible>
  );
}
