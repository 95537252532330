import {type ColumnDef} from "@tanstack/react-table";
import {format} from "date-fns";
import {z} from "zod";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import type {
  StatementSearchSupplierType,
  StatementSearchTableType,
} from "@/modules/statement-recon";
import {
  StatementReconFileStatus,
  StatementSearchRowActions,
  statementSearchSupplierSchema,
} from "@/modules/statement-recon";

import {FallbackMessages} from "@/utils/constants";

export const statementSearchTableColumns: ColumnDef<StatementSearchTableType>[] =
  [
    {
      accessorKey: "user_statement_file_name",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="File Name" />
      ),
      cell: ({row}) => (
        <div className="w-fit whitespace-nowrap">
          {row.getValue("user_statement_file_name") ? (
            row.getValue("user_statement_file_name")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "statement_file_status",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="File Status" />
      ),
      cell: ({row}) => {
        const fileStatus = row.getValue("statement_file_status");
        // console.log(fileStatus);

        let badgeContent: string;
        let badgeVariant:
          | "success"
          | "secondary"
          | "destructive"
          | "default"
          | "outline";

        switch (fileStatus) {
          case StatementReconFileStatus.STATEMENT_RECONCILED: // 3️⃣
            badgeContent = "Statement Reconciled";
            badgeVariant = "success";
            break;

          case StatementReconFileStatus.STATEMENT_PROCESSED: // 6️⃣
            badgeContent = "Statement Processed";
            badgeVariant = "outline";
            break;

          case StatementReconFileStatus.RECONCILE_ERROR: // 4️⃣
            badgeContent = "Reconcile Error";
            badgeVariant = "destructive";
            break;

          case StatementReconFileStatus.UPLOADED: // 1️⃣
            badgeContent = "Statement Uploaded";
            badgeVariant = "default";
            break;

          case StatementReconFileStatus.MAPPED: // 2️⃣
            badgeContent = "Statement Mapped";
            badgeVariant = "secondary";
            break;

          case StatementReconFileStatus.OCR_PENDING: // 5️⃣
            badgeContent = "OCR Pending";
            badgeVariant = "secondary";
            break;

          default:
            badgeContent = FallbackMessages.NO_DATA;
            badgeVariant = "destructive";
        }

        return (
          <div className="w-fit whitespace-nowrap">
            <Badge variant={badgeVariant} className="whitespace-nowrap">
              {badgeContent}
            </Badge>
          </div>
        );
      },
    },
    {
      accessorKey: "statement_file_size",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="File Size" />
      ),
      cell: ({row}) => (
        <div className="w-fit whitespace-nowrap">
          {row.getValue("statement_file_size") ? (
            row.getValue("statement_file_size")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "suppliers",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Supplier(s)" />
      ),
      cell: ({row}) => {
        const suppliersData = row.getValue("suppliers");
        let suppliers: Array<StatementSearchSupplierType> = [];

        if (Array.isArray(suppliersData)) {
          try {
            suppliers = z
              .array(statementSearchSupplierSchema)
              .parse(suppliersData);
          } catch (error) {
            console.error("Invalid suppliers data:", error);
          }
        }

        return (
          <div className="flex w-fit gap-2 whitespace-nowrap">
            {suppliers.length ? (
              suppliers.map((supplier, i) => {
                if (!supplier.supplier_id || !supplier.supplier_name) {
                  return (
                    <Badge key={i} variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  );
                } else {
                  return <Badge key={i}>{supplier.supplier_name}</Badge>;
                }
              })
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "statement_uploaded_by",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Uploaded By" />
      ),
      cell: ({row}) => (
        <div className="w-fit whitespace-nowrap">
          {row.getValue("statement_uploaded_by") ? (
            row.getValue("statement_uploaded_by")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "created_timestamp",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Uploaded On" />
      ),
      cell: ({row}) => (
        <div className="w-fit whitespace-nowrap">
          {row.getValue("created_timestamp") ? (
            format(
              new Date(row.getValue("created_timestamp")),
              "MMMM do, yyyy 'at' h:mm a"
            )
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      id: "actions",
      header: () => <Paragraph className="text-right">Actions</Paragraph>,
      cell: ({row}) => (
        <div className="flex justify-end">
          <StatementSearchRowActions
            statement_file_status={row.original.statement_file_status ?? 0}
            statement_file_id={row.original.statement_file_id}
          />
        </div>
      ),
    },
  ];
