// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type StatementReconMappingColumnsType} from "@/modules/statement-recon";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface SRMappingTableColumnsStoreState {
  storeSRMappingTableColumns: StatementReconMappingColumnsType | null;
  setStoreSRMappingTableColumns: (
    storeSRMappingTableColumns: StatementReconMappingColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for SRMapping table columns
export const useSRMappingTableColumnsStore = create(
  persist<SRMappingTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeSRMappingTableColumns: null,

      // Getter function to retrieve the current state
      getStoreSRMappingTableColumns: () => get().storeSRMappingTableColumns,

      // Setter function to update the state
      setStoreSRMappingTableColumns: (storeSRMappingTableColumns) => {
        set(() => ({storeSRMappingTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "sr-mapping-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useSRMappingTableColumns = () =>
  useSRMappingTableColumnsStore((state) => {
    return {
      storeSRMappingTableColumns: state.storeSRMappingTableColumns,
    };
  });
