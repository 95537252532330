import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";
import {ElectronicInvoicingIframe} from "@/modules/electronic-invoicing";

export function ElectronicInvoicingPage() {
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.READ_ELECTRONIC_INVOICING]}
    >
      <section
        className="flex flex-1 flex-col overflow-hidden"
        aria-roledescription="This is the Electronic Invoicing level two subroute."
      >
        <ElectronicInvoicingIframe />
      </section>
    </AccessPolicyWrapper>
  );
}
