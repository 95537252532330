import {format} from "date-fns";

import {Separator} from "@/components/ui/separator";
import {Skeleton} from "@/components/ui/skeleton";
import {Heading4, Paragraph} from "@/components/ui/typography";

import {useGetSupplierProfile, useGetSuppliersDetails} from "@/modules/imremit";

interface SupplierProfileTableProps {
  id: number;
}

export function SupplierProfileTable({id}: SupplierProfileTableProps) {
  //Fetch Supplier Profile
  const supplierProfileQuery = useGetSupplierProfile(id);
  const supplierProfileData = supplierProfileQuery.data?.content;
  const supplierProfileIsPending = supplierProfileQuery.isFetching;

  //Fetch Supplier details
  const supplierDetailsQuery = useGetSuppliersDetails(id);
  const supplierDetailsData = supplierDetailsQuery.data?.content;
  const supplierDetailsIsPending = supplierDetailsQuery.isFetching;

  return (
    <div className="flex justify-between p-4">
      <div className="space-y-1">
        <Heading4>Supplier Profile</Heading4>
        <Separator className="my-4" />
        <div className="grid grid-cols-2 text-sm">
          <Paragraph className="font-medium">Spend (Year to Date) :</Paragraph>
          <Paragraph className="font-bold">
            {supplierProfileIsPending ? (
              <Skeleton className="h-2 w-[150px]" />
            ) : supplierProfileData?.spend ? (
              ` $${supplierProfileData.spend}`
            ) : (
              "-"
            )}
          </Paragraph>
        </div>
        <div className="grid grid-cols-2  text-sm">
          <Paragraph className="font-medium">Date Enrolled :</Paragraph>
          <Paragraph className="font-bold">
            {supplierProfileIsPending ? (
              <Skeleton className="h-2 w-[150px]" />
            ) : supplierProfileData?.enrolledDate ? (
              format(
                new Date(supplierProfileData.enrolledDate),
                "MMMM dd, yyyy"
              )
            ) : (
              "-"
            )}
          </Paragraph>
        </div>
        <div className="grid grid-cols-2  text-sm">
          <Paragraph className="font-medium">No. of Payments Sent :</Paragraph>
          <Paragraph className="font-bold">
            {supplierProfileIsPending ? (
              <Skeleton className="h-2 w-[150px]" />
            ) : supplierProfileData?.paymentsSent ? (
              supplierProfileData.paymentsSent
            ) : (
              "-"
            )}
          </Paragraph>
        </div>
        <div className="grid grid-cols-2  text-sm">
          <Paragraph className="font-medium">Contact Name :</Paragraph>
          <Paragraph className="font-bold">
            {supplierDetailsIsPending ? (
              <Skeleton className="h-2 w-[150px]" />
            ) : supplierDetailsData?.contactName ? (
              supplierDetailsData.contactName
            ) : (
              "-"
            )}
          </Paragraph>
        </div>
        <div className="grid grid-cols-2  text-sm">
          <Paragraph className="font-medium">Contact Email :</Paragraph>
          <Paragraph className="font-bold">
            {supplierDetailsIsPending ? (
              <Skeleton className="h-2 w-[150px]" />
            ) : supplierDetailsData?.contactEmail ? (
              supplierDetailsData.contactEmail
            ) : (
              "-"
            )}
          </Paragraph>
        </div>
        <div className="grid grid-cols-2  text-sm">
          <Paragraph className="font-medium">Country :</Paragraph>
          <Paragraph className="font-bold">
            {supplierDetailsIsPending ? (
              <Skeleton className="h-2 w-[150px]" />
            ) : supplierDetailsData?.country ? (
              supplierDetailsData.country
            ) : (
              "-"
            )}
          </Paragraph>
        </div>
      </div>
    </div>
  );
}
