import {useQuery} from "@tanstack/react-query";

import {keycloakKyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  keycloakUserSessionsQueryKeys,
  type KeycloakUserType,
} from "@/modules/admin";

// Defining a type based on the function `getUserById`
type QueryFnType = typeof getUserById;

// Function to get all realm users
export function getUserById(userId: string) {
  const realm =
    (import.meta.env.VITE_KEYCLOAK_REALM as string | undefined) ?? "dev";
  return keycloakKyApiFn<KeycloakUserType>(
    `admin/realms/${realm}/users/${userId}`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useGetUserByIdGroup(
  userId: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: keycloakUserSessionsQueryKeys.byUserId(userId),
    queryFn: () => getUserById(userId),
    enabled: !!userId,
  });
}
