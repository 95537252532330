import {z} from "zod";

import {
  mappingNameRegex,
  normalStrRegex,
  normalStrRegexBankFile,
  // paymentFieldRegex,
  reconRegex,
  zipCodeRegex,
} from "@/utils/constants";

//Document Types
export const documentTypesSchema = z.object({
  documentTypeId: z.number(),
  typeName: z.string(),
});

export type DocumentTypesType = z.infer<typeof documentTypesSchema>;

//Delimiter Types
export const delimiterTypesSchema = z.object({
  delimiterTypeId: z.number(),
  typeValue: z.string(),
  value: z.string(),
});

export type delimiterTypesType = z.infer<typeof delimiterTypesSchema>;

//Date Format
export const dateFormatSchema = z.object({
  dateFormatId: z.number(),
  dateFormatValue: z.string(),
});

export type dateFormatType = z.infer<typeof dateFormatSchema>;

//Document Format
export const documentFormatsSchema = z.object({
  documentFormatId: z.number(),
  formatName: z.string(),
});

export type documentFormatsType = z.infer<typeof documentFormatsSchema>;

//Document Type Form
export const documentFormatSchema = z.object({
  documentTypeId: z.string().nullable(),
});

export type DocumentFormatType = z.infer<typeof documentFormatSchema>;

//Payment Mappings Schema
const errorMsg = "Please enter valid field position.";
export const paymentMappingFormSchema = z
  .object({
    // .regex(mappingNameRegex, errorMsg),
    masterMappingName: z
      .string()
      .min(2, "Master Mapping Name must be at least 2 characters.")
      .max(100, "Master Mapping Name must not exceed 100 characters."),
    documentTypeId: z.string(),
    type: z.string(),
    adjustmentAmount: z.string().nullable().optional(),
    paymentNumber: z.string().nullable().optional(),
    paymentMethod: z.string().nullable().optional(),
    supplierNumber: z.string().nullable().optional(),
    invoiceDate: z.string().nullable().optional(),
    invoiceNumber: z.string().nullable().optional(),
    invoiceAmount: z.string().nullable().optional(),
    supplierName: z.string().nullable().optional(),
    accountNumber: z.string().nullable().optional(),
    totalAmountPaid: z.string().nullable().optional(),
    paymentDate: z.string().nullable().optional(),
    currency: z.string().nullable().optional(),
    addressLine1: z.string().nullable().optional(),
    addressLine2: z.string().nullable().optional(),
    city: z.string().nullable().optional(),
    state: z.string().nullable().optional(),
    zip: z.string().nullable().optional(),
    locationCode: z.string().nullable().optional(),
    discountAmount: z.string().nullable().optional(),
    totalAmount: z.string().nullable().optional(),
    poNumber: z.string().nullable().optional(),
    invoiceType: z.string().nullable().optional(),
    invoiceComments: z.string().nullable().optional(),
    udf1: z.string().nullable().optional(),
    udf2: z.string().nullable().optional(),
    udf3: z.string().nullable().optional(),
    paymentZip: z.string().nullable().optional(),
    paymentFileIdentifier: z.string().nullable().optional(),
    invoiceFutureUse1: z.string().nullable().optional(),
  })
  .superRefine(
    (
      {
        type,
        paymentNumber,
        supplierName,
        invoiceAmount,
        invoiceNumber,
        zip,
        // paymentMethod,
        supplierNumber,
        invoiceDate,
        // paymentDate,
        // currency,
      },
      ctx
    ) => {
      if (zip && !zip.match(zipCodeRegex) && zip.trim() !== "") {
        ctx.addIssue({
          code: "custom",
          message: "Please enter valid zip code",
          path: ["zip"],
        });
      }
      if (type !== "ISO" && !paymentNumber) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter payment number",
          path: ["paymentNumber"],
        });
      }
      // if (type !== "ISO" && !paymentMethod) {
      //   ctx.addIssue({
      //     code: "custom",
      //     message: "Please enter payment method",
      //     path: ["paymentMethod"],
      //   });
      // }
      if (type !== "ISO" && !supplierNumber) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter supplier number",
          path: ["supplierNumber"],
        });
      }
      if (type !== "ISO" && !invoiceNumber) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter invoice number",
          path: ["invoiceNumber"],
        });
      }
      if (type !== "ISO" && !invoiceAmount) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter invoice amount",
          path: ["invoiceAmount"],
        });
      }
      if (type !== "ISO" && !supplierName) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter supplier name",
          path: ["supplierName"],
        });
      }
      if (type !== "ISO" && !invoiceDate) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter invoice date",
          path: ["invoiceDate"],
        });
      }
      // if (type !== "ISO" && !paymentDate) {
      //   ctx.addIssue({
      //     code: "custom",
      //     message: "Please enter payment date",
      //     path: ["paymentDate"],
      //   });
      // }
      // if (type !== "ISO" && !currency) {
      //   ctx.addIssue({
      //     code: "custom",
      //     message: "Please enter currency",
      //     path: ["currency"],
      //   });
      // }
    }
  );

export type paymentMappingFormType = z.infer<typeof paymentMappingFormSchema>;

export const paymentMappingListSchema = z.object({
  masterMappingName: z.string().nullable(),
  paymentNumber: z.string().nullable().optional(),
  accountNumber: z.string().nullable().optional(),
  totalAmountPaid: z.string().nullable().optional(),
  paymentDate: z.string().nullable().optional(),
  currency: z.string().nullable().optional(),
  paymentMethod: z.string().nullable().optional(),
  supplierNumber: z.string().nullable().optional(),
  supplierName: z.string().nullable().optional(),
  addressLine1: z.string().nullable().optional(),
  addressLine2: z.string().nullable().optional(),
  city: z.string().nullable().optional(),
  state: z.string().nullable().optional(),
  zip: z.string().nullable().optional(),
  locationCode: z.string().nullable().optional(),
  invoiceNumber: z.string().nullable().optional(),
  invoiceDate: z.string().nullable().optional(),
  invoiceAmount: z.string().nullable().optional(),
  discountAmount: z.string().nullable().optional(),
  totalAmount: z.string().nullable().optional(),
  poNumber: z.string().nullable().optional(),
  invoiceType: z.string().nullable().optional(),
  invoiceComments: z.string().nullable().optional(),
  udf1: z.string().nullable().optional(),
  udf2: z.string().nullable().optional(),
  udf3: z.string().nullable().optional(),
  paymentZip: z.string().nullable().optional(),
  paymentFileIdentifier: z.string().nullable().optional(),
  invoiceFutureUse1: z.string().nullable().optional(),
});

export type PaymentMappingListType = z.infer<typeof paymentMappingListSchema>;

// Recon Mappings Schema
export const reconMappingFormSchema = z.object({
  // Common fields
  masterMappingName: z
    .string()
    .min(2, "Master Mapping Name must be at least 2 characters.")
    .max(100, "Master Mapping Name must not exceed 100 characters.")
    .regex(mappingNameRegex, errorMsg),
  documentTypeId: z
    .string()
    .min(1, "Document Type ID is required.")
    .max(2, "Document Type ID must not exceed 2 characters.")
    .regex(reconRegex, errorMsg),
  relationId: z
    .string()
    .min(1, "Relation ID is required.")
    .max(2, "Relation ID must not exceed 2 characters.")
    .regex(reconRegex, errorMsg),
  supplierNumber: z
    .string()
    .min(1, "Supplier Number is required.")
    .max(100, "Supplier Number must not exceed 100 characters.")
    .regex(reconRegex, errorMsg),
  transactionId: z
    .string()
    .min(1, "Transaction ID is required.")
    .max(100, "Transaction ID must not exceed 100 characters.")
    .regex(reconRegex, errorMsg),
  transactionAmount: z
    .string()
    .min(1, "Transaction Amount is required.")
    .max(100, "Transaction Amount must not exceed 100 characters.")
    .regex(reconRegex, errorMsg),
  transactionDate: z
    .string()
    .min(1, "Transaction Date is required.")
    .max(100, "Transaction Date must not exceed 100 characters.")
    .regex(reconRegex, errorMsg),
  postingDate: z
    .string()
    .min(1, "Posting Date is required.")
    .max(100, "Posting Date must not exceed 100 characters.")
    .regex(reconRegex, errorMsg),

  // Optional fields
  cardNumber: z
    .string()
    .min(1, "Card Number must be at least 1 character.")
    .max(100, "Card Number must not exceed 100 characters.")
    .regex(reconRegex, errorMsg)
    .optional(),
  transactionType: z
    .string()
    .min(1, "Transaction Type must be at least 1 character.")
    .max(100, "Transaction Type must not exceed 100 characters.")
    .regex(reconRegex, errorMsg)
    .optional(),
  merchantName: z
    .string()
    .min(1, "Merchant Name must be at least 1 character.")
    .max(100, "Merchant Name must not exceed 100 characters.")
    .regex(reconRegex, errorMsg)
    .optional(),
  originalAmount: z
    .string()
    .min(1, "Original Amount must be at least 1 character.")
    .max(100, "Original Amount must not exceed 100 characters.")
    .regex(reconRegex, errorMsg)
    .optional(),
  udf1: z
    .string()
    .min(1, "UDF1 must be at least 1 character.")
    .max(100, "UDF1 must not exceed 100 characters.")
    .regex(reconRegex, errorMsg)
    .optional(),
  udf2: z
    .string()
    .min(1, "UDF2 must be at least 1 character.")
    .max(100, "UDF2 must not exceed 100 characters.")
    .regex(reconRegex, errorMsg)
    .optional(),
  udf3: z
    .string()
    .min(1, "UDF3 must be at least 1 character.")
    .max(100, "UDF3 must not exceed 100 characters.")
    .regex(reconRegex, errorMsg)
    .optional(),
  udf4: z
    .string()
    .min(1, "UDF4 must be at least 1 character.")
    .max(100, "UDF4 must not exceed 100 characters.")
    .regex(reconRegex, errorMsg)
    .optional(),
  udf5: z
    .string()
    .min(1, "UDF5 must be at least 1 character.")
    .max(100, "UDF5 must not exceed 100 characters.")
    .regex(reconRegex, errorMsg)
    .optional(),
  customData1: z
    .string()
    .min(1, "Custom Data 1 must be at least 1 character.")
    .max(100, "Custom Data 1 must not exceed 100 characters.")
    .regex(reconRegex, errorMsg)
    .optional(),
  customData2: z
    .string()
    .min(1, "Custom Data 2 must be at least 1 character.")
    .max(100, "Custom Data 100 must not exceed 100 characters.")
    .regex(reconRegex, errorMsg)
    .optional(),
  customData3: z
    .string()
    .min(1, "Custom Data 3 must be at least 1 character.")
    .max(100, "Custom Data 3 must not exceed 100 characters.")
    .regex(reconRegex, errorMsg)
    .optional(),
  orderMerchantName: z.string().optional(),
});

export type reconMappingFormType = z.infer<typeof reconMappingFormSchema>;

export const reconMappingListSchema = z.object({
  masterMappingName: z.string().nullable(),
  relationId: z.string().nullable(),
  supplierNumber: z.string().nullable(),
  cardNumber: z.string().nullable(),
  transactionType: z.string().nullable(),
  transactionId: z.string().nullable(),
  transactionAmount: z.string().nullable(),
  transactionDate: z.string().nullable(),
  postingDate: z.string().nullable(),
  merchantName: z.string().nullable(),
  originalAmount: z.string().nullable(),
  udf1: z.string().nullable(),
  udf2: z.string().nullable(),
  udf3: z.string().nullable(),
  udf4: z.string().nullable(),
  udf5: z.string().nullable(),
  customData1: z.string().nullable(),
  customData2: z.string().nullable(),
  customData3: z.string().nullable(),
  orderMerchantName: z.string().nullable(),
});

export type ReconMappingListType = z.infer<typeof reconMappingListSchema>;

//Bank & Audit Mappings Schema
const bankAuditMappingFileSpecsSchema = z.object({
  masterMappingName: z.string().nullable().optional(),
  order: z.number().nullable().optional(),
  headerName: z.string().nullable().optional(),
  dataLength: z.number().nullable().optional(),
  delimiterTypeId: z.string().nullable().optional(),
  typeValue: z.string().nullable().optional(),
  dateFormatValue: z.string().nullable().optional(),
  defaultValue: z.string().nullable().optional(),
  mappingName: z.string().nullable().optional(),
  isDecimalField: z.boolean().nullable().optional(),
  fixedLengthType: z.string().nullable().optional(),
  tableName: z
    .string()
    .nullable()
    .optional()
    .refine((value) => typeof value !== "string" || !/\d/.test(value), {
      message: "Table name cannot contain numerical values.",
    }),
});
export type BankAuditMappingFileSpecsType = z.infer<
  typeof bankAuditMappingFileSpecsSchema
>;

export const bankAuditMappingListSchema = z.object({
  masterMappingName: z.string(),
  fileSpecs: z.array(bankAuditMappingFileSpecsSchema),
});

export type bankAuditMappingListType = z.infer<
  typeof bankAuditMappingListSchema
>;

//Bank File Form Schema
const bankMappingRequestsSchema = z.object({
  order: z
    .string()
    .min(1, "Order must be at least 1 character.")
    .max(20, "Order must not exceed 20 characters."),
  headerName: z
    .string()
    .min(2, "Header Name must be at least 2 characters.")
    .max(200, "Header Name must not exceed 200 characters.")
    .regex(
      normalStrRegexBankFile,
      "Header Name must match the specified pattern."
    ),
  dataLength: z.string().min(1, "Data Length is required."),
  delimiterTypeId: z.string().nullable().optional(),
  dateFormatId: z.string().nullable().optional(),
  defaultValue: z
    .string()
    .min(2, "Default Value must be at least 2 characters.")
    .max(80, "Default Value must not exceed 80 characters.")
    .nullable()
    .optional(),
  mappingName: z
    .string()
    .min(2, "Mapping Name must be at least 2 characters.")
    .max(200, "Mapping Name must not exceed 200 characters.")
    .regex(normalStrRegex, "Mapping Name must match the specified pattern."),
  isDecimalField: z.boolean(),
  fixedLengthType: z.string().nullable().optional(),
});

export const bankMappingFormSchema = z
  .object({
    masterMappingName: z
      .string()
      .min(1, "Master Mapping Name must be at least 1 character.")
      .max(100, "Master Mapping Name must not exceed 100 characters.")
      .regex(mappingNameRegex, errorMsg),
    documentTypeId: z.string(),
    type: z.string(),
    bankMappingRequests: z.array(bankMappingRequestsSchema),

    //BAI2 fields
    receiverCode: z.string().nullable().optional(),
    senderId: z.string().nullable().optional(),
    receiverId: z.string().nullable().optional(),
    fileCreationDate: z.string().nullable().optional(),
    fileCreationTime: z.string().nullable().optional(),
    fileIdNumber: z.string().nullable().optional(),
    physicalRecordLength: z.string().nullable().optional(),
    blockSize: z.string().nullable().optional(),
    baiVersion: z.string().nullable().optional(),
    groupHeaderRecordCode: z.string().nullable().optional(),
    ultimateReceiverId: z.string().nullable().optional(),
    originatorId: z.string().nullable().optional(),
    groupStatus: z.string().nullable().optional(),
    asOfDate: z.string().nullable().optional(),
    asOfTime: z.string().nullable().optional(),
    groupHeaderCurrencyCode: z.string().nullable().optional(),
    asOfDateModifier: z.string().nullable().optional(),
    accountIdentifierRecordCode: z.string().nullable().optional(),
    customerAccountNumber: z.string().nullable().optional(),
    accountIdentifierCurrencyCode: z.string().nullable().optional(),
    transactionDetailRecordType: z.string().nullable().optional(),
    typeCode: z.string().nullable().optional(),
    amount: z.string().nullable().optional(),
    fundsType: z.string().nullable().optional(),
    transactionDetailValueDate: z.string().nullable().optional(),
    transactionDetailValueTime: z.string().nullable().optional(),
    bankReference: z.string().nullable().optional(),
    customerReference: z.string().nullable().optional(),
    accountTrailerRecordCode: z.string().nullable().optional(),
    accountControlTotal: z.string().nullable().optional(),
    accountTrailerNumberOfRecords: z.string().nullable().optional(),
    numberOfAccounts: z.string().nullable().optional(),
    groupTrailerNumberOfRecords: z.string().nullable().optional(),
    groupTrailerValueDate: z.string().nullable().optional(),
    groupTrailerValueTime: z.string().nullable().optional(),
    fileTrailerRecordCode: z.string().nullable().optional(),
    fileControlTotal: z.string().nullable().optional(),
    numberOfGroups: z.string().nullable().optional(),
    fileTrailerNumberOfRecords: z.string().nullable().optional(),
    continuationRecordFields: z.array(z.string()).nullable().optional(),
  })
  .superRefine(
    (
      {
        type,
        senderId,
        receiverId,
        ultimateReceiverId,
        originatorId,
        groupStatus,
        //  customerAccountNumber,
        accountIdentifierCurrencyCode,
        typeCode,
      },
      ctx
    ) => {
      if (
        type === "BAI2" &&
        (!senderId || senderId.length < 1 || senderId.length > 9)
      ) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter sender ID, it should be 9 characters long",
          path: ["senderId"],
        });
      }
      if (type === "BAI2" && !receiverId) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter receiver ID",
          path: ["receiverId"],
        });
      }
      if (type === "BAI2" && !ultimateReceiverId) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter ultimate receiver ID",
          path: ["ultimateReceiverId"],
        });
      }
      if (
        type === "BAI2" &&
        (!originatorId || originatorId.length < 1 || originatorId.length > 9)
      ) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter originator ID, it should be 9 characters long",
          path: ["originatorId"],
        });
      }
      if (
        type === "BAI2" &&
        (!groupStatus ||
          !/^\d+$/.test(groupStatus) ||
          groupStatus.length < 1 ||
          groupStatus.length > 2)
      ) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter group status, it should be 2 numbers",
          path: ["groupStatus"],
        });
      }
      // if (
      //   type === "BAI2" &&
      //   (!customerAccountNumber || !/\d/.test(customerAccountNumber))
      // ) {
      //   ctx.addIssue({
      //     code: "custom",
      //     message: "Please enter customer account number",
      //     path: ["customerAccountNumber"],
      //   });
      // }
      if (type === "BAI2" && !accountIdentifierCurrencyCode) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter currency code",
          path: ["accountIdentifierCurrencyCode"],
        });
      }
      if (type === "BAI2" && !typeCode) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter type code",
          path: ["typeCode"],
        });
      }
    }
  );

export type bankMappingFormType = z.infer<typeof bankMappingFormSchema>;

//Audit File Form Schema
const auditMappingRequestsSchema = z.object({
  mappingName: z
    .string()
    .min(2, "Mapping Name must be at least 2 characters.")
    .max(200, "Mapping Name must not exceed 200 characters.")
    .regex(normalStrRegex, "Mapping Name must match the specified pattern."),
  order: z.string().min(1, "Order must be at least 1 character."),
  headerName: z
    .string()
    .min(2, "Header Name must be at least 2 characters.")
    .max(200, "Header Name must not exceed 200 characters.")
    .regex(normalStrRegex, "Header Name must match the specified pattern."),
  dataLength: z.string().min(1, "Data Length is required."),
  dateFormatId: z.string(),
  isDecimalField: z.boolean(),
  tableName: z
    .string()
    .min(1, "Table Name must be at least 1 character.")
    .refine((value) => typeof value !== "string" || !/\d/.test(value), {
      message: "Table Name cannot contain numerical values.",
    }),
});

export const auditMappingFormSchema = z.object({
  masterMappingName: z
    .string()
    .min(1, "Master Mapping Name must be at least 1 character.")
    .max(100, "Master Mapping Name must not exceed 100 characters.")
    .regex(mappingNameRegex, errorMsg),
  documentTypeId: z.string(),
  auditMappingRequests: z.array(auditMappingRequestsSchema),
});

export type auditMappingFormType = z.infer<typeof auditMappingFormSchema>;

//Response File Form Schema
const responseMappingRequestsSchema = z.object({
  order: z.string().min(1, "Order must be at least 1 character."),
  headerName: z
    .string()
    .min(2, "Header Name must be at least 2 characters.")
    .max(200, "Header Name must not exceed 200 characters."),
  // .regex(normalStrRegex, "Header Name must match the specified pattern."),
  dataLength: z.string().min(1, "Data Length is required."),
  delimiterTypeId: z.string(),
  dateFormatId: z.string(),
  defaultValue: z.string().nullable().optional(),
  mappingName: z
    .string()
    .min(2, "Mapping Name must be at least 2 characters.")
    .max(200, "Mapping Name must not exceed 200 characters."),
  // .regex(normalStrRegex, "Mapping Name must match the specified pattern."),
  isDecimalField: z.boolean(),
  tableName: z
    .string()
    .refine((value) => typeof value !== "string" || !/\d/.test(value), {
      message: "Table Name cannot contain numerical values.",
    })
    .nullable()
    .optional(),
});

export const responseMappingFormSchema = z.object({
  masterMappingName: z
    .string()
    .min(1, "Master Mapping Name must be at least 1 character.")
    .max(100, "Master Mapping Name must not exceed 100 characters.")
    .regex(mappingNameRegex, errorMsg),
  documentTypeId: z.string(),
  documentFormatId: z.string(),
  responseMappingRequests: z.array(responseMappingRequestsSchema),
});

export type responseMappingFormType = z.infer<typeof responseMappingFormSchema>;

//Table column tytpes for ImREmit mapping
export const paymentMappingAdminTableColumnsSchema = z.object({
  masterMappingName: z.boolean(),
  paymentNumber: z.boolean(),
  accountNumber: z.boolean(),
  totalAmountPaid: z.boolean(),
  paymentDate: z.boolean(),
  currency: z.boolean(),
  paymentMethod: z.boolean(),
  supplierNumber: z.boolean(),
  supplierName: z.boolean(),
  addressLine1: z.boolean(),
  addressLine2: z.boolean(),
  city: z.boolean(),
  state: z.boolean(),
  zip: z.boolean(),
  paymentZip: z.boolean(),
  locationCode: z.boolean(),
  invoiceNumber: z.boolean(),
  invoiceDate: z.boolean(),
  invoiceAmount: z.boolean(),
  discountAmount: z.boolean(),
  totalAmount: z.boolean(),
  poNumber: z.boolean(),
  invoiceType: z.boolean(),
  invoiceComments: z.boolean(),
  paymentFileIdentifier: z.boolean(),
  udf1: z.boolean(),
  udf2: z.boolean(),
  udf3: z.boolean(),
});
export type PaymentMappingAdminTableColumnsType = z.infer<
  typeof paymentMappingAdminTableColumnsSchema
>;

export const reconMappingAdminTableColumnsSchema = z.object({
  masterMappingName: z.boolean(),
  relationId: z.boolean(),
  supplierNumber: z.boolean(),
  cardNumber: z.boolean(),
  transactionType: z.boolean(),
  transactionId: z.boolean(),
  transactionAmount: z.boolean(),
  transactionDate: z.boolean(),
  postingDate: z.boolean(),
  merchantName: z.boolean(),
  originalAmount: z.boolean(),
  udf1: z.boolean(),
  udf2: z.boolean(),
  udf3: z.boolean(),
  udf4: z.boolean(),
  udf5: z.boolean(),
  customData1: z.boolean(),
  customData2: z.boolean(),
  customData3: z.boolean(),
});
export type ReconMappingAdminTableColumnsType = z.infer<
  typeof reconMappingAdminTableColumnsSchema
>;

export const auditMappingAdminTableColumnsSchema = z.object({
  masterMappingName: z.boolean(),
  mappingName: z.boolean(),
  order: z.boolean(),
  headerName: z.boolean(),
  dataLength: z.boolean(),
  defaultValue: z.boolean(),
  isDecimalField: z.boolean(),
  tableName: z.boolean(),
});
export type AuditMappingAdminTableColumnsType = z.infer<
  typeof auditMappingAdminTableColumnsSchema
>;

export const bankMappingAdminTableColumnsSchema = z.object({
  masterMappingName: z.boolean(),
  mappingName: z.boolean(),
  order: z.boolean(),
  headerName: z.boolean(),
  dataLength: z.boolean(),
  defaultValue: z.boolean(),
  dateFormatValue: z.boolean(),
  delimiterTypeId: z.boolean(),
  isDecimalField: z.boolean(),
  fixedLengthType: z.boolean(),
});
export type BankMappingAdminTableColumnsType = z.infer<
  typeof bankMappingAdminTableColumnsSchema
>;

export const responseMappingAdminTableColumnsSchema = z.object({
  masterMappingName: z.boolean(),
  mappingName: z.boolean(),
  order: z.boolean(),
  headerName: z.boolean(),
  dataLength: z.boolean(),
  defaultValue: z.boolean(),
  dateFormatValue: z.boolean(),
  delimiterTypeId: z.boolean(),
  isDecimalField: z.boolean(),
  tableName: z.boolean(),
});
export type ResponseMappingAdminTableColumnsType = z.infer<
  typeof responseMappingAdminTableColumnsSchema
>;
