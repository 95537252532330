import {kyApiFn} from "@/lib/ky";
import {type EditSupplierDeclinedFormDTO} from "@/modules/imremit";

export const editSupplierDeclinedFn = (
  editSupplierDeclinedDTO: EditSupplierDeclinedFormDTO,
  supplierId: number
) =>
  kyApiFn(
    `api/e-payable/management/supplier-details/${supplierId}/edit`,
    "put",
    {
      json: editSupplierDeclinedDTO,
    }
  );
