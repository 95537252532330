import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import type {EmailConfiguration} from "@/modules/admin";
import {emailConfigurationsKeys} from "@/modules/admin";

// Defining a type based on the function `searchEmailConfigurations`
type QueryFnType = typeof getEmailConfigurations;

// Function to search emailConfigurations
export const getEmailConfigurations = (externalId: string) =>
  kyApiFn<EmailConfiguration[]>(
    `api/e-payable/notification-types/retrieve-email-configs?externalId=${externalId}`,
    "get"
  );

// Custom hook to fetch and cache emailConfigurations data using react-query
export function useGetEmailConfigurations(
  externalId: string, // The search query string
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: emailConfigurationsKeys.byCustomerId(externalId),
    queryFn: () => getEmailConfigurations(externalId),
    enabled: !!externalId,
  });
}
