import {useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {useParams} from "@tanstack/react-router";
import {format} from "date-fns";
import {
  InfoIcon,
  LucideBadgeCheck,
  LucideBadgeX,
  MessageSquarePlusIcon,
  MessagesSquareIcon,
  XIcon,
} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {Card} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {Label} from "@/components/ui/label";
import {ScrollArea} from "@/components/ui/scroll-area";
import {Separator} from "@/components/ui/separator";
import {Spinner} from "@/components/ui/spinner";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {Textarea} from "@/components/ui/textarea";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {GetKeycloakUserName} from "@/modules/admin";
import {
  AccessPolicyWrapper,
  calculateUserRoles,
  KeycloakRoleEnum,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {
  paymentSubmittedFn,
  ProxyPayAssignUser,
  ProxyPayCommentsFormSchema,
  usePaymentManagementComments,
  type PaymentManagementCommentType,
  type ProxyPayViewPageSchema,
} from "@/modules/imremit";

import {
  CustomerStatus,
  KeycloakRoles,
  PaymentModes,
  PaymentRequestPaymentMode,
} from "@/utils/constants";

interface ProxyPayCommentsProps {
  proxyPayComments: PaymentManagementCommentType;
}

interface ProxyPayCommentsDataProps {
  proxyPayViewData: ProxyPayViewPageSchema;
}

export function ProxyPayCommentsData({
  proxyPayViewData,
}: ProxyPayCommentsDataProps) {
  // Declaring the state for comment dialog
  const [openDialog, setOpenDialog] = useState(false);

  //Fetching logged user role
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  const {data: proxyPayComments} = usePaymentManagementComments(
    proxyPayViewData.paymentDetailId
  );

  const failedCounts: number =
    proxyPayComments?.content
      .filter((comment) => comment.failedCount !== undefined)
      .map((comment) => comment.failedCount)
      .at(0) || 0;

  const {paymentId} = useParams({
    from: "/app/imremit/proxy-pay-dashboard/$paymentId/view/$reProcess",
  });

  const proxyPaymentCommentsFormMethods = useForm<
    z.infer<typeof ProxyPayCommentsFormSchema>
  >({
    defaultValues: {
      userId: storeKeyCloakInstance?.tokenParsed?.sub as string,
      role: userRoleDisplay,
      paymentStatus: "",
    },
    resolver: zodResolver(ProxyPayCommentsFormSchema),
  });

  /**
   * Payment Submitted
   * @param {paymentStatus} - Payment Status PAID
   * @param {paymentId} - payment id
   * @param {comments} - comments
   *
   */
  const paymentSubmittedMutation = useMutation({
    mutationFn: (data: z.infer<typeof ProxyPayCommentsFormSchema>) => {
      const {paymentStatus, ...filteredData} = data;
      let payStatus = "";

      if (!paymentStatus) {
        if (
          proxyPayViewData.paymentMode === PaymentModes.PayByPhone ||
          proxyPayViewData.paymentRequestPaymentMode ===
            PaymentRequestPaymentMode.ManualPBW
        ) {
          if (
            proxyPayViewData.customerStatus === CustomerStatus.Paid ||
            proxyPayViewData.customerStatus ===
              CustomerStatus.PaidPendingVerification
          ) {
            proxyPayViewData.statusName == "Failed to Reconcile"
              ? (payStatus = "RECON_FAILED")
              : "";
          } else {
            payStatus = "IN_PROGRESS";
          }
        }
      } else {
        payStatus = paymentStatus;
      }

      return paymentSubmittedFn(filteredData, paymentId, payStatus);
    },
    onSuccess: (response) => {
      setOpenDialog(false);
      proxyPaymentCommentsFormMethods.reset();
      console.log("paymentSubmittedResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Payment submitted successfully",
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to submit payment",
      });
    },
  });

  const isMutating = paymentSubmittedMutation.isPending;
  /**
   * Handles payment submit form submission.
   *
   * @param {z.infer<typeof ProxyPayCommentsFormSchema>} data - Form data.
   * @returns {void}
   */
  function paymentSubmit(data: z.infer<typeof ProxyPayCommentsFormSchema>) {
    const {comments, ...rest} = data;

    const processedData = {
      ...rest,
      comments: comments.trim(),
    };
    paymentSubmittedMutation.mutate(processedData);
  }

  if (!proxyPayComments)
    return (
      <Button variant="outline" disabled>
        <span className="sr-only">Comments</span>
        <MessagesSquareIcon className="mr-2 size-4" />
        <Paragraph>Comments</Paragraph>
      </Button>
    );
  const {content: unsortedProxyPayComments} = proxyPayComments;
  const sortedProxyPayComments = unsortedProxyPayComments.sort(
    (a, b) => new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
  );

  return (
    <div className="flex flex-col gap-4 pt-0 md:flex-row md:justify-between">
      <Dialog open={openDialog} onOpenChange={setOpenDialog}>
        <DialogTrigger asChild>
          <Button
            variant="outline"
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            <span className="sr-only">Comments</span>
            <MessagesSquareIcon className="mr-2 size-4" />
            <Paragraph>Comments</Paragraph>
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[700px]" removeCloseButton>
          <DialogHeader>
            <DialogTitle>Comments</DialogTitle>
            <Button
              variant={"ghost"}
              className="absolute right-4 top-4 h-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
              onClick={() => {
                setOpenDialog(false);
              }}
            >
              <XIcon className="text-default h-4 w-4" />
              <span className="sr-only">Close</span>
            </Button>
          </DialogHeader>
          <Card>
            <ScrollArea className="max-h-[320px] rounded-lg">
              <Table>
                <TableCaption>
                  {sortedProxyPayComments.length == 0 && "No Comments Found!"}
                </TableCaption>
                <TableHeader>
                  <TableRow>
                    <TableHead className="w-[100px]">Date/Time</TableHead>
                    <TableHead>Username</TableHead>
                    <TableHead>Comment</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {sortedProxyPayComments.map((proxyPayComments, index) => (
                    <ProxypayComments
                      key={index}
                      proxyPayComments={proxyPayComments}
                    />
                  ))}
                </TableBody>
              </Table>
            </ScrollArea>
          </Card>

          <Form {...proxyPaymentCommentsFormMethods}>
            <form
              className="grid w-full gap-1.5"
              onSubmit={proxyPaymentCommentsFormMethods.handleSubmit((data) => {
                const {comments, ...rest} = data;

                const processedData = {
                  ...rest,
                  comments: comments.trim(),
                };

                paymentSubmittedMutation.mutate(processedData); // Submit the data
              })}
            >
              <Label className="flex space-x-2">
                <Paragraph>Add a comment:</Paragraph>
                {proxyPayViewData.paymentMode === PaymentModes.PayByWeb &&
                proxyPayViewData.customerStatus === CustomerStatus.Failed ? (
                  <TooltipProvider delayDuration={200}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="cursor-pointer">
                          <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                        </div>
                      </TooltipTrigger>
                      <TooltipContent>
                        <div className="flex justify-between space-x-4">
                          For manual payment add key word : <br /> Manual
                          payment done#*****(space)
                          <br /> Type keyword : man or # it will auto fill the
                          whole keyword.
                        </div>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ) : null}
              </Label>
              <FormField
                name="comments"
                render={() => (
                  <FormItem>
                    <FormControl>
                      <Textarea
                        id="comments"
                        placeholder="Type your comment here."
                        disabled={isMutating}
                        aria-disabled={isMutating}
                        autoCapitalize="none"
                        autoComplete="comments"
                        autoCorrect="off"
                        {...proxyPaymentCommentsFormMethods.register(
                          "comments"
                        )}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <div className="flex justify-end gap-4 pt-4">
                {(proxyPayViewData.paymentMode === PaymentModes.PayByPhone ||
                  (proxyPayViewData.paymentMode === PaymentModes.PayByWeb &&
                    proxyPayViewData.paymentRequestPaymentMode ===
                      PaymentRequestPaymentMode.ManualPBW)) &&
                  proxyPayViewData.statusName === "Failed to Reconcile" && (
                    <AccessPolicyWrapper
                      unauthorizedFallback={null}
                      policyActions={[
                        KeycloakRoleEnum.READ_PROXYPAY_RESOLVE_PAYMENT_BTN,
                      ]}
                    >
                      <Button
                        className="mb-2"
                        aria-label="Resolve Payment"
                        type="button"
                        size="xs"
                        variant="secondary"
                        aria-disabled={isMutating}
                        disabled={
                          !proxyPaymentCommentsFormMethods.formState.isValid ||
                          isMutating
                        }
                        onClick={proxyPaymentCommentsFormMethods.handleSubmit(
                          (data) => {
                            paymentSubmit({
                              ...data,
                              paymentStatus: "PAID",
                            });
                          }
                        )}
                      >
                        {isMutating ? (
                          <Spinner size="xs" />
                        ) : (
                          <LucideBadgeCheck className="mr-2 size-4" />
                        )}
                        Resolve Payment
                      </Button>
                    </AccessPolicyWrapper>
                  )}

                {(proxyPayViewData.statusName === "In Progress Payment" &&
                  proxyPayViewData.paymentMode === PaymentModes.PayByPhone) ||
                (proxyPayViewData.paymentMode === PaymentModes.PayByWeb &&
                  proxyPayViewData.paymentRequestPaymentMode ===
                    PaymentRequestPaymentMode.ManualPBW &&
                  proxyPayViewData.statusName === "In Progress Payment") ? (
                  <>
                    <Button
                      className="mb-2"
                      aria-label="Payment submitted"
                      type="button"
                      size="xs"
                      variant="secondary"
                      aria-disabled={isMutating}
                      disabled={
                        !proxyPaymentCommentsFormMethods.formState.isValid ||
                        isMutating
                      }
                      onClick={proxyPaymentCommentsFormMethods.handleSubmit(
                        (data) => {
                          paymentSubmit({
                            ...data,
                            paymentStatus: "PAID",
                          });
                        }
                      )}
                    >
                      {isMutating ? (
                        <Spinner size="xs" />
                      ) : (
                        <LucideBadgeCheck className="mr-2 size-4" />
                      )}
                      Payment Submitted
                    </Button>

                    <Button
                      className="mb-2"
                      aria-label="Payment Failed"
                      type="button"
                      size="xs"
                      variant="destructive"
                      aria-disabled={isMutating}
                      disabled={
                        !proxyPaymentCommentsFormMethods.formState.isValid ||
                        isMutating
                      }
                      onClick={proxyPaymentCommentsFormMethods.handleSubmit(
                        (data) => {
                          paymentSubmit({
                            ...data,
                            paymentStatus: "FAILED",
                          });
                        }
                      )}
                    >
                      {isMutating ? (
                        <Spinner size="xs" />
                      ) : (
                        <LucideBadgeX className="mr-2 size-4" />
                      )}
                      Failed Payment
                    </Button>
                  </>
                ) : null}

                <AccessPolicyWrapper
                  unauthorizedFallback={null}
                  policyActions={[
                    KeycloakRoleEnum.CREATE_ADD_PROXY_PAYMENT_COMMENT,
                  ]}
                >
                  <Button
                    type="submit"
                    aria-disabled={isMutating}
                    variant="default"
                    size="sm"
                    className="gap-2"
                    disabled={
                      !proxyPaymentCommentsFormMethods.formState.isValid ||
                      isMutating
                    }
                  >
                    {isMutating ? (
                      <Spinner size="xs" />
                    ) : (
                      <MessageSquarePlusIcon className="size-4" />
                    )}
                    Add comment
                  </Button>
                </AccessPolicyWrapper>
              </div>

              {/* Assign to imREmit User */}
              {proxyPayViewData.customerStatus != CustomerStatus.Paid &&
              failedCounts >= 3 &&
              (userRoleDisplay === KeycloakRoles.SupplierEnablement ||
                userRoleDisplay === KeycloakRoles.EPayablesUser ||
                userRoleDisplay === KeycloakRoles.SupportCsr ||
                userRoleDisplay === KeycloakRoles.ProgramManager) ? (
                <>
                  <Separator className="my-4" />
                  <ProxyPayAssignUser
                    paymentDetailId={proxyPayViewData.paymentDetailId}
                  />
                </>
              ) : (
                ""
              )}
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

/**
 * @type {React.FC<ProxyPayCommentsProps>}
 */
const ProxypayComments: React.FC<ProxyPayCommentsProps> = ({
  proxyPayComments,
}) => (
  <TableRow>
    <TableCell className="font-medium">
      {format(new Date(proxyPayComments.dateTime), "MMMM dd, yyyy kk:mm:ss")}
    </TableCell>
    <TableCell>
      {<GetKeycloakUserName userId={proxyPayComments.userId} />}
    </TableCell>
    <TableCell>
      {proxyPayComments.comments.length > 40 ? (
        <CommentsHover comments={proxyPayComments.comments} />
      ) : (
        proxyPayComments.comments
      )}
    </TableCell>
  </TableRow>
);

interface CommentsHoverProps {
  comments: string;
}
/**
 * @type {React.FC<CommentsHoverProps>}
 */
const CommentsHover: React.FC<CommentsHoverProps> = ({comments}) => (
  <HoverCard>
    <HoverCardTrigger asChild>
      <Button variant="link">{comments.slice(0, 40)}...</Button>
    </HoverCardTrigger>
    <HoverCardContent className="h-[150px] w-[300px] overflow-scroll">
      {comments}
    </HoverCardContent>
  </HoverCard>
);
