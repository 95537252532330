import {useCallback, useMemo} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate} from "@tanstack/react-router";
import {
  ArrowRightFromLineIcon,
  ArrowRightIcon,
  DownloadCloudIcon,
  HelpCircleIcon,
  ImportIcon,
} from "lucide-react";
import {useForm, useFormContext} from "react-hook-form";
import type z from "zod";

import {Button} from "@/components/ui/button";
import {CardContent, CardHeader, CardTitle} from "@/components/ui/card";
import {Dialog, DialogContent, DialogTrigger} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {Input} from "@/components/ui/input";
import {Textarea} from "@/components/ui/textarea";
import {Heading2, Heading3, Paragraph} from "@/components/ui/typography";
import {UploadDropzone} from "@/components/ui/upload";
import {toast} from "@/components/ui/use-toast";
import {LoadingSection} from "@/components/craft/loading-section";

import {useImRemitCustomerStore} from "@/lib/stores";
import {
  AccessPolicyWrapper,
  calculateUserRoles,
  KeycloakRoleEnum,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {SelectCustomerSectionImremit} from "@/modules/customers";
import type {SupplierCommentsFormSchema} from "@/modules/imremit";
import {
  AddSupplierFormSchema,
  importSupplierFn,
  importSupplierSchema,
  saveSupplierCommentFn,
} from "@/modules/imremit";
import {addSupplierFn} from "@/modules/landing";

export interface SupplierAPIMutatingProps {
  isMutating?: boolean;
  isMandatory?: boolean;
}

/**
 * AddSupplierFormProps Interface
 *
 * @interface AddSupplierFormProps
 * @property {boolean} isMutating - Indicates whether the mutation is in progress.
 * @property {Function} onSubmit - The function to be called upon form submission.
 */
interface AddSupplierFormProps {
  isMutating: boolean;
  onSubmit: (data: z.infer<typeof AddSupplierFormSchema>) => void;
}

/**
 * AddSupplierForm Component
 *
 * This component renders the AddSupplierForm form and handles its behavior.
 *
 * @param {AddSupplierFormProps} props - Properties passed to the component
 * @returns {JSX.Element} - Rendered component
 */
function AddSupplierForm({isMutating, onSubmit}: AddSupplierFormProps) {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;
  const changedBy = parsedUserInfo?.sub || "";

  const addSupplierFormMethods = useForm<z.infer<typeof AddSupplierFormSchema>>(
    {
      defaultValues: {
        changedBy: changedBy,
        saveAndContinue: true,
        phoneNumber: null,
      },
      mode: "onChange",
      resolver: zodResolver(AddSupplierFormSchema),
    }
  );

  // Watch form fields
  const formFields = addSupplierFormMethods.watch();

  // Custom onSubmit function to handle form submission
  // For mututally exclusive fields, we need to check if either one is present
  const customOnSubmit = useCallback(() => {
    if (!formFields.supplierEmail && !formFields.phoneNumber) {
      toast({
        variant: "destructive",
        title: "Unable to add Supplier!",
        description: "Please enter either supplier email or phone number",
      });
    } else {
      onSubmit(formFields);
    }
  }, [formFields, onSubmit]);

  return (
    <>
      <section>
        <div className="mb-4 flex flex-col-reverse gap-4 md:flex-row md:justify-between">
          <div className="md:w-1/2">
            <Heading2>Add Supplier</Heading2>
          </div>
          <div className="flex justify-end space-x-2 md:w-1/2">
            <ImportScriptField />
            <Link to="/app/imremit/supplier-management">
              <Button className="gap-2" variant="secondary">
                Back to list
                <ArrowRightIcon className="size-4" />
              </Button>
            </Link>
          </div>
        </div>
        <Form {...addSupplierFormMethods}>
          <form onSubmit={addSupplierFormMethods.handleSubmit(customOnSubmit)}>
            <div className="mb-8 rounded-md border border-border bg-root p-4">
              <div className="flex items-center justify-between">
                <Heading3 className="mb-4">Supplier Details</Heading3>
                <HoverCard openDelay={150} closeDelay={300}>
                  <HoverCardTrigger asChild>
                    <Button variant="ghost">
                      <HelpCircleIcon className="text-neutral-foreground" />
                    </Button>
                  </HoverCardTrigger>
                  <HoverCardContent className="max-w-prose translate-x-[-1.5rem]">
                    <Heading3 className="mb-4 text-foreground">
                      Add Supplier Help
                    </Heading3>
                    <Paragraph>
                      The add supplier form is used to add a new supplier to the
                      system. The supplier details are mandatory fields. The
                      supplier email and phone number are mutually exclusive
                      fields. Please enter either one of them or both.
                    </Paragraph>
                  </HoverCardContent>
                </HoverCard>
              </div>
              <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
                <SupplierNameField isMutating={isMutating} />
                <SupplierNumberField isMutating={isMutating} />
                <SupplierEmailField isMutating={isMutating} />
                <PhoneNumberField isMutating={isMutating} />
                <CommentsField isMutating={isMutating} />
              </div>
              <div className="flex w-full flex-row justify-end gap-2">
                <Link to="/app/imremit/supplier-management">
                  <Button variant="secondary">Cancel</Button>
                </Link>
                <Button
                  className="gap-2 bg-success-foreground text-white hover:bg-success-foreground/80"
                  type="submit"
                >
                  <span className="sr-only">Submit form</span>
                  Save and continue
                  <ArrowRightFromLineIcon className="size-4" />
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </section>
    </>
  );
}

export function AddSupplierPage() {
  const navigate = useNavigate();
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  const {imREmitStoreCustomer} = useImRemitCustomerStore();
  const externalId = imREmitStoreCustomer?.externalId;

  const memoizedId = useMemo(() => externalId || "", [externalId]);

  // Define mutation for adding supplier comment
  const addSupplierCommentMutation = useMutation({
    mutationFn: ({
      data,
      supplierId,
    }: {
      data: z.infer<typeof SupplierCommentsFormSchema>;
      supplierId: string;
    }) => saveSupplierCommentFn(data, supplierId),
    onSuccess: (response) => {
      console.log("addSupplierCommentResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Comment added successfully",
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add comment",
      });
    },
  });

  // Define mutation for adding supplier
  const addSupplierMutation = useMutation({
    mutationFn: (data: z.infer<typeof AddSupplierFormSchema>) => {
      if (!data.supplierEmail && !data.phoneNumber) {
        toast({
          variant: "destructive",
          title: "Error!",
          description: "Please enter either supplier email or phone number",
        });
      }
      // Destructure comments and other fields
      const {comments, ...filteredData} = data;

      // Call addSupplierFn and return the response
      return addSupplierFn(filteredData, memoizedId).then((response) => ({
        ...response,
        comments,
      }));
    },

    onSuccess: (addSupplierResponse) => {
      console.log("addSupplierResponse", addSupplierResponse);
      toast({
        variant: "success",
        title: "Success!",
        description: "Supplier added successfully",
      });

      void navigate({to: "/app/imremit/supplier-management", replace: true});

      const {content} = addSupplierResponse; // Destructure content

      // Assert the type, ensuring TypeScript knows `content` has `supplierId`
      const supplierId = (content as {supplierId: number}).supplierId;

      // If comments are present, trigger the add comment mutation
      if (addSupplierResponse.comments) {
        addSupplierCommentMutation.mutate({
          data: {
            comments: addSupplierResponse.comments,
            userId: storeKeyCloakInstance?.tokenParsed?.name as string,
            role: userRoleDisplay || "",
          },
          supplierId: supplierId.toString(),
        });
      }
    },
    onError: (error) => {
      console.error("errorMessage", error);
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add supplier",
      });
    },
  });

  if (!imREmitStoreCustomer) {
    return <SelectCustomerSectionImremit />;
  }

  // Render AddSupplierForm component
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.CREATE_SUPPLIER_MANAGEMENT]}
    >
      <AddSupplierForm
        isMutating={addSupplierMutation.isPending}
        onSubmit={addSupplierMutation.mutate}
      />
    </AccessPolicyWrapper>
  );
}

function ImportScriptField() {
  const {imREmitStoreCustomer} = useImRemitCustomerStore();
  const externalId = imREmitStoreCustomer?.externalId || "";

  //import suppliers
  const importSupplierUpload = useForm<z.infer<typeof importSupplierSchema>>({
    defaultValues: {
      files: undefined,
    },
    mode: "onChange",
    resolver: zodResolver(importSupplierSchema),
  });

  const importSupplierUploadMutation = useMutation({
    mutationFn: async () => {
      const files: File[] = importSupplierUpload.getValues("files");
      const file: File = files[0];

      return importSupplierFn(externalId, file);
    },

    onSuccess: (response) => {
      console.log("importSupplierResponse", response);
      toast({
        variant: "default",
        title: "Success!",
        description: "Suppliers imported successfully",
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to import suppliers",
      });
    },
  });

  const isMutating = importSupplierUploadMutation.isPending;

  function onSubmit() {
    importSupplierUploadMutation.mutate();
  }

  const handleFileDrop = (acceptedFiles: File[]) => {
    importSupplierUpload.setValue("files", acceptedFiles);
  };

  return (
    <>
      <Form {...importSupplierUpload}>
        <form>
          <Dialog>
            <DialogTrigger asChild>
              <Button
                type="button"
                disabled={isMutating}
                aria-disabled={isMutating}
                className="action:bg-success-foreground ml-auto whitespace-nowrap bg-success-foreground hover:bg-success-foreground/70"
              >
                <span className="sr-only">Import Script</span>
                <DownloadCloudIcon className="mr-2" />
                Import Script
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[700px]">
              <CardHeader>
                <CardTitle>Import Supplier</CardTitle>
              </CardHeader>
              <CardContent className="flex flex-col">
                <Paragraph className="mb-3 text-sm">
                  The sample template provided is just a basic example of what
                  your file should look like. Reach out to
                  SecurityAdmin@iterationm.com if you have questions, or require
                  additional assistance
                </Paragraph>

                <Paragraph className="mb-3 text-sm">
                  Please note: Supplier Name, Supplier ID, Supplier Email,
                  Supplier Phone are mandatory fields.
                </Paragraph>
                <Paragraph className="mb-3 text-sm">
                  Select your .CSV, XLS or XLSX file to import
                </Paragraph>

                <FormField
                  name="files"
                  render={() => (
                    <FormItem className="mb-4">
                      <FormControl>
                        <UploadDropzone
                          data-testid="upload-dropzone"
                          maxFiles={1}
                          accept={{
                            ".csv": ["text/csv"],
                            ".xls": ["application/vnd.ms-excel"],
                            ".xlsx": [
                              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            ],
                          }}
                          onDrop={handleFileDrop}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button
                  type="button"
                  // disabled={
                  //   isMutating ||
                  //   importSupplierUpload.getValues().acceptedFiles.length ===
                  //     0
                  // }
                  className="gap-2 bg-success-foreground text-white hover:bg-neutral-foreground hover:text-foreground"
                  onClick={() => importSupplierUpload.handleSubmit(onSubmit)()}
                >
                  Import suppliers
                  {isMutating ? (
                    <LoadingSection sectionSize="xs" />
                  ) : (
                    <ImportIcon className="size-4" />
                  )}
                </Button>
              </CardContent>
            </DialogContent>
          </Dialog>
        </form>
      </Form>
    </>
  );
}

function SupplierNameField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="supplierName"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="supplierName" showMandatoryAsterisk>
            Supplier Name:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the supplier name..."
              {...register("supplierName")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function SupplierNumberField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="supplierNumber"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="supplierNumber" showMandatoryAsterisk>
            Supplier Number:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the supplier Number..."
              {...register("supplierNumber")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function SupplierEmailField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="supplierEmail"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="supplierEmail" showMandatoryAsterisk>
            Supplier Email:
          </FormLabel>
          <FormControl>
            <Input
              type="email"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the supplier email..."
              {...register("supplierEmail")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
function PhoneNumberField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="phoneNumber"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="phoneNumber">Supplier Phone Number:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the supplier phone number..."
              {...register("phoneNumber", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CommentsField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="comments"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="phoneNumber">Add a comment:</FormLabel>
          <FormControl>
            <Textarea
              id="comments"
              placeholder="Type your comment here."
              disabled={isMutating}
              aria-disabled={isMutating}
              autoCapitalize="none"
              autoComplete="comments"
              autoCorrect="off"
              {...register("comments")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
