/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import {useMemo} from "react";

import {CheckIcon, ChevronsUpDownIcon} from "lucide-react";
import {useFormContext} from "react-hook-form";

import {Button} from "@/components/ui/button";
import {Checkbox} from "@/components/ui/checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Heading3} from "@/components/ui/typography";
import {PasswordInput} from "@/components/craft/password-input";

// import {PasswordInput} from "@/components/craft/password-input";

import {cn} from "@/lib/utils";
import {useAllRealmGroups, useGetUsersByRoleGroup} from "@/modules/admin";

// import {useAllUsers} from "@/modules/admin";

import {
  countrySelectOptions,
  KeycloakRolesWithHyphen,
  provinceOptionsCanada,
  stateOptionsUSA,
} from "@/utils/constants";

export function GenericCustomerForm({
  isMutating,
  methodType,
}: {
  isMutating: boolean;
  methodType: string;
}) {
  const {watch} = useFormContext();

  return (
    <>
      <section className="flex flex-col gap-y-4">
        <Heading3>Customer Details</Heading3>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
          <CustomerNameField isMutating={isMutating} />
          {methodType === "PUT" ? <ExternalCustomerIDField /> : ""}
          <AddressOneField isMutating={isMutating} />
          <AddressTwoField isMutating={isMutating} />
          <AddressThreeField isMutating={isMutating} />
          <ZipCodeField isMutating={isMutating} />
          <EnableZeroDollarPaymentField isMutating={isMutating} />
          <AccountNumberMaskingField isMutating={isMutating} />
          <AccountNumberMappingField isMutating={isMutating} />
          <BuyerEpayMailField isMutating={isMutating} />

          {watch("customerProfile.buyerZeroDollarFlag") && (
            <>
              <CustomerEpayMailPasswordField isMutating={isMutating} />
            </>
          )}
          <EpayManagerAssignedField isMutating={isMutating} />
          <CustomerProfileAssociationField isMutating={isMutating} />
          <BillingTypeField isMutating={isMutating} />
          <ConnectivityMethodField isMutating={isMutating} />
          <CountryField isMutating={isMutating} />
          <StateField isMutating={isMutating} />
          <CityField isMutating={isMutating} />
          <EmailField isMutating={isMutating} />
          <MccTemplateOptionsField isMutating={isMutating} />
          <PaymentMethodField isMutating={isMutating} />
          <PhoneField isMutating={isMutating} />
          <ReportingToolField isMutating={isMutating} />
          <SettlementTermsField isMutating={isMutating} />
          <BillField isMutating={isMutating} />
          <CompanyNameField isMutating={isMutating} />
          <ContactNameField isMutating={isMutating} />
          <CycleDateField isMutating={isMutating} />
          <InvoiceDayField isMutating={isMutating} />
          <CustomerProfileOptionsField isMutating={isMutating} />
          <CustomerPaymentTypeField isMutating={isMutating} />
          <CompanyTypeField isMutating={isMutating} />
          <ResponseFileDataMaskingField isMutating={isMutating} />
          <MultiSelectPIDMappingField isMutating={isMutating} />
          <PIDMappingField isMutating={isMutating} />
        </div>
      </section>
    </>
  );
}

function CustomerNameField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="buyerName"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerName" showMandatoryAsterisk>
            Customer name:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the customer name..."
              {...register("buyerName")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ExternalCustomerIDField() {
  const {register} = useFormContext();
  return (
    <FormField
      name="externalId"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="externalId" showMandatoryAsterisk>
            External ID:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              placeholder="Enter the external ID..."
              disabled
              {...register("externalId")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function StateField({isMutating}: {isMutating: boolean}) {
  const {setValue, watch, control} = useFormContext();

  const formFields = watch();
  const buyerProfileCountry = formFields.customerProfile
    ?.buyerProfileCountry as string | undefined;

  const stateOptions =
    buyerProfileCountry === "usa"
      ? stateOptionsUSA
      : buyerProfileCountry === "ca"
      ? provinceOptionsCanada
      : [];

  // Disable the entire component when stateOptions is empty
  if (stateOptions.length === 0) {
    return (
      <FormField
        name="state"
        render={() => (
          <FormItem className="mt-2 flex flex-col">
            <FormLabel htmlFor="state">State:</FormLabel>

            <Button
              variant="outline"
              disabled={isMutating || stateOptions.length === 0}
              aria-disabled={isMutating}
              className={cn("justify-between text-accent-foreground")}
            >
              {"Please select country first"}
            </Button>
          </FormItem>
        )}
      />
    );
  }

  return (
    <FormField
      control={control}
      name="customerProfile.buyerState"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="buyerState">State:</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? stateOptions.find((state) => state.value === field.value)
                        ?.label
                    : "Select State..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search states..." />
                <CommandEmpty>No states found.</CommandEmpty>
                <CommandGroup>
                  {stateOptions.map((regionOption) => (
                    <CommandItem
                      key={regionOption.value}
                      value={regionOption.label}
                      onSelect={() => {
                        setValue(
                          "customerProfile.buyerState",
                          regionOption.value
                        );
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          regionOption.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {regionOption.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CityField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="customerProfile.buyerCity"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerProfile.buyerCity">City:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the city name..."
              {...register("customerProfile.buyerCity")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function AddressOneField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="customerProfile.buyerProfileAddress1"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerProfile.buyerProfileAddress1">
            Address one:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the address one..."
              {...register("customerProfile.buyerProfileAddress1")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function AddressTwoField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="customerProfile.buyerProfileAddress2"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerProfile.buyerProfileAddress2">
            Address two:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the address two..."
              {...register("customerProfile.buyerProfileAddress2")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function AddressThreeField({isMutating}: {isMutating: boolean}) {
  const {register, setValue} = useFormContext();
  return (
    <FormField
      name="customerProfile.buyerProfileAddress3"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerProfile.buyerProfileAddress3">
            Address three:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the address three..."
              {...register("customerProfile.buyerProfileAddress3")}
              onChange={(e) => {
                setValue(
                  "customerProfile.buyerProfileAddress3",
                  e.target.value
                );
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ZipCodeField({isMutating}: {isMutating: boolean}) {
  const {register, watch} = useFormContext();

  const formFields = watch();
  const buyerProfileCountry = formFields.customerProfile
    ?.buyerProfileCountry as string | undefined;

  const currentLabel =
    buyerProfileCountry === "usa"
      ? "Zip code:"
      : buyerProfileCountry === "ca"
      ? "Postal code:"
      : "Zip code:";

  return (
    <FormField
      name="customerProfile.buyerProfileZip"
      render={() => (
        <FormItem>
          <FormLabel
            htmlFor="customerProfile.buyerProfileZip"
            showMandatoryAsterisk
          >
            {currentLabel}
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the zip code..."
              {...register("customerProfile.buyerProfileZip")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function EnableZeroDollarPaymentField({isMutating}: {isMutating: boolean}) {
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="customerProfile.buyerZeroDollarFlag"
      render={({field}) => (
        <FormItem className="flex flex-col gap-y-2">
          <FormLabel htmlFor="customerProfile.buyerZeroDollarFlag">
            Enable zero dollar payment:
          </FormLabel>
          <FormControl>
            <Checkbox
              id="customerProfile.buyerZeroDollarFlag"
              checked={field.value as boolean}
              aria-disabled={isMutating}
              disabled={isMutating}
              onCheckedChange={(value) => {
                field.onChange(value);
                setValue("customerProfile.buyerZeroDollarFlag", value);
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function AccountNumberMaskingField({isMutating}: {isMutating: boolean}) {
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="customerProfile.buyerProfileAccountNumberMasking"
      render={({field}) => (
        <FormItem className="flex flex-col gap-y-2">
          <FormLabel htmlFor="customerProfile.buyerProfileAccountNumberMasking">
            Enable account number masking:
          </FormLabel>
          <FormControl>
            <Checkbox
              id="customerProfile.buyerProfileAccountNumberMasking"
              checked={field.value as boolean}
              aria-disabled={isMutating}
              disabled={isMutating}
              onCheckedChange={(value) => {
                field.onChange(value);
                setValue(
                  "customerProfile.buyerProfileAccountNumberMasking",
                  value
                );
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function AccountNumberMappingField({isMutating}: {isMutating: boolean}) {
  const {control, setValue} = useFormContext();
  return (
    <FormField
      control={control}
      name="customerProfile.buyerProfileAccountNumberMapping"
      render={({field}) => (
        <FormItem className="flex flex-col gap-y-2">
          <FormLabel htmlFor="customerProfile.buyerProfileAccountNumberMapping">
            Enable account number mapping:
          </FormLabel>
          <FormControl>
            <Checkbox
              id="customerProfile.buyerProfileAccountNumberMapping"
              checked={field.value as boolean}
              aria-disabled={isMutating}
              disabled={isMutating}
              onCheckedChange={(value) => {
                field.onChange(value);
                setValue(
                  "customerProfile.buyerProfileAccountNumberMapping",
                  value
                );
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function BuyerEpayMailField({isMutating}: {isMutating: boolean}) {
  const {register, setValue, watch} = useFormContext();
  return (
    <FormField
      name="customerProfile.buyerEpayMail"
      render={() => (
        <FormItem>
          <FormLabel
            htmlFor="buyerEpayMail"
            showMandatoryAsterisk={
              watch("customerProfile.buyerZeroDollarFlag") as boolean
            }
          >
            Buyer Epay Mail:
          </FormLabel>

          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the buyer e-pay email..."
              {...register("customerProfile.buyerEpayMail")}
              onChange={(e) => {
                setValue("customerProfile.buyerEpayMail", e.target.value);
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CustomerEpayMailPasswordField({isMutating}: {isMutating: boolean}) {
  const {register, setValue} = useFormContext();
  return (
    <FormField
      name="customerProfile.buyerEpayMailPassword"
      render={() => (
        <FormItem>
          <FormLabel
            htmlFor="customerProfile.buyerEpayMailPassword"
            showMandatoryAsterisk
          >
            Supplier communication password:
          </FormLabel>
          <FormControl>
            <PasswordInput
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the supplier communication password..."
              {...register("customerProfile.buyerEpayMailPassword")}
              onChange={(e) => {
                setValue(
                  "customerProfile.buyerEpayMailPassword",
                  e.target.value
                );
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function EpayManagerAssignedField({isMutating}: {isMutating: boolean}) {
  const {control, setValue} = useFormContext();

  // Get the group id
  const realmGroupsList = useAllRealmGroups();
  const getImRemitManagerId =
    realmGroupsList.data?.find(
      ({name}) => name === KeycloakRolesWithHyphen.ProgramManager
    )?.id || "";

  /**
   * Get ImRemit User List
   * Role Group Id for Program Manager
   */
  const imRemitUserListQuery = useGetUsersByRoleGroup(getImRemitManagerId);
  const epayManagerAssigned = imRemitUserListQuery.data;

  // Format the users data to match the format needed by.
  const usersData = useMemo(() => {
    if (!epayManagerAssigned) return [];
    return epayManagerAssigned.map((users) => ({
      label: `${users.firstName} ${users.lastName}`,
      value: users.id,
    }));
  }, [epayManagerAssigned]);

  return (
    <FormField
      control={control}
      name="customerProfile.buyerEpayManager"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="customerProfile.buyerEpayManager">
            Program Manager Assigned:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? usersData.find(
                        (option) => option.value.toLowerCase() === field.value
                      )?.label
                    : "Select Program Manager..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command
                filter={(value, search) => {
                  const sanitizedSearch = search.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    "\\$&"
                  );

                  const searchRegex = new RegExp(sanitizedSearch, "i");

                  const userLabel =
                    usersData.find((user) => user.value === value)?.label || "";

                  return searchRegex.test(userLabel) ? 1 : 0;
                }}
              >
                <CommandInput placeholder="Search Program Manager..." />
                <CommandEmpty>No Program Managers found.</CommandEmpty>

                <CommandGroup>
                  {usersData.map((option) => (
                    <CommandItem
                      key={option.value}
                      value={option.value}
                      onSelect={(value) => {
                        setValue("epayManagerAssigned", value);
                        setValue("customerProfile.buyerEpayManager", value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          option.value.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {option.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CustomerProfileAssociationField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();

  return (
    <FormField
      name="customerProfile.buyerProfileAssociation"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerProfile.buyerProfileAssociation">
            Customer profile association:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the customer profile association..."
              {...register("customerProfile.buyerProfileAssociation")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function BillingTypeField({isMutating}: {isMutating: boolean}) {
  const {control, setValue} = useFormContext();

  const billingTypes = [
    {
      label: "DAILY",
      value: "daily",
    },

    {
      label: "WEEKLY",
      value: "weekly",
    },
    {
      label: "MONTHLY",
      value: "monthly",
    },
    {
      label: "QUARTERLY",
      value: "quarterly",
    },
    {
      label: "YEARLY",
      value: "yearly",
    },
    {
      label: "OTHER",
      value: "other",
    },
  ];

  return (
    <FormField
      control={control}
      name="customerProfile.buyerProfileBillingType"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="customerProfile.buyerProfileBillingType">
            Customer billing type:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  role="combobox"
                  className={cn(
                    "justify-between",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? billingTypes.find(
                        (option) =>
                          option.value.toLowerCase() ===
                          (field.value as string).toLowerCase()
                      )?.label
                    : "Select Billing Type..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search billing type..." />
                <CommandEmpty>No billing types found.</CommandEmpty>
                <CommandGroup>
                  {billingTypes.map((option) => (
                    <CommandItem
                      key={option.value}
                      value={option.value}
                      onSelect={(value) => {
                        setValue(
                          "customerProfile.buyerProfileBillingType",
                          value
                        );
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          option.value.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {option.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ConnectivityMethodField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="customerProfile.buyerProfileConnectivityMethod"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerProfile.buyerProfileConnectivityMethod">
            Connectivity method name:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the connectivity method name..."
              {...register("customerProfile.buyerProfileConnectivityMethod")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CountryField({isMutating}: {isMutating: boolean}) {
  const {control, setValue} = useFormContext();

  return (
    <FormField
      control={control}
      name="customerProfile.buyerProfileCountry"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel
            htmlFor="customerProfile.buyerProfileCountry"
            showMandatoryAsterisk
          >
            Country:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  role="combobox"
                  className={cn(
                    "justify-between",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? countrySelectOptions.find(
                        (countrySelectOption) =>
                          (
                            countrySelectOption.value as string
                          ).toLowerCase() ===
                          (field.value as string).toLowerCase()
                      )?.label
                    : "Select Country..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search countries..." />
                <CommandEmpty>No countries found.</CommandEmpty>
                <CommandGroup>
                  {countrySelectOptions.map((regionOption) => (
                    <CommandItem
                      key={regionOption.value}
                      value={regionOption.value}
                      onSelect={(value) => {
                        setValue("customerProfile.buyerProfileCountry", value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          regionOption.value.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {regionOption.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function EmailField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="customerProfile.buyerProfileEmail"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerProfile.buyerProfileEmail">
            Customer email:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the customer email..."
              {...register("customerProfile.buyerProfileEmail")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function MccTemplateOptionsField({isMutating}: {isMutating: boolean}) {
  const {register, setValue} = useFormContext();
  return (
    <FormField
      name="customerProfile.buyerProfileMccTemplate"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerProfile.buyerProfileMccTemplate">
            MCC template:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the MCC template..."
              {...register("customerProfile.buyerProfileMccTemplate")}
              onChange={(e) => {
                setValue("mccTemplateOptions", e.target.value);
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function PaymentMethodField({isMutating}: {isMutating: boolean}) {
  const {setValue, control} = useFormContext();
  const paymentMethods = [
    {
      label: "ACH",
      value: "ach",
    },
    {
      label: "SUA",
      value: "sua",
    },
    {
      label: "VC",
      value: "vc",
    },
  ];

  return (
    <FormField
      control={control}
      name="customerProfile.buyerProfilePaymentMethod"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="customerProfile.buyerProfilePaymentMethod">
            Customer payment method:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  role="combobox"
                  className={cn(
                    "justify-between",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? paymentMethods.find(
                        (option) =>
                          option.value.toLowerCase() ===
                          (field.value as string).toLowerCase()
                      )?.label
                    : "Select Payment Method..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search payment method..." />
                <CommandEmpty>No payment methods found.</CommandEmpty>
                <CommandGroup>
                  {paymentMethods.map((option) => (
                    <CommandItem
                      key={option.value}
                      value={option.value}
                      onSelect={(value) => {
                        setValue(
                          "customerProfile.buyerProfilePaymentMethod",
                          value
                        );
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          option.value.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {option.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function PhoneField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="customerProfile.buyerProfilePhone"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerProfile.buyerProfilePhone">
            Customer phone:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the customer phone..."
              {...register("customerProfile.buyerProfilePhone")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ReportingToolField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="customerProfile.buyerProfileReportingTool"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerProfile.buyerProfileReportingTool">
            Reporting tool:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the reporting tool..."
              {...register("customerProfile.buyerProfileReportingTool")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function SettlementTermsField({isMutating}: {isMutating: boolean}) {
  const {setValue, control} = useFormContext();
  const settlementTerms = [
    {
      label: "7 days",
      value: "7days",
    },
    {
      label: "10 days",
      value: "10days",
    },
    {
      label: "15 days",
      value: "15days",
    },
    {
      label: "20 days",
      value: "20days",
    },
    {
      label: "30 days",
      value: "30days",
    },
    {
      label: "45 days",
      value: "45days",
    },
    {
      label: "60 days",
      value: "60days",
    },
    {
      label: "90 days",
      value: "90days",
    },
    {
      label: "OTHER",
      value: "other",
    },
  ];
  return (
    <FormField
      control={control}
      name="customerProfile.buyerProfileSettlementTerms"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="customerProfile.buyerProfileSettlementTerms">
            Settlement terms:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  role="combobox"
                  className={cn(
                    "justify-between",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? settlementTerms.find(
                        (option) =>
                          option.value.toLowerCase() ===
                          (field.value as string).toLowerCase()
                      )?.label
                    : "Select Settlement Term..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search settlement terms..." />
                <CommandEmpty>No settlement terms found.</CommandEmpty>
                <CommandGroup>
                  {settlementTerms.map((option) => (
                    <CommandItem
                      key={option.value}
                      value={option.value}
                      onSelect={(value) => {
                        setValue(
                          "customerProfile.buyerProfileSettlementTerms",
                          value
                        );
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          option.value.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {option.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function BillField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="customerProfile.buyerProfileBill"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerProfile.buyerProfileBill">
            Customer profile bill:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the customer profile bill..."
              {...register("customerProfile.buyerProfileBill")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
function CompanyNameField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="customerProfile.buyerProfileCompanyName"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerProfile.buyerProfileCompanyName">
            Company name:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the company name..."
              {...register("customerProfile.buyerProfileCompanyName")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ContactNameField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="customerProfile.buyerProfileContactName"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerProfile.buyerProfileContactName">
            Company contact name:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the company contact name..."
              {...register("customerProfile.buyerProfileContactName")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CycleDateField({isMutating}: {isMutating: boolean}) {
  const {setValue, control} = useFormContext();

  const cycleDates = [
    {
      label: "DAILY",
      value: "daily",
    },
    {
      label: "LAST THURSDAY OF THE MONTH",
      value: "lastThursdayOfTheMonth",
    },
    {
      label: "LAST FRIDAY OF THE MONTH",
      value: "lastFridayOfTheMonth",
    },
    {
      label: "LAST WEDNESDAY OF THE MONTH",
      value: "lastWednesdayOfTheMonth",
    },
    {
      label: "OTHER",
      value: "other",
    },
  ];

  return (
    <FormField
      control={control}
      name="customerProfile.buyerProfileCycleDate"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="customerProfile.buyerProfileCycleDate">
            Cycle date:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  role="combobox"
                  className={cn(
                    "justify-between",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? cycleDates.find(
                        (option) =>
                          option.value.toLowerCase() ===
                          (field.value as string).toLowerCase()
                      )?.label
                    : "Select Cycle Date..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search cycle dates..." />
                <CommandEmpty>No cycle dates found.</CommandEmpty>
                <CommandGroup>
                  {cycleDates.map((option) => (
                    <CommandItem
                      key={option.value}
                      value={option.value}
                      onSelect={(value) => {
                        setValue(
                          "customerProfile.buyerProfileCycleDate",
                          option.value.toLowerCase() === value
                            ? option.value
                            : ""
                        );
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          option.value.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {option.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function InvoiceDayField({isMutating}: {isMutating: boolean}) {
  const {setValue, control} = useFormContext();

  const invoiceDays = [
    {
      label: "MONDAY",
      value: "monday",
    },
    {
      label: "TUESDAY",
      value: "tuesday",
    },
    {
      label: "WEDNESDAY",
      value: "wednesday",
    },
    {
      label: "THURSDAY",
      value: "thursday",
    },
    {
      label: "FRIDAY",
      value: "friday",
    },
    {
      label: "SATURDAY",
      value: "saturday",
    },
    {
      label: "SUNDAY",
      value: "sunday",
    },
  ];

  return (
    <FormField
      control={control}
      name="customerProfile.buyerProfileInvoiceDay"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="customerProfile.buyerProfileInvoiceDay">
            Invoice day:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  role="combobox"
                  className={cn(
                    "justify-between",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? invoiceDays.find(
                        (option) =>
                          option.value.toLowerCase() ===
                          (field.value as string).toLowerCase()
                      )?.label
                    : "Select Invoice Days..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search invoice days..." />
                <CommandEmpty>No invoice days found.</CommandEmpty>
                <CommandGroup>
                  {invoiceDays.map((option) => (
                    <CommandItem
                      key={option.value}
                      value={option.value}
                      onSelect={(value) => {
                        setValue(
                          "customerProfile.buyerProfileInvoiceDay",
                          value
                        );
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          option.value.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {option.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CustomerProfileOptionsField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="customerProfile.buyerProfileOptions"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerProfile.buyerProfileOptions">
            Customer profile options:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the customer profile options..."
              {...register("customerProfile.buyerProfileOptions")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CustomerPaymentTypeField({isMutating}: {isMutating: boolean}) {
  const {control, setValue} = useFormContext();

  const paymentTypes = [
    {
      label: "VIRTUAL CC",
      value: "virtualcc",
    },
    {
      label: "SUA",
      value: "sua",
    },
    {
      label: "SUA2",
      value: "sua2",
    },
    {
      label: "SUAVCC",
      value: "suavcc",
    },
    {
      label: "OTHER",
      value: "other",
    },
  ];

  return (
    <FormField
      control={control}
      name="customerProfile.buyerProfilePaymentType"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="customerProfile.buyerProfilePaymentType">
            Payment Type:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  role="combobox"
                  className={cn(
                    "justify-between",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? paymentTypes.find(
                        (option) =>
                          option.value.toLowerCase() ===
                          (field.value as string).toLowerCase()
                      )?.label
                    : "Select Payment Type..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search payment types..." />
                <CommandEmpty>No payment types found.</CommandEmpty>
                <CommandGroup>
                  {paymentTypes.map((option) => (
                    <CommandItem
                      key={option.value}
                      value={option.value}
                      onSelect={(value) => {
                        setValue(
                          "customerProfile.buyerProfilePaymentType",
                          value
                        );
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          option.value.toLowerCase() === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {option.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CompanyTypeField({isMutating}: {isMutating: boolean}) {
  const {register, setValue} = useFormContext();
  return (
    <FormField
      name="customerProfile.buyerProfileCompanyType"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerProfile.buyerProfileCompanyType">
            Company type:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the company type..."
              {...register("customerProfile.buyerProfileCompanyType")}
              onChange={(e) => {
                setValue("companyType", e.target.value);
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ResponseFileDataMaskingField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="customerProfile.buyerProfileResponseDataMasking"
      render={() => (
        <FormItem>
          <FormLabel
            htmlFor="customerProfile.buyerProfileResponseDataMasking"
            showMandatoryAsterisk
          >
            Response data masking:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the response data masking..."
              {...register("customerProfile.buyerProfileResponseDataMasking")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function MultiSelectPIDMappingField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="customerProfile.multiSelectPidMapping"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerProfile.multiSelectPidMapping">
            Multiselect PID mapping:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the pid Mapping..."
              {...register("customerProfile.multiSelectPidMapping")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function PIDMappingField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="customerProfile.pidMapping"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="customerProfile.pidMapping">
            PID mapping:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the pid Mapping..."
              {...register("customerProfile.pidMapping")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
