import {Badge} from "@/components/ui/badge";
import {Skeleton} from "@/components/ui/skeleton";
import {Paragraph} from "@/components/ui/typography";

import {useGetRevealCardDetails} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";

/**
 * `RevealCardDetailsProps` interface
 * @param {string} paymentDetailId - The paymentDetailId for fetching the unmasked card details.
 */
interface RevealCardDetailsProps {
  paymentDetailId?: string;
}

/**
 * Custom Hook: Multiview
 * @param {string} paymentDetailId - The paymentDetailId for fetching the unmasked card details.
 * @returns {Object} - The unmasked card details.
 */
export function RevealCardDetails({paymentDetailId}: RevealCardDetailsProps) {
  console.log("paymentDetailId:", paymentDetailId);

  //Fetch unmasked card details
  const getUnmaskedCardQuery = useGetRevealCardDetails(paymentDetailId);
  const getUnmaskedCardDate = getUnmaskedCardQuery.data?.content;

  return (
    <>
      <div className="flex flex-col">
        <Paragraph className="flex flex-col text-lg">
          <strong>Card Number: </strong>
          {getUnmaskedCardQuery.isPending ? (
            <Skeleton className="h-6 w-full" />
          ) : (
            getUnmaskedCardDate?.cardNumber ?? (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )
          )}
        </Paragraph>
        <Paragraph className="flex flex-col text-lg">
          <strong>Security Code: </strong>
          {getUnmaskedCardQuery.isPending ? (
            <Skeleton className="h-6 w-full" />
          ) : (
            getUnmaskedCardDate?.securityCode ?? (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )
          )}
        </Paragraph>
        <Paragraph className="flex flex-col text-lg">
          <strong>Expiration Date: </strong>
          {getUnmaskedCardQuery.isPending ? (
            <Skeleton className="h-6 w-full" />
          ) : (
            getUnmaskedCardDate?.expirationDate ?? (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )
          )}
        </Paragraph>
      </div>
    </>
  );
}
