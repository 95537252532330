import {UserSquare2Icon} from "lucide-react";

import {SubrouteNavigation} from "@/components/craft/subroute-navigation";

import {ProfileRoutesSchema} from "@/lib/routes/types";
import {KeycloakRoleEnum} from "@/modules/auth";

// Define an array with the data for each route button. This includes the 'to' route,
// the 'icon' to be displayed, and the 'label' for the button.
// This array holds the configuration for each SubrouteNavigationButton
// Each object in the array represents a button in the navigation menu
const routes = [
  {
    to: ProfileRoutesSchema.Values["/app/profile"],
    icon: <UserSquare2Icon className="h-10 w-10 pl-2" />,
    label: "User Profile",
    actionRole: KeycloakRoleEnum.READ_USER_MANAGEMENT,
  },
];

interface ProfileSubrouteNavigationProps {
  layout?: "horizontal" | "vertical";
}

// This component renders the SubrouteNavigation component with the routes array
// as a prop. It also passes the 'navigationAccessibleLabel' and
// 'navigationAriaLabelledby' props to the SubrouteNavigation component.
export function ProfileSubrouteNavigation(
  {layout}: ProfileSubrouteNavigationProps = {layout: "horizontal"}
) {
  return (
    <SubrouteNavigation
      routes={routes}
      layout={layout}
      navigationAccessibleLabel="Profile Subroute Navigation"
      navigationAriaLabelledby="profileSubrouteNavHeader"
    />
  );
}
