import {useState} from "react";

import {useParams} from "@tanstack/react-router";
import {type Row} from "@tanstack/react-table";
import {FileEditIcon, GripVerticalIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Dialog, DialogContent, DialogTrigger} from "@/components/ui/dialog";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Paragraph} from "@/components/ui/typography";

import {EditPaymentRunnerConfigForm} from "@/modules/admin";
import {GeneralRunnerConfigResponseSchema} from "@/modules/imremit";

/**
 * Props for RunnerConfigsRowActions component.
 * @typedef {Object} PaymentRunnerConfigRowActionsProps
 * @paymentId String - The type of data for the row.
 */
interface PaymentRunnerConfigRowActionsProps<TData> {
  row: Row<TData>;
}

/**
 * Represents the actions for a payment file row.
 * This component provides a dropdown menu for each row with various actions like edit, copy, favorite, and delete.
 * @returns {JSX.Element} The RunnerConfigsRowActions component.
 */

export function RunnerConfigsRowActions<TData>({
  row,
}: PaymentRunnerConfigRowActionsProps<TData>) {
  const {externalId} = useParams({
    from: "/app/admin/customer-management/imremit/$externalId/onboarding/runner-configuration",
  });

  console.log(externalId);

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const paymentRunnerConfigRows = GeneralRunnerConfigResponseSchema.parse(
    row.original
  );

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button className="flex h-12 w-12 gap-2 bg-theme p-0 hover:bg-neutral hover:bg-theme/90 ">
            <span className="sr-only">Open menu</span>
            <GripVerticalIcon className="size-6 bg-inherit" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          align="end"
          className="z-50 min-w-fit overflow-hidden rounded-md border bg-root/70 p-1 text-foreground shadow-md backdrop-blur-xl backdrop-brightness-150 animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
        >
          <div className="px-2 py-1.5 text-sm font-semibold">Actions</div>
          <Dialog
            open={isEditFormOpen}
            modal={true}
            onOpenChange={setIsEditFormOpen}
          >
            <DialogTrigger
              className={`w-full ${
                paymentRunnerConfigRows.batchRunnerType.toString() !== "1"
                  ? "cursor-not-allowed opacity-50" // Custom styles when disabled
                  : ""
              }`}
              disabled={
                paymentRunnerConfigRows.batchRunnerType.toString() !== "1"
              }
            >
              <div
                className={`relative flex cursor-default select-none items-center justify-between gap-4 rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:cursor-pointer hover:bg-root-foreground focus:bg-accent focus:text-accent-foreground ${
                  paymentRunnerConfigRows.batchRunnerType.toString() !== "1"
                    ? "cursor-not-allowed opacity-50" // Add custom styles for disabled state
                    : ""
                }`}
                onSelect={(e) => {
                  e.stopPropagation(); // 🛑 Prevents event propagation
                }}
              >
                <Paragraph>
                  {paymentRunnerConfigRows.batchRunnerType.toString() === "1"
                    ? "View/Update Config"
                    : "View/Update Config (Disabled)"}
                </Paragraph>
                <span className="sr-only">View/Update Config</span>
                <FileEditIcon className="size-4" />
              </div>
            </DialogTrigger>

            <DialogContent className="max-h-[300px] overflow-y-auto sm:min-w-[340px] md:max-h-[400px] md:min-w-[668px] lg:max-h-[500px] lg:min-w-[780px] xl:max-h-[724px] xl:min-w-[1080px] 2xl:max-h-[900px] 2xl:min-w-[1280px]">
              {paymentRunnerConfigRows.batchRunnerType.toString() === "1" && (
                <EditPaymentRunnerConfigForm
                  externalId={externalId}
                  paymentRunnerConfigId={paymentRunnerConfigRows.id}
                  setEditDialogOpen={setIsEditFormOpen}
                />
              )}
            </DialogContent>
          </Dialog>
        </PopoverContent>
      </Popover>
    </>
  );
}
