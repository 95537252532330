import {z} from "zod";

export const paymentManagementHistorySchema = z.object({
  changeDate: z.string().nullable().optional(),
  changeTime: z
    .array(
      z.object({
        hour: z.number(),
        minute: z.number(),
        second: z.number(),
        nano: z.number(),
      })
    )
    .nullable()
    .optional(),
  paymentDetailHistoryId: z.string().nullable().optional(),
  paymentStatusValue: z.string().nullable().optional(),
  historyInterface: z.string().nullable().optional(),
  comments: z.string().nullable().optional(),
  modifiedBy: z.string().nullable().optional(),
});

export type paymentManagementHistory = z.infer<
  typeof paymentManagementHistorySchema
>;
