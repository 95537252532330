import {z} from "zod";

export const dupesExemptionCharacterHistorySchema = z.object({
  id: z.string(),
  field_id: z.string(),
  character: z.string(),
  buyer_external_id: z.string(),
  user_id: z.string(),
  last_modified_user_id: z.string(),
  created_date: z.string(),
  modified_date: z.string(),
  type_id: z.string(),
  comments: z.string(),
});

export type DupesExemptionCharacterHistoryType = z.infer<
  typeof dupesExemptionCharacterHistorySchema
>;
