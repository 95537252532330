// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

import {type PaymentMethodNameColumnsType} from "@/modules/imremit";

// Interface defining the shape of the PaymentMethodTableColumnsStore state
// This state will consist of an array of PaymentMethodTableColumns objects
interface PaymentMethodTableColumnsStoreState {
  storePaymentMethodTableColumns: PaymentMethodNameColumnsType | null;
  setStorePaymentMethodTableColumns: (
    storePaymentMethodTableColumns: PaymentMethodNameColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for PaymentMethod table columns
export const usePaymentMethodTableColumnsStore = create(
  persist<PaymentMethodTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storePaymentMethodTableColumns: null,

      // Getter function to retrieve the current state
      getStorePaymentMethodTableColumns: () =>
        get().storePaymentMethodTableColumns,

      // Setter function to update the state
      setStorePaymentMethodTableColumns: (storePaymentMethodTableColumns) => {
        set(() => ({storePaymentMethodTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "payment-method-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const usePaymentMethodTableColumns = () =>
  usePaymentMethodTableColumnsStore((state) => {
    return {
      storePaymentMethodTableColumns: state.storePaymentMethodTableColumns,
    };
  });
