import {createRootRoute, createRoute} from "@tanstack/react-router";

import {ErrorBoundaryPage} from "@/components/layout/generic-error";
import {ModuleNotFound, PageNotFound} from "@/components/layout/not-found";

import {AuthorizedOutlet, RootOutlet} from "@/lib/config/outlets";
import {RoutesDirectory} from "@/lib/routes/directory";

export const rootRoute = createRootRoute({
  component: RootOutlet,
  errorComponent: ErrorBoundaryPage,
});

// Define the root route of the authorized routes
export const authorizedRootRoute = createRoute({
  // The path that will activate this route
  path: RoutesDirectory.APP,
  // The component that will be rendered when this route is active
  component: AuthorizedOutlet,
  // Function that returns the parent route of this route, in this case 'appRootRoute'
  getParentRoute: () => rootRoute,
});

// Define the root route of the not found routes
export const authorizedNotFoundRootRoute = createRoute({
  // all routes from /app that are not defined will be redirected to this route
  path: RoutesDirectory.CATCH_ALL,
  component: PageNotFound, // Use the dedicated not-found component
  getParentRoute: () => authorizedRootRoute,
});

export const authorizedNotFoundRoute = createRoute({
  path: RoutesDirectory.ROOT,
  component: ModuleNotFound,
  getParentRoute: () => authorizedNotFoundRootRoute,
});
