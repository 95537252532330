import {keycloakKyApiFn} from "@/lib/ky";
import type {RoleToGroupDTO} from "@/modules/admin";

export const deleteRoleToGroupFn = (
  RoleToGroupFnDTO: RoleToGroupDTO,
  groupId: string
) => {
  const realm =
    (import.meta.env.VITE_KEYCLOAK_REALM as string | undefined) ?? "dev";

  return keycloakKyApiFn(
    `admin/realms/${realm}/groups/${groupId}/role-mappings/realm`,
    "delete",
    {
      json: RoleToGroupFnDTO,
    }
  );
};
