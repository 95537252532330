import {createRoute} from "@tanstack/react-router";

import {PageNotFound} from "@/components/layout/not-found";

import {RoutesDirectory} from "@/lib/routes/directory";
import {authorizedRootRoute} from "@/lib/routes/root";
import {ProfileOutlet, ProfilePage} from "@/modules/profile";

export const profileRootRoute = createRoute({
  path: RoutesDirectory.PROFILE,
  component: ProfileOutlet,
  getParentRoute: () => authorizedRootRoute,
});

export const profileRoute = createRoute({
  path: RoutesDirectory.ROOT,
  component: ProfilePage,
  getParentRoute: () => profileRootRoute,
});

export const profileNotFoundRoute = createRoute({
  path: RoutesDirectory.CATCH_ALL,
  component: PageNotFound,
  getParentRoute: () => profileRootRoute,
});
