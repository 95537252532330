import {useMemo, useState} from "react";

import {useMutation} from "@tanstack/react-query";
import {useNavigate} from "@tanstack/react-router";
import {type Row} from "@tanstack/react-table";
import {
  CircleXIcon,
  FileEditIcon,
  FileTextIcon,
  GripVerticalIcon,
  PackageXIcon,
  TrashIcon,
} from "lucide-react";
import type {z} from "zod";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {Button} from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {Skeleton} from "@/components/ui/skeleton";
import {toast} from "@/components/ui/use-toast";

import type {CustomerFormDTO, CustomerFormSchema} from "@/modules/admin";
import {editCustomerFn} from "@/modules/admin";
import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {customerFetchSchema, useGetCustomerById} from "@/modules/customers";

import {ModuleNames} from "@/utils/constants";

interface CustomerManagementRowActionsProps<TData> {
  row: Row<TData>;
}

export function CustomerManagementRowActions<TData>({
  row,
}: CustomerManagementRowActionsProps<TData>) {
  const navigate = useNavigate();
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const parsedUserInfoRole = storeKeyCloakInstance?.tokenParsed;
  const isProgramManager = parsedUserInfoRole?.realm_access?.roles.includes(
    "name:program-manager"
  );

  let customerRows;
  try {
    customerRows = customerFetchSchema.parse(row.original);
  } catch (error) {
    console.error("Error parsing customer row data:", error);
    return null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!customerRows || !customerRows.externalId) {
    return null;
  }

  const params = {
    externalId: customerRows.externalId.toString(),
    modules: customerRows.modules ?? [],
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="flex h-12 w-12 gap-2 bg-theme p-0 hover:bg-neutral data-[state=open]:bg-secondary">
          <span className="sr-only">Open menu</span>
          <GripVerticalIcon className="size-6 bg-inherit" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" className="min-w-fit">
        <AccessPolicyWrapper
          unauthorizedFallback={null}
          policyActions={[KeycloakRoleEnum.UPDATE_CUSTOMER_MANAGEMENT]}
        >
          <DropdownMenuItem
            className="flex justify-between gap-4"
            disabled={
              isProgramManager &&
              !customerRows.modules?.some(
                (module: {moduleName: string}) =>
                  module.moduleName === ModuleNames.imremitLite
              )
            }
            onSelect={() => {
              void navigate({
                to: "/app/admin/customer-management/$externalId/edit",
                params,
                replace: true,
              });
            }}
          >
            Edit Customer Details
            <span className="sr-only">Edit Customer Details</span>
            <FileEditIcon className="size-4" />
          </DropdownMenuItem>
          <DropdownMenuSeparator />
        </AccessPolicyWrapper>

        <DropdownMenuItem
          className="flex justify-between gap-4"
          onSelect={() => {
            void navigate({
              to: "/app/admin/customer-management/$externalId/view",
              params,
              replace: true,
            });
          }}
        >
          View Customer Profile
          <span className="sr-only">View Customer Profile</span>
          <FileTextIcon className="size-4" />
        </DropdownMenuItem>

        {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
        {params.modules &&
          params.modules.map((module) => {
            const isOnboarded = module.moduleOnboarded ?? false;

            switch (module.moduleName) {
              case ModuleNames.imremit:
                return (
                  <DropdownMenuItem
                    key={module.moduleId}
                    className="flex justify-between gap-4"
                    onSelect={() => {
                      void navigate({
                        to: "/app/admin/customer-management/imremit/$externalId/onboarding/email-configuration",
                        params,
                        replace: true,
                      });
                    }}
                  >
                    {isOnboarded
                      ? "imREmit Edit Details"
                      : "imREmit Onboard Pending"}
                    <span className="sr-only">imREmit Customer</span>
                    <FileTextIcon className="size-4" />
                  </DropdownMenuItem>
                );

              case ModuleNames.duplicatePayments:
                return (
                  <DropdownMenuItem
                    key={module.moduleId}
                    className="flex justify-between gap-4"
                    onSelect={() => {
                      void navigate({
                        to: "/app/admin/customer-management/duplicate-payments/$externalId/onboarding",
                        params,
                        replace: true,
                      });
                    }}
                  >
                    {isOnboarded
                      ? "Duplicate Payments Edit Details"
                      : "Duplicate Payments Onboard Pending"}
                    <span className="sr-only">Dupes Customer</span>
                    <FileTextIcon className="size-4" />
                  </DropdownMenuItem>
                );
            }
          })}

        <AccessPolicyWrapper
          unauthorizedFallback={null}
          policyActions={[KeycloakRoleEnum.DELETE_CUSTOMER_MANAGEMENT]}
        >
          <DropdownMenuSeparator />
          {DeleteCustomer({
            externalId: customerRows.externalId.toString(),
          })}
        </AccessPolicyWrapper>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

interface DeleteCustomerProps {
  externalId: string;
}

function DeleteCustomer({externalId}: DeleteCustomerProps) {
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const storeKeyCloakInstance =
    useKeyCloakInstanceStore().storeKeyCloakInstance;
  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );

  const {
    data,
    isFetched: customerDetailsAreFetched,
    isError,
    error,
  } = useGetCustomerById(externalId);

  const deleteCustomerMutation = useMutation({
    mutationFn: (rawCustomerData: z.infer<typeof CustomerFormSchema>) => {
      return editCustomerFn(rawCustomerData);
    },

    onSuccess: (editCustomerResponse) => {
      console.log("editCustomerResponse", editCustomerResponse);

      toast({
        variant: "success",
        title: "SUCCESS!",
        description: "Customer deleted successfully",
      });
    },
    onError: (error: unknown) => {
      console.error("error", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to delete customer",
      });
    },
  });

  if (isError) {
    console.error("Error fetching customer details: ", error);
    throw new Error("Error fetching customer details: ", error);
  }

  return (
    <AlertDialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
      <AlertDialogTrigger asChild>
        <DropdownMenuItem
          className="flex justify-between gap-4 bg-destructive text-destructive-foreground hover:bg-destructive/90 hover:text-destructive-foreground/90"
          onSelect={(e) => {
            e.preventDefault();
            setDeleteDialogOpen(true);
          }}
        >
          Delete Customer
          <span className="sr-only">Delete Customer</span>
          <PackageXIcon className="size-4" />
        </DropdownMenuItem>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Are you sure you want to delete{" "}
            {customerDetailsAreFetched && data ? (
              data.content.buyerName
            ) : (
              <Skeleton className="h-6 w-24" />
            )}
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel className="gap-2">
            Cancel <CircleXIcon className="size-4" />
          </AlertDialogCancel>
          <AlertDialogAction
            className="gap-2"
            onClick={
              customerDetailsAreFetched && data
                ? () => {
                    deleteCustomerMutation.mutate({
                      ...data.content,
                      modifiedBy: parsedUserInfo?.name as string,
                      status: "deleted",
                    } as CustomerFormDTO);
                    setDeleteDialogOpen(false);
                  }
                : () => {
                    console.log("Customer details are not fetched yet.");
                  }
            }
          >
            Delete{" "}
            {customerDetailsAreFetched && data
              ? data.content.buyerName
              : "Customer"}
            <TrashIcon className="size-4" />
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
