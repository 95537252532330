import {Fragment, useEffect, useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {HelpCircleIcon, Trash2Icon} from "lucide-react";
import {useForm} from "react-hook-form";
import {z} from "zod";

import {
  AlertDialog,
  // AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {Button} from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {ScrollArea} from "@/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {Separator} from "@/components/ui/separator";
import {Skeleton} from "@/components/ui/skeleton";
import {Heading3, Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {LoadingSection} from "@/components/craft/loading-section";

import {useSavedSearchStore} from "@/lib/stores";
import {useKeyCloakInstanceStore} from "@/modules/auth";
import {deleteAllSavedSearchFn, useGetSavedSearches} from "@/modules/invoices";

/**
 * Interface for a saved search.
 */
interface SavedSearch {
  name: string;
}

/**
 * Zod schema for the saved search form.
 */
const SavedSearchFormSchema = z.object({
  savedSearch: z.string().optional(),
});

const defaultValues = {
  savedSearch: undefined,
};

/**
 * Custom hook for managing saved search related logic.
 * @returns Object containing methods and state related to saved searches.
 */
export function useSavedSearchLogic() {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  const {updateStoreSavedSearch, storeSavedSearch} = useSavedSearchStore();
  const searchQuery = useGetSavedSearches(
    storeKeyCloakInstance?.tokenParsed?.sub
  );
  const savedSearches = searchQuery.data?.content;

  return {updateStoreSavedSearch, storeSavedSearch, savedSearches};
}

/**
 * Custom hook for encapsulating form logic.
 * @returns Object containing methods for form management.
 */
function useFormLogic() {
  const form = useForm<z.infer<typeof SavedSearchFormSchema>>({
    defaultValues,
    resolver: zodResolver(SavedSearchFormSchema),
  });

  const {reset} = form;
  const savedSearchFieldValue = form.watch().savedSearch;

  /**
   * Handles form submission.
   * @param {Object} data - The form data.
   */
  function onSubmit(data: z.infer<typeof SavedSearchFormSchema>) {
    console.log("Data submitted: ", data);
  }

  return {form, reset, savedSearchFieldValue, onSubmit};
}

/**
 *
 * @returns - Return the JSX for the DeleteAllSavedSearchButton component
 */
function DeleteAllSavedSearchButton() {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const [open, setOpen] = useState(false);

  // Initialize the deleteAllSavedSearchMutation hook to delete all the saved search.
  const deleteAllSavedSearchMutation = useMutation({
    mutationFn: deleteAllSavedSearchFn,
    onSuccess: (deleteAllSavedSearchResponse) => {
      console.log("deleteAllSavedSearchResponse", deleteAllSavedSearchResponse);
      setOpen(false);
      toast({
        variant: "success",
        title: "Success!",
        description: "Saved searches deleted successfully",
      });
    },
    onError: (error) => {
      console.error("errorMessage", error);
      setOpen(false);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to delete saved searches",
      });
    },
  });

  return (
    <AlertDialog open={open} onOpenChange={setOpen}>
      <AlertDialogTrigger asChild>
        <Button className="w-full gap-2" variant="destructive">
          Delete All
          <span className="sr-only">Delete All</span>
          <Trash2Icon className="size-4" />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Are you sure you want to delete all searches ?
          </AlertDialogTitle>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            aria-disabled={deleteAllSavedSearchMutation.isPending}
            disabled={deleteAllSavedSearchMutation.isPending}
          >
            Cancel
          </AlertDialogCancel>
          <Button
            variant="destructive"
            className="flex items-center gap-2"
            aria-disabled={deleteAllSavedSearchMutation.isPending}
            disabled={deleteAllSavedSearchMutation.isPending}
            onClick={() => {
              deleteAllSavedSearchMutation.mutate(
                storeKeyCloakInstance?.tokenParsed?.sub || ""
              );
            }}
          >
            {deleteAllSavedSearchMutation.isPending ? (
              <LoadingSection sectionSize="xs" />
            ) : (
              <Trash2Icon className="size-4" />
            )}
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

/**
 * Component for rendering the saved search select dropdown.
 * @param {Object} props - Component props.
 * @param {SavedSearch[]} props.savedSearches - List of saved searches.
 * @param {Object} props.field - Form control field.
 */
function SavedSearchSelect({
  savedSearches,
  field,
}: {
  savedSearches: SavedSearch[];
  field: {value: string | undefined; onChange: (value: string) => void};
}) {
  const memoizedSavedSearches = useMemo(() => {
    if (!savedSearches.length) return null;
    return savedSearches.map((savedSearch, i) => (
      <Fragment key={i}>
        {i !== 0 && <SelectSeparator className="p-0" />}
        <SelectItem value={savedSearch.name}>{savedSearch.name}</SelectItem>
      </Fragment>
    ));
  }, [savedSearches]);

  return (
    <Select
      // defaultValue={undefined}
      value={field.value}
      onValueChange={field.onChange}
    >
      <FormControl>
        <SelectTrigger className="bg-root hover:bg-muted/70">
          <SelectValue placeholder="Choose saved search" />
        </SelectTrigger>
      </FormControl>
      <SelectContent>
        <ScrollArea className="max-h-72">
          <SelectGroup>
            {memoizedSavedSearches ? (
              memoizedSavedSearches
            ) : (
              <SelectItem value="No saved searches yet">
                No saved searches yet
              </SelectItem>
            )}
          </SelectGroup>
          <Separator />
          <SelectGroup className="mt-1">
            <DeleteAllSavedSearchButton />
          </SelectGroup>
        </ScrollArea>
      </SelectContent>
    </Select>
  );
}

/**
 * Main component for selecting saved searches.
 * @returns JSX Element.
 */
export function SelectSavedSearches() {
  const {updateStoreSavedSearch, storeSavedSearch, savedSearches} =
    useSavedSearchLogic();
  const {form, savedSearchFieldValue, onSubmit} = useFormLogic();

  // Add this effect to update form when savedSearches changes
  useEffect(() => {
    if (savedSearches?.length && storeSavedSearch?.name) {
      const exists = savedSearches.some(
        (search) => search.name === storeSavedSearch.name
      );
      if (exists) {
        form.setValue("savedSearch", storeSavedSearch.name);
      }
    }
  }, [savedSearches, storeSavedSearch, form]);

  const selectedSavedSearch = useMemo(() => {
    if (!savedSearches?.length) return null;
    return savedSearches.find(
      (savedSearch) => savedSearch.name === savedSearchFieldValue
    );
  }, [savedSearches, savedSearchFieldValue]);

  useEffect(() => {
    if (selectedSavedSearch) {
      updateStoreSavedSearch(selectedSavedSearch);
    }
  }, [updateStoreSavedSearch, selectedSavedSearch]);

  useEffect(() => {
    if (!storeSavedSearch) {
      form.setValue("savedSearch", "");
    } else {
      form.setValue("savedSearch", storeSavedSearch.name);
    }
  }, [storeSavedSearch, form]);

  if (!savedSearches) {
    return (
      <div className="grid grid-cols-1 grid-rows-2 gap-2">
        <Skeleton colour="foreground" />
        <Skeleton colour="background" />
      </div>
    );
  }

  // If there are no saved searches, render a message explaining how to save searches.
  if (savedSearches.length === 0) {
    return (
      <div className="flex items-center gap-2">
        <Paragraph className="text-xs font-semibold">
          No saved searches yet
        </Paragraph>
        <HoverCard openDelay={150} closeDelay={300}>
          <HoverCardTrigger asChild>
            <Button className="p-2" variant="ghost">
              <HelpCircleIcon className="text-theme" />
            </Button>
          </HoverCardTrigger>
          <HoverCardContent className="max-w-prose translate-x-[-1.5rem]">
            <Heading3 className="mb-4 text-foreground">Saved Searches</Heading3>
            <Paragraph>
              After making a search, you can save it by clicking the "Save
              Search" button on the search bar. This is so that you can save
              your search criteria for future use.
            </Paragraph>
          </HoverCardContent>
        </HoverCard>
      </div>
    );
  }

  return (
    <Form {...form}>
      <form className="w-48" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="savedSearch"
          render={({field}) => (
            <FormItem>
              <FormLabel htmlFor="savedSearch">Saved Searches:</FormLabel>
              <SavedSearchSelect savedSearches={savedSearches} field={field} />
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
}
