/* eslint-disable */

import {type ColumnDef} from "@tanstack/react-table";
import {z} from "zod";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {MISPaymentsReceivedType} from "@/modules/admin";

import {FallbackMessages} from "@/utils/constants";

// 💰 Formatter for currency values in USD
const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const misPaymentsReceivedTableColumnsSchema = z.object({
  year: z.boolean(),
  month: z.boolean(),
  total_amount: z.boolean(),
  ytd_total: z.boolean(),
  gross_total: z.boolean(),
});

export type misPaymentsReceivedColumnsType = z.infer<
  typeof misPaymentsReceivedTableColumnsSchema
>;

// 📊 Generates the column definitions for MIS payments received table
export function generateMISPaymentsReceivedColumns(
  months: string[],
  quarters: string[]
): ColumnDef<MISPaymentsReceivedType>[] {
  // 🛠️ Define initial columns for buyer details (excluding gross_total initially)
  const columns: ColumnDef<MISPaymentsReceivedType>[] = [
    {
      accessorKey: "buyer_external_id",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="PID" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("buyer_external_id") ? (
            <Paragraph className="font-semibold">
              {row.getValue("buyer_external_id")}
            </Paragraph>
          ) : (
            // 🚫 Show fallback badge if no data is available
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "buyer_name",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Customer Name" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("buyer_name") ? (
            <Paragraph className="font-semibold">
              {row.getValue("buyer_name")}
            </Paragraph>
          ) : (
            // 🚫 Show fallback badge if no data is available
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
  ];

  // 📅 Prepare combined array of month and quarter objects with date representations
  const combinedDates: {id: string; type: "month" | "quarter"; date: Date}[] =
    [];

  // 📆 Process each month string to create date objects
  months.forEach((monthYear) => {
    const [monthStr, yearStr] = monthYear.split(" ");
    const monthIndex = new Date(`${monthStr} 1, ${yearStr}`).getMonth();
    const year = parseInt(yearStr, 10);
    const date = new Date(year, monthIndex, 1);
    combinedDates.push({id: monthYear, type: "month", date});
  });

  // 🕒 Process each quarter string to create date objects
  quarters.forEach((quarterYear) => {
    const [quarterStr, yearStr] = quarterYear.split(" ");
    const quarterNumber = parseInt(quarterStr.replace("Q", ""), 10);
    const year = parseInt(yearStr, 10);
    const monthIndex = quarterNumber * 3; // Month index for the month after the quarter ends
    const date = new Date(year, monthIndex, 0); // Last day of the quarter
    combinedDates.push({id: quarterYear, type: "quarter", date});
  });

  // ⏳ Sort combinedDates array by date to ensure correct order of columns
  combinedDates.sort((a, b) => a.date.getTime() - b.date.getTime());

  // 🔄 Loop through sorted dates to add month and quarter columns as needed
  combinedDates.forEach((item) => {
    if (item.type === "month") {
      // 📅 Define column for each month
      const monthYear = item.id;
      columns.push({
        id: `month_${monthYear}`,
        header: ({column}) => (
          <DataTableColumnHeader column={column} title={monthYear} />
        ),
        cell: ({row}) => {
          const [month, yearStr] = monthYear.split(" ");
          const year = parseInt(yearStr, 10);

          // 🧩 Find corresponding data for this month
          const monthData = row.original.monthly_data.find(
            (data) => data.month === month && data.year === year
          );
          const totalAmount = monthData ? monthData.total_amount : null;

          return (
            <div className="w-fit">
              {totalAmount !== null ? (
                <Paragraph className="font-semibold">
                  {currencyFormatter.format(totalAmount)}
                </Paragraph>
              ) : (
                // 🚫 Show fallback badge if no data is available
                <Badge className="whitespace-nowrap" variant="destructive">
                  {FallbackMessages.NO_DATA}
                </Badge>
              )}
            </div>
          );
        },
      });
    } else if (item.type === "quarter") {
      // 🕒 Define column for each quarter
      const quarterYear = item.id;
      columns.push({
        id: `quarter_${quarterYear}`,
        header: ({column}) => (
          <DataTableColumnHeader column={column} title={quarterYear} />
        ),
        cell: ({row}) => {
          const [quarter, yearStr] = quarterYear.split(" ");
          const year = parseInt(yearStr, 10);

          // 🧩 Find corresponding data for this quarter
          const quarterData = row.original.quarterly_totals.find(
            (data) => data.quarter === quarter && data.year === year
          );

          const totalAmount = quarterData ? quarterData.quarter_total : null;

          return (
            <div className="w-fit">
              {totalAmount !== null ? (
                <Paragraph className="font-semibold">
                  {currencyFormatter.format(totalAmount)}
                </Paragraph>
              ) : (
                // 🚫 Show fallback badge if no data is available
                <Badge className="whitespace-nowrap" variant="destructive">
                  {FallbackMessages.NO_DATA}
                </Badge>
              )}
            </div>
          );
        },
      });
    }
  });

  // 💵 Add final column for gross total at the end
  columns.push({
    accessorKey: "gross_total",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Gross Total" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("gross_total") || row.getValue("gross_total") === 0 ? (
          <Paragraph className="font-semibold">
            {currencyFormatter.format(row.getValue("gross_total"))}
          </Paragraph>
        ) : (
          // 🚫 Show fallback badge if no data is available
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  });

  // 🚀 Return the fully constructed array of columns
  return columns;
}
