import {useEffect} from "react";

import {Tabs, TabsContent, TabsList, TabsTrigger} from "@/components/ui/tabs";

import {AdminRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";
import {
  MISPaymentsDifferencesPage,
  MISPaymentsPostedPage,
  MISPaymentsReceivedPage,
} from "@/modules/admin";

/**
 * 📍 Custom hook for setting the admin route in the store
 * This ensures that the current route is tracked in the store
 */
function useMISRoute() {
  const {setAdminRoute} = useRoutesStore();

  useEffect(() => {
    setAdminRoute(AdminRoutesSchema.Values["/app/admin/mis"]); // 🚦 Set the MIS admin route
  }, [setAdminRoute]); // 🔄 Only re-run when `setAdminRoute` changes
}

export function MISPaymentsPage() {
  useMISRoute();

  return (
    <Tabs defaultValue="received">
      <TabsList className="w-fit">
        <TabsTrigger value="received">Payments Received</TabsTrigger>
        <TabsTrigger value="posted">Payments Posted</TabsTrigger>
        <TabsTrigger value="differences">Percentage Growth</TabsTrigger>
      </TabsList>
      <TabsContent value="received">
        <MISPaymentsReceivedPage />
      </TabsContent>
      <TabsContent value="posted">
        <MISPaymentsPostedPage />
      </TabsContent>
      <TabsContent value="differences">
        <MISPaymentsDifferencesPage />
      </TabsContent>
    </Tabs>
  );
}
