import {useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Navigate, useParams} from "@tanstack/react-router";
import {
  BanIcon,
  EyeOffIcon,
  FileEditIcon,
  MessageSquarePlusIcon,
} from "lucide-react";
import {useForm} from "react-hook-form";
import {z} from "zod";

import {Badge} from "@/components/ui/badge";
import {Button} from "@/components/ui/button";
import {Card, CardContent, CardFooter, CardHeader} from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {Label} from "@/components/ui/label";
import {Separator} from "@/components/ui/separator";
import {Skeleton} from "@/components/ui/skeleton";
import {Spinner} from "@/components/ui/spinner";
import {Textarea} from "@/components/ui/textarea";
import {Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {cn} from "@/lib/utils";
import {
  AccessPolicyWrapper,
  calculateUserRoles,
  KeycloakRoleEnum,
  useAuthorization,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {
  blockPaymentFn,
  getStatusClass,
  MapPaymentCustomerLite,
  PaymentManagementCommentsLiteData,
  RevealCardDetails,
  statusVariants,
  useGetPaymentDetails,
  usePaymentManagementGenInfo,
  useSearchimREmits,
  type blockPaymentType,
} from "@/modules/imremit";
import {
  NewPaymentManagementLiteMultiView,
  PaymentManagementDetailsFooter,
} from "@/modules/imremit-lite";

import {FallbackMessages, KeycloakRoles} from "@/utils/constants";
import {formatCurrency} from "@/utils/functions";

export const blockPaymentSchema = z.object({
  comment: z
    .string()
    .min(1, "Please enter a comment.")
    .max(2000, "Comment must not exceed 2000 characters.")
    .refine((val) => val.trim().length > 0, {
      message: "Please ensure you have entered a comment.",
    }),
});

/**
 * Custom Hook: Return block the payment jsx.
 */
export function BlockPayment() {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  const {paymentId} = useParams({
    from: "/app/imremit-lite/payment-management/$paymentId/view/$list_type",
  });

  const blockPaymentForm = useForm<z.infer<typeof blockPaymentSchema>>({
    defaultValues: {
      comment: "",
    },
    resolver: zodResolver(blockPaymentSchema),
  });

  //Initialize tanstack query mutation for block the payment
  const blockPaymentMutation = useMutation({
    mutationFn: (blockPaymentPayload: blockPaymentType) => {
      return blockPaymentFn(blockPaymentPayload, paymentId);
    },
    onSuccess: (response) => {
      blockPaymentForm.reset();
      console.log("blockPaymentResponse", response);

      toast({
        variant: "success",
        title: "Success!",
        description: "Payment blocked successfully",
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";

      if (typeof error === "string") {
        errorMessage = error;
      }

      if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }

      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to block payment",
      });
    },
  });

  const isMutating = blockPaymentMutation.isPending;

  function onSubmit() {
    const blockPaymentPayload = {
      userId: storeKeyCloakInstance?.tokenParsed?.sub || "",
      comments: blockPaymentForm.watch("comment"),
      role:
        calculateUserRoles(
          storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
        ) || "",
    };
    blockPaymentMutation.mutate(blockPaymentPayload);
  }

  return (
    <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
      <Dialog>
        <DialogTrigger asChild>
          <Button size="sm" variant="destructive" className="w-40">
            <BanIcon className="mr-2 size-4" />
            <Paragraph>Block Payment</Paragraph>
          </Button>
        </DialogTrigger>
        <DialogContent>
          <Form {...blockPaymentForm}>
            <form
              className="space-y-2"
              onSubmit={blockPaymentForm.handleSubmit(onSubmit)}
            >
              <DialogHeader>
                <DialogTitle>Block Payment</DialogTitle>
              </DialogHeader>
              <div className="grid w-full space-y-6">
                <Label htmlFor="comment">
                  Add a Comment (2,000 Character Limit)
                </Label>
                <FormField
                  control={blockPaymentForm.control}
                  name="comment"
                  render={() => (
                    <FormItem className="flex flex-col ">
                      <FormControl>
                        <Textarea
                          placeholder="Type your comment here."
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          autoCapitalize="none"
                          aria-placeholder="Type your comment here."
                          autoComplete="comment"
                          autoCorrect="off"
                          {...blockPaymentForm.register("comment")}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <small>
                  <strong>Note:</strong> Once the payment is block, this action
                  cannot be reversed !
                </small>
              </div>
              <DialogFooter>
                <Button
                  size="xs"
                  type="submit"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                >
                  <span className="sr-only">Add comment</span>
                  {isMutating ? (
                    <Spinner size="xs" />
                  ) : (
                    <MessageSquarePlusIcon className="mr-2 size-4" />
                  )}
                  <Paragraph>Proceed to block payment</Paragraph>
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </div>
  );
}

// Defining the PaymentManagementDetails component
export function PaymentManagementDetails() {
  //Fetching logged user role
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  console.log("userRoleDisplay", userRoleDisplay);

  const {paymentId} = useParams({
    from: "/app/imremit-lite/payment-management/$paymentId/view/$list_type",
  });

  const paymentManagementGenInfoQuery = usePaymentManagementGenInfo(paymentId);
  const paymentManagementGenInfoData =
    paymentManagementGenInfoQuery.data?.content;

  const {isPending: genInfoPending} = paymentManagementGenInfoQuery;

  // Card reveal button action state
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const searchimREmitQuery = useGetPaymentDetails(paymentId);
  const searchimREmitData = searchimREmitQuery.data?.content;
  const allPaymentManagementQuery = useSearchimREmits(
    searchimREmitData?.externalId
  );
  const allPaymentManagementData = allPaymentManagementQuery.data?.content;

  const {checkPoliciesAccess} = useAuthorization();

  // Handle unauthorized access
  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_PAYMENT_MANAGEMENT_LITE])) {
    return <NotAuthorizedPage />;
  }

  const isPaymentIdInSavedPayments = allPaymentManagementData?.some(
    (item) => item.paymentDetailId === paymentId
  );

  // If paymentId is not found in allPaymentManagementData, redirect to payment list page
  if (!isPaymentIdInSavedPayments && searchimREmitQuery.isError) {
    return <Navigate to="/app/imremit-lite/payment-management" />;
  }

  return (
    <section>
      <NewPaymentManagementLiteMultiView />
      <Card>
        <CardHeader className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
          <Paragraph className="flex flex-col text-lg">
            <strong>Payment Number: </strong>
            {searchimREmitQuery.isPending ? (
              <Skeleton className="h-6 w-full" />
            ) : (
              searchimREmitData?.paymentNumber ?? (
                <Badge className="whitespace-nowrap" variant="destructive">
                  {FallbackMessages.NO_DATA}
                </Badge>
              )
            )}
          </Paragraph>
          <Paragraph className="flex flex-col text-lg">
            <strong>Account Number: </strong>
            {genInfoPending ? (
              <Skeleton className="h-6 w-full" />
            ) : (
              paymentManagementGenInfoData?.paymentRequestId ?? (
                <Badge className="whitespace-nowrap" variant="destructive">
                  {FallbackMessages.NO_DATA}
                </Badge>
              )
            )}
          </Paragraph>
          <Paragraph className="flex flex-col text-lg">
            <strong>Status: </strong>
            {searchimREmitQuery.isPending ? (
              <Skeleton className="h-6 w-full" />
            ) : searchimREmitData?.customerStatus === "Failed" &&
              (userRoleDisplay === KeycloakRoles.CustomerAdmin ||
                userRoleDisplay === KeycloakRoles.CustomerUser ||
                userRoleDisplay === "Facility User" ||
                userRoleDisplay === "ViewOnly User") ? (
              "In Progress"
            ) : searchimREmitData?.customerStatus ? (
              <span
                className={cn(
                  statusVariants({
                    statusColour: getStatusClass(
                      searchimREmitData.customerStatus
                    ),
                  })
                )}
              >
                {searchimREmitData.customerStatus}
              </span>
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>

          <div className="flex w-full justify-end gap-4">
            <PaymentManagementCommentsLiteData
              id={searchimREmitData?.paymentDetailId.toString()}
            />
            <AccessPolicyWrapper
              unauthorizedFallback={null}
              policyActions={[KeycloakRoleEnum.UPDATE_MOVE_PAYMENT]}
            >
              <MapPaymentCustomerLite
                payment_detail_id={searchimREmitData?.paymentDetailId.toString()}
              />
            </AccessPolicyWrapper>
            {(searchimREmitData?.customerStatus !== "Closed" ||
              searchimREmitData.description?.trim() ===
                "Received the recon with partial amount the payment is still open.") &&
              searchimREmitData?.customerStatus != "Blocked" &&
              searchimREmitData?.customerStatus != "Delivered" &&
              searchimREmitData?.customerStatus != "Open" &&
              searchimREmitData?.customerStatus != "In Progress" &&
              searchimREmitData?.customerStatus != "Re-Opened" && (
                <AccessPolicyWrapper
                  policyActions={[KeycloakRoleEnum.UPDATE_PAYMENT_BLOCK_LITE]}
                  unauthorizedFallback={null}
                >
                  <BlockPayment />
                </AccessPolicyWrapper>
              )}
            {(searchimREmitData?.customerStatus != "Closed" &&
              searchimREmitData?.customerStatus != "Delivered" &&
              searchimREmitData?.customerStatus != "Blocked" &&
              searchimREmitData?.isEditable) ||
              (searchimREmitData?.statusName == "Partially Paid" &&
                searchimREmitData.isEditable && (
                  <Button size="sm">
                    <FileEditIcon className="mr-2 size-4" />
                    <Paragraph>Edit</Paragraph>
                  </Button>
                ))}
          </div>
        </CardHeader>
        <Separator className="mb-2" />
        <CardContent>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
            <div className="grid grid-cols-1">
              <Paragraph className="flex flex-col text-lg">
                <strong>Amount: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : searchimREmitData &&
                  searchimREmitData.totalAmountSent !== null ? (
                  formatCurrency(searchimREmitData.totalAmountSent)
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )}
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Outstanding Balance: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : searchimREmitData &&
                  searchimREmitData.balanceAmount !== null ? (
                  formatCurrency(searchimREmitData.balanceAmount)
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )}
                <small>Transactions may take upto 3 days to post.</small>
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Amount Taken: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : searchimREmitData &&
                  searchimREmitData.amountTaken !== null ? (
                  formatCurrency(searchimREmitData.amountTaken)
                ) : (
                  <Badge variant="destructive">
                    {FallbackMessages.NO_DATA}
                  </Badge>
                )}
              </Paragraph>
            </div>

            <div className="grid grid-cols-1">
              <Paragraph className="flex flex-col text-lg">
                <strong>Authorization Type: </strong>
                {searchimREmitData?.singleTranExactAuth != null
                  ? searchimREmitData.singleTranExactAuth
                    ? "Single Authorization"
                    : "Multi Authorization"
                  : ""}
              </Paragraph>
              {searchimREmitData &&
              searchimREmitData.customerStatus !==
                "Paid - Pending Verification" &&
              // searchimREmitData.remittanceType == "Pay By Web" &&
              checkPoliciesAccess([KeycloakRoleEnum.READ_CARD_DETAILS_LITE]) ? (
                <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                  {isButtonClicked ? (
                    <RevealCardDetails
                      paymentDetailId={searchimREmitData.paymentDetailId}
                    />
                  ) : (
                    <Button
                      className="gap-2"
                      size="sm"
                      variant="outline"
                      onClick={() => {
                        setIsButtonClicked(true);
                      }}
                    >
                      <EyeOffIcon className="size-4" />
                      <Paragraph>Reveal Card Details</Paragraph>
                    </Button>
                  )}
                </div>
              ) : (
                <Paragraph className="flex flex-col text-lg">
                  <strong>Card Number: </strong>
                  {searchimREmitQuery.isPending ? (
                    <Skeleton className="h-6 w-full" />
                  ) : (
                    searchimREmitData?.cardNumber ?? (
                      <Badge variant="destructive">
                        {FallbackMessages.NO_DATA}
                      </Badge>
                    )
                  )}
                </Paragraph>
              )}

              {!isButtonClicked && (
                <>
                  <Paragraph className="flex flex-col text-lg">
                    <strong>Security Code: </strong>
                    {searchimREmitQuery.isPending ? (
                      <Skeleton className="h-6 w-full" />
                    ) : (
                      searchimREmitData?.securityCode ?? (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )
                    )}
                  </Paragraph>
                  <Paragraph className="flex flex-col text-lg">
                    <strong>Expiration Date: </strong>
                    {searchimREmitQuery.isPending ? (
                      <Skeleton className="h-6 w-full" />
                    ) : (
                      searchimREmitData?.expirationDate ?? (
                        <Badge variant="destructive">
                          {FallbackMessages.NO_DATA}
                        </Badge>
                      )
                    )}
                  </Paragraph>
                </>
              )}

              <Paragraph className="flex flex-col text-lg">
                <strong>Payment Zip Code / Postal Code : </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.paymentZip ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
            </div>

            <div className="grid grid-cols-1">
              <Paragraph className="flex flex-col text-lg">
                <strong>Bank: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.providerName ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>

              <Paragraph className="flex flex-col text-lg">
                <strong>Sent Date: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.initiatedDate ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>

              <Paragraph className="flex flex-col text-lg">
                <strong>Payment End Date: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.endDate ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>

              <Paragraph className="flex flex-col text-lg">
                <strong>Payment Method:</strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.methodName ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
            </div>
            <div className="grid grid-cols-1">
              <Paragraph className="flex flex-col text-lg">
                <strong>Supplier: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.supplierName ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Supplier Number: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.supplierNumber ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Supplier ID: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.supplierId ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
              <Paragraph className="flex flex-col text-lg">
                <strong>Payee Name: </strong>
                {searchimREmitQuery.isPending ? (
                  <Skeleton className="h-6 w-full" />
                ) : (
                  searchimREmitData?.facilityName ?? (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )
                )}
              </Paragraph>
            </div>
          </div>
        </CardContent>
        <Separator className="mb-4" />
        <CardFooter>
          <PaymentManagementDetailsFooter
            id={searchimREmitData?.paymentDetailId}
          />
        </CardFooter>
      </Card>
    </section>
  );
}
