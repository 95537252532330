import {useEffect, useMemo, useState} from "react";

import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  type ColumnDef,
  type ColumnFiltersState,
  type SortingState,
  type VisibilityState,
} from "@tanstack/react-table";

import {Card} from "@/components/ui/card";
import {Skeleton} from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableFooterRow,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {Paragraph} from "@/components/ui/typography";
import {Pagination} from "@/components/craft/pagination";

import {
  usePreferencesStore,
  useProxyPayIMRemitLiteTableColumns,
  useProxyPayIMRemitLiteTableColumnsStore,
} from "@/lib/stores";
import {useProxypayMetaStore} from "@/modules/imremit";
import {
  ProxyPayDataTableToolbar,
  type ProxyPayIMRemitLiteTableColumnsType,
} from "@/modules/imremit-lite";

/**
 * Custom hook for managing omitted columns
 * @returns {Object} - Object containing the current state of omitted columns
 */
const useOmittedColumns = () => {
  // Get the current state of the omitted columns from the store
  const {storeProxyPayIMRemitLiteTableColumns} =
    useProxyPayIMRemitLiteTableColumns();
  // Memoize the omitted columns so that it is not re-calculated on every render
  return useMemo(() => {
    // If there is no state in the store, return an empty object
    return (
      storeProxyPayIMRemitLiteTableColumns || {
        id: false,
      }
    );
  }, [storeProxyPayIMRemitLiteTableColumns]);
};

interface ProxypayDataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  isPending?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
}

/**
 * The main functional component that renders a data table for proxypay payment.
 *
 * @function
 * @param {InvoiceDataTableProps<TData, TValue>} props - The props that the component receives.
 * @template TData - The type of data each row in the table represents.
 * @template TValue - The type of the values of each cell in the table.
 * @param {ColumnDef<TData, TValue>[]} props.columns - Configuration for each column in the table.
 * @param {TData[]} props.data - Data that the table will display.
 * @param {boolean} [props.isSuccess] - Whether the table has successfully loaded data.
 * @param {boolean} [props.isError] - Whether the table has an error.
 * @returns {JSX.Element} - The JSX to render the component.
 */
export function ProxyPayDataTable<TData, TValue>({
  columns,
  data,
  isSuccess,
  isError,
}: ProxypayDataTableProps<TData, TValue>) {
  // Accessing the pagination meta and page change handler from the store.
  const {storeProxypayPaginationMeta, handleOnPageChange} =
    useProxypayMetaStore();

  // Using state to maintain the sorting state for the table.
  const [sorting, setSorting] = useState<SortingState>([]);
  // Using state to maintain the visibility state for the table.
  /** State for storing the table columns */
  const {setStoreProxyPayIMRemitLiteTableColumns} =
    useProxyPayIMRemitLiteTableColumnsStore();
  // Custom hook to manage omitted columns
  const omittedColumns = useOmittedColumns();
  const [columnVisibility, setColumnVisibility] =
    useState<VisibilityState>(omittedColumns);
  /** Update the store when the column visibility changes */
  useEffect(() => {
    setStoreProxyPayIMRemitLiteTableColumns(
      columnVisibility as ProxyPayIMRemitLiteTableColumnsType
    );
  }, [columnVisibility, setStoreProxyPayIMRemitLiteTableColumns]);
  // Using state to maintain the column filters state for the table.
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  // Using state to maintain the row selection state for the table.
  const [rowSelection, setRowSelection] = useState({});

  // The `usePreferencesStore` hook from the `@/lib/stores` module provides an API for interacting with the preferences store.
  // We are using it to get the number of rows per page to display in the table.
  const {numRowsPerPage} = usePreferencesStore();

  // useReactTable is a custom hook from the `@tanstack/react-table` package that provides an API for interacting with the table.
  // It takes an object that includes data, column configurations, a row model getter, a sorting change handler,
  // another row model getter for sorted data, and a state object.
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination: {
        pageSize: parseInt(numRowsPerPage),
        pageIndex: 0,
      },
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const tableBodySkeleton = useMemo(() => {
    return (
      <TableBody>
        {Array.from({length: parseInt(numRowsPerPage)}, (_row, rowIndex) => (
          <TableRow key={rowIndex}>
            {Array.from(
              // calculate how many cells we need to render based on the number of columns
              // minus the number of omitted columns
              {
                length: columns.length - Object.entries(omittedColumns).length,
              },
              (_cell, cellIndex) => (
                <TableCell
                  key={`${cellIndex.toString()}-${rowIndex.toString()}`}
                  className="px-2 py-6"
                >
                  <Skeleton
                    size="xl"
                    colour={rowIndex % 2 === 0 ? "background" : "foreground"}
                  />
                </TableCell>
              )
            )}
          </TableRow>
        ))}
      </TableBody>
    );
  }, [columns.length, omittedColumns, numRowsPerPage]);

  // The return statement is rendering the actual Table component.
  // The Table, TableHeader, TableBody, TableRow, and TableCell components come from your custom @/components/ui/data-table module.
  // If there are no rows to display, a single row with a "No results" message is displayed.
  return (
    <div className="space-y-4">
      <ProxyPayDataTableToolbar table={table} />

      <Card>
        <Table>
          <style type="text/css" media="print">
            {
              "@page { size: landscape; margin: 0.5in 0.25in; }\
              "
            }
          </style>
          <TableHeader>
            {/* Mapping through each header group */}
            {table.getHeaderGroups().map((headerGroup, i) => (
              <TableRow key={`${headerGroup.id}-${i.toString()}`}>
                {/* Mapping through each header within a group */}
                {headerGroup.headers.map((header, i) => {
                  return (
                    <TableHead key={`${header.id}-${i.toString()}`}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>

          {isError ? (
            <TableBody>
              <TableRow>
                <TableCell
                  className="h-24 text-center"
                  colSpan={columns.length}
                >
                  <Paragraph>
                    <strong>Oops!</strong>
                  </Paragraph>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : !isSuccess ? (
            tableBodySkeleton
          ) : (
            <TableBody>
              {/* Checking if there are rows to display */}
              {table.getRowModel().rows.length ? (
                // Mapping through each row to display
                table.getRowModel().rows.map((row, i) => (
                  <TableRow
                    key={`${row.id}-${i.toString()}`}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {/* Mapping through each cell within a row */}
                    {row.getVisibleCells().map((cell, i) => (
                      <TableCell key={`${cell.id}-${i.toString()}`}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                // Displaying 'No results' if no rows to display
                <TableRow>
                  <TableCell
                    className="h-24 text-center"
                    colSpan={columns.length}
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}

          <TableFooter>
            {/* Mapping through each footer group */}
            {table.getHeaderGroups().map((headerGroup, i) => (
              <TableFooterRow key={`${headerGroup.id}-${i.toString()}`}>
                {/* Mapping through each footer within a group */}
                {headerGroup.headers.map((header, i) => {
                  return (
                    <TableHead key={`${header.id}-${i.toString()}`}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableFooterRow>
            ))}
          </TableFooter>
        </Table>
      </Card>

      <Pagination
        paginationMeta={storeProxypayPaginationMeta}
        onPageChange={handleOnPageChange}
      />
    </div>
  );
}
