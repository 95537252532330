import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  imREmitRunnerTypeQueryKeys,
  type ImREmitRunnerType,
} from "@/modules/imremit";

// Defining a type based on the function `searchImREmitRunnerTypes`
type QueryFnType = typeof getImREmitRunnerTypes;

// Function to search imremits runnertypes
export function getImREmitRunnerTypes() {
  return kyApiFn<ImREmitRunnerType[]>(`api/e-payable/runner-types`, "get");
  // TODO: validate data
}

// Custom hook to fetch and cache imremits runnertypes data using react-query
export function useGetImREmitRunnerTypes(
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: imREmitRunnerTypeQueryKeys.all, // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getImREmitRunnerTypes(), // Setting the function to be used for fetching data
  });
}
