import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import {Heading1} from "@/components/ui/typography";
import {HomeButton} from "@/components/layout/home-button";

import {termsOfServiceItems} from "@/modules/landing";

// Creating a functional component named 'TermsOfConditionsPage'
export function TermsOfConditionsPage() {
  return (
    <article
      className="flex flex-1 flex-col justify-center p-8"
      role="region"
      aria-roledescription="This is the Terms and Conditions page. View the terms and conditions of using this website here."
    >
      <header className="mb-4 flex w-full justify-between gap-x-6">
        <Heading1>Terms and Conditions</Heading1>
        <HomeButton />
      </header>

      <section className="flex flex-1 flex-col" role="presentation">
        <Accordion className="rounded-md bg-root p-4 shadow-md" type="multiple">
          {termsOfServiceItems.map((item, index) => (
            <AccordionItem key={`item-${index}`} value={`item-${index}`}>
              <AccordionTrigger>{item.title}</AccordionTrigger>
              <AccordionContent>{item.content}</AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </section>
    </article>
  );
}
