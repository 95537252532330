import {AnchorButton} from "@/components/ui/button";
import {Paragraph} from "@/components/ui/typography";

import {CONTACT_INFO} from "@/utils/constants";

export const termsOfServiceItems = [
  {
    title: "1. Client relationship with Iteration Matrix",
    content: (
      <>
        <Paragraph>
          1.1 Your use of Iteration Matrix LP's (the "Service Provider")
          products, software, services and web sites (referred to collectively
          as the "Services" in this document and each a "Service") is subject to
          the terms and conditions set forth below. Your use of the Services
          constitutes your agreement to be bound by these terms and conditions
          (the "Universal Terms").
        </Paragraph>
        <Paragraph>
          1.2 Subject to section 18 below, no amendment or other agreement
          between you and the Service Provider shall be effective unless
          expressly otherwise agreed in writing with the Service Provider.
        </Paragraph>
      </>
    ),
  },
  {
    title: "2. Eligibility to Use the Services",
    content: (
      <Paragraph>
        2.1 You may not use the Services and may not accept the Terms if (a) you
        are not of legal age to form a binding contract with the Service
        Provider, or (b) you are a person barred from receiving the Services
        under the laws of United States, or other countries including the
        country in which you are resident or from which you use the Services.
      </Paragraph>
    ),
  },
  {
    title: "3. Language of the Terms",
    content: (
      <>
        <Paragraph>
          3.1 Where the Service Provider has provided you with a translation of
          the English language version of the Terms, then you agree that the
          translation is provided for your convenience only and that the English
          language versions of the Terms will govern your relationship with the
          Service Provider
        </Paragraph>
        <Paragraph>
          3.2 If there is any contradiction between what the English language
          version of the Terms says and what a translation says, then the
          English language version shall take precedence.
        </Paragraph>
      </>
    ),
  },
  {
    title: "4. Provision of the Services by the Service Provider",
    content: (
      <>
        <Paragraph>
          4.1 The Service Provider has subsidiaries, affiliated legal entities
          and contractors around the world ("Subsidiaries and Affiliates").
          Sometimes, these companies will be providing the Services to you on
          behalf of the Service Provider itself. You acknowledge and agree that
          Subsidiaries and Affiliates will be entitled to provide the Services
          to you.
        </Paragraph>
        <Paragraph>
          4.2 The Service Provider is constantly innovating in order to provide
          the best possible experience for its users. You acknowledge and agree
          that the form and nature of the Services which the Service Provider
          provides may change from time to time without prior notice to you.
        </Paragraph>
        <Paragraph>
          4.3 As part of this continuing innovation, you acknowledge and agree
          that the Service Provider may stop (permanently or temporarily)
          providing the Services (or any features within the Services) to you or
          to users generally at the Service Provider's sole discretion, without
          prior notice to you. You may stop using the Services at any time. You
          do not need to specifically inform the Service Provider when you stop
          using the Services.
        </Paragraph>
        <Paragraph>
          4.4 You acknowledge and agree that if the Service Provider disables
          access to your account, you may be prevented from accessing the
          Services, your account details or any files or other content which is
          contained in your account.
        </Paragraph>
        <Paragraph>
          4.5 You acknowledge and agree that while the Service Provider may not
          currently have set a fixed upper limit on the number of transmissions
          you may receive through the Services or on the amount of storage space
          used for the provision of any Service, such fixed upper limits may be
          set by the Service Provider at any time, at the Service Provider's
          discretion.
        </Paragraph>
        <Paragraph>
          4.6 You acknowledge and agree that your Company Taxation ID that is
          provided during registration is the key identifier of your company and
          the information provided to you. You provide the Service Provider
          permission to provide your Taxation ID to organizations providing
          content so that they may identify the content that relates to your
          company.
        </Paragraph>
        <Paragraph>
          4.7 You acknowledge and agree that the name of your company can be
          listed on the Service Provider websites, presentation material and
          other communications. The sole purpose is to inform other
          organizations who are utilizing the Services.
        </Paragraph>
      </>
    ),
  },
  {
    title: "5. Use of the Services by you",
    content: (
      <>
        <Paragraph>
          5.1 In order to access certain Services, you may be required to
          provide information about yourself (such as identification or contact
          details) as part of the registration process for the Service, or as
          part of your continued use of the Services. You agree that any
          registration information you give to the Service Provider will always
          be accurate, correct and up to date.
        </Paragraph>
        <Paragraph>
          5.2 You agree to use the Services only for purposes that are permitted
          by (a) these Universal Terms and (b) any applicable law, regulation or
          generally accepted practices or guidelines in the relevant
          jurisdictions, including without limitation any laws regarding
          handling of personal information, and the export of data or software
          to and from the United States and Canada or other relevant countries.
        </Paragraph>
        <Paragraph>
          5.3 You agree not to access (or attempt to access) any of the Services
          by any means other than through the interface that is provided by the
          Service Provider, unless you have been specifically allowed to do so
          in a separate agreement with the Service Provider. You specifically
          agree not to access (or attempt to access) any of the Services through
          any automated means (including use of scripts or web crawlers) and
          shall ensure that you comply with the instructions set out in any
          robots.txt file present on the Services.
        </Paragraph>
        <Paragraph>
          5.4 You agree that you will not engage in any activity that interferes
          with or disrupts the Services (or the servers and networks which are
          connected to the Services).
        </Paragraph>
        <Paragraph>
          5.5 You agree that should you view information that does not relate to
          your company, you will immediately inform the Service Provider of such
          information at{" "}
          <AnchorButton
            className="h-4 bg-root p-0 font-semibold text-neutral-foreground hover:bg-root hover:text-neutral-foreground active:text-neutral-foreground"
            href={`mailto:${CONTACT_INFO.contactEmail}`}
          >
            {CONTACT_INFO.contactEmail}
          </AnchorButton>
          .
        </Paragraph>
        <Paragraph>
          5.6 Unless you have been specifically permitted to do so in a separate
          agreement with the Service Provider, you agree that you will not
          reproduce, duplicate, copy, sell, trade or resell the Services for any
          purpose
        </Paragraph>
        <Paragraph>
          5.7 You agree that you are solely responsible for (and that the
          Service Provider has no responsibility to you or to any third party
          for) any breach of your obligations under the Universal Terms and for
          the consequences (including any loss or damage which the Service
          Provider may suffer) of any such breach.
        </Paragraph>
      </>
    ),
  },
  {
    title: "6. Your passwords and account security",
    content: (
      <>
        <Paragraph>
          6.1 You agree and understand that you are responsible for maintaining
          the confidentiality of passwords associated with any account you use
          to access the Services.
        </Paragraph>
        <Paragraph>
          6.2 Accordingly, you agree that you will be solely responsible to the
          Service Provider for all activities that occur under your account.
        </Paragraph>
        <Paragraph>
          6.3 If you become aware of any unauthorized use of your password or of
          your account, you agree to notify the Service Provider immediately, at{" "}
          <AnchorButton
            href={`mailto:${CONTACT_INFO.contactEmail}`}
            className="h-4 bg-root p-0 font-semibold text-neutral-foreground hover:bg-root hover:text-neutral-foreground active:text-neutral-foreground"
          >
            {CONTACT_INFO.contactEmail}
          </AnchorButton>
          .
        </Paragraph>
      </>
    ),
  },
  {
    title: "7. Privacy and your corporate information",
    content: (
      <>
        <Paragraph>
          7.1 Information about the Service Provider's data protection practices
          is available upon request. Our information policy explains how the
          Service Provider treats corporate information, and protects privacy,
          when you use the Services.
        </Paragraph>
        <Paragraph>
          7.2 You agree to the use of your data in accordance with the Service
          Provider's privacy policies.
        </Paragraph>
        <Paragraph>
          7.3 Notwithstanding anything to the contrary contained in these
          Universal Terms or any related agreement, you acknowledge and agree
          that the Service Provider has no control over and only randomly
          reviews the content of the invoices provided by you for the purposes
          of quality control and spot-checking.
        </Paragraph>
      </>
    ),
  },
  {
    title:
      "8. Content in the Services, Your Content and Your Obligations With Your Customers",
    content: (
      <>
        <Paragraph>
          8.1 You understand that all information (such as data files, written
          text, computer software, music, audio files or other sounds,
          photographs, videos or other images) which you may have access to as
          part of, or through your use of, the Services is the sole
          responsibility of the person from which such content originated. All
          such information is referred to below as the "Content".
        </Paragraph>
        <Paragraph>
          8.2 The Content which you have access to as part of, or through the
          use of the Services, including but not limited to advertisements in
          the Services and sponsored Content within the Services, is owned or
          licensed by the Services Provider, its business associates, and / or
          third parties and is protected by copyright, trademark, patent, and/or
          other intellectual property laws. Except as expressly provided herein,
          the Service Provider does not grant any express or implied rights to
          use the Content and Services. You agree not to copy, republish, frame,
          transmit, modify, rent, lease, loan, sell, assign, distribute,
          license, sublicense, reverse engineer, or create derivative works
          based on the Content or the Services except as expressly authorized by
          the owners of such Content or the Service Provider in respect of the
          Services.
        </Paragraph>
        <Paragraph>
          8.3 The Service Provider reserves the right (but shall have no
          obligation) to pre-screen, review, flag, filter, modify, refuse or
          remove any or all Content from any Service. For some of the Services,
          the Service Provider may provide tools to filter out inappropriate
          content.
        </Paragraph>
        <Paragraph>
          8.4 You understand that by using the Services you may be exposed to
          Content that you may find inappropriate and that, in this respect, you
          use the Services at your own risk.
        </Paragraph>
        <Paragraph>
          8.5 You agree that you are solely responsible for (and that the
          Service Provider has no responsibility to you or to any third party
          for) any Content that you create, transmit or display while using the
          Services and for the consequences of your actions (including any loss
          or damage which the Service Provider may suffer) by doing so. You are
          responsible for taking all necessary steps to check and approve such
          Content, and for the accuracy, reliability and completeness of such
          Content. The Service Provider will not be obliged or responsible and
          the Services do not include any verification, supplementation, editing
          or vetting of the Content you provide other than as strictly necessary
          for the purposes of tracking and formatting your Content in accordance
          with the Services, which, except for spot checking and related
          remediation, is automated.
        </Paragraph>
        <Paragraph>
          8.6 You are responsible for ensuring your use of the Services complies
          with your obligations to any customers, regulators, or other third
          parties, including without limitation any transactions you may enter
          with them and your obligations in respect of such transactions.
        </Paragraph>
        <Paragraph>
          8.7 You are solely responsible for any VAT taxes, levies or other
          government or regulatory charges ("Taxes") arising from any
          transaction you enter with any third parties notwithstanding that you
          use the Services in connection with any such transaction. The Service
          Provider shall only be responsible for Taxes levied against it,
          arising from its provision of the Services to you.
        </Paragraph>
      </>
    ),
  },
  {
    title: "9. Proprietary rights",
    content: (
      <>
        <Paragraph>
          9.1 You acknowledge and agree that the Service Provider (or the
          Service Provider's licensors) owns all legal right, title and interest
          in and to the Services, including any intellectual property rights
          which subsist in the Services (whether those rights happen to be
          registered or not, and wherever in the world those rights may exist).
        </Paragraph>
        <Paragraph>
          9.2 Unless you have agreed otherwise in writing with the Service
          Provider, nothing in the Universal Terms gives you a right to use any
          of the Service Provider's trade names, trade-marks, service marks,
          logos, domain names, and other distinctive brand features without
          prior written consent.
        </Paragraph>
        <Paragraph>
          9.3 Other than the limited license set forth in Section 10, the
          Service Provider acknowledges and agrees that it obtains no right,
          title or interest from you (or your licensors) under these Universal
          Terms in or to any Content that you submit, post, transmit or display
          on, or through, the Services, including any intellectual property
          rights which subsist in that Content (whether those rights happen to
          be registered or not, and wherever in the world those rights may
          exist). Unless you have agreed otherwise in writing with the Service
          Provider, you agree that you are responsible for protecting and
          enforcing those rights and that the Service Provider has no obligation
          to do so on your behalf.
        </Paragraph>
        <Paragraph>
          9.4 You agree that you shall not remove, obscure, or alter any
          proprietary rights notices (including copyright and trade mark
          notices) which may be affixed to or contained within the Services.
        </Paragraph>
        <Paragraph>
          9.5 Unless you have been expressly authorized to do so in writing by
          the Service Provider, you agree that in using the Services, you will
          not use any trade mark, service mark, trade name, logo of any company
          or organization in a way that is likely or intended to cause confusion
          about the owner or authorized user of such marks, names or logos,
          provided that as long as you comply with these Universal Terms, we
          encourage you to refer to your use of the Services in communications
          with your clients and associates.
        </Paragraph>
      </>
    ),
  },
  {
    title: "3. Language of the Terms",
    content: (
      <>
        <Paragraph>
          10.1 The Service Provider gives you a worldwide, royalty-free,
          non-assignable and non-exclusive license to use the software provided
          to you by the Service Provider as part of the Services provided to you
          by the Service Provider (referred to as the "Software" below). This
          license is for the sole purpose of enabling you to use and enjoy the
          benefit of the Services as provided by the Service Provider, in the
          manner permitted by these Universal Terms and the terms associated
          with the Software.
        </Paragraph>
        <Paragraph>
          10.2 You may not (and you may not permit anyone else to) copy, modify,
          create a derivative work of, reverse engineer, decompile or otherwise
          attempt to extract the source code of the Software or any part
          thereof, unless this is expressly permitted by written agreement with
          the Service Provider or required by law.
        </Paragraph>
        <Paragraph>
          10.3 Unless the Service Provider has given you specific written
          permission to do so, you may not assign (or grant a sub-license of)
          your rights to use the Software, grant a security interest in or over
          your rights to use the Software, or otherwise transfer any part of
          your rights to use the Software.
        </Paragraph>
      </>
    ),
  },
  {
    title: "11. Ending your relationship with the Service Provider",
    content: (
      <>
        <Paragraph>
          11.1 The Universal Terms will continue to apply until terminated by
          either you or the Service Provider as set out below.
        </Paragraph>
        <Paragraph>
          11.2 Subject to the terms and conditions of any other agreement
          between you and the Service Provider in respect of the Services, you
          may terminate your legal agreement with the Service Provider by (a)
          notifying the Service Provider at any time and (b) closing your
          accounts for all of the Services which you use, where the Service
          Provider has made this option available to you.
        </Paragraph>
        <Paragraph>
          11.3 The Service Provider may at any time, terminate its legal
          agreement with you if:
        </Paragraph>
        <Paragraph>
          (A) you have breached any provision of the Universal Terms (or have
          acted in manner which clearly shows that you do not intend to, or are
          unable to comply with the provisions of the Universal Terms, or any
          other agreement that you have with the Service Provider); or
        </Paragraph>
        <Paragraph>
          (B) the Service Provider is required to do so by law (for example,
          where the provision of the Services to you is, or becomes, unlawful);
          or
        </Paragraph>
        <Paragraph>
          (C) the partner with whom the Service Provider offered the Services to
          you has terminated its relationship with the Service Provider or
          ceased to offer the Services to you; or
        </Paragraph>
        <Paragraph>
          (D) the Service Provider is transitioning to no longer providing the
          Services to users in the country in which you are resident or from
          which you use the service;
        </Paragraph>
        <Paragraph>
          (E) the provision of the Services to you by the Service Provider is,
          in the Service Provider's opinion, no longer commercially viable; or
        </Paragraph>
        <Paragraph>
          (F) the Service Provider has terminated any other agreement it has
          with you in respect of the Services.
        </Paragraph>
        <Paragraph>
          11.4 Nothing in this Section shall affect the Service Provider's
          rights regarding provision of Services under Section 4 of these
          Universal Terms.
        </Paragraph>
        <Paragraph>
          11.4 Nothing in this Section shall affect the Service Provider's
          rights regarding provision of Services under Section 4 of these
          Universal Terms.
        </Paragraph>
        <Paragraph>
          11.6 When these Terms come to an end, all unpaid amounts owing by
          either party to the other party will be paid in full subject to the
          Service Provider's exclusive right to set-off amounts.
        </Paragraph>
      </>
    ),
  },
  {
    title: "12. RESERVED",
    content: <></>,
  },
  {
    title: "13. EXCLUSION OF WARRANTIES",
    content: (
      <>
        <Paragraph>
          13.1 NOTHING IN THESE TERMS, INCLUDING WITHOUT LIMITATION SECTIONS 14
          AND 15, SHALL EXCLUDE OR LIMIT THE SERVICE PROVIDER'S WARRANTY OR
          LIABILITY FOR LOSSES WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED BY
          APPLICABLE LAW. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF
          CERTAIN WARRANTIES OR CONDITIONS OR THE LIMITATION OR EXCLUSION OF
          LIABILITY FOR LOSS OR DAMAGE CAUSED BY NEGLIGENCE, BREACH OF CONTRACT
          OR BREACH OF IMPLIED TERMS, OR INCIDENTAL OR CONSEQUENTIAL DAMAGES.
          ACCORDINGLY, ONLY THE LIMITATIONS WHICH ARE LAWFUL IN YOUR
          JURISDICTION WILL APPLY TO YOU AND OUR LIABILITY WILL BE LIMITED TO
          THE MAXIMUM EXTENT PERMITTED BY LAW.
        </Paragraph>
        <Paragraph>
          13.2 YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES
          IS AT YOUR SOLE RISK AND THAT THE SERVICES ARE PROVIDED "AS IS" AND
          "AS AVAILABLE."
        </Paragraph>
        <Paragraph>
          13.3 IN PARTICULAR, THE SERVICE PROVIDER, ITS SUBSIDIARIES AND
          AFFILIATES, AND ITS LICENSORS, INCLUDING THE DIRECTORS, OFFICERS,
          SHAREHOLDERS, UNITHOLDERS, TRUSTEES, EMPLOYEES, AGENTS, ADVISORS AND
          CONSULTANTS OF EACH OF THEM, (COLLECTIVELY "RELEASEES") DO NOT
          REPRESENT OR WARRANT TO YOU THAT:
        </Paragraph>
        <Paragraph>
          (A) YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS,
        </Paragraph>
        <Paragraph>
          (B) YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE OR
          FREE FROM ERROR,
        </Paragraph>
        <Paragraph>
          (C) ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF THE
          SERVICES WILL BE ACCURATE OR RELIABLE, AND
        </Paragraph>
        <Paragraph>
          (D) THAT DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY SOFTWARE
          PROVIDED TO YOU AS PART OF THE SERVICES WILL BE CORRECTED.
        </Paragraph>
        <Paragraph>
          13.4 ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF
          THE SERVICES IS DONE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE
          SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER
          DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH
          MATERIAL.
        </Paragraph>
        <Paragraph>
          13.5 NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY
          YOU FROM IM INVOICE TRACKER OR THROUGH OR FROM THE SERVICES SHALL
          CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS.
        </Paragraph>
        <Paragraph>
          13.6 THE SERVICE PROVIDER FURTHER EXPRESSLY DISCLAIMS ALL WARRANTIES
          AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT
          NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF
          MERCHANTABILITY, TITLE, QUIET ENJOYMENT, INTEGRATION, FITNESS FOR A
          PARTICULAR PURPOSE AND NON-INFRINGEMENT.
        </Paragraph>
      </>
    ),
  },
  {
    title: "14. LIMITATION OF LIABILITY",
    content: (
      <>
        <Paragraph>
          14.1 YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE RELEASEES SHALL NOT
          BE LIABLE TO YOU FOR:
        </Paragraph>
        <Paragraph>
          (A) ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL CONSEQUENTIAL OR
          EXEMPLARY DAMAGES WHICH MAY BE INCURRED BY YOU, HOWEVER CAUSED AND
          UNDER ANY THEORY OF LIABILITY. THIS SHALL INCLUDE, BUT NOT BE LIMITED
          TO, ANY LOSS OF PROFIT (WHETHER INCURRED DIRECTLY OR INDIRECTLY), ANY
          LOSS OF GOODWILL OR BUSINESS REPUTATION, ANY LOSS OF DATA SUFFERED,
          COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOST REVENUE,
          FAILURE TO ACCURATELY TRANSFER READ OR TRANSMIT INFORMATION, SYSTEM
          INCOMPATIBILITY OR PROVIDING INCORRECT COMPATIBILITY INFORMATION, OR
          BREACHES IN SYSTEM SECURITYWHETHER OR NOT CAUSED BY THE SERVICE
          PROVIDER OR BY A THIRD PARTY SERVICE PROVIDER BREACH, OR OTHER
          INTANGIBLE LOSS;
        </Paragraph>
        <Paragraph>
          (B) ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU, INCLUDING BUT NOT
          LIMITED TO LOSS OR DAMAGE AS A RESULT OF:
        </Paragraph>
        <Paragraph>
          (I) ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY OR
          EXISTENCE OF ANY ADVERTISING, OR AS A RESULT OF ANY RELATIONSHIP OR
          TRANSACTION BETWEEN YOU AND ANY ADVERTISER OR SPONSOR WHOSE
          ADVERTISING APPEARS ON THE SERVICES;
        </Paragraph>
        <Paragraph>
          (II) ANY CHANGES WHICH THE SERVICE PROVIDER MAY MAKE TO THE SERVICES,
          OR FOR ANY PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF THE
          SERVICES (OR ANY FEATURES WITHIN THE SERVICES);
        </Paragraph>
        <Paragraph>
          (III) THE DELETION OF, CORRUPTION OF, OR FAILURE TO STORE, ANY CONTENT
          AND OTHER COMMUNICATIONS DATA MAINTAINED OR TRANSMITTED BY OR THROUGH
          YOUR USE OF THE SERVICES;
        </Paragraph>
        <Paragraph>
          (IV) YOUR FAILURE TO PROVIDE THE SERVICE PROVIDER. WITH ACCURATE
          ACCOUNT INFORMATION; OR
        </Paragraph>
        <Paragraph>
          (V) YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS SECURE AND
          CONFIDENTIAL;
        </Paragraph>
        <Paragraph>
          14.2 THE LIMITATIONS ON THE SERVICE PROVIDER'S LIABILITY TO YOU IN
          PARAGRAPH 14.1 ABOVE SHALL APPLY WHETHER OR NOT THE SERVICE PROVIDER
          HAS BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF
          ANY SUCH LOSSES ARISING.
        </Paragraph>
        <Paragraph>
          14.2 THE LIMITATIONS ON THE SERVICE PROVIDER'S LIABILITY TO YOU IN
          PARAGRAPH 14.1 ABOVE SHALL APPLY WHETHER OR NOT THE SERVICE PROVIDER
          HAS BEEN ADVISED OF OR SHOULD HAVE BEEN AWARE OF THE POSSIBILITY OF
          ANY SUCH LOSSES ARISING.
        </Paragraph>
      </>
    ),
  },
  {
    title: "15. Copyright policies",
    content: (
      <Paragraph>
        15.1 It is the Service Provider's policy to respond to notices of
        alleged copyright infringement that comply with applicable international
        intellectual property law (including, in the United States, the Digital
        Millennium Copyright Act) and to terminate the accounts of repeat
        infringers.
      </Paragraph>
    ),
  },
  {
    title: "16. Advertisements",
    content: (
      <>
        <Paragraph>
          16.1 Some of the Services may be supported by advertising revenue and
          may display advertisements and promotions. These advertisements may be
          targeted to the content of information stored on the Services, queries
          made through the Services or other information.
        </Paragraph>
        <Paragraph>
          16.2 The manner, mode and extent of advertising by the Service
          Provider on the Services are subject to change without specific notice
          to you.
        </Paragraph>
        <Paragraph>
          16.3 In consideration for the Service Provider granting you access to
          and use of the Services, you agree that the Service Provider may place
          such advertising on the Services.
        </Paragraph>
      </>
    ),
  },
  {
    title: "17. Other content",
    content: (
      <>
        <Paragraph>
          17.1 The Services may include hyperlinks to other web sites or content
          or resources. The Service Provider may have no control over any web
          sites or resources which are provided by companies or persons other
          than the Service Provider.
        </Paragraph>
        <Paragraph>
          17.2 You acknowledge and agree that the Service Provider is not
          responsible for the availability of any such external sites or
          resources, and does not endorse any advertising, products or other
          materials on or available from such web sites or resources.
        </Paragraph>
        <Paragraph>
          17.3 You acknowledge and agree that the Service Provider is not liable
          for any loss or damage which may be incurred by you as a result of the
          availability of those external sites or resources, or as a result of
          any reliance placed by you on the completeness, accuracy or existence
          of any advertising, products or other materials on, or available from,
          such web sites or resources.
        </Paragraph>
      </>
    ),
  },
  {
    title: "18. Changes to the Terms",
    content: (
      <>
        <Paragraph>
          18.1 The Service Provider may make changes to the Universal Terms from
          time to time. When these changes are made, the Service Provider will
          make a copy of the Universal Terms available on its website with
          reasonable notice to allow you to consider whether or not you wish to
          continue using the Services (the "Notice Period").
        </Paragraph>
        <Paragraph>
          18.2 You understand and agree that if you use the Services after the
          expiry of the Notice Period you will be bound by the Universal Terms
          as so amended by the Service Provider.
        </Paragraph>
      </>
    ),
  },
  {
    title: "19. General legal terms",
    content: (
      <>
        <Paragraph>
          19.1 Sometimes when you use the Services, you may (as a result of, or
          through your use of the Services) use a service or download a piece of
          software, or purchase goods, which are provided by another person or
          company. Your use of these other services, software or goods may be
          subject to separate terms between you and the company or person
          concerned. If so, the Universal Terms do not affect your legal
          relationship with these other companies or individuals.
        </Paragraph>
        <Paragraph>
          19.2 The Universal Terms constitute the whole legal agreement between
          you and the Service Provider and govern your use of the Services (but
          excluding any services which the Service Provider may provide to you
          under a separate written agreement), and completely replace any prior
          agreements between you and the Service Provider in relation to the
          Services.
        </Paragraph>
        <Paragraph>
          19.3 You agree that the Service Provider may provide you with notices,
          including those regarding changes to the Terms, by email, regular
          mail, or postings on the Services.
        </Paragraph>
        <Paragraph>
          19.4 You agree that if the Service Provider does not exercise or
          enforce any legal right or remedy which is contained in the Universal
          Terms (or which the Service Provider has the benefit of under any
          applicable law), this will not be taken to be a formal waiver of the
          Service Provider's rights and that those rights or remedies will still
          be available to the Service Provider.
        </Paragraph>
        <Paragraph>
          19.5 If any court of law, having the jurisdiction to decide on this
          matter, rules that any provision of these Universal Terms is invalid,
          then that provision will be removed from the Universal Terms without
          affecting the rest of the Universal Terms. The remaining provisions of
          the Universal Terms will continue to be valid and enforceable and
          these Universal Terms shall be construed, to the extent permissible at
          law, so as to place the parties in the same position economically and
          as concerns liability as each would have been had any such severed
          term been enforceable.
        </Paragraph>
        <Paragraph>
          19.6 You acknowledge and agree that each of the Releases, and each
          person with which the Service Provider contracts for the provision of
          the Services herein, shall be third party beneficiaries to the
          Universal Terms and that such other companies shall be entitled to
          directly enforce, and rely upon, any provision of the Universal Terms
          which confers a benefit on (or rights in favor of) them. Other than
          this, no other person or company shall be third party beneficiaries to
          the Universal Terms.
        </Paragraph>
        <Paragraph>
          19.7 The Universal Terms, and your relationship with the Service
          Provider, shall be governed by the laws of the Province of Alberta,
          Canada without regard to its conflict of law's provisions. You and the
          Service Provider agree to submit to the exclusive jurisdiction of the
          courts of Alberta to resolve any legal matter arising from the
          Universal Terms. Notwithstanding this, you agree that the Service
          Provider shall still be allowed to apply for injunctive remedies (or
          an equivalent type of urgent legal relief) in any jurisdiction.
        </Paragraph>
      </>
    ),
  },
];
