import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  dupesCustomerSettingsKeys,
  type DupesCustomerSettingsType,
  type DupesPaymentSettingsType,
} from "@/modules/duplicate-payments";

// Type alias for the function `getAllDupesCharacterSettings` 🧬
type QueryFnType = typeof getAllDupesCharacterSettings;

// Function to fetch all duplicate character settings for a customer 🚀
export function getAllDupesCharacterSettings(externalId?: string) {
  // API call using ky, specifying the return type 🌍
  return kyApiFn<DupesCustomerSettingsType>(
    `duplicate-payments/v1/customer-settings/${externalId ?? ""}`,
    "get"
  );
}

// Custom hook to fetch and cache duplicate customer settings using react-query 🪝
export function useAllDupesCustomerSettings(
  externalId?: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading additional configuration options 🛠
    queryKey: dupesCustomerSettingsKeys.byCustomerId(externalId), // Unique key for cache 🗝
    queryFn: () => getAllDupesCharacterSettings(externalId), // Data fetching function 📡
    enabled: !!externalId, // Condition to enable the query 🚦
  });
}

export const createDupesCustomerSettings = (
  createDefaultSettingsDTO: DupesPaymentSettingsType
) =>
  kyApiFn(`duplicate-payments/v1/customer-settings`, "post", {
    json: createDefaultSettingsDTO,
  });

export const updateDupesCustomerSettings = (
  externalId: string,
  updateDefaultSettingsDTO: DupesPaymentSettingsType
) =>
  kyApiFn(`duplicate-payments/v1/customer-settings/${externalId}`, "put", {
    json: updateDefaultSettingsDTO,
  });
