import {useState} from "react";

import {type Table} from "@tanstack/react-table";
import {ListRestartIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import {
  DataTablePagination,
  DataTableViewOptions,
} from "@/components/craft/data-table";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

/**
 * Props for SRDetailsDataTableToolbar component.
 *
 * @template TData - The type of data used in the table.
 * @typedef {Object} SRDetailsDataTableToolbarProps
 * @property {Table<TData>} table - The table instance to interact with.
 */
interface SRDetailsDataTableToolbarProps<TData> {
  table: Table<TData>;
  isPending?: boolean;
  isErrored?: boolean;
}

/**
 * Toolbar component for SR search data table.
 * Includes pagination, search input, filter components, and view options.
 *
 * @template TData - The type of data displayed in the table.
 * @param {SRDetailsDataTableToolbarProps<TData>} props - The component props.
 * @returns {JSX.Element} The rendered JSX element.
 */
export function SRDetailsDataTableToolbar<TData>({
  table,
  isPending,
  isErrored,
}: SRDetailsDataTableToolbarProps<TData>) {
  // State for the global filter input
  const [globalFilterInput, setGlobalFilterInput] = useState("");

  // Determine if the table is currently filtered
  const isFiltered =
    table.getState().columnFilters.length > 0 ||
    !!table.getState().globalFilter;

  //Columns other names
  const columnsOtherName = [
    {key: "im_sr_statement_id", value: "IM SR Statement Id"},
    {key: "st_invoice_number", value: "Statement Invoice Number"},
    {key: "st_invoice_date", value: "Statement Invoice Date"},
    {key: "st_invoice_amount", value: "Statement Invoice Amount"},
    {key: "st_po_number", value: "Statement PO Number"},
    {key: "im_sr_match_type", value: "Match Status"},
    {key: "supplier_number", value: "Supplier Number"},
    {key: "supplier_name", value: "Supplier Name"},
    {key: "invoice_number", value: "Invoice Number"},
    {key: "invoice_date", value: "Invoice Date"},
    {key: "po_number", value: "PO Number"},
    {key: "invoice_amount", value: "Invoice Amount"},
    {key: "invoice_paid_amount", value: "Invoice Paid Amount"},
    {key: "invoice_payment_number", value: "Invoice Payment Number"},
    {key: "created_timestamp", value: "Created Timestamp"},
    {key: "updated_timestamp", value: "Updated Timestamp"},
    {key: "id", value: "Id"},
    {key: "invoice_payment_date", value: "Invoice Payment Date"},
    {key: "invoice_id", value: "Invoice Id"},
    {key: "supplier_id", value: "Supplier Id"},
    {key: "invoice_erp_unique_id", value: "Invoice ERP Unique Id"},
    {key: "invoice_payment_method", value: "Invoice Payment Method"},
    {key: "payment_term", value: "Payment Term"},
    {key: "void_id", value: "Void Id"},
    {key: "void_date", value: "Void Date"},
    {key: "im_sr_customer_statement_id", value: "IM SR Customer Statement Id"},
    {key: "buyer_external_id", value: "Buyer External Id"},
    {key: "buyer_name", value: "Buyer Name"},
    {key: "invoice_po_number", value: "Invoice PO Number"},
    {key: "buyer_id", value: "Buyer Id"},
    {key: "invoice_status_id", value: "Invoice Status Id"},
    {key: "suffix", value: "Suffix"},
    {key: "compared", value: "Compared"},
    {key: "credit_pair", value: "Credit Pair"},
    {key: "cancel_id", value: "Cancel Id"},
    {key: "cancel_date", value: "Cancel Date"},
    {key: "invoice_type", value: "Invoice Type"},
    {key: "payment_status", value: "Payment Status"},
    {key: "insert_date", value: "Insert Date"},
    {key: "update_date", value: "Update Date"},
    {key: "isreconciled", value: "Is Reconciled"},
    {key: "facility_id", value: "Facility Id"},
    {key: "statement_id", value: "Statement Id"},
  ];

  return (
    <>
      <DataTablePagination
        table={table}
        isPending={isPending}
        isErrored={isErrored}
      />
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-1 flex-wrap items-center gap-y-2 space-x-2">
          <RowsPreferenceSelect />
          <Input
            placeholder="Search all entries..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={globalFilterInput}
            onChange={(event) => {
              setGlobalFilterInput(event.target.value);
              table.setGlobalFilter(event.target.value);
            }}
          />

          {isFiltered && (
            <Button
              variant="destructive"
              className="h-10 gap-2 px-2 hover:bg-destructive/70 hover:text-destructive-foreground/80 active:bg-destructive/80 active:text-destructive-foreground/80 lg:px-3"
              onClick={() => {
                table.resetColumnFilters();
                table.resetGlobalFilter();
                setGlobalFilterInput("");
              }}
            >
              <span className="sr-only">Reset search filters</span>
              Details Reset
              <ListRestartIcon className="size-4" />
            </Button>
          )}
        </div>

        <DataTableViewOptions
          table={table}
          columnsOtherName={columnsOtherName}
        />
      </div>
    </>
  );
}
