import {useEffect, useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {CheckIcon, ChevronsUpDownIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import {z} from "zod";

import {Button} from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Spinner} from "@/components/ui/spinner";
import {toast} from "@/components/ui/use-toast";

import {useStatementReconCustomerStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {useKeyCloakInstanceStore} from "@/modules/auth";
import {useAllCustomers, useAllSuppliersByCustomer} from "@/modules/customers";

/**
 * Schema definition for the SelectCustomer form using Zod.
 */
const SelectCustomerFormSchema = z.object({
  imREmitCustomer: z.string(),
});

/**
 * Custom React hook to fetch and process customer data for the select customer form.
 * @returns {Object} An object containing customer related states and data.
 */
function useFetchSelectCustomerBySupplierForm(supplierId: string) {
  // Fetch all customers using a custom hook
  const {
    data: customersBySupplierRawData,
    isPending: customersBySupplierArePending,
    isFetched: customersBySupplierAreFetched,
    isError: customersBySupplierError,
  } = useAllSuppliersByCustomer(supplierId);

  const {data: rawCustomersData} = useAllCustomers();

  // Extract the data (customers) from the response
  const customersDataContent = useMemo(() => {
    return rawCustomersData?.content || [];
  }, [rawCustomersData]);

  // Extract the data (customers) from the response
  const customersBySupplierContent = useMemo(() => {
    return customersBySupplierRawData?.content || [];
  }, [customersBySupplierRawData]);

  // useMemo is used to create a memoized version of customerBuyerNames.
  const imREmitCustomerBuyerNames = useMemo(() => {
    return customersBySupplierContent.map((customer) => {
      return {
        businessNameLabel: customer.buyer_name,
        businessNameValue: customer.buyer_external_id,
      };
    });
  }, [customersBySupplierContent]);

  return {
    customersBySupplierArePending,
    customersBySupplierAreFetched,
    customersBySupplierError,
    customersBySupplierContent,
    customersDataContent,
    imREmitCustomerBuyerNames,
  };
}

interface SelectCustomerFormProps {
  supplierId: string;
  requireLabel?: boolean;
}

/**
 * React component for selecting a customer from a list.
 */
export function SelectCustomerBySupplierForm({
  supplierId,
  requireLabel,
}: SelectCustomerFormProps) {
  // Extract the `tokenParsed` field from the store
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  // Extract the `customer` field from the tokenParsed object
  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;

  // Extract the `customers` field from the store
  // and the `updateStoreCustomers` function to update the store
  const {statementReconStoreCustomer, updateStatementReconStoreCustomer} =
    useStatementReconCustomerStore();

  // Create a form instance using react-hook-form's useForm
  // and use Zod for form validation
  const selectCustomerFormMethods = useForm<
    z.infer<typeof SelectCustomerFormSchema>
  >({
    defaultValues: {
      // Set the default value of the `customer` field to the value from the store
      imREmitCustomer: statementReconStoreCustomer
        ? statementReconStoreCustomer.buyerName
        : "",
    },
    mode: "onChange", // validate the form on change
    resolver: zodResolver(SelectCustomerFormSchema), // use Zod for validation
  });

  // Observe the 'customers' field from the form
  const formFields = selectCustomerFormMethods.watch();

  // Define a function to handle form submission
  function onSubmit(data: z.infer<typeof SelectCustomerFormSchema>) {
    // Display a toast notification when the form is submitted
    toast({
      title: "You submitted the following values:",
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data, null, 2)}</code>
        </pre>
      ),
    });
  }

  const {
    customersBySupplierArePending,
    customersDataContent,
    imREmitCustomerBuyerNames,
    customersBySupplierContent,
  } = useFetchSelectCustomerBySupplierForm(supplierId);

  const [selectedCustomerLabel, setSelectedCustomerLabel] = useState(
    formFields.imREmitCustomer
  );

  useEffect(() => {
    // We find the customer in customersDataContent that matches the current form field.
    const updatedCustomer = customersDataContent.find(
      (customer) =>
        customer.externalId.toLowerCase().trim() ===
        formFields.imREmitCustomer.toLowerCase().trim()
    );

    if (updatedCustomer) {
      // We call updateStoreCustomers, passing in customersDataContent.
      // This updates the stored customers to match the current form fields.
      updateStatementReconStoreCustomer(updatedCustomer);
      setSelectedCustomerLabel(updatedCustomer.buyerName); // Update the selected customer label
    }
  }, [
    updateStatementReconStoreCustomer,
    customersDataContent,
    customersBySupplierContent,
    formFields.imREmitCustomer,
  ]); // The effect will run again if these values change.

  if (parsedUserInfo?.customer || !parsedUserInfo?.supplier) {
    return null;
  }

  if (customersBySupplierArePending) {
    return (
      <section className="flex w-full justify-end">
        <Spinner size="lg" />
      </section>
    );
  }

  return (
    <Form {...selectCustomerFormMethods}>
      <form onSubmit={selectCustomerFormMethods.handleSubmit(onSubmit)}>
        <FormField
          control={selectCustomerFormMethods.control}
          name="imREmitCustomer"
          render={({field}) => (
            <FormItem className="flex flex-col space-y-0">
              {requireLabel && (
                <FormLabel
                  className="pb-2"
                  htmlFor="imREmitCustomer"
                  showMandatoryAsterisk
                >
                  Select Customer:
                </FormLabel>
              )}
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant="outline"
                      role="combobox"
                      className={cn(
                        "h-10 min-w-72 max-w-96 justify-between gap-2 bg-root capitalize",
                        !field.value && "text-accent-foreground"
                      )}
                    >
                      {selectedCustomerLabel || "Select customer..."}
                      <ChevronsUpDownIcon className="size-4 shrink-0 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                  <Command>
                    <CommandInput placeholder="Search customers..." />
                    <CommandEmpty>No customers found.</CommandEmpty>
                    <CommandGroup>
                      {imREmitCustomerBuyerNames.map(
                        (imREmitCustomerSelectionOption) => (
                          <CommandItem
                            key={
                              imREmitCustomerSelectionOption.businessNameValue
                            }
                            value={
                              imREmitCustomerSelectionOption.businessNameValue
                            }
                            onSelect={(value) => {
                              selectCustomerFormMethods.setValue(
                                "imREmitCustomer",
                                value
                              );
                              setSelectedCustomerLabel(
                                imREmitCustomerSelectionOption.businessNameLabel
                              );
                            }}
                          >
                            <CheckIcon
                              className={cn(
                                "mr-2 size-4",
                                imREmitCustomerSelectionOption.businessNameValue
                                  .trim()
                                  .toLowerCase() ===
                                  field.value.toLowerCase().trim()
                                  ? "opacity-100"
                                  : "opacity-0"
                              )}
                            />
                            {imREmitCustomerSelectionOption.businessNameLabel}
                          </CommandItem>
                        )
                      )}
                    </CommandGroup>
                  </Command>
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
}
