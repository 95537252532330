/* eslint-disable */
import {useCallback, useEffect, useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {debounce} from "lodash";
import {PlusIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import {z} from "zod";

import {Button} from "@/components/ui/button";
import {Dialog, DialogContent, DialogTrigger} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {Heading2, Paragraph} from "@/components/ui/typography";
import {FancyMultiSelect} from "@/components/craft/fancy-multi-select";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {StatementReconRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore, useStatementReconCustomerStore} from "@/lib/stores";
import {
  AccessPolicyWrapper,
  calculateUserRoles,
  KeycloakRoleEnum,
  useAuthorization,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {
  KeycloakTokenWithSupplierType,
  SelectCustomerBySupplierForm,
  SelectCustomerFormStatementRecon,
  SelectCustomerSectionStatementRecon,
  SelectSupplierSection,
  useGetAllSuppliersByCustomerFuzzy,
  useGetAllSupplierWithOutFilter,
} from "@/modules/customers";
import {
  CreateSupplierGroupingForm,
  StatementReconConfigFormSchema,
  StatementReconUploadDropzone,
  useSRGroupingsByExternalId,
} from "@/modules/statement-recon";

import {KeycloakRoles} from "@/utils/constants";

/**
 * Custom Hook: Manage the active route for the Statement Recon upload page
 */
function useStatementReconUploadRoute() {
  const {setStatementReconRoute} = useRoutesStore();

  useEffect(() => {
    setStatementReconRoute(
      StatementReconRoutesSchema.Values["/app/statement-recon/upload"]
    );
  }, [setStatementReconRoute]);
}

/**
 *  Custom Hook: Parse suppliers data
 * @param search
 * @param externalId
 * @returns
 */
export function useParsedSuppliers(search: string, externalId: string) {
  const getSupplierByCustomerIdQuery = useGetAllSuppliersByCustomerFuzzy(
    externalId,
    search
  );

  const rawSuppliersData = getSupplierByCustomerIdQuery.data?.content;

  const supplierData = useMemo(() => {
    if (!rawSuppliersData) return [];
    return rawSuppliersData.map((supplier) => ({
      label: `${supplier.supplier_name ?? "error"}`,
      value: supplier.supplier_id?.toString() || "",
    }));
  }, [rawSuppliersData]);

  return {
    supplierData,
    suppliersLoading: getSupplierByCustomerIdQuery.isPending,
  };
}

export function StatementReconUploadPage() {
  useStatementReconUploadRoute();

  const {statementReconStoreCustomer} = useStatementReconCustomerStore();
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const {checkPoliciesAccess} = useAuthorization();

  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_SR_UPLOAD])) {
    return <NotAuthorizedPage />;
  }

  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  // 📍 Memoize the supplier user check to prevent unnecessary re-renders
  const isASupplierUser = useMemo(
    () =>
      userRoleDisplay === KeycloakRoles.SupplierUser ||
      userRoleDisplay === KeycloakRoles.SupplierAdmin ||
      userRoleDisplay === KeycloakRoles.SupplierEnablement,
    [userRoleDisplay]
  );

  if (!statementReconStoreCustomer) {
    if (isASupplierUser) {
      return <SelectSupplierSection />;
    } else {
      return <SelectCustomerSectionStatementRecon />;
    }
  }

  return (
    <AccessPolicyWrapper policyActions={[KeycloakRoleEnum.READ_SR_UPLOAD]}>
      <StatementReconUploadContent />
    </AccessPolicyWrapper>
  );
}

function StatementReconUploadContent(this: any) {
  const {statementReconStoreCustomer} = useStatementReconCustomerStore();
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const externalId = statementReconStoreCustomer?.externalId;

  const [openGroupingForm, setOpenGroupingForm] = useState(false);
  const [supplierGroupingLoad, setSupplierGroupingLoad] = useState(false);

  const statementConfigFormMethods = useForm<
    z.infer<typeof StatementReconConfigFormSchema>
  >({
    defaultValues: {
      suppliers: [],
    },
    mode: "onChange",
    resolver: zodResolver(StatementReconConfigFormSchema),
  });

  const selectedSuppliers = statementConfigFormMethods.watch("suppliers");

  const [search, setSearch] = useState("");
  const debouncedSetSearch = useCallback(
    debounce((value) => setSearch(value), 300),
    []
  );

  useEffect(() => {
    return () => {
      debouncedSetSearch.cancel();
    };
  }, [debouncedSetSearch]);

  const {supplierData, suppliersLoading} = useParsedSuppliers(
    search,
    externalId ?? ""
  );

  //supplier grouping
  const supplierGroupsByExternalIdQuery =
    useSRGroupingsByExternalId(externalId);
  const supplierGroupsByExternalIdData = Array.isArray(
    supplierGroupsByExternalIdQuery.data?.content
  )
    ? supplierGroupsByExternalIdQuery.data.content
    : [];

  //Get All Supplier with out filter
  const rowAllSuppliersQuery = useGetAllSupplierWithOutFilter();
  const rowAllSuppliersData = rowAllSuppliersQuery.data?.content || [];

  const handleSupplierGrouping = (supplierGroupId: string) => {
    const supplierGroupIds = supplierGroupsByExternalIdData
      .filter((group) => group.im_sr_supplier_group_id === supplierGroupId)
      .map((group) => group.supplier_ids)
      .flat();

    const supplierGroupIdsData = rowAllSuppliersData
      .filter((supplier: {supplier_id: string}) =>
        supplierGroupIds.includes(supplier.supplier_id)
      )
      .map(
        (supplier: {
          supplier_name: any;
          supplier_id: {toString: () => any};
        }) => ({
          label: supplier.supplier_name ?? "error",
          value: supplier.supplier_id?.toString() || "",
        })
      );
    statementConfigFormMethods.setValue("suppliers", supplierGroupIdsData);
    setSupplierGroupingLoad(true);
  };

  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  const parsedUserInfo =
    storeKeyCloakInstance?.tokenParsed as KeycloakTokenWithSupplierType;

  // 📍 Memoize the supplier user check to prevent unnecessary re-renders
  const isASupplierUser = useMemo(
    () =>
      userRoleDisplay === KeycloakRoles.SupplierUser ||
      userRoleDisplay === KeycloakRoles.SupplierAdmin ||
      userRoleDisplay === KeycloakRoles.SupplierEnablement,
    [userRoleDisplay]
  );

  // console.log("isASupplierUser", isASupplierUser);

  return (
    <>
      <Form {...statementConfigFormMethods}>
        <div className="flex flex-col gap-4">
          {!isASupplierUser ? (
            <section className="flex justify-between gap-4">
              <div className="flex flex-col">
                <Heading2>Upload Statement</Heading2>
                <Paragraph>
                  Select your suppliers and please upload your statement in
                  either PDF, XLSX, XLS, or CSV format.
                </Paragraph>
              </div>

              <div className="flex gap-2 p-2">
                <SelectCustomerFormStatementRecon />

                <FormField
                  name="suppliers"
                  render={({field}) => (
                    <FormItem className="flex flex-col">
                      <FormControl>
                        <FancyMultiSelect
                          {...field}
                          setSearch={setSearch}
                          multiSelectData={supplierData}
                          customWidth="96"
                          placeholder={
                            !supplierData.length
                              ? "Start searching suppliers..."
                              : "Search suppliers..."
                          }
                          disabled={supplierGroupingLoad}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Select
                  onValueChange={handleSupplierGrouping}
                  disabled={supplierGroupsByExternalIdData.length === 0}
                >
                  <SelectTrigger className="w-[180px] bg-root">
                    <SelectValue
                      placeholder={
                        supplierGroupsByExternalIdData.length === 0
                          ? "No groups found..."
                          : "Select a group"
                      }
                    />
                  </SelectTrigger>
                  <SelectContent>
                    {supplierGroupsByExternalIdData.map((group, i) => (
                      <SelectItem value={group.im_sr_supplier_group_id} key={i}>
                        {group.im_sr_supplier_group_name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <div className="flex gap-2">
                  <Dialog
                    open={openGroupingForm}
                    onOpenChange={setOpenGroupingForm}
                  >
                    <DialogTrigger asChild>
                      <Button
                        className="gap-2"
                        disabled={suppliersLoading || !supplierData.length}
                      >
                        <PlusIcon className="size-4" />
                      </Button>
                    </DialogTrigger>
                    <DialogContent className="flex h-auto w-full max-w-screen-md items-center justify-center pt-2">
                      {statementReconStoreCustomer?.externalId && (
                        <CreateSupplierGroupingForm
                          externalId={statementReconStoreCustomer?.externalId}
                          setOpenGroupingForm={setOpenGroupingForm}
                          suppliersForGrouping={statementConfigFormMethods.watch(
                            "suppliers"
                          )}
                        />
                      )}
                    </DialogContent>
                  </Dialog>
                </div>
              </div>
            </section>
          ) : (
            <section className="flex justify-between gap-4">
              <div className="flex flex-col">
                <Heading2>Upload Statement</Heading2>
                <Paragraph>
                  Select your suppliers and please upload your statement in
                  either PDF, XLSX, XLS, or CSV format.
                </Paragraph>
              </div>

              <div className="flex gap-2 p-2">
                <SelectCustomerBySupplierForm
                  supplierId={parsedUserInfo.supplier}
                  requireLabel
                />
              </div>
            </section>
          )}

          {!isASupplierUser && !selectedSuppliers.length ? (
            <Paragraph className="pt-12 text-center font-semibold">
              Please select at least one supplier to proceed.
            </Paragraph>
          ) : (
            <section className="flex flex-col gap-4">
              <StatementReconUploadDropzone
                buyerExternalId={externalId || ""}
              />
            </section>
          )}
        </div>
      </Form>
    </>
  );
}
