import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {organizationsKeys, type OrganizationsSchema} from "@/modules/imremit";

// Defining a type based on the function `getOrganizationsList`
type QueryFnType = typeof getOrganizationsList;

// Function to search getOrganizationsList
export function getOrganizationsList(externalId?: string) {
  return kyApiFn<OrganizationsSchema[]>(
    `api/e-payable/management/${externalId}/organizations`,
    "get"
  );
}

// Custom hook to fetch and cache Organizations list based on the `participant id` data using react-query
export function useGetOrganizationsList(
  externalId?: string, // The search query string
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: organizationsKeys.byId(externalId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getOrganizationsList(externalId), // Setting the function to be used for fetching data
    enabled: !!externalId, // Setting the query to be enabled only if the search query string is not empty
  });
}
