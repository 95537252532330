import {useEffect} from "react";

import {Heading2, Paragraph} from "@/components/ui/typography";

import {AdminRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";
import {
  CustomerModuleManagementDataTable,
  customerModuleManagementTableColumns,
} from "@/modules/admin";
import {useAllCustomers} from "@/modules/customers";

function useCustomerManagementPage() {
  // Get the search customer query and data
  const searchCustomerQuery = useAllCustomers();
  const searchCustomerData = searchCustomerQuery.data?.content || [];

  // Return the state and utility functions
  return {
    searchCustomerQuery,
    searchCustomerData,
  };
}
/**
 * CustomerModuleConfiguration Component
 *
 * This component provides the interface for administrators to manage
 * different customer modules within the application.
 *
 */
export function CustomerModuleConfiguration() {
  // Use the custom hook for manipulating route data
  const {setAdminRoute} = useRoutesStore();

  // useEffect to set the admin route upon component mount
  useEffect(() => {
    // Define the active admin route for Customer Module Management using the route schema
    setAdminRoute(
      AdminRoutesSchema.Values["/app/admin/customer-module-management"]
    );
  }, [setAdminRoute]);

  const {searchCustomerQuery, searchCustomerData} = useCustomerManagementPage();

  // Render the User Role Configuration Interface
  return (
    <section role="presentation">
      <div className="mb-2 flex flex-col">
        <Heading2>Customer Module Management</Heading2>
        <Paragraph>Manage your customer modules here.</Paragraph>
      </div>
      <CustomerModuleManagementDataTable
        columns={customerModuleManagementTableColumns}
        data={searchCustomerData}
        isSuccess={searchCustomerQuery.isSuccess}
        isPending={searchCustomerQuery.isPending}
        isError={searchCustomerQuery.isError}
      />
    </section>
  );
}
