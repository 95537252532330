import {Navigate, useNavigate} from "@tanstack/react-router";

export function InvoicesRedirect() {
  // Get the navigate function from the router
  const navigate = useNavigate();

  void navigate({
    to: "/app/invoices/tracker/$invoicesPage/dashboard",
    params: {
      invoicesPage: "1",
    },
  });

  return (
    <Navigate
      to="/app/invoices/tracker/$invoicesPage/dashboard"
      params={{
        invoicesPage: "1",
      }}
    />
  );
}
