import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  paymentManagementCardsFilterKeys,
  type ImREmitPaymentType,
} from "@/modules/imremit";

// Defining a type based on the function `searchSuppliersDetails`
type QueryFnType = typeof getPaymentManagementCardsFilter;

// Function to search payment
export function getPaymentManagementCardsFilter(
  externalId?: string,
  methodType?: string,
  searchQueryString?: string
) {
  return kyApiFn<ImREmitPaymentType[]>(
    `api/e-payable/management/${externalId}/${methodType}?${searchQueryString}`,
    "get"
  );
}

// Custom hook to fetch and cache payment data using react-query
export function useGetPaymentManagementCardsFilter(
  externalId?: string, // The search query string
  methodType?: string, // The search query string
  searchQueryString?: string, // The search query string
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: paymentManagementCardsFilterKeys.bySearch(
      externalId,
      methodType,
      searchQueryString
    ), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () =>
      getPaymentManagementCardsFilter(
        externalId,
        methodType,
        searchQueryString
      ), // Setting the function to be used for fetching data
    enabled: !!methodType && !!externalId && !!searchQueryString, // Setting the query to be enabled only if the search query string is not empty
  });
}
