import {z} from "zod";

export const paymentManagementGenInfoSchema = z.object({
  paymentRequestId: z.string().nullable().optional(),
  merchantCategoryCode: z.string().nullable().optional(),
  dailyTransactionLimitAmount: z.number().nullable().optional(),
  singleTransactionLimitAmount: z.number().nullable().optional(),
  dailyTransactionLimitNumber: z.number().nullable().optional(),
  monthlyTransactionLimitNumber: z.number().nullable().optional(),
  payableDescription: z.string().nullable().optional(),
  singleTranExactAuth: z.boolean().nullable().optional(),
  udf1: z.string().nullable().optional(),
  udf2: z.string().nullable().optional(),
  udf3: z.string().nullable().optional(),
});

export type paymentManagementGenInfo = z.infer<
  typeof paymentManagementGenInfoSchema
>;
