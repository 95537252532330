import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  imREmitDocumentFormatQueryKeys,
  type ImREmitDocumentFormat,
} from "@/modules/imremit";

// Defining a type based on the function `searchImREmitDocumentFormats`
type QueryFnType = typeof getImREmitDocumentFormats;

// Function to search imremits document formats
export function getImREmitDocumentFormats(format: string) {
  return kyApiFn<ImREmitDocumentFormat[]>(
    `api/e-payable/document-formats/${format}`,
    "get"
  );
  // TODO: validate data
}

// Custom hook to fetch and cache imremits document formats data using react-query
export function useGetImREmitDocumentFormats(
  format: string,
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: imREmitDocumentFormatQueryKeys.byFormat(format), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getImREmitDocumentFormats(format), // Setting the function to be used for fetching data
    enabled: !!format,
  });
}
