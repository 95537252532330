import {useEffect} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate, useParams} from "@tanstack/react-router";
import type {KeycloakTokenParsed} from "keycloak-js";
import {ArrowRightIcon, UserCog2Icon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button, buttonVariants} from "@/components/ui/button";
import {Form} from "@/components/ui/form";
import {Heading2, Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {useCustomerStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {
  editUserFn,
  GenericUserForm,
  keycloakCreateUserFormSchema,
  useGetgroupByUserId,
  useGetUserByIdGroup,
} from "@/modules/admin";
import {
  KeycloakRoleEnum,
  useAuthorization,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {
  // SelectCustomerBySupplierForm,
  SelectCustomerForm,
} from "@/modules/customers";

import {KeycloakRoles, KeycloakRolesWithBackslash} from "@/utils/constants";

/**
 * EditUserFormProps Interface
 *
 * @interface EditUserFormProps
 * @property {boolean} isMutating - Indicates whether the mutation is in progress.
 * @property {Function} onSubmit - The function to be called upon form submission.
 */
interface EditUserFormProps {
  isMutating: boolean;
  onSubmit: (data: z.infer<typeof keycloakCreateUserFormSchema>) => void;
}

/**
 * EditUserForm Component
 *
 * This component renders the EditUserForm form and handles its behavior.
 *
 * @param {EditUserFormProps} props - Properties passed to the component
 * @returns {JSX.Element} - Rendered component
 */

type KeycloakTokenWithRDPType = KeycloakTokenParsed & {
  rdpIpaddress: string;
};

type KeycloakTokenSupplier = KeycloakTokenParsed & {
  supplier?: string;
};

function EditUserForm({isMutating, onSubmit}: EditUserFormProps) {
  const {storeCustomer} = useCustomerStore();

  // Extract the `tokenParsed` field from the store
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  // Extract the `rdpIpaddress` field from the tokenParsed object
  const parsedUserInformation =
    storeKeyCloakInstance?.tokenParsed as KeycloakTokenWithRDPType;

  const parsedUserSupplier =
    storeKeyCloakInstance?.tokenParsed as KeycloakTokenSupplier;

  // Extract the `customer` field from the tokenParsed object
  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;
  // Check if the user is a customer admin
  const isBuyerAdmin = parsedUserInfo?.realm_access?.roles.includes(
    "name:customer-admin"
  );

  const editUserForm = useForm<z.infer<typeof keycloakCreateUserFormSchema>>({
    defaultValues: {
      enabled: true,
      emailVerified: true,
      // Buyer Admins can only create users with the customer-user role
      groups: isBuyerAdmin
        ? [
            {
              value: KeycloakRolesWithBackslash.CustomerUser,
              label: KeycloakRoles.CustomerUser,
            },
          ]
        : [],
      rdpIpaddress: parsedUserInformation.rdpIpaddress,
      credentials: {
        type: "password",
        value: "",
        cvalue: "",
        temporary: false,
      },
    },
    mode: "onChange",
    resolver: zodResolver(keycloakCreateUserFormSchema),
  });

  const {userId} = useParams({
    from: "/app/admin/user-management/$userId/edit",
  });
  const searchUserQuery = useGetUserByIdGroup(userId);
  const searchUserData = searchUserQuery.data;

  const {data: userGroups} = useGetgroupByUserId(userId);

  // Reset the form when the data is fetched
  useEffect(() => {
    if (searchUserQuery.isSuccess && !!userGroups) {
      editUserForm.reset({
        username: searchUserData?.username,
        email: searchUserData?.email ?? "",
        firstName: searchUserData?.firstName,
        lastName: searchUserData?.lastName,
        enabled: searchUserData?.enabled,
        emailVerified: searchUserData?.emailVerified,
        groups: userGroups
          .filter(
            (group) =>
              group.path !== KeycloakRolesWithBackslash.BaseRole &&
              group.path !== KeycloakRolesWithBackslash.Management
          )
          .map((group) => ({
            value: group.path,
            label: group.path
              .replace(/\//g, "")
              .replace(/-/g, " ")
              .replace(/\b\w/g, (c) => c.toUpperCase()),
          })),
        requiredActions: searchUserData?.requiredActions,
        credentials: {
          type: "password",
          value: "",
          cvalue: "",
          temporary: false,
        },
        customer: storeCustomer?.buyerName.trim() ?? "",
        supplier: parsedUserSupplier.supplier ?? "",
      });
    }
  }, [
    parsedUserSupplier.supplier,
    searchUserQuery.isSuccess,
    searchUserQuery.data,
    editUserForm,
    searchUserData,
    userGroups,
    storeCustomer?.buyerName,
  ]);

  const {
    formState: {errors},
    setValue,
  } = editUserForm;

  const watchSelectedRole = editUserForm.watch("groups");

  useEffect(() => {
    if (storeCustomer?.buyerName) {
      setValue("customer", storeCustomer.buyerName.trim());
    } else {
      setValue("customer", "");
    }
  }, [storeCustomer?.buyerName, setValue]);

  console.log("errors", errors);
  // console.log("formFields: ", editUserForm.watch());

  return (
    <section>
      <div className="mb-4 flex flex-col-reverse gap-4 md:flex-row md:justify-between">
        <div className="md:w-1/2">
          <Heading2>Edit User</Heading2>
          <Paragraph>
            Fill in the form below to edit the existing user in the system.
          </Paragraph>
        </div>
        <div className="flex justify-end gap-2 space-x-2 md:w-1/2">
          <Link
            to="/app/admin/user-management"
            className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
          >
            Back to users list
            <span className="sr-only">Back to list</span>
            <ArrowRightIcon className="size-4" />
          </Link>
        </div>
      </div>

      <Form {...editUserForm}>
        <form onSubmit={editUserForm.handleSubmit(onSubmit)}>
          <GenericUserForm
            isMutating={isMutating}
            groupsField={null}
            tOtpStatus={searchUserData?.totp}
            isEditing={true}
            selectCustomerOrSupplier={
              <>
                {watchSelectedRole.some((role) =>
                  [
                    "Customer User",
                    "Customer Admin",
                    "Customer Reviewer",
                    "Customer Super Admin",
                  ].includes(role.label)
                ) ? (
                  <SelectCustomerForm
                    cusomterError={
                      errors.customer ? errors.customer.message : ""
                    }
                    requireLabel
                    onChange={(value) => {
                      console.log("Selected Customer:", value);
                      setValue("customer", value);
                    }}
                  />
                ) : watchSelectedRole.some((role) =>
                    [
                      "Supplier User",
                      "Supplier Admin",
                      "Supplier Enablement",
                    ].includes(role.label)
                  ) ? null : null}
              </>
            }
            saveButton={
              <div className="flex items-center justify-end space-x-2 p-2">
                <Button
                  className="gap-2 bg-success-foreground text-white hover:bg-success-foreground/80"
                  type="submit"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                >
                  <UserCog2Icon className="size-4" />
                  <span className="sr-only">Submit form</span>
                  Update User
                </Button>
              </div>
            }
          />
        </form>
      </Form>
    </section>
  );
}

export function EditUserPage() {
  const updateRealmUserMutation = useUpdateRealmUser();
  const {checkPoliciesAccess} = useAuthorization();

  return (
    <>
      {checkPoliciesAccess([KeycloakRoleEnum.UPDATE_USER_MANAGEMENT]) ? (
        <EditUserForm
          isMutating={updateRealmUserMutation.isPending}
          onSubmit={updateRealmUserMutation.mutate}
        />
      ) : (
        <NotAuthorizedPage />
      )}
    </>
  );
}

/**
 * Custom hook for performing the 'Update KeyCloak User' mutation.
 * This hook abstracts the mutation logic and side effects like toast notifications.
 *
 * @returns A mutation object with methods to trigger the mutation.
 */
export function useUpdateRealmUser() {
  const {userId} = useParams({
    from: "/app/admin/user-management/$userId/edit",
  });

  // Get the navigate function from the router
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data: z.infer<typeof keycloakCreateUserFormSchema>) => {
      // Remove data.crendentials.cvalue from the request
      // as it is not needed for the createUserFn
      const {credentials, rdpIpaddress, customer, supplier, groups, ...rest} =
        data;

      // Create a new object without the cvalue property
      const userReq = {
        ...rest,
        credentials: [
          {
            type: credentials.type,
            value: credentials.value,
            temporary: credentials.temporary,
          },
        ],
        // all users are assigned the base role by default
        groups: [
          ...groups.map((group) => group.value),
          KeycloakRolesWithBackslash.BaseRole,
        ],
        attributes: {
          rdpIpaddress: [rdpIpaddress ?? ""],
          customer: [customer],
          supplier: [supplier],
        },
      };
      // console.log("userReq", userReq);
      return editUserFn(userId, userReq);
    },
    onSuccess: (response) => {
      // Handle successful mutation
      console.log("createRealmUserResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "User updated successfully",
      });

      void navigate({
        to: "/app/admin/user-management",
        replace: true,
      });
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update user",
      });
    },
  });
}
