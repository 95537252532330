import {kyApiFn} from "@/lib/ky";
import {type RunnerConfigFormDTO} from "@/modules/admin";

export const updateCustomerRunnerConfigFn = (
  RunnerConfigFormDTO: RunnerConfigFormDTO,
  externalId: string,
  runnerConfigId: number
) =>
  kyApiFn(
    `api/e-payable/management/${externalId}/config/${runnerConfigId}`,
    "put",
    {
      json: RunnerConfigFormDTO,
    }
  );
