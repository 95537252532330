import {Link, Outlet, useNavigate, useRouter} from "@tanstack/react-router";
import {NewspaperIcon} from "lucide-react";

import {buttonVariants} from "@/components/ui/button";
import {Heading1, Paragraph} from "@/components/ui/typography";
import {ReturnToTop} from "@/components/craft/return-to-top";

import {InvoiceTrackerRoutesSchema} from "@/lib/routes/types";
import {cn} from "@/lib/utils";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";
import {SelectSavedSearches} from "@/modules/invoices";

export function InvoiceTrackerOutlet() {
  // Use useRouter to get the current route pathname
  const {pathname} = useRouter().latestLocation;

  // Get the navigate function from the router
  const navigate = useNavigate();

  if (pathname === "/app/invoices" || pathname === "/app/invoices/") {
    void navigate({
      to: "/app/invoices/tracker",
    });
  }

  return (
    <article
      role="region"
      aria-roledescription="This is the Invoice Tracker level one subroute. View your customer's invoices here."
    >
      <section
        className="flex flex-row items-start justify-between"
        role="presentation"
      >
        <div className="mb-4 flex flex-col">
          <Heading1 className="self-start">Invoices</Heading1>
          <Paragraph>
            Welcome to the Invoices Module. Here you can view your customer's
            invoices.
          </Paragraph>
        </div>

        {pathname.includes(
          InvoiceTrackerRoutesSchema.Values["/app/invoices/tracker"]
        ) ? (
          <SelectSavedSearches />
        ) : (
          <Link
            to="/app/invoices/tracker"
            className={cn(buttonVariants({variant: "secondary"}), "mb-4 gap-2")}
          >
            <span className="sr-only">View Invoices Tracker</span>
            View Invoices Tracker
            <NewspaperIcon className="size-4" />
          </Link>
        )}
      </section>

      <AccessPolicyWrapper policyActions={[KeycloakRoleEnum.READ_INVOICES]}>
        <Outlet />
      </AccessPolicyWrapper>

      <ReturnToTop />
    </article>
  );
}
