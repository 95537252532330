import {useEffect} from "react";

import {Link} from "@tanstack/react-router";
import {PackagePlusIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Heading2} from "@/components/ui/typography";

import {AdminRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";

/**
 * SupplierManagementPage Component
 *
 * This component provides the report for Supplier List
 * and rendering the SupplierManagementPage.
 */
export function SupplierManagementPage() {
  const {setAdminRoute} = useRoutesStore();

  useEffect(() => {
    setAdminRoute(AdminRoutesSchema.Values["/app/admin/supplier-management"]);
  }, [setAdminRoute]);

  //Get all suppliers custom hook
  // const searchSuppliersQuery = useGetAllSuppliers();
  // const searchSuppliersData = searchSuppliersQuery.data?.content || [];

  // //Suppliers list columns
  // const SupplierListColumns = getSupplierListColumns();

  return (
    <section
      className="flex flex-col gap-4"
      aria-label="This is the Supplier Management level"
    >
      <header className="flex flex-col gap-4 lg:flex-row lg:items-center lg:justify-between">
        <div className="flex flex-col">
          <Heading2>Supplier Management</Heading2>
        </div>
        <div className="flex flex-col gap-2 lg:flex-row lg:items-center">
          <Link className="mb-4" to="/app/admin/supplier-management/create">
            <Button className="flex gap-2">
              Create New Supplier
              <PackagePlusIcon className="size-6" />
              <span className="sr-only">Create New Supplier</span>
            </Button>
          </Link>
        </div>
      </header>
      {/* <SuppliersListDataTable
        columns={SupplierListColumns}
        data={searchSuppliersData}
      /> */}
    </section>
  );
}
