import {useEffect, useMemo, useState} from "react";

import {Link, useNavigate} from "@tanstack/react-router";
import {ArrowRightIcon, FilePlus2Icon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Heading2} from "@/components/ui/typography";

import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {
  SupplierScriptExportData,
  SupplierScriptsViewTimeline,
  useGetSupplierScripts,
  type ModuleTypeUnion,
} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";

interface ViewGenericSupplierScriptPageProps {
  supplierId: string;
  moduleName: string;
}

export function ViewGenericSupplierScriptPage({
  supplierId,
  moduleName,
}: ViewGenericSupplierScriptPageProps) {
  const navigate = useNavigate();

  // const {imREmitStoreCustomer} = useImRemitCustomerStore();
  const [currentSupplier, setCurrentSupplier] = useState<string | number>("");

  //supplier script page url
  const supplierScriptPageUrl = `/app/${
    moduleName as ModuleTypeUnion
  }/supplier-script-management` as const;

  // Fetch customer data from the customer store
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;
  let userEmail: string = "";

  !parsedUserInfo
    ? (userEmail = FallbackMessages.NOT_APPLICABLE)
    : (userEmail = parsedUserInfo.email as string);

  useEffect(() => {
    setCurrentSupplier(supplierId);
  }, [supplierId]);

  const searchSupplierScriptsQuery = useGetSupplierScripts(
    currentSupplier.toString()
  );
  const searchSupplierScriptsQueryData = useMemo(() => {
    if (!searchSupplierScriptsQuery.data?.content) return [];

    return searchSupplierScriptsQuery.data.content;
  }, [searchSupplierScriptsQuery]);

  const supplierScripts = useMemo(() => {
    return searchSupplierScriptsQueryData.map((supplierScript, i) => {
      return {
        id: i,
        ...supplierScript,
      };
    });
  }, [searchSupplierScriptsQueryData]);

  return (
    <section className="flex flex-col gap-4">
      <header className="flex flex-col gap-4 lg:flex-row lg:items-center lg:justify-between">
        <div className="flex flex-col">
          <Heading2>View Supplier Scripts</Heading2>
        </div>

        <div className="flex flex-col gap-2 lg:flex-row lg:items-center">
          <AccessPolicyWrapper
            unauthorizedFallback={null}
            policyActions={
              moduleName === "imremit"
                ? [KeycloakRoleEnum.UPDATE_SUPPLIER_SCRIPT_MANAGEMENT]
                : moduleName === "imremit-lite"
                ? [KeycloakRoleEnum.UPDATE_SUPPLIER_SCRIPT_MANAGEMENT_LITE]
                : []
            }
          >
            <Link
              to={`/app/${
                moduleName as ModuleTypeUnion
              }/supplier-script-management/$moduleName/$supplierId/edit/$paymentId`}
              params={{
                moduleName: moduleName,
                supplierId: currentSupplier.toString(),
                paymentId: "0",
              }}
            >
              <Button
                className="whitespace-nowrap"
                disabled={currentSupplier === ""}
              >
                <span className="sr-only">Update Script</span>
                <FilePlus2Icon className="mr-2" />
                Update Script
              </Button>
            </Link>
          </AccessPolicyWrapper>

          <SupplierScriptExportData
            id={currentSupplier.toString()}
            userEmail={userEmail}
          />

          <Button
            variant="secondary"
            onClick={() => {
              void navigate({
                to: supplierScriptPageUrl,
                replace: true,
              });
            }}
          >
            Back to list
            <ArrowRightIcon className="ml-2 size-4" />
          </Button>
        </div>
      </header>
      <section>
        <SupplierScriptsViewTimeline
          supplierId={Number(currentSupplier)}
          supplierScriptItems={supplierScripts}
          setCurrentSupplier={setCurrentSupplier}
        />
      </section>
    </section>
  );
}
