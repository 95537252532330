import * as React from "react";
import {useEffect, useMemo} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {type Table} from "@tanstack/react-table";
import {format} from "date-fns";
import {toZonedTime} from "date-fns-tz";
import {
  Calendar as CalendarIcon,
  CheckIcon,
  ChevronsUpDownIcon,
  ListRestartIcon,
} from "lucide-react";
import type {DateRange} from "react-day-picker";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {Calendar} from "@/components/ui/calendar";
import {Command, CommandGroup, CommandItem} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {toast} from "@/components/ui/use-toast";
import {AdvancedSearch} from "@/components/craft/advanced-search";
import {DataTableViewOptions} from "@/components/craft/data-table";
import {FancyMultiSelect} from "@/components/craft/fancy-multi-select";
import {DataTableFacetedFilter} from "@/components/craft/table";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

import {useImRemitLiteCustomerStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {useAllRealmGroups, useGetUsersByRoleGroup} from "@/modules/admin";
import {
  PaymentManagementFormSchema,
  remittanceMethods,
  toCamelCase,
  useFetchAndParsePopulationData,
  // useGetOrganizationsList,
  usePaymentCriteriaFields,
} from "@/modules/imremit";
import {usePaymentManagementMetaStore} from "@/modules/imremit-lite";

import {KeycloakRolesWithHyphen} from "@/utils/constants";

// This is the main component, "PaymentManagementDataTableToolbar". It expects the `table` object as a prop.
interface DataTableToolbarProps<TData> {
  table: Table<TData>;
}

export function PaymentManagementDataTableToolbar<TData>({
  table,
}: DataTableToolbarProps<TData>) {
  // Fetch customer data from the customer store
  const {imRemitLiteStoreCustomer} = useImRemitLiteCustomerStore();

  const [date, setDate] = React.useState<DateRange | undefined>();

  // Accessing the pagination meta and page change handler from the store.
  const {setStorePaymentManagementCriteria} = usePaymentManagementMetaStore();

  // Fetching organization data
  // const allOrganizationData = useGetOrganizationsList(
  //   imRemitLiteStoreCustomer?.externalId
  // ).data?.content;

  // Grouping the org id's
  // const orgList = useMemo(() => {
  //   const orgIdSet = new Set<string>();

  //   allOrganizationData?.forEach((res) => {
  //     orgIdSet.add(res.orgId);
  //   });

  //   return Array.from(orgIdSet);
  // }, [allOrganizationData]);

  // const {dateRange} = useSearch({
  //   from: imREmitPaymentManagementRoute.id,
  // });

  // Initialize useForm hook provided by react-hook-form with default values, mode, and resolver.
  // It uses the schema defined earlier to validate the form values.
  const paymentManagementFormMethods = useForm<
    z.infer<typeof PaymentManagementFormSchema>
  >({
    defaultValues: {
      remittanceMethod: "",
      accountNumber: "",
      paymentNumber: "",
      initiatedDate: "",
      endDate: "",
      suppliers: [],
      status: [],
    },
    mode: "onChange",
    resolver: zodResolver(PaymentManagementFormSchema),
  });

  // Destructure formState to get 'isDirty', it is true if user has interacted with any of the fields.
  // Also, 'watch' is used to observe changes in form field values.
  const {
    formState: {isDirty},
  } = paymentManagementFormMethods;

  // Destructure the form fields from the form object.
  // This is used to generate the search query.
  const formFields = paymentManagementFormMethods.watch();

  console.log("formFields:", formFields);

  useEffect(() => {
    const fromDate = date?.from
      ? toZonedTime(date.from, "UTC").toString()
      : undefined;
    const toDate = date?.to
      ? toZonedTime(date.to, "UTC").toString()
      : undefined;
    if (fromDate && !toDate) {
      paymentManagementFormMethods.setError("endDate", {
        type: "manual",
        message: "Please select full date range.",
      });
    }
    if (fromDate && toDate) {
      paymentManagementFormMethods.setValue("initiatedDate", fromDate, {
        shouldDirty: true,
      });
      paymentManagementFormMethods.setValue("endDate", toDate, {
        shouldDirty: true,
      });

      paymentManagementFormMethods.setError("endDate", {
        type: "manual",
        message: "",
      });
    }
  }, [date, paymentManagementFormMethods]);

  const criteriaFields = usePaymentCriteriaFields(formFields);

  // Effect hook to set the search criteria for storing invoices.
  useEffect(() => {
    setStorePaymentManagementCriteria(criteriaFields);
  }, [criteriaFields, setStorePaymentManagementCriteria]);

  const moduleType: string = "lite";
  const {suppliersData, statusData} = useFetchAndParsePopulationData(
    moduleType,
    imRemitLiteStoreCustomer?.externalId
  );

  // Get the group id
  const realmGroupsList = useAllRealmGroups();
  const getImRemitManagerId =
    realmGroupsList.data?.find(
      ({name}) => name === KeycloakRolesWithHyphen.ProgramManager
    )?.id || "";

  /**
   * Get ImRemit User List
   * Role Group Id for Program Manager
   */
  const imRemitUserListQuery = useGetUsersByRoleGroup(getImRemitManagerId);
  const epayManagerAssigned = imRemitUserListQuery.data;

  // Format the users data to match the format needed by.
  const usersData = useMemo(() => {
    if (!epayManagerAssigned) return [];

    return epayManagerAssigned
      .map((users) => {
        return {
          label: `${users.firstName}${
            users.lastName ? ` ${users.lastName}` : ""
          }`,
          value: users.id ? users.id : "",
        };
      })
      .filter(Boolean);
  }, [epayManagerAssigned]);

  // The onSubmit function is called when the form is submitted.
  // It displays a toast notification with the submitted data.
  function onSubmit(data: z.infer<typeof PaymentManagementFormSchema>) {
    toast({
      title: "You submitted the following values:",
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data, null, 2)}</code>
        </pre>
      ),
    });
  }

  //Columns other names
  const columnsOtherName = [
    {key: "supplierName", value: "Supplier Name"},
    {key: "supplierNumber", value: "Supplier ID"},
    {key: "facilityName", value: "Customer Name"},
    {key: "buyerAlias", value: "Customer Alias"},
    {key: "paymentNumber", value: "Payment Number"},
    {key: "totalAmountSent", value: "Sent Amount"},
    {key: "initiatedDate", value: "Sent Date"},
    {key: "customerStatus", value: "Status"},
    {key: "statusName", value: "Status Name"},
    {key: "description", value: "Status Description"},
    {key: "descriptionDetail", value: "Description Detail"},
    {key: "amountTaken", value: "Amount Taken"},
    {key: "balanceAmount", value: "Balance Amount"},
    {key: "userId", value: "Agent Name"},
    {key: "remittanceMethod", value: "Remittance Method"},
  ];

  // Return the form elements and fields as JSX. It uses Form, FormItem, FormControl, and FormMessage
  // components to structure and style the form.
  return (
    <>
      <Form {...paymentManagementFormMethods}>
        <form onSubmit={paymentManagementFormMethods.handleSubmit(onSubmit)}>
          <div className="flex justify-between gap-4">
            <div className="flex flex-wrap items-end gap-2">
              <RowsPreferenceSelect />
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    id="date"
                    variant={"outline"}
                    className={cn(
                      "w-[300px] justify-start text-left font-normal",
                      !date && "text-accent-foreground"
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {date?.from ? (
                      date.to ? (
                        <>
                          {format(date.from, "LLL dd, y")} -{" "}
                          {format(date.to, "LLL dd, y")}
                        </>
                      ) : (
                        format(date.from, "LLL dd, y")
                      )
                    ) : (
                      <span>Start Date & End Date</span>
                    )}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar
                    mode="range"
                    selected={date}
                    numberOfMonths={1}
                    captionLayout="dropdown-buttons"
                    fromYear={1970}
                    toYear={2050}
                    initialFocus
                    onSelect={setDate}
                  />
                </PopoverContent>
              </Popover>

              {/* <FormField
                name="accountNumber"
                render={() => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder="Enter account number..."
                        {...paymentManagementFormMethods.register(
                          "accountNumber"
                        )}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              /> */}
              <FormField
                name="paymentNumber"
                render={() => (
                  <FormItem>
                    <FormControl>
                      <Input
                        placeholder="Enter payment number..."
                        {...paymentManagementFormMethods.register(
                          "paymentNumber"
                        )}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="suppliers"
                render={({field}) => (
                  <FormItem className="flex flex-col">
                    <FormControl>
                      <FancyMultiSelect
                        {...field}
                        multiSelectData={suppliersData}
                        customWidth="370px"
                        disabled={!suppliersData.length}
                        placeholder={
                          !suppliersData.length
                            ? "No suppliers..."
                            : "Search suppliers..."
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={paymentManagementFormMethods.control}
                name="remittanceMethod"
                render={({field}) => (
                  <div className="space-y-2">
                    <FormItem>
                      <Popover>
                        <PopoverContent className="w-[260px] p-0">
                          <Command>
                            <CommandGroup>
                              {remittanceMethods.map((data, i) => (
                                <CommandItem
                                  key={i}
                                  value={toCamelCase(data.value) || ""}
                                  onSelect={(value) => {
                                    field.onChange(toCamelCase(value));
                                  }}
                                >
                                  <CheckIcon
                                    className={cn(
                                      "mr-2 size-4",
                                      toCamelCase(data.value)
                                        .trim()
                                        .toUpperCase() ===
                                        toCamelCase(field.value)
                                          .trim()
                                          .toUpperCase()
                                        ? "opacity-100"
                                        : "opacity-0"
                                    )}
                                  />
                                  {toCamelCase(data.label)}
                                </CommandItem>
                              ))}
                            </CommandGroup>
                          </Command>
                        </PopoverContent>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant="outline"
                              role="combobox"
                              className={cn(
                                "justify-between",
                                !field.value && "text-accent-foreground",
                                "w-[260px]"
                              )}
                            >
                              {field.value
                                ? toCamelCase(
                                    remittanceMethods.find(
                                      (item) =>
                                        toCamelCase(item.value) ===
                                        toCamelCase(field.value)
                                    )?.label
                                  )
                                : "Select Remittance Type..."}
                              <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  </div>
                )}
              />

              <FormField
                name="initiatedDate"
                render={() => (
                  <FormItem className="flex flex-col">
                    <Input
                      placeholder="Start Date"
                      {...paymentManagementFormMethods.register(
                        "initiatedDate"
                      )}
                      className="hidden"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="endDate"
                render={() => (
                  <FormItem className="flex flex-col">
                    <Input
                      placeholder="End Date"
                      {...paymentManagementFormMethods.register("endDate")}
                      className="hidden"
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />

              {isDirty && (
                <Button
                  className="gap-2 border-destructive-foreground/60 bg-destructive text-destructive-foreground hover:border-destructive-foreground/80 hover:bg-destructive-foreground/40 hover:text-destructive-foreground active:bg-destructive-foreground/60 active:text-destructive-foreground"
                  variant="outline"
                  onClick={() => {
                    paymentManagementFormMethods.reset();
                    setDate(undefined);
                  }}
                >
                  <ListRestartIcon className="size-4" />
                  Reset
                </Button>
              )}
            </div>

            <div className="flex flex-col items-end gap-2 lg:flex-row">
              <AdvancedSearch>
                <div className="grid grid-cols-1 gap-x-2 gap-y-2 lg:grid-cols-2 lg:gap-x-4 lg:gap-y-4 xl:grid-cols-3 2xl:grid-cols-4">
                  {/* <FormField
                    control={paymentManagementFormMethods.control}
                    name="orgId"
                    render={({field}) => (
                      <div className="space-y-2">
                        <FormLabel htmlFor="orgId">Organization ID:</FormLabel>
                        <FormItem>
                          <Popover>
                            <PopoverContent className="w-[260px] p-0">
                              <Command>
                                <CommandGroup>
                                  {orgList.map((organizationId, i) => (
                                    <CommandItem
                                      key={i}
                                      value={organizationId || ""}
                                      onSelect={(value) => {
                                        field.onChange(value);
                                      }}
                                    >
                                      <CheckIcon
                                        className={cn(
                                          "mr-2 size-4",
                                          organizationId
                                            .toString()
                                            .toUpperCase() ===
                                            field.value
                                              ?.toString()
                                              .toUpperCase()
                                            ? "opacity-100"
                                            : "opacity-0"
                                        )}
                                      />
                                      {organizationId}
                                    </CommandItem>
                                  ))}
                                </CommandGroup>
                              </Command>
                            </PopoverContent>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant="outline"
                                  role="combobox"
                                  className={cn(
                                    "justify-between",
                                    !field.value && "text-accent-foreground",
                                    "w-[260px]"
                                  )}
                                >
                                  {field.value
                                    ? field.value.toString().toUpperCase()
                                    : "Select Organization ID"}
                                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                          </Popover>
                          <FormMessage />
                        </FormItem>
                      </div>
                    )}
                  /> */}

                  <FormField
                    control={paymentManagementFormMethods.control}
                    name="userId"
                    render={({field}) => (
                      <div className="space-y-2">
                        <FormLabel htmlFor="userId">imRemit Agent:</FormLabel>
                        <FormItem>
                          <Popover>
                            <PopoverContent className="w-[260px] p-0">
                              <Command>
                                <CommandGroup>
                                  <CommandItem
                                    value=""
                                    onSelect={() => {
                                      paymentManagementFormMethods.setValue(
                                        "userId",
                                        field.value,
                                        {
                                          shouldDirty: true,
                                        }
                                      );
                                    }}
                                  >
                                    <CheckIcon
                                      className={cn(
                                        "mr-2 size-4",
                                        "" === field.value
                                          ? "opacity-100"
                                          : "opacity-0"
                                      )}
                                    />
                                    All
                                  </CommandItem>
                                  {usersData.map((data, i) => (
                                    <CommandItem
                                      key={i}
                                      value={data.value || ""}
                                      onSelect={(value) => {
                                        field.onChange(value);
                                      }}
                                    >
                                      <CheckIcon
                                        className={cn(
                                          "mr-2 size-4",
                                          data.value.toString().trim() ===
                                            field.value?.toString().trim()
                                            ? "opacity-100"
                                            : "opacity-0"
                                        )}
                                      />
                                      {data.label}
                                    </CommandItem>
                                  ))}
                                </CommandGroup>
                              </Command>
                            </PopoverContent>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant="outline"
                                  role="combobox"
                                  className={cn(
                                    "justify-between",
                                    !field.value && "text-accent-foreground",
                                    "w-[260px]"
                                  )}
                                >
                                  {field.value
                                    ? toCamelCase(
                                        usersData.find(
                                          (item) =>
                                            toCamelCase(item.value) ===
                                            toCamelCase(field.value)
                                        )?.label
                                      )
                                    : "All"}
                                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                          </Popover>
                          <FormMessage />
                        </FormItem>
                      </div>
                    )}
                  />
                </div>
              </AdvancedSearch>
              <FormField
                name="status"
                render={({field}) => (
                  <FormItem className="flex flex-col">
                    <FormControl>
                      <DataTableFacetedFilter
                        {...field}
                        facetedFilterData={statusData}
                        title="Status"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DataTableViewOptions
                table={table}
                columnsOtherName={columnsOtherName}
              />
            </div>
          </div>
        </form>
      </Form>
    </>
  );
}
