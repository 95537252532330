import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  paymentRunnerConfigKeys,
  type PaymentRunnerConfigType,
} from "@/modules/imremit";

// Defining a type based on the function `getAllPaymentRunnerConfigs`
type QueryFnType = typeof getAllPaymentRunnerConfigs;

// Function to search getAllPaymentRunnerConfigs
export function getAllPaymentRunnerConfigs(externalId?: string) {
  const exId = externalId || "";
  return kyApiFn<PaymentRunnerConfigType[]>(
    `api/e-payable/management/${exId}/config/1`,
    "get"
  );
}

// Custom hook to fetch and cache Runner Config list based on the `participant id` data using react-query
export function useGetAllPaymentRunnerConfigs(
  externalId?: string, // The search query string
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: paymentRunnerConfigKeys.byExternalId(externalId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getAllPaymentRunnerConfigs(externalId), // Setting the function to be used for fetching data
    enabled: !!externalId, // Setting the query to be enabled only if the search query string is not empty
  });
}
