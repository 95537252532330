import {create} from "zustand";

import {type CustomerType} from "@/modules/customers";

// Defining the shape of the state object for the CustomerStore
// In this case, the state will consist of an array of Customer objects
interface ImRemitLiteCustomersStoreState {
  imRemitLiteStoreCustomer: CustomerType | null;
}

// Defining the actions that can be performed on the CustomerStore state
// In this case, there's only one action, updateImRemitLiteStoreCustomer, which takes an array of Customer objects
interface ImRemitLiteCustomerStoreActions {
  updateImRemitLiteStoreCustomer: (
    imRemitLiteStoreCustomer: ImRemitLiteCustomersStoreState["imRemitLiteStoreCustomer"]
  ) => void;
}

// Creating the Zustand store using the create function
// The store is named useImRemitLiteCustomerStore, and it uses the types defined above for its state and actions
// The state begins with an empty imRemitLiteStoreCustomer array
// The updateImRemitLiteStoreCustomer action uses Zustand's set function to update the state
export const useImRemitLiteCustomerStore = create<
  ImRemitLiteCustomersStoreState & ImRemitLiteCustomerStoreActions
>((set) => ({
  // Initial state of the store. An empty array of imRemitLiteStoreCustomer.
  imRemitLiteStoreCustomer: null,

  // Action to update the imRemitLiteStoreCustomer array in the store.
  // This function takes a new imRemitLiteStoreCustomer array and uses the set function to update the state.
  updateImRemitLiteStoreCustomer: (imRemitLiteStoreCustomer) => {
    set(() => ({imRemitLiteStoreCustomer}));
  },
}));
