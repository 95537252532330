import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  filteredCustomersQueryKeys,
  type CustomerType,
} from "@/modules/customers";

import {ModuleNames} from "@/utils/constants";

// Defining a type based on the function `getFilteredCustomers`
type QueryFnType = typeof getFilteredCustomers;

// Function to fetch all customers
export function getFilteredCustomers(moduleName: string) {
  // Fetch all customers from API using ky
  const data = kyApiFn<CustomerType[]>(
    `customer/v1/customers?moduleName=${moduleName}`,
    "get"
  );
  // TODO: validate data
  // return customerSchema.parse(data);
  return data; // Returning the fetched data
}

// Custom hook to fetch and cache all imRemit lite customers data using react-query
export function useFilteredCustomers(
  moduleName: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: filteredCustomersQueryKeys.byModuleName(moduleName), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getFilteredCustomers(moduleName), // Setting the function to be used for fetching data
    // TODO: add onSuccess and onError handlers
  });
}

/**
 * Custom React hook to fetch and process customer data for the select customer form.
 * @returns {Object} An object containing customer related states and data.
 */
export function useFetchSelectCustomerFormImRemit() {
  const {
    data: imREmitCustomersRawData,
    isPending: imREmitCustomersArePending,
    isFetched: imREmitCustomersAreFetched,
    isError: imREmitCustomersError,
  } = useFilteredCustomers(ModuleNames.imremit);

  const imREmitCustomersContent = imREmitCustomersRawData?.content || [];

  const imREmitCustomerBuyerNames = imREmitCustomersContent.map(
    (imREmitCustomer) => ({
      businessNameLabel: imREmitCustomer.buyerName,
      businessNameValue: imREmitCustomer.buyerName,
    })
  );

  return {
    imREmitCustomersArePending,
    imREmitCustomersAreFetched,
    imREmitCustomersError,
    imREmitCustomersContent,
    imREmitCustomerBuyerNames,
  };
}

/**
 * Custom React hook to fetch and process customer data for the select customer form.
 * @returns {Object} An object containing customer related states and data.
 */
export function useFetchSelectCustomerLiteForm() {
  const {
    data: imREmitLiteCustomersRawData,
    isPending: imREmitLiteCustomersArePending,
    isFetched: imREmitLiteCustomersAreFetched,
    isError: imREmitLiteCustomersError,
  } = useFilteredCustomers(ModuleNames.imremitLite);

  // Extract the data (customers) from the response
  const imREmitLiteCustomersContent =
    imREmitLiteCustomersRawData?.content || [];

  // Create a set to store unique businessNameLabel values
  const uniqueBusinessNames = new Set<string>();

  // Filter out duplicates while mapping over imREmitLiteCustomersContent
  const imREmitLiteCustomerBuyerNames = imREmitLiteCustomersContent.reduce(
    (
      uniqueNames: {businessNameLabel: string; businessNameValue: string}[],
      imREmitCustomer
    ) => {
      if (!uniqueBusinessNames.has(imREmitCustomer.buyerName)) {
        uniqueBusinessNames.add(imREmitCustomer.buyerName);
        uniqueNames.push({
          businessNameLabel: imREmitCustomer.buyerName,
          businessNameValue: imREmitCustomer.buyerName,
        });
      }
      return uniqueNames;
    },
    []
  );

  return {
    imREmitLiteCustomersArePending,
    imREmitLiteCustomersAreFetched,
    imREmitLiteCustomersError,
    imREmitLiteCustomersContent,
    imREmitLiteCustomerBuyerNames,
  };
}
