// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type KeycloakUserManagementColumnsType} from "@/modules/admin";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface UserManagementAdminTableColumnsStoreState {
  storeUserManagementAdminTableColumns: KeycloakUserManagementColumnsType | null;
  setStoreUserManagementAdminTableColumns: (
    storeUserManagementAdminTableColumns: KeycloakUserManagementColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for UserManagementAdmin table columns
export const useUserManagementAdminTableColumnsStore = create(
  persist<UserManagementAdminTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeUserManagementAdminTableColumns: null,

      // Getter function to retrieve the current state
      getStoreUserManagementAdminTableColumns: () =>
        get().storeUserManagementAdminTableColumns,

      // Setter function to update the state
      setStoreUserManagementAdminTableColumns: (
        storeUserManagementAdminTableColumns
      ) => {
        set(() => ({storeUserManagementAdminTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "admin-user-management-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useUserManagementAdminTableColumns = () =>
  useUserManagementAdminTableColumnsStore((state) => {
    return {
      storeUserManagementAdminTableColumns:
        state.storeUserManagementAdminTableColumns,
    };
  });
