import {kyApiFn} from "@/lib/ky";

export const updatePaymentCustomerMappingFn = (
  paymentDetailId: string,
  externalId: string
) => {
  return kyApiFn(
    `api/e-payable/management/payment-details/update-participant/${paymentDetailId}?parentExternalId=${externalId}`,
    "put"
  );
};
