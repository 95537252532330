import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {statementReconSettingsKeys} from "@/modules/statement-recon";
import type {
  CreateSettingBodyType,
  SRSettingModificationType,
  SRSettingType,
  UpdateBooleanSettingsBodyType,
  UpdateCharacterSettingsBodyType,
} from "@/modules/statement-recon";

type getSettingsQueryFnType = typeof getSettings;
type getSettingsByExternalIdQueryFnType = typeof getSettingsByExternalId;
type getSettingsByExternalIdAndSupplierIdQueryFnType =
  typeof getSettingsByExternalIdAndSupplierId;

/**
 * Function to fetch all statement reconciliation settings from the API.
 * 📡 Performs a GET request to retrieve all settings.
 * @returns A promise that resolves to an array of `SRSettingType`.
 */
export function getSettings() {
  return kyApiFn<SRSettingType[]>(`statement-recon/v1/settings`, "get");
}

/**
 * Custom hook to fetch and cache all statement reconciliation settings using react-query.
 * 🪝 Uses `useQuery` from react-query for fetching and caching.
 * @param config Optional query configuration.
 * @returns React Query's `useQuery` result containing the settings.
 */
export function useSettings(config?: QueryConfig<getSettingsQueryFnType>) {
  return useQuery<ExtractFnReturnType<getSettingsQueryFnType>>({
    ...config,
    queryKey: statementReconSettingsKeys.all,
    queryFn: () => getSettings(),
  });
}

/**
 * Function to fetch statement reconciliation settings by external ID from the API.
 * 📡 Performs a GET request to retrieve settings based on the external ID.
 * @param buyer_external_id The external ID of the buyer.
 * @returns A promise that resolves to `SRSettingModificationType` for the given external ID.
 */
export function getSettingsByExternalId(buyer_external_id: string) {
  return kyApiFn<SRSettingModificationType[]>(
    `statement-recon/v1/settings/${buyer_external_id}`,
    "get"
  );
}

/**
 * Custom hook to fetch and cache statement reconciliation settings by external ID using react-query.
 * 🪝 Uses `useQuery` from react-query for fetching and caching.
 * @param buyer_external_id The external ID of the buyer.
 * @param config Optional query configuration.
 * @returns React Query's `useQuery` result containing the settings for the given external ID.
 */
export function useSettingsByExternalId(
  buyer_external_id: string,
  config?: QueryConfig<getSettingsByExternalIdQueryFnType>
) {
  return useQuery<ExtractFnReturnType<getSettingsByExternalIdQueryFnType>>({
    ...config,
    queryKey: statementReconSettingsKeys.byExternalId(buyer_external_id),
    queryFn: () => getSettingsByExternalId(buyer_external_id),
    enabled: !!buyer_external_id,
  });
}

/**
 * Function to fetch statement reconciliation settings by external ID and supplier ID from the API.
 * 📡 Performs a GET request to retrieve settings based on external ID and supplier ID.
 * @param buyer_external_id The external ID of the buyer.
 * @param supplier_id The supplier ID.
 * @returns A promise that resolves to `SRSettingModificationType` for the given external and supplier IDs.
 */
export function getSettingsByExternalIdAndSupplierId(
  buyer_external_id: string,
  supplier_id: string
) {
  return kyApiFn<SRSettingModificationType[]>(
    `statement-recon/v1/settings/${buyer_external_id}/${supplier_id}`,
    "get"
  );
}

/**
 * Custom hook to fetch and cache statement reconciliation settings by external ID and supplier ID using react-query.
 * 🪝 Uses `useQuery` from react-query for fetching and caching.
 * @param buyer_external_id The external ID of the buyer.
 * @param supplier_id The supplier ID.
 * @param config Optional query configuration.
 * @returns React Query's `useQuery` result containing the settings for the given external ID and supplier ID.
 */
export function useSettingsByExternalIdAndSupplierId(
  buyer_external_id: string,
  supplier_id: string,
  config?: QueryConfig<getSettingsByExternalIdAndSupplierIdQueryFnType>
) {
  return useQuery<
    ExtractFnReturnType<getSettingsByExternalIdAndSupplierIdQueryFnType>
  >({
    ...config,
    queryKey: statementReconSettingsKeys.byExternalIdAndSupplierId(
      buyer_external_id,
      supplier_id
    ),
    queryFn: () =>
      getSettingsByExternalIdAndSupplierId(buyer_external_id, supplier_id),
    enabled: !!buyer_external_id,
  });
}

/**
 * Function to add a new statement reconciliation settings modification.
 * 📡 Performs a POST request to create new settings modification.
 * @param data The data to create the new settings modification.
 * @returns A promise that resolves to an array of `SRSettingModificationType` after adding the modification.
 */
export function addSettingsModificationFn(data: CreateSettingBodyType) {
  return kyApiFn<SRSettingModificationType[]>(
    `statement-recon/v1/settings`,
    "post",
    {
      json: data,
    }
  );
}

/**
 * Function to update an existing statement reconciliation settings modification by modification ID.
 * 📡 Performs a PUT request to update the modification with the given ID.
 * @param sr_settings_modification_id The ID of the settings modification to update.
 * @param data The updated data for the modification.
 * @returns A promise that resolves to the updated `SRSettingModificationType`.
 */
export function updateSettingsModificationByModificationIdFn(
  sr_settings_modification_id: string,
  data: UpdateCharacterSettingsBodyType
) {
  return kyApiFn<SRSettingModificationType>(
    `statement-recon/v1/settings/${sr_settings_modification_id}`,
    "put",
    {json: data}
  );
}

/**
 * Function to update an existing statement reconciliation settings modification by settings ID and buyer external ID.
 * 📡 Performs a PUT request to update the modification with the given settings ID and external ID.
 * @param buyer_external_id The external ID of the buyer.
 * @param sr_settings_id The settings ID to update.
 * @param data The updated data for the modification.
 * @returns A promise that resolves to the updated `SRSettingModificationType`.
 */
export function updateSettingsModificationBySettingsIdFn(
  buyer_external_id: string,
  sr_settings_id: number,
  data: UpdateBooleanSettingsBodyType
) {
  return kyApiFn<SRSettingModificationType>(
    `statement-recon/v1/settings/${buyer_external_id}/${sr_settings_id.toString()}`,
    "put",
    {json: data}
  );
}

/**
 * Function to delete an existing statement reconciliation settings modification by modification ID.
 * 🚫 Performs a DELETE request to remove the settings modification with the given ID.
 * @param sr_settings_modification_id The ID of the settings modification to delete.
 */
export const deleteSettingsModification = (
  sr_settings_modification_id: string
) =>
  kyApiFn(
    `statement-recon/v1/settings/${sr_settings_modification_id}`,
    "delete"
  );
