import {useMemo} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {CheckIcon, ChevronsUpDownIcon, PlusCircleIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import {z} from "zod";

import {Button} from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {toast} from "@/components/ui/use-toast";

import {cn} from "@/lib/utils";
import {useAllCustomers} from "@/modules/customers";

const moduleList = [
  {label: "imREmit", value: "imREmit"},
  {label: " Duplicate Payments", value: "duplicatepayments"},
];

const CustomerModuleRelationFormSchema = z.object({
  module: z.string().optional(),
  customer: z.string().optional(),
});

export function CustomerModuleRelationForm() {
  const form = useForm<z.infer<typeof CustomerModuleRelationFormSchema>>({
    resolver: zodResolver(CustomerModuleRelationFormSchema),
  });

  function onSubmit(data: z.infer<typeof CustomerModuleRelationFormSchema>) {
    toast({
      title: "You submitted the following values:",
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data, null, 2)}</code>
        </pre>
      ),
    });
  }

  const allCustomersQuery = useAllCustomers();
  const rawCustomerData = allCustomersQuery.data?.content;

  // Format the customer data to match the format needed by FancyMultiSelect component.
  const customerData = useMemo(() => {
    if (!rawCustomerData) return [];
    return rawCustomerData.map((customer) => ({
      label: customer.buyerName,
      value: customer.id.toString(),
    }));
  }, [rawCustomerData]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>New Customer Module Relationship</CardTitle>
        <CardDescription>
          This form allows administrators to add a new PID.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form
            className="flex flex-col space-y-6"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="module"
              render={({field}) => (
                <div className="space-y-2">
                  <FormLabel htmlFor="module">Module</FormLabel>
                  <FormItem className="w-[200px]">
                    <Popover>
                      <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                        <Command>
                          <CommandGroup>
                            {moduleList.map((module, i) => (
                              <CommandItem
                                key={i}
                                value={module.value}
                                onSelect={(value) => {
                                  field.onChange(value);
                                }}
                              >
                                <CheckIcon
                                  className={cn(
                                    "mr-2 size-4",
                                    field.value &&
                                      field.value.toUpperCase() === module.value
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                                {module.label}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </Command>
                      </PopoverContent>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            role="combobox"
                            className={cn(
                              "justify-between",
                              !field.value && "text-accent-foreground",
                              "w-full"
                            )}
                          >
                            {field.value
                              ? moduleList.find(
                                  (item) =>
                                    item.value.toLowerCase() === field.value
                                )?.label
                              : "Select module"}
                            <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                </div>
              )}
            />
            <FormField
              control={form.control}
              name="customer"
              render={({field}) => (
                <FormItem className="flex flex-col">
                  <FormLabel htmlFor="customer">Select Customer</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          className={cn(
                            "w-[200px] justify-between",
                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                            !field.value && "text-accent-foreground"
                          )}
                        >
                          {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                          {field.value || "Select a customer"}
                          <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                      <Command>
                        <CommandInput placeholder="Search customers..." />
                        <CommandEmpty>No customers found.</CommandEmpty>
                        <CommandGroup>
                          {customerData.map((customer) => (
                            <CommandItem
                              key={customer.value}
                              value={customer.value}
                              onSelect={(value) => {
                                form.setValue("customer", value);
                              }}
                            >
                              <CheckIcon
                                className={cn(
                                  "mr-2 size-4",
                                  customer.value.toLowerCase() === field.value
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              {customer.label}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className="max-w-fit self-end" type="submit">
              Submit <PlusCircleIcon className="ml-2" />
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
