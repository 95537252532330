import {kyApiFn} from "@/lib/ky";
import {type bankMappingFormType} from "@/modules/imremit";

export const saveBankFileFn = (bankMappingFormType: bankMappingFormType) =>
  kyApiFn(`api/e-payable/admin/master-mapping/bank`, "post", {
    json: bankMappingFormType,
  });

export const saveBankFileBAI2Fn = (bankMappingFormType: bankMappingFormType) =>
  kyApiFn(`api/e-payable/admin/master-mapping/bank/bai2-format`, "post", {
    json: bankMappingFormType,
  });
