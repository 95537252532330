import {useState} from "react";

import {useMutation} from "@tanstack/react-query";
import {useNavigate} from "@tanstack/react-router";
import {FileTextIcon, GripVerticalIcon, RefreshCcwDot} from "lucide-react";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {Button} from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {toast} from "@/components/ui/use-toast";
import {LoadingSection} from "@/components/craft/loading-section";

import {paymentReProcessFn} from "@/modules/imremit";

/**
 * Props for PaymentManagementRowActions component.
 * @typedef {Object} PaymentManagementRowActionsProps
 * @paymentId String - The type of data for the row.
 */
interface PaymentManagementRowActionsProps {
  paymentId: string;
  isReprocess: boolean;
  supplierOnboardStatus: string;
  isEditable: boolean;
}

/**
 * Represents the actions for a payment file row.
 * This component provides a dropdown menu for each row with various actions like edit, copy, favorite, and delete.
 * @returns {JSX.Element} The PaymentManagementRowActions component.
 */
export function RemittanceManagementRowActions({
  paymentId,
  isReprocess,
  supplierOnboardStatus,
}: PaymentManagementRowActionsProps) {
  // Get the navigate function from the router
  const navigate = useNavigate();

  // Re-process alert box state
  const [openAlert, setOpenAlert] = useState(false);

  // Initialize Tanstack Query Mutation for Reprocessing the payment.
  const reProcessPayment = useMutation({
    mutationFn: paymentReProcessFn,
    onSuccess: (response) => {
      // Handle successful mutation
      console.log("reProcessPaymentResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Payment re-processed successfully",
      });
      setOpenAlert(false);
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to re-process payment",
      });
      setOpenAlert(false);
    },
  });

  const isMutating = reProcessPayment.isPending;

  return (
    <>
      <DropdownMenu>
        {/* Trigger button for the dropdown menu */}
        <DropdownMenuTrigger asChild>
          <Button className="flex bg-theme hover:bg-neutral data-[state=open]:bg-secondary">
            <GripVerticalIcon className="size-6 bg-inherit" />
          </Button>
        </DropdownMenuTrigger>

        {/* Content of the dropdown menu */}
        <DropdownMenuContent align="end" className="min-w-fit">
          <DropdownMenuItem
            className="flex justify-between gap-4"
            onSelect={() => {
              void navigate({
                to: "/app/imremit/remittance-management/$paymentId/view/$list_type",
                params: {paymentId: paymentId, list_type: "all"},
                replace: true,
              });
            }}
          >
            View Remittance Details
            <span className="sr-only">View Remittance Details</span>
            <FileTextIcon className="size-4" />
          </DropdownMenuItem>

          {isReprocess && supplierOnboardStatus === "ACTIVE" && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="flex justify-between gap-4"
                onSelect={(e) => {
                  e.preventDefault();
                }}
              >
                <AlertDialog open={openAlert} onOpenChange={setOpenAlert}>
                  <AlertDialogTrigger>
                    <div className="relative flex cursor-pointer select-none items-center justify-between rounded-sm text-sm outline-none transition-colors hover:cursor-pointer focus:bg-destructive  data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50">
                      Re-Process
                      <span className="sr-only"> Re-Process</span>
                    </div>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>
                        Are you sure you want to re-process this payment?
                        <AlertDialogDescription>
                          If you click "Yes" button , the payment will
                          re-process.
                        </AlertDialogDescription>
                      </AlertDialogTitle>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel
                        aria-disabled={isMutating}
                        disabled={isMutating}
                        onClick={() => {
                          setOpenAlert(false);
                        }}
                      >
                        No
                      </AlertDialogCancel>
                      <AlertDialogAction
                        aria-disabled={isMutating}
                        disabled={isMutating}
                        onClick={() => {
                          reProcessPayment.mutate(paymentId);
                        }}
                      >
                        Yes
                        {isMutating ? <LoadingSection sectionSize="xs" /> : ""}
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
                <RefreshCcwDot className="size-4" />
                <RefreshCcwDot className="size-4" />
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
