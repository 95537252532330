import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  reconFileMappingKeys,
  type ReconMappingListType,
} from "@/modules/imremit";

//Defining a type based on the function `getAllReconFileMapping`
type QueryFnType = typeof getAllReconFileMapping;

//Funciton to get the recon Reports
export function getAllReconFileMapping() {
  // Fetch data from API using ky
  return kyApiFn<ReconMappingListType[]>(
    `api/e-payable/admin/master-mapping/recon`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useReconFileDetails(config?: QueryConfig<QueryFnType>) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: reconFileMappingKeys.allReconFileMapping(),
    queryFn: () => getAllReconFileMapping(),
  });
}
