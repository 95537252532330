import {initialsRegex} from "@/utils/constants";

// Define the Keycloak user info type
export enum KeycloakUserRoleNames {
  SUPPORT_CSR = "name:support-csr",
  USER = "name:user",

  // new roles
  MANAGEMENT = "name:management",
  PROGRAM_MANAGER = "name:program-manager",
  PROJECT_MANAGER = "name:project-manager",
  IM_USER = "name:im-user",
  SUPPLIER_ENABLEMENT = "name:supplier-enablement",
  IMREMIT_ADMIN = "name:epayables-admin",
  IMREMIT_USER = "name:epayables-user",
  CUSTOMER_ADMIN = "name:customer-admin",
  CUSTOMER_SUPER_ADMIN = "name:customer-super-admin",
  CUSTOMER_USER = "name:customer-user",
  CUSTOMER_REVIEWER = "name:customer-reviewer",
  SUPPLIER_ADMIN = "name:supplier-admin",
  SUPPLIER_USER = "name:supplier-user",
}

// Define the type for role mappings
type RoleMappingsType = Record<string, string>;

// Mapping of Keycloak role enums to user-friendly names
const roleMappings: RoleMappingsType = {
  [KeycloakUserRoleNames.SUPPORT_CSR]: "Support CSR",
  [KeycloakUserRoleNames.USER]: "User",

  // new roles
  [KeycloakUserRoleNames.MANAGEMENT]: "Management",
  [KeycloakUserRoleNames.PROGRAM_MANAGER]: "Program Manager",
  [KeycloakUserRoleNames.PROJECT_MANAGER]: "Project Manager",
  [KeycloakUserRoleNames.IM_USER]: "IM User",
  [KeycloakUserRoleNames.SUPPLIER_ENABLEMENT]: "Supplier Enablement",
  [KeycloakUserRoleNames.IMREMIT_ADMIN]: "ePayables Admin",
  [KeycloakUserRoleNames.IMREMIT_USER]: "ePayables User",
  [KeycloakUserRoleNames.CUSTOMER_ADMIN]: "Customer Admin",
  [KeycloakUserRoleNames.CUSTOMER_SUPER_ADMIN]: "Customer Super Admin",
  [KeycloakUserRoleNames.CUSTOMER_USER]: "Customer User",
  [KeycloakUserRoleNames.CUSTOMER_REVIEWER]: "Customer Reviewer",
  [KeycloakUserRoleNames.SUPPLIER_ADMIN]: "Supplier Admin",
  [KeycloakUserRoleNames.SUPPLIER_USER]: "Supplier User",
};

/**
 * Converts an array of Keycloak role names to a comma-separated string of user-friendly role names.
 *
 * @param {string[]} roles - The array of Keycloak role names.
 * @returns {string | null} A comma-separated string of user-friendly role names, or null if no valid roles are provided.
 */
export function calculateUserRoles(roles?: string[]): string | null {
  // Return null early if no roles are provided or the array is empty
  if (!roles || roles.length === 0) {
    return null;
  }

  // Filter out roles that are not in the mapping, and then map them to their user-friendly names
  const userRoles = roles
    .filter((role) => roleMappings[role])
    .map((role) => roleMappings[role]);

  // Join the user-friendly role names into a comma-separated string, or return null if the list is empty
  return userRoles.length > 0 ? userRoles.join(", ") : null;
}

/**
 * Calculates the initials from a given name.
 * @param {string} name The full name.
 * @returns {string} The initials.
 */
export const calculateInitials = (name: string): string => {
  return (
    ([...name.matchAll(initialsRegex)].shift()?.[1] || "") +
    ([...name.matchAll(initialsRegex)].pop()?.[1] || "")
  ).toUpperCase();
};
