import {format} from "date-fns";
import type {z} from "zod";

import {type multiSelectDatumSchema} from "@/components/craft/fancy-multi-select";

import {type NumRowsType} from "@/lib/stores";

// Define a TypeScript type for the form fields object. The object is an indexed type
// where the keys are strings and the values can be strings, numbers, Date objects,
// or arrays of objects that match the structure defined by the 'multiSelectDatumSchema'.
// We'll probably need to add to this and keep this one updated as we add more form fields.
export type QueryParamInput = Record<
  string,
  string | number | Date | z.infer<typeof multiSelectDatumSchema>[]
>;

// Define a TypeScript type for the query params object. It includes the sort,
// page and size properties, which can be strings, numbers or undefined.
interface ApiQueryParams {
  sort?: string; // Maybe find a way to type this better
  sort_dir?: "asc" | "dsc";
  page?: number;
  size?: NumRowsType;
}

// Define a TypeScript type for the options object that will be passed to the
// generateQueryString function. It includes the form fields object and the
// date format string, which has a default value of "yyyy-MM-dd".
type QueryStringOptions = {
  formFields: QueryParamInput;
  dateFormat?: string;
  // includeEmpty?: boolean;
} & ApiQueryParams;

// Define a function that generates a URL query string from the form fields.
// The form fields are passed in as the first parameter and the date format
// is passed in as the second parameter, with a default value of "yyyy-MM-dd".
export function generateQueryString({
  formFields,
  dateFormat = "yyyy-MM-dd",
  // includeEmpty = false,
  sort,
  sort_dir = "asc",
  page = 0,
  size = "10",
}: QueryStringOptions): string {
  // Create a new instance of URLSearchParams, which will be used to
  // construct the query string.
  const query = new URLSearchParams();

  // Iterate over all properties in the formFields object.
  for (const [key, value] of Object.entries(formFields)) {
    // If the value of the current property is an array, append each item in
    // the array to the query string with the key as the parameter name.
    if (Array.isArray(value)) {
      value.forEach((item) => {
        query.append(key, item.value);
      });
    }
    // If the value of the current property is a Date object, format it as a
    // string and append it to the query string with the key as the parameter name.
    else if (value instanceof Date) {
      query.append(key, format(value, dateFormat));
    }
    // Cast the current property value to a boolean and append if true,
    // convert it to a string and append it to the query string with the
    // key as the parameter name.
    else if (value) {
      query.append(key, value.toString());
    }
  }

  // If query is empty, return an empty string.
  // This is so that the query hook knows to be disabled if the query is empty.
  // if (query.toString() === "") {
  //   return "";
  // }

  // If the sort parameter is defined, append it to the query string.
  if (sort) {
    query.append("sort", `${sort_dir === "dsc" ? "-" : "+"}${sort}`);
  }

  // Append the page and rows parameters to the query string.
  query.append("page", page.toString());
  query.append("size", size.toString());

  // Convert the URLSearchParams instance into a string and return it. This string
  // will be the URL query string that the function was designed to generate.
  return query.toString();
}
