import {z} from "zod";

export const providerPaymentMethodNameSchema = z.object({
  paymentMethodId: z.number(),
  methodName: z.string(),
  providerName: z.string(),
});

export type ProviderPaymentMethodName = z.infer<
  typeof providerPaymentMethodNameSchema
>;
