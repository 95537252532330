import {Link} from "@tanstack/react-router";
import {MoveRightIcon} from "lucide-react";

import {Button} from "@/components/ui/button";

import {AddPIDForm} from "@/modules/admin";

export function CreatePIDMappingPage() {
  return (
    <section>
      <Link to="/app/admin/pid-mapping">
        <Button variant="secondary">
          Go back
          <MoveRightIcon className="ml-2" />
        </Button>
      </Link>{" "}
      <AddPIDForm />
    </section>
  );
}
