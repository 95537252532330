import {z} from "zod";

import {
  appvanceURLPattern,
  cronIntervalRegex,
  // emailPattern,
  noSpecialCharctersRegex,
  phoneRegex,
  urlPattern,
  windowStartAndEndTimeRegex,
} from "@/utils/constants";

export const SupplierRoleGroupsSchema = z.enum(
  ["import", "enroll", "decline"],
  {
    required_error: "You need to select a role group.",
  }
);

export type SupplierRoleGroups = z.infer<typeof SupplierRoleGroupsSchema>;

export const CommonSupplierSchema = z.object({
  comments: z.string().optional().nullable(),
  supplierNumber: z
    .string()
    .min(1, "Supplier Number is required")
    .max(140, "Supplier Number must not exceed 140 characters."),
  supplierName: z.string().min(1, "Supplier Name is required").max(140),
  supplierEmail: z.string().email("Invalid email address").max(140),
  phoneNumber: z
    .string()
    .regex(phoneRegex, "Invalid Phone Number!")
    // .min(6, "Phone number must be at least 6 digits")
    .max(20, "Phone number cannot exceed 20 digits")
    .nullable(),
  changedBy: z.string().min(1, "Changed By is required"),
  saveAndContinue: z.boolean().default(false).optional(),
  batchSize: z.number().nullable().optional(),
  cronInterval: z
    .string()
    .refine(
      (val) => val === "" || cronIntervalRegex.test(val),

      {
        message: "Invalid cron interval format",
      }
    )
    .optional()
    .nullable(),
  maintenanceWindowStart: z
    .string()
    .refine(
      (val) => val === "" || windowStartAndEndTimeRegex.test(val),

      {
        message: "Invalid window start time format",
      }
    )
    .optional()
    .nullable(),
  maintenanceWindowEnd: z
    .string()
    .refine(
      (val) => val === "" || windowStartAndEndTimeRegex.test(val),

      {
        message: "Invalid window end time format",
      }
    )
    .optional()
    .nullable(),

  customer: z.string(),

  supplierId: z.preprocess(
    (args) => (args === "" ? undefined : args),
    z.coerce
      .number({invalid_type_error: "Supplier ID must be a number"})
      .positive("Supplier ID must be positive")
      .min(1, "Supplier ID must be greater than 0")
      .max(9999999999, "Supplier ID must be less than 10 billion")
  ),

  phone: z
    .string()
    .regex(
      new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/),
      "Invalid Number!"
    )
    .nullable()
    .optional(),

  declinedDate: z.date().min(new Date("1970-01-01"), {
    message: "Date is out of range",
  }),
  declinedReason: z
    .string()
    .min(1, "Declined Reason is required")
    .regex(noSpecialCharctersRegex, {
      message: "Special characters are not allowed.",
    }),

  enrolled: z.boolean().default(false).optional(),

  enrolledDate: z.date().min(new Date("1970-01-01"), {
    message: "Date is out of range",
  }),
  paymentMode: z.string(),
  remittanceEmail: z.array(
    z.string().email("Invalid email address").optional()
  ),

  appvanceScenarioUrl: z.string().optional().nullable(),
  appvanceAuthUrl: z.string().optional().nullable(),
  appvanceProcessingFlag: z.boolean().optional().nullable(),
  mfaPassword: z
    .string()
    .max(140, "MFA Password must not exceed 140 characters.")
    .nullable()
    .optional(),
  mfaEmail: z
    .string()
    .regex(/^(|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/, {
      message: "Invalid email address. Please provide a valid email.",
    })
    .optional()
    .nullable(),
  supplierSingleUseFlag: z.boolean().default(false).optional(),
  supplierCardOnFileFlag: z.boolean().default(false).optional(),
  supplierPaymentCounterFlag: z.boolean().default(false).optional(),
  supplierBufferLimitAmount: z
    .number()
    .min(0)
    .max(9999999999)
    .nullable()
    .optional(),
  supplierCardLimitAmount: z
    .number()
    .min(0)
    .max(9999999999)
    .nullable()
    .optional(),

  // PBW
  scriptFlag: z.boolean().default(false).optional(),
  proxyPayUrl: z.string().optional().nullable(),
  userId: z
    .string()
    .max(140, "User ID must not exceed 140 characters.")
    .nullable()
    .optional(),
  password: z
    .string()
    .max(140, "Password must not exceed 140 characters.")
    .nullable()
    .optional(),
  clientKey: z
    .string()
    .min(1, "Client Key is required")
    .max(140, "Client Key must not exceed 140 characters.")
    .nullable()
    .optional(),
  websiteKey: z
    .string()
    .min(1, "Website Key is required")
    .max(140, "Website Key must not exceed 140 characters.")
    .nullable()
    .optional(),

  // PBP
  remittanceName: z
    .string()
    .max(140, "Remittance Name must not exceed 140 characters.")
    .optional(),
  remittancePhoneNumber: z
    .string()
    .regex(phoneRegex, "Invalid Phone Number!")

    .nullable()
    .optional(),

  // for PBW, PBE, PBP
  paymentRestrictions: z.boolean().default(false),

  // PBW and PBP
  maxAmountPerTransaction: z.number().max(9999999999).nullable().optional(),
  minAmountPerTransaction: z.number().max(9999999999).nullable().optional(),
  amountTransactionLimitPerDay: z
    .number()
    .max(9999999999)
    .nullable()
    .optional(),
  noOfTransactionPerDay: z.number().max(9999999999).nullable().optional(),

  // Customer Profile for PBW, PBE, PBP - Only if enrolled is selected
  supplierContactName: z
    .string()
    .min(1, "Supplier Contact Name is required")
    .max(140, "Supplier Contact Name must not exceed 140 characters."),
  contactEmail: z
    .string()
    .regex(/^(|[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/, {
      message: "Invalid email address. Please provide a valid email.",
    }),
  taxId: z
    .string()
    .min(1, "Tax ID is required")
    .max(140, "Tax ID must not exceed 140 characters."),
  address1: z
    .string()
    .min(1, "Address Line 1 is required")
    .max(140, "Address Line 1 must not exceed 140 characters.")
    .nullable()
    .optional(),
  address2: z
    .string()
    .min(1, "Address Line 2 is required")
    .max(140, "Address Line 2 must not exceed 140 characters.")
    .nullable()
    .optional(),
  address3: z
    .string()
    .min(1, "Address Line 3 is required")
    .max(140, "Address Line 3 must not exceed 140 characters.")
    .nullable()
    .optional(),
  address4: z
    .string()
    .min(1, "Address Line 4 is required")
    .max(140, "Address Line 4 must not exceed 140 characters.")
    .nullable()
    .optional(),
  country: z
    .string()
    .min(1, "Country is required")
    .max(100, "Country must not exceed 100 characters.")
    .nullable()
    .optional(),
  state: z
    .string()
    .min(2, "State is required")
    .max(100, "State must not exceed 100 characters.")
    .nullable()
    .optional(),
  city: z
    .string()
    .min(1, "City is required")
    .max(100, "City must not exceed 100 characters.")
    .nullable()
    .optional(),
  zip: z.string().nullable().optional(),
  locationCode: z
    .string()
    .min(1, "Location Code is required")
    .max(100, "Location Code must not exceed 100 characters.")
    .nullable()
    .optional(),
  fileId: z
    .string()
    .min(1, "File ID is required")
    .max(140, "File ID must not exceed 140 characters.")
    .optional(),
  corporateName: z
    .string()
    .min(1, "Corporate Name is required")
    .max(140, "Corporate Name must not exceed 140 characters.")
    .optional(),

  scriptPaymentLimiter: z.boolean().default(false).nullable(),
  paymentLimitNumber: z
    .number()
    .max(
      9999999999,
      "Number of Transactions per Day must not exceed 9999999999"
    )
    .nullable()
    .optional(),

  loginCredentialRequests: z
    .array(
      z.object({
        credentialId: z.string().max(140).nullable().optional(),
        userId: z
          .string()
          .max(140, "User ID number must not exceed 140 characters.")
          .nullable()
          .optional(),
        password: z
          .string()
          .max(140, "Password must not exceed 140 characters.")
          .nullable()
          .optional(),
        scriptOptions: z.boolean().default(false).nullable(),
        paymentFileNameIdentifier: z
          .string()
          .max(140, "User ID number must not exceed 140 characters.")
          .nullable()
          .optional(),
      })
    )
    .nullable()
    .optional(),

  authorizationType: z.string().default("Single").nullable(),
  universalAcceptorComments: z.string().max(140).nullable().optional(),
  remittanceViaEmail: z.boolean().default(false).nullable().optional(),
  contactPhone: z
    .string()
    .regex(
      new RegExp(
        /^(?:([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+)?$/
      ),
      "Invalid Phone Number!"
    )
    .nullable()
    .optional(),

  universalAcceptor: z.boolean().default(false),
  doesSupplierTakeFees: z.boolean().default(false),
  supplierFeesComments: z.string().max(140).nullable().optional(),
  dataRequirementsToMakePayment: z.string().max(140).nullable().optional(),
  department: z.string().max(140).nullable().optional(),
  additionalPaymentRestrictions: z.string().max(140).nullable().optional(),
  confirmationOfPaymentEmail: z.string().max(140).nullable().optional(),
  internalEmailAlertFlag: z.boolean().default(true).optional(),
});

export const AddSupplierFormSchema = CommonSupplierSchema.pick({
  comments: true,
  supplierNumber: true,
  supplierName: true,
  supplierEmail: true,
  phoneNumber: true,
  changedBy: true,
  saveAndContinue: true,
  batchSize: true,
  cronInterval: true,
  maintenanceWindowStart: true,
  maintenanceWindowEnd: true,
}).superRefine(({supplierName}, ctx) => {
  // check if supplierName has any space before or after it which is not allowed
  if (supplierName && supplierName.trim() !== supplierName) {
    ctx.addIssue({
      code: "custom",
      message: "Supplier Name cannot have leading or trailing spaces!",
      path: ["supplierName"],
    });
  }
});

export const AddSupplierFormLiteSchema = CommonSupplierSchema.pick({
  comments: true,
  supplierNumber: true,
  supplierName: true,
  supplierEmail: true,
  phoneNumber: true,
  changedBy: true,
  saveAndContinue: true,
}).superRefine(({supplierName}, ctx) => {
  // check if supplierName has any space before or after it which is not allowed
  if (supplierName && supplierName.trim() !== supplierName) {
    ctx.addIssue({
      code: "custom",
      message: "Supplier Name cannot have leading or trailing spaces!",
      path: ["supplierName"],
    });
  }
});

export const SupplierFormSchema = CommonSupplierSchema.pick({
  batchSize: true,
  cronInterval: true,
  maintenanceWindowStart: true,
  maintenanceWindowEnd: true,
  appvanceScenarioUrl: true,
  appvanceProcessingFlag: true,
  mfaPassword: true,
  mfaEmail: true,
  customer: true,
  changedBy: true,
  supplierName: true,
  supplierId: true,
  supplierEmail: true,
  phone: true,
  declinedDate: true,
  declinedReason: true,
  enrolled: true,
  enrolledDate: true,
  paymentMode: true,
  remittanceEmail: true,
  supplierSingleUseFlag: true,
  supplierCardOnFileFlag: true,
  supplierPaymentCounterFlag: true,
  supplierBufferLimitAmount: true,
  supplierCardLimitAmount: true,
  scriptFlag: true,
  proxyPayUrl: true,
  userId: true,
  password: true,
  clientKey: true,
  websiteKey: true,
  remittanceName: true,
  remittancePhoneNumber: true,
  paymentRestrictions: true,
  maxAmountPerTransaction: true,
  minAmountPerTransaction: true,
  amountTransactionLimitPerDay: true,
  noOfTransactionPerDay: true,
  supplierContactName: true,
  contactEmail: true,
  taxId: true,
  address1: true,
  address2: true,
  address3: true,
  address4: true,
  country: true,
  state: true,
  city: true,
  zip: true,
  locationCode: true,
  appvanceAuthUrl: true,
  internalEmailAlertFlag: true,
});

export const EditSupplierFormSchema = SupplierFormSchema.omit({
  customer: true,
  supplierId: true,
  phone: true,
}).merge(
  CommonSupplierSchema.pick({
    supplierNumber: true,
    phoneNumber: true,
    saveAndContinue: true,
    fileId: true,
    corporateName: true,
  })
);

export const EditSupplierDeclinedFormSchema = CommonSupplierSchema.pick({
  supplierNumber: true,
  supplierName: true,
  supplierEmail: true,
  phoneNumber: true,
  changedBy: true,
  enrolled: true,
  saveAndContinue: true,
  declinedDate: true,
  declinedReason: true,
});

export const EditSupplierEnrolledFormSchema = SupplierFormSchema.omit({
  customer: true,
  supplierId: true,
  phone: true,
  declinedDate: true,
  declinedReason: true,
  userId: true,
  password: true,
})
  .merge(
    CommonSupplierSchema.pick({
      supplierNumber: true,
      phoneNumber: true,
      saveAndContinue: true,
      fileId: true,
      corporateName: true,
      enrolledDate: true,
      scriptPaymentLimiter: true,
      paymentLimitNumber: true,
      loginCredentialRequests: true,
      authorizationType: true,
      universalAcceptorComments: true,
      remittanceViaEmail: true,
      supplierCardLimitAmount: true,
      internalEmailAlertFlag: true,
    })
  )
  .superRefine((values, ctx) => {
    const {
      supplierName,
      scriptPaymentLimiter,
      scriptFlag,
      paymentLimitNumber,
      remittanceName,
      remittancePhoneNumber,
      paymentMode,
      proxyPayUrl,
      appvanceScenarioUrl,
      appvanceProcessingFlag,
      zip,
      country,
      appvanceAuthUrl,
      supplierCardOnFileFlag,
      supplierBufferLimitAmount,
      supplierCardLimitAmount,
    } = values;

    // Check if supplierName has any leading or trailing spaces
    if (supplierName && supplierName.trim() !== supplierName) {
      ctx.addIssue({
        code: "custom",
        message: "Supplier Name cannot have leading or trailing spaces!",
        path: ["supplierName"],
      });
    }

    // Validate Payment Limit Number
    if (scriptFlag && scriptPaymentLimiter && !paymentLimitNumber) {
      ctx.addIssue({
        code: "custom",
        message: "Payment Limit Number is required!",
        path: ["paymentLimitNumber"],
      });
    }

    // Validate Web URL
    if (scriptFlag && paymentMode === "Pay By Web" && !proxyPayUrl) {
      ctx.addIssue({
        code: "custom",
        message: "Web URL is required!",
        path: ["proxyPayUrl"],
      });
    }

    // Make 'appvanceAuthUrl', required only if 'appvanceProcessingFlag' is true
    if (appvanceProcessingFlag) {
      if (!appvanceAuthUrl) {
        ctx.addIssue({
          code: "custom",
          message: "Appvance Authorization URL is required!",
          path: ["appvanceAuthUrl"],
        });
      } else if (
        typeof appvanceAuthUrl === "string" &&
        !appvanceURLPattern.test(appvanceAuthUrl)
      ) {
        ctx.addIssue({
          code: "custom",
          message: "Invalid URL format",
          path: ["appvanceAuthUrl"],
        });
      }
    }

    // Validate Appvance Scenario URL
    if (appvanceProcessingFlag && scriptFlag) {
      if (!appvanceScenarioUrl) {
        ctx.addIssue({
          code: "custom",
          message: "Appvance Scenario URL is required!",
          path: ["appvanceScenarioUrl"],
        });
      } else if (!appvanceURLPattern.test(appvanceScenarioUrl)) {
        ctx.addIssue({
          code: "custom",
          message: "Invalid URL format",
          path: ["appvanceScenarioUrl"],
        });
      }
    }

    // Validate Contact Information for 'Pay By Phone'
    if (paymentMode === "Pay By Phone") {
      if (!remittanceName) {
        ctx.addIssue({
          code: "custom",
          message: "Contact Name is required!",
          path: ["remittanceName"],
        });
      }
      if (!remittancePhoneNumber) {
        ctx.addIssue({
          code: "custom",
          message: "Contact Phone is required!",
          path: ["remittancePhoneNumber"],
        });
      }
    }

    // Validate Zip/Postal Code based on country
    if (country === "US") {
      const usZipCodeRegex = /^\d{5}(-\d{4})?$/;
      if (!usZipCodeRegex.test(zip ?? "")) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter a valid zip code",
          path: ["zip"],
        });
      }
    } else if (country === "CA") {
      const caPostalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
      if (!caPostalCodeRegex.test(zip ?? "")) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter a valid postal code",
          path: ["zip"],
        });
      }
    }

    // 🔒 Validate buffer and card limit amounts when card on file is enabled
    if (supplierCardOnFileFlag) {
      if (!supplierBufferLimitAmount && supplierBufferLimitAmount !== 0) {
        ctx.addIssue({
          code: "custom",
          message:
            "Buffer Limit Amount is required when Card on File is enabled",
          path: ["supplierBufferLimitAmount"],
        });
      }

      if (!supplierCardLimitAmount && supplierCardLimitAmount !== 0) {
        ctx.addIssue({
          code: "custom",
          message: "Card Limit Amount is required when Card on File is enabled",
          path: ["supplierCardLimitAmount"],
        });
      }
    }
  });

export const EditSupplierEnrolledFormLiteSchema = SupplierFormSchema.omit({
  customer: true,
  supplierId: true,
  phone: true,
  declinedDate: true,
  declinedReason: true,
  remittanceEmail: true,
})
  .merge(
    CommonSupplierSchema.pick({
      supplierNumber: true,
      phoneNumber: true,
      saveAndContinue: true,
      contactPhone: true,
      fileId: true,
      corporateName: true,
      enrolledDate: true,
      scriptPaymentLimiter: true,
      paymentLimitNumber: true,
      authorizationType: true,
      universalAcceptor: true,
      doesSupplierTakeFees: true,
      universalAcceptorComments: true,
      supplierFeesComments: true,
      dataRequirementsToMakePayment: true,
      department: true,
      additionalPaymentRestrictions: true,
      confirmationOfPaymentEmail: true,
    })
  )
  .superRefine((values, ctx) => {
    const {
      scriptPaymentLimiter,
      scriptFlag,
      paymentLimitNumber,
      remittanceName,
      remittancePhoneNumber,
      paymentMode,
      proxyPayUrl,
      confirmationOfPaymentEmail,
      doesSupplierTakeFees,
      supplierFeesComments,
      supplierName,
      appvanceProcessingFlag,
      contactEmail,
      appvanceScenarioUrl,
      zip,
      country,
      appvanceAuthUrl,
    } = values;

    // check if supplierName has any space before or after it which is not allowed
    if (supplierName && supplierName.trim() !== supplierName) {
      ctx.addIssue({
        code: "custom",
        message: "Supplier Name cannot have leading or trailing spaces!",
        path: ["supplierName"],
      });
    }
    if (scriptFlag && scriptPaymentLimiter && !paymentLimitNumber) {
      ctx.addIssue({
        code: "custom",
        message: "Payment Limit Number is required!",
        path: ["paymentLimitNumber"],
      });
    }

    if (doesSupplierTakeFees && !supplierFeesComments) {
      ctx.addIssue({
        code: "custom",
        message: "Supplier Fees Commnets is required!",
        path: ["supplierFeesComments"],
      });
    }

    if (scriptFlag && !confirmationOfPaymentEmail) {
      ctx.addIssue({
        code: "custom",
        message: "Confirmation of Payment Email is required!",
        path: ["confirmationOfPaymentEmail"],
      });
    }
    if (scriptFlag && paymentMode === "Pay By Web" && !appvanceAuthUrl) {
      ctx.addIssue({
        code: "custom",
        message: "Appvance Authorization URL is required!",
        path: ["appvanceAuthUrl"],
      });
    } else if (
      typeof appvanceAuthUrl === "string" &&
      !appvanceURLPattern.test(appvanceAuthUrl)
    ) {
      ctx.addIssue({
        code: "custom",
        message: "Invalid URL format",
        path: ["appvanceAuthUrl"],
      });
    }
    if (appvanceProcessingFlag && scriptFlag) {
      if (!appvanceScenarioUrl) {
        ctx.addIssue({
          code: "custom",
          message: "Appvance Scenario URL is required!",
          path: ["appvanceScenarioUrl"],
        });
      } else if (!appvanceURLPattern.test(appvanceScenarioUrl)) {
        ctx.addIssue({
          code: "custom",
          message: "Invalid URL format",
          path: ["appvanceScenarioUrl"],
        });
      }
    }
    if (paymentMode === "Pay By Email" && !contactEmail) {
      ctx.addIssue({
        code: "custom",
        message: "Contact Email is required!",
        path: ["contactEmail"],
      });
    }

    if (paymentMode === "Pay By Phone" && !remittanceName) {
      ctx.addIssue({
        code: "custom",
        message: "Contact Name is required!",
        path: ["remittanceName"],
      });
    }
    if (paymentMode === "Pay By Phone" && !remittancePhoneNumber) {
      ctx.addIssue({
        code: "custom",
        message: "Contact Phone is required!",
        path: ["remittancePhoneNumber"],
      });
    }
    if (paymentMode === "Pay By Web") {
      if (!proxyPayUrl) {
        ctx.addIssue({
          code: "custom",
          message: "Web URL is required!",
          path: ["proxyPayUrl"],
        });
      } else if (!urlPattern.test(proxyPayUrl)) {
        ctx.addIssue({
          code: "custom",
          message: "Invalid URL format",
          path: ["proxyPayUrl"],
        });
      }
    }

    // Custom validation for zip code based on country
    if (country === "US") {
      const usZipCodeRegex = /^\d{5}(-\d{4})?$/;
      if (!usZipCodeRegex.test(zip ?? "")) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter a valid zip code",
          path: ["zip"],
        });
      }
    } else if (country === "CA") {
      const caPostalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
      if (!caPostalCodeRegex.test(zip ?? "")) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter a valid postal code",
          path: ["zip"],
        });
      }
    }
  });

export type AddSupplierFormDTO = z.infer<typeof AddSupplierFormSchema>;
export type AddSupplierFormLiteDTO = z.infer<typeof AddSupplierFormLiteSchema>;
export type EditSupplierFormDTO = z.infer<typeof EditSupplierFormSchema>;
export type EditSupplierDeclinedFormDTO = z.infer<
  typeof EditSupplierDeclinedFormSchema
>;
export type EditSupplierEnrolledFormDTO = z.infer<
  typeof EditSupplierEnrolledFormSchema
>;

export type EditSupplierEnrolledFormLiteDTO = z.infer<
  typeof EditSupplierEnrolledFormLiteSchema
>;

export type SupplierFormDTO = z.infer<typeof SupplierFormSchema>;
