import {kyApiFn} from "@/lib/ky";
import {type SupplierScriptData} from "@/modules/imremit";

export const addSupplierScriptFn = (
  addSupplierScriptDTO: SupplierScriptData,
  supplierId: string,
  paymentTimer: string,
  captchaTimer: string
) => {
  // Convert empty strings to nulls in the editSupplierScriptDTO
  const modifiedDTO = addSupplierScriptDTO.map((item) => {
    const modifiedItem: Record<string, string | number | null | boolean> = {
      ...item,
    };
    for (const key in modifiedItem) {
      if (Object.prototype.hasOwnProperty.call(modifiedItem, key)) {
        if (modifiedItem[key] === "") {
          modifiedItem[key] = null;
        }
      }
    }
    return modifiedItem;
  });

  return kyApiFn(
    `api/e-payable/management/${supplierId}/supplier-script/create?paymentTimer=${paymentTimer}&captchaTimer=${captchaTimer}`,
    "post",
    {
      json: modifiedDTO,
    }
  );
};
