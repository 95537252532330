import {useEffect} from "react";

import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {ImREmitRoutesSchema} from "@/lib/routes/types";
import {useImRemitCustomerStore, useRoutesStore} from "@/lib/stores";
import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useAuthorization,
} from "@/modules/auth";
import {SelectCustomerSectionImremit} from "@/modules/customers";
import {ProxyPayDashboardView} from "@/modules/imremit";

export function ProxyPayDashboardPage() {
  const {setimREmitRoute} = useRoutesStore();
  const {checkPoliciesAccess} = useAuthorization();
  const {imREmitStoreCustomer} = useImRemitCustomerStore();

  useEffect(() => {
    setimREmitRoute(
      ImREmitRoutesSchema.Values["/app/imremit/proxy-pay-dashboard"]
    );
  }, [setimREmitRoute]);

  // Handle unauthorized access
  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_PROXYPAY_DASHBOARD])) {
    return <NotAuthorizedPage />;
  }

  // If the customer is not yet selected, render the SelectCustomerSectionImremit
  if (!imREmitStoreCustomer) {
    return <SelectCustomerSectionImremit />;
  }

  /**
   * Render ProxyPayDashboardView
   *
   * Once all data is available, render the main ProxyPayDashboardView component.
   * Pass relevant props for rendering payment management functionalities.
   *
   * | Prop                     | Type            | Description                                                   |
   * |--------------------------|-----------------|---------------------------------------------------------------|
   * | proxyPays                | Object          | Data content for proxy payments                               |
   */
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.READ_PROXYPAY_DASHBOARD]}
    >
      <ProxyPayDashboardView />
    </AccessPolicyWrapper>
  );
}
