import {kyApiFn} from "@/lib/ky";

export const deleteCustomerParticipantRegisterFn = (
  externalId: string,
  participantRegisterId: number
) =>
  kyApiFn(
    `api/e-payable/management/${externalId}/organizations/${participantRegisterId}`,
    "delete"
  );
