import {z} from "zod";

export const dupesExemptionCharacterTypesSchema = z.object({
  exemption_type_id: z.string(),
  type_key: z.string(),
  weight: z.string(),
  single_entry: z.string(),
  input_label: z.string(),
  description: z.string().nullable(),
  entry_type: z.string().nullable(),
  entry_values: z.string().nullable(),
  type: z.string(),
});

export type DupesExemptionCharacterTypesType = z.infer<
  typeof dupesExemptionCharacterTypesSchema
>;
