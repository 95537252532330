import {ViewScriptItem} from "@/modules/imremit";
import type {SupplierScriptItem} from "@/modules/imremit";

// Props for the SupplierScriptsTimeline component
interface SupplierScriptsTimelineProps {
  supplierScriptItems: SupplierScriptItem[];
  supplierId: number;
  setCurrentSupplier: React.Dispatch<React.SetStateAction<string | number>>;
  module?: string;
}

// Main component to display a timeline of draggable supplier script items
export function SupplierScriptsViewTimeline({
  supplierScriptItems,
}: SupplierScriptsTimelineProps) {
  return (
    <section className="supplier-script-items">
      {supplierScriptItems.map((item, index) => (
        <ViewScriptItem key={index} item={item} />
      ))}
    </section>
  );
}
