import {useEffect} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link} from "@tanstack/react-router";
import {ArrowRightIcon, SquarePenIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {Card} from "@/components/ui/card";
import {Checkbox} from "@/components/ui/checkbox";
import {DialogFooter} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Spinner} from "@/components/ui/spinner";
import {Textarea} from "@/components/ui/textarea";
import {Heading2} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {DatePicker} from "@/components/craft/date-pickers";

import {
  dupesPaymentSettingsSchema,
  updateDupesCustomerSettings,
  useAllDupesCustomerSettings,
  type DupesCustomerSettingsType,
} from "@/modules/duplicate-payments";

interface OnboardDuplicatePaymentsCustomerFormProps {
  externalId: string;
}

export function DuplicatePaymentsOnboardingForm({
  externalId,
}: OnboardDuplicatePaymentsCustomerFormProps) {
  const customerSettingsQuery = useAllDupesCustomerSettings(externalId);
  const editDupesCustomerSettingsMutation = useMutation({
    mutationFn: (data: z.infer<typeof dupesPaymentSettingsSchema>) => {
      return updateDupesCustomerSettings(externalId, data);
    },
    onSuccess: (editDupesCustomerSettingsResponse) => {
      console.info(
        "editDupesCustomerSettingsResponse",
        editDupesCustomerSettingsResponse
      );

      // Display a success toast
      toast({
        variant: "success",
        title: "Success!",
        description: "Duplicate payments settings updated successfully",
      });
    },
    onError: (error) => {
      console.error("error", error);

      // Display an error toast
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update duplicate payments settings",
      });
    },
  });

  const onboardDuplicatePaymentCustomerFormMethods =
    useForm<DupesCustomerSettingsType>({
      defaultValues: {
        block_likely: false,
        block_maybe: false,
        block_different_vendor_likely: false,
        block_different_vendor_maybe: false,
      },
      resolver: zodResolver(dupesPaymentSettingsSchema),
    });

  // Reset the form when the data is fetched
  useEffect(() => {
    if (customerSettingsQuery.isSuccess) {
      onboardDuplicatePaymentCustomerFormMethods.reset(
        customerSettingsQuery.data.content
      );
    }
  }, [
    customerSettingsQuery.isSuccess,
    customerSettingsQuery.data?.content,
    onboardDuplicatePaymentCustomerFormMethods,
  ]);

  const formFields = onboardDuplicatePaymentCustomerFormMethods.watch();

  return (
    <section>
      <div className="mb-4 flex flex-col-reverse gap-4 md:flex-row md:justify-between">
        <div className="flex gap-4">
          <Heading2>Duplicate Payments Onboarding Form</Heading2>
        </div>
        <Link to="/app/admin/customer-management">
          <Button variant="secondary">
            Back to list
            <ArrowRightIcon className="ml-2 size-4" />
          </Button>
        </Link>
      </div>
      <Card className="p-4">
        <Form {...onboardDuplicatePaymentCustomerFormMethods}>
          <form
            className="flex flex-col gap-4"
            onSubmit={onboardDuplicatePaymentCustomerFormMethods.handleSubmit(
              (data) => {
                editDupesCustomerSettingsMutation.mutate(data);
              }
            )}
          >
            <FormField
              name="initial_run_cutoff_percent"
              render={() => (
                <FormItem>
                  <FormLabel
                    htmlFor="initial_run_cutoff_percent"
                    showMandatoryAsterisk
                  >
                    Run cutoff percentage:
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      placeholder="Enter cutoff percent..."
                      disabled={editDupesCustomerSettingsMutation.isPending}
                      aria-disabled={
                        editDupesCustomerSettingsMutation.isPending
                      }
                      {...onboardDuplicatePaymentCustomerFormMethods.register(
                        "initial_run_cutoff_percent",
                        {
                          setValueAs: (v) =>
                            v === "" ? undefined : parseInt(v as string, 10),
                        }
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex w-full gap-4">
              <FormField
                name="initial_run_start_date"
                render={({field}) => (
                  <FormItem className="flex w-full flex-col">
                    <FormLabel
                      htmlFor="initial_run_start_date"
                      showMandatoryAsterisk
                    >
                      Run start date:
                    </FormLabel>
                    <DatePicker
                      {...field}
                      placeholder="Run start date"
                      value={
                        field.value
                          ? new Date(field.value as string)
                          : undefined
                      }
                      disabled={
                        formFields.initial_run_end_date
                          ? {
                              before: new Date("1970-01-01"),
                              after: formFields.initial_run_end_date,
                            }
                          : undefined
                      }
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="initial_run_end_date"
                render={({field}) => (
                  <FormItem className="flex w-full flex-col">
                    <FormLabel
                      htmlFor="initial_run_end_date"
                      showMandatoryAsterisk
                    >
                      Run end date:
                    </FormLabel>
                    <DatePicker
                      {...field}
                      placeholder="Run end Date"
                      value={
                        field.value
                          ? new Date(field.value as string)
                          : undefined
                      }
                      disabled={
                        formFields.initial_run_start_date
                          ? {
                              before: formFields.initial_run_start_date,
                              after: new Date("9999-01-01"),
                            }
                          : undefined
                      }
                    />
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <FormField
                name="block_likely"
                render={({field}) => (
                  <FormItem className="flex flex-col">
                    <FormLabel htmlFor="block_likely">
                      Block Likely Payments:
                    </FormLabel>
                    <FormControl>
                      <Checkbox
                        checked={field.value as boolean}
                        disabled={editDupesCustomerSettingsMutation.isPending}
                        aria-disabled={
                          editDupesCustomerSettingsMutation.isPending
                        }
                        onCheckedChange={field.onChange}
                        {...onboardDuplicatePaymentCustomerFormMethods.register(
                          "block_likely"
                        )}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="block_maybe"
                render={({field}) => (
                  <FormItem className="flex flex-col">
                    <FormLabel htmlFor="block_maybe">
                      Block Maybe Payments:
                    </FormLabel>
                    <FormControl>
                      <Checkbox
                        checked={field.value as boolean}
                        disabled={editDupesCustomerSettingsMutation.isPending}
                        aria-disabled={
                          editDupesCustomerSettingsMutation.isPending
                        }
                        onCheckedChange={field.onChange}
                        {...onboardDuplicatePaymentCustomerFormMethods.register(
                          "block_maybe"
                        )}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="block_different_vendor_likely"
                render={({field}) => (
                  <FormItem className="flex flex-col">
                    <FormLabel htmlFor="block_different_vendor_likely">
                      Block Different Supplier Likely Payments:
                    </FormLabel>
                    <FormControl>
                      <Checkbox
                        checked={field.value as boolean}
                        disabled={editDupesCustomerSettingsMutation.isPending}
                        aria-disabled={
                          editDupesCustomerSettingsMutation.isPending
                        }
                        onCheckedChange={field.onChange}
                        {...onboardDuplicatePaymentCustomerFormMethods.register(
                          "block_different_vendor_likely"
                        )}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                name="block_different_vendor_maybe"
                render={({field}) => (
                  <FormItem className="flex flex-col">
                    <FormLabel htmlFor="block_different_vendor_maybe">
                      Block Different Supplier Maybe Payments:
                    </FormLabel>
                    <FormControl>
                      <Checkbox
                        checked={field.value as boolean}
                        disabled={editDupesCustomerSettingsMutation.isPending}
                        aria-disabled={
                          editDupesCustomerSettingsMutation.isPending
                        }
                        onCheckedChange={field.onChange}
                        {...onboardDuplicatePaymentCustomerFormMethods.register(
                          "block_different_vendor_maybe"
                        )}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <FormField
              name={`notes`}
              render={() => (
                <FormItem className="flex flex-col">
                  <FormLabel htmlFor="notes">Add Notes:</FormLabel>
                  <FormControl>
                    <Textarea
                      disabled={editDupesCustomerSettingsMutation.isPending}
                      aria-disabled={
                        editDupesCustomerSettingsMutation.isPending
                      }
                      {...onboardDuplicatePaymentCustomerFormMethods.register(
                        `notes`
                      )}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter className="pt-4">
              <Button
                type="submit"
                className="gap-2"
                disabled={
                  onboardDuplicatePaymentCustomerFormMethods.formState
                    .isSubmitting
                }
              >
                Submit Settings
                {editDupesCustomerSettingsMutation.isPending ? (
                  <Spinner size="xs" />
                ) : (
                  <SquarePenIcon className="size-4" />
                )}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </Card>
    </section>
  );
}
