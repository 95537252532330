import {HistoryIcon, ReceiptIcon} from "lucide-react";

import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from "@/components/ui/card";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "@/components/ui/tabs";

import {ImREmitInvoiceHistory, ImREmitPaymentHistory} from "@/modules/imremit";

interface PaymentManagementDetailsFooterPros {
  id?: string;
}

export function PaymentManagementDetailsFooter({
  id,
}: PaymentManagementDetailsFooterPros) {
  return (
    <Tabs defaultValue="invoices" className="w-full">
      <TabsList className="grid h-12 w-full grid-cols-2">
        <TabsTrigger value="invoices">
          <ReceiptIcon className="p-1" />
          Invoices
        </TabsTrigger>
        <TabsTrigger value="history">
          <HistoryIcon className="p-1" />
          History
        </TabsTrigger>
      </TabsList>
      <TabsContent value="invoices">
        <Card>
          <CardHeader>
            <CardDescription>
              Changes to invoices do not affect the payable total. If invoices
              have been added, they will appear at the bottom of the table
            </CardDescription>
          </CardHeader>
          <CardContent className="space-y-2">
            {/* INVOICE HISTORY  */}
            {id ? <ImREmitInvoiceHistory id={id} /> : ""}
          </CardContent>
        </Card>
      </TabsContent>
      <TabsContent value="history">
        <Card>
          <CardContent className="space-y-2 pt-5">
            {/* PAYMENT HISTORY */}
            {id ? <ImREmitPaymentHistory id={id} /> : ""}
          </CardContent>
        </Card>
      </TabsContent>
    </Tabs>
  );
}
