import {useCallback, useEffect, useMemo, useRef, useState} from "react";

import {debounce} from "lodash";
import {Check, X} from "lucide-react";

import {Badge} from "@/components/ui/badge";
import {Button} from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Skeleton} from "@/components/ui/skeleton";
import {Spinner} from "@/components/ui/spinner";

import {cn} from "@/lib/utils";

// Define the structure of an InfiniteMultiSelectOptions item 📦
interface InfiniteMultiSelectOptions {
  label: string;
  value: string;
  colour?: "theme" | "secondary" | "neutral" | "success" | "destructive"; // Using your theme colors
}

// Define the props for the InfiniteMultiSelect component 📋
interface InfiniteMultiSelectProps {
  value: InfiniteMultiSelectOptions[];
  onChange: (value: InfiniteMultiSelectOptions[]) => void;
  options: InfiniteMultiSelectOptions[];
  placeholder?: string;
  searchPlaceholder?: string;
  disabled?: boolean;
  isLoading?: boolean;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  onSearch?: (value: string) => void;
  onLoadMore?: () => void;
}

// InfiniteMultiSelect component allows users to select multiple items with infinite scrolling and search capabilities 🌐
export function InfiniteMultiSelect({
  value = [],
  onChange,
  options,
  placeholder = "Select items...",
  searchPlaceholder = "Search items...",
  disabled = false,
  isLoading = false,
  isFetchingNextPage = false,
  hasNextPage = false,
  onSearch,
  onLoadMore,
}: InfiniteMultiSelectProps) {
  // Reference to the scrollable div for detecting scroll events 📜
  const scrollRef = useRef<HTMLDivElement>(null);
  // Store the last scroll position to optimize scroll handling 📍
  const lastScrollTop = useRef(0);
  // State to control the visibility of the popover 🔓
  const [open, setOpen] = useState(false);
  // Local state for managing the search input value 🔍
  const [localSearch, setLocalSearch] = useState("");

  // Create a debounced search function to limit the frequency of search calls ⏳
  const debouncedSearch = useMemo(
    () => debounce((value: string) => onSearch?.(value), 300),
    [onSearch]
  );

  // Handle search input changes by updating local state and triggering the debounced search function 🔄
  const handleSearch = useCallback(
    (search: string) => {
      setLocalSearch(search);
      debouncedSearch(search);
    },
    [debouncedSearch]
  );

  // Cleanup the debounced search on component unmount to prevent memory leaks 🧹
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  return (
    // Popover component to display the dropdown menu when open 🗂️
    <Popover open={open} onOpenChange={setOpen}>
      {/* Trigger element for the popover, styled as a button 🎯 */}
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          disabled={disabled}
          className={cn(
            "min-h-10 w-full min-w-64 max-w-96 justify-between",
            value.length > 0 && "h-full"
          )}
        >
          {/* Display selected badges or placeholder text inside the button 🏷️ */}
          <div className="flex flex-wrap gap-1 overflow-hidden">
            {value.length === 0 && placeholder}
            {value.map((item) => (
              <Badge
                key={item.value}
                variant="secondary"
                className={cn(
                  "mr-1 truncate rounded-sm px-1 font-normal",
                  item.colour && {
                    "bg-theme text-theme-foreground": item.colour === "theme",
                    "bg-secondary text-secondary-foreground":
                      item.colour === "secondary",
                    "bg-neutral text-neutral-foreground":
                      item.colour === "neutral",
                    "bg-success text-success-foreground":
                      item.colour === "success",
                    "bg-destructive text-destructive-foreground":
                      item.colour === "destructive",
                  }
                )}
                onClick={(e) => {
                  // Prevent the click event from closing the popover and remove the selected item ❌
                  e.stopPropagation();
                  onChange(value.filter((i) => i.value !== item.value));
                }}
              >
                {item.label}
                {/* Button to remove the badge, showing an 'X' icon ❌ */}
                <Button
                  variant="ghost"
                  className="ml-1 h-auto rounded-none p-0 hover:bg-transparent"
                >
                  <X className="h-3 w-3" />
                </Button>
              </Badge>
            ))}
          </div>
        </Button>
      </PopoverTrigger>

      {/* Content of the popover, containing the search and options list 📄 */}
      <PopoverContent
        className="w-[--radix-popover-trigger-width] p-0"
        sideOffset={2}
      >
        <Command shouldFilter={false} className="w-full">
          {/* Search input field for filtering options 🔍 */}
          <CommandInput
            placeholder={searchPlaceholder}
            value={localSearch}
            onValueChange={handleSearch}
          />
          {/* Display when no items are found 📭 */}
          {!isLoading && <CommandEmpty>No items found.</CommandEmpty>}
          <CommandGroup>
            {/* Scrollable container for the list of options 📜 */}
            <div
              ref={scrollRef}
              className={cn(
                "max-h-[200px]",
                isLoading && options.length === 0
                  ? "overflow-hidden"
                  : "overflow-auto"
              )}
              onScroll={(e) => {
                // Don't trigger scroll handler during loading state
                if (isLoading && options.length === 0) return;

                const target = e.target as HTMLDivElement;
                // Check if more items should be loaded based on scroll position 📈
                if (
                  !isFetchingNextPage &&
                  hasNextPage &&
                  target.scrollHeight - target.scrollTop <=
                    target.clientHeight * 1.5
                ) {
                  onLoadMore?.();
                }
                lastScrollTop.current = target.scrollTop;
              }}
            >
              {/* Show loading skeletons when initial loading is in progress ⏳ */}
              {isLoading && options.length === 0 ? (
                <div className="h-[200px] space-y-2 p-2">
                  <Skeleton className="h-8 w-full" />
                  <Skeleton className="h-8 w-full" />
                  <Skeleton className="h-8 w-full" />
                </div>
              ) : (
                // Render the list of option items 📝
                options.map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.value}
                    onSelect={() => {
                      // Toggle selection of the option and keep the popover open 🔄
                      onChange(
                        value.some((item) => item.value === option.value)
                          ? value.filter((item) => item.value !== option.value)
                          : [...value, option]
                      );
                      setOpen(true);
                    }}
                  >
                    {/* Check icon to indicate selected items ✔️ */}
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        value.some((item) => item.value === option.value)
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                    {option.label}
                  </CommandItem>
                ))
              )}
              {/* Show a spinner when fetching the next page of options 🔄 */}
              {hasNextPage && isFetchingNextPage && (
                <div className="flex justify-center p-2">
                  <Spinner size="sm" />
                </div>
              )}
            </div>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
