import {useEffect} from "react";

import {Link} from "@tanstack/react-router";
import {FilePlus2Icon} from "lucide-react";

import {buttonVariants} from "@/components/ui/button";
import {Heading2, Paragraph} from "@/components/ui/typography";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {AdminRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {
  CustomerManagementDataTable,
  customerManagementTableColumns,
} from "@/modules/admin";
import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useAuthorization,
} from "@/modules/auth";
import {useAllCustomers} from "@/modules/customers";

/**
 * Custom hook to set the current admin route for customer management.
 */
function useSetCustomerManagementRoute() {
  // Get the setAdminRoute function from the store
  const {setAdminRoute} = useRoutesStore();

  // Sets the admin route when the component is mounted
  useEffect(() => {
    setAdminRoute(AdminRoutesSchema.Values["/app/admin/customer-management"]);
  }, [setAdminRoute]);
}

/**
 * Custom hook for managing the customer management page state and authorization.
 * @returns {Object} The state and utility functions for the Customer Management Page.
 */
export function useCustomerManagementPage() {
  // Set the current admin route
  useSetCustomerManagementRoute();

  // Get the search customer query and data
  const searchCustomerQuery = useAllCustomers();
  const searchCustomerData = searchCustomerQuery.data?.content || [];

  // Get the authorization utility functions
  const {checkPoliciesAccess} = useAuthorization();

  // Checks if the user has the required role to view the page
  const isAuthorized = checkPoliciesAccess([
    KeycloakRoleEnum.READ_CUSTOMER_MANAGEMENT,
  ]);

  // Return the state and utility functions
  return {
    searchCustomerQuery,
    searchCustomerData,
    isAuthorized,
  };
}

/**
 * Component for managing customers in the admin interface.
 * @returns {JSX.Element} The rendered component.
 */
export function CustomerManagementPage() {
  // Get the search customer query and data
  const {searchCustomerQuery, searchCustomerData, isAuthorized} =
    useCustomerManagementPage();

  // Renders the NotAuthorizedPage if the user does not have access
  if (!isAuthorized) {
    return <NotAuthorizedPage />;
  }

  // Return the rendered component
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.READ_CUSTOMER_MANAGEMENT]}
    >
      <section>
        <header className="flex items-center justify-between">
          <div className="flex flex-col justify-center">
            <Heading2>Customer Management</Heading2>
            <Paragraph>Manage and onboard your customers here.</Paragraph>
          </div>
          <AccessPolicyWrapper
            unauthorizedFallback={null}
            policyActions={[KeycloakRoleEnum.CREATE_CUSTOMER_MANAGEMENT]}
          >
            <Link
              to="/app/admin/customer-management/create"
              className={cn(buttonVariants(), "mb-4 gap-2")}
            >
              <span className="sr-only">Add customer</span>
              Add Customer
              <FilePlus2Icon className="size-4" />
            </Link>
          </AccessPolicyWrapper>
        </header>
        <CustomerManagementDataTable
          columns={customerManagementTableColumns}
          data={searchCustomerData}
          isSuccess={searchCustomerQuery.isSuccess}
          isPending={searchCustomerQuery.isPending}
          isError={searchCustomerQuery.isError}
        />
      </section>
    </AccessPolicyWrapper>
  );
}
