import {useFormContext} from "react-hook-form";

import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";

import type {SupplierAPIMutatingProps} from "@/modules/imremit";

export function CommonForm({isMutating}: SupplierAPIMutatingProps) {
  return (
    <>
      <SupplierNameField isMutating={isMutating} />
      <SupplierNumberField isMutating={isMutating} />
      <SupplierEmailField isMutating={isMutating} />
      <PhoneField isMutating={isMutating} />
    </>
  );
}

function SupplierNameField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="supplierName"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="supplierName" showMandatoryAsterisk>
            Supplier Name:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the supplier name..."
              {...register("supplierName")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function SupplierNumberField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="supplierNumber"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="supplierNumber" showMandatoryAsterisk>
            Supplier Number:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the supplier ID..."
              {...register("supplierNumber")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function SupplierEmailField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="supplierEmail"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="supplierEmail" showMandatoryAsterisk>
            Supplier Email:
          </FormLabel>
          <FormControl>
            <Input
              type="email"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the supplier email..."
              {...register("supplierEmail")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
function PhoneField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="phoneNumber"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="phoneNumber">Phone Number:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the phone number..."
              {...register("phoneNumber")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

// TODO: Use this component in the future
// function SupplierAction({isMutating}: SupplierAPIMutatingProps) {
//   const {control, register} = useFormContext();

//   return (
//     <FormField
//       control={control}
//       name="enrolled"
//       render={({field}) => (
//         <FormItem>
//           <FormLabel className="mr-2" htmlFor="enrolled">
//             Supplier Enrollment:
//           </FormLabel>
//           <FormControl>
//             <Switch
//               checked={field.value as boolean}
//               disabled={isMutating}
//               aria-disabled={isMutating}
//               onCheckedChange={field.onChange}
//               {...register("enrolled")}
//             />
//           </FormControl>
//           <FormMessage />
//         </FormItem>
//       )}
//     />
//   );
// }
