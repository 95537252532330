// Importing necessary libraries and components
import {Link} from "@tanstack/react-router";
import {cva} from "class-variance-authority";

import {buttonVariants} from "@/components/ui/button";
import {Heading2} from "@/components/ui/typography";

import {type RoutesType} from "@/lib/routes/types";
import {useRoutePersistence} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {AccessPolicyWrapper, type KeycloakRoleEnum} from "@/modules/auth";

// Define custom button variants for subroute navigation
const subrouteButtonVariants = cva("w-full justify-between py-6", {
  variants: {
    isActive: {
      true: "bg-success-foreground hover:bg-success-foreground/70 active:bg-success-foreground/80 text-root",
      false: "",
    },
    fontSize: {
      xs: "text-xs",
      sm: "text-sm",
      base: "text-base",
      lg: "text-lg",
    },
  },
  defaultVariants: {
    isActive: false,
    fontSize: "base",
  },
});

/**
 * Props definition for the SubrouteNavigationButton component
 */
interface SubrouteNavigationButtonProps {
  icon: React.ReactNode; // The icon to be displayed on the button
  to: RoutesType; // The route to which the button links
  label: string; // The label for the button
  isActive: boolean; // Specifies if the button is active
  fontSize?: "xs" | "sm" | "base" | "lg"; // Specifies the font size for the button
}

/**
 * SubrouteNavigationButton component
 * Represents a single button in the navigation menu
 */
export function SubrouteNavigationButton({
  icon,
  to,
  label,
  isActive,
  fontSize = "base",
}: SubrouteNavigationButtonProps) {
  return (
    <Link
      to={to}
      className={cn(
        buttonVariants(),
        subrouteButtonVariants({isActive, fontSize})
      )}
    >
      {label}
      <span aria-hidden="true">{icon}</span>
    </Link>
  );
}

// Define grid layout variants using Class Variance Authority
// Add xlColumns as a parameter if you want to change the number of columns for the xl breakpoint
const gridVariants = (xlColumns: number = 3) =>
  cva("grid gap-2", {
    variants: {
      layout: {
        horizontal: `grid-cols-1 md:grid-cols-2 xl:w-2/3 xl:grid-cols-3 2xl:grid-cols-${String(
          xlColumns
        )} 2xl:w-1/2`,
        vertical: "grid-rows-1",
      },
    },
    defaultVariants: {
      layout: "horizontal",
    },
  });

/**
 * Props definition for the SubrouteNavigation component
 */
interface RouteData {
  to: RoutesType;
  icon: React.ReactNode;
  label: string;
  actionRole: KeycloakRoleEnum;
  fontSize?: "xs" | "sm" | "base" | "lg";
}

interface SubrouteNavigationProps {
  routes: RouteData[]; // The array of routes to be displayed in the navigation menu
  navigationAccessibleLabel: string; // The accessible label for the navigation menu
  navigationAriaLabelledby: string; // The aria-labelledby attribute for the navigation menu
  layout?: "horizontal" | "vertical"; // Determines the orientation of the grid
  xlColumns?: number; // Number of columns for xl breakpoint
}

/**
 * SubrouteNavigation component
 * Generates a navigation menu based on the given routes
 */
export function SubrouteNavigation({
  routes,
  navigationAccessibleLabel,
  navigationAriaLabelledby,
  layout = "horizontal", // Default to horizontal layout
  xlColumns = 3, // Default value for xlColumns is set to 3
}: SubrouteNavigationProps) {
  // This stopped working when fine grain routing was implemented
  // const {pathname} = useRouter().latestLocation;

  const {lastVisited} = useRoutePersistence();

  return (
    <nav
      className={cn(gridVariants(xlColumns)({layout}))}
      role="navigation"
      aria-label={navigationAccessibleLabel}
      aria-labelledby={navigationAriaLabelledby}
    >
      <Heading2 id={navigationAriaLabelledby} className="sr-only">
        {navigationAccessibleLabel}
      </Heading2>
      {routes.map((route) => (
        <AccessPolicyWrapper
          key={route.to}
          policyActions={[route.actionRole]}
          unauthorizedFallback={null}
        >
          <SubrouteNavigationButton
            to={route.to}
            // isActive={pathname.includes(route.to)}
            isActive={lastVisited?.includes(route.to) ?? false}
            icon={route.icon}
            label={route.label}
            fontSize={route.fontSize}
          />
        </AccessPolicyWrapper>
      ))}
    </nav>
  );
}
