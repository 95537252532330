// Define the validation schema using zod library.

import {z} from "zod";

import {multiSelectDatumSchema} from "@/components/craft/fancy-multi-select";
import {facetedFilterDatumSchema} from "@/components/craft/table";

// This schema is used to validate and coerce the input values from the form.
export const SearchInvoicesFormSchema = z.object({
  customers: z.array(multiSelectDatumSchema),
  suppliers: z.array(multiSelectDatumSchema),
  facilities: z.array(multiSelectDatumSchema),
  invoiceDateStart: z
    .date()
    .min(new Date("1970-01-01"), {
      message: "Date is out of range",
    })
    .optional(),
  invoiceDateEnd: z
    .date()
    .min(new Date("1970-01-01"), {
      message: "Date is out of range",
    })
    .optional(),
  status: z.array(facetedFilterDatumSchema),
  invoiceDate: z
    .date()
    .min(new Date("1970-01-01"), {
      message: "Date is out of range",
    })
    .optional(),
  paymentDate: z
    .date()
    .min(new Date("1970-01-01"), {
      message: "Date is out of range",
    })
    .optional(),
  invoiceAmount: z.preprocess(
    (args) => (args === "" ? undefined : args),
    z.coerce
      .string()
      .refine(
        (val) => {
          const numericVal = parseFloat(val);
          return (
            !isNaN(numericVal) &&
            numericVal <= 9999999999 &&
            numericVal >= -9999999999
          );
        },
        {
          message:
            "Invoice amount must be a valid number with up to 2 decimal places, less than 10 billion, and greater than -10 billion",
        }
      )
      .optional()
  ),

  invoiceNumber: z.string().optional(),
  paymentNumber: z.string().min(0).max(50).optional(),
  poNumber: z.string().optional(),
  erpUniqueId: z.string().optional(),
});

export const InvoicesMappingFormSchema = z.object({
  configId: z.number(),
  taxId: z.number().nullable().optional(),
  facilityName: z.number().nullable().optional(),
  invoiceDate: z.number().nullable().optional(),
  invoiceAmount: z.number().nullable().optional(),
  status: z.number().nullable().optional(),
  paymentDate: z.number().nullable().optional(),
  facilityId: z.number().nullable().optional(),
  vendorNumber: z.number().nullable().optional(),
  invoicePaidAmount: z.number().nullable().optional(),
  poNumber: z.number().nullable().optional(),
  suffix: z.number().nullable().optional(),
  cancelDate: z.number().nullable().optional(),
  invoiceType: z.number().nullable().optional(),
  erpUniqueId: z.number().nullable().optional(),
  paymentStatus: z.number().nullable().optional(),
  vendorName: z.number().nullable().optional(),
  paymentTerms: z.number().nullable().optional(),
  paymentMethod: z.number().nullable().optional(),
  invoiceNo: z.number().nullable().optional(),
  paymentNumber: z.number().nullable().optional(),
  dueDate: z.number().nullable().optional(),
  cancelId: z.number().nullable().optional(),
  voidDate: z.number().nullable().optional(),
  voidId: z.number().nullable().optional(),
  vendorGroup: z.number().nullable().optional(),
  vendorLocationCode: z.number().nullable().optional(),
  operatingUnit: z.number().nullable().optional(),
  createDate: z.number().nullable().optional(),
  paymentType: z.number().nullable().optional(),
  voucherLineNo: z.number().nullable().optional(),
  voucherLineAmount: z.number().nullable().optional(),
  discountDate: z.number().nullable().optional(),
  discountAmount: z.number().nullable().optional(),
  salesAmount: z.number().nullable().optional(),
  freightAmount: z.number().nullable().optional(),
  miscAmount: z.number().nullable().optional(),
  origin: z.number().nullable().optional(),
  grossAmount: z.number().nullable().optional(),
  futureUse1: z.number().nullable().optional(),
  futureUse2: z.number().nullable().optional(),
  futureUse3: z.number().nullable().optional(),
  futureUse4: z.number().nullable().optional(),
  futureUse5: z.number().nullable().optional(),
});
export type InvoicesMappingFormType = z.infer<typeof InvoicesMappingFormSchema>;

export const RegisterSupplierRequestSchema = z.object({
  registerSupplierRequest: z.array(InvoicesMappingFormSchema),
});

export type RegisterSupplierRequestType = z.infer<
  typeof RegisterSupplierRequestSchema
>;

export const invoiceMappingTableColumnsSchema = z.object({
  id: z.boolean(),
  configId: z.boolean(),
  taxId: z.boolean(),
  facilityName: z.boolean(),
  invoiceDate: z.boolean(),
  invoiceAmount: z.boolean(),
  status: z.boolean(),
  paymentDate: z.boolean(),
  facilityId: z.boolean(),
  vendorNumber: z.boolean(),
  invoicePaidAmount: z.boolean(),
  poNumber: z.boolean(),
  suffix: z.boolean(),
  cancelDate: z.boolean(),
  invoiceType: z.boolean(),
  erpUniqueId: z.boolean(),
  paymentStatus: z.boolean(),
  vendorName: z.boolean(),
  paymentTerms: z.boolean(),
  paymentMethod: z.boolean(),
  invoiceNo: z.boolean(),
  paymentNumber: z.boolean(),
  dueDate: z.boolean(),
  cancelId: z.boolean(),
  voidDate: z.boolean(),
  voidId: z.boolean(),
  vendorGroup: z.boolean(),
  vendorLocationCode: z.boolean(),
  operatingUnit: z.boolean(),
  createDate: z.boolean(),
  paymentType: z.boolean(),
  voucherLineNo: z.boolean(),
  voucherLineAmount: z.boolean(),
  discountDate: z.boolean(),
  discountAmount: z.boolean(),
  salesAmount: z.boolean(),
  freightAmount: z.boolean(),
  miscAmount: z.boolean(),
  origin: z.boolean(),
  grossAmount: z.boolean(),
  futureUse1: z.boolean(),
  futureUse2: z.boolean(),
  futureUse3: z.boolean(),
  futureUse4: z.boolean(),
  futureUse5: z.boolean(),
});
export type invoiceMappingTableColumnsType = z.infer<
  typeof invoiceMappingTableColumnsSchema
>;
