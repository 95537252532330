import {useMemo} from "react";

import type {z} from "zod";

import type {PaymentManagementFormSchema} from "@/modules/imremit";
import {
  useGetOrganizationsList,
  useGetProxyPayPaymentStatuses,
  useGetSuppliersList,
} from "@/modules/imremit";

import {parseAndFormatDate} from "@/utils/functions";

/**
 * Transforms the form fields into a structure suitable for API calls.
 *
 * @param {object} formFields - The form fields to transform.
 * @returns {object} Transformed form fields.
 */
export function usePaymentCriteriaFields(
  formFields: z.infer<typeof PaymentManagementFormSchema>
) {
  // Destructure form fields for easier access and manipulation.
  const {
    suppliers,
    initiatedDate,
    endDate,
    paymentNumber,
    accountNumber,
    agentName,
    paymentDetailId,
    status,
    description,
    supplierNumber,
    supplierName,
    totalAmountSent,
    amountTaken,
    balanceAmount,
    orgId,
    facilityName,
    methodName,
    descriptionDetail,
    providerName,
    remittanceMethod,
    userId,
  } = formFields;

  return useMemo(() => {
    return {
      fromDate: initiatedDate
        ? (parseAndFormatDate(initiatedDate.toString()) as unknown as Date)
        : null,
      toDate: endDate
        ? (parseAndFormatDate(endDate.toString()) as unknown as Date)
        : null,
      paymentNumber: paymentNumber ? paymentNumber.toString() : null,
      accountNumber: accountNumber ? accountNumber.toString() : null,
      agentName: agentName ? agentName.toString() : null,
      paymentDetailId: paymentDetailId ? paymentDetailId.toString() : null,
      statusId: status.flatMap((statusObject) =>
        statusObject.value.split(",").map((id) => parseInt(id))
      ),
      description: description ? description.toString() : null,
      supplierNumber: supplierNumber ? supplierNumber.toString() : null,
      supplierName: supplierName ? supplierName.toString() : null,
      supplierId: suppliers.map((supplier) => parseInt(supplier.value)),
      totalAmountSent: totalAmountSent ? totalAmountSent : null,
      amountTaken: amountTaken ? amountTaken : null,
      balanceAmount: balanceAmount ? balanceAmount : null,
      orgId: orgId ? orgId.toString() : null,
      remittanceMethod: remittanceMethod ? remittanceMethod.toString() : null,
      userId: userId ? userId.toString() : null,
      facilityName: facilityName ? facilityName.toString() : null,
      methodName: methodName ? methodName.toString() : null,
      descriptionDetail: descriptionDetail
        ? descriptionDetail.toString()
        : null,
      providerName: providerName ? providerName.toString() : null,
    };
  }, [
    suppliers,
    initiatedDate,
    endDate,
    paymentNumber,
    accountNumber,
    agentName,
    paymentDetailId,
    status,
    description,
    supplierNumber,
    supplierName,
    totalAmountSent,
    amountTaken,
    balanceAmount,
    orgId,
    remittanceMethod,
    userId,
    facilityName,
    methodName,
    descriptionDetail,
    providerName,
  ]);
}

/**
 * Custom hook to fetch and parse population data, including payment statuses and suppliers.
 *
 * @returns {{
 *   suppliersData: {label: string, value: string}[],
 *   statusData: {label: string, value: string}[]
 * }} - Object containing parsed suppliersData and statusData.
 */
export function useFetchAndParsePopulationData(
  moduleType: string,
  externalId?: string
) {
  /**
   * @type {{data: {content: any[]} | undefined}}
   */
  const allStatusQuery = useGetProxyPayPaymentStatuses(moduleType);

  /**
   * @type {any[] | undefined}
   */
  const rawStatusData = allStatusQuery.data?.content;

  // console.log("rawStatusData", rawStatusData);

  /**
   * @type {{label: string, value: string}[]}
   */
  const statusData = useMemo(() => {
    if (!rawStatusData) return [];

    return rawStatusData.map((status) => ({
      label: status.customerStatus,
      value: status.paymentStatusId.toString(),
    }));
  }, [rawStatusData]);

  /**
   * @type {{data: {content: any[]} | undefined}}
   */
  const getSupplierByCustomerIdQuery = useGetSuppliersList(externalId);

  /**
   * @type {any[] | undefined}
   */
  const rawSuppliersData = getSupplierByCustomerIdQuery.data?.content;
  const suppliersPending = getSupplierByCustomerIdQuery.isPending;

  /**
   * @type {{label: string, value: string}[]}
   */
  const suppliersData = useMemo(() => {
    if (!rawSuppliersData) return [];

    return rawSuppliersData.map((supplier) => ({
      label: `${supplier.supplierNumber ? supplier.supplierNumber : ""} - ${
        supplier.supplierName ? supplier.supplierName : ""
      }`,
      value: supplier.supplierId.toString(),
    }));
  }, [rawSuppliersData]);

  /**
   * @type {{data: {content: any[]} | undefined}}
   */
  const getOrganizationsByCustomerIdQuery = useGetOrganizationsList(externalId);

  /**
   * @type {any[] | undefined}
   */
  const rawOrganizationssData = getOrganizationsByCustomerIdQuery.data?.content;

  /**
   * @type {{label: string, value: string}[]}
   */
  const organizationsData = useMemo(() => {
    if (!rawOrganizationssData) return [];

    return rawOrganizationssData
      .map((organization) => {
        return {
          label: organization.orgId,
          value: organization.orgId.toString(),
        };
      })
      .filter(Boolean);
  }, [rawOrganizationssData]);

  // remove duplicate organizations
  const organizationsList = organizationsData.filter(
    (organization, index, self) =>
      index ===
      self.findIndex(
        (t) => t.label === organization.label && t.value === organization.value
      )
  );

  return {
    suppliersData,
    statusData,
    organizationsList,
    suppliersPending,
  };
}
