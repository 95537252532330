import {useMutation} from "@tanstack/react-query";
import {Trash2Icon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  // DialogTrigger,
} from "@/components/ui/dialog";
import {toast} from "@/components/ui/use-toast";
import {LoadingSection} from "@/components/craft/loading-section";

import {deleteUserFn} from "@/modules/admin/api/keycloak-api/delete-user-api";

interface UserDeleteProps {
  userId: string;
  deleteDialogOpen: boolean;
  setDeleteDialogOpen: (open: boolean) => void;
}

export function UserDelete({
  userId,
  deleteDialogOpen,
  setDeleteDialogOpen,
}: UserDeleteProps) {
  const deleteUserMutation = useMutation({
    mutationFn: (userId: string) => {
      return deleteUserFn(userId);
    },
    onSuccess: (response) => {
      console.log("deleteUserResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "User deleted successfully",
      });

      setDeleteDialogOpen(false);
    },

    onError: (error) => {
      setDeleteDialogOpen(false);
      console.error("error", error);
      const errorMessage =
        typeof error === "string" ? error : JSON.stringify(error);
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to delete user",
      });
    },
  });

  return (
    <Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
      <DialogTrigger>
        <div className="relative flex cursor-pointer select-none items-center justify-between rounded-sm text-sm  outline-none transition-colors hover:cursor-pointer focus:bg-destructive  data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50">
          Delete User
          <span className="sr-only"> Delete User</span>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Are you sure?</DialogTitle>
          <DialogDescription>
            Do you want to delete the entry? Deleting this entry cannot be
            undone.
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              aria-disabled={deleteUserMutation.isPending}
              disabled={deleteUserMutation.isPending}
              variant="outline"
              onClick={(e) => {
                e.preventDefault();
                setDeleteDialogOpen(false);
              }}
            >
              Cancel
            </Button>
          </DialogClose>
          <Button
            variant="destructive"
            className="flex items-center gap-2"
            aria-disabled={deleteUserMutation.isPending}
            disabled={deleteUserMutation.isPending}
            onClick={() => {
              deleteUserMutation.mutate(userId);
            }}
          >
            {deleteUserMutation.isPending ? (
              <LoadingSection sectionSize="xs" />
            ) : (
              <Trash2Icon className="size-4" />
            )}
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
