import {z} from "zod";

export const supplierManagementAdminTableColumnsSchema = z.object({
  id: z.boolean(),
  name: z.boolean(),
  taxCode: z.boolean(),
  groupId: z.boolean(),
});
export type SupplierManagementAdminTableColumnsType = z.infer<
  typeof supplierManagementAdminTableColumnsSchema
>;
