import {useCallback, useEffect, useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {
  BadgeDollarSignIcon,
  BarChartHorizontalIcon,
  CoinsIcon,
  CopyXIcon,
  FileCogIcon,
  FileJson2Icon,
  HardDriveDownloadIcon,
  LandmarkIcon,
  LayoutTemplateIcon,
  NewspaperIcon,
  PencilRulerIcon,
  SquareStackIcon,
  TruckIcon,
  UserCog2Icon,
  UserCogIcon,
  UserSquareIcon,
} from "lucide-react";
import {useForm, useFormContext} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {Card, CardContent, CardHeader} from "@/components/ui/card";
import {Checkbox} from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Spinner} from "@/components/ui/spinner";
import {Paragraph, UnorderedList} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import type {KeycloakRoleType, RoleToGroupDTO} from "@/modules/admin";
import {
  addRoleToGroupFn,
  adminModule,
  customerManagementModule,
  deleteRoleToGroupFn,
  duplicatePaymentsModule,
  electronicInvoicingModule,
  fileProcessingModule,
  IMRemitDashboardModule,
  invoiceTrackerModule,
  keycloakActionSchema,
  paymentManagementModule,
  proxyPayModule,
  statementReconModule,
  supplierManagementModule,
  supplierScriptManagementModule,
  useAllRealmRoles,
  useGetGroupRolesById,
  userManagementModule,
} from "@/modules/admin";

interface LoadedRolesValues {
  [roleName: string]: boolean;
}

// A utility function that finds a role by name from a list of roles
const useRoleFinder = (allRoles: KeycloakRoleType[]) =>
  useCallback(
    (roleName: string) => allRoles.find((role) => role.name === roleName),
    [allRoles]
  );

// Component or hook function
const useRoleOperations = (
  formFields: LoadedRolesValues,
  loadedRolesValues: LoadedRolesValues,
  allRoles: KeycloakRoleType[]
) => {
  const findRoleByName = useRoleFinder(allRoles);

  // Determine roles that need to be deleted
  const rolesToDelete = useMemo(() => {
    return Object.keys(loadedRolesValues)
      .filter((role) => !formFields[role])
      .map(findRoleByName)
      .filter((role): role is KeycloakRoleType => role !== undefined);
  }, [formFields, loadedRolesValues, findRoleByName]);

  // Identify roles that are selected
  const selectedRoles = useMemo(() => {
    return Object.entries(formFields).filter(([, value]) => value);
  }, [formFields]);

  // Determine roles that need to be added
  const rolesToAdd = useMemo(() => {
    const loadedRoles = new Set(Object.keys(loadedRolesValues));
    return selectedRoles
      .filter(([roleName]) => {
        const isNotLoaded = !loadedRoles.has(roleName);
        return isNotLoaded;
      })
      .map(([roleName]) => findRoleByName(roleName))
      .filter((role): role is KeycloakRoleType => role !== undefined);
  }, [selectedRoles, loadedRolesValues, findRoleByName]);

  return {rolesToAdd, rolesToDelete};
};

export function RoleGroupForm({groupId}: {groupId: string}) {
  const groupRolesByIdQuery = useGetGroupRolesById(groupId);
  const {data: allRolesData} = useAllRealmRoles();

  const allRoles = allRolesData ?? [];

  const {
    isSuccess: groupRolesLoaded,
    isError: groupRolesError,
    data: groupRolesData,
  } = groupRolesByIdQuery;

  const groupRolesById = useMemo(
    () => groupRolesData?.realmMappings ?? [],
    [groupRolesData]
  );

  const loadedRolesValues = useMemo(
    () =>
      groupRolesById.reduce<{[key: string]: boolean}>((acc, role) => {
        acc[role.name] = true;
        return acc;
      }, {}),
    [groupRolesById]
  );

  const roleSettingsForm = useForm<z.infer<typeof keycloakActionSchema>>({
    mode: "onChange",
    resolver: zodResolver(keycloakActionSchema),
  });

  useEffect(() => {
    // eslint-disable-next-line
    if (groupRolesLoaded && !groupRolesError && !!groupId) {
      roleSettingsForm.reset(loadedRolesValues);
    }
  }, [
    groupRolesLoaded,
    groupRolesError,
    roleSettingsForm,
    loadedRolesValues,
    groupId,
  ]);

  const formFields = roleSettingsForm.watch();

  const {rolesToAdd, rolesToDelete} = useRoleOperations(
    formFields,
    loadedRolesValues,
    allRoles
  );

  // console.log("rolesToAdd: ", rolesToAdd);
  // console.log("rolesToDelete: ", rolesToDelete);

  function onSubmit() {
    console.log("submit");
  }

  return groupId ? (
    <Form {...roleSettingsForm}>
      <form onSubmit={roleSettingsForm.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 gap-4 pt-4 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
          <RolesConfigCard
            title="Invoice Tracker"
            icon={<NewspaperIcon />}
            roles={invoiceTrackerModule}
          />
          <RolesConfigCard
            title="Electronic Invoicing"
            icon={<LandmarkIcon />}
            roles={electronicInvoicingModule}
          />
          <RolesConfigCard
            title="File Processing"
            icon={<FileCogIcon />}
            roles={fileProcessingModule}
          />
          <RolesConfigCard
            title="Statement Recon"
            icon={<BarChartHorizontalIcon />}
            roles={statementReconModule}
          />
          <RolesConfigCard
            title="Duplicate Payments"
            icon={<SquareStackIcon />}
            roles={duplicatePaymentsModule}
          />
          <RolesConfigCard
            title="Proxy Pay Management"
            icon={<BadgeDollarSignIcon />}
            roles={proxyPayModule}
          />
          <RolesConfigCard
            title="Customer Management"
            icon={<UserSquareIcon />}
            roles={customerManagementModule}
          />
          <RolesConfigCard
            title="User Management"
            icon={<UserCogIcon />}
            roles={userManagementModule}
          />
          <RolesConfigCard
            title="Supplier Management"
            icon={<TruckIcon />}
            roles={supplierManagementModule}
          />
          <RolesConfigCard
            title="Supplier Script Management"
            icon={<FileJson2Icon />}
            roles={supplierScriptManagementModule}
          />
          <RolesConfigCard
            title="IMRemit Dashboard"
            icon={<LayoutTemplateIcon />}
            roles={IMRemitDashboardModule}
          />
          <RolesConfigCard
            title="Payment Management"
            icon={<CoinsIcon />}
            roles={paymentManagementModule}
          />
          <RolesConfigCard
            title="Admin"
            icon={<UserCog2Icon />}
            roles={adminModule}
          />
        </div>
      </form>
      <AddAndDeleteRoleDialog
        actionsToAdd={rolesToAdd}
        actionsToDelete={rolesToDelete}
        groupId={groupId}
      />
    </Form>
  ) : (
    <Paragraph>Please select a role group.</Paragraph>
  );
}

interface AddAndDeleteRoleDialogProps {
  actionsToAdd: KeycloakRoleType[];
  actionsToDelete: KeycloakRoleType[];
  groupId: string;
}

function AddAndDeleteRoleDialog({
  actionsToAdd,
  actionsToDelete,
  groupId,
}: AddAndDeleteRoleDialogProps) {
  const [dialogOpen, setDialogOpen] = useState(false);

  const addRole = useMutation({
    mutationFn: (data: RoleToGroupDTO) => {
      return addRoleToGroupFn(data, groupId);
    },
    onSuccess: (response) => {
      console.log("addRoleResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Role(s) successfully saved.",
      });
    },
    onError: (error: unknown) => {
      console.log("error:", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to save role(s)",
      });
    },
  });

  const deleteRole = useMutation({
    mutationFn: (data: RoleToGroupDTO) => {
      return deleteRoleToGroupFn(data, groupId);
    },
    onSuccess: (response) => {
      console.log("deleteRoleResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Role(s) successfully saved.",
      });
    },
    onError: (error: unknown) => {
      console.log("error:", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to save role(s)",
      });
    },
  });

  const {isPending: addRolesPending, mutate: addRolesMutation} = addRole;
  const {isPending: deleteRolesPending, mutate: deleteRolesMutation} =
    deleteRole;

  return (
    <div className="flex justify-end pt-4">
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogTrigger asChild>
          <Button
            className="gap-2"
            disabled={!actionsToAdd.length && !actionsToDelete.length}
          >
            <span className="sr-only">Modify Permissions</span>
            Modify Permissions <PencilRulerIcon className="size-4" />
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:min-w-[640px] md:min-w-[768px] lg:min-w-[1024px]">
          <DialogHeader>
            <DialogTitle>Modify Permissions</DialogTitle>
            <DialogDescription>
              Add or remove permissions from the selected role group.
            </DialogDescription>
          </DialogHeader>
          {!!actionsToAdd.length && (
            <>
              <DialogTitle>Permissions to Add</DialogTitle>
              <UnorderedList className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                {actionsToAdd.map((action) => (
                  <li key={action.name}>
                    <Paragraph>{action.name}</Paragraph>
                  </li>
                ))}
              </UnorderedList>
            </>
          )}

          {!!actionsToDelete.length && (
            <>
              <DialogTitle>Permissions to Delete</DialogTitle>
              <UnorderedList className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                {actionsToDelete.map((action) => (
                  <li key={action.name}>
                    <Paragraph>{action.name}</Paragraph>
                  </li>
                ))}
              </UnorderedList>
            </>
          )}

          <DialogFooter>
            <Button
              variant="secondary"
              className="gap-2"
              onClick={() => {
                setDialogOpen((prevOpen) => !prevOpen);
              }}
            >
              <span className="sr-only">Cancel</span>
              Cancel
              <CopyXIcon className="size-4" />
            </Button>
            <Button
              className="gap-2"
              disabled={!actionsToAdd.length && !actionsToDelete.length}
              onClick={() => {
                addRolesMutation(actionsToAdd);
                deleteRolesMutation(actionsToDelete);
                setDialogOpen((prevOpen) => !prevOpen);
              }}
            >
              <span className="sr-only">Save Permissions</span>
              Save Permissions
              {addRolesPending || deleteRolesPending ? (
                <Spinner />
              ) : (
                <HardDriveDownloadIcon className="size-4" />
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

interface RoleConfigCardProps {
  title: string;
  icon: JSX.Element;
  roles: string[];
}

function RolesConfigCard({title, icon, roles}: RoleConfigCardProps) {
  const {register, control} = useFormContext();
  const {isPending} = useAllRealmRoles();

  return (
    <Card className="w-full">
      <CardHeader>
        <div className="flex items-center gap-2">
          {icon}
          <span>{title}</span>
        </div>
      </CardHeader>
      <CardContent>
        {!isPending ? (
          roles.map((role) => (
            <div key={role} className="mt-2 flex items-center">
              <FormField
                control={control}
                name={role}
                render={({field}) => (
                  <FormItem>
                    <FormControl>
                      {/* eslint-disable @typescript-eslint/no-unsafe-assignment */}
                      <Checkbox
                        disabled={isPending}
                        aria-disabled={isPending}
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        {...register(role)}
                      />
                      {/* eslint-enable @typescript-eslint/no-unsafe-assignment */}
                    </FormControl>
                    <FormLabel className="ml-2" htmlFor={role}>
                      {role}
                    </FormLabel>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          ))
        ) : (
          <Spinner />
        )}
      </CardContent>
    </Card>
  );
}
