import {keycloakKyApiFn} from "@/lib/ky";
import {type KeycloakCreateUserTypeDTO} from "@/modules/admin";

export const editUserFn = (
  userId: string,
  KeycloakCreateUserTypeDTO: KeycloakCreateUserTypeDTO
) => {
  const realm =
    (import.meta.env.VITE_KEYCLOAK_REALM as string | undefined) ?? "dev";
  return keycloakKyApiFn(`admin/realms/${realm}/users/${userId}`, "put", {
    json: KeycloakCreateUserTypeDTO,
  });
};
