import {useMemo, useState} from "react";

import {useNavigate} from "@tanstack/react-router";
import {type Row} from "@tanstack/react-table";
import {
  FileEditIcon,
  FileTextIcon,
  GripVerticalIcon,
  Lock,
  TrashIcon,
} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  // DropdownMenuRadioGroup,
  // DropdownMenuRadioItem,
  DropdownMenuSeparator,
  // DropdownMenuShortcut,
  // DropdownMenuSub,
  // DropdownMenuSubContent,
  // DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import {
  useGetgroupByUserId,
  UserDelete,
  UserResetPassword,
  // UserResetPassword
} from "@/modules/admin";
import {
  AccessPolicyWrapper,
  calculateUserRoles,
  KeycloakRoleEnum,
  useAuthorization,
  useKeyCloakInstanceStore,
} from "@/modules/auth";

import {KeycloakRoles, KeycloakRolesWithHyphen} from "@/utils/constants";

/**
 * Props for UserManagementRowActions component.
 * @typedef {Object} UserManagementRowActionsProps
 * @template TData - The type of data for the row.
 * @property {Row<TData>} row - The data row.
 */
interface UserManagementRowActionsProps<TData> {
  row: Row<TData>;
  id: string;
}

/**
 * Represents the actions for a payment file row.
 * This component provides a dropdown menu for each row with various actions like edit, copy, favorite, and delete.
 *
 * @template TData - The data type for the table row.
 * @param {UserManagementRowActionsProps<TData>} props - The props for the component.
 * @returns {JSX.Element} The UserManagementRowActions component.
 */
export function UserManagementRowActions<TData>({
  id,
}: UserManagementRowActionsProps<TData>) {
  // Parses the row data using the schema defined for payment mapping list.

  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const {checkPoliciesAccess} = useAuthorization();
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  // const currentUserId = id.toString();
  const groupQuery = useGetgroupByUserId(id);
  const groupData = groupQuery.data;

  // Get the navigate function from the router
  const navigate = useNavigate();

  const userId = storeKeyCloakInstance?.tokenParsed?.sub as string;
  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  const userRole = useMemo(() => {
    if (!groupData) return [];
    return groupData.map((users) => ({
      name: users.name,
    }));
  }, [groupData]);

  const displayDeleteButton = useMemo(() => {
    return userRole.some(
      (role) =>
        (role.name == KeycloakRolesWithHyphen.SupplierUser &&
          userRoleDisplay === KeycloakRoles.SupplierAdmin) ||
        (role.name == KeycloakRolesWithHyphen.CustomerUser &&
          userRoleDisplay === KeycloakRoles.CustomerAdmin) ||
        ((role.name == KeycloakRolesWithHyphen.CustomerUser ||
          role.name == KeycloakRolesWithHyphen.CustomerAdmin) &&
          userRoleDisplay === KeycloakRoles.CustomerSuperAdmin) ||
        userRoleDisplay === KeycloakRoles.Management
    );
  }, [userRole, userRoleDisplay]);

  return (
    <>
      <DropdownMenu modal={false}>
        {/* Trigger button for the dropdown menu */}
        <DropdownMenuTrigger asChild>
          <Button className="flex h-12 w-12 gap-2 bg-theme p-0 hover:bg-neutral data-[state=open]:bg-secondary">
            <span className="sr-only">Open menu</span>
            <GripVerticalIcon className="size-6 bg-inherit" />
          </Button>
        </DropdownMenuTrigger>

        {/* Content of the dropdown menu */}
        <DropdownMenuContent align="end" className="min-w-fit">
          {/* List of actions in the dropdown menu */}
          {checkPoliciesAccess([KeycloakRoleEnum.UPDATE_USER_MANAGEMENT]) ? (
            <>
              <DropdownMenuItem
                className="flex justify-between gap-4"
                onSelect={() => {
                  void navigate({
                    to: "/app/admin/user-management/$userId/edit",
                    params: {userId: id},
                    replace: true,
                  });
                }}
              >
                Edit User Details
                <span className="sr-only">Edit User Details</span>
                <FileEditIcon className="size-4" />
              </DropdownMenuItem>
              <DropdownMenuSeparator />
            </>
          ) : null}

          <DropdownMenuItem
            className="flex justify-between gap-4"
            onSelect={() => {
              void navigate({
                to: "/app/admin/user-management/$userId/view",
                params: {userId: id},
                replace: true,
              });
            }}
          >
            View User Profile
            <span className="sr-only">View User Profile</span>
            <FileTextIcon className="size-4" />
          </DropdownMenuItem>
          {userId === id ? (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="flex justify-between gap-4"
                onSelect={(e) => {
                  e.preventDefault();
                }}
              >
                <UserResetPassword
                  userId={id}
                  resetDialogOpen={resetDialogOpen}
                  setResetDialogOpen={setResetDialogOpen}
                />
                <Lock className="size-4" />
              </DropdownMenuItem>
            </>
          ) : null}

          <AccessPolicyWrapper
            unauthorizedFallback={null}
            policyActions={[KeycloakRoleEnum.DELETE_USER_MANAGEMENT]}
          >
            {displayDeleteButton ? (
              <>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  className="flex justify-between gap-4"
                  onSelect={(e) => {
                    e.preventDefault();
                  }}
                >
                  <UserDelete
                    userId={id}
                    deleteDialogOpen={deleteDialogOpen}
                    setDeleteDialogOpen={setDeleteDialogOpen}
                  />
                  <TrashIcon className="size-4" />
                </DropdownMenuItem>
              </>
            ) : null}
          </AccessPolicyWrapper>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
