import {DropdownMenuTrigger} from "@radix-ui/react-dropdown-menu";
import type {Table} from "@tanstack/react-table";
import {SlidersHorizontalIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import {ScrollArea} from "@/components/ui/scroll-area";

// Define properties for DataTableViewOptions component
interface DataTableViewOptionsProps<TData> {
  table: Table<TData>; // React Table instance
  columnsOtherName?: {key: string; value: string}[];
}

// DataTableViewOptions is a generic React functional component which receives a react table instance as a prop.
export function DataTableViewOptions<TData>({
  table,
  columnsOtherName,
}: DataTableViewOptionsProps<TData>) {
  return (
    <DropdownMenu>
      {/* The dropdown trigger is a button that will display the dropdown menu when clicked */}
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline" // Button style
          size="sm" // Button size
          className="flex h-8 gap-2 whitespace-nowrap border-dashed bg-root" // CSS classes for styling
        >
          {/* This is an icon for the button */}
          <SlidersHorizontalIcon className="size-4" />
          Column Views
        </Button>
      </DropdownMenuTrigger>

      {/* The content of the dropdown menu */}
      <DropdownMenuContent align="end" className="min-w-fit">
        <ScrollArea className="max-h-80">
          {/* Label for the dropdown menu items */}
          <DropdownMenuLabel>Toggle columns</DropdownMenuLabel>
          {/* Divider line for the dropdown menu */}
          <DropdownMenuSeparator />

          {/* Mapping through all table columns */}
          {table
            .getAllColumns()
            // Filter columns to only those that have an accessor function and can be hidden
            .filter(
              (column) =>
                typeof column.accessorFn !== "undefined" && column.getCanHide()
            )
            // Map each column to a checkbox item
            .map((column) => {
              return (
                // The checkbox item has an event listener to toggle column visibility
                <DropdownMenuCheckboxItem
                  key={column.id} // React key for list
                  className="capitalize hover:cursor-pointer" // CSS class for styling
                  checked={column.getIsVisible()} // Checkbox checked state
                  // Event listener to toggle visibility
                  onCheckedChange={(value) => {
                    column.toggleVisibility(!!value);
                  }}
                >
                  <GetColumnName
                    columnId={column.id.toString()}
                    columnsOtherName={columnsOtherName}
                  />
                </DropdownMenuCheckboxItem>
              );
            })}
        </ScrollArea>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

interface GetColumnNameProps {
  columnId: string;
  columnsOtherName?: {key: string; value: string}[];
}

/**
 *
 * @param columnId
 * @param columnsOtherName
 * @returns
 */
function GetColumnName({columnId, columnsOtherName}: GetColumnNameProps) {
  if (columnsOtherName) {
    const columnExist = columnsOtherName.filter((obj) => obj.key === columnId);

    return columnExist.length > 0
      ? columnExist.map((resp) => resp.value)
      : columnId;
  } else {
    return columnId;
  }
}
