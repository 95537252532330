import {useEffect} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate, useParams} from "@tanstack/react-router";
import {format} from "date-fns";
import {
  CalendarIcon,
  CheckCircleIcon,
  SaveAllIcon,
  XCircleIcon,
} from "lucide-react";
import {useForm, useFormContext} from "react-hook-form";
import type * as z from "zod";

import {Button, buttonVariants} from "@/components/ui/button";
import {Calendar} from "@/components/ui/calendar";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Separator} from "@/components/ui/separator";
import {Spinner} from "@/components/ui/spinner";
import {Textarea} from "@/components/ui/textarea";
import {Heading3} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {LoadingSkeletonCard} from "@/components/craft/loading-skeleton-card";

import {cn} from "@/lib/utils";
import {useKeyCloakInstanceStore} from "@/modules/auth";
import type {SupplierAPIMutatingProps} from "@/modules/imremit";
import {
  CommonForm,
  editSupplierDeclinedFn,
  EditSupplierDeclinedFormSchema,
  useGetSuppliersDetails,
} from "@/modules/imremit";

export function DeclinedForm({children}: {children: React.ReactNode}) {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;
  const changedBy = parsedUserInfo?.sub || "";
  const navigate = useNavigate();
  const {supplierId} = useParams({
    from: "/app/imremit-lite/supplier-management/$supplierId/edit",
  });

  const searchSupplierIDQuery = useGetSuppliersDetails(Number(supplierId));

  const supplierManagementDeclinedFormMethods = useForm<
    z.infer<typeof EditSupplierDeclinedFormSchema>
  >({
    defaultValues: {
      enrolled: false,
      changedBy: changedBy,
      saveAndContinue: true,
      declinedDate: new Date(),
    },
    mode: "onChange",
    resolver: zodResolver(EditSupplierDeclinedFormSchema),
  });

  useEffect(() => {
    if (searchSupplierIDQuery.data?.content.declinedReason) {
      supplierManagementDeclinedFormMethods.setValue(
        "declinedReason",
        searchSupplierIDQuery.data.content.declinedReason
      );
    }
    if (searchSupplierIDQuery.data?.content.declinedDate) {
      supplierManagementDeclinedFormMethods.setValue(
        "declinedDate",
        new Date(searchSupplierIDQuery.data.content.declinedDate)
      );
    }

    if (searchSupplierIDQuery.data?.content.supplierName) {
      supplierManagementDeclinedFormMethods.setValue(
        "supplierName",
        searchSupplierIDQuery.data.content.supplierName
      );
      supplierManagementDeclinedFormMethods.setValue(
        "supplierName",
        searchSupplierIDQuery.data.content.supplierName
      );
    }

    if (searchSupplierIDQuery.data?.content.supplierNumber) {
      supplierManagementDeclinedFormMethods.setValue(
        "supplierNumber",
        searchSupplierIDQuery.data.content.supplierNumber
      );
      supplierManagementDeclinedFormMethods.setValue(
        "supplierNumber",
        searchSupplierIDQuery.data.content.supplierNumber
      );
    }

    if (searchSupplierIDQuery.data?.content.supplierEmail) {
      supplierManagementDeclinedFormMethods.setValue(
        "supplierEmail",
        searchSupplierIDQuery.data.content.supplierEmail
      );
      supplierManagementDeclinedFormMethods.setValue(
        "supplierEmail",
        searchSupplierIDQuery.data.content.supplierEmail
      );
    }
    if (searchSupplierIDQuery.data?.content.phoneNumber) {
      supplierManagementDeclinedFormMethods.setValue(
        "phoneNumber",
        searchSupplierIDQuery.data.content.phoneNumber
      );
    }

    // if (searchSupplierIDQuery.data?.content.enrolled) {
    //   supplierManagementDeclinedFormMethods.setValue("enrolled", searchSupplierIDQuery.data.content.enrolled);
    //   searchSupplierIDQuery.data.content.enrolled
    //     ? setEnrolledStatus("enrolled")
    //     : setEnrolledStatus("declined");
    // }
  }, [searchSupplierIDQuery.data, supplierManagementDeclinedFormMethods]);

  const saveSupplierMutation = useMutation({
    mutationFn: (data: z.infer<typeof EditSupplierDeclinedFormSchema>) => {
      return editSupplierDeclinedFn(data, Number(supplierId));
    },
    onSuccess: (response) => {
      supplierManagementDeclinedFormMethods.reset();
      console.log("saveSupplierResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Supplier updated successfully",
      });
      void navigate({
        to: "/app/imremit-lite/supplier-management",
        replace: true,
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update supplier",
      });
    },
  });
  const isMutating = saveSupplierMutation.isPending;

  function onSubmit(data: z.infer<typeof EditSupplierDeclinedFormSchema>) {
    saveSupplierMutation.mutate(data);
  }

  if (searchSupplierIDQuery.isPending) {
    return (
      <>
        <div className="grid grid-cols-2 gap-4">
          <LoadingSkeletonCard classNames="mt-8" lineCount={10} />
        </div>
        <Separator className="my-4" />
      </>
    );
  }

  return (
    <>
      <Form {...supplierManagementDeclinedFormMethods}>
        <form
          onSubmit={supplierManagementDeclinedFormMethods.handleSubmit(
            onSubmit
          )}
        >
          <Heading3 className="mb-4">Supplier Details</Heading3>
          <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
            <CommonForm isMutating={isMutating} />
            {children}
          </div>
          <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
            <DeclinedDateField isMutating={isMutating} />
            <DeclinedReasonField isMutating={isMutating} />
          </div>
          <div className="flex w-full flex-row justify-end gap-2">
            <Link
              to="/app/imremit-lite/supplier-management"
              className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
            >
              <span className="sr-only">Cancel</span>
              <XCircleIcon className="size-4" />
              Cancel
            </Link>

            <Button
              className="gap-2 bg-success-foreground text-white hover:bg-success-foreground/80"
              type="submit"
              aria-disabled={isMutating}
              disabled={isMutating}
              onClick={() => {
                supplierManagementDeclinedFormMethods.setValue(
                  "saveAndContinue",
                  true
                );
                supplierManagementDeclinedFormMethods.handleSubmit(onSubmit);
              }}
            >
              <span className="sr-only">Submit form</span>
              Save and continue{" "}
              {isMutating ? (
                <Spinner className="size-4" />
              ) : (
                <SaveAllIcon className="size-4" />
              )}
            </Button>
            <Button
              className="gap-2 bg-success-foreground text-white hover:bg-success-foreground/80"
              type="submit"
              aria-disabled={isMutating}
              disabled={isMutating}
              onClick={() => {
                supplierManagementDeclinedFormMethods.setValue(
                  "saveAndContinue",
                  false
                );
                supplierManagementDeclinedFormMethods.handleSubmit(onSubmit);
              }}
            >
              <span className="sr-only">Submit form</span>
              Save and submit
              {isMutating ? (
                <Spinner className="size-4" />
              ) : (
                <CheckCircleIcon className="size-4" />
              )}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
}

function DeclinedDateField({isMutating}: SupplierAPIMutatingProps) {
  const {control} = useFormContext();
  return (
    <FormField
      control={control}
      name="declinedDate"
      render={({field}) => (
        <FormItem className="flex flex-col">
          <FormLabel htmlFor="declinedDate" showMandatoryAsterisk>
            Declined Date:
          </FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="input"
                  className={cn(
                    "w-full pl-3 text-left font-normal",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value ? (
                    format(field.value as Date, "PPP")
                  ) : (
                    <span>Pick a date</span>
                  )}
                  <CalendarIcon className="ml-auto size-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent
              className="max-h-[var(--radix-popover-content-available-height)] w-[var(--radix-popover-trigger-width)] p-0"
              align="start"
            >
              <Calendar
                mode="single"
                selected={field.value as Date}
                disabled={(date) =>
                  date > new Date() || date < new Date("1900-01-01")
                }
                initialFocus
                onSelect={field.onChange}
              />
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function DeclinedReasonField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="declinedReason"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="declinedReason" showMandatoryAsterisk>
            Declined Reason:
          </FormLabel>
          <FormControl>
            <Textarea
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the declined reason..."
              {...register("declinedReason")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
