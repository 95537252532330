import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  ISOPaymentFileMappingQueryKeys,
  type paymentMappingFormType,
} from "@/modules/imremit";

type QueryFnType = typeof getISOPaymentFileMapping;

// Function to get iso payment file mapping defaults
export function getISOPaymentFileMapping() {
  return kyApiFn<paymentMappingFormType>(
    `api/e-payable/admin/master-mapping/iso-payment`,
    "get"
  );
}

// Custom hook to fetch and cache iso payment file mapping data using react-query
export function useGetISOPaymentFileMapping(
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: ISOPaymentFileMappingQueryKeys.all, // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getISOPaymentFileMapping(), // Setting the function to be used for fetching data
  });
}
