import {useMemo} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {
  Building2Icon,
  CheckIcon,
  ChevronsUpDownIcon,
  Contact2Icon,
  FileTextIcon,
  HeartHandshakeIcon,
} from "lucide-react";
import {useForm} from "react-hook-form";
import {z} from "zod";

import {Button} from "@/components/ui/button";
import {Checkbox} from "@/components/ui/checkbox";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {toast} from "@/components/ui/use-toast";
import {TabbedForm} from "@/components/craft/tabbed-form";

import {cn} from "@/lib/utils";
import {TermsOfConditionsPage} from "@/modules/landing";

import {
  countrySupplierSelectOptions,
  phoneRegex,
  stateOptionsUSA,
  zipCodeRegex,
} from "@/utils/constants";

const RegisterFormSchema = z.object({
  participantType: z.enum(["supplier"]),

  corporatename: z
    .string()
    .min(1, "Corporate Name is required")
    .max(140, "Corporate Name must not exceed 140 characters.")
    .optional(),
  addressOne: z
    .string()
    .min(1, "Address One is required")
    .max(140, "Address One must not exceed 140 characters.")
    .optional(),
  addressTwo: z
    .string()
    .min(1, "Address Two is required")
    .max(140, "Address Two must not exceed 140 characters.")
    .optional(),
  addressThree: z
    .string()
    .min(1, "Address Three is required")
    .max(140, "Address Three must not exceed 140 characters.")
    .optional(),
  city: z
    .string()
    .min(1, "City is required")
    .max(100, "City must not exceed 100 characters.")
    .optional(),
  state: z.string().optional(),
  zip: z
    .string()
    .regex(zipCodeRegex, "Please enter a valid ZIP code.")
    .optional(),
  country: z.enum(["usa", "canada"]),
  contactName: z
    .string()
    .min(1, "Contact Name is required")
    .max(140, "Contact Name must not exceed 140 characters.")
    .optional(),
  contactPhone: z
    .string()
    .regex(phoneRegex, "Invalid phone number!")
    .optional(),
  contactEmail: z.string().email("Invalid email address").optional(),
  userTitle: z
    .string()
    .min(1, "User Title is required")
    .max(140, "User Title must not exceed 140 characters."),
  tin: z
    .string()
    .min(1, "TIN is required")
    .max(140, "TIN must not exceed 140 characters.")
    .optional(),
  remitToAddressOne: z
    .string()
    .min(1, "Remit To Address One is required")
    .max(140, "Remit To Address One must not exceed 140 characters.")
    .optional(),
  remitToAddressTwo: z
    .string()
    .min(1, "Remit To Address Two is required")
    .max(140, "Remit To Address Two must not exceed 140 characters.")
    .optional(),
  remitToAddressThree: z
    .string()
    .min(1, "Remit To Address Three is required")
    .max(140, "Remit To Address Three must not exceed 140 characters.")
    .optional(),
  remitToCity: z
    .string()
    .min(1, "Remit To City is required")
    .max(140, "Remit To City must not exceed 140 characters.")
    .optional(),
  remitToState: z.string().optional(),
  remitToZip: z
    .string()
    .min(1, "Remit To Zip is required")
    .max(140, "Remit To Zip must not exceed 140 characters.")
    .optional(),
  remitToCountry: z.enum(["usa", "canada"]),
  agreeToTerms: z
    .boolean()
    .default(false)
    .refine((v) => v, {
      message: "You must agree to the terms and conditions",
    }),
});

const defaultValues = {
  participantType: "supplier" as const,
};

function onSubmit(data: z.infer<typeof RegisterFormSchema>) {
  toast({
    title: "You submitted the following values:",
    description: (
      <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
        <code className="text-white">{JSON.stringify(data, null, 2)}</code>
      </pre>
    ),
  });
}

export function RegistrationForm() {
  const form = useForm<z.infer<typeof RegisterFormSchema>>({
    defaultValues,
    resolver: zodResolver(RegisterFormSchema),
  });

  const formTabs = useMemo(() => {
    return {
      company: {
        formFields: [
          "participantType",
          "corporatename",
          "addressOne",
          "addressTwo",
          "addressThree",
          "city",
          "state",
          "zip",
          "country",
        ],
        label: "Company",
        name: "company",
        icon: Building2Icon,
        content: (
          <section className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
            <FormField
              name="corporatename"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="corporatename">Corporate name:</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter the company name..."
                      {...form.register("corporatename")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="addressOne"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="addressOne">Address Line 1:</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter line 1 of the address..."
                      {...form.register("addressOne")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="addressTwo"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="addressTwo">Address Line 2:</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter line 2 of the address..."
                      {...form.register("addressTwo")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="addressThree"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="addressThree">Address Line 3:</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter line 2 of the address..."
                      {...form.register("addressThree")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="city"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="city">City:</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter city..."
                      {...form.register("city")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="state"
              render={({field}) => (
                <FormItem className="mt-2 flex flex-col">
                  <FormLabel htmlFor="state">State:</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          className={cn(
                            "justify-between",
                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                            !field.value && "text-accent-foreground"
                          )}
                        >
                          {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                          {field.value || "Select state..."}
                          <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                      <Command>
                        <CommandInput placeholder="Search states..." />
                        <CommandEmpty>No states found.</CommandEmpty>
                        <CommandGroup>
                          {stateOptionsUSA.map((regionOption) => (
                            <CommandItem
                              key={regionOption.value}
                              value={regionOption.value}
                              onSelect={(value) => {
                                form.setValue("state", value);
                              }}
                            >
                              <CheckIcon
                                className={cn(
                                  "mr-2 size-4",
                                  regionOption.value.toLowerCase() ===
                                    field.value
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              {regionOption.label}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="zip"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="zip">Zip Code:</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter zip..."
                      {...form.register("zip")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="country"
              render={({field}) => (
                <FormItem className="mt-2 flex flex-col">
                  <FormLabel htmlFor="country">Country:</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          className={cn(
                            "justify-between",
                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                            !field.value && "text-accent-foreground"
                          )}
                        >
                          {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                          {field.value || "Select country..."}
                          <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                      <Command>
                        <CommandInput placeholder="Search countries..." />
                        <CommandEmpty>No countries found.</CommandEmpty>
                        <CommandGroup>
                          {countrySupplierSelectOptions.map((regionOption) => (
                            <CommandItem
                              key={regionOption.value}
                              value={regionOption.value}
                              onSelect={(value) => {
                                form.setValue(
                                  "country",
                                  value as "usa" | "canada"
                                );
                              }}
                            >
                              <CheckIcon
                                className={cn(
                                  "mr-2 size-4",
                                  regionOption.value.toLowerCase() ===
                                    field.value
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              {regionOption.label}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>
        ),
      },
      contact: {
        formFields: [
          "contactName",
          "contactPhone",
          "contactEmail",
          "userTitle",
          "tin",
          "remitToAddressOne",
          "remitToAddressTwo",
          "remitToAddressThree",
          "remitToCity",
          "remitToState",
          "remitToZip",
          "remitToCountry",
        ],
        label: "Contact",
        name: "contact",
        icon: Contact2Icon,
        content: (
          <section className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
            <FormField
              name="contactName"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="contactName">Contact name:</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter contact name..."
                      {...form.register("contactName")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="contactPhone"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="contactPhone">Contact phone:</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter contact phone..."
                      {...form.register("contactPhone")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="contactEmail"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="contactEmail">Contact email:</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter email address..."
                      {...form.register("contactEmail")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="userTitle"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="userTitle">User Title:</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="eg. Accounts Payable Manager"
                      {...form.register("userTitle")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="tin"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="tin">T.I.N.:</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="T.I.N."
                      {...form.register("tin")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="remitToAddressOne"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="remitToAddressOne">
                    Remit to Address Line 1
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter line 1 of the address..."
                      {...form.register("remitToAddressOne")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="remitToAddressTwo"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="remitToAddressTwo">
                    Remit to Address Line 2
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter line 2 of the address..."
                      {...form.register("remitToAddressTwo")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="remitToAddressThree"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="remitToAddressThree">
                    Remit to Address Line 3
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter line 3 of the address..."
                      {...form.register("remitToAddressThree")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="remitToCity"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="remitToCity">Remit to City:</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter city..."
                      {...form.register("remitToCity")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="remitToState"
              render={({field}) => (
                <FormItem className="mt-2 flex flex-col">
                  <FormLabel htmlFor="remitToState">Remit to State:</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          className={cn(
                            "justify-between",
                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                            !field.value && "text-accent-foreground"
                          )}
                        >
                          {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                          {field.value || "Select state..."}
                          <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                      <Command>
                        <CommandInput placeholder="Search states..." />
                        <CommandEmpty>No states found.</CommandEmpty>
                        <CommandGroup>
                          {stateOptionsUSA.map((regionOption) => (
                            <CommandItem
                              key={regionOption.value}
                              value={regionOption.label}
                              onSelect={(value) => {
                                form.setValue("remitToState", value);
                              }}
                            >
                              <CheckIcon
                                className={cn(
                                  "mr-2 size-4",
                                  regionOption.value.toLowerCase() ===
                                    field.value
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              {regionOption.label}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="remitToZip"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="remitToZip">Remit to Zip Code:</FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      placeholder="Enter zip..."
                      {...form.register("remitToZip")}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="remitToCountry"
              render={({field}) => (
                <FormItem className="mt-2 flex flex-col">
                  <FormLabel htmlFor="remitToCountry">
                    Remit to Country:
                  </FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          className={cn(
                            "justify-between",
                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                            !field.value && "text-accent-foreground"
                          )}
                        >
                          {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
                          {field.value || "Select country..."}
                          <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                      <Command>
                        <CommandInput placeholder="Search countries..." />
                        <CommandEmpty>No countries found.</CommandEmpty>
                        <CommandGroup>
                          {countrySupplierSelectOptions.map((regionOption) => (
                            <CommandItem
                              key={regionOption.value}
                              value={regionOption.value}
                              onSelect={(value) => {
                                form.setValue(
                                  "remitToCountry",
                                  value as "usa" | "canada"
                                );
                              }}
                            >
                              <CheckIcon
                                className={cn(
                                  "mr-2 size-4",
                                  regionOption.value.toLowerCase() ===
                                    field.value
                                    ? "opacity-100"
                                    : "opacity-0"
                                )}
                              />
                              {regionOption.label}
                            </CommandItem>
                          ))}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
          </section>
        ),
      },
      terms: {
        formFields: ["agreeToTerms"],
        label: "TermsOfConditionsPage",
        name: "terms",
        icon: HeartHandshakeIcon,
        content: (
          <Collapsible asChild>
            <div className="flex flex-col gap-y-4">
              <CollapsibleTrigger asChild>
                <Button className="max-w-fit" variant="outline">
                  <FileTextIcon className="mr-2 size-4" />
                  Read the terms and conditions
                </Button>
              </CollapsibleTrigger>
              <CollapsibleContent>
                <TermsOfConditionsPage />
              </CollapsibleContent>
              <FormField
                control={form.control}
                name="agreeToTerms"
                render={({field}) => (
                  <FormItem>
                    <FormLabel className="mr-2" htmlFor="agreeToTerms">
                      I agree to the terms and conditions
                    </FormLabel>
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </Collapsible>
        ),
      },
    };
  }, [form]);

  return (
    <Form {...form}>
      <TabbedForm
        formTabs={formTabs}
        formTabsToRender={["company", "contact", "terms"]}
        onSubmit={onSubmit}
      />
    </Form>
  );
}
