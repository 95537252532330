import {create} from "zustand";

import {type CustomerType} from "@/modules/customers";

// Defining the shape of the state object for the CustomerStore
// In this case, the state will consist of an array of Customer objects
interface ImRemitCustomersStoreState {
  imREmitStoreCustomer: CustomerType | null;
}

// Defining the actions that can be performed on the CustomerStore state
// In this case, there's only one action, updateImRemitStoreCustomer, which takes an array of Customer objects
interface ImRemitCustomerStoreActions {
  updateImRemitStoreCustomer: (
    imREmitStoreCustomer: ImRemitCustomersStoreState["imREmitStoreCustomer"]
  ) => void;
}

// Creating the Zustand store using the create function
// The store is named useImRemitCustomerStore, and it uses the types defined above for its state and actions
// The state begins with an empty imREmitStoreCustomer array
// The updateImRemitStoreCustomer action uses Zustand's set function to update the state
export const useImRemitCustomerStore = create<
  ImRemitCustomersStoreState & ImRemitCustomerStoreActions
>((set) => ({
  // Initial state of the store. An empty array of imREmitStoreCustomer.
  imREmitStoreCustomer: null,

  // Action to update the imREmitStoreCustomer array in the store.
  // This function takes a new imREmitStoreCustomer array and uses the set function to update the state.
  updateImRemitStoreCustomer: (imREmitStoreCustomer) => {
    set(() => ({imREmitStoreCustomer}));
  },
}));
