import {z} from "zod";

export const imREmitLitePaymentSchema = z.object({
  paymentDetailId: z.number(),
  customerStatus: z.string().nullable(),
  statusName: z
    .enum([
      "Paid",
      "Delivered",
      "Closed",
      "Failed",
      "Error",
      "PaidPendingVerification",
      "Duplicate",
      "InProgress",
      "Blocked",
      "Open",
    ])
    .nullable(),
  accountNumber: z.string().nullable(),
  supplierName: z.string().nullable(),
  paymentNumber: z.number().nullable(),
  facilityName: z.string().nullable(),
  description: z.string().nullable(),
  initiatedDate: z.string().nullable(),
  endDate: z.string().nullable(),
  supplierNumber: z.string().nullable(),
  totalAmountSent: z.number().nullable(),
  orgId: z.string().nullable(),
  methodName: z.string().nullable(),
  descriptionDetail: z.string().nullable(),
  amountTaken: z.number().nullable(),
  balanceAmount: z.number().nullable(),
  postingDate: z.string().nullable(),
  userId: z.string().nullable(),
  agentName: z.string().nullable(),
  remittanceMethod: z.string().nullable(),
});

export type ImREmitLitePaymentType = z.infer<typeof imREmitLitePaymentSchema>;
