import {useState} from "react";

import type {Table} from "@tanstack/react-table";
import {ListRestartIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import {
  DataTablePagination,
  // DataTableFacetedFilter,
  DataTableViewOptions,
} from "@/components/craft/data-table";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

// import {priorities, statuses} from "../data/data";

interface BankFileToolbarProps<TData> {
  table: Table<TData>;
  isPending?: boolean;
  isErrored?: boolean;
}

export function BankFileToolbar<TData>({
  table,
  isPending,
  isErrored,
}: BankFileToolbarProps<TData>) {
  const [globalFilterInput, setGlobalFilterInput] = useState("");
  const [masterMappingNameFilterInput, setMasterMappingNameFilterInput] =
    useState("");
  const [mappingNameFilterInput, setMappingNameFilterInput] = useState("");

  const isFiltered =
    table.getState().columnFilters.length > 0 ||
    !!table.getState().globalFilter;

  //Columns other names
  const columnsOtherName = [
    {key: "masterMappingName", value: "Master Mapping Name"},
    {key: "mappingName", value: "Mapping Name"},
    {key: "order", value: "Order"},
    {key: "headerName", value: "Header Name"},
    {key: "dataLength", value: "Data Length"},
    {key: "defaultValue", value: "Default Value"},
    {key: "dateFormatValue", value: "Date Format Value"},
    {key: "delimiterTypeId", value: "Delimiter Type Id"},
    {key: "isDecimalField", value: "Is Decimal Field"},
    {key: "fixedLengthType", value: "Fixed Length Type"},
  ];
  return (
    <>
      <DataTablePagination
        table={table}
        isPending={isPending}
        isErrored={isErrored}
      />
      <div className="flex items-center justify-between">
        <div className="flex flex-1 items-center space-x-2">
          <RowsPreferenceSelect />
          <Input
            placeholder="Search all entries..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={globalFilterInput}
            onChange={(event) => {
              setGlobalFilterInput(event.target.value);
              table.setGlobalFilter(event.target.value);
            }}
          />
          <Input
            placeholder="Search master mapping names..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={masterMappingNameFilterInput}
            onChange={(event) => {
              table;
              setMasterMappingNameFilterInput(event.target.value);
              table
                .getColumn("masterMappingName")
                ?.setFilterValue(event.target.value);
            }}
          />
          <Input
            placeholder="Search mapping names..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={mappingNameFilterInput}
            onChange={(event) => {
              table;
              setMappingNameFilterInput(event.target.value);
              table
                .getColumn("mappingName")
                ?.setFilterValue(event.target.value);
            }}
          />
          {/* {table.getColumn("status") && (
          <DataTableFacetedFilter
            column={table.getColumn("status")}
            title="Status"
            options={statuses}
          />
        )}
        {table.getColumn("priority") && (
          <DataTableFacetedFilter
            column={table.getColumn("priority")}
            title="Priority"
            options={priorities}
          />
        )} */}
          {isFiltered && (
            <Button
              variant="destructive"
              className="h-10 gap-2 px-2 hover:bg-destructive/70 hover:text-destructive-foreground/80 active:bg-destructive/80 active:text-destructive-foreground/80 lg:px-3"
              onClick={() => {
                table.resetColumnFilters();
                table.resetGlobalFilter();
                setGlobalFilterInput("");
                setMasterMappingNameFilterInput("");
                setMappingNameFilterInput("");
              }}
            >
              <span className="sr-only">Reset search filters</span>
              Search Reset
              <ListRestartIcon className="size-4" />
            </Button>
          )}
        </div>
        <DataTableViewOptions
          table={table}
          columnsOtherName={columnsOtherName}
        />
      </div>
    </>
  );
}
