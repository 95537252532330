import {useMemo} from "react";

/**
 * TailwindIndicator Component
 *
 * This component serves as an indicator for the current Tailwind CSS breakpoint.
 * It only renders in development mode and is hidden in production.
 *
 * @example
 * <TailwindIndicator />
 */
export function TailwindIndicator(): JSX.Element | null {
  const indicators = useMemo(() => {
    // Breakpoints and their respective labels
    const breakpoints: Array<{label: string; classes: string}> = [
      {label: "xs", classes: "block sm:hidden"},
      {
        label: "sm",
        classes: "hidden sm:block md:hidden lg:hidden xl:hidden 2xl:hidden",
      },
      {label: "md", classes: "hidden md:block lg:hidden xl:hidden 2xl:hidden"},
      {label: "lg", classes: "hidden lg:block xl:hidden 2xl:hidden"},
      {label: "xl", classes: "hidden xl:block 2xl:hidden"},
      {label: "2xl", classes: "hidden 2xl:block"},
    ];

    return breakpoints.map(({label, classes}, index) => (
      <div key={index} className={classes}>
        {label}
      </div>
    ));
  }, []);

  // Hide the indicator in production environment
  if (import.meta.env.PROD) return null;

  return (
    <div className="fixed bottom-64 left-4 z-50 flex size-6 items-center justify-center rounded-full bg-foreground p-4 font-mono text-xs text-root">
      {indicators}
    </div>
  );
}
