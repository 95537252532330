import {FolderCogIcon} from "lucide-react";

import {SubrouteNavigation} from "@/components/craft/subroute-navigation";

import {SettingsRoutesSchema} from "@/lib/routes/types";
import {KeycloakRoleEnum} from "@/modules/auth";

// Define an array with the data for each route button. This includes the 'to' route,
// the 'icon' to be displayed, and the 'label' for the button.
// This array holds the configuration for each SubrouteNavigationButton
// Each object in the array represents a button in the navigation menu
const routes = [
  {
    to: SettingsRoutesSchema.Values["/app/settings"],
    icon: <FolderCogIcon className="h-10 w-10 pl-2" />,
    label: "Iteration Matrix Settings",
    actionRole: KeycloakRoleEnum.READ_USER_MANAGEMENT,
  },
];

interface SettingsSubrouteNavigationProps {
  layout?: "horizontal" | "vertical";
}

// This component renders the SubrouteNavigation component with the routes array
// as a prop. It also passes the 'navigationAccessibleLabel' and
// 'navigationAriaLabelledby' props to the SubrouteNavigation component.
export function SettingsSubrouteNavigation(
  {layout}: SettingsSubrouteNavigationProps = {layout: "horizontal"}
) {
  return (
    <SubrouteNavigation
      routes={routes}
      layout={layout}
      navigationAccessibleLabel="Settings Subroute Navigation"
      navigationAriaLabelledby="settingsSubrouteNavHeader"
    />
  );
}
