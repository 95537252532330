import {z} from "zod";

export const GeneralRunnerConfigResponseSchema = z.object({
  id: z.number(),
  fileNameContainer: z.string(),
  firstRowHeader: z.boolean(),
  validMinimumLength: z.number(),
  batchRunnerType: z.number(),
  dateFormat: z.number().optional(), // Optional because some document formats do not require a date format
  delimiterType: z.number().optional(), // Optional because some document formats do not require a delimiter
  documentFormat: z.number(),
  documentFormatDenormalize: z.number().optional(), // Optional because some document formats do not require denormalization
  paymentProvider: z.number(),
});

// TODO: this is terrible, we need to consolidate this with the above schema
export const RunnerConfigFormSchema = z.object({
  id: z.number(),
  fileNameContainer: z.string(),
  firstRowHeader: z.boolean(),
  validMinimumLength: z.number(),
  batchRunnerType: z.number(),
  dateFormat: z.string(),
  delimiterType: z.string(),
  documentFormat: z.string(),
  documentFormatDenormalize: z.string(),
  paymentProvider: z.string(),
});

export const RunnerConfigsSchema = z.object({
  // Common fields
  id: z.number().nullable().optional(),
  batchRunnerType: z.string().nullable().optional(),
  paymentProvider: z.string().nullable().optional(),
  dateFormat: z.string().nullable().optional(),
  delimiterType: z.string().nullable().optional(),
  documentFormat: z.string().nullable().optional(),
  fileNameContainer: z.string().nullable().optional(),
  firstRowHeader: z.boolean().nullable().optional(),
  validMinimumLength: z.number().nullable().optional(),
  documentFormatDenormalize: z.string().nullable().optional(),
  // Audit/Temp Fields
  skipHeaderCount: z.number().nullable().optional(),
  skipFooterCount: z.number().nullable().optional(),
  // Payment fields
  customMapping: z.boolean().nullable().optional(),
  recordTypeLength: z.number().nullable().optional(),
  headerRecordType: z.string().nullable().optional(),
  dataRecordType: z.string().nullable().optional(),
  detailRecordType: z.string().nullable().optional(),
  customRecordType: z.string().nullable().optional(),
  footerRecordType: z.string().nullable().optional(),
  responseRequired: z.boolean().nullable().optional(),
  responseDataDateFormat: z.string().nullable().optional(),
  customerResponseViaEmail: z.string().nullable().optional(),

  fileNamePart: z.string().nullable().optional(),
  isRecordTypeRequired: z.boolean().nullable().optional(),
  isPaymentFileInternalAlertRequired: z.boolean().nullable().optional(),
  paymentFileFrequency: z.string().nullable().optional(),
  paymentFileFrequencies: z.array(z.string()).nullable().optional(),
  paymentFileExpectedTime: z.string().nullable().optional(),
  // Recon Fields
  isHeaderRequired: z.boolean().nullable().optional(),
  partialPayReconRequired: z.boolean().nullable().optional(),
  isConsolidatedReconRequired: z.boolean().nullable().optional(),
  ignoreCredits: z.boolean().nullable().optional(),
  isEmptyReconRequired: z.boolean().nullable().optional(),
  lineSeparator: z.string().nullable().optional(),
  isResponseFooterRequired: z.boolean().nullable().optional(),
  isResponseFooterRequiredRecon: z.boolean().nullable().optional(),
  isCustomerReconViaEmail: z.string().nullable().optional(),
  extension: z.string().nullable().optional(),
  fileNameDateFormat: z.string().nullable().optional(),
  responseHeaderDateFormat: z.string().nullable().optional(),
  responseHeaderDateFormatRecon: z.string().nullable().optional(),
  dailyReconBankRequired: z.boolean().nullable().optional(),
  monthlyReconBankRequired: z.boolean().nullable().optional(),
  weeklyReconBankRequired: z.boolean().nullable().optional(),
  removeDailyBankReconTimeStamp: z.boolean().nullable().optional(),
  removeWeeklyBankReconTimeStamp: z.boolean().nullable().optional(),
  removeMonthlyBankReconTimeStamp: z.boolean().optional().nullable(),
  dailySelectedTime: z.string().nullable().optional(),
  weeklySelectedTime: z.string().nullable().optional(),
  monthlySelectedTime: z.string().nullable().optional(),
  weeklySelectedValue: z.string().nullable().optional(),
  monthlySelectedValue: z.string().nullable().optional(),
  fileNamePartDaily: z.string().nullable().optional(),
  fileNamePartWeekly: z.string().nullable().optional(),
  fileNamePartMonthly: z.string().nullable().optional(),
  isDailyReconPostDate: z.boolean().nullable().optional(),
  isSeparatePaymentGroupingRequiredForDaily: z.boolean().nullable().optional(),
  isSeparatePaymentGroupingRequiredForWeekly: z.boolean().nullable().optional(),
  isSeparatePaymentGroupingRequiredForMonthly: z
    .boolean()
    .nullable()
    .optional(),
});

export const PaymentRunnerConfigSchema = RunnerConfigsSchema.pick({
  id: true,
  paymentProvider: true,
  dateFormat: true,
  delimiterType: true,
  isResponseFooterRequired: true,
  lineSeparator: true,
  responseHeaderDateFormat: true,
  documentFormat: true,
  fileNameContainer: true,
  firstRowHeader: true,
  validMinimumLength: true,
  documentFormatDenormalize: true,
  customMapping: true,
  recordTypeLength: true,
  headerRecordType: true,
  dataRecordType: true,
  detailRecordType: true,
  customRecordType: true,
  footerRecordType: true,
  responseRequired: true,
  responseDataDateFormat: true,
  fileNameDateFormat: true,
  fileNamePart: true,
  extension: true,
  isRecordTypeRequired: true,
  isPaymentFileInternalAlertRequired: true,
  paymentFileFrequency: true,
  paymentFileExpectedTime: true,
  customerResponseViaEmail: true,
  batchRunnerType: true,
  paymentFileFrequencies: true,
});

export type RunnerConfigFormType = z.infer<typeof RunnerConfigFormSchema>;

export type RunnerConfigsType = z.infer<typeof RunnerConfigsSchema>;

export type PaymentRunnerConfigType = z.infer<typeof PaymentRunnerConfigSchema>;
