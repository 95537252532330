import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {Paragraph} from "@/components/ui/typography";
import {LoadingSection} from "@/components/craft/loading-section";

import {useKeyCloakInstanceStore} from "@/modules/auth";

/**
 * ProfilePage Component
 * @description This component displays the user profile page.
 */
export function ProfilePage() {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;

  if (!parsedUserInfo) {
    return (
      <section className="flex min-h-max flex-1 items-center justify-center">
        <LoadingSection sectionSize="lg" />
      </section>
    );
  }

  return (
    <section className="grid grid-cols-1 gap-4 md:grid-cols-2">
      <Card>
        <CardHeader>
          <CardTitle>{parsedUserInfo.name}</CardTitle>
          <CardDescription>{parsedUserInfo.preferred_username}</CardDescription>
        </CardHeader>
        <CardContent>
          <Paragraph>
            <strong>Email:</strong> {parsedUserInfo.email}
          </Paragraph>
          <Paragraph>
            <strong>Email Verified:</strong>{" "}
            {parsedUserInfo.email_verified ? "Yes" : "No"}
          </Paragraph>
          <Paragraph>
            <strong>Latest Login:</strong>{" "}
            {!!parsedUserInfo.auth_time &&
              new Date(parsedUserInfo.auth_time * 1000).toUTCString()}
          </Paragraph>
          <Paragraph>
            <strong>Login Expiry:</strong>{" "}
            {!!parsedUserInfo.exp &&
              new Date(parsedUserInfo.exp * 1000).toUTCString()}
          </Paragraph>
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>
            {`${
              parsedUserInfo.given_name ? parsedUserInfo.given_name : "User"
            }'s Realm Access`}
          </CardTitle>
          <CardDescription>
            This section displays the user's realm access.
          </CardDescription>
        </CardHeader>
        <CardContent className="grid grid-cols-1 lg:grid-cols-2">
          {!!parsedUserInfo.realm_access &&
            parsedUserInfo.realm_access.roles.map((role) => (
              <Paragraph key={role}>
                <strong>Role:</strong> {role}
              </Paragraph>
            ))}
        </CardContent>
      </Card>
      <Card>
        <CardHeader>
          <CardTitle>
            {`${
              parsedUserInfo.given_name ? parsedUserInfo.given_name : "User"
            }'s Resources Access`}
          </CardTitle>
          <CardDescription>
            This section displays the user's resources access.
          </CardDescription>
        </CardHeader>
        <CardContent>
          {!!parsedUserInfo.resource_access &&
            parsedUserInfo.resource_access.account.roles.map((role) => (
              <Paragraph key={role}>
                <strong>Role:</strong> {role}
              </Paragraph>
            ))}
        </CardContent>
      </Card>
    </section>
  );
}
