import {useEffect, useState} from "react";

import {useMutation} from "@tanstack/react-query";
import {type Row} from "@tanstack/react-table";
import {
  CheckIcon,
  ChevronsUpDownIcon,
  FileEditIcon,
  GripVerticalIcon,
  PackageXIcon,
} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {FormItem} from "@/components/ui/form";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {toast} from "@/components/ui/use-toast";

import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useUserInfo,
} from "@/modules/auth";
import type {UpdateCharacterSettingsBodyType} from "@/modules/statement-recon";
import {
  character,
  deleteSettingsModification,
  leadingTrailing,
  leadingTrailingCharacters,
  updateSettingsModificationByModificationIdFn,
} from "@/modules/statement-recon";

/**
 * Props for SRRowActions component.
 * @typedef {Object} SRRowActionsProps
 * @template TData - The type of data for the row.
 * @property {Row<TData>} row - The data row.
 */
interface SRRowActionsProps<TData> {
  row: Row<TData>;
}

export function SRRowActions<TData>({row}: SRRowActionsProps<TData>) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const [trailingLeading, setTrailingLeading] = useState("");

  const settingsId = row.getValue("sr_settings_id");
  const settingsModificationId = String(
    row.getValue("sr_settings_modification_id")
  );
  const settingsName = row.getValue("sr_settings_name");
  const defaultValue = row.getValue("sr_settings_modification_value");
  const defaultValueLeadingTrailingType = row.getValue(
    "sr_settings_leading_trailing_type"
  );
  const displayUpdateButton = settingsId === 1 || settingsId === 2;

  const deleteSRSetting = useMutation({
    mutationFn: () => deleteSettingsModification(settingsModificationId),
    onSuccess: (response) => {
      console.log("deleteSRSettingResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Setting deleted successfully",
      });
    },
    onError: (error: unknown) => {
      console.error("errorMessage", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to delete setting",
      });
    },
  });

  const updateSRSettingByModIdMutation = useMutation({
    mutationFn: (body: UpdateCharacterSettingsBodyType) =>
      updateSettingsModificationByModificationIdFn(
        settingsModificationId,
        body
      ),

    onSuccess: (response) => {
      console.log("updateSRSettingByModIdResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Setting updated successfully",
      });
    },
    onError: (error) => {
      console.error("errorMessage", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update setting",
      });
    },
  });

  useEffect(() => {
    if (typeof defaultValue === "string") {
      setValue(defaultValue);
    }

    if (typeof defaultValueLeadingTrailingType === "string") {
      setTrailingLeading(defaultValueLeadingTrailingType);
    }
  }, [defaultValue, defaultValueLeadingTrailingType]);

  const {parsedUserInfo} = useUserInfo();

  const modified_by = (parsedUserInfo?.name as string).toString();

  const {isPending: isMutating} = updateSRSettingByModIdMutation;
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button className="flex h-12 w-12 gap-2 bg-theme p-0 hover:bg-neutral hover:bg-theme/90 data-[state=open]:bg-secondary">
            <span className="sr-only">Open menu</span>
            <GripVerticalIcon className="size-6 bg-inherit" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="min-w-fit">
          <DropdownMenuLabel>Actions</DropdownMenuLabel>
          <AccessPolicyWrapper
            unauthorizedFallback={null}
            policyActions={[KeycloakRoleEnum.UPDATE_SR_SETTINGS]}
          >
            {displayUpdateButton && (
              <>
                <Dialog open={open} onOpenChange={setOpen}>
                  <DialogTrigger>
                    <DropdownMenuItem
                      className="flex justify-between gap-4"
                      onSelect={(e) => {
                        e.preventDefault();
                      }}
                    >
                      Update Setting
                      <span className="sr-only">Update Setting</span>
                      <FileEditIcon className="size-4" />
                    </DropdownMenuItem>
                  </DialogTrigger>
                  <DialogContent className="sm:min-w-[340px] md:min-w-[668px] lg:min-w-[780px] xl:min-w-[1080px] 2xl:min-w-[1118px]">
                    <DialogHeader>
                      <DialogTitle>Update settings</DialogTitle>
                      <DialogDescription />
                    </DialogHeader>
                    {settingsId === 1 && (
                      <div className="flex flex-col gap-y-4">
                        <FormItem className="mt-2 flex flex-col">
                          <Popover>
                            <PopoverTrigger asChild>
                              <Button
                                variant="outline"
                                role="combobox"
                                className={
                                  trailingLeading ? "" : "text-muted-foreground"
                                }
                              >
                                {trailingLeading || "Select type..."}
                                <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                              <Command>
                                <CommandInput placeholder="Search type..." />
                                <CommandEmpty>No type found.</CommandEmpty>
                                <CommandGroup>
                                  {leadingTrailing.map((item) => (
                                    <CommandItem
                                      key={item.value}
                                      value={item.label}
                                      onSelect={(value: string) => {
                                        setTrailingLeading(value);
                                      }}
                                    >
                                      <CheckIcon
                                        className={
                                          trailingLeading.toLowerCase() ===
                                          item.value.toLowerCase()
                                            ? "opacity-100"
                                            : "opacity-0"
                                        }
                                      />
                                      {item.label}
                                    </CommandItem>
                                  ))}
                                </CommandGroup>
                              </Command>
                            </PopoverContent>
                          </Popover>
                        </FormItem>

                        <FormItem className="mt-2 flex flex-col">
                          <Popover>
                            <PopoverTrigger asChild>
                              <Button
                                variant="outline"
                                role="combobox"
                                className={value ? "" : "text-muted-foreground"}
                              >
                                {value || "Select character..."}
                                <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                              <Command>
                                <CommandInput placeholder="Search character..." />
                                <CommandEmpty>No character found.</CommandEmpty>
                                <CommandGroup>
                                  {leadingTrailingCharacters.map((item) => (
                                    <CommandItem
                                      key={item.value}
                                      value={item.label}
                                      onSelect={(value: string) => {
                                        setValue(value);
                                      }}
                                    >
                                      <CheckIcon
                                        className={
                                          value.toLowerCase() ===
                                          item.value.toLowerCase()
                                            ? "opacity-100"
                                            : "opacity-0"
                                        }
                                      />
                                      {item.label}
                                    </CommandItem>
                                  ))}
                                </CommandGroup>
                              </Command>
                            </PopoverContent>
                          </Popover>
                        </FormItem>
                        <Button
                          type="button"
                          disabled={isMutating}
                          onClick={() => {
                            updateSRSettingByModIdMutation.mutate({
                              sr_settings_modification_value: value,
                              sr_settings_name: settingsName as string,
                              sr_settings_leading_trailing_type:
                                trailingLeading,
                              sr_settings_id: settingsId,
                              modified_by: modified_by,
                            });
                          }}
                        >
                          Update Setting
                        </Button>
                      </div>
                    )}
                    {settingsId === 2 && (
                      <div className="flex flex-col gap-y-4">
                        <FormItem className="mt-2 flex flex-col">
                          <Popover>
                            <PopoverTrigger asChild>
                              <Button
                                variant="outline"
                                role="combobox"
                                className={value ? "" : "text-muted-foreground"}
                              >
                                {value || "Select character..."}
                                <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                              </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                              <Command>
                                <CommandInput placeholder="Search character..." />
                                <CommandEmpty>No character found.</CommandEmpty>
                                <CommandGroup>
                                  {character.map((item) => (
                                    <CommandItem
                                      key={item.value}
                                      value={item.label}
                                      onSelect={(value: string) => {
                                        setValue(value);
                                      }}
                                    >
                                      <CheckIcon
                                        className={
                                          value.toLowerCase() ===
                                          item.value.toLowerCase()
                                            ? "opacity-100"
                                            : "opacity-0"
                                        }
                                      />
                                      {item.label}
                                    </CommandItem>
                                  ))}
                                </CommandGroup>
                              </Command>
                            </PopoverContent>
                          </Popover>
                        </FormItem>
                        <Button
                          type="button"
                          disabled={isMutating}
                          onClick={() => {
                            updateSRSettingByModIdMutation.mutate({
                              sr_settings_modification_value: value,
                              sr_settings_name: settingsName as string,
                              sr_settings_id: settingsId,
                              modified_by: modified_by,
                            });
                          }}
                        >
                          Update Setting
                        </Button>
                      </div>
                    )}
                  </DialogContent>
                </Dialog>
                <DropdownMenuSeparator />
              </>
            )}
          </AccessPolicyWrapper>
          <DropdownMenuItem
            className="flex justify-between gap-4 bg-destructive text-destructive-foreground hover:bg-destructive/90 hover:text-destructive-foreground/90"
            onSelect={(e) => {
              e.preventDefault();
              deleteSRSetting.mutate();
            }}
          >
            Delete Setting
            <span className="sr-only">Delete Setting</span>
            <PackageXIcon className="size-4" />
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
