import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  revealCardDetailsKeys,
  type ImREmitPaymentType,
} from "@/modules/imremit";

type QueryFnType = typeof getRevealCardDetails;

// Function to get RevealCardDetails
export function getRevealCardDetails(paymentDetailId?: string) {
  return kyApiFn<ImREmitPaymentType>(
    `api/e-payable/management/payment-details/unmask/${paymentDetailId}`,
    "get"
  );
}

// Custom hook to fetch and cache card details data using react-query
export function useGetRevealCardDetails(
  paymentDetailId?: string, // The search query string
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: revealCardDetailsKeys.byPaymentId(paymentDetailId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getRevealCardDetails(paymentDetailId), // Setting the function to be used for fetching data
    enabled: !!paymentDetailId, // Setting the query to be enabled only if the search query string is not empty
  });
}
