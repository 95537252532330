import type {ChangeEvent, HTMLProps, ReactElement} from "react";
import {Children} from "react";

import {ChevronLeft, ChevronRight} from "lucide-react";
import type {DropdownProps} from "react-day-picker";
import {DayPicker} from "react-day-picker";

import {buttonVariants} from "@/components/ui/button";
import {ScrollArea} from "@/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

import {cn} from "@/lib/utils";

/**
 * @typedef CalendarProps
 * @type {object}
 * @property {string} className - Custom CSS classes to apply to the calendar.
 * @property {Record<string, string>} classNames - Custom classNames for inner elements.
 * @property {boolean} showOutsideDays - Whether to show days outside the current month.
 *
 * Type definition for Calendar component properties.
 */
type CalendarProps = React.ComponentProps<typeof DayPicker>;

/**
 * A reusable dropdown component for selecting items.
 *
 * @param {DropdownProps} props - Properties for the Dropdown component.
 */
const DropdownComponent = ({value, onChange, children}: DropdownProps) => {
  // Extract option elements from children
  const options = Children.toArray(children) as ReactElement<
    HTMLProps<HTMLOptionElement>
  >[];

  // Find the currently selected option
  const selected = options.find((child) => child.props.value === value);

  /**
   * Handle dropdown value change.
   *
   * @param {string} value - Selected value from dropdown.
   */
  const handleChange = (value: string) => {
    const fakeEvent = {
      target: {value},
    } as unknown as ChangeEvent<HTMLSelectElement>;
    onChange?.(fakeEvent);
  };
  return (
    <Select value={value?.toString()} onValueChange={handleChange}>
      <SelectTrigger className="pr-1.5 font-normal opacity-50 focus:ring-0">
        <SelectValue>{selected?.props.children}</SelectValue>
      </SelectTrigger>
      <SelectContent position="item-aligned" className="font-normal opacity-90">
        <ScrollArea className="max-h-80">
          {options.map((option, id: number) => (
            <SelectItem
              key={`${option.props.value?.toString() ?? "undefined"}-${id}`}
              value={option.props.value?.toString() ?? ""}
            >
              {option.props.children}
            </SelectItem>
          ))}
        </ScrollArea>
      </SelectContent>
    </Select>
  );
};

/**
 * Calendar component that wraps DayPicker with additional functionalities.
 *
 * @param {CalendarProps} props - Properties for the Calendar component.
 */
function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  // Consolidate classNames into a single object
  const combinedClassNames = {
    months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
    month: "space-y-4",
    caption: "flex justify-center pt-1 relative items-center",
    caption_label: "text-sm font-medium",
    caption_dropdowns: "flex justify-center gap-1",
    nav: "space-x-1 flex items-center",
    nav_button: cn(
      buttonVariants({variant: "outline"}),
      "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
    ),
    nav_button_previous: "absolute left-1",
    nav_button_next: "absolute right-1",
    table: "w-full border-collapse space-y-1",
    head_row: "flex",
    head_cell: "text-accent-foreground rounded-md w-9 font-semi-bold text-xs",
    row: "flex w-full mt-2",
    cell: "text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
    day: cn(
      buttonVariants({variant: "ghost"}),
      "h-9 w-9 p-0 font-normal aria-selected:opacity-100"
    ),
    day_selected:
      "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
    day_today: "bg-accent text-accent-foreground",
    day_outside: "text-accent-foreground opacity-50",
    day_disabled: "text-accent-foreground opacity-50",
    day_range_middle:
      "aria-selected:bg-accent aria-selected:text-accent-foreground",
    day_hidden: "invisible",
    ...classNames,
  };

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={combinedClassNames}
      components={{
        Dropdown: DropdownComponent,
        IconLeft: ({...props}) => <ChevronLeft className="size-4" {...props} />,
        IconRight: ({...props}) => (
          <ChevronRight className="size-4" {...props} />
        ),
      }}
      {...props}
    />
  );
}

// Component metadata
Calendar.displayName = "Calendar";

export {Calendar};
