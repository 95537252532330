import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {formatAmount, GetKeycloakUserName} from "@/modules/admin";
import {
  RenderCustomerDescription,
  RenderCustomerStatus,
  renderRemittanceBadge,
  type ImREmitPaymentType,
} from "@/modules/imremit";
import {PaymentManagementRowActions} from "@/modules/imremit-lite";

import {FallbackMessages} from "@/utils/constants";

export function useGetPaymentManagementColumns(queryParams: string) {
  // Get the store functions for sorting PaymentManagement

  const PMColumns: ColumnDef<ImREmitPaymentType>[] = [
    {
      accessorKey: "supplierName",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Supplier Name
      "
        />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("supplierName") ? (
            row.getValue("supplierName")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "supplierNumber",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
    Supplier ID
      "
        />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("supplierNumber") ? (
            row.getValue("supplierNumber")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "facilityName",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Customer Name
      "
        />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("facilityName") ? (
            row.getValue("facilityName")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "buyerAlias",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Customer Alias
      "
        />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("buyerAlias") ? (
            row.getValue("buyerAlias")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "paymentNumber",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Payment Number
      "
        />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("paymentNumber") ? (
            row.getValue("paymentNumber")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "totalAmountSent",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Sent Amount
      "
        />
      ),
      cell: ({row}) => {
        const amountValue = row.original.totalAmountSent;

        // Check if empty and return "no data" if empty
        const formattedAmount = amountValue ? (
          formatAmount(amountValue)
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );

        return <Paragraph>{formattedAmount}</Paragraph>;
      },
    },
    {
      accessorKey: "initiatedDate",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Sent Date
      "
        />
      ),
      cell: ({row}) => {
        return (
          <Paragraph>
            {row.original.initiatedDate ? (
              <Paragraph className="whitespace-nowrap">
                {row.original.initiatedDate}
              </Paragraph>
            ) : (
              <Badge className="whitespace-nowrap" variant="destructive">
                {FallbackMessages.NO_DATA}
              </Badge>
            )}
          </Paragraph>
        );
      },
    },
    {
      accessorKey: "customerStatus",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Status
      "
        />
      ),
      cell: ({row}) => {
        if (!row.original.customerStatus) {
          return (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          );
        }

        return RenderCustomerStatus(row.original.customerStatus);
      },
    },
    {
      accessorKey: "statusName",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Status Name
      "
        />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("statusName") ? (
            row.getValue("statusName")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },

    {
      accessorKey: "description",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Status Description
      "
        />
      ),
      cell: ({row}) => {
        if (!row.original.description) {
          return (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          );
        }

        return RenderCustomerDescription(
          row.original.customerStatus || "",
          row.original.description
        );
      },
    },
    // {
    //   accessorKey: "endDate",
    //   header: ({column}) => (
    //     <DataTableColumnHeader
    //       column={column}
    //       title="
    //   End Date
    //   "
    //     />
    //   ),
    //   cell: ({row}) => (
    //     <div className="w-fit">
    //       {row.getValue("endDate") ? (
    //         row.getValue("endDate")
    //       ) : (
    //         <Badge className="whitespace-nowrap" variant="destructive">{FallbackMessages.NO_DATA}</Badge>
    //       )}
    //     </div>
    //   ),
    // },

    {
      accessorKey: "descriptionDetail",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Description Detail" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("descriptionDetail") ? (
            row.getValue("descriptionDetail")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "amountTaken",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
    Amount Taken
      "
        />
      ),
      cell: ({row}) => {
        const amountValue = row.original.amountTaken;

        // Check if empty and return "no data" if empty
        const formattedAmount = amountValue ? (
          formatAmount(amountValue)
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );

        return <Paragraph>{formattedAmount}</Paragraph>;
      },
    },
    {
      accessorKey: "balanceAmount",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Balance Amount
      "
        />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("balanceAmount") ? (
            row.getValue("balanceAmount")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "userId",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Agent Name
      "
        />
      ),
      cell: ({row}) => {
        const userId = row.original.userId;

        return userId ? (
          <GetKeycloakUserName userId={userId} />
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        );
      },
    },
    {
      accessorKey: "remittanceMethod",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="
      Remittance Method
      "
        />
      ),
      cell: ({row}) => {
        if (!row.original.remittanceMethod) {
          return <Paragraph>{FallbackMessages.NO_DATA}</Paragraph>;
        }

        return renderRemittanceBadge(row.original.remittanceMethod);
      },
    },
    {
      id: "actions",
      header: () => <Paragraph className="text-right">Actions</Paragraph>,
      cell: ({row}) => (
        <div className="flex w-[80] justify-end">
          <PaymentManagementRowActions
            paymentId={row.original.paymentDetailId.toString()}
            isReprocess={row.original.isReprocess || false}
            supplierOnboardStatus={row.original.supplierOnboardStatus || ""}
            queryParams={queryParams}
          />
        </div>
      ),
    },
  ];

  return PMColumns;
}
