import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {failedAlertQueryKeys, type FailedAlertsType} from "@/modules/imremit";

type QueryFnType = typeof getFailedAlerts;

// Function to search failedAlerts
export function getFailedAlerts() {
  return kyApiFn<FailedAlertsType>(
    `api/e-payable/paybyweb-transaction/payment-details/faults`,
    "get"
  );
  // TODO: validate data
}

// Custom hook to fetch and cache failedAlerts data using react-query
export function useFailedAlerts(
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: failedAlertQueryKeys.all, // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getFailedAlerts(), // Setting the function to be used for fetching data
  });
}
