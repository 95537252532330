import {create} from "zustand";

import {type PaginationMetaType} from "@/lib/ky";
import {type SuppliersCriteriaType} from "@/modules/imremit";

import {type SortMetaType} from "@/utils/query-params-generator";

/**
 * Interface: SuppliersMetaStoreState
 * Defines the shape of the state for the SuppliersPaginationMetaStore
 */
interface SuppliersMetaStoreState {
  /**
   * storeSuppliersPaginationMeta
   * Holds the pagination metadata related to suppliers.
   */
  storeSuppliersPaginationMeta: PaginationMetaType | null;

  /**
   * storeSuppliersCriteria
   * Holds the criteria by which suppliers are filtered.
   */
  storeSuppliersCriteria: SuppliersCriteriaType | null;

  /**
   * storeSuppliersSortMeta
   * Holds the sort metadata related to suppliers.
   */
  storeSuppliersSortMeta: SortMetaType | null;

  /**
   * handleOnPageChange
   * Holds the function to handle page change.
   * This is used to update the page number in the store.
   * This is used in the SupplierManagementDataTable component.
   *
   * @param pageNumber - The new page number
   * @returns void
   *
   * @example
   */
  handleOnPageChange?: (pageNumber: number) => Promise<void>;
}

/**
 * Interface: SuppliersMetaStoreActions
 * Defines the set of actions for updating the SuppliersMetaStoreState.
 */
interface SuppliersMetaStoreActions {
  /**
   * Action: setStoreSuppliersPaginationMeta
   * Updates the 'storeSuppliersPaginationMeta' field in the state.
   *
   * @param storeSuppliersPaginationMeta - The new value for 'storeSuppliersPaginationMeta'
   */
  setStoreSuppliersPaginationMeta: (
    storeSuppliersPaginationMeta: SuppliersMetaStoreState["storeSuppliersPaginationMeta"]
  ) => void;

  /**
   * Action: setStoreSuppliersCriteria
   * Updates the 'storeSuppliersCriteria' field in the state.
   *
   * @param storeSuppliersCriteria - The new value for 'storeSuppliersCriteria'
   */
  setStoreSuppliersCriteria: (
    storeSuppliersCriteria: SuppliersMetaStoreState["storeSuppliersCriteria"]
  ) => void;

  /**
   * Action: setStoreSuppliersSortMeta
   * Updates the 'storeSuppliersSortMeta' field in the state.
   *
   * @param storeSuppliersSortMeta - The new value for 'storeSuppliersSortMeta'
   */
  setStoreSuppliersSortMeta: (
    storeSuppliersSortMeta: SuppliersMetaStoreState["storeSuppliersSortMeta"]
  ) => void;

  /**
   * Action: setHandleOnPageChange
   * Updates the 'handleOnPageChange' field in the state.
   *
   * @param handleOnPageChange - The new value for 'handleOnPageChange'
   */
  setHandleOnPageChange: (
    handleOnPageChange: (pageNumber: number) => Promise<void>
  ) => void;
}

/**
 * Function: useSuppliersMetaStore
 * Creates a Zustand store to handle supplier pagination metadata and criteria.
 */
export const useSuppliersMetaStore = create<
  SuppliersMetaStoreState & SuppliersMetaStoreActions
>((set) => ({
  // Initialize 'storeSuppliersPaginationMeta', 'storeSuppliersCriteria' and 'handleOnPageChange'
  // with default values of null
  storeSuppliersPaginationMeta: null,
  storeSuppliersCriteria: null,
  storeSuppliersSortMeta: null,
  handleOnPageChange: undefined,

  /**
   * Implementation for 'setStoreSuppliersPaginationMeta' action.
   * Updates the 'storeSuppliersPaginationMeta' field in the state.
   *
   * @param storeSuppliersPaginationMeta - The new value for 'storeSuppliersPaginationMeta'
   */
  setStoreSuppliersPaginationMeta: (storeSuppliersPaginationMeta) => {
    set(() => ({storeSuppliersPaginationMeta}));
  },

  /**
   * Implementation for 'setStoreSuppliersCriteria' action.
   * Updates the 'storeSuppliersCriteria' field in the state.
   *
   * @param storeSuppliersCriteria - The new value for 'storeSuppliersCriteria'
   */
  setStoreSuppliersCriteria: (storeSuppliersCriteria) => {
    set(() => ({storeSuppliersCriteria}));
  },

  /**
   * Implementation for 'setStoreSuppliersSortMeta' action.
   * Updates the 'storeSuppliersSortMeta' field in the state.
   *
   * @param storeSuppliersSortMeta - The new value for 'storeSuppliersSortMeta'
   */
  setStoreSuppliersSortMeta: (storeSuppliersSortMeta) => {
    set(() => ({storeSuppliersSortMeta}));
  },

  /**
   * Implementation for 'setHandleOnPageChange' action.
   * Updates the 'handleOnPageChange' field in the state.
   *
   * @param handleOnPageChange - The new value for 'handleOnPageChange'
   */
  setHandleOnPageChange: (handleOnPageChange) => {
    set(() => ({handleOnPageChange}));
  },
}));
