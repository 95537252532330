import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import type {ReconciledStatementResultType} from "@/modules/statement-recon";

import {FallbackMessages} from "@/utils/constants";

export const srListTableColumns: ColumnDef<ReconciledStatementResultType>[] = [
  {
    accessorKey: "im_sr_statement_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="IM SR Statement Id" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("im_sr_statement_id") ? (
          row.getValue("im_sr_statement_id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "st_invoice_number",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Statement Invoice Number" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("st_invoice_number") ? (
          row.getValue("st_invoice_number")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "st_invoice_date",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Statement Invoice Date" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("st_invoice_date") ? (
          row.getValue("st_invoice_date")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "st_invoice_amount",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Statement Invoice Amount" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("st_invoice_amount") ? (
          row.getValue("st_invoice_amount")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "st_po_number",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Statement PO Number" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("st_po_number") ? (
          row.getValue("st_po_number")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "im_sr_match_type",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Match Status" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("im_sr_match_type") ? (
          <Badge
            className="cursor-pointer uppercase hover:opacity-70"
            variant={
              row.getValue("im_sr_match_type") === "matched"
                ? "success"
                : "default"
            }
          >
            {row.getValue("im_sr_match_type")}
          </Badge>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "supplier_number",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Supplier Number" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("supplier_number") ? (
          row.getValue("supplier_number")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "supplier_name",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Supplier Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("supplier_name") ? (
          row.getValue("supplier_name")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "invoice_number",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Number" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoice_number") ? (
          row.getValue("invoice_number")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "invoice_date",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Date" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoice_date") ? (
          row.getValue("invoice_date")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "po_number",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="PO Number" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("po_number") ? (
          row.getValue("po_number")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "invoice_amount",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Amount" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoice_amount") ? (
          row.getValue("invoice_amount")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "invoice_paid_amount",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Paid Amount" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoice_paid_amount") ? (
          row.getValue("invoice_paid_amount")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "invoice_payment_number",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Payment Number" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoice_payment_number") ? (
          row.getValue("invoice_payment_number")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "created_timestamp",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Created Timestamp" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("created_timestamp") ? (
          row.getValue("created_timestamp")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "updated_timestamp",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Updated Timestamp" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("updated_timestamp") ? (
          row.getValue("updated_timestamp")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "id",
    header: ({column}) => <DataTableColumnHeader column={column} title="Id" />,
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("id") ? (
          row.getValue("id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "invoice_payment_date",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Payment Date" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoice_payment_date") ? (
          row.getValue("invoice_payment_date")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "invoice_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Id" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoice_id") ? (
          row.getValue("invoice_id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "supplier_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Supplier Id" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("supplier_id") ? (
          row.getValue("supplier_id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "invoice_erp_unique_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice ERP Unique Id" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoice_erp_unique_id") ? (
          row.getValue("invoice_erp_unique_id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "invoice_payment_method",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Payment Method" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoice_payment_method") ? (
          row.getValue("invoice_payment_method")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "payment_term",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment Term" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("payment_term") ? (
          row.getValue("payment_term")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "void_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Void Id" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("void_id") ? (
          row.getValue("void_id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "void_date",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Void Date" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("void_date") ? (
          row.getValue("void_date")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "im_sr_customer_statement_id",
    header: ({column}) => (
      <DataTableColumnHeader
        column={column}
        title="IM SR Customer Statement Id"
      />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("im_sr_customer_statement_id") ? (
          row.getValue("im_sr_customer_statement_id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "buyer_external_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Buyer External Id" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("buyer_external_id") ? (
          row.getValue("buyer_external_id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "buyer_name",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Buyer Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("buyer_name") ? (
          row.getValue("buyer_name")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "invoice_po_number",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice PO Number" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoice_po_number") ? (
          row.getValue("invoice_po_number")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "buyer_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Buyer Id" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("buyer_id") ? (
          row.getValue("buyer_id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "invoice_status_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Status Id" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoice_status_id") ? (
          row.getValue("invoice_status_id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "suffix",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Suffix" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("suffix") ? (
          row.getValue("suffix")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "compared",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Compared" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("compared") ? (
          row.getValue("compared")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "credit_pair",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Credit Pair" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("credit_pair") ? (
          row.getValue("credit_pair")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "cancel_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Cancel Id" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("cancel_id") ? (
          row.getValue("cancel_id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "cancel_date",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Cancel Date" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("cancel_date") ? (
          row.getValue("cancel_date")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "invoice_type",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Invoice Type" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("invoice_type") ? (
          row.getValue("invoice_type")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "payment_status",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment Status" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("payment_status") ? (
          row.getValue("payment_status")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "insert_date",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Insert Date" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("insert_date") ? (
          row.getValue("insert_date")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "update_date",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Update Date" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("update_date") ? (
          row.getValue("update_date")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "isreconciled",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Is Reconciled" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("isreconciled") ? (
          row.getValue("isreconciled")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "facility_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Facility Id" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("facility_id") ? (
          row.getValue("facility_id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "statement_id",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Statement Id" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("statement_id") ? (
          row.getValue("statement_id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
];
