import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate} from "@tanstack/react-router";
import {
  CheckIcon,
  ChevronsUpDownIcon,
  PlusIcon,
  SaveIcon,
  Trash2Icon,
  XIcon,
} from "lucide-react";
import {useFieldArray, useForm} from "react-hook-form";
import type * as z from "zod";

import {Button} from "@/components/ui/button";
import {Card, CardContent} from "@/components/ui/card";
import {Command, CommandGroup, CommandItem} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Label} from "@/components/ui/label";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {RadioGroup, RadioGroupItem} from "@/components/ui/radio-group";
import {Spinner} from "@/components/ui/spinner";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {toast} from "@/components/ui/use-toast";

import {cn} from "@/lib/utils";
import {
  auditMappingFormSchema,
  saveAuditFileFn,
  useDateFormatDetails,
  useMappingDocumentTypeStore,
} from "@/modules/imremit";

export function AuditFileAddMapping() {
  const navigate = useNavigate();

  const {storeDocumentType} = useMappingDocumentTypeStore();

  //get date format from custom hook `useDateFormatDetails`
  const DateFormatResponse = useDateFormatDetails();
  const DateFormatData = DateFormatResponse.data?.content;

  const auditForm = useForm<z.infer<typeof auditMappingFormSchema>>({
    defaultValues: {
      documentTypeId: storeDocumentType?.documentTypeId.toString() || "",
      auditMappingRequests: [],
    },
    resolver: zodResolver(auditMappingFormSchema),
  });

  const {fields, append, remove} = useFieldArray({
    control: auditForm.control,
    name: "auditMappingRequests",
    // rules: {minLength: 1},
  });

  // Initialize Tanstack Query Mutation for Refreshing Audit File
  const saveBankFileMutation = useMutation({
    mutationFn: saveAuditFileFn,

    onSuccess: (response) => {
      auditForm.reset();
      console.log("saveBankFileMutationResponse", response);

      toast({
        variant: "success",
        title: "Success!",
        description: "Audit file mapping added successfully",
      });
      void navigate({to: "/app/imremit/mapping", replace: true});
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add audit file mapping",
      });
    },
  });

  const isMutating = saveBankFileMutation.isPending;

  function onSubmit(data: z.infer<typeof auditMappingFormSchema>) {
    saveBankFileMutation.mutate(data);
  }

  return (
    <>
      <Card>
        <CardContent>
          <Form {...auditForm}>
            <form onSubmit={auditForm.handleSubmit(onSubmit)}>
              <div className="mb-5 mt-5 grid grid-cols-1 gap-4 space-x-2 md:grid-cols-6">
                <FormField
                  control={auditForm.control}
                  name="masterMappingName"
                  render={({field}) => (
                    <FormItem className="flex flex-col md:col-span-2 xl:col-span-2 2xl:col-span-1">
                      <Label showMandatoryAsterisk>Master Mapping Name :</Label>
                      <FormControl>
                        <Input placeholder="" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={auditForm.control}
                  name="documentTypeId"
                  render={({field}) => (
                    <FormItem>
                      <FormControl>
                        <Input placeholder="" {...field} className="hidden" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <Button
                  type="button"
                  className="col-span-1 col-end-8 mt-5"
                  onClick={() => {
                    append({
                      order: "",
                      headerName: "",
                      dataLength: "",
                      dateFormatId: "",
                      mappingName: "",
                      isDecimalField: true,
                      tableName: "",
                    });
                  }}
                >
                  <PlusIcon className="mr-2 size-4" /> Add
                </Button>
              </div>
              <Table>
                <TableCaption>
                  {fields.length == 0 && <p> No Data.</p>}
                </TableCaption>
                <TableHeader>
                  <TableRow>
                    <TableHead className="relative" showMandatoryAsterisk>
                      Mapping Name
                    </TableHead>
                    <TableHead className="relative" showMandatoryAsterisk>
                      Order
                    </TableHead>
                    <TableHead className="relative" showMandatoryAsterisk>
                      Data Length
                    </TableHead>
                    <TableHead className="relative" showMandatoryAsterisk>
                      Header Name
                    </TableHead>
                    <TableHead className="relative" showMandatoryAsterisk>
                      Decimal Field
                    </TableHead>
                    <TableHead>Date Format</TableHead>
                    <TableHead className="relative" showMandatoryAsterisk>
                      Table Name
                    </TableHead>
                    <TableHead className="text-right">Action</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {fields.map((item, index) => {
                    return (
                      <TableRow key={item.id}>
                        <TableCell className="w-80 font-medium">
                          <FormField
                            control={auditForm.control}
                            name={
                              `auditMappingRequests.${index.toString()}.mappingName` as `auditMappingRequests.${number}.mappingName`
                            }
                            render={() => (
                              <FormItem className="flex flex-col ">
                                <FormControl>
                                  <Input
                                    placeholder="Mapping Name"
                                    {...auditForm.register(
                                      `auditMappingRequests.${index.toString()}.mappingName` as `auditMappingRequests.${number}.mappingName`,
                                      {
                                        required: true,
                                      }
                                    )}
                                    className="transition-all duration-300 focus:w-48 focus:outline-none"
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TableCell>

                        <TableCell className="w-80 font-medium">
                          <FormField
                            control={auditForm.control}
                            name={
                              `auditMappingRequests.${index.toString()}.order` as `auditMappingRequests.${number}.order`
                            }
                            render={() => (
                              <FormItem className="flex flex-col ">
                                <FormControl>
                                  <Input
                                    type="number"
                                    max="20"
                                    placeholder="Order"
                                    {...auditForm.register(
                                      `auditMappingRequests.${index.toString()}.order` as `auditMappingRequests.${number}.order`,
                                      {
                                        required: true,
                                      }
                                    )}
                                    className="transition-all duration-300 focus:w-48 focus:outline-none"
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TableCell>

                        <TableCell className="w-80 font-medium">
                          <FormField
                            control={auditForm.control}
                            name={
                              `auditMappingRequests.${index.toString()}.dataLength` as `auditMappingRequests.${number}.dataLength`
                            }
                            render={() => (
                              <FormItem className="flex flex-col ">
                                <FormControl>
                                  <Input
                                    type="number"
                                    placeholder="Data Length"
                                    {...auditForm.register(
                                      `auditMappingRequests.${index.toString()}.dataLength` as `auditMappingRequests.${number}.dataLength`,
                                      {
                                        required: true,
                                      }
                                    )}
                                    className="transition-all duration-300 focus:w-48 focus:outline-none"
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TableCell>

                        <TableCell className="w-80 font-medium">
                          <FormField
                            control={auditForm.control}
                            name={
                              `auditMappingRequests.${index.toString()}.headerName` as `auditMappingRequests.${number}.headerName`
                            }
                            render={() => (
                              <FormItem className="flex flex-col ">
                                <FormControl>
                                  <Input
                                    placeholder="Header Name"
                                    {...auditForm.register(
                                      `auditMappingRequests.${index.toString()}.headerName` as `auditMappingRequests.${number}.headerName`,
                                      {
                                        required: true,
                                      }
                                    )}
                                    className="transition-all duration-300 focus:w-48 focus:outline-none"
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TableCell>

                        <TableCell className="w-80 font-medium">
                          <FormField
                            control={auditForm.control}
                            name={
                              `auditMappingRequests.${index.toString()}.isDecimalField` as `auditMappingRequests.${number}.isDecimalField`
                            }
                            render={({field}) => (
                              <FormItem className="space-y-4">
                                <FormControl>
                                  <RadioGroup
                                    className="flex flex-col space-y-1"
                                    defaultValue="1"
                                    onValueChange={(value) => {
                                      field.onChange(value === "1");
                                    }}
                                  >
                                    <div className="flex items-center space-x-2">
                                      <FormItem className="flex items-center space-x-3 space-y-0">
                                        <FormControl>
                                          <RadioGroupItem value="1" />
                                        </FormControl>
                                        <FormLabel className="font-normal">
                                          Yes
                                        </FormLabel>
                                      </FormItem>
                                      <FormItem className="flex items-center space-x-3 space-y-0">
                                        <FormControl>
                                          <RadioGroupItem value="0" />
                                        </FormControl>
                                        <FormLabel className="font-normal">
                                          No
                                        </FormLabel>
                                      </FormItem>
                                    </div>
                                  </RadioGroup>
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TableCell>

                        <TableCell className="w-80 font-medium">
                          <FormField
                            control={auditForm.control}
                            name={
                              `auditMappingRequests.${index.toString()}.dateFormatId` as `auditMappingRequests.${number}.dateFormatId`
                            }
                            render={({field}) => (
                              <Popover>
                                <PopoverContent className="w-[180px] p-0">
                                  <Command>
                                    <CommandGroup>
                                      {DateFormatData?.map((dateFormat, i) => (
                                        <CommandItem
                                          key={i}
                                          value={dateFormat.dateFormatId.toString()}
                                          onSelect={(dateFormatId) => {
                                            field.onChange(dateFormatId);
                                          }}
                                        >
                                          <CheckIcon
                                            className={cn(
                                              "mr-2 size-4",
                                              dateFormat.dateFormatId.toString() ===
                                                field.value.toString()
                                                ? "opacity-100"
                                                : "opacity-0"
                                            )}
                                          />
                                          {dateFormat.dateFormatValue}
                                        </CommandItem>
                                      ))}
                                    </CommandGroup>
                                  </Command>
                                </PopoverContent>
                                <PopoverTrigger asChild>
                                  <FormControl>
                                    <Button
                                      variant="outline"
                                      role="combobox"
                                      className={cn(
                                        "justify-between",
                                        !field.value &&
                                          "text-accent-foreground",
                                        "w-[180px]"
                                      )}
                                    >
                                      {field.value ? (
                                        DateFormatData?.find(
                                          (dateFormat) =>
                                            dateFormat.dateFormatId
                                              .toString()
                                              .toLowerCase() === field.value
                                        )?.dateFormatValue
                                      ) : (
                                        <>Select date format ID...</>
                                      )}

                                      <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                    </Button>
                                  </FormControl>
                                </PopoverTrigger>
                              </Popover>
                            )}
                          />
                        </TableCell>

                        <TableCell className="w-80 font-medium">
                          <FormField
                            control={auditForm.control}
                            name={
                              `auditMappingRequests.${index.toString()}.tableName` as `auditMappingRequests.${number}.tableName`
                            }
                            render={() => (
                              <FormItem className="flex flex-col ">
                                <FormControl>
                                  <Input
                                    placeholder="Table Name"
                                    {...auditForm.register(
                                      `auditMappingRequests.${index.toString()}.tableName` as `auditMappingRequests.${number}.tableName`,
                                      {
                                        required: true,
                                      }
                                    )}
                                    className="transition-all duration-300 focus:w-48 focus:outline-none"
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            )}
                          />
                        </TableCell>

                        <TableCell className="text-right">
                          <Button
                            className="flex items-center gap-2 px-3"
                            variant="outline"
                            size="xs"
                            onClick={() => {
                              remove(index);
                            }}
                          >
                            <Trash2Icon className="size-4" />
                            <span className="sr-only">Delete</span>
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>

              <div className="space-x-2 p-2 text-right">
                <Link className="mb-4" to="/app/imremit/mapping">
                  <Button
                    type="button"
                    variant="input"
                    className="col-span-1 gap-2"
                  >
                    <span className="sr-only">Cancel</span>
                    <XIcon className="size-4" />
                    Cancel
                  </Button>
                </Link>
                <Button
                  type="submit"
                  disabled={isMutating || !fields.length}
                  aria-disabled={isMutating}
                  variant="affirmative"
                  className="col-span-1 gap-2"
                >
                  <span className="sr-only">Save</span>
                  <SaveIcon className="size-4" />
                  {isMutating ? <Spinner size="xs" /> : ""} Save
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </Card>
    </>
  );
}
