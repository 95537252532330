import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {type CustomerFormDTO} from "@/modules/admin";
import {customerQueryKeys} from "@/modules/customers";

type QueryFnType = typeof getCustomerById;

export function getCustomerById(externalId: string) {
  const data = kyApiFn<CustomerFormDTO>(
    `customer/v1/customer/${externalId}`,
    "get"
  );
  return data;
}

export function useGetCustomerById(
  externalId: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: customerQueryKeys.byExternalId(externalId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getCustomerById(externalId), // Setting the function to be used for fetching data
    enabled: !!externalId, // Setting the query to be enabled only if the search id string is not empty
  });
}
