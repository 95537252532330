import {z} from "zod";

import {RoutesDirectory} from "@/lib/routes/directory";

export const BaseRoutesSchema = z.enum([
  RoutesDirectory.ROOT,
  RoutesDirectory.TERMS_OF_SERVICE,
  RoutesDirectory.PRIVACY_POLICY,
  RoutesDirectory.APP,
]);

export type BaseRoutesType = z.infer<typeof BaseRoutesSchema>;

export const InvoiceTrackerRoutesSchema = z.enum([
  `${RoutesDirectory.APP}${RoutesDirectory.INVOICES}${RoutesDirectory.TRACKER}`,
  `${RoutesDirectory.APP}${RoutesDirectory.INVOICES}${RoutesDirectory.MAPPING}`,
]);

export type InvoiceTrackerRoutesType = z.infer<
  typeof InvoiceTrackerRoutesSchema
>;

export const ElectronicInvoicingRoutesSchema = z.enum([
  `${RoutesDirectory.APP}${RoutesDirectory.ELECTRONIC_INVOICING}`,
]);

export type ElectronicInvoicingRoutesType = z.infer<
  typeof ElectronicInvoicingRoutesSchema
>;

export const ImREmitRoutesSchema = z.enum([
  `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT}`,
  `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT}${RoutesDirectory.DASHBOARD}`,
  `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT}${RoutesDirectory.PAYMENT_MANAGEMENT}`,
  `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT}${RoutesDirectory.REMITTANCE_MANAGEMENT}`,
  `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT}${RoutesDirectory.SUPPLIER_MANAGEMENT}`,
  `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT}${RoutesDirectory.FILE_PROCESSING}`,
  `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT}${RoutesDirectory.PROXY_PAY_DASHBOARD}`,
  `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT}${RoutesDirectory.SUPPLIER_SCRIPT_MANAGEMENT}`,
  `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT}${RoutesDirectory.MAPPING}`,
  `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT}${RoutesDirectory.PDF_UPLOAD}`,
]);

export type ImREmitRoutesType = z.infer<typeof ImREmitRoutesSchema>;

export const ImREmitLiteRoutesSchema = z.enum([
  `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT_LITE}`,
  `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT_LITE}${RoutesDirectory.DASHBOARD}`,
  `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT_LITE}${RoutesDirectory.PAYMENT_MANAGEMENT}`,
  `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT_LITE}${RoutesDirectory.SUPPLIER_MANAGEMENT}`,
  // `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT_LITE}${RoutesDirectory.CUSTOMER_MANAGEMENT}`,
  // `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT_LITE}${RoutesDirectory.FILE_PROCESSING}`,
  `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT_LITE}${RoutesDirectory.PROXY_PAY_DASHBOARD}`,
  `${RoutesDirectory.APP}${RoutesDirectory.IMREMIT_LITE}${RoutesDirectory.SUPPLIER_SCRIPT_MANAGEMENT}`,
]);

export type ImREmitLiteRoutesType = z.infer<typeof ImREmitLiteRoutesSchema>;

export const DuplicatePaymentRoutesSchema = z.enum([
  `${RoutesDirectory.APP}${RoutesDirectory.DUPLICATE_PAYMENT}`,
  `${RoutesDirectory.APP}${RoutesDirectory.DUPLICATE_PAYMENT}${RoutesDirectory.DASHBOARD}`,
  `${RoutesDirectory.APP}${RoutesDirectory.DUPLICATE_PAYMENT}${RoutesDirectory.TRACKER}`,
  `${RoutesDirectory.APP}${RoutesDirectory.DUPLICATE_PAYMENT}${RoutesDirectory.CRITERIA_SETTINGS}`,
]);

export type DuplicatePaymentRoutesType = z.infer<
  typeof DuplicatePaymentRoutesSchema
>;

export const StatementReconRoutesSchema = z.enum([
  `${RoutesDirectory.APP}${RoutesDirectory.STATEMENT_RECON}`,
  `${RoutesDirectory.APP}${RoutesDirectory.STATEMENT_RECON}${RoutesDirectory.FILE_HISTORY}`,
  `${RoutesDirectory.APP}${RoutesDirectory.STATEMENT_RECON}${RoutesDirectory.UPLOAD}`,
  `${RoutesDirectory.APP}${RoutesDirectory.STATEMENT_RECON}${RoutesDirectory.SETTINGS}`,
]);

export type StatementReconRoutesType = z.infer<
  typeof StatementReconRoutesSchema
>;

export const AdminRoutesSchema = z.enum([
  `${RoutesDirectory.APP}${RoutesDirectory.ADMIN}`,
  `${RoutesDirectory.APP}${RoutesDirectory.ADMIN}${RoutesDirectory.CUSTOMER_MANAGEMENT}`,
  `${RoutesDirectory.APP}${RoutesDirectory.ADMIN}${RoutesDirectory.MASTER_ROLE_SETTINGS}`,
  `${RoutesDirectory.APP}${RoutesDirectory.ADMIN}${RoutesDirectory.USER_ROLE_RELATION}`,
  `${RoutesDirectory.APP}${RoutesDirectory.ADMIN}${RoutesDirectory.CUSTOMER_MODULE_MANAGEMENT}`,
  `${RoutesDirectory.APP}${RoutesDirectory.ADMIN}${RoutesDirectory.PID_MAPPING}`,
  `${RoutesDirectory.APP}${RoutesDirectory.ADMIN}${RoutesDirectory.USER_MANAGEMENT}`,
  `${RoutesDirectory.APP}${RoutesDirectory.ADMIN}${RoutesDirectory.SUPPLIER_ONBOARDING}`,
  `${RoutesDirectory.APP}${RoutesDirectory.ADMIN}${RoutesDirectory.ADMIN_REPORT}`,
  `${RoutesDirectory.APP}${RoutesDirectory.ADMIN}${RoutesDirectory.SUPPLIER_MANAGEMENT}`,
  `${RoutesDirectory.APP}${RoutesDirectory.ADMIN}${RoutesDirectory.ALERT_MANAGEMENT}`,
  `${RoutesDirectory.APP}${RoutesDirectory.ADMIN}${RoutesDirectory.MIS}`,
]);

export type AdminRoutesType = z.infer<typeof AdminRoutesSchema>;

export const SettingsRoutesSchema = z.enum([
  `${RoutesDirectory.APP}${RoutesDirectory.SETTINGS}`,
]);

export type SettingsRoutesType = z.infer<typeof SettingsRoutesSchema>;

export const ProfileRoutesSchema = z.enum([
  `${RoutesDirectory.APP}${RoutesDirectory.PROFILE}`,
]);

export type ProfileRoutesType = z.infer<typeof ProfileRoutesSchema>;

export const HelpRoutesSchema = z.enum([
  `${RoutesDirectory.APP}${RoutesDirectory.HELP}`,
]);

export type HelpRoutesType = z.infer<typeof HelpRoutesSchema>;

export const RoutesSchema = z.union([
  BaseRoutesSchema,
  InvoiceTrackerRoutesSchema,
  ElectronicInvoicingRoutesSchema,
  ImREmitRoutesSchema,
  ImREmitLiteRoutesSchema,
  DuplicatePaymentRoutesSchema,
  StatementReconRoutesSchema,
  AdminRoutesSchema,
  SettingsRoutesSchema,
  ProfileRoutesSchema,
  HelpRoutesSchema,
]);

export type RoutesType = z.infer<typeof RoutesSchema>;

export interface RouteMeta {
  routeId: number;
  routeLabel: string;
  routeName: string;
  routeUrl: RoutesDirectory;
}
