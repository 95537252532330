// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type DupesRunHistoryTableColumnsType} from "@/modules/duplicate-payments";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface DupesRunHistoryTableColumnsStoreState {
  storeDupesRunHistoryTableColumns: DupesRunHistoryTableColumnsType | null;
  setStoreDupesRunHistoryTableColumns: (
    storeDupesRunHistoryTableColumns: DupesRunHistoryTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for DupesRunHistory table columns
export const useDupesRunHistoryTableColumnsStore = create(
  persist<DupesRunHistoryTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeDupesRunHistoryTableColumns: null,

      // Getter function to retrieve the current state
      getStoreDupesRunHistoryTableColumns: () =>
        get().storeDupesRunHistoryTableColumns,

      // Setter function to update the state
      setStoreDupesRunHistoryTableColumns: (
        storeDupesRunHistoryTableColumns
      ) => {
        set(() => ({storeDupesRunHistoryTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "DupesRunHistory-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useDupesRunHistoryTableColumns = () =>
  useDupesRunHistoryTableColumnsStore((state) => {
    return {
      storeDupesRunHistoryTableColumns: state.storeDupesRunHistoryTableColumns,
    };
  });
