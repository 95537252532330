import {z} from "zod";

import {toast} from "@/components/ui/use-toast";

import {ModuleNames} from "@/utils/constants";

export const CustomerFormSchema = z.object({
  // ONBOARDING FORM

  id: z.number(),
  externalId: z.string().max(140).nullable().optional(),
  buyerName: z.string().min(1, "Buyer Name is required").max(140),
  epayManagerAssigned: z.string().optional(),
  status: z
    .string()
    .min(1, "Status is required")
    .max(140, "Status should not be more than 140 characters!"),
  mccTemplateOptions: z.preprocess((foo) => {
    if (!foo || typeof foo !== "string") return undefined;
    return foo === "" ? undefined : foo;
  }, z.string().max(140).nullable().optional()),

  companyType: z.preprocess((foo) => {
    if (!foo || typeof foo !== "string") return undefined;
    return foo === "" ? undefined : foo;
  }, z.string().max(140).nullable().optional()),

  modules: z.string().max(140).nullable().optional(),

  modifiedBy: z.string().optional().nullable(),

  // MODULE SUBSCRIPTION
  moduleSubscriptions: z.array(
    z.object({
      moduleId: z.number().min(0),
      moduleName: z
        .string()
        .min(1, "Module Name is required")
        .max(140, "Module Name must not exceed 140 characters."),
      buyerId: z.number().nullable().optional(),
      moduleOnboarded: z.boolean().default(false).nullable().optional(),
      removeModule: z.boolean().default(false).nullable().optional(),
    })
  ),
  buyerAliases: z.array(z.string()).nullable().optional(),
  aliasSwitch: z.boolean().nullable().optional(),
  // CUSTOMER PROFILE

  customerProfile: z.object({
    buyerId: z.number().nullable().optional(),
    buyerProfileAccountNumberMasking: z
      .boolean()
      .default(false)
      .nullable()
      .nullable()
      .optional(),
    buyerState: z.string().max(140).nullable().optional(),
    buyerCity: z.string().max(140).nullable().optional(),
    buyerProfileAddress1: z.string().max(140).nullable().optional(),
    buyerProfileAddress2: z.string().max(140).nullable().optional(),
    buyerProfileAddress3: z.string().max(140).nullable().optional(),
    buyerProfileAssociation: z.string().max(140).nullable().optional(),
    buyerProfileBill: z.string().max(140).nullable().optional(),
    buyerProfileBillingType: z.string().max(140).nullable().optional(),
    buyerProfileCompanyName: z.string().max(140).nullable().optional(),
    buyerProfileConnectivityMethod: z.string().max(140).nullable().optional(),
    buyerProfileContactName: z.string().max(140).nullable().optional(),
    buyerProfileCountry: z
      .string()
      .min(1, "Country is required")
      .max(3, "Country must not exceed 3 characters."),
    buyerProfileCycleDate: z.string().max(140).nullable().optional(),
    buyerProfileEmail: z
      .string()
      .regex(new RegExp(/^$|^[^\s@]+@[^\s@]+\.[^\s@]+$/), "Invalid Email!")
      .max(140)
      .nullable()
      .optional(),
    buyerProfileInvoiceDay: z.string().max(140).nullable().optional(),
    buyerProfileMccTemplate: z.string().max(140).nullable().optional(),
    buyerProfileOptions: z.string().max(140).nullable().optional(),
    buyerProfileOrgId: z.string().max(140).nullable().optional(),
    buyerProfilePaymentMethod: z.string().max(140).nullable().optional(),
    buyerProfilePaymentType: z.string().max(140).nullable().optional(),
    buyerProfilePhone: z
      .string()
      .regex(
        new RegExp(/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])*$/),
        "Invalid Phone Number!"
      )
      .nullable()
      .optional(),
    buyerProfileCompanyType: z.string().max(140).nullable().optional(),
    buyerProfileReportingTool: z.string().max(140).nullable().optional(),
    buyerProfileResponseDataMasking: z
      .string()
      .min(1, "Response data masking is required")
      .max(140, "Response data masking must not exceed 140 characters."),
    buyerProfileSettlementTerms: z.string().max(140).nullable().optional(),
    buyerProfileAccountNumberMapping: z.boolean().nullable().optional(),
    buyerProfileZip: z.string(),
    buyerEpayMail: z.string().max(140).nullable().optional(),
    buyerEpayMailPassword: z.string().max(140).nullable().optional(),
    buyerZeroDollarFlag: z.boolean().nullable().optional(),
    buyerEpayManager: z.string().max(140).nullable().optional(),
    modifiedBy: z.string().optional().nullable(),
    pidMapping: z.string().max(140).nullable().optional(),
    multiSelectPidMapping: z.string().max(140).nullable().optional(),
  }),
});

export const CustomerModuleFormSchema = CustomerFormSchema.omit({
  customerProfile: true,
  epayManagerAssigned: true,
  mccTemplateOptions: true,
  companyType: true,
  modules: true,
});

export const AddCustomerFormSchema = CustomerFormSchema.omit({
  id: true,
  externalId: true,
}).superRefine((values, ctx) => {
  const {
    customerProfile: {
      buyerProfileCountry,
      buyerProfileZip,
      buyerEpayMail,
      buyerEpayMailPassword,
      buyerZeroDollarFlag,
    },
    buyerAliases,
    aliasSwitch,
    moduleSubscriptions,
  } = values;
  if (buyerZeroDollarFlag && !buyerEpayMail) {
    ctx.addIssue({
      code: "custom",
      message: "Please enter a valid email",
      path: ["customerProfile", "buyerEpayMail"],
    });
  }
  if (buyerZeroDollarFlag && !buyerEpayMailPassword) {
    ctx.addIssue({
      code: "custom",
      message: "Please enter your password",
      path: ["customerProfile", "buyerEpayMailPassword"],
    });
  }
  // Custom validation for zip code based on country
  if (buyerProfileCountry === "usa") {
    const usZipCodeRegex = /^\d{5}(-\d{4})?$/;
    if (!usZipCodeRegex.test(buyerProfileZip)) {
      ctx.addIssue({
        code: "custom",
        message: "Please enter a valid zip code",
        path: ["customerProfile", "buyerProfileZip"],
      });
    }
  } else if (buyerProfileCountry === "ca") {
    const caPostalCodeRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
    if (!caPostalCodeRegex.test(buyerProfileZip)) {
      ctx.addIssue({
        code: "custom",
        message: "Please enter a valid postal code",
        path: ["customerProfile", "buyerProfileZip"],
      });
    }
  }
  if (
    aliasSwitch &&
    moduleSubscriptions.some(
      (module) => module.moduleName === ModuleNames.imremitLite
    ) &&
    (!buyerAliases ||
      buyerAliases.length === 0 ||
      buyerAliases.some((alias) => alias.trim() === ""))
  ) {
    const message =
      !buyerAliases || buyerAliases.length === 0
        ? "Please ensure there is at least one alias."
        : "Please ensure no alias fields are empty.";

    toast({
      variant: "destructive",
      title: "Unable to create customer!",
      description: message,
    });

    ctx.addIssue({
      code: "custom",
      message: message,
      path: ["buyerAliases"],
    });
  }
});

export const UpdateCustomerFormSchema = CustomerFormSchema.superRefine(
  (values, ctx) => {
    const {
      customerProfile: {
        buyerProfileCountry,
        buyerProfileZip,
        buyerEpayMail,
        buyerEpayMailPassword,
        buyerZeroDollarFlag,
      },
      buyerAliases,
      aliasSwitch,
      moduleSubscriptions,
    } = values;
    if (buyerZeroDollarFlag && !buyerEpayMail) {
      ctx.addIssue({
        code: "custom",
        message: "Please enter a valid email",
        path: ["customerProfile", "buyerEpayMail"],
      });
    }
    if (buyerZeroDollarFlag && !buyerEpayMailPassword) {
      ctx.addIssue({
        code: "custom",
        message: "Please enter your password",
        path: ["customerProfile", "buyerEpayMailPassword"],
      });
    }
    // Custom validation for zip code based on country
    if (buyerProfileCountry === "usa") {
      const usZipCodeRegex = /^\d{5}(-\d{4})?$/;
      if (!usZipCodeRegex.test(buyerProfileZip)) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter a valid zip code",
          path: ["customerProfile", "buyerProfileZip"],
        });
      }
    } else if (buyerProfileCountry === "ca") {
      const caPostalCodeRegex = /^[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d$/;
      if (!caPostalCodeRegex.test(buyerProfileZip)) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter a valid postal code",
          path: ["customerProfile", "buyerProfileZip"],
        });
      }
    }
    if (
      aliasSwitch &&
      moduleSubscriptions.some(
        (module) => module.moduleName === ModuleNames.imremitLite
      ) &&
      (!buyerAliases ||
        buyerAliases.length === 0 ||
        buyerAliases.some((alias) => alias.trim() === ""))
    ) {
      const message =
        !buyerAliases || buyerAliases.length === 0
          ? "Please ensure there is at least one alias."
          : "Please ensure no alias fields are empty.";

      toast({
        variant: "destructive",
        title: "Unable to create customer!",
        description: message,
      });

      ctx.addIssue({
        code: "custom",
        message: message,
        path: ["buyerAliases"],
      });
    }
  }
);

export const AddPaymentMethodFormSchema = z.object({
  providerId: z.string().max(140).nullable(),
  paymentMethod: z.string().max(140).nullable(),
  customerPaymentMethodName: z.string().nullable().optional(),
  customerPaymentMethodDescription: z
    .string()
    .max(
      140,
      "Customer payment method description must not exceed 140 characters."
    )
    .nullable()
    .optional(),
  customerResponseViaEmail: z.string().nullable().optional(),
  modifiedBy: z.string().nullable(),
});

export const UpdatePaymentMethodFormSchema = z.object({
  customerPaymentMethodId: z.string().max(140).nullable().optional(),
  providerId: z.string().max(140).nullable(),
  paymentMethod: z.string().max(140).nullable(),
  customerPaymentMethodName: z.string().nullable().optional(),
  customerPaymentMethodDescription: z
    .string()
    .max(
      140,
      "Customer payment method description must not exceed 140 characters."
    )
    .nullable()
    .optional(),
  customerResponseViaEmail: z.string().nullable().optional(),
  modifiedBy: z.string().nullable(),
});

export const AddParticipantRegisterFormSchema = z.object({
  facilityName: z
    .string()
    .min(1, "Facility name is required")
    .max(140, "Facility name must not exceed 140 characters.")
    .nullable(),
  orgId: z
    .string()
    .min(1, "Organization ID is required")
    .max(140, "Organization ID must not exceed 140 characters.")
    .nullable(),
  userId: z
    .string()
    .min(1, "User ID is required")
    .max(140, "User ID must not exceed 140 characters.")
    .nullable(),
  groupId: z
    .string()
    .max(140, "Group ID must not exceed 140 characters.")
    .nullable()
    .optional(),
  futureUse1: z
    .string()
    .max(140, "Future use 1 must not exceed 140 characters.")
    .nullable()
    .optional(),
  futureUse2: z
    .string()
    .max(140, "Future use 2 must not exceed 140 characters.")
    .nullable()
    .optional(),
  bankAccountNumber: z
    .string()
    .max(140, "Bank Account # must not exceed 140 characters.")
    .nullable()
    .optional(),
  modifiedBy: z.string().nullable().optional(),
});

export const UpdateParticipantRegisterFormSchema = z.object({
  participantRegisterId: z.number(),
  facilityName: z
    .string()
    .min(1, "Facility Name is required")
    .max(140, "Facility Name must not exceed 140 characters.")
    .nullable(),
  orgId: z
    .string()
    .min(1, "Organization ID is required")
    .max(140, "Organization ID must not exceed 140 characters.")
    .nullable(),
  userId: z
    .string()
    .min(1, "User ID is required")
    .max(140, "User ID must not exceed 140 characters.")
    .nullable(),
  groupId: z
    .string()
    .max(140, "Group ID must not exceed 140 characters.")
    .nullable()
    .optional(),
  futureUse1: z
    .string()
    .max(140, "Future Use 1 must not exceed 140 characters.")
    .nullable()
    .optional(),
  futureUse2: z
    .string()
    .max(140, "Future Use 2 must not exceed 140 characters.")
    .nullable()
    .optional(),
  bankAccountNumber: z
    .string()
    .max(140, "Bank Account # must not exceed 140 characters.")
    .nullable()
    .optional(),
  modifiedBy: z.string().optional(),
});

export const RunnerConfigFormSchema = z.object({
  // Common fields
  batchRunnerType: z
    .string()
    .min(1, "Batch Runner Type is required")
    .max(140, "Batch Runner Type must not exceed 140 characters.")
    .nullable(),
  paymentProvider: z
    .string()
    .min(1, "Payment Provider is required")
    .max(140, "Payment Provider must not exceed 140 characters.")
    .nullable(),
  dateFormat: z
    .string()
    .min(1, "Date Format is required")
    .max(140, "Date Format must not exceed 140 characters.")
    .nullable(),
  delimiterType: z
    .string()
    .min(1, "Delimiter Type is required")
    .max(140, "Delimiter Type must not exceed 140 characters.")
    .nullable(),
  documentFormat: z
    .string()
    .min(1, "Document Format is required")
    .max(140, "Document Format must not exceed 140 characters.")
    .nullable(),
  fileNameContainer: z
    .string()
    .min(1, "File Name Container is required")
    .max(140, "File Name Container must not exceed 140 characters.")
    .nullable(),
  firstRowHeader: z.boolean().default(false).nullable(),
  validMinimumLength: z
    .number()
    .min(0, "Valid Minimum Length must be at least 0.")
    .max(140, "Valid Minimum Length must not exceed 140.")
    .nullable(),
  documentFormatDenormalize: z
    .string()
    .min(1, "Document Format Denormalize is required")
    .max(140, "Document Format Denormalize must not exceed 140 characters.")
    .nullable()
    .optional(),
  // Audit/Temp Fields
  skipHeaderCount: z.number().nullable().optional(),
  skipFooterCount: z.number().nullable().optional(),
  // Payment fields
  customMapping: z.boolean().default(false).nullable().optional(),
  recordTypeLength: z.number().nullable().optional(),
  headerRecordType: z
    .string()
    .min(1, "Header Record Type is required")
    .max(140, "Header Record Type must not exceed 140 characters.")
    .nullable()
    .optional(),
  dataRecordType: z
    .string()
    .min(1, "Data Record Type is required")
    .max(140, "Data Record Type must not exceed 140 characters.")
    .nullable()
    .optional(),
  detailRecordType: z
    .string()
    .min(1, "Detail Record Type is required")
    .max(140, "Detail Record Type must not exceed 140 characters.")
    .nullable()
    .optional(),
  customRecordType: z
    .string()
    .min(1, "Custom Record Type is required")
    .max(140, "Custom Record Type must not exceed 140 characters.")
    .nullable()
    .optional(),
  footerRecordType: z
    .string()
    .min(1, "Footer Record Type is required")
    .max(140, "Footer Record Type must not exceed 140 characters.")
    .nullable()
    .optional(),
  responseRequired: z.boolean().default(false).nullable().optional(),
  responseDataDateFormat: z.string().nullable().optional(),

  fileNamePart: z
    .string()
    .min(1, "File Name Part is required")
    .max(140, "File Name Part must not exceed 140 characters.")
    .nullable()
    .optional(),
  isRecordTypeRequired: z.boolean().default(false).nullable().optional(),
  isPaymentFileInternalAlertRequired: z
    .boolean()
    .default(false)
    .nullable()
    .optional(),
  paymentFileExpectedTime: z
    .string()
    .min(1, "Payment File Expected Time is required")
    .max(140, "Payment File Expected Time must not exceed 140 characters.")
    .nullable()
    .optional(),
  // Recon Fields
  isHeaderRequired: z.boolean().default(false).nullable().optional(),
  partialPayReconRequired: z.boolean().default(false).nullable().optional(),
  isConsolidatedReconRequired: z.boolean().default(false).nullable().optional(),
  ignoreCredits: z.boolean().default(false).nullable().optional(),
  isEmptyReconRequired: z.boolean().default(false).nullable().optional(),
  fileNameDateFormat: z
    .string()
    .min(1, "File Name Date Format is required")
    .max(140, "File Name Date Format must not exceed 140 characters.")
    .nullable()
    .optional(),
  lineSeparator: z
    .string()
    .min(1, "Line Separator is required")
    .max(140, "Line Separator must not exceed 140 characters.")
    .nullable()
    .optional(),
  isResponseFooterRequired: z.boolean().default(false).nullable().optional(),
  isResponseFooterRequiredRecon: z
    .boolean()
    .default(false)
    .nullable()
    .optional(),
  isCustomerReconViaEmail: z.string().nullable().optional(),
  extension: z.string().nullable().optional(),
  responseHeaderDateFormat: z
    .string()
    .min(1, "Response Header Date Format is required")
    .max(140, "Response Header Date Format must not exceed 140 characters.")
    .nullable()
    .optional(),
  responseHeaderDateFormatRecon: z
    .string()
    .min(1, "Bank File Header Date Format is required")
    .max(140, "Bank File Header Date Format must not exceed 140 characters.")
    .nullable()
    .optional(),
  dailyReconBankRequired: z.boolean().default(false).nullable().optional(),
  monthlyReconBankRequired: z.boolean().default(false).nullable().optional(),
  weeklyReconBankRequired: z.boolean().default(false).nullable().optional(),

  removeDailyBankReconTimeStamp: z
    .boolean()
    .default(false)
    .nullable()
    .optional(),
  removeWeeklyBankReconTimeStamp: z
    .boolean()
    .default(false)
    .nullable()
    .optional(),
  removeMonthlyBankReconTimeStamp: z
    .boolean()
    .default(false)
    .nullable()
    .optional(),
  dailySelectedTime: z
    .string()
    .min(1, "Daily Selected Time is required")
    .max(140, "Daily Selected Time must not exceed 140 characters.")
    .nullable()
    .optional(),
  weeklySelectedTime: z
    .string()
    .min(1, "Weekly Selected Time is required")
    .max(140, "Weekly Selected Time must not exceed 140 characters.")
    .nullable()
    .optional(),
  monthlySelectedTime: z
    .string()
    .min(1, "Monthly Selected Time is required")
    .max(140, "Monthly Selected Time must not exceed 140 characters.")
    .nullable()
    .optional(),
  weeklySelectedValue: z
    .string()
    .min(1, "Weekly Selected Value is required")
    .max(140, "Weekly Selected Value must not exceed 140 characters.")
    .nullable()
    .optional(),
  monthlySelectedValue: z
    .string()
    .min(1, "Monthly Selected Value is required")
    .max(140, "Monthly Selected Value must not exceed 140 characters.")
    .nullable()
    .optional(),
  fileNamePartDaily: z
    .string()
    .min(1, "File Name Part Daily is required")
    .max(140, "File Name Part Daily must not exceed 140 characters.")
    .nullable()
    .optional(),
  fileNamePartWeekly: z
    .string()
    .min(1, "File Name Part Weekly is required")
    .max(140, "File Name Part Weekly must not exceed 140 characters.")
    .nullable()
    .optional(),
  fileNamePartMonthly: z
    .string()
    .min(1, "File Name Part Monthly is required")
    .max(140, "File Name Part Monthly must not exceed 140 characters.")
    .nullable()
    .optional(),
  isDailyReconPostDate: z.boolean().default(false).nullable().optional(),
  modifiedBy: z.string().nullable().optional(),
});

export const PaymentRunnerConfigFormSchema = z
  .object({
    modifiedBy: z.string().nullable().optional(),
    id: z.number().nullable().optional(),
    batchRunnerType: z
      .string()
      .min(1, "Batch Runner Type is required")
      .max(140, "Batch Runner Type must not exceed 140 characters.")
      .nullable(),
    paymentProvider: z
      .string()
      .min(1, "Payment Provider is required")
      .max(140, "Payment Provider must not exceed 140 characters.")
      .nullable(),
    dateFormat: z
      .string()
      .min(1, "Date Format is required")
      .max(140, "Date Format must not exceed 140 characters.")
      .nullable(),
    delimiterType: z.string().nullable(),
    documentFormat: z
      .string()
      .min(1, "Document Format is required")
      .max(140, "Document Format must not exceed 140 characters.")
      .nullable(),
    fileNameContainer: z
      .string()
      .min(1, "File Name Container is required")
      .max(140, "File Name Container must not exceed 140 characters.")
      .nullable(),
    firstRowHeader: z.boolean().default(false).nullable(),
    validMinimumLength: z
      .number()
      .min(5, "Valid Minimum Length must be at least 5.")
      .max(140, "Valid Minimum Length must not exceed 140.")
      .nullable(),
    documentFormatDenormalize: z
      .string()
      .min(1, "Document Format Denormalize is required")
      .max(140, "Document Format Denormalize must not exceed 140 characters.")
      .nullable()
      .optional(),

    customMapping: z.boolean().default(false).nullable().optional(),
    recordTypeLength: z.number().nullable().optional(),
    headerRecordType: z
      .string()
      .max(140, "Header Record Type must not exceed 140 characters.")
      .nullable()
      .optional(),
    dataRecordType: z
      .string()
      .max(140, "Data Record Type must not exceed 140 characters.")
      .nullable()
      .optional(),
    detailRecordType: z
      .string()
      .max(140, "Detail Record Type must not exceed 140 characters.")
      .nullable()
      .optional(),
    customRecordType: z
      .string()
      .max(140, "Custom Record Type must not exceed 140 characters.")
      .nullable()
      .optional(),
    footerRecordType: z
      .string()
      .max(140, "Footer Record Type must not exceed 140 characters.")
      .nullable()
      .optional(),
    responseRequired: z.boolean().default(false).nullable().optional(),
    responseDataDateFormat: z.string().nullable().optional(),
    isResponseFooterRequired: z.boolean().default(false).nullable().optional(),
    lineSeparator: z.string().nullable().optional(),
    responseHeaderDateFormat: z.string().nullable().optional(),
    fileNameDateFormat: z.string().nullable().optional(),
    fileNamePart: z.string().nullable().optional(),
    extension: z.string().nullable().optional(),
    isRecordTypeRequired: z.boolean().default(false).nullable().optional(),
    isPaymentFileInternalAlertRequired: z
      .boolean()
      .default(false)
      .nullable()
      .optional(),
    paymentFileFrequencies: z.array(z.string()),
    paymentFileFrequency: z.string().nullable().optional(),
    paymentFileExpectedTime: z
      .string()
      .max(140, "Payment File Expected Time must not exceed 140 characters.")
      .nullable()
      .optional(),
    customerResponseViaEmail: z.string().nullable().optional(),
  })
  .superRefine(
    (
      {
        paymentFileExpectedTime,
        isRecordTypeRequired,
        headerRecordType,
        detailRecordType,
        customRecordType,
        dataRecordType,
        footerRecordType,
        isPaymentFileInternalAlertRequired,
        paymentFileFrequency,
      },
      ctx
    ) => {
      if (
        isPaymentFileInternalAlertRequired &&
        !paymentFileExpectedTime &&
        paymentFileExpectedTime !== null
      ) {
        ctx.addIssue({
          code: "custom",
          message: "Payment File Expected Time is required",
          path: ["paymentFileExpectedTime"],
        });
      }
      if (isPaymentFileInternalAlertRequired && !paymentFileFrequency) {
        ctx.addIssue({
          code: "custom",
          message: "Payment File Frequency is required",
          path: ["paymentFileFrequency"],
        });
      }
      if (isRecordTypeRequired && !headerRecordType) {
        ctx.addIssue({
          code: "custom",
          message: "Header Record Type is required",
          path: ["headerRecordType"],
        });
      }
      if (isRecordTypeRequired && !detailRecordType) {
        ctx.addIssue({
          code: "custom",
          message: "Detail Record Type is required",
          path: ["detailRecordType"],
        });
      }
      if (isRecordTypeRequired && !customRecordType) {
        ctx.addIssue({
          code: "custom",
          message: "Custom Record Type is required",
          path: ["customRecordType"],
        });
      }
      if (isRecordTypeRequired && !dataRecordType) {
        ctx.addIssue({
          code: "custom",
          message: "Data Record Type is required",
          path: ["dataRecordType"],
        });
      }
      if (isRecordTypeRequired && !footerRecordType) {
        ctx.addIssue({
          code: "custom",
          message: "Footer Record Type is required",
          path: ["footerRecordType"],
        });
      }
    }
  );

export const PaymentRunnerConfigColumnsSchema = z.object({
  id: z.boolean(),
  batchRunnerType: z.boolean(),
  paymentProvider: z.boolean(),
  dateFormat: z.boolean(),
  delimiterType: z.boolean(),
  documentFormat: z.boolean(),
  fileNameContainer: z.boolean(),
  firstRowHeader: z.boolean(),
  validMinimumLength: z.boolean(),
  documentFormatDenormalize: z.boolean(),
  customMapping: z.boolean(),
  recordTypeLength: z.boolean(),
  headerRecordType: z.boolean(),
  dataRecordType: z.boolean(),
  detailRecordType: z.boolean(),
  customRecordType: z.boolean(),
  footerRecordType: z.boolean(),
  responseRequired: z.boolean(),
  responseDataDateFormat: z.boolean(),
  isResponseFooterRequired: z.boolean(),
  lineSeparator: z.boolean(),
  responseHeaderDateFormat: z.boolean(),
  fileNameDateFormat: z.boolean(),
  fileNamePart: z.boolean(),
  extension: z.boolean(),
  isRecordTypeRequired: z.boolean(),
  isPaymentFileInternalAlertRequired: z.boolean(),
  paymentFileFrequencies: z.boolean(),
  paymentFileFrequency: z.boolean(),
  paymentFileExpectedTime: z.boolean(),
  customerResponseViaEmail: z.boolean(),
});

export type RunnerConfigsColumnsType = z.infer<
  typeof PaymentRunnerConfigColumnsSchema
>;

export const ReconRunnerConfigFormSchema = z
  .object({
    modifiedBy: z.string().nullable().optional(),
    id: z.number().nullable().optional(),
    batchRunnerType: z
      .string()
      .min(1, "Batch Runner Type is required")
      .max(140, "Batch Runner Type must not exceed 140 characters.")
      .nullable(),
    paymentProvider: z
      .string()
      .min(1, "Payment Provider is required")
      .max(140, "Payment Provider must not exceed 140 characters.")
      .nullable(),
    dateFormat: z
      .string()
      .min(1, "Date Format is required")
      .max(140, "Date Format must not exceed 140 characters.")
      .nullable(),
    delimiterType: z
      .string()
      .min(1, "Delimiter Type is required")
      .max(140, "Delimiter Type must not exceed 140 characters.")
      .nullable(),
    documentFormat: z
      .string()
      .min(1, "Document Format is required")
      .max(140, "Document Format must not exceed 140 characters.")
      .nullable(),
    fileNameContainer: z
      .string()
      .min(1, "File Name Container is required")
      .max(140, "File Name Container must not exceed 140 characters.")
      .nullable(),
    firstRowHeader: z.boolean().default(false).nullable(),
    validMinimumLength: z
      .number()
      .min(0, "Valid Minimum Length must be at least 0.")
      .max(140, "Valid Minimum Length must not exceed 140.")
      .nullable(),
    documentFormatDenormalize: z.string(),
    // DenormalizeDocumentFormatField is now mandatory -INP-4171
    //.min(1, "Valid Minimum Length must be at least 1.")
    //.max(140, "Document Format Denormalize must not exceed 140 characters."),

    isHeaderRequired: z.boolean().default(false).nullable().optional(),
    partialPayReconRequired: z.boolean().default(false).nullable().optional(),
    isConsolidatedReconRequired: z
      .boolean()
      .default(false)
      .nullable()
      .optional(),
    ignoreCredits: z.boolean().default(false).nullable().optional(),
    isEmptyReconRequired: z.boolean().default(false).nullable().optional(),
    fileNameDateFormat: z
      .string()
      .min(1, "File Name Date Format is required")
      .max(140, "File Name Date Format must not exceed 140 characters.")
      .nullable()
      .optional(),
    lineSeparator: z
      .string()
      .min(1, "Line Separator is required")
      .max(140, "Line Separator must not exceed 140 characters.")
      .nullable()
      .optional(),
    isResponseFooterRequired: z.boolean().default(false).nullable().optional(),
    isResponseFooterRequiredRecon: z
      .boolean()
      .default(false)
      .nullable()
      .optional(),
    isCustomerReconViaEmail: z.string().nullable().optional(),
    fileNamePart: z.string().nullable().optional(),
    extension: z.string().nullable().optional(),
    responseHeaderDateFormat: z
      .string()
      .min(1, "Response Header Date Format is required")
      .max(140, "Response Header Date Format must not exceed 140 characters.")
      .nullable()
      .optional(),
    responseHeaderDateFormatRecon: z
      .string()
      .min(1, "Bank File Header Date Format is required")
      .max(140, "Bank File Header Date Format must not exceed 140 characters.")
      .nullable()
      .optional(),
    dailyReconBankRequired: z.boolean().default(false).nullable().optional(),
    monthlyReconBankRequired: z.boolean().default(false).nullable().optional(),
    weeklyReconBankRequired: z.boolean().default(false).nullable().optional(),

    removeDailyBankReconTimeStamp: z
      .boolean()
      .default(false)
      .nullable()
      .optional(),
    removeWeeklyBankReconTimeStamp: z
      .boolean()
      .default(false)
      .nullable()
      .optional(),
    removeMonthlyBankReconTimeStamp: z
      .boolean()
      .default(false)
      .nullable()
      .optional(),
    dailySelectedTime: z
      .string()
      .min(1, "Daily Selected Time is required")
      .max(140, "Daily Selected Time must not exceed 140 characters.")
      .nullable()
      .optional(),
    weeklySelectedTime: z
      .string()
      .min(1, "Weekly Selected Time is required")
      .max(140, "Weekly Selected Time must not exceed 140 characters.")
      .nullable()
      .optional(),
    monthlySelectedTime: z
      .string()
      .min(1, "Monthly Selected Time is required")
      .max(140, "Monthly Selected Time must not exceed 140 characters.")
      .nullable()
      .optional(),
    weeklySelectedValue: z
      .string()
      .min(1, "Weekly Selected Value is required")
      .max(140, "Weekly Selected Value must not exceed 140 characters.")
      .nullable()
      .optional(),
    monthlySelectedValue: z
      .string()
      .min(1, "Monthly Selected Value is required")
      .max(140, "Monthly Selected Value must not exceed 140 characters.")
      .nullable()
      .optional(),
    fileNamePartDaily: z
      .string()
      .max(140, "File Name Part Daily must not exceed 140 characters.")
      .nullable()
      .optional(),
    fileNamePartWeekly: z
      .string()
      .max(140, "File Name Part Weekly must not exceed 140 characters.")
      .nullable()
      .optional(),
    fileNamePartMonthly: z
      .string()
      .max(140, "File Name Part Monthly must not exceed 140 characters.")
      .nullable()
      .optional(),
    isDailyReconPostDate: z.boolean().default(false).nullable().optional(),
    isSeparatePaymentGroupingRequiredForDaily: z
      .boolean()
      .default(false)
      .nullable()
      .optional(),
    isSeparatePaymentGroupingRequiredForWeekly: z
      .boolean()
      .default(false)
      .nullable()
      .optional(),
    isSeparatePaymentGroupingRequiredForMonthly: z
      .boolean()
      .default(false)
      .nullable()
      .optional(),
  })
  .superRefine(
    (
      {
        dailyReconBankRequired,
        weeklyReconBankRequired,
        monthlyReconBankRequired,
        dailySelectedTime,
        weeklySelectedTime,
        monthlySelectedTime,
        weeklySelectedValue,
        monthlySelectedValue,
        fileNamePartDaily,
        fileNamePartWeekly,
        fileNamePartMonthly,
      },
      ctx
    ) => {
      if (dailyReconBankRequired && !dailySelectedTime) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter a time",
          path: ["dailySelectedTime"],
        });
      }
      if (dailyReconBankRequired && !fileNamePartDaily) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter a file name part",
          path: ["fileNamePartDaily"],
        });
      }
      if (weeklyReconBankRequired && !weeklySelectedTime) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter a time",
          path: ["weeklySelectedTime"],
        });
      }
      if (weeklyReconBankRequired && !fileNamePartWeekly) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter a file name part",
          path: ["fileNamePartWeekly"],
        });
      }
      if (weeklyReconBankRequired && !weeklySelectedValue) {
        ctx.addIssue({
          code: "custom",
          message: "Please select as day",
          path: ["weeklySelectedValue"],
        });
      }
      if (monthlyReconBankRequired && !monthlySelectedTime) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter a time",
          path: ["monthlySelectedTime"],
        });
      }

      if (monthlyReconBankRequired && !fileNamePartMonthly) {
        ctx.addIssue({
          code: "custom",
          message: "Please enter a file name part",
          path: ["fileNamePartMonthly"],
        });
      }
      if (monthlyReconBankRequired && !monthlySelectedValue) {
        ctx.addIssue({
          code: "custom",
          message: "Please select as day",
          path: ["monthlySelectedValue"],
        });
      }
    }
  );

export const AuditTempRunnerConfigFormSchema = z.object({
  modifiedBy: z.string().nullable().optional(),
  id: z.number().nullable().optional(),
  batchRunnerType: z
    .string()
    .min(1, "Batch Runner Type is required")
    .max(140, "Batch Runner Type must not exceed 140 characters.")
    .nullable(),
  paymentProvider: z
    .string()
    .min(1, "Payment Provider is required")
    .max(140, "Payment Provider must not exceed 140 characters.")
    .nullable(),
  dateFormat: z
    .string()
    .min(1, "Date Format is required")
    .max(140, "Date Format must not exceed 140 characters.")
    .nullable(),
  delimiterType: z
    .string()
    .min(1, "Delimiter Type is required")
    .max(140, "Delimiter Type must not exceed 140 characters.")
    .nullable(),
  documentFormat: z
    .string()
    .min(1, "Document Format is required")
    .max(140, "Document Format must not exceed 140 characters.")
    .nullable(),
  fileNameContainer: z
    .string()
    .min(1, "File Name Container is required")
    .max(140, "File Name Container must not exceed 140 characters.")
    .nullable(),
  firstRowHeader: z.boolean().default(false).nullable(),
  validMinimumLength: z
    .number()
    .min(0, "Valid Minimum Length must be at least 0.")
    .max(140, "Valid Minimum Length must not exceed 140.")
    .nullable(),

  skipHeaderCount: z.number().nullable().optional(),
  skipFooterCount: z.number().nullable().optional(),
});

export const UpdateRunnerConfigFormSchema = z.object({
  // Common fields
  id: z.number(),
  batchRunnerType: z
    .string()
    .min(1, "Batch Runner Type is required")
    .max(140, "Batch Runner Type must not exceed 140 characters.")
    .nullable(),
  paymentProvider: z
    .string()
    .min(1, "Payment Provider is required")
    .max(140, "Payment Provider must not exceed 140 characters.")
    .nullable(),
  dateFormat: z
    .string()
    .min(1, "Date Format is required")
    .max(140, "Date Format must not exceed 140 characters.")
    .nullable(),
  delimiterType: z
    .string()
    .min(1, "Delimiter Type is required")
    .max(140, "Delimiter Type must not exceed 140 characters.")
    .nullable(),
  documentFormat: z
    .string()
    .min(1, "Document Format is required")
    .max(140, "Document Format must not exceed 140 characters.")
    .nullable(),
  fileNameContainer: z
    .string()
    .min(1, "File Name Container is required")
    .max(140, "File Name Container must not exceed 140 characters.")
    .nullable(),
  firstRowHeader: z.boolean().default(false).nullable(),
  validMinimumLength: z
    .number()
    .min(0, "Valid Minimum Length must be at least 0.")
    .max(140, "Valid Minimum Length must not exceed 140.")
    .nullable(),
  documentFormatDenormalize: z
    .string()
    .min(1, "Document Format Denormalize is required")
    .max(140, "Document Format Denormalize must not exceed 140 characters.")
    .nullable()
    .optional(),
  // Audit/Temp Fields
  skipHeaderCount: z.number().nullable().optional(),
  skipFooterCount: z.number().nullable().optional(),
  // Payment fields
  customMapping: z.boolean().default(false).nullable().optional(),
  recordTypeLength: z.number().nullable().optional(),
  headerRecordType: z
    .string()
    .min(1, "Header Record Type is required")
    .max(140, "Header Record Type must not exceed 140 characters.")
    .nullable()
    .optional(),
  dataRecordType: z
    .string()
    .min(1, "Data Record Type is required")
    .max(140, "Data Record Type must not exceed 140 characters.")
    .nullable()
    .optional(),
  detailRecordType: z
    .string()
    .min(1, "Detail Record Type is required")
    .max(140, "Detail Record Type must not exceed 140 characters.")
    .nullable()
    .optional(),
  customRecordType: z
    .string()
    .min(1, "Custom Record Type is required")
    .max(140, "Custom Record Type must not exceed 140 characters.")
    .nullable()
    .optional(),
  footerRecordType: z
    .string()
    .min(1, "Footer Record Type is required")
    .max(140, "Footer Record Type must not exceed 140 characters.")
    .nullable()
    .optional(),
  responseRequired: z.boolean().default(false).nullable().optional(),
  responseDataDateFormat: z.string().nullable().optional(),

  fileNamePart: z
    .string()
    .min(1, "File Name Part is required")
    .max(140, "File Name Part must not exceed 140 characters.")
    .nullable()
    .optional(),
  isRecordTypeRequired: z.boolean().default(false).nullable().optional(),
  isPaymentFileInternalAlertRequired: z
    .boolean()
    .default(false)
    .nullable()
    .optional(),
  paymentFileFrequency: z.string(),
  paymentFileFrequencies: z.array(z.string()),
  paymentFileExpectedTime: z
    .string()
    .min(1, "Payment File Expected Time is required")
    .max(140, "Payment File Expected Time must not exceed 140 characters.")
    .nullable()
    .optional(),
  // Recon Fields
  isHeaderRequired: z.boolean().default(false).nullable().optional(),
  partialPayReconRequired: z.boolean().default(false).nullable().optional(),
  isConsolidatedReconRequired: z.boolean().default(false).nullable().optional(),
  ignoreCredits: z.boolean().default(false).nullable().optional(),
  isEmptyReconRequired: z.boolean().default(false).nullable().optional(),
  fileNameDateFormat: z
    .string()
    .min(1, "File Name Date Format is required")
    .max(140, "File Name Date Format must not exceed 140 characters.")
    .nullable()
    .optional(),
  lineSeparator: z
    .string()
    .min(1, "Line Separator is required")
    .max(140, "Line Separator must not exceed 140 characters.")
    .nullable()
    .optional(),
  isResponseFooterRequired: z.boolean().default(false).nullable().optional(),
  isResponseFooterRequiredRecon: z
    .boolean()
    .default(false)
    .nullable()
    .optional(),
  isCustomerReconViaEmail: z.string().nullable().optional(),
  extension: z.string().nullable().optional(),
  responseHeaderDateFormat: z
    .string()
    .min(1, "Response Header Date Format is required")
    .max(140, "Response Header Date Format must not exceed 140 characters.")
    .nullable()
    .optional(),
  responseHeaderDateFormatRecon: z
    .string()
    .min(1, "Bank File Header Date Format is required")
    .max(140, "Bank File Header Date Format must not exceed 140 characters.")
    .nullable()
    .optional(),
  dailyReconBankRequired: z.boolean().default(false).nullable().optional(),
  monthlyReconBankRequired: z.boolean().default(false).nullable().optional(),
  weeklyReconBankRequired: z.boolean().default(false).nullable().optional(),

  removeDailyBankReconTimeStamp: z
    .boolean()
    .default(false)
    .nullable()
    .optional(),
  removeWeeklyBankReconTimeStamp: z
    .boolean()
    .default(false)
    .nullable()
    .optional(),
  removeMonthlyBankReconTimeStamp: z
    .boolean()
    .default(false)
    .nullable()
    .optional(),
  dailySelectedTime: z
    .string()
    .min(1, "Daily Selected Time is required")
    .max(140, "Daily Selected Time must not exceed 140 characters.")
    .nullable()
    .optional(),
  weeklySelectedTime: z
    .string()
    .min(1, "Weekly Selected Time is required")
    .max(140, "Weekly Selected Time must not exceed 140 characters.")
    .nullable()
    .optional(),
  monthlySelectedTime: z
    .string()
    .min(1, "Monthly Selected Time is required")
    .max(140, "Monthly Selected Time must not exceed 140 characters.")
    .nullable()
    .optional(),
  weeklySelectedValue: z
    .string()
    .min(1, "Weekly Selected Value is required")
    .max(140, "Weekly Selected Value must not exceed 140 characters.")
    .nullable()
    .optional(),
  monthlySelectedValue: z
    .string()
    .min(1, "Monthly Selected Value is required")
    .max(140, "Monthly Selected Value must not exceed 140 characters.")
    .nullable()
    .optional(),
  fileNamePartDaily: z
    .string()
    .min(1, "File Name Part Daily is required")
    .max(140, "File Name Part Daily must not exceed 140 characters.")
    .nullable()
    .optional(),
  fileNamePartWeekly: z
    .string()
    .min(1, "File Name Part Weekly is required")
    .max(140, "File Name Part Weekly must not exceed 140 characters.")
    .nullable()
    .optional(),
  fileNamePartMonthly: z
    .string()
    .min(1, "File Name Part Monthly is required")
    .max(140, "File Name Part Monthly must not exceed 140 characters.")
    .nullable()
    .optional(),
  isDailyReconPostDate: z.boolean().default(false).nullable().optional(),
});

export const OnboardCustomerFormSchema = z.object({
  id: z.string(),

  // PAYMENT METHOD FORM
  paymentMethods: z.array(
    z.object({
      paymentProviderName: z.string().nullable().optional(),
      paymentMethodName: z.string().nullable().optional(),
      customerPaymentMethodName: z
        .string()
        .min(1, "Customer payment method name is required")
        .max(
          140,
          "Customer payment method name must not exceed 140 characters."
        )
        .nullable()
        .optional(),
      description: z.string().min(1).max(140).nullable().optional(),
    })
  ),

  // PARTICIPANT REGISTER FORM
  participantRegister: z.array(
    z.object({
      id: z.number(),
      facilityName: z.string().min(1).max(140).nullable().optional(),
      organizationId: z.string().min(1).max(140).nullable().optional(),
      userId: z.string().min(1).max(140).nullable().optional(),
      paymentFilenameIdentifier: z
        .string()
        .min(1)
        .max(140)
        .nullable()
        .optional(),
      paymentFileIdentifier: z.string().min(1).max(140).nullable().optional(),
      groupId: z.string().min(1).max(140).nullable().optional(),
    })
  ),

  // RUNNER CONFIGURATION FORM
  runnerConfiguration: z.object({
    runnerType: z.string().nullable().optional(), //Changing this field will change the form fields below.
    paymentProvider: z.string().nullable().optional(), // This is available for all options
    documentFormat: z.string().nullable().optional(), // This is available for all options
    dateFormat: z.string().nullable().optional(), // This is available for all options
    delimiterType: z.string().nullable().optional(), // This is available for all options
    fileNameContainer: z.string().min(1).max(140).nullable().optional(), // This is available for all options
    firstRowHeader: z.boolean().default(false).nullable().optional(), // This is available for all options
    validMinLength: z.number().min(0).max(140), // This is available for all options
    denormalizeDocumentFormat: z.string().nullable().optional(), // This is available for payment and recon
    customerMapping: z.boolean().default(false).nullable().optional(), // This is available for payment and recon
    isRecordTypeRequired: z.boolean().default(false).nullable().optional(), // This is available for payment
    responseFileRequired: z.boolean().default(false).nullable().optional(), // This is available for payment

    // These fields are not in the new API
    fileNamePart: z.string().nullable().optional(), // This is available for recon
    extension: z.string().nullable().optional(), // This is available for recon
    isHeaderRequired: z.boolean().default(false).nullable().optional(), // This is available for recon
    isEmptyReconRequired: z.boolean().default(false).nullable().optional(), // This is available for recon
    isFooterRequired: z.boolean().default(false).nullable().optional(), // This is available for recon
    partialPayReconRequired: z.boolean().default(false).nullable().optional(), // This is available for recon
    consolidatedReconRequired: z.boolean().default(false).nullable().optional(), // This is available for recon
    bankFileHeaderDateFormat: z.string().nullable().optional(), // This is available for recon
    lineSeparator: z.string().nullable().optional(), // This is available for recon
    fileNameDateFormat: z.string().nullable().optional(), // This is available for recon
    customMapping: z.boolean().default(false).nullable().optional(), // This is available for payment
    skipHeaderCount: z.number().min(0).max(140).nullable().optional(), // This is available for audit and temp
    skipFooterCount: z.number().min(0).max(140).nullable().optional(), // This is available for audit and temp
  }),
});

// export a schema that is combination of CustomerFormSchema and OnboardCustomerFormSchema
export const OnboardSchema = CustomerFormSchema.merge(
  OnboardCustomerFormSchema
);

export const EmailConfigurationFormSchema = z.object({
  emailConfigId: z.string().nullable().optional(),
  notificationTypeId: z.string(),
  alertSend: z.boolean().default(false),
  participantId: z.string(),
  recipientEmails: z.array(z.string().email("Invalid email address")),
});

export type EmailConfigurationFormDTO = z.infer<
  typeof EmailConfigurationFormSchema
>;

export type CustomerFormDTO = z.infer<typeof CustomerFormSchema>;
export type CustomerModuleFormDTO = z.infer<typeof CustomerModuleFormSchema>;
export type AddCustomerFormDTO = z.infer<typeof AddCustomerFormSchema>;
export type OnboardCustomerFormDTO = z.infer<typeof OnboardCustomerFormSchema>;
export type OnboardDTO = z.infer<typeof OnboardSchema>;
export type AddPaymentMethodFormDTO = z.infer<
  typeof AddPaymentMethodFormSchema
>;
export type UpdatePaymentMethodFormDTO = z.infer<
  typeof UpdatePaymentMethodFormSchema
>;
export type AddParticipantRegisterFormDTO = z.infer<
  typeof AddParticipantRegisterFormSchema
>;
export type UpdateParticipantRegisterFormDTO = z.infer<
  typeof UpdateParticipantRegisterFormSchema
>;
export type RunnerConfigFormDTO = z.infer<typeof RunnerConfigFormSchema>;
export type PaymentRunnerConfigFormDTO = z.infer<
  typeof PaymentRunnerConfigFormSchema
>;

export const customerManagementAdminTableColumnsSchema = z.object({
  id: z.boolean(),
  buyerName: z.boolean(),
  status: z.boolean(),
  billingType: z.boolean(),
  paymentType: z.boolean(),
  settlementTerms: z.boolean(),
  paymentMethod: z.boolean(),
  cycleDate: z.boolean(),
  invoiceDay: z.boolean(),
  contactName: z.boolean(),
  address: z.boolean(),
  phone: z.boolean(),
  email: z.boolean(),
  bill: z.boolean(),
  mccTemplateOptions: z.boolean(),
  reportingTool: z.boolean(),
  organizationId: z.boolean(),
  accountNumberMasking: z.boolean(),
  responseFileDataMasking: z.boolean(),
  connectivityMethod: z.boolean(),
  pidmapping: z.boolean(),
  multiselectpidmapping: z.boolean(),
});
export type CustomerManagementAdminTableColumnsType = z.infer<
  typeof customerManagementAdminTableColumnsSchema
>;

export const customerModuleManagementAdminTableColumnsSchema =
  customerManagementAdminTableColumnsSchema.pick({
    id: true,
    buyerName: true,
  });

export type CustomerModuleManagementAdminTableColumnsType = z.infer<
  typeof customerModuleManagementAdminTableColumnsSchema
>;

export const SFTPConfigurationFormSchema = z.object({
  ipAddress: z.string().ip({version: "v4"}),
  userName: z.string().min(1).max(140),
  portNumber: z.number().min(1000).max(9999),
  password: z.string().min(1).max(140),
  fileNameDateFormatRequired: z.boolean().default(false),
  fileArchivingRequired: z.boolean().default(false),
  encryptionRequired: z.boolean().default(false),
  fileTransmissionTypes: z.array(z.string()),
  filesForEncryption: z.array(z.string()),
  publicKey: z.array(z.instanceof(File)).optional(),
});
export type SFTPConfigurationFormDTO = z.infer<
  typeof SFTPConfigurationFormSchema
>;
