import {create} from "zustand";

import {type CustomerType} from "@/modules/customers";

// This store contains all customers regardless of module
// Defining the shape of the state object for the CustomerStore
// In this case, the state will consist of an array of Customer objects
interface CustomersStoreState {
  storeCustomer: CustomerType | null;
}

// Defining the actions that can be performed on the CustomerStore state
// In this case, there's only one action, updatestoreCustomer, which takes an array of Customer objects
interface CustomerStoreActions {
  updateStoreCustomer: (
    storeCustomer: CustomersStoreState["storeCustomer"]
  ) => void;
}

// Creating the Zustand store using the create function
// The store is named CustomerStore, and it uses the types defined above for its state and actions
// The state begins with an empty storeCustomer array
// The updatestoreCustomer action uses Zustand's set function to update the state
export const useCustomerStore = create<
  CustomersStoreState & CustomerStoreActions
>((set) => ({
  // Initial state of the store. An empty array of storeCustomer.
  storeCustomer: null,

  // Action to update the storeCustomer array in the store.
  // This function takes a new storeCustomer array and uses the set function to update the state.
  updateStoreCustomer: (storeCustomer) => {
    set(() => ({storeCustomer}));
  },
}));
