import {useMemo} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {useNavigate} from "@tanstack/react-router";
import {
  HelpCircleIcon,
  MoveLeftIcon,
  MoveRightIcon,
  SaveIcon,
} from "lucide-react";
import {useForm, useFormContext} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {Card, CardContent} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {Input} from "@/components/ui/input";
import {Spinner} from "@/components/ui/spinner";
import {Heading2} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {
  addCustomerParticipantRegisterFn,
  AddParticipantRegisterFormSchema,
  CustomerOnboardingTabs,
  ParticipantRegisterDataTable,
  participantRegisterTableColumns,
} from "@/modules/admin";
import {useKeyCloakInstanceStore} from "@/modules/auth";
import {useGetAllFacilitiesByCustomer} from "@/modules/facilities";

import {ModuleNames} from "@/utils/constants";

/**
 * Custom hook for fetching and memoizing facilities.
 * @param externalId - Optional participant ID to filter facilities.
 * @returns A memoized array of facility options.
 */
export function useRegisteredParticipants(externalId?: string) {
  const validParticipantIds = [externalId].filter(Boolean) as string[];
  const {data} = useGetAllFacilitiesByCustomer(validParticipantIds);
  return useMemo(() => data?.content ?? [], [data]);
}

/**
 * ParticipantRegisterFormProps Interface
 *
 * @interface ParticipantRegisterFormProps
 * @property {number} externalId - Gets the customer ID.
 * @property {string} module -  Gets the module name
 */
interface ParticipantRegisterFormProps {
  externalId: string;
  module: string;
}

/**
 * ParticipantRegisterForm Component
 *
 * This component renders the ParticipantRegisterForm form and handles its behavior.
 *
 * @param {ParticipantRegisterFormProps} props - Properties passed to the component
 * @returns {JSX.Element} - Rendered component
 */

export function ParticipantRegisterForm({
  externalId,
  module,
}: ParticipantRegisterFormProps) {
  const addParticipantRegisterForm = useForm<
    z.infer<typeof AddParticipantRegisterFormSchema>
  >({
    mode: "onChange",
    resolver: zodResolver(AddParticipantRegisterFormSchema),
  });

  const navigate = useNavigate();
  const params = {
    externalId: externalId.toString(),
    module,
  };

  // Table data
  const allParticipantRegistrations = useRegisteredParticipants(
    externalId.toString()
  );

  const addParticipantRegisterMutation = useAddParticipantRegister(externalId);

  const validParticipantIds = [externalId].filter(Boolean);
  const ParticipantRegisterQuery =
    useGetAllFacilitiesByCustomer(validParticipantIds);
  const ParticipantRegisterData = ParticipantRegisterQuery.data?.content || [];

  const isBankAccountNumberUnique = (bankAccountNumber: string) => {
    return !allParticipantRegistrations.some(
      (participant) => participant.bankAccountNumber === bankAccountNumber
    );
  };
  function handleAdd(data: z.infer<typeof AddParticipantRegisterFormSchema>) {
    const bankAccountNumber = data.bankAccountNumber;

    if (bankAccountNumber) {
      if (isBankAccountNumberUnique(bankAccountNumber)) {
        addParticipantRegisterMutation.mutate(data);
      } else {
        toast({
          variant: "destructive",
          title: "Bank Account Number must be unique!",
        });
      }
    } else {
      addParticipantRegisterMutation.mutate(data);
    }
  }

  // const {
  //   formState: {errors},
  // } = addParticipantRegisterForm;

  // console.log("errors, ", errors);

  return (
    <section>
      <div className="mb-2 flex w-full flex-col items-start justify-between gap-2 lg:flex-row lg:items-center">
        <Heading2 className="flex gap-2">Participant Register</Heading2>
        <CustomerOnboardingTabs />
      </div>
      <Card>
        <CardContent>
          <Form {...addParticipantRegisterForm}>
            <form onSubmit={addParticipantRegisterForm.handleSubmit(handleAdd)}>
              <div className="mb-5 mt-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
                <FacilityNameFormField
                  isMutating={addParticipantRegisterMutation.isPending}
                />
                <OrganizationIdFormField
                  isMutating={addParticipantRegisterMutation.isPending}
                />
                <UserIdFormField
                  isMutating={addParticipantRegisterMutation.isPending}
                />
                <GroupIdFormField
                  isMutating={addParticipantRegisterMutation.isPending}
                />
                <PaymentFilenameIdentifierFormField
                  isMutating={addParticipantRegisterMutation.isPending}
                />
                <PaymentFileIdentifierFormField
                  isMutating={addParticipantRegisterMutation.isPending}
                />
                <BankAccountNumberFormField
                  isMutating={addParticipantRegisterMutation.isPending}
                />
              </div>
              <div className="flex items-center justify-end space-x-2 p-2">
                <Button
                  type="submit"
                  className="gap-2"
                  disabled={addParticipantRegisterMutation.isPending}
                  aria-disabled={addParticipantRegisterMutation.isPending}
                  variant="affirmative"
                >
                  {addParticipantRegisterMutation.isPending ? (
                    <Spinner size="xs" />
                  ) : (
                    ""
                  )}
                  Save Participant
                  <SaveIcon className="size-4" />
                </Button>
              </div>
            </form>
          </Form>
          <ParticipantRegisterDataTable
            columns={participantRegisterTableColumns}
            data={ParticipantRegisterData}
            isSuccess={ParticipantRegisterQuery.isSuccess}
            isPending={ParticipantRegisterQuery.isPending}
            isError={ParticipantRegisterQuery.isError}
          />

          <div className="flex items-center justify-end space-x-2 p-2">
            <Button
              type="button"
              variant="secondary"
              onClick={() => {
                module === ModuleNames.imremit &&
                  void navigate({
                    to: "/app/admin/customer-management/imremit/$externalId/onboarding/payment-method",
                    params,
                    replace: true,
                  });
              }}
            >
              <span className="sr-only">Previous page</span>
              <MoveLeftIcon className="mr-2" />
              Previous
            </Button>

            <Button
              disabled={allParticipantRegistrations.length == 0}
              type="button"
              onClick={() => {
                module === ModuleNames.imremit &&
                  void navigate({
                    to: "/app/admin/customer-management/imremit/$externalId/onboarding/runner-configuration",
                    params,
                    replace: true,
                  });
              }}
            >
              Next
              <MoveRightIcon className="ml-2" />
              <span className="sr-only">Next page</span>
            </Button>
          </div>
        </CardContent>
      </Card>
    </section>
  );
}

/**
 * Custom hook for performing the 'Add Payment Method' mutation.
 * This hook abstracts the mutation logic and side effects like toast notifications.
 *
 * @param {number | undefined} externalId - The ID of the customer.
 * @returns A mutation object with methods to trigger the mutation.
 */
function useAddParticipantRegister(externalId: string) {
  const storeKeyCloakInstance =
    useKeyCloakInstanceStore().storeKeyCloakInstance;

  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );

  const addParticipantRegisterForm = useForm<
    z.infer<typeof AddParticipantRegisterFormSchema>
  >({
    defaultValues: {
      modifiedBy: parsedUserInfo?.name as string,
    },
    mode: "onChange",
    resolver: zodResolver(AddParticipantRegisterFormSchema),
  });

  return useMutation({
    mutationFn: (data: z.infer<typeof AddParticipantRegisterFormSchema>) => {
      // Perform the mutation using the addParticipantRegisterFn function
      return addCustomerParticipantRegisterFn(data, externalId);
    },
    onSuccess: (response) => {
      // Handle successful mutation
      console.log("addParticipantRegisterResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Participant registered successfully",
      });

      addParticipantRegisterForm.reset({});
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to register participant",
      });
    },
  });
}

// Form fields
export function FacilityNameFormField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();

  return (
    <FormField
      name="facilityName"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="facilityName" showMandatoryAsterisk>
            Facility Name
          </FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="text"
              placeholder="Enter the facility name..."
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              {...register("facilityName")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function OrganizationIdFormField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="orgId"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="orgId" showMandatoryAsterisk>
            Organization ID
          </FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="text"
              placeholder="Enter the organization id..."
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              {...register("orgId")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function UserIdFormField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="userId"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="userId" showMandatoryAsterisk>
            User ID
          </FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="text"
              placeholder="Enter the user id..."
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              {...register("userId")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function GroupIdFormField({isMutating}: {isMutating: boolean}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="groupId"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="groupId">Group ID</FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="text"
              placeholder="Enter the group id..."
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              {...register("groupId")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function BankAccountNumberFormField({
  isMutating,
}: {
  isMutating: boolean;
}) {
  const {register} = useFormContext();
  return (
    <FormField
      name="bankAccountNumber"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="bankAccountNumber">Bank Account #</FormLabel>
          <FormControl>
            <Input
              disabled={isMutating}
              aria-disabled={isMutating}
              type="text"
              placeholder="Enter the bank account #..."
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              {...register("bankAccountNumber")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function PaymentFilenameIdentifierFormField({
  isMutating,
}: {
  isMutating: boolean;
}) {
  const {register, watch} = useFormContext();
  const futureUse2StringStatus = (watch("futureUse2") as string) ? true : false;

  return (
    <FormField
      name="futureUse1"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="futureUse1">
            Payment Filename Identifier
            <HoverCard openDelay={150} closeDelay={300}>
              <HoverCardTrigger className="align-middle" asChild>
                <Button variant="link" disabled>
                  <HelpCircleIcon className="size-4 text-theme" />
                </Button>
              </HoverCardTrigger>
              <HoverCardContent className="w-80">
                <div className="flex justify-between space-x-4">
                  <div className="space-y-1">
                    <h4 className="text-sm font-semibold">
                      Payment Filename Identifier
                    </h4>
                    <div className="items-left flex pt-2">
                      <span className="text-xs text-muted-foreground">
                        Used to capture any keywords on the payment filename IF
                        the Customer decides to send 1 payment file per payment
                        group or facility/location.
                      </span>
                    </div>
                  </div>
                </div>
              </HoverCardContent>
            </HoverCard>
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating || futureUse2StringStatus}
              aria-disabled={isMutating}
              placeholder="Enter the payment filename identifier..."
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              {...register("futureUse1")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export function PaymentFileIdentifierFormField({
  isMutating,
}: {
  isMutating: boolean;
}) {
  const {register, watch} = useFormContext();
  const futureUse1StringStatus = (watch("futureUse1") as string) ? true : false;
  return (
    <FormField
      name="futureUse2"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="futureUse2">
            Payment File Identifier
            <HoverCard openDelay={150} closeDelay={300}>
              <HoverCardTrigger className="align-middle" asChild>
                <Button variant="link" disabled>
                  <HelpCircleIcon className="size-4 text-theme" />
                </Button>
              </HoverCardTrigger>
              <HoverCardContent className="w-80">
                <div className="flex justify-between space-x-4">
                  <div className="space-y-1">
                    <h4 className="text-sm font-semibold">
                      Payment Filename Identifier
                    </h4>
                    <div className="items-left flex pt-2">
                      <span className="text-xs text-muted-foreground">
                        Used to capture any keywords with in the consolidated
                        payment file to identify the payment group or
                        facility/location (assuming there is more than 1).
                      </span>
                    </div>
                  </div>
                </div>
              </HoverCardContent>
            </HoverCard>
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating || futureUse1StringStatus}
              aria-disabled={isMutating}
              placeholder="Enter the payment file identifier..."
              onKeyDown={(e) => {
                e.stopPropagation();
              }}
              {...register("futureUse2")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
