/**
 * Component for uploading and importing supplier scripts.
 * @module SupplierScriptUploadDropzone
 * @component
 */
import {useCallback, useState} from "react";

import {DownloadCloudIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Card, CardContent, CardHeader, CardTitle} from "@/components/ui/card";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogTrigger,
} from "@/components/ui/dialog";
import {Separator} from "@/components/ui/separator";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {Paragraph} from "@/components/ui/typography";
import {UploadDropzone} from "@/components/ui/upload";

import {
  dateFormats,
  fieldTypes,
  findElementByValues,
  tableColumnNamesAddress,
  tableColumnNamesCustomerProfile,
  tableColumnNamesInvoiceDetail,
  tableColumnNamesInvoiceDetailAll,
  tableColumnNamesLoginCredential,
  tableColumnNamesPaymentDetail,
  tableColumnNamesPaymentRequest,
  tableColumnNamesPaymentResponse,
  tableColumnNamesSupplierDetail,
  tableNames,
  timerMethods,
} from "@/modules/imremit";

/**
 * Props for SupplierScriptUploadDropzone component.
 * @typedef {Object} SupplierScriptUploadDropzoneProps
 * @property {Function} onDrop - Callback function invoked when data is dropped.
 * @property {string} [accept] - File formats accepted by the dropzone.
 */

/**
 * Component for uploading and importing supplier scripts.
 * @param {SupplierScriptUploadDropzoneProps} props - Component props.
 * @returns {JSX.Element} React component.
 */
interface SupplierScriptUploadDropzoneProps {
  onDrop: (data: string[][], headers: string[]) => void;
  accept?: string | undefined;
}

export const SupplierScriptUploadDropzone: React.FC<
  SupplierScriptUploadDropzoneProps
> = ({onDrop}) => {
  const [csvData, setCsvData] = useState<string[][]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [isHeadersMatching, setIsHeadersMatching] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]); // Array to store errors

  /**
   * Handles the dropping of files into the dropzone.
   * @param {File[]} acceptedFiles - Array of accepted files.
   */
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file: File) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          if (e.target) {
            const content = e.target.result as string;
            const rows = content
              .split("\n")
              .map((row) =>
                row.split(",").map((cell) => cell.replace(/"/g, "").trim())
              );
            const fileHeaders = rows[0];

            const newErrors: string[] = [];

            // Check if the number of columns in each row matches the number of headers
            rows.slice(1).every((row) => {
              if (row.length !== fileHeaders.length) {
                newErrors.push(
                  "Unable to map, number of columns in some rows don't match the number of headers."
                );

                return false;
              }

              return true;
            });

            // Additional checks
            const mandatoryHeaders = [
              ["Seq. No", "SEQUENCEID"],
              ["Page Name", "PAGECATEGORY"],
              ["Field Name", "FIELDNAME"],
              ["X-Path", "FIELDVALUE"],
              ["Field Type", "FIELDTYPE"],
              ["Table Name", "TABLENAME"],
              ["Table Col. Name", "TABLECOLUMNNAME"],
              ["Position", "POSITION"],
              ["Default Value", "DROPDOWNVALUE"],
              ["Separator", "SEPARATOR"],
              ["Date Format", "DATEFORMAT"],
              ["Login Sequence", "SEQUENCEIDENTIFIER"],
              ["Timer", "TIMER"],
              ["Js Check", "JSCHECK"],
              ["Find Element By", "FINDELEMENTBY"],
            ];

            const missingHeaders: string[] = [];
            mandatoryHeaders.forEach((headerPair) => {
              const foundHeader = headerPair.find((header) =>
                fileHeaders.includes(header)
              );
              if (!foundHeader) {
                missingHeaders.push(headerPair.join(" or "));
              }
            });

            if (missingHeaders.length > 0) {
              newErrors.push(
                `Missing mandatory headers: ${missingHeaders.join(", ")}`
              );
            }
            // Function to validate column based on constants
            const validateColumn = (
              rows: string[][],
              fileHeaders: string[],
              header: string,
              constants: {value: string; label: string}[]
            ) => {
              const headerIndex = fileHeaders.indexOf(header);
              if (headerIndex !== -1) {
                rows.slice(1).forEach((row) => {
                  let value = row[headerIndex].trim();
                  if (value === "-") {
                    value = ""; // Convert "-" to an empty string
                  }
                  if (value !== "") {
                    if (
                      header === "Table Name" ||
                      header === "TABLENAME" ||
                      header === "FINDELEMENTBY" ||
                      header === "Find Element By"
                    ) {
                      value = value.toLowerCase(); // Transform value to lowercase
                    }
                    const isValidValue = constants.some(
                      (constant: {value: string}) =>
                        constant.value.toLowerCase() === value.toLowerCase() ||
                        constant.value === value
                    );
                    if (!isValidValue) {
                      newErrors.push(
                        `Invalid value in '${header}' column: ${value}`
                      );
                    }
                  }
                });
              }
            };

            const tableColumnNames = [
              ...Object.values(tableColumnNamesPaymentDetail),
              ...Object.values(tableColumnNamesPaymentRequest),
              ...Object.values(tableColumnNamesPaymentResponse),
              ...Object.values(tableColumnNamesSupplierDetail),
              ...Object.values(tableColumnNamesCustomerProfile),
              ...Object.values(tableColumnNamesInvoiceDetail),
              ...Object.values(tableColumnNamesInvoiceDetailAll),
              ...Object.values(tableColumnNamesAddress),
              ...Object.values(tableColumnNamesLoginCredential),
            ];

            // Validation for multiple headers
            const headersToValidate = [
              {
                name:
                  "Table Name" in {name: "Table Name"}
                    ? "Table Name"
                    : "TABLENAME",
                constants: tableNames,
              },
              {
                name:
                  "Field Type" in {name: "Field Type"}
                    ? "Field Type"
                    : "FIELDTYPE",
                constants: fieldTypes,
              },
              {
                name:
                  "Date Format" in {name: "Date Format"}
                    ? "Date Format"
                    : "DATEFORMAT",
                constants: dateFormats,
              },
              {
                name: "Timer" in {name: "Timer"} ? "Timer" : "TIMER",
                constants: timerMethods,
              },
              {
                name:
                  "Find Element By" in {name: "Find Element By"}
                    ? "Find Element By"
                    : "FINDELEMENTBY",
                constants: findElementByValues,
              },
              {
                name:
                  "Table Col. Name" in {name: "Table Col. Name"}
                    ? "Table Col. Name"
                    : "TABLECOLUMNNAME",
                constants: tableColumnNames,
              },
            ];

            // Perform validation for each header
            headersToValidate.forEach(({name, constants}) => {
              validateColumn(rows, fileHeaders, name, constants);
            });

            if (newErrors.length > 0) {
              setErrors(newErrors);
              return;
            }

            onDrop(rows, fileHeaders);
            handleCancelClick(); // Clear data after successful drop
            setCsvData(rows);
            setHeaders(fileHeaders);
            setIsHeadersMatching(true);
            setErrors([]); // Clear errors if file is valid
          }
        };

        reader.readAsText(file);
      });
    },
    [onDrop]
  );

  const handleCancelClick = () => {
    setCsvData([]);
    setHeaders([]);
    setIsHeadersMatching(false);
    setErrors([]); // Clear errors when canceling
  };

  const headerAlternatives = {
    "Seq. No": ["Seq. No", "SEQUENCEID"],
    "Page Name": ["Page Name", "PAGECATEGORY"],
    "Field Name": ["Field Name", "FIELDNAME"],
    "X-Path": ["X-Path", "FIELDVALUE"],
    "Field Type": ["Field Type", "FIELDTYPE"],
    "Table Name": ["Table Name", "TABLENAME"],
    "Table Col. Name": ["Table Col. Name", "TABLECOLUMNNAME"],
    Position: ["Position", "POSITION"],
    "Default Value": ["Default Value", "DROPDOWNVALUE"],
    Separator: ["Separator", "SEPARATOR"],
    "Date Format": ["Date Format", "DATEFORMAT"],
    "Login Sequence": ["Login Sequence", "SEQUENCEIDENTIFIER"],
    Timer: ["Timer", "TIMER"],
    "Js Check": ["Js Check", "JSCHECK"],
    "Find Element By": ["Find Element By", "FINDELEMENTBY"],
  };

  const isExpectedHeader = (header: string) =>
    Object.values(headerAlternatives).some((alternatives) =>
      alternatives.includes(header)
    );

  return (
    <>
      <Dialog>
        <DialogTrigger asChild>
          <Button
            type="button"
            variant={"default"}
            className="ml-auto whitespace-nowrap"
          >
            <DownloadCloudIcon className="mr-2" />
            Import Script
          </Button>
        </DialogTrigger>
        <DialogContent className="max-h-[600px] overflow-y-auto p-8 sm:max-w-[1000px] md:max-w-[1200px] lg:max-w-[1400px] xl:max-w-[1400px]">
          <Card>
            <CardHeader>
              <CardTitle>Import Supplier Script</CardTitle>
            </CardHeader>
            <CardContent>
              <Paragraph className="text-bold">
                Headers required (Follow this sequence): Seq. No, Page Name,
                Field Name, X-Path, Field Type, Table Name, Table Col. Name,
                Position, Default Value, Separator, Date Format, Login Sequence,
                Timer, Js Check and Find Element By.
              </Paragraph>
              <Paragraph className="text-bold pt-2">
                Please ensure your file is in CSV format.
              </Paragraph>
              <Separator className="mb-4" />
              <UploadDropzone
                maxFiles={1}
                accept={{
                  ".csv": ["text/csv", ".csv"],
                }}
                onDrop={handleDrop}
              />
            </CardContent>
          </Card>

          <div className="text-red-500">
            {errors.map((error, index) => (
              <div key={index}>{error}</div>
            ))}
          </div>

          {errors.length < 1 && isHeadersMatching ? (
            <Table>
              <TableHeader>
                <Paragraph className="text-bold bg-background text-xl">
                  Preview Uploaded Scripts
                </Paragraph>
              </TableHeader>
              <TableHead className="p-0">
                <TableRow>
                  {headers.map((header, index) =>
                    isExpectedHeader(header) ? (
                      <TableCell key={index}>{header}</TableCell>
                    ) : null
                  )}
                </TableRow>
                <TableBody className="bg-root text-black">
                  {csvData.slice(1).map((row, rowIndex) => (
                    // Render only the cells associated with the expected headers
                    <TableRow key={rowIndex}>
                      {row
                        .filter(
                          (_, index) =>
                            headers[index] === "Seq. No" ||
                            headers[index] === "Page Name" ||
                            headers[index] === "Field Name" ||
                            headers[index] === "X-Path" ||
                            headers[index] === "Field Type" ||
                            headers[index] === "Table Name" ||
                            headers[index] === "Table Col. Name" ||
                            headers[index] === "Position" ||
                            headers[index] === "Default Value" ||
                            headers[index] === "Separator" ||
                            headers[index] === "Date Format" ||
                            headers[index] === "Login Sequence" ||
                            headers[index] === "Timer" ||
                            headers[index] === "Js Check" ||
                            headers[index] === "Find Element By" ||
                            headers[index] === "SEQUENCEID" ||
                            headers[index] === "PAGECATEGORY" ||
                            headers[index] === "FIELDNAME" ||
                            headers[index] === "FIELDVALUE" ||
                            headers[index] === "FIELDTYPE" ||
                            headers[index] === "TABLENAME" ||
                            headers[index] === "TABLECOLUMNNAME" ||
                            headers[index] === "POSITION" ||
                            headers[index] === "DROPDOWNVALUE" ||
                            headers[index] === "SEPARATOR" ||
                            headers[index] === "DATEFORMAT" ||
                            headers[index] === "SEQUENCEIDENTIFIER" ||
                            headers[index] === "TIMER" ||
                            headers[index] === "JSCHECK" ||
                            headers[index] === "FINDELEMENTBY"
                        )
                        .map((cell, cellIndex) => (
                          <TableCell key={cellIndex}>{cell.trim()}</TableCell>
                        ))}
                    </TableRow>
                  ))}
                </TableBody>
              </TableHead>
            </Table>
          ) : null}

          <DialogClose>
            <div className="flex justify-end space-x-4">
              <Button variant="secondary" onClick={handleCancelClick}>
                Close
              </Button>
            </div>
          </DialogClose>
        </DialogContent>
      </Dialog>
    </>
  );
};
