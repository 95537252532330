import {CheckCheckIcon, ClockIcon} from "lucide-react";

import {Badge} from "@/components/ui/badge";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {Separator} from "@/components/ui/separator";
import {Skeleton} from "@/components/ui/skeleton";
import {Heading4, Paragraph} from "@/components/ui/typography";

import {useGetCustomerOnboardinStatus} from "@/modules/customers";

import {FallbackMessages} from "@/utils/constants";

interface CustomerOnboardingStatusTableProps {
  id: string;
}

export function CustomerOnboardingStatusTable({
  id,
}: CustomerOnboardingStatusTableProps) {
  // Fetch Customer Onboarding Statuses
  const customerOnboardingStatusQuery = useGetCustomerOnboardinStatus(id);
  const customerOnboardingStatusData =
    customerOnboardingStatusQuery.data?.content;
  const customerOnboardingStatusIsPending =
    customerOnboardingStatusQuery.isFetching;

  return (
    <HoverCard openDelay={150} closeDelay={300}>
      <HoverCardTrigger>
        <Paragraph className="cursor-pointer font-semibold underline hover:text-theme">
          {customerOnboardingStatusIsPending ? (
            <Skeleton className="h-2 w-[150px]" />
          ) : customerOnboardingStatusData?.modules ? (
            customerOnboardingStatusData.modules
              .map((module) => module.moduleName)
              .join(", ")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </Paragraph>
      </HoverCardTrigger>
      <HoverCardContent className="w-full">
        <div className="flex justify-between p-4">
          <div className="space-y-2">
            <Heading4>Customer Onboarding Status</Heading4>
            <Separator className="my-4" />
            {!customerOnboardingStatusIsPending &&
              (customerOnboardingStatusData?.modules ? (
                customerOnboardingStatusData.modules.map((module) => (
                  <div
                    key={module.moduleId}
                    className="flex justify-between text-sm"
                  >
                    <Paragraph className="pt-2 font-medium">
                      {module.moduleName}
                    </Paragraph>
                    <Paragraph className="font-bold">
                      {module.moduleOnboarded ? (
                        <Badge variant="success">
                          <span className="mr-2">
                            <CheckCheckIcon />
                          </span>
                          Active
                        </Badge>
                      ) : (
                        <Badge variant="secondary">
                          <span className="mr-2">
                            <ClockIcon />
                          </span>
                          Pending
                        </Badge>
                      )}
                    </Paragraph>
                  </div>
                ))
              ) : (
                <Paragraph>No data available</Paragraph>
              ))}
          </div>
        </div>
      </HoverCardContent>
    </HoverCard>
  );
}
