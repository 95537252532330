import {Fragment, useMemo} from "react";

import {Link} from "@tanstack/react-router";

import {buttonVariants} from "@/components/ui/button";
import {Separator} from "@/components/ui/separator";
import {Small} from "@/components/ui/typography";

import {cn} from "@/lib/utils";

export const footerMockData: {
  name: string;
  label: string;
  to: string;
}[] = [
  {
    name: "about",
    label: "About Iteration Matrix",
    to: "/terms-of-service",
  },
  // {
  //   name: "contacts",
  //   label: "Customer AP Contacts",
  //   to: "https://www.iterationmatrix.com/terms-of-service",
  // },
  {
    name: "terms-of-service",
    label: "Terms of Service",
    to: "/terms-of-service",
  },
  {
    name: "privacy-policy",
    label: "Privacy Policy",
    to: "/terms-of-service",
  },
  {
    name: "help",
    label: "Help",
    to: "/app/help",
  },
  {
    name: "version",
    label: `Version ${VITE_APP_VERSION}`,
    to: "",
  },
];

// This type is used to define the props for the FooterLinks component.
interface FooterLinksProps {
  elements: JSX.Element[];
}

// This React component is used to display footer links.
// It takes an array of JSX Elements as a prop and maps each element to a div.
// If the element isn't the last in the array, a separator is placed after it.
export function FooterLinks({elements}: FooterLinksProps) {
  return (
    <div className="grid grid-cols-2 gap-x-8 gap-y-0 md:gap-x-12 md:px-4 2xl:ml-4 2xl:flex 2xl:h-6 2xl:flex-row 2xl:items-center 2xl:gap-0 2xl:p-0">
      {elements.map((element, index) => (
        <Fragment key={index}>
          {/* Each element is wrapped in a div */}
          <div className="w-full 2xl:w-auto">{element}</div>
          {/* If this is not the last element, a separator is added after it */}
          {index < elements.length - 1 && (
            <div className="hidden 2xl:flex 2xl:h-full 2xl:w-full 2xl:items-center 2xl:justify-center">
              <Separator className="mx-3 bg-accent" orientation="vertical" />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
}

// This is the main Footer component.
// It generates footer links from the footerMockData and renders them along with copyright information and certificates.
export function Footer() {
  // Memoize the current year to prevent unnecessary re-renders
  const currentYear = useMemo(() => new Date().getFullYear(), []);
  // Generate the footer elements (links) from the footerMockData
  const footerElements = useMemo(
    () =>
      footerMockData.map(({name, label, to}) => {
        return (
          // <a
          //   key={name}
          //   href={to}
          //   className={cn(
          //     buttonVariants(),
          //     "whitespace-nowrap p-0 text-root hover:text-theme hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2"
          //   )}
          // >
          //   {label}
          // </a>
          <Link
            key={name}
            to={to}
            className={cn(
              buttonVariants({variant: "link"}),
              "whitespace-nowrap p-0 text-root hover:text-theme hover:underline focus:outline-none focus:ring-2 focus:ring-offset-2"
            )}
          >
            {label}
          </Link>
        );
      }),
    []
  );

  // The footer contains the generated footer links, copyright information, and certificates.
  return (
    <footer className="flex min-h-[5rem] w-full flex-col justify-center gap-4 overflow-auto bg-foreground p-4 shadow-md md:flex-row md:items-center md:justify-between md:gap-12 md:pl-20">
      {/* Render the footer links */}
      <FooterLinks elements={footerElements} />
      {/* Display copyright information and certificates */}
      <div className="flex items-center justify-end space-x-4 md:justify-start md:px-2">
        <Small className="text-xs text-zinc-200">
          Copyright ©{currentYear} Iteration Matrix Services Inc.
        </Small>

        {/* <button
          className="m-0 flex items-center justify-center bg-transparent p-0 focus:outline-none focus:ring-2 focus:ring-zinc-400 focus:ring-offset-2"
          onClick={() => {
            window.open(
              "https://www.sisainfosec.com/certificate.php?number=95422797940507756214&type=fsaq",
              "SISA Certificate",
              "width=685,height=670"
            );
          }}
        >
          <img
            src="/images/certs/sisa-new.png"
            alt="SISA Information Security Certificate"
            className="min-w-[7rem] max-w-[8rem] hover:cursor-pointer"
            aria-label="SISA Information Security Certificate"
          />
        </button> */}
        <button
          className="m-0 flex items-center justify-center bg-transparent p-0 focus:outline-none focus:ring-2 focus:ring-zinc-400 focus:ring-offset-2"
          onClick={() => {
            window.open(
              "https://seal.godaddy.com/verifySeal?sealID=1IIfqenkhB921Uk67EfKArsRBBJ0zJKQw4muzUktjnNV6hLG02V3DBBJyLNu",
              "GoDaddy Web Server Certificate",
              "width=685,height=670"
            );
          }}
        >
          <img
            src="/images/certs/godaddy-siteseal.png"
            alt="GoDaddy Web Server Certificate"
            className="min-w-[10rem] max-w-[12rem] hover:cursor-pointer"
            aria-label="GoDaddy Web Server Certificate"
          />
        </button>
      </div>
    </footer>
  );
}
