import {z} from "zod";

// Define the Zod schemas
export const SRSettingSchema = z.object({
  sr_settings_id: z.string(),
  sr_settings_name: z.string(),
  sr_settings_description: z.string().optional(),
  created_timestamp: z.date().nullable().optional(),
  updated_timestamp: z.date().nullable().optional(),
});

export const SRSettingModificationSchema = z.object({
  sr_settings_modification_id: z.string(),
  sr_settings_id: z.number(),
  sr_settings_name: z.string(),
  sr_settings_modification_value: z.string().optional().nullable(),
  sr_settings_leading_trailing_type: z.string().optional().nullable(),
  created_timestamp: z.date().nullable().optional(),
  updated_timestamp: z.date().nullable().optional(),
  buyer_external_id: z.string(),
  modified_by: z.string(),
  supplier_id: z.string(),
});

export const UpdateCharacterSettingsBodySchema =
  SRSettingModificationSchema.omit({
    buyer_external_id: true,
    supplier_id: true,
    created_timestamp: true,
    updated_timestamp: true,
    sr_settings_modification_id: true,
  });

export const UpdateBooleanSettingsBodySchema = SRSettingModificationSchema.omit(
  {
    sr_settings_id: true,
    sr_settings_leading_trailing_type: true,
    buyer_external_id: true,
    supplier_id: true,
    created_timestamp: true,
    updated_timestamp: true,
    sr_settings_modification_id: true,
  }
);

export const CreateSettingBodySchema = SRSettingModificationSchema.omit({
  created_timestamp: true,
  updated_timestamp: true,
  sr_settings_modification_id: true,
});

export const SRSettingChangesSchema = z.object({
  ignore_leading_trailing: z.string(),
  ignore_leading_trailing_character: z.string(),
  ignore_special_characters: z.string(),
  credit_pair_logic: z.string(),
  statement_invoice_statement_character_limit: z.number(),
  potential_match: z.string(),
  po_match: z.string(),
  recon_notification: z.string(),
});

export type SRSettingType = z.infer<typeof SRSettingSchema>;
export type SRSettingModificationType = z.infer<
  typeof SRSettingModificationSchema
>;
export type UpdateCharacterSettingsBodyType = z.infer<
  typeof UpdateCharacterSettingsBodySchema
>;
export type UpdateBooleanSettingsBodyType = z.infer<
  typeof UpdateBooleanSettingsBodySchema
>;
export type CreateSettingBodyType = z.infer<typeof CreateSettingBodySchema>;

export type SRSettingChangesType = z.infer<typeof SRSettingChangesSchema>;
