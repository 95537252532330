import {Link} from "@tanstack/react-router";
import {FileX2, HomeIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Heading1, Heading2, Paragraph} from "@/components/ui/typography";

function NotFound() {
  return (
    <div className="flex max-w-2xl flex-col items-center justify-center rounded-md bg-background p-8">
      <FileX2 className="mb-4 h-32 w-32 text-rose-600" aria-hidden="true" />
      <Heading1>Page Not Found</Heading1>
      <Heading2>We are sorry for the inconvenience</Heading2>
      <div className="flex flex-row items-center justify-between">
        <Paragraph>Please go back or navigate to the home page: </Paragraph>
        <Link to="/" preload="intent">
          <Button variant="ghost" className="ml-1 p-2">
            <HomeIcon />
          </Button>
        </Link>
      </div>
    </div>
  );
}

// DO NOT USE THIS COMPONENT
// Will modify later if needed
export function ModuleNotFound() {
  return (
    <section className="flex flex-1 flex-col items-center justify-center bg-gradient-to-r from-cyan-800 to-blue-900 p-4 text-center">
      <NotFound />
    </section>
  );
}

export function PageNotFound() {
  return (
    <section className="mt-20 flex items-center justify-center text-center">
      <NotFound />
    </section>
  );
}
