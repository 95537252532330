import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {supplierProfileKeys, type SupplierProfile} from "@/modules/imremit";

type QueryFnType = typeof getSupplierProfile;

export function getSupplierProfile(id: number) {
  return kyApiFn<SupplierProfile>(
    `api/e-payable/management/supplier-details/profile?id=${id}`,
    "get"
  );
}

export function useGetSupplierProfile(
  id: number,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: supplierProfileKeys.byId(id), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getSupplierProfile(id), // Setting the function to be used for fetching data
    enabled: !!id, // Setting the query to be enabled only if the search query string is not empty
  });
}
