import {useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {
  CheckIcon,
  ChevronsUpDownIcon,
  CopyIcon,
  PlusCircleIcon,
  Undo2Icon,
} from "lucide-react";
import {useForm} from "react-hook-form";
import {z} from "zod";

import {Button} from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {Command, CommandGroup, CommandItem} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Heading3} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {cn} from "@/lib/utils";
import {useAllCustomers} from "@/modules/customers";

import {useCopyToClipboard} from "@/utils/useCopyToClipboard";

const AddSupplierLinkFormSchema = z.object({
  customer: z.string().optional(), // TODO: Change to z.string().optional()
});

const initialState = {
  customerSaved: "",
};

export function RegistrationLinkForm() {
  const [{customerSaved}, setState] = useState(initialState);
  const clearState = () => {
    setState({...initialState});
  };

  const [, copy] = useCopyToClipboard();

  const form = useForm<z.infer<typeof AddSupplierLinkFormSchema>>({
    resolver: zodResolver(AddSupplierLinkFormSchema),
  });

  const allCustomersQuery = useAllCustomers();
  const rawCustomerData = allCustomersQuery.data?.content;
  const customerBuyerNames = useMemo(() => {
    if (!rawCustomerData) return [];

    return rawCustomerData.map((customer) => customer.buyerName);
  }, [rawCustomerData]);

  function onSubmit(data: z.infer<typeof AddSupplierLinkFormSchema>) {
    // This will be removed once the POST and activation link is implemented
    setState({
      ...initialState,
      customerSaved: data.customer ? data.customer : "",
    });

    toast({
      title: "You submitted the following values:",
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data, null, 2)}</code>
        </pre>
      ),
    });
  }

  function handleReset() {
    clearState();
  }

  async function handleCopy() {
    // get the base url
    const baseUrl = window.location.origin;
    const customerSavedURL = `${baseUrl}/self-register?customer=${customerSaved}`;

    await copy(customerSavedURL).catch((err) => {
      console.log(err);
    });
    toast({
      description: "Copied to clipboard!",
    });
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>Generate Self-Register Link</CardTitle>
        <CardDescription>
          This form allows administrators to generate or revoke self-register
          links for the customer.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form
            className="flex flex-col space-y-6"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="customer"
              render={({field}) => (
                <div className="space-y-2">
                  <FormItem className="w-[30%]">
                    <Popover>
                      <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                        <Command>
                          <CommandGroup>
                            {customerBuyerNames.map((customerBuyerName, i) => (
                              <CommandItem
                                key={i}
                                value={customerBuyerName}
                                onSelect={(value) => {
                                  field.onChange(value);
                                }}
                              >
                                <CheckIcon
                                  className={cn(
                                    "mr-2 size-4",
                                    field.value &&
                                      field.value.toUpperCase() ===
                                        customerBuyerName.toUpperCase()
                                      ? "opacity-100"
                                      : "opacity-0"
                                  )}
                                />
                                {customerBuyerName}
                              </CommandItem>
                            ))}
                          </CommandGroup>
                        </Command>
                      </PopoverContent>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant="outline"
                            role="combobox"
                            className={cn(
                              "justify-between",
                              !field.value && "text-accent-foreground",
                              "w-[100%]"
                            )}
                          >
                            <span>
                              {field.value ? field.value : "Select Customer"}
                            </span>
                            <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                    </Popover>
                    <FormMessage />
                  </FormItem>
                </div>
              )}
            />

            {!customerSaved ? (
              <Button className="max-w-fit self-end" type="submit">
                Generate Link <PlusCircleIcon className="ml-2" />
              </Button>
            ) : (
              <>
                <div className="flex flex-col space-y-6">
                  <Heading3 className="text-black">
                    Link to share with customer:
                  </Heading3>
                  <div className="flex flex-row">
                    <Input
                      className="w-[30%]"
                      type="text"
                      value={`self-register?customer=${customerSaved}`}
                      disabled
                    />
                    <Button className="ml-2" type="button" onClick={handleCopy}>
                      <CopyIcon />
                    </Button>
                  </div>
                </div>
                <Button
                  className="max-w-fit self-end"
                  type="button"
                  onClick={handleReset}
                >
                  Revoke Link <Undo2Icon className="ml-2" />
                </Button>
              </>
            )}
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
