import {useMemo} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate} from "@tanstack/react-router";
import {
  ArrowRightFromLineIcon,
  ArrowRightIcon,
  DownloadCloudIcon,
} from "lucide-react";
import {useForm, useFormContext} from "react-hook-form";
import type z from "zod";

import {Button} from "@/components/ui/button";
import {CardContent, CardHeader, CardTitle} from "@/components/ui/card";
import {Dialog, DialogContent, DialogTrigger} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Separator} from "@/components/ui/separator";
import {Textarea} from "@/components/ui/textarea";
import {Heading2, Paragraph} from "@/components/ui/typography";
import {UploadDropzone} from "@/components/ui/upload";
import {toast} from "@/components/ui/use-toast";

import {useImRemitLiteCustomerStore} from "@/lib/stores";
import {
  AccessPolicyWrapper,
  calculateUserRoles,
  KeycloakRoleEnum,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {SelectCustomerSectionLite} from "@/modules/customers";
import type {SupplierCommentsFormSchema} from "@/modules/imremit";
import {
  AddSupplierFormLiteSchema,
  saveSupplierCommentFn,
} from "@/modules/imremit";
import {addSupplierLiteFn} from "@/modules/imremit-lite";

export interface SupplierAPIMutatingProps {
  isMutating?: boolean;
}

/**
 * AddSupplierFormProps Interface
 *
 * @interface AddSupplierFormProps
 * @property {boolean} isMutating - Indicates whether the mutation is in progress.
 * @property {Function} onSubmit - The function to be called upon form submission.
 */
interface AddSupplierFormProps {
  isMutating: boolean;
  onSubmit: (data: z.infer<typeof AddSupplierFormLiteSchema>) => void;
}

/**
 * AddSupplierForm Component
 *
 * This component renders the AddSupplierForm form and handles its behavior.
 *
 * @param {AddSupplierFormProps} props - Properties passed to the component
 * @returns {JSX.Element} - Rendered component
 */

function AddSupplierForm({
  isMutating,

  onSubmit,
}: AddSupplierFormProps) {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;
  const changedBy = parsedUserInfo?.sub || "";

  const form = useForm<z.infer<typeof AddSupplierFormLiteSchema>>({
    defaultValues: {
      changedBy: changedBy,
      saveAndContinue: true,
      phoneNumber: null,
    },
    mode: "onChange",
    resolver: zodResolver(AddSupplierFormLiteSchema),
  });

  return (
    <>
      <section>
        <div className="mb-4 flex flex-col-reverse gap-4 md:flex-row md:justify-between">
          <div className="flex gap-4">
            <Heading2>Add Supplier</Heading2>
          </div>
          <ImportScriptField isMutating={isMutating} />
          <Link to="/app/imremit-lite/supplier-management">
            <Button variant="secondary">
              Back to list
              <ArrowRightIcon className="ml-2 size-4" />
            </Button>
          </Link>
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="mb-8 rounded-md border border-border bg-root p-4">
              <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
                <SupplierNameField isMutating={isMutating} />
                <SupplierNumberField isMutating={isMutating} />
                <SupplierEmailField isMutating={isMutating} />
                <PhoneNumberField isMutating={isMutating} />
                <CommentsField isMutating={isMutating} />
              </div>
              <div className="flex w-full flex-row justify-end gap-2">
                <Link to="/app/imremit-lite/supplier-management">
                  <Button variant="secondary">Cancel</Button>
                </Link>
                <Button
                  className="gap-2 bg-success-foreground text-white hover:bg-success-foreground/80"
                  type="submit"
                >
                  <span className="sr-only">Submit form</span>
                  Save and continue
                  <ArrowRightFromLineIcon className="size-4" />
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </section>
    </>
  );
}

export function AddSupplierPage() {
  // Get the navigate function from the router
  const navigate = useNavigate();
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";
  const {imRemitLiteStoreCustomer} = useImRemitLiteCustomerStore();
  const externalId = imRemitLiteStoreCustomer?.externalId;

  const memoizedId = useMemo(() => externalId || "", [externalId]);

  // Define mutation for adding supplier comment
  const addSupplierCommentMutation = useMutation({
    mutationFn: ({
      data,
      supplierId,
    }: {
      data: z.infer<typeof SupplierCommentsFormSchema>;
      supplierId: string;
    }) => saveSupplierCommentFn(data, supplierId),
    onSuccess: (response) => {
      console.log("addSupplierCommentResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Comment added successfully",
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add comment",
      });
    },
  });

  const addSupplierMutation = useMutation({
    mutationFn: (data: z.infer<typeof AddSupplierFormLiteSchema>) => {
      // Destructure comments and other fields
      const {comments, ...filteredData} = data;

      // Call addSupplierFn and return the response
      return addSupplierLiteFn(filteredData, memoizedId).then((response) => ({
        ...response,
        comments,
      }));
    },

    onSuccess: (addSupplierResponse) => {
      console.log("addSupplierResponse", addSupplierResponse);
      toast({
        variant: "success",
        title: "Success!",
        description: "Supplier added successfully",
      });
      // redirect to supplier management page
      void navigate({
        to: "/app/imremit-lite/supplier-management",
      });
      const {content} = addSupplierResponse; // Destructure content

      // Assert the type, ensuring TypeScript knows `content` has `supplierId`
      const supplierId = (content as {supplierId: number}).supplierId;

      // If comments are present, trigger the add comment mutation
      if (addSupplierResponse.comments) {
        addSupplierCommentMutation.mutate({
          data: {
            comments: addSupplierResponse.comments,
            userId: storeKeyCloakInstance?.tokenParsed?.name as string,
            role: userRoleDisplay || "",
          },
          supplierId: supplierId.toString(),
        });
      }
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add supplier",
      });
    },
  });

  if (!imRemitLiteStoreCustomer) {
    return <SelectCustomerSectionLite />;
  }

  // Render AddSupplierForm component
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.CREATE_SUPPLIER_MANAGEMENT_LITE]}
    >
      <AddSupplierForm
        isMutating={addSupplierMutation.isPending}
        onSubmit={addSupplierMutation.mutate}
      />
    </AccessPolicyWrapper>
  );
}

function ImportScriptField({isMutating}: {isMutating: boolean}) {
  return (
    <>
      <Dialog>
        <DialogTrigger asChild>
          <Button
            type="button"
            disabled={isMutating}
            aria-disabled={isMutating}
            className="ml-auto whitespace-nowrap bg-success-foreground hover:bg-success-foreground/70"
          >
            <span className="sr-only">Import Script</span>
            <DownloadCloudIcon className="mr-2" />
            Import Script
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[700px]">
          <CardHeader>
            <CardTitle>Import Supplier</CardTitle>
          </CardHeader>
          <CardContent>
            <Paragraph className="mb-3  text-sm text-gray-700">
              The sample template provided is just a basic example of what your
              file should look like. Reach out to SecurityAdmin@iterationm.com
              if you have questions, or require additional assistance
            </Paragraph>

            <Paragraph className="text-sm  text-gray-700">
              Please note: Supplier Name, Supplier ID, Supplier Email, Supplier
              Phone are mandatory fields.
            </Paragraph>
            <Separator className="mb-4" />
            <UploadDropzone
              data-testid="upload-dropzone"
              maxFiles={1}
              onDrop={(acceptedFiles) => {
                console.log(acceptedFiles);
              }}
            />
          </CardContent>
        </DialogContent>
      </Dialog>
    </>
  );
}

function SupplierNameField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="supplierName"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="supplierName" showMandatoryAsterisk>
            Supplier Name:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the supplier name..."
              {...register("supplierName")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function SupplierNumberField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="supplierNumber"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="supplierNumber" showMandatoryAsterisk>
            Supplier Number:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the supplier Number..."
              {...register("supplierNumber")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function SupplierEmailField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="supplierEmail"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="supplierEmail" showMandatoryAsterisk>
            Supplier Email:
          </FormLabel>
          <FormControl>
            <Input
              type="email"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the supplier email..."
              {...register("supplierEmail")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
function PhoneNumberField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="phoneNumber"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="phoneNumber">Supplier Phone Number:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the supplier phone number..."
              {...register("phoneNumber")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CommentsField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="comments"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="phoneNumber">Add a comment:</FormLabel>
          <FormControl>
            <Textarea
              id="comments"
              placeholder="Type your comment here."
              disabled={isMutating}
              aria-disabled={isMutating}
              autoCapitalize="none"
              autoComplete="comments"
              autoCorrect="off"
              {...register("comments")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
