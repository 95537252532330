import {z} from "zod";

export const statementReconGetVendorFieldsSchema = z.object({
  vendor_field_id: z.string(),
  created_timestamp: z.string(),
  updated_timestamp: z.string().optional().nullable(),
  im_vendor_field_name: z.string(),
});

export type StatementReconGetVendorFieldsType = z.infer<
  typeof statementReconGetVendorFieldsSchema
>;

export const srSavedMappingsDetailsSchema = z.object({
  sr_saved_mappings: z.array(
    z.object({
      vendor_field_id: z.string(),
      user_vendor_field_name: z.string(),
    })
  ),
});

export const srSavedMappingsSchema = z.object({
  saved_mapping_id: z.number(),
  saved_mapping_name: z.string().max(50),
  buyer_external_id: z.string().max(10),
  mapping_detail: srSavedMappingsDetailsSchema,
  created_timestamp: z.date().nullable().optional(),
});

export const srAddMappingSchema = srSavedMappingsSchema.pick({
  buyer_external_id: true,
  saved_mapping_name: true,
  mapping_detail: true,
});

export type srAddMappingType = z.infer<typeof srAddMappingSchema>;

const SrSavedMappingSchema = z.object({
  vendor_field_id: z.string(),
  user_vendor_field_name: z.string(),
});

const MappingDetailSchema = z.object({
  sr_saved_mappings: z.array(SrSavedMappingSchema),
});

const MappingContentSchema = z.object({
  saved_mapping_id: z.number(),
  saved_mapping_name: z.string(),
  buyer_external_id: z.string(),
  mapping_detail: MappingDetailSchema,
  created_timestamp: z.string(),
});

const GetSRMappingsByExternalIdResponseSchema = z.array(MappingContentSchema);

export type GetSRMappingsByExternalIdResponseType = z.infer<
  typeof GetSRMappingsByExternalIdResponseSchema
>;

export const statementReconMappingColumnsSchema = z.object({
  saved_mapping_id: z.boolean(),
  saved_mapping_name: z.boolean(),
  buyer_external_id: z.boolean(),
  supplier_ids: z.boolean(),
  created_timestamp: z.boolean(),
  updated_timestamp: z.boolean(),
});

export type StatementReconMappingColumnsType = z.infer<
  typeof statementReconMappingColumnsSchema
>;

export const statementReconMappingSchema = z.object({
  saved_mapping_id: z.string(),
  saved_mapping_name: z.string(),
  buyer_external_id: z.string(),
  supplier_ids: z.string(),
  created_timestamp: z.string(),
  updated_timestamp: z.string().optional().nullable(),
});

export type StatementReconMappingType = z.infer<
  typeof statementReconMappingSchema
>;
