// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

// Importing the type definition for InvoiceTableColumns
import {type PaymentManagementIMRemitLiteTableColumnsType} from "@/modules/imremit-lite";

// Interface defining the shape of the InvoiceTableColumnsStore state
// This state will consist of an array of InvoiceTableColumns objects
interface PaymentManagementIMRemitLiteTableColumnsStoreState {
  storePaymentManagementIMRemitLiteTableColumns: PaymentManagementIMRemitLiteTableColumnsType | null;
  setStorePaymentManagementIMRemitLiteTableColumns: (
    storePaymentManagementIMRemitLiteTableColumns: PaymentManagementIMRemitLiteTableColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for PaymentManagementIMRemitLite table columns
export const usePaymentManagementIMRemitLiteTableColumnsStore = create(
  persist<PaymentManagementIMRemitLiteTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storePaymentManagementIMRemitLiteTableColumns: null,

      // Getter function to retrieve the current state
      getStorePaymentManagementIMRemitLiteTableColumns: () =>
        get().storePaymentManagementIMRemitLiteTableColumns,

      // Setter function to update the state
      setStorePaymentManagementIMRemitLiteTableColumns: (
        storePaymentManagementIMRemitLiteTableColumns
      ) => {
        set(() => ({storePaymentManagementIMRemitLiteTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "PaymentManagementIMRemitLite-tracker-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const usePaymentManagementIMRemitLiteTableColumns = () =>
  usePaymentManagementIMRemitLiteTableColumnsStore((state) => {
    return {
      storePaymentManagementIMRemitLiteTableColumns:
        state.storePaymentManagementIMRemitLiteTableColumns,
    };
  });
