import {kyApiFn} from "@/lib/ky";
import {type paymentMappingFormType} from "@/modules/imremit";

export const savePaymentFileFn = (
  paymentMappingFormType: paymentMappingFormType
) =>
  kyApiFn(`api/e-payable/admin/master-mapping/payment`, "post", {
    // TODO: get rid of hard coded id
    json: paymentMappingFormType,
  });
