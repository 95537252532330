import {useEffect} from "react";

import {Link} from "@tanstack/react-router";
import {ListPlusIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Heading1, Paragraph} from "@/components/ui/typography";

import {AdminRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";

/**
 * SearchPIDMappingPage Component
 *
 * This component serves as the main container for managing PID (Participant ID) mappings.
 * It handles the initialization of route data and provides UI for adding new PIDs.
 */
export function SearchPIDMappingPage() {
  // Custom hook for manipulating route data
  const {setAdminRoute} = useRoutesStore();

  // React `useEffect` hook to set the active admin route when the component mounts
  useEffect(() => {
    // Update the active route for Admin PID Mapping using the predefined route schema
    setAdminRoute(AdminRoutesSchema.Values["/app/admin/pid-mapping"]);
  }, [setAdminRoute]);

  // Render the PID Mapping UI
  return (
    <article
      role="presentation"
      aria-roledescription="This is the PID Mapping page. This page allows administrators to add, edit, and delete PID mappings."
    >
      <div className="flex flex-1 flex-col md:flex-row md:justify-between">
        <Heading1>Search PIDs</Heading1>
        <div className="flex flex-1 flex-row items-center justify-center gap-2 md:justify-end">
          <Link to="/app/admin/pid-mapping/create" className="mt-4 md:mt-0">
            <Button>
              New PID <ListPlusIcon className="ml-2" />
            </Button>
          </Link>
          {/* TODO: make this conditional, render only once a record has been populated below */}
          <Link
            to="/app/admin/pid-mapping/$pid/edit"
            // TODO: put existing pid here
            params={{pid: "exiting_pid_goes_here"}}
            className="mt-4 md:mt-0"
          >
            <Button variant="secondary">
              Edit PID <ListPlusIcon className="ml-2" />
            </Button>
          </Link>
        </div>
      </div>
      <Paragraph>
        Create form to search PIDs from an endpoint that will be created on the
        mock server soon.
      </Paragraph>
      <Paragraph>
        Load in the data from the endpoint and display it in some way. Include
        the edit button.
      </Paragraph>
      <Paragraph>Create new page for creating PIDs.</Paragraph>
      <Paragraph>Create new page for editing PIDs.</Paragraph>
    </article>
  );
}
