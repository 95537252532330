import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {invoiceQueryKeys, type InvoiceStatusHistory} from "@/modules/invoices";

type QueryFnType = typeof getInvoiceStatusHistory;

export function getInvoiceStatusHistory(invoiceId: number) {
  return kyApiFn<InvoiceStatusHistory[]>(
    `invoice/v1/statushistory?invoice_id=${invoiceId}`,
    "get"
  );
  // TODO: validate data
  // return invoiceSchema.parse(data);
}

// Custom hook to fetch and cache invoices data using react-query
export function useGetInvoiceStatusHistory(
  invoiceId: number, // The search query string
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: invoiceQueryKeys.byId(invoiceId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getInvoiceStatusHistory(invoiceId), // Setting the function to be used for fetching data
    enabled: !!invoiceId, // Setting the query to be enabled only if the search query string is not empty
  });
}
