import {useEffect, useState} from "react";

import {type Table} from "@tanstack/react-table";
import {FilterIcon, ListRestartIcon} from "lucide-react";
import type {DateRange} from "react-day-picker";

import {Button} from "@/components/ui/button";
import {Checkbox} from "@/components/ui/checkbox";
import {
  HoverCard,
  HoverCardArrow,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {Input} from "@/components/ui/input";
import {Separator} from "@/components/ui/separator";
import {Heading3, Paragraph} from "@/components/ui/typography";
import {
  // DataTableFacetedFilter,
  DataTablePagination,
  DataTableViewOptions,
} from "@/components/craft/data-table";
import {DatePickerWithStateRange} from "@/components/craft/date-pickers";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

// import {
//   billingTypes,
//   paymentMethodTypes,
//   paymentTypes,
//   settlementTermsTypes,
//   statusTypes,
// } from "@/modules/admin";

/**
 * Props for DupesTrackerDataTableToolbar component.
 *
 * @template TData - The type of data used in the table.
 * @typedef {Object} DupesTrackerDataTableToolbarProps
 * @property {Table<TData>} table - The table instance to interact with.
 */
interface DupesTrackerDataTableToolbarProps<TData> {
  table: Table<TData>;
  isPending?: boolean;
  isErrored?: boolean;
}

/**
 * Toolbar component for customer management data table.
 * Includes pagination, search input, filter components, and view options.
 *
 * @template TData - The type of data displayed in the table.
 * @param {DupesTrackerDataTableToolbarProps<TData>} props - The component props.
 * @returns {JSX.Element} The rendered JSX element.
 */
export function DupesTrackerDataTableToolbar<TData>({
  table,
  isPending,
  isErrored,
}: DupesTrackerDataTableToolbarProps<TData>) {
  // State for the global filter input
  const [globalFilterInput, setGlobalFilterInput] = useState("");
  // State for the specific filter input for invoice numbers
  const [invoiceNumberFilterInput, setInvoiceNumberFilterInput] = useState("");
  // State for the specific filter input for facility name
  const [facilityNameFilterInput, setFacilityNameFilterInput] = useState("");
  // State for the specific filter input for vendor name
  const [vendorNameFilterInput, setVendorNameFilterInput] = useState("");
  // State for the specific filter input for supplier participant tax code
  const [
    supplierParticipantTaxCodeFilterInput,
    setSupplierParticipantTaxCodeFilterInput,
  ] = useState("");
  // State for the specific filter input for status
  const [statusFilterInput, setStatusFilterInput] = useState("");

  const [paymentDateRangeState, setPaymentDateRangeState] = useState<
    DateRange | undefined
  >();
  const [invoiceDateRangeState, setInvoiceDateRangeState] = useState<
    DateRange | undefined
  >();

  useEffect(() => {
    if (paymentDateRangeState) {
      table.getColumn("payment_date")?.setFilterValue(paymentDateRangeState);
    }
    if (invoiceDateRangeState) {
      table.getColumn("invoice_date")?.setFilterValue(invoiceDateRangeState);
    }
  }, [table, paymentDateRangeState, invoiceDateRangeState]);

  // Determine if the table is currently filtered
  const isFiltered =
    table.getState().columnFilters.length > 0 ||
    !!table.getState().globalFilter;

  //Columns other names
  const columnsOtherName = [
    {key: "invoice_number", value: "Invoice #"},
    {key: "invoice_date", value: "Invoice Date"},
    {key: "invoice_amount", value: "Invoice Amount"},
    {key: "invoice_paid_amount", value: "Paid Amount"},
    {key: "invoice_status", value: "Status"},
    {key: "facility_name", value: "Customer Name"},
    {key: "supplier_number", value: "Supplier Number"},
    {key: "invoice_payment_number", value: "Payment Number"},
    {key: "invoice_payment_date", value: "Payment Date"},
    {key: "supplier_name", value: "Supplier Name"},
    {key: "erp_unique_id", value: "Voucher ID"},
  ];

  return (
    <>
      <DataTablePagination
        table={table}
        isPending={isPending}
        isErrored={isErrored}
      />
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-1 flex-wrap items-center gap-y-2 space-x-2">
          <RowsPreferenceSelect />
          <Input
            placeholder="Search all entries..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={globalFilterInput}
            onChange={(event) => {
              setGlobalFilterInput(event.target.value);
              table.setGlobalFilter(event.target.value);
            }}
          />
          <Input
            placeholder="Search invoice numbers..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={invoiceNumberFilterInput}
            onChange={(event) => {
              setInvoiceNumberFilterInput(event.target.value);
              table
                .getColumn("invoice_number")
                ?.setFilterValue(event.target.value);
            }}
          />
          {isFiltered && (
            <Button
              variant="destructive"
              className="h-10 gap-2 px-2 hover:bg-destructive/70 hover:text-destructive-foreground/80 active:bg-destructive/80 active:text-destructive-foreground/80 lg:px-3"
              onClick={() => {
                table.resetColumnFilters();
                table.resetGlobalFilter();
                setGlobalFilterInput("");
                setInvoiceNumberFilterInput("");
                setFacilityNameFilterInput("");
                setVendorNameFilterInput("");
                setSupplierParticipantTaxCodeFilterInput("");
                setStatusFilterInput("");
                setPaymentDateRangeState(undefined);
                setInvoiceDateRangeState(undefined);
              }}
            >
              <span className="sr-only">Reset search filters</span>
              Search Reset
              <ListRestartIcon className="size-4" />
            </Button>
          )}
        </div>
        <div className="flex items-center space-x-2">
          {/* {table.getColumn("status") && (
            <DataTableFacetedFilter
              column={table.getColumn("status")}
              title="Status"
              options={statusTypes}
            />
          )}
          {table.getColumn("billingType") && (
            <DataTableFacetedFilter
              column={table.getColumn("billingType")}
              title="Billing"
              options={billingTypes}
            />
          )}
          {table.getColumn("paymentType") && (
            <DataTableFacetedFilter
              column={table.getColumn("paymentType")}
              title="Payment Type"
              options={paymentTypes}
            />
          )}
          {table.getColumn("paymentMethod") && (
            <DataTableFacetedFilter
              column={table.getColumn("paymentMethod")}
              title="Payment Method"
              options={paymentMethodTypes}
            />
          )}
          {table.getColumn("settlementTerms") && (
            <DataTableFacetedFilter
              column={table.getColumn("settlementTerms")}
              title="Settlement"
              options={settlementTermsTypes}
            />
          )} */}
          <HoverCard openDelay={125} closeDelay={50}>
            <HoverCardTrigger>
              <Button
                variant="outline"
                size="xs"
                className="gap-2 border-dashed px-2 lg:px-3"
              >
                <FilterIcon className="size-4" />
                Filters
              </Button>
            </HoverCardTrigger>
            <HoverCardContent>
              <HoverCardArrow />
              <Heading3>Filters</Heading3>
              <div className="flex flex-col gap-y-1 pb-2">
                <div className="flex items-center gap-2">
                  <Paragraph>Exclude commented invoices: </Paragraph>
                  <Checkbox disabled />
                </div>
                <div className="flex items-center gap-2">
                  <Paragraph>Exclude customer status invoices: </Paragraph>
                  <Checkbox disabled />
                </div>
                <div className="flex items-center gap-2">
                  <Paragraph>Exclude reassigned invoices: </Paragraph>
                  <Checkbox disabled />
                </div>
              </div>
              <Separator className="w-full bg-muted-foreground" />

              <div className="grid grid-cols-1 gap-x-4 gap-y-2 pb-2 pt-2 lg:grid-cols-2">
                <div className="flex flex-col gap-1">
                  <Paragraph>Facility Name: </Paragraph>
                  <Input
                    placeholder="Search facility name..."
                    className="h-10"
                    value={facilityNameFilterInput}
                    onChange={(event) => {
                      setFacilityNameFilterInput(event.target.value);
                      table
                        .getColumn("facility_name")
                        ?.setFilterValue(event.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <Paragraph>Supplier Name: </Paragraph>
                  <Input
                    placeholder="Search supplier name..."
                    className="h-10"
                    value={vendorNameFilterInput}
                    onChange={(event) => {
                      setVendorNameFilterInput(event.target.value);
                      table
                        .getColumn("supplier_name")
                        ?.setFilterValue(event.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <Paragraph>Supplier Number: </Paragraph>
                  <Input
                    placeholder="Search supplier number..."
                    className="h-10"
                    value={supplierParticipantTaxCodeFilterInput}
                    onChange={(event) => {
                      setSupplierParticipantTaxCodeFilterInput(
                        event.target.value
                      );
                      table
                        .getColumn("supplier_number")
                        ?.setFilterValue(event.target.value);
                    }}
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <Paragraph>Status: </Paragraph>
                  <Input
                    placeholder="Search status..."
                    className="h-10"
                    value={statusFilterInput}
                    onChange={(event) => {
                      setStatusFilterInput(event.target.value);
                      table
                        .getColumn("invoice_status")
                        ?.setFilterValue(event.target.value);
                    }}
                  />
                </div>
              </div>

              <Separator className="w-full bg-muted-foreground" />
              <div className="flex flex-col gap-4 pt-2 lg:flex-row">
                <div className="flex flex-col">
                  <Paragraph>Payment Date Range: </Paragraph>
                  <DatePickerWithStateRange
                    className="bg-root"
                    dateRangeState={paymentDateRangeState}
                    setDateRangeState={setPaymentDateRangeState}
                  />
                </div>
                <div className="flex flex-col">
                  <Paragraph>Invoice Date Range: </Paragraph>
                  <DatePickerWithStateRange
                    className="bg-root"
                    dateRangeState={invoiceDateRangeState}
                    setDateRangeState={setInvoiceDateRangeState}
                  />
                </div>
              </div>
            </HoverCardContent>
          </HoverCard>
          <DataTableViewOptions
            table={table}
            columnsOtherName={columnsOtherName}
          />
        </div>
      </div>
    </>
  );
}
