import {useEffect, useMemo, useState} from "react";

import {Link} from "@tanstack/react-router";
import {CheckIcon, ChevronsUpDownIcon, FilePlus2Icon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Heading2, Paragraph} from "@/components/ui/typography";
import {GenericError} from "@/components/layout/generic-error";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {ImREmitLiteRoutesSchema} from "@/lib/routes/types";
import {useImRemitLiteCustomerStore, useRoutesStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useAuthorization,
} from "@/modules/auth";
import {
  SelectCustomerFormLite,
  SelectCustomerSectionLite,
} from "@/modules/customers";
import {
  SupplierScriptSkeleton,
  useGetAllSuppliersDetails,
  useGetSupplierScripts,
} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";

export function SupplierScriptManagementPage() {
  const {setimREmitLiteRoute} = useRoutesStore();

  useEffect(() => {
    setimREmitLiteRoute(
      ImREmitLiteRoutesSchema.Values[
        "/app/imremit-lite/supplier-script-management"
      ]
    );
  }, [setimREmitLiteRoute]);

  const {imRemitLiteStoreCustomer} = useImRemitLiteCustomerStore();

  const [currentSupplier, setCurrentSupplier] = useState<string | number>("");

  useEffect(() => {
    // Reset current supplier when customer changes
    setCurrentSupplier("");
  }, [imRemitLiteStoreCustomer]);

  // TODO: we need a new getAllSuppliers hook??
  const supplierQuery = useGetAllSuppliersDetails(
    imRemitLiteStoreCustomer && imRemitLiteStoreCustomer.externalId
      ? imRemitLiteStoreCustomer.externalId.toString()
      : imRemitLiteStoreCustomer?.id.toString()
  );

  const availableSuppliersByCustomer = useMemo(() => {
    if (!imRemitLiteStoreCustomer || !supplierQuery.data?.content) return [];

    return supplierQuery.data.content
      .filter(
        (supplierData) =>
          supplierData.paymentMode === "Pay By Web" &&
          supplierData.onboardStatus !== "INACTIVE"
      ) // Filter based on payment mode and status) // Filter based on payment mode
      .map((supplierData) => {
        const {supplierId, supplierName, supplierNumber} = supplierData;

        return {
          value: supplierId,
          label: `${String(supplierNumber)} - ${
            supplierName ?? FallbackMessages.NOT_APPLICABLE
          }`, // 📝 Using String() and nullish coalescing for type safety
        };
      });
  }, [imRemitLiteStoreCustomer, supplierQuery]);

  const searchSupplierScriptsQuery = useGetSupplierScripts(
    currentSupplier.toString()
  );
  const searchSupplierScriptsQueryData = useMemo(() => {
    if (!searchSupplierScriptsQuery.data?.content) return [];

    return searchSupplierScriptsQuery.data.content;
  }, [searchSupplierScriptsQuery]);

  const supplierScripts = useMemo(() => {
    if (!imRemitLiteStoreCustomer) return [];

    return searchSupplierScriptsQueryData.map((supplierScript, i) => {
      return {
        id: i,
        ...supplierScript,
      };
    });
  }, [imRemitLiteStoreCustomer, searchSupplierScriptsQueryData]);

  const {checkPoliciesAccess} = useAuthorization();

  // Handle unauthorized access
  if (
    !checkPoliciesAccess([
      KeycloakRoleEnum.READ_SUPPLIER_SCRIPT_MANAGEMENT_LITE,
    ])
  ) {
    return <NotAuthorizedPage />;
  }

  if (!imRemitLiteStoreCustomer) {
    return <SelectCustomerSectionLite />;
  }

  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.READ_SUPPLIER_SCRIPT_MANAGEMENT_LITE]}
      wrapperLoadingState={{
        isLoading: supplierQuery.isLoading,
        loadingFallback: <SupplierScriptSkeleton />,
      }}
      wrapperErrorState={{
        isError: supplierQuery.isError,
        errorFallback: <GenericError error={new Error("An error occurred")} />,
      }}
    >
      <section
        className="flex flex-col gap-4"
        aria-label="This is the Supplier Script Management level three subroute. View your customer's
      Supplier Script Management data here."
      >
        <header className="flex flex-col gap-4 lg:flex-row lg:items-center lg:justify-between">
          <div className="flex flex-col">
            <Heading2>Supplier Script Management</Heading2>
            <Paragraph>
              View your customer's Supplier Script Management data here. You can
              also add a new script.
            </Paragraph>
          </div>
          <div className="flex flex-col gap-2 lg:flex-row lg:items-center">
            <div className="flex gap-2">
              <SelectCustomerFormLite />
            </div>

            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn(
                    "justify-between",
                    !currentSupplier && "text-accent-foreground",
                    "w-[420px]"
                  )}
                >
                  {currentSupplier
                    ? availableSuppliersByCustomer.find(
                        (item) => item.value === currentSupplier
                      )?.label
                    : "Select Supplier..."}
                  <ChevronsUpDownIcon className="size-4 shrink-0 opacity-50" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0 ">
                <Command>
                  <CommandInput placeholder="Search Supplier Name or ID" />
                  <CommandList>
                    <CommandEmpty>No supplier found.</CommandEmpty>
                    <CommandGroup>
                      {availableSuppliersByCustomer.map((supplierOption, i) => (
                        <CommandItem
                          key={i}
                          value={supplierOption.label}
                          onSelect={() => {
                            setCurrentSupplier(supplierOption.value);
                          }}
                        >
                          <CheckIcon
                            className={cn(
                              "mr-2 size-4",
                              supplierOption.value
                                .toString()
                                .toLowerCase()
                                .trim() ===
                                currentSupplier.toString().toLowerCase().trim()
                                ? "opacity-100"
                                : "opacity-0"
                            )}
                          />
                          {supplierOption.label}
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>

            {supplierScripts.length === 0 ? (
              <AccessPolicyWrapper
                unauthorizedFallback={null}
                policyActions={[
                  KeycloakRoleEnum.CREATE_SUPPLIER_SCRIPT_MANAGEMENT_LITE,
                ]}
              >
                <Link
                  to="/app/imremit-lite/supplier-script-management/$moduleName/$supplierId/create/$paymentId"
                  params={{
                    moduleName: "imremit-lite",
                    supplierId: currentSupplier.toString(),
                    paymentId: "0",
                  }}
                >
                  <Button
                    className="whitespace-nowrap"
                    disabled={currentSupplier === ""}
                  >
                    <span className="sr-only">Add Script</span>
                    <FilePlus2Icon className="mr-1" />
                    Add Script
                  </Button>
                </Link>
              </AccessPolicyWrapper>
            ) : (
              <Link
                to="/app/imremit-lite/supplier-script-management/$moduleName/$supplierId/view/$paymentId"
                params={{
                  moduleName: "imremit-lite",
                  supplierId: currentSupplier.toString(),
                  paymentId: "0",
                }}
              >
                <Button
                  className="whitespace-nowrap"
                  disabled={currentSupplier === ""}
                  variant={"affirmative"}
                >
                  <span className="sr-only">View Script</span>
                  <FilePlus2Icon className="mr-1" />
                  View Script
                </Button>
              </Link>
            )}
          </div>
        </header>
        <section>
          {supplierScripts.length === 0 ? (
            <Paragraph className="text-center text-xl">
              No supplier scripts found.
            </Paragraph>
          ) : (
            <Paragraph className="text-center text-xl">
              Supplier Scripts found!
            </Paragraph>
          )}
        </section>
      </section>
    </AccessPolicyWrapper>
  );
}
