import {Fragment} from "react";

import {Link} from "@tanstack/react-router";

import {Separator} from "@/components/ui/separator";
import {FailedAlertsSection} from "@/components/layout/failed-alerts";
import {HelpSection} from "@/components/layout/header-help-sections";

import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  UserInfoCard,
} from "@/modules/auth";

// Defining TypeScript type for the props that the Header component will receive.
// In this case, it can receive an optional array of ReactNode objects as 'components'
// ReactNode is a type that React elements, strings, numbers or arrays of those types could match.
interface HeaderProps {
  components?: React.ReactNode[];
}

// The Header component is defined as a Functional Component
// It is structured to receive the defined HeaderProps type object as its props.
export function Header({components}: HeaderProps) {
  // The component returns a div which serves as a container for the header content.
  // Various CSS classes are assigned to style the header.
  return (
    <header className="sticky top-0 z-10 flex h-20 items-center justify-between bg-root shadow-sm">
      <div className="flex items-center p-3">
        {/* A Link component is used to redirect to the ROOT route when the logo is clicked */}
        <Link to="/">
          {/* The logo image is included with specific styling and alt text */}
          <img className="h-14" src="/images/new_im_logo_2.png" alt="logo" />
        </Link>
      </div>
      {/* A div to host the custom components and AuthButton */}
      <div className="flex items-center">
        {/* Conditional rendering is performed here: If there are components received as props, they are rendered */}
        {components && (
          <div className="flex items-center">
            {/* Each component in the components prop array is rendered inside a Fragment */}
            {/* Fragment is used to return multiple elements and 'key' prop is provided to help React identify which items have changed, are added, or are removed */}
            {components.map((component, index) => (
              <Fragment key={index}>{component}</Fragment>
            ))}
          </div>
        )}
        <AccessPolicyWrapper
          unauthorizedFallback={null}
          policyActions={[KeycloakRoleEnum.READ_FAILED_ALERTS]}
        >
          <Separator
            className="mx-4 h-20"
            orientation="vertical"
            aria-orientation="vertical"
          />
          <FailedAlertsSection />
        </AccessPolicyWrapper>
        <Separator
          className="mx-4 h-20"
          orientation="vertical"
          aria-orientation="vertical"
        />
        <HelpSection />
        <Separator
          className="mx-4 h-20"
          orientation="vertical"
          aria-orientation="vertical"
        />
        {/* <HelpChatSection /> */}

        <UserInfoCard />
      </div>
    </header>
  );
}
