import {useEffect, useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {ParentSize} from "@visx/responsive";
import {FilterIcon, TablePropertiesIcon} from "lucide-react";
import {useForm} from "react-hook-form";

import {Button} from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {Form} from "@/components/ui/form";
import {Heading2, Paragraph} from "@/components/ui/typography";

import type {
  MISPaymentsReceivedType,
  PaymentFilterFormType,
} from "@/modules/admin";
import {
  generateMISPaymentsReceivedColumns,
  MISPaymentsReceivedDataTable,
  MISPaymentsReceivedForm,
  MISPaymentsReceivedGraph,
  paymentFilterFormSchema,
  useGetMISReceivedPayments,
} from "@/modules/admin";

/**
 * 🔍 Custom hook to generate query parameters based on form fields
 * Generates the query string for API calls depending on user inputs
 */
function useMISQueryParams(formFields: PaymentFilterFormType): string {
  const {customers, year, month, quarter, status, module} = formFields;

  const params = new URLSearchParams();

  // 🗓️ Append buyerExternalIds
  if (customers && customers.length > 0) {
    const buyerExternalIdValues = customers.map((b) => b.value);
    params.append("buyerExternalIds", buyerExternalIdValues.toString());
  }

  // 🗓️ Append year filter
  if (year) {
    params.append("year", encodeURIComponent(year));
  }

  // 📅 Append month filter
  if (month && month.length > 0) {
    const monthValues = month.map((m) => encodeURIComponent(m.value));
    params.append("month", monthValues.join(","));
  }

  // 🗓️ Append quarter filter
  if (quarter && quarter.length > 0) {
    const quarterValues = quarter.map((q) => encodeURIComponent(q.value));
    params.append("quarter", quarterValues.join(","));
  }

  // 🔍 Append status filter
  if (status && status.length > 0) {
    const statusValues = status.map((s) => encodeURIComponent(s.value));
    params.append("status", statusValues.join(","));
  }

  // 📦 Append module filter
  if (module) {
    params.append("module_name", encodeURIComponent(module));
  }

  return params.toString(); // 🚀 Return the generated query string
}

/**
 * 🌐 Main component for rendering the MIS Payments Received page
 * Contains the form and the data table
 */
export function MISPaymentsPostedPage() {
  // 🔍 State for the collapsible table
  const [isTableOpen, setIsTableOpen] = useState(false);

  // 📝 Initialize form using react-hook-form and zod validation
  const misPaymentsReceivedFormMethods = useForm<PaymentFilterFormType>({
    defaultValues: {
      customers: [],
      month: [],
      quarter: [],
      status: [
        {label: "Delivered", value: "Delivered"},
        {label: "Closed", value: "Closed"},
      ],
      module: "imREmit",
      year: "current", // 🗓️ Default current year
    },
    mode: "onChange",
    resolver: zodResolver(paymentFilterFormSchema),
  });

  // Destructure watch first
  const {watch} = misPaymentsReceivedFormMethods;

  // Watch for module changes to update status filters
  useEffect(() => {
    const subscription = watch((value, {name}) => {
      if (name === "module") {
        const form = misPaymentsReceivedFormMethods;
        if (value.module === "imREmit") {
          form.setValue("status", [
            {label: "Delivered", value: "Delivered"},
            {label: "Closed", value: "Closed"},
          ]);
        } else if (value.module === "imREmit_lite") {
          form.setValue("status", [{label: "Closed", value: "Closed"}]);
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, misPaymentsReceivedFormMethods]);

  // 🔍 Watch the form values to dynamically generate query params
  const formValues = watch(); // Get the current form values
  const queryParams = useMISQueryParams(formValues); // 🔄 Generate query string based on form state

  // 📡 Fetch payments received data based on the query params
  const {data, isSuccess, isError} = useGetMISReceivedPayments(queryParams);
  const receivedPayments = useMemo(() => data?.content || [], [data?.content]); // 🗂️ Memoize receivedPayments to prevent unnecessary re-renders

  // 📝 Extract unique months and quarters from the data
  const [months, setMonths] = useState<string[]>([]);
  const [quarters, setQuarters] = useState<string[]>([]);

  useEffect(() => {
    if (receivedPayments.length > 0) {
      const monthsSet = new Set<string>();
      const quartersSet = new Set<string>();

      receivedPayments.forEach((buyer: MISPaymentsReceivedType) => {
        buyer.monthly_data.forEach((monthData) => {
          const monthYear = `${monthData.month.toString()} ${monthData.year.toString()}`;
          monthsSet.add(monthYear);
        });

        buyer.quarterly_totals.forEach((quarterData) => {
          const quarterYear = `${quarterData.quarter.toString()} ${quarterData.year.toString()}`;
          quartersSet.add(quarterYear);
        });
      });

      const monthsArray = Array.from(monthsSet);
      const quartersArray = Array.from(quartersSet);

      // Sort months and quarters
      const monthOrder = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      monthsArray.sort((a, b) => {
        const [monthA, yearA] = a.split(" ");
        const [monthB, yearB] = b.split(" ");

        const yearDiff = parseInt(yearA, 10) - parseInt(yearB, 10);
        if (yearDiff !== 0) {
          return yearDiff;
        }

        return monthOrder.indexOf(monthA) - monthOrder.indexOf(monthB);
      });

      quartersArray.sort((a, b) => {
        const [quarterA, yearA] = a.split(" ");
        const [quarterB, yearB] = b.split(" ");

        const yearDiff = parseInt(yearA, 10) - parseInt(yearB, 10);
        if (yearDiff !== 0) {
          return yearDiff;
        }

        return (
          parseInt(quarterA.replace("Q", ""), 10) -
          parseInt(quarterB.replace("Q", ""), 10)
        );
      });

      setMonths(monthsArray);
      setQuarters(quartersArray);
    }
  }, [receivedPayments]);

  // 🛠️ Generate columns based on months and quarters
  const columns = generateMISPaymentsReceivedColumns(months, quarters);

  const isFormValid = formValues.customers && formValues.customers.length > 0;

  // 🖼️ Render the main page content
  return (
    <Dialog>
      <Collapsible open={isTableOpen} onOpenChange={setIsTableOpen}>
        <section className="space-y-4">
          {/* 🔍 Header section with title and customer selection */}
          <div className="flex flex-col gap-y-4 md:flex-row md:items-center md:justify-between">
            <div className="flex flex-col gap-y-2">
              <Heading2>MIS Payments Posted</Heading2> {/* 🏷️ Main heading */}
              <Paragraph>
                Please select a customer in filters to view the payments posted.
              </Paragraph>
              <div className="flex items-center gap-x-2">
                <CollapsibleTrigger asChild>
                  {isTableOpen ? (
                    <Button className="gap-2" variant="secondary">
                      Close Table <TablePropertiesIcon className="size-4" />
                    </Button>
                  ) : (
                    <Button className="gap-2">
                      Open Table <TablePropertiesIcon className="size-4" />
                    </Button>
                  )}
                </CollapsibleTrigger>
                <DialogTrigger asChild>
                  <Button className="gap-2">
                    Adjust Filters <FilterIcon className="size-4" />
                  </Button>
                </DialogTrigger>
              </div>
            </div>
          </div>

          <DialogContent className="min-w-full">
            <DialogHeader>
              <DialogTitle>Adjust Filters</DialogTitle>
            </DialogHeader>
            <DialogDescription>
              Adjust the filters to narrow down the payments displayed.
            </DialogDescription>

            {/* 📑 Form to filter payments */}
            <Form {...misPaymentsReceivedFormMethods}>
              <MISPaymentsReceivedForm showStatus={false} />
              {/* 🛠️ Render the form for filtering payments */}
            </Form>
            <DialogClose asChild>
              <DialogFooter className="hover:cursor-pointer hover:text-destructive-foreground">
                Close the dialog to view results.
              </DialogFooter>
            </DialogClose>
          </DialogContent>

          <CollapsibleContent>
            {/* 📑 Table to display payments */}
            <MISPaymentsReceivedDataTable
              columns={columns}
              data={receivedPayments}
              isSuccess={!isFormValid ? !isFormValid : isSuccess}
              isError={isError}
            />
          </CollapsibleContent>

          {/* 📊 Card to display the payments graph */}
          <Card>
            <CardHeader>
              <CardTitle>Payments Posted Graph</CardTitle> {/* 🏷️ Card title */}
              <CardDescription>
                This graph displays the payments posted for each month and
                quarter. Open the table to view the data. Open the filters to
                narrow down the data.
              </CardDescription>
            </CardHeader>
            <CardContent>
              <ParentSize>
                {({width}) => (
                  <MISPaymentsReceivedGraph
                    data={receivedPayments} // 📈 Data to display in the graph
                    width={width} // 🔄 Responsive width
                    height={720} // 📏 Fixed height
                  />
                )}
              </ParentSize>
            </CardContent>
          </Card>
        </section>
      </Collapsible>
    </Dialog>
  );
}
