import {useMemo, useState} from "react";

import {useMutation} from "@tanstack/react-query";
import {CheckIcon, ChevronsUpDownIcon, LucideBadgeCheck} from "lucide-react";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {FormControl} from "@/components/ui/form";
import {Label} from "@/components/ui/label";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Spinner} from "@/components/ui/spinner";
import {Paragraph} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {cn} from "@/lib/utils";
import {useAllRealmGroups, useGetUsersByRoleGroup} from "@/modules/admin";
import {calculateUserRoles, useKeyCloakInstanceStore} from "@/modules/auth";
import type {PaymentCommentsFormSchema} from "@/modules/imremit";
import {paymentSubmittedFn} from "@/modules/imremit";

import {KeycloakRolesWithHyphen} from "@/utils/constants";

interface ProxyPayAssignUserProps {
  paymentDetailId: string;
}

export function ProxyPayAssignUser({paymentDetailId}: ProxyPayAssignUserProps) {
  //Fetching logged user role
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  // State for assigning to imREmit User
  const [assignUser, setAssignUser] = useState("");

  // Get the group id
  const realmGroupsList = useAllRealmGroups();
  const getMagellanCsrId =
    realmGroupsList.data?.find(
      ({name}) => name === KeycloakRolesWithHyphen.EPayablesUser
    )?.id || "";

  /**
   * Get ImRemit User List
   * Role Group Id for imREmit User
   */
  const magellanUserListQuery = useGetUsersByRoleGroup(getMagellanCsrId);
  const magellanUserList = magellanUserListQuery.data;

  // Format the users data to match the format needed by.
  const usersData = useMemo(() => {
    if (!magellanUserList) return [];
    return magellanUserList.map((users) => ({
      label: `${users.firstName} ${users.lastName}`,
      value: `${users.firstName} ${users.lastName}`,
    }));
  }, [magellanUserList]);

  /**
   * Assign Payment
   * @param {paymentStatus} - Payment Status PAID
   * @param {paymentDetailId} - payment id
   * @param {comments} - comments
   *
   */
  const assignPayment = useMutation({
    mutationFn: (data: z.infer<typeof PaymentCommentsFormSchema>) => {
      return paymentSubmittedFn(data, paymentDetailId, "FAILED");
    },
    onSuccess: (response) => {
      console.log("assignPaymentResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Payment assigned successfully",
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to assign payment",
      });
    },
  });

  /**
   * @type {boolean}
   */
  const assignPaymentIsMutating = assignPayment.isPending;

  return (
    <>
      <Label className="flex space-x-2">
        <Paragraph>Assign to Magellan Supervisor :</Paragraph>
      </Label>
      <div className="grid w-full grid-cols-2 gap-2">
        <Popover>
          <PopoverTrigger asChild>
            <FormControl>
              <Button
                variant="outline"
                disabled={assignPaymentIsMutating}
                aria-disabled={assignPaymentIsMutating}
                role="combobox"
                className={cn(
                  "justify-between",
                  !assignUser && "text-accent-foreground"
                )}
              >
                {assignUser
                  ? usersData.find(
                      (option) =>
                        option.value.toLowerCase() === assignUser.toLowerCase()
                    )?.label
                  : "Select Magellan Supervisor..."}
                <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
              </Button>
            </FormControl>
          </PopoverTrigger>
          <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
            <Command>
              <CommandInput placeholder="Search Magellan Supervisor..." />
              <CommandEmpty>No Magellan Supervisor found.</CommandEmpty>
              <CommandGroup>
                {usersData.map((option) => (
                  <CommandItem
                    key={option.value}
                    value={option.value}
                    onSelect={(value) => {
                      setAssignUser(value);
                    }}
                  >
                    <CheckIcon
                      className={cn(
                        "mr-2 size-4",
                        option.value.toLowerCase() === assignUser
                          ? "opacity-100"
                          : "opacity-0"
                      )}
                    />
                    {option.label}
                  </CommandItem>
                ))}
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>

        <Button
          className="mb-2"
          aria-label="Assign"
          type="button"
          variant="secondary"
          disabled={assignPaymentIsMutating || assignUser == "" ? true : false}
          aria-disabled={
            assignPaymentIsMutating || assignUser == "" ? true : false
          }
          onClick={() => {
            assignPayment.mutate({
              userId: assignUser,
              comments: "Payment Attempt Failed from Supplier.",
              role: userRoleDisplay,
            });
          }}
        >
          {assignPaymentIsMutating ? (
            <Spinner size="xs" />
          ) : (
            <LucideBadgeCheck className="mr-2 size-4" />
          )}
          Assign
        </Button>
      </div>
    </>
  );
}
