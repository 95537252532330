import {createRoute} from "@tanstack/react-router";
import type {z} from "zod";

import {RoutesDirectory} from "@/lib/routes/directory";
import {
  AddImREmitPage,
  EditPaymentDetailsPage,
  imREmitRootRoute,
  imremitSearchFilterSchema,
  PaymentManagementPage,
  PaymentManagementRedirect,
  PaymentManagementSearchFilterList,
  RemittanceManagementPage,
  RemittanceManagementRedirect,
  ViewPaymentDetailsPage,
  ViewRemittanceDetailsPage,
} from "@/modules/imremit";

export type PaymentManagementSearchFilterType = z.infer<
  typeof imremitSearchFilterSchema
>;

export const imREmitPaymentManagementRoute = createRoute({
  path: `${RoutesDirectory.PAYMENT_MANAGEMENT}/$pmPage${RoutesDirectory.DASHBOARD}`,
  component: PaymentManagementPage,
  getParentRoute: () => imREmitRootRoute,
  validateSearch: (search) => imremitSearchFilterSchema.parse(search),
});

export const imREmitPaymentManagementRedirectRoute = createRoute({
  path: RoutesDirectory.PAYMENT_MANAGEMENT,
  component: PaymentManagementRedirect,
  getParentRoute: () => imREmitRootRoute,
});

export const imREmitRemittanceManagementRoute = createRoute({
  path: `${RoutesDirectory.REMITTANCE_MANAGEMENT}/$pmPage${RoutesDirectory.DASHBOARD}`,
  component: RemittanceManagementPage,
  getParentRoute: () => imREmitRootRoute,
  validateSearch: (search) => imremitSearchFilterSchema.parse(search),
});

export const imREmitViewRemittanceRoute = createRoute({
  path: `${RoutesDirectory.REMITTANCE_MANAGEMENT}/$paymentId${RoutesDirectory.VIEW}/$list_type`,
  component: ViewRemittanceDetailsPage,
  getParentRoute: () => imREmitRootRoute,
});

export const imREmitRemittanceManagementRedirectRoute = createRoute({
  path: RoutesDirectory.REMITTANCE_MANAGEMENT,
  component: RemittanceManagementRedirect,
  getParentRoute: () => imREmitRootRoute,
});

export const imREmitAddPaymentRoute = createRoute({
  path: `${RoutesDirectory.PAYMENT_MANAGEMENT}${RoutesDirectory.CREATE}`,
  component: AddImREmitPage,
  getParentRoute: () => imREmitRootRoute,
});

export const imREmitViewPaymentRoute = createRoute({
  path: `${RoutesDirectory.PAYMENT_MANAGEMENT}/$paymentId${RoutesDirectory.VIEW}/$list_type`,
  component: ViewPaymentDetailsPage,
  getParentRoute: () => imREmitRootRoute,
});

export const imREmitEditPaymentRoute = createRoute({
  path: `${RoutesDirectory.PAYMENT_MANAGEMENT}/$paymentId${RoutesDirectory.EDIT}`,
  component: EditPaymentDetailsPage,
  getParentRoute: () => imREmitRootRoute,
});

export const imREmitFilterPaymentRoute = createRoute({
  path: `${RoutesDirectory.PAYMENT_MANAGEMENT}/$pmPage${RoutesDirectory.PAYMENTS}/$methodType`,
  component: PaymentManagementSearchFilterList,
  getParentRoute: () => imREmitRootRoute,
});
