import {useCallback, useEffect, useState} from "react";

import {useMutation} from "@tanstack/react-query";
import {useNavigate, useParams} from "@tanstack/react-router";
import {ArrowRightFromLineIcon, PlusIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {toast} from "@/components/ui/use-toast";
import {SortableTimeline} from "@/components/craft/draggable-timeline";

import {
  CaptchaTimerField,
  captchaTimerMethods,
  ExpandableScriptItem,
  loginSkip,
  PaymentTimerField,
  SkipLoginField,
  SupplierScriptForm,
  timerMethods,
} from "@/modules/imremit";
import type {
  ModuleTypeUnion,
  OptionProps,
  SupplierScriptItem,
} from "@/modules/imremit";
import {
  deleteSupplierScriptSequenceFn,
  editSupplierScriptFn,
} from "@/modules/landing";

// Props for the SupplierScriptsTimeline component
interface SupplierScriptsTimelineProps {
  supplierScriptItems: SupplierScriptItem[];
  supplierId: number;
  setCurrentSupplier: React.Dispatch<React.SetStateAction<string | number>>;
  module?: string;
}

// Main component to display a timeline of draggable supplier script items
export function SupplierScriptsTimeline({
  supplierScriptItems,
  module,
}: // moduleName,
SupplierScriptsTimelineProps) {
  const navigate = useNavigate();
  const {paymentId} = useParams({
    // from: "/app/imremit/supplier-script-management/$moduleName/$supplierId/edit/$paymentId",
    from: `/app/${
      module as ModuleTypeUnion
    }/supplier-script-management/$moduleName/$supplierId/edit/$paymentId`,
  });
  const [paymentTimerValue, setPaymentTimerValue] = useState<string>("10");
  const [captchaTimerValue, setCaptchTimerValue] = useState<string>("120");
  const [timerValue, setTimerValue] = useState<string>("");
  const [loginSkipValue, setLoginSkipValue] = useState<string>("");
  const [fieldNameValue, setFieldNameValue] = useState<string>("");
  const [fieldValue, setFieldValue] = useState<string>("");
  const [pageCategoryValue, setpageCategoryValue] = useState<string>("");
  const [fieldTypeValue, setFieldTypeValue] = useState<string>("");
  const [tableNameValue, setTableNameValue] = useState<string>("");
  const [tableColumnNameValue, setTableColumnNameValue] = useState<string>("");
  const [positionValue, setPositionValue] = useState<string>("");
  const [dropDownValue, setDropDownValue] = useState<string>("");
  const [separatorValue, setSeparatorValue] = useState<string>("");
  const [dateFormatValue, setDateFormatValue] = useState<string>("");
  const [sequenceIdentifierValue, setSequenceIdentifierValue] =
    useState<string>("");
  const [jsCheckValue, setJsCheckValue] = useState<string>("");
  const [findElementByValue, setFindElementByValue] = useState<string>("");
  const [deletedSupplierScriptIds, setDeletedSupplierScriptIds] = useState<
    string[]
  >([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleAddScript = () => {
    setDialogOpen(false);
    // Create a new script item object
    const newScriptItem = {
      id: draggableSupplierScriptItems.length + 1,
      supplierScriptId: "-",
      supplierId: draggableSupplierScriptItems[0].supplierId,
      sequenceId: draggableSupplierScriptItems.length + 1,
      fieldName: fieldNameValue,
      fieldValue: fieldValue,
      loginSkip: loginSkipValue === "true" ? true : false,
      pageCategory: pageCategoryValue,
      dropDownValue: dropDownValue,
      fieldType: fieldTypeValue,
      position: positionValue,
      separator: separatorValue,
      tableName: tableNameValue,
      tableColumnName: tableColumnNameValue,
      timer: timerValue,
      captchaTimer: parseInt(captchaTimerValue),
      paymentTimer: parseInt(paymentTimerValue),
      dateFormat: dateFormatValue,
      sequenceIdentifier: sequenceIdentifierValue,
      jsCheck: jsCheckValue,
      findElementBy: findElementByValue,
    };

    // Update the state of the timeline to include the new script item
    setdraggableSupplierScriptItems((prevItems) => [
      ...prevItems,
      newScriptItem,
    ]);

    toast({
      variant: "success",
      title: "You successfully added another Script!",
    });
  };

  useEffect(() => {
    setTableNameValue("");
    setTableColumnNameValue("");
    setPositionValue("");
    setSeparatorValue("");
    setDateFormatValue("");
    setDropDownValue("");
    setSequenceIdentifierValue("");
    setJsCheckValue("");
    setFindElementByValue("");
    setFieldValue("");
  }, [fieldTypeValue]);

  const handleInputValueChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const {name, value} = event.target;
      switch (name) {
        case "fieldName":
          setFieldNameValue(value);
          break;
        case "fieldValue":
          setFieldValue(value);
          break;
        case "pageCategory":
          setpageCategoryValue(value);
          break;
        case "fieldType":
          setFieldTypeValue(value);
          break;
        case "position":
          setPositionValue(value);
          break;
        case "separator":
          setSeparatorValue(value);
          break;
        case "sequenceIdentifier":
          setSequenceIdentifierValue(value);
          break;
        case "jsCheck":
          setJsCheckValue(value);
          break;
        case "dropDownValue":
          setDropDownValue(value);
          break;
        default:
          break;
      }
    },
    []
  );

  const handleInputSelectChange = useCallback(
    (script: OptionProps, scriptType: string) => {
      switch (scriptType) {
        case "tableName":
          setTableNameValue(script.value);
          setTableColumnNameValue("");
          break;
        case "tableColumnName":
          setTableColumnNameValue(script.value);
          break;
        case "fieldType":
          setFieldTypeValue(script.value);
          break;
        case "dateFormat":
          setDateFormatValue(script.value);
          break;
        case "timer":
          setTimerValue(script.value);
          break;
        case "findElementBy":
          setFindElementByValue(script.value);
          break;
        default:
          break;
      }
    },
    []
  );

  // supplier script page url
  const supplierScriptPageUrl = `/app/${
    module as ModuleTypeUnion
  }/supplier-script-management` as const;

  // proxy pay dashboard view page url
  const proxyPayDashboardViewPageUrl = `/app/${
    module as ModuleTypeUnion
  }/proxy-pay-dashboard/$paymentId/view` as const;

  const handleTimerInputSelectChange = useCallback(
    (script: OptionProps, scriptType: string) => {
      switch (scriptType) {
        case "paymentTimer":
          setPaymentTimerValue(script.value);
          break;
        case "captchaTimer":
          setCaptchTimerValue(script.value);
          break;
        case "loginSkip":
          setLoginSkipValue(script.value);
          break;
        default:
          break;
      }
    },
    [setCaptchTimerValue, setPaymentTimerValue, setLoginSkipValue]
  );

  const editSupplierScriptMutation = useMutation({
    mutationFn: (items: {supplierScriptItems: SupplierScriptItem[]}) => {
      return editSupplierScriptFn(
        items.supplierScriptItems,
        supplierScriptItems[0].supplierId.toString(),
        captchaTimerValue,
        paymentTimerValue
      );
    },

    onSuccess: async (response) => {
      console.log("editSupplierScriptResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Supplier script updated successfully",
      });
      if (paymentId === "0") {
        void navigate({
          to: supplierScriptPageUrl,
          replace: true,
        });
      } else {
        void navigate({
          to: proxyPayDashboardViewPageUrl,
          replace: true,
          params: {paymentId: paymentId},
        });
      }
      // Delete the scripts if there are any to delete
      if (deletedSupplierScriptIds.length > 0) {
        try {
          await deleteSupplierScriptSequenceFn(deletedSupplierScriptIds);
          setDeletedSupplierScriptIds([]);
        } catch (error) {
          toast({
            variant: "destructive",
            title: "Unable to delete supplier scripts!",
          });
        }
      }
    },
    onError: (error) => {
      console.error("errorMessage", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to edit supplier script",
      });
    },
  });

  const [draggableSupplierScriptItems, setdraggableSupplierScriptItems] =
    useState(supplierScriptItems);

  useEffect(() => {
    setdraggableSupplierScriptItems(supplierScriptItems);
  }, [supplierScriptItems, setdraggableSupplierScriptItems]);

  // Function to find the index of an item by its 'id'
  const returnIndexById = useCallback(
    (id: number) => {
      const index = draggableSupplierScriptItems.findIndex(
        (item) => item.id === id
      );

      // Throw an error if the item with given 'id' is not found
      if (index === -1) {
        throw new Error(
          `Item with id ${id.toString()} not found in draggableSupplierScriptItems`
        );
      }

      return index;
    },
    [draggableSupplierScriptItems]
  );

  const currentSupplierId = draggableSupplierScriptItems[0].supplierId;
  const currentItem = draggableSupplierScriptItems[0];

  useEffect(() => {
    if (currentSupplierId) {
      const paymentTimerValue =
        typeof currentItem.paymentTimer !== "undefined" &&
        currentItem.paymentTimer !== 0
          ? currentItem.paymentTimer.toString()
          : "10";
      setPaymentTimerValue(paymentTimerValue);

      const captchaTimerValue =
        typeof currentItem.captchaTimer !== "undefined" &&
        currentItem.captchaTimer !== 0
          ? currentItem.captchaTimer.toString()
          : "120";
      setCaptchTimerValue(captchaTimerValue);

      const loginSkipValue = (currentItem.loginSkip ?? "").toString();
      setLoginSkipValue(loginSkipValue);
    }
  }, [
    currentSupplierId,
    currentItem.captchaTimer,
    currentItem.paymentTimer,
    currentItem.loginSkip,
  ]);

  return (
    <>
      <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-3 xl:grid-cols-3">
        <PaymentTimerField
          inputValue={paymentTimerValue}
          handleInputSelectChange={handleTimerInputSelectChange}
          allItems={timerMethods}
          defaultInputValue="10"
          isMutating={false}
        />
        <CaptchaTimerField
          isMutating={false}
          inputValue={captchaTimerValue}
          handleInputSelectChange={handleTimerInputSelectChange}
          allItems={captchaTimerMethods}
          defaultInputValue="120"
        />
        <SkipLoginField
          isMutating={false}
          inputValue={loginSkipValue}
          handleInputSelectChange={handleTimerInputSelectChange}
          allItems={loginSkip}
        />
      </div>
      <SortableTimeline
        items={draggableSupplierScriptItems}
        // Rendering each item within the SortableTimeline
        renderItem={(item) => {
          return (
            <SortableTimeline.Item
              key={item.id}
              sortableItemTitle={`Supplier script id: ${item.supplierScriptId}`}
              sortableItemId={item.id}
              sortableItemPosition={`Sequence position: ${(
                returnIndexById(item.id) + 1
              ).toString()}`}
            >
              <div className="flex w-full items-start gap-x-3">
                {/* Display the expandable script item */}
                <ExpandableScriptItem
                  item={item}
                  index={returnIndexById(item.id)}
                  isMutating={editSupplierScriptMutation.isPending}
                  draggableSupplierScriptItems={draggableSupplierScriptItems}
                  setDeletedSupplierScriptIds={setDeletedSupplierScriptIds}
                  deletedSupplierScriptIds={[]}
                  setdraggableSupplierScriptItems={
                    setdraggableSupplierScriptItems
                  }
                />

                <SortableTimeline.DragHandle />
              </div>
            </SortableTimeline.Item>
          );
        }}
        // Handling changes in the order of items
        onChange={(newItems) => {
          // Update sequence IDs based on the new order
          const updatedItems = newItems.map((item, index) => ({
            ...item,
            sequenceId: index + 1, // Assigning sequenceId based on the index
          }));

          // Update the state with the new items
          setdraggableSupplierScriptItems(updatedItems);
        }}
      />
      <div className="flex items-center space-x-2">
        <Button
          disabled={editSupplierScriptMutation.isPending}
          onClick={() => {
            const updatedSupplierScriptItems = draggableSupplierScriptItems.map(
              (item) => {
                const updatedItem = {...item};
                if (loginSkipValue === "true" || loginSkipValue === "false") {
                  updatedItem.loginSkip =
                    loginSkipValue === "true" ? true : false;
                }
                return updatedItem;
              }
            );

            editSupplierScriptMutation.mutate({
              supplierScriptItems: updatedSupplierScriptItems,
            });
          }}
        >
          Save Script <ArrowRightFromLineIcon className="ml-2" />
        </Button>

        <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
          <Dialog open={dialogOpen}>
            <DialogTrigger asChild>
              <Button
                className="ml-2"
                variant={"affirmative"}
                onClick={() => {
                  setDialogOpen(true);
                  setFieldNameValue("");
                  setFieldValue("");
                  setpageCategoryValue("");
                  setFieldTypeValue("");
                  setTableNameValue("");
                  setTableColumnNameValue("");
                  setPositionValue("");
                  setSeparatorValue("");
                  setDateFormatValue("");
                  setTimerValue("");
                  setDropDownValue("");
                  setSequenceIdentifierValue("");
                  setJsCheckValue("");
                  setFindElementByValue("");
                }}
              >
                Add Field <PlusIcon className="ml-2" />
              </Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[900px]" removeCloseButton>
              <DialogHeader>
                <DialogTitle>Add Supplier Script</DialogTitle>
                <DialogDescription>
                  Add your changes to the supplier script here.
                </DialogDescription>
              </DialogHeader>
              <div className="borderorder mb-8 rounded-md border bg-root p-4">
                <div className="mb-10 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3">
                  <SupplierScriptForm
                    isMutating={false}
                    handleInputValueChange={handleInputValueChange}
                    handleInputSelectChange={handleInputSelectChange}
                    pageCategoryValue={pageCategoryValue}
                    fieldNameValue={fieldNameValue}
                    fieldValue={fieldValue}
                    fieldTypeValue={fieldTypeValue}
                    tableNameValue={tableNameValue}
                    tableColumnNameValue={tableColumnNameValue}
                    positionValue={positionValue}
                    dropDownValue={dropDownValue}
                    separatorValue={separatorValue}
                    dateFormatValue={dateFormatValue}
                    sequenceIdentifierValue={sequenceIdentifierValue}
                    timerValue={timerValue}
                    jsCheckValue={jsCheckValue}
                    findElementByValue={findElementByValue}
                  />
                </div>
              </div>
              <DialogFooter>
                <Button
                  className="mr-2"
                  variant={"secondary"}
                  onClick={() => {
                    setDialogOpen(false);
                    setFieldNameValue("");
                    setFieldValue("");
                    setpageCategoryValue("");
                    setFieldTypeValue("");
                    setTableNameValue("");
                    setTableColumnNameValue("");
                    setPositionValue("");
                    setSeparatorValue("");
                    setDateFormatValue("");
                    setTimerValue("");
                    setDropDownValue("");
                    setSequenceIdentifierValue("");
                    setJsCheckValue("");
                    setFindElementByValue("");
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  onClick={() => {
                    if (timerValue === "") {
                      return;
                    } else if (
                      (fieldTypeValue.trim() === "alert" ||
                        fieldTypeValue.trim() === "dropdown") &&
                      dropDownValue.trim() === ""
                    ) {
                      return;
                    } else if (
                      fieldTypeValue !== "payment_loop_start" &&
                      fieldTypeValue !== "payment_loop_end" &&
                      pageCategoryValue.trim() === ""
                    ) {
                      return;
                    } else if (
                      fieldNameValue.trim() === "" ||
                      fieldTypeValue.trim() === "" ||
                      fieldTypeValue.trim() === "select"
                    ) {
                      return;
                    } else if (
                      fieldTypeValue !== "iframe_exit" &&
                      fieldTypeValue !== "handle_window" &&
                      fieldTypeValue !== "switch_to_previous_tab" &&
                      fieldTypeValue !== "get_reCAPTCHA_v2_response" &&
                      fieldTypeValue !== "insert_reCAPTCHA_v2_response" &&
                      fieldTypeValue !== "input_invoice_numbers_amounts" &&
                      fieldTypeValue !== "payment_loop_start" &&
                      fieldTypeValue !== "payment_loop_end" &&
                      fieldTypeValue !== "click_link_text" &&
                      fieldValue.trim() === ""
                    ) {
                      return;
                    } else if (
                      (fieldTypeValue === "input" ||
                        fieldTypeValue === "dropdown" ||
                        fieldTypeValue === "date_picker_table" ||
                        fieldTypeValue === "label_text") &&
                      (tableNameValue.trim() === "" ||
                        tableNameValue.trim() === "select" ||
                        tableColumnNameValue.trim() === "")
                    ) {
                      return;
                    } else if (
                      (fieldTypeValue === "input" ||
                        fieldTypeValue === "dropdown" ||
                        fieldTypeValue === "date_picker_table" ||
                        fieldTypeValue === "error" ||
                        fieldTypeValue === "confirmation_number" ||
                        fieldTypeValue === "alert" ||
                        fieldTypeValue === "pop_up_msg_ok_yes" ||
                        fieldTypeValue === "checkBox" ||
                        fieldTypeValue === "captcha_checkbox" ||
                        fieldTypeValue === "captcha_image" ||
                        fieldTypeValue === "captcha_image_value" ||
                        fieldTypeValue === "button" ||
                        fieldTypeValue === "label_text" ||
                        fieldTypeValue === "iframe" ||
                        fieldTypeValue === "scroll_to_element" ||
                        fieldTypeValue === "login_button" ||
                        fieldTypeValue === "switch_to_tab" ||
                        fieldTypeValue === "switch_to_window" ||
                        fieldTypeValue === "switch_to_previous_tab" ||
                        fieldTypeValue === "search_invoices_in_div") &&
                      findElementByValue.trim() === ""
                    ) {
                      return;
                    } else {
                      handleAddScript();
                    }
                  }}
                >
                  Save
                </Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      </div>
    </>
  );
}
