import {CoinsIcon} from "lucide-react";

import {Badge} from "@/components/ui/badge";

import {
  billingTypes,
  paymentMethodTypes,
  paymentTypes,
  settlementTermsTypes,
} from "@/modules/admin";

export function renderBillingTypeBadge(billingType: string) {
  const billingObject = billingTypes.find(
    (billing) => billing.value === billingType
  );

  if (!billingObject) {
    return (
      <Badge className="flex items-center gap-2 uppercase hover:opacity-70">
        <CoinsIcon className="size-4" />
        {billingType}
      </Badge>
    );
  }

  const IconComponent = billingObject.icon;

  return (
    <Badge className="flex items-center gap-2 uppercase hover:opacity-70">
      <IconComponent className="size-4" />
      {billingType}
    </Badge>
  );
}

export function renderPaymentType(paymentType: string) {
  const paymentObject = paymentTypes.find(
    (payment) => payment.value === paymentType
  );

  if (!paymentObject) {
    return (
      <Badge className="flex items-center gap-2 uppercase hover:opacity-70">
        <CoinsIcon className="size-4" />
        {paymentType}
      </Badge>
    );
  }

  const IconComponent = paymentObject.icon;

  return (
    <Badge className="flex items-center gap-2 uppercase hover:opacity-70">
      <IconComponent className="size-4" />
      {paymentType}
    </Badge>
  );
}

export function renderPaymentMethod(paymentMethod: string) {
  const paymentObject = paymentMethodTypes.find(
    (payment) => payment.value === paymentMethod
  );

  if (!paymentObject) {
    return (
      <Badge className="flex items-center gap-2 uppercase hover:opacity-70">
        <CoinsIcon className="size-4" />
        {paymentMethod}
      </Badge>
    );
  }

  const IconComponent = paymentObject.icon;

  return (
    <Badge className="flex items-center gap-2 uppercase hover:opacity-70">
      <IconComponent className="size-4" />
      {paymentMethod}
    </Badge>
  );
}

export function renderSettlementTerms(settlementTerm: string) {
  const settlementTermsObject = settlementTermsTypes.find(
    (settlementTerms) => settlementTerms.value === settlementTerm
  );

  if (!settlementTermsObject) {
    return (
      <Badge className="flex items-center gap-2 uppercase hover:opacity-70">
        <CoinsIcon className="size-4" />
        {settlementTerm}
      </Badge>
    );
  }

  const IconComponent = settlementTermsObject.icon;

  return (
    <Badge className="flex items-center gap-2 uppercase hover:opacity-70">
      <IconComponent className="size-4" />
      {settlementTermsObject.label}
    </Badge>
  );
}
