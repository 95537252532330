import {useCallback, useMemo} from "react";

import {useMutation} from "@tanstack/react-query";
import {useNavigate} from "@tanstack/react-router";
import axios from "axios";
import {useFormContext} from "react-hook-form";
import type {z} from "zod";

import {UploadDropzone} from "@/components/ui/upload";
import {toast} from "@/components/ui/use-toast";
import type {MultiSelectDatum} from "@/components/craft/fancy-multi-select";

import {
  calculateUserRoles,
  useKeyCloakInstanceStore,
  useUserInfo,
} from "@/modules/auth";
import type {KeycloakTokenWithSupplierType} from "@/modules/customers";
import type {srUploadMetaResponseSchema} from "@/modules/statement-recon";
import {
  createUploadMetaSR,
  srCompleteMetaResponseSchema,
} from "@/modules/statement-recon";

import {KeycloakRoles} from "@/utils/constants";

interface StatementReconUploadDropzoneProps {
  buyerExternalId: string;
}

export function StatementReconUploadDropzone({
  buyerExternalId,
}: StatementReconUploadDropzoneProps) {
  const navigate = useNavigate();
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const {watch} = useFormContext();

  // eslint-disable-next-line
  const supplierFormData: MultiSelectDatum[] = watch("suppliers");
  const supplierIds = supplierFormData
    .map((supplierData) => supplierData.value)
    .toString();

  // const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  // const userSub = storeKeyCloakInstance?.tokenParsed?.sub;

  const {parsedUserInfo} = useUserInfo();

  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  const isASupplierUser = useMemo(
    () => userRoleDisplay === KeycloakRoles.SupplierEnablement,
    [userRoleDisplay]
  );

  const supplierUser =
    storeKeyCloakInstance?.tokenParsed as KeycloakTokenWithSupplierType;

  // Define the mutation for creating file metadata 📝
  const createFileMetaMutation = useMutation({
    // Define the mutation function
    mutationFn: async (data: z.infer<typeof srUploadMetaResponseSchema>) => {
      const reqData = {
        buyer_external_id: buyerExternalId,
        statement_file_location: data.destinationPath,
        user_statement_file_name: data.originalName,
        statement_file_name: data.destinationPath.substring(
          data.destinationPath.lastIndexOf("/") + 1
        ),
        statement_file_size: data.size.toString(),
        statement_file_mime_type: data.mimeType,
        statement_file_status: 1,
        statement_uploaded_by:
          (parsedUserInfo?.name as string) || "Unknown user",
        supplier_ids: isASupplierUser ? supplierUser.supplier : supplierIds,
      };

      console.log("reqData", reqData);

      return createUploadMetaSR(reqData);
    },

    // Handle the mutation success 🎉
    onSuccess: (createFileMetaMutationResponse) => {
      console.log(
        "createFileMetaMutationResponse",
        createFileMetaMutationResponse
      );

      toast({
        variant: "success",
        title: "Success!",
        description: "File uploaded successfully",
      });

      // if statement mime type is jpg or pdf, navigate to OCR page
      if (
        createFileMetaMutationResponse.content.statement_file_mime_type ===
          "image/jpeg" ||
        createFileMetaMutationResponse.content.statement_file_mime_type ===
          "application/pdf"
      ) {
        void navigate({
          to: `/app/statement-recon/file-history`,
          replace: true,
        });
        return;
      }

      // Navigate to the mapping page 🗺
      void navigate({
        to: "/app/statement-recon/upload/$fileId/mapping/$externalId",
        params: {
          fileId:
            // set the file id to the response or default to 0
            createFileMetaMutationResponse.content.statement_file_id || "0",
          externalId: buyerExternalId,
        },
        replace: true,
      });
    },

    // Handle the mutation error 🚨
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to upload file",
      });
    },
  });

  const uploadEnv = (import.meta.env.VITE_KEYCLOAK_REALM as string) || "dev";
  const {mutate} = createFileMetaMutation;

  // Define the onDrop handler for file uploads 📂
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const formData = new FormData();
      formData.append("im-sr-upload", acceptedFiles[0]);

      console.log("uploadEnv", uploadEnv);

      try {
        const response = await axios.post(
          `https://${
            uploadEnv === "prod" ? "invoicetracker" : "ntdevtracker"
          }.iterationm.com/upload/v1/${uploadEnv}/single`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const parsedResponse = srCompleteMetaResponseSchema.parse(
          response.data
        );

        mutate(parsedResponse.data.content.file);
        // 🎉 Successfully uploaded the file
      } catch (error) {
        console.error(error);
        // 😞 Error uploading the file
        let errorMessage = "An error occurred";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error instanceof Error && error.message) {
          errorMessage = error.message;
        }
        toast({
          variant: "destructive",
          title: "Error uploading file!",
          description: errorMessage,
        });
      }
    },
    [mutate, uploadEnv]
  );

  return (
    <UploadDropzone
      data-testid="sr-upload-dropzone"
      maxFiles={1}
      accept={{
        // Flat file support for Statement Recon
        ".csv": ["text/csv", ".csv"],
        ".xls": ["application/vnd.ms-excel", ".xls"],
        ".xlsx": [
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ".xlsx",
        ],
        // PDF and JPG file support for OCR
        ".pdf": ["application/pdf", ".pdf"],
        ".jpg": ["image/jpeg", ".jpg"],
      }}
      onDrop={onDrop}
    />
  );
}
