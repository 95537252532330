import {create} from "zustand";

import {type proxyDashbaordFilterType} from "@/modules/imremit";

// Defining the shape of the state object for the ProxyDashboardFilterStore
// In this case, the state will consist of an array of objects
interface ProxyDashboardFilterStoreState {
  storeProxyDashboardFilter: proxyDashbaordFilterType | null;
}

// Defining the actions that can be performed on the ProxyDashboardFilterStore state
// In this case, there's only one action, updatestoreProxyDashboardFilter, which takes an array of objects
interface CustomerStoreActions {
  updatestoreProxyDashboardFilter: (
    storeProxyDashboardFilter: ProxyDashboardFilterStoreState["storeProxyDashboardFilter"]
  ) => void;
}

// Creating the Zustand store using the create function
// The store is named useProxyDashboardFilterStore, and it uses the types defined above for its state and actions
// The state begins with an empty storeProxyDashboardFilter array
// The updatestoreProxyDashboardFilter action uses Zustand's set function to update the state
export const useProxyDashboardFilterStore = create<
  ProxyDashboardFilterStoreState & CustomerStoreActions
>((set) => ({
  // Initial state of the store. An empty array of storeProxyDashboardFilter.
  storeProxyDashboardFilter: null,

  // Action to update the storeProxyDashboardFilter array in the store.
  // This function takes a new storeProxyDashboardFilter array and uses the set function to update the state.
  updatestoreProxyDashboardFilter: (storeProxyDashboardFilter) => {
    set(() => ({storeProxyDashboardFilter}));
  },
}));
