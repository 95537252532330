import {useEffect, useState} from "react";

import {CheckIcon, ChevronsUpDownIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Command, CommandGroup, CommandItem} from "@/components/ui/command";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";

// Use custom hook to access and mutate user preferences
import {usePreferencesStore, type NumRowsType} from "@/lib/stores";
import {cn} from "@/lib/utils";

import {ROWS_PER_PAGE} from "@/utils/constants";

// React component for allowing user to select number of rows displayed
export function RowsPreferenceSelect() {
  // Hook into the user's preferences for number of rows displayed
  const {numRowsPerPage, setNumRowsPerPage} = usePreferencesStore();
  // State for the number of rows displayed
  // Needed to help nudge type inference here lol
  const [selectedNumRowsPerPage, setSelectedNumRowsPerPage] =
    useState<string>(numRowsPerPage);

  // Update the state when the user's preferences change
  useEffect(() => {
    setNumRowsPerPage(selectedNumRowsPerPage as NumRowsType);
  }, [setNumRowsPerPage, selectedNumRowsPerPage]);

  // Add a popover open setOpen useState

  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} modal={true} onOpenChange={setOpen}>
      <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
        <Command>
          <CommandGroup>
            {ROWS_PER_PAGE.map((status) => (
              <CommandItem
                key={status}
                value={status}
                onSelect={() => {
                  setSelectedNumRowsPerPage(status);
                  setOpen(false);
                }}
              >
                {status}
                {selectedNumRowsPerPage === status ? (
                  <CheckIcon className="ml-2 size-4" />
                ) : null}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          className={cn(
            "justify-between",
            !selectedNumRowsPerPage && "text-accent-foreground",
            "w-[80px]"
          )}
        >
          {selectedNumRowsPerPage ? selectedNumRowsPerPage : "Rows"}
          <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
    </Popover>
  );
}
