import {useEffect} from "react";

import {Heading2, Paragraph} from "@/components/ui/typography";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {ImREmitRoutesSchema} from "@/lib/routes/types";
import {useImRemitCustomerStore, useRoutesStore} from "@/lib/stores";
import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useAuthorization,
} from "@/modules/auth";
import {
  SelectCustomerFormImRemit,
  SelectCustomerSectionImremit,
  type CustomerType,
} from "@/modules/customers";
import {
  ImREmitDashboardCards,
  ImREmitDashboardCustomerCard,
  type PaymentProvider,
} from "@/modules/imremit";

/**
 * Props for the CustomerProps interface.
 * @property {ImRemitCustomerType} imREmitStoreCustomer - Customer data from the ImRemit store.
 * @property {PaymentProvider[]} [paymentProviders] - Optional array of payment providers.
 */
export interface CustomerProps {
  imREmitStoreCustomer: CustomerType;
  paymentProviders?: PaymentProvider[];
}

/**
 * Props for the ImREmitDashboardContent component.
 * @property {ImRemitCustomerType} customer - The currently selected customer's data.
 */
interface ImREmitDashboardContentProps {
  customer: CustomerType;
}

/**
 * ImREmitDashboardContent component.
 * Renders the main content of the dashboard, including an overview of the customer's ImREmit payments.
 * @param {ImREmitDashboardContentProps} props - Props containing the customer data.
 * @returns {JSX.Element} JSX to render the main content section.
 */
function ImREmitDashboardContent({customer}: ImREmitDashboardContentProps) {
  return (
    <>
      {/* Section for the dashboard overview */}
      <header className="flex justify-between pb-4">
        <div className="flex flex-col">
          <Heading2>Your imREmit overview</Heading2>
          <Paragraph>
            Here you can find a quick overview of your imREmit payments.
          </Paragraph>
        </div>
        <SelectCustomerFormImRemit requireLabel />
      </header>

      <section className="flex flex-col items-start gap-4 xl:flex-row-reverse">
        {/* Individual flex item styling for self alignment */}
        <div className="w-full self-start 2xl:basis-1/4">
          <ImREmitDashboardCustomerCard customer={customer} />
        </div>
        {/* Grid layout for dashboard cards with self-start to prevent stretching */}
        <div className="grid w-full grid-cols-1 gap-4 self-start md:grid-cols-1 xl:grid-cols-2 2xl:basis-3/4 2xl:grid-cols-3">
          <ImREmitDashboardCards externalId={customer.externalId} />
        </div>
      </section>
    </>
  );
}

/**
 * ImREmitDashboardPage component.
 * This is the main functional component for the ImREmit Dashboard Page.
 * It utilizes several hooks and components to manage and render the dashboard.
 * @returns {JSX.Element} JSX to render the complete dashboard page.
 */
export function ImREmitDashboardPage() {
  // Hook to manage the current route state.
  const {setimREmitRoute} = useRoutesStore();
  // Hook to retrieve the current customer's data from the ImRemit customer store.
  const {imREmitStoreCustomer} = useImRemitCustomerStore();

  // Effect hook to set the current ImREmit route when the component is mounted.
  useEffect(() => {
    setimREmitRoute(ImREmitRoutesSchema.Values["/app/imremit/dashboard"]);
  }, [setimREmitRoute]);

  const {checkPoliciesAccess} = useAuthorization();

  // Handle unauthorized access
  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_IMREMIT_DASHBOARD])) {
    return <NotAuthorizedPage />;
  }

  // If the customer is not yet selected, render the SelectCustomerSectionImremit
  if (!imREmitStoreCustomer) {
    return <SelectCustomerSectionImremit />;
  }

  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.READ_IMREMIT_DASHBOARD]}
    >
      <ImREmitDashboardContent customer={imREmitStoreCustomer} />
    </AccessPolicyWrapper>
  );
}
