import {z} from "zod";

export const invoiceSchema = z.object({
  id: z.number(),
  taxCode: z.string().nullable(),
  invoiceDate: z.string().nullable(),
  invoiceAmount: z.string().nullable(),
  paymentDate: z.string().nullable(),
  facilityName: z.string().nullable(),
  facilityNumber: z.string().nullable(),
  invoiceNo: z.string().nullable(),
  status: z
    .enum([
      "approved",
      "cancelled",
      "pending",
      "pendingApproval",
      "paid",
      "deleted",
      "void",
    ])
    .nullable(),
  supplierName: z.string().nullable(),
  customerName: z.string().nullable(),
  paymentNumber: z.string().nullable(),
  paidAmount: z.number().nullable(),
  erpUniqueId: z.string().nullable(),
  poNumber: z.string().nullable(),
  paymentMethod: z.string().nullable(),
});

export type Invoice = z.infer<typeof invoiceSchema>;

export const invoiceTableColumnsSchema = z.object({
  // id: z.boolean(),
  taxCode: z.boolean(),
  invoiceDate: z.boolean(),
  invoiceAmount: z.boolean(),
  paymentDate: z.boolean(),
  facilityName: z.boolean(),
  facilityNumber: z.boolean(),
  invoiceNo: z.boolean(),
  status: z.boolean(),
  supplierName: z.boolean(),
  customerName: z.boolean(),
  paymentNumber: z.boolean(),
  paidAmount: z.boolean(),
  erpUniqueId: z.boolean(),
  poNumber: z.boolean(),
  paymentMethod: z.boolean(),
});

export type InvoiceTableColumnsType = z.infer<typeof invoiceTableColumnsSchema>;

export const invoicesCriteriaSchema = z.object({
  externalId: z.array(z.string()),
  supplierId: z.array(z.number()).nullable(),
  status: z.array(z.number()).nullable(),
  facilityId: z.array(z.number()).nullable(),
  invoiceDateStart: z.string().nullable(),
  invoiceDateEnd: z.string().nullable(),
  paymentDate: z.string().nullable(),
  paymentNumber: z.string().nullable(),
  poNumber: z.string().nullable(),
  invoiceNumber: z.string().nullable(),
  erpUniqueId: z.string().nullable(),
  invoiceAmount: z.string().nullable(),
  invoiceDate: z.string().nullable(),
});

export type InvoicesCriteriaType = z.infer<typeof invoicesCriteriaSchema>;

export const savedSearchesContentSchema = z.object({
  name: z.string(),
  criteria: invoicesCriteriaSchema,
});

export const savedSearchesDataSchema = z.object({
  content: z.array(savedSearchesContentSchema),
});

export type SavedSearchesDataType = z.infer<typeof savedSearchesDataSchema>;

// Define the Zod schema for the supplier
const suppliersListSchema = z.array(
  z.object({
    label: z.string(),
    value: z.string(),
  })
);

// Infer the TypeScript type from the schema
export type SuppliersList = z.infer<typeof suppliersListSchema>;
