import {lazy, StrictMode, Suspense} from "react";

import {QueryClientProvider} from "@tanstack/react-query";
import {RouterProvider} from "@tanstack/react-router";

// import Keycloak from "keycloak-js";

import {queryClient} from "@/lib/query-client";
import {router} from "@/lib/routes/index";

import "@/index.css";

// Conditionally load ReactQueryDevtools based on the environment (development or production)
const ReactQueryDevtools = import.meta.env.PROD
  ? () => null
  : lazy(() =>
      import("@tanstack/react-query-devtools").then((res) => ({
        default: res.ReactQueryDevtools,
      }))
    );

// Providers - Higher Order Component that wraps application with necessary providers.
// Includes providers for React Query and routing, as well as React Query Devtools in development mode.
export function Providers() {
  // const keycloak = new Keycloak({
  //   url: "http://10.194.168.13",
  //   realm: "dev",
  //   clientId: "nextec-ui",
  // });

  return (
    <StrictMode>
      {/* Provide the queryClient to the app for React Query */}
      <QueryClientProvider client={queryClient}>
        {/* Load ReactQueryDevtools and other components lazily */}
        <Suspense fallback={null}>
          <ReactQueryDevtools position="right" buttonPosition="bottom-right" />
          <RouterProvider router={router} />
        </Suspense>
      </QueryClientProvider>
    </StrictMode>
  );
}
