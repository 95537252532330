import {useEffect, useMemo, useRef, useState} from "react";

import {
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  type ColumnDef,
  type ColumnFiltersState,
  type SortingState,
  type VisibilityState,
} from "@tanstack/react-table";
import {useReactToPrint} from "react-to-print";

import {Card} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableFooterRow,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {Paragraph} from "@/components/ui/typography";
import {Pagination} from "@/components/craft/pagination";
import {TableSkeleton} from "@/components/craft/table";

import {
  useInvoiceTableColumns,
  useInvoiceTableColumnsStore,
  usePreferencesStore,
} from "@/lib/stores";
import {
  InvoiceDataTableToolbar,
  useInvoicesMetaStore,
  type InvoiceTableColumnsType,
} from "@/modules/invoices";

/**
 * Custom hook for managing omitted columns
 * @returns {Object} - Object containing the current state of omitted columns
 */
const useOmittedColumns = () => {
  // Get the current state of the omitted columns from the store
  const {storeInvoiceTableColumns} = useInvoiceTableColumns();

  // Memoize the omitted columns so that it is not re-calculated on every render
  return useMemo(() => {
    // If there is no state in the store, return an empty object
    return (
      storeInvoiceTableColumns || {
        // id: false,
        taxCode: false,
        facilityNumber: false,
        erpUniqueId: false,
        poNumber: false,
      }
    );
  }, [storeInvoiceTableColumns]);
};

/**
 * Defines the properties accepted by the InvoicesDataTable component.
 *
 * @typedef {Object} InvoiceDataTableProps
 * @template TData - The type of data each row in the table represents.
 * @template TValue - The type of the values of each cell in the table.
 * @property {ColumnDef<TData, TValue>[]} columns - Configuration for each column in the table.
 * @property {TData[]} data - Data that the table will display.
 */
interface InvoiceDataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  isPending?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
}

/**
 * The main functional component that renders a data table for invoices.
 *
 * @function
 * @param {InvoiceDataTableProps<TData, TValue>} props - The props that the component receives.
 * @template TData - The type of data each row in the table represents.
 * @template TValue - The type of the values of each cell in the table.
 * @param {ColumnDef<TData, TValue>[]} props.columns - Configuration for each column in the table.
 * @param {TData[]} props.data - Data that the table will display.
 * @param {boolean} [props.isPending] - Whether the table is pending data.
 * @param {boolean} [props.isSuccess] - Whether the table has successfully loaded data.
 * @param {boolean} [props.isError] - Whether the table has an error.
 * @returns {JSX.Element} - The JSX to render the component.
 */
export function InvoicesDataTable<TData, TValue>({
  columns,
  data,
  isSuccess,
  isError,
}: InvoiceDataTableProps<TData, TValue>) {
  /** Ref for the table component for printing purposes */
  const componentToPrintRef = useRef(null);

  /** Handles printing of the table */
  const handlePrint = useReactToPrint({
    content: () => componentToPrintRef.current,
    removeAfterPrint: true,
  });

  /** State for sorting the table */
  const [sorting, setSorting] = useState<SortingState>([]);

  /** State for storing the table columns */
  const {setStoreInvoiceTableColumns} = useInvoiceTableColumnsStore();

  // Custom hook to manage omitted columns
  const omittedColumns = useOmittedColumns();

  /** State for column visibility in the table */
  const [columnVisibility, setColumnVisibility] =
    useState<VisibilityState>(omittedColumns);

  /** Update the store when the column visibility changes */
  useEffect(() => {
    setStoreInvoiceTableColumns(columnVisibility as InvoiceTableColumnsType);
  }, [columnVisibility, setStoreInvoiceTableColumns]);

  /** State for column filters */
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  /** State for row selection */
  const [rowSelection, setRowSelection] = useState({});

  /**
   * Number of rows per page, fetched from the preferences store
   * @type {number}
   */
  const {numRowsPerPage} = usePreferencesStore();

  /**
   * Table API from the custom useReactTable hook
   * @type {Object}
   */
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination: {
        pageSize: parseInt(numRowsPerPage),
        pageIndex: 0,
      },
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const {storeInvoicesPaginationMeta, handleOnPageChange} =
    useInvoicesMetaStore();

  /**
   * JSX rendering for the component
   * @returns {JSX.Element} The JSX to render
   */
  return (
    <div className="space-y-2">
      <InvoiceDataTableToolbar table={table} handlePrint={handlePrint} />
      <Card ref={componentToPrintRef}>
        <Table>
          <style type="text/css" media="print">
            {
              "@page { size: landscape; margin: 0.5in 0.25in; }\
              "
            }
          </style>
          <TableHeader>
            {/* Mapping through each header group */}
            {table.getHeaderGroups().map((headerGroup, i) => (
              <TableRow key={`${headerGroup.id}-${i.toString()}`}>
                {/* Mapping through each header within a group */}
                {headerGroup.headers.map((header, i) => {
                  return (
                    <TableHead key={`${header.id}-${i.toString()}`}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>

          {isError ? (
            <TableBody>
              <TableRow>
                <TableCell
                  className="h-24 text-center"
                  colSpan={columns.length}
                >
                  <Paragraph>
                    <strong>Oops!</strong>
                  </Paragraph>
                </TableCell>
              </TableRow>
            </TableBody>
          ) : !isSuccess ? (
            <TableSkeleton
              columnsLength={columns.length}
              omittedColumns={omittedColumns}
              numRowsPerPage={parseInt(numRowsPerPage)}
            />
          ) : (
            <TableBody>
              {/* Checking if there are rows to display */}
              {table.getRowModel().rows.length ? (
                // Mapping through each row to display
                table.getRowModel().rows.map((row, i) => (
                  <TableRow
                    key={`${row.id}-${i.toString()}`}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {/* Mapping through each cell within a row */}
                    {row.getVisibleCells().map((cell, i) => (
                      <TableCell key={`${cell.id}-${i.toString()}`}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                // Displaying 'No results' if no rows to display
                <TableRow>
                  <TableCell
                    className="h-24 text-center"
                    colSpan={columns.length}
                  >
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}

          <TableFooter>
            {/* Mapping through each footer group */}
            {table.getHeaderGroups().map((headerGroup, i) => (
              <TableFooterRow key={`${headerGroup.id}-${i.toString()}`}>
                {/* Mapping through each footer within a group */}
                {headerGroup.headers.map((header, i) => {
                  return (
                    <TableHead key={`${header.id}-${i.toString()}`}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </TableHead>
                  );
                })}
              </TableFooterRow>
            ))}
          </TableFooter>
        </Table>
      </Card>
      <Pagination
        paginationMeta={storeInvoicesPaginationMeta}
        onPageChange={handleOnPageChange}
      />
    </div>
  );
}
