import {useCallback, useEffect, useRef, useState} from "react";

import Keycloak from "keycloak-js";

import {useKeyCloakInstanceStore, useTokenStore} from "@/modules/auth";

/**
 * Custom React hook for Keycloak authentication 🛡️
 * Initializes the Keycloak client, handles user login, and provides access tokens.
 *
 * @returns {Array} An array containing two elements:
 *                  1. A boolean flag indicating the login state 🚦
 *                  2. A string representing the access token or null if not available 🗝️
 */
export function useKeyCloakAuth() {
  // useRef to keep track of whether the effect has already run 🔍
  const isRun = useRef(false);

  // useState to manage the token, which can be a string, null, or undefined 🪪
  const [token, setToken] = useState<string | null>(null);

  // useState to manage the login state, initially set to false 🚪
  const [isLogin, setLogin] = useState(false);

  // Get the updateStoreToken function from the useTokenStore hook 📦
  const {updateStoreToken} = useTokenStore();

  // Get the updateStoreKeyCloakInstance function from the useKeyCloakInstanceStore hook 🔑
  const {updateStoreKeyCloakInstance} = useKeyCloakInstanceStore();

  /**
   * Function to schedule token refresh based on token expiry time.
   * @param {Keycloak} keycloak - The Keycloak instance.
   */
  const scheduleTokenRefresh = useCallback(
    (keycloak: Keycloak) => {
      // Calculate the time remaining until the token expires
      const expiresIn = keycloak.tokenParsed?.exp
        ? (keycloak.tokenParsed.exp * 1000 - Date.now()) / 2
        : 0;

      // Convert milliseconds to seconds and format to 2 decimal places
      const expiresInSeconds = (expiresIn / 1000).toFixed(2);

      // Log how much time is left before the token expires in seconds
      console.log(`Token expires in ${expiresInSeconds} seconds`);

      // If there's time before the token expires, set a timeout to refresh it
      if (expiresIn > 0) {
        setTimeout(() => {
          keycloak
            .updateToken(30) // Update token if it will expire in less than 30 seconds
            .then((refreshed) => {
              if (refreshed) {
                console.log("Token refreshed");
                updateStoreToken(keycloak.token || null);
                setToken(keycloak.token || null);
              } else {
                console.log("Token is still valid");
              }
              // Schedule the next refresh
              scheduleTokenRefresh(keycloak);
            })
            .catch((error: unknown) => {
              const err = error as Error;
              console.error("Failed to refresh token", err.message);
              setToken(null);
            });
        }, expiresIn);
      }
    },
    [updateStoreToken]
  );

  /**
   * useEffect to initialize Keycloak client and set token and login state 🔄
   * The effect runs only once because of the empty dependency array and isRun flag.
   */
  useEffect(() => {
    // Return if the effect has already run to prevent duplication ⛔
    if (isRun.current) return;

    // Mark the effect as run to prevent re-initialization ✅
    isRun.current = true;

    // Initialize the Keycloak client with environment variables 🌐
    const client = new Keycloak({
      url: import.meta.env.VITE_KEYCLOAK_URL
        ? (import.meta.env.VITE_KEYCLOAK_URL as string)
        : "https://ntauth.iterationm.com",
      realm: import.meta.env.VITE_KEYCLOAK_REALM
        ? (import.meta.env.VITE_KEYCLOAK_REALM as string)
        : "dev",
      clientId: import.meta.env.VITE_KEYCLOAK_CLIENT
        ? (import.meta.env.VITE_KEYCLOAK_CLIENT as string)
        : "nextec-ui",
    });

    // Initialize the Keycloak client 🚀
    void client
      .init({
        onLoad: "login-required", // Login is required for the application to load 🔐
      })
      .then((authenticated) => {
        console.log("Keycloak authenticated:", authenticated);
        // Update the login state based on Keycloak response ✨
        setLogin(authenticated);

        // Update the token in the store with the one from Keycloak or 'unauthorized' 📝
        updateStoreToken(
          typeof client.token !== "string" ? null : client.token
        );

        // Update the Keycloak instance in the store with the initialized client 🔄
        updateStoreKeyCloakInstance(client);

        // Update the token state with the token from Keycloak 🎫
        setToken(client.token || null);

        // Schedule the first token refresh
        scheduleTokenRefresh(client);
      })
      .catch((error: unknown) => {
        console.error("Keycloak initialization failed:", error);
        setToken(null);
        void client.logout();
      });

    // Cleanup function to reset the isRun flag when the component is unmounted 🧹
    return () => {
      isRun.current = true;
    };
  }, [updateStoreKeyCloakInstance, updateStoreToken, scheduleTokenRefresh]); // Dependencies for useEffect

  // Return the login state and token to the consumer of this hook 📤
  return [isLogin, token];
}
