import {useState} from "react";

import type {Table} from "@tanstack/react-table";
import {ListRestartIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import {
  DataTablePagination,
  // DataTableFacetedFilter,
  DataTableViewOptions,
} from "@/components/craft/data-table";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

// import {priorities, statuses} from "../data/data";

interface PaymentFileToolbarProps<TData> {
  table: Table<TData>;
  isPending?: boolean;
  isErrored?: boolean;
}

export function PaymentFileToolbar<TData>({
  table,
  isPending,
  isErrored,
}: PaymentFileToolbarProps<TData>) {
  const [globalFilterInput, setGlobalFilterInput] = useState("");
  const [mappingNameFilterInput, setMappingNameFilterInput] = useState("");

  const isFiltered =
    table.getState().columnFilters.length > 0 ||
    !!table.getState().globalFilter;

  //Columns other names
  const columnsOtherName = [
    {key: "masterMappingName", value: "Master Mapping Name"},
    {key: "paymentNumber", value: "Payment Number"},
    {key: "accountNumber", value: "Account Number"},
    {key: "totalAmountPaid", value: "Total Amount Paid"},
    {key: "paymentDate", value: "Payment Date"},
    {key: "adjustmentAmount", value: "Adjustment Amount"},
    {key: "currency", value: "Currency"},
    {key: "paymentMethod", value: "Payment Method"},
    {key: "supplierNumber", value: "Supplier Number"},
    {key: "supplierName", value: "Supplier Name"},
    {key: "addressLine1", value: "Address Line 1"},
    {key: "addressLine2", value: "Address Line 2"},
    {key: "city", value: "City"},
    {key: "state", value: "State"},
    {key: "zip", value: "Zip"},
    {key: "locationCode", value: "Location Code"},
    {key: "invoiceNumber", value: "Invoice Number"},
    {key: "invoiceDate", value: "Invoice Date"},
    {key: "invoiceAmount", value: "Invoice Amount"},
    {key: "discountAmount", value: "Discount Amount"},
    {key: "totalAmount", value: "Total Amount"},
    {key: "poNumber", value: "PO Number"},
    {key: "invoiceType", value: "Invoice Type"},
    {key: "invoiceComments", value: "Invoice Comments"},
    {key: "udf1", value: "UDF1"},
    {key: "udf2", value: "UDF2"},
    {key: "udf3", value: "UDF3"},
    {key: "paymentZip", value: "Payment Zip"},
    {key: "paymentFileIdentifier", value: "Payment File Identifier"},
  ];

  return (
    <>
      <DataTablePagination
        table={table}
        isPending={isPending}
        isErrored={isErrored}
      />
      <div className="flex items-center justify-between">
        <div className="flex flex-1 items-center space-x-2">
          <RowsPreferenceSelect />
          <Input
            placeholder="Search all entries..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={globalFilterInput}
            onChange={(event) => {
              setGlobalFilterInput(event.target.value);
              table.setGlobalFilter(event.target.value);
            }}
          />
          <Input
            placeholder="Search mapping names..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={mappingNameFilterInput}
            onChange={(event) => {
              table;
              setMappingNameFilterInput(event.target.value);
              table
                .getColumn("masterMappingName")
                ?.setFilterValue(event.target.value);
            }}
          />
          {/* {table.getColumn("status") && (
          <DataTableFacetedFilter
            column={table.getColumn("status")}
            title="Status"
            options={statuses}
          />
        )}
        {table.getColumn("priority") && (
          <DataTableFacetedFilter
            column={table.getColumn("priority")}
            title="Priority"
            options={priorities}
          />
        )} */}
          {isFiltered && (
            <Button
              variant="destructive"
              className="h-10 gap-2 px-2 hover:bg-destructive/70 hover:text-destructive-foreground/80 active:bg-destructive/80 active:text-destructive-foreground/80 lg:px-3"
              onClick={() => {
                table.resetColumnFilters();
                table.resetGlobalFilter();
                setGlobalFilterInput("");
                setMappingNameFilterInput("");
              }}
            >
              <span className="sr-only">Reset search filters</span>
              Search Reset
              <ListRestartIcon className="size-4" />
            </Button>
          )}
        </div>
        <DataTableViewOptions
          table={table}
          columnsOtherName={columnsOtherName}
        />
      </div>
    </>
  );
}
