import {z} from "zod";

const allowedStatusValues = [
  "pending",
  "approved",
  "paid",
  "exception",
  "void",
  "deleted",
  "cancelled",
];
export const invoiceStatusSchema = z.object({
  id: z.number(),
  status: z.string().refine((value) => allowedStatusValues.includes(value), {
    message: "Invalid status value",
  }),
});

export type InvoiceStatusType = z.infer<typeof invoiceStatusSchema>;
