import {z} from "zod";

export const supplierStatusSchema = z.object({
  supplierId: z.string().optional(),
  supplierOnboardStatus: z.string().optional(),
  changedBy: z.string().optional(),
  lastEditTimestamp: z.string().optional(),
});

export type SupplierStatus = z.infer<typeof supplierStatusSchema>;
