import {z} from "zod";

export const exportInvoicesSchema = z.object({
  // buyerId: z.array(z.number()).nullable(),
  // supplierId: z.array(z.number()).nullable(),
  // facilityId: z.array(z.number()).nullable(),
  // status: z.array(z.number()).nullable(),
  // startDate: z.string().nullable(),
  // endDate: z.string().nullable(),
  // paymentDate: z.string().nullable(),
  // invoiceDateStart: z.string().nullable(),
  // invoiceDateEnd: z.string().nullable(),
  // paymentNumber: z.string().nullable(),
  // poNumber: z.string().nullable(),
  // invoiceNumber: z.string().nullable(),
  // erpUniqueId: z.string().nullable(),
  // invoiceAmount: z.string().nullable(),
  email: z.string().min(1, {message: "Email is required"}).email({
    message: "Please enter a valid email address",
  }),
  subject: z.string().min(2, {message: "Subject is required"}),
  message: z.string().min(2, {message: "Message is required"}),
});

export type ExportInvoicesDTO = z.infer<typeof exportInvoicesSchema>;
