import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  savedInvoiceSearchQueryKeys,
  type SavedSearchDTOType,
} from "@/modules/invoices";

// Defining a type based on the function `getAllSavedSearchesFn`
type QueryFnType = typeof getAllSavedSearchesFn;

// Function to fetch all saved searches
export function getAllSavedSearchesFn(userId?: string) {
  return kyApiFn<SavedSearchDTOType[]>(
    `invtracker/v1/save-search?userId=${userId}`,
    "get"
  );
  // TODO: validate data
  // return searchSchema.parse(data);
}

// Custom hook to fetch and cache all saved searches data using react-query
export function useGetSavedSearches(
  userId?: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: savedInvoiceSearchQueryKeys.byUser(userId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getAllSavedSearchesFn(userId), // Setting the function to be used for fetching data
    enabled: !!userId, // Only fetch data if the userId is provided
  });
}

// Function to create a new saved search
export function createSavedSearchFn(savedSearchDTO: SavedSearchDTOType) {
  return kyApiFn<SavedSearchDTOType>(
    `invtracker/v1/save-search?userId=${savedSearchDTO.userId}`,
    "post",
    {
      json: savedSearchDTO,
    }
  );
}

// Function to delete an existing saved search
export function deleteSavedSearchFn(listId: number) {
  return kyApiFn(`invtracker/v1/save-search?list_id=${listId}`, "delete");
}

// Function to update a saved search
export function updateSavedSearchFn(
  savedSearchDTO: SavedSearchDTOType,
  listId: number
) {
  return kyApiFn<SavedSearchDTOType>(
    `invtracker/v1/save-search?list_id=${listId}`,
    "put",
    {
      json: savedSearchDTO,
    }
  );
}

// Function to delete all an existing saved search
export function deleteAllSavedSearchFn(userId: string) {
  return kyApiFn(
    `invtracker/v1/save-search/delete-all?userId=${userId}`,
    "delete"
  );
}
