import {useEffect, useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {useParams} from "@tanstack/react-router";
import {type Row} from "@tanstack/react-table";
import {
  FileEditIcon,
  GripVerticalIcon,
  PackageXIcon,
  Trash2Icon,
} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {CardContent, CardHeader, CardTitle} from "@/components/ui/card";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {Form} from "@/components/ui/form";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Separator} from "@/components/ui/separator";
import {Spinner} from "@/components/ui/spinner";
import {toast} from "@/components/ui/use-toast";
import {LoadingSection} from "@/components/craft/loading-section";

import {
  BankAccountNumberFormField,
  deleteCustomerParticipantRegisterFn,
  FacilityNameFormField,
  GroupIdFormField,
  OrganizationIdFormField,
  PaymentFileIdentifierFormField,
  PaymentFilenameIdentifierFormField,
  updateCustomerParticipantRegisterFn,
  UpdateParticipantRegisterFormSchema,
  useRegisteredParticipants,
  UserIdFormField,
} from "@/modules/admin";
import {useKeyCloakInstanceStore} from "@/modules/auth";

/**
 * Props for ParticipantRegisterRowActions component.
 * @typedef {Object} ParticipantRegisterRowActionsProps
 * @paymentId String - The type of data for the row.
 */
interface ParticipantRegisterRowActionsProps<TData> {
  row: Row<TData>;
}

/**
 * Represents the actions for a payment file row.
 * This component provides a dropdown menu for each row with various actions like edit, copy, favorite, and delete.
 * @returns {JSX.Element} The ParticipantRegisterRowActions component.
 */
export function ParticipantRegisterRowActions<TData>({
  row,
}: ParticipantRegisterRowActionsProps<TData>) {
  const {externalId} = useParams({
    from: "/app/admin/customer-management/imremit/$externalId/onboarding/participant-register",
  });

  const ParticipantRegisterRows = UpdateParticipantRegisterFormSchema.parse(
    row.original
  );

  const [open, setOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  return (
    <>
      <Popover>
        <PopoverTrigger asChild>
          <Button className="flex h-12 w-12 gap-2 bg-theme p-0 hover:bg-neutral hover:bg-theme/90 ">
            <span className="sr-only">Open menu</span>
            <GripVerticalIcon className="size-6 bg-inherit" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          align="end"
          className="z-50 min-w-fit overflow-hidden rounded-md border bg-root/70 p-1 text-foreground shadow-md backdrop-blur-xl backdrop-brightness-150 animate-in data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2"
        >
          <div className="px-2 py-1.5 text-sm font-semibold">Actions</div>
          <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger className="w-full">
              <div
                className="relative flex cursor-default select-none items-center justify-between gap-4 rounded-sm px-2 py-1.5 text-sm outline-none transition-colors hover:cursor-pointer focus:bg-accent focus:text-accent-foreground data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50"
                onSelect={(e) => {
                  e.stopPropagation();
                }}
              >
                Update Participant
                <span className="sr-only">Update Participant Details</span>
                <FileEditIcon className="size-4" />
              </div>
            </DialogTrigger>
            <DialogContent className=" sm:min-w-[340px] md:min-w-[668px] lg:min-w-[780px] xl:min-w-[1080px] 2xl:min-w-[1118px]">
              <DialogHeader>
                <DialogTitle>Update form</DialogTitle>
                <DialogDescription>
                  <UpdateParticipantRegisterForm
                    externalId={externalId}
                    ParticipantRegisterRows={ParticipantRegisterRows}
                    setOpen={setOpen}
                  />
                </DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
          <Separator className="my-1 -ml-1 -mr-2 h-px bg-foreground" />
          <Dialog open={isDeleteOpen} onOpenChange={setIsDeleteOpen}>
            <DialogTrigger className="w-full">
              <div
                className="relative flex cursor-default select-none items-center justify-between gap-4 rounded-sm bg-destructive px-2 py-1.5 text-sm text-destructive-foreground outline-none transition-colors hover:cursor-pointer hover:bg-destructive/90 hover:text-destructive-foreground/90 focus:bg-accent focus:text-accent-foreground data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50"
                onSelect={(e) => {
                  e.stopPropagation();
                }}
              >
                Delete Item
                <span className="sr-only">Delete Item</span>
                <PackageXIcon className="size-4" />
              </div>
            </DialogTrigger>
            <DeleteParticipantRegistrationById
              externalId={externalId}
              participantRegisterData={ParticipantRegisterRows}
              setIsDeleteOpen={setIsDeleteOpen}
            />
          </Dialog>
        </PopoverContent>
      </Popover>
    </>
  );
}

/**
 * Custom hook for performing the 'update Participant Register' mutation.
 * This hook abstracts the mutation logic and side effects like toast notifications.
 *
 * @param {number | undefined} externalId - The ID of the customer.
 * @params {number | undefined} participantRegisterId - The ID of the Participant Register.
 * @returns A mutation object with methods to trigger the mutation.
 */
function useUpdateParticipantRegister(
  externalId: string,
  participantRegisterId: number,
  setOpen: (open: boolean) => void
) {
  return useMutation({
    mutationFn: (data: z.infer<typeof UpdateParticipantRegisterFormSchema>) => {
      // Perform the mutation using the addParticipantRegisterFn function
      return updateCustomerParticipantRegisterFn(
        data,
        externalId,
        participantRegisterId
      );
    },
    onSuccess: (response) => {
      // Handle successful mutation
      console.log("updateParticipantRegisterResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Participant register updated successfully",
      });

      setOpen(false);
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update participant register",
      });
    },
  });
}

/**
 * Custom hook for performing the 'delete Participant Register' mutation.
 * This hook abstracts the mutation logic and side effects like toast notifications.
 *
 * @param {number | undefined} externalId - The ID of the customer.
 * @params {number | undefined} participantRegisterId - The ID of the Participant Register.
 * @returns A mutation object with methods to trigger the mutation.
 */

function useDeleteParticipantRegister(
  externalId: string,
  participantRegisterId: number,
  setIsDeleteOpen: (open: boolean) => void
) {
  return useMutation({
    mutationFn: () => {
      // Perform the mutation using the addParticipantRegisterFn function
      return deleteCustomerParticipantRegisterFn(
        externalId,
        participantRegisterId
      );
    },
    onSuccess: (response) => {
      // Handle successful mutation
      console.log("deleteParticipantRegisterResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Participant register deleted successfully",
      });

      setIsDeleteOpen(false);
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to delete participant register",
      });
      setIsDeleteOpen(false);
    },
  });
}

function UpdateParticipantRegisterForm({
  externalId,
  ParticipantRegisterRows,
  setOpen,
}: {
  externalId: string;
  ParticipantRegisterRows: z.infer<typeof UpdateParticipantRegisterFormSchema>;
  setOpen: (open: boolean) => void;
}) {
  const storeKeyCloakInstance =
    useKeyCloakInstanceStore().storeKeyCloakInstance;

  const parsedUserInfo = useMemo(
    () => storeKeyCloakInstance?.tokenParsed,
    [storeKeyCloakInstance]
  );

  const updateParticipantRegisterForm = useForm<
    z.infer<typeof UpdateParticipantRegisterFormSchema>
  >({
    mode: "onChange",
    resolver: zodResolver(UpdateParticipantRegisterFormSchema),
  });

  const updateParticipantRegisterMutation = useUpdateParticipantRegister(
    externalId,
    ParticipantRegisterRows.participantRegisterId,
    setOpen
  );

  useEffect(() => {
    updateParticipantRegisterForm.reset(ParticipantRegisterRows);
    if (parsedUserInfo?.name) {
      updateParticipantRegisterForm.setValue(
        "modifiedBy",
        parsedUserInfo.name as string
      );
    }
  }, [
    updateParticipantRegisterForm,
    ParticipantRegisterRows,
    parsedUserInfo?.name,
  ]);

  const allParticipantRegistrations = useRegisteredParticipants(
    externalId.toString()
  );

  const isBankAccountNumberUnique = (bankAccountNumber: string) => {
    return !allParticipantRegistrations.some(
      (participant) =>
        participant.bankAccountNumber === bankAccountNumber &&
        participant.participantRegisterId !==
          ParticipantRegisterRows.participantRegisterId
    );
  };

  return (
    <>
      <CardHeader>
        <CardTitle className="mb-5">Update Participant</CardTitle>
      </CardHeader>
      <div className="gap-y-4 sm:flex-row sm:gap-x-4">
        <CardContent>
          <Form {...updateParticipantRegisterForm}>
            <form
              onSubmit={updateParticipantRegisterForm.handleSubmit((data) => {
                const bankAccountNumber = data.bankAccountNumber;

                if (bankAccountNumber) {
                  if (isBankAccountNumberUnique(bankAccountNumber)) {
                    updateParticipantRegisterMutation.mutate(data);
                  } else {
                    toast({
                      variant: "destructive",
                      title: "Bank Account Number must be unique!",
                    });
                  }
                } else {
                  updateParticipantRegisterMutation.mutate(data);
                }
              })}
            >
              <div className="mb-5 mt-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
                <FacilityNameFormField
                  isMutating={updateParticipantRegisterMutation.isPending}
                />
                <OrganizationIdFormField
                  isMutating={updateParticipantRegisterMutation.isPending}
                />
                <UserIdFormField
                  isMutating={updateParticipantRegisterMutation.isPending}
                />
                <GroupIdFormField
                  isMutating={updateParticipantRegisterMutation.isPending}
                />
                <PaymentFilenameIdentifierFormField
                  isMutating={updateParticipantRegisterMutation.isPending}
                />
                <PaymentFileIdentifierFormField
                  isMutating={updateParticipantRegisterMutation.isPending}
                />
                <BankAccountNumberFormField
                  isMutating={updateParticipantRegisterMutation.isPending}
                />
              </div>
              <div className="flex items-center justify-end space-x-2 p-2">
                <Button
                  type="submit"
                  disabled={updateParticipantRegisterMutation.isPending}
                  aria-disabled={updateParticipantRegisterMutation.isPending}
                  variant="affirmative"
                >
                  {updateParticipantRegisterMutation.isPending ? (
                    <Spinner size="xs" />
                  ) : (
                    ""
                  )}
                  Save
                </Button>
              </div>
            </form>
          </Form>
        </CardContent>
      </div>
    </>
  );
}

function DeleteParticipantRegistrationById({
  externalId,
  participantRegisterData,
  setIsDeleteOpen,
}: {
  externalId: string;
  participantRegisterData: z.infer<typeof UpdateParticipantRegisterFormSchema>;
  setIsDeleteOpen: (open: boolean) => void;
}) {
  const deleteParticipantRegisterMutation = useDeleteParticipantRegister(
    externalId,
    participantRegisterData.participantRegisterId,
    setIsDeleteOpen
  );

  return (
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogDescription>
          Do you want to delete the entry? Deleting this entry cannot be undone.
        </DialogDescription>
      </DialogHeader>
      <DialogFooter>
        <DialogClose asChild>
          <Button
            aria-disabled={deleteParticipantRegisterMutation.isPending}
            disabled={deleteParticipantRegisterMutation.isPending}
            variant="outline"
          >
            Cancel
          </Button>
        </DialogClose>
        <Button
          variant="destructive"
          className="flex items-center gap-2"
          aria-disabled={deleteParticipantRegisterMutation.isPending}
          disabled={deleteParticipantRegisterMutation.isPending}
          onClick={() => {
            deleteParticipantRegisterMutation.mutate();
          }}
        >
          {deleteParticipantRegisterMutation.isPending ? (
            <LoadingSection sectionSize="xs" />
          ) : (
            <Trash2Icon className="size-4" />
          )}
          Delete
        </Button>
      </DialogFooter>
    </DialogContent>
  );
}
