import {useNavigate} from "@tanstack/react-router";
import {FileTextIcon, GripVerticalIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

/**
 * Props for ProxyPayRowActions component.
 * @typedef {Object} ProxyPayRowActionsProps
 * @paymentId String - The type of data for the row.
 */
interface ProxyPayRowActionsProps {
  paymentId: string;
}

/**
 * Represents the actions for a payment file row.
 * This component provides a dropdown menu for each row with various actions like edit, copy, favorite, and delete.
 * @returns {JSX.Element} The ProxyPayRowActions component.
 */
export function ProxyPayRowActions({paymentId}: ProxyPayRowActionsProps) {
  // Get the navigate function from the router
  const navigate = useNavigate();

  // Set the params for the routes
  const params = {paymentId: paymentId, reProcess: "false"};

  return (
    <DropdownMenu>
      {/* Trigger button for the dropdown menu */}
      <DropdownMenuTrigger asChild>
        <Button className="flex bg-theme hover:bg-neutral-foreground data-[state=open]:bg-secondary">
          <GripVerticalIcon className="size-6 bg-inherit" />
        </Button>
      </DropdownMenuTrigger>

      {/* Content of the dropdown menu */}
      <DropdownMenuContent align="end" className="min-w-fit">
        <DropdownMenuItem
          className="flex justify-between gap-4"
          onSelect={() => {
            void navigate({
              to: "/app/imremit/proxy-pay-dashboard/$paymentId/view/$reProcess",
              params,
              replace: true,
            });
          }}
        >
          View Payment Details
          <span className="sr-only">View Payment Details</span>
          <FileTextIcon className="size-4" />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
