import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  providerPaymentMethodNameKeys,
  type ProviderPaymentMethodName,
} from "@/modules/imremit";

// Defining a type based on the function `searchProviderPaymentMethodNames`
type QueryFnType = typeof getProviderPaymentMethodNames;

// Function to search providerPaymentMethodNames
export function getProviderPaymentMethodNames(providerId?: string) {
  return kyApiFn<ProviderPaymentMethodName[]>(
    `api/e-payable/payment-methods/${providerId}`,
    "get"
  );
}

// Custom hook to fetch and cache providerPaymentMethodNames data using react-query
export function useGetProviderPaymentMethodNames(
  providerId?: string, // The search query string
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: providerPaymentMethodNameKeys.byProviderId(providerId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getProviderPaymentMethodNames(providerId), // Setting the function to be used for fetching data
  });
}
