import {useEffect, useMemo} from "react";

import {Paragraph} from "@/components/ui/typography";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {ImREmitRoutesSchema} from "@/lib/routes/types";
import {useImRemitCustomerStore, useRoutesStore} from "@/lib/stores";
import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useAuthorization,
} from "@/modules/auth";
import {SelectCustomerSectionImremit} from "@/modules/customers";
import {
  fileProcessingColumns,
  FileProcessingDataTable,
  useFileProcessing,
} from "@/modules/imremit";

/**
 * Custom Hook: Manage the active route for the FileProcessingType Tracker Dashboard.
 */
function useManageFileProcessingRoute() {
  const {setimREmitRoute} = useRoutesStore();

  useEffect(() => {
    // Set the active route based on predefined route schema
    setimREmitRoute(ImREmitRoutesSchema.Values["/app/imremit/file-processing"]);
  }, [setimREmitRoute]);
}

/**
 * Main Component: FileProcessingType Tracker Dashboard Page
 * This component serves as the primary interface for tracking fileProcessings.
 *
 * @returns {React.Element} - The rendered component.
 */
export function FileProcessingPage() {
  const {imREmitStoreCustomer} = useImRemitCustomerStore();
  const externalId = imREmitStoreCustomer?.externalId;
  const searchFileProcessingQuery = useFileProcessing(externalId?.toString());

  const fileProcessingDataContent = useMemo(() => {
    if (!searchFileProcessingQuery.data) {
      return [];
    }
    return searchFileProcessingQuery.data.content;
  }, [searchFileProcessingQuery.data]);

  // Manage the active route for this page
  useManageFileProcessingRoute();

  const {checkPoliciesAccess} = useAuthorization();

  // Handle unauthorized access
  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_FILE_PROCESSING])) {
    return <NotAuthorizedPage />;
  }

  if (!imREmitStoreCustomer) {
    return <SelectCustomerSectionImremit />;
  }

  // TODO: replace with better error handling
  if (searchFileProcessingQuery.isError) {
    return <Paragraph>Something went wrong...</Paragraph>;
  }

  // Render the file processing data table
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.READ_FILE_PROCESSING]}
    >
      <FileProcessingDataTable
        columns={fileProcessingColumns}
        data={fileProcessingDataContent}
        isPending={searchFileProcessingQuery.isPending}
        isSuccess={searchFileProcessingQuery.isSuccess}
        isError={searchFileProcessingQuery.isError}
      />
    </AccessPolicyWrapper>
  );
}
