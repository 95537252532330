export const fieldTypes = [
  {label: "Select", value: "select"},
  {label: "Input", value: "input"},
  {label: "Drop Down", value: "dropdown"},
  {label: "Date Picker Table", value: "date_picker_table"},
  {label: "URL", value: "url"},
  {label: "Error", value: "error"},
  {label: "Confirmation Number", value: "confirmation_number"},
  {label: "Alert", value: "alert"},
  {label: "Pop-Up message with OK / Yes", value: "pop_up_msg_ok_yes"},
  {label: "Check Box", value: "checkBox"},
  {label: "CAPTCHA Checkbox", value: "captcha_checkbox"},
  {label: "CAPTCHA Image", value: "captcha_image"},
  {label: "CAPTCHA Image Value", value: "captcha_image_value"},
  {label: "Iframe exit", value: "iframe_exit"},
  {label: "Iframe", value: "iframe"},
  {
    label: "Radio Button/Submit Button/Next Button/Toggle",
    value: "button",
  },
  {label: "Login Button", value: "login_button"},
  {
    label: "label/text (if we need to verify any details on the page)",
    value: "label_text",
  },
  {label: "Switch to Tab", value: "switch_to_tab"},
  {label: "Switch to Window", value: "switch_to_window"},
  {label: "Switch to Previous Tab", value: "switch_to_previous_tab"},
  {label: "Handle Window", value: "handle_window"},
  {label: "Scroll to Element", value: "scroll_to_element"},
  {label: "Get reCAPTCHA V2 Response", value: "get_reCAPTCHA_v2_response"},
  {
    label: "Insert reCAPTCHA v2 Response",
    value: "insert_reCAPTCHA_v2_response",
  },
  {label: "Search Invoices in Div", value: "search_invoices_in_div"},
  {
    label: "Input Invoice Numbers Amounts",
    value: "input_invoice_numbers_amounts",
  },
  {label: "Payment Loop Start", value: "payment_loop_start"},
  {label: "Payment Loop End", value: "payment_loop_end"},
  {label: "Click Link Text", value: "click_link_text"},
];

export const loginSkip = [
  {label: "No", value: "false"},
  {label: "Yes", value: "true"},
];

export const tableNames = [
  {label: "Select", value: "select"},
  {label: "Payment Detail", value: "Payment Detail"},
  {label: "Payment Request", value: "Payment Request"},
  {label: "Payment Response", value: "Payment Response"},
  {label: "Supplier Detail", value: "Supplier Detail"},
  {label: "Customer Profile", value: "Customer Profile"},
  {label: "Invoice Detail", value: "Invoice Detail"},
  {label: "Address", value: "Address"},
  {label: "Login Credential", value: "Login Credential"},
  {label: "Invoice Detail All", value: "Invoice Detail All"},
];

export const tableColumnNamesLoginCredential = [
  {label: "supplierLoginUserId", value: "supplierLoginUserId"},
  {label: "supplierLoginPassword", value: "supplierLoginPassword"},
];

export const tableColumnNamesPaymentDetail = [
  {label: "totalAmountSent", value: "totalAmountSent"},
  {label: "Payment Number", value: "Payment Number"},
  {
    label: "Outstanding Balance (Amount Payable)",
    value: "Outstanding Balance (Amount Payable)",
  },
];

export const tableColumnNamesPaymentRequest = [
  {label: "Account Number", value: "accountNumber"},
  {label: "Payment Zip", value: "paymentZip"},
  {label: "Payment Number", value: "Payment Number"},
];
export const tableColumnNamesPaymentResponse = [
  {label: "Card Number", value: "cardNumber"},
  {label: "CVV", value: "cvv"},
  {label: "Exp Date", value: "expDate"},
  {label: "Card Type", value: "cardType"},
  {label: "Payment Number", value: "Payment Number"},
];
export const tableColumnNamesSupplierDetail = [
  {label: "supplier Name", value: "supplierName"},
  {label: "User ID", value: "userId"},
  {label: "Password", value: "password"},
];
export const tableColumnNamesCustomerProfile = [
  {label: "Customer zip code", value: "zip"},
  {label: "address 1", value: "address1"},
  {label: "address 2", value: "address2"},
  {label: "address 3", value: "address3"},
  {label: "City", value: "city"},
  {label: "State", value: "state"},
  {label: "Company Name", value: "companyName"},
  {label: "Customer Contact Person Name", value: "contactName"},
  {label: "Customer Contact Person / Mobile Phone Number", value: "phone"},
  {label: "Customer E-Mail Address", value: "email"},
  {label: "Buyer Epay Email", value: "buyerEpayEmail"},
];
export const tableColumnNamesInvoiceDetail = [
  {label: "invoiceNumber", value: "invoiceNumber"},
  {label: "invoiceTotalAmount", value: "invoiceTotalAmount"},
  {label: "Invoice Amount Paid", value: "Invoice Amount Paid"},
];

export const tableColumnNamesAddress = [
  {label: "city", value: "city"},
  {label: "state", value: "state"},
  {label: "country", value: "country"},
  {label: "zip", value: "zip"},
];

export const tableColumnNamesInvoiceDetailAll = [
  {label: "invoiceNumber", value: "invoiceNumber"},
  {label: "invoiceTotalAmount", value: "invoiceTotalAmount"},
  {label: "Invoice Amount Paid", value: "Invoice Amount Paid"},
];

export const findElementByValues = [
  {label: "XPath", value: "XPath"},
  {label: "ID", value: "ID"},
  {label: "CSS Selector", value: "CSS Selector"},
  {label: "Name", value: "Name"},
  {label: "Class Name", value: "Class Name"},
];

export const timerMethods = [
  {value: "1", label: "1 Second"},
  {value: "5", label: "5 Seconds"},
  {value: "10", label: "10 Seconds"},
  {value: "15", label: "15 Seconds"},
  {value: "20", label: "20 Seconds"},
];

export const paymentTimerMethods = [
  {value: "5", label: "5 Seconds"},
  {value: "10", label: "10 Seconds"},
  {value: "15", label: "15 Seconds"},
  {value: "20", label: "20 Seconds"},
  {value: "30", label: "30 Seconds"},
  {value: "60", label: "60 Seconds"},
  {value: "90", label: "90 Seconds"},
  {value: "120", label: "120 Seconds"},
];

export const captchaTimerMethods = [
  {value: "10", label: "10 Seconds"},
  {value: "30", label: "30 Seconds"},
  {value: "60", label: "60 Seconds"},
  {value: "90", label: "90 Seconds"},
  {value: "120", label: "120 Seconds"},
];

export const dateFormats = [
  {value: "dd", label: "dd"},
  {value: "MM", label: "MM"},
  {value: "yyyy", label: "yyyy"},
  {value: "mm/dd/yyyy", label: "mm/dd/yyyy"},
  {value: "MM/DD/YYYY", label: "MM/DD/YYYY"},
  {value: "DD/MM/YYYY", label: "DD/MM/YYYY"},
  {value: "dd/mm/yyyy", label: "dd/mm/yyyy"},
  {value: "YYYY/MM/DD", label: "YYYY/MM/DD"},
  {value: "yyyy/mm/dd", label: "yyyy/mm/dd"},
  {value: "YYYY/DD/MM", label: "YYYY/DD/MM"},
  {value: "yyyy/dd/mm", label: "yyyy/dd/mm"},
  {value: "mm - mmm", label: "mm - mmm"},
  {value: "mm", label: "mm"},
  {value: "yy", label: "yy"},
  {value: "yyyy", label: "yyyy"},
  {value: "mm/yyyy", label: "mm/yyyy"},
  {value: "mm/yy", label: "mm/yy"},
  {value: "mmm/yyyy", label: "mmm/yyyy"},
  {value: "MM - MMM", label: "MM - MMM"},
];
