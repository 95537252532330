import {createRoute} from "@tanstack/react-router";

import {PageNotFound} from "@/components/layout/not-found";

import {RoutesDirectory} from "@/lib/routes/directory";
import {authorizedRootRoute} from "@/lib/routes/root";
import {
  ElectronicInvoicingOutlet,
  ElectronicInvoicingPage,
} from "@/modules/electronic-invoicing";

export const electronicInvoicingRootRoute = createRoute({
  path: RoutesDirectory.ELECTRONIC_INVOICING,
  component: ElectronicInvoicingOutlet,
  getParentRoute: () => authorizedRootRoute,
});

export const electronicInvoicingPageRoute = createRoute({
  path: RoutesDirectory.ROOT,
  component: ElectronicInvoicingPage,
  getParentRoute: () => electronicInvoicingRootRoute,
});

export const electronicInvoicingNotFoundRoute = createRoute({
  path: RoutesDirectory.CATCH_ALL,
  component: PageNotFound,
  getParentRoute: () => electronicInvoicingRootRoute,
});
