import {type Row} from "@tanstack/react-table";
import type {DateRange} from "react-day-picker";

/**
 * Props for dateBetweenFilterProps function.
 * @template TData - The type of data for the row.
 * @property {Row<TData>} row - The data row.
 */
interface dateBetweenFilterProps<TData> {
  row: Row<TData>;
  columnId: string;
  filterValue: DateRange;
}

/**
 *
 * Custom filter function for check if target date falls within the range.
 *
 * @template TData - The data type for the table row.
 * @param {dateBetweenFilterProps<TData>} props - The props for the function.
 * @param {columnId} string - Table Column Name
 * @param {filterValue} DateRange - from & to date
 * @returns {JSX.Element} The dateBetweenFilterProps function.
 */
export function dateBetweenFilter<TData>({
  row,
  columnId,
  filterValue,
}: dateBetweenFilterProps<TData>) {
  const start = filterValue.from ?? filterValue.from;
  const end = filterValue.to ?? filterValue.to;

  if (start && end) {
    // Convert strings to Date objects
    const targetDate = new Date(row.getValue(columnId));
    const fromDate = new Date(start);
    const toDate = new Date(end);

    // Check if target date falls within the range
    const isWithinRange: boolean =
      targetDate >= fromDate && targetDate <= toDate;

    return isWithinRange;
  } else {
    //default TRUE
    return true;
  }
}
