/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {
  UserManagementRowActions,
  UserSessionsTable,
  type KeycloakUserType,
} from "@/modules/admin";

import {FallbackMessages} from "@/utils/constants";
import {formatDate} from "@/utils/functions";

export const userManagementTableColumns: ColumnDef<KeycloakUserType>[] = [
  {
    accessorKey: "id",
    header: ({column}) => <DataTableColumnHeader column={column} title="ID" />,
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("id") ? (
          row.getValue("id")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
    // enableSorting: false,
    // enableHiding: false,
  },
  {
    accessorKey: "firstName",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Name" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {
          // Enhanced logic to ensure both names are considered
          row.getValue("firstName") || row.original.lastName ? (
            // Construct full name with space only if both names are present
            [
              row.getValue("firstName") || "", // Get firstName or empty string if not present
              row.original.lastName || "", // Get lastName or empty string if not present
            ]
              .filter(Boolean)
              .join(" ") // Filter out empty strings and join with a space
          ) : (
            // Display a badge if neither name is present
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )
        }
      </div>
    ),
  },
  {
    accessorKey: "email",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Email" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("email") ? (
          row.getValue("email")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "username",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Username" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("username") ? (
          row.getValue("username")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "emailVerified",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Email Verified" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("emailVerified") ? (
          <Badge variant="success">Yes</Badge>
        ) : (
          <Badge variant="destructive">No</Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "enabled",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Enabled" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("enabled") ? (
          <Badge variant="success">Yes</Badge>
        ) : (
          <Badge variant="destructive">No</Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "createdTimestamp",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Date Created" />
    ),
    cell: ({row}) => (
      <div className="w-fit whitespace-nowrap">
        {row.getValue("createdTimestamp") ? (
          formatDate(new Date(row.getValue("createdTimestamp")))
        ) : (
          // new Date(row.getValue("createdTimestamp")).toLocaleString()
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    id: "sessions",
    header: () => <Paragraph>Sessions</Paragraph>,
    cell: ({row}) => (
      <HoverCard openDelay={50} closeDelay={150}>
        <HoverCardTrigger asChild>
          <Paragraph className="text-theme underline hover:cursor-pointer hover:font-bold hover:text-theme/80">
            View Sessions
          </Paragraph>
        </HoverCardTrigger>
        <HoverCardContent>
          <UserSessionsTable userId={row.original.id} />
        </HoverCardContent>
      </HoverCard>
    ),
  },
  {
    id: "actions",
    header: () => <Paragraph className="text-right">Actions</Paragraph>,
    cell: ({row}) => (
      <div className="flex justify-end">
        <UserManagementRowActions row={row} id={row.original.id} />
      </div>
    ),
  },
];
