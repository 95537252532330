import {useCallback, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {useNavigate} from "@tanstack/react-router";
import {
  ArrowRightIcon,
  CheckIcon,
  ChevronsUpDownIcon,
  InfoIcon,
  PlusIcon,
  Trash2Icon,
} from "lucide-react";
import {useFieldArray, useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {Command, CommandGroup, CommandItem} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Spinner} from "@/components/ui/spinner";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {Heading2} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {cn} from "@/lib/utils";
import {
  CaptchaTimerField,
  captchaTimerMethods,
  dateFormats,
  fieldTypes,
  findElementByValues,
  loginSkip,
  PaymentTimerField,
  SkipLoginField,
  supplierScriptManagementSchema,
  SupplierScriptUploadDropzone,
  tableColumnNamesAddress,
  tableColumnNamesCustomerProfile,
  tableColumnNamesInvoiceDetail,
  tableColumnNamesInvoiceDetailAll,
  tableColumnNamesLoginCredential,
  tableColumnNamesPaymentDetail,
  tableColumnNamesPaymentRequest,
  tableColumnNamesPaymentResponse,
  tableColumnNamesSupplierDetail,
  tableNames,
  timerMethods,
  type ModuleTypeUnion,
  type OptionProps,
  type supplierScriptData,
} from "@/modules/imremit";
import {addSupplierScriptFn} from "@/modules/landing";

// Helper function to handle adding rows to the table
export const handleAddScriptRow = (
  rows: Array<SupplierScriptFormRow>,
  values: SupplierScriptFormRow
) => {
  return [...rows, values];
};

// Helper function to handle updating rows in the table
export const handleUpdateScriptRow = (
  rows: Array<SupplierScriptFormRow>,
  values: SupplierScriptFormRow,
  tableIndex: number
) => {
  const newRows = [...rows];
  newRows[tableIndex] = values;
  return newRows;
};

export interface DefaultProps {
  [key: string]: {value: string; label: string}[];
}

export interface SupplierScriptFormRow {
  sequenceId: string;
  pageCategory: string;
  fieldName: string;
  fieldType: string;
  fieldValue: string;
  dropDownValue: string;
  tableName: string;
  tableColumnName: string;
  dateFormatName: string;
  position: number;
  separator: string;
  sequenceIdentifier: string;
  timer: string;
  jsCheck: string;
  findElementBy: string;
}

/**
 * SupplierScriptFormProps Interface
 *
 * @interface SupplierScriptFormProps
 * @property {boolean} isMutating - Indicates whether the mutation is in progress.
 * @property {Function} onSubmit - The function to be called upon form submission.
 */
export interface SupplierScriptFormProps {
  timerValue: string;
  setTimerValue: (value: string) => void;
  captchaTimerValue: string;
  setCaptchTimerValue: (value: string) => void;
  isMutating: boolean;
  onSubmit: (data: z.infer<typeof supplierScriptManagementSchema>) => void;
}

export interface SupplierScriptProps {
  paymentTimerValue: string;
  setPaymentTimerValue: (value: string) => void;
  captchaTimerValue: string;
  setCaptchTimerValue: (value: string) => void;
  loginSkipValue: string;
  setLoginSkipValue: (value: string) => void;
  moduleName: string;
  paymentId: string;
  supplierId: string;
}
/**
 * AddSupplierScriptForm Component
 *
 * This component renders the AddSupplierScriptForm form and handles its behavior.
 *
 * @param {SupplierScriptFormProps} props - Properties passed to the component
 * @returns {JSX.Element} - Rendered component
 */

export function AddSupplierScriptForm({
  paymentTimerValue,
  setPaymentTimerValue,
  captchaTimerValue,
  setCaptchTimerValue,
  loginSkipValue,
  setLoginSkipValue,
  moduleName,
  paymentId,
  supplierId,
}: SupplierScriptProps) {
  const supplierScriptForm = useForm<
    z.infer<typeof supplierScriptManagementSchema>
  >({
    defaultValues: {
      supplierScriptRequests: [],
    },
    resolver: zodResolver(supplierScriptManagementSchema),
  });

  const handleInputSelectChange = useCallback(
    (script: OptionProps, scriptType: string) => {
      switch (scriptType) {
        case "paymentTimer":
          setPaymentTimerValue(script.value);
          break;
        case "captchaTimer":
          setCaptchTimerValue(script.value);
          break;
        case "loginSkip":
          setLoginSkipValue(script.value);
          break;
        default:
          break;
      }
    },
    [setCaptchTimerValue, setPaymentTimerValue, setLoginSkipValue]
  );

  const {fields, append, remove} = useFieldArray({
    control: supplierScriptForm.control,
    name: "supplierScriptRequests",
  });

  //supplier script page url
  const supplierScriptPageUrl = `/app/${
    moduleName as ModuleTypeUnion
  }/supplier-script-management` as const;

  //proxy pay dashboard view page url
  const proxyPayDashboardViewPageUrl = `/app/${
    moduleName as ModuleTypeUnion
  }/proxy-pay-dashboard/$paymentId/view` as const;

  const navigate = useNavigate();
  // Initialize Tanstack Query Mutation for Refreshing Response File
  const saveSupplierScriptMutation = useMutation({
    mutationFn: (data: z.infer<typeof supplierScriptData>) => {
      return addSupplierScriptFn(
        data,
        supplierId,
        paymentTimerValue,
        captchaTimerValue
      );
    },
    onSuccess: (response) => {
      console.log("addSupplierScriptResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Supplier script added successfully",
      });

      //If the payment id exist then route to proxy-pay details page or route to supplier script page
      paymentId === "0"
        ? void navigate({
            to: supplierScriptPageUrl,
            replace: true,
          })
        : void navigate({
            to: proxyPayDashboardViewPageUrl,
            replace: true,
            params: {paymentId: paymentId},
          });
    },
    onError: (error: string) => {
      const errorMessage =
        error || "An error occurred while adding supplier scripts!";
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add supplier scripts",
      });
    },
  });

  const isMutating = saveSupplierScriptMutation.isPending;

  function onSubmit(data: z.infer<typeof supplierScriptManagementSchema>) {
    saveSupplierScriptMutation.mutate(data.supplierScriptRequests);
  }

  // Watch form fields
  const sequenceIds = supplierScriptForm
    .watch("supplierScriptRequests")
    .map((item) => item.sequenceId);

  // Check for uniqueness
  const hasDuplicateSequenceIds =
    new Set(sequenceIds).size !== sequenceIds.length;

  // Convert sequenceIds to numbers
  const numericSequenceIds = sequenceIds
    .map(Number)
    .filter((value) => !isNaN(value));

  // Check for missing numbers
  const maxSequenceId = Math.max(...numericSequenceIds);
  const hasMissingNumbers = Array.from(
    {length: maxSequenceId},
    (_, i) => i + 1
  ).some((expectedNumber) => !numericSequenceIds.includes(expectedNumber));

  // Custom onSubmit function to handle form submission
  // For mututally exclusive fields, we need to check if either one is present

  const customOnSubmit = () => {
    if (hasDuplicateSequenceIds) {
      toast({
        variant: "destructive",
        title: "Unable to add supplier script!",
        description: "Please make sure the sequence id is unique",
      });
    } else if (hasMissingNumbers) {
      toast({
        variant: "destructive",
        title: "Unable to add supplier script!",
        description: "Please make sure there are no missing sequence numbers",
      });
    } else {
      const data = supplierScriptForm.getValues();
      const requestData = data.supplierScriptRequests.map((item) => ({
        ...item,
        ...(loginSkipValue === "true" || loginSkipValue === "false"
          ? {loginSkip: loginSkipValue === "true" ? true : false}
          : {}),
      }));
      onSubmit({...data, supplierScriptRequests: requestData});
    }
  };

  // Handler function for receiving CSV data from the SupplierScriptUploadDropzone component
  const handleCsvData = (csvData: string[][], headers: string[]) => {
    // Process the received CSV data and map each row into supplierScriptRequests
    const newSupplierScriptRequests = csvData.slice(1).map((row) => {
      const rowData: {[key: string]: string} = {}; // Object to store row data
      row.forEach((value, index) => {
        rowData[headers[index]] = value; // Map each column to its corresponding header
      });
      return {
        sequenceId: rowData["Seq. No"] || rowData["SEQUENCEID"],
        pageCategory: rowData["Page Name"] || rowData["PAGECATEGORY"],
        fieldName: rowData["Field Name"] || rowData["FIELDNAME"],
        fieldValue: rowData["X-Path"] || rowData["FIELDVALUE"],
        fieldType: rowData["Field Type"] || rowData["FIELDTYPE"],
        tableName: rowData["Table Name"] || rowData["TABLENAME"],
        tableColumnName:
          rowData["Table Col. Name"] || rowData["TABLECOLUMNNAME"],
        position: rowData["Position"] || rowData["POSITION"],
        dropDownValue: rowData["Default Value"] || rowData["DROPDOWNVALUE"],
        separator: rowData["Separator"] || rowData["SEPARATOR"],
        dateFormat: rowData["Date Format"] || rowData["DATEFORMAT"],
        sequenceIdentifier:
          rowData["Login Sequence"] || rowData["SEQUENCEIDENTIFIER"],
        timer: rowData["Timer"] || rowData["TIMER"],
        jsCheck: rowData["Js Check"] || rowData["JSCHECK"],
        findElementBy: rowData["Find Element By"] || rowData["FINDELEMENTBY"],
      };
    });

    // Concatenate the new data with the existing supplierScriptRequests array
    supplierScriptForm.setValue("supplierScriptRequests", [
      ...supplierScriptForm.getValues("supplierScriptRequests"),
      ...newSupplierScriptRequests,
    ]);
  };

  return (
    <section>
      <div className="mb-4 flex flex-col-reverse gap-4 md:flex-row md:justify-between">
        <div className="flex gap-4">
          <Heading2>Add New Supplier Script</Heading2>
        </div>
        <SupplierScriptUploadDropzone onDrop={handleCsvData} />
        <Button
          variant="secondary"
          onClick={() => {
            paymentId === "0"
              ? void navigate({
                  to: supplierScriptPageUrl,
                  replace: true,
                })
              : void navigate({
                  to: proxyPayDashboardViewPageUrl,
                  replace: true,
                  params: {paymentId: paymentId},
                });
          }}
        >
          Back to list
          <ArrowRightIcon className="ml-2 size-4" />
        </Button>
      </div>
      <Form {...supplierScriptForm}>
        <form onSubmit={supplierScriptForm.handleSubmit(customOnSubmit)}>
          <div className="mb-8 rounded-md border border-border bg-root p-4">
            <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-3 xl:grid-cols-3">
              <PaymentTimerField
                isMutating={isMutating}
                inputValue={paymentTimerValue}
                handleInputSelectChange={handleInputSelectChange}
                allItems={timerMethods}
                defaultInputValue="10"
              />
              <CaptchaTimerField
                isMutating={isMutating}
                inputValue={captchaTimerValue}
                handleInputSelectChange={handleInputSelectChange}
                allItems={captchaTimerMethods}
                defaultInputValue="120"
              />
              <SkipLoginField
                isMutating={false}
                inputValue={loginSkipValue}
                handleInputSelectChange={handleInputSelectChange}
                allItems={loginSkip}
              />
            </div>

            <Table>
              <TableCaption>
                {fields.length == 0 && <p> No Data.</p>}
              </TableCaption>
              <TableHeader>
                <TableRow>
                  <TableHead>Seq. No</TableHead>
                  <TableHead>Page Name </TableHead>
                  <TableHead>Field Name </TableHead>
                  <TableHead>X-Path </TableHead>
                  <TableHead>Field Type </TableHead>
                  <TableHead>Table Name </TableHead>
                  <TableHead>Table Col Name </TableHead>

                  <TableHead>
                    <div className="flex items-center justify-between gap-x-1">
                      Position
                      <TooltipProvider delayDuration={200}>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div className="cursor-pointer">
                              <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                            </div>
                          </TooltipTrigger>
                          <TooltipContent>
                            <div className="flex justify-between space-x-4">
                              Position will start from 0.
                            </div>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  </TableHead>

                  <TableHead>Default Value </TableHead>
                  <TableHead>Separator</TableHead>
                  <TableHead>Date Format</TableHead>
                  <TableHead>Login Sequence</TableHead>
                  <TableHead>
                    <div className="flex items-center justify-between gap-x-1">
                      Timer
                      <TooltipProvider delayDuration={200}>
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <div className="cursor-pointer">
                              <InfoIcon className="mr-2 size-4 text-neutral-foreground" />
                            </div>
                          </TooltipTrigger>
                          <TooltipContent>
                            <div className="flex justify-between space-x-4">
                              Since we are using automation, we need to <br />
                              specify time, so that it will wait until <br />
                              the page load for the specified amount of time.
                            </div>
                          </TooltipContent>
                        </Tooltip>
                      </TooltipProvider>
                    </div>
                  </TableHead>
                  <TableHead>Js Check</TableHead>
                  <TableHead>Find Element By </TableHead>
                  <TableHead className="text-right">Action </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {fields.map((item, index: number) => {
                  const fieldType = supplierScriptForm.watch(
                    `supplierScriptRequests.${index.toString()}.fieldType` as `supplierScriptRequests.${number}.fieldType`
                  );
                  const tableName = supplierScriptForm.watch(
                    `supplierScriptRequests.${index.toString()}.tableName` as `supplierScriptRequests.${number}.tableName`
                  );
                  return (
                    <TableRow key={item.id}>
                      <TableCell className="w-80 font-medium">
                        <FormField
                          control={supplierScriptForm.control}
                          name={
                            `supplierScriptRequests.${index.toString()}.sequenceId` as `supplierScriptRequests.${number}.sequenceId`
                          }
                          render={({field}) => (
                            <FormItem className="relative flex flex-col">
                              <FormControl>
                                <div className="relative">
                                  {!field.value && (
                                    <span className="absolute right-2 top-1/2 -translate-y-1/2 transform text-red-500">
                                      *
                                    </span>
                                  )}
                                  <Input
                                    className="h-[60px] w-[140px]"
                                    type="text"
                                    placeholder="Sequence No"
                                    {...supplierScriptForm.register(
                                      `supplierScriptRequests.${index.toString()}.sequenceId` as `supplierScriptRequests.${number}.sequenceId`
                                    )}
                                  />
                                </div>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </TableCell>
                      <TableCell className="w-80 font-medium">
                        <FormField
                          control={supplierScriptForm.control}
                          name={
                            `supplierScriptRequests.${index.toString()}.pageCategory` as `supplierScriptRequests.${number}.pageCategory`
                          }
                          render={({field}) => (
                            <FormItem className="relative flex flex-col">
                              <FormControl>
                                <div className="relative">
                                  {!field.value &&
                                    fieldType !== "payment_loop_start" &&
                                    fieldType !== "payment_loop_end" &&
                                    fieldType !==
                                      "insert_reCAPTCHA_v2_response" && (
                                      <span className="absolute right-2 top-1/2 -translate-y-1/2 transform text-red-500">
                                        *
                                      </span>
                                    )}
                                  <Input
                                    className="h-[60px] w-[140px]"
                                    type="text"
                                    placeholder="Page Name"
                                    {...supplierScriptForm.register(
                                      `supplierScriptRequests.${index.toString()}.pageCategory` as `supplierScriptRequests.${number}.pageCategory`
                                    )}
                                  />
                                </div>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </TableCell>
                      <TableCell className="w-80 font-medium">
                        <FormField
                          control={supplierScriptForm.control}
                          name={
                            `supplierScriptRequests.${index.toString()}.fieldName` as `supplierScriptRequests.${number}.fieldName`
                          }
                          render={({field}) => (
                            <FormItem className="relative flex flex-col">
                              <FormControl>
                                <div className="relative">
                                  {!field.value && (
                                    <span className="absolute right-2 top-1/2 -translate-y-1/2 transform text-red-500">
                                      *
                                    </span>
                                  )}
                                  <Input
                                    className="h-[60px] w-[140px]"
                                    type="text"
                                    placeholder="Field Name"
                                    {...supplierScriptForm.register(
                                      `supplierScriptRequests.${index.toString()}.fieldName` as `supplierScriptRequests.${number}.fieldName`
                                    )}
                                  />
                                </div>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </TableCell>

                      <TableCell className="w-80 font-medium">
                        <FormField
                          control={supplierScriptForm.control}
                          name={
                            `supplierScriptRequests.${index.toString()}.fieldValue` as `supplierScriptRequests.${number}.fieldValue`
                          }
                          render={({field}) => (
                            <FormItem className="relative flex flex-col">
                              <FormControl>
                                <div className="relative">
                                  {!field.value &&
                                    fieldType !== "handle_window" &&
                                    fieldType !== "switch_to_previous_tab" &&
                                    fieldType !== "get_reCAPTCHA_v2_response" &&
                                    fieldType !==
                                      "input_invoice_numbers_amounts" &&
                                    fieldType !== "payment_loop_start" &&
                                    fieldType !== "click_link_text" &&
                                    fieldType !== "payment_loop_end" &&
                                    fieldType !==
                                      "insert_reCAPTCHA_v2_response" && (
                                      <span className="absolute right-2 top-1/2 -translate-y-1/2 transform text-red-500">
                                        *
                                      </span>
                                    )}
                                  <Input
                                    className="h-[60px] w-[140px]"
                                    type="text"
                                    placeholder="X Path"
                                    disabled={
                                      fieldType === "iframe_exit" ||
                                      fieldType === "get_reCAPTCHA_v2_response"
                                    }
                                    {...supplierScriptForm.register(
                                      `supplierScriptRequests.${index.toString()}.fieldValue` as `supplierScriptRequests.${number}.fieldValue`
                                    )}
                                  />
                                </div>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </TableCell>

                      <TableCell className="w-80 font-medium">
                        <FormField
                          control={supplierScriptForm.control}
                          name={
                            `supplierScriptRequests.${index.toString()}.fieldType` as `supplierScriptRequests.${number}.fieldType`
                          }
                          render={({field}) => (
                            <Popover>
                              <PopoverContent className="w-[260px] p-0">
                                <Command>
                                  <CommandGroup>
                                    {fieldTypes.map((item, i) => (
                                      <CommandItem
                                        key={i}
                                        value={item.value}
                                        onSelect={() => {
                                          field.onChange(item.value);
                                        }}
                                      >
                                        <CheckIcon
                                          className={cn(
                                            "mr-2 size-4",
                                            item.value === field.value
                                              ? "opacity-100"
                                              : "opacity-0"
                                          )}
                                        />
                                        {item.label}
                                      </CommandItem>
                                    ))}
                                  </CommandGroup>
                                </Command>
                              </PopoverContent>
                              <PopoverTrigger asChild>
                                <FormControl>
                                  <Button
                                    variant="outline"
                                    role="combobox"
                                    className={cn(
                                      "justify-between",
                                      !field.value && "text-accent-foreground",
                                      "h-[60px] w-[260px]"
                                    )}
                                  >
                                    {field.value ? (
                                      fieldTypes.find(
                                        (item) => item.value === field.value
                                      )?.label
                                    ) : (
                                      <div>
                                        Select field Type
                                        <span className="text-red-500">*</span>
                                      </div>
                                    )}

                                    <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                  </Button>
                                </FormControl>
                              </PopoverTrigger>
                            </Popover>
                          )}
                        />
                      </TableCell>

                      <TableCell className="w-80 font-medium">
                        <FormField
                          control={supplierScriptForm.control}
                          name={
                            `supplierScriptRequests.${index.toString()}.tableName` as `supplierScriptRequests.${number}.tableName`
                          }
                          render={({field}) => (
                            <div className="space-y-2">
                              <FormItem>
                                <Popover>
                                  <PopoverContent className="w-[220px] p-0">
                                    <Command>
                                      <CommandGroup>
                                        {tableNames.map((tableName, i) => (
                                          <CommandItem
                                            key={i}
                                            value={tableName.value}
                                            onSelect={() => {
                                              field.onChange(tableName.value);
                                              supplierScriptForm.setValue(
                                                `supplierScriptRequests.${index.toString()}.tableColumnName` as `supplierScriptRequests.${number}.tableColumnName`,
                                                ""
                                              );
                                            }}
                                          >
                                            <CheckIcon
                                              className={cn(
                                                "mr-2 size-4",
                                                field.value &&
                                                  field.value ===
                                                    tableName.value
                                                  ? "opacity-100"
                                                  : "opacity-0"
                                              )}
                                            />
                                            {tableName.label}
                                          </CommandItem>
                                        ))}
                                      </CommandGroup>
                                    </Command>
                                  </PopoverContent>
                                  <PopoverTrigger asChild>
                                    <FormControl>
                                      <Button
                                        variant="outline"
                                        role="combobox"
                                        disabled={
                                          fieldType !== "input" &&
                                          fieldType !== "dropdown" &&
                                          fieldType !== "date_picker_table" &&
                                          fieldType !== "label_text" &&
                                          fieldType !== "payment_loop_start" &&
                                          fieldType !== "payment_loop_end" &&
                                          fieldType !==
                                            "input_invoice_numbers_amounts" &&
                                          fieldType !== "click_link_text" &&
                                          fieldType !== "search_invoices_in_div"
                                        }
                                        className={cn(
                                          "justify-between",
                                          !field.value &&
                                            "text-accent-foreground",
                                          "h-[60px] w-[220px]"
                                        )}
                                      >
                                        <span>
                                          {field.value ? (
                                            tableNames.find(
                                              (item) =>
                                                item.value === field.value
                                            )?.label
                                          ) : (
                                            <>
                                              Select table name
                                              {fieldType !== "input" &&
                                              fieldType !== "dropdown" &&
                                              fieldType !==
                                                "date_picker_table" &&
                                              fieldType !== "label_text" ? (
                                                ""
                                              ) : (
                                                <span className="text-red-500">
                                                  *
                                                </span>
                                              )}
                                            </>
                                          )}
                                        </span>

                                        <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                      </Button>
                                    </FormControl>
                                  </PopoverTrigger>
                                </Popover>
                                <FormMessage />
                              </FormItem>
                            </div>
                          )}
                        />
                      </TableCell>

                      <TableCell className="w-80 font-medium">
                        <FormField
                          control={supplierScriptForm.control}
                          name={
                            `supplierScriptRequests.${index.toString()}.tableColumnName` as `supplierScriptRequests.${number}.tableColumnName`
                          }
                          render={({field}) => (
                            <div className="space-y-2">
                              <FormItem>
                                <Popover>
                                  <PopoverContent className="w-[220px] p-0">
                                    <Command>
                                      <CommandGroup>
                                        {(() => {
                                          switch (tableName) {
                                            case "Payment Detail":
                                              return tableColumnNamesPaymentDetail.map(
                                                (column, i) => (
                                                  <CommandItem
                                                    key={i}
                                                    value={column.value}
                                                    onSelect={() => {
                                                      field.onChange(
                                                        column.value
                                                      );
                                                    }}
                                                  >
                                                    <CheckIcon
                                                      className={cn(
                                                        "mr-2 size-4",
                                                        field.value &&
                                                          field.value.toUpperCase() ===
                                                            column.value.toUpperCase()
                                                          ? "opacity-100"
                                                          : "opacity-0"
                                                      )}
                                                    />
                                                    {column.label}
                                                  </CommandItem>
                                                )
                                              );
                                            case "Payment Request":
                                              return tableColumnNamesPaymentRequest.map(
                                                (column, i) => (
                                                  <CommandItem
                                                    key={i}
                                                    value={column.value}
                                                    onSelect={() => {
                                                      field.onChange(
                                                        column.value
                                                      );
                                                    }}
                                                  >
                                                    <CheckIcon
                                                      className={cn(
                                                        "mr-2 size-4",
                                                        field.value &&
                                                          field.value.toUpperCase() ===
                                                            column.value.toUpperCase()
                                                          ? "opacity-100"
                                                          : "opacity-0"
                                                      )}
                                                    />
                                                    {column.label}
                                                  </CommandItem>
                                                )
                                              );
                                            case "Payment Response":
                                              return tableColumnNamesPaymentResponse.map(
                                                (column, i) => (
                                                  <CommandItem
                                                    key={i}
                                                    value={column.value}
                                                    onSelect={() => {
                                                      field.onChange(
                                                        column.value
                                                      );
                                                    }}
                                                  >
                                                    <CheckIcon
                                                      className={cn(
                                                        "mr-2 size-4",
                                                        field.value &&
                                                          field.value.toUpperCase() ===
                                                            column.value.toUpperCase()
                                                          ? "opacity-100"
                                                          : "opacity-0"
                                                      )}
                                                    />
                                                    {column.label}
                                                  </CommandItem>
                                                )
                                              );

                                            case "Supplier Detail":
                                              return tableColumnNamesSupplierDetail.map(
                                                (column, i) => (
                                                  <CommandItem
                                                    key={i}
                                                    value={column.value}
                                                    onSelect={() => {
                                                      field.onChange(
                                                        column.value
                                                      );
                                                    }}
                                                  >
                                                    <CheckIcon
                                                      className={cn(
                                                        "mr-2 size-4",
                                                        field.value &&
                                                          field.value.toUpperCase() ===
                                                            column.value.toUpperCase()
                                                          ? "opacity-100"
                                                          : "opacity-0"
                                                      )}
                                                    />
                                                    {column.label}
                                                  </CommandItem>
                                                )
                                              );
                                            case "Customer Profile":
                                              return tableColumnNamesCustomerProfile.map(
                                                (column, i) => (
                                                  <CommandItem
                                                    key={i}
                                                    value={column.value}
                                                    onSelect={() => {
                                                      field.onChange(
                                                        column.value
                                                      );
                                                    }}
                                                  >
                                                    <CheckIcon
                                                      className={cn(
                                                        "mr-2 size-4",
                                                        field.value &&
                                                          field.value.toUpperCase() ===
                                                            column.value.toUpperCase()
                                                          ? "opacity-100"
                                                          : "opacity-0"
                                                      )}
                                                    />
                                                    {column.label}
                                                  </CommandItem>
                                                )
                                              );
                                            case "Invoice Detail":
                                              return tableColumnNamesInvoiceDetail.map(
                                                (column, i) => (
                                                  <CommandItem
                                                    key={i}
                                                    value={column.value}
                                                    onSelect={() => {
                                                      field.onChange(
                                                        column.value
                                                      );
                                                    }}
                                                  >
                                                    <CheckIcon
                                                      className={cn(
                                                        "mr-2 size-4",
                                                        field.value &&
                                                          field.value.toUpperCase() ===
                                                            column.value.toUpperCase()
                                                          ? "opacity-100"
                                                          : "opacity-0"
                                                      )}
                                                    />
                                                    {column.label}
                                                  </CommandItem>
                                                )
                                              );
                                            case "Invoice Detail All":
                                              return tableColumnNamesInvoiceDetailAll.map(
                                                (column, i) => (
                                                  <CommandItem
                                                    key={i}
                                                    value={column.value}
                                                    onSelect={() => {
                                                      field.onChange(
                                                        column.value
                                                      );
                                                    }}
                                                  >
                                                    <CheckIcon
                                                      className={cn(
                                                        "mr-2 size-4",
                                                        field.value &&
                                                          field.value.toUpperCase() ===
                                                            column.value.toUpperCase()
                                                          ? "opacity-100"
                                                          : "opacity-0"
                                                      )}
                                                    />
                                                    {column.label}
                                                  </CommandItem>
                                                )
                                              );
                                            case "Address":
                                              return tableColumnNamesAddress.map(
                                                (column, i) => (
                                                  <CommandItem
                                                    key={i}
                                                    value={column.value}
                                                    onSelect={() => {
                                                      field.onChange(
                                                        column.value
                                                      );
                                                    }}
                                                  >
                                                    <CheckIcon
                                                      className={cn(
                                                        "mr-2 size-4",
                                                        field.value &&
                                                          field.value.toUpperCase() ===
                                                            column.value.toUpperCase()
                                                          ? "opacity-100"
                                                          : "opacity-0"
                                                      )}
                                                    />
                                                    {column.label}
                                                  </CommandItem>
                                                )
                                              );
                                            case "Login Credential":
                                              return tableColumnNamesLoginCredential.map(
                                                (column, i) => (
                                                  <CommandItem
                                                    key={i}
                                                    value={column.value}
                                                    onSelect={() => {
                                                      field.onChange(
                                                        column.value
                                                      );
                                                    }}
                                                  >
                                                    <CheckIcon
                                                      className={cn(
                                                        "mr-2 size-4",
                                                        field.value &&
                                                          field.value.toUpperCase() ===
                                                            column.value.toUpperCase()
                                                          ? "opacity-100"
                                                          : "opacity-0"
                                                      )}
                                                    />
                                                    {column.label}
                                                  </CommandItem>
                                                )
                                              );
                                            case "":
                                              return tableColumnNamesSupplierDetail.map(
                                                (column, i) => (
                                                  <CommandItem
                                                    key={i}
                                                    value={column.value}
                                                    onSelect={() => {
                                                      field.onChange(
                                                        column.value
                                                      );
                                                    }}
                                                  >
                                                    <CheckIcon
                                                      className={cn(
                                                        "mr-2 size-4",
                                                        field.value &&
                                                          field.value.toUpperCase() ===
                                                            column.value.toUpperCase()
                                                          ? "opacity-100"
                                                          : "opacity-0"
                                                      )}
                                                    />
                                                    {column.label}
                                                  </CommandItem>
                                                )
                                              );
                                            default:
                                              return tableColumnNamesSupplierDetail.map(
                                                (column, i) => (
                                                  <CommandItem
                                                    key={i}
                                                    value={column.value}
                                                    onSelect={() => {
                                                      field.onChange(
                                                        column.value
                                                      );
                                                    }}
                                                  >
                                                    <CheckIcon
                                                      className={cn(
                                                        "mr-2 size-4",
                                                        field.value &&
                                                          field.value.toUpperCase() ===
                                                            column.value.toUpperCase()
                                                          ? "opacity-100"
                                                          : "opacity-0"
                                                      )}
                                                    />
                                                    {column.label}
                                                  </CommandItem>
                                                )
                                              );
                                          }
                                        })()}
                                      </CommandGroup>
                                    </Command>
                                  </PopoverContent>
                                  <PopoverTrigger asChild>
                                    <FormControl>
                                      <Button
                                        variant="outline"
                                        role="combobox"
                                        disabled={
                                          fieldType !== "input" &&
                                          fieldType !== "dropdown" &&
                                          fieldType !== "date_picker_table" &&
                                          fieldType !== "label_text" &&
                                          fieldType !== "payment_loop_start" &&
                                          fieldType !== "click_link_text" &&
                                          fieldType !== "payment_loop_end" &&
                                          fieldType !==
                                            "input_invoice_numbers_amounts" &&
                                          fieldType !== "search_invoices_in_div"
                                        }
                                        className={cn(
                                          "justify-between",
                                          !field.value &&
                                            "text-accent-foreground",
                                          "h-[60px] w-[220px]"
                                        )}
                                      >
                                        <span>
                                          {field.value ? (
                                            (() => {
                                              switch (tableName) {
                                                case "Payment Detail":
                                                  return tableColumnNamesPaymentDetail.find(
                                                    (item) =>
                                                      item.value.toLowerCase() ===
                                                      field.value?.toLowerCase()
                                                  )?.label;
                                                case "Payment Request":
                                                  return tableColumnNamesPaymentRequest.find(
                                                    (item) =>
                                                      item.value.toLowerCase() ===
                                                      field.value?.toLowerCase()
                                                  )?.label;
                                                case "Payment Response":
                                                  return tableColumnNamesPaymentResponse.find(
                                                    (item) =>
                                                      item.value.toLowerCase() ===
                                                      field.value?.toLowerCase()
                                                  )?.label;
                                                case "Supplier Detail":
                                                  return tableColumnNamesSupplierDetail.find(
                                                    (item) =>
                                                      item.value.toLowerCase() ===
                                                      field.value?.toLowerCase()
                                                  )?.label;
                                                case "Customer Profile":
                                                  return tableColumnNamesCustomerProfile.find(
                                                    (item) =>
                                                      item.value.toLowerCase() ===
                                                      field.value?.toLowerCase()
                                                  )?.label;
                                                case "Invoice Detail":
                                                  return tableColumnNamesInvoiceDetail.find(
                                                    (item) =>
                                                      item.value.toLowerCase() ===
                                                      field.value?.toLowerCase()
                                                  )?.label;
                                                case "Invoice Detail All":
                                                  return tableColumnNamesInvoiceDetailAll.find(
                                                    (item) =>
                                                      item.value.toLowerCase() ===
                                                      field.value?.toLowerCase()
                                                  )?.label;
                                                case "Address":
                                                  return tableColumnNamesAddress.find(
                                                    (item) =>
                                                      item.value.toLowerCase() ===
                                                      field.value?.toLowerCase()
                                                  )?.label;
                                                case "Login Credential":
                                                  return tableColumnNamesLoginCredential.find(
                                                    (item) =>
                                                      item.value.toLowerCase() ===
                                                      field.value?.toLowerCase()
                                                  )?.label;
                                                case "":
                                                  return tableColumnNamesSupplierDetail.find(
                                                    (item) =>
                                                      item.value.toLowerCase() ===
                                                      field.value?.toLowerCase()
                                                  )?.label;
                                                default:
                                                  return tableColumnNamesSupplierDetail.find(
                                                    (item) =>
                                                      item.value.toLowerCase() ===
                                                      field.value?.toLowerCase()
                                                  )?.label;
                                              }
                                            })()
                                          ) : (
                                            <>
                                              Select table column name
                                              {fieldType !== "input" &&
                                              fieldType !== "dropdown" &&
                                              fieldType !==
                                                "date_picker_table" &&
                                              fieldType !== "label_text" ? (
                                                ""
                                              ) : (
                                                <span className="text-red-500">
                                                  *
                                                </span>
                                              )}
                                            </>
                                          )}
                                        </span>

                                        <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                      </Button>
                                    </FormControl>
                                  </PopoverTrigger>
                                </Popover>
                                <FormMessage />
                              </FormItem>
                            </div>
                          )}
                        />
                      </TableCell>

                      <TableCell className="w-80 font-medium">
                        <FormField
                          control={supplierScriptForm.control}
                          name={
                            `supplierScriptRequests.${index.toString()}.position` as `supplierScriptRequests.${number}.position`
                          }
                          render={() => (
                            <FormItem className="flex flex-col ">
                              <FormControl>
                                <Input
                                  className="h-[60px] w-[140px]"
                                  type="text"
                                  max="20"
                                  placeholder="Position"
                                  {...supplierScriptForm.register(
                                    `supplierScriptRequests.${index.toString()}.position` as `supplierScriptRequests.${number}.position`
                                  )}
                                  disabled={
                                    fieldType !== "input" &&
                                    fieldType !== "date_picker_table" &&
                                    fieldType !== "handle_window" &&
                                    fieldType !== "payment_loop_start" &&
                                    fieldType !== "payment_loop_end" &&
                                    fieldType !== "click_link_text" &&
                                    fieldType !==
                                      "input_invoice_numbers_amounts" &&
                                    fieldType !== "search_invoices_in_div"
                                  }
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </TableCell>

                      <TableCell className="w-80 font-medium">
                        <FormField
                          control={supplierScriptForm.control}
                          name={
                            `supplierScriptRequests.${index.toString()}.dropDownValue` as `supplierScriptRequests.${number}.dropDownValue`
                          }
                          render={({field}) => (
                            <FormItem className="relative flex flex-col">
                              <FormControl>
                                <div className="relative">
                                  {!field.value &&
                                    (fieldType == "alert" ||
                                      fieldType == "dropdown") && (
                                      <span className="absolute right-2 top-1/2 -translate-y-1/2 transform text-red-500">
                                        *
                                      </span>
                                    )}
                                  <Input
                                    className="h-[60px] w-[140px]"
                                    type="text"
                                    placeholder="Default Value"
                                    {...supplierScriptForm.register(
                                      `supplierScriptRequests.${index.toString()}.dropDownValue` as `supplierScriptRequests.${number}.dropDownValue`
                                    )}
                                    disabled={
                                      fieldType !== "alert" &&
                                      fieldType !== "dropdown" &&
                                      fieldType !==
                                        "get_reCAPTCHA_v2_response" &&
                                      fieldType !== "payment_loop_start" &&
                                      fieldType !== "payment_loop_end" &&
                                      fieldType !==
                                        "input_invoice_numbers_amounts" &&
                                      fieldType !== "search_invoices_in_div"
                                    }
                                  />
                                </div>
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </TableCell>

                      <TableCell className="w-80 font-medium">
                        <FormField
                          control={supplierScriptForm.control}
                          name={
                            `supplierScriptRequests.${index.toString()}.separator` as `supplierScriptRequests.${number}.separator`
                          }
                          render={() => (
                            <FormItem className="flex flex-col ">
                              <FormControl>
                                <Input
                                  className="h-[60px] w-[140px]"
                                  placeholder="Separator"
                                  {...supplierScriptForm.register(
                                    `supplierScriptRequests.${index.toString()}.separator` as `supplierScriptRequests.${number}.separator`
                                  )}
                                  disabled={
                                    fieldType !== "input" &&
                                    fieldType !== "date_picker_table" &&
                                    fieldType !== "payment_loop_start" &&
                                    fieldType !== "payment_loop_end" &&
                                    fieldType !== "click_link_text" &&
                                    fieldType !==
                                      "input_invoice_numbers_amounts" &&
                                    fieldType !== "search_invoices_in_div"
                                  }
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </TableCell>

                      <TableCell className="w-80 font-medium">
                        <FormField
                          control={supplierScriptForm.control}
                          name={
                            `supplierScriptRequests.${index.toString()}.dateFormat` as `supplierScriptRequests.${number}.dateFormat`
                          }
                          render={({field}) => (
                            <div className="space-y-2">
                              <FormItem>
                                <Popover>
                                  <PopoverContent className="w-[220px] p-0">
                                    <Command>
                                      <CommandGroup>
                                        {dateFormats.map((dateFormat, i) => (
                                          <CommandItem
                                            key={i}
                                            value={dateFormat.value}
                                            onSelect={() => {
                                              field.onChange(dateFormat.value);
                                            }}
                                          >
                                            <CheckIcon
                                              className={cn(
                                                "mr-2 size-4",
                                                field.value &&
                                                  field.value ===
                                                    dateFormat.value
                                                  ? "opacity-100"
                                                  : "opacity-0"
                                              )}
                                            />
                                            {dateFormat.label}
                                          </CommandItem>
                                        ))}
                                      </CommandGroup>
                                    </Command>
                                  </PopoverContent>
                                  <PopoverTrigger asChild>
                                    <FormControl>
                                      <Button
                                        variant="outline"
                                        role="combobox"
                                        disabled={
                                          fieldType !== "date_picker_table" &&
                                          fieldType !== "dropdown" &&
                                          fieldType !== "payment_loop_start" &&
                                          fieldType !== "payment_loop_end" &&
                                          fieldType !==
                                            "input_invoice_numbers_amounts" &&
                                          fieldType !== "search_invoices_in_div"
                                        }
                                        className={cn(
                                          "justify-between",
                                          !field.value &&
                                            "text-accent-foreground",
                                          "h-[60px] w-[220px]"
                                        )}
                                      >
                                        <span>
                                          {field.value ? (
                                            dateFormats.find(
                                              (item) =>
                                                item.value === field.value
                                            )?.label
                                          ) : (
                                            <>
                                              Select date format
                                              {fieldType !==
                                              "date_picker_table" ? (
                                                ""
                                              ) : (
                                                <span className="text-red-500">
                                                  *
                                                </span>
                                              )}
                                            </>
                                          )}
                                        </span>

                                        <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                      </Button>
                                    </FormControl>
                                  </PopoverTrigger>
                                </Popover>
                                <FormMessage />
                              </FormItem>
                            </div>
                          )}
                        />
                      </TableCell>

                      <TableCell className="w-80 font-medium">
                        <FormField
                          control={supplierScriptForm.control}
                          name={
                            `supplierScriptRequests.${index.toString()}.sequenceIdentifier` as `supplierScriptRequests.${number}.sequenceIdentifier`
                          }
                          render={() => (
                            <FormItem className="flex flex-col ">
                              <FormControl>
                                <Input
                                  className="h-[60px] w-[140px]"
                                  placeholder="Login Sequence"
                                  {...supplierScriptForm.register(
                                    `supplierScriptRequests.${index.toString()}.sequenceIdentifier` as `supplierScriptRequests.${number}.sequenceIdentifier`
                                  )}
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </TableCell>

                      <TableCell className="w-80 font-medium">
                        <FormField
                          control={supplierScriptForm.control}
                          name={
                            `supplierScriptRequests.${index.toString()}.timer` as `supplierScriptRequests.${number}.timer`
                          }
                          render={({field}) => (
                            <div className="space-y-2">
                              <FormItem>
                                <Popover>
                                  <PopoverContent className="w-[220px] p-0">
                                    <Command>
                                      <CommandGroup>
                                        {timerMethods.map((timer, i) => (
                                          <CommandItem
                                            key={i}
                                            value={timer.value}
                                            onSelect={(value) => {
                                              field.onChange(value);
                                            }}
                                          >
                                            <CheckIcon
                                              className={cn(
                                                "mr-2 size-4",
                                                field.value &&
                                                  field.value.toUpperCase() ===
                                                    timer.value.toUpperCase()
                                                  ? "opacity-100"
                                                  : "opacity-0"
                                              )}
                                            />
                                            {timer.label}
                                          </CommandItem>
                                        ))}
                                      </CommandGroup>
                                    </Command>
                                  </PopoverContent>
                                  <PopoverTrigger asChild>
                                    <FormControl>
                                      <Button
                                        variant="outline"
                                        role="combobox"
                                        className={cn(
                                          "justify-between",
                                          !field.value &&
                                            "text-accent-foreground",
                                          "h-[60px] w-[220px]"
                                        )}
                                      >
                                        <span>
                                          {field.value ? (
                                            timerMethods.find(
                                              (item) =>
                                                item.value.toLowerCase() ===
                                                field.value
                                            )?.label
                                          ) : (
                                            <>
                                              Select timer
                                              <span className="text-red-500">
                                                *
                                              </span>
                                            </>
                                          )}
                                        </span>

                                        <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                      </Button>
                                    </FormControl>
                                  </PopoverTrigger>
                                </Popover>
                                <FormMessage />
                              </FormItem>
                            </div>
                          )}
                        />
                      </TableCell>

                      <TableCell className="w-80 font-medium">
                        <FormField
                          control={supplierScriptForm.control}
                          name={
                            `supplierScriptRequests.${index.toString()}.jsCheck` as `supplierScriptRequests.${number}.jsCheck`
                          }
                          render={() => (
                            <FormItem className="flex flex-col ">
                              <FormControl>
                                <Input
                                  className="h-[60px] w-[140px]"
                                  placeholder="JS Check"
                                  {...supplierScriptForm.register(
                                    `supplierScriptRequests.${index.toString()}.jsCheck` as `supplierScriptRequests.${number}.jsCheck`
                                  )}
                                  disabled={
                                    fieldType !== "input" &&
                                    fieldType !== "dropdown" &&
                                    fieldType !== "date_picker_table" &&
                                    fieldType !== "error" &&
                                    fieldType !== "confirmation_number" &&
                                    fieldType !== "alert" &&
                                    fieldType !== "pop_up_msg_ok_yes" &&
                                    fieldType !== "checkbox" &&
                                    fieldType !== "captcha_checkbox" &&
                                    fieldType !== "captcha_image" &&
                                    fieldType !== "captcha_image_value" &&
                                    fieldType !== "button" &&
                                    fieldType !== "label_text" &&
                                    fieldType !== "login_button" &&
                                    fieldType !== "payment_loop_start" &&
                                    fieldType !== "payment_loop_end" &&
                                    fieldType !==
                                      "input_invoice_numbers_amounts" &&
                                    fieldType !== "search_invoices_in_div" &&
                                    fieldType !== ""
                                  }
                                />
                              </FormControl>
                              <FormMessage />
                            </FormItem>
                          )}
                        />
                      </TableCell>

                      <TableCell className="w-80 font-medium">
                        <FormField
                          control={supplierScriptForm.control}
                          name={
                            `supplierScriptRequests.${index.toString()}.findElementBy` as `supplierScriptRequests.${number}.findElementBy`
                          }
                          render={({field}) => (
                            <div className="space-y-2">
                              <FormItem>
                                <Popover>
                                  <PopoverContent className="w-[220px] p-0">
                                    <Command>
                                      <CommandGroup>
                                        {findElementByValues.map(
                                          (findElementByValue, i) => (
                                            <CommandItem
                                              key={i}
                                              value={findElementByValue.value}
                                              onSelect={() => {
                                                field.onChange(
                                                  findElementByValue.value
                                                );
                                              }}
                                            >
                                              <CheckIcon
                                                className={cn(
                                                  "mr-2 size-4",
                                                  field.value &&
                                                    field.value.toUpperCase() ===
                                                      findElementByValue.value.toUpperCase()
                                                    ? "opacity-100"
                                                    : "opacity-0"
                                                )}
                                              />
                                              {findElementByValue.label}
                                            </CommandItem>
                                          )
                                        )}
                                      </CommandGroup>
                                    </Command>
                                  </PopoverContent>
                                  <PopoverTrigger asChild>
                                    <FormControl>
                                      <Button
                                        variant="outline"
                                        role="combobox"
                                        disabled={
                                          fieldType !== "input" &&
                                          fieldType !== "dropdown" &&
                                          fieldType !== "date_picker_table" &&
                                          fieldType !== "error" &&
                                          fieldType !== "confirmation_number" &&
                                          fieldType !== "alert" &&
                                          fieldType !== "pop_up_msg_ok_yes" &&
                                          fieldType !== "checkbox" &&
                                          fieldType !== "captcha_checkbox" &&
                                          fieldType !== "captcha_image" &&
                                          fieldType !== "captcha_image_value" &&
                                          fieldType !== "button" &&
                                          fieldType !== "label_text" &&
                                          fieldType !== "login_button" &&
                                          fieldType !== "iframe" &&
                                          fieldType !== "iframe_exit" &&
                                          fieldType !== "scroll_to_element" &&
                                          fieldType !== "switch_to_tab" &&
                                          fieldType !== "switch_to_window" &&
                                          fieldType !==
                                            "switch_to_previous_tab" &&
                                          fieldType !==
                                            "insert_reCAPTCHA_v2_response" &&
                                          fieldType !== "" &&
                                          fieldType !== "payment_loop_start" &&
                                          fieldType !== "payment_loop_end" &&
                                          fieldType !==
                                            "input_invoice_numbers_amounts" &&
                                          fieldType !== "search_invoices_in_div"
                                        }
                                        className={cn(
                                          "justify-between",
                                          !field.value &&
                                            "text-accent-foreground",
                                          "h-[60px] w-[220px]"
                                        )}
                                      >
                                        <span>
                                          {field.value ? (
                                            findElementByValues.find(
                                              (item) =>
                                                item.value === field.value
                                            )?.label
                                          ) : (
                                            <>
                                              Select find element by
                                              {fieldType !== "input" &&
                                              fieldType !== "dropdown" &&
                                              fieldType !==
                                                "date_picker_table" &&
                                              fieldType !== "error" &&
                                              fieldType !==
                                                "confirmation_number" &&
                                              fieldType !== "alert" &&
                                              fieldType !==
                                                "pop_up_msg_ok_yes" &&
                                              fieldType !== "checkbox" &&
                                              fieldType !==
                                                "captcha_checkbox" &&
                                              fieldType !== "captcha_image" &&
                                              fieldType !==
                                                "captcha_image_value" &&
                                              fieldType !== "button" &&
                                              fieldType !== "label_text" &&
                                              fieldType !== "login_button" &&
                                              fieldType !==
                                                "scroll_to_element" &&
                                              fieldType !== "switch_to_tab" &&
                                              fieldType !==
                                                "switch_to_window" &&
                                              fieldType !==
                                                "switch_to_previous_tab" &&
                                              fieldType !== "" ? (
                                                ""
                                              ) : (
                                                <span className="text-red-500">
                                                  *
                                                </span>
                                              )}
                                            </>
                                          )}
                                        </span>

                                        <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                                      </Button>
                                    </FormControl>
                                  </PopoverTrigger>
                                </Popover>
                                <FormMessage />
                              </FormItem>
                            </div>
                          )}
                        />
                      </TableCell>

                      <TableCell className="text-right">
                        <Button
                          className="flex items-center gap-2 px-3"
                          variant="outline"
                          size="xs"
                          onClick={() => {
                            remove(index);
                          }}
                        >
                          <Trash2Icon className="size-4" />
                          <span className="sr-only">Delete</span>
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>

            <div className="flex items-center justify-end space-x-2 text-right ">
              <Button
                type="button"
                onClick={() => {
                  append({
                    sequenceId: (fields.length + 1).toString(),
                    pageCategory: "",
                    fieldName: "",
                    fieldValue: "",
                    fieldType: "",
                    tableName: "",
                    tableColumnName: "",
                    position: "",
                    dropDownValue: "",
                    separator: "",
                    dateFormat: "",
                    sequenceIdentifier: "",
                    timer: "",
                    jsCheck: "",
                    findElementBy: "",
                  });
                }}
              >
                <PlusIcon className="mr-2 size-4" /> Add
              </Button>
              <Button
                className="bg-success-foreground text-white hover:bg-success-foreground/80"
                type="submit"
                disabled={isMutating || !fields.length}
                aria-disabled={isMutating}
                variant="affirmative"
              >
                {isMutating ? <Spinner size="xs" /> : ""} Submit
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </section>
  );
}

interface AddGenericSupplierScriptPageProps {
  moduleName: string;
  paymentId: string;
  supplierId: string;
}

/**
 * AddGenericSupplierScriptPage
 *
 * This page serves as the main entry point for to AddGenericSupplierScriptPage.
 *
 * @param {string} - moduleName
 * @returns  {JSX.Element} - Rendered page
 */
export function AddGenericSupplierScriptPage({
  moduleName,
  paymentId,
  supplierId,
}: AddGenericSupplierScriptPageProps) {
  const [paymentTimerValue, setPaymentTimerValue] = useState<string>("10");
  const [captchaTimerValue, setCaptchTimerValue] = useState<string>("120");
  const [loginSkipValue, setLoginSkipValue] = useState<string>("");

  // Render AddSupplierScriptForm component
  return (
    <AddSupplierScriptForm
      paymentTimerValue={paymentTimerValue}
      setPaymentTimerValue={setPaymentTimerValue}
      captchaTimerValue={captchaTimerValue}
      setCaptchTimerValue={setCaptchTimerValue}
      loginSkipValue={loginSkipValue}
      setLoginSkipValue={setLoginSkipValue}
      moduleName={moduleName}
      paymentId={paymentId}
      supplierId={supplierId}
    />
  );
}
