import {useMemo} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate} from "@tanstack/react-router";
import {ArrowRightIcon, UserCog2Icon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button, buttonVariants} from "@/components/ui/button";
import {Form} from "@/components/ui/form";
import {Heading2} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {useImRemitCustomerStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {SelectCustomerSectionImremit} from "@/modules/customers";
import {useGetAllFacilitiesByCustomer} from "@/modules/facilities";
import type {SupplierDetailsType} from "@/modules/imremit";
import {
  AddImREmitFormSchema,
  DefineForm,
  NotifyForm,
  SelectForm,
  useGetAllSuppliersList,
  useGetPaymentMethodNames,
} from "@/modules/imremit";
import {addImREmitFn} from "@/modules/landing";

import {FallbackMessages} from "@/utils/constants";

/**
 * Transforms a payment method item to a select option format.
 * @param item - The payment method item with id and optional name.
 * @returns An object with `value` as string and `label`.
 */
function toPaymentMethodOption(item: {
  customerPaymentMethodId: number;
  paymentMethodName?: string;
  providerId: number;
}) {
  return {
    value: item.customerPaymentMethodId.toString(),
    label: item.paymentMethodName
      ? item.paymentMethodName
      : FallbackMessages.NOT_APPLICABLE,
    id: item.providerId,
  };
}

/**
 * Transforms a supplier item to a select option format.
 * @param item - The supplier item with supplierId and optional supplierName.
 * @returns An object with `value` as string and `label`.
 */
function toSupplierOption(item: SupplierDetailsType) {
  return {
    value: item.supplierId.toString(),
    label:
      item.supplierName && item.supplierNumber
        ? item.supplierNumber + "-" + item.supplierName
        : FallbackMessages.NOT_APPLICABLE,
  };
}

/**
 * Transforms a facility item to a select option format.
 * @param item - The facility item with id and optional name.
 * @returns An object with `value` as string and `label`.
 */
function toFacilityOption(item: {
  participantRegisterId: number;
  facilityName?: string;
}) {
  return {
    value: item.participantRegisterId.toString(),
    label: item.facilityName ?? FallbackMessages.NOT_APPLICABLE,
  };
}

/**
 * Custom hook for fetching and memoizing payment methods.
 * @param externalId - Optional participant ID to filter payment methods.
 * @returns A memoized array of payment method options.
 */
export function usePaymentMethods(externalId?: string) {
  const {data} = useGetPaymentMethodNames(externalId?.toString());
  // Transform data to match the expected shape for toPaymentMethodOption
  const transformedData = useMemo(() => {
    return (
      data?.content.map((item) => ({
        customerPaymentMethodId: item.customerPaymentMethodId,
        paymentMethodName: item.paymentMethodName ?? undefined, // Ensure it is either string or undefined
        providerId: item.providerId ?? 0, // Provide a default value if null
      })) ?? []
    );
  }, [data]);

  return transformedData.map(toPaymentMethodOption);
}

/**
 * Custom hook for fetching and memoizing suppliers.
 * @param externalId - Optional participant ID to filter suppliers.
 * @returns A memoized array of supplier options.
 */
export function useSuppliers(externalId?: string) {
  const {data} = useGetAllSuppliersList(externalId, "Payment_Management");
  // Memoizes the transformed supplier data for performance optimization
  return useMemo(() => data?.content.map(toSupplierOption) ?? [], [data]);
}

/**
 * Custom hook for fetching and memoizing facilities.
 * @param externalId - Optional participant ID to filter facilities.
 * @returns A memoized array of facility options.
 */
export function useFacilities(externalId?: string) {
  const validParticipantIds = [externalId].filter(Boolean) as string[];
  const {data} = useGetAllFacilitiesByCustomer(validParticipantIds);

  // Memoizes the transformed facilities data for performance optimization
  return useMemo(() => data?.content.map(toFacilityOption) ?? [], [data]);
}

/**
 * AddImREmitFormProps Interface
 *
 * @interface AddImREmitFormProps
 * @property {boolean} isMutating - Indicates whether the mutation is in progress.
 * @property {Function} onSubmit - The function to be called upon form submission.
 */
interface AddImREmitFormProps {
  isMutating: boolean;
  onSubmit: (data: z.infer<typeof AddImREmitFormSchema>) => void;
}

/**
 * AddImREmitForm Component
 *
 * This component renders the AddImREmitForm form and handles its behavior.
 *
 * @param {AddImREmitFormProps} props - Properties passed to the component
 * @returns {JSX.Element} - Rendered component
 */

function AddImREmitForm({isMutating, onSubmit}: AddImREmitFormProps) {
  const {imREmitStoreCustomer} = useImRemitCustomerStore();
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  // Define form using react-hook-form
  const addImREmitForm = useForm<z.infer<typeof AddImREmitFormSchema>>({
    defaultValues: {
      initiatedDate: new Date(),
      endDate: new Date(new Date().setMonth(new Date().getMonth() + 2)),
      modifiedBy:
        storeKeyCloakInstance?.tokenParsed?.sub
          ?.replace(/\D/g, "")
          .substring(0, 14) ?? null,
    },
    mode: "onChange",
    resolver: zodResolver(AddImREmitFormSchema),
  });

  const {
    formState: {errors},
  } = addImREmitForm;

  console.log("errors", errors);

  // Use custom hooks to fetch and memoize options
  const allPaymentMethods = usePaymentMethods(imREmitStoreCustomer?.externalId);
  const allSuppliers = useSuppliers(imREmitStoreCustomer?.externalId);
  const allFacilities = useFacilities(
    imREmitStoreCustomer?.externalId.toString()
  );

  // If the customer is not yet selected, render the SelectCustomerSectionImremit
  if (!imREmitStoreCustomer) {
    return <SelectCustomerSectionImremit />;
  }

  // Otherwise, render the AddImREmitForm component
  return (
    <>
      <section>
        <div className="mb-4 flex flex-col-reverse gap-4 md:flex-row md:justify-between">
          <div className="flex gap-4">
            <Heading2>Add Payment</Heading2>
          </div>

          <Link
            to="/app/imremit/payment-management"
            className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
          >
            Back to list
            <span className="sr-only">Back to list</span>
            <ArrowRightIcon className="size-4" />
          </Link>
        </div>

        <div className="mb-8 rounded-md border border-border bg-root p-4">
          <Form {...addImREmitForm}>
            <form onSubmit={addImREmitForm.handleSubmit(onSubmit)}>
              <SelectForm
                isMutating={isMutating}
                allFacilities={allFacilities}
                allPaymentMethods={allPaymentMethods}
                allSuppliers={allSuppliers}
              />
              <DefineForm isMutating={isMutating} />
              <NotifyForm isMutating={isMutating} />

              <div className="flex w-full flex-row justify-end gap-2">
                <Button
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  className="gap-2 whitespace-nowrap bg-success-foreground text-root hover:bg-success-foreground/80 active:bg-success-foreground/80"
                  type="submit"
                >
                  <span className="sr-only">Submit form</span>
                  Create imREmit <UserCog2Icon className="size-6" />
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </section>
    </>
  );
}

/**
 * Custom hook for performing the 'Add Im Remit' mutation.
 * This hook abstracts the mutation logic and side effects like toast notifications.
 *
 * @param {number | undefined} externalId - The ID of the customer. Optional.
 * @returns A mutation object with methods to trigger the mutation.
 */
export function useAddImREmit(externalId?: string) {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (data: z.infer<typeof AddImREmitFormSchema>) => {
      // Perform the mutation using the addImREmitFn function
      return addImREmitFn(data, externalId);
    },
    onSuccess: (response) => {
      // Handle successful mutation
      console.log("addImREmitResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Payment created successfully",
      });

      void navigate({
        to: "/app/imremit/payment-management",
        replace: true,
      });
    },
    onError: (error: unknown) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error["message"];
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to create payment",
      });
    },
  });
}

/**
 * Component representing the page for adding an 'Im Remit'.
 * This component sets up the necessary context and renders the form.
 *
 * @returns React component for adding Im Remit.
 */
export function AddImREmitPage() {
  const {imREmitStoreCustomer} = useImRemitCustomerStore();
  const externalId = imREmitStoreCustomer?.externalId;

  const memoizedId = useMemo(() => externalId || "", [externalId]);

  // Use custom hook to manage the add Im Remit mutation
  const addImREmitMutation = useAddImREmit(memoizedId);

  // Render the form with the necessary props
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.CREATE_PAYMENT_MANAGEMENT]}
    >
      <AddImREmitForm
        isMutating={addImREmitMutation.isPending}
        onSubmit={addImREmitMutation.mutate}
      />
    </AccessPolicyWrapper>
  );
}
