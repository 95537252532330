import {useMemo} from "react";

import {type ColumnDef} from "@tanstack/react-table";
import {format} from "date-fns";

import {Badge} from "@/components/ui/badge";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {
  ImremitPaymentHistoryDataTable,
  useWebPaymentHistory,
  type imREmitPaymentId,
  type WebPaymentHistoryType,
} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";

// Definition of payment history columns for the datatable
export const webPaymentHistoryColumns: ColumnDef<WebPaymentHistoryType>[] = [
  {
    accessorKey: "transactionCreatedDate",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Date" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("transactionCreatedDate") ? (
          format(
            new Date(row.getValue("transactionCreatedDate")),
            "MMMM dd, yyyy"
          )
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "payByWebTransactionHistoryId",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Transaction ID" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("payByWebTransactionHistoryId") ? (
          row.getValue("payByWebTransactionHistoryId")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "paymentDetailId",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment ID" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("paymentDetailId") ? (
          row.getValue("paymentDetailId")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "confirmationNumber",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Confirmation Number" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("confirmationNumber") ? (
          row.getValue("confirmationNumber")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "amount",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Amount" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("amount") ? (
          row.getValue("amount")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "transactionStatus",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment Status" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("transactionStatus") ? (
          row.getValue("transactionStatus")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "reason",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Reason" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("reason") ? (
          row.getValue("reason")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "transactionUpdatedDate",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Last Update Date" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("transactionUpdatedDate") ? (
          row.getValue("transactionUpdatedDate")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
];

export function ProxyPayWebPaymentHistory({id}: imREmitPaymentId) {
  const paymentHistoryQuery = useWebPaymentHistory(id.toString());

  const paymentHistoryData = useMemo(() => {
    return paymentHistoryQuery.data?.content ?? [];
  }, [paymentHistoryQuery.data?.content]);

  // Rendering the table
  return (
    <ImremitPaymentHistoryDataTable
      columns={webPaymentHistoryColumns}
      data={paymentHistoryData}
      isPending={paymentHistoryQuery.isPending}
      isSuccess={paymentHistoryQuery.isSuccess}
      isError={paymentHistoryQuery.isError}
    />
  );
}
