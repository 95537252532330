import {type ColumnDef} from "@tanstack/react-table";
import {z} from "zod";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {type AlertManagementType} from "@/modules/admin";

import {FallbackMessages} from "@/utils/constants";

export const alertsTableColumnsSchema = z.object({
  alertDetailId: z.boolean(),
  alertLevelId: z.boolean(),
  alertLevel: z.boolean(),
  alertType: z.boolean(),
  alertSeverity: z.boolean(),
  alertSource: z.boolean(),
  alertRecipient: z.boolean(),
  alertContent: z.boolean(),
});

export type alertsTableColumnsType = z.infer<typeof alertsTableColumnsSchema>;

export const alertsTableColumns: ColumnDef<AlertManagementType>[] = [
  {
    accessorKey: "alertDetailId",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Alert Detail ID" />
    ),
    cell: ({row}) => (
      <div className="min-w-36">
        {row.getValue("alertDetailId") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("alertDetailId")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "alertLevelId",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Alert Level ID" />
    ),
    cell: ({row}) => (
      <div className="min-w-36">
        {row.getValue("alertLevelId") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("alertLevelId")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "alertLevel",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Alert Level" />
    ),
    cell: ({row}) => (
      <div className="min-w-36">
        {row.getValue("alertLevel") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("alertLevel")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "alertType",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Alert Type" />
    ),
    cell: ({row}) => (
      <div className="min-w-36">
        {row.getValue("alertType") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("alertType")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "alertSeverity",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Alert Severity" />
    ),
    cell: ({row}) => (
      <div className="min-w-36">
        {row.getValue("alertSeverity") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("alertSeverity")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "alertSource",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Alert Source" />
    ),
    cell: ({row}) => (
      <div className="min-w-36">
        {row.getValue("alertSource") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("alertSource")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "alertRecipient",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Alert Recipient" />
    ),
    cell: ({row}) => (
      <div className="min-w-36">
        {row.getValue("alertRecipient") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("alertRecipient")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "alertContent",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Alert Content" />
    ),
    cell: ({row}) => (
      <div className="min-w-36">
        {row.getValue("alertContent") ? (
          <div className="flex flex-col gap-y-2">
            <Paragraph className="font-semibold">
              {row.getValue("alertContent")}
            </Paragraph>
          </div>
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
];
