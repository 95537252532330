// 🔄 Core imports
import {createRoot} from "react-dom/client";

import {Providers} from "@/lib/config/providers";

import "@/lib/zod";

// 📊 Analytics & monitoring imports
import Clarity from "@microsoft/clarity";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";

// 🔐 Environment variables
const {
  VITE_LOGROCKET_APP_ID: logrocketAppId,
  VITE_KEYCLOAK_REALM: realm,
  VITE_SENTRY_DSN: sentryDsn,
  VITE_CLARITY_PROJECT_ID: clarityProjectId,
} = import.meta.env as Record<string, string>;

// 🔐 Environment variables Logrocket
if (logrocketAppId) {
  LogRocket.init(logrocketAppId);
  console.log("✅ LogRocket initialized successfully.");
} else {
  console.error("❌ LogRocket App ID not provided");
}

// 🛠 Initialize monitoring services based on environment configuration
if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration({}),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: realm === "prod" ? 0.1 : 1.0,
    replaysSessionSampleRate: realm === "prod" ? 0.05 : 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: realm,
  });
  console.log("✅ Sentry initialized successfully.");
} else {
  console.info("ℹ️ Sentry initialization skipped - DSN not provided");
}

if (typeof window !== "undefined" && clarityProjectId) {
  Clarity.init(clarityProjectId);
  console.log("✅ Clarity initialized successfully.");
} else {
  console.info(
    "ℹ️ Clarity initialization skipped - Project ID not provided or not in browser context"
  );
}

// 🚀 Initialize services

// 🎨 Render application
const container = document.getElementById("root");
if (!container) {
  throw new Error("❌ Root container not found. Check your HTML file.");
}

createRoot(container).render(
  <Sentry.ErrorBoundary
    fallback={<div>An error has occurred. Please try again later.</div>}
  >
    <Providers />
  </Sentry.ErrorBoundary>
);
