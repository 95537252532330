import {useEffect} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {Link} from "@tanstack/react-router";
import {CheckIcon, ChevronsUpDownIcon, PlusIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button, buttonVariants} from "@/components/ui/button";
import {Command, CommandGroup, CommandItem} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {Heading1, Paragraph} from "@/components/ui/typography";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {ImREmitRoutesSchema} from "@/lib/routes/types";
import {useImRemitCustomerStore, useRoutesStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {
  AccessPolicyWrapper,
  KeycloakRoleEnum,
  useAuthorization,
} from "@/modules/auth";
import {SelectCustomerSectionImremit} from "@/modules/customers";
import {
  AuditFileList,
  BankFileList,
  documentFormatSchema,
  PaymentFileList,
  ReconFileList,
  ResponseFileList,
  useDocumentTypesDetails,
  useMappingDocumentTypeStore,
} from "@/modules/imremit";

/**
 * ImREmitMapping Component
 *
 * This component provides the report for imREmit Mapping List
 * and rendering the ImREmitMapping.
 */
export function ImREmitMapping() {
  const {setimREmitRoute} = useRoutesStore();

  const {checkPoliciesAccess} = useAuthorization();
  const {imREmitStoreCustomer} = useImRemitCustomerStore();

  // Fetch the documents type data using the custom hook `useDocumentTypesDetails`
  const searchDocumentsTypeResponse = useDocumentTypesDetails();

  // Destructure the `data` property, focusing on its `content` attribute
  const documentTypeData = searchDocumentsTypeResponse.data?.content;

  // Extract the `document type` field from the store
  // and the `updateStoreDocumentType` function to update the store
  const {storeDocumentType, updatestoreDocumentType} =
    useMappingDocumentTypeStore();

  const form = useForm<z.infer<typeof documentFormatSchema>>({
    mode: "onChange", // validate the form on change
    resolver: zodResolver(documentFormatSchema), // use Zod for validation
  });

  // Observe the field from the form
  const formFields = form.watch();

  useEffect(() => {
    setimREmitRoute(ImREmitRoutesSchema.Values["/app/imremit/mapping"]);
  }, [setimREmitRoute]);

  useEffect(() => {
    const docType = formFields.documentTypeId;

    //Find the document type from useDocumentTypeDetails hook list
    const selectedDocumentType = documentTypeData?.find((data) => {
      return data.documentTypeId.toString() === docType;
    });

    //If no docuement type is found ,we return
    if (!selectedDocumentType) return;

    // We call updatestoreDocumentType, passing in documentTypeId.
    // This updates the stored Document Type  to match the current form fields.
    updatestoreDocumentType(selectedDocumentType);
  }, [updatestoreDocumentType, formFields.documentTypeId, documentTypeData]);

  // Handle unauthorized access
  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_IMREMIT_MAPPING])) {
    return <NotAuthorizedPage />;
  }

  // If the customer is not yet selected, render the SelectCustomerSectionImremit
  if (!imREmitStoreCustomer) {
    return <SelectCustomerSectionImremit />;
  }
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.READ_IMREMIT_MAPPING]}
    >
      <section
        className="flex flex-col"
        aria-label="This is the Supplier List level"
      >
        <header className="flex flex-col gap-2 lg:flex-row lg:items-center lg:justify-between">
          <div className="flex flex-col">
            <Heading1 className="self-start">imREmit Master Mapping</Heading1>
            <Paragraph className="self-start">
              Welcome to the imREmit {storeDocumentType?.typeName} Master
              Mapping page.
            </Paragraph>
          </div>

          <div className="mt-4 flex flex-col gap-4 lg:flex-row">
            <Form {...form}>
              <form className="w-50 xl:mb-4">
                <FormField
                  control={form.control}
                  name="documentTypeId"
                  render={({field}) => (
                    <FormItem>
                      <Popover>
                        <PopoverTrigger asChild>
                          <FormControl>
                            <Button
                              variant="outline"
                              role="combobox"
                              className={cn(
                                "justify-between",
                                !field.value && "text-accent-foreground",
                                "w-[260px]"
                              )}
                            >
                              {field.value
                                ? documentTypeData?.find(
                                    (item) =>
                                      item.documentTypeId.toString() ===
                                      field.value
                                  )?.typeName
                                : "Select Configuration Type..."}
                              <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                            </Button>
                          </FormControl>
                        </PopoverTrigger>
                        <PopoverContent className="w-[260px] p-0">
                          <Command>
                            <CommandGroup>
                              {documentTypeData &&
                                documentTypeData.map(
                                  (data, i) =>
                                    data.documentTypeId !== 2 && (
                                      <CommandItem
                                        key={i}
                                        value={data.documentTypeId.toString()}
                                        onSelect={(value) => {
                                          field.onChange(value);
                                        }}
                                      >
                                        <CheckIcon
                                          className={cn(
                                            "mr-2 size-4",
                                            data.documentTypeId.toString() ===
                                              field.value
                                              ? "opacity-100"
                                              : "opacity-0"
                                          )}
                                        />
                                        {data.typeName}
                                      </CommandItem>
                                    )
                                )}
                            </CommandGroup>
                          </Command>
                        </PopoverContent>
                      </Popover>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </form>
            </Form>
            <AccessPolicyWrapper
              policyActions={[KeycloakRoleEnum.CREATE_IMREMIT_MAPPING]}
              unauthorizedFallback={null}
            >
              <Link
                to="/app/imremit/mapping/create"
                className={cn(buttonVariants(), "mb-4 gap-2")}
              >
                <PlusIcon className="size-4" /> New Master Mapping
                <span className="sr-only">New Master Mapping</span>
              </Link>
            </AccessPolicyWrapper>
          </div>
        </header>

        {/* If no document type is selected */}
        {!storeDocumentType && (
          <div className="flex w-full items-center justify-center">
            <Paragraph className="mt-8 font-semibold">
              Please select document type
            </Paragraph>
          </div>
        )}

        {/* Renering the components based on the documentTypeId  */}
        {storeDocumentType?.documentTypeId == 1 && <PaymentFileList />}
        {storeDocumentType?.documentTypeId == 3 && <ReconFileList />}
        {storeDocumentType?.documentTypeId == 4 && <BankFileList />}
        {storeDocumentType?.documentTypeId == 5 && <AuditFileList />}
        {storeDocumentType?.documentTypeId == 6 && <ResponseFileList />}
      </section>
    </AccessPolicyWrapper>
  );
}
