import {type PropsWithChildren, type ReactElement} from "react";

import {FileSearchIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {Heading3} from "@/components/ui/typography";

/**
 * Represents the Advanced Search component.
 *
 * This component creates a hover card that displays additional content when hovered over.
 * It uses a button with an icon to trigger the hover card.
 *
 * @param {PropsWithChildren} props - The props for the component.
 * @return {ReactElement} The AdvancedSearch component.
 */
export function AdvancedSearch({children}: PropsWithChildren): ReactElement {
  return (
    <Popover>
      {/* Trigger for the hover card */}
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          // Applying styles for different states of the button
          className="h-8 min-w-fit gap-2 whitespace-nowrap border-dashed bg-root data-[state=open]:border-secondary data-[state=open]:bg-secondary data-[state=open]:text-secondary-foreground"
        >
          <FileSearchIcon className="size-4" />
          {/* Accessible label for screen readers */}
          <span className="sr-only">Advanced Search</span>
          Advanced Search
        </Button>
      </PopoverTrigger>

      {/* Content of the hover card */}
      <PopoverContent className="w-full" side="bottom">
        <PopoverArrow />
        <Heading3 className="mb-4">
          <span className="sr-only">Advanced Search</span>
          Advanced Search
        </Heading3>
        {children} {/* Child components or elements */}
      </PopoverContent>
    </Popover>
  );
}
