import {zodResolver} from "@hookform/resolvers/zod";
import {useForm} from "react-hook-form";
import {z} from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {Paragraph} from "@/components/ui/typography";

const imREmitCustomerFormSchema = z.object({
  imREmitCustomer: z.string(),
});

const mockImREmitCustomers = [
  {
    name: "test-customer-1",
    label: "Test Customer 1",
  },
  {
    name: "test-customer-2",
    label: "Test Customer 2",
  },
  {
    name: "test-customer-3",
    label: "Test Customer 3",
  },
  {
    name: "test-customer-4",
    label: "Test Customer 4",
  },
  {
    name: "test-customer-5",
    label: "Test Customer 5",
  },
  {
    name: "test-customer-6",
    label: "Test Customer 6",
  },
];

export function ImREmitCustomerForm(): JSX.Element {
  const imREmitCustomerForm = useForm<
    z.infer<typeof imREmitCustomerFormSchema>
  >({
    resolver: zodResolver(imREmitCustomerFormSchema),
  });

  const fieldValue = imREmitCustomerForm.watch("imREmitCustomer");

  return (
    <div className="mb-4 flex flex-col gap-2 lg:flex-row lg:items-center lg:gap-8">
      <Form {...imREmitCustomerForm}>
        <form className="w-72">
          <FormField
            control={imREmitCustomerForm.control}
            name="imREmitCustomer"
            render={({field}) => (
              <FormItem>
                <FormLabel htmlFor="imREmitCustomer">
                  ImREmit Customer:
                </FormLabel>
                <Select {...field} defaultValue={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a Customer..." />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {mockImREmitCustomers.map((customer) => (
                      <SelectItem key={customer.name} value={customer.name}>
                        {customer.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>

      <Paragraph className="w-72 lg:self-end">
        <span className="text-xl font-bold">Bank:</span>
        <span className="ml-2 font-semibold">{!!fieldValue && "TD Bank"}</span>
      </Paragraph>
    </div>
  );
}
