import {useEffect, useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate, useParams} from "@tanstack/react-router";
// import {cva} from "class-variance-authority";
import {format} from "date-fns";
import {decode, encode} from "js-base64";
import {
  // BadgeInfo,
  CalendarIcon,
  CheckCircleIcon,
  CheckIcon,
  ChevronsUpDownIcon,
  InfoIcon,
  PlusIcon,
  SaveAllIcon,
  Trash2Icon,
  XCircleIcon,
} from "lucide-react";
import {useFieldArray, useForm, useFormContext} from "react-hook-form";
import type * as z from "zod";

import {Button, buttonVariants} from "@/components/ui/button";
import {Calendar} from "@/components/ui/calendar";
import {Card, CardContent} from "@/components/ui/card";
import {Checkbox} from "@/components/ui/checkbox";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Label} from "@/components/ui/label";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {RadioGroup, RadioGroupItem} from "@/components/ui/radio-group";
import {Separator} from "@/components/ui/separator";
import {Spinner} from "@/components/ui/spinner";
import {Switch} from "@/components/ui/switch";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableRow,
} from "@/components/ui/table";
import {
  Tooltip,
  // TooltipArrow,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import {Heading3, Heading4} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";
import {PasswordInput} from "@/components/craft/password-input";

import {cn} from "@/lib/utils";
import {
  AccessPolicyWrapper,
  calculateUserRoles,
  KeycloakRoleEnum,
  useKeyCloakInstanceStore,
} from "@/modules/auth";
import {
  CommonForm,
  editSupplierEnrolledFn,
  EditSupplierEnrolledFormSchema,
  flagResetFn,
  useGetSuppliersDetails,
  type SupplierAPIMutatingProps,
} from "@/modules/imremit";

import {
  countrySupplierSelectOptions,
  InternalEmails,
  KeycloakRoles,
  PaymentModes,
  provinceOptionsCanada,
  stateOptionsUSA,
} from "@/utils/constants";

export const remittanceMethods = [
  {label: "Pay By Email", value: "Pay By Email"},
  {label: "Pay By Web", value: "Pay By Web"},
  {label: "Pay By Phone", value: "Pay By Phone"},
] as const;

interface LoginCredentialRequestsProps {
  userId: string;
  password: string;
  scriptOptions: boolean;
  paymentFileNameIdentifier: string;
}

/**
 * Custom hook to fetch Supplier details by Supplier ID.
 *
 * This hook uses `useGetSupplierById` to retrieve supplier details and memoizes the relevant data.
 * It provides an easy-to-use interface for components that need to access supplier data.
 *
 * @param {number} supplierId - The unique identifier for the supplier.
 * @returns {Object} An object containing:
 *   - searchSupplierIDData: The supplier's detailed data, or null if not available.
 *   - supplierDetailsIsPending: Boolean indicating if the supplier details request is pending.
 *   - supplierDetailsAreFetched: Boolean indicating if the supplier details have been fetched.
 *   - isError: Boolean indicating if there was an error in fetching data.
 *   - error: The error object, if an error occurred.
 */
export function useGetSupplierDetails(supplierId: number) {
  // Utilize the useGetSupplierById hook to fetch supplier data.
  const {
    data,
    isPending: supplierDetailsIsPending,
    isFetched: supplierDetailsAreFetched,
    isError,
    error,
  } = useGetSuppliersDetails(supplierId);

  // Memoize the supplier's detailed data for optimization.
  // This ensures that the data is recalculated only when 'data' changes.
  const searchSupplierIDData = useMemo(() => {
    // Extract the 'content' field from the data, default to null if not available.
    return data?.content || null;
  }, [data]);

  // Return the structured object containing the supplier data and status flags.
  return {
    searchSupplierIDData,
    supplierDetailsIsPending,
    supplierDetailsAreFetched,
    isError,
    error,
  };
}

export function EnrolledForm({children}: {children: React.ReactNode}) {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;
  const changedBy = parsedUserInfo?.sub || "";
  const navigate = useNavigate();
  const {supplierId} = useParams({
    from: "/app/imremit/supplier-management/$supplierId/edit",
  });

  const [bufferDisabled, setBufferDisabled] = useState(false);

  const {searchSupplierIDData} = useGetSupplierDetails(Number(supplierId));

  const supplierManagementEnrolledFormMethods = useForm<
    z.infer<typeof EditSupplierEnrolledFormSchema>
  >({
    defaultValues: {
      enrolled: true,
      changedBy: changedBy,
      saveAndContinue: true,
      paymentRestrictions: false,
      supplierSingleUseFlag: true,
      supplierCardOnFileFlag: false,
      supplierPaymentCounterFlag: false,
      scriptFlag: false,
      scriptPaymentLimiter: false,
      remittanceViaEmail: false,
      appvanceProcessingFlag: false,
      supplierBufferLimitAmount: 0,
      enrolledDate: new Date(),
      contactEmail: "",
      country: "US",
      // internalEmailAlertFlag: true,
    },
    mode: "onChange",
    resolver: zodResolver(EditSupplierEnrolledFormSchema),
  });

  const remittanceMethod =
    supplierManagementEnrolledFormMethods.watch("paymentMode");
  const paymentRestrictionsChecked =
    supplierManagementEnrolledFormMethods.watch("paymentRestrictions");
  const supplierCardOnFileFlag = supplierManagementEnrolledFormMethods.watch(
    "supplierCardOnFileFlag"
  ) as boolean;

  const supplierOnboardStatus = searchSupplierIDData?.onboardStatus || "";

  const supplierPaymentCounterFlag =
    supplierManagementEnrolledFormMethods.watch("supplierPaymentCounterFlag");

  useEffect(() => {
    if (supplierCardOnFileFlag && supplierPaymentCounterFlag) {
      setBufferDisabled(true);
    }
    if (supplierOnboardStatus.toUpperCase() === "ACTIVE") {
      setBufferDisabled(true);
      // if (!supplierPaymentCounterFlag) {
      //   setBufferDisabled(false);
      // }
    }
  }, [
    supplierCardOnFileFlag,
    supplierPaymentCounterFlag,
    supplierOnboardStatus,
  ]);

  useEffect(() => {
    if (
      searchSupplierIDData?.onboardStatus &&
      searchSupplierIDData.supplierCardOnFileFlag
    ) {
      if (searchSupplierIDData.onboardStatus.toUpperCase() === "ACTIVE") {
        if (!searchSupplierIDData.supplierPaymentCounterFlag) {
          setBufferDisabled(false);
        } else {
          setBufferDisabled(true);
        }
      }
    }

    if (searchSupplierIDData?.supplierName) {
      supplierManagementEnrolledFormMethods.setValue(
        "supplierName",
        searchSupplierIDData.supplierName
      );
    }

    if (searchSupplierIDData?.supplierNumber) {
      supplierManagementEnrolledFormMethods.setValue(
        "supplierNumber",
        searchSupplierIDData.supplierNumber
      );
    }

    if (searchSupplierIDData?.supplierEmail) {
      supplierManagementEnrolledFormMethods.setValue(
        "supplierEmail",
        searchSupplierIDData.supplierEmail
      );
    }
    if (searchSupplierIDData?.phoneNumber) {
      supplierManagementEnrolledFormMethods.setValue(
        "phoneNumber",
        searchSupplierIDData.phoneNumber
      );
    }
    if (searchSupplierIDData?.enrolled) {
      supplierManagementEnrolledFormMethods.setValue(
        "enrolled",
        searchSupplierIDData.enrolled
      );
    }
    if (searchSupplierIDData?.enrolledDate) {
      supplierManagementEnrolledFormMethods.setValue(
        "enrolledDate",
        new Date(searchSupplierIDData.enrolledDate)
      );
    }
    if (searchSupplierIDData?.supplierSingleUseFlag) {
      const cardFlag = searchSupplierIDData.supplierSingleUseFlag;
      supplierManagementEnrolledFormMethods.setValue(
        "supplierSingleUseFlag",
        cardFlag
      );
      supplierManagementEnrolledFormMethods.setValue(
        "supplierCardOnFileFlag",
        !cardFlag
      );
    }
    if (searchSupplierIDData?.supplierCardOnFileFlag) {
      const cardFlag = searchSupplierIDData.supplierCardOnFileFlag;
      supplierManagementEnrolledFormMethods.setValue(
        "supplierCardOnFileFlag",
        cardFlag
      );
      supplierManagementEnrolledFormMethods.setValue(
        "supplierSingleUseFlag",
        !cardFlag
      );
    }
    if (searchSupplierIDData?.supplierPaymentCounterFlag) {
      supplierManagementEnrolledFormMethods.setValue(
        "supplierPaymentCounterFlag",
        searchSupplierIDData.supplierPaymentCounterFlag
      );
    }
    if (searchSupplierIDData?.supplierBufferLimitAmount) {
      supplierManagementEnrolledFormMethods.setValue(
        "supplierBufferLimitAmount",
        searchSupplierIDData.supplierBufferLimitAmount
      );
    }
    if (searchSupplierIDData?.supplierCardLimitAmount) {
      supplierManagementEnrolledFormMethods.setValue(
        "supplierCardLimitAmount",
        searchSupplierIDData.supplierCardLimitAmount
      );
    }
    if (searchSupplierIDData?.paymentLimitNumber) {
      supplierManagementEnrolledFormMethods.setValue(
        "paymentLimitNumber",
        searchSupplierIDData.paymentLimitNumber
      );
    }
    if (searchSupplierIDData?.scriptPaymentLimiter) {
      supplierManagementEnrolledFormMethods.setValue(
        "scriptPaymentLimiter",
        searchSupplierIDData.scriptPaymentLimiter
      );
    }
    if (searchSupplierIDData?.remittanceEmail) {
      const emailString = searchSupplierIDData.remittanceEmail;
      const emailArray = emailString.split(",");

      // Convert the email to lower case for case-insensitive comparison
      const internalEmail = InternalEmails.internalEmailProxyEpay.toLowerCase();

      // Remove any instance of the internal email from the array
      const filteredEmailArray = emailArray.filter(
        (email) => email.toLowerCase() !== internalEmail
      );

      // Only unshift the internal email if internalEmailAlertFlag is true
      if (searchSupplierIDData.internalEmailAlertFlag !== false) {
        filteredEmailArray.unshift(InternalEmails.internalEmailProxyEpay);
      }

      // Set the value if payment mode is "Pay By Email"
      if (searchSupplierIDData.paymentMode === "Pay By Email") {
        supplierManagementEnrolledFormMethods.setValue(
          "remittanceEmail",
          filteredEmailArray
        );
      }
    }
    if (searchSupplierIDData?.scriptFlag) {
      supplierManagementEnrolledFormMethods.setValue(
        "scriptFlag",
        searchSupplierIDData.scriptFlag
      );
    }
    if (searchSupplierIDData?.proxyPayUrl) {
      supplierManagementEnrolledFormMethods.setValue(
        "proxyPayUrl",
        searchSupplierIDData.proxyPayUrl
      );
    }
    if (searchSupplierIDData?.loginCredentialDTOs) {
      const decodedLoginCredentialDTOs =
        searchSupplierIDData.loginCredentialDTOs.map((data) => {
          return {
            ...data,
            password: decode(data.password),
          };
        });

      supplierManagementEnrolledFormMethods.setValue(
        "loginCredentialRequests",
        decodedLoginCredentialDTOs
      );
    }

    if (searchSupplierIDData?.clientKey) {
      supplierManagementEnrolledFormMethods.setValue(
        "clientKey",
        searchSupplierIDData.clientKey
      );
    }
    if (searchSupplierIDData?.websiteKey) {
      supplierManagementEnrolledFormMethods.setValue(
        "websiteKey",
        searchSupplierIDData.websiteKey
      );
    }
    if (searchSupplierIDData?.contactName) {
      supplierManagementEnrolledFormMethods.setValue(
        "remittanceName",
        searchSupplierIDData.contactName
      );
    }
    if (searchSupplierIDData?.phoneNumber) {
      supplierManagementEnrolledFormMethods.setValue(
        "remittancePhoneNumber",
        searchSupplierIDData.phoneNumber
      );
    }
    if (searchSupplierIDData?.paymentMode) {
      supplierManagementEnrolledFormMethods.setValue(
        "paymentMode",
        searchSupplierIDData.paymentMode
      );
    }
    if (searchSupplierIDData?.paymentRestrictions) {
      supplierManagementEnrolledFormMethods.setValue(
        "paymentRestrictions",
        searchSupplierIDData.paymentRestrictions
      );
    }
    if (searchSupplierIDData?.minAmountPerTransaction) {
      supplierManagementEnrolledFormMethods.setValue(
        "minAmountPerTransaction",
        searchSupplierIDData.minAmountPerTransaction
      );
    }
    if (searchSupplierIDData?.maxAmountPerTransaction) {
      supplierManagementEnrolledFormMethods.setValue(
        "maxAmountPerTransaction",
        searchSupplierIDData.maxAmountPerTransaction
      );
    }
    if (searchSupplierIDData?.amountTransactionLimitPerDay) {
      supplierManagementEnrolledFormMethods.setValue(
        "noOfTransactionPerDay",
        searchSupplierIDData.amountTransactionLimitPerDay
      );
    }
    if (searchSupplierIDData?.noOfTransactionPerDay) {
      supplierManagementEnrolledFormMethods.setValue(
        "amountTransactionLimitPerDay",
        searchSupplierIDData.noOfTransactionPerDay
      );
    }
    if (searchSupplierIDData?.contactName) {
      supplierManagementEnrolledFormMethods.setValue(
        "supplierContactName",
        searchSupplierIDData.contactName
      );
    }
    if (searchSupplierIDData?.contactEmail) {
      supplierManagementEnrolledFormMethods.setValue(
        "contactEmail",
        searchSupplierIDData.contactEmail
      );
    }
    if (searchSupplierIDData?.taxId) {
      supplierManagementEnrolledFormMethods.setValue(
        "taxId",
        searchSupplierIDData.taxId
      );
    }
    if (searchSupplierIDData?.address1) {
      supplierManagementEnrolledFormMethods.setValue(
        "address1",
        searchSupplierIDData.address1
      );
    }
    if (searchSupplierIDData?.address2) {
      supplierManagementEnrolledFormMethods.setValue(
        "address2",
        searchSupplierIDData.address2
      );
    }
    if (searchSupplierIDData?.address3) {
      supplierManagementEnrolledFormMethods.setValue(
        "address3",
        searchSupplierIDData.address3
      );
    }
    if (searchSupplierIDData?.address4) {
      supplierManagementEnrolledFormMethods.setValue(
        "address4",
        searchSupplierIDData.address4
      );
    }
    if (searchSupplierIDData?.country) {
      supplierManagementEnrolledFormMethods.setValue(
        "country",
        searchSupplierIDData.country
      );
    }
    if (searchSupplierIDData?.state) {
      supplierManagementEnrolledFormMethods.setValue(
        "state",
        searchSupplierIDData.state
      );
    }
    if (searchSupplierIDData?.city) {
      supplierManagementEnrolledFormMethods.setValue(
        "city",
        searchSupplierIDData.city
      );
    }
    if (searchSupplierIDData?.zip) {
      supplierManagementEnrolledFormMethods.setValue(
        "zip",
        searchSupplierIDData.zip
      );
    }
    if (searchSupplierIDData?.locationCode) {
      supplierManagementEnrolledFormMethods.setValue(
        "locationCode",
        searchSupplierIDData.locationCode
      );
    }
    if (searchSupplierIDData?.batchSize) {
      supplierManagementEnrolledFormMethods.setValue(
        "batchSize",
        searchSupplierIDData.batchSize
      );
    }
    if (searchSupplierIDData?.cronInterval) {
      supplierManagementEnrolledFormMethods.setValue(
        "cronInterval",
        searchSupplierIDData.cronInterval
      );
    }
    if (searchSupplierIDData?.maintenanceWindowStart) {
      supplierManagementEnrolledFormMethods.setValue(
        "maintenanceWindowStart",
        searchSupplierIDData.maintenanceWindowStart
      );
    }
    if (searchSupplierIDData?.maintenanceWindowEnd) {
      supplierManagementEnrolledFormMethods.setValue(
        "maintenanceWindowEnd",
        searchSupplierIDData.maintenanceWindowEnd
      );
    }
    if (searchSupplierIDData?.appvanceScenarioUrl) {
      supplierManagementEnrolledFormMethods.setValue(
        "appvanceScenarioUrl",
        searchSupplierIDData.appvanceScenarioUrl
      );
    }
    if (searchSupplierIDData?.appvanceProcessingFlag) {
      supplierManagementEnrolledFormMethods.setValue(
        "appvanceProcessingFlag",
        searchSupplierIDData.appvanceProcessingFlag
      );
    }
    if (searchSupplierIDData?.appvanceAuthUrl) {
      supplierManagementEnrolledFormMethods.setValue(
        "appvanceAuthUrl",
        searchSupplierIDData.appvanceAuthUrl
      );
    }
    if (searchSupplierIDData?.mfaEmail) {
      supplierManagementEnrolledFormMethods.setValue(
        "mfaEmail",
        searchSupplierIDData.mfaEmail
      );
    }
    if (searchSupplierIDData?.mfaPassword) {
      supplierManagementEnrolledFormMethods.setValue(
        "mfaPassword",
        decode(searchSupplierIDData.mfaPassword)
      );
    }
    if (searchSupplierIDData?.internalEmailAlertFlag) {
      supplierManagementEnrolledFormMethods.setValue(
        "internalEmailAlertFlag",
        !!searchSupplierIDData.internalEmailAlertFlag
      );
    }
  }, [searchSupplierIDData, supplierManagementEnrolledFormMethods]);

  const paymentMode =
    supplierManagementEnrolledFormMethods.watch("paymentMode");

  //Current User Role
  const userRoleDisplay = useMemo(
    () => calculateUserRoles(parsedUserInfo?.realm_access?.roles),
    [parsedUserInfo]
  );

  useEffect(() => {
    const formatRemittanceEmails = (emails: string): string[] => {
      const emailArray = emails.split(",");
      const internalEmail = InternalEmails.internalEmailProxyEpay.toLowerCase();

      // Filter out any existing instances of the internal email
      const filteredEmailArray = emailArray.filter(
        (email) => email.toLowerCase() !== internalEmail
      );

      // Unshift the internal email to the first position
      filteredEmailArray.unshift(InternalEmails.internalEmailProxyEpay);

      return filteredEmailArray;
    };

    // Determine the default email string or from data
    const emailString =
      searchSupplierIDData?.remittanceEmail ||
      InternalEmails.internalEmailProxyEpay;

    // Set the appropriate value based on the payment mode
    const remittanceEmailValue =
      paymentMode === "Pay By Email"
        ? formatRemittanceEmails(emailString)
        : [InternalEmails.internalEmailProxyEpay];

    supplierManagementEnrolledFormMethods.setValue(
      "remittanceEmail",
      remittanceEmailValue
    );
  }, [
    searchSupplierIDData,
    paymentMode,
    supplierManagementEnrolledFormMethods,
  ]);

  const {
    formState: {errors},
  } = supplierManagementEnrolledFormMethods;
  console.log("errors:", errors);

  const saveSupplierMutation = useMutation({
    mutationFn: (data: z.infer<typeof EditSupplierEnrolledFormSchema>) => {
      console.log("data:", data);
      return editSupplierEnrolledFn(data, Number(supplierId));
    },
    onSuccess: (response) => {
      supplierManagementEnrolledFormMethods.reset();
      toast({
        variant: "success",
        title: "Success!",
        description: response.message,
      });
      void navigate({to: "/app/imremit/supplier-management", replace: true});
    },
    onError: (error) => {
      console.error("Supplier management enrolled form error:", error);
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      toast({
        variant: "destructive",
        title: "Unable to edit Supplier!",
        description: errorMessage,
      });
    },
  });
  const isMutating = saveSupplierMutation.isPending;

  function onSubmit(data: z.infer<typeof EditSupplierEnrolledFormSchema>) {
    saveSupplierMutation.mutate(data);
  }

  return (
    <>
      <Form {...supplierManagementEnrolledFormMethods}>
        <form
          onSubmit={supplierManagementEnrolledFormMethods.handleSubmit(
            onSubmit
          )}
        >
          <Heading3 className="mb-4">Supplier Details</Heading3>
          <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
            <CommonForm isMutating={isMutating} />
            {children}
          </div>
          <Separator className="mb-5" />
          <Heading4 className="mb-4">Supplier Details</Heading4>
          <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
            <EnrolledDateField isMutating={isMutating} />
            <AccessPolicyWrapper
              unauthorizedFallback={null}
              policyActions={[KeycloakRoleEnum.READ_CARD_TYPE]}
            >
              <CardTypeField isMutating={isMutating} />
            </AccessPolicyWrapper>
            {supplierCardOnFileFlag && (
              <>
                <BufferLimitAmountField
                  isMutating={isMutating}
                  bufferDisabled={
                    bufferDisabled ||
                    userRoleDisplay === KeycloakRoles.EPayablesAdmin ||
                    userRoleDisplay === KeycloakRoles.EPayablesUser
                  }
                />
                <SupplierCardLimitAmountField
                  isMutating={isMutating}
                  bufferDisabled={
                    bufferDisabled ||
                    userRoleDisplay === KeycloakRoles.EPayablesAdmin ||
                    userRoleDisplay === KeycloakRoles.EPayablesUser
                  }
                />
              </>
            )}
            <PaymentModeField isMutating={isMutating} />
          </div>
          <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
            <RemittanceEmailField isMutating={isMutating} />
            {remittanceMethod === "Pay By Web" && (
              <LoginCredentialRequestsField isMutating={isMutating} />
            )}
          </div>
          <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
            {remittanceMethod === "Pay By Web" && (
              <>
                <ProxyPayUrlField isMutating={isMutating} />
                <ClientKeyField isMutating={isMutating} />
                <WebsiteKeyField isMutating={isMutating} />

                {/* If the `enableScriptFlagField` property is not exist,then the deafult value will be as TRUE. */}
                <ScriptFlagField
                  isMutating={isMutating}
                  // enableScriptFlagField={
                  //   searchSupplierIDData?.enableScriptFlagField
                  //     ? searchSupplierIDData.enableScriptFlagField
                  //     : true
                  // }
                />
              </>
            )}

            {remittanceMethod === "Pay By Phone" && (
              <>
                <RemittanceNameField isMutating={isMutating} />
                <RemittancePhoneNumberField isMutating={isMutating} />
              </>
            )}
            <PaymentRestrictionsField isMutating={isMutating} />

            {(remittanceMethod === "Pay By Web" ||
              remittanceMethod === "Pay By Phone") &&
              paymentRestrictionsChecked && (
                <>
                  <MinAmountPerTransactionField isMutating={isMutating} />
                  <MaxAmountPerTransactionField isMutating={isMutating} />
                  <NoOfTransactionPerDayField isMutating={isMutating} />
                  <AmountTransactionLimitPerDayField isMutating={isMutating} />
                </>
              )}
          </div>

          <>
            <Separator className="mb-5" />
            <Heading4 className="mb-4">Supplier Profile</Heading4>

            <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-2">
              <SupplierContactNameField isMutating={isMutating} />
              <ContactEmailField isMutating={isMutating} />
              <TaxIdField isMutating={isMutating} />
              <Address1Field isMutating={isMutating} />
              <Address2Field isMutating={isMutating} />
              <Address3Field isMutating={isMutating} />
              <Address4Field isMutating={isMutating} />
              <CountryField isMutating={isMutating} />
              <StateField isMutating={isMutating} />
              <CityField isMutating={isMutating} />
              <ZipField isMutating={isMutating} />
              <LocationCodeField isMutating={isMutating} />
              <BatchSizeField isMutating={isMutating} />
              <CronIntervalField isMutating={isMutating} />
              <WindowStartField isMutating={isMutating} />
              <WindowEndField isMutating={isMutating} />
            </div>
            <div className="flex w-full flex-row justify-end gap-2">
              <Link
                to="/app/imremit/supplier-management"
                className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
              >
                <span className="sr-only">Cancel</span>
                <XCircleIcon className="size-4" />
                Cancel
              </Link>

              <Button
                className="gap-2 bg-success-foreground text-white hover:bg-success-foreground/80"
                type="submit"
                aria-disabled={isMutating}
                disabled={isMutating}
                onClick={() => {
                  supplierManagementEnrolledFormMethods.setValue(
                    "saveAndContinue",
                    true
                  );
                  supplierManagementEnrolledFormMethods.handleSubmit(onSubmit);
                }}
              >
                <span className="sr-only">Submit form</span>
                Save and continue{" "}
                {isMutating ? (
                  <Spinner className="size-4" />
                ) : (
                  <SaveAllIcon className="size-4" />
                )}
              </Button>
              <Button
                className="gap-2 bg-success-foreground text-white hover:bg-success-foreground/80"
                type="submit"
                aria-disabled={isMutating}
                disabled={isMutating}
                onClick={() => {
                  supplierManagementEnrolledFormMethods.setValue(
                    "saveAndContinue",
                    false
                  );
                  supplierManagementEnrolledFormMethods.handleSubmit(onSubmit);
                }}
              >
                <span className="sr-only">Submit form</span>
                Save and submit
                {isMutating ? (
                  <Spinner className="size-4" />
                ) : (
                  <CheckCircleIcon className="size-4" />
                )}
              </Button>
            </div>
          </>
        </form>
      </Form>
    </>
  );
}

function EnrolledDateField({isMutating}: SupplierAPIMutatingProps) {
  const {control} = useFormContext();

  return (
    <FormField
      control={control}
      name="enrolledDate"
      render={({field}) => (
        <FormItem className="mt-3 flex flex-col">
          <FormLabel showMandatoryAsterisk>Enrolled Date:</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="input"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  className={cn(
                    "w-full pl-3 text-left font-normal",
                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value ? (
                    format(field.value as Date, "PPP")
                  ) : (
                    <span>Pick a date</span>
                  )}
                  <CalendarIcon className="ml-auto size-4 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent
              className="max-h-[var(--radix-popover-content-available-height)] w-[var(--radix-popover-trigger-width)] p-0"
              align="start"
            >
              <Calendar
                mode="single"
                selected={field.value as Date}
                disabled={(date) =>
                  date > new Date() || date < new Date("1900-01-01")
                }
                initialFocus
                onSelect={field.onChange}
              />
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CardTypeField({isMutating}: {isMutating: boolean}) {
  const {watch, setValue} = useFormContext();

  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;

  //Current User Role
  const userRoleDisplay = useMemo(
    () => calculateUserRoles(parsedUserInfo?.realm_access?.roles),
    [parsedUserInfo]
  );

  const navigate = useNavigate();

  const {supplierId} = useParams({
    from: "/app/imremit/supplier-management/$supplierId/edit",
  });

  const supplierCardOnFileFlag = watch("supplierCardOnFileFlag") as boolean;
  const supplierSingleUseFlag = watch("supplierSingleUseFlag") as boolean;

  const supplierSingleUseFlagDisabled =
    supplierCardOnFileFlag &&
    (userRoleDisplay === KeycloakRoles.EPayablesAdmin ||
      userRoleDisplay === KeycloakRoles.EPayablesUser);

  const supplierPaymentCounterFlag = watch(
    "supplierPaymentCounterFlag"
  ) as boolean;
  const counterFlagValue: string = "false";

  const flagResetMutation = useMutation({
    mutationFn: () => {
      return flagResetFn(supplierId, counterFlagValue);
    },
    onSuccess: (response) => {
      console.log("flagResetResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Flag reset successfully",
      });

      void navigate({
        to: "/app/imremit/supplier-management",
        replace: true,
      });
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to reset flag",
      });
    },
  });
  const flagResetIsMutation = flagResetMutation.isPending;

  return (
    <>
      <div className="flex flex-row space-x-3 space-y-6">
        <RadioGroup>
          <Label showMandatoryAsterisk>Card Type:</Label>

          <div className="flex items-center space-x-2">
            <RadioGroupItem
              value="single-use-card"
              disabled={isMutating || supplierSingleUseFlagDisabled}
              aria-disabled={isMutating || supplierSingleUseFlagDisabled}
              id="single-use-card"
              checked={supplierSingleUseFlag}
              onClick={() => {
                setValue("supplierSingleUseFlag", true);
                setValue("supplierCardOnFileFlag", false);
              }}
            />
            <Label htmlFor="single-use-card"> Single Use Card </Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem
              value="card-on-file"
              id="card-on-file"
              checked={supplierCardOnFileFlag}
              disabled={
                (userRoleDisplay != KeycloakRoles.ProgramManager &&
                  userRoleDisplay != KeycloakRoles.Management) ||
                isMutating
              }
              aria-disabled={
                (userRoleDisplay != KeycloakRoles.ProgramManager &&
                  userRoleDisplay != KeycloakRoles.Management) ||
                isMutating
              }
              onClick={() => {
                setValue("supplierSingleUseFlag", false);
                setValue("supplierCardOnFileFlag", true);
              }}
            />
            <Label htmlFor="card-on-file"> Card On File </Label>
          </div>
        </RadioGroup>
        {supplierCardOnFileFlag && (
          <AccessPolicyWrapper
            unauthorizedFallback={null}
            policyActions={[KeycloakRoleEnum.UPDATE_FLAG_RESET]}
          >
            <Button
              variant="secondary"
              size="xs"
              type="button"
              className="!mt-[48px] gap-2"
              aria-disabled={isMutating}
              disabled={
                isMutating || flagResetIsMutation || !supplierPaymentCounterFlag
              }
              onClick={() => {
                flagResetMutation.mutate();
              }}
            >
              Flag Reset
              {flagResetIsMutation ? <Spinner className="size-2" /> : ""}
            </Button>
          </AccessPolicyWrapper>
        )}
      </div>
    </>
  );
}

function BufferLimitAmountField({
  isMutating,
  bufferDisabled,
}: {
  isMutating: boolean;
  bufferDisabled: boolean;
}) {
  const {register} = useFormContext();

  return (
    <FormField
      name="supplierBufferLimitAmount"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="supplierBufferLimitAmount" showMandatoryAsterisk>
            Card Limit Buffer:
          </FormLabel>
          <FormControl>
            <Input
              type="number"
              disabled={isMutating || bufferDisabled}
              aria-disabled={isMutating || bufferDisabled}
              placeholder="Enter the card limit buffer..."
              {...register("supplierBufferLimitAmount", {
                setValueAs: (v) =>
                  v === "" ? undefined : parseInt(v as string, 10),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function SupplierCardLimitAmountField({
  isMutating,
  bufferDisabled,
}: {
  isMutating: boolean;
  bufferDisabled: boolean;
}) {
  const {register} = useFormContext();

  return (
    <FormField
      name="supplierCardLimitAmount"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="supplierCardLimitAmount" showMandatoryAsterisk>
            Card Limit Amount:
          </FormLabel>
          <FormControl>
            <Input
              type="number"
              disabled={isMutating || bufferDisabled}
              aria-disabled={isMutating || bufferDisabled}
              placeholder="Enter the card limit amount..."
              {...register("supplierCardLimitAmount", {
                setValueAs: (v) =>
                  v === "" ? undefined : parseInt(v as string, 10),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function PaymentModeField({isMutating}: SupplierAPIMutatingProps) {
  const {control} = useFormContext();

  return (
    <FormField
      control={control}
      name="paymentMode"
      render={({field}) => (
        <div className="space-y-2">
          <FormItem>
            <FormLabel htmlFor="paymentMode" showMandatoryAsterisk>
              Remittance Method:
            </FormLabel>
            <Popover>
              <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
                <Command>
                  <CommandGroup>
                    {remittanceMethods.map((remittanceMethods, i) => (
                      <CommandItem
                        key={i}
                        value={remittanceMethods.value}
                        onSelect={(value) => {
                          field.onChange(
                            value
                              .toLowerCase()
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")
                          );
                        }}
                      >
                        <CheckIcon
                          className={cn(
                            "mr-2 size-4",
                            typeof field.value === "string" &&
                              typeof remittanceMethods.value === "string" &&
                              field.value.toUpperCase() ===
                                remittanceMethods.value.toUpperCase()
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                        {remittanceMethods.label}
                      </CommandItem>
                    ))}
                  </CommandGroup>
                </Command>
              </PopoverContent>
              <PopoverTrigger asChild>
                <FormControl>
                  <Button
                    variant="outline"
                    disabled={isMutating}
                    aria-disabled={isMutating}
                    role="combobox"
                    className={cn(
                      "justify-between",
                      !field.value && "text-accent-foreground",
                      "w-full"
                    )}
                  >
                    {field.value
                      ? remittanceMethods.find(
                          (item) =>
                            (item.value as string).toUpperCase() ===
                            (field.value as string).toUpperCase()
                        )?.label
                      : "Select remittance method"}
                    <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                  </Button>
                </FormControl>
              </PopoverTrigger>
            </Popover>
            <FormMessage />
          </FormItem>
        </div>
      )}
    />
  );
}

function ProxyPayUrlField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="proxyPayUrl"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="proxyPayUrl" showMandatoryAsterisk>
            Web URL:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the web url..."
              {...register("proxyPayUrl")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ClientKeyField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="clientKey"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="clientKey">Client Key:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the client key..."
              {...register("clientKey", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function WebsiteKeyField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="websiteKey"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="websiteKey">Website Key:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the website key..."
              {...register("websiteKey", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

interface ScriptFlagFieldProps {
  isMutating?: boolean;
  // enableScriptFlagField?: boolean;
}

function ScriptFlagField({isMutating}: ScriptFlagFieldProps) {
  const {watch, setValue, register} = useFormContext();
  const scriptFlag = watch("scriptFlag") as boolean;
  const appvanceProcessingFlag = watch("appvanceProcessingFlag") as boolean;
  const scriptPaymentLimiter = watch("scriptPaymentLimiter") as boolean;

  return (
    <div className="grid grid-cols-3 gap-4">
      <RadioGroup>
        <div className="flex">
          <Label showMandatoryAsterisk>Supplier Script:</Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value="script-no"
            disabled={isMutating} //|| enableScriptFlagField
            aria-disabled={isMutating}
            id="script-no"
            checked={!scriptFlag}
            onClick={() => {
              setValue("scriptFlag", false);
            }}
          />
          <Label htmlFor="script-no"> No </Label>
        </div>
        <div className="flex items-center space-x-2">
          <RadioGroupItem
            value="script-yes"
            disabled={isMutating} //|| enableScriptFlagField
            aria-disabled={isMutating}
            id="script-yes"
            checked={scriptFlag}
            onClick={() => {
              setValue("scriptFlag", true);
            }}
          />
          <Label htmlFor="script-yes"> Yes</Label>
        </div>
      </RadioGroup>
      {scriptFlag && (
        <>
          <RadioGroup>
            <Label> Script Payment Limiter:</Label>
            <div className="flex items-center space-x-2">
              <RadioGroupItem
                value="payment-limiter-no"
                disabled={isMutating}
                aria-disabled={isMutating}
                id="payment-limiter-no"
                checked={!scriptPaymentLimiter}
                onClick={() => {
                  setValue("scriptPaymentLimiter", false);
                }}
              />
              <Label htmlFor="payment-limiter-no"> No </Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem
                value="payment-limiter-yes"
                disabled={isMutating}
                aria-disabled={isMutating}
                id="payment-limiter-yes"
                checked={scriptPaymentLimiter}
                onClick={() => {
                  setValue("scriptPaymentLimiter", true);
                }}
              />
              <Label htmlFor="payment-limiter-yes"> Yes</Label>
            </div>
          </RadioGroup>

          {scriptPaymentLimiter ? (
            <FormField
              name="paymentLimitNumber"
              render={() => (
                <FormItem>
                  <FormLabel htmlFor="paymentLimitNumber" showMandatoryAsterisk>
                    Payment Limit Number:
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="number"
                      disabled={isMutating}
                      aria-disabled={isMutating}
                      placeholder="Enter the payment limit number..."
                      {...register("paymentLimitNumber", {
                        setValueAs: (v) =>
                          v === "" ? undefined : parseInt(v as string, 10),
                      })}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          ) : (
            <div />
          )}
          <Separator className="col-span-3" />
          <Heading4 className="col-span-3">Appvance</Heading4>
          <FormField
            name="appvanceProcessingFlag"
            render={({field}) => (
              <FormItem className="flex flex-col space-y-2 pt-1">
                <FormLabel showMandatoryAsterisk>
                  Appvance Processing Flag:
                </FormLabel>
                <FormControl className="flex flex-row items-center">
                  <Switch
                    checked={field.value as boolean}
                    onCheckedChange={field.onChange}
                    {...register("appvanceProcessingFlag")}
                    aria-readonly
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <FormField
            name="appvanceAuthUrl"
            render={() => (
              <FormItem className="col-span-2">
                <FormLabel
                  htmlFor="appvanceAuthUrl"
                  showMandatoryAsterisk={appvanceProcessingFlag}
                >
                  Appvance Authorization URL:
                </FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    disabled={isMutating}
                    aria-disabled={isMutating}
                    placeholder="Enter the appvance authorization URL..."
                    {...register("appvanceAuthUrl", {
                      setValueAs: (value): string | null =>
                        typeof value === "string" && value.trim() === ""
                          ? null
                          : (value as string),
                    })}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {appvanceProcessingFlag && (
            <FormField
              name="appvanceScenarioUrl"
              render={() => (
                <FormItem className="col-span-2">
                  <FormLabel
                    htmlFor="appvanceScenarioUrl"
                    showMandatoryAsterisk={appvanceProcessingFlag}
                  >
                    Appvance Scenario URL:
                  </FormLabel>
                  <FormControl>
                    <Input
                      type="text"
                      disabled={isMutating}
                      aria-disabled={isMutating}
                      placeholder="Enter the appvance scenario URL..."
                      {...register("appvanceScenarioUrl", {
                        setValueAs: (value): string | null =>
                          typeof value === "string" && value.trim() === ""
                            ? null
                            : (value as string),
                      })}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          <FormField
            name="mfaEmail"
            render={() => (
              <FormItem>
                <FormLabel htmlFor="mfaEmail">MFA Email:</FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    disabled={isMutating}
                    aria-disabled={isMutating}
                    placeholder="Enter the contact email..."
                    {...register("mfaEmail")}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            name="mfaPassword"
            render={() => (
              <FormItem>
                <FormLabel className="mr-2" htmlFor="mfaPassword">
                  MFA Password:
                </FormLabel>
                <FormControl>
                  <PasswordInput
                    disabled={isMutating}
                    aria-disabled={isMutating}
                    placeholder="Enter the password..."
                    {...register(`mfaPassword`, {
                      setValueAs: (value): string | null =>
                        typeof value === "string" && value.trim() === ""
                          ? null
                          : encode(value as string),
                    })}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </>
      )}
    </div>
  );
}

function RemittanceNameField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="remittanceName"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="remittanceName" showMandatoryAsterisk>
            Contact Name:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the contact name..."
              {...register("remittanceName")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function RemittancePhoneNumberField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="remittancePhoneNumber"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="remittancePhoneNumber" showMandatoryAsterisk>
            Contact Phone:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the contact phone..."
              {...register("remittancePhoneNumber")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function PaymentRestrictionsField({isMutating}: SupplierAPIMutatingProps) {
  const {watch, setValue} = useFormContext();
  const paymentRestrictions = watch("paymentRestrictions") as boolean;

  return (
    <>
      <div className="grid grid-cols-1 gap-2">
        <RadioGroup>
          <FormLabel showMandatoryAsterisk>
            Are there any Payment Restrictions:
          </FormLabel>
          <div className="flex items-center space-x-2">
            <RadioGroupItem
              value="restrictions-no"
              disabled={isMutating}
              aria-disabled={isMutating}
              id="restrictions-no"
              checked={!paymentRestrictions}
              onClick={() => {
                setValue("paymentRestrictions", false);
              }}
            />
            <Label htmlFor="restrictions-no"> No </Label>
          </div>
          <div className="flex items-center space-x-2">
            <RadioGroupItem
              value="restrictions-yes"
              disabled={isMutating}
              aria-disabled={isMutating}
              id="restrictions-yes"
              checked={paymentRestrictions}
              onClick={() => {
                setValue("paymentRestrictions", true);
              }}
            />
            <Label htmlFor="restrictions-yes"> Yes</Label>
          </div>
        </RadioGroup>
      </div>
    </>
  );
}
function MinAmountPerTransactionField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="minAmountPerTransaction"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="minAmountPerTransaction">
            Minimum Amount Per Transaction:
          </FormLabel>
          <FormControl>
            <Input
              type="number"
              step=".01"
              pattern="^\d*(\.\d{0,2})?$"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter minimum amount per transaction..."
              {...register("minAmountPerTransaction", {
                setValueAs: (v) =>
                  v === "" ? undefined : parseFloat(v as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function MaxAmountPerTransactionField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="maxAmountPerTransaction"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="maxAmountPerTransaction">
            Maximum Amount Per Transaction:
          </FormLabel>
          <FormControl>
            <Input
              type="number"
              step=".01"
              pattern="^\d*(\.\d{0,2})?$"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter maximum amount per transaction..."
              {...register("maxAmountPerTransaction", {
                setValueAs: (v) =>
                  v === "" ? undefined : parseFloat(v as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function NoOfTransactionPerDayField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="noOfTransactionPerDay"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="noOfTransactionPerDay">
            Maximum Number of Transactions Per Day:
          </FormLabel>
          <FormControl>
            <Input
              type="number"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter maximum transactions per day..."
              {...register("noOfTransactionPerDay", {
                setValueAs: (v) =>
                  v === "" ? undefined : parseInt(v as string, 10),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function AmountTransactionLimitPerDayField({
  isMutating,
}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="amountTransactionLimitPerDay"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="amountTransactionLimitPerDay">
            Amount Transactions Limit Per Day:
          </FormLabel>
          <FormControl>
            <Input
              type="number"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter total transactions per day..."
              {...register("amountTransactionLimitPerDay", {
                setValueAs: (v) =>
                  v === "" ? undefined : parseInt(v as string, 10),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function RemittanceEmailField({isMutating}: SupplierAPIMutatingProps) {
  const {control, register, setValue, getValues, watch} = useFormContext();

  // Watch both values to ensure reactivity
  const remittanceEmailValue = watch("remittanceEmail") as string[] | undefined;
  const internalEmailAlertFlag = watch("internalEmailAlertFlag") as boolean;

  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const parsedUserInfoRole = storeKeyCloakInstance?.tokenParsed;
  const isManagementUser =
    parsedUserInfoRole?.realm_access?.roles.includes("name:management");

  // Initialize state with the internalEmailAlertFlag value
  const [includeInternalEmail, setIncludeInternalEmail] = useState(
    internalEmailAlertFlag
  );

  // Sync checkbox state with form value when internalEmailAlertFlag changes
  useEffect(() => {
    setIncludeInternalEmail(internalEmailAlertFlag);
  }, [internalEmailAlertFlag]);

  // Get initial values from the form with a fallback to an empty array
  const preloadedEmails =
    (getValues("remittanceEmail") as string[] | undefined) || [];

  // Initialize state for email inputs with preloaded values
  const [emailList, setEmailList] = useState<string[]>(preloadedEmails);

  const remittanceEmail = useMemo(() => {
    return remittanceEmailValue || [];
  }, [remittanceEmailValue]);

  const paymentMode = watch("paymentMode") as string;

  // Sync state with form values
  useEffect(() => {
    if (remittanceEmail.length) {
      const emails = includeInternalEmail
        ? remittanceEmail
        : remittanceEmail.filter(
            (email) => email !== InternalEmails.internalEmailProxyEpay
          );
      setEmailList(emails);
    }
  }, [remittanceEmail, includeInternalEmail]);

  // Update internalEmailAlertFlag when includeInternalEmail changes
  useEffect(() => {
    setValue("internalEmailAlertFlag", includeInternalEmail);
  }, [includeInternalEmail, setValue]);

  // Function to add a new email input field
  const addEmailField = () => {
    if (emailList.length < 5) {
      const updatedEmails = [...emailList, ""]; // Add an empty string for new email input
      setEmailList(updatedEmails);
      setValue("remittanceEmail", updatedEmails); // Update the form field with the new array
    }
  };

  // Function to handle email input changes
  const handleEmailChange = (index: number, newEmail: string) => {
    const updatedEmails = [...emailList];
    updatedEmails[index] = newEmail;
    setEmailList(updatedEmails);

    // Only include internal email if the checkbox is checked
    const emailsToSave = includeInternalEmail
      ? [
          InternalEmails.internalEmailProxyEpay,
          ...updatedEmails.filter((_, i) => i !== 0),
        ]
      : updatedEmails.filter((_, i) => i !== 0);

    setValue("remittanceEmail", emailsToSave);
  };

  // Function to remove an email input field
  const removeEmailField = (index: number) => {
    const updatedEmails = emailList.filter((_, i) => i !== index);
    setEmailList(updatedEmails);
    setValue("remittanceEmail", updatedEmails); // Update the form field
  };

  // Update internalEmailAlertFlag when includeInternalEmail changes
  useEffect(() => {
    // 🔒 Ensure boolean value is set
    setValue("internalEmailAlertFlag", !!includeInternalEmail);
  }, [includeInternalEmail, setValue]);

  // When handling checkbox changes
  const handleInternalEmailChange = (checked: boolean) => {
    const isChecked = !!checked; // 🔐 Ensure boolean
    setIncludeInternalEmail(isChecked);
    setValue("internalEmailAlertFlag", isChecked, {shouldDirty: true});

    // Update email list when checkbox changes
    const currentEmails = getValues("remittanceEmail") as string[];
    const updatedEmails = isChecked
      ? [
          InternalEmails.internalEmailProxyEpay,
          ...currentEmails.filter(
            (email) => email !== InternalEmails.internalEmailProxyEpay
          ),
        ]
      : currentEmails.filter(
          (email) => email !== InternalEmails.internalEmailProxyEpay
        );

    setValue("remittanceEmail", updatedEmails, {shouldDirty: true});
    setEmailList(updatedEmails);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Heading3 className="mb-4 mt-4 flex items-center">
            Remittance Email
          </Heading3>

          {/* Update checkbox for management users */}
          {isManagementUser && (
            <div className="mb-4 flex items-center space-x-2">
              <Checkbox
                id="includeInternalEmail"
                checked={includeInternalEmail}
                disabled={isMutating}
                onCheckedChange={handleInternalEmailChange}
              />
              <Label htmlFor="includeInternalEmail">
                Include Internal Email ({InternalEmails.internalEmailProxyEpay})
              </Label>
            </div>
          )}

          <div className="mb-5">
            <Button
              className="col-span-1 col-end-8"
              disabled={
                isMutating ||
                emailList.length >= 5 ||
                paymentMode !== PaymentModes.PayByEmail
              } // Maximum of 5 emails
              onClick={addEmailField}
            >
              <PlusIcon className="mr-2 size-4" /> Add
            </Button>
          </div>
          <FormField
            control={control}
            name="remittanceViaEmail"
            render={({field}) => (
              <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
                <div className="space-y-0.5">
                  <FormLabel className="flex flex-row items-center text-base">
                    Remittance Via Email
                    <TooltipProvider delayDuration={200}>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <div className="cursor-pointer">
                            <InfoIcon className="ml-2 size-4 text-neutral-foreground" />
                          </div>
                        </TooltipTrigger>
                        <TooltipContent>
                          <div className="flex justify-between space-x-4">
                            If an Email Address is provided and "Remittance Via
                            Email" Option is selected, then you are choosing to
                            receive the "Remittance" via Email.
                          </div>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </FormLabel>
                </div>
                <FormControl>
                  <Switch
                    checked={field.value as boolean}
                    onCheckedChange={field.onChange}
                    {...register("remittanceViaEmail")}
                    aria-readonly
                  />
                </FormControl>
              </FormItem>
            )}
          />
          <Table>
            <TableCaption>
              {emailList.length === 0 && <p>No Data.</p>}
            </TableCaption>
            <TableBody>
              {emailList.map((email, index) => (
                <TableRow key={index}>
                  <TableCell className="w-fit font-medium">
                    <FormItem className="flex flex-col">
                      <FormControl>
                        <Input
                          placeholder="Enter the remittance email..."
                          value={email}
                          disabled={isMutating || index === 0} // First email is disabled
                          aria-disabled={isMutating || index === 0}
                          onChange={(e) => {
                            handleEmailChange(index, e.target.value);
                          }} // Update email value
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  </TableCell>
                  <TableCell className="text-right">
                    {index !== 0 && (
                      <Button
                        className="flex items-center gap-2 px-3"
                        variant="outline"
                        size="xs"
                        onClick={(e) => {
                          e.preventDefault();
                          removeEmailField(index);
                        }} // Remove the selected email input
                      >
                        <Trash2Icon className="size-4" />
                        <span className="sr-only">Delete</span>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
}

function LoginCredentialRequestsField({isMutating}: SupplierAPIMutatingProps) {
  const {watch, register, control} = useFormContext();
  const {fields, append, remove} = useFieldArray({
    control,
    name: "loginCredentialRequests",
  });

  const supplierScriptFlag = watch("scriptFlag") as boolean;
  const loginCredentialRequestsField = watch(
    "loginCredentialRequests"
  ) as LoginCredentialRequestsProps[];
  return (
    <>
      <Card>
        <CardContent>
          <Heading3 className="mb-4 mt-4">User Credentials</Heading3>
          <div className="mb-5 grid grid-cols-1 gap-6 md:grid-cols-1 xl:grid-cols-1">
            <div className="mb-5  ">
              <Button
                type="button"
                className="col-span-1 col-end-8"
                disabled={isMutating || fields.length >= 3}
                onClick={() => {
                  append({
                    credentialId: "",
                    userId: "",
                    password: "",
                    scriptOptions: false,
                    paymentFileNameIdentifier: "",
                  });
                }}
              >
                <PlusIcon className="mr-2 size-4" /> Add
              </Button>
            </div>
            <Table>
              <TableCaption>
                {fields.length == 0 && <p> No Data.</p>}
              </TableCaption>

              <TableBody>
                {fields.map((field, index) => (
                  <TableRow key={field.id}>
                    <TableCell className="hidden w-fit font-medium">
                      <FormField
                        control={control}
                        name={`loginCredentialRequests.${index.toString()}.credentialId`}
                        render={() => (
                          <FormItem>
                            <FormLabel
                              className="mr-2"
                              htmlFor={`loginCredentialRequests.${index.toString()}.credentialId`}
                            >
                              Credential ID:
                            </FormLabel>
                            <FormControl>
                              <Input
                                type="text"
                                disabled={isMutating}
                                aria-disabled={isMutating}
                                placeholder="Enter the credential id..."
                                {...register(
                                  `loginCredentialRequests.${index.toString()}.credentialId`
                                )}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </TableCell>
                    <TableCell className="w-fit font-medium">
                      <FormField
                        control={control}
                        name={`loginCredentialRequests.${index.toString()}.userId`}
                        render={() => (
                          <FormItem>
                            <FormLabel
                              className="mr-2"
                              htmlFor={`loginCredentialRequests.${index.toString()}.userId`}
                            >
                              User ID:
                            </FormLabel>
                            <FormControl>
                              <Input
                                type="text"
                                disabled={isMutating}
                                aria-disabled={isMutating}
                                placeholder="Enter the user id..."
                                {...register(
                                  `loginCredentialRequests.${index.toString()}.userId`
                                )}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </TableCell>
                    <TableCell className="w-fit font-medium">
                      <FormField
                        control={control}
                        name={`loginCredentialRequests.${index.toString()}.password`}
                        render={() => (
                          <FormItem>
                            <FormLabel
                              className="mr-2"
                              htmlFor={`loginCredentialRequests.${index.toString()}.password`}
                            >
                              Password:
                            </FormLabel>
                            <FormControl>
                              <PasswordInput
                                disabled={isMutating}
                                aria-disabled={isMutating}
                                placeholder="Enter the password..."
                                {...register(
                                  `loginCredentialRequests.${index.toString()}.password`,
                                  {
                                    setValueAs: (value): string | null =>
                                      typeof value === "string" &&
                                      value.trim() === ""
                                        ? null
                                        : encode(value as string),
                                  }
                                )}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </TableCell>
                    <TableCell
                      className={`w-40 font-medium ${
                        supplierScriptFlag ? "" : "hidden"
                      }`}
                    >
                      <FormField
                        control={control}
                        name={`loginCredentialRequests.${index.toString()}.scriptOptions`}
                        render={({field}) => (
                          <FormItem className="flex flex-col">
                            <FormLabel
                              className="mb-4 mr-2"
                              htmlFor={`loginCredentialRequests.${index.toString()}.scriptOptions`}
                            >
                              Script Options:
                            </FormLabel>
                            <FormControl>
                              <Switch
                                checked={field.value as boolean}
                                disabled={isMutating}
                                aria-disabled={isMutating}
                                onCheckedChange={field.onChange}
                                {...register(
                                  `loginCredentialRequests.${index.toString()}.scriptOptions`
                                )}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </TableCell>
                    <TableCell
                      className={`w-fit font-medium ${
                        supplierScriptFlag &&
                        loginCredentialRequestsField[index].scriptOptions
                          ? ""
                          : "hidden"
                      }`}
                    >
                      <FormField
                        control={control}
                        name={`loginCredentialRequests.${index.toString()}.paymentFileNameIdentifier`}
                        render={() => (
                          <FormItem>
                            <FormLabel
                              className="mr-2"
                              htmlFor={`loginCredentialRequests.${index.toString()}.paymentFileNameIdentifier`}
                            >
                              Payment Filename Identifier:
                            </FormLabel>
                            <FormControl>
                              <Input
                                type="text"
                                disabled={isMutating}
                                aria-disabled={isMutating}
                                placeholder="Enter the payment filename identifier..."
                                {...register(
                                  `loginCredentialRequests.${index.toString()}.paymentFileNameIdentifier`
                                )}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    </TableCell>
                    <TableCell className="w-fit font-medium">
                      <Button
                        className="mt-6 flex items-center gap-2 px-3"
                        variant="outline"
                        size="xs"
                        onClick={() => {
                          remove(index);
                        }}
                      >
                        <Trash2Icon className="size-4" />
                        <span className="sr-only">Delete</span>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
    </>
  );
}

function SupplierContactNameField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="supplierContactName"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="supplierContactName" showMandatoryAsterisk>
            Contact Name:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the contact name..."
              {...register("supplierContactName")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ContactEmailField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="contactEmail"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="contactEmail" showMandatoryAsterisk>
            Contact Email:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the contact email..."
              {...register("contactEmail")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function TaxIdField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="taxId"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="taxId" showMandatoryAsterisk>
            Tax ID:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the tax ID..."
              {...register("taxId")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function Address1Field({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="address1"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="address1">Address 1:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the address 1..."
              {...register("address1", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function Address2Field({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="address2"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="address2">Address 2:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the address 2..."
              {...register("address2", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function Address3Field({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="address3"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="address3">Address 3:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the address 3..."
              {...register("address3", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function Address4Field({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="address4"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="address4">Address 4:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the address 4..."
              {...register("address4", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CountryField({isMutating}: SupplierAPIMutatingProps) {
  const {control, setValue} = useFormContext();

  return (
    <FormField
      control={control}
      name="country"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="country">Country:</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? countrySupplierSelectOptions.find(
                        (country) => country.value === field.value
                      )?.label
                    : "Select Country..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search countries..." />
                <CommandEmpty>No countries found.</CommandEmpty>
                <CommandGroup>
                  {countrySupplierSelectOptions.map((regionOption) => (
                    <CommandItem
                      key={regionOption.value}
                      value={regionOption.value}
                      onSelect={(value) => {
                        setValue("country", value.toUpperCase());
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          regionOption.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {regionOption.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function StateField({isMutating}: SupplierAPIMutatingProps) {
  const {control, setValue, watch} = useFormContext();
  const formFields = watch();

  const stateOptions =
    formFields.country === "US"
      ? stateOptionsUSA
      : formFields.country === "CA"
      ? provinceOptionsCanada
      : [];

  // Disable the entire component when stateOptions is empty
  if (stateOptions.length === 0) {
    return (
      <FormField
        name="state"
        render={() => (
          <FormItem className="mt-2 flex flex-col">
            <FormLabel htmlFor="state">State:</FormLabel>

            <Button
              variant="outline"
              disabled={isMutating || stateOptions.length === 0}
              aria-disabled={isMutating}
              className={cn("justify-between text-accent-foreground")}
            >
              {"Please select country first"}
            </Button>
          </FormItem>
        )}
      />
    );
  }

  return (
    <FormField
      control={control}
      name="state"
      render={({field}) => (
        <FormItem className="mt-2 flex flex-col">
          <FormLabel htmlFor="state">State:</FormLabel>
          <Popover>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  role="combobox"
                  className={cn(
                    "justify-between",

                    !field.value && "text-accent-foreground"
                  )}
                >
                  {field.value
                    ? stateOptions.find((state) => state.value === field.value)
                        ?.label
                    : "Select State..."}
                  <ChevronsUpDownIcon className="ml-2 size-4 shrink-0 opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="w-[var(--radix-popover-trigger-width)] p-0">
              <Command>
                <CommandInput placeholder="Search states..." />
                <CommandEmpty>No states found.</CommandEmpty>
                <CommandGroup>
                  {stateOptions.map((regionOption) => (
                    <CommandItem
                      key={regionOption.value}
                      value={regionOption.label}
                      onSelect={() => {
                        setValue("state", regionOption.value);
                      }}
                    >
                      <CheckIcon
                        className={cn(
                          "mr-2 size-4",
                          regionOption.value === field.value
                            ? "opacity-100"
                            : "opacity-0"
                        )}
                      />
                      {regionOption.label}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CityField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="city"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="city">City:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the city..."
              {...register("city", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function ZipField({isMutating}: SupplierAPIMutatingProps) {
  const {register, watch} = useFormContext();

  const formFields = watch();
  const currentCountry = formFields.country as string;

  const currentLabel =
    currentCountry === "US"
      ? "Zip code:"
      : currentCountry === "CA"
      ? "Postal code:"
      : "Zip code:";

  return (
    <FormField
      name="zip"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="zip" showMandatoryAsterisk>
            {currentLabel}
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the zip..."
              {...register("zip")}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function LocationCodeField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="locationCode"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="locationCode">Location Code:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the location code..."
              {...register("locationCode", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function BatchSizeField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="batchSize"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="batchSize">Proxy Pay Batch Size:</FormLabel>
          <FormControl>
            <Input
              type="number"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the batch size..."
              {...register("batchSize", {
                required: true,
                setValueAs: (v) =>
                  v === "" ? undefined : parseFloat(v as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function CronIntervalField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="cronInterval"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="cronInterval">Proxy Pay Cron Interval:</FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the cron interval..."
              {...register("cronInterval", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function WindowStartField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="maintenanceWindowStart"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="maintenanceWindowStart">
            Maintenance Window Start Time:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the maintenance window start..."
              {...register("maintenanceWindowStart", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

function WindowEndField({isMutating}: SupplierAPIMutatingProps) {
  const {register} = useFormContext();
  return (
    <FormField
      name="maintenanceWindowEnd"
      render={() => (
        <FormItem>
          <FormLabel htmlFor="maintenanceWindowEnd">
            Maintenance Window End Time:
          </FormLabel>
          <FormControl>
            <Input
              type="text"
              disabled={isMutating}
              aria-disabled={isMutating}
              placeholder="Enter the maintenance window end..."
              {...register("maintenanceWindowEnd", {
                setValueAs: (value): string | null =>
                  typeof value === "string" && value.trim() === ""
                    ? null
                    : (value as string),
              })}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
