import {useParams} from "@tanstack/react-router";

import {PaymentMethodForm} from "@/modules/admin";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";

import {ModuleNames} from "@/utils/constants";

export function CustomerOnboardingImREmitPaymentMethodPage() {
  const {externalId} = useParams({
    from: "/app/admin/customer-management/imremit/$externalId/onboarding/payment-method",
  });

  // Render OnboardCustomerForm component
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.UPDATE_CUSTOMER_MANAGEMENT]}
    >
      <PaymentMethodForm externalId={externalId} module={ModuleNames.imremit} />
    </AccessPolicyWrapper>
  );
}
