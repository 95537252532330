/* eslint-disable */

import {useEffect, useMemo, useRef} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {debounce} from "lodash";
import {useForm} from "react-hook-form";
import {z} from "zod";

// import {Button} from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
// import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {Skeleton} from "@/components/ui/skeleton";
import {Spinner} from "@/components/ui/spinner";
import {toast} from "@/components/ui/use-toast";

import {useImRemitCustomerStore} from "@/lib/stores";
// import {cn} from "@/lib/utils";
import {useKeyCloakInstanceStore} from "@/modules/auth";
import {
  useGetCustomerByName,
  useSelectInfiniteCustomers,
  type CustomerType,
} from "@/modules/customers";

/**
 * Schema definition for the SelectCustomer form using Zod.
 */
const SelectCustomerFormSchema = z.object({
  imREmitCustomer: z.string(),
  customerName: z.string().optional(),
});

interface SelectCustomerFormProps {
  requireLabel?: boolean;
  cusomterError?: string; // Error message passed from the parent
  onChange?: (value: string) => void; // Callback to pass the updated value
}

// 🎯 Add minimum search length constant
const MIN_SEARCH_LENGTH = 3;

/**
 * React component for selecting a customer from a list.
 */
export function SelectCustomerForm({
  requireLabel,
  cusomterError,
  onChange,
}: SelectCustomerFormProps) {
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const parsedUserInfo = storeKeyCloakInstance?.tokenParsed;
  const {imREmitStoreCustomer, updateImRemitStoreCustomer} =
    useImRemitCustomerStore();

  // Form setup
  const selectCustomerFormMethods = useForm<
    z.infer<typeof SelectCustomerFormSchema>
  >({
    defaultValues: {
      imREmitCustomer: imREmitStoreCustomer
        ? imREmitStoreCustomer.buyerName
        : "",
    },
    mode: "onChange",
    resolver: zodResolver(SelectCustomerFormSchema),
  });

  const formFields = selectCustomerFormMethods.watch();

  // Query setup with minimum length check
  const {
    data: infiniteData,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useSelectInfiniteCustomers(
    formFields.customerName &&
      formFields.customerName.length >= MIN_SEARCH_LENGTH
      ? formFields.customerName
      : undefined
  );

  // 🔄 Add query to fetch selected customer details
  const {data: selectedCustomerData} = useGetCustomerByName(
    formFields.imREmitCustomer,
    {
      enabled: !!formFields.imREmitCustomer,
      // 🎯 Keep the data fresh for 5 minutes
      staleTime: 5 * 60 * 1000,
    }
  );

  // Refs
  const parentRef = useRef<HTMLDivElement>(null);
  const lastScrollTop = useRef(0);

  // Data processing
  const allCustomers = useMemo(() => {
    if (!infiniteData?.pages) return [];

    return infiniteData.pages.reduce<CustomerType[]>((acc, page) => {
      if (!page?.content) return acc;
      return [...acc, ...page.content];
    }, []);
  }, [infiniteData]);

  // 🔄 Update to use proper typing and consistent naming
  const customerOptions = useMemo(() => {
    const options = allCustomers.map((customer: CustomerType) => ({
      businessNameLabel: customer.buyerName,
      businessNameValue: customer.buyerName,
    }));

    if (
      selectedCustomerData &&
      Array.isArray(selectedCustomerData) &&
      selectedCustomerData.length > 0 &&
      !options.some(
        (opt) => opt.businessNameValue === selectedCustomerData[0].buyerName
      )
    ) {
      options.unshift({
        businessNameLabel: selectedCustomerData[0].buyerName,
        businessNameValue: selectedCustomerData[0].buyerName,
      });
    }

    return options;
  }, [allCustomers, selectedCustomerData]);

  // Effects
  useEffect(() => {
    const updatedCustomer = allCustomers.find(
      (customer) =>
        customer.buyerName.toLowerCase().trim() ===
        formFields.imREmitCustomer.toLowerCase().trim()
    );

    if (updatedCustomer) {
      updateImRemitStoreCustomer(updatedCustomer);
    }

    // Notify the parent component about the change
    if (typeof onChange === "function") {
      onChange(formFields.imREmitCustomer);
    }
  }, [
    updateImRemitStoreCustomer,
    allCustomers,
    formFields.imREmitCustomer,
    onChange,
  ]);

  // 🔄 Update store when customer is selected
  useEffect(() => {
    if (
      formFields.imREmitCustomer &&
      Array.isArray(selectedCustomerData) &&
      selectedCustomerData[0]
    ) {
      updateImRemitStoreCustomer(selectedCustomerData[0]);
    }
  }, [
    formFields.imREmitCustomer,
    selectedCustomerData,
    updateImRemitStoreCustomer,
  ]);

  // Optimized debounce
  const debouncedCustomerNameChange = useMemo(
    () =>
      debounce((value: string) => {
        if (value.length >= MIN_SEARCH_LENGTH || value === "") {
          selectCustomerFormMethods.setValue("customerName", value);
        }
      }, 300),
    [selectCustomerFormMethods]
  );

  // Add search status message
  const searchStatus = useMemo(() => {
    if (
      formFields.customerName &&
      formFields.customerName.length < MIN_SEARCH_LENGTH
    ) {
      return `Please enter at least ${MIN_SEARCH_LENGTH.toString()} characters to search`;
    }
    return null;
  }, [formFields.customerName]);

  // Clean up effect should also be before any returns
  useEffect(() => {
    return () => {
      debouncedCustomerNameChange.cancel();
    };
  }, [debouncedCustomerNameChange]);

  // Early return for parsed user info
  if (parsedUserInfo?.customer) {
    return null;
  }

  // Define a function to handle form submission
  function onSubmit(data: z.infer<typeof SelectCustomerFormSchema>) {
    // Display a toast notification when the form is submitted
    toast({
      title: "You submitted the following values:",
      description: (
        <pre className="mt-2 w-[340px] rounded-md bg-slate-950 p-4">
          <code className="text-white">{JSON.stringify(data, null, 2)}</code>
        </pre>
      ),
    });
  }

  // 🔄 Add handler for customer selection
  const onCustomerSelect = (value: string) => {
    const selectedCustomer = allCustomers.find(
      (customer: CustomerType) => customer.buyerName === value
    );

    if (selectedCustomer) {
      updateImRemitStoreCustomer(selectedCustomer);
    }

    selectCustomerFormMethods.setValue("imREmitCustomer", value);
  };

  return (
    <Form {...selectCustomerFormMethods}>
      <form onSubmit={selectCustomerFormMethods.handleSubmit(onSubmit)}>
        <FormField
          control={selectCustomerFormMethods.control}
          name="imREmitCustomer"
          render={({field}) => (
            <FormItem className="flex flex-col space-y-0">
              {requireLabel && (
                <FormLabel
                  className="pb-2"
                  htmlFor="imREmitCustomer"
                  showMandatoryAsterisk
                >
                  Select Customer:
                </FormLabel>
              )}
              <Select
                value={field.value}
                onValueChange={onCustomerSelect}
                onOpenChange={(open) => {
                  // Prevent focus when opening the select
                  if (open) {
                    document.activeElement instanceof HTMLElement &&
                      document.activeElement.blur();
                  }
                }}
              >
                <FormControl>
                  <SelectTrigger className="h-10 min-w-80 max-w-96">
                    <SelectValue placeholder="Select customer...">
                      {field.value}
                    </SelectValue>
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <div className="w-full border-b">
                    <Input
                      placeholder={`Search customers (min. ${MIN_SEARCH_LENGTH.toString()} characters)...`}
                      defaultValue={formFields.customerName}
                      className="rounded-none border-0 focus-visible:ring-0"
                      onKeyDown={(e) => {
                        e.stopPropagation();
                      }}
                      onChange={(e) =>
                        debouncedCustomerNameChange(e.target.value)
                      }
                    />
                  </div>
                  <div
                    ref={parentRef}
                    className="max-h-[300px] overflow-auto overscroll-contain"
                    onScroll={(e) => {
                      const target = e.target as HTMLDivElement;
                      const maxScroll =
                        target.scrollHeight - target.clientHeight;

                      // Check if we're at the boundaries and still trying to scroll
                      if (
                        (target.scrollTop <= 0 &&
                          target.scrollTop < lastScrollTop.current) ||
                        (target.scrollTop >= maxScroll &&
                          target.scrollTop > lastScrollTop.current)
                      ) {
                        target.scrollTop =
                          target.scrollTop <= 0 ? 0 : maxScroll;
                        e.preventDefault();
                        e.stopPropagation();
                      }

                      // Update last scroll position
                      lastScrollTop.current = target.scrollTop;

                      // Infinite scroll logic
                      if (
                        !isFetchingNextPage &&
                        hasNextPage &&
                        target.scrollHeight - target.scrollTop <=
                          target.clientHeight * 1.5
                      ) {
                        void fetchNextPage();
                      }
                    }}
                  >
                    {searchStatus ? (
                      <div className="p-2 text-center text-gray-500">
                        {searchStatus}
                      </div>
                    ) : isFetching && customerOptions.length === 0 ? (
                      <div className="space-y-2 p-2">
                        <Skeleton className="h-8 w-full" />
                        <Skeleton className="h-8 w-full" />
                        <Skeleton className="h-8 w-full" />
                        <Skeleton className="h-8 w-full" />
                        <Skeleton className="h-8 w-full" />
                      </div>
                    ) : (
                      customerOptions.map((customer) => (
                        <SelectItem
                          key={customer.businessNameValue}
                          value={customer.businessNameValue}
                        >
                          {customer.businessNameLabel}
                        </SelectItem>
                      ))
                    )}
                    {hasNextPage && isFetchingNextPage && (
                      <div className="flex justify-center p-2">
                        <Spinner size="sm" />
                      </div>
                    )}
                  </div>
                </SelectContent>
              </Select>
              <FormMessage>
                {cusomterError && (
                  <span className="text-red-500">{cusomterError}</span>
                )}
              </FormMessage>
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
}
