// import {useParams} from "@tanstack/react-router";
import {useParams} from "@tanstack/react-router";

import {EmailConfigurationForm} from "@/modules/admin";
import {AccessPolicyWrapper, KeycloakRoleEnum} from "@/modules/auth";

export function CustomerOnboardingImREmitEmailConfigurationPage() {
  const {externalId} = useParams({
    from: "/app/admin/customer-management/imremit/$externalId/onboarding/email-configuration",
  });
  // Render OnboardCustomerForm component
  return (
    <AccessPolicyWrapper
      policyActions={[KeycloakRoleEnum.UPDATE_CUSTOMER_MANAGEMENT]}
    >
      <EmailConfigurationForm externalId={externalId} />
    </AccessPolicyWrapper>
  );
}
