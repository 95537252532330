import {Navigate, useNavigate, useRouter} from "@tanstack/react-router";

import {RoutesDirectory} from "@/lib/routes/directory";
import {useRoutePersistence} from "@/lib/stores";

export function LandingPage() {
  // Get the navigate function from the router
  const navigate = useNavigate();
  // Use the custom hook useRoutePersistence to get the last visited route
  const {lastVisited} = useRoutePersistence();

  // Get pathname of user's current route
  const {pathname} = useRouter().latestLocation;

  // If the user is not authenticated and the current route is not the login page,
  // redirect to the login page
  if (pathname === "/") {
    void navigate({
      to: RoutesDirectory.APP,
    });
  }

  void navigate({
    to: lastVisited ? lastVisited : RoutesDirectory.APP,
  });

  // If the user is authenticated, navigate to the last visited route, if it exists.
  return <Navigate to={lastVisited ? lastVisited : RoutesDirectory.APP} />;
}
