/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {
  renderRemittanceBadge,
  renderSupplierStatus,
  SupplierManagementRowActions,
  SupplierProfileTable,
  type SupplierDetailsType,
} from "@/modules/imremit";

import {FallbackMessages} from "@/utils/constants";
import {formatDate} from "@/utils/functions";

export const supplierManagementTableColumns: ColumnDef<SupplierDetailsType>[] =
  [
    {
      accessorKey: "customerName",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Customer Name" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("customerName") ? (
            row.getValue("customerName")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "supplierNumber",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Supplier ID" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("supplierNumber") ? (
            row.getValue("supplierNumber")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "supplierName",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Supplier Name" />
      ),
      cell: ({row}) => {
        if (!row.original.supplierName) {
          return <Paragraph>{FallbackMessages.NOT_APPLICABLE}</Paragraph>;
        }

        return (
          <HoverCard openDelay={150} closeDelay={300}>
            <HoverCardTrigger>
              <Paragraph className="cursor-pointer font-semibold underline hover:text-theme">
                {row.original.supplierName}
              </Paragraph>
            </HoverCardTrigger>
            <HoverCardContent className="p-0">
              <SupplierProfileTable id={row.original.supplierId} />
            </HoverCardContent>
          </HoverCard>
        );
      },
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "taxId",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Tax ID" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("taxId") ? (
            row.getValue("taxId")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },

    {
      accessorKey: "phoneNumber",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Phone Number" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("phoneNumber") ? (
            row.getValue("phoneNumber")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "zip",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Zip" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("zip") ? (
            row.getValue("zip")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "supplierEmail",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Supplier Email" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("supplierEmail") ? (
            row.getValue("supplierEmail")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false
      // enableHiding: false,
    },

    {
      accessorKey: "onboardStatus",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Status" />
      ),

      cell: ({row}) => {
        if (!row.original.onboardStatus) {
          return (
            <Badge>
              <Paragraph>{FallbackMessages.NO_DATA}</Paragraph>
            </Badge>
          );
        }
        return renderSupplierStatus(row.original.onboardStatus);
      },

      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "paymentMode",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Remittance Method" />
      ),

      cell: ({row}) => {
        if (!row.original.paymentMode) {
          return (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          );
        }

        return renderRemittanceBadge(row.original.paymentMode);
      },
      filterFn: (row, id, value) => {
        return value.includes(row.getValue(id));
      },
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "selfRegisteredFlag",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Self Registered" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          <Badge
            variant={
              row.getValue("selfRegisteredFlag") ? "success" : "destructive"
            }
          >
            {row.getValue("selfRegisteredFlag") ? "YES" : "NO"}
          </Badge>
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },
    {
      accessorKey: "lastEditTimestamp",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Updated Date" />
      ),
      cell: ({row}) => (
        <div className="w-fit whitespace-nowrap">
          {row.getValue("lastEditTimestamp") ? (
            <span>
              {formatDate(new Date(row.getValue("lastEditTimestamp")))}
            </span>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
      // enableSorting: false,
      // enableHiding: false,
    },

    {
      id: "actions",
      header: () => <Paragraph className="text-right">Actions</Paragraph>,
      cell: ({row}) => (
        <div className="flex w-[80] justify-end">
          <SupplierManagementRowActions row={row} />
        </div>
      ),
    },
  ];
