import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import type {
  StatementReconAddGroupType,
  StatementReconGetGroupType,
} from "@/modules/statement-recon";
import {statementReconGroupingKeys} from "@/modules/statement-recon";

// Defining a type based on the function `getSRGroupings`
type QueryFnTypeByExternalId = typeof getSRGroupingsByExternalId;

// Defining a function to fetch data from API
export function getSRGroupingsByExternalId(buyer_external_id?: string) {
  // Fetch data from API using ky
  return kyApiFn<StatementReconGetGroupType[]>(
    `statement-recon/v1/supplier-grouping/customer/${buyer_external_id ?? ""}`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useSRGroupingsByExternalId(
  buyer_external_id?: string,
  config?: QueryConfig<QueryFnTypeByExternalId>
) {
  return useQuery<ExtractFnReturnType<QueryFnTypeByExternalId>>({
    ...config,
    queryKey: statementReconGroupingKeys.byExternalId(buyer_external_id), // Unique key for cache 🗝
    queryFn: () => getSRGroupingsByExternalId(buyer_external_id), // Data fetching function 📡
    enabled: !!buyer_external_id, // Condition to enable the query 🚦
  });
}

export function addSRGroupingsFn(data: StatementReconAddGroupType) {
  return kyApiFn<StatementReconAddGroupType>(
    `statement-recon/v1/supplier-grouping`,
    "post",
    {json: data}
  );
}
