/* eslint-disable */

import {useEffect, useRef, useState} from "react";

import {AxisBottom, AxisLeft} from "@visx/axis";
import {Group} from "@visx/group";
import {LegendOrdinal} from "@visx/legend";
import {scaleBand, scaleLinear, scaleOrdinal} from "@visx/scale";
import {Bar, Circle, LinePath} from "@visx/shape";

import {Button} from "@/components/ui/button";
import {Checkbox} from "@/components/ui/checkbox";

// 📊 Interface defining the structure of payment data with received vs posted metrics
interface MISPaymentsDifferencesType {
  year: number;
  month: string;
  payments_received: number;
  payments_posted: number;
  diff_received_value: number | null;
  diff_received_percent: number | null;
  diff_posted_value: number | null;
  diff_posted_percent: number | null;
}

// 🎨 Props interface for the graph component defining required dimensions and data
interface MISPaymentsDifferencesGraphProps {
  data: MISPaymentsDifferencesType[];
  width: number;
  height: number;
}

export function MISPaymentsDifferencesGraph({
  data,
  width,
  height,
}: MISPaymentsDifferencesGraphProps) {
  // 🔄 Toggle states for graph display options
  const [showDifferences, setShowDifferences] = useState<boolean>(false);
  const [showTrendlines, setShowTrendlines] = useState<boolean>(false);

  // 📌 Reference for graph container to enable download functionality
  const containerRef = useRef<HTMLDivElement | null>(null);

  // 📏 Dynamic margin state to accommodate varying axis label widths
  const [leftMargin, setLeftMargin] = useState(60);

  // Define margins with dynamic left margin
  const margin = {top: 20, right: 20, bottom: 50, left: leftMargin};
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // 🎯 Data accessors and type definitions for graph rendering
  const getX = (d: MISPaymentsDifferencesType) => `${d.month} ${d.year}`;

  type DataKey =
    | "payments_received"
    | "payments_posted"
    | "diff_received_value"
    | "diff_posted_value";

  const paymentKeys: DataKey[] = ["payments_received", "payments_posted"];
  const diffKeys: DataKey[] = ["diff_received_value", "diff_posted_value"];

  const keys = showDifferences ? diffKeys : paymentKeys;

  // 🎨 Visual styling - color mapping for different data series
  const colorMap: Record<DataKey, string> = {
    payments_received: "#C900FF",
    payments_posted: "#FFB500",
    diff_received_value: "#C900FF",
    diff_posted_value: "#FFB500",
  };

  // 📐 Scale configuration for x-axis using band scale
  const xScale = scaleBand<string>({
    domain: data.map(getX),
    range: [0, xMax],
    padding: 0.2,
  });

  // 📈 Calculate y-axis domain with dynamic range adjustment
  const yValues = data.flatMap((d) => {
    const values = showDifferences
      ? [d.diff_received_value, d.diff_posted_value]
      : [d.payments_received, d.payments_posted];
    return values.filter((v): v is number => v !== null && v !== undefined);
  });

  const minYValue = Math.min(0, ...yValues);
  const maxYValue = Math.max(0, ...yValues);

  const yScale = scaleLinear<number>({
    domain: [minYValue, maxYValue],
    nice: true,
    range: [yMax, 0],
  });

  // 💾 Export graph as PNG image
  const handleDownload = () => {
    if (!containerRef.current) return;

    const svg = containerRef.current.querySelector("svg");
    const svgData = new XMLSerializer().serializeToString(svg!);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();

    canvas.width = width;
    canvas.height = height;

    img.onload = () => {
      ctx!.fillStyle = "white";
      ctx!.fillRect(0, 0, width, height);
      ctx!.drawImage(img, 0, 0);

      const link = document.createElement("a");
      link.download = "graph.png";
      link.href = canvas.toDataURL("image/png");
      link.click();
    };

    img.src = "data:image/svg+xml;base64," + btoa(svgData);
  };

  // 🔄 Dynamically adjust left margin based on axis label width
  useEffect(() => {
    // Create temporary SVG to measure text width
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    const text = document.createElementNS("http://www.w3.org/2000/svg", "text");
    text.setAttribute("font-size", "11px");

    // Get the longest y-axis label
    const yLabels = yScale.ticks().map((value) => Number(value).toFixed(2));
    const longestLabel = yLabels.reduce(
      (a, b) => (a.length >= b.length ? a : b),
      ""
    );

    text.textContent = longestLabel;
    svg.appendChild(text);
    document.body.appendChild(svg);

    // Get the width and add more padding
    const textWidth = text.getComputedTextLength();
    const newMargin = Math.ceil(textWidth) + 20;

    setLeftMargin(newMargin);

    // Cleanup
    document.body.removeChild(svg);
  }, [yScale, showDifferences]);

  // Add this after the other scale definitions
  const legendScale = scaleOrdinal({
    domain: showDifferences
      ? ["Received Difference", "Posted Difference"]
      : ["Payments Received", "Payments Posted"],
    range: [colorMap[keys[0]], colorMap[keys[1]]],
  });

  return (
    <section>
      {/* 🎛️ User controls for graph visualization options */}
      <div className="flex items-center justify-between">
        <Button onClick={handleDownload} variant="outline">
          Download Graph
        </Button>

        {/* Center section for legend */}
        <div className="flex flex-1 justify-center">
          <LegendOrdinal
            scale={legendScale}
            direction="row"
            labelMargin="0 15px 0 0"
            shapeWidth={15}
            shapeHeight={15}
            itemDirection="row"
            shape="circle"
            style={{
              display: "flex",
              gap: "2rem",
              fontSize: "14px",
            }}
          />
        </div>

        {/* Right section for checkboxes */}
        <div className="flex items-center gap-x-4">
          {!showDifferences && (
            <div className="flex items-center gap-x-2">
              <span>Show Trendlines</span>
              <Checkbox
                checked={showTrendlines}
                onCheckedChange={() => setShowTrendlines(!showTrendlines)}
              />
            </div>
          )}
          <div className="flex items-center gap-x-2">
            <span>Show Differences</span>
            <Checkbox
              checked={showDifferences}
              onCheckedChange={() => setShowDifferences(!showDifferences)}
            />
          </div>
        </div>
      </div>

      {/* 📊 Graph visualization container */}
      <div ref={containerRef} style={{position: "relative"}}>
        <svg width={width} height={height}>
          <Group left={margin.left} top={margin.top}>
            {showDifferences ? (
              //  Line graph rendering for difference mode
              keys.map((key) => {
                const lineData = data
                  .map((d) => ({
                    x: getX(d),
                    y: d[key],
                  }))
                  .filter(
                    (d): d is {x: string; y: number} =>
                      d.y !== null && d.y !== undefined
                  );

                return (
                  <g key={`line-${key}`}>
                    <LinePath
                      data={lineData}
                      x={(d) => xScale(d.x)! + xScale.bandwidth() / 2}
                      y={(d) => yScale(d.y)}
                      stroke={colorMap[key]}
                      strokeWidth={2}
                    />
                  </g>
                );
              })
            ) : (
              <>
                {/* 📊 Bar chart rendering for standard mode */}
                {data.map((d) => {
                  const xValue = getX(d);
                  return keys.map((key) => {
                    const value = d[key];
                    if (value === null || value === undefined) return null;

                    const barWidth = xScale.bandwidth() / 2;
                    const xOffset = key === "payments_received" ? 0 : barWidth;

                    return (
                      <Bar
                        key={`bar-${xValue}-${key}`}
                        x={xScale(xValue)! + xOffset}
                        y={yScale(Math.max(0, value))}
                        width={barWidth}
                        height={Math.abs(yScale(value) - yScale(0))}
                        fill={colorMap[key]}
                      />
                    );
                  });
                })}

                {/* 📈 Trend analysis visualization with regression lines */}
                {showTrendlines &&
                  keys.map((key) => {
                    const lineData = data
                      .map((d, index) => ({
                        index,
                        x: getX(d),
                        y: d[key],
                      }))
                      .filter(
                        (d): d is {index: number; x: string; y: number} =>
                          d.y !== null && d.y !== undefined
                      );

                    // Calculate linear regression
                    const xMean =
                      lineData.reduce((sum, d) => sum + d.index, 0) /
                      lineData.length;
                    const yMean =
                      lineData.reduce((sum, d) => sum + d.y, 0) /
                      lineData.length;

                    const numerator = lineData.reduce(
                      (sum, d) => sum + (d.index - xMean) * (d.y - yMean),
                      0
                    );
                    const denominator = lineData.reduce(
                      (sum, d) => sum + Math.pow(d.index - xMean, 2),
                      0
                    );

                    const slope =
                      denominator !== 0 ? numerator / denominator : 0;
                    const intercept = yMean - slope * xMean;

                    // Generate trendline points
                    const trendlineData = lineData.map((d) => ({
                      x: d.x,
                      y: slope * d.index + intercept,
                    }));

                    return (
                      <g key={`trendline-${key}`}>
                        <LinePath
                          data={trendlineData}
                          x={(d) => xScale(d.x)! + xScale.bandwidth() / 2}
                          y={(d) => yScale(d.y)}
                          stroke={colorMap[key]}
                          strokeWidth={2}
                        />
                        {/* Only add points for trendline values */}
                        {trendlineData.map((d, i) => (
                          <Circle
                            key={`point-${key}-${i}`}
                            cx={xScale(d.x)! + xScale.bandwidth() / 2}
                            cy={yScale(d.y)}
                            r={4}
                            fill={colorMap[key]}
                          />
                        ))}
                      </g>
                    );
                  })}
              </>
            )}
            {/* 📏 Axis rendering with formatted labels */}
            <AxisBottom
              top={yMax}
              scale={xScale}
              tickFormat={(d) => d}
              tickLabelProps={() => ({
                fill: "#000",
                fontSize: 11,
                textAnchor: "middle",
              })}
            />
            <AxisLeft
              scale={yScale}
              tickFormat={(value) => Number(value).toFixed(2)}
              tickLabelProps={() => ({
                fill: "#000",
                fontSize: 11,
                textAnchor: "end",
                dy: "0.33em",
              })}
            />
          </Group>
        </svg>
      </div>
    </section>
  );
}
