import {useMemo, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {Label} from "@radix-ui/react-label";
import {type Table} from "@tanstack/react-table";
import {ListRestartIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import {z} from "zod";

import {Button} from "@/components/ui/button";
import {Form, FormControl, FormField, FormItem} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {AdvancedSearch} from "@/components/craft/advanced-search";
import {
  DataTableFacetedFilter,
  DataTablePagination,
  DataTableViewOptions,
} from "@/components/craft/data-table";
import {DatePicker} from "@/components/craft/date-pickers";
import {
  FancyMultiSelect,
  multiSelectDatumSchema,
} from "@/components/craft/fancy-multi-select";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

import {useImRemitCustomerStore} from "@/lib/stores";
import {useGetSuppliersList} from "@/modules/imremit";

const statusData = [
  {label: "Active", value: "ACTIVE"},
  {label: "Inactive", value: "INACTIVE"},
  {label: "Declined Enrollment", value: "DECLINED_ENROLLMENT"},
  {label: "Approval Pending", value: "APPROVAL_PENDING"},
  {label: "Onboarding Pending", value: "ONBOARDING_PENDING"},
  {label: "Form Declined", value: "FORM_DECLINED"},
];

/**
 * Props for SupplierManagementDataTableToolbar component.
 *
 * @template TData - The type of data used in the table.
 * @typedef {Object} SupplierManagementDataTableToolbarProps
 * @property {Table<TData>} table - The table instance to interact with.
 */
interface SupplierManagementDataTableToolbarProps<TData> {
  table: Table<TData>;
  isPending?: boolean;
  isErrored?: boolean;
}

const SupplierManagementFormSchema = z.object({
  lastEditTimestamp: z.string().nullable().optional(),
  suppliers: z.array(multiSelectDatumSchema),
});

const isValidEmail = (email: string) => {
  // Regular expression to validate email address
  const emailRegex = /^$|^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const isValidPhoneNumber = (phoneNumber: string) => {
  // Regular expression to validate phone number, allowing empty input
  const phoneRegex =
    /^$|^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
  return phoneRegex.test(phoneNumber);
};

const isValidZipCode = (zipCode: string) => {
  // Regular expression to validate zip code, allowing empty input
  const zipCodeRegex = /^$|^\d{5}(?:[-\s]\d{4})?$/;
  return zipCodeRegex.test(zipCode);
};

/**
 * Toolbar component for supplier management data table.
 * Includes pagination, search input, filter components, and view options.
 *
 * @template TData - The type of data displayed in the table.
 * @param {SupplierManagementDataTableToolbarProps<TData>} props - The component props.
 * @returns {JSX.Element} The rendered JSX element.
 */
export function SupplierManagementDataTableToolbar<TData>({
  table,
  isPending,
  isErrored,
}: SupplierManagementDataTableToolbarProps<TData>) {
  const supplierManagementToolbarFormMethods = useForm<
    z.infer<typeof SupplierManagementFormSchema>
  >({
    defaultValues: {
      lastEditTimestamp: undefined,
      suppliers: [],
    },
    mode: "onChange",
    resolver: zodResolver(SupplierManagementFormSchema),
  });

  const formFields = supplierManagementToolbarFormMethods.watch();

  // State for the global filter input
  const [globalFilterInput, setGlobalFilterInput] = useState("");
  // State for the specific filter input
  const [emailFilterInput, setEmailFilterInput] = useState("");
  const [phoneNoFilterInput, setPhoneNoFilterInput] = useState("");
  const [zipCodeFilterInput, setZipCodeFilterInput] = useState("");

  // Errors
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [zipError, setZipError] = useState(false);

  // Determine if the table is currently filtered
  const isFiltered =
    table.getState().columnFilters.length > 0 ||
    !!table.getState().globalFilter;

  const {imREmitStoreCustomer} = useImRemitCustomerStore();

  /**
   * @type {string | undefined}
   */
  const externalId = imREmitStoreCustomer?.externalId;

  /**
   * @type {{data: {content: any[]} | undefined}}
   */
  const getSupplierByCustomerIdQuery = useGetSuppliersList(externalId);

  /**
   * @type {any[] | undefined}
   */
  const rawSuppliersData = getSupplierByCustomerIdQuery.data?.content;

  /**
   * @type {{label: string, value: string}[]}
   */
  const suppliersData = useMemo(() => {
    if (!rawSuppliersData) return [];
    return rawSuppliersData.map((supplier) => ({
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      label: supplier.supplierNumber + "-" + supplier.supplierName,
      value: supplier.supplierNumber?.toString() || "",
    }));
  }, [rawSuppliersData]);

  //Columns other names
  const columnsOtherName = [
    {key: "customerName", value: "Customer Name"},
    {key: "supplierNumber", value: "Supplier ID"},
    {key: "supplierName", value: "Supplier Name"},
    {key: "taxId", value: "Tax ID"},
    {key: "phoneNumber", value: "Phone Number"},
    {key: "zip", value: "Zip"},
    {key: "supplierEmail", value: "Supplier Email"},
    {key: "onboardStatus", value: "Status"},
    {key: "paymentMode", value: "Remittance Method"},
    {key: "selfRegisteredFlag", value: "Self Registered"},
    {key: "lastEditTimestamp", value: "Updated Date"},
  ];

  return (
    <>
      <DataTablePagination
        table={table}
        isPending={isPending}
        isErrored={isErrored}
      />
      <Form {...supplierManagementToolbarFormMethods}>
        <div className="flex items-center justify-between gap-2">
          <div className="flex flex-1 flex-wrap items-center gap-y-2 space-x-2">
            <RowsPreferenceSelect />

            <Input
              placeholder="Search all entries..."
              className="h-10 w-[150px] lg:w-[256px]"
              value={globalFilterInput}
              onChange={(event) => {
                setGlobalFilterInput(event.target.value);
                table.setGlobalFilter(event.target.value);
              }}
            />

            <FormField
              name="suppliers"
              render={({field}) => (
                <FormItem className="flex flex-col">
                  <FormControl>
                    <FancyMultiSelect
                      {...field}
                      multiSelectData={suppliersData}
                      disabled={!suppliersData.length}
                      customWidth="fit"
                      placeholder={
                        !suppliersData.length
                          ? "No suppliers..."
                          : "Search suppliers..."
                      }
                      onChange={(selectedItems) => {
                        field.onChange(selectedItems);

                        // Loop through each selected item and set filter value
                        // Extract the values from the selected items
                        const supplierIdFilterInput = selectedItems.map(
                          (item) => item.value
                        );

                        // Set the filter value with the new selections
                        table
                          .getColumn("supplierNumber")
                          ?.setFilterValue(supplierIdFilterInput.join(","));
                      }}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            {isFiltered && (
              <Button
                variant="destructive"
                className="h-10 gap-2 px-2 hover:bg-destructive/70 hover:text-destructive-foreground/80 active:bg-destructive/80 active:text-destructive-foreground/80 lg:px-3"
                onClick={() => {
                  table.resetColumnFilters();
                  table.resetGlobalFilter();
                  setGlobalFilterInput("");

                  setEmailFilterInput("");
                  setPhoneNoFilterInput("");
                  setZipCodeFilterInput("");
                  supplierManagementToolbarFormMethods.reset();
                }}
              >
                <span className="sr-only">Reset search filters</span>
                Search Reset
                <ListRestartIcon className="size-4" />
              </Button>
            )}
          </div>
          <div className="flex flex-col items-end gap-2 lg:flex-row">
            {table.getColumn("onboardStatus") && (
              <DataTableFacetedFilter
                column={table.getColumn("onboardStatus")}
                title="Status"
                options={statusData}
              />
            )}
            <AdvancedSearch>
              <div className="grid grid-cols-1 gap-x-2 gap-y-2 lg:grid-cols-2 lg:gap-x-4 lg:gap-y-4 xl:grid-cols-3 2xl:grid-cols-4">
                <div className="flex flex-col space-y-2">
                  <Label
                    className={`text-sm ${
                      emailError ? "text-destructive-foreground" : ""
                    }`}
                  >
                    Supplier Email
                  </Label>
                  <Input
                    placeholder="Search email..."
                    className="h-10 w-[150px] lg:w-[256px]"
                    value={emailFilterInput}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      setEmailFilterInput(inputValue);
                      setEmailError(!isValidEmail(inputValue));
                      table
                        .getColumn("supplierEmail")
                        ?.setFilterValue(event.target.value);
                    }}
                  />
                  {emailError && (
                    <p className="text-sm font-medium text-destructive-foreground">
                      Not a valid email
                    </p>
                  )}
                </div>
                <div className="flex flex-col space-y-2">
                  <Label
                    className={`text-sm ${
                      phoneError ? "text-destructive-foreground" : ""
                    }`}
                  >
                    Phone Number
                  </Label>
                  <Input
                    placeholder="Search phone no..."
                    className="h-10 w-[150px] lg:w-[256px]"
                    value={phoneNoFilterInput}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      setPhoneNoFilterInput(inputValue);
                      setPhoneError(!isValidPhoneNumber(inputValue));
                      table
                        .getColumn("phoneNumber")
                        ?.setFilterValue(inputValue);
                    }}
                  />
                  {phoneError && (
                    <p className="text-sm font-medium text-destructive-foreground">
                      Not a valid phone number
                    </p>
                  )}
                </div>
                <div className="flex flex-col space-y-2">
                  <Label
                    className={`text-sm ${
                      zipError ? "text-destructive-foreground" : ""
                    }`}
                  >
                    Zip Code
                  </Label>
                  <Input
                    id="zipCodeFilter"
                    placeholder="Search zip code..."
                    className="h-10 w-[150px] lg:w-[256px]"
                    value={zipCodeFilterInput}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      setZipCodeFilterInput(inputValue);
                      setZipError(!isValidZipCode(inputValue));
                      table.getColumn("zip")?.setFilterValue(inputValue);
                    }}
                  />
                  {zipError && (
                    <p className="text-sm font-medium text-destructive-foreground">
                      Not a valid zipcode
                    </p>
                  )}
                </div>
                <div className="flex flex-col space-y-2">
                  <Label className="text-sm">Updated Date</Label>
                  <FormField
                    name="lastEditTimestamp"
                    render={({field}) => (
                      <FormItem className="flex flex-col">
                        <DatePicker
                          {...field}
                          placeholder="Search updated date..."
                          disabled={
                            formFields.lastEditTimestamp
                              ? {
                                  before: new Date("1970-01-01"),
                                  after: new Date("9999-01-01"),
                                }
                              : undefined
                          }
                          onChange={(date) => {
                            field.onChange(date);
                            const filteredDate = new Date(date);
                            filteredDate.setDate(date.getDate() + 1);
                            table
                              .getColumn("lastEditTimestamp")
                              ?.setFilterValue(
                                filteredDate.toISOString().substring(0, 10)
                              );
                          }}
                        />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
            </AdvancedSearch>
            <DataTableViewOptions
              table={table}
              columnsOtherName={columnsOtherName}
            />
          </div>
        </div>
      </Form>
    </>
  );
}
