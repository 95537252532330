import {useMemo} from "react";

import {Link, useParams} from "@tanstack/react-router";
import {ArrowRightIcon, FileEditIcon} from "lucide-react";

import {Badge} from "@/components/ui/badge";
import {buttonVariants} from "@/components/ui/button";
import {Card, CardContent, CardHeader} from "@/components/ui/card";
import {Separator} from "@/components/ui/separator";
import {Heading1, Heading2, Paragraph} from "@/components/ui/typography";
import {LoadingSkeletonCard} from "@/components/craft/loading-skeleton-card";
import {NotAuthorizedPage} from "@/components/layout/not-authorized";

import {cn} from "@/lib/utils";
import {KeycloakRoleEnum, useAuthorization} from "@/modules/auth";
import {useGetCustomerById} from "@/modules/customers";

import {FallbackMessages, ModuleNames} from "@/utils/constants";

/**
 * Custom hook to fetch and manage customer details in the context of the current route.
 *
 * This hook retrieves the customer ID from the URL parameters and uses it to fetch customer details.
 * It memoizes the necessary data and provides an interface for components to access customer-specific information.
 *
 * @returns {Object} An object containing:
 *   - searchCustomerIDQuery: The query object returned by useGetCustomerById hook, containing customer data and metadata.
 *   - searchCustomerData: The detailed customer data, or null if not available.
 *   - externalId: The ID of the customer extracted from URL parameters.
 */
function useCustomerDetails() {
  // Extract the externalId from the URL parameters.
  // The useParams hook is configured to parse the externalId from a specific route pattern.
  const {externalId} = useParams({
    from: "/app/admin/customer-management/$externalId/view",
  });

  // Fetch customer data using the externalId. The externalId is coerced to a number as the API expects a numerical ID.
  const searchCustomerIDQuery = useGetCustomerById(externalId);

  // Memoize the customer's detailed data.
  // This ensures that the data is recalculated only when the underlying 'data' changes, optimizing performance.
  const searchCustomerData = useMemo(() => {
    // Extract the 'content' field from the query data, default to null if not available.
    return searchCustomerIDQuery.data?.content || null;
  }, [searchCustomerIDQuery.data]);

  // Return an object containing the query result, the processed customer data, and the externalId.
  return {
    searchCustomerIDQuery,
    searchCustomerData,
    externalId,
  };
}

/**
 * Component for viewing the details of a customer.
 *
 * This component utilizes custom hooks to fetch customer details based on the URL parameters and to check user authorization.
 * It conditionally renders different UI elements based on the data fetching status and the fetched data itself.
 *
 * @returns {JSX.Element} A JSX element representing the customer details page.
 *                        This includes handling for loading states, authorization checks, and the actual customer data display.
 */
export function ViewCustomerDetailsPage() {
  // Extracting customer data and status from the custom hook.
  const {searchCustomerIDQuery, searchCustomerData, externalId} =
    useCustomerDetails();

  // Hook to check user's authorization for certain actions.
  const {checkPoliciesAccess} = useAuthorization();

  // Handling unauthorized access scenario.
  if (!checkPoliciesAccess([KeycloakRoleEnum.READ_CUSTOMER_MANAGEMENT])) {
    // Render a component to indicate lack of authorization.
    return <NotAuthorizedPage />;
  }

  // Handling the loading state of the customer data.
  if (searchCustomerIDQuery.isPending) {
    // Render a skeleton screen while the data is being fetched.
    return <LoadingSkeletonCard lineCount={9} />;
  }

  const imremitLiteExists = searchCustomerData?.moduleSubscriptions.find(
    (module) => module.moduleName === ModuleNames.imremitLite
  );

  // Main render logic for the customer details page.
  return (
    <section>
      <div className="flex justify-between gap-4 pb-4">
        <div className="flex flex-col">
          <Heading2>Customer Profile</Heading2>
          <Paragraph>
            Here you can find a quick overview of your customer details.
          </Paragraph>
        </div>
        <Link
          to="/app/admin/customer-management"
          className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
        >
          Back to Customer Management
          <ArrowRightIcon className="size-4" />
        </Link>
      </div>

      <Card>
        <CardHeader className="flex gap-2">
          <div className="mb-4 flex flex-col-reverse gap-4 md:flex-row md:justify-between">
            <div className="flex w-full items-center justify-between gap-4">
              <Heading1>
                {!searchCustomerData?.buyerName
                  ? "Customer not found"
                  : searchCustomerData.buyerName}
              </Heading1>
              {/* Render action buttons if customer data is available */}
              {searchCustomerData && (
                <div className="flex justify-end">
                  <CustomerManagementActionButtons />
                </div>
              )}
            </div>
          </div>
          {/* Display message if customer data is not found */}
          {!searchCustomerData && (
            <div className="flex w-full items-center justify-center">
              <Paragraph className="font-semibold">
                Customer not found
              </Paragraph>
            </div>
          )}
          {/* Render customer details if data is fetched and available */}
          {searchCustomerIDQuery.isFetched && searchCustomerData && (
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
              <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                <Paragraph className="text-lg">
                  <strong className="text-lg font-bold">Customer ID: </strong>
                  {externalId}
                </Paragraph>
              </div>
              <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                <Paragraph className="text-lg">
                  <strong className="text-lg font-bold">Buyer Name: </strong>
                  {searchCustomerData.buyerName}
                </Paragraph>
              </div>
              <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                <Paragraph className="text-lg">
                  <strong className="text-lg font-bold">Status: </strong>
                  {searchCustomerData.status}
                </Paragraph>
              </div>
            </div>
          )}
        </CardHeader>
        {/* Render customer details if data is fetched and available */}
        {searchCustomerIDQuery.isFetched && searchCustomerData && (
          <>
            <Separator className="mb-4" />
            <CardContent>
              {/* Multiple conditional blocks for displaying customer information */}
              {/* Each block checks for the availability of specific data fields */}
              {/* Fallback messages are displayed if data is not available */}
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-3">
                <Paragraph>
                  <strong className="font-bold">Billing Type:</strong>{" "}
                  {searchCustomerData.customerProfile
                    .buyerProfileBillingType ? (
                    searchCustomerData.customerProfile.buyerProfileBillingType
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>
                <Paragraph>
                  <strong className="font-bold">Payment Type:</strong>{" "}
                  {searchCustomerData.customerProfile
                    .buyerProfilePaymentType ? (
                    searchCustomerData.customerProfile.buyerProfilePaymentType
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>
                <Paragraph>
                  <strong className="font-bold">Settlement Terms:</strong>{" "}
                  {searchCustomerData.customerProfile
                    .buyerProfileSettlementTerms ? (
                    searchCustomerData.customerProfile
                      .buyerProfileSettlementTerms
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>
                <Paragraph>
                  <strong className="font-bold">Payment Method:</strong>{" "}
                  {searchCustomerData.customerProfile
                    .buyerProfilePaymentMethod ? (
                    searchCustomerData.customerProfile.buyerProfilePaymentMethod
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>
                <Paragraph>
                  <strong className="font-bold">Cycle Date:</strong>{" "}
                  {searchCustomerData.customerProfile.buyerProfileCycleDate ? (
                    searchCustomerData.customerProfile.buyerProfileCycleDate
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>
                <Paragraph>
                  <strong className="font-bold">Invoice Day:</strong>{" "}
                  {searchCustomerData.customerProfile.buyerProfileInvoiceDay ? (
                    searchCustomerData.customerProfile.buyerProfileInvoiceDay
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>

                <Paragraph>
                  <strong className="font-bold">Contact Name:</strong>{" "}
                  {searchCustomerData.customerProfile
                    .buyerProfileContactName ? (
                    searchCustomerData.customerProfile.buyerProfileContactName
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>
                <Paragraph>
                  <strong className="font-bold">Address:</strong>{" "}
                  {searchCustomerData.customerProfile.buyerProfileAddress1 &&
                  searchCustomerData.customerProfile.buyerProfileAddress2 &&
                  searchCustomerData.customerProfile.buyerProfileAddress3 ? (
                    searchCustomerData.customerProfile.buyerProfileAddress1 +
                    ", " +
                    searchCustomerData.customerProfile.buyerProfileAddress2 +
                    ", " +
                    searchCustomerData.customerProfile.buyerProfileAddress3
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>
                <Paragraph>
                  <strong className="font-bold">Phone:</strong>{" "}
                  {searchCustomerData.customerProfile.buyerProfilePhone ? (
                    searchCustomerData.customerProfile.buyerProfilePhone
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>
                <Paragraph>
                  <strong className="font-bold">Email:</strong>{" "}
                  {searchCustomerData.customerProfile.buyerProfileEmail ? (
                    searchCustomerData.customerProfile.buyerProfileEmail
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>
                <Paragraph>
                  <strong className="font-bold">Bill:</strong>{" "}
                  {searchCustomerData.customerProfile.buyerProfileBill ? (
                    searchCustomerData.customerProfile.buyerProfileBill
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>
                <Paragraph>
                  <strong className="font-bold">MCC Template Options:</strong>{" "}
                  {searchCustomerData.mccTemplateOptions ? (
                    searchCustomerData.mccTemplateOptions
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>
                <Paragraph>
                  <strong className="font-bold">Reporting Tool:</strong>{" "}
                  {searchCustomerData.customerProfile
                    .buyerProfileReportingTool ? (
                    searchCustomerData.customerProfile.buyerProfileReportingTool
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>
                {/* <Paragraph>
                  <strong className="font-bold">Organization ID:</strong>{" "}
                  {searchCustomerData.organizationId ? (
                    searchCustomerData.organizationId
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph> */}
                <Paragraph>
                  <strong className="font-bold">Account Number Masking:</strong>{" "}
                  {searchCustomerData.customerProfile
                    .buyerProfileAccountNumberMapping ? (
                    searchCustomerData.customerProfile
                      .buyerProfileAccountNumberMapping
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>
                <Paragraph>
                  <strong className="font-bold">
                    Response File Data Masking:
                  </strong>{" "}
                  {searchCustomerData.customerProfile
                    .buyerProfileResponseDataMasking ? (
                    searchCustomerData.customerProfile
                      .buyerProfileResponseDataMasking
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>
                <Paragraph>
                  <strong className="font-bold">Connectivity Method:</strong>{" "}
                  {searchCustomerData.customerProfile
                    .buyerProfileConnectivityMethod ? (
                    searchCustomerData.customerProfile
                      .buyerProfileConnectivityMethod
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>
                <Paragraph>
                  <strong className="font-bold">PID Mapping:</strong>{" "}
                  {searchCustomerData.customerProfile.pidMapping ? (
                    searchCustomerData.customerProfile.pidMapping
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>
                <Paragraph>
                  <strong className="font-bold">Multiselect pidmapping:</strong>{" "}
                  {searchCustomerData.customerProfile.multiSelectPidMapping ? (
                    searchCustomerData.customerProfile.multiSelectPidMapping
                  ) : (
                    <Badge variant="destructive">
                      {FallbackMessages.NO_DATA}
                    </Badge>
                  )}
                </Paragraph>
                {imremitLiteExists && searchCustomerData.buyerAliases && (
                  <Paragraph>
                    <strong className="font-bold">Buyer Aliases:</strong>{" "}
                    {searchCustomerData.buyerAliases.length > 0 ? (
                      searchCustomerData.buyerAliases.map((alias, index) => (
                        <Badge key={index} className="mr-1">
                          {alias}
                        </Badge>
                      ))
                    ) : (
                      <Badge variant="destructive">
                        {FallbackMessages.NO_DATA}
                      </Badge>
                    )}
                  </Paragraph>
                )}
              </div>
            </CardContent>
          </>
        )}
      </Card>
    </section>
  );
}

/**
 * CustomerManagementActionButtons renders action buttons for managing customer details.
 * It includes buttons for editing, blocking, and commenting on customer details.
 *
 * The component utilizes a `useCustomerDetails` hook to retrieve the current customer's ID,
 * and uses this ID to dynamically generate the edit link.
 * It employs TailwindCSS for styling and a combination of custom icons and text within buttons.
 */
function CustomerManagementActionButtons() {
  // Extract externalId from useCustomerDetails hook.
  const {externalId} = useCustomerDetails();

  return (
    <div className="flex flex-col gap-2 md:flex-row md:items-center md:justify-between">
      {/* Edit customer button */}
      <Link
        to="/app/admin/customer-management/$externalId/edit"
        params={{externalId}}
        className={cn(buttonVariants({size: "sm"}), "gap-2")}
      >
        <span className="sr-only">Edit</span>
        <FileEditIcon className="size-4" />
        <Paragraph>Edit</Paragraph>
      </Link>
    </div>
  );
}
