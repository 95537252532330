// Importing necessary Zustand functions and types
import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

import {type FacilitiesColumnsType} from "@/modules/facilities";

// Interface defining the shape of the ParticipantRegisterTableColumnsStore state
// This state will consist of an array of ParticipantRegisterTableColumns objects
interface ParticipantRegisterTableColumnsStoreState {
  storeParticipantRegisterTableColumns: FacilitiesColumnsType | null;
  setStoreParticipantRegisterTableColumns: (
    storeParticipantRegisterTableColumns: FacilitiesColumnsType
  ) => void; // Setter function to update the state
}

// Creating the Zustand store with persistence
// The store manages the state for ParticipantRegister table columns
export const useParticipantRegisterTableColumnsStore = create(
  persist<ParticipantRegisterTableColumnsStoreState>(
    (set, get) => ({
      // Initial state with null value
      storeParticipantRegisterTableColumns: null,

      // Getter function to retrieve the current state
      getStoreParticipantRegisterTableColumns: () =>
        get().storeParticipantRegisterTableColumns,

      // Setter function to update the state
      setStoreParticipantRegisterTableColumns: (
        storeParticipantRegisterTableColumns
      ) => {
        set(() => ({storeParticipantRegisterTableColumns}));
      },
    }),
    {
      // Configuration for persisting the state in local storage
      name: "participant-register-columns-store", // Key used in local storage
      storage: createJSONStorage(() => localStorage), // Defining the storage method
    }
  )
);

// Hook to retrieve the current state of the store
// This can be used in components to access the state
export const useParticipantRegisterTableColumns = () =>
  useParticipantRegisterTableColumnsStore((state) => {
    return {
      storeParticipantRegisterTableColumns:
        state.storeParticipantRegisterTableColumns,
    };
  });
