import {createRoute} from "@tanstack/react-router";

import {LandingOutlet} from "@/lib/config/outlets";
import {RoutesDirectory} from "@/lib/routes/directory";
import {rootRoute} from "@/lib/routes/root";
import {
  LandingPage,
  PrivacyPolicyPage,
  TermsOfConditionsPage,
} from "@/modules/landing";

export const landingRootRoute = createRoute({
  path: RoutesDirectory.ROOT,
  component: LandingPage,
  getParentRoute: () => rootRoute,
});

export const helpRootRoute = createRoute({
  path: RoutesDirectory.HELP,
  component: LandingOutlet,
  getParentRoute: () => rootRoute,
});

export const privacyRootRoute = createRoute({
  path: RoutesDirectory.PRIVACY_POLICY,
  component: LandingOutlet,
  getParentRoute: () => rootRoute,
});

export const privacyRoute = createRoute({
  path: RoutesDirectory.ROOT,
  component: PrivacyPolicyPage,
  getParentRoute: () => privacyRootRoute,
});

export const termsRootRoute = createRoute({
  path: RoutesDirectory.TERMS_OF_SERVICE,
  component: LandingOutlet,
  getParentRoute: () => rootRoute,
});

export const termsRoute = createRoute({
  path: RoutesDirectory.ROOT,
  component: TermsOfConditionsPage,
  getParentRoute: () => termsRootRoute,
});
