import {useCallback, useEffect, useState} from "react";

import {TimePickerComponent} from "@/components/ui/time-picker/time-picker-component";

/**
 * Props for the TimePicker component.
 */
interface TimePickerProps {
  /**
   * Function called when the date changes.
   * @param newDate The new date value.
   */
  onChange: (newDate: Date | undefined) => void;

  /**
   * Indicates whether the TimePicker is disabled.
   */
  disabled?: boolean;
  dateValue?: string;
}

/**
 * A component for selecting time.
 * @param {TimePickerProps} props The props for the TimePicker component.
 * @returns {JSX.Element} The rendered TimePicker component.
 */
export function TimePicker({
  onChange,
  disabled,
  dateValue,
}: TimePickerProps): JSX.Element {
  const [date, setDate] = useState<Date | undefined>();

  /**
   * Handler function to update the date state.
   * @param {Date | undefined} newDate The new date value.
   */
  const handleDateChange = useCallback(
    (newDate: Date | undefined) => {
      if (!disabled) {
        setDate(newDate);
        onChange(newDate);
      }
    },
    [disabled, onChange]
  );

  useEffect(() => {
    if (disabled) {
      setDate(undefined);
    }
  }, [disabled]);

  useEffect(() => {
    if (dateValue) {
      const [hours, minutes] = dateValue.split(":");
      const newDate = new Date();
      newDate.setHours(Number(hours));
      newDate.setMinutes(Number(minutes));
      handleDateChange(newDate);
    }
  }, [dateValue, handleDateChange]);

  return (
    <TimePickerComponent
      setDate={handleDateChange} // Pass the handleDateChange function as setDate prop
      date={date}
      disabled={disabled}
    />
  );
}
