import {z} from "zod";

import {multiSelectDatumSchema} from "@/components/craft/fancy-multi-select";
import {facetedFilterDatumSchema} from "@/components/craft/table";

export const paymentManagementIMRemitPremiumTableColumnsSchema = z.object({
  orgId: z.boolean(),
  supplierName: z.boolean(),
  supplierNumber: z.boolean(),
  facilityName: z.boolean(),
  paymentNumber: z.boolean(),
  accountNumber: z.boolean(),
  totalAmountSent: z.boolean(),
  postingDate: z.boolean(),
  customerStatus: z.boolean(),
  statusName: z.boolean(),
  description: z.boolean(),
  descriptionDetail: z.boolean(),
  paymentDetailId: z.boolean(),
  initiatedDate: z.boolean(),
  endDate: z.boolean(),
  methodName: z.boolean(),
  amountTaken: z.boolean(),
  balanceAmount: z.boolean(),
  userId: z.boolean(),
  agentName: z.boolean(),
  remittanceMethod: z.boolean(),
});
export type PaymentManagementIMRemitPremiumTableColumnsType = z.infer<
  typeof paymentManagementIMRemitPremiumTableColumnsSchema
>;

export const paymentManagementTransactionsSchema = z.object({
  transactionDetailId: z.number().nullable().optional(),
  transactionDate: z.string().nullable().optional(),
  transactionId: z.string().nullable().optional(),
  postingDate: z.string().nullable().optional(),
  merchantName: z.string().nullable().optional(),
  transactionAmount: z.number().nullable().optional(),
  originalAmount: z.number().nullable().optional(),
});

export type paymentManagementTransactions = z.infer<
  typeof paymentManagementTransactionsSchema
>;

export const PaymentManagementCriteriaSchema = z.object({
  paymentDetailId: z.string().nullable(),
  // customerStatus: z.string().nullable(),
  description: z.string().nullable(),
  supplierNumber: z.string().nullable(),
  supplierName: z.string().nullable(),
  // supplierId: z.string().nullable(),
  totalAmountSent: z.number().nullable(),
  amountTaken: z.number().nullable(),
  balanceAmount: z.number().nullable(),
  orgId: z.string().nullable(),
  facilityName: z.string().nullable(),
  methodName: z.string().nullable(),
  descriptionDetail: z.string().nullable(),
  providerName: z.string().nullable(),
  fromDate: z.date().nullable(),
  toDate: z.date().nullable(),
  paymentNumber: z.string().nullable(),
  accountNumber: z.string().nullable(),
  agentName: z.string().nullable(),
});

export type PaymentManagementFormCriteriaType = z.infer<
  typeof PaymentManagementCriteriaSchema
>;

export const PaymentManagementFormSchema = z.object({
  paymentDetailId: z.string().optional(),
  customerStatus: z.string().optional(),
  description: z.string().optional(),
  suppliers: z.array(multiSelectDatumSchema),
  status: z.array(facetedFilterDatumSchema),
  supplierNumber: z.string().optional(),
  supplierName: z.string().optional(),
  supplierId: z.string().optional(),
  totalAmountSent: z.number().optional(),
  amountTaken: z.number().optional(),
  balanceAmount: z.number().optional(),
  orgId: z.string().optional(),
  facilityName: z.string().optional(),
  methodName: z.string().optional(),
  descriptionDetail: z.string().optional(),
  providerName: z.string().optional(),
  initiatedDate: z.string().optional(),
  endDate: z.string().optional(),
  paymentNumber: z.string().optional(),
  accountNumber: z.string().optional(),
  agentName: z.string().optional(),
  userId: z.string().optional(),
  remittanceMethod: z.string().optional(),
});

export type PaymentManagementFormType = z.infer<
  typeof PaymentManagementFormSchema
>;
