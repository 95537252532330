import {createRoute} from "@tanstack/react-router";
import type {z} from "zod";

import {RoutesDirectory} from "@/lib/routes/directory";
import {
  imREmitLiteRootRoute,
  imremitLiteSearchFilterSchema,
  PaymentManagementDetails,
  PaymentManagementPage,
  PaymentManagementRedirect,
  PaymentManagementSearchFilterList,
} from "@/modules/imremit-lite";

export type PaymentManagementSearchFilterType = z.infer<
  typeof imremitLiteSearchFilterSchema
>;

export const imREmitLitePaymentManagementRoute = createRoute({
  path: `${RoutesDirectory.PAYMENT_MANAGEMENT}/$pmPage${RoutesDirectory.DASHBOARD}`,
  component: PaymentManagementPage,
  getParentRoute: () => imREmitLiteRootRoute,
  validateSearch: (search) => imremitLiteSearchFilterSchema.parse(search),
});

export const imREmitLitePaymentManagementRedirectRoute = createRoute({
  path: RoutesDirectory.PAYMENT_MANAGEMENT,
  component: PaymentManagementRedirect,
  getParentRoute: () => imREmitLiteRootRoute,
});

export const imREmitLitePaymentViewRootRoute = createRoute({
  path: `${RoutesDirectory.PAYMENT_MANAGEMENT}/$paymentId${RoutesDirectory.VIEW}/$list_type`,
  component: PaymentManagementDetails,
  getParentRoute: () => imREmitLiteRootRoute,
});

export const imREmitLiteFilterPaymentRoute = createRoute({
  path: `${RoutesDirectory.PAYMENT_MANAGEMENT}/$pmPage${RoutesDirectory.PAYMENTS}/$methodType`,
  component: PaymentManagementSearchFilterList,
  getParentRoute: () => imREmitLiteRootRoute,
});
