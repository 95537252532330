import {useCallback} from "react";

import {useNavigate} from "@tanstack/react-router";
import axios from "axios";

import {UploadDropzone} from "@/components/ui/upload";
import {toast} from "@/components/ui/use-toast";

import {pdfCompleteMetaResponseSchema} from "@/modules/imremit";

interface PDFUploadDropzoneProps {
  buyerExternalId: string;
}

export function PDFUploadDropzone({buyerExternalId}: PDFUploadDropzoneProps) {
  const navigate = useNavigate();

  // const {parsedUserInfo} = useUserInfo();

  // // Define the mutation for creating file metadata 📝
  // const createPDFFileMetaMutation = useMutation({
  //   // Define the mutation function
  //   mutationFn: async (data: z.infer<typeof pdfUploadMetaResponseSchema>) => {
  //     const reqData = {
  //       payment_info: data.payment_info || "",
  //       payment_links: data.payment_links,
  //       linked_id: data.linked_id || "",
  //       buyer_external_id: buyerExternalId,
  //       file_location: data.file_location,
  //       file_name: data.file_location.substring(
  //         data.file_location.lastIndexOf("/") + 1
  //       ),
  //       file_size: data.file_size.toString(),
  //       file_mime_type: data.file_mime_type,
  //       file_status_id: 1,
  //       uploaded_by: (parsedUserInfo?.name as string) || "Unknown user",
  //     };

  //     console.log("reqData", reqData);

  //     return createUploadPDFMeta(reqData, buyerExternalId);
  //   },

  //   // Handle the mutation success 🎉
  //   onSuccess: (createPDFFileMetaMutationResponse) => {
  //     console.log(
  //       "createPDFFileMetaMutationResponse",
  //       createPDFFileMetaMutationResponse
  //     );

  //     toast({
  //       variant: "success",
  //       title: `SUCCESS!`,
  //       description: `You have successfully uploaded ${createPDFFileMetaMutationResponse.content.file_name}`,
  //     });

  //     // Navigate to the uploaded PDF page 🗺
  //     void navigate({
  //       to: "/app/imremit/pdf-upload/$fileId/$externalId",
  //       params: {
  //         fileId:
  //           // set the file id to the response or default to 0
  //           createPDFFileMetaMutationResponse.content.file_id?.toString() ||
  //           "0",
  //         externalId: buyerExternalId,
  //       },
  //       replace: true,
  //     });
  //   },

  //   // Handle the mutation error 🚨
  //   onError: (error: unknown) => {
  //     let errorMessage = "An error occurred";
  //     if (typeof error === "string") {
  //       errorMessage = error;
  //     } else if (error instanceof Error && error.message) {
  //       errorMessage = error.message;
  //     }
  //     toast({
  //       variant: "destructive",
  //       title: "Error uploading file!",
  //       description: errorMessage,
  //     });
  //   },
  // });

  const uploadEnv = (import.meta.env.VITE_KEYCLOAK_REALM as string) || "dev";

  // Define the onDrop handler for file uploads 📂
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const formData = new FormData();
      formData.append("im-pdf-ocr-upload", acceptedFiles[0]);

      try {
        const baseURL = (() => {
          switch (import.meta.env.VITE_KEYCLOAK_REALM as string) {
            case "prod":
              return "invoicetracker";
            case "dev":
              return "ntdevtracker";
            case "qa":
              return "ntqatracker";
            case "staging":
              return "ntstagingtracker";
            default:
              return "ntdevtracker";
          }
        })();

        const response = await axios.post(
          `https://${baseURL}.iterationm.com/pdf-ocr/v1/${uploadEnv}/upload/${buyerExternalId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const parsedResponse = pdfCompleteMetaResponseSchema.parse(
          response.data
        );

        // createPDFFileMetaMutation.mutate(parsedResponse.data);

        // Navigate to the uploaded PDF page 🗺
        void navigate({
          to: "/app/imremit/pdf-upload/$fileId/$externalId",
          params: {
            fileId:
              // set the file id to the response or default to 0
              parsedResponse.data.file_id?.toString() || "0",
            externalId: buyerExternalId,
          },
          replace: true,
        });
        // 🎉 Successfully uploaded the file
      } catch (error) {
        console.error(error);
        // 😞 Error uploading the file
        let errorMessage = "An error occurred";
        if (typeof error === "string") {
          errorMessage = error;
        } else if (error instanceof Error && error.message) {
          errorMessage = error.message;
        }
        toast({
          variant: "destructive",
          title: "Error uploading file!",
          description: errorMessage,
        });
      }
    },
    [buyerExternalId, navigate, uploadEnv]
  );

  return (
    <UploadDropzone
      data-testid="pdf-upload-dropzone"
      maxFiles={1}
      accept={{
        ".pdf": ["application/pdf", ".pdf"],
      }}
      onDrop={onDrop}
    />
  );
}
