import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {statementReconDateFormatKeys} from "@/modules/statement-recon";
import type {srDateFormatSchemaType} from "@/modules/statement-recon/types/sr-date-format-types";

//Defining a type based on the function `getSRSummary`
type QueryFnType = typeof getSRDateFormats;

// Defining a function to fetch data from API
export function getSRDateFormats() {
  // Fetch data from API using ky
  return kyApiFn<srDateFormatSchemaType[]>(
    `statement-recon/v1/date_formats`,
    "get"
  );
}

// Custom hook to fetch and cache data using react-query
export function useSRDateFormats(config?: QueryConfig<QueryFnType>) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config,
    queryKey: statementReconDateFormatKeys.all,
    queryFn: getSRDateFormats,
  });
}
