import {CircuitBoardIcon} from "lucide-react";

import {SubrouteNavigation} from "@/components/craft/subroute-navigation";

import {ElectronicInvoicingRoutesSchema} from "@/lib/routes/types";
import {KeycloakRoleEnum} from "@/modules/auth";

// Define an array with the data for each route button. This includes the 'to' route,
// the 'icon' to be displayed, and the 'label' for the button.
// This array holds the configuration for each SubrouteNavigationButton
// Each object in the array represents a button in the navigation menu
const routes = [
  {
    to: ElectronicInvoicingRoutesSchema.Values["/app/electronic-invoicing"],
    icon: <CircuitBoardIcon className="h-10 w-10 pl-2" />,
    label: "Intelligent Invoicing Portal",
    actionRole: KeycloakRoleEnum.READ_ELECTRONIC_INVOICING,
  },
];

interface ElectronicInvoicingSubrouteNavigationProps {
  layout?: "horizontal" | "vertical";
}

// This component renders the SubrouteNavigation component with the routes array
// as a prop. It also passes the 'navigationAccessibleLabel' and
// 'navigationAriaLabelledby' props to the SubrouteNavigation component.
export function ElectronicInvoicingSubrouteNavigation(
  {layout}: ElectronicInvoicingSubrouteNavigationProps = {layout: "horizontal"}
) {
  return (
    <SubrouteNavigation
      routes={routes}
      layout={layout}
      navigationAccessibleLabel="ElectronicInvoicing Subroute Navigation"
      navigationAriaLabelledby="electronicInvoicingSubrouteNavHeader"
    />
  );
}
