import {useState} from "react";

import {type Table} from "@tanstack/react-table";
import {ListRestartIcon} from "lucide-react";

import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import {
  // DataTableFacetedFilter,
  DataTablePagination,
  DataTableViewOptions,
} from "@/components/craft/data-table";
import {RowsPreferenceSelect} from "@/components/craft/table/rows-preference";

import "@/modules/admin"; // billingTypes,

// paymentMethodTypes,
// paymentTypes,
// settlementTermsTypes,
// statusTypes,

import {SelectCustomerFormImRemit} from "@/modules/customers";

/**
 * Props for FileProcessingDataTableToolbar component.
 *
 * @template TData - The type of data used in the table.
 * @typedef {Object} FileProcessingDataTableToolbarProps
 * @property {Table<TData>} table - The table instance to interact with.
 */
interface FileProcessingDataTableToolbarProps<TData> {
  table: Table<TData>;
  isPending?: boolean;
  isErrored?: boolean;
}

/**
 * Toolbar component for customer management data table.
 * Includes pagination, search input, filter components, and view options.
 *
 * @template TData - The type of data displayed in the table.
 * @param {FileProcessingDataTableToolbarProps<TData>} props - The component props.
 * @returns {JSX.Element} The rendered JSX element.
 */
export function FileProcessingDataTableToolbar<TData>({
  table,
  isPending,
  isErrored,
}: FileProcessingDataTableToolbarProps<TData>) {
  // State for the global filter input
  const [globalFilterInput, setGlobalFilterInput] = useState("");
  // State for the specific filter input
  const [fileIDFilterInput, setFileIDFilterInput] = useState("");
  const [fileNameFilterInput, setFileNameFilterInput] = useState("");

  // Determine if the table is currently filtered
  const isFiltered =
    table.getState().columnFilters.length > 0 ||
    !!table.getState().globalFilter;

  //Columns other names
  const columnsOtherName = [
    {key: "packageSummaryId", value: "Package ID"},
    {key: "fileName", value: "Name"},
    {key: "formatName", value: "Format"},
    {key: "processStatus", value: "Status"},
    {key: "received", value: "Received"},
    {key: "totalCount", value: "Totals"},
    {key: "processCount", value: "Successes"},
    {key: "failCount", value: "Failures"},
  ];

  return (
    <>
      <DataTablePagination
        table={table}
        isPending={isPending}
        isErrored={isErrored}
      />
      <div className="flex items-center justify-between gap-4">
        <div className="flex flex-1 flex-wrap items-center gap-y-2 space-x-2">
          <RowsPreferenceSelect />
          <Input
            placeholder="Search all entries..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={globalFilterInput}
            onChange={(event) => {
              setGlobalFilterInput(event.target.value);
              table.setGlobalFilter(event.target.value);
            }}
          />
          <Input
            placeholder="Search by ID..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={fileIDFilterInput}
            onChange={(event) => {
              setFileIDFilterInput(event.target.value);
              table
                .getColumn("packageSummaryId")
                ?.setFilterValue(event.target.value);
            }}
          />
          <Input
            placeholder="Search by filename..."
            className="h-10 w-[150px] lg:w-[256px]"
            value={fileNameFilterInput}
            onChange={(event) => {
              setFileNameFilterInput(event.target.value);
              table.getColumn("fileName")?.setFilterValue(event.target.value);
            }}
          />
          <SelectCustomerFormImRemit />
          {isFiltered && (
            <Button
              variant="destructive"
              className="h-10 gap-2 px-2 hover:bg-destructive/70 hover:text-destructive-foreground/80 active:bg-destructive/80 active:text-destructive-foreground/80 lg:px-3"
              onClick={() => {
                table.resetColumnFilters();
                table.resetGlobalFilter();
                setGlobalFilterInput("");
                setFileIDFilterInput("");
                setFileNameFilterInput("");
              }}
            >
              <span className="sr-only">Reset search filters</span>
              Search Reset
              <ListRestartIcon className="size-4" />
            </Button>
          )}
        </div>
        <div className="flex items-center space-x-2">
          {/* {table.getColumn("status") && (
            <DataTableFacetedFilter
              column={table.getColumn("status")}
              title="Status"
              options={statusTypes}
            />
          )} */}
          {/* {table.getColumn("billingType") && (
            <DataTableFacetedFilter
              column={table.getColumn("billingType")}
              title="Billing"
              options={billingTypes}
            />
          )}
          {table.getColumn("paymentType") && (
            <DataTableFacetedFilter
              column={table.getColumn("paymentType")}
              title="Payment Type"
              options={paymentTypes}
            />
          )}
          {table.getColumn("paymentMethod") && (
            <DataTableFacetedFilter
              column={table.getColumn("paymentMethod")}
              title="Payment Method"
              options={paymentMethodTypes}
            />
          )}
          {table.getColumn("settlementTerms") && (
            <DataTableFacetedFilter
              column={table.getColumn("settlementTerms")}
              title="Settlement"
              options={settlementTermsTypes}
            />
          )} */}

          <DataTableViewOptions
            table={table}
            columnsOtherName={columnsOtherName}
          />
        </div>
      </div>
    </>
  );
}
