import type {Dispatch} from "react";
import {forwardRef, useCallback} from "react";

import {addDays, format} from "date-fns";
import {CalendarIcon} from "lucide-react";
import {type DateRange} from "react-day-picker";

import {Button} from "@/components/ui/button";
import {Calendar} from "@/components/ui/calendar";
import {Command, CommandGroup, CommandItem} from "@/components/ui/command";
import {Popover, PopoverContent, PopoverTrigger} from "@/components/ui/popover";

import {cn} from "@/lib/utils";

const options = [
  {value: "0", label: "Today"},
  {value: "7", label: "Next Week"},
  {value: "30", label: "In 30 Days"},
  {value: "365", label: "Next Year"},
];
// Define properties for the DatePicker component
interface BaseDatePickerProps {
  value?: Date; // A selected date value
  placeholder?: string; // Placeholder text to display when no date is selected
  disabled?: {
    before: Date; // Disable dates before this date
    after: Date; // Disable dates after this date
  };
  className?: string; // Additional class names to apply to the component
  onChange: (date: Date) => void; // Callback function to handle change in date
}

export type DatePickerRef = HTMLButtonElement;
export type DateRangePickerRef = HTMLDivElement;

export type DateRangePickerProps = Omit<
  BaseDatePickerProps,
  "value" | "onChange"
> & {
  onChange: (dateRange?: DateRange) => void; // Callback function to handle change in date range
  dateRange: DateRange; // A selected date range
};

// Create DatePicker component
export const DatePicker = forwardRef<DatePickerRef, BaseDatePickerProps>(
  ({value: date, placeholder, disabled, onChange}, ref) => {
    // Handle selection of date
    // This is a memoized function that only changes if the `onChange` dependency changes
    const handleSelect = useCallback(
      (value?: Date) => {
        if (value) onChange(value); // Call onChange with the selected date
      },
      [onChange] // List of dependencies
    );

    // Return the JSX for the DatePicker component
    return (
      <Popover>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            variant="outline"
            className={cn(
              "justify-start gap-2 bg-root text-left font-normal",
              !date && "text-accent-foreground"
            )}
          >
            <CalendarIcon className="size-4" style={{transform: "none"}} />{" "}
            {/* Calendar icon */}
            {date ? (
              format(date, "PPP")
            ) : (
              <span>{placeholder ? placeholder : "Pick a date"}</span>
            )}{" "}
            {/* Display selected date or default text */}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="flex w-auto flex-col space-y-2 p-0">
          <Calendar
            mode="single" // Only one date can be selected
            selected={date} // Currently selected date
            // eslint-disable-next-line react/jsx-sort-props
            onSelect={handleSelect} // Call handleSelect when a date is selected
            // Disable dates before the `disabled.before` date or after the `disabled.after` date
            // If `disabled` is not provided, disable dates before 1970-01-01
            disabled={(date) => {
              // Check if dates should be disabled based on the `disabled` prop
              if (disabled) {
                // Disable dates before the `disabled.before` date or after the `disabled.after` date
                return date < disabled.before || date > disabled.after;
              }

              // By default, dates before 1970-01-01 are disabled
              return date < new Date("1970-01-01");
            }}
            captionLayout="dropdown-buttons"
            fromYear={1970}
            toYear={2050}
          />
        </PopoverContent>
      </Popover>
    );
  }
);
DatePicker.displayName = "DatePicker";

// Create DatePickerWithPresets component
export const DatePickerWithPresets = forwardRef<
  DatePickerRef,
  BaseDatePickerProps
>(({value: date, placeholder, disabled, onChange}, ref) => {
  // Handle selection of date
  // This is a memoized function that only changes if the `onChange` dependency changes
  const handleSelect = useCallback(
    (value?: Date) => {
      if (value) onChange(value); // Call onChange with the selected date
    },
    [onChange] // List of dependencies
  );

  // Return the JSX for the DatePickerWithPresets component
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          ref={ref}
          variant="outline"
          className={cn(
            "justify-start gap-2 text-left font-normal",
            !date && "text-accent-foreground"
          )}
        >
          <CalendarIcon className="size-4" /> {/* Calendar icon */}
          {date ? (
            format(date, "PPP")
          ) : (
            <span>{placeholder ? placeholder : "Pick a date"}</span>
          )}{" "}
          {/* Display selected date or default text */}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="flex w-auto flex-col space-y-2 p-2">
        <Command>
          <CommandGroup>
            {options.map((option) => (
              <CommandItem
                key={option.value}
                value={option.value}
                onSelect={
                  (value) => {
                    onChange(addDays(new Date(), parseInt(value)));
                  } // Call onChange with selected date
                }
              >
                {option.label}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
        <div className="rounded-md border">
          <Calendar
            mode="single"
            selected={date}
            disabled={(date) => {
              if (disabled) {
                return date < disabled.before || date > disabled.after;
              }

              return date < new Date("1970-01-01");
            }}
            onSelect={handleSelect}
          />
          {/* Single selection calendar */}
        </div>
      </PopoverContent>
    </Popover>
  );
});
DatePickerWithPresets.displayName = "DatePickerWithPresets";

// The DateRangePicker is a React functional component that allows users to pick a range of dates.
// It uses forwardRef to allow the component to receive a ref from a parent component, and
// the component props are defined by the DateRangePickerProps type.
export const DateRangePicker = forwardRef<
  DateRangePickerRef,
  DateRangePickerProps
>(({dateRange, placeholder, className, disabled, onChange}, ref) => {
  // The component returns a div that contains a Popover component for the date range picker.
  return (
    <div ref={ref} className={cn("grid gap-2", className)}>
      {/* The Popover component will show when the button is clicked */}
      <Popover>
        {/* The trigger for the Popover is a Button component */}
        <PopoverTrigger asChild>
          {/* Button to open the date range picker. If a range is selected, it displays the range; otherwise, it displays a placeholder. */}
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[260px] justify-start text-left font-normal",
              dateRange.from || (dateRange.to && "text-accent-foreground")
            )}
          >
            {/* Icon for the calendar */}
            <CalendarIcon className="mr-2 size-4" />

            {/* Check if a date range is selected and format the display accordingly */}
            {dateRange.from ? (
              dateRange.to ? (
                <>
                  {/* Format and display the date range when both 'from' and 'to' dates are selected */}
                  {format(dateRange.from, "LLL dd, y")} -{" "}
                  {format(dateRange.to, "LLL dd, y")}
                </>
              ) : (
                // Display the 'from' date if only it is selected
                format(dateRange.from, "LLL dd, y")
              )
            ) : (
              // If no date range is selected, display the placeholder
              <span>{placeholder ? placeholder : "Set date range"}</span>
            )}
          </Button>
        </PopoverTrigger>

        {/* The content of the Popover is a Calendar component */}
        <PopoverContent className="w-auto p-0" align="end">
          {/* Calendar for selecting date range */}
          <Calendar
            mode="range"
            defaultMonth={dateRange.from}
            selected={{from: dateRange.from, to: dateRange.to}}
            numberOfMonths={2}
            disabled={(date) => {
              // Check if dates should be disabled based on the 'disabled' prop
              if (disabled) {
                return date < disabled.before || date > disabled.after;
              }

              // By default, dates before 1970-01-01 are disabled
              return date < new Date("1970-01-01");
            }}
            initialFocus
            onSelect={onChange}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
});
// Set the display name for debugging purposes
DateRangePicker.displayName = "DateRangePicker";

interface DatePickerWithStateProps
  extends React.HTMLAttributes<HTMLDivElement> {
  dateRangeState: DateRange | undefined;
  setDateRangeState: Dispatch<React.SetStateAction<DateRange | undefined>>;
}
export function DatePickerWithStateRange({
  className,
  dateRangeState,
  setDateRangeState,
}: DatePickerWithStateProps) {
  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-[300px] justify-start gap-2 text-left font-normal",
              !dateRangeState && "text-accent-foreground"
            )}
          >
            <CalendarIcon className="size-4" />
            {dateRangeState?.from ? (
              dateRangeState.to ? (
                <>
                  {format(dateRangeState.from, "LLL dd, y")} -{" "}
                  {format(dateRangeState.to, "LLL dd, y")}
                </>
              ) : (
                format(dateRangeState.from, "LLL dd, y")
              )
            ) : (
              <span>Pick a date range</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            mode="range"
            defaultMonth={dateRangeState?.from}
            selected={dateRangeState}
            numberOfMonths={2}
            initialFocus
            onSelect={setDateRangeState}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
// Set the display name for debugging purposes
DatePickerWithStateRange.displayName = "DatePickerWithStateRange";
