import {useEffect, useMemo} from "react";

import {Link, useParams} from "@tanstack/react-router";
import {AxisBottom, AxisLeft} from "@visx/axis";
import {localPoint} from "@visx/event";
import {Group} from "@visx/group";
import {ParentSize} from "@visx/responsive";
import {scaleBand, scaleLinear} from "@visx/scale";
import {Bar} from "@visx/shape";
import {Text} from "@visx/text";
import {defaultStyles, TooltipWithBounds, useTooltip} from "@visx/tooltip";
import {ArrowRightIcon, TrendingUp} from "lucide-react";

import {buttonVariants} from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {Heading2, Paragraph} from "@/components/ui/typography";
import {LoadingSkeletonCard} from "@/components/craft/loading-skeleton-card";

import {StatementReconRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore, useStatementReconCustomerStore} from "@/lib/stores";
import {cn} from "@/lib/utils";
import {calculateUserRoles, useKeyCloakInstanceStore} from "@/modules/auth";
import {
  SelectCustomerSectionStatementRecon,
  SelectSupplierSection,
} from "@/modules/customers";
import {useReconSummaryByFileId} from "@/modules/statement-recon";

import {KeycloakRoles} from "@/utils/constants";

// Define the interface for Statement Summary data
interface StatementSummary {
  matched: string;
  invDateDiscrepancy: string;
  invAmountDiscrepancy: string;
  invDateAmountDiscrepancy: string;
  noMatch: string;
}

/**
 * Custom Hook: Manage the active route for the Statement Recon FileHistory page
 */
function useStatementReconStatementSearchRoute() {
  const {setStatementReconRoute} = useRoutesStore();

  useEffect(() => {
    // Set the active route based on predefined route schema
    setStatementReconRoute(
      StatementReconRoutesSchema.Values["/app/statement-recon/file-history"]
    );
  }, [setStatementReconRoute]);
}

export function StatementReconiliationSummaryPage() {
  useStatementReconStatementSearchRoute();
  const {statementReconStoreCustomer} = useStatementReconCustomerStore();
  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();

  const {fileId} = useParams({
    from: "/app/statement-recon/file-history/$fileId/summary",
  });

  const fetchStatementSummaryQuery = useReconSummaryByFileId(fileId);
  const statementSummaryData = fetchStatementSummaryQuery.data?.content;

  const keysAndValues: Array<{key: keyof StatementSummary; label: string}> = [
    {key: "matched", label: "MATCHED"},
    {key: "invDateDiscrepancy", label: "DISCREPANCY - INV DATE"},
    {key: "invAmountDiscrepancy", label: "DISCREPANCY - INV AMOUNT"},
    {
      key: "invDateAmountDiscrepancy",
      label: "DISCREPANCY - INV DATE & AMOUNT",
    },
    {key: "noMatch", label: "NO MATCH"},
  ];

  const userRoleDisplay =
    calculateUserRoles(
      storeKeyCloakInstance?.tokenParsed?.realm_access?.roles
    ) || "";

  const isASupplierUser = useMemo(
    () => userRoleDisplay === KeycloakRoles.SupplierEnablement,
    [userRoleDisplay]
  );

  if (!statementReconStoreCustomer) {
    if (isASupplierUser) {
      return <SelectSupplierSection />;
    } else {
      return <SelectCustomerSectionStatementRecon />;
    }
  }

  if (fetchStatementSummaryQuery.isLoading || !statementSummaryData) {
    return <LoadingSkeletonCard lineCount={6} />;
  }

  const chartData = keysAndValues.map(({key, label}) => ({
    month: label,
    desktop: Number(statementSummaryData[key] || 0),
  }));

  return (
    <>
      <div className="flex items-center justify-between gap-4 pb-2">
        <div className="flex flex-col">
          <Heading2>Statement Recon Result</Heading2>
          <Paragraph>
            View the reconciliation results here for the selected statement.
          </Paragraph>
        </div>
        <Link
          to="/app/statement-recon/file-history"
          className={cn(buttonVariants({variant: "secondary"}), "gap-2")}
        >
          Back to statement search
          <span className="sr-only">Back to statement search</span>
          <ArrowRightIcon className="size-4" />
        </Link>
      </div>
      <Card>
        <CardHeader>
          <CardTitle>Statement Summary</CardTitle>
          <CardDescription className="flex">
            Showing total statement summary report{" "}
            <TrendingUp className="h-4 w-4" />
          </CardDescription>
        </CardHeader>
        <CardContent>
          <StatementReconciliationSummaryChart data={chartData} />
        </CardContent>
      </Card>
    </>
  );
}

// Define the interface for chart data items
interface ChartDataItem {
  month: string;
  desktop: number;
}

// VisX Bar Chart Component
function StatementReconciliationSummaryChart({data}: {data: ChartDataItem[]}) {
  const margin = {top: 20, right: 20, bottom: 40, left: 240};

  // Tooltip setup
  const {
    tooltipOpen,
    tooltipLeft,
    tooltipTop,
    tooltipData,
    showTooltip,
    hideTooltip,
  } = useTooltip<ChartDataItem>();

  // Function to get bar color based on label
  const getBarColor = (label: string): string => {
    if (label === "MATCHED") {
      return "#C900FF";
    } else if (label === "NO MATCH") {
      return "red";
    } else {
      return "#FFB500";
    }
  };

  return (
    <div style={{position: "relative", width: "100%", height: 400}}>
      <ParentSize>
        {({width, height}) => {
          const xMax = width - margin.left - margin.right;
          const yMax = height - margin.top - margin.bottom;

          // Accessors
          const x = (d: ChartDataItem) => d.desktop;
          const y = (d: ChartDataItem) => d.month;

          // Calculate the maximum data value
          const maxDataValue = Math.max(...data.map(x));

          // Adjust the xScale domain to prevent invalid scales when maxDataValue is zero
          const xScale = scaleLinear<number>({
            domain: [0, maxDataValue > 0 ? maxDataValue : 1],
            range: [0, xMax],
            nice: true,
          });

          const yScale = scaleBand<string>({
            domain: data.map(y),
            range: [0, yMax],
            padding: 0.2,
          });

          return (
            <svg width={width} height={height}>
              <Group left={margin.left} top={margin.top}>
                {data.map((d: ChartDataItem, i: number) => {
                  const barWidth = xScale(x(d));
                  const barHeight = yScale.bandwidth();
                  const barX = 0;
                  const barY = yScale(y(d)) ?? 0;

                  return (
                    <Group key={`bar-${i.toString()}`}>
                      <Bar
                        x={barX}
                        y={barY}
                        width={barWidth}
                        height={barHeight}
                        fill={getBarColor(y(d))}
                        rx={4}
                        onMouseLeave={() => {
                          hideTooltip();
                        }}
                        onMouseMove={(event) => {
                          const coords = localPoint(event);
                          if (!coords) return;
                          showTooltip({
                            tooltipData: d,
                            tooltipLeft: coords.x + margin.left,
                            tooltipTop: coords.y + margin.top,
                          });
                        }}
                      />
                      {/* Label for the value */}
                      <Text
                        x={barX + barWidth + 8}
                        y={barY + barHeight / 2}
                        verticalAnchor="middle"
                        fill="var(--foreground)"
                        fontSize={12}
                      >
                        {x(d)}
                      </Text>
                    </Group>
                  );
                })}
              </Group>

              {/* Y-axis */}
              <AxisLeft
                scale={yScale}
                top={margin.top}
                left={margin.left}
                stroke={"#333"}
                tickStroke={"#333"}
                hideAxisLine={false}
                tickLabelProps={() => ({
                  fill: "#333",
                  fontSize: 12,
                  textAnchor: "end",
                  dx: -10,
                  dy: yScale.bandwidth() / 2,
                })}
              />

              {/* X-axis */}
              <AxisBottom
                scale={xScale}
                top={height - margin.bottom}
                left={margin.left}
                stroke={"#333"}
                tickStroke={"#333"}
                numTicks={5}
                tickLabelProps={() => ({
                  fill: "#333",
                  fontSize: 12,
                  textAnchor: "middle",
                  dy: "0.5em",
                })}
              />
            </svg>
          );
        }}
      </ParentSize>
      {/* Tooltip */}
      {tooltipOpen && tooltipData && (
        <TooltipWithBounds
          top={tooltipTop}
          left={tooltipLeft}
          style={{
            ...defaultStyles,
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            color: "white",
          }}
        >
          <strong>{tooltipData.month}</strong>: {tooltipData.desktop}
        </TooltipWithBounds>
      )}
    </div>
  );
}
