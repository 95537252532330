import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {
  type ReconciledStatementResultType,
  // SRFileHistoryRowActions,
} from "@/modules/statement-recon";

import {FallbackMessages, invoiceStatuses} from "@/utils/constants";

export const srFileHistoryTableColumns: ColumnDef<ReconciledStatementResultType>[] =
  [
    {
      accessorKey: "user_statement_invoice_number",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Statement Invoice #" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("user_statement_invoice_number") ? (
            row.getValue("user_statement_invoice_number")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "user_statement_invoice_date",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Statement Invoice Date" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("user_statement_invoice_date") ? (
            row.getValue("user_statement_invoice_date")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "user_statement_invoice_amount",
      header: ({column}) => (
        <DataTableColumnHeader
          column={column}
          title="Statement Invoice Amount"
        />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("user_statement_invoice_amount") ? (
            row.getValue("user_statement_invoice_amount")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "user_statement_po_number",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Statement PO Number" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("user_statement_po_number") ? (
            row.getValue("user_statement_po_number")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "im_sr_match_type",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Match Status" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("im_sr_match_type") ? (
            <Badge>{row.getValue("im_sr_match_type")}</Badge>
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "supplier_number",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Supplier Number" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("supplier_number") ? (
            row.getValue("supplier_number")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "supplier_name",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Supplier Name" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("supplier_name") ? (
            row.getValue("supplier_name")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "invoice_status_name",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Invoice Status" />
      ),
      cell: ({row}) => {
        if (!row.original.invoice_status_name) {
          return <Paragraph>{FallbackMessages.NOT_APPLICABLE}</Paragraph>;
        }

        const statusObject = invoiceStatuses.find(
          (status) => status.label === row.original.invoice_status_name
        );

        if (!statusObject) {
          return <Paragraph>{row.original.invoice_status_name}</Paragraph>;
        }

        const IconComponent = statusObject.icon;

        return (
          <Badge className="flex items-center gap-2 uppercase hover:opacity-70">
            <IconComponent />
            {row.original.invoice_status_name}
          </Badge>
        );
      },
    },
    {
      accessorKey: "invoice_amount",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Invoice Amount" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("invoice_amount") ? (
            row.getValue("invoice_amount")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "invoice_date",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Invoice Date" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("invoice_date") ? (
            row.getValue("invoice_date")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "invoice_paid_amount",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Invoice Paid Amount" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("invoice_paid_amount") ? (
            row.getValue("invoice_paid_amount")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "invoice_payment_date",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Invoice Payment Date" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("invoice_payment_date") ? (
            row.getValue("invoice_payment_date")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "facility_id",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Facility ID" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("facility_id") ? (
            row.getValue("facility_id")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "invoice_id",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Invoice ID" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("invoice_id") ? (
            row.getValue("invoice_id")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "supplier_id",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Supplier ID" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("supplier_id") ? (
            row.getValue("supplier_id")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "created_timestamp",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Created Timestamp" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("created_timestamp") ? (
            row.getValue("created_timestamp")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    {
      accessorKey: "updated_timestamp",
      header: ({column}) => (
        <DataTableColumnHeader column={column} title="Updated Timestamp" />
      ),
      cell: ({row}) => (
        <div className="w-fit">
          {row.getValue("updated_timestamp") ? (
            row.getValue("updated_timestamp")
          ) : (
            <Badge className="whitespace-nowrap" variant="destructive">
              {FallbackMessages.NO_DATA}
            </Badge>
          )}
        </div>
      ),
    },
    // {
    //   id: "actions",
    //   header: () => <Paragraph className="text-right">Actions</Paragraph>,
    //   cell: ({row}) => (
    //     <div className="flex justify-end">
    //       <SRFileHistoryRowActions row={row} />
    //     </div>
    //   ),
    // },
  ];
