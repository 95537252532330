import {kyApiFn} from "@/lib/ky";
import {
  type CustomerFormDTO,
  type CustomerModuleFormDTO,
} from "@/modules/admin";

export const editCustomerFn = (editCustomerDTO: CustomerFormDTO) =>
  kyApiFn(`customer/v1/edit`, "put", {
    json: editCustomerDTO,
  });

export const editCustomerModuleFn = (
  CustomerModuleFormDTO: CustomerModuleFormDTO
) =>
  kyApiFn(`customer/v1/edit`, "put", {
    json: CustomerModuleFormDTO,
  });
