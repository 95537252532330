import {zodResolver} from "@hookform/resolvers/zod";
import {useMutation} from "@tanstack/react-query";
import {Link, useNavigate} from "@tanstack/react-router";
import {ArrowRightIcon, SaveIcon} from "lucide-react";
import {useForm} from "react-hook-form";
import {type z} from "zod";

import {Button, buttonVariants} from "@/components/ui/button";
import {Card, CardContent, CardHeader} from "@/components/ui/card";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {Input} from "@/components/ui/input";
import {Heading2} from "@/components/ui/typography";
import {toast} from "@/components/ui/use-toast";

import {cn} from "@/lib/utils";
import {
  InvoicesMappingFormSchema,
  saveInvoiceMappingFn,
  useManageInvoicesMappingRoute,
} from "@/modules/invoices";

export function CreateInvoicesMappingPage() {
  useManageInvoicesMappingRoute();

  // Get the navigate function from the router
  const navigate = useNavigate();

  const createInvoicesMappingFormMethods = useForm<
    z.infer<typeof InvoicesMappingFormSchema>
  >({
    resolver: zodResolver(InvoicesMappingFormSchema),
  });

  // Initialize Tanstack Query Mutation for Refreshing Invoice Mapping
  const saveInvoiceMappingMutation = useMutation({
    mutationFn: saveInvoiceMappingFn,
    onSuccess: (response) => {
      console.log("saveInvoiceMappingResponse", response);
      createInvoicesMappingFormMethods.reset();
      toast({
        variant: "success",
        title: "Success!",
        description: "Invoice mapping saved successfully",
      });
      void navigate({to: "/app/invoices/mapping", replace: true});
    },
    onError: (error) => {
      let errorMessage = "An error occurred";
      if (typeof error === "string") {
        errorMessage = error;
      } else if (error instanceof Error && error.message) {
        errorMessage = error.message;
      }
      console.error("errorMessage", errorMessage);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to add invoice mapping",
      });
    },
  });
  const isMutating = saveInvoiceMappingMutation.isPending;

  function onSubmit(data: z.infer<typeof InvoicesMappingFormSchema>) {
    saveInvoiceMappingMutation.mutate(data);
  }

  return (
    <section>
      <div className="flex justify-between gap-2">
        <Heading2>Invoices Mapping</Heading2>
        <Link
          to="/app/invoices/mapping"
          className={cn(buttonVariants({variant: "secondary"}), "mb-4 gap-2")}
        >
          <span className="sr-only">Back to Invoices Mapping</span>
          Back to Invoices Mapping
          <ArrowRightIcon className="size-4" />
        </Link>
      </div>
      <Card>
        <CardHeader>
          <Heading2>Configure Invoices Mapping</Heading2>
        </CardHeader>
        <CardContent>
          <Form {...createInvoicesMappingFormMethods}>
            <form
              className="flex w-full flex-col gap-4"
              onSubmit={createInvoicesMappingFormMethods.handleSubmit(onSubmit)}
            >
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4 2xl:grid-cols-6">
                <FormField
                  name="configId"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="configId" showMandatoryAsterisk>
                        configId:
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter configId"
                          {...createInvoicesMappingFormMethods.register(
                            "configId",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="taxId"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="taxId">taxId:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter taxId"
                          {...createInvoicesMappingFormMethods.register(
                            "taxId",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="facilityName"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="facilityName">
                        facilityName:
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter facilityName"
                          {...createInvoicesMappingFormMethods.register(
                            "facilityName",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="invoiceDate"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="invoiceDate">invoiceDate:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter invoiceDate"
                          {...createInvoicesMappingFormMethods.register(
                            "invoiceDate",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="invoiceAmount"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="invoiceAmount">
                        invoiceAmount:
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter invoiceAmount"
                          {...createInvoicesMappingFormMethods.register(
                            "invoiceAmount",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="status"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="status">status:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter status"
                          {...createInvoicesMappingFormMethods.register(
                            "status",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="paymentDate"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="paymentDate">paymentDate:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter paymentDate"
                          {...createInvoicesMappingFormMethods.register(
                            "paymentDate",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="facilityId"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="facilityId">facilityId:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter facilityId"
                          {...createInvoicesMappingFormMethods.register(
                            "facilityId",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="vendorNumber"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="vendorNumber">
                        vendorNumber:
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter vendorNumber"
                          {...createInvoicesMappingFormMethods.register(
                            "vendorNumber",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="invoicePaidAmount"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="invoicePaidAmount">
                        invoicePaidAmount:
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter invoicePaidAmount"
                          {...createInvoicesMappingFormMethods.register(
                            "invoicePaidAmount",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="poNumber"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="poNumber">poNumber:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter poNumber"
                          {...createInvoicesMappingFormMethods.register(
                            "poNumber",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="suffix"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="suffix">suffix:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter suffix"
                          {...createInvoicesMappingFormMethods.register(
                            "suffix",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="cancelDate"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="cancelDate">cancelDate:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter cancelDate"
                          {...createInvoicesMappingFormMethods.register(
                            "cancelDate",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="invoiceType"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="invoiceType">invoiceType:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter invoiceType"
                          {...createInvoicesMappingFormMethods.register(
                            "invoiceType",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="erpUniqueId"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="erpUniqueId">erpUniqueId:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter erpUniqueId"
                          {...createInvoicesMappingFormMethods.register(
                            "erpUniqueId",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="paymentStatus"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="paymentStatus">
                        paymentStatus:
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter paymentStatus"
                          {...createInvoicesMappingFormMethods.register(
                            "paymentStatus",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="vendorName"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="vendorName">vendorName:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter vendorName"
                          {...createInvoicesMappingFormMethods.register(
                            "vendorName",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="paymentTerms"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="paymentTerms">
                        paymentTerms:
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter paymentTerms"
                          {...createInvoicesMappingFormMethods.register(
                            "paymentTerms",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="paymentMethod"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="paymentMethod">
                        paymentMethod:
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter paymentMethod"
                          {...createInvoicesMappingFormMethods.register(
                            "paymentMethod",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="invoiceNo"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="invoiceNo">invoiceNo:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter invoiceNo"
                          {...createInvoicesMappingFormMethods.register(
                            "invoiceNo",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="paymentNumber"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="paymentNumber">
                        paymentNumber:
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter paymentNumber"
                          {...createInvoicesMappingFormMethods.register(
                            "paymentNumber",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="dueDate"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="dueDate">dueDate:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter dueDate"
                          {...createInvoicesMappingFormMethods.register(
                            "dueDate",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="cancelId"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="cancelId">cancelId:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter cancelId"
                          {...createInvoicesMappingFormMethods.register(
                            "cancelId",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="voidDate"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="voidDate">voidDate:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter voidDate"
                          {...createInvoicesMappingFormMethods.register(
                            "voidDate",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="voidId"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="voidId">voidId:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter voidId"
                          {...createInvoicesMappingFormMethods.register(
                            "voidId",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="vendorGroup"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="vendorGroup">vendorGroup:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter vendorGroup"
                          {...createInvoicesMappingFormMethods.register(
                            "vendorGroup",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="vendorLocationCode"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="vendorLocationCode">
                        vendorLocationCode:
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter vendorLocationCode"
                          {...createInvoicesMappingFormMethods.register(
                            "vendorLocationCode",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="operatingUnit"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="operatingUnit">
                        operatingUnit:
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter operatingUnit"
                          {...createInvoicesMappingFormMethods.register(
                            "operatingUnit",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="createDate"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="createDate">createDate:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter createDate"
                          {...createInvoicesMappingFormMethods.register(
                            "createDate",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="paymentType"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="paymentType">paymentType:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter paymentType"
                          {...createInvoicesMappingFormMethods.register(
                            "paymentType",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="voucherLineNo"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="voucherLineNo">
                        voucherLineNo:
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter voucherLineNo"
                          {...createInvoicesMappingFormMethods.register(
                            "voucherLineNo",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="voucherLineAmount"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="voucherLineAmount">
                        voucherLineAmount:
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter voucherLineAmount"
                          {...createInvoicesMappingFormMethods.register(
                            "voucherLineAmount",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="discountDate"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="discountDate">
                        discountDate:
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter discountDate"
                          {...createInvoicesMappingFormMethods.register(
                            "discountDate",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="discountAmount"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="discountAmount">
                        discountAmount:
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter discountAmount"
                          {...createInvoicesMappingFormMethods.register(
                            "discountAmount",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="salesAmount"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="salesAmount">salesAmount:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter salesAmount"
                          {...createInvoicesMappingFormMethods.register(
                            "salesAmount",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="freightAmount"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="freightAmount">
                        freightAmount:
                      </FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter freightAmount"
                          {...createInvoicesMappingFormMethods.register(
                            "freightAmount",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="miscAmount"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="miscAmount">miscAmount:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter miscAmount"
                          {...createInvoicesMappingFormMethods.register(
                            "miscAmount",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="origin"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="origin">origin:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter origin"
                          {...createInvoicesMappingFormMethods.register(
                            "origin",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="grossAmount"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="grossAmount">grossAmount:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter grossAmount"
                          {...createInvoicesMappingFormMethods.register(
                            "grossAmount",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="futureUse1"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="futureUse1">futureUse1:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter futureUse1"
                          {...createInvoicesMappingFormMethods.register(
                            "futureUse1",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="futureUse2"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="futureUse2">futureUse2:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter futureUse2"
                          {...createInvoicesMappingFormMethods.register(
                            "futureUse2",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="futureUse3"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="futureUse3">futureUse3:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter futureUse3"
                          {...createInvoicesMappingFormMethods.register(
                            "futureUse3",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="futureUse4"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="futureUse4">futureUse4:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter futureUse4"
                          {...createInvoicesMappingFormMethods.register(
                            "futureUse4",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  name="futureUse5"
                  render={() => (
                    <FormItem>
                      <FormLabel htmlFor="futureUse5">futureUse5:</FormLabel>
                      <FormControl>
                        <Input
                          type="number"
                          min="0"
                          disabled={isMutating}
                          aria-disabled={isMutating}
                          placeholder="Enter futureUse5"
                          {...createInvoicesMappingFormMethods.register(
                            "futureUse5",
                            {
                              setValueAs: (v) =>
                                v === ""
                                  ? undefined
                                  : parseInt(v as string, 10),
                            }
                          )}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
              <Button
                type="submit"
                aria-disabled={isMutating}
                disabled={isMutating}
                size="sm"
                className="gap-2 self-end"
                variant="affirmative"
              >
                <SaveIcon className="size-4" />
                Save Invoices Mapping
              </Button>
            </form>
          </Form>
        </CardContent>
      </Card>
    </section>
  );
}
