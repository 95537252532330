import {createRoute} from "@tanstack/react-router";

import {PageNotFound} from "@/components/layout/not-found";

import {RoutesDirectory} from "@/lib/routes/directory";
import {authorizedRootRoute} from "@/lib/routes/root";
import {HelpOutlet, HelpPage} from "@/modules/help";

export const helpRootRoute = createRoute({
  path: RoutesDirectory.HELP,
  component: HelpOutlet,
  getParentRoute: () => authorizedRootRoute,
});

export const helpRoute = createRoute({
  path: RoutesDirectory.ROOT,
  component: HelpPage,
  getParentRoute: () => helpRootRoute,
});

export const helpNotFoundRoute = createRoute({
  path: RoutesDirectory.CATCH_ALL,
  component: PageNotFound,
  getParentRoute: () => helpRootRoute,
});
