import {useEffect, useState} from "react";

import {zodResolver} from "@hookform/resolvers/zod";
import {Label} from "@radix-ui/react-label";
import {useMutation} from "@tanstack/react-query";
import {type Row} from "@tanstack/react-table";
import {
  CircleSlashIcon,
  Edit2Icon,
  GripVerticalIcon,
  SendHorizonalIcon,
} from "lucide-react";
import {useForm} from "react-hook-form";
import type {z} from "zod";

import {Button} from "@/components/ui/button";
import {Dialog, DialogContent} from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/ui/form";
import {Spinner} from "@/components/ui/spinner";
import {Textarea} from "@/components/ui/textarea";
import {toast} from "@/components/ui/use-toast";

import {useKeyCloakInstanceStore} from "@/modules/auth";
import {
  deletePaymentCommentFn,
  PaymentCommentsFormSchema,
  PaymentManagementCommentSchema,
  updatePaymentCommentFn,
} from "@/modules/imremit";

import {KeycloakRoles} from "@/utils/constants";

interface PaymentCommentsRowActionsProps<TData> {
  row: Row<TData>;
}

export function PaymentCommentsRowActions<TData extends {comments: string}>({
  row,
}: PaymentCommentsRowActionsProps<TData>) {
  const paymentCommentRow = PaymentManagementCommentSchema.parse(row.original);

  const {storeKeyCloakInstance} = useKeyCloakInstanceStore();
  const [isEditing, setIsEditing] = useState(false);
  const paymentCommentsFormMethods = useForm<
    z.infer<typeof PaymentCommentsFormSchema>
  >({
    defaultValues: {
      userId: storeKeyCloakInstance?.tokenParsed?.sub as string,
      // TODO: Get rid of hard coded role
      role: KeycloakRoles.ProgramManager,
    },
    resolver: zodResolver(PaymentCommentsFormSchema),
  });

  const updatePaymentCommentMutation = useMutation({
    mutationFn: (data: z.infer<typeof PaymentCommentsFormSchema>) => {
      return updatePaymentCommentFn(
        data,
        paymentCommentRow.commentId.toString()
      );
    },

    onSuccess: (response) => {
      console.log("updatePaymentCommentResponse", response);
      paymentCommentsFormMethods.reset();

      toast({
        variant: "success",
        title: "Success!",
        description: "Comment updated successfully",
      });
      setIsEditing(false);
    },
    onError: (error: unknown) => {
      console.error("errorMessage", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to update comment",
      });
      setIsEditing(false);
    },
  });

  const deletePaymentCommentMutation = useMutation({
    mutationFn: () => {
      return deletePaymentCommentFn(paymentCommentRow.commentId);
    },
    onSuccess: (response) => {
      console.log("deletePaymentCommentResponse", response);
      toast({
        variant: "success",
        title: "Success!",
        description: "Comment deleted successfully",
      });
    },
    onError: (error: unknown) => {
      console.error("errorMessage", error);
      toast({
        variant: "destructive",
        title: "Error!",
        description: "Failed to delete comment",
      });
    },
  });

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = (data: z.infer<typeof PaymentCommentsFormSchema>) => {
    updatePaymentCommentMutation.mutate(data);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const {isPending: isMutating} = updatePaymentCommentMutation;

  useEffect(() => {
    if (paymentCommentRow.comments) {
      paymentCommentsFormMethods.setValue(
        "comments",
        paymentCommentRow.comments
      );
    }
  }, [paymentCommentRow.comments, paymentCommentsFormMethods]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="flex bg-theme hover:bg-neutral data-[state=open]:bg-secondary">
          <GripVerticalIcon className="size-6 bg-inherit" />
        </Button>
      </DropdownMenuTrigger>

      <Dialog open={isEditing}>
        <DialogContent
          className="space-x-2 rounded-md bg-white p-4"
          removeCloseButton
        >
          <Form {...paymentCommentsFormMethods}>
            <form
              className="grid w-full max-w-lg gap-1.5"
              onSubmit={paymentCommentsFormMethods.handleSubmit((data) => {
                handleSaveClick(data);
              })}
            >
              <Label htmlFor="comments">Edit your comment here:</Label>
              <FormField
                name="comments"
                render={() => (
                  <FormItem>
                    <FormControl>
                      <Textarea
                        id="comments"
                        placeholder="Type your comment here."
                        {...paymentCommentsFormMethods.register("comments")}
                        autoComplete="comments"
                        autoCorrect="off"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="mt-4 flex gap-2">
                <Button
                  type="submit"
                  disabled={isMutating}
                  aria-disabled={isMutating}
                  variant="affirmative"
                  size="xs"
                >
                  {isMutating ? (
                    <Spinner size="xs" />
                  ) : (
                    <SendHorizonalIcon className="mr-2 text-white" />
                  )}
                  Save
                </Button>
                <Button
                  variant="secondary"
                  size="xs"
                  onClick={(event) => {
                    event.preventDefault();
                    handleCancelClick();
                  }}
                >
                  <CircleSlashIcon className="mr-2" />
                  Cancel
                </Button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>

      <DropdownMenuContent align="end">
        <DropdownMenuItem
          className="flex justify-between gap-4"
          onSelect={handleEditClick}
        >
          <span className="sr-only">Edit Comment</span>
          Edit Comment
          <Edit2Icon className="size-4" />
        </DropdownMenuItem>
        {
          // Only the user who created the comment can delete it
          storeKeyCloakInstance?.tokenParsed?.sub ===
            paymentCommentRow.userId && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="gap-4 bg-destructive text-destructive-foreground"
                onSelect={() => {
                  deletePaymentCommentMutation.mutate();
                }}
              >
                Delete Comment
                <DropdownMenuShortcut>⌘⌫</DropdownMenuShortcut>
              </DropdownMenuItem>
            </>
          )
        }
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
