import {createRoute} from "@tanstack/react-router";
import {z} from "zod";

import {PageNotFound} from "@/components/layout/not-found";

import {RoutesDirectory} from "@/lib/routes/directory";
import {authorizedRootRoute} from "@/lib/routes/root";
import {ImREmitOutlet} from "@/modules/imremit";

export const imREmitRootRoute = createRoute({
  path: RoutesDirectory.IMREMIT,
  component: ImREmitOutlet,
  getParentRoute: () => authorizedRootRoute,
});

export const imremitSearchFilterSchema = z.object({
  status: z.string().optional(),
  dateRange: z
    .object({
      startDate: z.number().optional(),
      endDate: z.number().optional(),
    })
    .optional(),
});

export const imREmitNotFoundRoute = createRoute({
  // all routes from /app that are not defined will be redirected to this route
  path: RoutesDirectory.CATCH_ALL,
  component: PageNotFound,
  getParentRoute: () => imREmitRootRoute,
});
