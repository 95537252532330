import {forwardRef, useCallback} from "react";

import type {VariantProps} from "class-variance-authority";
import {cva} from "class-variance-authority";
import {LogOutIcon} from "lucide-react";

import {cn} from "@/lib/utils";
import {useKeyCloakInstanceStore} from "@/modules/auth";

// Define class variants for the button
const authButtonVariants = cva(
  "border-none outline-none px-0 cursor-pointer h-16 w-16 flex flex-col justify-center items-center text-center no-underline transition-colors",
  {
    variants: {
      isDestructive: {
        true: "text-destructive-foreground bg-destructive hover:text-destructive-foreground hover:bg-destructive-foreground/40 active:bg-destructive-foreground/60 active:text-destructive-foreground",
        false:
          "text-success-foreground bg-success hover:text-success-foreground hover:bg-success-foreground/40 active:bg-success-foreground/60 active:text-success-foreground",
      },
    },
  }
);

// Define props type for the button
type AuthButtonProps = React.HTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof authButtonVariants>;

// Define a button primitive that supports the variant defined above
const AuthButtonPrimative = forwardRef<HTMLButtonElement, AuthButtonProps>(
  ({className, isDestructive, ...props}, ref) => {
    // Combine the class names from the variant and any additional classes passed in
    return (
      <button
        ref={ref}
        className={cn(authButtonVariants({isDestructive, className}))}
        {...props}
      />
    );
  }
);
AuthButtonPrimative.displayName = "AuthButtonPrimative";

function useLogoutFn() {
  // Get the storeKeyCloakInstance from the useKeyCloakInstanceStore hook
  const storeKeyCloakInstance =
    useKeyCloakInstanceStore().storeKeyCloakInstance;

  // Define the logout function
  const logoutFn = useCallback(() => {
    void storeKeyCloakInstance?.logout();
  }, [storeKeyCloakInstance]);

  return {logoutFn};
}

// Define the main AuthButton component that uses the primitive and authentication hooks
export function AuthButton() {
  // Get the parsedUserInfo and logoutFn from the useUserInfo hook
  const {logoutFn} = useLogoutFn();

  // If there is a user, show a logout button
  return (
    <AuthButtonPrimative
      isDestructive
      onClick={() => {
        logoutFn();
      }}
    >
      <span className="sr-only">Logout</span>
      <LogOutIcon className="size-8" />
    </AuthButtonPrimative>
  );
}
