import {create} from "zustand";
import {createJSONStorage, persist} from "zustand/middleware";

import {type PaymentManagementFormType} from "@/modules/imremit";

interface PaymentManagementFormStoreState {
  paymentManagementFormStoreValue: PaymentManagementFormType;
  setPaymentManagementFormStoreValue: (
    paymentManagementFormStoreValue: PaymentManagementFormType
  ) => void;
}

export const usePaymentManagementFormStore = create(
  persist<PaymentManagementFormStoreState>(
    (set) => ({
      paymentManagementFormStoreValue: {
        remittanceMethod: "",
        accountNumber: "",
        paymentNumber: "",
        initiatedDate: "",
        endDate: "",
        suppliers: [],
        status: [],
      },
      setPaymentManagementFormStoreValue: (paymentManagementFormStoreValue) => {
        set({paymentManagementFormStoreValue});
      },
    }),
    {
      name: "payment-management-form-store",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
