import {createRoute} from "@tanstack/react-router";

import {RoutesDirectory} from "@/lib/routes/directory";
import {FileProcessingPage, imREmitRootRoute} from "@/modules/imremit";

export const imREmitFileProcessingRoute = createRoute({
  path: RoutesDirectory.FILE_PROCESSING,
  component: FileProcessingPage,
  getParentRoute: () => imREmitRootRoute,
});
