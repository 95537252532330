import {createRoute} from "@tanstack/react-router";

import {RoutesDirectory} from "@/lib/routes/directory";
import {imREmitRootRoute, PDFUpload, ViewPDFUpload} from "@/modules/imremit";

export const pdfUploadRoute = createRoute({
  path: RoutesDirectory.PDF_UPLOAD,
  component: PDFUpload,
  getParentRoute: () => imREmitRootRoute,
});

export const pdfUploadViewRoute = createRoute({
  path: `${RoutesDirectory.PDF_UPLOAD}/$fileId/$externalId`,
  component: ViewPDFUpload,
  getParentRoute: () => imREmitRootRoute,
});
