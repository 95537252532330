import {kyApiFn} from "@/lib/ky";
import {type blockPaymentType} from "@/modules/imremit";

export const blockPaymentFn = (
  blockPaymentType: blockPaymentType,
  paymentId: string
) =>
  kyApiFn(
    `api/e-payable/management/payment-details/${paymentId}/block`,
    "put",
    {
      json: blockPaymentType,
    }
  );
