import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  dupesRunHistoryKeys,
  type DupesRunHistoryType,
} from "@/modules/duplicate-payments";

// Type alias for the function `getDupesRunHistoryByExternalId` 🧬
type QueryFnType = typeof getDupesRunHistoryByExternalId;

// Function to fetch all duplicate character settings for a customer 🚀
export function getDupesRunHistoryByExternalId(externalId?: string) {
  // API call using ky, specifying the return type 🌍
  return kyApiFn<DupesRunHistoryType[]>(
    `duplicate-payments/v1/run-history/${externalId || ""}/list`,
    "get"
  );
}

// Custom hook to fetch and cache duplicate customer settings using react-query 🪝
export function useGetDupesRunHistoryByExternalId(
  externalId?: string,
  config?: QueryConfig<QueryFnType>
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading additional configuration options 🛠
    queryKey: dupesRunHistoryKeys.byCustomerId(externalId), // Unique key for cache 🗝
    queryFn: () => getDupesRunHistoryByExternalId(externalId), // Data fetching function 📡
    enabled: !!externalId, // Condition to enable the query 🚦
  });
}
