import {create} from "zustand";

import {type SavedSearchDTOType} from "@/modules/invoices";

// Defining the shape of the state object for the SavedSearchStore
// In this case, the state will consist of an array of SavedSearchDTOType objects
interface SavedSearchStoreState {
  storeSavedSearch: SavedSearchDTOType | null;
}

// Defining the actions that can be performed on the SavedSearchStore state
// In this case, there's only one action, updateStoreSavedSearch, which takes an array of SavedSearchDTOType objects
interface SavedSearchStoreActions {
  updateStoreSavedSearch: (
    storeSavedSearch: SavedSearchStoreState["storeSavedSearch"]
  ) => void;
}

// Creating the Zustand store using the create function
// The store is named useSavedSearchStore, and it uses the types defined above for its state and actions
// The state begins with an empty storeSavedSearch array
// The updateStoreSavedSearch action uses Zustand's set function to update the state
export const useSavedSearchStore = create<
  SavedSearchStoreState & SavedSearchStoreActions
>((set) => ({
  // Initial state of the store. An empty array of storeSavedSearch.
  storeSavedSearch: null,

  // Action to update the storeSavedSearch array in the store.
  // This function takes a new storeSavedSearch array and uses the set function to update the state.
  updateStoreSavedSearch: (storeSavedSearch) => {
    set(() => ({storeSavedSearch}));
  },
}));
