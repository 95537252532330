import {createRoute} from "@tanstack/react-router";

import {RoutesDirectory} from "@/lib/routes/directory";
import {
  StatementMappingPage,
  StatementReconciliationPage,
  statementReconRootRoute,
  StatementReconUploadPage,
} from "@/modules/statement-recon";

export const statementReconFileUploadRoute = createRoute({
  path: RoutesDirectory.UPLOAD,
  component: StatementReconUploadPage,
  getParentRoute: () => statementReconRootRoute,
});

export const statementReconMappingRoute = createRoute({
  path: `${RoutesDirectory.UPLOAD}/$fileId${RoutesDirectory.MAPPING}/$externalId`,
  component: StatementMappingPage,
  getParentRoute: () => statementReconRootRoute,
});

export const statementReconciliationRoute = createRoute({
  path: `${RoutesDirectory.UPLOAD}/$fileId${RoutesDirectory.RECONCILIATION}/$externalId`,
  component: StatementReconciliationPage,
  getParentRoute: () => statementReconRootRoute,
});
