import {z} from "zod";

export const paymentManagementInvoicesSchema = z.object({
  invoiceId: z.string(),
  invoiceNumber: z.string(),
  invoiceDate: z.string(),
  poNumber: z.string().nullable().optional(),
  invoiceAmountPaid: z.number(),
  invoiceTotalAmount: z.number(),
  discountAmount: z.number(),
  adjustmentAmount: z.number(),
  comments: z.string().nullable().optional(),
});

export type paymentManagementInvoices = z.infer<
  typeof paymentManagementInvoicesSchema
>;
