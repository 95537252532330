// Constants for query keys related to Payment File mappings 📂
export const paymentFileMappingKeys = {
  // Base query key for Payment File mappings
  base: ["paymentFileMapping"] as const,

  // Generates a query key for fetching all Payment File mappings
  allPaymentFileMapping: () =>
    [...paymentFileMappingKeys.base, "allPaymentFileMapping"] as const,
};

// Constants for query keys related to Recon File mappings 🗃️
export const reconFileMappingKeys = {
  // Base query key for Recon File mappings
  base: ["reconFileMapping"] as const,

  // Generates a query key for fetching all Recon File mappings
  allReconFileMapping: () =>
    [...reconFileMappingKeys.base, "allReconFileMapping"] as const,
};

// Constants for query keys related to Audit File mappings 📊
export const auditFileMappingKeys = {
  // Base query key for Audit File mappings
  base: ["auditFileMapping"] as const,

  // Generates a query key for fetching all Audit File mappings
  allAuditFileMapping: () =>
    [...auditFileMappingKeys.base, "allAuditFileMapping"] as const,
};

// Constants for query keys related to Bank File mappings 🏦
export const bankFileMappingKeys = {
  // Base query key for Bank File mappings
  base: ["bankFileMapping"] as const,

  // Generates a query key for fetching all Bank File mappings
  allBankFileMapping: () =>
    [...bankFileMappingKeys.base, "allBankFileMapping"] as const,
};

// Constants for query keys related to Response File mappings 📩
export const responseFileMappingKeys = {
  // Base query key for Response File mappings
  base: ["responseFileMapping"] as const,

  // Generates a query key for fetching all Response File mappings
  allResponseFileMapping: () =>
    [...responseFileMappingKeys.base, "allResponseFileMapping"] as const,
};

// Constants for query keys related to Date Format configurations 📅
export const dateFormatsKeys = {
  // Base query key for Date Format configurations
  base: ["dateFormats"] as const,

  // Generates a query key for fetching all Date Format configurations
  allDateFormats: () => [...dateFormatsKeys.base, "allDateFormats"] as const,
};

// Constants for query keys related to Delimiter Types configurations 🔢
export const delimiterTypesKeys = {
  // Base query key for Delimiter Types configurations
  base: ["delimiterTypes"] as const,

  // Generates a query key for fetching all Delimiter Types configurations
  allDelimiterTypes: () =>
    [...delimiterTypesKeys.base, "allDelimiterTypes"] as const,
};

// Constants for query keys related to Document Format settings 📝
export const documentFormatKeys = {
  // Base query key for Document Format settings
  base: ["documentFormat"] as const,

  // Generates a query key for fetching all Document Format settings
  allDocumentFormats: () =>
    [...documentFormatKeys.base, "allDocumentFormats"] as const,
};

// Constants for query keys related to Document Types configurations 🗂️
export const documentTypesKeys = {
  // Base query key for Document Types configurations
  base: ["documentTypes"] as const,

  // Generates a query key for fetching all Document Types configurations
  allDocumentFormats: () =>
    [...documentTypesKeys.base, "allDocumentFormats"] as const,
};
