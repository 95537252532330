import {
  BankFileDataTable,
  responseFileColumns,
  useResponseFileDetails,
  type BankAuditMappingFileSpecsType,
} from "@/modules/imremit";

/**
 * ResponseFileList Component
 *
 * This component provides the report for Response File Mapping
 * and rendering the Response File Mapping  report table.
 */
export function ResponseFileList() {
  // Fetch the Response File report data using the custom hook `useResponseFileDetails`
  const searchResponseFileResponse = useResponseFileDetails();

  // Destructure the `data` property, focusing on its `content` attribute
  const responseFiletData = searchResponseFileResponse.data?.content || [];

  /**
   * `responseFileAllData` array is holding all the `fileSpecs[]` data in single array- not a good approch
   * This is temporary basis, will update in future
   * */
  const responseFileAllData: BankAuditMappingFileSpecsType[] = [];
  responseFiletData.forEach((data) => {
    // This is stupid approach, will update in future
    responseFileAllData.push(data);
    data.fileSpecs.forEach((fileSpecsData) => {
      fileSpecsData.masterMappingName = data.masterMappingName;
      responseFileAllData.push(fileSpecsData);
    });
  });

  // Render the Response File report table when the  data available
  return (
    <BankFileDataTable
      columns={responseFileColumns}
      data={responseFileAllData}
      isPending={searchResponseFileResponse.isPending}
      isSuccess={searchResponseFileResponse.isSuccess}
      isError={searchResponseFileResponse.isError}
    />
  );
}
