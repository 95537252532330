import {z} from "zod";

export const supplierScriptSchema = z.object({
  supplierScriptId: z.string(),
  supplierId: z.number(),
  sequenceId: z.number(),
  fieldName: z.string(),
  fieldValue: z.string(),
  pageCategory: z.string(),
  fieldType: z.string(),
  position: z.string(),
  separator: z.string(),
  dropDownValue: z.string(),
  tableName: z.string(),
  tableColumnName: z.string(),
  timer: z.string(),
  captchaTimer: z.number(),
  paymentTimer: z.number(),
  dateFormat: z.string(),
  loginSkip: z.boolean().nullable(),
  jsCheck: z.string(),
  sequenceIdentifier: z.string(),
  findElementBy: z.string(),
});

// EXPORT SUPPLIER SCRIPTS
export const ExportSupplierScriptsDataFormSchema = z.object({
  emailId: z.string(),
  subject: z.string().min(1).max(140),
  message: z.string().min(1),
});

export type ExportSupplierScriptsDataFormDTO = z.infer<
  typeof ExportSupplierScriptsDataFormSchema
>;

export type SupplierScript = z.infer<typeof supplierScriptSchema>;

//Union for module type
export type ModuleTypeUnion = "imremit" | "imremit-lite";
