import {useQuery} from "@tanstack/react-query";

import {kyApiFn} from "@/lib/ky";
import {type ExtractFnReturnType, type QueryConfig} from "@/lib/query-client";
import {
  imREmitSupplierScriptsQueryKey,
  type SupplierScript,
} from "@/modules/imremit";

// Defining a type based on the function `searchSupplierScripts`
type QueryFnType = typeof getSupplierScripts;

// Function to search imREmits supplier scripts
export function getSupplierScripts(supplierId?: string) {
  return kyApiFn<SupplierScript[]>(
    `api/e-payable/management/supplier-script/${supplierId}/edit`,
    "get"
  );
  // TODO: validate data
}

// Custom hook to fetch and cache imREmits supplier scripts data using react-query
export function useGetSupplierScripts(
  supplierId?: string,
  config?: QueryConfig<QueryFnType> // The config object
) {
  return useQuery<ExtractFnReturnType<QueryFnType>>({
    ...config, // Spreading the received config
    queryKey: imREmitSupplierScriptsQueryKey.bySupplierId(supplierId), // Setting the query key to be used for caching and fetching by react-query
    queryFn: () => getSupplierScripts(supplierId), // Setting the function to be used for fetching data
    enabled: !!supplierId,
  });
}
