import {
  reconFileColumns,
  ReconFileDataTable,
  useReconFileDetails,
} from "@/modules/imremit";

/**
 * ReconFileList Component
 *
 * This component provides the report for Recon File Mapping
 * and rendering the Recon file report table.
 */
export function ReconFileList() {
  // Fetch the recon report data using the custom hook `useReconFileDetails`
  const searchReconFileResponse = useReconFileDetails();

  // Destructure the `data` property, focusing on its `content` attribute
  const reconFiletData = searchReconFileResponse.data?.content || [];

  // Render the  Recon file report table when the  Recon file mapping data available
  return (
    <ReconFileDataTable
      columns={reconFileColumns}
      data={reconFiletData}
      isPending={searchReconFileResponse.isPending}
      isSuccess={searchReconFileResponse.isSuccess}
      isError={searchReconFileResponse.isError}
    />
  );
}
