import {type ColumnDef} from "@tanstack/react-table";

import {Badge} from "@/components/ui/badge";
import {Paragraph} from "@/components/ui/typography";
import {DataTableColumnHeader} from "@/components/craft/data-table";

import {ParticipantRegisterRowActions} from "@/modules/admin";
import {type Facilities} from "@/modules/facilities";

import {FallbackMessages} from "@/utils/constants";

export const participantRegisterTableColumns: ColumnDef<Facilities>[] = [
  {
    accessorKey: "facilityName",
    header: ({column}) => (
      <DataTableColumnHeader
        column={column}
        title="
      Facility Name
      "
      />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("facilityName") ? (
          row.getValue("facilityName")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "bankAccountNumber",
    header: ({column}) => (
      <DataTableColumnHeader
        column={column}
        title="
      Bank Account #
      "
      />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("bankAccountNumber") ? (
          row.getValue("bankAccountNumber")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "orgId",
    header: ({column}) => (
      <DataTableColumnHeader
        column={column}
        title="
      Organization ID
      "
      />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("orgId") ? (
          row.getValue("orgId")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },

  {
    accessorKey: "userId",
    header: ({column}) => (
      <DataTableColumnHeader
        column={column}
        title="
      User ID
      "
      />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("userId") ? (
          row.getValue("userId")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "groupId",
    header: ({column}) => (
      <DataTableColumnHeader
        column={column}
        title="
      Group ID
      "
      />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("groupId") ? (
          row.getValue("groupId")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "futureUse1",
    header: ({column}) => (
      <DataTableColumnHeader
        column={column}
        title="Payment Filename Identifier"
      />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("futureUse1") ? (
          row.getValue("futureUse1")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    accessorKey: "futureUse2",
    header: ({column}) => (
      <DataTableColumnHeader column={column} title="Payment File Identifier" />
    ),
    cell: ({row}) => (
      <div className="w-fit">
        {row.getValue("futureUse2") ? (
          row.getValue("futureUse2")
        ) : (
          <Badge className="whitespace-nowrap" variant="destructive">
            {FallbackMessages.NO_DATA}
          </Badge>
        )}
      </div>
    ),
  },
  {
    id: "actions",
    header: () => <Paragraph className="text-right">Actions</Paragraph>,
    cell: ({row}) => (
      <div className="flex justify-end">
        <ParticipantRegisterRowActions row={row} />
      </div>
    ),
  },
];
