import {useEffect} from "react";

import {Outlet} from "@tanstack/react-router";

import {Heading1, Paragraph} from "@/components/ui/typography";
import {ReturnToTop} from "@/components/craft/return-to-top";

import {SettingsRoutesSchema} from "@/lib/routes/types";
import {useRoutesStore} from "@/lib/stores";

export function SettingsOutlet() {
  const {setSettingsRoute} = useRoutesStore();

  useEffect(() => {
    setSettingsRoute(SettingsRoutesSchema.Values["/app/settings"]);
  }, [setSettingsRoute]);

  return (
    <article role="region" aria-roledescription="This is the settings page.">
      <section
        className="flex flex-row items-start justify-between"
        role="presentation"
      >
        <div className="mb-4 flex flex-col">
          <Heading1 className="self-start">Settings</Heading1>
          <Paragraph>
            This is the settings page. Adjust your settings here.
          </Paragraph>
        </div>
      </section>

      <Outlet />

      <ReturnToTop />
    </article>
  );
}
